import React, { Component } from 'react';
import Slider from 'react-slick';
import { Grid } from '@material-ui/core';

export default class CarouselAvaliacao extends Component {

    render() {
        let settings = {
            dots: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: true,
            responsive: [
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
            ]
        };
        return (
            <Grid container justify={'center'} className={'carousel'}>
                <Grid item xs={12} className={this.props.top ? 'line-top' : 'line-bot'}>
                    <div className={'carousel-width'}>
                        <Slider {...settings}>
                            {this.props.content}
                        </Slider>
                    </div>
                </Grid>
            </Grid>
        );
    }
}