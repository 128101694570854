import React, { Component } from 'react';
import Modal from '@material-ui/core/Modal';
import Icon from '@material-ui/core/Icon';
import './modal.scss';

export default class SimpleModal extends Component  {

    render() {
        return (
            <Modal 
            aria-labelledby='simple-modal-title' 
            aria-describedby='simple-modal-description'
            disableBackdropClick={this.props.disableClick != null ? this.props.disableClick : false}
            open={this.props.isOpen} 
            onClose={this.props.handleClose}>
                <div className={'modal-pai ' + (this.props.className ? this.props.className : '') } style={{width: this.props.width}}>
                    <Icon onClick={this.props.handleClose}>clear</Icon>
                    {/* this.props.type should be 'info', or 'warning' or 'success' */}
                    <div className={this.props.type}></div>
                    <div className={'modal-filho'}>
                        {this.props.children}
                    </div>
                </div>
            </Modal>
        );
    };
}
