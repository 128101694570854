import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import './sectorHeaderCard.scss'
import DashboardCard from '../dashboardCard/dashboardCard';

export default class SectorHeaderCard extends Component {

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className={'card card-carousel-fixed'}>
                    {this.props.tabSector ? <>
                        <Grid container direction={'column'} justify={'center'} className={'card-light-gray'}>
                            <Grid item container justify={'center'}>
                                <div className='card-titles-div bold'>
                                    <label >{'SETORES'}</label>
                                    <label >{'INDUSTRIAIS'}</label>
                                </div>
                                
                            </Grid>
                        </Grid>
                        <>
                            <Grid item xs={12} >
                                <DashboardCard color={'card-blue-small'} text={
                                <div className='card-titles-div white-label'>
                                    <label >{'Atendimentos'}</label>
                                    <label >{'concluídos'}</label>
                                </div>} />
                            </Grid>
                            <Grid item xs={12} >
                                <DashboardCard color={'card-green'} text={
                                    <div className='card-titles-div white-label'>
                                        <label >{'Atendimentos'}</label>
                                        <label >{'em andamento'}</label>
                                    </div> }  />
                            </Grid>
                            <Grid item xs={12}>
                                <DashboardCard color={'card-dark-gray'} text={
                                    <div className='card-titles-div white-label'>
                                        <label >{'Média de aumento'}</label>
                                        <label >{'de produtividade'}</label>
                                        <label >{'dos atendimentos'}</label>
                                        <label >{'concluídos'}</label>
                                    </div>} /> 
                            </Grid>
                        </>
                    </> : 
                    <>
                        <Grid container direction={'column'} justify={'center'} className={'card-light-gray'}>
                            <Grid item container justify={'center'}>
                                <div className='card-titles-div bold'>
                                    <label >{'NOME DA'}</label>
                                    <label >{'FERRAMENTA'}</label>
                                </div>
                                
                            </Grid>
                        </Grid>
                        <>
                            <Grid item xs={12} >
                                <DashboardCard color={'card-green'} text={
                                    <div className='card-titles-div white-label'>
                                        <label >{'Nº de utilizações'}</label>
                                        <label >{'nos atendimentos'}</label>
                                    </div> }  />
                            </Grid>
                            <Grid item xs={12}>
                                <DashboardCard color={'card-dark-gray'} text={
                                    <div className='card-titles-div white-label'>
                                        <label >{'Setor industrial'}</label>
                                        <label >{'que mais utiliza'}</label>
                                        <label >{'a ferramenta'}</label>
                                    </div>} /> 
                            </Grid>
                        </>
                    </> 
                    }
                    
                </Grid>
            </Grid>
        )
    }
}

