import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper';
import { Grid } from '@material-ui/core';
import Page from '../../components/page/page';
import API from '../../comm/API';
import InputSelect from '../../components/inputselect/inputselect';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import './eventoNovo.scss'

export default class EventoNovo extends Component {

    titles = ['Dados do Evento', 'Participantes', 'Revisão'];

    evento = {
        nome: '',
        ano: new Date().getFullYear(),
        tipoEvento: '',
        cidade: '',
        pais: 'BRASIL',
        isOrganizado: true,
        isRecebida: false,
        descricao: '',
        duration: '',
        dataInicio: new Date(),
        dataConclusao: new Date(),
        estado: '',
        classificacao: '',
        unidade: null,
        eventoColaboradorList: []
    };

    errors = {
        nome: '',
        tipoEvento: '', 
        classificacao: '', 
        cidade: '', 
        pais: '', 
        ano: '', 
        duration: '', 
        eventoColaboradorList: '', 
        dataInicio: '', 
        dataConclusao: ''
    };

    tipoEventoList = [
        { id: 0, type: 'tipoEvento', label: 'Congresso' },
        { id: 1, type: 'tipoEvento', label: 'Seminário' },
        { id: 2, type: 'tipoEvento', label: 'Simpósio' },
        { id: 3, type: 'tipoEvento', label: 'Oficina' },
        { id: 4, type: 'tipoEvento', label: 'Encontro' },
        { id: 5, type: 'tipoEvento', label: 'Olimpíada' },
        { id: 6, type: 'tipoEvento', label: 'Feira' },
        { id: 7, type: 'tipoEvento', label: 'Exposição' }
    ];
    classificacaoList = [
        { id: 0, type: 'classificacao', label: 'Internacional' },
        { id: 1, type: 'classificacao', label: 'Nacional' },
        { id: 2, type: 'classificacao', label: 'Regional' },
        { id: 3, type: 'classificacao', label: 'Local' }
    ];
    anoList = [];
    currentYear = new Date().getFullYear();

    constructor(props) {
        super(props)
        this.state = {
            user: undefined,
            idColaborador: props.idEvento !== undefined ? props.idEvento : undefined,
            participantesList: []
        };

        this.getEvento();
        for (var i = this.currentYear; i >= 1943; i--) {
            this.anoList.push({ id: i, type: 'ano', label: i });
        }
    }

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getEvento = () => {
        if (this.props.idEvento !== undefined && !isNaN(this.props.idEvento)) {
            API.get('rest/gestao/Evento/' + this.props.idEvento).then(res => {
                this.evento = res.data;
                this.getColaboradorList();
                this.setState(this.state);
            });
        } else {
            if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
                this.evento.unidade = this.props.usuarioConectado.colaborador.unidade;
            } else {
                this.evento.unidade = this.props.usuarioConectado.unidade;
            }
            this.getColaboradorList();
        }
    };

    getColaboradorList = () => {
        var filter = { 'unidade': this.evento.unidade.id };
        API.get('rest/gestao/ColaboradorUnidade/find', { params: filter }).then(res => {
            this.setState({ colaboradorList: res.data });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao carregar lista de colaboradores. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    validateEvento = (step) => {
        switch (step) {
            case 0:
                return this.evento.nome && this.evento.tipoEvento && this.evento.classificacao && this.evento.cidade && this.evento.pais && this.evento.duration
                    && this.errors.dataConclusao === '' && this.errors.dataInicio === '';
            case 1:
                return this.evento.eventoColaboradorList.length > 0;
            default:
                return true;
        }
    };

    saveEvento = () => {
        this.showLoading();
        if (this.evento.id === undefined) {
            API.post('rest/gestao/Evento', this.evento).then(res => {
                this.closeLoading();
                this.showToast('Evento cadastrado com sucesso!', 'success', '/cad/pessoa/evento');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao cadastrar evento.Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Evento/' + this.evento.id, this.evento).then(res => {
                this.closeLoading();
                this.showToast('Evento editado com sucesso!', 'success', '/cad/pessoa/evento');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao editar evento. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }

    formatData(date) {
        if (typeof (date) !== 'string') {
            let dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                var dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    }

    changeInput = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'dataInicio') {
                this.evento[e.target.id] = e.target.value + 'T00:00:00-03:00';
            } else if (e.target.id === 'dataConclusao') {
                this.evento[e.target.id] = e.target.value + 'T23:59:59-03:00';
            } else if (e.target.type === 'text' || e.target.type === 'textarea') {
                this.evento[e.target.id] = e.target.value.toUpperCase();
            } else {
                this.evento[e.target.id] = e.target.value;
            }
            if (e.target.value === '' && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors[e.target.id] = 'Campo obrigatório';
            } else if (this.formatData(this.evento.dataInicio) > this.formatData(this.evento.dataConclusao) && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors[e.target.id] = 'Data de início deve ser menor ou igual a data de conclusão';
            } else if (this.formatData(this.evento.dataInicio) <= this.formatData(this.evento.dataConclusao) && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors['dataConclusao'] = '';
                this.errors['dataInicio'] = '';
            } else if (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio') {
                this.errors[e.target.id] = '';
            }
            if (e.target.type === 'radio') {
                this.evento.isOrganizado = !this.evento.isOrganizado;
            } else if (e.target.required) {
                if (e.target.value == null || e.target.value === '') {
                    this.errors[e.target.id] = 'Campo obrigatório';
                } else {
                    this.errors[e.target.id] = '';
                }
            }
        } else if (e != null && e.type != null) {
            this.evento[e.type] = e.label;
        } else if (e != null && e !== '') {
            this.evento.eventoColaboradorList = e;
        }
        this.setState(this.state);
    }

    getSteps = () => {
        return [
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={7} xl={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Nome'} id={'nome'} inputValue={this.evento.nome} changeValue={this.changeInput}
                                required={'required'} error={this.errors.nome} subClass={this.errors.nome.subClass} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <InputSelect
                                label={'Tipo do Evento'}
                                suggestions={this.tipoEventoList}
                                itemKey={'label'}
                                itemLabel={'label'}
                                id={'tipoEvento'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ label: this.evento.tipoEvento != null ? this.evento.tipoEvento : undefined }}
                                error={this.errors.tipoEvento}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect
                                label={'Classificação do Evento'}
                                suggestions={this.classificacaoList}
                                itemKey={'label'}
                                itemLabel={'label'}
                                id={'classificacao'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ label: this.evento.classificacao != null ? this.evento.classificacao : undefined }}
                                error={this.errors.classificacao}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <FormField type={'text'} label={'Cidade'} id={'cidade'} inputValue={this.evento.cidade} changeValue={this.changeInput}
                                required={'required'} error={this.errors.cidade} />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <FormField type={'text'} label={'País'} id={'pais'} inputValue={this.evento.pais} changeValue={this.changeInput}
                                required={'required'} error={this.errors.pais} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputSelect
                                label={'Ano'}
                                suggestions={this.anoList}
                                itemKey={'label'}
                                itemLabel={'label'}
                                id={'ano'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ label: this.evento.ano != null ? this.evento.ano : undefined }}
                                error={this.errors.ano}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <FormField type={'date'} label={'Início do Evento'} ref='dataInicio' id='dataInicio'
                                inputValue={this.formatData(this.evento.dataInicio)} changeValue={this.changeInput}
                                error={this.errors.dataInicio} icon={'calendar.svg'} />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <FormField type={'date'} label={'Fim do Evento'} ref='dataConclusao' id='dataConclusao'
                                inputValue={this.formatData(this.evento.dataConclusao)} changeValue={this.changeInput}
                                error={this.errors.dataConclusao} icon={'calendar.svg'} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormField type={'number'} label={'Duração'} id={'duration'} placeholder={'Em horas'} inputValue={this.evento.duration} changeValue={this.changeInput}
                                required={'required'} error={this.errors.duration}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label className={'radio-label'}>Sua unidade SENAI foi organizadora?</label>
                            <div style={{ display: 'flex' }}>
                                <FormField type={'radio'} radioLabel={'Sim'} checked={this.evento.isOrganizado === true} inputValue={true} changeValue={this.changeInput} name={'isOrganizado'} />
                                <FormField type={'radio'} radioLabel={'Não'} checked={this.evento.isOrganizado === false} inputValue={false} changeValue={this.changeInput} name={'isOrganizado'} />
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <FormField type={'textarea'} label={'Descrição'} id={'descricao'} inputValue={this.evento.descricao} changeValue={this.changeInput}/>
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <AutoCompleteMany
                        suggestions={this.state.colaboradorList}
                        itemlabel={'nome'}
                        getInput={this.changeInput}
                        itemKey={'id'}
                        label={'Participante(s)'}
                        chipLabel={'Nomes'}
                        id={'nome'}
                        initialOjectSelectedList={this.evento.eventoColaboradorList}
                        initialOjectSelectedListClass={'colaborador'}
                    />
                </Grid>
            </Grid>,
            <Grid container className={'step review-step'}>
                <Grid item xs={12} sm={10} md={6} xl={4} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>1</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Dados do Evento</label>
                        <Grid container>
                            <Grid item xs={6}>Nome</Grid>
                            <Grid item xs={6}>{this.evento.nome}</Grid>
                            <Grid item xs={6}>Tipo do Evento</Grid>
                            <Grid item xs={6}>{this.evento.tipoEvento}</Grid>
                            <Grid item xs={6}>Classificação do Evento</Grid>
                            <Grid item xs={6}>{this.evento.classificacao}</Grid>
                            <Grid item xs={6}>Sua unidade SENAI foi organizadora?</Grid>
                            <Grid item xs={6}>{this.evento.isOrganizado ? 'Sim' : 'Não'}</Grid>
                            <Grid item xs={6}>Cidade</Grid>
                            <Grid item xs={6}>{this.evento.cidade}</Grid>
                            <Grid item xs={6}>País</Grid>
                            <Grid item xs={6}>{this.evento.pais}</Grid>
                            <Grid item xs={6}>Ano</Grid>
                            <Grid item xs={6}>{this.evento.ano}</Grid>
                            <Grid item xs={6}>Início do Evento</Grid>
                            <Grid item xs={6}>{new Date(this.evento.dataInicio).toLocaleDateString()}</Grid>
                            <Grid item xs={6}>Fim do Evento</Grid>
                            <Grid item xs={6}>{new Date(this.evento.dataConclusao).toLocaleDateString()}</Grid>
                            <Grid item xs={6}>Duração</Grid>
                            <Grid item xs={6}>{this.evento.duration}</Grid>
                            <Grid item xs={6}>Descrição</Grid>
                            <Grid item xs={6}>{this.evento.descricao}</Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} md={6} xl={4} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>2</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Participantes</label>
                        <Grid container>
                            <Grid item xs={12}>
                                {this.evento.eventoColaboradorList.map(
                                    (eventoColaborador, index) => (
                                        <p key={index}>{eventoColaborador.colaborador.nome}</p>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid >]
    }

    render() {
        return (
            <Page icon={'doc.svg'} label={'Novo Evento'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'novo-evento'}>
                    <Stepper
                        titles={this.titles}
                        steps={this.getSteps()}
                        validation={this.validateEvento}
                        sendForm={this.saveEvento}
                        history={this.props.history} />
                </Grid>
            </Page>
        );
    }
}