import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBarHorizontal from '../../../components/graphBar/graphBarHorizontal';

export default class GraficoAlunosTurmasDR extends Component {

    tooltip = {
        formatter: function() {
            console.log(this.series);
            let tooltip = `<p style="font: bold 13px Raleway; color: #707070">${this.series.name.serieName}</p><br>` +
            `<p style="font: 13px Raleway; font-weight: 500; color: #707070;">${this.point.category}: ${this.point.y}</p><br>` +
            `<p style="font: 13px Raleway; color: #707070">Total: ${this.point.total}</p>`;
            return tooltip;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };
    
    legend = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function() {
            return '<div style="cursor:auto;display:flex;align-items:center;"><div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '"></div><label style="font-weight:500;font-size:11px">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
    };

    plotOptions = {
        series: {
            states: {
                inactive: {
                    opacity: 1
                }
            },
            pointPadding: 0.25,
            pointWidth: 13
        },
        bar: {
            stacking: 'normal',
            borderColor: null,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            switch: [
                {
                    label: 'Turmas', 
                    title: 'Turmas Brasil Mais por unidade operacional', 
                    tooltip: this.tooltip, 
                    categories: [],
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoTurmas,
                    tickInterval: 20,
                    legend: this.legend,
                    plotOptions: this.plotOptions
                },
                {
                    label: 'Alunos', 
                    title: 'Alunos mentorados por unidade operacional', 
                    tooltip: this.tooltip, 
                    categories: [],
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoAlunos,
                    tickInterval: 100,
                    legend: this.legend,
                    plotOptions: this.plotOptions
                }
            ]
        };
    };

    fillGraficoAlunos = async (dataInicio, dataTermino, busca, limite) => {
        let series = [];
        let categories = [];
        let height = 0;
        await API.get(
            `rest/sistema/DashboardBrasilMais/alunosMentoradosTurmasDR?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isTurmas=${false}&busca=${busca}&limite=${limite}`
        ).then(async res => {
            let dataAguardando = [];
            let dataEmMentoria = [];
            let dataMentorados = [];
            if (res.data != null && res.data.unidades != null) {
                categories = await res.data.unidades.map(i => i[0]);
                await res.data.unidades.forEach(und => {
                    dataAguardando.push(und[1]);
                    dataEmMentoria.push(und[2]);
                    dataMentorados.push(und[3]);
                });
                series = [{
                        name: { legendName: 'Alunos aguardando', serieName: 'Aguardando'},
                        data: dataAguardando,
                        color: '#B7EE8E'
                    }, {
                        name: { legendName: 'Alunos em mentoria', serieName: 'Em Mentoria'},
                        data: dataEmMentoria,
                        color: '#7DAE59'
                    }, {
                        name: { legendName: 'Alunos Mentorados', serieName: 'Mentorados'},
                        data: dataMentorados,
                        color: '#00B1E8'
                    }
                ];
                height = series.length === 1 ? 132 : series.length > 1 && series[0].data.length > 1 ? (54 * series[0].data.length) + 54 : 132;
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, height];
    };

    fillGraficoTurmas = async (dataInicio, dataTermino, busca, limite) => {
        let series = [];
        let categories = [];
        let height = 0;
        await API.get(
            `rest/sistema/DashboardBrasilMais/alunosMentoradosTurmasDR?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isTurmas=${true}&busca=${busca}&limite=${limite}`
        ).then(async res => {
            let dataEspera = [];
            let dataExecucao = [];
            let dataConcluidas = [];
            if (res.data != null && res.data.unidades != null) {
                categories = await res.data.unidades.map(i => i[0]);
                await res.data.unidades.forEach(und => {
                    dataEspera.push(und[1]);
                    dataExecucao.push(und[2]);
                    dataConcluidas.push(und[3]);
                });
                series = [{
                        name: { legendName: 'Turmas em espera', serieName: 'Espera'},
                        data: dataEspera,
                        color: '#B7EE8E'
                    }, {
                        name: { legendName: 'Turmas em execução', serieName: 'Execução'},
                        data: dataExecucao,
                        color: '#7DAE59'
                    }, {
                        name: { legendName: 'Turmas concluídas', serieName: 'Concluídas'},
                        data: dataConcluidas,
                        color: '#00B1E8'
                    }
                ];
                height = series.length === 1 ? 132 : series.length > 1 && series[0].data.length > 1 ? (54 * series[0].data.length) + 54 : 132;
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, height];
    };
   
    render() {
        return (
            <Grid container justify={'center'}>
                <GraphBarHorizontal switch={this.state.switch} disabledSwitch={false} hasFilter={true} init={'left'} />
            </Grid>
        );
    };

}