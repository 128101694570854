import React, { Component } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import { withStyles} from "@material-ui/core/styles";
import API from '../../../comm/API';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import CarouselAvaliacao from '../../../components/carousel/carouselAvaliacao';

export default class GraphBarAvaliacaoSatisfacao extends Component {

    constructor(props) {
        super(props);
        this.state = {
            L:{
                1: [63,2,30,1,4],
                2: [20,7,40,2,31],
                3: [87,2,3,1,7],
                4: [43,3,51,2,1],
                5: [4,2,3,26,65],
                6: [45,4,21,18,12]
            },
            D:{
                1: [15,50,20,7,8],
                2: [3,43,51,1,2],
                3: [9,12,52,24,3],
                4: [14,19,64,1,2],
                5: [6,2,56,35,1],
                6: [19,18,25,31,7]
            },
            LD:{
                1: [1,38,39,19,3],
                2: [16,4,75,3,2],
                3: [11,23,22,29,15],
                4: [20,17,19,28,16],
                5: [2,8,25,4,61],
                6: [1,70,15,4,10]
            },
            
        }

        this.carregarStatus();
    }

    pushNotas = (object) =>{
        let array = [];
        array.push(object.nada_satisfeito);
        array.push(object.pouco_satisfeito);
        array.push(object.satisfeito);
        array.push(object.muito_satisfeito);
        array.push(object.totalmente_satisfeito);
        return array;

    }

    getStatusAtendimento = async () => {
		
        await API.get('rest/sistema/DashboardBrasilMais/avaliacaoAtendimento').then( async res => {
			let dados = res.data;
            let questionsL = {
                1: this.pushNotas(dados.lean.consultores),
                2: this.pushNotas(dados.lean.capacitacao),
                3: this.pushNotas(dados.lean.metodologia),
                4: this.pushNotas(dados.lean.tempo_execucao),
                5: this.pushNotas(dados.lean.custo),
                6: this.pushNotas(dados.lean.resultados)};
            let questionsD = {
                1: this.pushNotas(dados.digital.consultores),
                2: this.pushNotas(dados.digital.capacitacao),
                3: this.pushNotas(dados.digital.metodologia),
                4: this.pushNotas(dados.digital.tempo_execucao),
                5: this.pushNotas(dados.digital.custo),
                6: this.pushNotas(dados.digital.resultados)};
            let questionsLD = {
                1: this.pushNotas(dados.ambos.consultores),
                2: this.pushNotas(dados.ambos.capacitacao),
                3: this.pushNotas(dados.ambos.metodologia),
                4: this.pushNotas(dados.ambos.tempo_execucao),
                5: this.pushNotas(dados.ambos.custo),
                6: this.pushNotas(dados.ambos.resultados)};
			
            this.setState({L:questionsL ,D : questionsD,LD : questionsLD});
		}).catch(error => {
			console.log(error);
		});
	}

	carregarStatus = async () => {
		await this.getStatusAtendimento();
	}

    

    createGrafico  =  (fase,page) => {
        let series = [];
	    series = this.state[fase][page];
        let max = series.reduce((partialSum, a) => partialSum + a, 0);
        let options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Raleway'
                },
                marginTop: 0,
                spacingTop: 0,
                marginBottom: 0,
                spacingBottom: 0,
                height: 200,
                backgroundColor:"#F2F1F6"
            },
            title:{
                floating : true,
                text: ''
            },
            xAxis: {
                gridLineColor: '#B4B8C0',
                gridLineWidth: 0,
                tickmarkPlacement:'between',
                categories: ['Nada satisfeito', 'Pouco satisfeito', 'Satisfeito', 'Muito satisfeito','Totalmente satisfeito'],
                opposite: true,
                labels: {
                    style: {
                        width: '400px',
                        textOverflow: 'allow',
                    },
                    useHTML: true,
                    formatter: function () {
                        let val;
                        switch(this.value){
                            case 'Nada satisfeito':
                                val = 0;
                                break;
                            case 'Pouco satisfeito' :
                                val = 1;
                                break;
                            case 'Satisfeito' :
                                val = 2;
                                break;
                            case 'Muito satisfeito' :
                                val = 3;
                                break;
                            case 'Totalmente satisfeito':
                                val = 4;
                                break;
                        }
                        return '<div> <label style="font-weight:bold">'  + series[val] + '</label> <label style="text-transform: none; margin-left: 10px">' + this.value + '</label> </div>'
                    }
                },
                lineColor: '#707070',
                lineWidth: 1,
            },
            yAxis: {
                gridLineWidth: 0,
                lineColor: '#707070',
                min: 0,
                max: max,
                title: {
                    text: ''
                },
                labels: {
                    enabled: false
                }
            },
            plotOptions: {
                series:{
                    pointWidth: 30,
                },
                bar: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            legend:{
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>'+ this.x +'</b><br/>' + 
                            'valor: ' + this.y + '<br/> Porcentagem: ' + (this.y/max *100).toFixed(1) + '%';
                }
            },
            series: [{
                data: [max,max,max,max,max],
                name: 'backgroundSeries',
                colors: [
                    '#FFFFFF',
                    '#FFFFFF',
                    '#FFFFFF',
                    '#FFFFFF',
                    '#FFFFFF'
                ],
                grouping: false,
                animation: false,
                enableMouseTracking: false,
                showInLegend: false
              },{
                data: series,
                colors: [
                    '#D44E54',
                    '#E9B03D',
                    '#B2B4B7',
                    '#7DAE59',
                    '#077CDC'
                ]
            }],
            credits: {
                enabled: false
            },
            exporting: false
        };

        return options;
    };

    formatData = (date) => {
        date = date + 'T00:00:00';
        let dataList;
        if (typeof (date) !== 'string') {
            dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

    AvaliacaoCarousel() {
		let content = [];
		for(let i=0;i<6;i++){
			content.push(
				<div key={'carrossel-card-' + i}>
					{this.graficoAvaliacao(i + 1)}
				</div>
			)
		};
		return content;
	}

  
   
    graficoAvaliacao(page) {
        let title = 'teste status';
        let currGraph = this.createGrafico(this.props.fase,page);
        let series;
        series = this.state[this.props.fase][page];
        let max = Math.max(...series);
        let total = series.reduce((partialSum, a) => partialSum + a, 0);
        let index = series.indexOf(max);
        let categories =  ['Nada satisfeito', 'Pouco satisfeito', 'Satisfeito', 'Muito satisfeito','Totalmente satisfeito'];
        let perguntas = [
            'Qual o seu nível de satisfação com o atendimento realizado pelo(s) consultor(es)?',
            'Qual o seu nível de satisfação com a capacitação teórica?',
            'Qual o seu nível de satisfação com a aplicação da metodologia na empresa?',
            'Qual o seu nível de satisfação com o tempo de execução da metodologia?',
            'Qual o seu nível de satisfação com o custo do produto?',
            'Qual o seu nível de satisfação com os resultados alcançados?'
        ];
        let porcentagem =  (max/total * 100).toFixed(2);
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
        return (
            <Grid container spacing={0} >
                
                <Grid item xs={12} style={{marginTop:"20px"}}>
                    <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0} style={{maxHeight:"100%",height:"100%"}}>
                        <Grid item xs={3} className={'card'} style={{maxHeight:"100%",height:"100%"}}>
                            <DashboardCard color={'card-dark-gray'} text={
                                <div className='card-titles-div white-label'>
                                    <label >{perguntas[page-1]}</label>
                                </div>} />
                        </Grid>                
                        <Grid item xs={7}>
                            <div >
                                <HighchartsReact key={title}
                                    highcharts={Highcharts}
                                    options={currGraph} />
                            </div>
                        </Grid>
                        <Grid item xs={2} className={'card'} style={{maxHeight:"100%",height:"100%"}}>
                            <DashboardCard color={'card-dark-gray'}  id = {'invertido'} text={categories[index]} value={porcentagem + '%'}/>
                        </Grid>            
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className='page-label'>
                        <label >{page + '/6'}</label>
                    </div>
                </Grid>
            </Grid>
            
        );
    }

    render() {
        
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {this.props.fase == "L" && <CarouselAvaliacao content={this.AvaliacaoCarousel()} top={true}/>}
                    {this.props.fase == "D" && <CarouselAvaliacao content={this.AvaliacaoCarousel()} top={true}/>}
                    {this.props.fase == "LD" && <CarouselAvaliacao content={this.AvaliacaoCarousel()} top={true}/>}
                </Grid>
            </Grid>
            
        );
    }

}