import React, { Component } from 'react';
import Slider from 'react-slick';
import { Grid } from '@material-ui/core';

export default class Carousel extends Component {

    render() {
        let settings = {
            dots: false,
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            arrows: true,
            responsive: [
                {
                  breakpoint: 1100,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                  }
                },
                {
                  breakpoint: 1000,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                  }
                }
            ]
        };
        return (
            <Grid container justify={'center'} className={'carousel'}>
                <Grid item className={'font-title-carousel'}>{this.props.title}</Grid>
                <Grid item xs={12} className={'line'}>
                    <div className={'carousel-width'}>
                        <Slider {...settings}>
                            {this.props.content}
                        </Slider>
                    </div>
                </Grid>
            </Grid>
        );
    }
}