import React, { Component } from 'react';
import VisitasDefault from './visitasDefault';
import API from '../../comm/API';
import AtendimentosCard from './atendimentosCard';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtil';
import AtividadeDefault from './atividadeDefault';
import AtividadeLinhaDeProducao from './atividadeLinhaDeProducao';
import AtividadePlanoDeAcao from './atividadePlanoDeAcao';
import AtividadeProcessoProdutivo from './atividadeProcessoProdutivo';
import AtividadeDesperdicio from './atividadeDesperdicio';

export default class MentoriaPratica extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    STATUS_EMPROGRESSO = 2;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'visitas'
    };

    switchAnexos = null;
    atendimentoEtapaB = null;

    constructor(props) {
        super(props);

        const etapaName = `etapaPratica${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Prática C';
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);

        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoPratica: undefined,
            etapa,
            atendimentoList,
            showTopButtons: true,
            switchAnexos: [],
            clientName: '',
            disabled: false
        };
    };

    getDesperdicioList = async (desperdicio) => {
        let list = desperdicio.defeitoList;
        list = list.concat(desperdicio.esperaList); 
        list = list.concat(desperdicio.estoqueList); 
        list = list.concat(desperdicio.intelectualList); 
        list = list.concat(desperdicio.processamentoList); 
        list = list.concat(desperdicio.superProducaoList);
        list = list.concat(desperdicio.transporteList);
        list = list.concat(desperdicio.movimentacaoList);
        return await list;
    };

    getLinhaDeProducaoModel = () => {
        return {
            status: 1,
            ordemVisita: null,
            tipoSistemaProdutivoList: [],
            tipoArranjoFisicoList: [],
            anexoLinhaProducaoList: [],
            tipoProducao: 1,
            variedadeProducao: 'Baixa',
            coletaIndicadoresText: '',
            grauUtilizacaoTecnologica: 'Baixo',
            ifColetaIndicadores: false,
            organizacao5s: 'Baixa',
            condicaoDeTrabalho: 'Baixa',
            grauManutencao: 'Baixo',
            setupEquipamentos: 'Baixo',
            niveisAcumuloEstoque: 'Baixo',
            tipoProduto: 1,
            tipoArranjo: 0,
            tipoSistema: 0,
            currTipoList: '',
            currTipo: ''
        };
    };

    getProcessoProdutivoModel() { 
        return { anexoProcessoProdutivoList: [], operadoresPorTurno: 0, numeroDeTurnos: 0, horasPorTurno: 0, capacidadeProdutiva: 0, movimentacao: 0, 
            horasPorOperador: 0, producaoPorTurno: 0, retrabalhoPorTurno: 0, materiaPrima: null, retrabalho: null, insumos: null, descarte: null, superproducao: null, 
            produtividade: 0.0, qualidade: 0.0, status: 1, ordemVisita: null };
    };

    getPlanoDeAcaoModel() {
        return { planoList: [], status: 1, visitaOrdem: null };
    };

    getDesperdicioModel() {
        return { status: 1, ordemVisita: null, desperdicioList: [], defeitoList: [], esperaList: [], estoqueList: [], intelectualList: [], processamentoList: [], superProducaoList: [], transporteList: [], movimentacaoList: [] }; 
    };

    getModels = () => {
        return {
            linhaDeProducao: this.getLinhaDeProducaoModel(),
            processoProdutivo: this.getProcessoProdutivoModel(),
            planoDeAcao: this.getProcessoProdutivoModel()
        }
    }

    validateLinhaDeProducao(linhaDeProducao) {
        const coletaIndicadores = (!linhaDeProducao.coletaIndicadoresText && !linhaDeProducao.ifColetaIndicadores) || (linhaDeProducao.coletaIndicadoresText && linhaDeProducao.ifColetaIndicadores)
        return linhaDeProducao.ordemVisita && linhaDeProducao.anexoLinhaProducaoList.length > 0 && linhaDeProducao.anexoLinhaProducaoList.every(anx => { return anx.descricao }) && coletaIndicadores && 
            linhaDeProducao.tipoSistemaProdutivoList && linhaDeProducao.tipoSistemaProdutivoList.length > 0 && linhaDeProducao.tipoArranjoFisicoList && linhaDeProducao.tipoArranjoFisicoList.length > 0;
    };

    validatePlanoDeAcao(plano) {
        return plano && plano.planoList && plano.planoList.length > 0 && plano.planoList.every(aux => { return aux.nomePlano && aux.OQue && aux.porQue && aux.quem && aux.quando && aux.onde && aux.como && aux.quanto });
    };

    validateProcessoProdutivo(processo) { //alterar na etapa d para adicionar os campos específicos da etapa
        return processo && processo.anexoProcessoProdutivoList && processo.anexoProcessoProdutivoList.length > 0 && processo.operadoresPorTurno > 0 && processo.numeroDeTurnos > 0 && 
            processo.horasPorTurno > 0 && processo.capacidadeProdutiva > 0 && processo.horasPorOperador > 0 && processo.producaoPorTurno > 0 && processo.retrabalhoPorTurno > 0 && 
            processo.anexoProcessoProdutivoList.every(anx => { return anx.descricao });
    };

    validateDesperdicio(desperdicio) {
        return desperdicio && desperdicio.desperdicioList && desperdicio.desperdicioList.length > 0 && desperdicio.desperdicioList.every(anx => { return anx.descricao });
    };

    updateViewData = (atendimento) => { 
        this.viewData.visitas.data = atendimento.visitaList;
        const { linhaDeProducao, planoDeAcao, processoProdutivo } = atendimento;

        this.setState({disabled: this.state.turma.status === 3});

        this.viewData.atividades.linhaDeProducao.data = JSON.parse(JSON.stringify(linhaDeProducao || this.getLinhaDeProducaoModel()));
        this.viewData.atividades.linhaDeProducao.anexos.data = getByKeyOrEmpty(linhaDeProducao, 'anexoLinhaProducaoList');

        this.viewData.atividades.planoDeAcao.data = JSON.parse(JSON.stringify(planoDeAcao || this.getPlanoDeAcaoModel()));

        this.viewData.atividades.processoProdutivo.data = JSON.parse(JSON.stringify(processoProdutivo || this.getProcessoProdutivoModel()));
        this.viewData.atividades.processoProdutivo.anexos.data = getByKeyOrEmpty(processoProdutivo, 'anexoProcessoProdutivoList');
    
        this.viewData.atividades.desperdicio.data = JSON.parse(JSON.stringify(this.atendimentoEtapaB.desperdicio || this.getDesperdicioModel()));
    };

    validaAtendimentoPratica = async (atendimentoPratica) => {  
        let visitaList = atendimentoPratica.visitaList;
        let linhaDeProducao = atendimentoPratica.linhaDeProducao;
        let planoDeAcao = atendimentoPratica.planoDeAcao;
        let processoProdutivo = atendimentoPratica.processoProdutivo;
        if (!atendimentoPratica.cancelado) {
            return await visitaList && visitaList.length > 0 && visitaList.every(visita => { return this.validaVisita(visita) }) 
                && linhaDeProducao && linhaDeProducao.status === this.STATUS_FINALIZADO && planoDeAcao && planoDeAcao.status === this.STATUS_CONTINUO 
                && planoDeAcao.planoList.length > 0 && processoProdutivo && processoProdutivo.status ===  this.STATUS_FINALIZADO && !atendimentoPratica.cancelado;
        }
        return true;
    };
    
    validaVisita = (visita) => {
        return visita && visita.anexoVisitaList && visita.anexoVisitaList.length > 0 && visita.anexoVisitaList.some(anx => { return anx.descricao === 'lista de presença' })
             && visita.data && visita.turno != null && visita.horasApropriadas > 0  && visita.isVirtual != null;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoPratica = this.state.atendimentoPratica;
        let turma = this.props.turma;
        let validado = await this.validaAtendimentoPratica(atendimentoPratica);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoPratica.finalizado = true;
            let indexAtendimento = turma.etapaPraticaC.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
            turma.etapaPraticaC.atendimentoList[indexAtendimento] = atendimentoPratica;
            let etapa = turma.etapaPraticaC;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/Turma/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/Turma/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaPraticaC;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.setState({ turma, etapa, atendimentoList, atendimentoPratica: atd }, this.goBackAtendimentos());
                        this.props.closeLoading();
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    validaAtividade = async (viewData, atendimentoPratica, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoPratica[nomeAtividade] ? atendimentoPratica[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo && nomeAtividade === 'desperdicio') atividade[nomeAnexo] = await this.getDesperdicioList(atividade); 
        else if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoPratica[nomeAtividade] = atividade;
    };

    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoPratica = this.state.atendimentoPratica;
        let visitasData = viewData.visitas.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;
            
            if (currScreenAtividade === 'linhaDeProducao') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'linhaDeProducao', 'anexoLinhaProducaoList', this.validateLinhaDeProducao, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'processoProdutivo') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'processoProdutivo', 'anexoProcessoProdutivoList', this.validateProcessoProdutivo, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'planoDeAcao') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'planoDeAcao', '', this.validatePlanoDeAcao, this.STATUS_CONTINUO);
            else if (currScreenAtividade === 'desperdicios') 
            await this.validaAtividade(viewData, this.atendimentoEtapaB, currScreenAtividade, 'desperdicio', 'desperdicioList', this.validateDesperdicio, this.STATUS_CONTINUO);

            if (selectedVisita) {
                selectedVisita.anexoVisitaList = [];
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaVisita(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = visitasData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoPratica.visitaList[index] = selectedVisita;
                else atendimentoPratica.visitaList.push(selectedVisita);
            };
            atendimentoPratica.horasApropriadas = atendimentoPratica.visitaList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let atividades = viewData.atividades;
            let planoDeAcao = atividades.planoDeAcao;
            let linhaDeProducao = atividades.linhaDeProducao;
            let processoProdutivo = atividades.processoProdutivo;
            if (!planoDeAcao.data.ordemVisita) atendimentoPratica.planoDeAcao = null;
            if (!linhaDeProducao.data.ordemVisita) atendimentoPratica.linhaDeProducao = null;
            if (!processoProdutivo.data.ordemVisita) atendimentoPratica.processoProdutivo = null;
            atendimentoPratica.horasApropriadas = atendimentoPratica.visitaList.reduce((a, b) => + a + + b.horasApropriadas, 0);
            atendimentoPratica.visitaList = visitasData;
        }
        let indexAtendimento = turma.etapaPraticaC.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
        turma.etapaPraticaC.atendimentoList[indexAtendimento] = atendimentoPratica;
        let indexAtendimentoB = turma.etapaPraticaB.atendimentoList.findIndex(obj => { return obj.id === this.atendimentoEtapaB.id });
        turma.etapaPraticaB.atendimentoList[indexAtendimentoB] = this.atendimentoEtapaB;
        if(atendimentoPratica.horasApropriadas > 4){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else{
            return API.post('rest/gestao/Turma/' + turma.id, turma).then(() => {
                return API.get('rest/gestao/Turma/' + turma.id).then(async res => {
                    turma = res.data;
                    etapa = turma.etapaPraticaC;
                    let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                    let atd = etapa.atendimentoList[index];
                    this.atendimentoEtapaB = this.getAtendimentoEtapaB(atd);
                    await this.updateViewData(atd);
                    this.setState({ turma, etapa, atendimentoPratica: atd, switchAnexos: undefined, showTopButtons: true });
                    this.props.showToast('Etapa editada com sucesso!', 'success');
                }).catch(error => {
                    this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                    throw new Error(error);
                });
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }
        
    };

    showTopButtons = (value) => {
        this.setState({ showTopButtons: value });
    };

    switchAnexos = (anexosList) => {
        this.setState({ switchAnexos: anexosList });
    };

    getAtendimentoEtapaB = (atendimentoPratica) => {
        return this.props.turma.etapaPraticaB.atendimentoList.find(atd => { return atd.idAtendimento === atendimentoPratica.idAtendimento });
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = this.state.showTopButtons;
        let showAnexos = true;
        let render = null;
        let switchAnexos = this.state.switchAnexos && this.state.switchAnexos.length > 0 ? this.state.switchAnexos : selectedAtividade.anexos.data;
        switch (selectedAtividade.screenAtividade) {
            case 'linhaDeProducao':
                render = <AtividadeLinhaDeProducao atividade={selectedAtividade} atendimento={this.state.atendimentoPratica} etapa={this.state.etapa} 
                            turma={this.props.turma} showTopButtons={this.showTopButtons} switchAnexos={this.switchAnexos} disabled={this.state.disabled}/>;
                break;
            case 'planoDeAcao':
                render = <AtividadePlanoDeAcao atividade={selectedAtividade.data} atendimento={this.state.atendimentoPratica} etapa={this.state.etapa} 
                            turma={this.props.turma} disabled={this.state.disabled}/>;
                showTopButtons = false;
                showAnexos = false;
                break;
            case 'processoProdutivo':
                render = <AtividadeProcessoProdutivo atividade={selectedAtividade} atendimento={this.state.atendimentoPratica} etapa={this.state.etapa} 
                            turma={this.props.turma} showTopButtons={this.showTopButtons} switchAnexos={this.switchAnexos} disabled={this.state.disabled}/>;
                break;
            case 'desperdicios':
                render = <AtividadeDesperdicio atividade={selectedAtividade.data} atendimento={this.state.atendimentoPratica} etapa={this.state.etapa} turma={this.props.turma}
                            showLoading={this.props.showLoading} closeLoading={this.props.closeLoading} showToast={this.props.showToast} disabled={this.state.disabled}/>;
                showTopButtons = false;
                showAnexos = false;
                break;
            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefault
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ switchAnexos }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                showTopButtons={ showTopButtons  && !this.state.disabled}
                showAnexos={ showAnexos }
                renderChildren={ render } 
                clientName={ this.state.clientName }
                viewData={ this.state.viewData }
                disabled={this.state.disabled} />
        );
    };

    renderVisita = (atendimento) => {
        this.atendimentoEtapaB = this.getAtendimentoEtapaB(atendimento);
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoPratica: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoPratica: undefined, switchAnexos: undefined, showTopButtons: true });
    };

    render() {
        return (<>
            {this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCard
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Mentoria Prática' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita }
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <VisitasDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    etapa = {this.state.etapa}
                    models={ this.getModels }
                    atendimento={ this.state.atendimentoPratica }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    saveEtapa={ this.saveEtapa }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    switchAnexos={ this.switchAnexos }
                    dontShowFinalizarMentoria={ this.state.atendimentoPratica.finalizado }
                    disabled={this.state.disabled}
                />
            }
        </>)
    };
};