import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import CardVisita from './cardVisita'
import Visita from './visita';
import EmptyList from '../../components/emptyList/emptyList';
import GenericButton from '../../components/genericButton/genericButton';
import PageBrasilMais from './pageBrasilMais';
import CardAtividade from './cardAtividade';
import InfoVisita from './infoVisita';
import FormField from '../../components/formField/formField';
import SimpleModal from './../../components/modal/modal';

import './visitasDefault.scss';

export default class Mentoria extends Component {
    
    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    selectedVisita = {};
    statusList = [
        { id: 1, label: 'Pendente', class: 'pendente' },
        { id: 2, label: 'Em progresso', class: 'emprogresso' },
        { id: 3, label: 'Feito', class: 'feito' },
        { id: 4, label: 'Contínua', class: 'continuo' },
        { id: 5, label: 'Complementar', class: 'complementar'}
    ];
    selectedAtividade = {};
    openModal = false;
    currVisita = null;

    constructor(props) {
        super(props);

        this.state = {
            renderBody: 'visitas', editVisitaOrdem: null,
            viewData: JSON.parse(JSON.stringify(this.props.viewData)),
            otherFerramentaLabel: '',
            errorInput: ''
        }
        this.viewDataClone = JSON.parse(JSON.stringify(this.props.viewData));
        this.bindFunctions();
    };

    countAtividades = () => {
        let totalAtividades = this.state.viewData.totalAtividades;
        if (this.state.viewData.ferramentas) {
            totalAtividades += this.state.viewData.ferramentas.outrasFerramentasList.length;
        }
        return totalAtividades;
    }

    getOutrasFerramentasModelView = () => {
        return {
            data: {},
            icon: 'outrasFerramentas.svg',
            label: '',
            screenAtividade: '',
            anexos: {
                data: [],
                title: '',
                defaultIcon: 'outras-ferramentas',
            }
        }
    }

    bindFunctions() {
        this.setRenderBody = this.setRenderBody.bind(this);
        this.renderFooterButtons = this.renderFooterButtons.bind(this);
        this.renderFooterButtonsAtividades = this.renderFooterButtonsAtividades.bind(this);
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ viewData: nextProps.viewData })
        this.viewDataClone = JSON.parse(JSON.stringify(nextProps.viewData));
    };

    setRenderBody(value, clear) {
        if (clear) {
            const viewDataClone = JSON.parse(JSON.stringify(this.viewDataClone));
            this.setState({ renderBody: value, viewData: viewDataClone });
        } else {
            this.setState({ renderBody: value });
        }
    };

    checkDate = () =>{
        let d = new Date();
        let dataI = new Date(this.props.etapa.dataInicio);
        let dataF = new Date(this.props.etapa.dataTermino);
        let proxMes = dataF.getMonth() == 11 ? 0 : dataF.getMonth() + 1;
        let proxAno = dataF.getMonth() == 11 ? dataF.getFullYear() + 1 : dataF.getFullYear();
        let maxDate = new Date(proxAno,proxMes,8)
        return(d.getTime() <= maxDate.getTime())
    }

    getHourLimit = (etapa) =>{
        switch (etapa) {
            case 2:
            case 4:
                return 8;
            case 3:
            case 6:
            case 7:
                return 12;
            case 5:
            case 8:
            case 9:
                return 4;
        }
    }

    renderTopButtons(setRenderBody) {
        return (<>
            <Grid item md={6} >
                {!this.state.viewData.etapa.etapaFinalizada && <Grid container style={{ justifyContent: 'flex-end' }}>
                    <label className={'labelButtonAdd'}>Adicionar Encontro</label>
                    <GenericButton color={'lightBlue'} subClass={'icon-button'}
                        icon={process.env.PUBLIC_URL + 'plus.svg'}
                        click={() => {
                                    if(!this.state.viewData.etapa.etapaFinalizada || this.checkDate()){
                                        if(this.props.atendimento ? this.props.atendimento.horasApropriadas < this.getHourLimit(this.props.etapa.etapa): this.props.etapa.horasApropriadas < this.getHourLimit(this.props.etapa.etapa)){
                                            let ordem = 0;
                                            let visitasData = this.state.viewData.visitas.data;
                                            if (visitasData) {
                                                ordem = visitasData.length === 0 ? 1 : visitasData[visitasData.length - 1].ordem + 1;
                                            } 
                                            this.selectedVisita = { data: new Date(), ordem: ordem, isVirtual : false, turno: 0, horasApropriadas: 0, imagens: [], listaPresenca: [], outrosDocumentos: [] };
                                            this.state.viewData.visitas.data.push(this.selectedVisita);
                                            setRenderBody('atividades');
                                        }
                                        else{
                                            this.props.showToast('Não é permitido criar mais encontros. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
                                        }
                                    }
                                    else{
                                        this.props.showToast('Não é permitido criar novos encontros. O prazo para inclusão de novos encontros em etapas concluídas foi ultrapassado.', 'error');
                                    }
                                }} />
                </Grid>}
            </Grid>
        </>)
    };

    save = async (goTo, viewData, rmVisita) => {
        this.props.showLoading();
        this.state.viewData.selected.visita = this.selectedVisita;
        this.state.viewData.selected.atividade = this.selectedAtividade;
        
        try {
            await this.props.saveEtapa(viewData ? viewData : this.state.viewData, rmVisita);
            this.selectedAtividade = null;
            this.setRenderBody(goTo, true);
        } catch(error) {
            console.log(error);
        } finally {
            this.props.closeLoading();
        }
    };
    
    countAtividadeNumber = (element, atividades) => {
        return Object.keys(atividades).reduce((prev, key) => {
            const atividadeAtual = atividades[key];
            if (Array.isArray(atividadeAtual)) return prev + this.countAtividadeNumber(element, atividadeAtual);
            const { data } = atividadeAtual;
            let ordem = this.props.fase === 'C' && key === 'mfv' ? 1 : data.ordemVisita;
            const value = this.itsFinishedStatus(data) && ordem <= element.ordem && !atividadeAtual.dontCount ? 1 : 0;
            return prev + value;
        }, 0);
    };

    countAtividadeFerramenta = (element) => {
        const { atividades } = this.state.viewData;
        const { ferramentas } = this.state.viewData;
        let count = this.countAtividadeNumber(element, atividades);
        if (ferramentas) count += this.countAtividadeNumber(element, ferramentas);
        return count;
    }
    
    itsFinishedStatus = (entity) => {
        return entity && (entity.status === this.STATUS_FINALIZADO || entity.status === this.STATUS_CONTINUO);  
    };

    cleanOtherFerramentaLabel = () => {
        this.setState({otherFerramentaLabel: '', errorInput: ''});
    }

    renderFooterButtons() {
        const goBack = () => {
            this.props.goBackAtendimentos();
            this.cleanOtherFerramentaLabel();
        };
        return (
            <Grid item xs={12} className={'bottom-buttons'}>
                { !this.props.dontShowFinalizarMentoria &&
                    <GenericButton color={'darkGreen'} subClass={'basic-button'} label={'Finalizar Mentoria'} click={() => this.props.handleFinishMentoria(this.state.viewData) } />
                }
                &emsp;
                { !this.props.goBackAtendimentos &&
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Retornar à Trilha'} click={this.props.goBack} />
                }
                &emsp;
                { this.props.goBackAtendimentos &&
                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Voltar'} click={ goBack } />
                }
            </Grid>
        );
    };

    renderFooterButtonsAtividades(type, setRenderBody) {
        const renderBody = () => {
            setRenderBody('visitas', true);
            this.cleanOtherFerramentaLabel();
        };
        if (type === 'info') 
            return (
                <Grid item xs={12} className={'bottom-buttons'}>
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Voltar'} click={ renderBody } />
                    &emsp;
                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Salvar'} disabled={this.props.disabled} click={(e) => { this.save('visitas')}} />                    
                </Grid> 
            );
        else 
            return (
                <Grid item xs={12} className={'bottom-buttons'}>
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Retornar à Trilha'} click={this.props.goBack} />
                    &emsp;
                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Voltar'} click={ renderBody } />
                </Grid> 
            );
    };

    removerVisitaCard = async (id) => {
        let viewData = this.state.viewData;
        let atividades = viewData.atividades;
        let visitas = viewData.visitas;
        let index = visitas.data.findIndex(obj => obj.id === id);
        if (index > -1) {
            let ordem = visitas.data[index].ordem;
            let atividadeKeys = Object.keys(atividades);
            atividadeKeys.forEach(key => {
                if (atividades[key].data.ordemVisita === ordem) {
                    if (key === 'desperdicio' && this.props.fase === 'C') return;
                    atividades[key].data = this.props.models ? this.props.models()[key] : {};
                    atividades[key].anexos.data = [];
                }
            });
            if (viewData.ferramentas) {
                let ferramentas = viewData.ferramentas;
                let ferramentasKeys = Object.keys(ferramentas);
                ferramentasKeys.forEach(key => {
                    if(key === 'outrasFerramentasList') {
                        ferramentas[key].forEach(tool => {
                            if (tool.data.ordemVisita === ordem) {
                                tool.data = {};
                                tool.anexos.data = [];
                            }
                        })
                    }
                    else {
                        ferramentas[key].data = {};
                        ferramentas[key].anexos.data = [];
                    }
                });
                viewData.ferramentas = ferramentas;
            }
            visitas.data.splice(index, 1);
            viewData.visitas = visitas;
            viewData.atividades = atividades;
        }
        await this.save('visitas', viewData, true);
        this.setRenderBody('visitas', true);
        this.closeModalVisita();
    }

    openModalVisita = (visita) => {
        this.openModal = true;
        this.currVisita = visita;
        this.setState(this.state);
    }

    closeModalVisita = () => {
        this.openModal = false;
        this.currVisita = null;
        this.setState(this.state);
    }

    renderVisitasCards() {
        const goback = () => {
            this.props.goBackAtendimentos();
            this.cleanOtherFerramentaLabel();
        };
        return (this.state.viewData.visitas.data.length === 0 ?
            (<>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <EmptyList icon={'nuvem'} type={'noItens'} msg={' '} subMsg={'Você ainda não adicionou visitas.'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={'bottom-buttons'}>
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Retornar à Trilha'} click={this.props.goBack} />
                    &emsp;
                    { this.props.goBackAtendimentos &&
                        <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Voltar'} click={ goback } />
                    }
                </Grid>
            </>)
            :
            (<>
                <Grid item xs={12} style={{ paddingLeft: '4%', marginTop: '10px' }}>
                    <Grid container spacing={2}>
                        {this.state.viewData.visitas.data.map((element, index) => {
                            return <CardVisita 
                                        key={index}
                                        visita={element}
                                        openModalVisita={this.openModalVisita}
                                        removerVisitaCard={(id) => { this.removerVisitaCard(id) }}
                                        etapaTurma={this.state.viewData.visitas.title}
                                        etapaFinalizada={this.state.viewData.etapa.etapaFinalizada}
                                        atendimento={this.props.atendimento}
                                        faseTurma={this.props.fase}
                                        nAtividades={ this.countAtividadeFerramenta(element) }
                                        totalAtividades={ this.countAtividades() }
                                        onClick={() => { this.selectedVisita = element; this.setState({ renderBody: 'atividades' }); }}>
                                    </CardVisita>
                        })}
                    </Grid>
                    {this.currVisita && <SimpleModal
                        isOpen={this.openModal}
                        handleClose={this.closeModalVisita}
                        type={'warning'}
                        width={'500px'}>
                        <div className={'modal'}>
                            <Grid container className={'modal-remove-visita'}>
                                <Grid item xs={12}> 
                                    <p>
                                        Você confirma a remoção do
                                        <label> Encontro {this.currVisita.ordem > 10 ? this.currVisita.ordem : '0' + this.currVisita.ordem} </label> –
                                        { !(this.state.viewData.etapa.nome.includes('Mentoria Teórica')) && (<> <label> {this.props.clientName} </label> –</>) }
                                        <label> {this.state.viewData.etapa.nome} </label> –
                                        <label> {this.props.turma.nome} </label>?
                                    </p> 
                                </Grid>
                                <br/>
                                <Grid item xs={12} className={'rodape'}>
                                    <GenericButton
                                        color={'darkGrey-outline'}
                                        label={'Cancelar'}
                                        subClass={'basic-button'}
                                        click={this.closeModalVisita} />
                                    <GenericButton color={'darkBlue'}
                                        label={'Confirmar'}
                                        subClass={'basic-button'}
                                        click={() => { this.removerVisitaCard(this.currVisita.id) }} />
                                </Grid>
                            </Grid>
                        </div>
                    </SimpleModal>}
                </Grid>
                {this.renderFooterButtons()}
            </>)
        )
    };

    renderVisita(setRenderBody) {
        return ( <Visita renderAtividades={ () => this.renderAtividadesEferramentas(setRenderBody) } renderInfo={ () => this.renderInfo(setRenderBody) } /> );
    };

    capitalizeNameTool = (string) => {
        let splitStr = string.split(' ');
        return splitStr.map((str) => { 
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }).join(' ');
    };

    changeInput = (e) => {
        let otherFerramentaLabel = this.state.otherFerramentaLabel;
        if (e && e.target && e.target.value) {
            otherFerramentaLabel = this.capitalizeNameTool(e.target.value);
            this.setState({ otherFerramentaLabel: otherFerramentaLabel });
        }
    };

    addFerramentas = () => {
        let viewData = this.state.viewData;
        let otherFerramentaLabel = this.state.otherFerramentaLabel;
        let otherFerramentaModel = this.getOutrasFerramentasModelView();
        let errorInput = this.state.errorInput;
        if (otherFerramentaLabel && otherFerramentaLabel.length > 0) {
            let index = viewData.ferramentas.outrasFerramentasList.findIndex(tool => {
                return tool.label.toUpperCase() === otherFerramentaLabel.toUpperCase();
            });
            if (index < 0) {
                otherFerramentaModel.label = otherFerramentaLabel;
                otherFerramentaModel.anexos.title = otherFerramentaLabel;
                otherFerramentaModel.screenAtividade = otherFerramentaLabel;
                viewData.ferramentas.outrasFerramentasList.push(otherFerramentaModel);
                errorInput = '';
                otherFerramentaLabel = '';
            } else errorInput = 'Já existe uma ferramenta com esse nome.'
        } else errorInput = 'Informe um nome para a nova ferramenta.'
        this.setState({ viewData: viewData, otherFerramentaLabel: otherFerramentaLabel, errorInput: errorInput });
    }

    renderAtividadesEferramentas(setRenderBody) {
        return (
            <>
                { this.renderAtividades(setRenderBody) }
                { this.renderFerramentas() }
                { this.state.viewData.ferramentas && <Grid container direction={'row'} style={{ marginTop: '2%' }}>
                    <Grid item xs={12} md={4} xl={3} >
                        <div className={'add-ferramentas'}>
                            <FormField type={'text'} label={'Inclua mais ferramentas'} id={'label'} inputValue={this.state.otherFerramentaLabel} 
                            pressEnter={this.addFerramentas} placeholder={''} changeValue={(e) => { this.changeInput(e) }} error={this.state.errorInput} disabled={this.props.disabled}/>
                            <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addFerramentas} icon={'plus.svg'} disabled={this.props.disabled}/>
                        </div>
                    </Grid>
                  </Grid> }
                { this.renderFooterButtonsAtividades('atividades', setRenderBody) }
            </>
        )
    }

    renderCardAtividade(index, atividade, statusIndex) {
        let status = this.statusList.find(aux => { return aux.id === atividade.data.status });
        return (
            <CardAtividade
                key={index}
                icon={ atividade.icon } 
                label={ atividade.label }
                disabled={atividade.disabled}
                status={ status ? status : this.statusList[statusIndex] }
                onClick={() => { this.selectedAtividade = atividade; this.setRenderBody( 'atividade' ); }} 
            />
        );
    }

    renderAtividades() {
        const { atividades } = this.state.viewData;
        const cardsAtividade = Object.keys(atividades).map((key, index) => {
            const atividade = atividades[key];
            return this.renderCardAtividade(index, atividade, 0)
        });
        return (<>
            { this.state.viewData.ferramentas && <Grid item className={'label-ferramentas'} style={{ marginTop: '55px' }}> <label> Estado Presente </label> </Grid> }
            <Grid container style={{ marginTop: '10px' }}>
                { cardsAtividade }
            </Grid>
        </>)
    };

    renderFerramentas() {
        const { ferramentas } = this.state.viewData;
        if (ferramentas) {
            const cardsAtividade = Object.keys(ferramentas).map((key, index) => {
                const ferramenta = ferramentas[key];
                if (Array.isArray(ferramenta)) {
                    return Object.keys(ferramenta).map((key, index) => {
                        const innerTool = ferramenta[key];
                        return this.renderCardAtividade(index, innerTool, 4);
                    });
                }
                else return this.renderCardAtividade(index, ferramenta, 4);
            });
            return (<>
                <Grid item className={'label-ferramentas'} style={{ marginTop: '55px', marginBottom: '12px' }}> <label> Ferramentas da intervenção </label> </Grid>
                <Grid container> { cardsAtividade } </Grid>
            </>)
        }
    };

    renderFooterButtonsDefault = () => {
        const renderBody = () => {
            this.setRenderBody('atividades', true);
            this.cleanOtherFerramentaLabel();
        };
        return (
            <Grid item xs={12} className={'bottom-buttons'}>
                <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Voltar'} click={ renderBody } />
                &emsp;
                <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Salvar'} disabled={this.props.disabled} click={() => this.save('visitas') } />
            </Grid>
        );
    };

    renderInfo(setRenderBody) {
        this.selectedAtividade = null;
        return (<>
            <InfoVisita
                idturma={this.props.turma.id}
                turma={this.props.turma}
                etapaTurma={this.state.viewData.visitas.title}
                faseTurma={this.props.fase}
                visita={this.selectedVisita}
                closeLoading={this.props.closeLoading}
                showLoading={this.props.showLoading}
                disabled={this.props.disabled}
                showToast={this.props.showToast}>
            </InfoVisita>
            {this.renderFooterButtonsAtividades('info', setRenderBody)}
        </>)
    };

    sumHorasRealizadasEtapa() {
        return this.state.viewData.visitas.data.reduce((a, b) => + a + + b.horasApropriadas, 0);
    };
    
    reloadAtividade = () => {
        this.setRenderBody('atividade');
    };

    render() {
        switch (this.state.renderBody) {
            case 'visitas':
                return (
                    <PageBrasilMais
                        name={this.props.turma.nome}
                        fase={this.props.fase}
                        title={this.state.viewData.visitas.title}
                        subTitle={ this.props.clientName ? 
                            <label style={{ display: 'flex', fontSize: '15px', fontWeight: '600' }}>
                                <img style={{ marginRight: '10px' }} src={process.env.PUBLIC_URL + '/assets/icons/showcase-cinza.svg'} alt={'icon'} />
                                <label style={{ marginTop: '4px' }}>{this.props.clientName}</label>
                                <label style={{ marginTop: '4px', marginLeft: '40px', fontWeight: '400' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.state.viewData.horas}</label>
                            </label> : <label>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.props.viewData.horas}</label> }
                        renderTopButtons={(e) => { return this.renderTopButtons(this.setRenderBody) }}>
                        {this.renderVisitasCards()}
                    </PageBrasilMais>
                );
            case 'atividades':
                return (
                    <PageBrasilMais
                        name={this.props.turma.nome}
                        fase={this.props.fase}
                        title={<><label className={'label-bold-head'}>Encontro {this.selectedVisita.ordem > 10 ? this.selectedVisita.ordem: '0' + this.selectedVisita.ordem}</label><label> | {this.state.viewData.visitas.title}</label></>}
                        subTitle={ this.props.clientName ? 
                            <label style={{ display: 'flex', fontSize: '15px', fontWeight: '600' }}>
                                <img style={{ marginRight: '10px' }} src={process.env.PUBLIC_URL + '/assets/icons/showcase-cinza.svg'} alt={'icon'} />
                                <label style={{ marginTop: '4px' }}>{this.props.clientName}</label>
                                <label style={{ marginTop: '4px', marginLeft: '40px', fontWeight: '400' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.state.viewData.horas}</label>
                            </label> : <label style={{ display: 'flex', marginBottom: '4px' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.props.viewData.horas}</label> }>
                        {this.renderVisita(this.setRenderBody)}
                    </PageBrasilMais>
                )
            case 'atividade':
                return (
                    <Grid container>
                        {this.props.renderAtividade(this.selectedAtividade, this.reloadAtividade)}
                        {this.renderFooterButtonsDefault()}
                    </Grid>
                )
            default:
                return;
        }
    };
};