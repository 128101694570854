import React, { Component } from 'react';
import CurrencyInput from './currencyInput';
import { InputCurrencyMask } from './inputCurrencyMask';
import './formField.scss';

export default class FormField extends Component {

    get subClass() {
        return (this.props.subClass !== undefined ? this.props.subClass : '')
    }

    get subClassLabel() {
        return (this.props.subClassLabel !== undefined ? this.props.subClassLabel : '')
    }

    get erroFlag() {
        return (this.props.error === undefined || this.props.error === '' ? 'none' : 'inline')
    }

    get infoFlag() {
        return (this.props.info === undefined || this.props.info === '' ? 'none' : 'inline')
    }

    get warningFlag() {
        return (this.props.warning === undefined || this.props.warning === '' ? 'none' : 'inline')
    }

    get hasButton() {
        return (this.props.button === undefined || this.props.button === '' ? 'none' : 'flex')
    }

    get isRadioButton() {
        return (this.props.type === 'radio' || this.props.type === 'checkbox' ? true : false)
    }

    get isTextArea() {
        return (this.props.type === 'textarea' ? true : false)
    }

    submitForm = (e) => {
        if (e.charCode === 13 && e.type === 'keypress' && this.props.pressEnter != null) 
            this.props.pressEnter();
    }

    background = () => {
        if(this.props.icon != null){
            return '{background: url(' + process.env.PUBLIC_URL + '/assets/icons/' + this.props.icon + 
            '; background-position-x: right;' +
            'background-size: 20px;' +
            'background-origin: content-box}';
        } else {
            return ''
        }
    }

    formatDataWithSlash(date) {
        if (date != null) {
            var dataList = date.split('T');
            var dataAux = dataList[0].split('-');
            var data = dataAux[2] + '/' + dataAux[1] + '/' + dataAux[0]
            return data;
        }

        return '';
    };

    renderInputContent() {
        if (this.props.isOnlyLabel) {

            return (
                <div className='formField-onlyLabel-container'>
                    {this.props.type === 'date' ? (
                        <span className='formField-onlyLabel-container-date'>
                            {this.formatDataWithSlash(this.props.value || this.props.inputValue)}
                        </span>
                    ) : (
                        <span>
                            {(this.props.value || this.props.inputValue || 0).toFixed(6).replace('.', ',')}
                        </span>
                    )}
                </div>
            );
        }

        return (
            this.props.type === 'currency' ?
            <InputCurrencyMask 
                onChange={this.props.changeValue}
                value={this.props.value} 
                className={`${this.subClass} ${(this.props.error != null && this.props.error !== '') ? 'isInvalid' : ''}`} 
                placeholder={this.props.placeholder}  
                disabled={this.props.disabled} 
            /> 
            : (
                <>
                    {this.isTextArea && 
                        <>
                            <textarea 
                                id={this.props.id} 
                                className={`${this.subClass} ${(this.props.error != null && this.props.error !== '') ? 'isInvalid' : ''}`}  
                                placeholder={this.props.placeholder} 
                                onClick={this.props.click} 
                                value={this.props.inputValue} 
                                onChange={this.props.changeValue}
                                onBlur={this.props.changeValueBlur}
                                disabled={this.props.disabled} 
                                required={this.props.required}
                                name={this.props.name}
                                maxLength={`${this.props.maxLength || this.props.counter}`}
                            />
                            {this.props.counter && (
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', color: '#B4B8C0'}}>
                                    {this.props.inputValue.length}/{this.props.counter}
                                </div>
                            )}
                        </>
                    }
                    { this.props.type === 'number' && this.props.subtype &&
                        <CurrencyInput separator={','}
                            id={this.props.id}
                            enableEmpty={this.props.enableEmpty}
                            defaultValue={this.props.inputValue + ''} 
                            sufix={this.props.subtype === 'porcentagem' ? '%' : ''}
                            className={`${this.subClass} ${(this.props.error != null && this.props.error !== '') ? 'isInvalid' : ''}`} 
                            placeholder={this.props.placeholder}  
                            onBlur={this.props.changeValue}
                            disabled={this.props.disabled} 
                            required={this.props.required}
                            step={`${this.props.step}`}
                            min={`${this.props.min}`}
                            max={`${this.props.max}`}
                            maxLength={`${this.props.maxLength}`}
                            name={this.props.name}
                            data={this.props.data} 
                            subtype={this.props.subtype} /> 
                    }
                    { this.props.subtype == null && !this.isTextArea && 
                        <input type={this.props.type} 
                            id={this.props.id}
                            defaultValue={this.props.initialValue}
                            className={`${this.subClass} ${(this.props.error != null && this.props.error !== '') ? 'isInvalid' : ''}`} 
                            placeholder={this.props.placeholder} 
                            onClick={this.props.click} 
                            value={this.props.inputValue} 
                            onChange={this.props.changeValue}
                            onBlur={this.props.changeValueBlur}
                            disabled={this.props.disabled} 
                            onKeyPress={this.submitForm} 
                            required={this.props.required}
                            style={{background: this.props.icon != null ? 'url(' + process.env.PUBLIC_URL + '/assets/icons/' + this.props.icon + ')' : ''}}
                            step={`${this.props.step}`}
                            min={`${this.props.min}`}
                            max={`${this.props.max}`}
                            maxLength={`${this.props.maxLength}`}
                            name={this.props.name}
                            autoComplete={`${this.props.complete}`}
                            data={this.props.data} />
                    } 
                </>                   
            )
        )
    }

    render() {
        return (
            
            <div className={'formField'} title={this.props.title != null ? this.props.title : '' }>
                {this.isRadioButton ? 
                <div className={'input'}>
                    <label>{this.props.label}</label>
                    <label className={"radio" + (this.props.disabled ? ' disabled' : '')}>
                        <label>{this.props.radioLabel}</label>
                        {this.props.isOnlyLabel ?  (
                            <div>is only label</div>
                        ) : (
                            <>
                                <input type={this.props.type}   
                                    value={this.props.inputValue} 
                                    onChange={this.props.changeValue}  
                                    disabled={this.props.disabled} 
                                    checked={this.props.checked}
                                    name={this.props.name} />
                                <span className={'checkmark'}></span>
                            </>
                        )}
                    </label>
                    <span className={'isInvalid'} style={{display: this.erroFlag}}>{this.props.error}</span>
                    <span style={{display: this.warningFlag}}>{this.props.warning}</span>
                </div>
                :
                <div className={'input'}>
                    <label className={`${this.subClassLabel}`}  style={{display: this.props.label != null ? 'inline' : 'none'}}>{this.props.label}</label>
                    { this.renderInputContent() }
                <span className={'addInfo'} style={{display: this.infoFlag}}>{this.props.info}</span >
                <span className={'isInvalid'} style={{display: this.erroFlag}}>{this.props.error}</span>
                <span style={{display: this.warningFlag}}>{this.props.warning}</span>
                </div>}
                <div className={'button'} style={{display: this.hasButton}}>
                    {this.props.button}
                </div>               
            </div>
        )
    }
}

