import React, { Component } from 'react';
import Page from '../../components/page/page';
import { Grid } from "@material-ui/core";
import GenericButton from '../../components/genericButton/genericButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import Table from './../../components/table/table';
import Checkbox from '@material-ui/core/Checkbox';
import SimpleModal from '../../components/modal/modal';
import EmptyList from '../../components/emptyList/emptyList';
import API, { BASE_URL } from './../../comm/API';
import Masks from '../../comm/masks';
import { Link } from 'react-router-dom';
import './arquivadosMentoriaDigital.scss';


export default class ArquivadosMentoriaLean extends Component {

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    titles = [
        { id: '1', canOrder: false, label: '' },
        { id: '2', canOrder: false, label: 'N° Cliente' },
        { id: '3', canOrder: false, label: 'CNPJ' },
        { id: '4', canOrder: false, label: 'Empresa' },
        { id: '5', canOrder: false, label: 'Município' },
        { id: '6', canOrder: false, label: 'Bairro' },    ]

    state = {
        listArquivados: [], titles: this.titles, listSelected: [], openModalDesarquivar: false,

        listNomes: [], openModalExcluir: false, emptyList: ''

    };

    componentDidMount() {
        this.findArquivados();
    };

    findArquivados = async () => {
        this.showLoading();
        let listArquivados = (await API.get(`/rest/sistema/Cluster/getClientesArquivados/${this.props.usuarioConectado.unidade.id}`)).data;
        listArquivados.forEach(cli => {
            cli.selected = false;
        });
        await this.setState({ listArquivados: listArquivados, openModalDesarquivar: false, openModalExcluir : false});

        if(listArquivados.length === 0){
            await this.setState({emptyList:'noItens'});
        }
        else{
            await this.setState({emptyList:''});

        }
        this.closeLoading();
    }

    selecionarCliente(e,  idcliente) {

        let clients = this.state.listArquivados;
        clients.forEach(cli => {
            if (cli.idcliente === idcliente) {
                cli.selected = e.target.checked;
            }
        });
        this.setState({ listArquivados: clients });
    };

    getLinesArquivados() {
        let list = this.state.listArquivados.map((item, index) => {
            
                return [item.idcliente,
                    <Checkbox checked={item.selected} onChange={(e) => { this.selecionarCliente(e, item.idcliente) }} />,
                    item.idcliente,
                    (item.cpfcnpj.length > 11 ? Masks.maskByTipoPessoa(item.cpfcnpj, 1) :
                        Masks.maskByTipoPessoa(item.cpfcnpj, 0)),
                    item.razaosocial,
                    item.municipio,
                    item.bairro];
            
        });
        return list;
    }

    closeModalDesarquivar = () => {
        this.setState({ openModalDesarquivar: false });
    }

    closeModalExcluir = () => {
        this.setState({ openModalExcluir: false });
    }

    async handleDesarquivar() {
        let list = [];
        let nomes = [];
        this.state.listArquivados.forEach(cli => {
            if (cli.selected) {
                list.push(cli.idcliente);
                nomes.push(cli.razaosocial);
            };
        });
        await this.setState({ listSelected: list, listNomes: nomes });

        if (this.state.listSelected.length > 0) {
            await this.setState({openModalDesarquivar : true});
        }

    }

    async handleExcluir() {
        let list = [];
        let nomes = [];
        this.state.listArquivados.forEach(cli => {
            if (cli.selected) {
                list.push(cli.idcliente);
                nomes.push(cli.razaosocial);
            };
        });
        await this.setState({ listSelected: list, listNomes: nomes });

        if (this.state.listSelected.length > 0) {
            await this.setState({openModalExcluir : true});
        }

    }

    desarquivarClientes = async () => {
        this.showLoading();
        let clientes = {clienteMentoriaLeanList: this.state.listSelected.join(',') };
        await API.post(`/rest/sistema/Cluster/desarquivar/${this.props.usuarioConectado.unidade.id}`, clientes).then(async res => {
            await this.setState({ flagButton: true }, this.findArquivados);
            this.showToast('Desarquivamento realizado com sucesso!', 'success');
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao tentar mover clientes. Por favor, entre em contato com o suporte.', 'error');
        });
    }

    excluirClientes = async () => {
        this.showLoading();
        let clientes = {clienteMentoriaLeanList: this.state.listSelected.join(',') };
        await API.post(`/rest/sistema/Cluster/deletar/${this.props.usuarioConectado.unidade.id}`, clientes).then(async res => {
            await  this.setState({ flagButton: true }, this.findArquivados);;
            this.showToast('Clientes excluidos com sucesso!', 'success');
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao tentar excluir clientes. Por favor, entre em contato com o suporte.', 'error');
        });
    }

    downloadRelatorio = async () => {
        try {
            this.showLoading();
            let path = `rest/gestao/RelatorioTurmaLean/getSetupUO/ClientesArquivados/${this.props.usuarioConectado.unidade.id}`;
            window.open(BASE_URL + path);
            this.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }
    }


    render() {
        return (
            <Page icon={'brasil-mais.svg'} label={'Mentoria Digital'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container spacing={1} className={'top-container'} justify={'space-between'}>
                    <Grid item>
                        <Grid container style={{marginTop: '10px'}} direction={'row'} justify={'flex-start'}>
                            <Grid item>
                                <Link to={'/brasilmais/mentorialean'} style={{ cursor: "pointer" }}>
                                    <Breadcrumb label={'Gerenciar Turmas'} icon={'breadcrumb-blue.svg'} color={'primary'} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to={'/brasilmais/unidadesoperacionais'} style={{ cursor: "pointer" }}>
                                    <Breadcrumb label={'Setup UO\'s'} icon={'breadcrumb-gray-inverted.svg'} color={'primary'} />
                                </Link>
                            </Grid>
                            <Grid item><Breadcrumb label={'Clientes Arquivados'} icon={'breadcrumb-gray.svg'} color={'secondary'} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>
              {this.state.listArquivados.length > 0 && <Grid container id={'unidades-mentoria-digital'}>
                        <Grid container spacing={1} className={'top-container'} direction={'column'} justify={'flex-start'} alignItems={'center'}>
                            <Grid item>
                                <Grid container direction={'column'} justify={'flex-start'} alignItems={'flex-start'}>
                                    <Grid item>
                                        <Table lines={this.getLinesArquivados()} titles={this.state.titles} initialOrder={'0'} pagination={false} />
                                    </Grid>
                                    <Grid item className={'botao-relatorio'}>
                                        <GenericButton color={'darkBlue'} label={'Baixar relatório'} subClass={'basic-button with-icon'}
                                            click={this.downloadRelatorio} icon={process.env.PUBLIC_URL + 'download-white.svg'} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2} direction={'row'} justify={'center'} alignItems={'flex-start'}>
                                    <Grid item>
                                        <GenericButton color={'darkGrey-outline'} label={'Excluir'} subClass={'basic-button'} click={(e) => { this.handleExcluir(); }}  />
                                    </Grid>
                                    <Grid item>
                                        <GenericButton color={'darkBlue'} label={'Desarquivar'} subClass={'basic-button'} click={(e) => { this.handleDesarquivar(); }} />

                                    </Grid>
                                </Grid>
                            </Grid> 
                        </Grid>
                    </Grid>}
                {this.state.listArquivados.length === 0 &&
                    <EmptyList type={this.state.emptyList} msg={"Ainda não há clientes arquivados"} 
                    subMsg={"Para arquivar, na tela de \"Setup UO's\", clique no botão \"Arquivar\" selecione os clientes e clique novamente em \"Arquivar\"."} />}

                <SimpleModal
                    isOpen={this.state.openModalDesarquivar}
                    handleClose={this.closeModalDesarquivar}
                    disableClick={true}
                    type={'success'}
                    width={'480px'}
                    className={'evento-modal'}>
                    <label className={'confirmacao-label'}>O(s) cliente(s) desarquivado(s) irá(ão) retornar para a listagem em Setup UO's.</label>
                    <br /><br />
                    <label className={'confirmacao-label'}>Voce confirma o desarquivamento do(s) cliente(s) </label>
                    <label className={'confirmacao-label-bold'}>{this.state.listNomes.join(", ")}</label>
                    <div className={'rodape'}>
                        <GenericButton color={'darkBlue'} label={'Cancelar'} subClass={'basic-button'} click={this.closeModalDesarquivar} />
                        <GenericButton color={'darkGrey-outline'} label={'Desarquivar'} subClass={'basic-button'} click={this.desarquivarClientes} />
                    </div>
                </SimpleModal>
                <SimpleModal
                    isOpen={this.state.openModalExcluir}
                    handleClose={this.closeModalExcluir}
                    disableClick={true}
                    type={'warning'}
                    width={'480px'}
                    className={'evento-modal'}>
                    <label className={'confirmacao-label'}>Você confirma a exclusão da(s) empresa(s) </label>
                    <label className={'confirmacao-label-bold'}>{this.state.listNomes.join(", ")}</label>
                    <label className={'confirmacao-label'}> do arquivo?</label>
                    <div className={'rodape'}>
                        <GenericButton color={'darkBlue'} label={'Cancelar'} subClass={'basic-button'} click={this.closeModalExcluir} />
                        <GenericButton color={'darkGrey-outline'} label={'Excluir'} subClass={'basic-button'} click={this.excluirClientes} />
                    </div>
                </SimpleModal>
            </Page>)
    }

}