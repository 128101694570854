import React, { Component } from 'react';
import Popover from '@material-ui/core/Popover';
import Button from '@material-ui/core/Button';
import './simplePopover.scss'

export default class SimpleModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            id: undefined,
            position: null
        }
    }

    openPopover = (event) => {
        this.setState({ position: event.currentTarget });
        this.setState({ open: true });
        this.setState({ id: 'mouse-over-popover' });
    }

    closePopover = (event) => {
        this.setState({ position: null });
        this.setState({ open: false });
        this.setState({ id: undefined });
    }

    render() {
        return (
            <div className={'popover-icon'}>
                <Button
                    aria-owns={this.state.id}
                    aria-haspopup="true"
                    onMouseEnter={this.openPopover}
                    onMouseLeave={this.closePopover}
                    className={'transparent'}>
                    <img style={{ display: 'block' }}
                        src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                        width={18}
                        height={18}
                        alt={'info'} />
                </Button>
                <Popover
                    className={'popover'}
                    id={'mouse-over-popover'}
                    open={this.state.open}
                    anchorEl={this.state.position}
                    onClose={this.closePopover}
                    anchorOrigin={{ vertical: 0, horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 0 }}
                    disableRestoreFocus>
                    <img style={{ display: 'block' }}
                        src={process.env.PUBLIC_URL + '/assets/icons/info.svg'}
                        width={18}
                        height={18}
                        alt={'info'} />
                    <p>{this.props.content}</p>
                </Popover>
            </div>
        );
    }
}
