import * as Sentry from "@sentry/browser";
import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { BASE_URL } from "./API";
import { SyncRequest } from "./APIsync";

const PrivateRoute = ({ component: Component, ...rest }) => {
  var usuarioConectado = SyncRequest("get", BASE_URL + "rest/security/usuarioConectado");
  var isUO = false;
  var accessNotification = false;
  let isISI = false;

  Sentry.setUser({ email: usuarioConectado.login });

  if (usuarioConectado.colaborador != null && usuarioConectado.colaborador.isAtivo) {
    isUO = true;

    //Caso o colaborador não tenha uma unidade,
    //o sistema seleciona a primeira unidade na lista colaboradorUnidade
    if (usuarioConectado.colaborador.unidade == null) {
      usuarioConectado.colaborador.unidade = usuarioConectado.colaborador.colaboradorUnidadeList[0].unidade;
      usuarioConectado.unidade = usuarioConectado.colaborador.colaboradorUnidadeList[0].unidade;
    }
    isISI = usuarioConectado.colaborador.unidade.especialidadeUnidade.id === 1 ? true : false;
  }
  if (usuarioConectado) {
    accessNotification = usuarioConectado.perfilUsuario.nome === "ADMINISTRADOR" || usuarioConectado.perfilUsuario.nome === "GESTOR" || usuarioConectado.perfilUsuario.nome === "COORDENADOR";
  }
  if (usuarioConectado !== false) {
    var index = -1;
    if (usuarioConectado.perfilUsuario != null && usuarioConectado.perfilUsuario.telaPermitidaTempList != null)
      index = usuarioConectado.perfilUsuario.telaPermitidaTempList.findIndex((telaPermitida) => rest.location.pathname.includes(telaPermitida.tela.comando));
    if (
      index !== -1 ||
      rest.location.pathname === "/cad/sistema/meusDados" ||
      rest.location.pathname === "/brasilmais/configuracao" ||
      (rest.location.pathname === "/cad/sistema/notificacao" && accessNotification)
    ) {
      if (
        (isUO &&
          (rest.location.pathname === "/cad/parametro" ||
            rest.location.pathname === "/cad/sistema/usuario/novo" ||
            rest.location.pathname === "/cad/atendimento/produtoRegional/novo" ||
            rest.location.pathname === "/cad/sistema/perfilUsuario")) ||
        (!isUO && (rest.location.pathname === "/cad/atendimento/producao/colaborador" || rest.location.pathname === "/receita" || rest.location.pathname.length === "/brasilmais/mentorialean/novo")) ||
        (isUO && !isISI && rest.location.pathname.toLowerCase() === "/dashboardisi")
      )
        return <Route render={(props) => <Redirect to={{ pathname: "/noAccess", state: { from: props.location, usuarioConectado: usuarioConectado } }} />} />;
      else return <Route render={(props) => <Component {...rest.computedMatch.params} usuarioConectado={usuarioConectado} history={props.history} />} />;
    } else {
      return <Route render={(props) => <Redirect to={{ pathname: "/noAccess", state: { from: props.location, usuarioConectado: usuarioConectado } }} />} />;
    }
  } else {
    return <Route render={(props) => <Redirect to={{ pathname: "/", state: { from: props.location } }} />} />;
  }
};

export default withRouter(PrivateRoute);
