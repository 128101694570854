import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import './visita.scss';

export default class Visita extends Component {
    
    constructor(props) {
        super(props);
        this.state = { tab: 'Atividades' };
    };

    changeTab = async (value, newValue) => {
        await this.setState({ tab: newValue });
    };

    renderContent() {
        switch(this.state.tab) {
            case 'Atividades':
                return this.props.renderAtividades();
            case 'Informações':
                return this.props.renderInfo();
            default:
                return;
        }
    }

    render() {
        return (
            <Grid container style={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                    <AppBar position='static'>
                        <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                            <Tab key={'Atividades'} value={'Atividades'} label={'Atividades'}></Tab>
                            <Tab key={'Informações'} value={'Informações'} label={'Informações'}></Tab>
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item xs={12}>
                    {this.renderContent()}
                </Grid>
            </Grid>
        )
    };
};