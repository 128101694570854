import React, { Component } from 'react'
import './produtoNacionalNovo.scss'
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper'
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page';
import GenericButton from './../../components/genericButton/genericButton';
import AutoComplete from '../../components/autoComplete/autoComplete';
import Table from '../../components/table/table';
import API from '../../comm/API'
import InputSelect from '../../components/inputselect/inputselect';

export default class ProdutoNacionalNovo extends Component {

    produtoNacional = {
        codigoDN: '',
        CR: '',
        descricao: '',
        produtoCategoria: {
            produtoLinha: {
                descricao: ''
            },
            descricao: ''
        },
        isAtivo: true,
        motivo: '',
        isBaseNacional: false,
        constantesList: []
    };

    titlesConstantes = [
        { id: 'descricao', canOrder: false, label: 'Indicadores' },
        { id: 'fieldValue', canOrder: false, label: 'Valor da constante' }
    ];

    titlesMetasDR = [
        { id: '1', canOrder: false, label: 'Departamento Regional' },
        { id: '2', canOrder: false, label: 'Metas' },
        { id: '3', canOrder: false, label: '' },
    ];

    metaDR = {
        dr: '',
        valor: ''
    };

    errors = {
        descricao: '',
        CR: '',
        codigoDN: '',
        produtoCategoria: '',
        linha: ''
    };

    regionais = [];
    produtoNacionalRegional = {};
    titles = ['Dados do Produto Nacional', 'Associe a uma Linha e Categoria', 'Revisão'];
    isProdutoNacionalAntigo = false;

    constructor(props) {
        super(props)
        this.state = {
            idProdutoNacional: props.idProdutoNacional !== undefined ? props.idProdutoNacional : undefined,
            produtoCategoriaList: [],
            produtoLinhaList: [],
            drList: [],
            drMetasList: [],
            tableID: 0,
            produtoLinha: undefined,
            isNew: true,
            errorMetas: { dr: '', valor: '' }
        };
        if (props.idProdutoNacional !== undefined) {
            API.get('rest/auxiliar/ProdutoNacional/' + props.idProdutoNacional).then(res => {
                this.produtoNacional = res.data;
                this.setState({ isNew: false });
                this.getConstantesList();
                this.getProdutoLinhaList(this.produtoNacional);
                if (this.produtoNacional && this.produtoNacional.metaRegionalList) this.unpopulate();
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Erro no GET de produto nacional. Por favor, entre em contato com o suporte.', 'error');
            });
        } else {
            this.getConstantesList();
            this.getProdutoLinhaList();
            this.getProdutoCategoriaList();
        }
    }

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    }

    getProdutoLinhaList = async (produtoNacional) => {
        var filter = { isVisivel: true }
        await API.get('rest/auxiliar/ProdutoLinha', { params: filter }).then(res => {
            var produtoLinhaList = res.data
            this.setState({ produtoLinhaList: produtoLinhaList })
            if (produtoNacional !== undefined) {
                produtoLinhaList.forEach((obj) => {
                    if (obj.id === parseInt(this.produtoNacional.produtoCategoria.produtoLinha.id)) {
                        this.setState({ produtoLinha: obj })
                    }
                });
            }
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro no GET de produto linha. Por favor, entre em contato com o suporte.', 'error');
        });
        if (produtoNacional !== undefined) {
            this.setState({ produtoLinha: produtoNacional.produtoCategoria.produtoLinha })
            this.getProdutoCategoriaList(produtoNacional.produtoCategoria.produtoLinha)
        }
    }

    changeValueConstante = async (e, constante) => {
        this.produtoNacional.constantesList.forEach((elem, index, array) => {
            if (elem.constanteProdutoNacional.id === constante.constanteProdutoNacional.id) {
                array[index].valor = parseFloat(e.target.value)
            }
        })
    }

    getConstantesList = () => {
        API.get('rest/gestao/ConstanteProdutoNacional').then(res => {
            let constantes = res.data;
            if (this.produtoNacional.constantesList === null || this.produtoNacional.constantesList.length === 0) {
                let constantesList = [];
                let line;
                constantes.forEach(elem => {
                    line = {};
                    line.constanteProdutoNacional = elem;
                    line.valor = 0;
                    constantesList.push(line);
                })
                this.produtoNacional.constantesList = constantesList;
            }
            this.mountConstantesTable();
        }).catch(error => {
            this.showToast('Erro no GET de constantes. Por favor, entre em contato com o suporte.', 'error');
        })
    }

    mountConstantesTable = async () => {
        let constantesList = [];
        this.produtoNacional.constantesList.forEach(elem => {
            elem.descricao = elem.constanteProdutoNacional.descricao;
            elem.fieldValue = (
                <FormField className={'input-constante'} type={'number'}
                    key={'key' + elem.constanteProdutoNacional.id}
                    inputValue={elem.valor}
                    changeValue={(e) => this.changeValueConstante(e, elem)}
                    min='0' maxLength='15' subtype={'duascasas'} />
            );
            constantesList.push(elem);
        });
        this.produtoNacional.constantesList = constantesList;
        this.setState(this.state);
    }

    getProdutoCategoriaList = (produtoLinha) => {
        var filter = {}
        if (produtoLinha === undefined) {
            filter = { isVisivel: true }
        } else {
            filter = { isVisivel: true, 'produtoLinha.id': produtoLinha.id }
        }
        API.get('rest/auxiliar/ProdutoCategoria', { params: filter }).then(res => {
            var produtoCategoriaList = res.data
            this.setState({ produtoCategoriaList: produtoCategoriaList })
            if (this.produtoNacional.id !== undefined) {
                if (this.produtoNacional.produtoCategoria.produtoLinha === produtoLinha) {
                    produtoCategoriaList.forEach((obj) => {
                        if (obj.id === parseInt(this.produtoNacional.produtoCategoria.id)) {
                            this.produtoNacional.produtoCategoria = obj
                            this.setState(this.state);
                        }
                    });
                } else {
                    this.errors.categoria = 'Campo obrigatório.';
                    this.setState(this.state);
                }
            }
            this.closeLoading();
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro no GET de produto categoria. Por favor, entre em contato com o suporte.', 'error');
        })
    }

    async componentWillMount() {
        this.getDrs();
        await this.getProdutoNacionalAntigo();
    }

    getDrs = () => {
        API.get('rest/gestao/Unidade/drs').then(resp => {
            let drs = resp.data;
            this.setState({ drList: drs });
        }).catch(error => {
            this.showToast('Erro ao buscar lista de departamentos regionais. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    getProdutoNacionalAntigo = () => {
        if (this.props.idProdutoNacional) {
            API.get('/rest/sistema/Parametro?chave=ID_%&valorstring=' + String(this.props.idProdutoNacional)).then(resp => {
                let data = resp.data;
                if (data && data.length > 0) this.isProdutoNacionalAntigo = true;
                else this.isProdutoNacionalAntigo = false;
            }).catch(error => {
                console.log(error);
                this.showToast('Erro ao buscar lista de departamentos regionais. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }

    changeInput = (e) => {
        if (e.target) {
            if (e.target.type === 'radio') {
                this.produtoNacional[e.target.name] = JSON.parse(e.target.value)
            } else {
                this.produtoNacional[e.target.id] = e.target.value.toUpperCase();
                this.errors[e.target.id] = (e.target.value !== undefined && e.target.value !== '') ? '' : 'Campo obrigatório.';
            }
            this.setState(this.state);
        }
    }

    changeDR = (e) => {
        this.metaDR.dr = e;
    }

    changeLinha = (e) => {
        if (e != null && e !== '') {
            if (e !== this.state.produtoLinha) {
                this.setState({ produtoLinha: e });
                if (this.produtoNacional.produtoCategoria != null) {
                    if (e.descricao !== this.produtoNacional.produtoCategoria.produtoLinha.descricao) {
                        this.produtoNacional.produtoCategoria.descricao = '';
                        this.errors.produtoCategoria = 'Campo obrigatório.';
                    }
                    this.setState(this.state);
                }
                this.getProdutoCategoriaList(e)
            }
        }
        if (e.descricao === '') {
            this.errors.linha = 'Campo obrigatório.';
            this.errors.produtoCategoria = 'Campo obrigatório.';
        }
    }

    changeCategoria = (e) => {
        if (e != null && e !== '') {
            if (e !== this.produtoNacional.produtoCategoria) {
                this.produtoNacional.produtoCategoria = e;
                this.errors.produtoCategoria = '';
                this.setState(this.state);
            }
        }
    }

    validateProdutoNacional = (step) => {
        switch (step) {
            case 0:
                return this.produtoNacional.descricao && this.produtoNacional.CR && this.produtoNacional.codigoDN
            case 1:
                return this.produtoNacional.produtoCategoria.produtoLinha.descricao && this.produtoNacional.produtoCategoria.descricao
            default:
                return true
        }
    }

    populate = () => {
        let regionais = [];
        for (let i = 0; i < this.state.drMetasList.length; i++) {
            regionais.push({
                unidade: { id: this.state.drMetasList[i][0] },
                itemMetaRegionalList: [{ meta: parseInt(this.state.drMetasList[i][2].replaceAll('.', '')), metaSenai: 0, metaGoverno: 0, tipoMeta: this.produtoNacional.descricao }]
            });
        }
        this.produtoNacional.metaRegionalList = regionais;
        this.produtoNacionalRegional = { produtoNacional: this.produtoNacional, regionais: regionais };
    }

    unpopulate = () => {
        let drMetasList = [];
        for (let i = 0; i < this.produtoNacional.metaRegionalList.length; i++) {
            let meta = this.produtoNacional.metaRegionalList[i];
            let line = [];
            line.push(meta.unidade.id);
            line.push(meta.unidade.descricao.toUpperCase());
            line.push(meta.itemMetaRegionalList[0].meta.toLocaleString('pt-BR'));
            line.push(
                <div key={'tabela' + meta.unidade.id} className={'tab-metas-dr'}>
                    <GenericButton key={'tabela-button' + meta.unidade.id} color={'transparent'} click={(e) => { this.removeMetaDR(meta.unidade.id) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                </div>
            );
            drMetasList.push(line);
        }
        this.setState({ drMetasList });
    }

    saveProdutoNacional = () => {
        this.showLoading();
        this.populate();
        if (this.produtoNacional.id === undefined) {
            API.post('rest/auxiliar/ProdutoNacional', this.produtoNacional).then(res => {
                this.closeLoading();
                this.showToast('Produto Nacional cadastrado com sucesso!', 'success', '/cad/atendimento/produtoNacional');
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Error ao cadastrar produto nacional. Por favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/auxiliar/ProdutoNacional/' + this.produtoNacional.id, this.produtoNacional).then(res => {
                this.closeLoading();
                if (res && res.data && res.data.response) {
                    if (res.data.response.includes("porém")) this.showToast(res.data.response, 'warning', '/cad/atendimento/produtoNacional');
                    else this.showToast(res.data.response, 'success', '/cad/atendimento/produtoNacional');
                }
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Error ao editar produto nacional.Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }

    removeMetaDR = (id) => {
        let drMetasList = this.state.drMetasList;
        let index = drMetasList.findIndex(meta => meta[0] === id);
        drMetasList.splice(index, 1);
        this.setState({ drMetasList: drMetasList });
    }

    addMetaDR = () => {
        let line = [];
        let errorMetas = this.state.errorMetas;
        let drMetasList = this.state.drMetasList;
        if (this.metaDR.dr === '') errorMetas.dr = 'Campo obrigatório';
        else errorMetas.dr = '';
        if (this.metaDR.valor === '' || this.metaDR.valor === 0) errorMetas.valor = 'Campo obrigatório';
        else errorMetas.valor = '';
        if (errorMetas.dr === '' && errorMetas.valor === '') {
            for (let i = 0; i < drMetasList.length; i++) {
                if (this.metaDR.dr[0] === drMetasList[i][0]) {
                    errorMetas.dr = 'Regional já cadastrado.';
                    break;
                } else {
                    errorMetas.dr = '';
                }
            }
        }
        if (errorMetas.dr === '' && errorMetas.valor === '') {
            let metaDR = Object.assign({}, this.metaDR);
            line.push(metaDR.dr[0]);
            line.push(metaDR.dr[1]);
            line.push(metaDR.valor.toLocaleString('pt-BR'));
            line.push(
                <div key={'tabela' + metaDR.dr[0]} className={'tab-metas-dr'}>
                    <GenericButton color={'transparent'} click={(e) => { this.removeMetaDR(metaDR.dr[0]) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                </div>
            );
            drMetasList.push(line);
        }
        this.setState({ errorMetas, drMetasList });
    }

    getSteps = () => {
        return [
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Descrição'} id={'descricao'} inputValue={this.produtoNacional.descricao} changeValue={this.changeInput}
                                error={this.errors.descricao} required={'required'} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <FormField type={'text'} label={'CR'} id={'CR'} inputValue={this.produtoNacional.CR} changeValue={this.changeInput}
                                error={this.errors.CR} required={'required'} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField type={'text'} label={'Código UNIGEST'} id={'codigoDN'} inputValue={this.produtoNacional.codigoDN} changeValue={this.changeInput}
                                error={this.errors.codigoDN} required={'required'} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <label className={'radio-label'}>Status</label>
                            <div style={{ display: 'flex' }}>
                                <FormField type={'radio'} radioLabel={'Ativo'} checked={this.produtoNacional.isAtivo === true} inputValue={true} changeValue={this.changeInput} name={'isAtivo'} />
                                <FormField type={'radio'} radioLabel={'Inativo'} checked={this.produtoNacional.isAtivo === false} inputValue={false} changeValue={this.changeInput} name={'isAtivo'} />
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <label className={'radio-label'}>É um produto de base nacional?</label>
                            <div style={{ display: 'flex' }}>
                                <FormField type={'radio'} radioLabel={'Sim'} checked={this.produtoNacional.isBaseNacional === true} inputValue={true} changeValue={this.changeInput} name={'isBaseNacional'} disabled={this.produtoNacional.id} />
                                <FormField type={'radio'} radioLabel={'Não'} checked={this.produtoNacional.isBaseNacional === false} inputValue={false} changeValue={this.changeInput} name={'isBaseNacional'} disabled={this.produtoNacional.id} />
                            </div>
                        </Grid>

                        {this.produtoNacional.isBaseNacional && !this.isProdutoNacionalAntigo &&
                            <>
                                {this.produtoNacional.constantesList && this.produtoNacional.constantesList.length > 0 &&
                                    <>
                                        <Grid item xs={12}>
                                            <label className={'meta-departamento-title sem-margin'}>Constantes dos indicadores</label>
                                        </Grid>
                                        <Grid item xs={12} xl={12} className={'metasDR-nova-tabela'}>
                                            <Table lines={this.produtoNacional.constantesList} titles={this.titlesConstantes} initialOrder={'0'} pagination={false} />
                                        </Grid>
                                    </>}
                                <Grid item xs={12} >
                                    <label className={'meta-departamento-title com-margin-top'}>Metas dos Departamentos Regionais</label>
                                </Grid>
                                <Grid item xs={12} sm={9} className={'paddingForm'} >
                                    <AutoComplete id={'dr'} label={'Departamento Regional (DR)'} suggestions={this.state.drList} itemKey={0} key={'autoCompleteDepartamentoReginal'} error={this.state.errorMetas.dr}
                                        placeholder={'Digite o nome de um Departamento Regional'} initialSelectedItem={this.metaDR.dr} itemlabel={1} getInput={this.changeDR} />
                                    {this.state.errorMetas.dr !== '' &&
                                        <span style={{ fontSize: 13 }} className={'agrupamento-error'}>{this.state.errorMetas.dr}</span>}
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <FormField type={'number'} label={'Metas'} id={'valor'} inputValue={this.metaDR.valor} changeValue={(e) => { this.metaDR.valor = Number(e.target.value) }}
                                        min={'0'} maxLength={'10'} subtype={'inteiro'} warning={this.state.errorMetas.valor} />
                                </Grid>
                                <Grid item xs={12} sm={1} className={'botao'}>
                                    <GenericButton color={'darkBlue'} subClass={'icon-button'} id={'button-add-metas-dr'} click={this.addMetaDR} icon={'plus.svg'} />
                                </Grid>
                                {this.state.drMetasList != null && this.state.drMetasList.length > 0 &&
                                    <Grid item xs={12} xl={12} className={'metasDR-nova-tabela'}>
                                        <Table lines={this.state.drMetasList} titles={this.titlesMetasDR} initialOrder={'1'} pagination={false} />
                                    </Grid>}
                            </>}
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputSelect
                                id={'produtoLinha'}
                                label={'Linha'}
                                suggestions={this.state.produtoLinhaList}
                                itemLabel={'descricao'}
                                itemKey={'descricao'}
                                getInput={this.changeLinha}
                                initialSelectedItem={this.state.produtoLinha} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect
                                id={'produtoCategoria'}
                                label={'Competência'}
                                suggestions={this.state.produtoCategoriaList}
                                itemLabel={'descricao'}
                                itemKey={'descricao'}
                                getInput={this.changeCategoria}
                                initialSelectedItem={this.produtoNacional.produtoCategoria}
                                error={this.errors.produtoCategoria} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={"step"}>
                <Grid item xs={12} sm={10} md={7} xl={6} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>1</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Dados do Produto Nacional</label>
                        <Grid container>
                            <Grid item xs={6}>Descrição</Grid>
                            <Grid item xs={6}>{this.produtoNacional.descricao}</Grid>
                            <Grid item xs={6}>CR</Grid>
                            <Grid item xs={6}>{this.produtoNacional.CR}</Grid>
                            <Grid item xs={6}>Código UNIGEST</Grid>
                            <Grid item xs={6}>{this.produtoNacional.codigoDN}</Grid>
                            <Grid item xs={6}>Status</Grid>
                            <Grid item xs={6}>{this.produtoNacional.isAtivo ? 'Ativo' : 'Inativo'}</Grid>
                            <Grid item xs={6}>Produto de Base Nacional</Grid>
                            <Grid item xs={6}>{this.produtoNacional.isBaseNacional ? 'Sim' : 'Não'}</Grid>
                            {this.produtoNacional.isBaseNacional && this.state.drMetasList != null && this.state.drMetasList.length > 0 && !this.isProdutoNacionalAntigo &&
                                <>
                                    <Grid item xs={6} className={'review-drmeta'}>Departamento Regional (DR)</Grid>
                                    <Grid item xs={6} className={'review-drmeta'}>Metas</Grid>
                                    {this.state.drMetasList.map(item => (
                                        <Grid container key={'meta-dr-valor-' + item[1]}>
                                            <Grid item xs={6}>{item[1]}</Grid>
                                            <Grid item xs={6}>{item[2]}</Grid>
                                        </Grid>
                                    ))}
                                </>}
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} md={7} xl={6} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>2</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Associe a uma Linha e Categoria</label>
                        <Grid container>
                            <Grid item xs={6}>Linha</Grid>
                            <Grid item xs={6}>
                                {this.produtoNacional.produtoCategoria != null ? this.produtoNacional.produtoCategoria.produtoLinha.descricao : ''}
                            </Grid>
                            <Grid item xs={6}>Categoria</Grid>
                            <Grid item xs={6}>
                                {this.produtoNacional.produtoCategoria != null ? this.produtoNacional.produtoCategoria.descricao : ''}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>]
    }

    render() {
        return (
            <Page icon={'profile.svg'} label={'Novo Produto Nacional'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'novo-produto-nacional'}>
                    <Stepper
                        titles={this.titles}
                        steps={this.getSteps()}
                        reset={''}
                        validation={this.validateProdutoNacional}
                        sendForm={this.saveProdutoNacional}
                        history={this.props.history} />
                </Grid>
            </Page>
        );
    }
}