import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import InputSelect from '../../components/inputselect/inputselect';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import { Grid } from '@material-ui/core';
import API from '../../comm/API';
import DownloadFiles from '../../comm/DownloadFiles';
import './infoVisita.scss';
import { BorderBottom } from '@material-ui/icons';
import AnexosDefault from './anexosDefault';

export default class InfoEncontro extends Component {

    descricaoList = {
        PRESENCE_LIST: 'lista de presença',
        OTHERS: 'outros'
    };

    virtualOptions = [
        {id: 0 ,label: 'Virtual'},
        {id: 1 ,label: 'Presencial'}
    ]
    
    turnoOptions = [
        { id: 0, label: 'Manhã' },
        { id: 1, label: 'Tarde' },
        { id: 2, label: 'Noite' },
        { id: 3, label: 'Manhã/Tarde' },
        { id: 4, label: 'Tarde/Noite '},
        { id: 5, label: 'Manhã/Noite'}

    ];

    mentoresOptions = []

    outrosDefault = 'outrosDocumentos';

    constructor(props) {
        super(props);
        let visita = props.encontro;
        visita.listaPresenca = [];
        visita.outrosDocumentos = [];
        visita.imagens = [];
        this.mentoresOptions = [];
        console.log('this.props.mentoresDados' ,this.props.mentoresDados);
        this.props.mentoresDados.forEach(mentor => {this.mentoresOptions.push({id:mentor.id, label: mentor.nome})})
        console.log('etapa',this.props.etapa)
        if (visita.anexoEncontroList != null) {
            visita.anexoEncontroList.forEach(anexo => {
                if (anexo.descricao === this.descricaoList.PRESENCE_LIST && anexo.isDocumento) visita.listaPresenca.push(anexo);
                else if (anexo.descricao === this.descricaoList.OTHERS && anexo.isDocumento) visita.outrosDocumentos.push(anexo);
                else visita.imagens.push(anexo);
            });
        }
        this.outrosDefault = this.outrosDefault;
        this.state = { visita: visita };
    };

    formatData(date) {
        if (date) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    };

    changeInput = (e) => {
        let visita = this.state.visita;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                var d = new Date();
                var n = new Date(e.target.value + 'T00:00:00-03:00');
                d.setHours(0,0,0,0);
                n.setHours(0,0,0,0);
                if(n.getMonth() == d.getMonth() && n.getFullYear() == d.getFullYear()){
                    visita.data = e.target.value + 'T00:00:00-03:00';
                }
                else{
                    this.props.showToast('Só é permitido editar a data de um encontro para o mesmo mês da sua criação', 'error');
                }
                               
             
            } else if (e.target.type === 'number') {
                visita.horasApropriadas = e.target.value;
            }
        } else if (e && e.id != null) {
            visita.turno = e.id;
        }
        this.setState({ visita: this.state.visita });
    };

    changeInputMentor = (e) => {
        let visita = this.state.visita;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                visita.data = e.target.value + 'T00:00:00-03:00';
            } else if (e.target.type === 'number') {
                visita.horasApropriadas = e.target.value;
            }
        } else if (e && e.id != null) {
            visita.idMentor = e.id;
        }
        this.setState({ visita: this.state.visita });
    };

    changeVirtual = (e) =>{
        let visita = this.state.visita;
        visita.isVirtual = (e.id == 0);
        this.setState({ visita: this.state.visita });
    }

    

    checkImage = (anexo) => {
        return anexo.descricao != this.descricaoList.PRESENCE_LIST && anexo.descricao != this.descricaoList.OTHERS;
      }

    renderAnexos = () => {
        const icon = this.props.defaultIcon;
        const nomeAnexo = icon === 'depoimentos_' ? 'depoimentos' : 'evidências';
        return (
            <>
                {this.state.visita.imagens.length > 0 && <Grid container>
                    <Grid item xs={12} md={6} style={{ paddingTop: '15px' }}>
                        <label className={'registroFoto'}>{'REGISTRO FOTOGRÁFICO'}</label>
                    </Grid>
                    <Grid item xs={12} md={6} style={{ paddingTop: '15px' }}></Grid>
                    
                </Grid>}
                <AnexosDefault
                    disableForm={this.props.disabled}
                    showEmptyEvidencias={ this.props.showEmptyEvidencias }
                    anexos={ this.state.visita.imagens }
                    removerAnexo={(element) => this.removeAnexo(element,'imagens')}
                    icon={ icon }
                    msg={`Você ainda não anexou ${nomeAnexo}.`} 
                    turma={this.props.turma}
                    fase={this.props.faseTurma}
                    title='Registro fotográfico'
                />
            </>
        );
    };

    uploadAnexo = (e, listName) => {
        this.props.showLoading();
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file && !(file.name.toLowerCase().includes('jpg') || file.name.toLowerCase().includes('jpeg') || 
            file.name.toLowerCase().includes('png') || file.name.toLowerCase().includes('pdf'))) {
            this.props.closeLoading();
            this.props.showToast('Tipo de arquivo não permitido. Anexar apenas PDF, JPG, JPEG ou PNG.', 'error');
            return;
        }
        if (file.size > 10000000) {
            this.props.closeLoading();
            this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }
        API.post('rest/upload/anexoTurmaDigital/' + this.props.idturma, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let visita = this.state.visita;
            let descricao = listName === 'listaPresenca' ? 
                this.descricaoList.PRESENCE_LIST : listName === 'outrosDocumentos' ?
                this.descricaoList.OTHERS : ''
            let anexo = {
                descricao: descricao,
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                isDocumento : true
            };
            visita[listName].push(anexo);
            this.setState({ visita: visita });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        });
    };

    uploadImagem = (e, listName) => {
        this.props.showLoading();
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file && !(file.name.toLowerCase().includes('jpg') || file.name.toLowerCase().includes('jpeg') || 
            file.name.toLowerCase().includes('png') )) {
            this.props.closeLoading();
            this.props.showToast('Tipo de arquivo não permitido. Anexar apenas JPG, JPEG ou PNG.', 'error');
            return;
        }
        if (file.size > 10000000) {
            this.props.closeLoading();
            this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }
        API.post('rest/upload/anexoTurmaDigital/' + this.props.idturma, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let visita = this.state.visita;
            let descricao =  ''
            let anexo = {
                descricao: descricao,
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
            };
            visita[listName].push(anexo);
            this.setState({ visita: visita });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        });
    };

    removeAnexo = (anexo, listName) => {
        let visita = this.state.visita; 
        let index = visita[listName].findIndex(prop => {
            return (anexo.id && anexo.id === prop.id) || anexo.nomeArquivo === prop.nomeArquivo;
        });
        if (index !== -1) {
            visita[listName].splice(index, 1);
        }
        this.setState({ visita: visita });
    };

    checkDate = () =>{
        let d = new Date();
        let encontroDate = new Date(this.props.encontro.data);
        let proxMes = encontroDate.getMonth() == 11 ? 0 : encontroDate.getMonth() + 1;
        let proxAno = encontroDate.getMonth() == 11 ? encontroDate.getFullYear() + 1 : encontroDate.getFullYear();
        let maxDate = new Date(proxAno,proxMes,8)
        return(!(d.getMonth() == encontroDate.getMonth() || d.getTime() <= maxDate.getTime()));
    }

    formatDateMaxMin = (date) =>{
        let d = new Date(date);
        
        var dd = d.getDate();
        var mm = d.getMonth() + 1; //January is 0!
        var yyyy = d.getFullYear();

        if (dd < 10) {
        dd = '0' + dd;
        }

        if (mm < 10) {
        mm = '0' + mm;
        } 
            
        return(yyyy + '-' + mm + '-' + dd);
    }


    checkMonth = () =>{
        let d = new Date();
        let encontroDate = new Date(this.props.encontro.data);
        return(!(d.getMonth() == encontroDate.getMonth() && encontroDate.getFullYear() == d.getFullYear()));
    }


    render() {
        return (
            <Grid container style={{ justifyContent: 'center' }}>
                <Grid item xs={12} style={{ marginTop: '5%' }}>
                    <Grid container spacing={4} style={{ justifyContent: 'center' }} className={'campos-visita'}>
                        <Grid item xs={4} style={{ display: 'flex' }}>
                            <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/virtual.svg'} width={30} height={30} style={{marginRight: '10px', marginTop:'10px', marginBottom:'10px'}} />
                            </div>
                            <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                                <InputSelect
                                    disabled={this.props.disabled}
                                    suggestions={this.virtualOptions}
                                    itemKey={'id'}
                                    itemLabel={'label'}
                                    label={'Tipo'}
                                    id={'Tipo de Encontro'}
                                    getInput={this.changeVirtual}
                                    initialSelectedItem={{ id: this.state.visita.isVirtual ? 0 : 1}} />
                                </div>
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex' }}>
                            <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/calendar-brasilmais.svg'} width={30} height={30} style={{marginRight: '10px', marginTop:'10px', marginBottom:'10px'}} />
                            </div>
                            <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                                <FormField type={'date'} error={''}
                                    disabled={this.checkMonth() || this.props.disabled}
                                    ref='data' id={'data'}
                                    label={'Data'}
                                    min={this.formatDateMaxMin(new Date().setDate(1))}
                                    max={this.formatDateMaxMin(new Date())}
                                    inputValue={this.state.visita.data ? this.formatData(this.state.visita.data) : ''}
                                    changeValue={this.changeInput}
                                    icon={'calendar.svg'} />
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex' }}>
                            <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/turno-brasilmais.svg'} width={30} height={30} style={{marginRight: '10px', marginTop:'10px', marginBottom:'10px'}} />
                            </div>
                            <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                                <InputSelect
                                    disabled={this.props.disabled}
                                    suggestions={this.turnoOptions}
                                    itemKey={'id'}
                                    label={'Turno'}
                                    itemLabel={'label'}
                                    id={'turno'}
                                    getInput={this.changeInput}
                                    initialSelectedItem={{ id: this.state.visita.turno }} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '5%' }}>
                    <Grid container spacing={4} style={{ justifyContent: 'center' }} className={'campos-visita'}>
                        <Grid item xs={4} style={{ display: 'flex' }}>
                            <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/instalador.svg'} width={30} height={30} style={{marginRight: '10px', marginTop:'10px', marginBottom:'10px'}} />
                            </div>
                            <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                                <InputSelect
                                    disabled={this.props.disabled}
                                    suggestions={this.mentoresOptions}
                                    itemKey={'id'}
                                    itemLabel={'label'}
                                    label={'Mentor(a)/Consultor(a)'}
                                    id={'mentor'}
                                    getInput={this.changeInputMentor}
                                    initialSelectedItem={{ id: this.state.visita.idMentor }} />
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex' }}>
                            <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/timer-brasilmais.svg'} width={30} height={30} style={{marginRight: '10px', marginTop:'10px', marginBottom:'10px'}} />
                            </div>
                            <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                                <FormField type={'number'}  ref='horas' id={'horasApropriadas'}
                                    disabled = {this.checkDate() || this.props.disabled}
                                    inputValue={this.state.visita.horasApropriadas}
                                    label={'Horas Produzidas'}
                                    changeValue={this.changeInput}
                                    min="1" max="100" />
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ display: 'flex' }}>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ marginTop: '5%' }}>
                    <Grid container spacing={4} style={{ justifyContent: 'flex-start' , flexDirection : "column"}} className={'anexos-visitas'}>
                        <Grid container spacing={4} style={{ justifyContent: 'center' }} className={'anexos-visitas'}>
                        { (this.props.etapaTurma === 'Mentoria Prática' || this.props.etapaTurma === 'Mentoria Teórica') &&<Grid item xs={4} xl={3} className={'anexos-visitas-box-top'}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/colab-brasilmais.svg'} width={30} height={30} />
                                <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>Lista de presença</span>
                            </Grid>
                            <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                                <label
                                    className={'lightBlue basic-button control-label'}
                                    id="upload-relatorio"
                                    htmlFor="uploadfile-listapresenca"
                                    style={{opacity: this.props.disabled ? 0.4 : 1,
                                        cursor: this.props.disabled ? 'not-allowed': 'cursor'}}>Anexar
                                </label>
                                <input
                                    className={'file-input listaPresenca'}
                                    type="file"
                                    id="uploadfile-listapresenca"
                                    name="file"
                                    value={undefined}
                                    style={{opacity : this.props.disabled ? 0.5 : 1 }}
                                    disabled={this.props.disabled}
                                    onChange={(e) => { this.uploadAnexo(e, 'listaPresenca') }} />
                            </Grid>
                            <Grid>
                                {this.state.visita.listaPresenca.length > 0 ?
                                    this.state.visita.listaPresenca.map(
                                        (anexo, index) => (
                                            <Chip key={index}
                                                label={<label style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                    <p>{anexo.nomeArquivo}</p></label>}
                                                icon={this.props.disabled ? <></> : <Icon onClick={() => this.removeAnexo(anexo, 'listaPresenca')} className={'close'}>clear</Icon>}
                                            />
                                        )) : <></>}
                            </Grid>
                        </Grid>}
                        {(this.props.etapaTurma === 'Alinhamento' || this.props.etapaTurma === 'Mentoria Prática' || this.props.etapaTurma === 'Consultoria') && <Grid item xs={4} xl={3} className={'anexos-visitas-box-top'}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/photo-register.svg'} width={30} height={30} />
                                <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>Registro fotográfico</span>
                            </Grid>
                            <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                                <label
                                    className={'lightBlue basic-button control-label'}
                                    id="upload-relatorio"
                                    htmlFor="uploadfile-relatorios"
                                    style={{opacity: this.props.disabled ? 0.4 : 1,
                                        cursor: this.props.disabled ? 'not-allowed': 'cursor'}}>Anexar
                                </label>
                                <input
                                    className={'file-input relatorios'}
                                    type="file"
                                    id="uploadfile-relatorios"
                                    name="file"
                                    value={undefined}
                                    style={{opacity : this.props.disabled ? 0.5 : 1 }}
                                    disabled={this.props.disabled}
                                    onChange={(e) => { this.uploadImagem(e, 'imagens') }} />
                            </Grid>
                        </Grid>}                        
                        <Grid item xs={4} xl={3} className={'anexos-visitas-box-top'}>
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/documento-brasilmais.svg'} width={30} height={30} />
                                <Grid item xs={12} style={{ display: 'flex', flexDirection : "column", justifyContent: "flex-start" }}>
                                    <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>
                                    Outros documentos
                                    </span>
                                    <span style={{ paddingLeft: '25px', textAlign: 'left', font: 'italic normal medium 11px/13px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>
                                    *Opcional
                                    </span>
                                </Grid>
                                
                            </Grid>
                            <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                                <label
                                    className={'lightBlue basic-button control-label'}
                                    id="upload-relatorio"
                                    htmlFor="uploadfile-outrosDocumentos"
                                    style={{opacity: this.props.disabled ? 0.4 : 1,
                                        cursor: this.props.disabled ? 'not-allowed': 'cursor'}}>Anexar
                                </label>

                                <input
                                    className={'file-input outrosDocumentos'}
                                    type="file"
                                    id="uploadfile-outrosDocumentos"
                                    name="file"
                                    value={undefined}
                                    style={{opacity : this.props.disabled ? 0.5 : 1 }}
                                    disabled={this.props.disabled}
                                    onChange={(e) => { this.uploadAnexo(e, this.outrosDefault) }} />
                            </Grid>
                            <Grid>
                                {this.state.visita[this.outrosDefault].length > 0 ?
                                    this.state.visita[this.outrosDefault].map(
                                        (anexo, index) => (
                                            <Chip key={index}
                                                label={<label style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                    <p>{anexo.nomeArquivo}</p></label>}
                                                icon={this.props.disabled ? <></> : <Icon onClick={() => this.removeAnexo(anexo, this.outrosDefault)} className={'close'}>clear</Icon>}
                                            />
                                        )) : <></>}
                            </Grid>
                        </Grid>
                        {(this.props.etapaTurma === 'Alinhamento' || this.props.etapaTurma === 'Mentoria Teórica' || this.props.etapaTurma === 'Consultoria') && <Grid item xs={4} xl={3} className={'anexos-visitas-box-top'}>
                            <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                                
                            </Grid>
                        </Grid>}
                        { !(this.props.faseTurma === 'D' && this.props.etapaTurma === 'Consultoria' ) && <Grid item xs={12} xl={9} style={{ display: 'flex', flexDirection : "column", justifyContent: "flex-start", borderTop: 0 }} className={'anexos-visitas-box-bottom'}>
                            <span style={{ paddingLeft: '25px', textAlign: 'left', font: '15px Raleway', letterSpacing: '0px', color: '#D44E54', opacity: 1 }}>
                            <strong>Atenção!</strong> Preencher o "Registro de Encontros" ao final de cada etapa. 
                            </span>
                            <span style={{ paddingLeft: '25px', textAlign: 'left', font: '15px Raleway', letterSpacing: '0px', color: '#D44E54', opacity: 1 }}>
                            Será obrigatório anexar este documento completamente preenchido na Consultoria da Etapa D.
                            </span>
                        </Grid> }                       
                    </Grid>
                        
                    </Grid>
                    
                </Grid>
                <Grid item xs={12} style={{ marginTop: '1%' }}>
                {this.renderAnexos()}
                </Grid>
            </Grid>
        )
    }
}

