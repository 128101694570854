import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBar from '../../../components/graphBar/graphBar';

export default class GraficoAtendimentosPorSegmento extends Component {

	tooltipAtendimento = {
        formatter: function() {
            let tooltip = `<p style="font: bold 13px Raleway; color: #707070">${this.series.name.serieName}</p><br>` +
            `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.series.chart.options.fullCategories[this.point.category - 1]}: ${this.point.y}</p><br>` +
            `<p style="font: 13px Raleway; color: #707070">Total: ${this.point.total}</p>`;
            return tooltip;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    tooltipProdutividade = {
        formatter: function() {
            let yValue = this.point.y;
            return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.series.userOptions.fullCategories[this.point.category - 1]}: ${yValue.toLocaleString('pt-BR')}%</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    legendAtendimento = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function() {
            return '<div style="cursor:auto;display:flex;align-items:center;"><div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '"></div><label style="font-weight:500;font-size:11px">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
    };

    legendProdutividade = { enabled: false };

    plotOptionsAtendimento = {
        series: {
            states: {
                inactive: {
                  opacity: 1
                }
              },
              pointPadding: 0.25,
        },
        column: {
            stacking: 'normal',
            borderColor: null,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };

    plotOptionsProdutividade = {
        column: {
            pointPadding: 0.2,
            borderWidth: 0,
            zones: [{ value: 20, color: '#D44E54' }, { value: 100, color: '#00B1E8' }, { color: '#E9B03D' }],
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };

    plotLinesProdutividade = [
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 20 }, 
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 100 }
    ];

    labelsYProdutividade =  {
        formatter: function() {
            if(this.value <= 20 || (this.value === 100))
                return this.value + "%";
        },
        style: {
            color: '#707070'
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            switch: [
                { 
                    label: 'Produtividade', 
                    title: `Aumento de produtividade industrial por segmento industrial ${this.props.perfil === "UO" ? " na UO" : ""}`, 
                    tooltip: this.tooltipProdutividade, 
                    categories: [], 
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoProdutividade,
                    tickInterval: 20,
                    legend: this.legendProdutividade,
                    height: 250,
                    plotOptions: this.plotOptionsProdutividade,
                    plotLines: this.plotLinesProdutividade,
                    labelsY: this.labelsYProdutividade,
                    gridLineWidthY: 0
                },
                {
                    label: 'Atendimento', 
                    title: `Atendimentos Brasil Mais por segmento industrial ${this.props.perfil === "UO" ? " na UO" : ""}`, 
                    tooltip: this.tooltipAtendimento, 
                    categories: [], 
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoAtendimento,
                    tickInterval: 100,
                    legend: this.legendAtendimento,
                    height: 250,
                    plotOptions: this.plotOptionsAtendimento
                }
            ]
        };
    };

    fillGraficoAtendimento = async (dataInicio, dataTermino) => {
        let series = [];
        let categories = [];
        let fullCategories = [];
        let resumeInfos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/setorAtendimentosProdutividade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${true}`).then(async res => {
            let dataNegociacao = [];
            let dataAceitacao = [];
            let dataConcluido = [];
            let dataExecucao = [];
            if (res.data != null) {
                let aux = res.data;
                let data = aux.resumo;
                if (data.length > 0 && data.length === 3) {
                    resumeInfos = {
                        title: 'Segmentos com mais atendimentos',
                        data: [
                            { title: aux.resumo[0][0], value: aux.resumo[0][1] }, 
                            { title: aux.resumo[1][0], value: aux.resumo[1][1] }, 
                            { title: aux.resumo[2][0], value: aux.resumo[2][1] }
                        ]
                    };
                }               

                await aux.grafico.forEach((segmento, index) => {
                    categories.push(index + 1);
                    fullCategories.push(segmento[0]);
                    dataNegociacao.push(segmento[1]);
                    dataAceitacao.push(segmento[2]);
                    dataConcluido.push(segmento[3]);
                    dataExecucao.push(segmento[4]);
                });
                series = [{
                        name: { legendName: 'Atendimentos em negociação', serieName: 'Negociação'},
                        data: dataNegociacao,
                        color: '#E9B03D'
                    }, {
                        name: { legendName: 'Atendimentos aceitos', serieName: 'Aceitos'},
                        data: dataAceitacao,
                        color: '#B7EE8E'
                    }, {
                        name: { legendName: 'Atendimentos em execução', serieName: 'Execução'},
                        data: dataExecucao,
                        color: '#7DAE59'
                    }, {
                        name: { legendName: 'Atendimentos concluídos', serieName: 'Concluídos'},
                        data: dataConcluido,
                        color: '#00B1E8'
                    }
                ];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, fullCategories, resumeInfos];
    };

    fillGraficoProdutividade = async (dataInicio, dataTermino) => {
        let series = { data: [] };
        let categories = [];
        let resumeInfos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/setorAtendimentosProdutividade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${false}`).then(async res => {
            let auxSeries = [];
            let fullCategories = [];
            if (res.data != null) {
                let aux = res.data;
                let data = aux.resumo;
                if (data.length > 0 && data.length === 3) {
                    resumeInfos = {
                        title: 'Segmentos com maior média de produtividade',
                        data: [
                            { title: aux.resumo[0][0], value: aux.resumo[0][1], suffix: '%' }, 
                            { title: aux.resumo[1][0], value: aux.resumo[1][1], suffix: '%' }, 
                            { title: aux.resumo[2][0], value: aux.resumo[2][1], suffix: '%' }
                        ]
                    };                 
                } 

                await aux.grafico.forEach((segmento, index) => {
                    categories.push(index + 1);
                    fullCategories.push(segmento[0]);
                    auxSeries.push(segmento[1]);
                });
                
                series = [{ data: auxSeries, fullCategories }];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, series[0].fullCategories, resumeInfos];
    };
   
    render() {
        return (
            <Grid container justify={'center'}>      
                <GraphBar switch={this.state.switch} disabledSwitch={false} hasFilter={true} init={'left'} />
            </Grid>
        );
    };
}