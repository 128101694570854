import React, { Component } from 'react'
export default class CellTable extends Component {
    render() {
        return (
            <div >
                <span className={'font-blue ' + (this.props.status ? `${this.props.cell1 }` : '')}>{this.props.cell1 || this.props.cell1 === 0 ? (this.props.round ? this.props.cell1.toFixed(2) : this.props.cell1) : '-'}</span><br />
                <span className={this.props.status ? `${this.props.cell2 }` : ''}>{this.props.cell2 || this.props.cell2 === 0 ? this.props.cell2 : '-'}</span><br />
            </div>
        )
    }
}