import React, { Component } from 'react';
import { Grid, Typography, Tooltip, AppBar, Tab, Tabs } from '@material-ui/core';
import { withStyles} from '@material-ui/core/styles';
import AnexosDefault from './anexosDefault';
import UploadTopButtonsDigital from './uploadTopButtonsDigital';
import Collapse from './../../components/collapse/collapse';
import GenericButton from './../../components/genericButton/genericButton'
import InputMany from '../../components/inputMany/inputMany';
import FormField from '../../components/formField/formField';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import API from '../../comm/API';

import './atividadeCicloPDCADigital.scss';

export default class AtividadeCicloPDCADigital extends Component {

	constructor(props) {
        super(props);
        let disabled = this.props.disabled;
        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let fase = this.props.faseTurma;
        let relatorioA3Digital = this.props.atividade;
        
        if (!relatorioA3Digital.temaA3.historico)  {
            relatorioA3Digital.temaA3.historico = {
                codItem: 1,
                descricao: '',
                id: 2,
                nome:'HISTORICO',
                status: 1,
                anexoItemA3List: []}
        }
        if (!relatorioA3Digital.temaA3.condicoesAtuais)  {
            relatorioA3Digital.temaA3.condicoesAtuais = {
                codItem: 2,
                descricao: '',
                id: 4,
                nome: 'CONDICOES ATUAIS',
                status: 1,
                anexoItemA3List: []}  
        }
        if (!relatorioA3Digital.temaA3.metas)  {
            relatorioA3Digital.temaA3.metas = {
                codItem: 3,
                descricao: '',
                id: 3,
                nome:'METAS',
                status: 1,
                anexoItemA3List:[]}  
        }
        if (!relatorioA3Digital.temaA3.analise)  {
            relatorioA3Digital.temaA3.analise = {
                codItem: 4,
                descricao: '',
                id: 1, 
                nome: 'ANALISE', 
                status: 1,
                anexoItemA3List:[]}
        }        
        
        if (!relatorioA3Digital.temaA3.verificacao)  {
            relatorioA3Digital.temaA3.verificacao = {
                codItem: 5,
                descricao: '',
                id: 5, 
                nome: 'VERIFICACAO',
                status: 1,
                anexoItemA3List:[],
            }   
        }

        if(!relatorioA3Digital.temaA3.contramedidasA3Digital) {
            relatorioA3Digital.temaA3.contramedidasA3Digital = {
                planoDeAcaoList: [],
                anexoContramedidasA3List: [],
                id:1
            }
        }

        if(!relatorioA3Digital.temaA3.acompanhamentoA3Digital) {
            relatorioA3Digital.temaA3.acompanhamentoA3Digital = {
                medidasAcompanhamentoList: [],
                anexoAcompanhamentoA3List: [],
                id:1
            }
        }

        if(!relatorioA3Digital.temaA3.conclusaoA3Digital) {
            relatorioA3Digital.temaA3.conclusaoA3Digital = {
                anexoMelhoriaA3List:[],
                id:1
            }
        }
    
        this.state = { 
            tituloA3: [], 
            contentRender: [], 
            botaoSelecionado: [], 
            anexo: [], 
            relatorioA3Digital : this.props.atividade,
            relatorioA3Key: '',
            contramedidas: [],
            isEdit: false,
            tab: 0,
            
        }
    };

    componentDidMount() {

    }

    uploadFile = (formData) => {
        this.props.showLoading();
    
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let relatorioA3Digital = this.state.relatorioA3Digital;
            let relatorioA3Key = this.state.relatorioA3Key;
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
            };

            switch (this.state.relatorioA3Key) {
                case 'historico':
                    relatorioA3Digital.temaA3.historico.anexoItemA3List.push(anexo);
                    relatorioA3Key = 'historico';
                break;
                case 'condicoesAtuais':
                    relatorioA3Digital.temaA3.condicoesAtuais.anexoItemA3List.push(anexo);
                    relatorioA3Key = 'condicoesAtuais';
                break;
                case 'metas':
                    relatorioA3Digital.temaA3.metas.anexoItemA3List.push(anexo);
                    relatorioA3Key = 'metas';
                break;
                case 'analise':
                    relatorioA3Digital.temaA3.analise.anexoItemA3List.push(anexo);
                    relatorioA3Key = 'analise';
                break;
                case 'verificacao':
                    relatorioA3Digital.temaA3.verificacao.anexoItemA3List.push(anexo);
                    relatorioA3Key = 'verificacao';
                break;
                case 'contramedidasA3Digital':
                    relatorioA3Digital.temaA3.contramedidasA3Digital.anexoContramedidasA3List.push(anexo);
                    relatorioA3Key = 'contramedidasA3Digital';
                    break;
                case 'acompanhamentoA3Digital':
                    relatorioA3Digital.temaA3.acompanhamentoA3Digital.anexoAcompanhamentoA3List.push(anexo);
                    relatorioA3Key = 'acompanhamentoA3Digital';
            } 

            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
            this.setState({ relatorioA3Digital, relatorioA3Key })
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
        });
    };

    changeTab = (value, newValue) => {
    	this.setState({ tab: newValue });
    };

    uploadBefore = (formData) => {
        this.props.showLoading();
    
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let relatorioA3Digital = this.state.relatorioA3Digital;
            let relatorioA3Key = this.state.relatorioA3Key;
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                antes: true
            };
            relatorioA3Digital.temaA3.conclusaoA3Digital.anexoMelhoriaA3List.push(anexo);
            await this.setState({ relatorioA3Digital, relatorioA3Key })
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
        });
    };

    uploadAfter = (formData) => {
        this.props.showLoading();
    
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let relatorioA3Digital = this.state.relatorioA3Digital;
            let relatorioA3Key = this.state.relatorioA3Key;
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                antes: false
            };
            relatorioA3Digital.temaA3.conclusaoA3Digital.anexoMelhoriaA3List.push(anexo);
            await this.setState({ relatorioA3Digital, relatorioA3Key })
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
        });
    };

    removerAnexo = (element) => {
        let relatorioA3Digital = this.state.relatorioA3Digital;
        let anexos = this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key] 
            && this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key][this.getAnexo()] 
            ? this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key][this.getAnexo()] : [];

        let index = -1;
        index = anexos.findIndex(item => ((element.id && item.id === element.id) || (item.descricao === element.descricao && item.nomeArquivo === element.nomeArquivo)));
        if (index > -1) {
            anexos.splice(index, 1);
        }
        relatorioA3Digital.temaA3[this.state.relatorioA3Key].anexoItemA3List = anexos;
        this.setState({ relatorioA3Digital });
    };

    changeInputDesc = (e) => {
        let relatorioA3Digital = this.state.relatorioA3Digital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            relatorioA3Digital.temaA3[this.state.relatorioA3Key].descricao = e.target.value;            
            this.setState({ relatorioA3Digital: relatorioA3Digital, errors: errors });
        }
    };

    changeInputCountermeasure = (e, id, name) => {
        let relatorioA3Digital = this.state.relatorioA3Digital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                relatorioA3Digital.temaA3.contramedidasA3Digital.planoDeAcaoList[id][name] = e.target.value + 'T00:00:00-03:00';
            }
            else{
                relatorioA3Digital.temaA3.contramedidasA3Digital.planoDeAcaoList[id][name] = e.target.value;
            }                        
            this.setState({ relatorioA3Digital: relatorioA3Digital, errors: errors });
        }
    };

    changeInputActionPlain = (e, id, name) => {
        let relatorioA3Digital = this.state.relatorioA3Digital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                relatorioA3Digital.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList[id][name] = e.target.value + 'T00:00:00-03:00';
            }
            else{
                relatorioA3Digital.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList[id][name] = e.target.value;
            }                        
            this.setState({ relatorioA3Digital: relatorioA3Digital, errors: errors });
        }
    };

    createA3 = (e) => {
        let relatorioA3Digital = this.state.relatorioA3Digital;
        let errors = this.state.errors;
        if (e && e[0] ) {
            relatorioA3Digital.temaA3.nome = e[0];            
            this.setState({ relatorioA3Digital: relatorioA3Digital, errors: errors });
        }
    };


    formatData(date) {
        if (date) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    };

    onChangeCollapseNameEdit = (e) => {
        const relatorioA3 = this.state.relatorioA3Digital;

        relatorioA3.temaA3.nome = e.target.value

        this.setState({relatorioA3Digital: relatorioA3})
    }

    renderContentCollapse(){
        const listContentRender = []
        let anexo = this.state.anexo
        let botoesEsquerda = [{id: 'Histórico', anexos: anexo.historico, key: 'historico'}, { id: 'Condições Atuais', anexos: anexo.condicoesAtuais, key: 'condicoesAtuais'}, 
                                { id:'Metas/Objetivos', anexos: anexo.metas, key: 'metas'}, { id: 'Análise', anexos: anexo.analise, key:'analise'}];
        let botoesDireita = [{id:'Contramedidas', key: 'contramedidasA3Digital'}, {id:'Verificação', key: 'verificacao'}];
        let botaoSelecionado = this.state.botaoSelecionado;
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
        })(Tooltip);

        let item = {
            id: 'TituloA3',
            title: 
                <Grid item xs={12}>

                    {this.state.isEdit ? (

                        <input 
                            className={'edit-A3-theme'} 
                            value={this.state.relatorioA3Digital.temaA3.nome} 
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            onChange={this.onChangeCollapseNameEdit} 
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    this.setState({ isEdit: false });
                                };
                            }}
                        />
                    ) : (
                        <Typography style={{ flexBasis: '100%' }} noWrap>{this.state.relatorioA3Digital.temaA3.nome}</Typography>
                    )}
                </Grid>,
            body:
                <Grid container spacing={1}>
                    <Grid item xs={12} className={'collapse-cicloA3-body-container'}>
                        <label className={'collapse-note-title'}>Clique em cada item para inserir dados:</label>
                        <Grid item xs={12} className={'grid-buttons'}>
                            <Grid item xs={6} className={'grid-buttons-divide'}>
                                {botoesEsquerda.map((botao) => {
                                    return (
                                        <GenericButton 
                                            color={botaoSelecionado && botaoSelecionado === botao.id ? 'orange' : 'lightBlue'} 
                                            label={botao.id} 
                                            subClass={'basic-button-cicloPDCA'}
                                            click={() => this.setState({relatorioA3Key: botao.key, botaoSelecionado: botao.id})}
                                        />
                                    )
                                })}
                            </Grid>
                            <Grid item xs={6} className={'grid-buttons-divide'}>
                                {botoesDireita.map((botao) => {
                                    return (
                                        <GenericButton 
                                            color={botaoSelecionado && botaoSelecionado === botao.id ? 'orange' : 'lightBlue'} 
                                            label={botao.id} 
                                            subClass={'basic-button-cicloPDCA-large'}
                                            disabled={(botao.id ==='Verificação' && this.props.etapaAtividade != 'consultoriaC') }
                                            click={() => this.setState({relatorioA3Key: botao.key, botaoSelecionado: botao.id})}
                                        />       
                                    )
                                })}
                                <GenericButton
                                    color={botaoSelecionado && botaoSelecionado === 'Acompanhamento' ? 'orange' : 'lightBlue'} 
                                    label={'Acompanhamento'} 
                                    subClass={'basic-button-cicloPDCA'} 
                                    disabled={(this.props.etapaAtividade != 'consultoriaC') }
                                    click={() => this.setState({relatorioA3Key: 'acompanhamentoA3Digital', botaoSelecionado: 'Acompanhamento'})}
                                />
                        
                            </Grid>
                        </Grid>
                        <Grid item xs={12} className={'grid-button-conclude'}>
                            <GenericButton 
                                color={botaoSelecionado && botaoSelecionado === 'Conclusão' ? 'orange' : 'lightBlue'} 
                                label={'Conclusão'} 
                                subClass={'basic-button-cicloPDCA-conclude'}
                                disabled={(this.props.etapaAtividade != 'consultoriaC') }
                                click={() => this.setState({relatorioA3Key: 'conclusaoA3Digital', botaoSelecionado: 'Conclusão'})}
                            />
                        </Grid>
                        {this.props.etapaAtividade != 'consultoriaC' && (
                            <footer className={"collapse-note-message"}>* Os campos bloqueados serão liberados para preenchimento na Consultoria da Etapa C.</footer>
                        )}
                    </Grid>
                </Grid>,
            trashButton:
                    <ModifiedTooltip title="Editar tema" placement="top" arrow>
                        <div>
                            <GenericButton 
                                color={'transparent'} 
                                click={() => {this.setState({ isEdit: !this.state.isEdit })}} 
                                subClass={'icon-and-button'} 
                                icon={'edit-pen.svg'}
                                disabled={this.props.disabled}
                            />
                        </div>
                    </ModifiedTooltip>
        };
        listContentRender.push(item)
        return listContentRender;
    }

    renderContentCollapseCounterMeasures(contramedida, index){
        const collapseContentList = [];
        const idCollapse = index
        const planoDeAcao = contramedida;
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
        })(Tooltip);
        let collapseContent = {
            id: `counterMeasure ${idCollapse}`,
            title: 
                <Grid container>
                    <Typography style={{ flexBasis: '100%' }} noWrap>{planoDeAcao.nome}</Typography>
                </Grid>,
            body:
                <Grid container spacing={1}>
                    <Grid item xs={12} className={'grid-forms-countermeasure'}>
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'}  style={{ width: '600px'}}>
                                <FormField 
                                    type={'textarea'}
                                    label={'Causa suspeita'}
                                    color={'darkBlue'}
                                    counter={'2000'}
                                    id={'causaSuspeita'}
                                    disabled={this.props.disabled}
                                    inputValue={planoDeAcao.causaSuspeita}
                                    changeValue={(e) => this.changeInputCountermeasure(e, index,'causaSuspeita')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'} style={{ width: '670px', paddingLeft:'5%'}}>
                                <FormField 
                                    type={'textarea'}
                                    label={'Item ação'}
                                    color={'darkBlue'}
                                    counter={'2000'}
                                    id={'itemAcao'}
                                    disabled={this.props.disabled}
                                    inputValue={planoDeAcao.itemAcao}
                                    changeValue={(e) => this.changeInputCountermeasure(e, index,'itemAcao')}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={'grid-forms-responsible-deadline'}>
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'} style={{ width: '400px'}}>
                                <FormField 
                                    type={'text'}
                                    label={'Responsável pela ação'}
                                    color={'darkBlue'}
                                    maxLength={'200'}
                                    placeholder={'Nome'}
                                    id={'responsavelAcao'}
                                    disabled={this.props.disabled}
                                    inputValue={planoDeAcao.responsavelAcao}
                                    changeValue={(e) => this.changeInputCountermeasure(e, index,'responsavelAcao')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'} style={{ width: '313px', paddingLeft: '5%'}}>
                                <FormField 
                                    type={'date'} 
                                    error={''}
                                    label={'Prazo'}
                                    ref='data' 
                                    id={'data'}
                                    disabled={this.props.disabled}

                                    name = {'prazo'}
                                    inputValue={planoDeAcao.prazo ? this.formatData(planoDeAcao.prazo) : ''}
                                    changeValue={(e) => this.changeInputCountermeasure(e, index, 'prazo')}
                                    icon={'calendar.svg'} 
                                />    
                            </div>
                        </Grid> 
                    </Grid>  
                    <Grid item xs={12}>
                        <div className={'field-label'}>
                            <FormField 
                                type={'textarea'}
                                label={
                                    <>
                                      <b style={{ fontWeight: 'normal' }}>Achados</b> 
                                      {this.props.etapaAtividade === 'praticaC' &&
                                      <i style={{ color: 'darkGrey', fontSize: 11}}> * O preenchimento deste campo não é obrigatório nesta etapa mas será obrigatório na próxima (Consultoria - Etapa C)</i>
                                      }
                                    </>
                                }
                                color={'darkBlue'}
                                counter={'2000'}
                                id={index}
                                disabled={this.props.disabled}
                                name={'achados'}
                                inputValue={planoDeAcao.achados}
                                changeValue={(e) => this.changeInputCountermeasure(e, index,'achados')}
                            />
                        </div>
                    </Grid>
                </Grid>,
            trashButton:
                <ModifiedTooltip title="Excluir contramedida" placement="top" arrow>
                    <div>
                        <GenericButton 
                            color={'transparent'} 
                            click={() => {this.deleteCountermeasures(idCollapse)}} 
                            subClass={'icon-and-button'} 
                            icon={'trash.svg'}
                            disabled={this.props.disabled}
                        />
                    </div>
                </ModifiedTooltip>
        };

        collapseContentList.push(collapseContent);
        
        const collapse = 
            <div className={'collapse-countermeasure-and-action-plain'}>
                <Collapse
                    id={idCollapse}
                    content={collapseContentList}
                    isExpanded={true}
                    hideButton={false}
                />
            </div>
            

        return(collapse);
    }

    addCountermeasure(){
        let relatorioA3Digital = this.state.relatorioA3Digital;
        let contramedida = {
            achados: '',
            causaSuspeita: '',
            id: null,
            itemAcao: '',
            nome: `Contramedida  #${relatorioA3Digital.temaA3.contramedidasA3Digital.planoDeAcaoList.length + 1}`,
            prazo: '',
            responsavelAcao: ''
        }
        relatorioA3Digital.temaA3.contramedidasA3Digital.planoDeAcaoList.push(contramedida);
        this.setState({ relatorioA3Digital: relatorioA3Digital });
        
    }

    deleteCountermeasures(idCollapse){
        let relatorioA3Digital = this.state.relatorioA3Digital;
        const countermeasuresArray =  [...this.state.relatorioA3Digital.temaA3.contramedidasA3Digital.planoDeAcaoList];
        countermeasuresArray.splice(idCollapse, 1);
        for(let i = 0;i < countermeasuresArray.length;i++){
            countermeasuresArray[i].nome = `Contramedida  #${i + 1}`
        }
        relatorioA3Digital.temaA3.contramedidasA3Digital.planoDeAcaoList = countermeasuresArray;
        this.setState({ relatorioA3Digital: relatorioA3Digital });
        
    }

    changeInput = (e) => {
      
    };

    renderActionPlain(acao, index){
        const collapseContentList = [];
        const idCollapse = index
        const acompanhamento = acao;
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
        })(Tooltip);
        let collapseContent = {
            id: `actionPlain ${idCollapse}`,
            title: 
                <Grid container>
                    <Typography style={{ flexBasis: '100%' }} noWrap>{acompanhamento.nome}</Typography>
                </Grid>,
            body:
                <Grid container spacing={1}>
                    <Grid item xs={12} className={'grid-forms-countermeasure'}>
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'}  style={{width: '650px', paddingLeft: '22%'}}>
                                <FormField 
                                    type={'textarea'}
                                    label={'Item verificação'}
                                    color={'darkBlue'}
                                    counter={'2000'}
                                    id={'item'}
                                    disabled={this.props.disabled}

                                    inputValue={acompanhamento.item}
                                    changeValue={(e) => this.changeInputActionPlain(e, index,'item')}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'} style={{width: '500px', paddingLeft: '14%'}}>
                                <FormField 
                                    type={'text'}
                                    label={'Responsável pela ação'}
                                    placeholder={'Nome'}
                                    color={'darkBlue'}
                                    maxLength={'200'}
                                    id={'responsavel'}
                                    disabled={this.props.disabled}

                                    inputValue={acompanhamento.responsavel}
                                    changeValue={(e) => this.changeInputActionPlain(e, index,'responsavel')}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} className={'grid-forms-responsible-deadline'}>
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'} style={{ width: '500px', paddingLeft: '22%'}}>
                                <FormField 
                                    type={'date'} 
                                    error={''}
                                    label={'Se a ação NÃO for permanente, inserir prazo:'}
                                    ref='data' 
                                    id={'data'}
                                    disabled={this.props.disabled}

                                    name = {'prazo'}
                                    inputValue={acompanhamento.prazo ? this.formatData(acompanhamento.prazo) : ''}
                                    changeValue={(e) => this.changeInputActionPlain(e, index, 'prazo')}
                                    icon={'calendar.svg'} 
                                />    
                            </div>
                        </Grid> 
                        <Grid item xs={6} className={'grid-forms-divide'}>
                            <div className={'field-label'} style={{paddingLeft: '14%'}}>
                                <label>Status</label>
                                <Grid className={'action-status'}>

                                        <RadioGroup disabled={this.props.disabled} aria-label="position" row className={'radio-group'} value={acompanhamento.status} onChange={(e) => this.changeInputActionPlain(e, index, 'status')}>

                                            <FormControlLabel className={'radio'} value={'Em progresso'} control={<Radio />} label={'Em progresso'} disabled={this.props.disabled}/>
                                            <FormControlLabel className={'radio'} value={'Concluído'} control={<Radio />} label={'Concluído'} disabled={this.props.disabled}/>
                                        </RadioGroup>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>  
                </Grid>,
            trashButton:
                <ModifiedTooltip title="Excluir ação" placement="top" arrow>
                    <div>
                        <GenericButton 
                            color={'transparent'} 
                            click={() => {this.deleteAction(idCollapse)}} 
                            subClass={'icon-and-button'} 
                            icon={'trash.svg'}
                            disabled={this.props.disabled}

                        />
                    </div>
                </ModifiedTooltip>
        };

        collapseContentList.push(collapseContent);
        
        const collapse = 
            <div className={'collapse-countermeasure-and-action-plain'}>
                <Collapse
                    id={idCollapse}
                    content={collapseContentList}
                    isExpanded={true}
                    hideButton={false}
                />
            </div>
            
        return(collapse);
    }


    addAction(){
        let relatorioA3Digital = this.state.relatorioA3Digital;
        let acao = {
            item: '',
            id: null,
            responsavel : '',
            prazo: '',
            status: 'Em progresso',
            nome: `Ação  #${relatorioA3Digital.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList.length + 1}`,
        
        }
        relatorioA3Digital.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList.push(acao);
        this.setState({ relatorioA3Digital: relatorioA3Digital });
    }

    deleteAction(idCollapse){
        let relatorioA3Digital = this.state.relatorioA3Digital;
        const acaoArray =  [...this.state.relatorioA3Digital.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList];
        acaoArray.splice(idCollapse, 1);
        for(let i = 0;i < acaoArray.length;i++){
            acaoArray[i].nome = `Ação  #${i + 1}`
        }
        relatorioA3Digital.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList = acaoArray;
        this.setState({ relatorioA3Digital: relatorioA3Digital });
    }

    closeButtons = () => {
        this.setState({relatorioA3Key: '', botaoSelecionado: []});
    }

    getAnexo = () => {

        switch(this.state.relatorioA3Key) {
            case 'contramedidasA3Digital':
                return 'anexoContramedidasA3List';
            case 'acompanhamentoA3Digital':
                return 'anexoAcompanhamentoA3List';
            case 'conclusaoA3Digital':
                return 'anexoMelhoriaA3List';
            default: 
                return 'anexoItemA3List';
        }
    }

    renderContentGridButton() {
        let content;
        let content2;
        let content3;
        const contramedidas = this.state.relatorioA3Digital.temaA3.contramedidasA3Digital.planoDeAcaoList;
        const acao = this.state.relatorioA3Digital.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList;
        let label = ''

        switch (this.state.relatorioA3Key) {
            // Um case para cada botão
            
            case 'historico':
                label = 'Descreva os problemas encontrados:';
                break;
            case 'condicoesAtuais':
                label = 'Descreva as condições atuais encontradas:';
                break;
            case 'metas':
                label = 'Descreva as metas e objetivos definidos:';
                break;
            case 'analise':
                label = 'Descreva o que está gerando o problema em análise:';
                break;
            case 'verificacao':
                label = 'Descreva o experimento de verificação da proposta:';
                break;
            case 'contramedidasA3Digital': 
        
                content = 
                <>
                   <Grid container style={{ margin: '2% 0'}}>
                        <label className={'label-btn-add-collapse'}>Insira uma contramedida</label>

                        <GenericButton  
                            color={'lightBlue'} 
                            disabled={this.props.disabled}
                            subClass={'icon-button'}
                            icon={process.env.PUBLIC_URL + 'plus.svg'}
                            click={() => this.addCountermeasure()}
                        />  

                    </Grid>
                    {contramedidas.map((contramedida, index) => <div>{this.renderContentCollapseCounterMeasures(contramedida, index)}</div>)}
                </>
                break;
            case 'acompanhamentoA3Digital':

                content2 = 
                <>
                   <Grid container style={{ margin: '2% 0'}}>
                        <label className={'label-btn-add-collapse'}>Insira uma ação de acompanhamento</label>
                        <GenericButton 
                            color={'lightBlue'} 
                            disabled={this.props.disabled}
                            subClass={'icon-button'}
                            icon={process.env.PUBLIC_URL + 'plus.svg'}
                            click={() => this.addAction()}
                        />  
                    </Grid>
                    {acao.map((acao, index) => <div>{this.renderActionPlain(acao, index)}</div>)}
                </>
                break;
            case 'conclusaoA3Digital':
                content3 =
                <>
                    <Grid container justify={'flex-start'} direction={'row'} spacing={4}>
                        { this.state.tab === 0 && 
                            <Grid item xs={12}>
                                <Grid container direction={'row'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-start" }}>
                                        <label className={'label-selected-item'}>{this.state.botaoSelecionado}</label>
                                        <label style={{ paddingTop: '2px', fontSize: '14px' }}> Preencha os campos abaixo incluindo imagens das melhorias (antes e depois):</label>
                                    </Grid>
                                    {!this.props.disabled ? <UploadTopButtonsDigital
                                        direction={'row'}
                                        uploadFile={this.uploadBefore}
                                        showToast={this.props.showToast} 
                                        type="file"
                                        renderOnBody
                                    /> : <Grid item xs={6}></Grid>}
                                    <AppBar position='static'>
                                        <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto' style={{marginTop: '2%'}}>
                                            <Tab key={'Tab0'} value={0} label={'Antes'}></Tab>
                                            <Tab key={'Tab1'} value={1} label={'Depois'}></Tab>
                                        </Tabs>
                                    </AppBar>
                                </Grid>
                                <Grid item xs={12}>
                                    <AnexosDefault
                                        title='Relatório A3: Ciclo PDCA'
                                        anexos={this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key] 
                                            ? this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key][this.getAnexo()].filter(anx => anx.antes) : []}
                                        fase={this.props.fase}
                                        turma={this.props.turma}
                                        darkBorder={'none'}
                                        disableForm={this.props.disabled}
                                        showEmptyEvidencias={ true }
                                        addInfoAnexo={this.props.addInfoAnexo}
                                        removerAnexo={(element) => this.removerAnexo(element)}
                                        msg={'Você ainda não anexou evidências.'} 
                                    />
                                </Grid>
                            </Grid> 
                        }
                        { this.state.tab === 1 &&
                            <Grid item xs={12}>
                                <Grid container direction={'row'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-start" }}>
                                        <label className={'label-selected-item'}>{this.state.botaoSelecionado}</label>
                                        <label style={{ paddingTop: '2px', fontSize: '14px' }}> Preencha os campos abaixo incluindo imagens das melhorias (antes e depois):</label>
                                    </Grid>
                                    {!this.props.disabled ? <UploadTopButtonsDigital
                                        direction={'row'}
                                        uploadFile={this.uploadAfter}
                                        showToast={this.props.showToast} 
                                        type="file"
                                        renderOnBody
                                    /> : <Grid item xs={6}></Grid>}
                                    <AppBar position='static'>
                                        <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto' style={{marginTop: '2%'}}>
                                            <Tab key={'Tab0'} value={0} label={'Antes'}></Tab>
                                            <Tab key={'Tab1'} value={1} label={'Depois'}></Tab>
                                        </Tabs>
                                    </AppBar>
                                    
                                </Grid>
                                <Grid item xs={12}>
                                    <AnexosDefault
                                        title='Relatório A3: Ciclo PDCA'
                                        anexos={ this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key] 
                                            ? this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key][this.getAnexo()].filter(anx => !anx.antes) : [] }
                                        fase={this.props.fase}
                                        turma={this.props.turma}
                                        darkBorder={'none'}
                                        disableForm={this.props.disabled}
                                        showEmptyEvidencias={ true }
                                        addInfoAnexo={this.props.addInfoAnexo}
                                        removerAnexo={(element) => this.removerAnexo(element)}
                                        msg={'Você ainda não anexou evidências.'} 
                                    />
                                </Grid> 
                            </Grid>}
                    </Grid>
                </>
        }

        return (
            
            <Grid className={'atividade-cicloPDCA'}>
                <Grid container justify={'center'} direction={"row"} spacing={1}>
                    {this.state.relatorioA3Key.length > 0 && this.state.relatorioA3Key !== 'conclusaoA3Digital' &&
                        <Grid container justify={'center'}>
                            <Grid item xs={12}>
                                <Grid container direction={'row'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Grid item xs={6} style={{ display: 'flex', flexDirection: "column", justifyContent: "flex-start" }}>
                                        <label className={'label-selected-item'}>{this.state.botaoSelecionado}</label>
                                        <label style={{ paddingTop: '2px', fontSize: '14px' }}> Preencha o campo abaixo e anexe arquivos/imagens:</label>
                                    </Grid>
                                    {!this.props.disabled ? <UploadTopButtonsDigital
                                        direction={'row'}
                                        uploadFile={this.uploadFile}
                                        showToast={this.props.showToast} 
                                        darkBorder={ false }
                                        type="file"
                                        disabled={this.props.disabled}
                                        renderOnBody
                                    /> : <Grid item xs={6}></Grid>}
                                </Grid>
                                    <AnexosDefault
                                        title='Relatório A3: Ciclo PDCA'
                                        showEmptyEvidencias={ false }
                                        addInfoAnexo={this.props.addInfoAnexo}
                                        darkBorder = {false}
                                        turma={this.props.turma}
                                        fase={ this.props.fase }
                                        disableForm={this.props.disabled}
                                        removerAnexo={(element) => this.removerAnexo(element)}
                                        anexos={this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key] 
                                            ? this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key][this.getAnexo()] : []}
                                    />
                            </Grid>
                            {content}
                            {content2}
                            {this.state.relatorioA3Key !== 'contramedidasA3Digital' && 
                                this.state.relatorioA3Key !== 'acompanhamentoA3Digital' && 
                                <Grid item xs={12} style={{ margin: '2% 0'}}>
                                    <div className='field-label'>
                                        <FormField 
                                            type={'textarea'}
                                            label={label}
                                            color={'darkBlue'}
                                            counter={'2000'}
                                            disabled={this.props.disabled}
                                            placeholder={'Descreva brevemente'}
                                            inputValue={this.state.relatorioA3Digital.temaA3[this.state.relatorioA3Key].descricao}
                                            changeValue={this.changeInputDesc} 
                                        />

                                    </div>
                                </Grid>
                            }
                        </Grid>
                    }
                    {content3}
            
                </Grid>
            </Grid>
        )
    }

    renderBody = () => {
        return (
            <>
                <Grid container justify={'center'} >
                    <Grid item xs={8} style={{ marginTop: '2%', paddingLeft: '1%' }}>
                        <InputMany
                            getInput={this.createA3}
                            id ={'nome'}
                            placeholder={'Informe o tema do A3'}
                            noChipLabel={true}
                            disabled={((this.state.relatorioA3Digital.temaA3.nome) || (!this.state.relatorioA3Digital.temaA3.nome && this.state.isEdit))}
                            key={'titulo'} />
                    </Grid>
                    <div className={'collapse-title-temaA3'}>
                        {((this.state.relatorioA3Digital.temaA3.nome != '') || (this.state.relatorioA3Digital.temaA3.nome === '' && this.state.isEdit)) && (
                            <Collapse
                                id={'TituloA3'}
                                content={this.renderContentCollapse()}
                                isExpanded={true}
                                hideButton={false}
                                clickFunction={true}
                                onClick={this.closeButtons}
                                disabled={this.props.disabled} 
                            />
                        )}
                    </div>
                </Grid>
                {this.state.relatorioA3Key && this.props.etapaAtividade === 'praticaC' && (
                <Grid container justify={'center'} style={{ borderTop: '1px solid #E1E3E6'}}>
                    <Grid item xs={10} style={{ margin: '2% 0'}}>
                        {this.state.relatorioA3Key && (
                            this.renderContentGridButton()
                        )}
                    </Grid>
                </Grid>
                )}
                 {this.state.relatorioA3Key && this.props.etapaAtividade === 'consultoriaC' && (
                <Grid container justify={'center'} style={{ borderTop: '1px solid #E1E3E6', marginTop: '2%' }}>
                    <Grid item xs={10} style={{ margin: '2% 0'}}>
                        {this.state.relatorioA3Key && (
                            this.renderContentGridButton()
                        )}
                    </Grid>
                </Grid>
                )}

            </>
        );
            
    };

    render() {
        return (
            <Grid container className={'atividade-cicloPDCA'} style={{ margin: '1% 0'}}>
                {this.renderBody()}
            </Grid>
        )
    };
}