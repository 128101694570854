import React, { Component } from 'react';
import Page from './../../components/page/page';
import { Grid } from '@material-ui/core';
import "./noAccess.scss";

export default class NoAccess extends Component {

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };


    render() {
        return (
      <Page
        icon={"alert.svg"}
        label={"Sem acesso"}
        getFunctions={this.getFunctions}
        usuarioConectado={this.props.location.state.usuarioConectado}
      >
        <Grid id={"no-access"} container>
          <Grid id={"div-bigger"}>
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/no-access.svg"}
              alt={""}
            />
            <Grid className={"div-smaller"}>
              <p className={"title"}>Ops, você não tem acesso à essa tela</p>
              <p className={"description"}>
                Seu perfil não está autorizado a visualizar os dados dessa tela.
                Para maiores informações, entre em contato com o administrador
                do seu regional.
              </p>
            </Grid>
          </Grid>
        </Grid>
            </Page>
        );
    };
}