import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import GraphBar from '../../../components/graphBar/graphBar';
import CollapseGraph from '../../../components/collapseGraph/collapseGraph';

export default class GraficoConsultoresHomologados extends Component {

    estados = { 
        'AC': 'Acre', 'AL': 'Alagoas', 'AM': 'Amazonas', 'AP': 'Amapá', 'BA': 'Bahia', 'CE': 'Ceará',
        'DF': 'Distrito Federal', 'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão', 'MG': 'Minas Gerais',
        'MS': 'Mato Grosso do Sul', 'MT': 'Mato Grosso', 'PA': 'Pará', 'PB': 'Paraíba', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'PR': 'Paraná', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte', 'RO': 'Rondônia', 
        'RR': 'Roraima', 'RS': 'Rio Grande do Sul', 'SC': 'Santa Catarina', 'SE': 'Sergipe', 'SP': 'São Paulo', 'TO': 'Tocantins'
    };
 

    constructor(props) {
        super(props);
        this.state = {
            dados : {digital : undefined, lean: undefined, ambos: undefined},
			total: {digital : 0, lean: 0, ambos: 0},
            
        };
        this.carregarDados();
    };

    carregarDados = async () => {
		await this.fillGraficoConsultores();
	}

    fillGraficoConsultores = async () => {
        
        let digital = [];
		let lean = [];
		let ambos = [];

        await API.get(`rest/sistema/DashboardBrasilMais/mentoresPorEstado`).then(async res => {
            let dataD = [];
			let dataL = [];
			let dataLD = [];
			let categories = Object.keys(this.estados);
		if (res.data != null ) {
			await categories.forEach(estado => {
				dataD.push(Number(res.data.digital[estado]));
				dataL.push(Number(res.data.lean[estado]));
				dataLD.push(Number(res.data.ambos[estado]));
			});
			digital.push({
				name: '',
				data: dataD,
				color: '#077CDC',
			})
			;
			lean.push({
				name: '',
				data: dataL,
				color: '#077CDC',
			})
			;
			ambos.push({
				name: '',
				data: dataLD,
				color: '#077CDC',
			})
			;
                let totalL = dataL.reduce((partialSum, a) => partialSum + a, 0);
				let totalD = dataD.reduce((partialSum, a) => partialSum + a, 0);
				let totalLD = dataLD.reduce((partialSum, a) => partialSum + a, 0);
                this.setState({ dados : {digital: digital, lean:lean, ambos: ambos}, total: {digital: totalD, lean:totalL, ambos: totalLD}});
            }
        }).catch(error => {
            console.log(error);
        });
    };

    create = (fase) => {

        let categories = Object.keys(this.estados);

		let series = [];

		let subtitle
		let total = 0


		if(fase == "L"){
			series = this.state.dados.lean;
			total = this.state.total.lean;
			subtitle = {
				enabled:true,
				text: `Fase 1: Mentoria Lean`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		else if(fase == "D"){
			series = this.state.dados.digital;
			total = this.state.total.digital;
			subtitle = {
				enabled:true,
				text: `Fase 2: Mentoria Digital`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		else{
			series = this.state.dados.ambos;
			total = this.state.total.ambos;
			subtitle = {
				enabled:true,
				text: `Brasil Mais`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		

		return {
			chart: {
                type: 'column',
                style: {
                    fontFamily: 'Raleway'
                },
				height:  420 ,
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				marginTop : 100
			},
			title: {
				enabled:true,
				text: `CONSULTORES HOMOLOGADOS E ATIVOS`,
				style: {
					fontFamily: 'Raleway',
					font: "20px",
					fontWeight: 600,

					'color': '#077CDC',
				},
                margin: 80
				
			},
            fullCategories: this.estados,
            xAxis: {
                lineColor: '#707070',
                lineWidth: 0.5,
                categories: categories,
                labels: {
                    style: {
                        color: '#707070'
                    }
                },
            },
			subtitle: subtitle,
			tooltip: {
				headerFormat: '<b>Consultores</b><br/>',
                pointFormat: '{point.category}: {point.y}<br/>Nº Total de Mentores:' + total
					},
			yAxis: {
                lineColor: '#707070',
                lineWidth: 0,
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    style: {
                        color: '#707070'
                    }
                }
            },
			plotOptions: {
				column: {
					dataLabels: {
						enabled: true,
						style: {
							font: 'Raleway',
							fontSize: '15px !important',
							color: '#707070'
						}
					},
					states: {
	                    hover: {
	                        enabled: false
	                    },
						inactive: {
							opacity: 1
						}
					},
					showInLegend: false
				},				
			},
			series: series,
			credits: {
				enabled: false
			},
			exporting: {
				width: 2000,
				menuItemDefinitions: {
					// Custom definition
					printChart: {
						text: 'Imprimir gráfico'
					},
					downloadPNG:{
						text: 'Baixar como PNG'
					},
					downloadJPEG:{
						text: 'Baixar como JPEG'
					},
					downloadSVG:{
						text: 'Baixar como SVG'
					},
					viewFullscreen:{
						text: 'Ver em tela cheia'
					},
					downloadCSV:{
						text: 'Baixar tabela de dados'
					}
				},
                subtitle :{
                    style: {
                        'margin-top': '30',
                    }				
                },
                chart: {
                    marginTop: '150'
                },
                subtitle:{
                    y: 0
                },
				fallbackToExportServer: false,
				buttons: {
					contextButton: {
						menuItems:[ "printChart", "viewFullscreen", "downloadCSV", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"]
					}
				}
			}
		};
	}

    render() {

		let contentPanel ={
			id: 'ConsultoresHomologadosInfo',
			body :	
			<div className='dashboard-extra-info'>
				<label>Nº total de consultores: <span className='blue-value'> 110</span> </label>
				<label>Consultores homologados: <span className='blue-value'> 26 </span> </label>
				<label>Consultores ativos: <span className='blue-value'> 421</span> </label>
			</div>
		}

		let GraphL =  this.create('L');
		let GraphD =  this.create('D');
		let GraphLD =  this.create('LD');

        return (
            <Grid container justify={'center'} className={'dashboard-porte-setor border-cinza'}>
               <Grid item xs={12}>
					<div style={{ margin: '40px' }}>
						{this.props.fase == 'L' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphL} />}
						{this.props.fase == 'D' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphD} />}
						{this.props.fase == 'LD' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphLD} />}
					</div>
				</Grid>
            </Grid>
        );
    };

}