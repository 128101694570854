import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

export default class UploadTopButtons extends Component {

    uploadArquivo = async (element) => {
        let file = element && element.target && element.target.files ? element.target.files[0] : null;
        if (!file) {
            return;
        } else if (file && !(file.name.toLowerCase().includes('jpg') || file.name.toLowerCase().includes('jpeg') || 
            file.name.toLowerCase().includes('png') || file.name.toLowerCase().includes('mp4'))) {
            this.props.showToast('Tipo de arquivo não permitido. Anexar apenas JPG, JPEG, PNG ou MP4.', 'error');
            return;
        }
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file.size > 10000000) {
            this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }
        
        this.props.uploadFile(formData);
        element.target.value = null;
    };

    render() {
        return(
            <Grid item md={6}>
                <Grid container direction={'row'} justify={'flex-end'}>
                    <Grid item xs={8} md={5} xl={3} style={{ display: 'inline-flex', marginTop: '7px'}}>
                        <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/cam-brasilmais.svg'} width={30} height={30} />
                        <span id={'span-upload-atividade'}>Arquivo ou imagem</span>
                    </Grid>
                    <Grid item xs={4} md={3} xl={2}>
                        <label
                            className={'lightBlue basic-button control-label'}
                            id="upload-atividade"
                            htmlFor="uploadfile-atividades">
                            Anexar
                        </label>
                        <input
                            className={'file-input atividades'}
                            type="file"
                            id="uploadfile-atividades"
                            name="file"
                            value={undefined}
                            onChange={(element) => { this.uploadArquivo(element) }} />
                    </Grid>
                </Grid>
            </Grid>
        )
    }

}
    
    