import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import API from '../../comm/API';
import Table from '../../components/table/table';
import Page from '../../components/page/page';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import GenericButton from '../../components/genericButton/genericButton';
import './reviewTurmaMentoriaDigital.scss';

const titlesTab = [
    { id: 'turma', canOrder: false, label: 'Turma' },
    { id: 'dataDeInicio', canOrder: false, label: 'Data de início' },
    { id: 'dataDeTermino', canOrder: true, label: 'Data de término' },
    { id: 'label', canOrder: true, label: '' },
]

const titleEtapas = [
    { id: 'etapa', canOrder: false, label: 'Etapa' },
    { id: 'dataDeInicio', canOrder: false, label: 'Data de início' },
    { id: 'dataDeTermino', canOrder: true, label: 'Data de término' },
    { id: 'mentor', canOrder: true, label: 'Mentor/Consultor' },
]

export default function ReviewTurmaMentoriaDigital(props) {
    
    let showLoading = () => {};
    let closeLoading = () => {};
    let showToast = () => {};
    const { state: locationState } = useLocation();
    const [ lineTab, setLineTab ] = useState({});
    const [ linesEtapas, setLinesEtapa ] = useState([]);

    const createEtapaLines = (etapaData, mentoresNames) => {

        const etapaTeoricaB = etapaData.etapaTeoricaList.find(etapaTeorica => etapaTeorica.etapa === 3);
        const etapaTeoricaC = etapaData.etapaTeoricaList.find(etapaTeorica => etapaTeorica.etapa === 6);
        const etapaTeoricaD = etapaData.etapaTeoricaList.find(etapaTeorica => etapaTeorica.etapa === 9);

        const {
            etapaAlinhamentoB,
            etapaPraticaB,
            etapaConsultoriaB,
            etapaPraticaC,
            etapaConsultoriaC,
            etapaPraticaD,
            etapaConsultoriaD
        } = etapaData;

        const table = [
            etapaAlinhamentoB && {
                etapa: 'B - Alinhamento',
                dataDeInicio: formatDataTimestamp(etapaAlinhamentoB.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaAlinhamentoB.dataTermino, false),
                mentor: mentoresNames.etapaAlinhamentoB.join(', ')
            },
            etapaTeoricaB && {
                etapa: 'B - Mentoria Teórica',
                dataDeInicio: formatDataTimestamp(etapaTeoricaB.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaTeoricaB.dataTermino, false),
                mentor: mentoresNames.etapaTeoricaB.join(', ')
            },
            etapaPraticaB && {
                etapa: 'B - Mentoria Prática',
                dataDeInicio: formatDataTimestamp(etapaPraticaB.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaPraticaB.dataTermino, false),
                mentor: mentoresNames.etapaPraticaB.join(', ')
            },
            etapaConsultoriaB && {
                etapa: 'B - Consultoria',
                dataDeInicio: formatDataTimestamp(etapaConsultoriaB.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaConsultoriaB.dataTermino, false),
                mentor: mentoresNames.etapaConsultoriaB.join(', ')
            },
            etapaTeoricaC && {
                etapa: 'C - Mentoria Teórica',
                dataDeInicio: formatDataTimestamp(etapaTeoricaC.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaTeoricaC.dataTermino, false),
                mentor: mentoresNames.etapaTeoricaC.join(', ')
            },
            etapaPraticaC && {
                etapa: 'C - Mentoria Prática',
                dataDeInicio: formatDataTimestamp(etapaPraticaC.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaPraticaC.dataTermino, false),
                mentor: mentoresNames.etapaPraticaC.join(', ')
            },
            etapaConsultoriaC &&{
                etapa: 'C - Consultoria',
                dataDeInicio: formatDataTimestamp(etapaConsultoriaC.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaConsultoriaC.dataTermino, false),
                mentor: mentoresNames.etapaConsultoriaC.join(', ')
            },
            etapaTeoricaD && {
                etapa: 'D - Mentoria Teórica',
                dataDeInicio: formatDataTimestamp(etapaTeoricaD.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaTeoricaD.dataTermino, false),
                mentor: mentoresNames.etapaTeoricaD.join(', ')
            },
            etapaPraticaD && {
                etapa: 'D - Mentoria Prática',
                dataDeInicio: formatDataTimestamp(etapaPraticaD.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaPraticaD.dataTermino, false),
                mentor: mentoresNames.etapaPraticaD.join(', ')
            },
            etapaConsultoriaD && {
                etapa: 'D - Consultoria',
                dataDeInicio: formatDataTimestamp(etapaConsultoriaD.dataInicio, false),
                dataDeTermino: formatDataTimestamp(etapaConsultoriaD.dataTermino, false),
                mentor: mentoresNames.etapaConsultoriaD.join(', ')
            },

        ]

        return table.filter(item => item !== undefined);
    }

    const formatDataTimestamp = (timestamp, hasHour) => {
        var data = new Date(timestamp).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
        let dataSplit = data.split(' ');
        let dataList = dataSplit[0].split('/');
        let hour = '';
        if (hasHour) hour = ' às ' + dataSplit[1].split(':').slice(0, 2).join(':')
        return dataList[0] + '/' + dataList[1] + '/' + dataList[2] + hour;
    }
    
    const getFunctions = (showToastParam, showLoadingParam, closeLoadingParam) => {

        if (showLoadingParam !== null) {
            showLoading = showLoadingParam;
        };
        
        if (closeLoadingParam !== null) {
            closeLoading = closeLoadingParam;
        };

        if (showToastParam !== null) {
            showToast = showToastParam;
        }
    };
    
    const handleGetReview = async (idTurma) => {
        try {
            showLoading();

            const { data } = await API.get(`rest/gestao/TurmaMentoriaDigital/${idTurma}`);

            const { data: dataMentores } = await API.get(`rest/gestao/TurmaMentoriaDigital/mentoresNomes/${idTurma}`);

            closeLoading();
            
            const turmaData = {
                turma: data.nome,
                dataDeInicio: formatDataTimestamp(data.dataInicio, false),
                dataDeTermino: formatDataTimestamp(data.dataTermino, false),
            }

            
            setLineTab(turmaData);
            setLinesEtapa(createEtapaLines(data, dataMentores));

        } catch (error) {
            console.log(error)
            closeLoading();
            showToast('Error ao realizar GET da revisão. Favor, entrar em contato com o suporte.', 'error');
        } 
    }

   
    
    useEffect(() => {
        closeLoading();

        const idTurma = window.location.hash.split('/').slice(-1)[0];
        handleGetReview(idTurma || locationState.id);
    }, [])

    return (
        <Page icon={'brasil-mais.svg'} getFunctions={getFunctions} label={'Mentoria Digital'} usuarioConectado={props.usuarioConectado}>
            <Grid container style={{marginTop: '10px'}} direction={'row'} justify={'flex-start'} >
                <Grid item>
                    <Link to={'/brasilmais/turmasmentoriadigital'} style={{ cursor: "pointer" }}>
                        <Breadcrumb label={'Gerenciar Turmas'} icon={'breadcrumb-blue.svg'} color={'primary'} />
                    </Link>
                </Grid>
                
                <Grid item><Breadcrumb label={'Revisão Turma'} icon={'breadcrumb-gray.svg'} color={'secondary'} /></Grid>
            </Grid>

            {lineTab.hasOwnProperty('turma') && (
                <>
                    <Grid container justify="center" alignItems="center">
                        <Grid item xs={12} className="title-container-review-mentoria-digital">
                            <h1>Revisão</h1>
                        </Grid>

                        <Grid item xs={8} className={'Table'}>
                            <Table
                                lines={[lineTab]}
                                titles={titlesTab}
                                initialOrder={'0'}
                                pagination={false} 
                            />
                        </Grid>

                        {!!linesEtapas.length && (
                            <Grid item xs={8} className={'Table'} className="etapa-table-review-mentoria-digital">
                                <Table
                                    lines={linesEtapas}
                                    titles={titleEtapas}
                                    initialOrder={'0'}
                                    pagination={false} 
                                />
                            </Grid>
                        )}



                    </Grid>

                    <Grid container justify="center" className="container-button-review-mentoria-digital">
                        <Link to="/brasilmais/turmasmentoriadigital" style={{ marginBottom: 32 }}>
                            <GenericButton color={'darkBlue-outline'} label={'Voltar'} subClass={'basic-button'} click={() => {}} />
                        </Link>
                    </Grid>

                </>
            )}
        </Page>
    )
}