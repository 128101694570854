import React, { Component } from 'react';
import { withStyles} from "@material-ui/core/styles";
import { Grid, Tooltip } from "@material-ui/core"
import Typography from '@material-ui/core/Typography';
import GenericButton from '../../components/genericButton/genericButton';
import TermoEncerramento from './termoEncerramento';
import SimpleModal from '../../components/modal/modal';
import TermoEncerramentoDigital from './termoEncerramentoDigital';

export default class AtividadeResultados extends Component {

    referencia = React.createRef();

    constructor(props) {
        super(props);

        const atendimento = this.props.turma.atendimentoTurmaList.find(atdTurma => { return atdTurma.atendimento.id === this.props.atendimentoPratica.idAtendimento }).atendimento;

        const cliente = atendimento.cliente;

        this.state = { 
            procProdC: this.props.atendimentoPraticaC.processoProdutivo, 
            procProdD: this.props.atendimentoPraticaD.processoProdutivo,
            resultado: this.props.atividade,
            cliente,
            atendimento,
            modalExcluirAssinatura : false,
            showTermo: false 
        };
    };

    calculaAumento = (attrC, attrD) => {
        return (attrC > 0 && attrD > 0 ? 100 * (attrD - attrC) / attrC : (attrC === 0 && attrD > 0 ? attrD : 0));
    };

    calculaReducao = (attrC, attrD) => {
        return (attrC > 0 && attrD > 0 ? 100 * (attrC - attrD) / attrC : 0);
    };

    formatNumber = (value) => {
        return (value).toLocaleString('pt-BR', { maximumFractionDigits: 6 });
    };

    calculaAumentoProdutividade = () => {
        const produtividadeC = this.state.procProdC.produtividade;
        const produtividadeD = this.state.procProdD.produtividade;
        return [produtividadeC, produtividadeD, this.calculaAumento(produtividadeC, produtividadeD)];
    };

    calculaAumentoQualidade = () => {
        const qualidadeC = this.state.procProdC.qualidade;
        const qualidadeD = this.state.procProdD.qualidade;
        return [qualidadeC, qualidadeD, this.calculaAumento(qualidadeC, qualidadeD)];
    };

    calculaReducaoMovimentacao = () => {
        const movimentacaoC = this.state.procProdC.movimentacao;
        const movimentacaoD = this.state.procProdD.movimentacao;
        return [movimentacaoC, movimentacaoD, this.calculaReducao(movimentacaoC, movimentacaoD)];
    };

    renderInfo = (label, value, sym) => {
        return (
            <Grid item xs className={'style-info'}>
                <Typography gutterBottom> 
                    <label className={'labels'}> {label} </label> 
                </Typography>
                <Typography gutterBottom style={{ marginTop: '5px' }}> 
                    <label className={'diff'}> {value}{sym} </label> 
                </Typography>
            </Grid>
        )
    };

    aumentoRules = (percent) => {
        if (percent <= 19) return 'war';
        else if (percent >= 20 && percent <= 100) return 'ok';
        else if (percent >= 101) return 'full';
    };

    changeShowTermo = (value) => {
        if (value) this.referencia.current.parentElement.parentElement.style.visibility = 'hidden';
        else this.referencia.current.parentElement.parentElement.style.visibility = 'visible';
        this.setState({ showTermo: value });
    };

    renderCard = (label, result) => {
        const unit = label === 'Produtividade' ? '/hh' : '';
        const labelValue = label === 'Movimentação' ? 'redução' : 'aumento';
        return (
            <Grid container item xs={12} md={6} xl={4} className={'result-card'} spacing={4}>
                <Grid item container style={{ marginBottom: '24px' }}>
                    <Grid item style={{ marginBottom: '6px' }}>
                        <Typography> 
                            <label className={'label-card'}> {label} </label> 
                        </Typography>
                    </Grid>
                    { label !== 'Payback' ?
                        <>
                            <Grid item xs={8} container justify='center' className={'info-card'}>
                                {this.renderInfo('antes', this.formatNumber(result[0]), unit)}
                                {this.renderInfo('depois', this.formatNumber(result[1]), unit)}
                            </Grid>
                            <Grid item xs={4} container className={`info-aumento ${label === 'Produtividade' && this.aumentoRules(result[2])}`}>
                                {this.renderInfo(labelValue, result[2].toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 }), '%')}
                            </Grid>
                        </> 
                        :
                        <>
                            <Grid item xs={9} container justify='center' className={'info-card'} style={{ marginTop: '2px' }}>
                                <Grid item xs className={'style-info'}>
                                    <Typography gutterBottom className={'payback'}>
                                        <label> {this.formatNumber(result)} meses </label>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={3} className={'info-card'} style={{ marginTop: '2px' }} container></Grid>
                        </>
                    }
                </Grid>
            </Grid>
        )
    };

    renderIndicadores = (marginTop) => {
        return (
            <Grid container justify='center' style={{ marginTop: marginTop }} spacing={1}>
                {this.renderCard('Produtividade', this.calculaAumentoProdutividade())}
                {this.renderCard('Qualidade', this.calculaAumentoQualidade())}
                <Grid item xs={12}></Grid>
                {this.renderCard('Movimentação', this.calculaReducaoMovimentacao())}
                {this.renderCard('Payback', this.state.procProdD.retornoDoPrograma)}
            </Grid>
        );
    };

    render() {
        const assinaturas = this.state.resultado.assinaturas;
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
        return (
            <Grid container justify={'center'} className={'atividade-resultados'} style={{ marginTop: '2%' }} ref={this.referencia}>
                
                {!this.state.showTermo && (
                    <Grid container justify='center'>
                        <Grid item xs={12} md={8} xl={6}>
                            <Grid container direction={'row'} justify={'center'} className={'termo-grid'}>
                                <Grid item xs={12} md={5} xl={4} style={{ display: 'flex', alignItems: 'center' }}>
                                    <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/termo-encerramento.svg'} width={30} height={30} />
                                    <div>
                                    <div className={'termo-info'}> <span id={'span-upload-atividade'}>Termo de encerramento</span> </div>
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'center'}}>
                                    <GenericButton 
                                    color={!!assinaturas.length ? 'darkGreen' : 'lightBlue'} 
                                    label={!!assinaturas.length ? 'Assinado' : 'Assinar'} 
                                    subClass={'basic-button'}
                                    click={() => assinaturas.length === 0 && this.changeShowTermo(true)}
                                    disabled={this.props.disabled} 
                                    />

                                    {assinaturas.length > 0 && (
                                    <ModifiedTooltip title='Excluir assinatura' placement='top' arrow>
                                        <div>
                                        <img 
                                            alt={'Excluir assinatura'} 
                                            src={process.env.PUBLIC_URL + '/assets/icons/trash.svg'} 
                                            width={20} 
                                            height={20} 
                                            style={{ marginLeft: 8, opacity: this.props.disabled ? 0.4 : 1,
                                            cursor: this.props.disabled ? 'not-allowed': 'pointer'}}
                                            onClick={this.props.disabled ? () => {} : () => this.setState({ modalExcluirAssinatura: true })}
                                        />
                                        </div>
                                    </ModifiedTooltip>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}><div className={'div-border-bottom'} /></Grid>
                        { this.renderIndicadores('5%') }
                    </Grid> 
            )}
                { this.state.showTermo && 
                    <Grid item xs={12}>
                        
                        <TermoEncerramento
                            turma={this.props.turma}
                            atendimento={this.state.atendimento}
                            changeShowTermo={this.changeShowTermo}
                            atividade={this.props.atividade}
                            showLoading={this.props.showLoading} 
                            closeLoading={this.props.closeLoading} 
                            showToast={this.props.showToast}
                            renderIndicadores={this.renderIndicadores}
                            usuarioConectado={ this.props.usuarioConectado }
                        />
                    </Grid> }
                    <SimpleModal
                        isOpen={this.state.modalExcluirAssinatura}
                        handleClose={() => this.setState({ modalExcluirAssinatura: false })}
                        type={'warning'}
                        width={'400px'}>
                        <Grid container>
                        <p style={{ color: '#707070', fontSize: '17px', textAlign: 'center'}}>
                            Você confirma a remoção das assinaturas do Termo de Encerramento da  
                            <strong>
                            {' ' + this.state.cliente.razaoSocial}
                            </strong>
                            ?
                        </p>

                        <Grid item xs={12} className={'rodape'}>
                            <GenericButton
                            color={'darkBlue'}
                            label={'Cancelar'}
                            subClass={'basic-button'}
                            click={() => this.setState({ modalExcluirAssinatura: false }) }
                            />

                            <GenericButton 
                            color={'darkGrey-outline'}
                            label={'Remover'}
                            subClass={'basic-button'}
                            click={() => {
                                const resultado = this.state.resultado;

                                resultado.assinaturas = [];

                                this.setState({ resultado, modalExcluirAssinatura: false });
                                this.props.showToast('Assinaturas removidas com sucesso!', 'success');
                            }}
                            />
                        </Grid>

                        </Grid>
                    </SimpleModal>
            </Grid>
        );
    };
}