import React, { Component } from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import {
    Grid,
    RadioGroup,
    FormControlLabel,
    Radio,
    Checkbox
} from "@material-ui/core"

import './dashboard.scss'
import './dashboardDivergencia.scss'
import Page from '../../components/page/page'
import API from '../../comm/API'
import GenericButton from '../../components/genericButton/genericButton';
import SimpleModal from './../../components/modal/modal';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table';
import InputSelect from '../../components/inputselect/inputselect';
import Legenda from './legenda'
import LegendaGrafico from './legendaGrafico'
import CellTable from './cellTable'
import CustomTooltip from './customTooltip'

export default class DashboardDivergencia extends Component {

    anoList = []
    ano = { value: new Date().getFullYear() }

    unidade = null;
    atendimento = null;
    atendimentos = [];
    atendimentosDivergencia = [];
    atendimentosDivergenciaSliced = [];
    nivel = "";
    statusSelecionados = ["ACEITO", "CONCLUÍDO", "CANCELADO"];
    filtering = false;
    ordemMes = {
        'JAN': 1, 'FEV': 2,
        'MAR': 3, 'ABR': 4,
        'MAI': 5, 'JUN': 6,
        'JUL': 7, 'AGO': 8,
        'SET': 9, 'OUT': 10,
        'NOV': 11, 'DEZ': 12
    };

    months = [
        { nome: "JANEIRO", id: 1 }, { nome: "FEVEREIRO", id: 2 },
        { nome: "MARÇO", id: 3 }, { nome: "ABRIL", id: 4 },
        { nome: "MAIO", id: 5 }, { nome: "JUNHO", id: 6 },
        { nome: "JULHO", id: 7 }, { nome: "AGOSTO", id: 8 },
        { nome: "SETEMBRO", id: 9 }, { nome: "OUTUBRO", id: 10 },
        { nome: "NOVEMBRO", id: 11 }, { nome: "DEZEMBRO", id: 12 }
    ];

    titles = [
        { id: '0', canOrder: true, label: 'Atendimento' },
        { id: '1', canOrder: false, label: 'Status' },
        { id: '2', canOrder: false, label: 'Receita' },
        { id: '3', canOrder: false, label: 'Produção (horas)' },
        { id: '4', canOrder: false, label: 'Produção (horas)' },
        { id: '5', canOrder: false, label: 'Produção (horas)' },
        { id: '6', canOrder: false, label: 'Produção (horas)' },
        { id: '7', canOrder: false, label: 'Divergencia' }
    ];

    mes = this.months.find(function (mes) { return mes.id === new Date().getMonth() + 1 });

    constructor(props) {
        super(props);
        this.showLoading();
        this.getAnoList();
        this.modalFiltro = false;
        this.valores = 'Executado';
        this.usuario = this.props.usuarioConectado;
        this.unidade = this.usuario.unidade;
        this.unidadeSelected = this.unidade ? JSON.parse(JSON.stringify(this.unidade)) : '';
        this.state = {
            usuario: this.usuario, carregando: true, filtroModal: {
                aceito: true,
                concluido: true,
                cancelado: true,
                cdivergencia: true,
                sdivergencia: true,
                valores: 'Executado'
            },
            busca: ''
        };

        if (this.unidade !== undefined) {
            this.nivel = this.unidade.tipoUnidade.sigla;
        }
        this.cleanArrays();
        this.filtro = '';
        Highcharts.setOptions({
            lang: {
                decimalPoint: ',',
                thousandsSep: '.'
            }
        });

    }

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast !== null) this.showToast = showToast;
        if (showLoading !== null) this.showLoading = showLoading;
        if (closeLoading !== null) this.closeLoading = closeLoading;
    };


    async componentWillMount() {
        this.showLoading()
        var i;
        if (this.nivel === "DN") {
            for (i = 1; i < 29; i++)
                await this.carregaGraficos(this.mes.id, this.ano.value, this.nivel, i); //para cada DR
            this.closeLoading();
        } else if (this.nivel === "DR") {
            for (i = 1; i <= this.mes.id; i++)
                await this.carregaGraficos(i, this.ano.value, this.nivel, this.unidadeSelected.id);
            this.closeLoading();
        } else if (this.nivel === "UO") {
            await this.carregaGraficos(this.mes.id, this.ano.value, this.nivel, this.unidadeSelected.id);
            this.closeLoading();
        }
        this.setState({ carregando: false })
        this.closeLoading();
    }


    getAnoList = () => {
        for (var i = new Date().getFullYear(); i >= 2010; i--) {
            this.anoList.push({ value: i });
        }
    }

    cleanArrays() {
        this.categories = [];
        this.seriesQuantidade = [{ name: "SGT", color: "#077CDC", data: [] }, { name: "SI", color: "#B5CFE5", data: [] }];
        this.seriesReceita = [{ name: "SGT", color: "#077CDC", data: [] }, { name: "SI", color: "#B5CFE5", data: [] }];
        this.seriesHoras = [{ name: "SGT", color: "#077CDC", data: [] }, { name: "SI", color: "#B5CFE5", data: [] }];
        this.seriesEnsaios = [{ name: "SGT", color: "#077CDC", data: [] }, { name: "SI", color: "#B5CFE5", data: [] }];
        this.seriesRelatorios = [{ name: "SGT", color: "#077CDC", data: [] }, { name: "SI", color: "#B5CFE5", data: [] }];
        this.seriesCalibracoes = [{ name: "SGT", color: "#077CDC", data: [] }, { name: "SI", color: "#B5CFE5", data: [] }];
        this.diferencasQuantidade = {};
        this.diferencasReceita = {};
        this.diferencasHoras = {};
        this.diferencasEnsaios = {};
        this.diferencasRelatorios = {};
        this.diferencasCalibracoes = {};
        this.diferencaQuantidade = this.diferencasModel();
        this.diferencaReceita = this.diferencasModel();
        this.diferencaHoras = this.diferencasModel();
        this.diferencaEnsaios = this.diferencasModel();
        this.diferencaRelatorios = this.diferencasModel();
        this.diferencaCalibracoes = this.diferencasModel();
    };

    diferencasModel() {
        return { totalSGT: 0, totalSI: 0, divergencia: 0 }
    };

    loadChartConfigs = (titulo, eixoX, eixoY, diferencas) => {
        var self = this
        if (this.nivel === "DN") {
            eixoY[0].data = eixoY[0].data.sort((a, b) => (a.categoria > b.categoria) ? 1 : -1);
            eixoY[1].data = eixoY[1].data.sort((a, b) => (a.categoria > b.categoria) ? 1 : -1);
            eixoX.sort();
        } else if (this.nivel === "DR") {
            eixoY[0].data = eixoY[0].data.sort((a, b) => (this.ordemMes[a.titulo] > this.ordemMes[b.titulo]) ? 1 : -1);
            eixoY[1].data = eixoY[1].data.sort((a, b) => (this.ordemMes[a.titulo] > this.ordemMes[b.titulo]) ? 1 : -1);
            eixoX.sort((a, b) => (this.ordemMes[a] > this.ordemMes[b]) ? 1 : -1);
        }
        if ((this.nivel === "DN" && eixoY[0].data.length === 28 && eixoY[1].data.length === 28) ||
            (this.nivel === "DR" && eixoY[0].data.length === this.mes.id)) {
            this.diferencaQuantidade.divergencia = Math.abs(this.diferencaQuantidade.totalSGT - this.diferencaQuantidade.totalSI);
            this.diferencaReceita.divergencia = Math.abs(this.diferencaReceita.totalSGT - this.diferencaReceita.totalSI);
            this.diferencaHoras.divergencia = Math.abs(this.diferencaHoras.totalSGT - this.diferencaHoras.totalSI);
            this.diferencaEnsaios.divergencia = Math.abs(this.diferencaEnsaios.totalSGT - this.diferencaEnsaios.totalSI);
            this.diferencaRelatorios.divergencia = Math.abs(this.diferencaRelatorios.totalSGT - this.diferencaRelatorios.totalSI);
            this.diferencaCalibracoes.divergencia = Math.abs(this.diferencaCalibracoes.totalSGT - this.diferencaCalibracoes.totalSI);
        }
        var opcoes = { maximumFractionDigits: 2 };
        if (titulo === 'Receita (valor apropriado)') opcoes = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        return {
            chart: {
                type: 'column',
                sytle: {
                    fontFamily: 'Raleway',
                    color: '#707070'
                },
                marginRight: 0
            },
            credits: { enabled: false },
            legend: { enabled: false },
            yAxis: {
                visible: true,
                title: { text: '' }
            },
            exporting: { enabled: false },
            title: {
                text: titulo,
                align: 'left',
                x: 0,
                style: {
                    color: '#707070',
                    "fontSize": '14px',
                    fontFamily: 'Raleway'
                }
            },
            xAxis: {
                categories: eixoX,
                crosshair: false,
                labels: {
                    useHTML: true,
                    autoRotation: false,
                    formatter: function () {
                        var diferenca = diferencas[this.value];
                        var aux = '';
                        if (diferenca !== undefined && (diferenca.total > 0 || diferenca.aceito > 0 || diferenca.cancelado > 0 || diferenca.concluido > 0))
                            aux = '<i class="circle after icone" style="position: relative; margin-top: 10px;">' +
                                '<div class="tooltip" style="width: 250px; height: 170px;background-color:white;">' +
                                '<div style="color:#707070;font-weight: 600;">' +
                                '<span style="font-family:Arial;">' + diferenca.estado + '</span></div>' +

                                '<div style="padding:15px">' +
                                '<div style="display:flex;color:#ABB4BE;justify-content:space-between;font-size:11px;">' +
                                '<span style="margin-right:20px">Status</span><span><i class="circle after icone" style="position: relative; margin-top: 10px;margin-right: 5px;"></i>Divergência</span></div>' +
                                '<hr style="color:#B4B8C0;margin-top:5px;margin-bottom:5px">' +
                                '<div style="display:flex;color:#707070;justify-content:space-between;margin-bottom:5px;">' +
                                '<span>CONCLUÍDO</span><span style="color: #D44E54;">' + parseFloat(diferenca.aceito).toLocaleString('pt-br', opcoes) + '</span></div>' +
                                '<div style="display:flex;color:#707070;justify-content:space-between;margin-bottom:5px;">' +
                                '<span>CANCELADO</span><span style="color: #D44E54;">' + parseFloat(diferenca.concluido).toLocaleString('pt-br', opcoes) + '</span></div>' +
                                '<div style="display:flex;color:#707070;justify-content:space-between;margin-bottom:5px;">' +
                                '<span>ACEITO</span><span style="color: #D44E54;">' + parseFloat(diferenca.cancelado).toLocaleString('pt-br', opcoes) + '</span></div>' +
                                '<hr style="color:#B4B8C0;margin-top:5px;margin-bottom:5px">' +
                                '<div style="display:flex;color:#707070;justify-content:space-between;padding-bottom:5px;font-family:\'Raleway\', Arial, sans-serif;">' +
                                '<span style="color:#707070;font-weight: 600;">TOTAL</span><span style="color:#707070;font-weight: 600;">' + parseFloat(diferenca.total).toLocaleString('pt-br', opcoes) + '</span>' +
                                '</div>' +

                                '</div>' +
                                '</div>' +
                                '</i>';
                        return '<div class="divSpan" style="overflow: visible;"><p class="categoria">' + this.value + '</p>' + aux + '</div>';
                    }
                }
            },
            tooltip: {
                useHTML: true,
                borderWidth: 0,
                padding: 0,

                formatter: function () {

                    var index = eixoX.indexOf(this.x);
                    var comment = eixoY[0].data[index].titulo;
                    // console.log(eixoY[0].data[index]) // sgt
                    // console.log(eixoY[1].data[index]) // SI
                    if (this.nivel === "DR") comment = this.months[index].nome;
                    return (
                        '<div style="background-color:white; opacity:1!important; z-index:9999!important;">' +
                        '<div style="display:flex;color:#707070;align-items:center;">' +
                        '<div style="background-color:' + this.point.color + ';width:30px;height:20px;margin-right:10px;"></div>' +
                        '<span style="font-family:Arial;">' + comment + '</span></div>' +
                        '<div style="padding:15px">' +
                        '<div style="display:flex;color:#ABB4BE;justify-content:space-between;font-family:\'Raleway\', Arial, sans-serif;">' +
                        '<span style="margin-right:20px">Status</span><span>Valor aproximado</span></div>' +
                        '<hr style="margin-top:5px;margin-bottom:5px">' +
                        '<div style="display:flex;color:#707070;justify-content:space-between;margin-bottom:5px;font-family:\'Raleway\', Arial, sans-serif;">' +
                        '<span>ACEITO</span><span>' + parseFloat(this.point.aceito).toLocaleString('pt-br', opcoes) + '</span></div>' +
                        '<div style="display:flex;color:#707070;justify-content:space-between;margin-bottom:5px;font-family:\'Raleway\', Arial, sans-serif;">' +
                        '<span>CONCLUÍDO</span><span>' + parseFloat(this.point.concluido).toLocaleString('pt-br', opcoes) + '</span></div>' +
                        '<div style="display:flex;color:#707070;justify-content:space-between;margin-bottom:5px;font-family:\'Raleway\', Arial, sans-serif;">' +
                        '<span>CANCELADO</span><span>' + parseFloat(this.point.cancelado).toLocaleString('pt-br', opcoes) + '</span></div>' +

                        '<div style="display:flex;color:#707070;justify-content:space-between;padding-bottom:5px;font-family:\'Raleway\', Arial, sans-serif;">' +
                        '<span><b>TOTAL</b></span><span><b>' + parseFloat(this.point.y).toLocaleString('pt-br', opcoes) + '</b></span></div></div></div>');
                },
            },
            series: eixoY,
            plotOptions: {
                series: {
                    cursor: 'pointer',
                    point: {
                        events: {
                            click: async function () {
                                await self.openInfo(this.nivel, this.id, this.titulo);
                            }
                        }
                    }
                }
            },
            exporting: false
        }

    }

    getFiltro(mes, ano, nivel, unidade) {
        var filtro = '?';
        filtro += '&mes=' + mes;
        filtro += '&ano=' + ano;
        filtro += '&unidade=' + unidade;
        filtro += '&nivel=' + nivel;
        this.statusSelecionados.forEach(elem => {
            filtro += '&status=' + elem;
        })

        // filtro.id = $('#atendimento').val();
        return filtro;
    }

    getDados = async (mes, ano, nivel, unidade) => {
        let result = (await API.get('rest/sistema/Dashboard/divergencia' + this.getFiltro(mes, ano, nivel, unidade))).data;
        return result
    }

    async carregaGraficos(mes, ano, nivel, unidade) {
        var result = await this.getDados(mes, ano, nivel, unidade)
        if (nivel !== "UO" && result !== null && result !== [] && (Object.keys(result).length > 0)) {
            this.categories.push(result.categoria);
            //quantidade
            this.seriesQuantidade[0].data.push(result.sgtValue[0]);
            this.seriesQuantidade[1].data.push(result.siValue[0]);
            var diff = result.sgtValue[0].y - result.siValue[0].y > 0 ? result.sgtValue[0].y - result.siValue[0].y : result.siValue[0].y - result.sgtValue[0].y;
            var diffAceito = result.sgtValue[0].aceito - result.siValue[0].aceito > 0 ? result.sgtValue[0].aceito - result.siValue[0].aceito : result.siValue[0].aceito - result.sgtValue[0].aceito;
            var diffCancelado = result.sgtValue[0].cancelado - result.siValue[0].cancelado > 0 ? result.sgtValue[0].cancelado - result.siValue[0].cancelado : result.siValue[0].cancelado - result.sgtValue[0].cancelado;
            var diffConcluido = result.sgtValue[0].concluido - result.siValue[0].concluido > 0 ? result.sgtValue[0].concluido - result.siValue[0].concluido : result.siValue[0].concluido - result.sgtValue[0].concluido;
            this.diferencasQuantidade[result.categoria] = { estado: result.sgtValue[0].titulo, total: diff, aceito: diffAceito, cancelado: diffCancelado, concluido: diffConcluido };
            this.diferencaQuantidade.totalSGT = this.diferencaQuantidade.totalSGT + result.sgtValue[0].y;
            this.diferencaQuantidade.totalSI = this.diferencaQuantidade.totalSI + result.siValue[0].y;

            //receita
            this.seriesReceita[0].data.push(result.sgtValue[1]);
            this.seriesReceita[1].data.push(result.siValue[1]);
            diff = result.sgtValue[1].y - result.siValue[1].y > 0 ? result.sgtValue[1].y - result.siValue[1].y : result.siValue[1].y - result.sgtValue[1].y;
            diffAceito = result.sgtValue[1].aceito - result.siValue[1].aceito > 0 ? result.sgtValue[1].aceito - result.siValue[1].aceito : result.siValue[1].aceito - result.sgtValue[1].aceito;
            diffCancelado = result.sgtValue[1].cancelado - result.siValue[1].cancelado > 0 ? result.sgtValue[1].cancelado - result.siValue[1].cancelado : result.siValue[1].cancelado - result.sgtValue[1].cancelado;
            diffConcluido = result.sgtValue[1].concluido - result.siValue[1].concluido > 0 ? result.sgtValue[1].concluido - result.siValue[1].concluido : result.siValue[1].concluido - result.sgtValue[1].concluido;
            this.diferencasReceita[result.categoria] = { estado: result.sgtValue[1].titulo, total: diff, aceito: diffAceito, cancelado: diffCancelado, concluido: diffConcluido };
            this.diferencaReceita.totalSGT = this.diferencaReceita.totalSGT + result.sgtValue[1].y;
            this.diferencaReceita.totalSI = this.diferencaReceita.totalSI + result.siValue[1].y;

            //ensaios
            this.seriesEnsaios[0].data.push(result.sgtValue[2]);
            this.seriesEnsaios[1].data.push(result.siValue[2]);
            diff = result.sgtValue[2].y - result.siValue[2].y > 0 ? result.sgtValue[2].y - result.siValue[2].y : result.siValue[2].y - result.sgtValue[2].y;
            diffAceito = result.sgtValue[2].aceito - result.siValue[2].aceito > 0 ? result.sgtValue[2].aceito - result.siValue[2].aceito : result.siValue[2].aceito - result.sgtValue[2].aceito;
            diffCancelado = result.sgtValue[2].cancelado - result.siValue[2].cancelado > 0 ? result.sgtValue[2].cancelado - result.siValue[2].cancelado : result.siValue[2].cancelado - result.sgtValue[2].cancelado;
            diffConcluido = result.sgtValue[2].concluido - result.siValue[2].concluido > 0 ? result.sgtValue[2].concluido - result.siValue[2].concluido : result.siValue[2].concluido - result.sgtValue[2].concluido;
            this.diferencasEnsaios[result.categoria] = { estado: result.sgtValue[2].titulo, total: diff, aceito: diffAceito, cancelado: diffCancelado, concluido: diffConcluido };
            this.diferencaEnsaios.totalSGT = this.diferencaEnsaios.totalSGT + result.sgtValue[2].y;
            this.diferencaEnsaios.totalSI = this.diferencaEnsaios.totalSI + result.siValue[2].y;

            //calibracoes
            this.seriesCalibracoes[0].data.push(result.sgtValue[3]);
            this.seriesCalibracoes[1].data.push(result.siValue[3]);
            diff = result.sgtValue[3].y - result.siValue[3].y > 0 ? result.sgtValue[3].y - result.siValue[3].y : result.siValue[3].y - result.sgtValue[3].y;
            diffAceito = result.sgtValue[3].aceito - result.siValue[3].aceito > 0 ? result.sgtValue[3].aceito - result.siValue[3].aceito : result.siValue[3].aceito - result.sgtValue[3].aceito;
            diffCancelado = result.sgtValue[3].cancelado - result.siValue[3].cancelado > 0 ? result.sgtValue[3].cancelado - result.siValue[3].cancelado : result.siValue[3].cancelado - result.sgtValue[3].cancelado;
            diffConcluido = result.sgtValue[3].concluido - result.siValue[3].concluido > 0 ? result.sgtValue[3].concluido - result.siValue[3].concluido : result.siValue[3].concluido - result.sgtValue[3].concluido;
            this.diferencasCalibracoes[result.categoria] = { estado: result.sgtValue[3].titulo, total: diff, aceito: diffAceito, cancelado: diffCancelado, concluido: diffConcluido };
            this.diferencaCalibracoes.totalSGT = this.diferencaCalibracoes.totalSGT + result.sgtValue[3].y;
            this.diferencaCalibracoes.totalSI = this.diferencaCalibracoes.totalSI + result.siValue[3].y;

            //relatorios
            this.seriesRelatorios[0].data.push(result.sgtValue[4]);
            this.seriesRelatorios[1].data.push(result.siValue[4]);
            diff = result.sgtValue[4].y - result.siValue[4].y > 0 ? result.sgtValue[4].y - result.siValue[4].y : result.siValue[4].y - result.sgtValue[4].y;
            diffAceito = result.sgtValue[4].aceito - result.siValue[4].aceito > 0 ? result.sgtValue[4].aceito - result.siValue[4].aceito : result.siValue[4].aceito - result.sgtValue[4].aceito;
            diffCancelado = result.sgtValue[4].cancelado - result.siValue[4].cancelado > 0 ? result.sgtValue[4].cancelado - result.siValue[4].cancelado : result.siValue[4].cancelado - result.sgtValue[4].cancelado;
            diffConcluido = result.sgtValue[4].concluido - result.siValue[4].concluido > 0 ? result.sgtValue[4].concluido - result.siValue[4].concluido : result.siValue[4].concluido - result.sgtValue[4].concluido;
            this.diferencasRelatorios[result.categoria] = { estado: result.sgtValue[4].titulo, total: diff, aceito: diffAceito, cancelado: diffCancelado, concluido: diffConcluido };
            this.diferencaRelatorios.totalSGT = this.diferencaRelatorios.totalSGT + result.sgtValue[4].y;
            this.diferencaRelatorios.totalSI = this.diferencaRelatorios.totalSI + result.siValue[4].y;

            //horas
            this.seriesHoras[0].data.push(result.sgtValue[5]);
            this.seriesHoras[1].data.push(result.siValue[5]);
            diff = result.sgtValue[5].y - result.siValue[5].y > 0 ? result.sgtValue[5].y - result.siValue[5].y : result.siValue[5].y - result.sgtValue[5].y;
            diffAceito = result.sgtValue[5].aceito - result.siValue[5].aceito > 0 ? result.sgtValue[5].aceito - result.siValue[5].aceito : result.siValue[5].aceito - result.sgtValue[5].aceito;
            diffCancelado = result.sgtValue[5].cancelado - result.siValue[5].cancelado > 0 ? result.sgtValue[5].cancelado - result.siValue[5].cancelado : result.siValue[5].cancelado - result.sgtValue[5].cancelado;
            diffConcluido = result.sgtValue[5].concluido - result.siValue[5].concluido > 0 ? result.sgtValue[5].concluido - result.siValue[5].concluido : result.siValue[5].concluido - result.sgtValue[5].concluido;
            this.diferencasHoras[result.categoria] = { estado: result.sgtValue[5].titulo, total: diff, aceito: diffAceito, cancelado: diffCancelado, concluido: diffConcluido };
            this.diferencaHoras.totalSGT = this.diferencaHoras.totalSGT + result.sgtValue[5].y;
            this.diferencaHoras.totalSI = this.diferencaHoras.totalSI + result.siValue[5].y;

        } else if (result !== null && result !== [] && this.nivel === "UO") {
            var diffList = {};
            if (result.atendimentosSGT !== undefined) {
                for (var i = 0; i < result.atendimentosSGT.length; i++) {
                    if (diffList[result.atendimentosSGT[i][0]] === undefined) diffList[result.atendimentosSGT[i][0]] = [];
                    diffList[result.atendimentosSGT[i][0]].push({
                        "id": result.atendimentosSGT[i][0], "titulo": result.atendimentosSGT[i][1], "status": result.atendimentosSGT[i][2],
                        "receita": result.atendimentosSGT[i][3], "ensaio": result.atendimentosSGT[i][4], "calibracao": result.atendimentosSGT[i][5], "relatorio": result.atendimentosSGT[i][6], "horas": result.atendimentosSGT[i][7],
                        "receitaprevista": result.atendimentosSGT[i][8], "ensaioprevisto": result.atendimentosSGT[i][9], "calibracaoprevisto": result.atendimentosSGT[i][10], "relatorioprevisto": result.atendimentosSGT[i][11],
                        "horasprevista": result.atendimentosSGT[i][12]
                    });
                }
            }
            if (result.atendimentosSI !== undefined) {
                for (i = 0; i < result.atendimentosSI.length; i++) {
                    if (diffList[result.atendimentosSI[i][0]] === undefined) diffList[result.atendimentosSI[i][0]] = [[]]; //posicao 0(SGT) preenchida, posicao 1(SI) a preencher
                    diffList[result.atendimentosSI[i][0]].push({
                        "id": result.atendimentosSI[i][0], "titulo": result.atendimentosSI[i][1], "status": result.atendimentosSI[i][2],
                        "receita": result.atendimentosSI[i][3], "ensaio": result.atendimentosSI[i][4], "calibracao": result.atendimentosSI[i][5], "relatorio": result.atendimentosSI[i][6], "horas": result.atendimentosSI[i][7],
                        "receitaprevista": result.atendimentosSI[i][8], "ensaioprevisto": result.atendimentosSI[i][9], "calibracaoprevisto": result.atendimentosSI[i][10], "relatorioprevisto": result.atendimentosSI[i][11],
                        "horasprevista": result.atendimentosSI[i][12]
                    });
                }
            }

            this.atendimentos = diffList;
            this.atendimentosDivergencia = diffList;
            this.atendimentosDivergenciaSliced = Object.entries(diffList);
            this.atendimentoList = Object.entries(diffList);
        }
    };
    // saveArrayState(nivel) {
    //     if (nivel === 'DN') {
    //         this.stateDN = this.getStateProperties();
    //     } else if (nivel === 'DR') {
    //         this.stateDR = this.getStateProperties();
    //     }
    // };

    getStateProperties() {
        var list = {
            categories: JSON.parse(JSON.stringify(this.categories)),
            seriesQuantidade: JSON.parse(JSON.stringify(this.seriesQuantidade)),
            diferencasQuantidade: JSON.parse(JSON.stringify(this.diferencasQuantidade)),
            diferencaQuantidade: JSON.parse(JSON.stringify(this.diferencaQuantidade)),
            seriesReceita: JSON.parse(JSON.stringify(this.seriesReceita)),
            diferencasReceita: JSON.parse(JSON.stringify(this.diferencasReceita)),
            diferencaReceita: JSON.parse(JSON.stringify(this.diferencaReceita)),
            seriesHoras: JSON.parse(JSON.stringify(this.seriesHoras)),
            diferencasHoras: JSON.parse(JSON.stringify(this.diferencasHoras)),
            diferencaHoras: JSON.parse(JSON.stringify(this.diferencaHoras)),
            seriesEnsaios: JSON.parse(JSON.stringify(this.seriesEnsaios)),
            diferencasEnsaios: JSON.parse(JSON.stringify(this.diferencasEnsaios)),
            diferencaEnsaios: JSON.parse(JSON.stringify(this.diferencaEnsaios)),
            seriesRelatorios: JSON.parse(JSON.stringify(this.seriesRelatorios)),
            diferencasRelatorios: JSON.parse(JSON.stringify(this.diferencasRelatorios)),
            diferencaRelatorios: JSON.parse(JSON.stringify(this.diferencaRelatorios)),
            seriesCalibracoes: JSON.parse(JSON.stringify(this.seriesCalibracoes)),
            diferencasCalibracoes: JSON.parse(JSON.stringify(this.diferencasCalibracoes)),
            diferencaCalibracoes: JSON.parse(JSON.stringify(this.diferencaCalibracoes)),
        };
        this.cleanArrays();
        return list;
    };

    async openInfo(nivel, unidade, titulo) {
        // this.saveArrayState(nivel);
        this.cleanArrays();
        this.showLoading();
        this.setState({ carregando: true })
        if (nivel === "DN") {
            this.unidadeSelected.id = unidade;
            this.unidadeSelected.descricao = titulo;
            this.nivel = "DR";
            for (var i = 1; i <= this.mes.id; i++)
                await this.carregaGraficos(i, this.ano.value, this.nivel, this.unidadeSelected.id); //para cada mes
            this.setState({ carregando: false })
            this.closeLoading();
        } else {

            try {
                this.mes = this.months.find(function (mes) { return mes.nome.toUpperCase().includes(titulo) });
                this.nivel = "UO";
                await this.carregaGraficos(this.mes.id, this.ano.value, this.nivel, this.unidadeSelected.id);
                this.setState({ carregando: false })
                this.closeLoading();
            } catch (error) {
                this.setState({ carregando: false })
                this.closeLoading();
                this.showToast('Erro ao listar as divergências dessa unidade. Por favor entre em contato com o suporte.', 'error');
            }



        }

    };

    checkDivergencia(obj) {
        if (obj[1][0] && obj[1][1]) {
            if (obj[1][0].status !== obj[1][1].status || obj[1][0].receita !== obj[1][1].receita || obj[1][0].ensaiocalibracao !== obj[1][1].ensaiocalibracao ||
                obj[1][0].relatorio !== obj[1][1].relatorio || obj[1][0].horas !== obj[1][1].horas || obj[1][0].receitaprevista !== obj[1][1].receitaprevista ||
                obj[1][0].ensaiocalibracaoprevisto !== obj[1][1].ensaiocalibracaoprevisto || obj[1][0].relatorioprevisto !== obj[1][1].relatorioprevisto ||
                obj[1][0].horasprevista !== obj[1][1].horasprevista || obj[1][0].calibracao !== obj[1][1].calibracao ||
                obj[1][0].calibracaoprevisto !== obj[1][1].calibracaoprevisto) {
                return true;
            }
            return false;
        }
        return true;
    };

    listToTableRows(lista) {
        let listaParaTabela = []
        let isExecutado = this.valores === 'Executado';

        lista.forEach(elem => {
            let aux = []
            // id atendimento
            aux[0] = elem[1][0].id ? elem[1][0].id : (elem[1][1].id ? elem[1][1].id : '-')
            //status
            aux[1] = (<CellTable status={true} cell1={elem[1][0] ? elem[1][0].status : ''} cell2={elem[1][1] ? elem[1][1].status : ''} />)
            aux[2] = isExecutado ?
                (<CellTable cell1={elem[1][0] ? elem[1][0].receita : ''} cell2={elem[1][1] ? elem[1][1].receita : ''} round={true} />) :
                (<CellTable cell1={elem[1][0] ? elem[1][0].receitaprevista : ''} cell2={elem[1][1] ? elem[1][1].receitaprevista : ''} round={true} />)
            aux[3] = isExecutado ?
                (<CellTable cell1={elem[1][0] ? elem[1][0].horas + ' h' : ''} cell2={elem[1][1] ? elem[1][1].horas + ' h' : ''} round={false} />) :
                (<CellTable cell1={elem[1][0] ? elem[1][0].horasprevista + ' h' : ''} cell2={elem[1][1] ? elem[1][1].horasprevista + ' h' : ''} round={false} />)
            aux[4] = isExecutado ?
                (<CellTable cell1={elem[1][0] ? elem[1][0].ensaio + ' h' : ''} cell2={elem[1][1] ? elem[1][1].ensaio + ' h' : ''} round={false} />) :
                (<CellTable cell1={elem[1][0] ? elem[1][0].ensaioprevisto + ' h' : ''} cell2={elem[1][1] ? elem[1][1].ensaioprevisto + ' h' : ''} round={false} />)
            aux[5] = isExecutado ?
                (<CellTable cell1={elem[1][0] ? elem[1][0].calibracao + ' h' : ''} cell2={elem[1][1] ? elem[1][1].calibracao + ' h' : ''} round={false} />) :
                (<CellTable cell1={elem[1][0] ? elem[1][0].calibracaoprevisto + ' h' : ''} cell2={elem[1][1] ? elem[1][1].calibracaoprevisto + ' h' : ''} round={false} />)
            aux[6] = isExecutado ?
                (<CellTable cell1={elem[1][0] ? elem[1][0].relatorio + ' h' : ''} cell2={elem[1][1] ? elem[1][1].relatorio + ' h' : ''} round={false} />) :
                (<CellTable cell1={elem[1][0] ? elem[1][0].relatoriosprevisto + ' h' : ''} cell2={elem[1][1] ? elem[1][1].relatoriosprevisto + ' h' : ''} round={false} />)
            aux[7] = this.checkDivergencia(elem) ?
                (<CustomTooltip element={<i className={"circle after icone"} style={{ position: 'relative', marginTop: '10px', color: '#D44E54' }}></i>}>
                    <Grid container spacing={0} style={{ fontSize: '13px', width: '300px', height: '250px' }}>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: 'center', color: '#707070', fontWeight: '600' }}>
                                    {'Atendimento ' + elem[0] + ' - ' + this.mes.nome + ' ' + this.ano.value}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={12} style={{ textAlign: 'right' }}>
                                    <i className={"circle after icone"} style={{ color: '#D44E54', position: 'relative', marginTop: '10px' }}></i>
                                    <span style={{ fontSize: '11px', color: '#B4B8C0' }}>Divergência</span>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='space-between'>
                                <Grid item xs={6} style={{ color: '#707070' }}>
                                    Receita
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right', color: '#D44E54' }}>
                                    R$ {elem[1][0] && elem[1][0].receita ? elem[1][0].receita : 0 - elem[1][1] && elem[1][1].receita ? elem[1][1].receita : 0}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='space-between'>
                                <Grid item xs={6} style={{ color: '#707070' }}>
                                    Produção (horas)
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right', color: '#D44E54' }}>
                                    {elem[1][0] && elem[1][0].horas ? elem[1][0].horas : 0 - elem[1][1] && elem[1][1].horas ? elem[1][1].horas : 0} h
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='space-between'>
                                <Grid item xs={6} style={{ color: '#707070' }}>
                                    Produção (ensaios)
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right', color: '#D44E54' }}>
                                    {elem[1][0] && elem[1][0].ensaio ? elem[1][0].ensaio : 0 - elem[1][1] && elem[1][1].ensaio ? elem[1][1].ensaio : 0}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='space-between'>
                                <Grid item xs={6} style={{ color: '#707070' }}>
                                    Produção (calibrações)
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right', color: '#D44E54' }}>
                                    {elem[1][0] && elem[1][0].calibracao ? elem[1][0].calibracao : 0 - elem[1][1] && elem[1][1].calibracao ? elem[1][1].calibracao : 0}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='space-between'>
                                <Grid item xs={6} style={{ color: '#707070' }}>
                                    Produção (relatórios)
                                </Grid>
                                <Grid item xs={6} style={{ textAlign: 'right', color: '#D44E54' }}>
                                    {elem[1][0] && elem[1][0].relatorio ? elem[1][0].relatorio : 0 - elem[1][1] && elem[1][1].relatorio ? elem[1][1].relatorio : 0}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CustomTooltip>) : '';
            listaParaTabela.push(aux)
        })
        return listaParaTabela
    }

    async voltar(nivel) {
        if (this.podeVoltar(this.nivel)) {
            this.showLoading();
            this.cleanArrays();
            this.setState({ carregando: true })
            this.nivel = nivel;
            // this.loadArrayState(this.nivel);
            // inicio
            var mes = this.ano.value < new Date().getFullYear() ? 11 : (new Date().getMonth() - 1 < 0 ? 0 : new Date().getMonth() - 1);
            this.mes = this.months[mes];
            var i;
            if (this.nivel === "DN") {
                for (i = 1; i < 29; i++)
                    await this.carregaGraficos(this.mes.id, this.ano.value, this.nivel, i); //para cada DR
                this.closeLoading();
            } else if (this.nivel === "DR") {
                for (i = 1; i <= this.mes.id; i++)
                    await this.carregaGraficos(i, this.ano.value, this.nivel, this.unidadeSelected.id);
                this.closeLoading();
            }
            this.setState({ carregando: false })
            this.closeLoading();
            // fim
        }

    };

    podeVoltar(nivel) {
        if (this.unidade && this.unidade.tipoUnidade && this.unidade.tipoUnidade.sigla) {
            return (this.unidade.tipoUnidade.sigla === 'DR' && nivel === 'UO') ||
                (this.unidade.tipoUnidade.sigla === 'DN' && (nivel === 'UO' || nivel === 'DR'))
        }
        return false;
    }

    // loadArrayState(nivel) {
    //     let oldState = {};
    //     if (nivel === 'DN') {
    //         oldState = JSON.parse(JSON.stringify(this.stateDN));
    //     } else {
    //         oldState = JSON.parse(JSON.stringify(this.stateDR));
    //     }

    //     this.categories = JSON.parse(JSON.stringify(oldState.categories));
    //     this.seriesQuantidade = JSON.parse(JSON.stringify(oldState.seriesQuantidade));
    //     this.diferencasQuantidade = JSON.parse(JSON.stringify(oldState.diferencasQuantidade));
    //     this.diferencaQuantidade = JSON.parse(JSON.stringify(oldState.diferencaQuantidade));
    //     this.seriesReceita = JSON.parse(JSON.stringify(oldState.seriesReceita));
    //     this.diferencasReceita = JSON.parse(JSON.stringify(oldState.diferencasReceita));
    //     this.diferencaReceita = JSON.parse(JSON.stringify(oldState.diferencaReceita));
    //     this.seriesHoras = JSON.parse(JSON.stringify(oldState.seriesHoras));
    //     this.diferencasHoras = JSON.parse(JSON.stringify(oldState.diferencasHoras));
    //     this.diferencaHoras = JSON.parse(JSON.stringify(oldState.diferencaHoras));
    //     this.seriesEnsaios = JSON.parse(JSON.stringify(oldState.seriesEnsaios));
    //     this.diferencasEnsaios = JSON.parse(JSON.stringify(oldState.diferencasEnsaios));
    //     this.diferencaEnsaios = JSON.parse(JSON.stringify(oldState.diferencaEnsaios));
    //     this.seriesRelatorios = JSON.parse(JSON.stringify(oldState.seriesRelatorios));
    //     this.diferencasRelatorios = JSON.parse(JSON.stringify(oldState.diferencasRelatorios));
    //     this.diferencaRelatorios = JSON.parse(JSON.stringify(oldState.diferencaRelatorios));
    //     this.seriesCalibracoes = JSON.parse(JSON.stringify(oldState.seriesRelatorios));
    //     this.diferencasCalibracoes = JSON.parse(JSON.stringify(oldState.diferencasRelatorios));
    //     this.diferencaCalibracoes = JSON.parse(JSON.stringify(oldState.diferencaRelatorios));

    // };

    updateAtendimentoList() {
        this.showLoading();
        if (this.state.busca && this.state.busca !== '') {
            var result = this.atendimentosDivergenciaSliced.filter(atendimento => {
                return atendimento[0].includes(this.state.busca);
            });
            if (result) {
                this.atendimentoList = result;
            };
        } else {
            this.atendimentoList = this.atendimentosDivergenciaSliced;
        }
        this.setState(this.state)
        this.closeLoading();
    }

    changeInput = (e) => {
        if (e !== null && e.target !== null) {
            this.setState({ [e.target.id]: e.target.value })
        }
    }

    changeCheckbox = (e) => {
        this.setState(({
            filtroModal: { ...this.state.filtroModal, [e.target.name]: !this.state.filtroModal[e.target.name] }
        }));
    };

    changeRadio = (event) => {
        this.setState(({
            filtroModal: { ...this.state.filtroModal, [event.target.name]: event.target.value }
        }));
    };

    changeModal = (modal, status) => {
        this[modal] = status;
        this.setState(this.state);
    }

    changeAno = async (e) => {
        if (this.ano.value !== e.value) {
            this.showLoading();
            this.cleanArrays();
            this.setState({ carregando: true })
            this.ano = e;
            var mes = this.ano.value < new Date().getFullYear() ? 11 : (new Date().getMonth() - 1 < 0 ? 0 : new Date().getMonth() - 1);
            this.mes = this.months[mes];
            var i;
            if (this.nivel === "DN") {
                for (i = 1; i < 29; i++)
                    await this.carregaGraficos(this.mes.id, this.ano.value, this.nivel, i); //para cada DR
                this.closeLoading();
            } else if (this.nivel === "DR") {
                for (i = 1; i <= this.mes.id; i++)
                    await this.carregaGraficos(i, this.ano.value, this.nivel, this.unidadeSelected.id);
                this.closeLoading();
            }
            this.setState({ carregando: false })
            this.closeLoading();
        }
    };

    changeFiltro = () => {
        this.showLoading();
        this.valores = this.state.filtroModal.valores;
        // Filtro aplicado apenas nos atendimento do SGT
        var result = this.atendimentosDivergenciaSliced.filter(atendimento => {
            let atdSGT = atendimento[1][0] // sgt
            var isDivergente = this.checkDivergencia(atendimento);
            return (
                ((this.state.filtroModal.aceito ? atdSGT.status === 'ACEITO' : false) ||
                    (this.state.filtroModal.cancelado ? atdSGT.status === 'CANCELADO' : false) ||
                    (this.state.filtroModal.concluido ? atdSGT.status === 'CONCLUÍDO' : false))
                &&
                ((this.state.filtroModal.cdivergencia ? isDivergente === true : false) ||
                    (this.state.filtroModal.sdivergencia ? isDivergente === false : false))
            )
        });
        this.atendimentoList = result;
        this.setState(this.state)
        this.closeLoading();

    }

    render() {
        if (this.state.carregando === false && (this.nivel === 'DN' || this.nivel === 'DR')) {

            let chart1 = this.loadChartConfigs('Quantidade de atendimentos', this.categories, this.seriesQuantidade, this.diferencasQuantidade);
            let chart2 = this.loadChartConfigs('Receita (valor apropriado)', this.categories, this.seriesReceita, this.diferencasReceita);
            let chart3 = this.loadChartConfigs('Produção (horas apropriadas)', this.categories, this.seriesHoras, this.diferencasHoras);
            let chart4 = this.loadChartConfigs('Produção (ensaios realizados)', this.categories, this.seriesEnsaios, this.diferencasEnsaios);
            let chart5 = this.loadChartConfigs('Produção (relatórios registrados)', this.categories, this.seriesRelatorios, this.diferencasRelatorios);
            let chart6 = this.loadChartConfigs('Produção (calibrações registradas)', this.categories, this.seriesCalibracoes, this.diferencasCalibracoes);
            return (
                <Page icon={'graphic.svg'} label={'Dashboard de Divergências'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                    <Grid container>
                        <Grid item xs={12}>
                            {this.nivel === 'DN' && <Grid container>
                                <Grid item xs={11}>
                                    <label className={'dashboard-header-label'}>Todos os DRs</label>
                                    <hr className={'dashboard-header'} />
                                </Grid>
                            </Grid>}
                            {this.nivel === 'DR' && <Grid container>
                                <Grid item xs={11}>
                                    <label className={'dashboard-header-label'}>
                                        {this.unidade.tipoUnidade.sigla === 'DN' ?
                                            <span style={{ fontWeight: 'normal' }} onClick={() => this.voltar('DN')}>Todos os DRs   {'>'}   </span>
                                            : null}
                                        <span>{this.unidadeSelected.descricao}</span>
                                    </label>
                                    <hr className={'dashboard-header'} />
                                </Grid>
                            </Grid>}
                            <Grid container style={{ padding: '16px 0px' }}>
                                <Grid item xs={11}>
                                    <Grid container justify='space-between' style={{ alignItems: 'center', padding: '16px 0px' }}>
                                        <Grid item xs={3}>
                                            <InputSelect
                                                label={'Período'}
                                                suggestions={this.anoList}
                                                itemLabel={'value'}
                                                getInput={this.changeAno}
                                                id={'ano'}
                                                itemKey={'value'}
                                                initialSelectedItem={this.ano} />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Legenda />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid container id={'divergencia'} >
                                <Grid container>
                                    <Grid item id={'chart1'} xs={9}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chart1}
                                            oneTo />
                                    </Grid>
                                    <Grid item xs={2} className={'legenda'}>
                                        <LegendaGrafico
                                            titulo1={'Total de atendimentos:'}
                                            sgt={this.diferencaQuantidade.totalSGT}
                                            si={this.diferencaQuantidade.totalSI}
                                            divergencia={this.diferencaQuantidade.divergencia} />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item id={'chart2'} xs={9}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chart2} />
                                    </Grid>
                                    <Grid item xs={2} className={'legenda'}>
                                        <LegendaGrafico
                                            titulo1={'Valor total apropriado:'}
                                            sgt={this.diferencaReceita.totalSGT.toFixed(2)}
                                            si={this.diferencaReceita.totalSI.toFixed(2)}
                                            divergencia={this.diferencaReceita.divergencia.toFixed(2)} />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item id={'chart3'} xs={9}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chart3} />
                                    </Grid>
                                    <Grid item xs={2} className={'legenda'}>
                                        <LegendaGrafico
                                            titulo1={'Total de horas trabalhadas: '}
                                            sgt={this.diferencaHoras.totalSGT}
                                            si={this.diferencaHoras.totalSI}
                                            divergencia={this.diferencaHoras.divergencia} />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item id={'chart4'} xs={9}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chart4} />
                                    </Grid>
                                    <Grid item xs={2} className={'legenda'}>
                                        <LegendaGrafico
                                            titulo1={'Total de ensaios realizados: '}
                                            sgt={this.diferencaEnsaios.totalSGT}
                                            si={this.diferencaEnsaios.totalSI}
                                            divergencia={this.diferencaEnsaios.divergencia} />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item id={'chart5'} xs={9}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chart5} />
                                    </Grid>
                                    <Grid item xs={2} className={'legenda'}>
                                        <LegendaGrafico
                                            titulo1={'Total de relatórios registrados:'}
                                            sgt={this.diferencaRelatorios.totalSGT}
                                            si={this.diferencaRelatorios.totalSI}
                                            divergencia={this.diferencaRelatorios.divergencia} />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item id={'chart6'} xs={9}>
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chart6} />
                                    </Grid>
                                    <Grid item xs={3} className={'legenda'}>
                                        <LegendaGrafico
                                            titulo1={'Total de calibrações realizadas:'}
                                            sgt={this.diferencaCalibracoes.totalSGT}
                                            si={this.diferencaCalibracoes.totalSI}
                                            divergencia={this.diferencaCalibracoes.divergencia} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Page>
            );
        } else {
            if (this.state.carregando === false && this.nivel === 'UO') {
                var tableList = this.listToTableRows(this.atendimentoList ? this.atendimentoList : [])
                return (
                    <Page icon={'graphic.svg'} label={'Dashboard de Divergências'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                        <Grid container className={'divergencia-uo-container'}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <label className={'dashboard-header-label'}>
                                            {this.unidade.tipoUnidade.sigla === 'DN' ?
                                                <span style={{ fontWeight: 'normal' }} > Todos os DRs   {'>'}   </span>
                                                : null}
                                            <span style={{ fontWeight: 'normal' }} onClick={() => this.voltar('DR')}>{this.unidadeSelected.descricao + '   >   '}</span>
                                            <span>{this.mes.nome + this.ano.value}</span>
                                        </label>
                                        <hr className={'dashboard-header'} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={11}>
                                        <Grid container style={{ alignItems: 'center', padding: '16px 0px' }}>
                                            <Grid item xs={4} className={'paddingForm'}>
                                                <FormField type={'text'} label={''} id={'busca'}
                                                    button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={() => this.updateAtendimentoList()} />}
                                                    inputValue={this.state.busca}
                                                    placeholder={'Busque pelo ID do atendimento'}
                                                    changeValue={this.changeInput} />
                                            </Grid>
                                            <Grid item xs={3} className={'paddingForm'}>
                                                <GenericButton color={'lightBlue'} label={'Busca avançada'} subClass={'basic-button busca-avancada'}
                                                    icon={process.env.PUBLIC_URL + 'filter.svg'}
                                                    click={() => this.changeModal('modalFiltro', true)} />
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={4}>
                                                <Legenda />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={11} className={'tabela-atendimentos-divergencia'}>
                                        <Table
                                            lines={tableList}
                                            titles={this.titles}
                                            initialOrder={'0'}
                                            pagination={true} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <SimpleModal
                            isOpen={this.modalFiltro}
                            handleClose={() => this.changeModal('modalFiltro', false)}
                            type={'info'}
                            width={'650px'}>
                            <div className={'modal modal-atendimento-filtro atendimentos-divergencia'}>
                                <h2>Busca avançada</h2>
                                {/* style={{ alignItems: 'flex-end' }} */}
                                <Grid container>
                                    <Grid item xs={4}>
                                        <p className={'font-blue'} style={{ margin: 0 }}>Status</p>
                                        <Grid container className={'checkbox-status'}>
                                            <Grid item xs={12}>
                                                <FormControlLabel value="end" control={<Checkbox checked={this.state.filtroModal.aceito} onChange={this.changeCheckbox} name="aceito" value={this.state.filtroModal.aceito} />} label="Aceito" labelPlacement="end" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel value="end" control={<Checkbox checked={this.state.filtroModal.concluido} onChange={this.changeCheckbox} name="concluido" value={this.state.filtroModal.concluido} />} label="Concluído" labelPlacement="end" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel value="end" control={<Checkbox checked={this.state.filtroModal.cancelado} onChange={this.changeCheckbox} name="cancelado" value={this.state.filtroModal.cancelado} />} label="Contrato cancelado" labelPlacement="end" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className={'font-blue'} style={{ margin: 0 }}>Divergência</p>
                                        <Grid container className={'checkbox-divergencia'}>
                                            <Grid item xs={12}>
                                                <FormControlLabel value="end" control={<Checkbox checked={this.state.filtroModal.cdivergencia} onChange={this.changeCheckbox} name="cdivergencia" value={this.state.filtroModal.cdivergencia} />} label="Com divergência" labelPlacement="end" />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControlLabel value="end" control={<Checkbox checked={this.state.filtroModal.sdivergencia} onChange={this.changeCheckbox} name="sdivergencia" value={this.state.filtroModal.sdivergencia} />} label="Sem divergência" labelPlacement="end" />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container>
                                            <p className={'font-blue'} style={{ margin: 0 }}>Valores</p>
                                            <Grid item xs={12}>
                                                <RadioGroup aria-label="position" name="valores" value={this.state.filtroModal.valores} onChange={this.changeRadio}>
                                                    <FormControlLabel
                                                        value="Executado"
                                                        control={<Radio color="primary" />}
                                                        label="Executado"
                                                        labelPlacement="end"
                                                    />
                                                    <FormControlLabel
                                                        value="Previsto"
                                                        control={<Radio color="primary" />}
                                                        label="Previsto"
                                                        labelPlacement="end"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <div className={'rodape'}>
                                    <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.changeModal('modalFiltro', false)} />
                                    <GenericButton color={'darkBlue'} label={'Buscar'} subClass={'basic-button'} click={this.changeFiltro} />
                                </div>
                            </div>
                        </SimpleModal>
                    </Page >
                )
            }
            return (<Page icon={'graphic.svg'} label={'Dashboard de Divergências'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}></Page>)
        }
    }
}