import React, { Component } from 'react';
import MapaBrasil from '../../../components/mapaBrasil/mapaBrasil';
import API from '../../../comm/API';
import { Grid } from '@material-ui/core';

export default class GraficoMapaBrasil extends Component {

	legenda = {
		title: 'Quantidade de empresas',
		values: [
			['#FFFFFF', '1 - 10'],
			['#CCEFFB', '11 - 20'],
			['#73D4F2', '21 - 30'],
			['#00B1E8', '31 - 40'],
			['#077CDC', 'Acima de 40'],
			['#D5D9E1', 'Sem dados']
		]
	};

	switch = {
		left: 'Atendimento',
		right: 'Meta'
	};

	constructor(props) {
		super(props);
		this.state = { 
			dados: { atendimento: {
				'AC': ['#D5D9E1', 0], 'AL': ['#D5D9E1', 0], 'AM': ['#D5D9E1', 0], 'AP': ['#D5D9E1', 0], 'BA': ['#D5D9E1', 0], 
				'CE': ['#D5D9E1', 0], 'DF': ['#D5D9E1', 0], 'ES': ['#D5D9E1', 0], 'EST': ['#D5D9E1', 0], 'GO': ['#D5D9E1', 0], 
				'MA': ['#D5D9E1', 0], 'MG': ['#D5D9E1', 0], 'MS': ['#D5D9E1', 0], 'MT': ['#D5D9E1', 0], 'PA': ['#D5D9E1', 0], 
				'PB': ['#D5D9E1', 0], 'PE': ['#D5D9E1', 0], 'PI': ['#D5D9E1', 0], 'PR': ['#D5D9E1', 0], 'RJ': ['#D5D9E1', 0], 
				'RN': ['#D5D9E1', 0], 'RO': ['#D5D9E1', 0], 'RR': ['#D5D9E1', 0], 'RS': ['#D5D9E1', 0], 'SC': ['#D5D9E1', 0], 
				'SE': ['#D5D9E1', 0], 'SP': ['#D5D9E1', 0], 'TO': ['#D5D9E1', 0]
			}}
		};

		API.get('rest/sistema/DashboardBrasilMais/geralMapa').then(res => {
			let list = {};
			if (res.data != null && res.data.atendimento != null) {
				res.data.atendimento.forEach(aux => {
					list[aux[0]] = [aux[1], aux[2]];
				});
				this.setState({ dados: {atendimento: list} });
			}
        }).catch(error => {
            console.log(error);
            this.showToast('Erro ao buscar mapa do dashboard. Favor, entrar em contato com o suporte.', 'error');
        });
	};

	tooltipBox = (numAtdSetor, setor, total, estado) => {
		let font = '12px Raleway';
		return(
			`onMouseMove="javascript:(function() { 
				let tooltip = document.getElementById('tooltip');
				tooltip.innerHTML = '';
				let estado = document.createElement('p');
				estado.style.font = '${font}';
				estado.style.margin = 0;
				estado.innerHTML = '${numAtdSetor}';
				tooltip.appendChild(estado);
				tooltip.style.display = 'block';
				tooltip.style.left = evt.pageX - 30 + 'px';
				tooltip.style.top = evt.pageY  - 20 + 'px';
			})()"`
		)
	};

	render() {
		return (
			<Grid container justify={'center'} className={'border-cinza'} style={{height: '100%'}}>
				<MapaBrasil dados={this.state.dados} legenda={this.legenda} switch={this.switch} isSetor={true} disabledSwitch={true} title={'Empresas atendidas por estado'} tooltip={this.tooltipBox} />
			</Grid>
		);
	};

}
