import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import Page from "../../components/page/page";
import API from "../../comm/API";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputSelect from "../../components/inputselect/inputselect";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import GenericButton from "../../components/genericButton/genericButton";
import { CreateMeta } from "./modal";
import "./metas.scss";

export default class Metas extends Component {
  isDR = true;
  isUO = true;
  metasList = []; //todas as metas não organizadas
  unidade = {};
  dr = {};
  meta = {};
  titulo = "";
  metas = { departamentoRegionalList: [], unidadeList: [] }; //metas organizadas utilizadas na exibição das metas

  constructor(props) {
    super(props);
    this.state = {
      unidadeList: [],
      departamentoRegionalList: [],
      isAtendimento: true,
      isProducao: true,
      isReceita: true,
      isDespesa: true,
      metasDR: [],
      metasUO: [],
      unidades: [],
      open: false,
    };
    this.getUnidades();
  }

  showToast = () => {};
  showLoading = () => {};
  closeLoading = () => {};
  getFunctions = (showToast, showLoading, closeLoading) => {
    if (showToast != null) this.showToast = showToast;
    if (showLoading != null) this.showLoading = showLoading;
    if (closeLoading != null) this.closeLoading = closeLoading;
  };

  getMetaExibicaoModel() {
    return {
      unidadeId: null,
      unidadeNome: null,
      produtoLinha: null,
      unidadeIdPai: null,
      ano: new Date().getFullYear(),
      atendimento: [],
      despesa: [],
      horas: [],
      relatorios: [],
      calibracoes: [],
      ensaios: [],
      receita: [],
    };
  }

  getMetaModel(tipoMeta, unidade, ano, produtoLinha) {
    return {
      unidade: unidade,
      tipoMeta: tipoMeta,
      ano: ano,
      produtoLinha: produtoLinha,
      total: 0,
      jan: 0,
      fev: 0,
      mar: 0,
      abr: 0,
      mai: 0,
      jun: 0,
      jul: 0,
      ago: 0,
      set: 0,
      out: 0,
      nov: 0,
      dez: 0,
    };
  }

  getInput = (e) => {
    if (e != null && e.target != null) {
      this.setState({ [e.target.value]: e.target.checked });
    } else if (e != null && e.length === 2) {
      this.dr = e;
      this.unidade = {};
      this.setState(this.state);
    } else if (e != null && e.length === 3) {
      this.unidade = e;
      this.setState(this.state);
    }
  };

  filtrarUnidadeSelect = (obj) => {
    return obj[2] === this.dr[0];
  };

  filtrarUnidadeDR = (obj) => {
    if (
      this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN" &&
      (this.dr == null || this.dr.length == null)
    ) {
      return true;
    }
    return obj.unidadeId === this.dr[0];
  };

  filtrarUnidadeUO = (obj) => {
    if (
      (this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DR" ||
        this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN") &&
      (this.unidade == null || this.unidade.length == null)
    ) {
      return obj.unidadeIdPai === this.dr[0];
    }
    return obj.unidadeId === this.unidade[0];
  };

  aplicarFiltro = (e) => {
    this.showLoading();
    if (e != null && e.target != null && e.target.id === "aplicar") {
      if (
        this.props.usuarioConectado != null &&
        this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DR"
      ) {
        this.closeLoading();
        this.setState({
          metasDR: this.metas.departamentoRegionalList,
          metasUO:
            this.unidade.length == null
              ? this.metas.unidadeList
              : this.metas.unidadeList.filter(this.filtrarUnidadeUO),
        });
      } else if (
        this.props.usuarioConectado != null &&
        this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN"
      ) {
        this.closeLoading();
        this.setState({
          metasDR:
            this.dr.length == null
              ? this.metas.departamentoRegionalList
              : this.metas.departamentoRegionalList.filter(
                  this.filtrarUnidadeDR
                ),
          metasUO:
            this.dr.length == null
              ? []
              : this.metas.unidadeList.filter(this.filtrarUnidadeUO),
        });
      } else {
        this.closeLoading();
      }
    } else if (
      (e != null && e.target != null && e.target.id === "verTodos") ||
      e === "inicial"
    ) {
      if (
        this.props.usuarioConectado != null &&
        this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DR"
      ) {
        this.unidade = {};
        this.closeLoading();
        this.setState({
          metasDR: this.metas.departamentoRegionalList,
          metasUO: this.metas.unidadeList,
        });
      } else if (
        this.props.usuarioConectado != null &&
        this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN"
      ) {
        this.unidade = {};
        this.dr = {};
        this.closeLoading();
        this.setState({
          metasDR: this.metas.departamentoRegionalList,
          metasUO: [],
        });
      } else if (
        this.props.usuarioConectado != null &&
        this.props.usuarioConectado.unidade.tipoUnidade.sigla === "UO"
      ) {
        this.closeLoading();
        this.setState({ metasDR: [], metasUO: this.metas.unidadeList });
      } else {
        this.closeLoading();
      }
    } else {
      this.closeLoading();
    }
  };

  getUnidades = () => {
    API.get("rest/sistema/Metas/selectBox")
      .then((res) => {
        this.getMetas(res);
      })
      .catch((error) => {
        console.log(error);
        this.showToast(
          "Erro ao realizar GET das unidade. Por favor, entre em contato com o suporte."
        );
      });
  };

  getMetas(res) {
    if (
      this.props.usuarioConectado != null &&
      this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DR"
    ) {
      this.isUO = false;
      this.isDR = true;
      this.dr = res.data.departamentoRegionalList[0];
      this.unidade = {};
    } else if (
      this.props.usuarioConectado != null &&
      this.props.usuarioConectado.unidade.tipoUnidade.sigla === "UO"
    ) {
      this.isUO = true;
      this.isDR = false;
      this.dr = res.data.departamentoRegionalList[0];
      this.unidade = res.data.unidadeList[0];
    } else if (
      this.props.usuarioConectado != null &&
      this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN"
    ) {
      this.isUO = false;
      this.isDR = false;
      this.dr = {};
      this.unidade = {};
    }
    API.get("rest/sistema/Metas/metas")
      .then((resp) => {
        this.setState({
          unidadeList: res.data.unidadeList,
          departamentoRegionalList: res.data.departamentoRegionalList,
        });
        this.metasList = resp.data;
        for (let i = 0; i < res.data.departamentoRegionalList.length; i++) {
          //para cada DR
          let unidadeDR = res.data.departamentoRegionalList[i];
          let metaExibicao = this.getMetaExibicaoModel();
          metaExibicao.unidadeId = unidadeDR[0];
          metaExibicao.unidadeNome = unidadeDR[1];
          metaExibicao.unidadeIdPai = 29;
          metaExibicao.produtoLinha = 2;
          metaExibicao.atendimento = this.encontrarMeta(
            "Atendimento",
            unidadeDR[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.receita = this.encontrarMeta(
            "Receita",
            unidadeDR[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.despesa = this.encontrarMeta(
            "Despesa",
            unidadeDR[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.horas = this.encontrarMeta(
            "Horas",
            unidadeDR[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.calibracoes = this.encontrarMeta(
            "Calibrações",
            unidadeDR[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.ensaios = this.encontrarMeta(
            "Ensaios",
            unidadeDR[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.relatorios = this.encontrarMeta(
            "Relatórios",
            unidadeDR[0],
            metaExibicao.ano,
            2
          );
          this.metas.departamentoRegionalList.push(metaExibicao);
        }
        for (let i = 0; i < res.data.unidadeList.length; i++) {
          //para cada UO
          let unidadeUO = res.data.unidadeList[i];
          let metaExibicao = this.getMetaExibicaoModel();
          metaExibicao.unidadeId = unidadeUO[0];
          metaExibicao.unidadeNome = unidadeUO[1];
          metaExibicao.unidadeIdPai = unidadeUO[2];
          metaExibicao.produtoLinha = 2;
          metaExibicao.atendimento = this.encontrarMeta(
            "Atendimento",
            unidadeUO[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.receita = this.encontrarMeta(
            "Receita",
            unidadeUO[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.despesa = this.encontrarMeta(
            "Despesa",
            unidadeUO[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.horas = this.encontrarMeta(
            "Horas",
            unidadeUO[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.calibracoes = this.encontrarMeta(
            "Calibrações",
            unidadeUO[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.ensaios = this.encontrarMeta(
            "Ensaios",
            unidadeUO[0],
            metaExibicao.ano,
            2
          );
          metaExibicao.relatorios = this.encontrarMeta(
            "Relatórios",
            unidadeUO[0],
            metaExibicao.ano,
            2
          );
          this.metas.unidadeList.push(metaExibicao);
        }
        this.aplicarFiltro("inicial");
      })
      .catch((error) => {
        console.log(error);
        this.closeLoading();
        this.showToast(
          "Erro ao realizar GET das metas. Entre em contato com o suporte.",
          "error"
        );
      });
  }

  atualizaProdutoLinha = (unidade, unidadeTipo, produtoLinha) => {
    this.showLoading();
    this.atualizaMetaExibicao(unidade, null, unidadeTipo, produtoLinha);
  };

  atualizaAno = (unidade, novoAno, unidadeTipo) => {
    this.showLoading();
    this.atualizaMetaExibicao(unidade, novoAno, unidadeTipo, null);
  };

  atualizaMetaExibicao = (unidade, ano, unidadeTipo, produtoLinha) => {
    let index = -1;
    if (unidadeTipo === "DR") {
      index = this.metas.departamentoRegionalList
        .map((p) => p.unidadeId)
        .indexOf(unidade);
      if (produtoLinha != null)
        this.metas.departamentoRegionalList[index].produtoLinha = produtoLinha;
      else
        produtoLinha = this.metas.departamentoRegionalList[index].produtoLinha;
      if (ano != null) this.metas.departamentoRegionalList[index].ano = ano;
      else ano = this.metas.departamentoRegionalList[index].ano;
      this.metas.departamentoRegionalList[index].atendimento =
        this.encontrarMeta("Atendimento", unidade, ano, produtoLinha);
      this.metas.departamentoRegionalList[index].receita = this.encontrarMeta(
        "Receita",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.departamentoRegionalList[index].despesa = this.encontrarMeta(
        "Despesa",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.departamentoRegionalList[index].horas = this.encontrarMeta(
        "Horas",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.departamentoRegionalList[index].calibracoes =
        this.encontrarMeta("Calibrações", unidade, ano, produtoLinha);
      this.metas.departamentoRegionalList[index].ensaios = this.encontrarMeta(
        "Ensaios",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.departamentoRegionalList[index].relatorios =
        this.encontrarMeta("Relatórios", unidade, ano, produtoLinha);
      this.closeLoading();
      this.setState(this.state);
    } else if (unidadeTipo === "UO") {
      index = this.metas.unidadeList.map((p) => p.unidadeId).indexOf(unidade);
      if (produtoLinha != null)
        this.metas.unidadeList[index].produtoLinha = produtoLinha;
      else produtoLinha = this.metas.unidadeList[index].produtoLinha;
      if (ano != null) this.metas.unidadeList[index].ano = ano;
      else ano = this.metas.unidadeList[index].ano;
      this.metas.unidadeList[index].atendimento = this.encontrarMeta(
        "Atendimento",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.unidadeList[index].receita = this.encontrarMeta(
        "Receita",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.unidadeList[index].despesa = this.encontrarMeta(
        "Despesa",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.unidadeList[index].horas = this.encontrarMeta(
        "Horas",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.unidadeList[index].calibracoes = this.encontrarMeta(
        "Calibrações",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.unidadeList[index].ensaios = this.encontrarMeta(
        "Ensaios",
        unidade,
        ano,
        produtoLinha
      );
      this.metas.unidadeList[index].relatorios = this.encontrarMeta(
        "Relatórios",
        unidade,
        ano,
        produtoLinha
      );
      this.closeLoading();
      this.setState(this.state);
    }
  };

  encontrarMeta(tipoMeta, unidade, ano, produtoLinha) {
    let metaUnidade = this.getMetaModel(tipoMeta, unidade, ano, produtoLinha);
    if (this.metasList != null) {
      var obj = this.metasList.filter(
        (x) =>
          x.tipoMeta === tipoMeta &&
          x.produtoLinha === produtoLinha &&
          x.unidade === unidade &&
          x.ano === ano
      )[0];
      if (obj != null) {
        metaUnidade.total = obj.total;
        metaUnidade.jan = obj.jan ? obj.jan : 0;
        metaUnidade.fev = obj.fev ? obj.fev : 0;
        metaUnidade.mar = obj.mar ? obj.mar : 0;
        metaUnidade.abr = obj.abr ? obj.abr : 0;
        metaUnidade.mai = obj.mai ? obj.mai : 0;
        metaUnidade.jun = obj.jun ? obj.jun : 0;
        metaUnidade.jul = obj.jul ? obj.jul : 0;
        metaUnidade.ago = obj.ago ? obj.ago : 0;
        metaUnidade.set = obj.set ? obj.set : 0;
        metaUnidade.out = obj.out ? obj.out : 0;
        metaUnidade.nov = obj.nov ? obj.nov : 0;
        metaUnidade.dez = obj.dez ? obj.dez : 0;
        metaUnidade.id = obj.id;
      }
    }
    return metaUnidade;
  }

  handleModal = (obj, tipoMeta) => {
    if (
      this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DR" &&
      obj != null &&
      tipoMeta != null &&
      obj.unidadeIdPai !== 29
    ) {
      this.meta = this.encontrarMeta(
        tipoMeta,
        obj.unidadeId,
        obj.ano,
        obj.produtoLinha
      );
      this.titulo = obj.unidadeNome;
      this.setState({ open: true });
    } else if (obj == null || tipoMeta == null) {
      this.setState({ open: false });
    }
  };

  montarMetas = (metas) => {
    return metas.map((unidade, index) => (
      <Grid
        container
        key={"grid1" + unidade.unidadeId}
        className={"grid-metas"}
      >
        <Grid item xs={12}>
          {unidade.unidadeIdPai === 29 ? (
            <span className={"tipo-unidade-span dr"}>DR</span>
          ) : (
            <span className={"tipo-unidade-span uo"}>UO</span>
          )}
          <label
            className={
              "tipo-unidade-label" +
              (unidade.unidadeIdPai === 29 ? " dr" : " uo")
            }
          >
            {unidade.unidadeNome}
          </label>
        </Grid>
        <Grid item xs={12} className={"tabs"} key={"gird2" + unidade.unidadeId}>
          <AppBar position="static">
            <Tabs
              value={unidade.produtoLinha}
              onChange={(e, newValue) =>
                this.atualizaProdutoLinha(
                  unidade.unidadeId,
                  unidade.unidadeIdPai === 29 ? "DR" : "UO",
                  newValue
                )
              }
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                key={"NavbarMetas0"}
                value={2}
                label={"Pesquisa, Desenvolvimento e Inovação"}
              ></Tab>
              <Tab
                key={"NavbarMetas1"}
                value={4}
                label={"Serviços Técnicos Especializados"}
              ></Tab>
              <Tab key={"NavbarMetas2"} value={3} label={"Metrologia"}></Tab>
              <Tab key={"NavbarMetas3"} value={1} label={"Consultoria"}></Tab>
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12} className={"metas-table"}>
          <Table id="tableBody" key={"table" + unidade.unidadeId}>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell style={{ minWidth: "140px" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/arrow-full-left.svg"
                    }
                    alt={"arrow"}
                    onClick={(e) =>
                      this.atualizaAno(
                        unidade.unidadeId,
                        unidade.ano - 1,
                        unidade.unidadeIdPai === 29 ? "DR" : "UO"
                      )
                    }
                    id={"ano-left"}
                  />
                  {unidade.ano}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/icons/arrow-full-right.svg"
                    }
                    alt={"arrow"}
                    onClick={(e) =>
                      this.atualizaAno(
                        unidade.unidadeId,
                        unidade.ano + 1,
                        unidade.unidadeIdPai === 29 ? "DR" : "UO"
                      )
                    }
                    id={"ano-right"}
                  />
                </TableCell>
                <TableCell>Jan</TableCell>
                <TableCell>Fev</TableCell>
                <TableCell>Mar</TableCell>
                <TableCell>Abr</TableCell>
                <TableCell>Mai</TableCell>
                <TableCell>Jun</TableCell>
                <TableCell>Jul</TableCell>
                <TableCell>Ago</TableCell>
                <TableCell>Set</TableCell>
                <TableCell>Out</TableCell>
                <TableCell>Nov</TableCell>
                <TableCell>Dez</TableCell>
              </TableRow>
            </TableHead>
            <TableBody key={"body-" + unidade.unidadeId}>
              {this.state.isAtendimento ? (
                <TableRow
                  onClick={(e) => this.handleModal(unidade, "Atendimento")}
                  className={
                    "on-click-" +
                    (unidade.unidadeIdPai === 29 ||
                    this.props.usuarioConectado.unidade.tipoUnidade.sigla !==
                      "DR"
                      ? "dr"
                      : "uo")
                  }
                >
                  <TableCell className={"tipo-meta"}>Atendimento</TableCell>
                  <TableCell>{unidade.atendimento.total}</TableCell>
                  <TableCell>{unidade.atendimento.jan}</TableCell>
                  <TableCell>{unidade.atendimento.fev}</TableCell>
                  <TableCell>{unidade.atendimento.mar}</TableCell>
                  <TableCell>{unidade.atendimento.abr}</TableCell>
                  <TableCell>{unidade.atendimento.mai}</TableCell>
                  <TableCell>{unidade.atendimento.jun}</TableCell>
                  <TableCell>{unidade.atendimento.jul}</TableCell>
                  <TableCell>{unidade.atendimento.ago}</TableCell>
                  <TableCell>{unidade.atendimento.set}</TableCell>
                  <TableCell>{unidade.atendimento.out}</TableCell>
                  <TableCell>{unidade.atendimento.nov}</TableCell>
                  <TableCell>{unidade.atendimento.dez}</TableCell>
                </TableRow>
              ) : null}
              {this.state.isProducao ? (
                <TableRow
                  onClick={(e) => this.handleModal(unidade, "Horas")}
                  className={
                    "on-click-" +
                    (unidade.unidadeIdPai === 29 ||
                    this.props.usuarioConectado.unidade.tipoUnidade.sigla !==
                      "DR"
                      ? "dr"
                      : "uo")
                  }
                >
                  <TableCell className={"tipo-meta"}>
                    Produção (horas)
                  </TableCell>
                  <TableCell>{unidade.horas.total} h</TableCell>
                  <TableCell>{unidade.horas.jan} h</TableCell>
                  <TableCell>{unidade.horas.fev} h</TableCell>
                  <TableCell>{unidade.horas.mar} h</TableCell>
                  <TableCell>{unidade.horas.abr} h</TableCell>
                  <TableCell>{unidade.horas.mai} h</TableCell>
                  <TableCell>{unidade.horas.jun} h</TableCell>
                  <TableCell>{unidade.horas.jul} h</TableCell>
                  <TableCell>{unidade.horas.ago} h</TableCell>
                  <TableCell>{unidade.horas.set} h</TableCell>
                  <TableCell>{unidade.horas.out} h</TableCell>
                  <TableCell>{unidade.horas.nov} h</TableCell>
                  <TableCell>{unidade.horas.dez} h</TableCell>
                </TableRow>
              ) : null}
              {unidade.produtoLinha === 3 && this.state.isProducao ? (
                <TableRow
                  onClick={(e) => this.handleModal(unidade, "Calibrações")}
                  className={
                    "on-click-" +
                    (unidade.unidadeIdPai === 29 ||
                    this.props.usuarioConectado.unidade.tipoUnidade.sigla !==
                      "DR"
                      ? "dr"
                      : "uo")
                  }
                >
                  <TableCell className={"tipo-meta"}>
                    Produção (calibrações)
                  </TableCell>
                  <TableCell>{unidade.calibracoes.total}</TableCell>
                  <TableCell>{unidade.calibracoes.jan}</TableCell>
                  <TableCell>{unidade.calibracoes.fev}</TableCell>
                  <TableCell>{unidade.calibracoes.mar}</TableCell>
                  <TableCell>{unidade.calibracoes.abr}</TableCell>
                  <TableCell>{unidade.calibracoes.mai}</TableCell>
                  <TableCell>{unidade.calibracoes.jun}</TableCell>
                  <TableCell>{unidade.calibracoes.jul}</TableCell>
                  <TableCell>{unidade.calibracoes.ago}</TableCell>
                  <TableCell>{unidade.calibracoes.set}</TableCell>
                  <TableCell>{unidade.calibracoes.out}</TableCell>
                  <TableCell>{unidade.calibracoes.nov}</TableCell>
                  <TableCell>{unidade.calibracoes.dez}</TableCell>
                </TableRow>
              ) : null}
              {unidade.produtoLinha === 3 && this.state.isProducao ? (
                <TableRow
                  onClick={(e) => this.handleModal(unidade, "Ensaios")}
                  className={
                    "on-click-" +
                    (unidade.unidadeIdPai === 29 ||
                    this.props.usuarioConectado.unidade.tipoUnidade.sigla !==
                      "DR"
                      ? "dr"
                      : "uo")
                  }
                >
                  <TableCell className={"tipo-meta"}>
                    Produção (ensaios)
                  </TableCell>
                  <TableCell>{unidade.ensaios.total}</TableCell>
                  <TableCell>{unidade.ensaios.jan}</TableCell>
                  <TableCell>{unidade.ensaios.fev}</TableCell>
                  <TableCell>{unidade.ensaios.mar}</TableCell>
                  <TableCell>{unidade.ensaios.abr}</TableCell>
                  <TableCell>{unidade.ensaios.mai}</TableCell>
                  <TableCell>{unidade.ensaios.jun}</TableCell>
                  <TableCell>{unidade.ensaios.jul}</TableCell>
                  <TableCell>{unidade.ensaios.ago}</TableCell>
                  <TableCell>{unidade.ensaios.set}</TableCell>
                  <TableCell>{unidade.ensaios.out}</TableCell>
                  <TableCell>{unidade.ensaios.nov}</TableCell>
                  <TableCell>{unidade.ensaios.dez}</TableCell>
                </TableRow>
              ) : null}
              {unidade.produtoLinha === 3 && this.state.isProducao ? (
                <TableRow
                  onClick={(e) => this.handleModal(unidade, "Relatórios")}
                  className={
                    "on-click-" +
                    (unidade.unidadeIdPai === 29 ||
                    this.props.usuarioConectado.unidade.tipoUnidade.sigla !==
                      "DR"
                      ? "dr"
                      : "uo")
                  }
                >
                  <TableCell className={"tipo-meta"}>
                    Produção (relatórios)
                  </TableCell>
                  <TableCell>{unidade.relatorios.total}</TableCell>
                  <TableCell>{unidade.relatorios.jan}</TableCell>
                  <TableCell>{unidade.relatorios.fev}</TableCell>
                  <TableCell>{unidade.relatorios.mar}</TableCell>
                  <TableCell>{unidade.relatorios.abr}</TableCell>
                  <TableCell>{unidade.relatorios.mai}</TableCell>
                  <TableCell>{unidade.relatorios.jun}</TableCell>
                  <TableCell>{unidade.relatorios.jul}</TableCell>
                  <TableCell>{unidade.relatorios.ago}</TableCell>
                  <TableCell>{unidade.relatorios.set}</TableCell>
                  <TableCell>{unidade.relatorios.out}</TableCell>
                  <TableCell>{unidade.relatorios.nov}</TableCell>
                  <TableCell>{unidade.relatorios.dez}</TableCell>
                </TableRow>
              ) : null}
              {this.state.isReceita ? (
                <TableRow
                  onClick={(e) => this.handleModal(unidade, "Receita")}
                  className={
                    "on-click-" +
                    (unidade.unidadeIdPai === 29 ||
                    this.props.usuarioConectado.unidade.tipoUnidade.sigla !==
                      "DR"
                      ? "dr"
                      : "uo")
                  }
                >
                  <TableCell className={"tipo-meta"}>Receita</TableCell>
                  <TableCell>
                    R$ {unidade.receita.total.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.jan.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.fev.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.mar.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.abr.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.mai.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.jun.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.jul.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.ago.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.set.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.out.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.nov.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.receita.dez.toLocaleString("pt-BR")}
                  </TableCell>
                </TableRow>
              ) : null}
              {this.state.isDespesa ? (
                <TableRow
                  onClick={(e) => this.handleModal(unidade, "Despesa")}
                  className={
                    "on-click-" +
                    (unidade.unidadeIdPai === 29 ||
                    this.props.usuarioConectado.unidade.tipoUnidade.sigla !==
                      "DR"
                      ? "dr"
                      : "uo")
                  }
                >
                  <TableCell className={"tipo-meta"}>Despesa</TableCell>
                  <TableCell>
                    R$ {unidade.despesa.total.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.jan.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.fev.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.mar.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.abr.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.mai.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.jun.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.jul.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.ago.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.set.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.out.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.nov.toLocaleString("pt-BR")}
                  </TableCell>
                  <TableCell>
                    R$ {unidade.despesa.dez.toLocaleString("pt-BR")}
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    ));
  };

  salvarMeta = (meta) => {
    this.showLoading();
    if (
      meta.unidade != null &&
      meta.ano != null &&
      meta.produtoLinha != null &&
      meta.tipoMeta != null
    ) {
      let id = meta.id ? meta.id : "";
      API.post("rest/sistema/Metas/" + id, meta)
        .then((res) => {
          //atualiza valores na lista de metas
          let json = res.data;
          let index = this.metasList.map((p) => p.id).indexOf(json.metas[0].id);
          if (index > -1) this.metasList.splice(index, 1, json.metas[0]);
          else this.metasList.push(json.metas[0]);
          index = this.metasList.map((p) => p.id).indexOf(json.metas[1].id);
          if (index > -1) this.metasList.splice(index, 1, json.metas[1]);
          else this.metasList.push(json.metas[1]);
          index = this.metas.unidadeList
            .map((p) => p.unidadeId)
            .indexOf(json.metas[0].unidade);
          let produtoLinhaUO = this.metas.unidadeList[index].produtoLinha;
          let anoUO = this.metas.unidadeList[index].ano;
          index = this.metas.departamentoRegionalList
            .map((p) => p.unidadeId)
            .indexOf(json.metas[1].unidade);
          let produtoLinhaDR =
            this.metas.departamentoRegionalList[index].produtoLinha;
          let anoDR = this.metas.departamentoRegionalList[index].ano;
          if (produtoLinhaDR === produtoLinhaUO && anoDR === anoUO)
            //atualiza exibição DR
            this.atualizaMetaExibicao(
              json.metas[1].unidade,
              json.metas[1].ano,
              "DR",
              json.metas[1].produtoLinha
            );
          this.atualizaMetaExibicao(
            json.metas[0].unidade,
            json.metas[0].ano,
            "UO",
            json.metas[0].produtoLinha
          ); //atualiza exibição UO
          this.closeLoading();
          this.setState(
            { open: false },
            this.showToast("Meta salva com sucesso!", "success")
          );
        })
        .catch((error) => {
          console.log(error);
          this.closeLoading();
          this.setState(
            { open: false },
            this.showToast(
              "Falha ao salvar a meta, por favor contate o suporte.",
              "error"
            )
          );
        });
    } else {
      this.closeLoading();
      this.showToast("Meta inválida, por favor contate o suporte.", "error");
    }
  };

  render() {
    return (
      <Page
        icon={"indicadores.svg"}
        label={"Gerenciamento de Metas"}
        getFunctions={this.getFunctions}
        usuarioConectado={this.props.usuarioConectado}
      >
        <Grid container className={"indicador-metas"}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4} xl={3}>
                <InputSelect
                  label={"Departamento Regional"}
                  suggestions={this.state.departamentoRegionalList}
                  itemLabel={"1"}
                  getInput={this.getInput}
                  id={"dr"}
                  itemKey={"0"}
                  initialSelectedItem={this.dr}
                  key={"dr"}
                  disabled={this.isDR || this.isUO}
                  value={this.dr}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} xl={3}>
                <InputSelect
                  label={"Unidade"}
                  suggestions={this.state.unidadeList.filter(
                    this.filtrarUnidadeSelect
                  )}
                  itemLabel={"1"}
                  getInput={this.getInput}
                  id={"unidade"}
                  itemKey={"0"}
                  initialSelectedItem={this.unidade}
                  key={"unidade"}
                  disabled={this.isUO || this.dr[0] == null}
                  value={this.unidade}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} xl={2}>
                <Grid container className={"grid-filtro"}>
                  <Grid item xs={12} sm={4}>
                    <GenericButton
                      color={"darkBlue"}
                      subClass={"basic-button"}
                      label={"Aplicar"}
                      click={this.aplicarFiltro}
                      id={"aplicar"}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <GenericButton
                      color={"darkBlue"}
                      subClass={"basic-button darkBlue-outline"}
                      label={"Ver todos"}
                      click={this.aplicarFiltro}
                      id={"verTodos"}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={6} xl={4}>
                <label id="labelMetas">Metas</label>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isAtendimento}
                          onChange={this.getInput}
                          value="isAtendimento"
                          className={"checkbox"}
                        />
                      }
                      label="Atendimento"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isReceita}
                          onChange={this.getInput}
                          value="isReceita"
                          className={"checkbox"}
                        />
                      }
                      label="Receita"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isProducao}
                          onChange={this.getInput}
                          value="isProducao"
                          className={"checkbox"}
                        />
                      }
                      label="Produção"
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.isDespesa}
                          onChange={this.getInput}
                          value="isDespesa"
                          className={"checkbox"}
                        />
                      }
                      label="Despesa"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.state.metasDR.length > 0
            ? this.montarMetas(this.state.metasDR)
            : null}
          {this.state.metasUO.length > 0
            ? this.montarMetas(this.state.metasUO)
            : null}
          {this.state.open ? (
            <Grid item xs={12}>
              <CreateMeta
                titulo={this.titulo}
                meta={this.meta}
                open={this.state.open}
                handleModal={this.handleModal}
                salvarMeta={this.salvarMeta}
              />
            </Grid>
          ) : null}
        </Grid>
      </Page>
    );
  }
}
