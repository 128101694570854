/* eslint-disable */
// TODO: REMOVER OS WARNINGS
import {
    AppBar,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Tab,
    Tabs
} from "@material-ui/core"
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import React, { Component } from 'react'

import API, { BASE_URL, BASE_URL_NEW_BACKEND } from '../../comm/API'
import Masks from '../../comm/masks'
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany'
import GenericButton from '../../components/genericButton/genericButton'
import InputSelect from '../../components/inputselect/inputselect'
import Page from '../../components/page/page'
import AutoComplete from './../../components/autoComplete/autoComplete'
import SimpleModal from './../../components/modal/modal'
import './dashboard.scss'

export default class Dashboard extends Component {

    constructor(props) {
        super(props);
        let anoList = [];
        let currentYear = new Date().getFullYear();
        for (var i = currentYear; i >= 2014; i--) {
            anoList.push(i);
        }
        this.state = {
            usuario: null,
            userUnidade: {},
            podeVerCard: false,
            valoresCards: [],
            selectBoxList: [],
            filtroList: [],
            filtro: {},
            obj: {},
            canApplyFilter: false,
            unidade: false,
            unidadeList: [],
            savedFilterColaboradores: false,
            colaborador: false,
            canSave: false,
            tipoGrafico: '',
            chart: { exporting: false },
            legendas: {},
            statusTitle: '',
            situationTitle: '',
            dataInicioTitle: '',
            dataFimTitle: '',
            tab: 0,
            ano: currentYear,
            mes: new Date().getMonth(),
            anoList: anoList,
            mesList: [
                { id: 0, nome: "Jan" }, { id: 1, nome: "Fev" }, { id: 2, nome: "Mar" },
                { id: 3, nome: "Abr" }, { id: 4, nome: "Mai" }, { id: 5, nome: "Jun" },
                { id: 6, nome: "Jul" }, { id: 7, nome: "Ago" }, { id: 8, nome: "Set" },
                { id: 9, nome: "Out" }, { id: 10, nome: "Nov" }, { id: 11, nome: "Dez" }],
            modalFormData: {
                mesAnterior: 0,
                anoAnterior: 0,
                mesPosterior: 0,
                anoPosterior: 0,
                dadosVisualizados: 'Produção',
                produtoCategoria: false,
                produtoNacional: false,
                produtoRegional: false,
                porteEmpresa: false,
                tipoCliente: false,
                nomeEmpresa: false,
                origemRecurso: false,
                aguardandoValidacao: false,
                validado: false,
                comCritica: false,
                envioManual: false,
                departamentoRegional: false,
                unidades: false,
                unidadesList: [],
                colaboradores: false,
                colaboradoresList: [],
                produtoCategoriaItem: '',
                produtoNacionalItem: '',
                produtoRegionalItem: '',
                porteEmpresaItem: '',
                tipoClienteItem: '',
                nomeEmpresaItem: '',
                origemRecursoItem: '',
                departamentoRegionalItem: '',
                emprospeccao: true,
                negociacao: true,
                aceito: true,
                recusado: true,
                concluido: true,
                cancelado: true,
                emexecucao: true,
                arquivado: true,
                emAnalise: false,
                conforme: false,
                naoConforme: false
            },
            dataHoje: new Date(),
            isEditing: false,
            colaboradorList: [],
            isUO: false,
            modalFiltro: false
        }

        let usuario = this.props.usuarioConectado;

        this.state.usuario = usuario;
        this.state.userUnidade = usuario.unidade !== undefined ? usuario.unidade : undefined;

        if (this.state.usuario.perfilUsuario.nome === 'CONSULTOR' || this.state.usuario.perfilUsuario.nome === 'EXECUTOR' || this.state.userUnidade.tipoUnidade.sigla === 'DN') { //Usuário com perfil consultor ou executor não pode ver os cards
            this.state.podeVerCard = false;
        } else {
            this.state.podeVerCard = true;
            API.get('rest/sistema/Dashboard/cards').then(json => {
                this.state.valoresCards = json.data;
            }).catch(error => {
                console.log(error);
                this.showToast('Erro ao buscar cards do dashboard. Favor, entrar em contato com o suporte.', 'error');
            });
        }
        Highcharts.setOptions({
            lang: {
                decimalPoint: ',',
                thousandsSep: '.'
            }
        });
        API.get('rest/sistema/Dashboard/selectBox').then(jsonList => {
            this.state.selectBoxList = jsonList.data;

            if (this.state.selectBoxList.unidadeList === undefined) this.state.selectBoxList = { ...this.state.selectBoxList, unidadeList: [] };
            API.get('rest/sistema/Filtro?ordenarPor=id&usuario=' + usuario.id).then(filtroList => {

                this.state.filtroList = filtroList.data;
                this.switchLinha(undefined);

            }).catch(error => {
                console.log(error);
                this.showToast('Erro ao buscar lista de filtros. Favor, entrar em contato com o suporte.', 'error');
            });
        }).catch(error => {
            console.log(error);
            this.showToast('Erro ao buscar lista de opções. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    switchLinha = (idLinha) => {
        let filtro = this.myFilter(this.state.filtroList, 'produtoLinha', idLinha, false);

        if (filtro != null) {
            this.state.filtro = filtro;

            if (this.state.filtro.atendimentoStatusSI == null || this.state.filtro.atendimentoStatusSI === "") {
                this.state.filtro = { ...this.state.filtro, atendimentoStatusSI: [] };
            }
            if (this.state.filtro.status == null || this.state.filtro.status === "") {
                this.state.filtro = { ...this.state.filtro, status: [] };
            }
            this.reloadData();
        } else {
            let dataMesAnterior = new Date();
            dataMesAnterior.setDate(1);
            dataMesAnterior.setMonth(this.state.dataHoje.getMonth() - 1);

            this.state.filtro = {
                usuario: this.state.usuario.id,
                produtoLinha: idLinha,
                tipoGrafico: "Quantidade",
                dataInicio: dataMesAnterior,
                dataFim: this.state.dataHoje,
                status: [],
                unidadeFederativa: null,
                unidade: null,
                produtoCategoria: null,
                produtoNacional: null,
                produtoRegional: null,
                tipoPrevisaoReceita: null,
                cliente: null,
                porteCliente: null,
                atendimentoStatusSI: [],
                tipoPessoa: null,
                unidades: null,
                colaboradores: null,
                emAnalise: false,
                conforme: false,
                naoConforme: false
            };
            this.setFiltroUnidade();
        }

        this.state.canApplyFilter = this.state.filtro.dataInicio <= this.state.filtro.dataFim;
        this.aplicarFiltro(false);
    }

    reloadData = () => {
        let dataInicio = new Date(this.state.filtro.dataInicio);
        let dataFim = new Date(this.state.filtro.dataFim);
        dataInicio.setDate(1);
        dataFim.setDate(1);

        this.state.filtro = { ...this.state.filtro, dataInicio, dataFim };

        if (this.state.userUnidade !== undefined && this.state.userUnidade.tipoUnidade.sigla === 'UO') {
            this.state.isUO = true;
        }
        this.stringToArrayStatus();
        this.unidadesToArray();
        this.colaboradoresToArray();
    }

    stringToArrayStatus = () => {
        if (typeof (this.state.filtro.status) == "string") {
            if (this.state.filtro.status !== "") {
                let status = (this.state.filtro.status).split(",");
                for (let i = 0; i < status.length; i++) {
                    status[i] = parseInt(status[i]);
                }
                this.state.filtro = { ...this.state.filtro, status };
            } else {
                this.state.filtro = { ...this.state.filtro, status: [] };
            }
        }

        if (typeof (this.state.filtro.atendimentoStatusSI) == "string") {
            if (this.state.filtro.atendimentoStatusSI !== "") {
                let atendimentoStatusSI = (this.state.filtro.atendimentoStatusSI).split(",");
                for (let i = 0; i < atendimentoStatusSI.length; i++) {
                    atendimentoStatusSI[i] = parseInt(atendimentoStatusSI[i]);
                }
                this.state.filtro = { ...this.state.filtro, atendimentoStatusSI };
            } else {
                this.state.filtro = { ...this.state.filtro, atendimentoStatusSI: [] };
            }
        }
    }

    unidadesToArray = () => {
        if (this.state.filtro.unidades) {
            let arr = this.state.filtro.unidades.toString().split(",");
            this.state.unidade = true;
            arr.forEach((val, i) => {
                let contem = this.state.selectBoxList.unidadeList.some((value) => { return value && arr[i] === value[0] });
                if (!contem) {
                    let unid = this.state.selectBoxList.unidadeList.filter(function (value) { return value && arr[i] === value[0] });
                    let unidadeList = this.state.selectBoxList.unidadeList.push(unid[0]);
                    this.state.unidadeList = unidadeList;
                }
            });
        }
    }

    colaboradoresToArray = () => {
        if (this.state.filtro.colaboradores) {
            let arr = this.state.filtro.colaboradores.split(",");
            this.state.savedFilterColaboradores = true;
            this.state.colaborador = true;
            arr.forEach((val, i) => {
                var contem = this.state.selectBoxList.colaboradorList.some((value) => { return arr[i] == value[0] });
                if (!contem) {
                    var col = this.state.selectBoxList.colaboradorList.filter((value) => { return arr[i] == value[0] });
                    let colaboradorList = this.state.colaboradorList.push(col[0]);
                    this.state.colaboradorList = colaboradorList;
                }
            });
        }
    }

    setFiltroUnidade = () => {
        if (this.state.userUnidade !== undefined && (this.state.userUnidade.tipoUnidade.sigla === 'UO' || this.state.userUnidade.tipoUnidade.sigla === 'DR')) {
            this.state.filtro = { ...this.state.filtro, unidadeFederativa: this.state.userUnidade.id };
        }
        if (this.state.userUnidade !== undefined && this.state.userUnidade.tipoUnidade.sigla === 'UO') {
            this.state.filtro = { ...this.state.filtro, unidade: [this.state.userUnidade.id, this.state.userUnidade.descricao], unidadeFederativa: this.state.userUnidade.unidadePai.id, unidades: this.state.userUnidade.id };
            this.state.isUO = true;
        }
    }

    aplicarFiltro = (canSave) => {
        this.showLoading();
        this.refreshTitle();

        let filtro = this.state.filtro;
        filtro.atendimentoStatusSI = filtro.atendimentoStatusSI.toString();
        filtro.status = filtro.status.toString();

        API.post('rest/sistema/Dashboard/novoGrafico', filtro).then(result => {
            result = result.data;
            result.series.forEach(serie => {
                serie.data = serie.data.map(a => parseFloat(Masks.maskDecimal(a, 2)));
            });

            this.setState({ chart: this.loadChartConfigs(filtro.tipoGrafico, '', result.categories, result.series) });
            API.post('rest/sistema/Dashboard/novaLegenda', filtro).then(result => {
                result = result.data;

                this.setState({ legendas: result });
                this.stringToArrayStatus();
                this.closeLoading();

            }).catch(error => {
                console.log(error);
                this.showToast('Erro ao registrar legenda. Favor, entrar em contato com o suporte.', 'error');
                this.closeLoading();
            });
        }).catch(error => {
            console.log(error);
            this.showToast('Erro ao registrar gráfico. Favor, entrar em contato com o suporte.', 'error');
            this.closeLoading();
        });
    }

    refreshTitle = () => {
        let statusTitle = this.state.filtro.status ? this.state.filtro.status.toString() : '';
        statusTitle = statusTitle.replace('2', ' em prospeccao');
        statusTitle = statusTitle.replace('4', ' aceitos');
        statusTitle = statusTitle.replace('8', ' em execução');
        statusTitle = statusTitle.replace('3', ' em negociação');
        statusTitle = statusTitle.replace('7', ' cancelados');
        statusTitle = statusTitle.replace('9', ' arquivados');
        statusTitle = statusTitle.replace('5', ' recusados');
        statusTitle = statusTitle.replace('6', ' concluídos');
        statusTitle = statusTitle.replace('[', '');
        statusTitle = statusTitle.replace(']', '');
        statusTitle += statusTitle === "" ? "" : ";";

        let situationTitle = this.state.filtro.atendimentoStatusSI.toString();
        situationTitle = situationTitle.replace('7', ' aguardando validação');
        situationTitle = situationTitle.replace('6', ' validado');
        situationTitle = situationTitle.replace('8', ' com crítica');
        situationTitle = situationTitle.replace('10', ' envio Manual');
        situationTitle = situationTitle.replace('[', '');
        situationTitle = situationTitle.replace(']', '');
        situationTitle += situationTitle === "" ? "" : ";";

        this.state.tipoTitle = this.state.filtro.tipoGrafico;
        this.state.statusTitle = statusTitle;
        this.state.situationTitle = situationTitle;
        this.state.dataInicioTitle = this.state.filtro.dataInicio;
        this.state.dataFimTitle = this.state.filtro.dataFim;
    }

    myFilter = (array, expression, value, notComparator) => {
        let result;

        array.forEach(obj => {
            if (notComparator === true) {
                if (eval("obj." + expression) !== value) {
                    result = obj;
                }
            } else {
                if (eval("obj." + expression) === value) {
                    result = obj;
                }
            }
        });

        return result;
    }

    gerarRelatorio = () => {
        this.showLoading();
        let filtro = this.state.filtro;
        filtro.atendimentoStatusSI = filtro.atendimentoStatusSI.toString();
        filtro.status = filtro.status.toString();
        API.post('rest/sistema/Dashboard/relatorio', filtro).then(json => {
            this.stringToArrayStatus();
            this.closeLoading();
            if (json.data.code === 0) {
                window.open(BASE_URL + "rest/sistema/Dashboard/arquivo/" + this.state.filtro.tipoGrafico);
            } else {
                this.showToast("Falha ao gerar o relatório. Por favor, entre com contato com o suporte.", 'error');
            }
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    gerarRelatorioBP = () => {
        this.showLoading();

        try {
            let path = `${BASE_URL_NEW_BACKEND}programa-base-nacional/relatorio/bmp`;
            window.open(path);
            this.closeLoading();

        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }
    }

    
    gerarRelatorioIndicadores = () => {
        this.showLoading();

        try {
            let path = `${BASE_URL_NEW_BACKEND}/relatorio/exportar`;
            window.open(path);
            this.closeLoading();

        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }
    }


    loadChartConfigs = (tipo, titulo, eixoX, eixoY) => {
        tipo = tipo === "Quantidade" ? 'column' : 'line';
        return {
            chart: {
                type: tipo
            },
            credits: {
                enabled: false
            },
            title: {
                text: titulo,
                align: 'left',
                x: 70,
                style: {
                    color: '#eeeeee',
                    "fontSize": '12px',
                    fontStyle: 'italic'
                }
            },
            subtitle: {
                text: undefined,
                align: 'left',
                x: 70,
                style: {
                    color: '#eeeeee',
                    "fontSize": '12px',
                    fontStyle: 'italic'
                }
            },
            legend: {
                symbolHeight: 12,
                symbolWidth: 30,
                symbolRadius: 5,
                squareSymbol: false
            },
            xAxis: {
                categories: eixoX,
                crosshair: true
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.state.filtro.tipoGrafico
                }
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}:&nbsp</td>' +
                    '<td style="padding:0"><b>{point.y:,.2f}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0,
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                },
                line: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    }
                },
                allowPointSelect: false
            },
            series: eixoY,
            exporting: false
        }
    }

    isOnStatusList = (id) => {
        if (this.state.filtro != null && this.state.filtro.status != null) {
            return this.state.filtro.status.includes(id);
        }
    }

    onChangeTab = (event, value) => {
        this.showLoading();
        this.state.filtro.produtoLinha = (value === 0) ? undefined : value;
        this.setState({ tab: value });
        this.switchLinha((value === 0) ? undefined : value);
    }

    handleChangeStatus = (event) => {
        this.state.modalFormData.emexecucao = event.target.value === 'emexecucao';
        this.state.modalFormData.concluido = event.target.value === 'concluido';
        this.state.modalFormData.cancelado = event.target.value === 'cancelado';
        this.setState(({
            modalFormData: { ...this.state.modalFormData, [event.target.name]: event.target.value }
        }));
    }

    handleChange = (event) => {
        if (event.target != null && event.target.name === "dadosVisualizados") {
            this.state.filtro.tipoGrafico = event.target.value;
            if (event.target.value !== 'Produção') {
                this.state.modalFormData.colaboradores = false;
                this.state.modalFormData.colaboradoresList = [];
            }
            if (event.target.value === "Produção" || event.target.value === 'Receita') {
                this.state.modalFormData.emprospeccao = false;
                this.state.modalFormData.negociacao = false;
                this.state.modalFormData.aceito = false;
                this.state.modalFormData.recusado = false;
                this.state.modalFormData.arquivado = false;
                this.state.modalFormData.concluido = true;
                this.state.modalFormData.cancelado = true;
                this.state.modalFormData.emexecucao = true;
                const modalFormData = this.state.modalFormData;
                modalFormData.emAnalise = false;
                modalFormData.conforme = false;
                modalFormData.naoConforme = false;
                this.setState({ modalFormData: { ...modalFormData, [event.target.name]: event.target.value } });
            } else {
                this.state.modalFormData.emprospeccao = true;
                this.state.modalFormData.negociacao = true;
                this.state.modalFormData.aceito = true;
                this.state.modalFormData.recusado = true;
                this.state.modalFormData.concluido = true;
                this.state.modalFormData.cancelado = true;
                this.state.modalFormData.emexecucao = true;
                this.state.modalFormData.arquivado = true;
                const modalFormData = this.state.modalFormData;
                modalFormData.emAnalise = this.state.filtro.emAnalise || false;
                modalFormData.conforme = this.state.filtro.conforme || false;
                modalFormData.naoConforme = this.state.filtro.naoConforme || false;
                this.setState({ modalFormData: { ...modalFormData, [event.target.name]: event.target.value } });
            }
        }
        this.setState(({
            modalFormData: { ...this.state.modalFormData, [event.target.name]: event.target.value }
        }));
    };

    handleChangeCheckbox = (event) => {
        this.setState(({
            modalFormData: { ...this.state.modalFormData, [event.target.name]: !this.state.modalFormData[event.target.name], [event.target.name + 'Item']: '', [event.target.name + 'List']: [] }
        }));
    };
    handleChangeJustOne = (event) => {
        if (event.target.name === 'emexecucao') {
            this.state.modalFormData.emexecucao = !this.state.modalFormData.emexecucao;
            this.state.modalFormData.concluido = false;
            this.state.modalFormData.cancelado = false;
        } else if (event.target.name === 'concluido') {
            this.state.modalFormData.concluido = !this.state.modalFormData.concluido;
            this.state.modalFormData.emexecucao = false;
            this.state.modalFormData.cancelado = false;
        } else {
            this.state.modalFormData.cancelado = !this.state.modalFormData.cancelado;
            this.state.modalFormData.emexecucao = false;
            this.state.modalFormData.concluido = false;
        }
        this.setState(this.state);
    }

    changeModal = (modal, status) => {
        this.filtroToModalFormData();
        this.setState({ modalFiltro: status });
    }

    changeDepartamentoRegional = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, departamentoRegionalItem: e } });
    }

    changeUnidades = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, unidadesList: e } });
    }

    changeColaboradores = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, colaboradoresList: e } });
    }

    changeProdutoCategoria = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, produtoCategoriaItem: e } });
    }

    changeProdutoNacional = (e) => {
        const modalFormData = this.state.modalFormData;
        if (!e || (e && !e[3])) {
            modalFormData.emAnalise = false;
            modalFormData.conforme = false;
            modalFormData.naoConforme = false;
        }
        modalFormData.produtoNacionalItem = e
        this.setState({ modalFormData: modalFormData });
    }

    changeProdutoRegional = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, produtoRegionalItem: e } });
    }

    changePorteEmpresa = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, porteEmpresaItem: e } });
    }

    changeTipoCliente = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, tipoClienteItem: e } });
    }

    changeNomeEmpresa = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, nomeEmpresaItem: e } });
    }

    changeOrigemRecurso = (e) => {
        this.setState({ modalFormData: { ...this.state.modalFormData, origemRecursoItem: e } });
    }

    salvarGrafico = () => {
        this.modalFormDataToFiltro();
        API.post('rest/sistema/Filtro', this.state.filtro).then(filtro => {
            API.get('rest/sistema/Filtro?ordenarPor=id&usuario=' + this.props.usuarioConectado.id).then(filtroList => {
                this.state.filtroList = filtroList.data;
                this.changeModal('modalFiltro', false);
                this.showToast('Gráfico salvo com sucesso!', 'success');
                this.showLoading();
                this.reloadData();
                this.aplicarFiltro(true);
            });
        }).catch(error => {
            console.log(error);
            this.showToast('Erro ao salvar gráfico. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    modalFormDataToFiltro = () => {
        this.state.filtro = {
            usuario: this.state.usuario.id,
            produtoLinha: this.state.filtro.produtoLinha,
            unidadeFederativa: null,
            unidade: null,
            produtoCategoria: null,
            produtoNacional: null,
            produtoRegional: null,
            tipoPrevisaoReceita: null,
            cliente: null,
            porteCliente: null,
            tipoPessoa: null,
            unidades: null,
            colaboradores: null,
            emAnalise: this.state.modalFormData.emAnalise,
            conforme: this.state.modalFormData.conforme,
            naoConforme: this.state.modalFormData.naoConforme
        };

        let dataInicio = new Date();
        let dataFim = new Date();
        dataInicio.setDate(1);
        dataInicio.setMonth(this.state.modalFormData.mesAnterior);
        dataInicio.setFullYear(this.state.anoList[this.state.modalFormData.anoAnterior]);
        dataFim.setDate(1);
        dataFim.setMonth(this.state.modalFormData.mesPosterior);
        dataFim.setFullYear(this.state.anoList[this.state.modalFormData.anoPosterior]);
        this.state.filtro.dataInicio = dataInicio;
        this.state.filtro.dataFim = dataFim;

        this.state.filtro.tipoGrafico = this.state.modalFormData.dadosVisualizados;

        this.state.filtro.produtoCategoria = this.state.modalFormData.produtoCategoriaItem[0];
        this.state.filtro.produtoNacional = this.state.modalFormData.produtoNacionalItem[0];
        this.state.filtro.produtoRegional = this.state.modalFormData.produtoRegionalItem[0];
        this.state.filtro.porteCliente = this.state.modalFormData.porteEmpresaItem[0];
        this.state.filtro.tipoPessoa = this.state.modalFormData.tipoClienteItem[0];
        this.state.filtro.cliente = this.state.modalFormData.nomeEmpresaItem[0];
        this.state.filtro.tipoPrevisaoReceita = this.state.modalFormData.origemRecursoItem[0];
        this.state.filtro.unidadeFederativa = this.state.modalFormData.departamentoRegionalItem[0];

        let atendimentoStatusSI = [];
        if (this.state.modalFormData.aguardandoValidacao) atendimentoStatusSI.push(7);
        if (this.state.modalFormData.validado) atendimentoStatusSI.push(6);
        if (this.state.modalFormData.comCritica) atendimentoStatusSI.push(8);
        if (this.state.modalFormData.envioManual) atendimentoStatusSI.push(10);
        atendimentoStatusSI = atendimentoStatusSI.toString();
        this.state.filtro.atendimentoStatusSI = atendimentoStatusSI;

        let status = [];
        if (this.state.modalFormData.emprospeccao) status.push(2);
        if (this.state.modalFormData.negociacao) status.push(3);
        if (this.state.modalFormData.aceito) status.push(4);
        if (this.state.modalFormData.recusado) status.push(5);
        if (this.state.modalFormData.concluido) status.push(6);
        if (this.state.modalFormData.cancelado) status.push(7);
        if (this.state.modalFormData.emexecucao) status.push(8);
        if (this.state.modalFormData.arquivado) status.push(9);
        status = status.toString();
        this.state.filtro.status = status;

        if (this.state.modalFormData.unidadesList[0]) {
            let unidades = [];
            this.state.modalFormData.unidadesList.forEach((value, index) => {
                unidades.push(value[0]);
            });
            unidades = unidades.toString();
            unidades = unidades.replace('[', '');
            unidades = unidades.replace(']', '');
            this.state.filtro.unidades = unidades;
            this.state.filtro.unidade = this.state.modalFormData.unidadesList[0][0];
        }
        if (this.state.modalFormData.colaboradoresList[0]) {
            let colaboradores = [];
            this.state.modalFormData.colaboradoresList.forEach((value, index) => {
                colaboradores.push(value[0]);
            });
            colaboradores = colaboradores.toString();
            colaboradores = colaboradores.replace('[', '');
            colaboradores = colaboradores.replace(']', '');
            this.state.filtro.colaboradores = colaboradores;
        }
        const filtro = {
            ...this.state.filtro,
            emAnalise: this.state.modalFormData.emAnalise,
            conforme: this.state.modalFormData.conforme,
            naoConforme: this.state.modalFormData.naoConforme
        }
        this.setState({ filtro: filtro });
    }

    filtroToModalFormData = () => {
        this.state.modalFormData = {
            mesAnterior: 0,
            anoAnterior: 0,
            mesPosterior: 0,
            anoPosterior: 0,
            dadosVisualizados: 'Produção',
            produtoCategoria: false,
            produtoNacional: false,
            produtoRegional: false,
            porteEmpresa: false,
            tipoCliente: false,
            nomeEmpresa: false,
            origemRecurso: false,
            aguardandoValidacao: false,
            validado: false,
            comCritica: false,
            envioManual: false,
            departamentoRegional: false,
            unidades: false,
            unidadesList: [],
            colaboradores: false,
            colaboradoresList: [],
            produtoCategoriaItem: '',
            produtoNacionalItem: '',
            produtoRegionalItem: '',
            porteEmpresaItem: '',
            tipoClienteItem: '',
            nomeEmpresaItem: '',
            origemRecursoItem: '',
            departamentoRegionalItem: '',
            emprospeccao: true,
            negociacao: true,
            aceito: true,
            recusado: true,
            concluido: true,
            cancelado: true,
            emexecucao: true,
            arquivado: true,
            emAnalise: this.state.filtro.emAnalise ? this.state.filtro.emAnalise : false,
            conforme: this.state.filtro.conforme ? this.state.filtro.conforme : false,
            naoConforme: this.state.filtro.naoConforme ? this.state.filtro.naoConforme : false,
        }

        let dataInicio = new Date(this.state.filtro.dataInicio);
        let dataFim = new Date(this.state.filtro.dataFim);
        this.state.modalFormData.mesAnterior = dataInicio.getMonth();
        this.state.modalFormData.anoAnterior = this.state.anoList.indexOf(dataInicio.getFullYear());
        this.state.modalFormData.mesPosterior = dataFim.getMonth();
        this.state.modalFormData.anoPosterior = this.state.anoList.indexOf(dataFim.getFullYear());

        this.state.modalFormData.dadosVisualizados = this.state.filtro.tipoGrafico;

        if (this.state.filtro.tipoGrafico === 'Produção' || this.state.filtro.tipoGrafico === 'Receita') {
            let status = this.state.filtro.status;
            if (typeof status !== 'string') status = status.toString();
            if (status !== '') status = status.split(',');
            else status = [];
        }

        this.state.selectBoxList.produtoCategoriaList.forEach((value, index) => {
            if (value[0] === this.state.filtro.produtoCategoria) {
                this.state.modalFormData.produtoCategoriaItem = value;
                this.state.modalFormData.produtoCategoria = true;
            }
        });
        this.state.selectBoxList.produtoNacionalList.forEach((value, index) => {
            if (value[0] === this.state.filtro.produtoNacional) {
                this.state.modalFormData.produtoNacionalItem = value;
                this.state.modalFormData.produtoNacional = true;
            }
        });
        this.state.selectBoxList.produtoRegionalList.forEach((value, index) => {
            if (value[0] === this.state.filtro.produtoRegional) {
                this.state.modalFormData.produtoRegionalItem = value;
                this.state.modalFormData.produtoRegional = true;
            }
        });
        this.state.selectBoxList.porteClienteList.forEach((value, index) => {
            if (value[0] === this.state.filtro.porteCliente) {
                this.state.modalFormData.porteEmpresaItem = value;
                this.state.modalFormData.porteEmpresa = true;
            }
        });
        this.state.selectBoxList.tipoPessoaList.forEach((value, index) => {
            if (value[0] === this.state.filtro.tipoPessoa) {
                this.state.modalFormData.tipoClienteItem = value;
                this.state.modalFormData.tipoCliente = true;
            }
        });
        if (this.state.selectBoxList.clienteList != null) {
            this.state.selectBoxList.clienteList.forEach((value, index) => {
                if (value[0] === this.state.filtro.cliente) {
                    this.state.modalFormData.nomeEmpresaItem = value;
                    this.state.modalFormData.nomeEmpresa = true;
                }
            });
        }
        this.state.selectBoxList.tipoPrevisaoReceitaList.forEach((value, index) => {
            if (value[0] === this.state.filtro.tipoPrevisaoReceita) {
                this.state.modalFormData.origemRecursoItem = value;
                this.state.modalFormData.origemRecurso = true;
            }
        });
        this.state.selectBoxList.unidadeFederativaList.forEach((value, index) => {
            if (value[0] === this.state.filtro.unidadeFederativa) {
                this.state.modalFormData.departamentoRegionalItem = value;
                this.state.modalFormData.departamentoRegional = true;
            }
        });

        let atendimentoStatusSI = this.state.filtro.atendimentoStatusSI;
        if (typeof atendimentoStatusSI !== 'string') atendimentoStatusSI = atendimentoStatusSI.toString();
        if (atendimentoStatusSI !== '') atendimentoStatusSI = atendimentoStatusSI.split(',');
        else atendimentoStatusSI = [];
        atendimentoStatusSI.forEach((value, index) => {
            if (value === '7') this.state.modalFormData.aguardandoValidacao = true;
            if (value === '6') this.state.modalFormData.validado = true;
            if (value === '8') this.state.modalFormData.comCritica = true;
            if (value === '10') this.state.modalFormData.envioManual = true;
        });

        let status = this.state.filtro.status;
        if (typeof status !== 'string') status = status.toString();
        if (status !== '') status = status.split(',');
        else status = [];
        status.forEach((value, index) => {
            this.state.modalFormData.emprospeccao = this.isOnStatusList(2);
            this.state.modalFormData.negociacao = this.isOnStatusList(3);
            this.state.modalFormData.aceito = this.isOnStatusList(4);
            this.state.modalFormData.recusado = this.isOnStatusList(5);
            this.state.modalFormData.concluido = this.isOnStatusList(6);
            this.state.modalFormData.cancelado = this.isOnStatusList(7);
            this.state.modalFormData.emexecucao = this.isOnStatusList(8);
            this.state.modalFormData.arquivado = this.isOnStatusList(9);
        });

        if (this.state.filtro.unidades && this.state.filtro.unidades !== "") {
            let unidades = this.state.filtro.unidades.toString().split(',');
            unidades.forEach((value, index) => {
                this.state.selectBoxList.unidadeList.forEach((valueSelectBox, indexSelectBox) => {
                    if (valueSelectBox && value.toString() === valueSelectBox[0].toString()) {
                        this.state.modalFormData.unidadesList.push(valueSelectBox);
                        this.state.modalFormData.unidades = true;
                    }
                });
            });
        }

        if (this.state.filtro.colaboradores && this.state.filtro.colaboradores !== "") {
            let colaboradores = this.state.filtro.colaboradores.toString().split(',');
            colaboradores.forEach((value, index) => {
                this.state.selectBoxList.colaboradorList.forEach((valueSelectBox, indexSelectBox) => {
                    if (value.toString() === valueSelectBox[0].toString()) {
                        this.state.modalFormData.colaboradoresList.push(valueSelectBox);
                        this.state.modalFormData.colaboradores = true;
                    }
                });
            });
        }

        this.setState();
    }

    formatData(date) {
        if (date != null) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    }

    checkPerfilProduto = () => {

        if (this.state.usuario && this.state.usuario.perfilUsuario && this.state.filtro) {

            switch (this.state.usuario.perfilUsuario.nome) {
                case 'Brasil + Produtivo':
                    this.state.filtro.produtoNacional = 193;
                    this.state.filtro.produtoCategoria = 1;
                    return true;
                case 'B+P IPEA':
                    this.state.filtro.produtoNacional = 205;
                    this.state.filtro.produtoCategoria = 1;
                    return true;
                case 'I+A':
                    this.state.filtro.produtoNacional = 203;
                    this.state.filtro.produtoCategoria = 1;
                    return true;
                case 'I+E':
                    this.state.filtro.produtoNacional = 207;
                    this.state.filtro.produtoCategoria = 1;
                    return true;
                default:
                    return false;
            }
        }
    }

    perfisAutorizadosRelatorioBP = (userUnidade) => {
        return userUnidade.tipoUnidade.sigla === 'DN' || userUnidade.tipoUnidade.sigla === 'DR' || this.props.usuarioConectado.isAdministrador;
    }

    render() {
        const { valoresCards, chart, legendas, filtro, tab, modalFiltro, isUO,
            mesList, anoList, modalFormData, selectBoxList, podeVerCard, userUnidade } = this.state;
            

        let produtoLinha;
        if (filtro) produtoLinha = filtro.produtoLinha;
        const ano = new Date().getFullYear();
        const showSolicitacaoDNFilter = filtro.tipoGrafico === 'Quantidade' && modalFormData.produtoNacionalItem && modalFormData.produtoNacionalItem[3]

        return (
            <Page icon={'graphic.svg'} label={'Dashboard de Atendimentos'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container id={'dashboard'}>
                    {podeVerCard ?
                        <Grid container spacing={5} className={'dashboard-cards'}>
                            <Grid item xs={12} md={3}>
                                <Grid container spacing={1} justify="space-between">
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between">
                                            <Grid item className={'font-blue'}>Atendimentos</Grid>
                                            <Grid item>Hoje</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <Grid container direction={'column'} justify={'center'} className={'card-green'}>
                                                    <Grid item className={'font-bigger'}>{valoresCards.atendimentosAceitos}</Grid>
                                                    <Grid item>Aceitos</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container direction={'column'} justify={'center'} className={'card-blue'}>
                                                    <Grid item className={'font-bigger'}>{valoresCards.atendimentosConcluidos}</Grid>
                                                    <Grid item>Concluídos</Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Grid container spacing={1} justify="space-between">
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between">
                                            <Grid item className={'font-blue'}>Produção</Grid>
                                            <Grid item>{ano}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs>
                                                <Grid container direction={'column'} justify={'center'} className={'card'}>
                                                    <Grid item className={'font-bigger'}>{valoresCards.horasTrabalhadas}h</Grid>
                                                    <Grid item>Horas trabalhadas</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container direction={'column'} justify={'center'} className={'card'}>
                                                    <Grid item className={'font-bigger'}>{valoresCards.ensaiosRealizados}</Grid>
                                                    <Grid item>Ensaios realizados</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container direction={'column'} justify={'center'} className={'card'}>
                                                    <Grid item className={'font-bigger'}>{valoresCards.calibracoesRealizadas}</Grid>
                                                    <Grid item>Calibrações realizadas</Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs>
                                                <Grid container direction={'column'} justify={'center'} className={'card'}>
                                                    <Grid item className={'font-bigger'}>{valoresCards.relatoriosRegistrados}</Grid>
                                                    <Grid item>Relatórios registrados</Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Grid container justify="space-between" spacing={1}>
                                    <Grid item xs={12}>
                                        <Grid container justify="space-between">
                                            <Grid item className={'font-blue'}>Receita</Grid>
                                            <Grid item>{ano}</Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Grid container direction={'column'} justify={'center'} className={'card'}>
                                                    <Grid item className={'font-bigger'}>R$ {valoresCards.receitaApropriada}</Grid>
                                                    <Grid item>Valor apropriado</Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        : null}
                    <Grid container className={'dashboard-grafico'}>
                        <Grid item xs={12}>
                            <AppBar position='static'>
                                <Tabs value={tab} onChange={this.onChangeTab} variant='scrollable' scrollButtons='auto'>
                                    <Tab key={'NavbarDashboardAtendimentos-0'} value={undefined} label={'Geral'}></Tab>
                                    <Tab key={'NavbarDashboardAtendimentos-1'} value={2} label={'Pesquisa, Desenvolvimento e Inovação'}></Tab>
                                    <Tab key={'NavbarDashboardAtendimentos-2'} value={4} label={'Serviços Técnicos Especializados'}></Tab>
                                    <Tab key={'NavbarDashboardAtendimentos-3'} value={3} label={'Metrologia'}></Tab>
                                    <Tab key={'NavbarDashboardAtendimentos-4'} value={1} label={'Consultoria'}></Tab>
                                </Tabs>
                            </AppBar>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={6} className={'header-buttons'}>
                                    <GenericButton label={'Baixar relatório'} color={'darkBlue'} subClass={'basic-button'} click={this.gerarRelatorio} />
                                    {this.perfisAutorizadosRelatorioBP(userUnidade) && (<GenericButton label={'Baixar relatório B+P'} color={'darkBlue'} subClass={'basic-button'} click={this.gerarRelatorioBP} />)}
                                    {this.perfisAutorizadosRelatorioBP(userUnidade) && (<GenericButton label={'Baixar relatório Indicadores'} color={'darkBlue'} subClass={'basic-button'} click={this.gerarRelatorioIndicadores} />)}
                                    <GenericButton color={'lightBlue'} label={'Busca avançada'} subClass={'basic-button busca-avancada'}
                                        icon={process.env.PUBLIC_URL + 'filter.svg'} click={() => this.changeModal('modalFiltro', true)} />
                                </Grid>
                                <Grid item xs={6} className={'header-info'}>
                                    {modalFormData.aceito ? <Grid item>Atendimentos aceitos: {legendas.aceitos}</Grid> : <div></div>}
                                    {modalFormData.concluido ? <Grid item>Atendimentos concluídos: {legendas.concluidos}</Grid> : <div></div>}
                                    <Grid item>Horas trabalhadas: {legendas.producao}</Grid>
                                    {produtoLinha == 3 ? <Grid item>Ensaios realizados: {legendas.producao_ensaios}</Grid> : <div></div>}
                                    {produtoLinha == 3 ? <Grid item>Calibrações realizadas: {legendas.producao_calibracao}</Grid> : <div></div>}
                                    {produtoLinha == 3 ? <Grid item>Relatórios registrados: {legendas.producao_relatorios}</Grid> : <div></div>}
                                    <Grid item>Valor apropriado: R$: {legendas.realizado}</Grid>
                                    <Grid item className={'font-blue'}>Empresas atendidas: {legendas.empresas}</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={11}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={chart}
                            />
                        </Grid>
                        <SimpleModal
                            isOpen={modalFiltro}
                            handleClose={() => this.changeModal('modalFiltro', false)}
                            type={'info'}
                            width={'800px'}>

                            <Grid container className={'modal modal-atendimento-filtro'}>
                                <Grid item>
                                    <h2>Filtro avançado</h2>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container>
                                                <Grid item xs={12} style={{ alignItems: 'flex-end' }}>
                                                    <p className={'font-blue'}>Período</p>
                                                </Grid>
                                                <Grid item xs={12} className={'selects'}>
                                                    <FormControl variant="outlined">
                                                        <Select
                                                            value={modalFormData.mesAnterior}
                                                            onChange={this.handleChange}
                                                            name={'mesAnterior'}>

                                                            {mesList.map((value, index) => (
                                                                <MenuItem key={'MenuItemMesAnterior' + index} value={value.id}>{value.nome}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined">
                                                        <Select
                                                            value={modalFormData.anoAnterior}
                                                            onChange={this.handleChange}
                                                            name={'anoAnterior'}
                                                        >
                                                            {anoList.map((value, index) => (
                                                                <MenuItem key={'MenuItemAnoAnterior' + index} value={index}>{value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>

                                                    <img src={process.env.PUBLIC_URL + '/assets/icons/arrowRight.svg'} alt='arrowIcon' style={{ padding: '0 8px', paddingTop: '6px' }} />

                                                    <FormControl variant="outlined">
                                                        <Select
                                                            value={modalFormData.mesPosterior}
                                                            onChange={this.handleChange}
                                                            name={'mesPosterior'}>

                                                            {mesList.map((value, index) => (
                                                                <MenuItem key={'MenuItemMesPosterior' + index} value={value.id}>{value.nome}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="outlined">
                                                        <Select
                                                            value={modalFormData.anoPosterior}
                                                            onChange={this.handleChange}
                                                            name={'anoPosterior'}
                                                        >
                                                            {anoList.map((value, index) => (
                                                                <MenuItem key={'MenuItemAnoPosterior' + index} value={index}>{value}</MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container>
                                                <Grid item xs={12} className={'flex-column status'}>
                                                    <p className={'font-blue'}>Dados visualizados</p>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <RadioGroup aria-label="position" name="dadosVisualizados" value={modalFormData.dadosVisualizados} onChange={this.handleChange} row>
                                                            <FormControlLabel
                                                                value="Quantidade"
                                                                control={<Radio color="primary" />}
                                                                label="Quantidade"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="Receita"
                                                                control={<Radio color="primary" />}
                                                                label="Receita"
                                                                labelPlacement="end"
                                                            />
                                                            <FormControlLabel
                                                                value="Produção"
                                                                control={<Radio color="primary" />}
                                                                label="Produção"
                                                                labelPlacement="end"
                                                            />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container>
                                                <Grid item xs={12} className={'flex-column status'}>
                                                    <p className={'font-blue'}>Localização</p>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox disabled={userUnidade.tipoUnidade.sigla != 'DN'} checked={modalFormData.departamentoRegional}
                                                                onChange={this.handleChangeCheckbox} name={'departamentoRegional'} value="departamentoRegional" />}
                                                            label="Departamento Regional"
                                                        />
                                                        {modalFormData.departamentoRegional ? (<FormControl variant="outlined">
                                                            <InputSelect
                                                                disabled={userUnidade.tipoUnidade.sigla === 'UO' || userUnidade.tipoUnidade.sigla === 'DR'}
                                                                initialSelectedItem={modalFormData.departamentoRegionalItem}
                                                                getInput={this.changeDepartamentoRegional}
                                                                name={'departamentoRegionalItem'}
                                                                suggestions={selectBoxList.unidadeFederativaList}
                                                                itemLabel={1}
                                                                itemKey={0} />
                                                        </FormControl>) : <div></div>}
                                                        <FormControlLabel
                                                            control={<Checkbox disabled={userUnidade.tipoUnidade.sigla == 'UO'} checked={modalFormData.unidades || isUO}
                                                                onChange={this.handleChangeCheckbox} name={'unidades'} value={modalFormData.unidades} />}
                                                            label="Unidade Operacional"
                                                        />
                                                        {(modalFormData.unidades || isUO) ? <AutoCompleteMany
                                                            disabled={isUO && modalFormData.unidades}
                                                            suggestions={selectBoxList.unidadeList}
                                                            itemlabel={1}
                                                            itemKey={0}
                                                            getInput={this.changeUnidades}
                                                            initialSelectedItensList={modalFormData.unidadesList} /> : <div></div>}
                                                        <FormControlLabel
                                                            control={<Checkbox disabled={filtro.tipoGrafico !== 'Produção'} checked={filtro.tipoGrafico !== 'Produção' ? false : modalFormData.colaboradores}
                                                                onChange={this.handleChangeCheckbox} name={'colaboradores'} value={modalFormData.colaboradores} />}
                                                            label="Colaboradores"
                                                        />
                                                        {modalFormData.colaboradores ? <AutoCompleteMany
                                                            suggestions={selectBoxList.colaboradorList}
                                                            itemlabel={1}
                                                            itemKey={0}
                                                            getInput={this.changeColaboradores}
                                                            disabled={filtro.tipoGrafico !== 'Produção'}
                                                            initialSelectedItensList={modalFormData.colaboradoresList} /> : <div></div>}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container>
                                                <Grid item xs={12} className={'flex-column status'}>
                                                    <p className={'font-blue'}>Status</p>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {filtro.tipoGrafico === 'Quantidade' ?
                                                        <FormControl component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.emprospeccao} onChange={this.handleChangeCheckbox}
                                                                    name="emprospeccao" value={modalFormData.emprospeccao} />}
                                                                label="Em prospecção (rascunho)"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.negociacao} onChange={this.handleChangeCheckbox}
                                                                    name="negociacao" value={modalFormData.negociacao} />}
                                                                label="Em negociação"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.aceito} onChange={this.handleChangeCheckbox}
                                                                    name="aceito" value={modalFormData.aceito} />}
                                                                label="Aceito/Contratado"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.emexecucao} onChange={this.handleChangeCheckbox}
                                                                    name="emexecucao" value={modalFormData.emexecucao} />}
                                                                label="Aceito/Em execução"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.concluido} onChange={this.handleChangeCheckbox}
                                                                    name="concluido" value={modalFormData.concluido} />}
                                                                label="Concluído"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.cancelado} onChange={this.handleChangeCheckbox}
                                                                    name="cancelado" value={modalFormData.cancelado} />}
                                                                label="Cancelado"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.recusado} onChange={this.handleChangeCheckbox}
                                                                    name="recusado" value={modalFormData.recusado} />}
                                                                label="Recusado"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.arquivado} onChange={this.handleChangeCheckbox}
                                                                    name="arquivado" value={modalFormData.arquivado} />}
                                                                label="Arquivado"
                                                            />
                                                        </FormControl>
                                                        :
                                                        <FormControl component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.emexecucao} onChange={this.handleChangeJustOne}
                                                                    name="emexecucao" value={modalFormData.emexecucao} />}
                                                                label="Aceito/Em execução"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.concluido} onChange={this.handleChangeJustOne}
                                                                    name="concluido" value={modalFormData.concluido} />}
                                                                label="Concluído"
                                                            />
                                                            <FormControlLabel
                                                                control={<Checkbox checked={modalFormData.cancelado} onChange={this.handleChangeJustOne}
                                                                    name="cancelado" value={modalFormData.cancelado} />}
                                                                label="Cancelado"
                                                            />
                                                        </FormControl>
                                                    }
                                                </Grid>
                                                {showSolicitacaoDNFilter &&
                                                    <Grid item xs={12}>
                                                        <Grid container>
                                                            <Grid item xs={12} className={'flex-column status'}>
                                                                <p className={'font-blue'}>Solicitação DN</p>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <FormControl component="fieldset">
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={modalFormData.emAnalise} onChange={this.handleChangeCheckbox} name="emAnalise" value={modalFormData.emAnalise} />}
                                                                        label="Em análise"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={modalFormData.conforme} onChange={this.handleChangeCheckbox} name="conforme" value={modalFormData.conforme} />}
                                                                        label="Conforme"
                                                                    />
                                                                    <FormControlLabel
                                                                        control={<Checkbox checked={modalFormData.naoConforme} onChange={this.handleChangeCheckbox} name="naoConforme" value={modalFormData.naoConforme} />}
                                                                        label="Não conforme"
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>}
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Grid container direction={'column'}>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={12} className={'flex-column status'}>
                                                            <p className={'font-blue'}>Produto</p>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled={this.checkPerfilProduto()} checked={modalFormData.produtoCategoria} onChange={this.handleChangeCheckbox} name={'produtoCategoria'} value={modalFormData.produtoCategoria} />}
                                                                    label="Categoria de Produto"
                                                                />
                                                                {modalFormData.produtoCategoria ? <AutoComplete
                                                                    disabled={this.checkPerfilProduto()}
                                                                    suggestions={selectBoxList.produtoCategoriaList}
                                                                    key='produtoCategoria'
                                                                    initialSelectedItem={modalFormData.produtoCategoriaItem}
                                                                    itemlabel={1}
                                                                    getInput={this.changeProdutoCategoria} /> : <div></div>}
                                                                <FormControlLabel
                                                                    control={<Checkbox disabled={this.checkPerfilProduto()} checked={modalFormData.produtoNacional} onChange={this.handleChangeCheckbox} name={'produtoNacional'} value={modalFormData.produtoNacional} />}
                                                                    label="Produto Nacional"
                                                                />
                                                                {modalFormData.produtoNacional ? <AutoComplete
                                                                    disabled={this.checkPerfilProduto()}
                                                                    suggestions={selectBoxList.produtoNacionalList}
                                                                    key='produtoNacional'
                                                                    initialSelectedItem={modalFormData.produtoNacionalItem}
                                                                    itemlabel={1}
                                                                    getInput={this.changeProdutoNacional} /> : <div></div>}
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.produtoRegional} onChange={this.handleChangeCheckbox} name={'produtoRegional'} value={modalFormData.produtoRegional} />}
                                                                    label="Produto Regional"
                                                                />
                                                                {modalFormData.produtoRegional ? <AutoComplete
                                                                    suggestions={selectBoxList.produtoRegionalList}
                                                                    key='produtoRegional'
                                                                    initialSelectedItem={modalFormData.produtoRegionalItem}
                                                                    itemlabel={1}
                                                                    getInput={this.changeProdutoRegional} /> : <div></div>}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={12} className={'flex-column status'}>
                                                            <p className={'font-blue'}>Empresa</p>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.porteEmpresa} onChange={this.handleChangeCheckbox} name="porteEmpresa" value={modalFormData.porteEmpresa} />}
                                                                    label="Porte da empresa"
                                                                />
                                                                {modalFormData.porteEmpresa ? <AutoComplete
                                                                    suggestions={selectBoxList.porteClienteList}
                                                                    key='porteEmpresa'
                                                                    initialSelectedItem={modalFormData.porteEmpresaItem}
                                                                    itemlabel={1}
                                                                    getInput={this.changePorteEmpresa} /> : <div></div>}
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.tipoCliente} onChange={this.handleChangeCheckbox} name="tipoCliente" value={modalFormData.tipoCliente} />}
                                                                    label="Tipo de cliente"
                                                                />
                                                                {modalFormData.tipoCliente ? <AutoComplete
                                                                    suggestions={selectBoxList.tipoPessoaList}
                                                                    key='tipoCliente'
                                                                    initialSelectedItem={modalFormData.tipoClienteItem}
                                                                    itemlabel={1}
                                                                    getInput={this.changeTipoCliente} /> : <div></div>}
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.nomeEmpresa} onChange={this.handleChangeCheckbox} name="nomeEmpresa" value={modalFormData.nomeEmpresa} />}
                                                                    label="Nome da empresa"
                                                                />
                                                                {modalFormData.nomeEmpresa ? <AutoComplete
                                                                    suggestions={selectBoxList.clienteList}
                                                                    key='nomeEmpresa'
                                                                    initialSelectedItem={modalFormData.nomeEmpresaItem}
                                                                    itemlabel={1}
                                                                    getInput={this.changeNomeEmpresa} /> : <div></div>}
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.origemRecurso} onChange={this.handleChangeCheckbox} name="origemRecurso" value={modalFormData.origemRecurso} />}
                                                                    label="Origem do recurso"
                                                                />
                                                                {modalFormData.origemRecurso ? <AutoComplete
                                                                    suggestions={selectBoxList.tipoPrevisaoReceitaList}
                                                                    key='origemRecurso'
                                                                    initialSelectedItem={modalFormData.origemRecursoItem}
                                                                    itemlabel={1}
                                                                    getInput={this.changeOrigemRecurso} /> : <div></div>}
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item>
                                                    <Grid container>
                                                        <Grid item xs={12} className={'flex-column status'}>
                                                            <p className={'font-blue'}>Situação</p>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.aguardandoValidacao} onChange={this.handleChangeCheckbox} name="aguardandoValidacao" value={modalFormData.aguardandoValidacao} />}
                                                                    label="Aguardando validação"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.validado} onChange={this.handleChangeCheckbox} name="validado" value={modalFormData.validado} />}
                                                                    label="Validado"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.comCritica} onChange={this.handleChangeCheckbox} name="comCritica" value={modalFormData.comCritica} />}
                                                                    label="Com crítica"
                                                                />
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={modalFormData.envioManual} onChange={this.handleChangeCheckbox} name="envioManual" value={modalFormData.envioManual} />}
                                                                    label="Envio manual"
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <div className={'rodape'}>
                                        <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.changeModal('modalFiltro', false)} />
                                        <GenericButton color={'darkBlue'} label={'Filtrar'} subClass={'basic-button'} click={this.salvarGrafico} />
                                    </div>
                                </Grid>
                            </Grid>
                        </SimpleModal>
                    </Grid>
                </Grid>
            </Page>
        );
    }
}