import React, { Component } from 'react';
import VisitasDefault from './visitasDefault';
import API from '../../comm/API';
import AtendimentosCard from './atendimentosCard';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtil';
import AtividadeDefault from './atividadeDefault';
import AtividadeEstadoPresente from './atividadeEstadoPresente';
import AtividadeMFV from './atividadeMFV';
import AtividadeDesperdicio from './atividadeDesperdicio';

export default class MentoriaPratica extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'visitas'
    };

    constructor(props) {
        super(props);
        const etapaName = `etapaPratica${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.turma = this.props.turma.nome;
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Prática B';
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);
        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoPratica: undefined,
            etapa,
            atendimentoList,
            clientName: '',
            disabled: false
        };
    };

    getDesperdicioList = async (desperdicio) => {
        let list = desperdicio.defeitoList;
        list = list.concat(desperdicio.esperaList); 
        list = list.concat(desperdicio.estoqueList); 
        list = list.concat(desperdicio.intelectualList); 
        list = list.concat(desperdicio.processamentoList); 
        list = list.concat(desperdicio.superProducaoList);
        list = list.concat(desperdicio.transporteList);
        list = list.concat(desperdicio.movimentacaoList);
        return await list;
    };

    getEstadoPresenteModel() {
        return { status: 1, ordemVisita: null, anexoEstadoPresenteList: [], atuacaoEmpresa: '', familiaProdutoPrincipal: '', principaisClientesList: [], principalCliente: '' };
    };

    getMfvModel() {
        return { status: 1, ordemVisita: null, anexoMFVList: [], leadTime: 0, taktTime: 0, linhaProducaoIntervencao: '', mainProdutoLinha: '', motivoLinhaProducao: '', isLinhaGargaloFabrica: false, tipoProduto: 1, maisInformacoesList: [], maisInfo: '' }
    };

    getDesperdicioModel() {
        return { status: 1, ordemVisita: null, desperdicioList: [], defeitoList: [], esperaList: [], estoqueList: [], intelectualList: [], processamentoList: [], superProducaoList: [], transporteList: [], movimentacaoList: [] }; 
    };

    getModels = () => {
        return {
            estadoPresente: this.getEstadoPresenteModel(),
            mfv: this.getMfvModel(),
            desperdicio: this.getDesperdicioModel()
        }
    }

    validateEstadoPresente(estadoPresente) {
        return estadoPresente.anexoEstadoPresenteList.length > 0 && estadoPresente.atuacaoEmpresa && estadoPresente.familiaProdutoPrincipal && 
            estadoPresente.principaisClientesList.length > 0 && estadoPresente.anexoEstadoPresenteList.every(elm => { return elm.descricao });
    };

    validateMfv(mfv) {
        return mfv.anexoMFVList.length > 0 && mfv.anexoMFVList.every(anx => { return anx.descricao }) && mfv.linhaProducaoIntervencao && mfv.mainProdutoLinha && 
            mfv.motivoLinhaProducao && mfv.maisInformacoesList.length > 0 && mfv.leadTime > 0 && mfv.taktTime > 0;
    };

    validateDesperdicio(desperdicio) {
        return desperdicio && desperdicio.desperdicioList && desperdicio.desperdicioList.length > 0 && desperdicio.desperdicioList.every(anx => { return anx.descricao });
    };

    updateViewData = (atendimento) => {
        this.viewData.visitas.data = atendimento.visitaList;
        const { mfv, estadoPresente, desperdicio } = atendimento;
        
        this.setState({disabled: this.state.turma.status === 3});
        
        this.viewData.atividades.mfv.data =  JSON.parse(JSON.stringify(mfv || this.getMfvModel()));
        this.viewData.atividades.mfv.anexos.data = getByKeyOrEmpty(mfv, 'anexoMFVList');
        
        this.viewData.atividades.estadoPresente.data = JSON.parse(JSON.stringify(estadoPresente || this.getEstadoPresenteModel()));
        this.viewData.atividades.estadoPresente.anexos.data = getByKeyOrEmpty(estadoPresente, 'anexoEstadoPresenteList');

        this.viewData.atividades.desperdicio.data = JSON.parse(JSON.stringify(desperdicio || this.getDesperdicioModel()));
    };

    validaAtendimentoPratica = async (atendimentoPratica) => {  
        let visitaList = atendimentoPratica.visitaList;
        let estadoPresente = atendimentoPratica.estadoPresente;
        let mfv = atendimentoPratica.mfv;
        let desperdicio = atendimentoPratica.desperdicio;
        if (!atendimentoPratica.cancelado) {
            return await visitaList && visitaList.length > 0 && visitaList.every(visita => { return this.validaVisita(visita) }) 
                && estadoPresente && estadoPresente.status === this.STATUS_FINALIZADO && mfv && mfv.status === this.STATUS_FINALIZADO 
                && desperdicio && desperdicio.desperdicioList && desperdicio.desperdicioList.length > 0 && desperdicio.status === this.STATUS_CONTINUO; 
        }
        return true;        
    };

    validaVisita = (visita) => {
        return visita && visita.anexoVisitaList && visita.anexoVisitaList.length > 0 && visita.anexoVisitaList.some(anx => { return anx.descricao === 'lista de presença' })
             && visita.data && visita.turno != null && visita.horasApropriadas > 0  && visita.isVirtual != null;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoPratica = this.state.atendimentoPratica;
        let turma = this.props.turma;
        let validado = await this.validaAtendimentoPratica(atendimentoPratica);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoPratica.finalizado = true;
            let indexAtendimento = turma.etapaPraticaB.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
            turma.etapaPraticaB.atendimentoList[indexAtendimento] = atendimentoPratica; //atualiza atendimentoPratica na etapa pratica b da turma
            let etapa = turma.etapaPraticaB;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/Turma/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/Turma/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaPraticaB;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.props.closeLoading();
                        this.setState({ turma, etapa, atendimentoList, atendimentoPratica: atd }, this.goBackAtendimentos());
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    validaAtividade = async (viewData, atendimentoPratica, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoPratica[nomeAtividade] ? atendimentoPratica[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo && nomeAtividade === 'desperdicio') atividade[nomeAnexo] = await this.getDesperdicioList(atividade); 
        else if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoPratica[nomeAtividade] = atividade;
    };
    
    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoPratica = this.state.atendimentoPratica;
        let visitasData = viewData.visitas.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;

            if (currScreenAtividade === 'estadoPresente') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'estadoPresente', 'anexoEstadoPresenteList', this.validateEstadoPresente, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'mfv') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'mfv', 'anexoMFVList', this.validateMfv, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'desperdicios') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'desperdicio', 'desperdicioList', this.validateDesperdicio, this.STATUS_CONTINUO);

            if (selectedVisita) {
                selectedVisita.anexoVisitaList = [];
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaVisita(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = visitasData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoPratica.visitaList[index] = selectedVisita;
                else atendimentoPratica.visitaList.push(selectedVisita);
            }
            atendimentoPratica.horasApropriadas = atendimentoPratica.visitaList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let atividades = viewData.atividades;
            let estadoPresente = atividades.estadoPresente;
            let mfv = atividades.mfv;
            let desperdicio = atividades.desperdicio;
            if (!estadoPresente.data.ordemVisita) atendimentoPratica.estadoPresente = null;
            if (!mfv.data.ordemVisita) atendimentoPratica.mfv = null;
            if (!desperdicio.data.ordemVisita) atendimentoPratica.desperdicio = null;
            atendimentoPratica.horasApropriadas = atendimentoPratica.visitaList.reduce((a, b) => + a + + b.horasApropriadas, 0);
            atendimentoPratica.visitaList = visitasData;
        }
        let indexAtendimento = turma.etapaPraticaB.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
        turma.etapaPraticaB.atendimentoList[indexAtendimento] = atendimentoPratica; //atualiza atendimentoPratica na etapa pratica b da turma
        if(atendimentoPratica.horasApropriadas > 12){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else{
            return API.post('rest/gestao/Turma/' + turma.id, turma).then(() => {
                return API.get('rest/gestao/Turma/' + turma.id).then(async res => {
                    turma = res.data;
                    etapa = turma.etapaPraticaB;
                    let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                    let atd = etapa.atendimentoList[index];
                    await this.updateViewData(atd);
                    this.setState({ turma, etapa, atendimentoPratica: atd });
                    this.props.showToast('Etapa editada com sucesso!', 'success');
                }).catch(error => {
                    this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                    throw new Error(error);
                });
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }
        
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = true;
        let showAnexos = true;
        let render = null;
        switch (selectedAtividade.screenAtividade) {
            case 'estadoPresente':
                render = <AtividadeEstadoPresente atividade={selectedAtividade.data} disabled={this.state.disabled}/>;
                break;
            case 'mfv':
                render = <AtividadeMFV atividade={selectedAtividade.data} disabled={this.state.disabled}/>;
                break;
            case 'desperdicios':
                render = <AtividadeDesperdicio atividade={selectedAtividade.data} atendimento={this.state.atendimentoPratica}  etapa={this.state.etapa}
                            showLoading={this.props.showLoading} closeLoading={this.props.closeLoading} showToast={this.props.showToast} disabled={this.state.disabled}
                            fase={ this.props.fase } turma={ this.state.turma }/>;
                showTopButtons = false;
                showAnexos = false
                break;
            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefault
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                showTopButtons={ showTopButtons && !this.state.disabled}
                showAnexos={ showAnexos }
                renderChildren={ render }
                clientName={ this.state.clientName }
                viewData={ this.state.viewData }
                disabled={this.state.disabled} />
        );
    };

    renderVisita = (atendimento) => {
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoPratica: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoPratica: undefined });
    };

    render() {
        return (<>
            { this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCard
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Mentoria Prática' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita } 
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <VisitasDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    etapa = {this.state.etapa}
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    models={ this.getModels }
                    atendimento={ this.state.atendimentoPratica }
                    saveEtapa={ this.saveEtapa }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    dontShowFinalizarMentoria={ this.state.atendimentoPratica.finalizado }
                    disabled={this.state.disabled}
                />
            }
        </>)
    };
};