import React, { Component } from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page';
import API from '../../comm/API';
import SimpleModal from '../../components/modal/modal';
import { Link } from 'react-router-dom';
import './notificacao.scss';
import Masks from '../../comm/masks';

export default class Notificacao extends Component {


    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    }

    constructor(props) {
        super(props);
        this.state = {
            atendimentoList: [],
            atendimentoRecusadoList: []
        }
        this.modalAtendimento = false;
        this.modalRecusaAtendimento = false;
        this.atendimentoList = [];
        this.atendimentoRecusadoList = [];
        this.usuario = this.props.usuarioConectado;
        this.hasColaborador = this.props.usuarioConectado.colaborador != null;

        //Apenas usuário UO com perfil ADMINISTRADOR, GESTOR ou COORDENADOR podem aceitar ou recusar atendimento em rede.
        this.isUO = this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo;
        this.hasPerfil = ((this.props.usuarioConectado.perfilUsuario.nome === 'ADMINISTRADOR') ||
                        (this.props.usuarioConectado.perfilUsuario.nome === 'GESTOR') ||
                        (this.props.usuarioConectado.perfilUsuario.nome === 'COORDENADOR'));
        this.getAtendimentos();
    }

    changeInput = (e) => {
        this.setState(this.state);
    }

    componentDidMount() {

    }

    getFiltro() {
        var filtro = {}
        filtro['unidadeParceira.id'] = this.usuario.unidade.id;
        return filtro;
    };

    getFiltroRecusado() {
        var filtro = {}
        filtro['atendimentoExecutor.unidade.id'] = this.usuario.unidade.id;
        filtro['statusNotificacao.id'] = 'between(2,3)';
        return filtro;
    };

    getAtendimentos() {
        API.get('rest/gestao/AtendimentoRede', { params: this.getFiltro() }).then(res => {
            let atendimentoList = res.data;
            this.atendimentoList = atendimentoList;
            API.get('rest/gestao/AtendimentoRede', { params: this.getFiltroRecusado() }).then(res => {
                let atendimentosRecusadosList = res.data;
                this.atendimentoRecusadoList = atendimentosRecusadosList;
                this.setState({
                    atendimentoList: this.atendimentoList,
                    atendimentoRecusadoList: this.atendimentoRecusadoList
                })
                this.closeLoading();
            });
        });
    };

    checkAtendimentoStatus(atendimentoRede, idStatusNotificacao) {
        return atendimentoRede.statusNotificacao.id === idStatusNotificacao;
    };

    isAtendimentoAberto(atendimentoRede) {
        return this.checkAtendimentoStatus(atendimentoRede, 1);
    };
    isAtendimentoAceito(atendimentoRede) {
        return this.checkAtendimentoStatus(atendimentoRede, 2);
    };
    isAtendimentoNegado(atendimentoRede) {
        return this.checkAtendimentoStatus(atendimentoRede, 3);
    };

    formatData(date) {
        var dataList = date.split('T')[0].split('-');
        return dataList[2] + '/' + dataList[1] + '/' + dataList[0];
    }

    changeModal(modal, status) {
        this[modal] = status;
        this.setState(this.state);
    }
    // () => this.openModalAtendimento(atendimento)
    openModalAtendimento = (obj) => {
        this.modalAtendimento = true;
        this.setState({ atendimentoModal: obj });
    }

    openModalRecusaAtendimento = (obj) => {
        this.modalRecusaAtendimento = true;
        this.setState({ atendimentoRecusa: obj });
    }

    recusarAtendimento() {
        let atendimentorede = this.state.atendimentoRecusa;
        atendimentorede.statusNotificacao = { id: 3 };
        this.showLoading();
        API.post('rest/gestao/AtendimentoRede/' + this.state.atendimentoRecusa.id, this.state.atendimentoRecusa).then(res => {
            this.closeLoading();
            this.changeModal('modalRecusaAtendimento', false)
            this.setState(this.state);
            this.showToast('O atendimento foi recusado!', 'success');
            this.showLoading();
            this.getAtendimentos();
        }).catch(error => {
            this.showToast('Erro ao recusar o atendimento. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    render() {
        return (
            <Page icon={'bell.svg'} label={'Notificações'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container id={'notificacao'}>
                    {this.state.atendimentoList.map((atd, index) => {
                        return (<Grid item xs={8} key={index} className={'atendimento-notificacao'}>
                            {this.isAtendimentoAberto(atd) && <div className={'circulo-notificacao'}></div>}
                            <Grid item xs={7} className={'descricao-notificacao normal-text'}>
                                <Grid item xs={12} className={'data'}>{this.formatData(atd.atendimentoExecutor.dataEmissao)}</Grid>
                                <Grid item xs={12} className={'titulo'}>Atendimento em rede</Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Grid item xs={3}>Unidade executora</Grid>
                                    <Grid item xs={9}>{atd.atendimentoExecutor.unidade.descricao}</Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Grid item xs={3}>Título</Grid>
                                    <Grid item xs={9}>{atd.atendimentoExecutor.titulo}</Grid>
                                </Grid>
                            </Grid>
                            {this.hasPerfil && this.isUO && this.isAtendimentoAberto(atd) && <Grid item xs={5} className={'botoes-notificacao'}>
                                <Grid item xs={4}><Link to={`/cad/atendimento/atendimento/${atd.atendimentoExecutor.id}/rede`}><GenericButton color={'darkBlue'} label={'Aceitar'} subClass={'basic-button'} /></Link></Grid>
                                <Grid item xs={4}><GenericButton color={'red'} label={'Recusar'} subClass={'basic-button'} click={() => this.openModalRecusaAtendimento(atd)} /></Grid>
                                <Grid item xs={4}><GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Ver detalhes'} click={() => this.openModalAtendimento(atd)} /></Grid>
                            </Grid>}
                            {this.isAtendimentoAceito(atd) && <Grid item xs={5} className={'botoes-notificacao'}>
                                <span>Status:</span>
                                <div className={'circulo'}>
                                </div><span>Aceito</span>
                            </Grid>}
                            {this.isAtendimentoNegado(atd) && <Grid item xs={5} className={'botoes-notificacao'}>
                                <span>Status:</span><div className={'circulo-red'}></div><span>Recusado</span>
                            </Grid>}
                        </Grid>)
                    })}
                    {this.state.atendimentoRecusadoList.map((atd, index) => {
                        return (<Grid item xs={8} key={index} className={'atendimento-notificacao'}>
                            {this.isAtendimentoAberto(atd) && <div className={'circulo-notificacao'}></div>}
                            <Grid item xs={7} className={'descricao-notificacao normal-text'}>
                                <Grid item xs={12} className={'data'}>{this.formatData(atd.atendimentoExecutor.dataEmissao)}</Grid>
                                <Grid item xs={12} className={'titulo'}>Atendimento em rede</Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Grid item xs={3}>Unidade executora</Grid>
                                    <Grid item xs={9}>{atd.atendimentoExecutor.unidade.descricao}</Grid>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Grid item xs={3}>Título</Grid>
                                    <Grid item xs={9}>{atd.atendimentoExecutor.titulo}</Grid>
                                </Grid>
                            </Grid>
                            {this.isAtendimentoAberto(atd) && <Grid item xs={5} className={'botoes-notificacao'}>
                                <Grid item xs={3}><Link to={`/cad/atendimento/atendimento/${atd.atendimentoExecutor.id}/rede`}><GenericButton color={'darkBlue'} label={'Aceitar'} subClass={'basic-button'} /></Link></Grid>
                                <Grid item xs={3}><GenericButton color={'red'} label={'Recusar'} subClass={'basic-button'} click={() => this.openModalRecusaAtendimento(atd)} /></Grid>
                                <Grid item xs={4}><GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Ver detalhes'} click={() => this.openModalAtendimento(atd)} /></Grid>
                            </Grid>}
                            {this.isAtendimentoAceito(atd) && <Grid item xs={5} className={'botoes-notificacao'}>
                                <span>Status:</span>
                                <div className={'circulo'}>
                                </div><span>Aceito</span>
                            </Grid>}
                            {this.isAtendimentoNegado(atd) && <Grid item xs={5} className={'botoes-notificacao'}>
                                <span>Status:</span><div className={'circulo-red'}></div><span>Recusado</span>
                            </Grid>}
                        </Grid>)
                    })}
                    <SimpleModal
                        isOpen={this.modalAtendimento}
                        handleClose={() => this.changeModal('modalAtendimento', false)}
                        type={'info'}
                        width={'650px'}>
                        {this.modalAtendimento ? (
                            <div className={'modal-atendimento'}>
                                <h3 className={'headerModal'}>Resumo do atendimento</h3>
                                <h3>Informações gerais</h3>
                                <Grid container>
                                    <Grid item xs={12} className={'paddingForm alinhamento'}>
                                        <Grid item xs={4}>Título do atendimento</Grid>
                                        <Grid item xs={8}><b>{this.state.atendimentoModal.atendimentoExecutor.titulo}</b></Grid>
                                    </Grid>
                                    <Grid item xs={12} className={'paddingForm alinhamento'}>
                                        <Grid item xs={4}>Unidade parceira</Grid>
                                        <Grid item xs={8}><b>{this.state.atendimentoModal.unidadeParceira.descricao}</b></Grid>
                                    </Grid>
                                </Grid>
                                <h3>Produção</h3>
                                <Grid container>
                                    <Grid item xs={12} className={'paddingForm alinhamento'}>
                                        <Grid item xs={6}></Grid>
                                        <Grid item xs={3}>Horas previstas</Grid>
                                        <Grid item xs={3}>Receita prevista</Grid>
                                    </Grid>
                                    {this.state.atendimentoModal.atendimentoExecutor.previsaoProducaoEmRedeList.map((prod, index) => {
                                        return (
                                            <Grid item xs={12} className={'paddingForm alinhamento'} key={index}>
                                                <Grid item xs={6}>{prod.unidade.abreviacao}</Grid>
                                                <Grid item xs={3}>{prod.numeroDeProducaoEstimada} horas</Grid>
                                                <Grid item xs={3}>{Masks.maskCurrency(prod.numeroDeReceitaEstimada)}</Grid>
                                            </Grid>
                                        )
                                    })}
                                    <Grid item xs={12} className={'paddingForm alinhamento'}>
                                        <Grid item xs={6} style={{ fontWeight: 'bold' }}>Total</Grid>
                                        <Grid item xs={3} style={{ fontWeight: 'bold' }}>{this.state.atendimentoModal.atendimentoExecutor.numeroDeProducaoEstimada} horas</Grid>
                                        <Grid item xs={3} style={{ fontWeight: 'bold' }}>{Masks.maskCurrency(this.state.atendimentoModal.atendimentoExecutor.numeroDeReceitaEstimada)}</Grid>
                                    </Grid>
                                </Grid>
                            </div>) : (<div />)}
                    </SimpleModal>
                    <SimpleModal
                        isOpen={this.modalRecusaAtendimento}
                        handleClose={() => this.changeModal('modalRecusaAtendimento', false)}
                        type={'warning'}
                        width={'380px'}
                        className={'modal-recusa-atendimento'}>
                        <label>Você realmente deseja recusar o atendimento em rede?</label>
                        <div className={'div-botoes'}>
                            <GenericButton color={'darkBlue'} label={'Cancelar'} subClass={'basic-button'} click={() => this.changeModal('modalRecusaAtendimento', false)} />
                            <GenericButton color={'darkGrey-outline'} subClass={'basic-button'} label={'Recusar atendimento'} click={() => this.recusarAtendimento()} />
                        </div>
                    </SimpleModal>
                </Grid>
            </Page>
        )
    }
}


