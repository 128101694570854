import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import GraficoAtividadesMentoresHabilitados from './graficoAtividadesMentoresHabilitados';


export default class DashboardBrasilMaisMentores extends Component {

	constructor(props) {
		super(props);
		this.state = {
			mentoresAtivos: 0,			
		}

		API.get(`rest/sistema/DashboardBrasilMais/mentoresAtivosHabilitados/${true}`).then(json => {
			if (json.data && json.data.mentores)
				this.setState({ mentoresAtivos: json.data.mentores });
		}).catch(error => {
			console.log(error);
		});
	};

	render() {
		return (
			<Grid container spacing={2} className={'dashboard-brasil-mais-mentores'}>
				<Grid item xs={12}>
					<Grid item xs={2} className={'card'}>
						<DashboardCard id={'invertido'} color={'card-blue  card-mentor'} text={'Mentores ativos no momento'} value={this.state.mentoresAtivos} />
					</Grid>
				</Grid>
				<Grid item xs={12} style={{ marginTop: '24px' }}>
					<Grid item className={'font-title'}>{'Atividades dos mentores habilitados nas Turmas do Brasil Mais'}</Grid>
					<Grid item className={'line'}></Grid>
					<GraficoAtividadesMentoresHabilitados />
				</Grid>
			</Grid>
		);
	};

};