import { TipoInputEnum } from "./tipoInput";

export const tipoInputSelectBMPTD = {
    [TipoInputEnum.SmartFactory]: [
        { id: 1, label: 'Não', value: '0' },
        { id: 2, label: 'Sim', value: '1' },
    ],
    [TipoInputEnum.InvestimentoTransformacao]: [
        { id: 1, label: 'Financiamento', value: '0' },
        { id: 2, label: 'Investimento Direto', value: '1' },
        { id: 3, label: 'Não irá investir', value: '2' },
    ],
    [TipoInputEnum.NivelTransformacao]: [
        { id: 1, label: '0', value: '0' },
        { id: 2, label: '1', value: '1' },
        { id: 3, label: '2', value: '2' },
        { id: 4, label: '3', value: '3' },
        { id: 5, label: '4', value: '4' },
        { id: 6, label: '5', value: '5' },
    ],
    [TipoInputEnum.PrioridadeTransformacao]: [
        { id: 1, label: 'Processos - Integração Vertical', value: '32' },
        { id: 2, label: 'Processos - Integração Horizontal', value: '33' },
        { id: 3, label: 'Processos - Integração do Ciclo de Vida do Produto', value: '34' },
        { id: 4, label: 'Tecnologia - Automação no Chão de Fábrica', value: '35' },
        { id: 5, label: 'Tecnologia - Automação na Empresa', value: '36' },
        { id: 6, label: 'Tecnologia - Automação nas Instalações', value: '37' },
        { id: 7, label: 'Tecnologia - Conectividade no Chão de Fábrica', value: '38' },
        { id: 8, label: 'Tecnologia - Conectividade na Empresa', value: '39' },
        { id: 9, label: 'Tecnologia - Conectividade nas Instalações', value: '40' },
        { id: 10, label: 'Tecnologia - Inteligência no Chão de Fábrica', value: '41' },
        { id: 11, label: 'Tecnologia - Inteligência na Empresa', value: '42' },
        { id: 12, label: 'Tecnologia - Inteligência nas Instalações', value: '43' }
    ],
    [TipoInputEnum.IncluiTransformacao]: [
        { id: 1, label: 'Não', value: '0' },
        { id: 2, label: 'Sim', value: '1' },
    ],
    [TipoInputEnum.FaixaTransformacao]: [
        { id: 1, label: "Até R$200.000", value: '0' },
        { id: 2, label: "Entre R$200.001 e R$400.000", value: '1' },
        { id: 3, label: "Entre R$400.001 e R$600.000", value: '2' },
        { id: 4, label: "Entre R$600.001 e R$800.000", value: '3' },
        { id: 5, label: "Entre R$800.001 e R$1.000.000", value: '4' },
        { id: 6, label: "Entre R$1.000.001 e R$1.200.000", value: '5' },
        { id: 7, label: "Entre R$1.200.001 e R$1.400.000", value: '6' },
        { id: 8, label: "Entre R$1.400.001 e R$1.600.000", value: '7' },
        { id: 9, label: "Entre R$1.600.001 e R$1.800.000", value: '8' },
        { id: 10, label: "Entre R$1.800.001 e R$2.000.000", value: '9' },
        { id: 11, label: "Mais que R$2.000.000", value: '10'}
    ],
    [TipoInputEnum.TecnologiaTransformacao]: [
        { id: 1, label: "Big Data & Analytics", value: '0' },
        { id: 2, label: "BMS (Sistema de Gestão Predial)", value: '1' },
        { id: 3, label: "Chatbots", value: '2' },
        { id: 4, label: "Cibersegurança", value: '3' },
        { id: 5, label: "Cloud Computing", value: '4' },
        { id: 6, label: "Cobots (Robôs Colaborativos)", value: '5' },
        { id: 7, label: "Comunicação M2M", value: '6' },
        { id: 8, label: "DCS (Sistema de Controle Distribuído)", value: '7' },
        { id: 9, label: "Digital Twins", value: '8' },
        { id: 10, label: "Drones", value: '9' },
        { id: 11, label: "ERP (Sistema de Gestão Empresarial)", value: '10' },
        { id: 12, label: "Manufatura Aditiva", value: '11' },
        { id: 13, label: "Máquina CNC", value: '12' },
        { id: 14, label: "MES (Sistema de Execução de Manufatura)", value: '13' },
        { id: 15, label: "Realidade Aumentada", value: '14' },
        { id: 16, label: "Realidade Virtual", value: '15' },
        { id: 17, label: "Redes 4G/5G Wifi", value: '16' },
        { id: 18, label: "Robôs Industriais", value: '17' },
        { id: 19, label: "Simulação de Processos Produtivos", value: '18' },
        { id: 20, label: "TMS (Software de Gestão Logística)", value: '19' },
        { id: 21, label: "Visão Computacional (Video Analytics)", value: '20' },
        { id: 22, label: "WMS (Sistema de Gerenciamento de Estoque)", value: '21' },
        { id: 23, label: "Outra", value: '22' },
    ],
    [TipoInputEnum.Aprovacao]: [
        { id: 1, label: 'Sim', value: '1' },
        { id: 2, label: 'Não', value: '0' },
    ],
};
