import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper';
import Page from '../../components/page/page';
import API from '../../comm/API';
import InputSelect from '../../components/inputselect/inputselect';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import Masks from '../../comm/masks';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import './colaboradorNovo.scss';

export default class Colaborador extends Component {

    titles = ['Dados Pessoais', 'Informações de Trabalho', 'Competências', 'Skills', 'Revisão'];
    ativoList = [{type: 'isAtivo', label: 'ATIVO', valor: true}, {type: 'isAtivo', label: 'INATIVO', valor: false}];
    colaborador = {};
    skillList = [];
    competenciaList = [];
    unidadeList = [];
    allUnidadeList = [];
    errors = { 
        nome: '', 
        cpf: '', 
        email: '',
        telefone: '',
        numeroDeHorasDeTrabalho: '', 
        numeroCentroResponsabilidade: '', 
        colaboradorUnidadeList: '',
        isAtivo: ''
    };
    renderAutocomplete = false;
    loadSelectContrato = false;
    dptoRegionalUser = '';
    isHomologadoBrasilMais = false;
    idProdutoBrasilMaisLEAN = null;

    constructor(props) {
        super(props);
        this.colaborador = this.colaboradorModel();
        let unidadeSigla = props.usuarioConectado.unidade.tipoUnidade.sigla;
        this.dptoRegionalUser = unidadeSigla === 'UO' ? props.usuarioConectado.unidade.unidadePai.id : (unidadeSigla === 'DR' ? props.usuarioConectado.unidade.id : '');
        this.state = { 
            idColaborador: props.idColaborador !== undefined ? props.idColaborador : undefined, 
            formacaoColaboradorList: [],
            tipoContratoColaboradorList: [], 
            funcaoColaboradorList: [], 
            isNew: true, searchUnidade: '', 
            showDropbox: false
        };
        API.get('rest/auxiliar/Skill').then(res => {
            this.skillList = res.data;
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao realizar GET das skills. Por favor, entre em contato com o suporte.', 'error');
        });
        API.get('/rest/sistema/Parametro?chave=ID_PRODUTO_BRASIL_MAIS_MENTORIA_LEAN').then(res => {
            this.idProdutoBrasilMaisLEAN = parseInt(res.data[0].valorstring);
            this.getColaborador(props.idColaborador);
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao realizer GET do parâmetro. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    colaboradorModel() {
        return { 
            nome: '', 
            cpf: '', 
            numeroDeHorasDeTrabalho: '', 
            urlFoto: 'dist/img/user-login.png', 
            telefone: '', 
            linkCurriculumVitae: '', 
            linkCurriculumLinkedin: '', 
            isAtivo: true, 
            isLaboratorio: false,
            isTransferidoParaIndustria: false, 
            numeroCentroResponsabilidade: '', 
            email: '', 
            formacaoColaborador: null, 
            tipoContratoColaborador: null, 
            funcaoColaborador: null,  
            colaboradorUnidadeList: [],
            unidadeFederativa: null, 
            colaboradorSkillList: [], 
            colaboradorProdutoRegionalList: [],
            isHomologadoBrasilMais: false };
    };

    getColaborador = async (idColaborador) => {
        if (idColaborador != null && !isNaN(idColaborador)) {
            API.get('rest/gestao/Colaborador/' + idColaborador).then(async res => {
                this.colaborador = res.data;
                this.colaborador.isHomologadoBrasilMais = res.data.isHomologadoBrasilMais ? res.data.isHomologadoBrasilMais : false;
                this.isHomologadoBrasilMais = this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN' &&
                    this.colaborador.colaboradorProdutoRegionalList.find(prod => { return prod.produtoRegional.produtoNacional.id === this.idProdutoBrasilMaisLEAN });
                this.renderAutocomplete = true;
                this.dptoRegionalUser = this.colaborador.colaboradorUnidadeList[0].unidade.tipoUnidade.sigla === 'UO' ? 
                                        this.colaborador.colaboradorUnidadeList[0].unidade.unidadePai.id :
                                        this.colaborador.colaboradorUnidadeList[0].unidade.id;
                this.getProdutoRegionalList();
                this.setState({ isNew: false });
                this.getFormacaoColaboradorList();
                this.getTipoContratoColaboradorList();
                this.getFuncaoColaboradorList();
                await this.getUnidadeList();
            }).catch(error => {
                console.log(error);
                this.renderAutocomplete = true;
                this.closeLoading();
                this.showToast('Erro ao realizar GET do colaborador. Por favor, entre em contato com o suporte.', 'error');
            });
        } else {
            this.setState({ isNew: true });
            this.renderAutocomplete = true;
            if (this.dptoRegionalUser != null && Number(this.dptoRegionalUser) > 0) this.getProdutoRegionalList();
            this.getFormacaoColaboradorList();
            this.getTipoContratoColaboradorList();
            this.getFuncaoColaboradorList();
            await this.getUnidadeList();
        }
    };

    async getUnidadeList() {
        let filter = '?id=greater(29)';
        if (this.dptoRegionalUser != null && Number(this.dptoRegionalUser) > 0) filter = filter + '&unidadePai.id=' + this.dptoRegionalUser;
        API.get('rest/gestao/Unidade' + filter).then(res => {
            this.unidadeList = res.data;
            this.allUnidadeList = res.data;
            this.closeLoading();
            this.setState(this.state);
        }).catch(error => {
            console.log(error);
            this.showToast('Erro ao realizar GET das unidades. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    getProdutoRegionalList() {
        let route = 'rest/auxiliar/ProdutoRegional/findall/' + this.dptoRegionalUser;
        API.get(route).then(res => {
            this.competenciaList = res.data;
        }).catch(error => {
            this.showToast('Erro ao realizar GET das competências. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    getTipoContratoColaboradorList = () => {
        API.get('rest/auxiliar/TipoContratoColaborador').then(res => {
            this.setState({ tipoContratoColaboradorList: res.data });
            if (this.colaborador.tipoContratoColaborador != null) {
                this.state.tipoContratoColaboradorList.forEach((obj) => {
                    if (obj.id === this.colaborador.tipoContratoColaborador.id) {
                        this.colaborador.tipoContratoColaborador = obj;
                        this.setState();
                    }
                });
            }
            this.setState();
        }).catch(error => {
            alert('Erro ao realizar GET dos tipos de contrato.\nPor favor, entre em contato com o suporte.');
        });
    };

    getFormacaoColaboradorList = () => {
        API.get('rest/auxiliar/FormacaoColaborador').then(res => {
            this.setState({ formacaoColaboradorList: res.data })
            if (this.colaborador.formacaoColaborador != null) {
                this.state.formacaoColaboradorList.forEach((obj) => {
                    if (obj.id === this.colaborador.formacaoColaborador.id) {
                        this.colaborador.formacaoColaborador = obj;
                        this.setState();
                    }
                });
            }
            this.setState();
        }).catch(error => {
            alert('Erro ao realizar GET das formações.\nPor favor, entre em contato com o suporte.');
        });
    };

    getFuncaoColaboradorList = () => {
        API.get('rest/auxiliar/FuncaoColaborador').then(res => {
            this.setState({ funcaoColaboradorList: res.data })
            if (this.colaborador.funcaoColaborador != null) {
                this.state.funcaoColaboradorList.forEach((obj) => {
                    if (obj.id === this.colaborador.funcaoColaborador.id) {
                        this.colaborador.funcaoColaborador = obj;
                        this.setState();
                    }
                });
            }
            this.setState();
        }).catch(error => {
            alert('Erro ao realizar GET das funções.\nPor favor, entre em contato com o suporte.');
        })
    };

    validateColaborador = (step) => {
        switch (step) {
            case 0:
                return this.colaborador && this.colaborador.nome && this.validateCpf() && 
                    this.errors.cpf === '' && this.validateEmail() && this.errors.email === '' && this.errors.telefone === ''
                    && (this.colaborador.colaboradorUnidadeList ? (this.colaborador.colaboradorUnidadeList.length >= 1) : true);
            case 1:
                return this.colaborador.numeroDeHorasDeTrabalho && this.colaborador.numeroCentroResponsabilidade;
            default:
                return true;
        }
    };

    validateCpf() {
        var cpf = this.colaborador.cpf.replace(/\./g, '').replace(/-/g, '');
        if (cpf.length === 11 && !isNaN(cpf)) return true;
        else return false;
    };

    cpfEmailRepetido = async (opcao) => {
        var valor = this.colaborador.cpf.replace(/\./g, '').replace(/-/g, '');
        if (opcao === 'email') valor = this.colaborador.email;
        var url = 'rest/gestao/Colaborador/repetido?' + opcao + '=' + valor;
        if (this.props.idColaborador != null && !isNaN(this.props.idColaborador)) url = 'rest/gestao/Colaborador/repetido?' + opcao + '=' + valor + '&id=' + this.props.idColaborador;
        await API.get(url).then(res => {
            if (res.data != null && res.data === true) {
                this.errors[opcao] = opcao.toUpperCase() + ' já cadastrado.';
                this.setState(this.state);
            } 
        }).catch(error => {
            this.showToast('Erro ao verificar CPF/Email. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    validateEmail(email) {
        if(email) return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
        else return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.colaborador.email);
    };

    validateTelefone(telefone) {
        return new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g).test(telefone) && telefone.length >= 14; //eslint-disable-line
    };

    maskCpfCnpj(value) {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    };

    saveColaborador = () => {
        this.showLoading();
        this.colaborador.cpf = this.colaborador.cpf.replace(/\./g, '').replace(/-/g, '');
        this.colaborador.telefone = this.colaborador.telefone ? Masks.removeMask(this.colaborador.telefone) : '';
        if (this.props.idColaborador != null && !isNaN(this.props.idColaborador)) {
            API.post('rest/gestao/Colaborador/' + this.colaborador.id, this.colaborador).then(res => {
                if (this.props.usuarioConectado.colaborador != null && this.colaborador.id === this.props.usuarioConectado.colaborador.id){
                    var usuario = this.props.usuarioConectado;
                    usuario.colaborador = this.colaborador;
                    API.post('rest/sistema/Usuario/change', usuario).then(res => {
                        this.closeLoading();
                        this.showToast('Colaborador editado com sucesso!', 'success', '/cad/pessoa/colaborador');
                    })
                } else {
                    this.closeLoading();
                    this.showToast('Colaborador editado com sucesso!', 'success', '/cad/pessoa/colaborador');
                }
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao editar o colaborador. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Colaborador', this.colaborador).then(res => {
                this.closeLoading();
                this.showToast('Colaborador cadastrado com sucesso!', 'success', '/cad/pessoa/colaborador');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao cadastrar colaborador. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    };

    createTemplateCompetencia(produtoRegional) {
        return (
            <div className={'auto-complete-options'}>
                <div>
                    <label className={'square'}>{produtoRegional.uf_sigla}</label>
                    <label>{produtoRegional.nome}</label>
                </div>
                <div>
                    <p>{produtoRegional.prod_nacional_descricao}</p>
                    <p>{produtoRegional.prod_categoria_descricao} - {produtoRegional.prod_linha_descricao}</p>
                </div>
            </div>
        );
    };
    createTemplateUnidade(unidade){
        return (
            <div className={'auto-complete-options'}>
                <div>
                    <label className={'square'}>{unidade.municipio ? unidade.municipio.unidadeFederativa.sigla : ''}</label>
                    <label>{unidade.municipio ? unidade.municipio.descricao : ''}</label>
                </div>
                <div>
                    <p>{unidade.descricao}</p>                    
                </div>
            </div>
        );
    };

    changeInput = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'cpf') {
                this.colaborador[e.target.id] = this.maskCpfCnpj(e.target.value);
                if (this.validateCpf()) this.cpfEmailRepetido('cpf');
            } else if (e.target.id === 'email') {
                this.colaborador[e.target.id] = e.target.value;
                if (this.validateEmail()) this.cpfEmailRepetido('email');
            } else if (e.target.type === 'text') {
                this.colaborador[e.target.id] = e.target.value.toUpperCase();
            } else if (e.target.id === 'isHomologadoBrasilMais') {
                this.colaborador.isHomologadoBrasilMais = !this.colaborador.isHomologadoBrasilMais;
            } else {
                this.colaborador[e.target.id] = e.target.value;
            }

            if (e.target.id === 'email' && e.target.value !== '' && !this.validateEmail(e.target.value)) {
                this.errors[e.target.id] = 'Email inválido';
            } else if (e.target.id === 'telefone' && e.target.value !== '' && !this.validateTelefone(e.target.value)) {
                this.errors[e.target.id] = 'Telefone inválido';
            } else if ((e.target.required || e.target.id === 'numeroCentroResponsabilidade') && (e.target.value == null || e.target.value === '')) {
                this.errors[e.target.id] = 'Campo obrigatório';
            } else {
                this.errors[e.target.id] = '';
            }
        } else if (e != null && e.type != null) {
            this.colaborador[e.type] = e.id != null ? e : e.valor;
        }
        this.setState(this.state);
    };

    changeCompetencias = (e) => {
        this.isHomologadoBrasilMais = this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN' &&
            e.find(prod => { return (prod.produtoRegional.produtoNacional && prod.produtoRegional.produtoNacional.id === this.idProdutoBrasilMaisLEAN) || prod.produtoRegional.prod_nacional_id === this.idProdutoBrasilMaisLEAN });
        this.colaborador.colaboradorProdutoRegionalList = e;
        if (!this.isHomologadoBrasilMais && this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN') this.colaborador.isHomologadoBrasilMais = false;
        this.setState(this.state);
    };

    changeUnidades = (e) => {
        if (e != null && e.length > 0) {
            this.colaborador.colaboradorUnidadeList = e;
            if (this.dptoRegionalUser == null || this.dptoRegionalUser !== e[0].unidade.unidadePai.id) {
                this.dptoRegionalUser = this.colaborador.colaboradorUnidadeList[0].unidade.tipoUnidade.sigla === 'UO' ? 
                                        this.colaborador.colaboradorUnidadeList[0].unidade.unidadePai.id :
                                        this.colaborador.colaboradorUnidadeList[0].unidade.id;
                this.getProdutoRegionalList();
            }
            this.unidadeList = this.allUnidadeList.filter(unidade => {
                return unidade.unidadePai.id === e[0].unidade.unidadePai.id;
            });
            this.setState(this.state);
        } else if (e != null && e.length === 0 && this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN') {
            this.colaborador.colaboradorUnidadeList = e;
            this.competenciaList = [];
            this.unidadeList = this.allUnidadeList;
            this.colaborador.colaboradorProdutoRegionalList = [];
            this.dptoRegionalUser = '';
            this.setState(this.state);
        } else if (e != null && e.length === 0) {
            this.colaborador.colaboradorUnidadeList = e;
            this.setState(this.state);
        }
    };

    changeSkill = (e) => {
        this.colaborador.colaboradorSkillList = e;
        this.setState(this.state);
    };

    getSteps = () => {   
        return [
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={9} className={'paddingForm'}>
                            <FormField type={'text'} label={'Nome'} id={'nome'} inputValue={this.colaborador.nome} changeValue={this.changeInput}
                                required={'required'} error={this.errors.nome} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <InputSelect label={'Status'} suggestions={this.ativoList} itemLabel={'label'} getInput={this.changeInput} id={'isAtivo'} itemKey={'label'}
                                value={this.colaborador.isAtivo ? this.ativoList[0] : this.ativoList[1]} initialSelectedItem={this.colaborador.isAtivo ? this.ativoList[0] : this.ativoList[1]}
                                error={this.errors.isAtivo} />
                        </Grid>
                        <Grid item xs={12} sm={3} className={'paddingForm'}>
                            <FormField type={'text'} label={'CPF'} id={'cpf'} inputValue={this.colaborador.cpf} changeValue={this.changeInput}
                                required={'required'} error={this.errors.cpf} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <FormField type={'text'} label={'Email'} id={'email'} inputValue={this.colaborador.email} changeValue={this.changeInput}
                                required={'required'} error={this.errors.email} />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <FormField type={'text'} label={'Telefone'} id={'telefone'} inputValue={Masks.maskTelefone(this.colaborador.telefone)} changeValue={this.changeInput}
                                error={this.errors.telefone} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Currículo Lattes'} id={'linkCurriculumVitae'} inputValue={this.colaborador.linkCurriculumVitae ? this.colaborador.linkCurriculumVitae : ''}
                                changeValue={this.changeInput} placeholder={'Insira a URL do currículo Lattes'} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Currículo Linkedin'} id={'linkCurriculumLinkedin'} inputValue={this.colaborador.linkCurriculumLinkedin ? this.colaborador.linkCurriculumLinkedin : ''}
                                changeValue={this.changeInput} placeholder={'Insira a URL do currículo linkedin'} />
                        </Grid>
                        <Grid item xs={12} >
                             {this.renderAutocomplete ? 
                                <AutoCompleteMany
                                key={'colaboradorUnidadeList'}
                                itemlabel={'descricao'}
                                suggestions={this.unidadeList}
                                getInput={this.changeUnidades}
                                itemTemplate={this.createTemplateUnidade}
                                itemKey={'id'}
                                label={'Selecione a unidade'}
                                chipLabel={'Unidade(s)'}
                                placeholder={'Ex: Insituto Senai de Inovação'}
                                id={'colaboradorUnidade'}
                                initialOjectSelectedList={this.colaborador.colaboradorUnidadeList}
                                initialOjectSelectedListClass={'unidade'}
                            /> : null }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <InputSelect label={'Tipo de Contrato'} suggestions={this.state.tipoContratoColaboradorList} itemKey={'id'} itemLabel={'descricao'} id={'tipoContratoColaborador'} 
                                getInput={this.changeInput} initialSelectedItem={{ id: this.colaborador.tipoContratoColaborador != null ? this.colaborador.tipoContratoColaborador.id : undefined }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect label={'Formação'} suggestions={this.state.formacaoColaboradorList} itemKey={'id'} itemLabel={'descricao'} id={'formacaoColaborador'} getInput={this.changeInput} 
                                initialSelectedItem={{ id: this.colaborador.formacaoColaborador != null ? this.colaborador.formacaoColaborador.id : undefined }} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <InputSelect label={'Função'} suggestions={this.state.funcaoColaboradorList} itemKey={'id'} itemLabel={'descricao'} id={'funcaoColaborador'} getInput={this.changeInput} 
                                initialSelectedItem={{ id: this.colaborador.funcaoColaborador != null ? this.colaborador.funcaoColaborador.id : undefined }} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField type={'text'} label={'CR'} id={'numeroCentroResponsabilidade'} inputValue={this.colaborador.numeroCentroResponsabilidade} changeValue={this.changeInput}
                                error={this.errors.numeroCentroResponsabilidade} placeholder={'Digite o CR do(a) colaborador(a)'} />
                        </Grid>
                        <Grid item xs={6} className={'paddingForm'}>
                            <FormField type={'number'} label={'Nº de Horas'} id={'numeroDeHorasDeTrabalho'} inputValue={this.colaborador.numeroDeHorasDeTrabalho} changeValue={this.changeInput}
                                error={this.errors.numeroDeHorasDeTrabalho} placeholder={'Horas de trabalho por mês'} required={'required'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <AutoCompleteMany
                        itemlabel={'nome'}
                        suggestions={this.competenciaList}
                        getInput={this.changeCompetencias}
                        itemTemplate={this.createTemplateCompetencia}
                        itemKey={'id'}
                        label={'Tipo de Competência'}
                        chipLabel={'Competências Associadas'}
                        placeholder={'Digite uma Competência do(a) colaborador(a)'}
                        id={'nome'}
                        initialOjectSelectedList={this.colaborador.colaboradorProdutoRegionalList}
                        initialOjectSelectedListClass={'produtoRegional'}
                    />
                </Grid>
                <Grid item xs={12}></Grid>
                { this.isHomologadoBrasilMais && 
                    <Grid item xs={12} md={9} lg={6} xl={5}>
                        <FormControlLabel control={<Checkbox checked={this.colaborador.isHomologadoBrasilMais} onChange={this.changeInput} id={'isHomologadoBrasilMais'}
                            className={'checkbox'} name={'isHomologadoBrasilMais'} />} label='Brasil Mais - Mentor Homologado' />
                    </Grid> }
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} sm={7} lg={6} xl={5}>
                    <AutoCompleteMany
                        key={'skills'}
                        itemlabel={'descricao'}
                        suggestions={this.skillList}
                        getInput={this.changeSkill}
                        itemKey={'id'}
                        label={'Tipo de Skill'}
                        chipLabel={'Skills Associadas'}
                        placeholder={'Selecione uma Skill do(a) colaborador(a)'}
                        id={'descricao'}
                        initialOjectSelectedList={this.colaborador.colaboradorSkillList}
                        initialOjectSelectedListClass={'skill'}
                    />
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} sm={6} xl={3} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>1</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Dados Pessoais</label>
                        <Grid container>
                            <Grid item xs={3}>Nome</Grid>
                            <Grid item xs={9}>{this.colaborador.nome}</Grid>
                            <Grid item xs={3}>Status</Grid>
                            <Grid item xs={9}>{this.colaborador.isAtivo ? 'Ativo' : 'Inativo'}</Grid>
                            <Grid item xs={3}>CPF</Grid>
                            <Grid item xs={9}>{this.colaborador.cpf}</Grid>
                            <Grid item xs={3}>Email</Grid>
                            <Grid item xs={9}>{this.colaborador.email}</Grid>
                            <Grid item xs={3}>Telefone</Grid>
                            <Grid item xs={9}>{Masks.maskTelefone(this.colaborador.telefone)}</Grid>
                            <Grid item xs={3}>Lattes</Grid>
                            <Grid item xs={9}>{this.colaborador.linkCurriculumVitae}</Grid>
                            <Grid item xs={3}>Linkedin</Grid>
                            <Grid item xs={9}>{this.colaborador.linkCurriculumLinkedin}</Grid>
                            <Grid item xs={3}>Unidade</Grid>                         
                            <Grid item xs={9}>
                            {this.colaborador.colaboradorUnidadeList != null ? 
                                this.colaborador.colaboradorUnidadeList.map(
                                    (colaboradorUnidade, index) => (
                                        <p key={index}>{colaboradorUnidade.unidade.descricao}</p>
                                    )
                                ) : null}
                            </Grid>                                
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} xl={3} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>2</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Informações de Trabalho</label>
                        <Grid container>
                            <Grid item xs={3}>Contrato</Grid>
                            <Grid item xs={9}>{this.colaborador.tipoContratoColaborador != null ? this.colaborador.tipoContratoColaborador.descricao : ''}</Grid>
                            <Grid item xs={3}>Função</Grid>
                            <Grid item xs={9}>{this.colaborador.funcaoColaborador != null ? this.colaborador.funcaoColaborador.descricao : ''}</Grid>
                            <Grid item xs={3}>Formação</Grid>
                            <Grid item xs={9}>{this.colaborador.formacaoColaborador != null ? this.colaborador.formacaoColaborador.descricao : ''}</Grid>
                            <Grid item xs={3}>Nº de Horas</Grid>
                            <Grid item xs={9}>{this.colaborador.numeroDeHorasDeTrabalho}</Grid>
                            <Grid item xs={3}>CR</Grid>
                            <Grid item xs={9}>{this.colaborador.numeroCentroResponsabilidade}</Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} xl={3} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>3</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Competências</label>
                        <Grid container>
                            <Grid item xs={12}>
                                {this.colaborador.colaboradorProdutoRegionalList.map(
                                    (colaboradorProdutoRegional, index) => (
                                        <p key={colaboradorProdutoRegional.id}>{colaboradorProdutoRegional.produtoRegional ? colaboradorProdutoRegional.produtoRegional.nome : ''}</p>
                                    )
                                )}
                            </Grid>
                            { this.isHomologadoBrasilMais && 
                                <Grid item xs={12}>
                                    <p key={'isHomologadoBrasilMais'}><b>Brasil Mais - Mentor Homologado: {this.colaborador.isHomologadoBrasilMais ? 'SIM' : 'NÃO' }</b></p>
                                </Grid> }
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={6} xl={3} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>4</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Skills</label>
                        <Grid container>
                            <Grid item xs={12}>
                                {this.colaborador.colaboradorSkillList.map(
                                    (colaboradorSkill, index) => (
                                        <p key={colaboradorSkill.id}>{colaboradorSkill.skill.descricao}</p>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid >]
    }

    
    render() {
        return (
            <Page icon={'profile.svg'} label={'Novo Colaborador'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'novo-colaborador'}>
                    <Stepper titles={this.titles} steps={this.getSteps(this.state)} validation={this.validateColaborador} sendForm={this.saveColaborador} history={this.props.history} />
                </Grid>
            </Page>
        )
        
    }
}