import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from './../../comm/API';
import './solicitacoesDN.scss';

export default class Metas extends Component {

    state = { qtdAceitoGoverno: null, qtdAceitoSenai: null, metaGoverno: null, metaSenai: null };

    componentWillMount() {
        this.getQtdeAtendimentoAceitoSetorGoverno();
        this.getQtdeAtendimentoAceitoSetorSenai();
        this.getItemMetaRegional();
    }

    async getQtdeAtendimentoAceitoSetor() {
        let qtdAceitoSetor = (await API.get(`rest/gestao/Atendimento/count?setor.id=${this.props.atendimento.setor.id}&solicitacaoStatus.chave=conforme`)).data;
        this.setState({ qtdAceitoSetor: qtdAceitoSetor.count });
    }
    async getQtdeAtendimentoAceitoSetorGoverno() {
        let qtdAceitoGoverno = (await API.get(`/rest/gestao/Atendimento/count?isMetaGoverno=true&setor.id=${this.props.atendimento.setor.id}&solicitacaoStatus.chave=conforme`)).data;
        this.setState({ qtdAceitoGoverno: qtdAceitoGoverno.count });
    }
    async getQtdeAtendimentoAceitoSetorSenai() {
        let qtdAceitoSenai = (await API.get(`rest/gestao/Atendimento/count?isMetaGoverno=notEquals(true)&setor.id=${this.props.atendimento.setor.id}&solicitacaoStatus.chave=conforme`)).data;
        this.setState({ qtdAceitoSenai: qtdAceitoSenai.count });
    }
    async getItemMetaRegional() {
        let itemMetaRegional = (await API.get(`rest/gestao/ItemMetaRegional?metaRegional.unidade.id=${this.props.atendimento.unidade.unidadePai.id}&setor.id=${this.props.atendimento.setor.id}`)).data;
        if (itemMetaRegional && itemMetaRegional.length > 0) {
            this.setState({ metaGoverno: itemMetaRegional[0].metaGoverno, metaSenai: itemMetaRegional[0].metaSenai });
        }
    }

    render() {
        return (
            <div className={'greyDiv'}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h2 className={'font-blue'}>Metas</h2>
                    </Grid>
                    <Grid item xs={6}>
                        <b>Setor</b>
                    </Grid>
                    <Grid item xs={6}>
                        <label>{this.props.atendimento.setor.descricao}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <b>Atendimento</b>
                    </Grid>
                    <Grid item xs={3}>
                        <label>{this.state.qtdAceitoSenai} Senai</label>
                    </Grid>
                    <Grid item xs={3}>
                        <label>{this.state.qtdAceitoGoverno} Gov.</label>
                    </Grid>
                    <Grid item xs={6}>
                        <b>Meta Senai</b>
                    </Grid>
                    <Grid item xs={6}>
                        <label>{this.state.metaSenai}</label>
                    </Grid>
                    <Grid item xs={6}>
                        <b>Meta Governo</b>
                    </Grid>
                    <Grid item xs={6}>
                        <label>{this.state.metaGoverno}</label>
                    </Grid>
                </Grid>
            </div>
        )
    }
}