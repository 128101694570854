import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from "@material-ui/core";
import React, { Component } from "react";
import API from "./../../comm/API";
import FormField from "./../../components/formField/formField";
import GenericButton from "./../../components/genericButton/genericButton";
import Table from "./../../components/table/table";

export default class RegistroIndicadores extends Component {
  types = ["label", "label", "inputNumber", "inputNumber", "label", "icon"];

  registroTitlesPayback = [
    { id: "0", canOrder: true, label: "Nome do indicador" },
    { id: "2", canOrder: false, label: "Valor do Programa (R$)" },
    { id: "1", canOrder: false, label: "Retorno Mensal (R$)" },
    { id: "3", canOrder: false, label: "Resultado" },
    { id: "4", canOrder: false, label: "" },
  ];

  registroTitles = [
    { id: "0", canOrder: true, label: "Nome do indicador" },
    { id: "2", canOrder: false, label: "Medição Inicial" },
    { id: "1", canOrder: false, label: "Medição Final" },
    { id: "3", canOrder: false, label: "Resultado" },
    { id: "4", canOrder: false, label: "" },
  ];

  registroTitlesAvaliacaoPayback = this.registroTitlesPayback.concat([{ id: "5", canOrder: false, label: "Parecer DN" }]);
  registroTitlesAvaliadoPayback = [{ id: "5", canOrder: false, label: "Parecer DN" }].concat(this.registroTitlesPayback);
  registroTitlesAvaliacao = this.registroTitles.concat([{ id: "5", canOrder: false, label: "Parecer DN" }]);
  registroTitlesAvaliado = [{ id: "5", canOrder: false, label: "Parecer DN" }].concat(this.registroTitles);

  parecerDNOptions = {
    conforme: { id: 2, chave: "conforme", descricao: "Conforme" },
    naoConforme: { id: 3, chave: "naoconforme", descricao: "Não Conforme" },
  };

  configuracoesIndicadores = {
    412157: { obrigatorio: ["1", "4", "25", "26", "27"] },
    412158: { obrigatorio: ["5", "28", "6", "12", "13", "14", "29"] },
    42120: { obrigatorio: ["1", "2", "3", "8"] },
    412148: { obrigatorio: ["1", "3", "4", "11", "16", "8", "9", "10", "15", "17", "18", "25", "26", "27"] },
  };

  indicadorReferencialLines = [];
  indicadorReferencialList = [];

  constructor(props) {
    super(props);
    this.state = {
      atendimentoCP: JSON.parse(JSON.stringify(this.props.atendimento)),
    };
    this.isDN = this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DN";
    this.getIndicadoresRefresh();
  }

  getIndicadorReferencialLines = async () => {
    let codigoDN = this.props.atendimento.produtoRegional.produtoNacional.codigoDN;

    try {
      const res = await API.get("/rest/auxiliar/IndicadorReferencial");
      this.indicadorReferencialList = res.data.sort((i, j) => (i.id > j.id ? 1 : -1));

      // Converte o código DN para string para a comparação, pois as chaves em 'configuracoes' são strings
      codigoDN = String(codigoDN);

      if (this.configuracoesIndicadores.hasOwnProperty(codigoDN)) {
        // Converte os IDs dos indicadores para string antes de verificar
        const idsObrigatorios = this.configuracoesIndicadores[codigoDN].obrigatorio;
        this.indicadorReferencialList = this.indicadorReferencialList
          .filter((indicador) => idsObrigatorios.includes(indicador.id.toString()))
          .sort((a, b) => idsObrigatorios.indexOf(a.id.toString()) - idsObrigatorios.indexOf(b.id.toString()));
      }

      // Criar as linhas de registro para os indicadores
      this.indicadorReferencialLines = this.indicadorReferencialList.map((indicador, index) => this.createLineRegistro(indicador, index));
    } catch (error) {
      console.log(error);
    }
  };

  getIndicadoresRefresh = async (index) => {
    if (this.isDN) {
      let atendimento = this.state.atendimentoCP;
      let indicadores = atendimento.indicadorAtendimentoReferencialList;
      if (this.indicadorReferencialLines.length === 0) {
        indicadores = indicadores.sort((curr, other) => (curr.indicadorReferencial.id > other.indicadorReferencial.id ? 1 : -1));
        await indicadores.forEach((indicador, i) => {
          this.indicadorReferencialLines.push(this.createLineAvaliacao(indicador, i));
        });
      } else {
        this.indicadorReferencialLines[index] = this.createLineAvaliacao(indicadores[index], index);
      }
    } else if (index >= 0) {
      this.indicadorReferencialLines[index] = this.createLineRegistro(this.indicadorReferencialList[index], index);
    } else {
      await this.getIndicadorReferencialLines();
    }
    this.setState(this.state);
    this.props.closeLoading();
  };

  getIndicadorAtendimentoRefModel = (indicador) => {
    return {
      indicadorReferencial: indicador,
      medicaoFinal: "",
      medicaoInicial: "",
      resultado: "",
    };
  };

  getInputTable = (id, placeholder, attr, index, isDisabled) => {
    let atendimento = this.state.atendimentoCP;
    let inputValue = atendimento.indicadorAtendimentoReferencialList[index][attr];
    if (placeholder) {
      placeholder = placeholder.split(";");
      placeholder = placeholder.length > 1 ? { medicaoInicial: placeholder[0], medicaoFinal: placeholder[1] }[attr] : placeholder;
    }
    return (
      <Grid>
        {this.isDN ? (
          <label key={`${attr}-${id}`}>
            {" "}
            {inputValue} {placeholder}{" "}
          </label>
        ) : (
          <FormField
            key={`${attr}-${id}-${inputValue}`}
            type={"number"}
            changeValue={this.changeMedicao}
            id={index}
            subtype={"seiscasas"}
            inputValue={inputValue ? inputValue : 0}
            title={placeholder}
            placeholder={placeholder}
            name={attr}
            min="1"
            maxLength="20"
            disabled={isDisabled}
          />
        )}
      </Grid>
    );
  };

  getIconTable = (formula) => {
    let prefixFormula = "-";
    let codigoDN = String(this.props.atendimento.produtoRegional.produtoNacional.codigoDN);

    if (String(codigoDN) === "412158") {
      prefixFormula = "";
    }

    const icon = <img src={"./../../assets/icons/formula-indicador.svg"} alt="" />;
    return (
      <div className={"tooltip-indicadores"}>
        <div>
          <span className={"formula"}>
            <Grid container>
              <Grid item container>
                <Grid item> {icon} </Grid>
                <Grid item>
                  <label className={"icon-label"}> Fórmula do resultado </label> <br />
                </Grid>
              </Grid>
              <Grid item>
                {" "}
                <label>
                  {" "}
                  {prefixFormula} {formula}{" "}
                </label>{" "}
              </Grid>
            </Grid>
          </span>
        </div>
        {icon}
      </div>
    );
  };

  checkParecerDN = (indicador, index) => {
    let solicitacaoStatus = indicador.solicitacaoStatus ? indicador.solicitacaoStatus.descricao : "";
    const setIcon = (icon) => <img src={`./../../assets/icons/${icon}.svg`} alt="" />;
    return (
      <Grid item xs={12}>
        <Grid container style={{ marginBottom: "3%" }} direction={"row"}>
          <Grid item className={"radioDisabled"}>
            <FormControl component="fieldset">
              <RadioGroup aria-label="position" name={`radio-group-${index}`} value={solicitacaoStatus} onChange={this.changeInput} row>
                <Grid container direction={"row"}>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Aprovar </span>
                    <FormControlLabel
                      value={"Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Não Conforme" ? "conforme-gray" : "conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                  <Grid item xs={5} className={"tooltip-solicitacao"}>
                    <span className={"tooltip-info avaliar"}> Reprovar </span>
                    <FormControlLabel
                      value={"Não Conforme"}
                      control={
                        <Radio
                          color="primary"
                          disabled={false}
                          checkedIcon={setIcon("nao-conforme-checked")}
                          icon={setIcon(solicitacaoStatus === "Conforme" ? "nao-conforme-gray" : "nao-conforme-unchecked")}
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  calculaFormula = (indicador, ref) => {
    let result = NaN;
    const localeStr = (value) => value.toLocaleString("pt-BR", { minimumFractionDigits: 6, maximumFractionDigits: 6 });
    if (!isNaN(indicador.medicaoFinal) && !isNaN(indicador.medicaoInicial)) {
      if (ref.sufixo === "%") {
        let subtract = indicador.medicaoFinal - indicador.medicaoInicial;
        if (ref.id === 3 || ref.id === 5 || ref.id === 6) {
          subtract = indicador.medicaoInicial - indicador.medicaoFinal;
        }
        result = (100 * subtract) / indicador.medicaoInicial;
      } else if (ref.sufixo === "meses") result = indicador.medicaoInicial / indicador.medicaoFinal;
      else {
        result = ref.id === 12 || ref.id === 13 ? indicador.medicaoInicial - indicador.medicaoFinal : indicador.medicaoFinal - indicador.medicaoInicial;
      }

      result = this.calculateBPEE(ref, indicador, result);

      result = isFinite(result) ? result : NaN;
      indicador.resultado = isNaN(result) ? "" : parseFloat(localeStr(result).replace(",", "."));
    }
    return (
      <label>
        {" "}
        <b> {(isNaN(result) ? "-" : localeStr(result)) + "   " + ref.sufixo} </b>{" "}
      </label>
    );
  };

  changeMedicao = (e) => {
    let atendimento = this.state.atendimentoCP;
    let indicadores = atendimento.indicadorAtendimentoReferencialList;
    let index = parseInt(e.target.id);
    let value = Number(e.target.value);
    let codigoDN = this.props.atendimento.produtoRegional.produtoNacional.codigoDN;

    const indicador = indicadores[index];
    const isIndicadorParaProdutividade =
      indicador &&
      indicador.indicadorReferencial &&
      (indicador.indicadorReferencial.nome === "Número de operadores por turno" ||
        indicador.indicadorReferencial.nome === "Quantidade de horas por turno" ||
        indicador.indicadorReferencial.nome === "Quantidade produzida nas horas trabalhadas por turno");
    indicadores[index][e.target.name] = isNaN(value) ? "" : value;

    this.syncIndicadoresEficienciaEnergetica(indicadores, index, codigoDN, e, value);

    if (isIndicadorParaProdutividade) {
      const indicadorNO = indicadores.find((obj) => obj.indicadorReferencial.nome === "Número de operadores por turno");
      const indicadorQH = indicadores.find((obj) => obj.indicadorReferencial.nome === "Quantidade de horas por turno");
      const indicadorQP = indicadores.find((obj) => obj.indicadorReferencial.nome === "Quantidade produzida nas horas trabalhadas por turno");
      const indexProdutividade = indicadores.findIndex((obj) => {
        return obj.indicadorReferencial.nome === "Produtividade Lean";
      });
      if (indicadorNO.medicaoInicial !== "" && indicadorQH.medicaoInicial !== "" && indicadorQP.medicaoInicial !== "") {
        this.changeMedicao({
          target: {
            id: indexProdutividade,
            value: parseFloat((indicadorQP.medicaoInicial / indicadorQH.medicaoInicial / indicadorNO.medicaoInicial).toFixed(6)),
            name: "medicaoInicial",
          },
        });
      }
      if (indicadorNO.medicaoFinal !== "" && indicadorQH.medicaoFinal !== "" && indicadorQP.medicaoFinal !== "") {
        this.changeMedicao({
          target: {
            id: indexProdutividade,
            value: parseFloat((indicadorQP.medicaoFinal / indicadorQH.medicaoFinal / indicadorNO.medicaoFinal).toFixed(6)),
            name: "medicaoFinal",
          },
        });
      }
    }
    atendimento.indicadorAtendimentoReferencialList = indicadores;
    this.setState({ atendimentoCP: atendimento });
    this.getIndicadoresRefresh(index);
  };

  changeInput = (e) => {
    const index = parseInt(e.target.name.split("-")[2]);
    let atendimento = this.state.atendimentoCP;
    let indicador = atendimento.indicadorAtendimentoReferencialList[index];
    indicador.solicitacaoStatus = e.target.value === "Conforme" ? this.parecerDNOptions.conforme : this.parecerDNOptions.naoConforme;
    atendimento.indicadorAtendimentoReferencialList[index] = indicador;
    this.setState({ atendimentoCP: atendimento });
    this.getIndicadoresRefresh(index);
  };

  createLine = (indicador, index) => {
    let referencial = indicador.indicadorReferencial;
    let parecerDNOptions = { naoconforme: "reprovado", conforme: "aprovado" };
    let iconParecer = indicador.solicitacaoStatus ? parecerDNOptions[indicador.solicitacaoStatus.chave] : "nao-avaliado";
    const isDisabled = this.getDisableFields(indicador);
    let montar = [
      <b>{referencial.nome}</b>,
      this.getInputTable(referencial.id, referencial.placeholder, "medicaoFinal", index, isDisabled),
      this.getInputTable(referencial.id, referencial.placeholder, "medicaoInicial", index, isDisabled),
      this.calculaFormula(indicador, referencial),
      this.getIconTable(referencial.formula),
    ];

    let avaliado = (
      <Grid container className={"indicadores-avaliados"}>
        <Grid item>
          {" "}
          <img src={`./../../assets/icons/${iconParecer}.svg`} alt="" />{" "}
        </Grid>
        <Grid item>
          {" "}
          <label> {iconParecer === "nao-avaliado" ? "não avaliado" : iconParecer} </label>{" "}
        </Grid>
      </Grid>
    );
    montar = this.isDN ? montar : montar.concat(avaliado);

    return montar;
  };

  getDisableFields(indicador) {
    if (indicador && indicador.indicadorReferencial) {
      let codigoDN = String(this.props.atendimento.produtoRegional.produtoNacional.codigoDN);

      if (indicador.indicadorReferencial.nome === "Produtividade Lean") {
        return true;
      }

      if (String(codigoDN) === "412158") {
        const idsParaAtualizar = [28, 12, 13, 14];

        if (idsParaAtualizar.includes(indicador.indicadorReferencial.id)) {
          return true;
        }
      }
    }

    return false;
  }

  createLineAvaliacao = (indicador, index) => {
    let line = this.createLine(indicador, index);
    line.push(this.checkParecerDN(indicador, index));
    return line;
  };

  createLineRegistro = (indicador, index) => {
    let codigoDN = String(this.props.atendimento.produtoRegional.produtoNacional.codigoDN);

    let sort = (indicadores) => indicadores.sort((i, j) => (i.indicadorReferencial.id > j.indicadorReferencial.id ? 1 : -1));

    // Filtrar baseado no codigoDN
    if (this.configuracoesIndicadores.hasOwnProperty(codigoDN)) {
      const idsObrigatorios = this.configuracoesIndicadores[codigoDN].obrigatorio;
      if (!idsObrigatorios.includes(indicador.id.toString())) {
        return null; // Retorna null se o indicador não for obrigatório para este codigoDN
      }
      sort = (indicadores) => {
        return indicadores.sort((a, b) => {
          const indexA = idsObrigatorios.indexOf(a.indicadorReferencial.id.toString());
          const indexB = idsObrigatorios.indexOf(b.indicadorReferencial.id.toString());
          return indexA - indexB;
        });
      };
    }

    // Lógica existente para criar a linha

    let atendimento = this.state.atendimentoCP;
    let indicadores = atendimento.indicadorAtendimentoReferencialList;
    atendimento.indicadorAtendimentoReferencialList = sort(indicadores);
    let indicadorAtdRef = atendimento.indicadorAtendimentoReferencialList.find((obj) => obj.indicadorReferencial.id === indicador.id);

    if (!indicadorAtdRef) {
      indicadorAtdRef = this.getIndicadorAtendimentoRefModel(indicador);
      indicadores.push(indicadorAtdRef);
      atendimento.indicadorAtendimentoReferencialList = sort(indicadores);
      this.setState({ atendimentoCP: atendimento });
    }

    return this.createLine(indicadorAtdRef, index);
  };

  salvarRegistros = async () => {
    let atendimento = JSON.parse(JSON.stringify(this.state.atendimentoCP));
    let preenchidos = atendimento.indicadorAtendimentoReferencialList
      .filter((obj) => obj.resultado != null && !isNaN(obj.resultado) && obj.resultado !== "" && !(obj.medicaoInicial === "" && obj.medicaoFinal === "" && obj.resultado === 0))
      .slice();
    atendimento.indicadorAtendimentoReferencialList = preenchidos;
    try {
      this.props.showLoading();
      await API.post(`/rest/gestao/Atendimento/${atendimento.id}`, atendimento);
      this.setState({ atendimentoCP: atendimento });
      this.props.refreshAtendimento(atendimento);
      this.props.closeLoading();
      this.props.showToast(!this.isDN ? "Indicadores registrados com sucesso!" : "Indicadores avaliados com sucesso!", "success");
      this.backToResumo();
    } catch (error) {
      this.props.closeLoading();
      this.props.showToast("Erro ao registrar os indicadores. Favor entrar em contato com o suporte.", "error");
    }
  };

  backToResumo = () => {
    window.scrollTo(0, 0);
    this.props.openIndicadoresScreen(false);
  };

  calculateBPEE(ref, indicador, result) {
    let codigoDN = this.props.atendimento.produtoRegional.produtoNacional.codigoDN;

    if (String(codigoDN) === "412158") {
      const operacoes = {
        5: () => Math.abs(((indicador.medicaoInicial - indicador.medicaoFinal) / indicador.medicaoInicial) * 100),
        6: () => Math.abs(indicador.medicaoFinal - indicador.medicaoInicial),
        12: () => Math.abs((indicador.medicaoInicial - indicador.medicaoFinal) * 0.0426),
        13: () => Math.abs((indicador.medicaoInicial - indicador.medicaoFinal) / 4169.76),
        14: () => Math.abs((indicador.medicaoInicial - indicador.medicaoFinal) / 1.98),
        28: () => Math.abs(indicador.medicaoInicial - indicador.medicaoFinal),
        29: () => indicador.medicaoInicial,
      };

      const operacao = operacoes[ref.id];

      if (operacao) {
        result = operacao();
      }
    }
    return result;
  }

  updateTitleColumnsEE() {
    let codigoDN = this.props.atendimento.produtoRegional.produtoNacional.codigoDN;

    if (String(codigoDN) === "412158") {
      this.registroTitlesAvaliadoPayback = this.registroTitlesAvaliadoPayback.filter((coluna) => coluna.id !== "1");
      this.registroTitlesAvaliacaoPayback = this.registroTitlesAvaliacaoPayback.filter((coluna) => coluna.id !== "1");

      this.registroTitlesAvaliadoPayback = this.registroTitlesAvaliadoPayback.map((coluna) => {
        if (coluna.label === "Valor do Programa (R$)") {
          return { ...coluna, label: "Indicador" };
        }
        return coluna;
      });

      this.registroTitlesAvaliacaoPayback = this.registroTitlesAvaliacaoPayback.map((coluna) => {
        if (coluna.label === "Valor do Programa (R$)") {
          return { ...coluna, label: "Indicador" };
        }
        return coluna;
      });
    }
  }

  syncIndicadoresEficienciaEnergetica(indicadores, index, codigoDN, e, value) {
    if (indicadores[index].indicadorReferencial.id === 5 && String(codigoDN) === "412158") {
      const idsParaAtualizar = [28, 12, 13, 14];

      idsParaAtualizar.forEach((id) => {
        let idx = indicadores.findIndex((indic) => indic.indicadorReferencial.id === id);
        if (idx !== -1) {
          if (e.target.name === "medicaoInicial") {
            this.changeMedicao({
              target: {
                id: idx,
                value: value,
                name: "medicaoInicial",
              },
            });
          }
          if (e.target.name === "medicaoFinal") {
            this.changeMedicao({
              target: {
                id: idx,
                value: value,
                name: "medicaoFinal",
              },
            });
          }
          indicadores[idx][e.target.name] = value;
        }
      });
    }
  }

  render() {
    const icon = (img) => <img className={"tooltipIndicadores"} src={`./../../assets/icons/${img}.svg`} alt="" />;
    const action = this.isDN ? "Avaliar" : "Registrar";
    let indicadorReferencialLinesPayback = [];
    let indicadorReferencialLinesRest = [];
    if (this.indicadorReferencialLines && this.indicadorReferencialLines.length > 0) {
      let index = this.indicadorReferencialLines.findIndex((obj) => {
        return obj[0].props && (obj[0].props.children === "Retorno do Programa" || obj[0].props.children === "Retorno do Programa (EE)");
      });
      indicadorReferencialLinesPayback = index > -1 ? [this.indicadorReferencialLines[index]] : [];
      indicadorReferencialLinesRest = this.indicadorReferencialLines.filter(
        (obj) => obj[0].props && obj[0].props.children !== "Retorno do Programa" && obj[0].props.children !== "Retorno do Programa (EE)"
      );

      this.updateTitleColumnsEE();
    }

    return (
      <Grid container>
        <Grid item xs={12}>
          <Grid container direction={"row"} className={"registar-anexo"} spacing={2}>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"left"}> Resumo do Atendimento </label>{" "}
            </Grid>
            <Grid item className={"arrow-indicadores"}>
              {" "}
              {icon("arrow-registro-indicadores")}{" "}
            </Grid>
            <Grid item className={"lbl-arrow"}>
              {" "}
              <label className={"right"}>{action} Indicadores</label>{" "}
            </Grid>
          </Grid>
        </Grid>
        {indicadorReferencialLinesPayback.length > 0 && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Table
              cellWidth={"15%"}
              lines={indicadorReferencialLinesPayback}
              titles={this.isDN ? this.registroTitlesAvaliacaoPayback : this.registroTitlesAvaliadoPayback}
              types={this.types}
              initialOrder={""}
              pagination={false}
            ></Table>
          </Grid>
        )}
        {indicadorReferencialLinesRest.length > 0 && (
          <Grid item xs={12} style={{ marginTop: "2%" }}>
            <Table
              cellWidth={"15%"}
              lines={indicadorReferencialLinesRest}
              titles={this.isDN ? this.registroTitlesAvaliacao : this.registroTitlesAvaliado}
              types={this.types}
              initialOrder={""}
              pagination={false}
            ></Table>
          </Grid>
        )}
        {this.indicadorReferencialLines.length > 0 && (
          <Grid item container xs={12} justify={"center"} style={{ marginTop: "2%" }}>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton
                color={"darkBlue-outline"}
                label={"Cancelar"}
                subClass={"basic-button"}
                click={this.backToResumo}
              />
            </Grid>
            <Grid item xs={2} className={"rodape"} style={{ display: "flex", justifyContent: "center" }}>
              <GenericButton color={"darkBlue"} label={action} subClass={"basic-button"} click={this.salvarRegistros} />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}
