import React, {Component} from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table';
import {Grid} from '@material-ui/core';
import Page from '../../components/page/page';
import API from '../../comm/API';
import SimpleModal from './../../components/modal/modal';
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';
import './evento.scss';

export default class Evento extends Component {

    titles = [
    { id: '0', canOrder: true, label: 'Nome' },
    { id: '1', canOrder: true, label: 'Tipo de Evento' },
    { id: '2', canOrder: true, label: 'Cidade' },
    { id: '3', canOrder: true, label: 'País' },
    { id: '4', canOrder: true, label: 'Data de Início' },
    { id: '5', canOrder: true,  label: 'Ano' },
    { id: '6', canOrder: true,  label: 'Unidade' },
    { id: '7', canOrder: true, label: 'Organizadores' },
    { id: '8', canOrder: false,  label: ''}];

    isUO = false;
    constructor(props) {
        super(props);
        var filterAux = new URLSearchParams();
        filterAux.append('ordenarPor', 'nome');
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            this.isUO = true;
            filterAux.append('unidade.id', this.props.usuarioConectado.colaborador.unidade.id);
        } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR') {
            filterAux.append('unidade.unidadePai.id', this.props.usuarioConectado.unidade.id);
        }
        this.state = {
            eventoList: [], 
            emptyList: 'seeResults',
            lines: [],
            filter : filterAux,
            buscaLivreEvento: '',
            remover: null
        };
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };
    
    changeBuscaLivreEvento = (e) => {
        var filterAux = this.state.filter;        
        if (e.target.value !== undefined && e.target.value !== '') {
            this.setState({buscaLivreEvento: e.target.value});
            filterAux.delete('buscaLivreEvento');
            filterAux.append('buscaLivreEvento', e.target.value)
        } else {
            filterAux.delete('buscaLivreEvento');
        }
        this.setState({filter: filterAux});
    };

    deleteItem = () => {
        this.showLoading();
        let remover = this.state.remover;
        if (remover != null && remover.id != null && this.props.usuarioConectado) {
            API.delete('rest/gestao/Evento/' + remover.id).then(res => {
                this.closeLoading();
                this.setState({ remover: null });
                if (res.data === true) {
                    this.getEventos();
                    this.showToast('Evento excluído com sucesso!', 'success');
                } else {
                    this.showToast('Não foi possível remover o evento. Favor, entre em contato com o suporte.', 'error');
                }
            }).catch(error => {
                this.closeLoading();
                this.setState({ remover: null });
                this.showToast('Ocorreu um erro ao tentar excluir o evento. Favor, entre em contato com o suporte.', 'error');
            });
        } else {
            this.closeLoading();
            this.setState({ remover: null });
            this.showToast('Evento para excluir inválido.', 'error');
        }
    };

    getEventos = () => {
        this.setState({lines: []});
        this.showLoading();

        API.get('rest/gestao/Evento', {
            params: this.state.filter
        }).then(res => {
            this.setState({eventoList: res.data});
            var lines = [];

            for(var i = 0; i < this.state.eventoList.length ; i ++){
                let eventoAux = [];
                let evento = this.state.eventoList[i];
                eventoAux.push(`${evento.nome}`);
                eventoAux.push(`${evento.tipoEvento}`);
                eventoAux.push(`${evento.cidade}`);
                eventoAux.push(`${evento.pais}`);
                eventoAux.push(new Date(evento.dataInicio).toLocaleDateString());
                eventoAux.push(`${evento.ano}`);
                eventoAux.push(`${evento.unidade != null ? evento.unidade.abreviacao : ''}`);
                eventoAux.push(`${evento.isOrganizado === true ? 'SIM' : 'NÃO'}`);
                eventoAux.push( 
                    <div style={{ display: 'flex' }}>
                        <Link to={`/cad/pessoa/evento/${evento.id}`}>
                            <GenericButton color={'transparent'} subClass={'icon-button'} 
                            icon={'pen.svg'}/>
                        </Link>
                        <GenericButton color={'transparent'} subClass={'icon-button'} icon={'trash.svg'} click={(e) => this.setState({ remover: evento })} />
                    </div>);
                lines.push(eventoAux);                  
            }
            this.setState({ lines: lines });
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            console.log(error)
            this.closeLoading();
            this.showToast('Ocorreu um erro ao carregar lista de eventos. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    render() {
        return (
            <Page icon={'doc.svg'} label={'Gerenciar Eventos'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-eventos'}>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={(this.isUO ? 6 : 5)} xl={(this.isUO ? 5 : 4)} className={'grid-left-button'}>
                                {this.isUO ? 
                                    <Link to={'/cad/pessoa/evento/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Novo Evento'} subClass={'basic-button'}/>
                                    </Link> 
                                : null }
                                <FormField type={'text'} label={''} placeholder={'busque por nome, tipo, cidade ou país'}
                                button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={this.getEventos}/>}
                                inputValue={this.state.filter.buscaLivreEvento} 
                                changeValue={this.changeBuscaLivreEvento}
                                pressEnter={this.getEventos} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.lines} titles={this.titles} initialOrder={'0'} />
                        <EmptyList type={this.state.emptyList} seeAll={this.getEventos} />
                    </Grid>
                    {this.state.remover != null ? 
                        <SimpleModal isOpen={true} handleClose={(e) => this.setState({ remover: null })} disableClick={false} type={'warning'} width={'380px'} className={'evento-modal'}>
                            <label>Voce confirma a exclusão do evento </label>
                            <label className={'label-bold'}>ID {this.state.remover.id} - {this.state.remover.nome}?</label>
                            <div className={'div-botoes'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={(e) => this.setState({ remover: null })} />
                                <GenericButton color={'darkBlue'} label={'Excluir'} subClass={'basic-button'} click={this.deleteItem} />
                            </div>
                        </SimpleModal> : null
                    }
                </Grid>
            </Page>
        );
    }
}