import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API , { BASE_URL } from '../../../comm/API';
import GenericButton from '../../../components/genericButton/genericButton';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import GraphCard from '../../../components/graphCard/graphCard';
import GraficoMapaBrasil from './graficoMapaBrasil';
import GraficoMetas from './graficoMetas.js';
import GraficoPorteSetor from './graficoPorteSetor.js';
import GraficoAtendimentosPorEstado from './graficoAtendimentosPorEstado.js';
import GraficoCadastradoConcluido from './graficoCadastradoConcluido';
import GraficoAtendimentoProdutividade from './graficoAtendimentoProdutividade';
import GraphBarHorizontalStatus from '../../../components/graphBar/graphBarHorizontalStatus';
import GraficoCadastradosRecebidos from './graficoCadastradosRecebidos';
import GraficoAtendimentosCadastros from './graficoAtendimentosCadastros';

export default class DashboardBrasilMaisGeral extends Component {

	

	constructor(props) {
		super(props);
		this.state = {
			valoresCards: [],
			valoresGraficoCards: [],
		}
		API.get('rest/sistema/DashboardBrasilMais/cards').then(json => {
			let dados = [];
			if(this.props.perfil === 'DN'){
				
				
				dados.qtdEmpresasCadastradas = {
					L : json.data.qtdEmpresasCadastradas.lean,
					D : json.data.qtdEmpresasCadastradas.digital,
					LD : json.data.qtdEmpresasCadastradas.ambos,
				}
				dados.qtdAtendimentosDr = {
					L : json.data.qtdAtendimentosDr.lean,
					D : json.data.qtdAtendimentosDr.digital,
					LD : json.data.qtdAtendimentosDr.ambos,
				}
				dados.taxaConversao = {
					L : json.data.taxaConversao.lean,
					D : json.data.taxaConversao.digital,
					LD : json.data.taxaConversao.ambos,
				}
				dados.qtdAtdsAnaliseDn = {
					L : json.data.qtdAtdsAnaliseDn.lean,
					D : json.data.qtdAtdsAnaliseDn.digital,
					LD : json.data.qtdAtdsAnaliseDn.ambos,
				}
				dados.qtdDrsPrograma = {
					L : json.data.qtdDrsPrograma.lean,
					D : json.data.qtdDrsPrograma.digital,
					LD : json.data.qtdDrsPrograma.ambos,
				}	
			} else{
				dados = json.data;
			}

			this.setState({ valoresCards: dados })
		}).catch(error => {
			console.log(error);
			this.props.showToast('Erro ao buscar cards do dashboard. Favor, entrar em contato com o suporte.', 'error');
		});

		API.get('rest/sistema/DashboardBrasilMais/cardsHorasReceita').then(json => {
			this.setState({ valoresGraficoCards: json.data })
		}).catch(error => {
			console.log(error);
		});

	}

	downloadRelatorio = () => {
        this.props.showLoading();


        try {
            let path = `rest/gestao/RelatorioTurmaLean/getRelatorioBrasilMais`;
            window.open(BASE_URL + path);
            this.props.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.props.closeLoading();
            this.props.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }

    };

	render() {
		if (this.props.perfil === 'DN')
			return (
				<Grid container spacing={3} className={'dashboard-brasil-mais-geral'}>
					<Grid item container spacing={3} xs={12}>
						<Grid item xs={4}>
							<div style={{ marginTop: "20px",marginBottom: "10px"}}>
								<label>ADESÃO</label>
							</div>
							<div>
								<Grid container
								spacing={4}
								direction="row"
								justifyContent="flex-start"
								alignItems="center">
									<Grid item xs={4} className={'card'}>
										<DashboardCard color={'card-light-gray'} 
										id = {'invertido'}
										text={	<div className={'card-label'}>
													<label>Departamentos</label>
													<label>Regionais (DR)</label>
												</div>}
										value={this.state.valoresCards.qtdDrsPrograma ? this.state.valoresCards.qtdDrsPrograma[this.props.fase].toLocaleString('pt-BR') : 0} />
									</Grid>
									<Grid item xs={8} className={'card'}> 
										<DashboardCard 
										color={'card-light-gray'}
										id = {'invertido'} 
										text={
											<div className={'card-label'}>
												<label>Empresas Cadastradas</label>
												<label>no Programa</label>
											</div>
											}
										tooltipLight={false}
										tooltip={
											<div>
											Atenção! Existe uma diferença entre "Atendimentos” e "Empresas cadastradas": A contabilização de empresas cadastradas calcula quantas empresas passam pelo programa, mas uma mesma empresa cadastrada pode ter mais de um atendimento/status. <br /><br />
											Ex 1: A empresa X participou das duas fases do programa: Mentoria Lean e Mentoria Digital. Esta empresa pode possuir dois atendimentos com status diferentes: finalizado + em execução.<br /> <br />
											Ex 2: A empresa Y iniciou uma trilha, mas acabou desistindo do programa, meses depois retomou a negociação para retomar o programa. Esta empresa possui dois atendimentos com status diferentes: cancelado e em negociação.
											</div>	} 
										value={this.state.valoresCards.qtdEmpresasCadastradas ? this.state.valoresCards.qtdEmpresasCadastradas[this.props.fase].toLocaleString('pt-BR') : 0} />
									</Grid>
								</Grid>
							</div>  
						</Grid>
						<Grid item xs={8}>
							<div style={{ marginTop: "20px",marginBottom: "10px"}}>
								<label>STATUS</label>
							</div>
							<div >
								<Grid container
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								className={'card'}>
									<Grid item xs ={12} className={'card-light-gray'}>
										<GraphBarHorizontalStatus fase={this.props.fase}/>
									</Grid>
								</Grid>
							</div>
						</Grid>
					</Grid>
					<Grid item container spacing={3} xs={12} direction="row">
						<Grid item container 
						direction="column"
						justifyContent="space-between"
						alignItems="center"
						xs={3} style={{height:'100%'}}>
							<Grid item xs={4} className={'card'} style={{maxWidth:"100%",width:"100%", display:'flex'}}>
								<DashboardCard color={'card-dark-blue card-top'} 
								id = {'invertido'}
								text={	<div className={'card-label-light'}>
											<label>Atendimentos Contratados Brasil Mais</label>
										</div>}
								value={this.state.valoresCards.qtdAtendimentosDr ? this.state.valoresCards.qtdAtendimentosDr[this.props.fase].toLocaleString('pt-BR') : 0} />
							</Grid>
							<Grid item xs={4} className={'card'} style={{maxWidth:"100%",width:"100%", display:'flex'}}>
								<DashboardCard color={'card-blue card-mid'} 
								id = {'invertido'}
								text={	<div className={'card-label-light'}>
											<label>Taxa de conversão </label>
											<label>atendimentos "em negociação" para </label>
											<label>"aceitos, em execução e concluídos"</label>
										</div>}
								tooltipLight={true}
								tooltip={<div>
										A taxa de conversão é calculada sobre os atendimentos que mudaram de status nos últimos 30 dias.<br /><br />
										O cálculo é realizado para mensurar a evolução do status “Em negociação” para “Aceito/ Contratado”, “Aceito/ Em execução ou “Concluído”.<br /><br />
										Ex: No 1º dia do mês X, 512 atendimentos possuem o status “Em negociação”. No 1º dia do mês seguinte, desses 512 atendimentos, 42 mudaram de status para “Aceito/Em execução”, “Aceito/Contratado” ou “Concluído”. Neste caso, o cálculo da Taxa de Conversão seria: 42/512 (8,2%).
										</div>} 
								value={this.state.valoresCards.taxaConversao ? this.state.valoresCards.taxaConversao[this.props.fase].toLocaleString('pt-BR') + '%': 0} />
							</Grid>
							<Grid item xs={4} className={'card'} style={{maxWidth:"100%",width:"100%", display:'flex'}}>
								<DashboardCard color={'card-orange card-bot'} style={{alignSelf:"flex-end"}}
								id = {'invertido'}
								text={	<div className={'card-label-light'}>
											<label>Atendimentos para análise DN</label>
										</div>}
								value={this.state.valoresCards.qtdAtdsAnaliseDn ? this.state.valoresCards.qtdAtdsAnaliseDn[this.props.fase].toLocaleString('pt-BR') : 0} />
							</Grid>
						</Grid>
						<Grid item container xs={9} style={{height:'100%'}}>
							<Grid item xs={12}>
								<GraficoAtendimentosCadastros fase={this.props.fase}/>
							</Grid>							
						</Grid>
					</Grid>
					<Grid item container 
					direction="row"
					justifyContent="flex-start"
					alignItems="center"
					spacing={3}
					xs={12} >
						<Grid item xs={8} >
							<GraficoCadastradosRecebidos fase={this.props.fase}/>
						</Grid>
						<Grid item xs={4} style={{height:'100%'}}>
							<GraficoPorteSetor showToast={this.props.showToast} fase={this.props.fase}/>
						</Grid>
					</Grid>					
				</Grid>
			);
		else if (this.props.perfil === 'DR')
			return (
				<Grid container spacing={3} className={'dashboard-brasil-mais-geral'}>
					<Grid item xs={12} md={4} xl={3}>
							<Grid container className={'relatorio-box'}>
								<Grid item className={'relatorio-label'}>
									<label>Baixe aqui o relatório geral do Brasil Mais</label>
									<label>contendo dados atualizados sobre</label>
									<label className={'bold'}>clientes, atendimentos, indicadores</label>
									<label className={'bold'}>e consultores.</label>
								</Grid>
								<Grid item className={'relatorio-title'}>
									<label>Relatório Geral </label>
									<label>BRASIL MAIS</label>
									<GenericButton color={'darkBlue'} label={'Baixar relatório'} subClass={'basic-button'} click={() => { this.downloadRelatorio(); }} />
								</Grid>
							</Grid>							
					</Grid>
					<Grid item xs={12} md={8} xl={9}>
						<GraficoCadastradoConcluido isDR={true} />
					</Grid>
					<Grid item xs={12}>
						<GraficoAtendimentoProdutividade />
					</Grid>
					<Grid item xs={12} className={'dashboard-cards'}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={3}>
								<DashboardCard color={'card-blue'} text={'Atendimentos Brasil Mais'} value={this.state.valoresCards[1] ? this.state.valoresCards[1].toLocaleString('pt-BR') : 0} />
							</Grid>
							<Grid item xs={12} md={3}>
								<DashboardCard color={'card-orange'} text={'Clientes cadastrados na ABDI em espera'} value={this.state.valoresCards[2] ? this.state.valoresCards[2].toLocaleString('pt-BR') : 0} />
							</Grid>
							<Grid item xs={12} md={3}>
								<DashboardCard color={'card-dark-gray'} text={'Número de atendimentos cancelados'} value={this.state.valoresCards[3] ? this.state.valoresCards[3].toLocaleString('pt-BR') : 0} />
							</Grid>
							<Grid item xs={12} md={3}>
								<DashboardCard color={'card-dark-gray'} text={'Número de atendimentos recusados'} value={this.state.valoresCards[4] ? this.state.valoresCards[4].toLocaleString('pt-BR') : 0} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<GraphCard title={'Horas previstas x apropriadas em STI'} subtitle={'(acumulado de atendimentos aceitos e concluídos)'} graficoReceita={false} value1={this.state.valoresGraficoCards[0]} value2={this.state.valoresGraficoCards[1]} />
							</Grid>
							<Grid item xs={12}>
								<GraphCard title={'Receita prevista x realizada em STI'} subtitle={'(acumulado de atendimentos aceitos e concluídos)'} graficoReceita={true} value1={this.state.valoresGraficoCards[2]} value2={this.state.valoresGraficoCards[3]} />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} md={4}>
						<GraficoPorteSetor showToast={this.props.showToast} changeSize={true} />
					</Grid>
					<Grid item xs={12} md={4} className={'dashboard-cards'}>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<DashboardCard color={'card-blue-dr'} text={'Média de aumento da produtividade industrial no DR'} value={this.state.valoresCards[0] ? this.state.valoresCards[0].toLocaleString('pt-BR') + '%' : 0} />
							</Grid>
							<Grid item xs={12}>
								<DashboardCard color={'card-orange-dr'} text={'Número de atendimento com produtividade fora da meta no DR'} value={this.state.valoresCards[5] ? this.state.valoresCards[5].toLocaleString('pt-BR') : 0} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
		else if (this.props.perfil === 'UO')
			return (
				<Grid container spacing={3} className={'dashboard-brasil-mais-geral'}>
					<Grid item xs={12} md={4} xl={3}>
						<GraficoPorteSetor showToast={this.props.showToast} changeSize={true} />
					</Grid>
					<Grid item xs={12} md={8} xl={9}>
						<GraficoCadastradoConcluido />
					</Grid>
					<Grid item xs={12} className={'dashboard-cards'}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={3}>
								<DashboardCard color={'card-blue-dr'} text={'Número de empresas atendidas pela UO'} value={this.state.valoresCards[5] ? this.state.valoresCards[5].toLocaleString('pt-BR') : 0} />
							</Grid>
							<Grid item xs={12} md={3} className={'graph-column-geral-height'}>
								<GraphCard title={'Horas previstas x apropriadas em STI'} subtitle={'(acumulado de atendimentos aceitos e concluídos)'} graficoReceita={false} value1={this.state.valoresGraficoCards[0]} value2={this.state.valoresGraficoCards[1]} />
							</Grid>
							<Grid item xs={12} md={3}>
								<DashboardCard color={'card-blue-dr'} text={'Média de aumento da produtividade industrial'} value={this.state.valoresCards[0] ? this.state.valoresCards[0].toLocaleString('pt-BR') + '%' : 0} />
							</Grid>
							<Grid item xs={12} md={3}>
								<DashboardCard color={'card-orange-dr'} text={'Número de atendimento com produtividade fora da meta'} value={this.state.valoresCards[4] ? this.state.valoresCards[4].toLocaleString('pt-BR') : 0} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			);
	}
}