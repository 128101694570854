import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import GenericButton from './../../components/genericButton/genericButton';
import DownloadFiles from '../../comm/DownloadFiles';
import API from '../../comm/API';
import InputSelect from '../../components/inputselect/inputselect';
import { Grid, Switch, AppBar, Tabs, Tab, FormControl, RadioGroup, 
    FormControlLabel, Radio, Chip, Icon} from "@material-ui/core"

export default class AtividadeProcessoProdutivoDigital extends Component {


    metodoContagemProducaoOptions = [
        { id: 1, label: "Sim", value: "SIM" },
        { id: 2, label: "Sim, manual", value: "SIM, MANUAL" },
        { id: 3, label: "Sim, automatizado" , value:  "SIM, AUTOMATIZADO" },
        { id: 4, label: 'Sim, parcialmente manual', value: "SIM, PARCIALMENTE MANUAL"},
        { id: 5, label: "Não", value: "NAO"}
    ];

    faturamentoOptions = [     
        { id: 1, label: "Menor ou igual à R$ 360 mil", value: "MENOR OU IGUAL A R$ 360 MIL" },
        { id: 2, label: "Entre R$ 360 mil e R$ 4,8 milhões", value: "ENTRE R$ 360 MIL E R$ 4,8 MILHOES" },
        { id: 3, label: "Entre R$ 4,8 milhões e R$ 300 milhões" , value:  "ENTRE R$ 4,8 MILHOES E R$ 300 MILHOES" },
        { id: 4, label: 'Maior que R$ 300 milhões', value: "MAIOR QUE R$ 300 MILHOES"},
    ]
    
    constructor(props) {
        super(props);
        let disabled = this.props.disabled;
        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let processoProdutivoDigital  = props.atividade;
        processoProdutivoDigital.kickoff = [];
        if (processoProdutivoDigital.anexoProcessoProdutivoList != null) {
            processoProdutivoDigital.anexoProcessoProdutivoList.forEach(anexo => {
                if (anexo.isDocumento) {
                    processoProdutivoDigital.kickoff.push(anexo);
                    let index = processoProdutivoDigital.anexoProcessoProdutivoList.indexOf(anexo);
                    processoProdutivoDigital.anexoProcessoProdutivoList.splice(index,1);
                }
            });
        }
        this.state = {
            processoProdutivoDigital :props.atividade,
            errors: {
                coletaIndicadoresText: '',
            }
        };
    };


    changeInput = (e) => {
        let processoProdutivoDigital = this.state.processoProdutivoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
                processoProdutivoDigital[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
                processoProdutivoDigital[e.target.name] = e.target.value;
            }
            this.setState({ processoProdutivoDigital: processoProdutivoDigital, errors: errors });
        }
    };

    uploadKickOff = (e) => {
        this.props.showLoading();
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file && !(file.name.toLowerCase().includes('jpg') || file.name.toLowerCase().includes('jpeg') || 
            file.name.toLowerCase().includes('png') || file.name.toLowerCase().includes('pdf'))) {
            this.props.closeLoading();
            this.props.showToast('Tipo de arquivo não permitido. Anexar apenas PDF, JPG, JPEG ou PNG.', 'error');
            return;
        }
        if (file.size > 10000000) {
            this.props.closeLoading();
            this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }  
        let processoProdutivoDigital = this.state.processoProdutivoDigital;      
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            const anexo = {
                descricao: 'Ata de kickoff',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                isDocumento: true,
            };
            processoProdutivoDigital.kickoff.push(anexo);
            this.setState({ processoProdutivoDigital: processoProdutivoDigital });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        })
        
       
    };

    removerKickOff = (element) => {
        let processoProdutivoDigital = this.state.processoProdutivoDigital;         
        let index = processoProdutivoDigital.kickoff.findIndex(prop => {
            return (element.id && element.id === prop.id) || element.nomeArquivo === prop.nomeArquivo;
        });
        if (index !== -1) {
            processoProdutivoDigital.kickoff.splice(index, 1);
        }
        this.setState({ processoProdutivoDigital: processoProdutivoDigital });
    };


    changeInputBool = (e) => {
        let processoProdutivoDigital = this.state.processoProdutivoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            
            if (e.target.name) {
                processoProdutivoDigital[e.target.name] = this.str2bool(e.target.value);
            }
            this.setState({ processoProdutivoDigital: processoProdutivoDigital, errors: errors });
        }
    };

    changeMetodo = (e) => {
        let processoProdutivoDigital = this.state.processoProdutivoDigital;
        processoProdutivoDigital.metodoContagemProducao = this.metodoContagemProducaoOptions[e.id - 1].value;
        this.setState({ processoProdutivoDigital: this.state.processoProdutivoDigital });
    };

    changeFaturamento = (e) => {
        let processoProdutivoDigital = this.state.processoProdutivoDigital;
        processoProdutivoDigital.faturamento = this.faturamentoOptions[e.id - 1].value;
        this.setState({ processoProdutivoDigital: this.state.processoProdutivoDigital });
    };


    str2bool = (value) => {
        if (value && typeof value === "string") {
             if (value.toLowerCase() === "true") return true;
             if (value.toLowerCase() === "false") return false;
        }
        return value;
     }

    renderRadioButton = (property, type, label) => {
        if(type == "D/C"  || type == "M/A"){
            let val1, val2, op1, op2;
            
            if(type == "D/C"){
                val1 = "DISCRETA";
                val2 = "CONTINUA";
                op1 = "Discreta";
                op2 = "Contínua";
            }
            
            else{
                val1 = "MANUAL";
                val2 = "AUTOMATIZADA";
                op1 = "Manual";
                op2 = "Automatizada";
            }
            return (
                <Grid item xs={12} md={4} xl={4}>
                    <Grid container style={{ marginBottom: '1%' }} direction={'column'}>
                        <Grid item> <label style={{maxWidth:'80%' , display:'flex', marginBottom: -10}}> {label} </label> </Grid>
                        <Grid item className={'radioDisabled'}>
                            <FormControl component="fieldset">
                                <RadioGroup disabled={this.props.disabled} aria-label="position" name={property} value={this.state.processoProdutivoDigital[property]} 
                                onChange={this.changeInput} row>
                                    <FormControlLabel
                                        value={val1}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" /> }
                                        label={op1}
                                        labelPlacement="end"
                                        />
                                    <FormControlLabel
                                        value={val2}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" />}
                                        label={op2}
                                        labelPlacement="end"
                                        />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        else if(type == "S/N"){
            let val1 = 'true';
            let val2 = 'false';
            let op1 = "Sim";
            let op2 = "Não";
            return (
                <Grid item xs={12} md={4} xl={4}>
                    <Grid container style={{ marginBottom: '1%' }} direction={'column'}>
                        <Grid item> <label style={{maxWidth:'80%' , display:'flex', marginBottom: -10}}> {label} </label> </Grid>
                        <Grid item className={'radioDisabled'}>
                            <FormControl component="fieldset">
                                <RadioGroup disabled={this.props.disabled} aria-label="position" name={property} value={this.state.processoProdutivoDigital[property] != null ? this.state.processoProdutivoDigital[property].toString() :null} 
                                onChange={this.changeInputBool} row>
                                    <FormControlLabel
                                        value={val1}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" /> }
                                        label={op1}
                                        labelPlacement="end"
                                        />
                                    <FormControlLabel
                                        value={val2}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" />}
                                        label={op2}
                                        labelPlacement="end"
                                        />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            )
            }
        else{
        let val1, val2, val3, op1, op2, op3;
        op1 = 'Diário';
        op2 = 'Semanal';
        op3 = 'Não';
        val1 = 'DIARIO';
        val2 = 'SEMANAL';
        val3 = 'NAO';
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Grid container style={{ marginBottom: '3%' }} direction={'column'}>
                    <Grid item> <label style={{maxWidth:'80%' , display:'flex'}}> {label} </label> </Grid>
                    <Grid item className={'radioDisabled'}>
                        <FormControl component="fieldset">
                            <RadioGroup disabled={this.props.disabled} aria-label="position" name={property} value={this.state.processoProdutivoDigital[property]} 
                            onChange={this.changeInput} row>
                                <FormControlLabel
                                    value={val1}
                                    control={<Radio color="primary" /> }
                                    label={op1}
                                    disabled={this.props.disabled}
                                    labelPlacement="end"
                                    />
                                <FormControlLabel
                                    value={val2}
                                    control={<Radio color="primary" />}
                                    label={op2}
                                    disabled={this.props.disabled}
                                    labelPlacement="end"
                                    />
                                <FormControlLabel
                                    value={val3}
                                    control={<Radio color="primary" />}
                                    label={op3}
                                    disabled={this.props.disabled}
                                    labelPlacement="end"
                                    />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )

        }
        
    }

    renderBody = () => {
        let atividade = this.state.processoProdutivoDigital;
        return (
            <Grid container justify={'center'} style={{ marginTop: '3%' }}>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ paddingLeft: '3%' }}>
                    <Grid item xs={12} md={4} xl={3} className={'title-label'} >
                    <div>
                    <label> Sobre a empresa </label>
                    </div>
                    </Grid>
                                        
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginBottom: 32 }} >
                    <Grid item xs={12} md={4} xl={3} >
                        <div className={'div-field-btn field-label'}>
                            <FormField
                            type={'number'} 
                            id={'quantidadeFuncionarios'} 
                            key={'quantidadeFuncionarios'} 
                            label={'Quantidade de funcionários'} 
                            changeValue={this.changeInput} 
                            disabled={this.props.disabled}
                            inputValue={atividade.quantidadeFuncionarios}/>                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <InputSelect
                                suggestions={this.faturamentoOptions}
                                label={'Faturamento'}
                                itemKey={'id'}
                                itemLabel={'label'}
                                id={'tipo'}
                                disabled={this.props.disabled}
                                getInput={this.changeFaturamento}
                                initialSelectedItem={{ id: this.state.processoProdutivoDigital.faturamento ? this.faturamentoOptions.find(o => o.value === this.state.processoProdutivoDigital.faturamento) ? this.faturamentoOptions.find(o => o.value === this.state.processoProdutivoDigital.faturamento).id : 1 : 1 }} />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <FormField
                            type={'number'}
                            id={'pontuacaoMaturidade'} 
                            key={'pontuacaoMaturidade'}
                            min='1' 
                            maxLength='15'
                            subtype={'duascasas'}
                            placeholder={'Caso a empresa não conclui Mentoria Lean'} 
                            label={'Pontuação da Avaliação de Maturidade'}
                            info={'* Opcional'}
                            changeValue={this.changeInput}
                            disabled={this.props.disabled}
                            inputValue={atividade.pontuacaoMaturidade}/>                            
                        </div>
                    </Grid>                   
                   
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%' }} >
                    <Grid item xs={12} md={4} xl={3} style={{ paddingTop: 18 }}>
                        <Grid container justify={'flex-start'} direction={'row'} className={'div-field-btn field-label'}>
                            <Grid item xs={12}>
                                <Grid container style={{ marginBottom: '1%' }} direction={'column'}>
                                    <Grid item> <label style={{maxWidth:'80%' , display:'flex', marginBottom: -10}}> A empresa possui algum ERP? </label> </Grid>
                                    <Grid item className={'radioDisabled'}>
                                        <FormControl component="fieldset">
                                            <RadioGroup disabled={this.props.disabled} aria-label="position" name={'possuiERP'} value={this.state.processoProdutivoDigital.possuiERP != null ? this.state.processoProdutivoDigital.possuiERP.toString() :null} 
                                            onChange={this.changeInputBool} row>
                                                <FormControlLabel
                                                    value={'true'}
                                                    control={<Radio color="primary" /> }
                                                    label={'Sim'}
                                                    disabled={this.props.disabled}
                                                    labelPlacement="end"
                                                    />
                                                <FormControlLabel
                                                    value={"false"}
                                                    control={<Radio color="primary" />}
                                                    label={"Não"}
                                                    disabled={this.props.disabled}
                                                    labelPlacement="end"
                                                    />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>                        
                    </Grid>                    
                    <Grid item xs={12} md={4} xl={3} style={{ paddingTop: 18 }}>
                        <div className={'div-field-btn field-label'}>
                            <FormField
                            id={'qualERP'} 
                            key={'qualERP'}
                            label={'Qual ERP a empresa possui?'} 
                            changeValue={this.changeInput}
                            disabled={(this.state.processoProdutivoDigital.possuiERP != null  ? !this.state.processoProdutivoDigital.possuiERP : true ) || this.props.disabled}
                            placeholder={'Se não possuir ERP, não responder'} 
                            inputValue={atividade.qualERP}/>                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3} style={{ paddingTop: 18 }}>
                        <div className={'div-field-btn field-label'}>
                            <InputSelect
                                suggestions={this.metodoContagemProducaoOptions}
                                label={'A empresa possui algum método de contagem de sua produção?'}
                                itemKey={'id'}
                                itemLabel={'label'}
                                id={'tipo'}
                                disabled={this.props.disabled}
                                getInput={this.changeMetodo}
                                initialSelectedItem={{ id: this.state.processoProdutivoDigital.metodoContagemProducao ? this.metodoContagemProducaoOptions.find(o => o.value === this.state.processoProdutivoDigital.metodoContagemProducao) ? this.metodoContagemProducaoOptions.find(o => o.value === this.state.processoProdutivoDigital.metodoContagemProducao).id : 1 : 1 }} />
                        </div>
                    </Grid> 
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ marginTop: '2%', borderTop: '1px solid #E1E3E6', paddingLeft: '3%' }}>
                    <Grid item xs={12} className={'title-label'} >
                    <div>
                    <label> Sobre a gestão de produtividade os processos produtivos </label>
                    </div>
                    </Grid>            
                </Grid>
                <Grid container justify={'flex-start'} style={{ marginTop: '2%'}} className={'linha-producao-box'}>
                    <Grid item xs = {12}>
                            <Grid container justify={'space-evenly'} direction={'row'} style={{ marginTop: '1%', marginBottom: '1%'}}>
                                {this.renderRadioButton("acompanhamentoProdutividade", "D/S", "A empresa faz acompanhamento da produtividade?")}
                                {this.renderRadioButton("acompanhamentoProducao", "D/S", "A empresa faz acompanhamento da produção?")}
                                {this.renderRadioButton("metodoMonitoramento", "S/N", "A empresa possui algum método de monitoramento de paradas?")}
                            </Grid>
                            <Grid container justify={'flex-start'} direction={'row'} style={{ marginTop: '1%', marginBottom: '1%'}}>
                                {this.renderRadioButton("sabeMotivosParadaDeLinha", "S/N", "A empresa sabe os motivos de parada da linha?")}
                                {this.renderRadioButton("trataMotivosParadaDeLinha", "S/N", "A empresa trata os motivos de parada da linha?")}
                            </Grid>                        
                    </Grid>                  
                </Grid>
                <Grid container justify={'flex-start'} style={{ marginTop: '2%', borderTop: '1px solid #E1E3E6'}} >
                    <Grid item xs={4} xl={3} className={'anexos-visitas-box-top'} style={{opacity: this.props.alinhamento ? 0.5 : 1}}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', paddingTop: '10%' }}>
                            <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/relatorio-brasilmais.svg'} width={30} height={30} />
                            <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>Ata de kickoff</span>
                        </Grid>
                        <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                            <label
                                className={'lightBlue basic-button control-label'}
                                id="upload-atividade"
                                htmlFor="uploadfile-listapresenca">Anexar
                            </label>
                            <input
                                className={'file-input listaPresenca'}
                                type="file"
                                id="uploadfile-listapresenca"
                                name="file"
                                disabled={this.props.alinhamento || this.props.disabled}
                                value={undefined}
                                onChange={(element) => { this.uploadKickOff(element) }}
                                 />
                        </Grid>
                        <Grid>
                            {atividade.kickoff.length > 0 ?
                                atividade.kickoff.map(
                                    (anexo, index) => (
                                        <Chip key={index}
                                            label={<label style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                                                <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                <p>{anexo.nomeArquivo}</p></label>}
                                            icon={<Icon onClick={() => this.removerKickOff(anexo)} className={'close'}>clear</Icon>}
                                        />
                                    )) : <></>}
                            
                        </Grid>
                    </Grid>
                    <Grid item xs = {4}>
                        <div className={'div-field-btn field-label'}>
                            <FormField
                            type={'textarea'}
                            placeholder={'Descreva brevemente o processo produtivo da indústria analisada, insira o tipo de processo produtivo, explique os processos sucintamente, quantos operadores trabalham, quantos turnos e os principais problemas (excesso de demanda, excesso de paradas, desperdícios principais, etc).'}
                            id={'descricao'} 
                            key={'descricao'}
                            disabled={this.props.alinhamento || this.props.disabled}
                            label={'Descrição do processo produtivo'} 
                            changeValue={this.changeInput}
                            subClass={'double-height'} 
                            inputValue={atividade.descricao}/>                            
                        </div>                        
                    </Grid>                  
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ marginTop: '2%', borderBottom: '1px solid #E1E3E6', paddingLeft: '3%' }}>
                    {this.props.alinhamento &&
                    <Grid item xs={12} className={'warning-label'} >
                    <div>
                    <label> * Os campos bloqueados serão liberados para preenchimento na Mentoria Prática da Etapa B. </label>
                    </div>
                    </Grid>}        
                </Grid>
                
                
            </Grid>
        )
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao-digital'} style={{ marginTop: '30px' }}>
                { this.renderBody() }
            </Grid>
        )
    }

}