import React, { useEffect, useState, useCallback } from 'react';
import { Grid, Tooltip } from "@material-ui/core";
import { withStyles} from "@material-ui/core/styles";
import './results.scss';

export default function Results({
  title,
  initial,
  final,
  subItems,
  type
}) {

  const [ suffix, setSuffix ] = useState('');
  const [ ModifiedTooltip, setModifiedTooltip ] = useState(Tooltip);
  const [ finalPercentage, setFinalPercentage ] = useState(0);

  useEffect(() => {
    setFinalPercentage(calculateFinalPercentage(initial, final));
    defineSuffix();
    modifyTooltipStyle();
  }, [initial, final]);

  const calculateFinalPercentage  = (initial, final) => {
    const variation = final - initial;

    const result = (variation / initial) * 100;

    return result;
  }

  const defineSuffix = useCallback(() => {
    switch(type) {
      case 'hour':
        setSuffix('/hh')
        break;
      case 'percent':
        setSuffix('%')
        break;
      default:
        setSuffix('')
        break;
    }
  }, [suffix, type]);

  const modifyTooltipStyle = useCallback(() => {
    const modifiedTooltip = withStyles({
      tooltip: {
          fontSize: '15px',
          fontFamily:'Raleway'
      }
    })(Tooltip);

    setModifiedTooltip(modifiedTooltip);
  }, [ModifiedTooltip]);

  const hasIncrease = (value) => {
    return value > 0;
  };

  return (
    <Grid container className='results-container'>

      <Grid container className='results-header-container'>

        <Grid item xs={2} className='results-header-title'>
          {title}
        </Grid>

        <Grid item xs={3} className='results-header-indicator'>
          <span>inicial</span>
          {`${initial.toFixed(6)}${suffix}`}
        </Grid>


        <Grid item xs={3} className='results-header-indicator'>
          <span>final</span>
          {`${final.toFixed(6)}${suffix}`}
        </Grid>

        <Grid 
          item 
          xs={3} 
          className='results-header-result' 
          style={finalPercentage > 20 && title === 'Produtividade' ? { backgroundColor: '#7DAE59'} : {}}
        >
          <div>
            <span>{hasIncrease(finalPercentage) ? 'aumento' : 'redução'}</span>        
            {`${finalPercentage.toFixed(6)}%`}
          </div>

          <img 
            style={{height: 32, width: 32}} 
            src={process.env.PUBLIC_URL + `/assets/icons/${hasIncrease(finalPercentage) ? 'arrow-up-white-circle': 'arrow-down-white-circle'}.svg`} 
            alt={'icon'} 
          />
          
        </Grid>
        <Grid item xs={1} style={{ display: 'flex', alignItems: 'center', paddingLeft: '8px' }}>
          <ModifiedTooltip 
            title={ 
              <div>
                {title} final<br />
                <span style={{color:'#E9B03D'}}>(-) subtração </span><br/>
                {title} inicial<br/>
                <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                {title} inicial
              </div>
            }
            placement="right" 
            arrow
          >
            <img 
              style={{height: 20, width: 20}} 
              src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} 
              alt={'icon'} 
            />
          </ModifiedTooltip>
        </Grid>

      </Grid>

      {subItems && Object.entries(subItems).map(subItem => {
        const [keyName, subItemObj] = subItem;

        const percentage = calculateFinalPercentage(subItemObj.initial, subItemObj.final);

        return (
          <Grid container className='results-subitems-container'>
            <Grid item xs={2} className='results-subitems-title'>
              {keyName}
            </Grid>

            <Grid item xs={3} className='results-subitems-indicator results-subitems-indicator-first'>
              <span>inicial</span>
              {`${subItemObj.initial.toFixed(6)}${suffix}`}
            </Grid>


            <Grid item xs={3} className='results-subitems-indicator'>
              <span>final</span>
              {`${subItemObj.final.toFixed(6)}${suffix}`}
            </Grid>


            <Grid item xs={3} className='results-subitems-result'>
              <div>
                <span>{hasIncrease(percentage) ? 'aumento' : 'redução'}</span>        
                {`${percentage.toFixed(6)}%`}
              </div>

              <img 
                style={{height: 32, width: 32}} 
                src={process.env.PUBLIC_URL + `/assets/icons/${hasIncrease(percentage) ? 'arrow-up-white-circle': 'arrow-down-white-circle'}.svg`} 
                alt={'icon'} 
              />
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  );
};