import React, { Component } from 'react';
import API, { newApi, newBackend } from "./../../comm/API";
import Page from './../../components/page/page';
import FormField from './../../components/formField/formField';
import Table from './../../components/table/table';
import { Grid } from "@material-ui/core";
import GenericButton from './../../components/genericButton/genericButton';
import EmptyList from './../../components/emptyList/emptyList';

import { Redirect } from 'react-router-dom';
import './../usuario/usuario.scss';


export default class LoginSuporte extends Component {

    titles = [
        { id: '0', canOrder: true, label: 'ID' },
        { id: '1', canOrder: true, label: 'NOME' },
        { id: '2', canOrder: true, label: 'LOGIN' },
        { id: '3', canOrder: true, label: 'UNIDADE' },
        { id: '4', canOrder: true, label: 'PERFIL' },
        { id: '5', canOrder: true, label: 'ATIVO' },
        { id: '6', canOrder: false, label: '' }]
    usuarios = [];
    busca = '';

    constructor(props) {
        super(props);
        this.state = { usuarios: [], emptyList: 'seeResults', redirect: false };
    };

    loginSuporte(usuario) {
        API.post('rest/security/loginSuporte', usuario).then(async res => {
            if (res.data.login === "true") {
                try {
                    const token = localStorage.getItem("sgt:tokenJWT");
        
                    const config = {
                        headers: { Authorization: `Bearer ${token}` }
                    };

                    try {
                        await newBackend.post(`/auth/impersonate/${usuario.id}`, {}, { withCredentials: true });
                    } catch (error) {
                        console.log(error);
                    }
        
                    newApi.post(`/rest/accounts/supportLogin/${usuario.id}`, {}, config).then(({data}) => {
                        localStorage.setItem('sgt:tokenJWT', data.token);
                    }).catch(() => {
                        console.log('Erro no login suporte da nova api')
                    });
                } catch (error) {
                    console.log('Erro no login suporte da nova api');
                }
                this.setState({ redirect: true });
            } else {
                this.showToast("Falha ao tentar executar login", 'error');
            }
        }).catch(function (res) {
            this.showToast("Falha ao tentar executar login", 'error');
        });
    };

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    getUsuarios = async (url) => {
        await API.get(url).then(res => {
            this.usuarios = res.data;
            for (var i = 0; i < this.usuarios.length; i++) {
                let usu = { id: this.usuarios[i][0] };
                this.usuarios[i].push(
                    <div style={{ display: 'flex' }}>
                        <GenericButton click={() => this.loginSuporte(usu)} color={'darkBlue'} label={'Login'} subClass={'basic-button'} />
                    </div>
                );
            }
            this.closeLoading();
            this.setState({ usuarios: this.usuarios, emptyList: (res.data != null && res.data.length === 0) ? 'noResults' : ''});
        }).catch(error => {
            this.closeLoading();
            console.log(error);
        });
    };

    aplicarFiltro = async () => {
        this.showLoading();
        if (this.busca != null && this.busca !== '') {
            this.getUsuarios('/rest/sistema/Usuario/find?buscaLivreUsuario=' + this.busca.toLowerCase());
        } else {
            this.getUsuarios('/rest/sistema/Usuario/find');
        }
    };

    changeBusca = (e) => {
        this.busca = e.target.value;
    }

    
    render() {
        if (this.state.redirect) {
            return (<Redirect to='/dashboard' />);
        } else {
            return (
                <Page icon={'profile.svg'} label={'Gerenciamento de Usuários'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                    <Grid container className={'gerenciar-usuarios'}>
                        <Grid item xs={12} className={'pesquisa'}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={5} xl={4} className={'grid-left-button'}>
                                    <FormField type={'text'} label={''} placeholder={'busque por nome, login, perfil ou unidade'} id={'busca'} pressEnter={this.aplicarFiltro}
                                        button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'}
                                            click={this.aplicarFiltro} />} changeValue={this.changeBusca} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Table lines={this.state.usuarios} titles={this.titles} initialOrder={'1'} />
                            <EmptyList type={this.state.emptyList} seeAll={this.aplicarFiltro} />
                        </Grid>
                    </Grid>
                </Page>
            );
        }
    }

}
