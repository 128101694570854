import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import { Grid } from "@material-ui/core";
import API from '../../comm/API'
import SimpleModal from './../../components/modal/modal'
import InputSelect from '../../components/inputselect/inputselect';
import Masks from '../../comm/masks';

export default class Recurso extends Component {

    atendimento = this.props.atendimento;
    totalReceitaApropriada = 0;
    totalReceitaCompetencia = 0;
    historicoReceitaApropriada = [];

    firstMonth = '';
    firstYear = '';
    lastMonth = '';
    lastYear = '';
    anoList = [];

    mesList = [
        { value: 0, label: "Jan" },
        { value: 1, label: "Fev" },
        { value: 2, label: "Mar" },
        { value: 3, label: "Abr" },
        { value: 4, label: "Mai" },
        { value: 5, label: "Jun" },
        { value: 6, label: "Jul" },
        { value: 7, label: "Ago" },
        { value: 8, label: "Set" },
        { value: 9, label: "Out" },
        { value: 10, label: "Nov" },
        { value: 11, label: "Dez" }
    ]

    constructor(props) {
        super(props);
        this.state = {}
        this.getAnoList();
    }

    componentDidMount() {
        this.getTotalReceitaApropriada();
    };

    getAnoList = () => {
        for (var i = new Date().getFullYear(); i >= 2010; i--) {
            this.anoList.push({ value: i });
        }
    }
    
    getTotalReceitaCompetencia = () => {
        const rtno = this.props.atendimento.previsaoReceitaList.reduce((acc, current) => {
            return acc + current.valor + current.economico;
        }, 0);
        return rtno;
    }

    getTotalReceitaApropriada = () => {
        var filter = {};
        filter["atendimento.id"] = this.props.atendimento.id;

        API.get('/rest/gestao/ReceitaFaturamento/sum/valor', {
            params: filter
        }).then(res => {
            this.totalReceitaApropriada = res.data.sum;
            this.setState(this.state);
        })
    }
    getHistoricoRecurso = () => {
        if (!this.props.modalRecurso) {
            this.props.changeModal('modalRecurso', true);
        }
        if (this.props.hasValue(this.firstMonth) && this.props.hasValue(this.firstYear) && this.props.hasValue(this.lastMonth) && this.props.hasValue(this.lastYear)) {
            this.props.showLoading();
            let firstDay = new Date();
            let lastDay = new Date();
            firstDay.setDate(1);
            firstDay.setMonth(this.firstMonth);
            firstDay.setFullYear(this.firstYear);
            lastDay.setDate(1);
            lastDay.setMonth(this.lastMonth === 11 ? 0 : this.lastMonth + 1);
            lastDay.setFullYear(this.lastMonth === 11 ? this.lastYear + 1 : this.lastYear);
            lastDay.setDate(lastDay.getDate() - 1);

            var filter = {};
            filter["atendimento.id"] = this.props.atendimento.id;
            filter['data'] = "between '" + this.props.formatData(firstDay) + " 00:00:00' and '" + this.props.formatData(lastDay) + " 00:00:00' ";
            API.get('/rest/gestao/ReceitaFaturamento/historicoReceita', {
                params: filter
            }).then(res => {
                this.historicoReceitaApropriada = res.data;
                this.setState(this.state);
                this.props.closeLoading();
            }).catch(error => {
                this.props.closeLoading();
            });
     
        }
    }
    changeFirstYear = (e) => {
        this.firstYear = e.value;
        this.setState(this.state);
        this.getHistoricoRecurso();
    }
    changeFirstMonth = (e) => {
        this.firstMonth = e.value;
        this.setState(this.state);
        this.getHistoricoRecurso();
    }
    changeLastYear = (e) => {
        this.lastYear = e.value;
        this.setState(this.state);
        this.getHistoricoRecurso();
    }
    changeLastMonth = (e) => {
        this.lastMonth = e.value;
        this.setState(this.state);
        this.getHistoricoRecurso();
    }
    formatDataMonth = (date) => {
        if (date != null) {
            var dataListAux = date.split('T');
            var dataList = dataListAux[0].split('-');
            var year = dataList[0];
            var month = parseInt(dataList[1]);
            var data = this.mesList[month - 1].label + '/' + year.slice(2, 4);
            return data;
        }
    }
    render() {
        return (
            <Grid item xs={12} md={6}>
                <div className={'greyDiv'}>
                    <GenericButton color={'darkBlue'} subClass={'basic-button'}
                        click={() => this.props.changeModal('modalRecurso', true)} label={'Ver detalhes'} />
                    <h2>Recursos</h2>
                    <Grid container>
                        <Grid item xs={8}><b>Total receita apropriada</b></Grid>
                        <Grid item xs={4}>{Masks.maskCurrency(this.totalReceitaApropriada)}</Grid>
                        <Grid item xs={8}><b>Total receita competência</b></Grid>
                        <Grid item xs={4}>{Masks.maskCurrency(this.getTotalReceitaCompetencia())}</Grid>
                    </Grid>
                </div>
                {this.props.modalRecurso ?
                    <SimpleModal
                        isOpen={this.props.modalRecurso}
                        handleClose={() => this.props.changeModal('modalRecurso', false)}
                        type={'info'}
                        width={'600px'}>
                        <div className={'modal modal-historico-producao'}>
                            <h2>Recursos</h2>

                            <Grid container>
                                <Grid item xs={12}>
                                    <label className={'font-blue'}>Período</label>
                                </Grid>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <InputSelect
                                        suggestions={this.mesList}
                                        itemKey={'value'}
                                        itemLabel={'label'}
                                        id={'firstMonth'}
                                        getInput={this.changeFirstMonth}
                                        initialSelectedItem={{ value: this.firstMonth }}
                                    />
                                    <InputSelect
                                        suggestions={this.anoList}
                                        itemKey={'value'}
                                        itemLabel={'value'}
                                        id={'firstYear'}
                                        getInput={this.changeFirstYear}
                                        initialSelectedItem={{ value: this.firstYear }}
                                    />
                                    <img src={process.env.PUBLIC_URL + '/assets/icons/arrowRight.svg'} alt='arrowIcon' style={{ padding: '0 8px' }} />
                                    <InputSelect
                                        suggestions={this.mesList}
                                        itemKey={'value'}
                                        itemLabel={'label'}
                                        id={'lastMonth'}
                                        getInput={this.changeLastMonth}
                                        initialSelectedItem={{ value: this.lastMonth }}
                                    />
                                    <InputSelect
                                        suggestions={this.anoList}
                                        itemKey={'value'}
                                        itemLabel={'value'}
                                        id={'lastYear'}
                                        getInput={this.changeLastYear}
                                        initialSelectedItem={{ value: this.lastYear }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <p className={'font-blue'}>Histórico de recursos</p>
                                </Grid>
                                {this.historicoReceitaApropriada.length === 0 ?
                                    <p>
                                        {
                                            (this.props.hasValue(this.firstMonth) && this.props.hasValue(this.firstYear) && this.props.hasValue(this.lastMonth) && this.props.hasValue(this.lastYear)) ?
                                                'Não há registros nesse período de tempo.' : 'Selecione um intervalo de tempo.'
                                        }
                                    </p>
                                    :
                                    <Grid item xs={12}>
                                        {this.historicoReceitaApropriada.map((receita, index) => (
                                            <Grid container key={index} className={'colaborador'}>
                                                <Grid item xs={6}>{receita.usuario.nome}</Grid>
                                                <Grid item xs={3}>{this.formatDataMonth(receita.data)}</Grid>
                                                <Grid item xs={3} className={'horas'}>{Masks.maskCurrency(receita.valor)}</Grid>
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </SimpleModal> : null}
            </Grid>
        )
    }
}