import React, { Component } from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import CompositeButton from '../../components/compositeButton/compositeButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table';
import { Grid } from '@material-ui/core';
import Page from '../../components/page/page';
import API, { BASE_URL } from '../../comm/API';
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';
import SimpleModal from './../../components/modal/modal';
import AutoComplete from '../../components/autoComplete/autoComplete';
import InputSelect from '../../components/inputselect/inputselect';
import Masks from '../../comm/masks';
import { FormControl, FormControlLabel, Tooltip } from '@material-ui/core';
import { withStyles} from "@material-ui/core/styles";
import Checkbox from '@material-ui/core/Checkbox';
import TrilhaMentoriaDigital from './trilhaMentoriaDigital';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './turmaMentoriaDigital.scss';
import Trilha from './trilha';

export default class TurmaMentoriaDigital extends Component {

    turmaList = [];
    filteredAtendimentos = [];

    modalBusca = false;

    filter = {};
    hasFilter = false;

    dateOptions = [
        { id: 0, label: 'Últimos 15 dias' },
        { id: 1, label: 'Todos' },
    ];

    dateOptionCp = 1;
    dateEscolhido = '';

    buscaLivre = '';

    buscaAvancadaTurma = '';
    buscaAvancadaTurmaCp = this.buscaAvancadaTurma;
    buscaAvancadaAtendimento = '';
    buscaAvancadaAtendimentoCp = this.buscaAvancadaAtendimento;


    titlesTab = this.setDefaultValuesTab();

    linesTab = null;

    titles = [
        { id: '1', canOrder: true, label: 'Nome da empresa' },
        { id: '2', canOrder: true, label: 'Unidade Operacional' },
        { id: '3', canOrder: false, label: '' },
        
    ];
    titlesTrilha = [
        { id: '1', canOrder: false, label: '' },
        { id: '2', canOrder: false, label: 'Etapa' },
        { id: '3', canOrder: false, label: 'Data de início' },
        { id: '4', canOrder: false, label: 'Data de término' },
        { id: '5', canOrder: false, label: 'Mentor/Consultor' },
    ];
    titleAluno = [
        { id: '1', canOrder: false, label: 'Nome' },
        { id: '2', canOrder: false, label: 'CPF' },
        { id: '3', canOrder: false, label: '' }
    ];
    isUO = false;
    isDR = false;
    isDN = false;
    isHomologado = false;
    turmaValidada = false;
    showMenu = false;
    etapaList = [
        { id: 1, etapa: 'A - Lean Game Digital', type: 'Etapa' },
        { id: 2, etapa: 'B - Teórica', type: 'Etapa' },
        { id: 3, etapa: 'B - Prática', type: 'Etapa' },
        { id: 4, etapa: 'C - Teórica', type: 'Etapa' },
        { id: 5, etapa: 'C - Prática', type: 'Etapa' },
        { id: 6, etapa: 'C - Consultoria', type: 'Etapa' },
        { id: 7, etapa: 'D - Teórica', type: 'Etapa' },
        { id: 8, etapa: 'D - Prática', type: 'Etapa' },
        { id: 9, etapa: 'D - Consultoria', type: 'Etapa' },
    ];

    departamentosList = [];
    statusList = [];
    unidade = '';

    constructor(props) {
        super(props);
        const usuario = this.props.usuarioConectado;
        if (usuario.colaborador != null && usuario.colaborador.isAtivo) this.isUO = true;
        else if ((usuario.colaborador == null || !usuario.colaborador.isAtivo) && usuario.unidade.tipoUnidade.id === 1) this.isDR = true;
        this.isHomologado = props.usuarioConectado.colaborador && props.usuarioConectado.colaborador.isAtivo && props.usuarioConectado.colaborador.isHomologadoBrasilMais;

        this.isDN = (usuario.unidade.tipoUnidade.sigla === 'DN');

        if (this.isUO) this.unidade = usuario.unidade.unidadePai;
        if (this.isDR) this.unidade = usuario.unidade;

        this.state = {
            turmaList: [], emptyList: 'seeResults', buscaLivre: '', buscaAvancadaTurma: '', buscaAvancadaAtendimento: '', dateEscolhido: '', tab: 3, totalTurmas: '0', turmaEsperaElementList: [], atendimentoTurmaList: [], atendimentoList: [],
            turmaExecucaoElementList: [], turmaFinalizadaElementList: [], openModal: false, tipo: '', turma: undefined, error: false, openInfo: false, unidadeAgrupamentoElementList: [], agrupamento: false,
            iniciarTurma: false, novosClientes: 0, useBusca: null, editModal: false, editAtendimentoId: '', editAtendimentoNome: '', mentoresDados: null
        }

        this.filter = {
            dataInicio: new Date(),
            dataFim: new Date(),
            dataQuinze: false,
            dataTodos: false,
            execucao: false,
            naoIniciada: false,
            finalizada: false,
            cancelada: false,
            faseA: false,
            faseB: false,
            faseC: false,
            faseD: false
        };
    };

    componentDidMount() {
        if (this.isUO) {
            this.getAgrupamento();
        }
        if (this.isDR) {
            this.getTotalNovosClientes();
        }
        this.getTurmas();
    };

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    formatDataWithSlash(date) {
        if (date != null) {
            var dataList = date.split('T');
            var dataAux = dataList[0].split('-');
            var data = dataAux[2] + '/' + dataAux[1] + '/' + dataAux[0]
            return data;
        }
    };

    getAtendimentoTurmaModel(idAtendimentoTurma) {
        return { id: idAtendimentoTurma };
    };

    getAgrupamento = () => {
        API.get('rest/gestao/Turma/agrupamento').then(res => {
            let agrupamentoList = res.data;
            let agrupamento = agrupamentoList != null && agrupamentoList.length > 0 ? true : false;
            let unidadeAgrupamentoElementList = [];
            if (agrupamento === true) {
                unidadeAgrupamentoElementList.push(<label key={'titulo'} className={'titulo'}>Compatilhamento de turmas com:</label>);
                unidadeAgrupamentoElementList.push(<br key={'titulobr'} />);
                for (var i = 0; i < agrupamentoList.length; i++) {
                    unidadeAgrupamentoElementList.push(<label className={'item'} key={'agrupamento-' + i}>- {agrupamentoList[i]}</label>);
                    unidadeAgrupamentoElementList.push(<br key={'agrupamentobr-' + i} />);
                }
            } else {
                unidadeAgrupamentoElementList.push(<label key={'titulo'} className={'titulo'}>Não há compartilhamento de turmas com outras unidades.</label>);
            }
            this.setState({ unidadeAgrupamentoElementList: unidadeAgrupamentoElementList, agrupamento: agrupamento });
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Error ao realizar GET dos agrupamentos. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    getTotalNovosClientes = async () => {
        let novosClientes = (await API.get(`/rest/sistema/Cluster/count/${this.props.usuarioConectado.unidade.id}`)).data;
        this.setState({ novosClientes: novosClientes });
    };

    getAtendimentos = async () => {
        await API.get('rest/gestao/Turma/atendimentos').then(res => {
            let atendimentoList = res.data != null ? res.data : [];
            this.filteredAtendimentos = atendimentoList.filter(aux => { return aux[5] === true }); //[id, titulo, cpfcnpj, nome, unidade, flag para aparecer na listagem]
            this.setState({ atendimentoList: atendimentoList });
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Error ao realizar GET dos atendimentos. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    getTurmas = () => {
        this.showLoading();
        this.buscaLivre = this.state.buscaLivre;
        this.setState({ useBusca: this.buscaLivre.length > 0 ? true : false });
        API.get('rest/gestao/Turma/turmas', this.buscaLivre !== '' ? { params: { 'buscaLivreTurma': this.buscaLivre } } : {}).then(res => {
            this.turmaList = res.data != null ? res.data : [];
            this.getLinesTurmas();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao realizar GET das turmas. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    getTurmasAvancado = () => {
        this.showLoading();
        this.filter['buscaLivreTurma'] = this.state.buscaAvancadaTurma;
        this.filter['buscaAvancadaAtendimento'] = this.state.buscaAvancadaAtendimento;

        if (this.unidade) this.filter['departamentoRegional'] = this.unidade.id;

        API.get('rest/gestao/Turma/turmas', { params: this.filter }).then(res => {
            this.turmaList = res.data != null ? res.data : [];
            this.getLinesTurmas();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao realizar GET das turmas. Favor, entrar em contato com o suporte.', 'error');
        });
    };


    setModal = (linesId) => {

        const { statusId } = this.linesTab.find(line => line.id === linesId)

        if (statusId === 1) {
            this.changeModal(true, "espera", linesId)
        } else {
            this.changeModal(true, 'trilha', linesId)
        }
    }

    formatDataTimestamp(timestamp, hasHour) {
        var data = new Date(timestamp).toLocaleString('pt-BR', { timeZone: 'America/Sao_Paulo' });
        let dataSplit = data.split(' ');
        let dataList = dataSplit[0].split('/');
        let hour = '';
        if (hasHour) hour = ' às ' + dataSplit[1].split(':').slice(0, 2).join(':')
        return dataList[0] + '/' + dataList[1] + '/' + dataList[2] + hour;
    }

    setDefaultValuesTab() {
        
        return [
            { id: 'turma', canOrder: false, label: 'Turma' },
            { id: 'colaborador', canOrder: false, label: 'Mentor/Colaborador' },
            { id: 'dr', canOrder: true, label: 'DR' },
            { id: 'etapa', canOrder: false, label: 'Etapa' },
            { id: 'status', canOrder: false, label: 'Status' },
            { id: 'icons', canOrder: true, label: '' },
        ];
    }



    getLinesTurmas = () => {
        this.linesTab = [];
        this.titlesTab = this.setDefaultValuesTab();
        let totalTurmas = this.turmaList.length.toString();
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);

        this.turmaList.forEach((elem_turma, index) => {
            let line = {
                id: elem_turma[0],
                turma: elem_turma[1],
                etapa: elem_turma[2],
                dr: elem_turma[3],
                statusId: elem_turma[4],
                status: elem_turma[4],
                colaborador: elem_turma[5] ? elem_turma[5].toString().replaceAll(",", ", ") : '',
                date: new Date(elem_turma[6]),
                icons: '',
            };
            let turmaLabelStatus = ''
            let turmaStatus = ''

            switch (line.status) {
                case 1:
                    turmaLabelStatus = 'Não iniciada';
                    turmaStatus = 'Espera';

                    line.icons = 
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 70}}>
                            {(this.isUO || this.isDR) && (
                                    <label>
                                        <Link to={'/brasilmais/mentorialean/' + line.id}>
                                            <ModifiedTooltip title="Editar Turma" placement="top" arrow>
                                                <img className={'turma-pen'}
                                                src={process.env.PUBLIC_URL + '/assets/icons/pen.svg'} alt='icon' />
                                            </ModifiedTooltip>
                                        </Link>
                                    </label>
                            )}

                            <label onClick={() => this.changeModal(true, "espera", line.id)}>
                                <ModifiedTooltip title="Editar Atendimentos" placement="top" arrow>
                                    <img className={'turma-pen'}
                                    src={process.env.PUBLIC_URL + '/assets/icons/atendimentos.svg'} alt='icon' />
                                </ModifiedTooltip>
                            </label>

                            {this.isDN && (
                                <label>
                                    <Link to={{
                                        pathname: '/brasilmais/mentorialean/review/' + line.id,
                                        state: { id: 1 }
                                    }}>
                                        <ModifiedTooltip title="Ver Revisão Turma" placement="top" arrow>
                                            <img className={'turma-pen'}
                                            src={process.env.PUBLIC_URL + '/assets/icons/review.svg'} alt='icon' />
                                        </ModifiedTooltip>
                                    </Link>
                                </label>
                            )}
                        </div>

                    break;
                case 2:
                    turmaLabelStatus = 'Em execução';
                    turmaStatus = 'Execucao';
                    line.icons = 
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: this.isDR ? 110 : 70}}>
                            {(this.isUO || this.isDR) && (
                                    <label>
                                        <Link to={'/brasilmais/mentorialean/' + line.id}>
                                            <ModifiedTooltip title="Editar Turma" placement="top" arrow>
                                                <img className={'turma-pen'}
                                                src={process.env.PUBLIC_URL + '/assets/icons/pen.svg'} alt='icon' />
                                            </ModifiedTooltip>
                                        </Link>
                                    </label>
                            )}

                            <label onClick={() => this.changeModal(true, "trilha", line.id)}>
                                <ModifiedTooltip title="Ver Trilha" placement="top" arrow>
                                    <img className={'turma-pen'}
                                    src={process.env.PUBLIC_URL + '/assets/icons/trilha.svg'} alt='icon' />
                                </ModifiedTooltip>
                            </label>

                            {this.isDR && (
                                <label onClick={() => this.changeModal(true, "editar", line.id)}>
                                    <ModifiedTooltip title={<div>Editar <br/> Atendimentos </div>} placement="top" arrow>
                                        <img className={'turma-pen'}
                                        src={process.env.PUBLIC_URL + '/assets/icons/atendimentos.svg'} alt='icon' />
                                    </ModifiedTooltip>
                                </label>
                            )}

                            {this.isDN && (
                                <label>
                                    <Link to={{
                                        pathname: '/brasilmais/mentorialean/review/' + line.id,
                                        state: { id: 1 }
                                    }}>
                                        <ModifiedTooltip title="Ver Revisão Turma" placement="top" arrow>
                                            <img className={'turma-pen'}
                                            src={process.env.PUBLIC_URL + '/assets/icons/review.svg'} alt='icon' />
                                        </ModifiedTooltip>
                                    </Link>
                                </label>
                            )}
                        </div>
                    break;
                case 3:
                    turmaLabelStatus = 'Finalizada';
                    turmaStatus = 'Finalizada';
                    
                    line.icons = 
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width:60}}>
                                <label onClick={() => this.changeModal(true, "trilha", line.id)}>
                                    <ModifiedTooltip title="Ver Trilha" placement="top" arrow>
                                        <img className={'turma-pen'}
                                        src={process.env.PUBLIC_URL + '/assets/icons/trilha.svg'} alt='icon' />
                                    </ModifiedTooltip>
                                </label>

                                <label>
                                    <Link to={{
                                        pathname: '/brasilmais/mentorialean/review/' + line.id,
                                        state: { id: 1 }
                                    }}>
                                        <ModifiedTooltip title="Ver Revisão Turma" placement="top" arrow>
                                            <img className={'turma-pen'}
                                            src={process.env.PUBLIC_URL + '/assets/icons/review.svg'} alt='icon' />
                                        </ModifiedTooltip>
                                    </Link>
                                </label>
                            </div>

                    break;
                default:
                    turmaLabelStatus = 'Cancelada';
                    turmaStatus = 'Cancelada';
                    break;
            }


            line.status =
                <div className={'status-' + turmaStatus + ' statusBox'}>
                    <label className={'status' + turmaStatus}>
                        <div className={"tooltip-solicitacao"}>
                            <span className={'tooltip-info'}>
                                {!isNaN(Date.parse(line.date)) ? `Última atualização: ${this.formatDataTimestamp(line.date, true)}` : line.date}
                            </span>
                            <span className={'em-analise'}>{turmaLabelStatus}</span>
                        </div>
                    </label>
                </div>;
            if (line.etapa >= 1 && line.etapa <= 11) {
                line.etapa = this.etapaList.find(etapa => etapa.id === line.etapa).etapa
            }
            else {
                line.etapa = 'Não iniciada'
            }

            this.linesTab.push(line);
        });
        this.setState({ totalTurmas: totalTurmas });
        this.closeLoading();
    };

    closeModalEdit = () => {
        this.setState({editModal: false, editAtendimentoId: '', editAtendimentoNome: ''});
    }

    resetFields = () => {
        this.filter.naoIniciada = false;
        this.filter.execucao = false;
        this.filter.dataInicio = new Date();
        this.filter.dataFim = new Date();
        this.filter.dataQuinze = false;
        this.filter.dataTodos = false;
        this.filter.execucao = false;
        this.filter.naoIniciada = false;
        this.filter.finalizada = false;
        this.filter.cancelada = false;
        this.filter.faseA = false;
        this.filter.faseB = false;
        this.filter.faseC = false;
        this.filter.faseD = false;
        this.hasFilter = false;
        this.dateOption = '';
        this.setState({ buscaLivre: '', buscaAvancadaTurma: '', buscaAvancadaAtendimento: '' })
    }

    changeBuscaLivre = (e) => {
        if (e != null && e.target != null && e.target.value != null) {
            this.setState({ buscaLivre: e.target.value });
        }
    };

    changeModal = (openModal, tipo, idTurma) => {
        if (openModal === false) {
            this.setState({ openModal: false, openInfo: false, tipo: '', turma: undefined, atendimentoElementList: [], error: false, atendimentoTurmaList: [], iniciarTurma: false });
        } else {
            this.showLoading();
            API.get('rest/gestao/Turma/' + idTurma).then(async res => {
                let turma = res.data;
                let atendimentoTurmaList = [];
                let atendimentoElementList = [];
                let mentoresDados = ''
                let i;
                if (tipo === 'trilha'){
                    /*
                    await API.get('rest/gestao/TurmaMentoriaDigital/mentoresDados/' + idTurma).then(res =>{
                        mentoresDados = res.data;
                    })
                    */
                }
                if (tipo === 'espera') {
                    if (this.isUO) await this.getAtendimentos();
                    this.validarTurma(turma);
                    atendimentoTurmaList = Object.assign([], turma.atendimentoTurmaList);
                    for (i = 0; atendimentoTurmaList != null && i < atendimentoTurmaList.length; i++) {
                        let idAtendimentoTurma = atendimentoTurmaList[i].id;
                        let line = [];
                        line.push(idAtendimentoTurma);
                        line.push(atendimentoTurmaList[i].atendimento.cliente.nome);
                        line.push(atendimentoTurmaList[i].atendimento.unidade.abreviacao);
                        line.push(
                            <div key={'atendimento-chip-' + idAtendimentoTurma} className={'atendimento-chip'}>
                                <GenericButton color={'transparent'} click={(e) => { this.removeLine(idAtendimentoTurma) }} subClass={'icon-and-button'} icon={'trash.svg'} disabled={!this.isUO} />
                            </div>
                        );
                        atendimentoElementList.push(line);
                    };
                }
                else if (tipo === 'editar') {
                    /*
                    atendimentoTurmaList = Object.assign([], turma.atendimentoTurmaList);
                    for (i = 0; atendimentoTurmaList != null && i < atendimentoTurmaList.length; i++) {
                        let idAtendimentoTurma = atendimentoTurmaList[i].id;
                        let nome = atendimentoTurmaList[i].atendimento.cliente.nome;
                        let idAtd = atendimentoTurmaList[i].atendimento.id
                        let line = [];
                        line.push(idAtendimentoTurma);
                        line.push(nome);
                        line.push(atendimentoTurmaList[i].atendimento.unidade.abreviacao);
                        line.push(
                            <div key={'atendimento-chip-' + idAtendimentoTurma} className={'atendimento-chip'}>
                                <GenericButton color={'transparent'} click={(e) => { this.setState({openModal: false,editModal: true,editAtendimentoId:idAtd, editAtendimentoNome:nome}) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                            </div>
                        );
                        console.log('turma.etapaAlinhamentoB',turma.etapaAlinhamentoB)
                        let idAlinhamentoB =  turma.etapaAlinhamentoB.atendimentoList.findIndex( atd => atd.idAtendimento === idAtd);
                        if(!turma.etapaAlinhamentoB.atendimentoList[idAlinhamentoB].cancelado){
                            atendimentoElementList.push(line);
                        }
                        
                    };
                    */
                }
                this.setState({ openModal: true, openInfo: false, tipo: tipo, turma: turma, atendimentoElementList: atendimentoElementList, atendimentoTurmaList: (tipo === 'espera' || tipo === 'editar') ? atendimentoTurmaList : [], mentoresDados : mentoresDados }, this.closeLoading());
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Error ao realizar GET da turma. Favor, entrar em contato com o suporte.', 'error');
            });
        };
    };

    changeModalAvancado(modal, status) {
        this[modal] = status;
        this.setState(this.state);
    }

    changeInput = (e) => {
        if (e.target && e.target.type === 'checkbox') {
            if (e.target.value === 'todas') {
                this.setState(this.state)
                this.filter.faseA = e.target.checked
                this.filter.faseB = e.target.checked
                this.filter.faseC = e.target.checked
                this.filter.faseD = e.target.checked
            } else {
                this.setState(this.state)
                this.filter[e.target.value] = e.target.checked
            }
        } else if (e.id != null) {
            this.dateOption = e.id;
            this.filter.dataInicio = new Date();
            this.filter.dataFim = new Date();

            if (e.id === 0) {
                this.filter.dataInicio.setDate(this.filter.dataInicio.getDate() - 15);
                this.filter.dataQuinze = true;
            } else {
                this.filter.dataInicio.setMonth(this.filter.dataInicio.getDate());
                this.filter.dataTodos = true;
            }
            this.setState(this.state);

        }
    };

    addLine = (e) => {
        if (e != null && e[0] != null) {
            let atendimentoElementList = this.state.atendimentoElementList;
            let atendimentoTurmaList = this.state.atendimentoTurmaList;
            let atendimentoList = this.state.atendimentoList;
            let index = atendimentoElementList.findIndex(aux => { return aux[0] === e[0] });
            if (index < 0) {
                let line = [];
                let idAtendimentoTurma = e[0];
                let atendimentoTurma = this.getAtendimentoTurmaModel(idAtendimentoTurma);
                line.push(idAtendimentoTurma);
                line.push(e[3]);
                line.push(e[4]);
                line.push(
                    <div key={'atendimento-chip-' + idAtendimentoTurma} className={'atendimento-chip'}>
                        <GenericButton color={'transparent'} click={(e) => { this.removeLine(idAtendimentoTurma) }} subClass={'icon-and-button'} icon={'trash.svg'} disabled={!this.isUO} />
                    </div>
                );
                atendimentoElementList.push(line);
                atendimentoTurmaList.push(atendimentoTurma);
                index = atendimentoList.findIndex(aux => { return aux[0] === idAtendimentoTurma });
                atendimentoList[index][5] = false; //[idatendimentoturma, titulo do atendimento, cpfcnpj, nome, unidade, flag para aparecer na listagem]
                this.filteredAtendimentos = atendimentoList.filter(aux => { return aux[5] === true });
                this.setState({ error: false, atendimentoElementList: atendimentoElementList, atendimentoTurmaList: atendimentoTurmaList, openInfo: false, atendimentoList: atendimentoList });
            } else {
                this.setState({ error: true, openInfo: false });
            }
        }
    };

    removeLine = (idAtendimentoTurma) => {
        if (idAtendimentoTurma != null) {
            let atendimentoElementList = this.state.atendimentoElementList;
            let atendimentoTurmaList = this.state.atendimentoTurmaList;
            let atendimentoList = this.state.atendimentoList;
            let index = atendimentoElementList.findIndex(aux => { return aux[0] === idAtendimentoTurma });
            atendimentoElementList.splice(index, 1);
            atendimentoTurmaList.splice(index, 1);
            index = atendimentoList.findIndex(aux => { return aux[0] === idAtendimentoTurma });
            if (index >= 0) atendimentoList[index][5] = true; //[id, titulo, cpfcnpj, nome, unidade, flag para aparecer na listagem]
            this.filteredAtendimentos = atendimentoList.filter(aux => { return aux[5] === true }); //[id, titulo, cpfcnpj, nome, unidade, flag para aparecer na listagem]
            this.setState({ atendimentoElementList: atendimentoElementList, atendimentoTurmaList: atendimentoTurmaList, openInfo: false, atendimentoList: atendimentoList });
        }
    };

    createTemplateAtendimento(atendimento) {
        return (
            <div className={'auto-complete-options'}>
                <div>
                    <label>{atendimento[3]} - {Masks.maskByTipoPessoa(atendimento[2], 1)}</label>
                </div>
                <div>
                    <p>Atendimento: {atendimento[1]}</p>
                    <p>Unidade: {atendimento[4]}</p>
                </div>
            </div>
        );
    };

    createTemplateUnidade(unidade) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{unidade.abreviacao}</label>
                </div>
                <div>
                    <p>{unidade.municipio.descricao} - {unidade.municipio.unidadeFederativa.sigla}</p>
                </div>
            </div>
        );
    }

    validarTurma = (turma) => {
        this.turmaValidada = turma != null && turma.dataInicio != null && turma.dataInicio !== '' && turma.dataTermino != null &&
            turma.dataTermino !== '' && turma.etapaA != null && turma.etapaTeoricaList != null && turma.etapaTeoricaList.length === 3 &&
            turma.etapaPraticaB != null && turma.etapaPraticaC != null && turma.etapaPraticaD != null && turma.etapaConsultoriaC != null &&
            turma.etapaConsultoriaD != null;
    };

    goBack = () => {
        this.setState({ openModal: false, openInfo: false, tipo: '', turma: undefined, atendimentoElementList: [], error: false, atendimentoTurmaList: [], iniciarTurma: false }, this.closeLoading);
    };

    getIniciarTurma() {
        return this.state.iniciarTurma && !this.state.atendimentoElementList != null && this.state.atendimentoElementList.length >= 1 &&
            this.state.atendimentoElementList.length <= 8 && this.state.tipo === 'espera' && this.turmaValidada === true;
    }

    salvarTurma = () => {
        this.showLoading();
        let turma = this.state.turma;
        if (this.state.tipo === 'espera') {
            turma.atendimentoTurmaList = this.state.atendimentoTurmaList;
            if (this.getIniciarTurma()) {
                turma.status = 2;
                turma.etapaAtual = 0;
                if (turma.atendimentoTurmaList.length > 0) {
                    turma.etapaPraticaB.atendimentoList = [];
                    turma.etapaPraticaC.atendimentoList = [];
                    turma.etapaConsultoriaC.atendimentoList = [];
                    turma.etapaPraticaD.atendimentoList = [];
                    turma.etapaConsultoriaD.atendimentoList = [];
                    let atendimentoList = this.state.atendimentoList;
                    for (var i = 0; i < turma.atendimentoTurmaList.length; i++) {
                        let currAtendimento = this.isUO ? atendimentoList.find(aux => { return aux[0] === turma.atendimentoTurmaList[i].id })[6] : turma.atendimentoTurmaList[i].atendimento.id; //eslint-disable-line
                        turma.etapaPraticaB.atendimentoList.push({
                            idAtendimento: currAtendimento,
                            horasApropriadas: 0,
                            finalizado: false,
                            etapa: 3
                        })
                        turma.etapaPraticaC.atendimentoList.push({
                            idAtendimento: currAtendimento,
                            horasApropriadas: 0,
                            finalizado: false,
                            etapa: 5,
                            planoDeAcao: []
                        })
                        turma.etapaConsultoriaC.atendimentoList.push({
                            idAtendimento: currAtendimento,
                            horasApropriadas: 0,
                            finalizado: false,
                            etapa: 6
                        })
                        turma.etapaPraticaD.atendimentoList.push({
                            idAtendimento: currAtendimento,
                            horasApropriadas: 0,
                            finalizado: false,
                            etapa: 8
                        })
                        turma.etapaConsultoriaD.atendimentoList.push({
                            idAtendimento: currAtendimento,
                            horasApropriadas: 0,
                            finalizado: false,
                            etapa: 9
                        })
                    }
                }
            }
        }
        else if(this.state.tipo === 'editar'){
            /*
            let idPraticaB = turma.etapaPraticaB.atendimentoList.findIndex( atd => atd.idAtendimento === this.state.editAtendimentoId);
            turma.etapaPraticaB.atendimentoList[idPraticaB].status = 7;
            turma.etapaPraticaB.atendimentoList[idPraticaB].cancelado = true;
            let idPraticaC = turma.etapaPraticaC.atendimentoList.findIndex( atd => atd.idAtendimento === this.state.editAtendimentoId);
            turma.etapaPraticaC.atendimentoList[idPraticaC].status = 7;
            turma.etapaPraticaC.atendimentoList[idPraticaC].cancelado = true;
            let idConsultoriaC = turma.etapaConsultoriaC.atendimentoList.findIndex( atd => atd.idAtendimento === this.state.editAtendimentoId);
            turma.etapaConsultoriaC.atendimentoList[idConsultoriaC].status = 7;
            turma.etapaConsultoriaC.atendimentoList[idConsultoriaC].cancelado = true;
            let idPraticaD = turma.etapaPraticaD.atendimentoList.findIndex( atd => atd.idAtendimento === this.state.editAtendimentoId);
            turma.etapaPraticaD.atendimentoList[idPraticaD].status = 7;
            turma.etapaPraticaD.atendimentoList[idPraticaD].cancelado = true;
            let idConsultoriaD = turma.etapaConsultoriaD.atendimentoList.findIndex( atd => atd.idAtendimento === this.state.editAtendimentoId);
            turma.etapaConsultoriaD.atendimentoList[idConsultoriaD].status = 7;
            turma.etapaConsultoriaD.atendimentoList[idConsultoriaD].cancelado = true;
            let i, count = 0;
            if(turma.etapaAtual== 3 ||  turma.etapaAtual== 5 || turma.etapaAtual== 6 || turma.etapaAtual== 8 || turma.etapaAtual== 9 ){
                switch (turma.etapaAtual) {
                    case 3:
                        for (i = 0; i < turma.etapaPraticaB.atendimentoList.length; i++) {           
                            if (!turma.etapaPraticaB.atendimentoList[i].finalizado && !turma.etapaPraticaB.atendimentoList[i].cancelado) break;
                            count++;
                        }
                        if(count == turma.etapaPraticaB.atendimentoList.length){
                            turma.etapaAtual++;
                            turma.etapaPraticaB.finalizada = true;
                        }
                        break;
                    case 5:
                        for (i = 0; i < turma.etapaPraticaC.atendimentoList.length; i++) {           
                            if (!turma.etapaPraticaC.atendimentoList[i].finalizado && !turma.etapaPraticaC.atendimentoList[i].cancelado) break;
                            count++;
                        }
                        if(count == turma.etapaPraticaC.atendimentoList.length){
                            turma.etapaAtual++;
                            turma.etapaPraticaC.finalizada = true;
                        }
                        break;
                    case 6:
                        for (i = 0; i < turma.etapaConsultoriaC.atendimentoList.length; i++) {           
                            if (!turma.etapaConsultoriaC.atendimentoList[i].finalizado && !turma.etapaConsultoriaC.atendimentoList[i].cancelado) break;
                            count++;
                        }
                        if(count == turma.etapaConsultoriaC.atendimentoList.length){
                            turma.etapaAtual++;
                            turma.etapaConsultoriaC.finalizada = true;
                        }
                        break;
                    case 8:
                        for (i = 0; i < turma.etapaPraticaD.atendimentoList.length; i++) {           
                            if (!turma.etapaPraticaD.atendimentoList[i].finalizado && !turma.etapaPraticaD.atendimentoList[i].cancelado) break;
                            count++;
                        }
                        if(count == turma.etapaPraticaD.atendimentoList.length){
                            turma.etapaAtual++;
                            turma.etapaPraticaD.finalizada = true;
                        }
                        break;
                    case 9:
                        for (i = 0; i < turma.etapaConsultoriaD.atendimentoList.length; i++) {           
                            if (!turma.etapaConsultoriaD.atendimentoList[i].finalizado && !turma.etapaConsultoriaD.atendimentoList[i].cancelado) break;
                            count++;
                        }
                        if(count == turma.etapaConsultoriaD.atendimentoList.length){
                            turma.etapaAtual++;
                            turma.etapaConsultoriaD.finalizada = true;
                        }
                        break;
                
                    default:
                        break;
                }
            }
                */
        }
        API.post('rest/gestao/Turma/' + turma.id, turma).then(res => {
            this.setState({
                openModal: false,
                openInfo: false,
                turma: undefined,
                tipo: '',
                atendimentoElementList: [],
                error: false,
                atendimentoTurmaList: [],
                editAtendimentoId: '',
                editAtendimentoNome: '',
                editModal: false
            }, this.getTurmasAvancado());

            this.showToast('Turma editada com sucesso!', 'success');
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Error ao tentar editar turma. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    downloadRelatorio = () => {
        this.showLoading();

        let queryParams = '';
        
        if (this.state.buscaAvancadaTurma || this.state.buscaAvancadaAtendimento) {
            queryParams += `?buscaLivreTurma=${this.state.buscaAvancadaTurma}&buscaAvancadaAtendimento=${this.state.buscaAvancadaAtendimento}`;
    
            if (this.unidade)  queryParams += `&departamentoRegional=${this.unidade.id}`;
        } else if (this.state.buscaLivre) { 
            queryParams += `?buscaLivreTurma=${this.state.buscaLivre}`;
        }

        try {
            let path = `rest/gestao/RelatorioTurmaLean/getTurmas${queryParams}`;
            window.open(BASE_URL + path);
            this.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }

    };

    render() {
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
          

        return (
            <Page icon={'brasil-mais.svg'} label={'Mentoria Lean'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                {this.state.tipo !== 'trilha' &&
                    <Grid container className={'turma-mentoria-digital'}>
                        <Grid container direction={'row'} justify={'flex-start'} spacing={3} className={'top-container'}>
                            <Grid item><Breadcrumb label={'Gerenciar Turmas'} icon={'breadcrumb-blue.svg'} color={'primary'} /></Grid>
                        </Grid>
                        <Grid item xs={12} className={'pesquisa'}>
                            <Grid container spacing={2} >
                                <Grid item xs={12} sm={(this.isUO ? 6 : 5)} xl={(this.isUO ? 5 : 4)} className={'grid-left-button'}>
                                    {this.isUO &&
                                        <Link to={'/brasilmais/mentorialean/novo'}>
                                            <GenericButton color={'darkBlue'} label={'Cadastrar Nova Turma'} subClass={'basic-button without-margin'} />
                                        </Link>}
                                    {this.isDR &&
                                        <Link to={'/brasilmais/unidadesoperacionais'}>
                                            <ModifiedTooltip title={this.state.novosClientes == 0 ? 'Zero clientes no Setup' : 'Existem clientes aguardando ação no Setup'} placement="top-start" arrow>
                                                <CompositeButton color={'darkBlue'} label={'Setup UO\'s'} subClass={'basic-button'} value={this.state.novosClientes} secondColor={this.state.novosClientes == 0 ? 'yellow' : 'darkGreen'} />
                                            </ModifiedTooltip>
                                        </Link>}
                                    <FormField type={'text'} label={''} pressEnter={() => { this.showLoading(); this.getTurmas(); }}
                                        button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={() => { this.resetFields(); this.showLoading(); this.getTurmas(); }} />}
                                        inputValue={this.state.buscaLivre}
                                        changeValue={this.changeBuscaLivre}
                                        placeholder={'Busque por nome ou atendimento'} />
                                </Grid>

                                <Grid item xs={12} sm={4} xl={4} style={{ display: 'flex', height: '30px', marginTop: '10px' }}>
                                    <Grid container spacing={2}>
                                    <Grid item >
                                        <GenericButton color={'lightBlue'} label={'Busca avançada'} subClass={'basic-button busca-avancada'}
                                            icon={process.env.PUBLIC_URL + 'filter.svg'}
                                            click={() => { this.dateOptionCp = this.dateOption; this.changeModalAvancado('modalBusca', true); this.hasFilter = true; }} />
                                        {this.hasFilter === true &&
                                            <GenericButton color={'white'} subClass={'icon-button'} click={() => { this.resetFields(); this.getTurmas(); }} icon={'close.svg'} />
                                        }
                                    </Grid>
                                    <Grid item  >
                                    <ModifiedTooltip title={'Baixar relatório'} placement="top-start" arrow onClick={this.downloadRelatorio}>
                                        <img style={{ cursor: "pointer" }}
                                            src={process.env.PUBLIC_URL + '/assets/icons/download-darkblue.svg'}
                                            width={'30px'}
                                            height={'30px'} />
                                    </ModifiedTooltip>
                                    </Grid>
                                    <Grid item className={'total'} style={{marginTop :'10px'}}>
                                        <div >
                                            <label>Turmas Criadas: <b>{this.state.totalTurmas ? this.state.totalTurmas.toLocaleString('pt-BR') : 0}</b></label>
                                        </div>
                                    </Grid>
                                    </Grid>
                                </Grid>
                                {this.isUO &&
                                    <div className={'turma-agrupamento'}>
                                        <p>Agrupamento: {this.state.agrupamento === true ? 'SIM' : 'NÃO'}</p>
                                        <ClickAwayListener onClickAway={() => this.setState({ openInfo: false })}>
                                            <div>
                                                <Tooltip PopperProps={{ disablePortal: true }} open={this.state.openInfo} onClose={() => this.setState({ openInfo: false })} disableFocusListener disableHoverListener
                                                    disableTouchListener title={<React.Fragment>{this.state.unidadeAgrupamentoElementList}</React.Fragment>}  placement='left'>
                                                    <GenericButton color={'transparent'} subClass={'pen icon-button'} icon={'info_azul.svg'} click={() => { this.setState({ openInfo: !this.state.openInfo }); }} />
                                                </Tooltip>
                                            </div>
                                        </ClickAwayListener>
                                    </div>
                                }
                            </Grid>
                        </Grid>
                        <SimpleModal
                            isOpen={this.state.editModal}
                            handleClose={() => this.closeModalEdit()}
                            type={'warning'}
                            width={'380px'}
                            height={'220px'}
                            className={'evento-modal'}>
                            <label>Você confirma a remoção da empresa </label>
                            <label className={'bold'}>{this.state.editAtendimentoNome}</label>
                            <label> da turma? O atendimento ficará com o status cancelado. </label>
                            <div className={'rodape'}>
                                <GenericButton color={'darkBlue'} label={'Cancelar'} subClass={'basic-button'} click={ () => this.closeModalEdit()} />
                                <GenericButton color={'darkGrey-outline'} label={'Remover'} subClass={'basic-button'} click={this.salvarTurma} />
                            </div>
                        </SimpleModal>

                        {/* Modal Busca avançada */}
                        <SimpleModal
                            isOpen={this.modalBusca}
                            handleClose={() => this.changeModalAvancado('modalBusca', false)}
                            type={'info'}
                            width={'600px'}
                            height={'1300px'}>

                            <div className={'modal'}>
                                <h2 className={'font-blue'} style={{ textAlign: 'center', marginTop: '30px' }} >Busca avançada</h2>
                            </div>
                            <div className={"turma-atendimento"}>
                                <FormField
                                    type={"text"}
                                    label={"Nome da turma"}
                                    inputValue={this.state.buscaAvancadaTurma}
                                    changeValue={(e) =>
                                        this.setState({ buscaAvancadaTurma: e.target.value })
                                    }
                                    placeholder={"Digite o nome da turma"} />
                            </div>

                            <div className={"turma-atendimento"}>
                                <FormField
                                    type={"text"}
                                    label={"Atendimento"}
                                    inputValue={this.state.buscaAvancadaAtendimento}
                                    changeValue={(e) => this.setState({ buscaAvancadaAtendimento: e.target.value })}
                                    placeholder={"Digite o nome da empresa ou CNPJ"} />
                            </div>

                            <div className={"turma-atendimento"}>
                                <p className={"font-blue"} style={{ fontSize: '15px', marginTop: '30px', marginBottom: '0px', color: '$darkGrey' }}> Departamento Regional</p>
                                <AutoComplete itemKey={'id'} key='autoCompleteUnidade'
                                    itemTemplate={this.createTemplateUnidade}
                                    getInput={(e) => this.unidade = e} itemlabel={'abreviacao'} initialSelectedItem={this.unidade}
                                    path={`/rest/gestao/Unidade/0/3?tipoUnidade.sigla=DR&buscaLivre=`}
                                    disabled={this.isUO || this.isDR} defaultObject={true} />
                            </div>

                            <div item xs={6} className="periodo">
                                <p className={"sub-titulo-modal"}>Período de inicío</p>
                                <InputSelect
                                    suggestions={this.dateOptions}
                                    itemKey={"id"}
                                    itemLabel={"label"}
                                    id={"dateOption"}
                                    getInput={this.changeInput}
                                    initialSelectedItem={{ id: this.dateOption }} />
                            </div>

                            <div className={"turma-atendimento"}>
                                <p className={"sub-titulo-modal"}>Status de turma</p>
                                <div>
                                    <FormControlLabel
                                        control={<Checkbox checked={this.filter.naoIniciada} onChange={this.changeInput} value="naoIniciada" />}
                                        label="Não iniciada" />
                                    <FormControlLabel
                                        control={<Checkbox checked={this.filter.execucao} onChange={this.changeInput} value="execucao" />}
                                        label="Em execução" />
                                    <FormControlLabel
                                        control={<Checkbox checked={this.filter.finalizada} onChange={this.changeInput} value="finalizada" />}
                                        label="Finalizada" />
                                    <FormControlLabel
                                        control={<Checkbox checked={this.filter.cancelada} onChange={this.changeInput} value="cancelada" />}
                                        label="Cancelada" />
                                </div>

                            </div>


                            <div item xs={6} className="fase">
                                <FormControl component="fieldset" className={"checkbox-fase"}>
                                    <p className={"sub-titulo-modal"} style={{ margin: "42px 0px 0px 38px" }}> Fase </p>
                                    <div style={{ display: 'Flex' }}>
                                        <div>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.filter.faseA} onChange={this.changeInput} value="faseA" />}
                                                label="Etapa A" />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.filter.faseB} onChange={this.changeInput} value="faseB" />}
                                                label="Etapa B" />
                                        </div>

                                        <div>
                                            <FormControlLabel
                                                control={<Checkbox checked={this.filter.faseC} onChange={this.changeInput} value="faseC" />}
                                                label="Etapa C" />
                                            <FormControlLabel
                                                control={<Checkbox checked={this.filter.faseD} onChange={this.changeInput} value="faseD" />}
                                                label="Etapa D" />
                                        </div>
                                    </div>
                                </FormControl>
                            </div>

                            <div className={"rodape-modal-turma"}>
                                <section>
                                    <GenericButton
                                        color={"darkGrey-outline"}
                                        label={"Cancelar"}
                                        subClass={"basic-button"}
                                        click={() => {
                                            this.dateOption = this.dateOptionCp;
                                            this.resetFields();
                                            this.changeModalAvancado("modalBusca", false);
                                        }} />
                                </section>
                                <section>
                                    <GenericButton
                                        color={"darkBlue"}
                                        label={"Buscar"}
                                        subClass={"basic-button"}
                                        click={() => {
                                            this.getTurmasAvancado();
                                            this.changeModalAvancado("modalBusca", false);
                                        }} />
                                </section>
                            </div>
                        </SimpleModal>
                        {this.linesTab !== null && this.linesTab !== [] &&
                            <Grid item xs={12} className={'Table'}>
                                <Table
                                    lines={this.linesTab}
                                    titles={this.titlesTab}
                                    initialOrder={''}
                                    actionParamer={'id'}
                                    pagination={true} />
                            </Grid>}

                        {this.linesTab === null &&
                            <Grid item xs={12} className={'emptylist'}>
                                <EmptyList type={this.state.emptyList} seeAll={() => { this.getTurmas() }} />
                            </Grid>}

                        {this.linesTab !== null && this.linesTab.length === 0 && this.hasFilter && (
                            <Grid item xs={12} className={'emptylist'}>
                                <EmptyList type={'noItens'} msg={'Não foram encontradas turmas para este filtro'} icon={'turma'} />
                            </Grid>
                        )}

                        {this.linesTab !== null && this.linesTab.length === 0 && !this.hasFilter && (
                            <Grid item xs={12} className={'emptylist'}>
                                <EmptyList type={'noItens'} msg={this.state.useBusca ? 'Não foram encontradas turmas para essa busca' : 'Ainda não há turmas cadastradas'} icon={'turma'} />
                            </Grid>
                        )}
                        
                        {this.state.openModal && this.state.tipo === 'espera' &&
                            <SimpleModal isOpen={this.state.openModal} handleClose={() => this.changeModal(false, '', undefined)} disableClick={true} type={'info'} width={'700px'} className={'turma-modal'}>
                                <h2>{this.state.turma.nome}</h2>
                                <Grid container>
                                    {this.isUO &&
                                        <Grid item xs={12} className={'atendimento-busca'}>

                                            <AutoComplete
                                                suggestions={this.filteredAtendimentos}
                                                disabled={this.state.atendimentoElementList == null || (this.state.atendimentoElementList != null && this.state.atendimentoElementList.length > 7) || this.state.tipo !== 'espera'}
                                                itemKey={0}
                                                placeholder={'Digite o nome da empresa ou atendimento'}
                                                itemlabel={3}
                                                button={true}
                                                label={'Atendimentos'}
                                                getInputButton={(e) => { this.addLine(e) }}
                                                isComponentShouldNotPropsUpdate={true}
                                                itemTemplate={this.createTemplateAtendimento}
                                                initialSelectedItem={''} />

                                        </Grid>}
                                    {this.state.error &&
                                        <Grid item xs={12}>
                                            <span className={'atendimento-error'}>Opção já cadastrada.</span>
                                        </Grid>}

                                    <Grid item xs={12} className={'grid-tabela'}>
                                        {this.state.atendimentoElementList != null && this.state.atendimentoElementList.length > 0 ?
                                            <Table lines={this.state.atendimentoElementList} titles={this.titles} initialOrder={''} pagination={false} /> :
                                            <EmptyList type={'noItens'} msg={' '} subMsg={'Cadastre atendimento/cliente nessa turma.'} />}
                                    </Grid>
                                    {((this.isUO && this.isHomologado) || this.isDR) &&
                                        <Grid item xs={12} className={'grid-checkbox'}>
                                            <ModifiedTooltip title={<div>A turma só poderá ser iniciada<br /> com 1 a 8 empresas vinculadas</div>} placement="top-start" arrow>
                                            <FormControlLabel value='end' control={<Checkbox checked={this.getIniciarTurma()} onChange={(e) => { this.setState({ iniciarTurma: !this.state.iniciarTurma }) }} />}
                                                disabled={!(this.state.atendimentoElementList != null && this.state.atendimentoElementList.length >= 1 &&
                                                    this.state.atendimentoElementList.length <= 8 && this.state.tipo === 'espera' && this.turmaValidada === true)}
                                                label={'Iniciar turma'} labelPlacement='end' />
                                            </ModifiedTooltip>
                                        </Grid>}
                                </Grid>
                                {(this.isUO || this.isDR) ?
                                    <div className={'div-botoes'}>
                                        <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.changeModal(false, '', undefined)} />
                                        <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} click={this.salvarTurma} />
                                    </div> :
                                    <div className={'div-botoes'}>
                                        <GenericButton color={'darkBlue'} label={'Fechar'} subClass={'basic-button'} click={() => this.changeModal(false, '', undefined)} />
                                    </div>}
                            </SimpleModal>}

                            {this.state.openModal && this.state.tipo === 'editar' &&
                            <SimpleModal isOpen={this.state.openModal} handleClose={() => this.changeModal(false, '', undefined)} disableClick={true} type={'info'} width={'700px'} className={'turma-modal'}>
                                <h2>{this.state.turma.nome}</h2>
                                <Grid container>

                                    <Grid item xs={12} className={'grid-tabela'}>
                                        {this.state.atendimentoElementList != null && this.state.atendimentoElementList.length > 0 ?
                                            <Table lines={this.state.atendimentoElementList} titles={this.titles} initialOrder={''} pagination={false} /> :
                                            <EmptyList type={'noItens'} msg={' '} subMsg={'Cadastre atendimento/cliente nessa turma.'} />}
                                    </Grid>
                                </Grid>
                                    <div className={'div-botoes'}>
                                        <GenericButton color={'darkBlue'} label={'Fechar'} subClass={'basic-button'} click={() => this.changeModal(false, '', undefined)} />
                                    </div>
                            </SimpleModal>}
                    </Grid>}
                {
                    this.state.openModal && this.state.tipo === 'trilha' &&
                    <Trilha
                        showToast={this.showToast}
                        showLoading={this.showLoading}
                        closeLoading={this.closeLoading}
                        turma={this.state.turma}
                        mentoresDados={this.state.mentoresDados}
                        goBack={this.goBack}
                        isUO={this.isUO}
                        usuarioConectado={this.props.usuarioConectado} />
                }
            </Page >
        );
    }
}