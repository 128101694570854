import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Masks from '../../comm/masks';
import GenericButton from '../../components/genericButton/genericButton';
import SimpleModal from './../../components/modal/modal';
import SignatureCanvas from 'react-signature-canvas';
import API from '../../comm/API';
import './termoEncerramento.scss';

export default class TermoEncerramentoDigital extends Component {

    estadosDE = ['MG', 'SC', 'GO', 'PE', 'SP', 'SE', 'AL', 'RO', 'RR'];
    estadosDA = ['PB', 'BA'];
    sigCanvas = {};
    sigCanvasConsultor = {};

    constructor(props) {
        super(props);
        let cliente = this.props.atendimento.cliente;
        let endereco = cliente.enderecoList[0];
        let unidade = this.props.atendimento.unidade;

        console.log('this.props.atendimento =>', this.props.atendimento);

        this.state = { 
            openModal: false,
            clienteNome: cliente.nome.toUpperCase(), 
            clienteMunicipio: endereco.municipio.descricao.toUpperCase(),
            clienteEstadoSigla: endereco.municipio.unidadeFederativa.sigla.toUpperCase(),
            clienteEstado: endereco.municipio.unidadeFederativa.descricao.toUpperCase(),
            clienteLogradouro: endereco.logradouro.toUpperCase(),
            clienteNumero: endereco.numero.toUpperCase(),
            clienteBairro: endereco.bairro.toUpperCase(),
            clienteCnpj: Masks.maskByTipoPessoa(cliente.cpfcnpj, cliente.tipoPessoa),
            unidadeMunicipio: unidade.municipio.descricao.toUpperCase(),
            unidadeEstadoSigla: unidade.municipio.unidadeFederativa.sigla.toUpperCase(),
            unidadeEstado: unidade.municipio.unidadeFederativa.descricao.toUpperCase(),
            proposta: this.props.atendimento.proposta.descricao.toUpperCase(),
            clienteEmpresario: cliente.contatoList[0] ? cliente.contatoList[0].nome ? cliente.contatoList[0].nome : '' : '',
            clienteEmpresarioCpf: '',
            tab: 'empresario'
        };
    };

    getPreposicao(sigla) {
        if (this.estadosDA.includes(sigla)) return 'da';
        else if (this.estadosDE.includes(sigla)) return 'de';
        else return 'do';
    };

    changeModal = async (value) => {
        await this.setState({ openModal: value });

        if (this.state.tab === 'consultor') {
          this.setState({ tab: 'empresario' });
        }
    };

    async Base64ToFile(url, filename, mimeType) {
        return await (fetch(url)
            .then(function(res) { return res.arrayBuffer(); })
            .then(function(buf) { return new File([buf], filename, {type:mimeType}); })
        );
    };

    saveAssinatura = async (type) => {
        this.props.showLoading();
        let fileName = 'signature' + new Date().getTime() + '.png';
        let file= undefined;
        if (type === 'empresario') {
          file = await this.Base64ToFile(this.sigCanvas.getTrimmedCanvas().toDataURL('image/png'), fileName, 'image/png');
        } else {
          file =  await this.Base64ToFile(this.sigCanvasConsultor.getTrimmedCanvas().toDataURL('image/png'), fileName, 'image/png');
        }
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', fileName);
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let atividade = this.props.atividade;
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                idMentor : type === 'consultor' ? this.props.usuarioConectado.colaborador.id : null
            };

            if (atividade.assinaturas.length > 0 && type === 'empresario') {
              anexo.id = atividade.assinaturas[0].id
              atividade.assinaturas[0] = anexo;
            } else if (atividade.assinaturas.length > 1 && type === 'consultor') {
              anexo.id = atividade.assinaturas[1].id
              atividade.assinaturas[1] = anexo;
            } else {
              atividade.assinaturas.push(anexo);
            }
            
            this.props.closeLoading();

            if (type === 'consultor') {
              this.props.changeShowTermo(false);
              this.props.showToast('Termo de encerramento assinado com sucesso!', 'success');
              this.changeModal(false)
            }
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao tentar assinar o termo de encerramento. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        });
    };

    clear = (canvas) => {
      canvas.clear();
    };

    clearSigCanvas = () => {
      this.sigCanvas.clear();
    }

    render() {
        return (
            <Grid container className={'turma-termo-encerramento'} direction={'column'} justify={'center'} alignItems={'center'} alignContent={'center'}>
                <Grid item xs={12} md={9} xl={6}>
                    <label className={'cliente-nome-title'}>
                        <img style={{ marginRight: '10px' }} src={process.env.PUBLIC_URL + '/assets/icons/showcase-azul.svg'} alt={'icon'} />
                        <label style={{ marginTop: '4px' }}>{this.state.clienteNome}</label>
                    </label>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={9} xl={6} style={{ display: 'contents' }}>
                    <h1>Termo de Encerramento</h1>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={9} xl={6}>
                    <h3>
                        A empresa <label className={'cliente-nome'}>{this.state.clienteNome}</label>, localizada em {this.state.clienteMunicipio}, Estado {this.getPreposicao(this.state.clienteEstadoSigla)} {this.state.clienteEstado}, 
                        localizada na {this.state.clienteLogradouro} nº {this.state.clienteNumero}, Bairro de {this.state.clienteBairro}, inscrito no Cadastro Geral de Contribuintes do Ministério 
                        da Fazenda sob nº {this.state.clienteCnpj} por seu representante ao final assinado, e Serviço Nacional de Aprendizagem Industrial, doravante denominado simplesmente SENAI, 
                        estabelecido na Cidade de {this.state.unidadeMunicipio}, Estado {this.getPreposicao(this.state.unidadeEstadoSigla)} {this.state.unidadeEstado}, declaram encerrado a Proposta 
                        nº {this.state.proposta} referente à Consultoria do Programa Brasil Mais Produtivo - Mentoria Digital e que tenho ciência do relatório e resultados do atendimento conforme tabela de indicadores 
                        abaixo:
                    </h3>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={9} xl={6} style={{ display: 'contents' }}>
                  { this.props.children(true) }
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={9} xl={6} style={{ marginTop: '10px' }}>
                    <h3>
                        A empresa {this.state.clienteNome}, declara ter recebido uma cópia física do relatório da mentoria e também que o serviço contratado foi executado em concordância com a proposta técnica citada, sendo realizadas 48 horas de capacitação para cada funcionário participante do programa e 22 horas de consultoria, e que reconhece que os objetivos foram plenamente atendidos dentro do que foi contratado, não remanescendo nenhuma outra obrigação ou responsabilidade do SENAI.
                    </h3>
                </Grid>
                <Grid item xs={12} className={'bottom-buttons'}>
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Não aceito'} click={() => this.props.changeShowTermo(false)} />
                    &emsp;
                    <GenericButton color={'darkGreen'} subClass={'basic-button'} label={'Eu aceito'} click={() => this.changeModal(true) } />
                </Grid>
                <SimpleModal
                    isOpen={this.state.openModal}
                    handleClose={() => this.changeModal(false)}
                    type={'info'}
                    disableClick={true}
                    width={'900px'}>
                    <div className={'modal modal-entrega'}>
                        <Grid container direction={'column'} justify={'center'} alignItems={'center'} alignContent={'center'} style={{ paddingTop: '30px' }}>
                            {this.state.tab === 'empresario'  ? (
                              <>
                                <h2>Assinatura do Empresário/Empresária</h2>
                                <Grid item xs={12} className={'grid-termo-encerramento'}>
                                    <SignatureCanvas 
                                      ref={(ref) => { this.sigCanvas = ref }} 
                                      penColor={'black'} 
                                      canvasProps={{ width: 600, height: 324, className: 'assinatura-canvas' }} 
                                    />
                                    <GenericButton color={'transparent'} click={() => this.clear(this.sigCanvas)} subClass={'icon-and-button'} icon={'trash.svg'} />
                                </Grid> 
                                <Grid item xs={12} style={{ paddingTop: '10px', paddingBottom: '30px'}}>
                                    <label className={'label-empresario'}>{this.state.clienteEmpresario}</label>
                                    <label>{this.state.clienteEmpresarioCpf}</label>
                                </Grid>
                              </>

                            ) : (
                              <>
                                <h2>Assinatura do Consultor/Consultora</h2>
                                <Grid item xs={12} className={'grid-termo-encerramento'}>
                                    <SignatureCanvas 
                                      ref={(ref) => { this.sigCanvasConsultor = ref }} 
                                      penColor={'black'} 
                                      canvasProps={{ width: 600, height: 324, className: 'assinatura-canvas' }} 
                                    />
                                    <GenericButton color={'transparent'} click={() => this.clear(this.sigCanvasConsultor)} subClass={'icon-and-button'} icon={'trash.svg'} />
                                </Grid> 
                                <Grid 
                                  item 
                                  xs={12} 
                                  style={{ 
                                    paddingTop: '10px', 
                                    paddingBottom: '30px', 
                                    display: 'flex', 
                                    flexDirection: 'column', 
                                    alignItems: 'center'
                                  }}
                                >
                                    <label className={'label-empresario'}>{this.props.usuarioConectado.nome || ''}</label>
                                    <label>{(this.props.usuarioConectado.colaborador && this.props.usuarioConectado.colaborador.cpf) || ''}</label>
                                </Grid>
                              </>
                            )}

                            <Grid item xs={12}>
                                <GenericButton 
                                  color={'darkBlue-outline'} 
                                  label={'Cancelar'} 
                                  subClass={'basic-button'} 
                                  click={() => {
                                    this.changeModal(false)
                                  }} 
                                />
                                &emsp;
                                <GenericButton 
                                  color={this.state.tab === 'empresario' ? 'darkBlue' : 'darkGreen'} 
                                  label={this.state.tab === 'empresario' ? 'Prosseguir' : 'Salvar'} 
                                  subClass={'basic-button'} 
                                  click={async () => {
                                    if (this.state.tab === 'empresario') {
                                      await this.saveAssinatura('empresario')
                                      this.clearSigCanvas()
                                      this.setState({ tab: 'consultor'});
                                    } else  {
                                      this.saveAssinatura('consultor');
                                    }
                                  }} 
                                />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
            </Grid>
        );
    };
};