import React, { Component } from 'react';
import { Grid, Tabs, AppBar, Tab } from '@material-ui/core';
import API from './../../comm/API';
import { getBoxes, mappedBox } from './dashboardISIData';
import GenericButton from './../../components/genericButton/genericButton';
import Page from './../../components/page/page';
import InputSelect from '../../components/inputselect/inputselect';
import Box from './box';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import './dashboardISI.scss';

export default class DashboardISI extends Component {

    isi = null;
    anoList = [];
    isiList = [];
    periodoList = [];
    currentYear = new Date().getFullYear();
    ano = this.currentYear;
    ultimaAtualizacao = null; 
    KPIList = [];
    kpi = null;
    isUO = true;
    strategicOrder = [[34], [32], [21], [6, 5, 13, 9]];
    rampupOrder = [[34, 39, 42], [28, 29, 31], [19, 22, 23], [17, 8, 10, 9]]
    stableOrder = [[34, 35, 37, 38, 40, 41], [25, 28, 29, 30, 31, 32], [20, 21, 22, 23], [6, 15, 8, 14, 13, 10, 9, 16]];

    constructor(props) {
        super(props);
        this.state = { tab: 'strategic', boxes: getBoxes(this.ano), open: false, periodoListFiltered: [], periodo: undefined, ano: this.ano, isi: undefined };
        this.getAnoList();
        this.getISIList();
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getISIList = () => {
        //Verifica se usuário tem colaborador
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            this.unidadeUsuario = this.props.usuarioConectado.colaborador.unidade;
        } else {
            this.unidadeUsuario = this.props.usuarioConectado.unidade;
        }

        let filter = {};
        this.isUO = false;
        filter['especialidadeUnidade.id'] = 1; //ISI
        if (this.unidadeUsuario.tipoUnidade.sigla === 'UO') {
            this.isUO = true;
            filter['id'] = this.unidadeUsuario.id;
        } else if (this.unidadeUsuario.tipoUnidade.sigla === 'DR') {
            filter['unidadePai.id'] = this.unidadeUsuario.id;
        }
        API.get('rest/gestao/Unidade', { params: filter }).then(res => {
            this.isi = res.data[0];
            this.isiList = res.data;
            this.setState({ isi: this.isi });
            this.getPeriodoList(true);
        });
    };

    getAnoList = () => {
        for (var i = this.currentYear; i >= 2018; i--) {
            this.anoList.push({ id: i, label: i });
        }
    };

    getPeriodoList = (init, atualizado) => {
        let filter = {};
        filter['unidade'] = this.isi.id; //ISI
        API.get('rest/sistema/DashboardIsi/periodo', { params: filter }).then(res => {
            this.periodoList = res.data;
            this.changeAno({label: this.ano});
            if (init) {
                this.loadDashboard();
                this.ultimaAtualizacao = res.data.length <= 0 ? null : res.data[0];
            } else if (this.ano === new Date().getFullYear()) {
                this.ultimaAtualizacao = res.data.length <= 0 ? null : res.data[0];
                this.closeLoading();
                if (atualizado) { 
                    this.showToast('Dashboard atualizado com sucesso.', 'success'); 
                    this.setState(this.state);
                }
            }
        }).catch(error => {
            this.periodoList = [];
            this.changeAno({label: this.ano});
            if (init) this.loadDashboard();
            this.closeLoading();
            this.showToast('Ocorreu um erro ao buscar a lista de períodos. Favor, entrar em contato com o suporte.', 'error');
        });       
    };

    changeTab = (e, newValue) => {
        if (newValue != null) {
            this.setState({ tab: newValue }, this.closeLoading());
        }
    };

    changeISI = (e) => {
        this.showLoading();
        if (e != null && e !== '' && e.id !== this.isi.id) {
            this.isi = e;
            this.getPeriodoList();
        }
    };

    changeAno = (e) => {
        if (e != null) {
            this.ano = e.label;
            let aux = this.periodoList.filter(this.filterPeriodo);
            this.setState({ periodoListFiltered: aux });
            this.changePeriodo(aux.length > 0 ? aux[0] : undefined);
        }
    };

    filterPeriodo = (periodo) => {
        return periodo.includes(this.ano);
    };

    changePeriodo = (e) => {
        if (e !== this.state.periodo) this.setState({ periodo: e });
    };

    loadDashboard = (atualizado) => {
        this.showLoading();
        if (this.state.periodo == null || this.state.periodo === 'Não há dados salvos') {
            let filter = { ano: this.ano, unidade: this.isi.id };
            API.get('rest/sistema/KPI/dadosKPIList/' + this.ano + '/' + this.isi.id).then(res => { //valores dinâmicos
                var KPIValues = res.data;
                API.get('rest/sistema/KPI', { params: filter }).then(res => {
                    this.loadValoresDinamicos(KPIValues, res.data);
                }).catch(error => {
                    console.log(error);
                    this.closeLoading();
                    this.showToast('Ocorreu um erro ao buscar a lista de KPIs. Favor, entrar em contato com o suporte.', 'error');
                });
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Ocorreu um erro ao buscar os dados dinâmicos. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            let data_hora = this.state.periodo.split(' ');
            let data = data_hora[0].split('/');
            let hora = data_hora[1].split(':');
            let new_date = data[2] + '-' + data[1] + '-' + data[0] + 'T' + hora[0] + ':' + hora[1] + ':00';
            let filter = { 'dataCadastro': new_date, 'idUnidade': this.isi.id };
            API.get('rest/sistema/DashboardIsi/findDashboard?', { params: filter }).then(res => {
                this.loadValoresHistorico(res.data[0], atualizado);
            }).catch(error => {
                console.log(error);
                this.loadValoresHistorico(null, atualizado);
                this.showToast('Ocorreu um erro ao buscar o histórico solicitado. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    };

    loadValoresDinamicos = (KPIValues, inputList, atualizado) => {
        let boxes = getBoxes(this.ano);
        boxes['strategic'][1].actual_value = KPIValues.customerSatisfationPast ? KPIValues.customerSatisfationPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//32 order 27 actualPast
        boxes['strategic'][2].actual_value = KPIValues.dependencyCustomerPast ? KPIValues.dependencyCustomerPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//21 order 24 actualPast
        boxes['strategic'][3].actual_value = KPIValues.totalRevenuePast ? (KPIValues.totalRevenuePast > 100000 ? (KPIValues.totalRevenuePast/1000000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'M' : (KPIValues.totalRevenuePast/1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'K') : '-';//6 order 2
        boxes['strategic'][3].children[1].actual_value = KPIValues.shareRevenuePDIPast ? KPIValues.shareRevenuePDIPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//13 order 19
        boxes['strategic'][3].children[2].actual_value = KPIValues.shareIndustryPast ? KPIValues.shareIndustryPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//9 order 5
        boxes['rampup'][0].children[0].actual_value = KPIValues.investimentsPast ? KPIValues.investimentsPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//39 order 16
        boxes['rampup'][0].children[1].actual_value = KPIValues.projectsInCollaborationPast ? KPIValues.projectsInCollaborationPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//42 order 17
        boxes['rampup'][1].actual_value = KPIValues.successRatePast ? KPIValues.successRatePast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//28 order 12
        boxes['rampup'][1].children[0].actual_value = KPIValues.inExecutionPast ? KPIValues.inExecutionPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//29 order 13
        boxes['rampup'][1].children[1].actual_value = KPIValues.deliveredInTimePast ? KPIValues.deliveredInTimePast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//31 order 14
        boxes['rampup'][2].actual_value = KPIValues.totalCustomersPast ? KPIValues.totalCustomersPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//19 order 7
        boxes['rampup'][2].children[0].actual_value = KPIValues.smallMediumCustomersPast ? KPIValues.smallMediumCustomersPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//22 order 8
        boxes['rampup'][2].children[1].actual_value = KPIValues.largeCustomersPast ? KPIValues.largeCustomersPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//23 order 9
        boxes['rampup'][3].actual_value = KPIValues.projectsOutsidePast ? KPIValues.projectsOutsidePast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//17 order 0
        boxes['rampup'][3].children[1].actual_value = KPIValues.shareFundingPast ? KPIValues.shareFundingPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//10 order 4
        boxes['rampup'][3].children[2].actual_value = KPIValues.shareIndustryPast ? KPIValues.shareIndustryPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//9 order 5
        boxes['stable'][0].children[0].actual_value = KPIValues.employedTechnicalStaffPast ? KPIValues.employedTechnicalStaffPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//35 order 28
        boxes['stable'][0].children[4].actual_value = KPIValues.shareReceivedPartnersPast ? KPIValues.shareReceivedPartnersPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//41 order 33
        boxes['stable'][1].actual_value = KPIValues.successRatePast ? KPIValues.successRatePast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//28 order 12
        boxes['stable'][1].children[0].actual_value = KPIValues.averageRevenueProjectPast ? (KPIValues.averageRevenueProjectPast/1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'K' : '-';//25 order 25
        boxes['stable'][1].children[1].actual_value = KPIValues.inExecutionPast ? KPIValues.inExecutionPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//29
        boxes['stable'][1].children[2].actual_value = KPIValues.averageRevenueResearcherPast ? KPIValues.averageRevenueResearcherPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//30 order 26
        boxes['stable'][1].children[3].actual_value = KPIValues.deliveredInTimePast ? KPIValues.deliveredInTimePast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//31 order 14
        boxes['stable'][1].children[4].actual_value = KPIValues.customerSatisfationPast ? KPIValues.customerSatisfationPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//32 order 27
        boxes['stable'][2].actual_value = KPIValues.newCustomersPast ? KPIValues.newCustomersPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//20 order 23
        boxes['stable'][2].children[0].actual_value = KPIValues.dependencyCustomerPast ? KPIValues.dependencyCustomerPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//21 order 24
        boxes['stable'][2].children[1].actual_value = KPIValues.smallMediumCustomersPast ? KPIValues.smallMediumCustomersPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';;//22 order 8
        boxes['stable'][2].children[2].actual_value = KPIValues.largeCustomersPast ? KPIValues.largeCustomersPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//23
        boxes['stable'][3].actual_value = KPIValues.totalRevenuePast ? (KPIValues.totalRevenuePast > 100000 ? (KPIValues.totalRevenuePast/1000000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'M' : (KPIValues.totalRevenuePast/1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'K') : '-';//6 order 2
        boxes['stable'][3].children[0].actual_value = KPIValues.numberPublicationsPast ? KPIValues.numberPublicationsPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-';//15 order 21
        boxes['stable'][3].children[3].actual_value = KPIValues.shareRevenuePDIPast ? KPIValues.shareRevenuePDIPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%' : '-';//13 order 19
        boxes['stable'][3].children[4].actual_value = KPIValues.shareFundingPast ? KPIValues.shareFundingPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%'  : '-';//10 order 4
        boxes['stable'][3].children[5].actual_value = KPIValues.shareIndustryPast ? KPIValues.shareIndustryPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%'  : '-';//9 order 5

        var mappedBoxes = mappedBox();
        mappedBoxes.forEach(map => {
            let obj = this.getInput(map.order, inputList);
            if (obj != null) {
                let box = map.children != null ? boxes[map.type][map.id].children[map.children] : boxes[map.type][map.id];
                box.trend = obj.trend != null && obj.trend !== '' ? obj.trend : '-';
                box.assessment = obj.assessment != null && obj.assessment !== '' ? obj.assessment : '-';
                if (obj.forecastCurrent == null || obj.forecastCurrent === '') box.forecast_value = '-';
                else if (obj.ordem === 2) box.forecast_value = obj.forecastCurrent > 100000 ? (obj.forecastCurrent/1000000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'M' : (obj.forecastCurrent/1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'K';
                else if (obj.ordem === 25) box.forecast_value = (obj.forecastCurrent/1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'K';
                else if ([27,24,19,5,16,12,14,4,5,33,12,14,27,24,19,4,5].includes(obj.ordem)) box.forecast_value = obj.forecastCurrent.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%';
                else box.forecast_value = obj.forecastCurrent.toLocaleString('pt-BR', { maximumFractionDigits: 2 });
                if (!(box.forecast2_value == null)) {
                    if (obj.forecastFuture == null || obj.forecastFuture === '') box.forecast2_value = '-';
                    else if (obj.ordem === 2) box.forecast2_value = obj.forecastFuture > 100000 ? (obj.forecastFuture/1000000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'M' : (obj.forecastFuture/1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'K';
                    else if (obj.ordem === 25) box.forecast2_value = (obj.forecastFuture/1000).toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + 'K';
                    else if ([27,24,19,5,16,12,14,4,5,33,12,14,27,24,19,4,5].includes(obj.ordem)) box.forecast2_value = obj.forecastFuture.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) + '%';
                    else box.forecast2_value = obj.forecastFuture.toLocaleString('pt-BR', { maximumFractionDigits: 2 });
                }
                if (obj.ordem === 15 || obj.ordem === 3 || obj.ordem === 30 || obj.ordem === 31 || obj.ordem === 32 || obj.ordem === 20 || obj.ordem === 3 || obj.ordem === 22) {
                    box.actual_value = obj.actualPast && obj.actualPast !== '' ? obj.actualPast.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : '-' ;
                }
            }
        });
        this.setState({ boxes: boxes, ano: this.ano, isi: this.isi });
        if (!atualizado) this.closeLoading();
    };

    getInput(ordem, inputList) {
        let item;
        inputList.forEach(elem => { if (Number(elem.ordem) === Number(ordem)) item = elem; });
        return item;
    };

    loadValoresHistorico = (historicValues, atualizado) => {
        let boxes = getBoxes(this.ano);
        if (historicValues != null) {
            boxes['strategic'][0].actual_value = historicValues['strategic']['strategic0_actual_value'];
            boxes['strategic'][0].forecast_value = historicValues['strategic']['strategic0_forecast_value'];
            boxes['strategic'][0].trend = historicValues['strategic']['strategic0_trend'];
            boxes['strategic'][0].assessment = historicValues['strategic']['strategic0_assessment'];
            boxes['strategic'][1].actual_value = historicValues['strategic']['strategic1_actual_value'];
            boxes['strategic'][1].forecast_value = historicValues['strategic']['strategic1_forecast_value'];
            boxes['strategic'][1].trend = historicValues['strategic']['strategic1_trend'];
            boxes['strategic'][1].assessment = historicValues['strategic']['strategic1_assessment'];
            boxes['strategic'][2].actual_value = historicValues['strategic']['strategic2_actual_value'];
            boxes['strategic'][2].forecast_value = historicValues['strategic']['strategic2_forecast_value'];
            boxes['strategic'][2].trend = historicValues['strategic']['strategic2_trend'];
            boxes['strategic'][2].assessment = historicValues['strategic']['strategic2_assessment'];
            boxes['strategic'][3].actual_value = historicValues['strategic']['strategic3_actual_value'];
            boxes['strategic'][3].forecast_value = historicValues['strategic']['strategic3_forecast_value'];
            boxes['strategic'][3].trend = historicValues['strategic']['strategic3_trend'];
            boxes['strategic'][3].assessment = historicValues['strategic']['strategic3_assessment'];
            boxes['strategic'][3].forecast2_value = historicValues['strategic']['strategic3_forecast2_value'];
            boxes['strategic'][3].children[0].actual_value = historicValues['strategic']['strategic30_actual_value'];
            boxes['strategic'][3].children[0].forecast_value = historicValues['strategic']['strategic30_forecast_value'];
            boxes['strategic'][3].children[0].trend = historicValues['strategic']['strategic30_trend'];
            boxes['strategic'][3].children[0].assessment = historicValues['strategic']['strategic30_assessment'];
            boxes['strategic'][3].children[0].forecast2_value = historicValues['strategic']['strategic30_forecast2_value'];
            boxes['strategic'][3].children[1].actual_value = historicValues['strategic']['strategic31_actual_value'];
            boxes['strategic'][3].children[1].forecast_value = historicValues['strategic']['strategic31_forecast_value'];
            boxes['strategic'][3].children[1].trend = historicValues['strategic']['strategic31_trend'];
            boxes['strategic'][3].children[1].assessment = historicValues['strategic']['strategic31_assessment'];
            boxes['strategic'][3].children[1].forecast2_value = historicValues['strategic']['strategic31_forecast2_value'];
            boxes['strategic'][3].children[2].actual_value = historicValues['strategic']['strategic32_actual_value'];
            boxes['strategic'][3].children[2].forecast_value = historicValues['strategic']['strategic32_forecast_value'];
            boxes['strategic'][3].children[2].trend = historicValues['strategic']['strategic32_trend'];
            boxes['strategic'][3].children[2].assessment = historicValues['strategic']['strategic32_assessment'];
            boxes['strategic'][3].children[2].forecast2_value = historicValues['strategic']['strategic32_forecast2_value'];

            boxes['rampup'][0].actual_value = historicValues['rampup']['rampup0_actual_value'];
            boxes['rampup'][0].forecast_value = historicValues['rampup']['rampup0_forecast_value'];
            boxes['rampup'][0].trend = historicValues['rampup']['rampup0_trend'];
            boxes['rampup'][0].assessment = historicValues['rampup']['rampup0_assessment'];
            boxes['rampup'][0].children[0].actual_value = historicValues['rampup']['rampup00_actual_value'];
            boxes['rampup'][0].children[0].forecast_value = historicValues['rampup']['rampup00_forecast_value'];
            boxes['rampup'][0].children[0].trend = historicValues['rampup']['rampup00_trend'];
            boxes['rampup'][0].children[0].assessment = historicValues['rampup']['rampup00_assessment'];
            boxes['rampup'][0].children[1].actual_value = historicValues['rampup']['rampup01_actual_value'];
            boxes['rampup'][0].children[1].forecast_value = historicValues['rampup']['rampup01_forecast_value'];
            boxes['rampup'][0].children[1].trend = historicValues['rampup']['rampup01_trend'];
            boxes['rampup'][0].children[1].assessment = historicValues['rampup']['rampup01_assessment'];
            boxes['rampup'][1].actual_value = historicValues['rampup']['rampup1_actual_value'];
            boxes['rampup'][1].forecast_value = historicValues['rampup']['rampup1_forecast_value'];
            boxes['rampup'][1].trend = historicValues['rampup']['rampup1_trend'];
            boxes['rampup'][1].assessment = historicValues['rampup']['rampup1_assessment'];
            boxes['rampup'][1].children[0].actual_value = historicValues['rampup']['rampup10_actual_value'];
            boxes['rampup'][1].children[0].forecast_value = historicValues['rampup']['rampup10_forecast_value'];
            boxes['rampup'][1].children[0].trend = historicValues['rampup']['rampup10_trend'];
            boxes['rampup'][1].children[0].assessment = historicValues['rampup']['rampup10_assessment'];
            boxes['rampup'][1].children[1].actual_value = historicValues['rampup']['rampup11_actual_value'];
            boxes['rampup'][1].children[1].forecast_value = historicValues['rampup']['rampup11_forecast_value'];
            boxes['rampup'][1].children[1].trend = historicValues['rampup']['rampup11_trend'];
            boxes['rampup'][1].children[1].assessment = historicValues['rampup']['rampup11_assessment'];
            boxes['rampup'][2].actual_value = historicValues['rampup']['rampup2_actual_value'];
            boxes['rampup'][2].forecast_value = historicValues['rampup']['rampup2_forecast_value'];
            boxes['rampup'][2].trend = historicValues['rampup']['rampup2_trend'];
            boxes['rampup'][2].assessment = historicValues['rampup']['rampup2_assessment'];
            boxes['rampup'][2].children[0].actual_value = historicValues['rampup']['rampup20_actual_value'];
            boxes['rampup'][2].children[0].forecast_value = historicValues['rampup']['rampup20_forecast_value'];
            boxes['rampup'][2].children[0].trend = historicValues['rampup']['rampup20_trend'];
            boxes['rampup'][2].children[0].assessment = historicValues['rampup']['rampup20_assessment'];
            boxes['rampup'][2].children[1].actual_value = historicValues['rampup']['rampup21_actual_value'];
            boxes['rampup'][2].children[1].forecast_value = historicValues['rampup']['rampup21_forecast_value'];
            boxes['rampup'][2].children[1].trend = historicValues['rampup']['rampup21_trend'];
            boxes['rampup'][2].children[1].assessment = historicValues['rampup']['rampup21_assessment'];
            boxes['rampup'][3].actual_value = historicValues['rampup']['rampup3_actual_value'];
            boxes['rampup'][3].forecast_value = historicValues['rampup']['rampup3_forecast_value'];
            boxes['rampup'][3].trend = historicValues['rampup']['rampup3_trend'];
            boxes['rampup'][3].assessment = historicValues['rampup']['rampup3_assessment'];
            boxes['rampup'][3].children[0].actual_value = historicValues['rampup']['rampup30_actual_value'];
            boxes['rampup'][3].children[0].forecast_value = historicValues['rampup']['rampup30_forecast_value'];
            boxes['rampup'][3].children[0].trend = historicValues['rampup']['rampup30_trend'];
            boxes['rampup'][3].children[0].assessment = historicValues['rampup']['rampup30_assessment'];
            boxes['rampup'][3].children[1].actual_value = historicValues['rampup']['rampup31_actual_value'];
            boxes['rampup'][3].children[1].forecast_value = historicValues['rampup']['rampup31_forecast_value'];
            boxes['rampup'][3].children[1].trend = historicValues['rampup']['rampup31_trend'];
            boxes['rampup'][3].children[1].assessment = historicValues['rampup']['rampup31_assessment'];
            boxes['rampup'][3].children[2].actual_value = historicValues['rampup']['rampup32_actual_value'];
            boxes['rampup'][3].children[2].forecast_value = historicValues['rampup']['rampup32_forecast_value'];
            boxes['rampup'][3].children[2].trend = historicValues['rampup']['rampup32_trend'];
            boxes['rampup'][3].children[2].assessmen = historicValues['rampup']['rampup32_assessment'];

            boxes['stable'][0].actual_value = historicValues['stable']['stable0_actual_value'];
            boxes['stable'][0].forecast_value = historicValues['stable']['stable0_forecast_value'];
            boxes['stable'][0].trend = historicValues['stable']['stable0_trend'];
            boxes['stable'][0].assessment = historicValues['stable']['stable0_assessment'];
            boxes['stable'][0].children[0].actual_value = historicValues['stable']['stable00_actual_value'];
            boxes['stable'][0].children[0].forecast_value = historicValues['stable']['stable00_forecast_value'];
            boxes['stable'][0].children[0].trend = historicValues['stable']['stable00_trend'];
            boxes['stable'][0].children[0].assessment = historicValues['stable']['stable00_assessment'];
            boxes['stable'][0].children[1].actual_value = historicValues['stable']['stable01_actual_value'];
            boxes['stable'][0].children[1].forecast_value = historicValues['stable']['stable01_forecast_value'];
            boxes['stable'][0].children[1].trend = historicValues['stable']['stable01_trend'];
            boxes['stable'][0].children[1].assessment = historicValues['stable']['stable01_assessment'];
            boxes['stable'][0].children[2].actual_value = historicValues['stable']['stable02_actual_value'];
            boxes['stable'][0].children[2].forecast_value = historicValues['stable']['stable02_forecast_value'];
            boxes['stable'][0].children[2].trend = historicValues['stable']['stable02_trend'];
            boxes['stable'][0].children[2].assessment = historicValues['stable']['stable02_assessment'];
            boxes['stable'][0].children[3].actual_value = historicValues['stable']['stable03_actual_value'];
            boxes['stable'][0].children[3].forecast_value = historicValues['stable']['stable03_forecast_value'];
            boxes['stable'][0].children[3].trend = historicValues['stable']['stable03_trend'];
            boxes['stable'][0].children[3].assessment = historicValues['stable']['stable03_assessment'];
            boxes['stable'][0].children[4].actual_value = historicValues['stable']['stable04_actual_value'];
            boxes['stable'][0].children[4].forecast_value = historicValues['stable']['stable04_forecast_value'];
            boxes['stable'][0].children[4].trend = historicValues['stable']['stable04_trend'];
            boxes['stable'][0].children[4].assessment = historicValues['stable']['stable04_assessment'];
            boxes['stable'][1].actual_value = historicValues['stable']['stable1_actual_value'];
            boxes['stable'][1].forecast_value = historicValues['stable']['stable1_forecast_value'];
            boxes['stable'][1].trend = historicValues['stable']['stable1_trend'];
            boxes['stable'][1].assessment = historicValues['stable']['stable1_assessment'];
            boxes['stable'][1].children[0].actual_value = historicValues['stable']['stable10_actual_value'];
            boxes['stable'][1].children[0].forecast_value = historicValues['stable']['stable10_forecast_value'];
            boxes['stable'][1].children[0].trend = historicValues['stable']['stable10_trend'];
            boxes['stable'][1].children[0].assessment = historicValues['stable']['stable10_assessment'];
            boxes['stable'][1].children[1].actual_value = historicValues['stable']['stable11_actual_value'];
            boxes['stable'][1].children[1].forecast_value = historicValues['stable']['stable11_forecast_value'];
            boxes['stable'][1].children[1].trend = historicValues['stable']['stable11_trend'];
            boxes['stable'][1].children[1].assessment = historicValues['stable']['stable11_assessment'];
            boxes['stable'][1].children[2].actual_value = historicValues['stable']['stable12_actual_value'];
            boxes['stable'][1].children[2].forecast_value = historicValues['stable']['stable12_forecast_value'];
            boxes['stable'][1].children[2].trend = historicValues['stable']['stable12_trend'];
            boxes['stable'][1].children[2].assessment = historicValues['stable']['stable12_assessment'];
            boxes['stable'][1].children[3].actual_value = historicValues['stable']['stable13_actual_value'];
            boxes['stable'][1].children[3].forecast_value = historicValues['stable']['stable13_forecast_value'];
            boxes['stable'][1].children[3].trend = historicValues['stable']['stable13_trend'];
            boxes['stable'][1].children[3].assessment = historicValues['stable']['stable13_assessment'];
            boxes['stable'][1].children[4].actual_value = historicValues['stable']['stable14_actual_value'];
            boxes['stable'][1].children[4].forecast_value = historicValues['stable']['stable14_forecast_value'];
            boxes['stable'][1].children[4].trend = historicValues['stable']['stable14_trend'];
            boxes['stable'][1].children[4].assessment = historicValues['stable']['stable14_assessment'];
            boxes['stable'][2].actual_value = historicValues['stable']['stable2_actual_value'];
            boxes['stable'][2].forecast_value = historicValues['stable']['stable2_forecast_value'];
            boxes['stable'][2].trend = historicValues['stable']['stable2_trend'];
            boxes['stable'][2].assessment = historicValues['stable']['stable2_assessment'];
            boxes['stable'][2].children[0].actual_value = historicValues['stable']['stable20_actual_value'];
            boxes['stable'][2].children[0].forecast_value = historicValues['stable']['stable20_forecast_value'];
            boxes['stable'][2].children[0].trend = historicValues['stable']['stable20_trend'];
            boxes['stable'][2].children[0].assessment = historicValues['stable']['stable20_assessment'];
            boxes['stable'][2].children[1].actual_value = historicValues['stable']['stable21_actual_value'];
            boxes['stable'][2].children[1].forecast_value = historicValues['stable']['stable21_forecast_value'];
            boxes['stable'][2].children[1].trend = historicValues['stable']['stable21_trend'];
            boxes['stable'][2].children[1].assessment = historicValues['stable']['stable21_assessment'];
            boxes['stable'][2].children[2].actual_value = historicValues['stable']['stable22_actual_value'];
            boxes['stable'][2].children[2].forecast_value = historicValues['stable']['stable22_forecast_value'];
            boxes['stable'][2].children[2].trend = historicValues['stable']['stable22_trend'];
            boxes['stable'][2].children[2].assessment = historicValues['stable']['stable22_assessment'];
            boxes['stable'][3].actual_value = historicValues['stable']['stable3_actual_value'];
            boxes['stable'][3].forecast_value = historicValues['stable']['stable3_forecast_value'];
            boxes['stable'][3].trend = historicValues['stable']['stable3_trend'];
            boxes['stable'][3].assessment = historicValues['stable']['stable3_assessment'];
            boxes['stable'][3].children[0].actual_value = historicValues['stable']['stable30_actual_value'];
            boxes['stable'][3].children[0].forecast_value = historicValues['stable']['stable30_forecast_value'];
            boxes['stable'][3].children[0].trend = historicValues['stable']['stable30_trend'];
            boxes['stable'][3].children[0].assessment = historicValues['stable']['stable30_assessment'];
            boxes['stable'][3].children[1].actual_value = historicValues['stable']['stable31_actual_value'];
            boxes['stable'][3].children[1].forecast_value = historicValues['stable']['stable31_forecast_value'];
            boxes['stable'][3].children[1].trend = historicValues['stable']['stable31_trend'];
            boxes['stable'][3].children[1].assessment = historicValues['stable']['stable31_assessment'];
            boxes['stable'][3].children[2].actual_value = historicValues['stable']['stable32_actual_value'];
            boxes['stable'][3].children[2].forecast_value = historicValues['stable']['stable32_forecast_value'];
            boxes['stable'][3].children[2].trend = historicValues['stable']['stable32_trend'];
            boxes['stable'][3].children[2].assessment = historicValues['stable']['stable32_assessment'];
            boxes['stable'][3].children[3].actual_value = historicValues['stable']['stable33_actual_value'];
            boxes['stable'][3].children[3].forecast_value = historicValues['stable']['stable33_forecast_value'];
            boxes['stable'][3].children[3].trend = historicValues['stable']['stable33_trend'];
            boxes['stable'][3].children[3].assessment = historicValues['stable']['stable33_assessment'];
            boxes['stable'][3].children[4].actual_value = historicValues['stable']['stable34_actual_value'];
            boxes['stable'][3].children[4].forecast_value = historicValues['stable']['stable34_forecast_value'];
            boxes['stable'][3].children[4].trend = historicValues['stable']['stable34_trend'];
            boxes['stable'][3].children[4].assessment = historicValues['stable']['stable34_assessment'];
            boxes['stable'][3].children[5].actual_value = historicValues['stable']['stable35_actual_value'];
            boxes['stable'][3].children[5].forecast_value = historicValues['stable']['stable35_forecast_value'];
            boxes['stable'][3].children[5].trend = historicValues['stable']['stable35_trend'];
            boxes['stable'][3].children[5].assessment = historicValues['stable']['stable35_assessment'];
            boxes['stable'][3].children[6].actual_value = historicValues['stable']['stable36_actual_value'];
            boxes['stable'][3].children[6].forecast_value = historicValues['stable']['stable36_forecast_value'];
            boxes['stable'][3].children[6].trend = historicValues['stable']['stable36_trend'];
            boxes['stable'][3].children[6].assessment = historicValues['stable']['stable36_assessment'];
        }
        this.setState({ boxes: boxes, ano: this.ano, isi: this.isi });
        this.closeLoading();
    };

    atualizar = () => {
        this.showLoading();
        let ano = new Date().getFullYear();
        let periodo = new Date();
        periodo.setSeconds(0);
        periodo.setMilliseconds(0);
        API.get('rest/sistema/KPI/dadosKPIList/' + ano + '/' + this.state.isi.id).then(res => { //valores dinâmicos
            var KPIValues = res.data;
            let filter = { ano: ano, unidade: this.state.isi.id };
            API.get('rest/sistema/KPI', { params: filter }).then(async res => {
                await this.loadValoresDinamicos(KPIValues, res.data, true);
                let boxes = this.state.boxes;
                let historicoModel = { 
                    'ano': ano, 
                    'unidade': this.state.isi, 
                    'dataCadastro': periodo,
                    'strategic': {
                        'strategic0_actual_value' : boxes['strategic'][0].actual_value,
                        'strategic0_forecast_value' : boxes['strategic'][0].forecast_value,
                        'strategic0_trend' : boxes['strategic'][0].trend,
                        'strategic0_assessment' : boxes['strategic'][0].assessment,
                        'strategic1_actual_value' : boxes['strategic'][1].actual_value,
                        'strategic1_forecast_value' : boxes['strategic'][1].forecast_value,
                        'strategic1_trend' : boxes['strategic'][1].trend,
                        'strategic1_assessment' : boxes['strategic'][1].assessment,
                        'strategic2_actual_value' : boxes['strategic'][2].actual_value,
                        'strategic2_forecast_value' : boxes['strategic'][2].forecast_value,
                        'strategic2_trend' : boxes['strategic'][2].trend,
                        'strategic2_assessment' : boxes['strategic'][2].assessment,
                        'strategic3_actual_value' : boxes['strategic'][3].actual_value,
                        'strategic3_forecast_value' : boxes['strategic'][3].forecast_value,
                        'strategic3_trend' : boxes['strategic'][3].trend,
                        'strategic3_assessment' : boxes['strategic'][3].assessment,
                        'strategic3_forecast2_value' : boxes['strategic'][3].forecast2_value,
                        'strategic30_actual_value' : boxes['strategic'][3].children[0].actual_value,
                        'strategic30_forecast_value' : boxes['strategic'][3].children[0].forecast_value,
                        'strategic30_trend' : boxes['strategic'][3].children[0].trend,
                        'strategic30_assessment' : boxes['strategic'][3].children[0].assessment,
                        'strategic30_forecast2_value' : boxes['strategic'][3].children[0].forecast2_value,
                        'strategic31_actual_value' : boxes['strategic'][3].children[1].actual_value,
                        'strategic31_forecast_value' : boxes['strategic'][3].children[1].forecast_value,
                        'strategic31_trend' : boxes['strategic'][3].children[1].trend,
                        'strategic31_assessment' : boxes['strategic'][3].children[1].assessment,
                        'strategic31_forecast2_value' : boxes['strategic'][3].children[1].forecast2_value,
                        'strategic32_actual_value' : boxes['strategic'][3].children[2].actual_value,
                        'strategic32_forecast_value' : boxes['strategic'][3].children[2].forecast_value,
                        'strategic32_trend' : boxes['strategic'][3].children[2].trend,
                        'strategic32_assessment' : boxes['strategic'][3].children[2].assessment,
                        'strategic32_forecast2_value'  : boxes['strategic'][3].children[2].forecast2_value
                    }, 'rampup': {
                        'rampup0_actual_value' : boxes['rampup'][0].actual_value,
                        'rampup0_forecast_value' : boxes['rampup'][0].forecast_value,
                        'rampup0_trend' : boxes['rampup'][0].trend,
                        'rampup0_assessment' : boxes['rampup'][0].assessment,
                        'rampup00_actual_value' : boxes['rampup'][0].children[0].actual_value,
                        'rampup00_forecast_value' : boxes['rampup'][0].children[0].forecast_value,
                        'rampup00_trend' : boxes['rampup'][0].children[0].trend,
                        'rampup00_assessment' : boxes['rampup'][0].children[0].assessment,
                        'rampup01_actual_value' : boxes['rampup'][0].children[1].actual_value,
                        'rampup01_forecast_value' : boxes['rampup'][0].children[1].forecast_value,
                        'rampup01_trend' : boxes['rampup'][0].children[1].trend,
                        'rampup01_assessment' : boxes['rampup'][0].children[1].assessment,
                        'rampup1_actual_value' : boxes['rampup'][1].actual_value,
                        'rampup1_forecast_value' : boxes['rampup'][1].forecast_value,
                        'rampup1_trend' : boxes['rampup'][1].trend,
                        'rampup1_assessment' : boxes['rampup'][1].assessment,
                        'rampup10_actual_value' : boxes['rampup'][1].children[0].actual_value,
                        'rampup10_forecast_value' : boxes['rampup'][1].children[0].forecast_value,
                        'rampup10_trend' : boxes['rampup'][1].children[0].trend,
                        'rampup10_assessment' : boxes['rampup'][1].children[0].assessment,
                        'rampup11_actual_value' : boxes['rampup'][1].children[1].actual_value,
                        'rampup11_forecast_value' : boxes['rampup'][1].children[1].forecast_value,
                        'rampup11_trend' : boxes['rampup'][1].children[1].trend,
                        'rampup11_assessment' : boxes['rampup'][1].children[1].assessment,
                        'rampup2_actual_value' : boxes['rampup'][2].actual_value,
                        'rampup2_forecast_value' : boxes['rampup'][2].forecast_value,
                        'rampup2_trend' : boxes['rampup'][2].trend,
                        'rampup2_assessment' : boxes['rampup'][2].assessment,
                        'rampup20_actual_value' : boxes['rampup'][2].children[0].actual_value,
                        'rampup20_forecast_value' : boxes['rampup'][2].children[0].forecast_value,
                        'rampup20_trend' : boxes['rampup'][2].children[0].trend,
                        'rampup20_assessment' : boxes['rampup'][2].children[0].assessment,
                        'rampup21_actual_value' : boxes['rampup'][2].children[1].actual_value,
                        'rampup21_forecast_value' : boxes['rampup'][2].children[1].forecast_value,
                        'rampup21_trend' : boxes['rampup'][2].children[1].trend,
                        'rampup21_assessment' : boxes['rampup'][2].children[1].assessment,
                        'rampup3_actual_value' : boxes['rampup'][3].actual_value,
                        'rampup3_forecast_value' : boxes['rampup'][3].forecast_value,
                        'rampup3_trend' : boxes['rampup'][3].trend,
                        'rampup3_assessment' : boxes['rampup'][3].assessment,
                        'rampup30_actual_value' : boxes['rampup'][3].children[0].actual_value,
                        'rampup30_forecast_value' : boxes['rampup'][3].children[0].forecast_value,
                        'rampup30_trend' : boxes['rampup'][3].children[0].trend,
                        'rampup30_assessment' : boxes['rampup'][3].children[0].assessment,
                        'rampup31_actual_value' : boxes['rampup'][3].children[1].actual_value,
                        'rampup31_forecast_value' : boxes['rampup'][3].children[1].forecast_value,
                        'rampup31_trend' : boxes['rampup'][3].children[1].trend,
                        'rampup31_assessment' : boxes['rampup'][3].children[1].assessment,
                        'rampup32_actual_value' : boxes['rampup'][3].children[2].actual_value,
                        'rampup32_forecast_value' : boxes['rampup'][3].children[2].forecast_value,
                        'rampup32_trend' : boxes['rampup'][3].children[2].trend,
                        'rampup32_assessment' : boxes['rampup'][3].children[2].assessment
                    }, 'stable': {
                        'stable0_actual_value' : boxes['stable'][0].actual_value,
                        'stable0_forecast_value' : boxes['stable'][0].forecast_value,
                        'stable0_trend' : boxes['stable'][0].trend,
                        'stable0_assessment' : boxes['stable'][0].assessment,
                        'stable00_actual_value' : boxes['stable'][0].children[0].actual_value,
                        'stable00_forecast_value' : boxes['stable'][0].children[0].forecast_value,
                        'stable00_trend' : boxes['stable'][0].children[0].trend,
                        'stable00_assessment' : boxes['stable'][0].children[0].assessment,
                        'stable01_actual_value' : boxes['stable'][0].children[1].actual_value,
                        'stable01_forecast_value' : boxes['stable'][0].children[1].forecast_value,
                        'stable01_trend' : boxes['stable'][0].children[1].trend,
                        'stable01_assessment' : boxes['stable'][0].children[1].assessment,
                        'stable02_actual_value' : boxes['stable'][0].children[2].actual_value,
                        'stable02_forecast_value' : boxes['stable'][0].children[2].forecast_value,
                        'stable02_trend' : boxes['stable'][0].children[2].trend,
                        'stable02_assessment' : boxes['stable'][0].children[2].assessment,
                        'stable03_actual_value' : boxes['stable'][0].children[3].actual_value,
                        'stable03_forecast_value' : boxes['stable'][0].children[3].forecast_value,
                        'stable03_trend' : boxes['stable'][0].children[3].trend,
                        'stable03_assessment' : boxes['stable'][0].children[3].assessment,
                        'stable04_actual_value' : boxes['stable'][0].children[4].actual_value,
                        'stable04_forecast_value' : boxes['stable'][0].children[4].forecast_value,
                        'stable04_trend' : boxes['stable'][0].children[4].trend,
                        'stable04_assessment' : boxes['stable'][0].children[4].assessment,
                        'stable1_actual_value' : boxes['stable'][1].actual_value,
                        'stable1_forecast_value' : boxes['stable'][1].forecast_value,
                        'stable1_trend' : boxes['stable'][1].trend,
                        'stable1_assessment' : boxes['stable'][1].assessment,
                        'stable10_actual_value' : boxes['stable'][1].children[0].actual_value,
                        'stable10_forecast_value' : boxes['stable'][1].children[0].forecast_value,
                        'stable10_trend' : boxes['stable'][1].children[0].trend,
                        'stable10_assessment' : boxes['stable'][1].children[0].assessment,
                        'stable11_actual_value' : boxes['stable'][1].children[1].actual_value,
                        'stable11_forecast_value' : boxes['stable'][1].children[1].forecast_value,
                        'stable11_trend' : boxes['stable'][1].children[1].trend,
                        'stable11_assessment' : boxes['stable'][1].children[1].assessment,
                        'stable12_actual_value' : boxes['stable'][1].children[2].actual_value,
                        'stable12_forecast_value' : boxes['stable'][1].children[2].forecast_value,
                        'stable12_trend' : boxes['stable'][1].children[2].trend,
                        'stable12_assessment' : boxes['stable'][1].children[2].assessment,
                        'stable13_actual_value' : boxes['stable'][1].children[3].actual_value,
                        'stable13_forecast_value' : boxes['stable'][1].children[3].forecast_value,
                        'stable13_trend' : boxes['stable'][1].children[3].trend,
                        'stable13_assessment' : boxes['stable'][1].children[3].assessment,
                        'stable14_actual_value' : boxes['stable'][1].children[4].actual_value,
                        'stable14_forecast_value' : boxes['stable'][1].children[4].forecast_value,
                        'stable14_trend' : boxes['stable'][1].children[4].trend,
                        'stable14_assessment' : boxes['stable'][1].children[4].assessment,
                        'stable2_actual_value' : boxes['stable'][2].actual_value,
                        'stable2_forecast_value' : boxes['stable'][2].forecast_value,
                        'stable2_trend' : boxes['stable'][2].trend,
                        'stable2_assessment' : boxes['stable'][2].assessment,
                        'stable20_actual_value' : boxes['stable'][2].children[0].actual_value,
                        'stable20_forecast_value' : boxes['stable'][2].children[0].forecast_value,
                        'stable20_trend' : boxes['stable'][2].children[0].trend,
                        'stable20_assessment' : boxes['stable'][2].children[0].assessment,
                        'stable21_actual_value' : boxes['stable'][2].children[1].actual_value,
                        'stable21_forecast_value' : boxes['stable'][2].children[1].forecast_value,
                        'stable21_trend' : boxes['stable'][2].children[1].trend,
                        'stable21_assessment' : boxes['stable'][2].children[1].assessment,
                        'stable22_actual_value' : boxes['stable'][2].children[2].actual_value,
                        'stable22_forecast_value' : boxes['stable'][2].children[2].forecast_value,
                        'stable22_trend' : boxes['stable'][2].children[2].trend,
                        'stable22_assessment' : boxes['stable'][2].children[2].assessment,
                        'stable3_actual_value' : boxes['stable'][3].actual_value,
                        'stable3_forecast_value' : boxes['stable'][3].forecast_value,
                        'stable3_trend' : boxes['stable'][3].trend,
                        'stable3_assessment' : boxes['stable'][3].assessment,
                        'stable30_actual_value' : boxes['stable'][3].children[0].actual_value,
                        'stable30_forecast_value' : boxes['stable'][3].children[0].forecast_value,
                        'stable30_trend' : boxes['stable'][3].children[0].trend,
                        'stable30_assessment' : boxes['stable'][3].children[0].assessment,
                        'stable31_actual_value' : boxes['stable'][3].children[1].actual_value,
                        'stable31_forecast_value' : boxes['stable'][3].children[1].forecast_value,
                        'stable31_trend' : boxes['stable'][3].children[1].trend,
                        'stable31_assessment' : boxes['stable'][3].children[1].assessment,
                        'stable32_actual_value' : boxes['stable'][3].children[2].actual_value,
                        'stable32_forecast_value' : boxes['stable'][3].children[2].forecast_value,
                        'stable32_trend' : boxes['stable'][3].children[2].trend,
                        'stable32_assessment' : boxes['stable'][3].children[2].assessment,
                        'stable33_actual_value' : boxes['stable'][3].children[3].actual_value,
                        'stable33_forecast_value' : boxes['stable'][3].children[3].forecast_value,
                        'stable33_trend' : boxes['stable'][3].children[3].trend,
                        'stable33_assessment' : boxes['stable'][3].children[3].assessment,
                        'stable34_actual_value' : boxes['stable'][3].children[4].actual_value,
                        'stable34_forecast_value' : boxes['stable'][3].children[4].forecast_value,
                        'stable34_trend' : boxes['stable'][3].children[4].trend,
                        'stable34_assessment' : boxes['stable'][3].children[4].assessment,
                        'stable35_actual_value' : boxes['stable'][3].children[5].actual_value,
                        'stable35_forecast_value' : boxes['stable'][3].children[5].forecast_value,
                        'stable35_trend' : boxes['stable'][3].children[5].trend,
                        'stable35_assessment' : boxes['stable'][3].children[5].assessment,
                        'stable36_actual_value' : boxes['stable'][3].children[6].actual_value,
                        'stable36_forecast_value' : boxes['stable'][3].children[6].forecast_value,
                        'stable36_trend' : boxes['stable'][3].children[6].trend,
                        'stable36_assessment' : boxes['stable'][3].children[6].assessment
                    }
                };
                API.post('rest/sistema/DashboardIsi', historicoModel).then(res => {
                    this.changeAno({ label: ano });
                    this.setState({ ano: ano });
                    this.getPeriodoList(false, true);
                }).catch(error => {
                    console.log(error);
                    this.closeLoading();
                    this.showToast('Ocorreu um erro ao atualizar. Favor, entrar em contato com o suporte.', 'error');
                });  
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Ocorreu um erro ao buscar a lista de KPIs. Favor, entrar em contato com o suporte.', 'error');
            });
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Ocorreu um erro ao buscar os dados dinâmicos. Favor, entrar em contato com o suporte.', 'error');
        });     
    };

    render() {
        return (
            <div>
                <Page icon={'contas.svg'} label={'Dashboard de KPI'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                    <Grid container id={'dashboard-isis'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4}>
                                <InputSelect label={'ISI'} suggestions={this.isiList} itemLabel={'descricao'} getInput={this.changeISI} id={'isi'} itemKey={'id'} 
                                    initialSelectedItem={this.isi} disabled={this.isUO} key={'isi'} />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <InputSelect label={'Ano'} suggestions={this.anoList} itemKey={'label'} itemLabel={'label'} id={'ano'} getInput={this.changeAno} 
                                    initialSelectedItem={{ label: this.ano }} />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputSelect label={'Período'} suggestions={this.state.periodoListFiltered.length <= 0 ? ['Não há dados salvos'] : this.state.periodoListFiltered} id={'periodo'} getInput={this.changePeriodo} 
                                    initialSelectedItem={this.state.periodoListFiltered.length <= 0 ? 'Não há dados salvos' : this.state.periodo} disabled={this.state.periodoListFiltered.length <= 0} />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                <Grid container spacing={this.unidadeUsuario && this.unidadeUsuario.tipoUnidade.sigla !== 'DN' ? 0 : 3}>
                                    <Grid item xs={12} sm={this.unidadeUsuario && this.unidadeUsuario.tipoUnidade.sigla !== 'DN' ? 2 : 3} className={'header-buttons'}>
                                        <GenericButton label={'Aplicar'} color={'darkBlue'} subClass={'basic-button'} id={'btn-aplicar'} click={this.loadDashboard} />
                                    </Grid>
                                    <Grid item xs={12} sm={this.unidadeUsuario && this.unidadeUsuario.tipoUnidade.sigla !== 'DN' ? 3 : 8} className={'header-buttons'}>
                                        {this.unidadeUsuario && this.unidadeUsuario.tipoUnidade.sigla === 'DN' ? 
                                            <GenericButton color={'darkBlue-outline'} subClass={'basic-button atualizar-carga'} label={'Atualizar'} click={this.atualizar} 
                                                icon={process.env.PUBLIC_URL + 'reload.svg'} /> : null}
                                        {this.ultimaAtualizacao != null ?
                                        <div className={'ultima-atualizacao-div'} style={{'float': this.unidadeUsuario && this.unidadeUsuario.tipoUnidade.sigla !== 'DN' ? 'left' : 'right'}}>
                                            <span>Última atualização:</span>
                                            <span>{this.ultimaAtualizacao}</span>
                                        </div> : null}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <AppBar position='static' className={'app-bar'}>
                                <Tabs value={this.state.tab} onChange={(e, newValue) => {this.showLoading(); this.changeTab(e, newValue);}} variant='scrollable' scrollButtons='auto'>
                                    <Tab key={'strategic-kpi'} value={'strategic'} label={'Strategic KPI'}></Tab>
                                    <Tab key={'ramp-up'} value={'rampup'} label={'Ramp-up'}></Tab>
                                    <Tab key={'stable-operations'} value={'stable'} label={'Stable Operations'}></Tab>
                                </Tabs>
                            </AppBar>
                        </Grid>
                        {this.state.tab !== 'strategic' ? 
                            <Grid item xs={3}>
                                <Grid container className='box-target'>
                                    <Grid item xs={12} className='title'>
                                        <span>Time frame</span>
                                    </Grid> 
                                    <Grid container>
                                        <Grid item className='text' sm={8} xl={5}>
                                            <span>Actual: Janeiro - Dezembro {this.state.ano - 1}</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item className='text' sm={8} xl={5}>
                                            <span>Forecast: Janeiro - Dezembro {this.state.ano}</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid> : null
                        }
                        <Grid item xs={this.state.tab === 'strategic' ? 12 : 3}>
                            <Grid container className='box-target'>
                                <Grid item xs={12} className='title'>
                                    <span>Target {this.state.ano} agreed with DR</span>
                                </Grid>
                                <Grid container>
                                    <Grid item className='text' sm={this.state.tab === 'strategic' ? 3 : 8} xl={this.state.tab === 'strategic' ? 2 : 5}>
                                        <span>Total STI revenue (R$)</span>
                                    </Grid>
                                    <Grid item className='text' sm={this.state.tab === 'strategic' ? 2 : 3}>
                                        <span>-</span>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item className='text' sm={this.state.tab === 'strategic' ? 3 : 8} xl={this.state.tab === 'strategic' ? 2 : 5}>
                                        <span>Cost covered by revenue (%)</span>
                                    </Grid>
                                    <Grid item className='text' sm={this.state.tab === 'strategic' ? 2 : 3}>
                                        <span>-</span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container style={{marginTop: '30px'}}>
                            <Grid item xs={12} className='box-info'>
                                <span>Ver legenda</span>
                                <ClickAwayListener onClickAway={() => this.setState({ open: false })}>
                                    <div>
                                        <Tooltip PopperProps={{ disablePortal: true }} open={this.state.open} onClose={() => this.setState({ open: false })} disableFocusListener disableHoverListener 
                                            disableTouchListener title={<img src={process.env.PUBLIC_URL + '/assets/icons/kpidefault.svg'} alt='seta' className={'tooltip-img'} />} placement='right'>
                                            <GenericButton color={'transparent'} subClass={'pen icon-button'} icon={'info_azul.svg'} click={() => { this.setState({ open: !this.state.open }); }} />
                                        </Tooltip>
                                    </div>
                                </ClickAwayListener>
                            </Grid>
                        </Grid>
                        <Grid container className='boxes'>
                            {this.state.boxes[this.state.tab].map((box, index) => {
                                return (
                                    <Grid item md={3} key={'parent' + index}>
                                        <Box only_content={box.only_content} title={box.title} header_duplo={box.header_duplo} name={box.name} actual_year={box.actual_year} actual_value={box.actual_value} 
                                            forecast_year={box.forecast_year} forecast_value={box.forecast_value} forecast2_year={box.forecast2_year} forecast2_value={box.forecast2_value} 
                                            assessment={box.assessment} trend={box.trend} color_index={box.color_index} header={box.header} icon={this.state.tab === 'strategic' ? this.state.tab : this.state.tab + index} />
                                            {box.children.map((boxChildren, indexChildren) => (
                                                <Box only_content={boxChildren.only_content} title={boxChildren.title} name={boxChildren.name} header_duplo={boxChildren.header_duplo} actual_year={boxChildren.actual_year} 
                                                    actual_value={boxChildren.actual_value} forecast_year={boxChildren.forecast_year} forecast_value={boxChildren.forecast_value} key={'child-box' + indexChildren}
                                                    forecast2_year={boxChildren.forecast2_year} forecast2_value={boxChildren.forecast2_value} assessment={boxChildren.assessment} header={boxChildren.header}
                                                    trend={boxChildren.trend} color_index={boxChildren.color_index} tab={this.state.tab}/>
                                            ))}
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </Grid>
                </Page>
            </div>
        );
    };
}
