import React from 'react';
import { createMuiTheme, makeStyles, withStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from "@material-ui/core";
import './collapseGraph.scss'


const collapseSgtTheme = createMuiTheme({
    typography: {
        fontFamily: [
            'Raleway',
            '-apple-system',
            'BlinkMacSystemFont',
        ].join(','),
        fontWeightMedium: 500,
        body1: {
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: 12,
        },
        button: {
            fontStyle: 'italic',
        },
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%"
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular
    }
  }));
  
  const ExpansionSummary = withStyles({
    
    content: {
      flexGrow: 0
    }
  })(ExpansionPanelSummary);

export default function CollapseGraph(props) {
    //const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (idpanel, updateAtendimento) => (event, isExpanded) => {
        setExpanded(isExpanded ? idpanel : false);
        if(props.clickFunction){
            props.onClick();
        }
        // updateAtendimento(idpanel)
    };
    const classes = useStyles();

    return (
        

        
            <div className={classes.root}>

                {!props.isExpanded && props.content.map(
                    (panel, index) => (
                        <Grid container key={index} >
                            <Grid item xs={props.hideButton ? 12 : 11}>
                                <ExpansionPanel key={index} expanded={expanded === panel.id} onChange={handleChange(panel.id, props.function)} className={'box-graph'}>
                                    <ExpansionSummary expandIcon={<ExpandMoreIcon fontSize="large"/>} className={'boxSun-graph'}
                                        aria-controls="bh-content" id="bh-header">
                                    </ExpansionSummary>
                                    <ExpansionPanelDetails className={'details-graph'}>
                                        {panel.body}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                            {props.hideButton ? null :
                                <Grid item xs={1}>
                                    {panel.trashButton}
                                </Grid>
                            }
                        </Grid>
                    )
                )}

                {props.isExpanded && props.content.map(
                    (panel, index) => (
                        <Grid container key={index} >
                            <Grid item xs={props.hideButton ? 12 : 11}>
                                <ExpansionPanel key={index} onChange={handleChange(panel.id, props.function)} className={'box-graph'}>
                                    <ExpansionSummary expandIcon={<ExpandMoreIcon fontSize="large"/>} className={'boxSun-graph'}
                                        aria-controls="bh-content" id="bh-header">
                                    </ExpansionSummary>
                                    <ExpansionPanelDetails className={'details-graph'}>
                                        {panel.body}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                            {props.hideButton ? null :
                                <Grid item xs={1}>
                                    {panel.trashButton}
                                </Grid>
                            }
                        </Grid>
                    )
                )}

            </div>
    );
}