import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import GenericButton from './../../components/genericButton/genericButton';
import DownloadFiles from '../../comm/DownloadFiles';
import API from '../../comm/API';
import InputSelect from '../../components/inputselect/inputselect';
import { Grid, Switch, AppBar, Tabs, Tab, FormControl, RadioGroup, 
    FormControlLabel, Radio, Chip, Icon} from "@material-ui/core"
import UploadTopButtonsDigital from './uploadTopButtonsDigital';
import AnexosDefault from './anexosDefault';

export default class AtividadeArtefatos extends Component {


    SensorOptions = [
        { id: 1, label: "Aquisição empresa", value: "AQUISICAO EMPRESA" },
        { id: 2, label: 'Aquisição via SENAI (contrato)', value: 'AQUISICAO VIA SENAI (CONTRATO)' },
        { id: 3, label: "Não se aplica", value: "NAO SE APLICA"},
        { id: 4, label: "Outro", value: "OUTRO"}
    ];

    ColetorOptions =[
        { id: 1, label: "Aquisição empresa", value: "AQUISICAO EMPRESA" },
        { id: 2, label: 'Aquisição via SENAI (cessão 20 semanas)', value: 'AQUISICAO VIA SENAI (CESSAO 20 SEMANAS)' },
        { id: 3, label: 'Aquisição via SENAI (cessão 12 meses)', value: 'AQUISICAO VIA SENAI (CESSAO 12 MESES)' },
        { id: 4, label: 'Aquisição via SENAI (contrato)', value: 'AQUISICAO VIA SENAI (CONTRATO)'},
        { id: 5, label: "Outro", value: "OUTRO"}
    ]

    MinaOptions =[
        { id: 1, label: 'Aquisição via SENAI (licença de 20 semanas)', value: 'AQUISICAO VIA SENAI (LICENCA DE 20 SEMANAS)' },
        { id: 2, label: 'Aquisição via SENAI (licença de 12 meses)', value: 'AQUISICAO VIA SENAI (LICENCA DE 12 MESES)'},
        { id: 3, label: "Outro", value: "OUTRO"}
    ]

    KitOptions = [
           
        { id: 1, label: "Sem kit", value: "SEM KIT" },
        { id: 2, label: "Kit 1 (básico)", value: "KIT 1 (BASICO)" },
        { id: 3, label: "Kit 2 (Industrial)" , value:  "KIT 2 (INDUSTRIAL)" },
        { id: 4, label: 'Outro', value: "OUTRO"},
    ]

    tipoModelosOptions = [         
        { id: 1, label: "Advantech: Wise-4050" },
        { id: 2, label: "Advantech: Wise-4051" },
        { id: 3, label: "Advantech: Wise-4012" },
        { id: 4, label: 'Novus: DigiRail OEE WRL 2A-6DI-2DO'},
        { id: 5, label: "Novus: DigiRail OEE ETH 2A-6DI-2DO"},
        { id: 6, label: "Outro Modelo"}
    ]

    
    constructor(props) {
        super(props);

        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let artefatosInstalacaoDigital  = props.atividade;
        artefatosInstalacaoDigital.registroVisitas = [];
        artefatosInstalacaoDigital.imagensAntes = [];
        artefatosInstalacaoDigital.imagensDepois = [];
        artefatosInstalacaoDigital.sensorOutros = '';
        if(artefatosInstalacaoDigital.artefatoKitDigital.sensor && !this.SensorOptions.find(o => o.value === artefatosInstalacaoDigital.artefatoKitDigital.sensor)){
            artefatosInstalacaoDigital.sensorOutros = artefatosInstalacaoDigital.artefatoKitDigital.sensor;
            artefatosInstalacaoDigital.artefatoKitDigital.sensor = "OUTRO";
        }
        artefatosInstalacaoDigital.coletorOutros = '';
        if(artefatosInstalacaoDigital.artefatoKitDigital.coletor && !this.ColetorOptions.find(o => o.value === artefatosInstalacaoDigital.artefatoKitDigital.coletor)){
            artefatosInstalacaoDigital.coletorOutros = artefatosInstalacaoDigital.artefatoKitDigital.coletor;
            artefatosInstalacaoDigital.artefatoKitDigital.coletor = "OUTRO";
        }
        artefatosInstalacaoDigital.minaOutros = '';
        if(artefatosInstalacaoDigital.artefatoKitDigital.mina && !this.MinaOptions.find(o => o.value === artefatosInstalacaoDigital.artefatoKitDigital.mina)){
            artefatosInstalacaoDigital.minaOutros = artefatosInstalacaoDigital.artefatoKitDigital.mina;
            artefatosInstalacaoDigital.artefatoKitDigital.mina = "OUTRO";
        }
        artefatosInstalacaoDigital.kitOutros = '';
        if(artefatosInstalacaoDigital.artefatoKitDigital.kitMontagem && !this.KitOptions.find(o => o.value === artefatosInstalacaoDigital.artefatoKitDigital.kitMontagem)){
            artefatosInstalacaoDigital.kitOutros = artefatosInstalacaoDigital.artefatoKitDigital.kitMontagem;
            artefatosInstalacaoDigital.artefatoKitDigital.kitMontagem = "OUTRO";
        }
        artefatosInstalacaoDigital.instalacaoDigital.horas = 4;
        artefatosInstalacaoDigital.modelOutros = '';
        console.log(artefatosInstalacaoDigital.artefatoKitDigital.kitMontagem)
        if (artefatosInstalacaoDigital.anexoArtefatosInstalacaoList != null) {
            artefatosInstalacaoDigital.anexoArtefatosInstalacaoList.forEach(anexo => {
                if (anexo.isDocumento) {
                    artefatosInstalacaoDigital.registroVisitas.push(anexo);
                }
                else{
                    if(anexo.antes){
                        artefatosInstalacaoDigital.imagensAntes.push(anexo);
                    }
                    else{
                        artefatosInstalacaoDigital.imagensDepois.push(anexo);
                    }
                }
            });
        }
        this.state = {
            artefatosInstalacaoDigital :props.atividade,
            errors: {
                coletaIndicadoresText: '',
                tipoArranjo: ['Cadastre pelo menos um tipo.', ''],
            },
            outros: false,
            tab: 0,
        };
    };

    changeInput = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
                artefatosInstalacaoDigital[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
                artefatosInstalacaoDigital[e.target.name] = e.target.value;
            }
            this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital, errors: errors });
        }
    };

    changeInputInst = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                artefatosInstalacaoDigital.instalacaoDigital[e.target.name] = e.target.value + 'T00:00:00-03:00';
            }
            else if (e.target.name) {
                artefatosInstalacaoDigital.instalacaoDigital[e.target.name] = e.target.value;
            }
            else if (e.target.id) {
                artefatosInstalacaoDigital.instalacaoDigital[e.target.id] = e.target.value;
                
            }
            this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital, errors: errors });
        }
    };

    changeInputInstalador = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
                artefatosInstalacaoDigital.instalacaoDigital.instaladorDigital[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
                artefatosInstalacaoDigital.instalacaoDigital.instaladorDigital[e.target.name] = e.target.value;
            }
            this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital, errors: errors });
        }
    };

    changeInputKit = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                artefatosInstalacaoDigital.artefatoKitDigital[e.target.name] = e.target.value + 'T00:00:00-03:00';
            }
            else if (e.target.id) {
                console.log(e.target.value);
                artefatosInstalacaoDigital.artefatoKitDigital[e.target.name] = e.target.value;
            }
            else if (e.target.name) {
                artefatosInstalacaoDigital.artefatoKitDigital[e.target.name] = e.target.value;
            }
            this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital, errors: errors });
        }
    };

    uploadRegister = (e) => {
        this.props.showLoading();
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file && !(file.name.toLowerCase().includes('jpg') || file.name.toLowerCase().includes('jpeg') || 
            file.name.toLowerCase().includes('png') || file.name.toLowerCase().includes('pdf'))) {
            this.props.closeLoading();
            this.props.showToast('Tipo de arquivo não permitido. Anexar apenas PDF, JPG, JPEG ou PNG.', 'error');
            return;
        }
        if (file.size > 10000000) {
            this.props.closeLoading();
            this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }  
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;      
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            const anexo = {
                descricao: 'Registro de Visitas',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                isDocumento: true,
            };
            artefatosInstalacaoDigital.registroVisitas.push(anexo);
            this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        })
        
       
    };

    uploadAntes = (formData) => {
        this.props.showLoading();
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                antes: true
            };
            artefatosInstalacaoDigital.imagensAntes.push(anexo);
            await this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        });
    };

    uploadDepois = (formData) => {
        this.props.showLoading();
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                antes: false
            };
            artefatosInstalacaoDigital.imagensDepois.push(anexo);
            await this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        });
    };

    removerAnexo = (element) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        let anexos = this.state.tab === 0 ? artefatosInstalacaoDigital.imagensAntes : artefatosInstalacaoDigital.imagensDepois ;
        let index = -1;
        index = anexos.findIndex(item => ((element.id && item.id === element.id) || (item.descricao === element.descricao && item.nomeArquivo === element.nomeArquivo)));
        if (index > -1) {
            anexos.splice(index, 1); 
        }
        if(this.state.tab === 0){
            artefatosInstalacaoDigital.imagensAntes = anexos;
        }
        else{
            artefatosInstalacaoDigital.imagensDepois = anexos;
        }
        this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital });
    };

    removerRegister = (element) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;         
        let index = artefatosInstalacaoDigital.registroVisitas.findIndex(prop => {
            return (element.id && element.id === prop.id) || element.nomeArquivo === prop.nomeArquivo;
        });
        if (index !== -1) {
            artefatosInstalacaoDigital.registroVisitas.splice(index, 1);
        }
        this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital });
    };

    changeInputBool = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            
            if (e.target.name) {
                artefatosInstalacaoDigital[e.target.name] = this.str2bool(e.target.value);
            }
            this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital, errors: errors });
        }
    };

    changeTab = (value, newValue) => {
    	this.setState({ tab: newValue });
    };

    addTipoModel = () => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        const text = this.tipoModelosOptions.find(i => i.id == artefatosInstalacaoDigital.tipoModelo).label;
        let textExists = artefatosInstalacaoDigital.instalacaoDigital.coletorDigitalList.find(item => item.modelo == text);
        let outros = false;
        if(artefatosInstalacaoDigital.tipoModelo == 6){
        outros = true;
        }
        else if (!textExists) {
        artefatosInstalacaoDigital.instalacaoDigital.coletorDigitalList.push({ id: null , modelo: text });
        outros = false;
        }
        this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital, outros : outros});
    };

    addOutrosModel = () => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        const text = artefatosInstalacaoDigital.modelOutros;
        let textExists = artefatosInstalacaoDigital.instalacaoDigital.coletorDigitalList.find(item => item.modelo == text);
        let outros = false;
        if (!textExists) {
        artefatosInstalacaoDigital.instalacaoDigital.coletorDigitalList.push({ id: null , modelo: text });
        outros = false;
        }
        this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital, outros : outros});
    };

    removeTipoModel = (item) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        let index = artefatosInstalacaoDigital.instalacaoDigital.coletorDigitalList.findIndex(obj => { return obj.modelo === item.modelo; });
        if (index >= 0) {
            artefatosInstalacaoDigital.instalacaoDigital.coletorDigitalList.splice(index, 1);
            this.setState({ artefatosInstalacaoDigital: artefatosInstalacaoDigital});
        }
    };

    formatData(date) {
        if (date) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    };

    convertInfo = () => {
        return this.state.artefatosInstalacaoDigital.instalacaoDigital.coletorDigitalList.map((item, index) => (
            <Chip key={index} label={<label style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px' }} >
                <p style={{ paddingTop: '17px' }}>{item.modelo}</p></label>}
                icon={ this.props.disabled ? <></> : <Icon onClick={() => { this.removeTipoModel(item) }} className={'close'}>clear</Icon>} />
        ));
    };

    changeMetodo = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        artefatosInstalacaoDigital.metodoContagemProducao = this.metodoContagemProducaoOptions[e.id - 1].value;
        this.setState({ artefatosInstalacaoDigital: this.state.artefatosInstalacaoDigital });
    };

    changeSensor = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        artefatosInstalacaoDigital.artefatoKitDigital.sensor = this.SensorOptions[e.id - 1].value;
        this.setState({ artefatosInstalacaoDigital: this.state.artefatosInstalacaoDigital });
    };

    changeColetor = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        artefatosInstalacaoDigital.artefatoKitDigital.coletor = this.ColetorOptions[e.id - 1].value;
        this.setState({ artefatosInstalacaoDigital: this.state.artefatosInstalacaoDigital });
    };

    changeMina = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        artefatosInstalacaoDigital.artefatoKitDigital.mina = this.MinaOptions[e.id - 1].value;
        this.setState({ artefatosInstalacaoDigital: this.state.artefatosInstalacaoDigital });
    };

    changeKit = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        artefatosInstalacaoDigital.artefatoKitDigital.kitMontagem = this.KitOptions[e.id - 1].value;
        this.setState({ artefatosInstalacaoDigital: this.state.artefatosInstalacaoDigital });
    };

    changeTipoModelo = (e) => {
        let artefatosInstalacaoDigital = this.state.artefatosInstalacaoDigital;
        artefatosInstalacaoDigital.tipoModelo = e.id;
        this.setState({ artefatosInstalacaoDigital: this.state.artefatosInstalacaoDigital });
    }


    str2bool = (value) => {
        if (value && typeof value === "string") {
             if (value.toLowerCase() === "true") return true;
             if (value.toLowerCase() === "false") return false;
        }
        return value;
     }

    renderRadioButton = (property, type, label) => {        
        let val1, val2, op1, op2;
        val1 = "DR";
        val2 = "EMPRESA";
        op1 = "DR";
        op2 = "Empresa";       
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Grid container style={{ marginBottom: '1%' }} direction={'column'}>
                    <Grid item> <label> {label} </label> </Grid>
                    <Grid item className={'radioDisabled'}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name={property} value={this.state.artefatosInstalacaoDigital.artefatoKitDigital[property]} 
                            onChange={this.changeInput} row>
                                <FormControlLabel
                                    value={val1}
                                    control={<Radio color="primary" /> }
                                    label={op1}
                                    labelPlacement="end"
                                    disabled={this.props.disabled}
                                    />
                                <FormControlLabel
                                    value={val2}
                                    control={<Radio color="primary" />}
                                    label={op2}
                                    labelPlacement="end"
                                    disabled={this.props.disabled}
                                    />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )
        
        
    }

    renderBody = () => {
        let atividade = this.state.artefatosInstalacaoDigital;
        return (
            <Grid container justify={'center'} style={{ marginTop: '3%' }}>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ paddingLeft: '3%' }}>
                    <Grid item xs={12} md={4} xl={3} className={'title-label'} >
                    <div>
                    <label> Artefatos e Kit </label>
                    </div>
                    </Grid>
                                        
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginBottom: 32 }} >
                    <Grid item xs={4}  >
                        <Grid container justify={'flex-start'} direction={'row'} spacing={2} style={{ paddingLeft: '3%' }} >
                            <Grid item xs ={12}>
                                <Grid container justify={'flex-start'} direction={'row'} spacing={0} style={{ paddingLeft: '3%' }} >
                                    <Grid item xs ={12}>
                                        <div className={'div-field-btn field-label'}>
                                            <InputSelect
                                            suggestions={this.SensorOptions}
                                            label={'Artefato 1 (Sensor)'}
                                            itemKey={'id'}
                                            itemLabel={'label'}
                                            id={'tipo'}
                                            getInput={this.changeSensor}
                                            disabled={this.props.disabled}
                                            initialSelectedItem={{ id: atividade.artefatoKitDigital.sensor ? this.SensorOptions.find(o => o.value === atividade.artefatoKitDigital.sensor) ? this.SensorOptions.find(o => o.value === atividade.artefatoKitDigital.sensor).id : 4 : 1 }}/>                            
                                        </div>
                                    </Grid>
                                    {atividade.artefatoKitDigital.sensor == "OUTRO" && 
                                    <Grid item xs ={12}>
                                        <div className={'div-field-btn field-label'}>
                                            <FormField
                                            label={'Outros - Artefato 1 (Sensor)'}
                                            id={'sensorOutros'} 
                                            key={'sensorOutros'} 
                                            changeValue={this.changeInput}
                                            disabled={this.props.disabled}
                                            inputValue={atividade.sensorOutros}/>                            
                                        </div> 
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <Grid item xs={4} >
                        <Grid container justify={'flex-start'} direction={'row'} spacing={2} style={{ paddingLeft: '3%'}} >
                            <Grid item xs ={12}>
                                <Grid container justify={'flex-start'} direction={'row'} spacing={0} style={{ paddingLeft: '3%' }} >
                                    <Grid item xs ={12}>
                                        <Grid container justify={'flex-start'} direction={'row'} spacing={2} >
                                            <Grid item xs ={8}>                                
                                                <div className={'div-field-btn field-label'}>
                                                    <InputSelect
                                                    suggestions={this.ColetorOptions}
                                                    label={'Artefato 2 (Coletor)'}
                                                    itemKey={'id'}
                                                    itemLabel={'label'}
                                                    id={'tipo'}
                                                    getInput={this.changeColetor}
                                                    disabled={this.props.disabled}
                                                    initialSelectedItem={{ id: atividade.artefatoKitDigital.coletor ?  this.ColetorOptions.find(o => o.value === atividade.artefatoKitDigital.coletor) ? this.ColetorOptions.find(o => o.value === atividade.artefatoKitDigital.coletor).id : 5 : 1 }}/>                            
                                                </div>
                                            </Grid>
                                            <Grid item xs ={4}>
                                                <div className={'div-field-btn field-label'}>
                                                    <FormField type={'date'} error={''}
                                                    label={'Início da cessão'}
                                                    ref='data' id={'data'}
                                                    name = {'dataCessaoColetor'}
                                                    disabled={this.props.disabled || (atividade.artefatoKitDigital.coletor ? !(atividade.artefatoKitDigital.coletor == "AQUISICAO VIA SENAI (CESSAO 20 SEMANAS)" || atividade.artefatoKitDigital.coletor == "AQUISICAO VIA SENAI (CESSAO 12 MESES)") : true)}
                                                    inputValue={atividade.artefatoKitDigital.dataCessaoColetor ? this.formatData(atividade.artefatoKitDigital.dataCessaoColetor) : ''}
                                                    changeValue={this.changeInputKit}
                                                    icon={'calendar.svg'} />                          
                                                </div>
                                            </Grid>
                                        </Grid>                                        
                                    </Grid>
                                    {atividade.artefatoKitDigital.coletor == "OUTRO" && 
                                    <Grid item xs ={12}>
                                        <div className={'div-field-btn field-label'}>
                                            <FormField
                                            label={'Outros - Artefato 2 (Coletor)'}
                                            id={'coletorOutros'} 
                                            key={'coletorOutros'}
                                            changeValue={this.changeInput}
                                            disabled={this.props.disabled}
                                            inputValue={atividade.coletorOutros}/>                            
                                        </div> 
                                    </Grid>}
                                </Grid>
                            </Grid>                            
                        </Grid>                        
                    </Grid>
                    <Grid item xs={4} >
                        <Grid container justify={'flex-start'} direction={'row'} spacing={2} style={{ paddingLeft: '3%' }} >
                        <Grid item xs ={12}>
                                <Grid container justify={'flex-start'} direction={'row'} spacing={0} style={{ paddingLeft: '3%' }} >
                                    <Grid item xs ={12}>
                                        <Grid container justify={'flex-start'} direction={'row'} spacing={2} >
                                            <Grid item xs ={8}>
                                                <div className={'div-field-btn field-label'}>
                                                    <InputSelect
                                                    suggestions={this.MinaOptions}
                                                    label={'Artefato 3 (Plataforma IOT)'}
                                                    itemKey={'id'}
                                                    itemLabel={'label'}
                                                    id={'tipo'}
                                                    getInput={this.changeMina}
                                                    disabled={this.props.disabled}
                                                    initialSelectedItem={{ id: atividade.artefatoKitDigital.mina ?  this.MinaOptions.find(o => o.value === atividade.artefatoKitDigital.mina) ? this.MinaOptions.find(o => o.value === atividade.artefatoKitDigital.mina).id : 3 : 1 }}/>                            
                                                </div>
                                            </Grid>
                                            <Grid item xs ={4}>
                                                <div className={'div-field-btn field-label'}>
                                                    <FormField type={'date'} error={''}
                                                    label={'Início da cessão'}
                                                    ref='data' id={'data'}
                                                    name = {'dataCessaoMina'}
                                                    disabled={this.props.disabled || (atividade.artefatoKitDigital.mina ? !(atividade.artefatoKitDigital.mina == "AQUISICAO VIA SENAI (LICENCA DE 20 SEMANAS)" || atividade.artefatoKitDigital.mina == "AQUISICAO VIA SENAI (LICENCA DE 12 MESES)") : true)}
                                                    inputValue={atividade.artefatoKitDigital.dataCessaoMina ? this.formatData(atividade.artefatoKitDigital.dataCessaoMina) : ''}
                                                    changeValue={this.changeInputKit}
                                                    icon={'calendar.svg'} />                          
                                                </div>
                                            </Grid>
                                        </Grid>                                        
                                    </Grid>
                                    {atividade.artefatoKitDigital.mina == "OUTRO" && 
                                    <Grid item xs ={12}>
                                        <div className={'div-field-btn field-label'}>
                                        <FormField
                                            label={'Outros - Artefato 3 (Plataforma IOT)'}
                                            id={'minaOutros'} 
                                            key={'minaOutros'}
                                            changeValue={this.changeInput}
                                            disabled={this.props.disabled}
                                            inputValue={atividade.minaOutros}/>                           
                                        </div> 
                                    </Grid>}
                                </Grid>
                            </Grid>
                            
                        </Grid>                        
                    </Grid>                  
                   
                </Grid>
                
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginBottom: 32 }} >
                    <Grid item xs={4} >
                        <Grid container justify={'flex-start'} direction={'row'} spacing={2} style={{ paddingLeft: '3%' }} >
                            <Grid item xs ={12}>
                                <Grid container justify={'flex-start'} direction={'row'} spacing={0} style={{ paddingLeft: '3%' }} >
                                    <Grid item xs ={12}>
                                    <div className={'div-field-btn field-label'}>
                                        <InputSelect
                                        suggestions={this.KitOptions}
                                        label={'Kit de Montagem'}
                                        itemKey={'id'}
                                        itemLabel={'label'}
                                        id={'tipo'}
                                        getInput={this.changeKit}
                                        disabled={this.props.disabled}
                                        initialSelectedItem={{ id: atividade.artefatoKitDigital.kitMontagem ?  this.KitOptions.find(o => o.value === atividade.artefatoKitDigital.kitMontagem)  ? this.KitOptions.find(o => o.value === atividade.artefatoKitDigital.kitMontagem).id : 6 : 1 }}/>                            
                                    </div> 
                                    </Grid>
                                    {atividade.artefatoKitDigital.kitMontagem == "OUTRO" && 
                                    <Grid item xs ={12}>
                                        <div className={'div-field-btn field-label'}>
                                            <FormField
                                            label={'Outros - Kit de Montagem'}
                                            id={'kitOutros'} 
                                            key={'kitOutros'} 
                                            changeValue={this.changeInput}
                                            inputValue={atividade.kitOutros}
                                            disabled={this.props.disabled}/>                          
                                        </div> 
                                    </Grid>}
                                </Grid>
                            </Grid>
                        </Grid>
                                          
                    </Grid>
                    <Grid item xs={4} >
                        <Grid container style={{ marginBottom: '1%' }} direction={'column'}>
                            <div className={'div-field-btn field-label'}>
                            <Grid item> <label> Em que local o kit foi montado? </label> </Grid>
                            </div>
                            <Grid item className={'radioDisabled'}>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="position" name={'local'} value={atividade.artefatoKitDigital.local } 
                                    onChange={this.changeInputKit} row>
                                        <FormControlLabel
                                            value={'DR'}
                                            control={<Radio color="primary" /> }
                                            label={'DR'}
                                            labelPlacement="end"
                                            disabled={this.props.disabled}
                                            />
                                        <FormControlLabel
                                            value={"EMPRESA"}
                                            control={<Radio color="primary" />}
                                            label={"Empresa"}
                                            labelPlacement="end"
                                            disabled={this.props.disabled}
                                            />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                             
                        </Grid>     
                    </Grid>
                    <Grid item xs={4} >
                        <div className={'div-field-btn field-label'} >
                            <InputSelect
                                suggestions={this.tipoModelosOptions}
                                label={'Informe o modelo do coletor instalado (um ou mais)'}
                                itemKey={'id'}
                                itemLabel={'label'}
                                id={'tipo'}
                                getInput={this.changeTipoModelo}
                                disabled={this.props.disabled}
                                initialSelectedItem={{ id: atividade.tipoModelo }} />
                            <GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'plus.svg'} click={this.addTipoModel} />
                        </div>
                        {this.state.outros &&
                        <div className={'div-field-btn field-label'} >
                        <FormField
                            label={'Outros - Modelo de coletor'}
                            id={'modelOutros'} 
                            key={'modelOutros'}
                            changeValue={this.changeInput}
                            inputValue={atividade.modelOutros} />
                        <GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'plus.svg'} click={this.addOutrosModel} />
                        </div>
                        }
                        <div>
                            { this.convertInfo() }
                        </div> 
                    </Grid>                  
                   
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginBottom: 32 }} >
                    <Grid item xs={4}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                type={'number'} 
                                id={'valorTotal'} 
                                key={'valorTotal'}
                                min='0' 
                                maxLength='15'
                                subtype={'duascasas'} 
                                label={'Informe o valor total da consultoria (mesmo valor do termo de aceite)'} 
                                changeValue={this.changeInputInst} 
                                inputValue={atividade.instalacaoDigital.valorTotal}
                                disabled={this.props.disabled}/>                               
                            </div>               
                    </Grid>                
                   
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginBottom: 32 , borderTop: '1px solid #E1E3E6'}} >
                    <Grid item xs={4}>
                            <div className={'div-field-btn field-label'}>
                                <FormField 
                                type={'number'}
                                id={'pontosColeta'} 
                                key={'pontosColeta'}
                                subtype={'inteiro'} 
                                label={'Nº pontos de coleta'} 
                                changeValue={this.changeInputInst} 
                                disabled={this.props.disabled}
                                inputValue={atividade.instalacaoDigital.pontosColeta}/>                               
                            </div>               
                    </Grid>
                    <Grid item xs={4}>
                            <div className={'div-field-btn field-label'}>
                                <FormField 
                                type={'number'}
                                id={'numeroSensores'} 
                                key={'numeroSensores'} 
                                label={'Nº de sensores'}
                                subtype={'inteiro'} 
                                disabled={this.props.disabled}
                                changeValue={this.changeInputInst} 
                                inputValue={atividade.instalacaoDigital.numeroSensores}/>                               
                            </div>               
                    </Grid> 
                    <Grid item xs={4}>
                            <div className={'div-field-btn field-label'}>
                                <FormField 
                                type={'number'}
                                id={'numeroColetores'} 
                                key={'numeroColetores'} 
                                label={'Nº de coletores'}
                                subtype={'inteiro'} 
                                disabled={this.props.disabled}
                                changeValue={this.changeInputInst} 
                                inputValue={atividade.instalacaoDigital.numeroColetores}/>                               
                            </div>               
                    </Grid>                 
                   
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginBottom: 32 }} >
                    <Grid item xs={4} >
                            <div className={'div-field-btn field-label'}>
                                <FormField 
                                type={'textarea'}
                                placeholder={'Informadas pelo instalador'}
                                id={'observacoesInfraestrutura'} 
                                key={'observacoesInfraestrutura'}
                                label={'Observações sobre infraestrutura:'} 
                                disabled={this.props.disabled}
                                subClass={'double-height'}
                                changeValue={this.changeInputInst} 
                                inputValue={atividade.instalacaoDigital.observacoesInfraestrutura}/>                               
                            </div>               
                    </Grid>                
                   
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ marginTop: '2%', borderTop: '1px solid #E1E3E6', paddingLeft: '3%' }}>
                    <Grid item xs={12} md={4} xl={3} className={'title-label'} >
                    <div>
                    <label> Instalação </label>
                    </div>
                    </Grid>            
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginBottom: 32 }} >
                    <Grid item xs={3} style={{ display: 'flex' }}>
                        <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                        <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/calendar-brasilmais.svg'} width={30} height={30} style={{marginRight: '10px', marginBottom:'10px'}} />
                        </div>
                        <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                        <FormField type={'date'} 
                            ref='data' id={'data'}
                            name = {'dataInstalacao'}
                            placeholder={'Data da visita'}
                            disabled={this.props.disabled}
                            inputValue={atividade.instalacaoDigital.dataInstalacao ? this.formatData(atividade.instalacaoDigital.dataInstalacao) : ''}
                            changeValue={this.changeInputInst}
                            icon={'calendar.svg'} />
                        </div> 
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                        <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                        <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/instalador.svg'} width={30} height={30} style={{marginRight: '10px', marginBottom:'10px'}} />
                        </div>
                        <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                        <FormField 
                            id={'nome'} 
                            key={'nome'} 
                            disabled={this.props.disabled}
                            placeholder={'Nome completo'}
                            inputValue={atividade.instalacaoDigital.instaladorDigital.nome}
                            changeValue={this.changeInputInstalador}
                            label={'Instalador'} />
                        </div>

                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                        <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                        <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/mail.svg'} width={30} height={30} style={{marginRight: '10px', marginBottom:'10px'}} />
                        </div>
                        <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                        <FormField 
                            id={'email'} 
                            key={'email'} 
                            disabled={this.props.disabled}
                            placeholder={'E-mail'}
                            inputValue={atividade.instalacaoDigital.instaladorDigital.email}
                            changeValue={this.changeInputInstalador}
                            label={'Instalador'} />
                        </div>
                    </Grid>
                    <Grid item xs={3} style={{ display: 'flex' }}>
                        <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                        <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/timer-brasilmais.svg'} width={30} height={30} style={{marginRight: '10px', marginBottom:'10px'}} />
                        </div>
                        <div className={'div-field-btn field-label'} style={{ width: '100%' }}>
                        <FormField 
                            id={'horas'} 
                            key={'horas'} 
                            disabled={true}
                            placeholder={'Horas (4h)'}
                            inputValue={atividade.instalacaoDigital.horas}
                            changeValue={this.changeInputInstalador}
                            label={'Horas (4h)'} />
                        </div>
                    </Grid> 
                </Grid>
                <Grid container justify={'flex-start'} style={{ marginTop: '2%', borderTop: '1px solid #E1E3E6', paddingLeft: '3%' }} >
                    <Grid item xs={4} xl={3} className={'anexos-visitas-box-top'} >
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', paddingTop: '10%' }}>
                            <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/relatorio-brasilmais.svg'} width={30} height={30} />
                            <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>{'Registro de Visitas > Instalador'}</span>
                        </Grid>
                        <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                            <label
                                className={'lightBlue basic-button control-label'}
                                id="upload-atividade"
                                htmlFor="uploadfile-listapresenca"
                                style={{opacity: this.props.disabled ? 0.4 : 1,
                                        cursor: this.props.disabled ? 'not-allowed': 'cursor'}}>Anexar
                            </label>
                            <input
                                className={'file-input listaPresenca'}
                                type="file"
                                id="uploadfile-listapresenca"
                                name="file"
                                value={undefined}
                                style={{opacity : this.props.disabled ? 0.5 : 1 }}
                                disabled={this.props.disabled}
                                onChange={(element) => { this.uploadRegister(element) }}
                                 />
                        </Grid>
                        <Grid>
                            {atividade.registroVisitas.length > 0 ?
                                atividade.registroVisitas.map(
                                    (anexo, index) => (
                                        <Chip key={index}
                                            label={<label style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                                                <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                <p>{anexo.nomeArquivo}</p></label>}
                                            icon={ this.props.disabled ? <></> : <Icon onClick={() => this.removerRegister(anexo)} className={'close'}>clear</Icon>}
                                        />
                                    )) : <></>}
                            
                        </Grid>
                    </Grid>                 
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ marginTop: '2%', borderTop: '1px solid #E1E3E6', paddingLeft: '3%' }}>
                    <Grid item xs={12} md={4} xl={3} className={'title-label'} >
                    <div>
                        <label> Imagens do local da instalação </label>
                    </div>
                    </Grid>            
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ marginTop: '2%', paddingLeft: '3%' }}>
                    <Grid item className='tabs' xs={12}>
                        <AppBar position='static'>
                            <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                                <Tab key={'Tab0'} value={0} label={'Antes'}></Tab>
                                <Tab key={'Tab1'} value={1} label={'Depois'}></Tab>
                            </Tabs>
                        </AppBar>
                    </Grid>            
                </Grid>
                
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ marginTop: '2%', paddingLeft: '3%' }}>
                    { this.state.tab === 0 && 
                        <Grid item xs={12} >
                            <Grid container justify={'center'} style={{ display: 'flex', alignItems: 'flex-end' }}>
                            
                                <Grid item xs={12} style={{paddingBottom: 'unset'}}>
                                    <Grid container direction={'row'} style={{ display: 'flex', justifyContent: 'flex-end' }}>                                        
                                        {!this.props.disabled && <UploadTopButtonsDigital uploadFile={ this.uploadAntes } showToast={ this.props.showToast } /> }                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <AnexosDefault
                                        showEmptyEvidencias={ true }
                                        title='Artefatos, Kit & Instalação'
                                        anexos={ atividade.imagensAntes }
                                        title={this.props.title}
                                        fase={this.props.fase}
                                        turma={this.props.turma}
                                        disableForm={this.props.disabled}
                                        removerAnexo={(element) => this.removerAnexo(element)}
                                        darkBorder={ true }
                                        msg={'Você ainda não anexou evidências.'} />
                                </Grid> 
                            </Grid>
                        </Grid> }
                    { this.state.tab === 1 && 
                        <Grid item xs={12}>
                            <Grid container justify={'center'} style={{ display: 'flex', alignItems: 'flex-end' }}>
                            
                                <Grid item xs={12} style={{paddingBottom: 'unset'}}>
                                    <Grid container direction={'row'} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        {!this.props.disabled && <UploadTopButtonsDigital uploadFile={ this.uploadDepois } showToast={ this.props.showToast } />} 
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <AnexosDefault
                                        showEmptyEvidencias={ true }
                                        title='Artefatos, Kit & Instalação'
                                        anexos={ atividade.imagensDepois }
                                        fase={this.props.fase}
                                        turma={this.props.turma}
                                        removerAnexo={(element) => this.removerAnexo(element)}
                                        darkBorder={ true }
                                        disableForm={this.props.disabled}
                                        msg={'Você ainda não anexou evidências.'} />
                                </Grid> 
                            </Grid>
                        </Grid> }
                </Grid>
                
            </Grid>
        )
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao-digital'} style={{ marginTop: '30px' }}>
                { this.renderBody() }
            </Grid>
        )
    }

}