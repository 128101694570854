import React, { Component } from 'react'
import GenericButton from './../../components/genericButton/genericButton'
import { Grid } from "@material-ui/core";
import FormField from './../../components/formField/formField';
import Table from './../../components/table/table'
import API from './../../comm/API';
import Page from './../../components/page/page';
import { Link } from 'react-router-dom';
import EmptyList from '../../components/emptyList/emptyList';

export default class Unidade extends Component {
    //titles = ['Descrição', 'Abreviação', 'Município', 'UF', 'Tipo', 'Especialidade'];
    
    titles = [
        { id: '0', canOrder: true, label: 'Id' },
        { id: '1', canOrder: true, label: 'Descrição' },
        { id: '2', canOrder: true, label: 'Abreviação' },
        { id: '3', canOrder: true, label: 'Município' },
        { id: '4', canOrder: true, label: 'UF' },
        { id: '5', canOrder: true, label: 'Tipo' },
        { id: '6', canOrder: true, label: 'Especialidade'},
        { id: '7', canOrder: false, label: ''}]

    lines = [];

    state = { lines: this.lines, titles: this.titles, types: this.types, filtroTexto: '', emptyList: 'seeResults' };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    listarUnidades = async () => {
        this.showLoading();
        await API.get(this.getUrl()).then(res => {
            let lines = this.transformarResponse(res);
            this.setState({ lines: lines });
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            this.showToast('Infelizmente houve um erro ao listar as unidades. Por favor entre em contato com o suporte.', 'error');
        });
    }

    getUrl = () => {
        if (this.state.filtroTexto === '') {
            return `rest/gestao/Unidade`;
        }
        return `rest/gestao/Unidade?buscaLivre=${this.state.filtroTexto}`;
    }

    transformarResponse = (res) => {
        let filtrado = [];
        res.data.forEach((unidade) => {
            let linha = ['', '', '', '', '', '',''];
            linha[0] = unidade.id;
            if (unidade.descricao) linha[1] = unidade.descricao;
            if (unidade.abreviacao) linha[2] = unidade.abreviacao;
            if (unidade.municipio && unidade.municipio.descricao) linha[3] = unidade.municipio.descricao;
            if (unidade.municipio && unidade.municipio.unidadeFederativa && unidade.municipio.unidadeFederativa.sigla) linha[4] = unidade.municipio.unidadeFederativa.sigla;
            if (unidade.tipoUnidade && unidade.tipoUnidade.descricao) linha[5] = unidade.tipoUnidade.descricao;
            if (unidade.especialidadeUnidade && unidade.especialidadeUnidade.descricao) linha[6] = unidade.especialidadeUnidade.descricao;
            linha.push(
                <div style={{display: 'flex'}}>
                <Link to={`/cad/pessoa/unidade/novo/${unidade.id}`}>
                    <GenericButton color={'transparent'} subClass={'pen icon-button'} icon={process.env.PUBLIC_URL + 'pen.svg'} />
                </Link>
            </div>
            )
            filtrado.push(linha);
        });
        return filtrado;
    }

    
    render() {
        return (
            <div>
                <Page icon={'config2.svg'} label={'Unidade'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                    <Grid container >
                        <Grid item xs={12} className={'pesquisa'}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} xl={5} className={'grid-left-button'}>
                                    <Link to={'unidade/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Nova Unidade'} subClass={'basic-button'} />
                                    </Link>
                                    <FormField type={'text'} pressEnter={this.listarUnidades} placeholder={'busque por nome, descrição, município ou UF'} 
                                        button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                        icon={'search.svg'}
                                        click={this.listarUnidades} />}
                                        inputValue={this.state.filtroTexto} 
                                        changeValue={(e) => { this.setState({ filtroTexto: e.target.value }); }} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Table lines={this.state.lines} titles={this.state.titles} initialOrder={'1'}/>
                            <EmptyList type={this.state.emptyList} seeAll={this.listarUnidades}/>
                        </Grid>
                    </Grid>
                </Page>

            </div>
        );
    }
}