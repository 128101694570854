import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import GenericButton from '../../components/genericButton/genericButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import AnexosDefault from './anexosDefault';
import UploadTopButtons from './uploadTopButtons';
import API from '../../comm/API';

export default class AtividadeDesperdicio extends Component {

    tipoDesperdicio = [
        { codDesperdicio: 1, descricao: 'Defeito' },
        { codDesperdicio: 2, descricao: 'Movimentação' },
        { codDesperdicio: 3, descricao: 'Superprodução' },
        { codDesperdicio: 4, descricao: 'Processamento' },
        { codDesperdicio: 5, descricao: 'Espera' },
        { codDesperdicio: 6, descricao: 'Intelectual' },
        { codDesperdicio: 7, descricao: 'Transporte' },
        { codDesperdicio: 8, descricao: 'Estoque' }
    ];

	constructor(props) {
        super(props);
        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let disableEditAntes = etapa.etapa >= 8; 
        let tab = !disableEditAntes ? 'Antes' : 'Depois';
        let formAntes = tab === 'Antes' ? props.atividade : turma.etapaPraticaB.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).desperdicio; //sempre mentoria prática b
        let formDepois = tab === 'Depois' ? props.atividade : turma.etapaPraticaD.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).desperdicio; //sempre mentoria prática d
        this.state = { tab: tab, botaoSelecionadoAntes: undefined, botaoSelecionadoDepois: undefined, disableDepois: turma.etapaAtual < 8, disableEditAntes: disableEditAntes, formAntes: formAntes, formDepois: formDepois };
    };

    changeTab = (value, newValue) => {
        this.setState({ tab: newValue });
    };

    changeBotao = (botao) => {
        this.props.showLoading();
        if (this.state.tab === 'Antes') this.setState({ botaoSelecionadoAntes: botao }, this.props.closeLoading());
        else this.setState({ botaoSelecionadoDepois: botao }, this.props.closeLoading());
    };

    uploadFile = (formData) => {
        this.props.showLoading();
        API.post(`rest/upload/anexoTurma/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let botaoSelecionado = this.state.tab === 'Antes' ? this.state.botaoSelecionadoAntes : this.state.botaoSelecionadoDepois;
            let tipo = this.tipoDesperdicio.find(tipo => { return tipo.descricao === botaoSelecionado.id });
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
                codDesperdicio: tipo.codDesperdicio
            };
            botaoSelecionado.anexos.push(anexo);
            if (this.state.tab === 'Antes') await this.setState({ botaoSelecionadoAntes: botaoSelecionado });
            else await this.setState({ botaoSelecionadoDepois: botaoSelecionado });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        });
    };

    removerAnexo = (element) => {
        let botaoSelecionado = this.state.tab === 'Antes' ? this.state.botaoSelecionadoAntes : this.state.botaoSelecionadoDepois;
        let anexos = botaoSelecionado.anexos;
        let index = -1;
        index = anexos.findIndex(item => ((element.id && item.id === element.id) || (item.descricao === element.descricao && item.nomeArquivo === element.nomeArquivo)));
        if (index > -1) {
            anexos = anexos.splice(index, 1);
        }
        if (this.state.tab === 'Antes') this.setState({ botaoSelecionadoAntes: botaoSelecionado });
        else this.setState({ botaoSelecionadoDepois: botaoSelecionado });
    };

    renderBody = () => {
        let atividade = this.state.tab === 'Antes' ? this.state.formAntes : this.state.formDepois;
        let botaoSelecionado = this.state.tab === 'Antes' ? this.state.botaoSelecionadoAntes : this.state.botaoSelecionadoDepois;
        let botoes = [{ id: 'Defeito', anexos: atividade.defeitoList }, { id: 'Movimentação', anexos: atividade.movimentacaoList }, 
            { id:'Superprodução', anexos: atividade.superProducaoList }, { id: 'Processamento', anexos: atividade.processamentoList }, 
            { id: 'Espera', anexos: atividade.esperaList }, { id: 'Intelectual', anexos: atividade.intelectualList }, 
            { id: 'Transporte', anexos: atividade.transporteList }, { id: 'Estoque', anexos: atividade.estoqueList }];
        return (
            <Grid container justify={'center'} spacing={1} className={'grid-body'}>
                { botoes.map((botao, index) => {
                    return (
                        <Grid item key={'botao-' + botao.id}>
                            { index === 0 && <label className={'label-botoes'}>Clique no tipo de desperdício</label> }
                            <GenericButton color={botaoSelecionado && botaoSelecionado === botao.id ? 'darkGrey' : 'lightBlue'} subClass={'basic-button botao-desperdicio'} label={botao.id + (botao.anexos.length > 0 ? ' (' + botao.anexos.length + ')' : '')} 
                                click={() => this.changeBotao(botao) } style={{width: '100%'}} />
                        </Grid>
                    )
                })}
                { botaoSelecionado && 
                    <Grid item xs={12} style={{paddingBottom: 'unset', paddingTop: '50px'}}>
                        <Grid container>
                            <Grid item xs={12} md={6} style={{ paddingTop: '15px' }}>
                                <label className={'label-botao'}>{botaoSelecionado.id}</label>
                            </Grid>
                            { this.props.disabled || (this.state.tab === 'Antes' && this.state.disableEditAntes) || (this.state.tab === 'Depois' && !this.state.disableEditAntes) ?
                                <Grid item md={6} style={{ margin: '8px 0px', height: '30px' }}></Grid> :
                                <UploadTopButtons uploadFile={ this.uploadFile } showToast={ this.props.showToast } /> }
                        </Grid>
                    </Grid> }
                { botaoSelecionado && 
                    <Grid item xs={12}>
                        <AnexosDefault
                            disableForm={this.props.disabled || (this.state.tab === 'Antes' && this.state.disableEditAntes) || (this.state.tab === 'Depois' && !this.state.disableEditAntes) }
                            showEmptyEvidencias={ true }
                            anexos={ botaoSelecionado.anexos }
                            removerAnexo={(element) => this.removerAnexo(element)}
                            darkBorder={ true }
                            msg={'Você ainda não anexou evidências.'}
                            turma={this.props.turma}
                            fase={this.props.fase}
                            title='Desperdícios' />
                    </Grid> }
            </Grid>
        );
    };

    render() {
        return (
            <Grid container className={'atividade-desperdicio'} style={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                    <AppBar position='static'>
                        <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                            <Tab key={'Antes'} value={'Antes'} label={'Antes'} disabled={false || !this.state.formAntes}></Tab>
                            <Tab key={'Depois'} value={'Depois'} label={'Depois'} disabled={this.state.disableDepois || !this.state.formDepois}></Tab>
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item xs={12}>
                    { this.renderBody() }
                </Grid>
            </Grid>
        )
    };

}