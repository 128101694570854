import { TipoInputEnum } from "./tipoInput";

export const indicadorTipoInput = [
    { id: 31, tipo: TipoInputEnum.SmartFactory },
    { id: 32, tipo: TipoInputEnum.NivelTransformacao },
    { id: 33, tipo: TipoInputEnum.NivelTransformacao },
    { id: 34, tipo: TipoInputEnum.NivelTransformacao },
    { id: 35, tipo: TipoInputEnum.NivelTransformacao },
    { id: 36, tipo: TipoInputEnum.NivelTransformacao },
    { id: 37, tipo: TipoInputEnum.NivelTransformacao },
    { id: 38, tipo: TipoInputEnum.NivelTransformacao },
    { id: 39, tipo: TipoInputEnum.NivelTransformacao },
    { id: 40, tipo: TipoInputEnum.NivelTransformacao },
    { id: 41, tipo: TipoInputEnum.NivelTransformacao },
    { id: 42, tipo: TipoInputEnum.NivelTransformacao },
    { id: 43, tipo: TipoInputEnum.NivelTransformacao },
    { id: 44, tipo: TipoInputEnum.NivelTransformacao },
    { id: 45, tipo: TipoInputEnum.NivelTransformacao },
    { id: 46, tipo: TipoInputEnum.NivelTransformacao },
    { id: 47, tipo: TipoInputEnum.NivelTransformacao },
    { id: 48, tipo: TipoInputEnum.PrioridadeTransformacao },
    { id: 49, tipo: TipoInputEnum.FaixaTransformacao },
    { id: 50, tipo: TipoInputEnum.IncluiTransformacao },
    { id: 51, tipo: TipoInputEnum.IncluiTransformacao },
    { id: 52, tipo: TipoInputEnum.IncluiTransformacao },
    { id: 53, tipo: TipoInputEnum.TecnologiaTransformacao },
    { id: 54, tipo: TipoInputEnum.InvestimentoTransformacao },
    { id: 55, tipo: TipoInputEnum.Aprovacao },
    { id: 56, tipo: TipoInputEnum.Cnpj },
    { id: 57, tipo: TipoInputEnum.Cnpj },
];