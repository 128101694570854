import React, { Component } from 'react';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import GenericButton from '../genericButton/genericButton';
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Icon from '@material-ui/core/Icon';

export default class InputMany extends Component {

    state = {selectedItem: this.setInitialSelectedItens(), value:'' };

    constructor(props) {
        super(props);
        this.addItens = this.addItens.bind(this);
    }
 
    async removeItens(item) {
        let array = this.state.selectedItem.filter((it) => {
            return item !== it;
        });
        await this.setState({ selectedItem: array });    
        this.props.getInput(this.state.selectedItem);
    }

    setInitialSelectedItens() {
        let array = [];
        if (this.props.initialSelectedItensList) {
            this.props.initialSelectedItensList.forEach(element => {
                array.push(element);
            })
        }
        this.props.getInput(array);
        return array;
    }

    renderChipList() {

        if (this.state.selectedItem) {
            return (
                <div>
                    {this.state.selectedItem.length > 0 &&
                        this.state.selectedItem.map(item => (
                            <Chip 
                            key={item} 
                            label={item.toUpperCase()} 
                            icon={this.props.disabled ? null : <Icon onClick={() => this.removeItens(item)} className={'close'}>clear</Icon>} />
                        ))}
                </div>
            );
        } else {
            return (<div></div>);
        }

    }

    async addItens() {
        
        if (this.state.value) {
            let isIn = this.state.selectedItem.filter((item) => {
                 return this.state.value === item;
            });

            if (isIn.length === 0) {
                await this.setState(({ selectedItem }) => ({
                    selectedItem: [...selectedItem, this.state.value]
                }));

                this.props.getInput(this.state.selectedItem);
            }
            this.setState({value:''});
        }
    }

    renderInput(inputProps) {
        const { InputProps, classes, ref, ...other } = inputProps;
        return (
            <TextField
                InputProps={{
                    inputRef: ref,
                    disabled: this.props.disabled,
                    classes: {
                    },
                    ...InputProps,
                }}
                {...other} />
        );
    };

    get erroFlag() {
        return (this.props.error === undefined || this.props.error === '' ? 'none' : 'inline')
    }

    get warningFlag() {
        return (this.props.warning === undefined || this.props.warning === '' ? 'none' : 'inline')
    }

    submitForm = (e) => {
        if (e.charCode === 13 && e.type === 'keypress' && this.props.pressEnter != null) 
            this.props.pressEnter();
    };

    render() {
        return (
            <div className={'auto-complete-many'} >
                <label>{this.props.label}</label>
                <Grid container>
                    <Grid item xs={12} className={'auto-complete-field'}>
                        <Downshift disableUnderline={true} selectedItem={this.state.value} id="downshift-options" >
                            {({
                                clearSelection,
                                getInputProps,
                                getItemProps,
                                getLabelProps,
                                getMenuProps,
                                highlightedIndex,
                                stateChangeTypes,
                                inputValue,
                                isOpen,
                                openMenu,
                                selectedItem
                            }) => {
                                const { onBlur, onChange, onFocus, onKeyPress, ...inputProps } = getInputProps({
                                    placeholder: this.props.placeholder,
                                    onChange: async (event) => {
 
                                        event.persist();
                                        await this.setState({ value: event.target.value.toUpperCase() });
                                      
                                    },
                                    onFocus: openMenu,
                                    onKeyPress: (e) => {
                                        if (e.charCode === 13 && e.type === 'keypress') 
                                            this.addItens();
                                    }
                                });

                                return (
                                    <div >
                                        {this.renderInput({
                                            fullWidth: true,
                                            InputLabelProps: getLabelProps({ shrink: true }),
                                            InputProps: { onBlur, onChange, onFocus, onKeyPress },
                                            inputProps
                                        })}

                                    </div>
                                );
                            }}
                        </Downshift>
                        <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addItens} icon={'plus.svg'} disabled={this.props.disabled} />
                    </Grid>
                    <Grid item xs={12}>
                        <br></br>
                        <p className={'chip-label'}>{this.props.chipLabel}</p>
                        {!this.props.noChipLabel && this.renderChipList()}
                        <span style={{ display: this.erroFlag }}>{this.props.error}</span>
                        <span style={{ display: this.warningFlag }}>{this.props.warning}</span>
                    </Grid>
                </Grid>
            </div>

        )
    }
}