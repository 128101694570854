import { Grid } from '@material-ui/core';
import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import Page from '../../components/page/page';
import Stepper from '../../components/stepper/stepper';
import API, { BASE_URL } from './../../comm/API';
import { SyncRequest } from './../../comm/APIsync';
import InputSelect from './../../components/inputselect/inputselect';
import './produtoRegionalNovo.scss';

export default class ProdutoRegionalNovo extends Component {

	produto = {};
	linha = { descricao: '' };
	categoria = { descricao: '' };
	linhas = [];
	categorias = [];
	produtosNacionais = [];
    titles = ['Dados do Produto Regional', 'Produto Nacional associado', 'Revisão'];
    ativoList = [{type: 'isAtivo', label: 'ATIVO', valor: true}, {type: 'isAtivo', label: 'INATIVO', valor: false}];
    errors = { nome: {error: ''}, CR: {error: ''}, unidade: {error: ''}, produtoNacional: {error: ''}, isAtivo: {error: ''} };
    
    constructor(props) {
        super(props);
        this.state = { unidades: [], produtoNacionalList: [], produtoLinhaList: [], produtoCategoriaList: [] };   
        this.produto = this.produtoRegionalModel(); 
        if (props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN') {
	        API.get('rest/gestao/Unidade/drsecni?id=29').then(res => { 
	            this.setState({ unidades: res.data });
	        }).catch(error => {
                this.showToast('Erro ao realizar GET das unidades.', 'error');
	        });
	    } else if (props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR') {
	    	this.state = { ...this.state, unidades: [props.usuarioConectado.unidade] };
	    	this.produto.unidade = props.usuarioConectado.unidade;
	    } else if (props.usuarioConectado.unidade.tipoUnidade.sigla === 'UO') {
	    	this.state = { ...this.state, unidades: [props.usuarioConectado.unidade.unidadePai] };
	    	this.produto.unidade = props.usuarioConectado.unidade.unidadePai;
	    }
        API.get('rest/auxiliar/ProdutoNacional?isAtivo=true&ordenarPor=descricao').then(res => {
        	this.produtosNacionais = res.data;
            this.setState({ produtoNacionalList: res.data });
        }).catch(error => {
            this.showToast('Error ao realizar GET dos produtos nacionais.', 'error');
        });
        API.get('rest/auxiliar/ProdutoLinha?isVisivel=true&ordenarPor=descricao').then(res => {
        	this.linhas = res.data;
            this.setState({ produtoLinhaList: res.data });
        }).catch(error => {
            this.showToast('Error ao realizar GET das linhas.', 'error');
        });
        API.get('rest/auxiliar/ProdutoCategoria?isVisivel=true&ordenarPor=descricao').then(res => {
        	this.categorias = res.data;
            this.setState({ produtoCategoriaList: res.data });
        }).catch(error => {
            this.showToast('Error ao realizar GET das linhas.', 'error');
        });
        if (props.idProdutoRegional != null && !isNaN(props.idProdutoRegional)) { //atualização
        	API.get('rest/auxiliar/ProdutoRegional/' + props.idProdutoRegional).then(res => {
        		this.produto = res.data;
        		this.linha = res.data.produtoNacional.produtoCategoria ? res.data.produtoNacional.produtoCategoria.produtoLinha : {};
        		this.categoria = res.data.produtoNacional.produtoCategoria;
	        }).catch(error => {
                this.showToast('Error ao realizar GET do produto regional.\nPor favor, entre em contato com o suporte.', 'error');
	        });          
        }
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    produtoRegionalModel() {
    	return { id: null, CR: '', nome: '', produtoNacional: {}, unidade: {}, isAtivo: true, motivo: '' };
    }; 

    validateProduto = (step) => {
        switch (step) {
            case 0:
                return this.produto.CR && this.produto.nome && this.produto.unidade.id && this.produto.isAtivo !== null;
            case 1:
            	return this.produto.produtoNacional.id; 
            default:
                return true;
        }
    };

    changeInput = (e) => {
        if (e != null && e.target != null) {
            this.produto[e.target.id] = e.target.value;
            if (e.target.value == null || e.target.value === '') this.errors[e.target.id].error = 'Campo obrigatório';
            else this.errors[e.target.id].error = '';
        } else if (e != null && e.type != null) {
            this.produto[e.type] = e.id != null ? e : e.valor;
            this.errors[e.type].error = '';
        } 
        this.setState(this.state);
    };

    nomeRepetido = () => {
    	var nome = this.produto.nome.toUpperCase().replace(/ /g, '+');
    	var unidade = this.produto.unidade.id;
    	var url = 'rest/auxiliar/ProdutoRegional?nome=' + nome + '&unidade.id=' + unidade;
        if (this.produto.id != null) url = 'rest/auxiliar/ProdutoRegional?nome=' + nome + '&unidade.id=' + unidade + '&id=notEquals(' + this.produto.id + ')';
        var res = SyncRequest('get', BASE_URL + url);
        if (res != null && res.length > 0) return true;
        else return false;
    };

    salvarProduto = () => {
        this.showLoading();
        this.produto.nome = this.produto.nome.toUpperCase();
        this.produto.CR = this.produto.CR.toUpperCase();
        if (this.props.usuarioConectado.unidade.tipoUnidade.sigla !== 'UO') {
        	if (!this.nomeRepetido() && this.produto.id == null) { //cadastro
	            API.post('rest/auxiliar/ProdutoRegional', this.produto).then(res => {
                    this.closeLoading();
                    this.showToast('Produto Regional cadastrado com sucesso!', 'success', '/cad/atendimento/produtoRegional');
	            }).catch(error => {
                    this.closeLoading();
                    this.showToast('Error ao cadastrar produto regional.Favor, entrar em contato com o suporte.', 'error');
	            });
            } else if (this.produto.id != null) { //atualização
	            API.post('rest/auxiliar/ProdutoRegional/' + this.produto.id, this.produto).then(res => {
                    this.closeLoading();
                    this.showToast('Produto Regional atualizado com sucesso!', 'success', '/cad/atendimento/produtoRegional');
	            }).catch(error => {
                    this.closeLoading();
                    this.showToast('Error ao editar produto regional.Favor, entrar em contato com o suporte.', 'error');
	            });
            } else { //repetido cadastro
                this.closeLoading();
                this.showToast('Já existe um produto com esse nome registrado na unidade escolhida.', 'error');
		    }
        } else {
            this.closeLoading();
            this.showToast('Unidade UO não realiza cadastro/atualização de produto regional.', 'error');
        }
    };

    filterList = (e) => {
    	if (e != null && e.type != null && e.type === 'produtoLinha' && e.id !== this.linha.id) {
    		var categoriaList = this.categorias.filter(function (value) { return value.produtoLinha.id === e.id });
    		this.linha = e;
    		this.categoria = { descricao: '' };
            this.produto.produtoNacional = {};
            this.errors.produtoNacional.error = '';
            this.setState({ produtoCategoriaList: categoriaList });
        } else if (e != null && e.type != null && e.type === 'produtoCategoria' && e.id !== this.categoria.id) {
            var produtos = this.produtosNacionais.filter(function (value) { return value.produtoCategoria.id === e.id });
            this.categoria = e;
            this.produto.produtoNacional = {};
            if (produtos.length === 0){
                this.errors.produtoNacional.error = 'Esta categoria não possui produtos nacionais relacionados.'
            } else {
                this.errors.produtoNacional.error = ''
            }
            this.setState({ produtoNacionalList: produtos });
        } 
    };

    getSteps() {
        return (
            [<Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <FormField type={'text'} label={'Nome'} id={'nome'} changeValue={this.changeInput} inputValue={this.produto.nome} 
                            	error={this.errors.nome.error} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <FormField type={'text'} label={'CR Regional'} id={'CR'} changeValue={this.changeInput} inputValue={this.produto.CR} error={this.errors.CR.error} />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <InputSelect label={'Regional'} suggestions={this.state.unidades} itemKey={'id'} itemLabel={'abreviacao'} id={'unidade'} getInput={this.changeInput}
                                initialSelectedItem={this.produto.unidade} key={'unidade'} />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <InputSelect label={'Status'} suggestions={this.ativoList} itemLabel={'label'} getInput={this.changeInput} id={'isAtivo'} itemKey={'label'}
                            	value={this.produto.isAtivo ? this.ativoList[0] : this.ativoList[1]} error={this.errors.isAtivo.error} 
                            	initialSelectedItem={this.produto.isAtivo ? this.ativoList[0] : this.ativoList[1]} key={'isAtivo'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputSelect label={'Linha'} suggestions={this.state.produtoLinhaList} itemKey={'id'} itemLabel={'descricao'} id={'linha'} getInput={this.filterList} 
                            	initialSelectedItem={this.linha} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect label={'Categoria'} suggestions={this.state.produtoCategoriaList} itemKey={'id'} itemLabel={'descricao'} id={'categoria'} getInput={this.filterList} 
                            	initialSelectedItem={this.categoria} disabled={this.linha.id ? false : true} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect label={'Produto Nacional'} suggestions={this.state.produtoNacionalList} itemKey={'id'} itemLabel={'descricao'} id={'produtoNacional'} getInput={this.changeInput}
                                disabled={this.categoria.id ? false : true} initialSelectedItem={this.produto.produtoNacional} key={'produtoNacional'} error={this.errors.produtoNacional.error} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={7} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} className={'review-div'}>
                            <div className={'review-square'}>
                                <label>1</label>
                            </div>
                            <div className={'review-info'}>
                                <label className={'review-title'}>Dados do Produto Regional</label>
                                <Grid container>
                                    <Grid item xs={3}>Nome</Grid>
                                    <Grid item xs={9}>{this.produto.nome.toUpperCase()}</Grid>
                                    <Grid item xs={3}>CR Regional</Grid>
                                    <Grid item xs={9}>{this.produto.CR}</Grid> 
                                    <Grid item xs={3}>Regional</Grid>
                                    <Grid item xs={9}>{this.produto.unidade.descricao}</Grid> 
                                    <Grid item xs={3}>Status</Grid>
                                    <Grid item xs={9}>{this.produto.isAtivo ? 'ATIVO' : 'INATIVO'}</Grid> 
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>,
                <Grid item xs={12} md={7} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} className={'review-div'}>
                            <div className={'review-square'}>
                                <label>2</label>
                            </div>
                            <div className={'review-info'}>
                                <label className={'review-title'}>Produto Nacional associado</label>
                                <Grid container>
                                    <Grid item xs={3}>Linha</Grid>
                                    <Grid item xs={9}>{this.linha.descricao.toUpperCase()}</Grid>
                                    <Grid item xs={3}>Categoria</Grid>
                                    <Grid item xs={9}>{this.categoria.descricao.toUpperCase()}</Grid> 
                                    <Grid item xs={3}>Produto Nacional</Grid>
                                    <Grid item xs={9}>{this.produto.produtoNacional.descricao}</Grid> 
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>]
        );
    };

    
    render() {
        return (
            <Page icon={'paste.svg'} label={'Novo Produto Regional'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'produto-regional-novo'}>
                    <Stepper titles={this.titles} steps={this.getSteps()} sendForm={this.salvarProduto} validation={this.validateProduto} history={this.props.history} />
                </Grid>
            </Page>
        );
    };
}