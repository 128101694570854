export function getBoxes(ano) {
    return {
        'strategic': [
            {
                only_content: false,
                title: 'Resources/IC',
                name: '# employed researchers',
                header_duplo: 'Competences & Capacities',
                actual_year: ano - 1,
                actual_value: '-',
                forecast_year: ano,
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 3,
                order: 15,
                children: []
            },
            {
                only_content: false,
                title: 'Business Processes',
                name: '% customer satisfaction',
                actual_year: ano - 1,
                actual_value: '-',
                forecast_year: ano,
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 2,
                order: 27,
                children: []
            },
            {
                only_content: false,
                title: 'Costumer Value',
                name: '% dependency on main customer',
                actual_year: ano - 1,
                actual_value: '-',
                forecast_year: ano,
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 0,
                order: 24,
                children: []
            },
            {
                only_content: false,
                title: 'Business Sucess',
                name: 'R$ total STI revenue',
                actual_year: ano - 1,
                actual_value: '-',
                forecast_year: ano,
                forecast_value: '-',
                forecast2_year: ano + 1,
                forecast2_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 1,
                order: 2,
                children: [
                    {
                        only_content: true,
                        name: '% cost covered by revenue',
                        actual_year: ano - 1,
                        actual_value: '-',
                        forecast_year: ano,
                        forecast_value: '-',
                        forecast2_year: ano + 1,
                        forecast2_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 3,
                    },
                    {
                        only_content: true,
                        name: '% R&D&I revenue',
                        actual_year: ano - 1,
                        actual_value: '-',
                        forecast_year: ano,
                        forecast_value: '-',
                        forecast2_year: ano + 1,
                        forecast2_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 19,
                    },
                    {
                        only_content: true,
                        name: '% industry revenue',
                        actual_year: ano - 1,
                        actual_value: '-',
                        forecast_year: ano,
                        forecast_value: '-',
                        forecast2_year: ano + 1,
                        forecast2_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 5,
                    }
                ]
            }
        ],
        'rampup': [
            {
                only_content: false,
                title: 'Resources/IC',
                name: '# employed researchers',
                header: 'Human Capital',
                header_duplo: 'Competences & Capacities',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 3,
                order: 15,
                children: [
                    {
                        only_content: false,
                        name: '% investments made according to strategy',
                        header: 'Structural Capital',
                        header_duplo: 'Organization & Infrastructure',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 3,
                        order: 16,
                    },
                    {
                        only_content: false,
                        name: '# R&D&I projects in collaboration',
                        header: 'Relational Capital',
                        header_duplo: 'Relationship & Network',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 3,
                        order: 17,
                    }
                ]
            },
            {
                only_content: false,
                title: 'Business Processes',
                header: 'Marketing & Sales',
                name: '% acquisition success rate',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 2,
                order: 12,
                children: [
                    {
                        only_content: false,
                        name: '# R&D&I projects in execution',
                        header: 'Applied R&D Innovation',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 2,
                        order: 13,
                    },
                    {
                        only_content: false,
                        name: '% R&D&I projects delivered in time',
                        header: 'Project Management / Service Delivery',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 2,
                        order: 14,
                    }
                ]
            },
            {
                only_content: false,
                title: 'Costumer Value',
                name: '# R&D&I customer',
                header: 'High Quality R&D Brand',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 0,
                order: 7,
                children: [
                    {
                        only_content: false,
                        name: '# SMEs attended',
                        header: 'New Revenue & Market Opportunities',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 0,
                        order: 8,
                    },
                    {
                        only_content: false,
                        name: '# large companies attended',
                        header: 'Optimized Productivity',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 0,
                        order: 9,
                    },
                ]
            },
            {
                only_content: false,
                title: 'Business Sucess',
                name: '# projects outside state',
                header: 'Image & Reputation',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 1,
                order: 0,
                children: [
                    {
                        only_content: false,
                        name: '% cost covered by revenue',
                        header: 'Financial Sustainability',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 3,
                    },
                    {
                        only_content: false,
                        name: '% revenue from funding programs',
                        header: 'Growth of National Innovation System',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 4,
                    },
                    {
                        only_content: false,
                        name: '% industry revenue',
                        header: 'Competitiveness of Industry',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 5,
                    }
                ]
            }
        ],
        'stable': [
            {
                only_content: false,
                title: 'Resources/IC',
                name: '# employed researchers',
                header: 'Human Capital',
                header_duplo: 'Competences & Capacities',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 3,
                order: 15,
                children: [
                    {
                        only_content: true,
                        name: '# employed technical staff',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 3,
                        order: 28,
                    },
                    {
                        only_content: false,
                        name: '% financial resources invested',
                        header: 'Structural Capital',
                        header_duplo: 'Organization & Infrastructure',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 3,
                        order: 30,
                    },
                    {
                        only_content: true,
                        name: '% leaving research & technical staff',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 3,
                        order: 31,
                    },
                    {
                        only_content: false,
                        name: '% R&D&I revenue transferred to partners',
                        header: 'Relational Capital',
                        header_duplo: 'Relationship & Network',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 3,
                        order: 32,
                    },
                    {
                        only_content: true,
                        name: '% R&D&I revenue received from partners',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 3,
                        order: 33,
                    }
                ]
            },
            {
                only_content: false,
                title: 'Business Processes',
                header: 'Marketing & Sales',
                name: '% acquisition success rate',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 2,
                order: 12,
                children: [
                    {
                        only_content: true,
                        name: 'R$ revenue per R&D&I project',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 2,
                        order: 25,
                    },
                    {
                        only_content: false,
                        name: '# R&D&I projects in execution',
                        header: 'Applied R&D Innovation',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 2,
                        order: 13,
                    },
                    {
                        only_content: true,
                        name: 'R$ R&D&I revenue per researcher',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 2,
                        order: 26,
                    },
                    {
                        only_content: false,
                        name: '% R&D&I projects delivered in time',
                        header: 'Project Management / Service Delivery',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 2,
                        order: 14,
                    },
                    {
                        only_content: true,
                        name: '% customer satisfaction',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 2,
                        order: 27,
                    }
                ]
            },
            {
                only_content: false,
                title: 'Costumer Value',
                name: '# new R&D&I customer',
                header: 'High Quality R&D Brand',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 0,
                order: 23,
                children: [
                    {
                        only_content: true,
                        name: '% dependency on main customer',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 0,
                        order: 24,
                    },
                    {
                        only_content: false,
                        name: '# SMEs attended',
                        header: 'New Revenue & Market Opportunities',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 0,
                        order: 8,
                    },
                    {
                        only_content: false,
                        name: '# large companies attended',
                        header: 'Optimized Productivity',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 0,
                        order: 9,
                    },
                ]
            },
            {
                only_content: false,
                title: 'Business Sucess',
                name: 'R$ total STI revenue',
                header: 'Image & Reputation',
                actual_value: '-',
                forecast_value: '-',
                assessment: '-',
                trend: '-',
                color_index: 1,
                order: 2,
                children: [
                    {
                        only_content: true,
                        name: '# publications',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 21,
                    },
                    {
                        only_content: false,
                        name: '% cost covered by revenue',
                        header: 'Financial Sustainability',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 3,
                    },
                    {
                        only_content: true,
                        name: '% customer retention rate',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 20,
                    },
                    {
                        only_content: false,
                        name: '% R&D&I revenue',
                        header: 'Growth of National Innovation System',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 19,
                    },
                    {
                        only_content: true,
                        name: '% revenue from funding programs',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 4,
                    },
                    {
                        only_content: false,
                        name: '% industry revenue',
                        header: 'Competitiveness of Industry',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 5,
                    },
                    {
                        only_content: true,
                        name: '# intellectual property applications',
                        actual_value: '-',
                        forecast_value: '-',
                        assessment: '-',
                        trend: '-',
                        color_index: 1,
                        order: 22,
                    }
                ]
            }
        ]
    }
};

export function mappedBox() {
    return [
        {
            type: 'strategic',
            id: 0,
            order: 15
        },
        {
            type: 'strategic',
            id: 1,
            order: 27
        },
        {
            type: 'strategic',
            id: 2,
            order: 24
        },
        {
            type: 'strategic',
            id: 3,
            order: 2
        },
        {
            type: 'strategic',
            id: 3,
            children: 0,
            order: 3
        },
        {
            type: 'strategic',
            id: 3,
            children: 1,
            order: 19,
        },
        {
            type: 'strategic',
            id: 3,
            children: 2,
            order: 5
        },
        {
            type: 'rampup',
            id: 0,
            order: 15,
        },
        {
            type: 'rampup',
            id: 0,
            children: 0,
            order: 16,
        },
        {
            type: 'rampup',
            id: 0,
            children: 1,
            order: 17,
        },
        {
            type: 'rampup',
            id: 1,
            order: 12
        },
        {
            type: 'rampup',
            id: 1,
            children: 0,
            order: 13,
        },
        {
            type: 'rampup',
            id: 1,
            children: 1,
            order: 14,
        },
        {
            type: 'rampup',
            id: 2,
            order: 7
        },
        {
            type: 'rampup',
            id: 2,
            children: 0,
            order: 8
        },
        {
            type: 'rampup',
            id: 2,
            children: 1,
            order: 9
        },
        {
            type: 'rampup',
            id: 3,
            order: 0
        },
        {
            type: 'rampup',
            id: 3,
            children: 0,
            order: 3,
        },
        {
            type: 'rampup',
            id: 3,
            children: 1,
            order: 4,
        },
        {
            type: 'rampup',
            id: 3,
            children: 2,
            order: 5,
        },
        {
            type: 'stable',
            id: 0,
            order: 15
        },
        {
            type: 'stable',
            id: 0,
            children: 0, 
            order: 28
        },
        {
            type: 'stable',
            id: 0,
            children: 1,
            order: 30
        },
        {
            type: 'stable',
            id: 0,
            children: 2,
            order: 31
        },
        {
            type: 'stable',
            id: 0,
            children: 3,
            order: 32
        },
        {
            type: 'stable',
            id: 0,
            children: 4,
            order: 33
        },
        {
            type: 'stable',
            id: 1,
            order: 12
        },
        {
            type: 'stable',
            id: 1,
            children: 0,
            order: 25,
        },
        {
            type: 'stable',
            id: 1,
            children: 1,
            order: 13,
        },
        {
            type: 'stable',
            id: 1,
            children: 2,
            order: 26,
        },
        {
            type: 'stable',
            id: 1,
            children: 3,
            order: 14,
        },
        {
            type: 'stable',
            id: 1,
            children: 4,
            order: 27,
        },
        {
            type: 'stable',
            id: 2,
            order: 23,
        },
        {
            type: 'stable',
            id: 2,
            children: 0,
            order: 24
        },
        {
            type: 'stable',
            id: 2,
            children: 1,
            order: 8
        },
        {
            type: 'stable',
            id: 2,
            children: 2,
            order: 9,
        },
        {
            type: 'stable',
            id: 3,
            order: 2
        },
        {
            type: 'stable',
            id: 3,
            children: 0,
            order: 21
        },
        {
            type: 'stable',
            id: 3,
            children: 1,
            order: 3
        },
        {
            type: 'stable',
            id: 3,
            children: 2,
            order: 20
        },
        {
            type: 'stable',
            id: 3,
            children: 3,
            order: 19
        },
        {
            type: 'stable',
            id: 3,
            children: 4,
            order: 4
        },
        {
            type: 'stable',
            id: 3,
            children: 5,
            order: 5
        },
        {
            type: 'stable',
            id: 3,
            children: 6,
            order: 22
        }
    ];
}
