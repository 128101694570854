import { Grid } from "@material-ui/core";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';
import WarningIcon from '@material-ui/icons/Warning';
import { Alert, AlertTitle } from "@material-ui/lab";
import moment from 'moment';
import React, { Component } from 'react';
import { MdArrowCircleUp } from "react-icons/md";
import API, { BASE_URL } from '../../comm/API';
import { mesList } from '../../comm/variables';
import EmptyList from '../../components/emptyList/emptyList';
import GenericButton from '../../components/genericButton/genericButton';
import SimpleModal from '../../components/modal/modal';
import Page from '../../components/page/page';
import Table from '../../components/table/table';
import InputSelect from './../../components/inputselect/inputselect';
import './solucaoIntegradora.scss';

export default class SolucaoIntegradora extends Component {

    state = {
        openModalConfirmacao: false, anoList: [], ano: 0, mesList: [], mes: {}, arquivoCargaList: [], file: null, declaracaoValidacao: false,
        disableValidarButton: true, validacaoPendente: false, cargaPendente: false, emptyList: 'noItens'
    };

    titles = [
        { id: 'codigoArquivoCarga', canOrder: true, label: 'Código da Carga'},
        { id: 'dataCarga', canOrder: true, label: 'Data de Envio' },
        { id: 'qtdeAtendimentos', canOrder: true, label: 'Atendimentos' },
        { id: 'satusProcessamentoTexto', canOrder: true, label: 'Processamento' },
        { id: 'qtdeCriticas', canOrder: true, label: 'Total com críticas' },
        { id: 'qtdeCriticasImpeditivas', canOrder: true, label: 'Críticas Impeditivas' },
        { id: 'situacao', canOrder: true, label: 'Situação' },
        { id: 'download', canOrder: false, label: '' }];

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    async componentWillMount() {
        await this.configData();
        this.getCargasPendentes();
        this.getCargas();
    };

    componentDidMount() {
        this.closeLoading();
    };

    async getCargasPendentes() {
        let mesAnterior = moment().subtract(1, 'months').format('MM');
        let anoAnterior = moment().subtract(1, 'months').format('YYYY');
        var filter = {
            "anoMes": `${anoAnterior}${mesAnterior}`,
            "unidade.id": this.props.usuarioConectado.unidade.id
        }
        let resp = await API.get('/rest/gestao/ArquivoCarga/count', { params: filter });

        if (resp.data.count < 0) {
            this.setState({ cargaPendente: true });
        }
    };

    getAnoList() {
        let anoList = []
        for (let i = new Date().getFullYear(); i >= 2016; i--) {
            anoList.push(i);
        }
        return anoList;
    };

    async configData() {
        let anoList = []
        for (let i = new Date().getFullYear(); i >= 2016; i--) {
            anoList.push(i);
        }
        let lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        let mes = mesList.find(m => { return m.id === lastMonth.getMonth() });
        await this.setState({ anoList: anoList, ano: lastMonth.getFullYear(), mesList: mesList, mes: mes });
    };

    formatData(date) {
        let dataList = '';
        if (date && date.length > 10) {
            dataList = date.split('T');
            dataList = dataList[0].split('-');
            dataList = dataList[2] + '/' + dataList[1] + '/' + dataList[0];
        }
        return dataList;
    };

    getStatusProcessamento(status) {
        switch (status) {
            case 1:
                return 'Validando o Layout';
            case 2:
                return 'Aguardando a Validação das Regras de Negócio';
            case 3:
                return 'Processamento finalizado com erros de regra';
            case 4:
                return 'Processamento finalizado sem erros de regra';
            case 5:
                return 'Erro de Layout Impeditivo';
            case 7:
                return 'Erro na carga';
            default:
                return '-';
        }
    };

    getColorStatusProcessamento(status) {
        switch (status) {
            case 1:
                return 'validando-layout';
            case 2:
                return 'aguardando-validacao';
            case 3:
                return 'finalizado-com-erros';
            case 4:
                return 'finalizado-sem-erros';
            case 5:
                return 'errro-layout';
            case 7:
                return 'errro-carga';
            default:
                return '';
        }
    };

    getSituacao(arquivo) {
        if (arquivo.processamento === 0) {
            return 'Processando críticas';
        }
        if (arquivo.processamento === 50) {
            return 'Aguardando validação';
        }
        if (arquivo.processamento === 80) {
            return 'Validação em andamento';
        }
        if (arquivo.processamento === 100 && arquivo.satusProcessamento < 7) {
            return `Validado - ${this.formatData(arquivo.dataValidacao)}`;
        }
        if (arquivo.processamento === 100 && arquivo.satusProcessamento >= 7) {
            return `Carga com problema - ${this.formatData(arquivo.dataValidacao)}`;
        }
    };

    getCargas(mensagem, status) {
        this.showLoading();
        API.get(`/rest/gestao/ArquivoCarga/0/49?anoMes=${this.state.ano}${this.state.mes.value}&unidade.id=${this.props.usuarioConectado.unidade.id}`).then(res => {
            let list = res.data.map(arqCarga => {
                arqCarga.dataCarga = this.formatData(arqCarga.dataCarga);
                arqCarga.satusProcessamentoTexto = this.getStatusProcessamento(arqCarga.satusProcessamento);
                arqCarga.situacao = <span className={this.getColorStatusProcessamento(arqCarga.satusProcessamento)}>{this.getSituacao(arqCarga)}</span>
                arqCarga.download = <GenericButton color={'transparent'} subClass={'pen icon-button'} icon={'download.svg'} click={() => { this.downloadArquivoCarga(arqCarga) }} />
                return arqCarga;
            });
            this.setState({ arquivoCargaList: list, emptyList: list.length === 0 ? 'noItens' : '' });
            this.closeLoading();
            if (mensagem && status) this.showToast(mensagem, status);
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro na listagem de Cargas. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    downloadArquivoCarga(arqCarga) {
        window.open(`${BASE_URL}rest/api-integrador/arquivo/critica/${arqCarga.codigoArquivoCarga}/${this.props.usuarioConectado.unidade.id}`);
    };

    enviarCargaAutomatico = () => {
        this.showLoading();
        API.get(`/rest/integracao/uploadSI/${this.state.ano}/${this.state.mes.value}/${this.getRegional()}`).then(res => {
            if (res.data.code === 0) {
                this.getCargas('Carga enviada com sucesso!', 'success');
            } else if (res.data.code === 2) {
                this.getCargas(res.data.message, 'error');
            }
        }).catch(error => {
            this.closeLoading();
            this.showToast('Infelizmente ocorreu um erro ao enviar o arquivo para a solução integradora!', 'error');
        });
    };

    getMesAtual() {
        let mes = new Date().getMonth();
        return this.state.mesList.find(m => { return m.id = mes });
    };

    isCargaManual() {
        let regionaisCargaManualList = ['SP', 'GO', 'RJ', 'SC'];
        return regionaisCargaManualList.indexOf(this.props.usuarioConectado.unidade.municipio.unidadeFederativa.sigla) !== -1;
    };

    
    isCargaSaneamento() {
        let regionaisSaneamentoList = ["MG", "AC", "TO", "PE", "DF", "RS", "SE", "PA", "ES", "AL", "CT", "MA", "RO", "MT", "PR", "BA"];
        return regionaisSaneamentoList.indexOf(this.props.usuarioConectado.unidade.municipio.unidadeFederativa.sigla) !== -1;
    }
    
    enviarCargaSaneamento = () => {
        this.showLoading();
        API.get(`/rest/integracao/uploadSaneamentoSI/${this.state.ano}/${this.state.mes.value}/${this.getRegional()}`).then(res => {
            if (res.data.code === 0) {
                this.getCargas('Carga de Saneamento de dados foi enviada com sucesso!', 'success');
            } else if (res.data.code === 2) {
                this.getCargas(res.data.message, 'error');
            }
        }).catch(error => {
            this.closeLoading();
            this.showToast('Infelizmente ocorreu um erro ao enviar o arquivo para a solução integradora!', 'error');
        });
    };

    uploadAnexo = (e) => {
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        this.setState({ file: formData });
    };

    verificarMensagem() {
        let criticas = this.state.arquivoCargaList.some(carga => {
            return carga.processamento === 50 && carga.qtdeCriticasImpeditivas > 0;
        });
        return criticas;
    };

    getAtendimentosCritica = () => {
        this.showLoading();
        API.get(`rest/api-integrador/criticas/${this.state.ano}/${this.state.mes.value}/${this.props.usuarioConectado.unidade.id}`).then(res => {
            this.consultaValidacao(res.data.message, res.data.code === 0 ? 'success' : 'warning');
        }).catch(error => {
            this.consultaValidacao();
        });
    };

    subirArquivoIntegracao() {
        API.get(`rest/api-integrador/validar/${this.state.ano}/${this.state.mes.value}/${this.props.usuarioConectado.unidade.codigoOBA}`).then(res => {
            if (res.data.code === 0) {
                this.showToast("Validação iniciada com sucesso!", 'success');
                this.getCargas();
            } else {
                this.closeLoading();
                this.showToast("Falha ao iniciar validação: " + res.data.message, 'error');
            }
        }).catch(error => {
            this.closeLoading();
            this.showToast('Falha ao iniciar validação.', 'error');
        });
    };

    validarArquivo = () => {
        this.setState({ openModalConfirmacao: false, disableValidarButton: true });
        this.showLoading();
        API.get(`rest/api-integrador/validar/${this.state.ano}/${this.state.mes.value}/${this.props.usuarioConectado.unidade.codigoOBA}`).then(res => {
            if (res.data.code === 0) {
                this.getCargas('Validação iniciada com sucesso', 'success');
            } else {
                this.closeLoading();
                this.showToast(res.data.message, 'error');
            }
        }).catch(error => {
            this.closeLoading();
            console.log(error);
            this.showToast('Falha ao iniciar validação. Por favor, entre em contato com o suporte', 'error');
        });
    };

    consultaValidacao(message, status) {
        API.get(`rest/api-integrador/consulta/validacao/${this.state.ano}/${this.state.mes.value}/${this.props.usuarioConectado.unidade.codigoOBA}`).then(res => {
            this.getCargas(message, status);
        }).catch(error => {
            this.getCargas();
            console.log(error);
            this.showToast('Ocorreu um error na requisição. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    existeCargaStatusInicial() {
        let inicial = this.state.arquivoCargaList.some(arqCarga => {
            return arqCarga.processamento < 50;
        });
        return inicial;
    };

    existeCargaEmExecucao() {
        let emExecucao = this.state.arquivoCargaList.some(arqCarga => {
            return arqCarga.emExecucao;
        });
        return emExecucao;
    };

    existeCargaEmProcessamento() {
        let emProcessamento = this.state.arquivoCargaList.some(arqCarga => {
            return arqCarga.processamento === 0;
        });
        return emProcessamento;
    };

    async validacaoPendente() {
        this.showLoading();
        var filter = {
            "anoMes": `less(${this.state.ano}${this.state.mes.value})`,
            "dataValidacao": "is(null)",
            "satusProcessamento": 'between(3,4)',
            "unidade.id": this.props.usuarioConectado.unidade.id
        };
        let resp = await API.get('/rest/gestao/ArquivoCarga/count', { params: filter });
        if (resp.data.count > 0) {
            this.setState({ validacaoPendente: true });
        } else {
            this.setState({ validacaoPendente: false });
        }
    };

    getRegional() {
        if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN') {
            return 0;
        } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR') {
            return this.props.usuarioConectado.unidade.id;
        }
    };

    enviarArquivoIntegracao() {
        this.showLoading();
        API.post(`rest/integracao/uploadSI/${this.state.ano}/${this.state.mes.value}/${this.getRegional()}`, this.state.file, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(res => {
            if (res.data.code === 1) {
                this.getCargas('O upload do arquivo foi realizado com sucesso!', 'success');
            } else if (res.data.code === 2) {
                this.getCargas('Infelizmente ocorreu um erro ao fazer o upload do arquivo!', 'error');
            }
        }).catch(error => {
            this.showToast('Infelizmente ocorreu um erro ao fazer o upload do arquivo!', 'error');
            this.closeLoading();
        });
    };

    render() {
        let temCriticaImpeditiva = this.verificarMensagem();
        return (
            <Page icon={'solucao.svg'} label={'Solução Integradora > Enviar carga'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'solucao-integradora'} direction='column' justify='center'>
                    <label className={'font-blue'}>Relatório de atendimento</label>
                    <label className={'font-grey'}>Selecione o ano e o mês do relatório que deseja enviar carga para a solução integradora.</label>
                    { this.isCargaSaneamento() && 
                        <Alert  severity="info">
                        <AlertTitle>Novidade: Opção "Saneamento de Dados" Disponível</AlertTitle>
                        Olá! Disponibilizamos a opção "Saneamento de Dados" em nosso sistema.<br /> 
                        Essa nova funcionalidade permite o envio dos casos que foram validados em conjunto com o Departamento Nacional para o processo de saneamento.<br />
                        Este é um passo importante para garantir a precisão e a integridade de todas as informações que gerenciamos.<br />
                        Caso haja qualquer dúvida ou se precisar de ajuda, nossa equipe de suporte está à disposição!<br />
                        Não hesite em entrar em contato pelo e-mail: suporte.sgt@pe.senai.br.<br />
                        Agradecemos a sua colaboração!<br />
                        </Alert>
                    } 
                    <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center" className={'solucao-header'}>
                        <Grid item xs={12}>
                            <InputSelect key='ano' suggestions={this.state.anoList} initialSelectedItem={this.state.ano}
                                getInput={async (e) => { await this.setState({ ano: e }); this.validacaoPendente(); this.getCargas(); }} />
                            <InputSelect key='mes' suggestions={this.state.mesList} initialSelectedItem={this.state.mes}
                                itemLabel={'label'} itemKey={'id'}
                                getInput={async (e) => { await this.setState({ mes: e }); this.validacaoPendente(); this.getCargas(); }} />
                            { this.isCargaManual() && 
                                <Grid className={'button-download'}>
                                    <label
                                        className={'lightBlue icon-button'}
                                        id="control-label"
                                        style={{ marginBottom: '0px' }}
                                        htmlFor="uploadfile">
                                        <img src={process.env.PUBLIC_URL + '/assets/icons/plus.svg'}
                                            width={25} height={25} alt={''} />
                                    </label>
                                    <input
                                        className={'file-input'} type="file" id="uploadfile"
                                        name="file" value={undefined} onChange={this.uploadAnexo} />
                                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Upload'} click={this.enviarArquivoIntegracao} />
                                    <label>{this.state.file ? this.state.file.get('fileName') : ''}</label>
                                </Grid> }  
                            { this.isCargaSaneamento() && 
                                <Grid className={'button-download'}>
                                    <MdArrowCircleUp className={'lightBlue icon-button'} />
                                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Saneamento de dados'} click={this.enviarCargaSaneamento} />
                                    <label>{this.state.file ? this.state.file.get('fileName') : ''}</label>
                                </Grid> }  
                            <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Enviar para a solução integradora'} 
                                click={this.enviarCargaAutomatico} disabled={this.existeCargaStatusInicial()} /> 
                        </Grid>
                    </Grid>
                    <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginTop: 20 }}>
                        <label className={'font-blue-2'}>{this.state.mes.label} {this.state.ano}</label>
                        <GenericButton color={'lightBlue'} subClass={'basic-button validar'} label={'Validar'} 
                            click={() => this.setState({ openModalConfirmacao: true, declaracaoValidacao: false })} disabled={this.existeCargaStatusInicial()} />
                        <GenericButton color={'darkBlue-outline'} subClass={'basic-button atualizar-carga'} label={'Atualizar'} click={this.getAtendimentosCritica}
                            icon={process.env.PUBLIC_URL + 'reload.svg'} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 15 }}>
                        { this.state.validacaoPendente && 
                            <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginBottom: '10px' }}>
                                <Icon><WarningIcon color="error" /></Icon>
                                <label className={'label-error'}>Existem cargas de meses anteriores a serem validadas.</label>
                            </Grid> }
                        { this.existeCargaEmExecucao() && 
                            <Grid container direction="row" justify="flex-start" alignItems="center" style={{ marginBottom: '10px' }}>
                                <Icon><WarningIcon color="error" /></Icon>
                                <label className={'label-error'}>Atualizando situação dos atendimentos.</label>
                            </Grid> }
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.arquivoCargaList} titles={this.titles} initialOrder={'0'} />
                        <EmptyList type={this.state.emptyList} msg={'Não há cargas enviadas neste mês'} />
                    </Grid>
                    <SimpleModal isOpen={this.state.openModalConfirmacao}
                        handleClose={() => this.setState({ openModalConfirmacao: false })}
                        type={temCriticaImpeditiva ? 'warning' : 'success'} width={'600px'}>
                        <div className={'modal-solucao-integradora'}>
                            <FormControlLabel value="end" control={
                                <Checkbox onChange={(e) => { this.setState({ disableValidarButton: !this.state.disableValidarButton }) }} />}
                                label="Declaro que a carga de produção de Serviços de Tecnologia e Inovação referentes ao mês/ano selecionado está validada." labelPlacement="end" />
                            { temCriticaImpeditiva && <p>Existem atendimentos com críticas impeditivas nas cargas a serem validadas!</p>}
                            <div className={'space'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.setState({ openModalConfirmacao: false, disableValidarButton: true })} />
                                <GenericButton color={'darkBlue'} label={'Validar'} subClass={'basic-button'} click={this.validarArquivo} disabled={this.state.disableValidarButton} />
                            </div>
                        </div>
                    </SimpleModal>
                </Grid>
            </Page>
        );
    };

}