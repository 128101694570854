import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import GenericButton from '../../components/genericButton/genericButton';
import Collapse from '../../components/collapse/collapse';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Table from '../../components/table/table';
import SimpleModal from '../../components/modal/modal';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import API from '../../comm/API';
import EtapaMentoriaDigital from './etapaMentoriaDigital';
import './trilha.scss';

export default class TrilhaMentoriaDigital extends Component {

	numAtendimentos = 0;
	numAlunos = 0;
	up = [];
	down = ['Educação à distância', 'Alinhamento', 'Mentoria Teórica', 'Mentoria Prática', 'Consultoria', 'Mentoria Teórica', 'Mentoria Prática', 'Consultoria', 'Mentoria Teórica', 'Mentoria Prática', 'Consultoria'];
	state = { showReticenciasLeft: false, showReticenciasRight: true, openModalInfoTurma: false, openModalDigitalGame: false, turma: this.props.turma, atendimentoTurmaList: JSON.parse(JSON.stringify(this.props.turma.atendimentoTurmaList)), 
        atendimentoElementList: [], openEtapa: false, concluido: false };
	refFirstStep = React.createRef();
    refEtapaList = [React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(),  React.createRef(),  React.createRef()];  
    activeStep = 2;
    etapa = 0;
    mentorLogado = this.props.usuarioConectado.colaborador;

    titleGame = [
        { id: '1', canOrder: false, label: 'Turma' },
        { id: '2', canOrder: false, label: 'Data de início' },
        { id: '3', canOrder: false, label: 'Data de término' }
    ];
    titleAluno = [
        { id: '1', canOrder: false, label: 'Nome' },
        { id: '2', canOrder: false, label: 'CPF' }
    ];
    titleAlunoGame = [
        { id: '1', canOrder: false, label: 'Nome' },
        { id: '2', canOrder: false, label: 'CPF' },
        { id: '3', canOrder: false, label: '' }
    ];

	componentWillMount() {
        this.up = this.getUpList();
		this.state.atendimentoTurmaList.forEach(atdTurma => {
            atdTurma.alunosList.forEach((aluno, index) => {
                this.numAlunos++;
            });
			this.numAtendimentos++;
		});
        let turma = this.state.turma;

        this.activeStep = turma.etapaAtual === 11 && turma.status === 3 ? 15 : 
        ([1].includes(turma.etapaAtual) ? 1 : 
        ([2, 3, 4, 5].includes(turma.etapaAtual) ? turma.etapaAtual + 1 :
        ([6, 7, 8].includes(turma.etapaAtual) ? turma.etapaAtual + 2 : turma.etapaAtual + 3)));
	};

    componentDidMount() {
        this.refFirstStep.current.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleRezise);
        let ref = this.refEtapaList[this.state.turma.etapaAtual - 1];
        if (this.state.turma.status === 3 || [1, 2, 3, 4, 5].includes(this.state.turma.etapaAtual)) this.refFirstStep.current.scrollLeft = 0;
        else this.refFirstStep.current.scrollLeft = ref.current.offsetLeft/2;
    };

    getUpList = (turmaEtapaAtual=this.state.turma.etapaAtual) => {
        let phaseA = 'phase-a.png';
        let phaseB = 'phase-b.png';
        let phaseC = 'phase-c.png';
        let phaseD = 'phase-d.png';

        if (turmaEtapaAtual === 0) {
            phaseA =  'phase-a.png';
            phaseB =  'phase-b.png';
            phaseC =  'phase-c.png';
            phaseD =  'phase-d.png';
        }
        else if (turmaEtapaAtual === 1) {
            phaseA =  'phase_a_current.svg';
            phaseB =  'phase-b.png';
            phaseC =  'phase-c.png';
            phaseD =  'phase-d.png';
        } else if (turmaEtapaAtual > 1 && turmaEtapaAtual < 6) {
            phaseA =  'phase_a_finished.svg';
            phaseB =  'phase_b_current.svg';
            phaseC =  'phase-c.png';
            phaseD =  'phase-d.png';
        } else if (turmaEtapaAtual >= 6 && turmaEtapaAtual < 9) {
            phaseA =  'phase_a_finished.svg';
            phaseB =  'phase_b_finished.svg';
            phaseC =  'phase_c_current.svg';
            phaseD =  'phase-d.png';
        } else if( turmaEtapaAtual >= 9 && turmaEtapaAtual <= 12 && this.state.turma.status != 3) {
            phaseA =  'phase_a_finished.svg';
            phaseB =  'phase_b_finished.svg';
            phaseC =  'phase_c_finished.svg';
            phaseD =  'phase_d_current.svg';
        } else {
            phaseA =  'phase_a_finished.svg';
            phaseB =  'phase_b_finished.svg';
            phaseC =  'phase_c_finished.svg';
            phaseD =  'phase_d_finished.svg';
        }
        

        return [{descricao: 'EAD - Digital Game', icon: phaseA}, {descricao: 'Diagnóstico', icon: phaseB}, {descricao: 'Plano de ação', icon: phaseC}, {descricao: 'Autossuficiência', icon: phaseD}];
    }

    handleRezise = async (event) => {
        this.refFirstStep = React.createRef();
        this.refEtapaList = [React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef(), React.createRef()];  
        await this.setState(this.state);
        if (this.refFirstStep.current) {
            this.refFirstStep.current.addEventListener('scroll', this.handleScroll);
            let ref = this.refEtapaList[this.state.turma.etapaAtual - 1];
            if (this.state.turma.status === 3 || [1, 2, 3, 4, 5].includes(this.state.turma.etapaAtual)) this.refFirstStep.current.scrollLeft = 0;
            else if ([9, 10,].includes(this.state.turma.etapaAtual)) this.refFirstStep.current.scrollLeft = ref.current.offsetLeft;
            else this.refFirstStep.current.scrollLeft = ref.current.offsetLeft/2;
        }
    };

    handleScroll = (event) => {
        let maxScrollLeft = event.srcElement.scrollWidth - event.srcElement.clientWidth;
        if (event.srcElement.scrollLeft + 100 >= maxScrollLeft) {
            this.setState({ showReticenciasRight: false, showReticenciasLeft: true }); 
        } else if (event.srcElement.scrollLeft - 100 <= 0) {
            this.setState({ showReticenciasRight: true, showReticenciasLeft: false }); 
        } else {
            this.setState({ showReticenciasRight: true, showReticenciasLeft: true }); 
        }
    };

	scrollTo = (tipo) => {
		let maxScrollLeft = this.refFirstStep.current.scrollWidth - this.refFirstStep.current.clientWidth;
		if (tipo === 'right') {
			if (this.refFirstStep.current.scrollLeft + 490 >= maxScrollLeft) {
				this.refFirstStep.current.scrollLeft = maxScrollLeft;
			} else {
				this.refFirstStep.current.scrollLeft += 440;
			}
		} else {
			if (this.refFirstStep.current.scrollLeft - 490 <= 0) {
				this.refFirstStep.current.scrollLeft = 0;
			} else {
				this.refFirstStep.current.scrollLeft -= 440;
			}
		}
	};

    getMentoresIdArray(mentores='') {
        return mentores.split(',').map(item => +item);
    }

    getIconStep = (tipo, etapa, initialStep, finalStep) => {
        let retorno = { src: process.env.PUBLIC_URL + '/assets/icons/arrow.png', color: 'lightGrey', data: '', ano: '' };
        let emProgress = false;
        let turma = this.state.turma;
        if (tipo === 'up' && this.state.turma.etapaAtual === 9 && this.state.turma.status === 3) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/phase_finished.png';
        } else if (tipo === 'up' && this.state.turma.etapaAtual >= initialStep && this.state.turma.etapaAtual <= finalStep) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/phase_current.png';
        } else if (tipo === 'up' && this.state.turma.etapaAtual > finalStep) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/phase_finished.png';
        } else if (tipo === 'up' && this.state.turma.etapaAtual < finalStep) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/phase.png';       
        } else if (tipo === 'down' && this.state.turma.etapaAtual === 9 && this.state.turma.status === 3) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/step_finished.png';
            retorno.color = 'darkGreen';
        } else if (tipo === 'down' && etapa < this.state.turma.etapaAtual) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/step_finished.png';
            retorno.color = 'darkGreen';
        } else if (tipo === 'down' && etapa > this.state.turma.etapaAtual) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/step.png';
            retorno.color = 'lightGrey';
        } else if (tipo === 'down' && etapa === this.state.turma.etapaAtual) {
            retorno.src = process.env.PUBLIC_URL + '/assets/icons/step_current.png';
            retorno.color = 'darkBlue';
        }
        if(this.state.turma.status == 3){
            retorno.color = 'darkGreen';
            if(tipo === 'down'){
                retorno.src = process.env.PUBLIC_URL + '/assets/icons/step_finished.png';
            }
            else{
                retorno.src = process.env.PUBLIC_URL + '/assets/icons/phase_finished.png';
            }
        }
        if (tipo === 'down') {
            if ((!this.props.isUO && turma.status !== 3) || (etapa === 1 && turma.status === 3)) emProgress = false;
            else if(etapa !== 1 && turma.status === 3) emProgress = true;
            else emProgress = this.mentorLogado != null && (this.getMentoresIdArray(turma.etapaTeoricaList[0].mentores).includes(this.mentorLogado.id) ||
                this.getMentoresIdArray(turma.etapaTeoricaList[1].mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaTeoricaList[2].mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaAlinhamentoB.mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaPraticaB.mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaConsultoriaB.mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaPraticaC.mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaConsultoriaC.mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaPraticaD.mentores).includes(this.mentorLogado.id) || 
                this.getMentoresIdArray(turma.etapaConsultoriaD.mentores).includes(this.mentorLogado.id));
            return (
                <Step ref={this.refEtapaList[etapa - 1]} key={tipo + etapa}>
                    <StepLabel icon={<img className={'down'} alt='' src={retorno.src} />}>
                        <div className={'phase down'}>
                            <div className={'line'}></div>
                            <div className={'div-buttons'}>
                                <GenericButton color={retorno.color} subClass={'basic-button ' + (emProgress ? '' : 'disabled')} label={this.down[etapa - 1]} 
                                    click={() => { this.changeEtapa(etapa, true); }} />
                                { retorno.color === 'darkBlue' && emProgress &&
                                    <GenericButton color={'lightBlue'} subClass={'icon-button'} click={() => { this.changeEtapa(etapa, true); }} icon={'play.svg'} /> }
                            </div>
                        </div>
                    </StepLabel>
                </Step>
            );
        } else {
            if (etapa === 0) {
                retorno.data = this.state.turma.dataInicio;
                retorno.ano = this.state.turma.dataInicio;
            } else if (etapa === 1) {
                let index = this.state.turma.etapaTeoricaList.findIndex(aux => { return aux.etapa === 3 }); 
                retorno.data = this.state.turma.etapaTeoricaList[index].dataInicio;
            } else if (etapa === 2) {
                let index = this.state.turma.etapaTeoricaList.findIndex(aux => { return aux.etapa === 6 }); 
                retorno.data = this.state.turma.etapaTeoricaList[index].dataInicio;
                retorno.ano = this.state.turma.etapaTeoricaList[index].dataInicio;
            } else if (etapa === 3) {
                let index = this.state.turma.etapaTeoricaList.findIndex(aux => { return aux.etapa === 9 }); 
                retorno.data = this.state.turma.etapaTeoricaList[index].dataInicio;
            }
            if (retorno.data.length > 0) retorno.data = new Date(retorno.data).toLocaleDateString();
            if (retorno.ano.length > 0) retorno.ano = new Date(retorno.ano).getFullYear(); 
            return (
                <Step key={tipo + etapa}>
                    <StepLabel icon={<div className={'div-data'}><img className={'up'} alt='' src={retorno.src} /><label>{retorno.data}</label></div>}>
                        <div className={'phase up'}>
                            <span>{retorno.ano}</span>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <img src={process.env.PUBLIC_URL + '/assets/icons/' + this.up[etapa].icon} alt='icon' />
                                <div className={'line'}></div>
                            </div>
                            <label>{this.up[etapa].descricao}</label>
                        </div>
                    </StepLabel>
                </Step>
            );
        }
    };

    formatDataWithSlash(date) {
        if (date != null) {
            var dataList = date.split('T');
            var dataAux = dataList[0].split('-');
            var data = dataAux[2] + '/' + dataAux[1] + '/' + dataAux[0]
            return data;
        }
    };

    changeEtapa = (etapa, open) => {
        if (open === false) {
            this.setState({ atendimentoTurmaList: JSON.parse(JSON.stringify(this.props.turma.atendimentoTurmaList)), atendimentoElementList: [], openModalInfoTurma: false, openModalDigitalGame: false });
        } else if (etapa === 1 || etapa === 0) { //GAME ou INFO
            this.props.showLoading();
            let atendimentoTurmaList = this.state.atendimentoTurmaList;
            let atendimentoElementList = [];
            atendimentoTurmaList.forEach(atdTurma => {
                let idAtendimentoTurma = atdTurma.id;
                const atendimento = atdTurma.atendimento;
                let idAtendimento = atendimento.id;
                let cliente = atendimento.cliente.nome;
                const isAtendimentoStatusCancelado = atendimento.atendimentoStatus.chave === 'cancelado';
                let alunoElementList = [];
                atdTurma.alunosList.forEach((aluno, index) => {
                    alunoElementList.push([
                        idAtendimentoTurma,
                        aluno.nome,
                        aluno.cpfcnpj,
                        <Grid item xs={12}>
                            <FormControlLabel value='end' control={
                                <Checkbox checked={aluno.concluiuEAD} 
                                    disabled={isAtendimentoStatusCancelado}
                                    onChange={(e) => { this.refreshContent(idAtendimentoTurma, aluno.id, isAtendimentoStatusCancelado); }} 
                                />} label={'Concluiu o EAD'} labelPlacement='end' />
                        </Grid>
                    ]);
                });
                let item = {
                    id: idAtendimento,
                    title:
                        <Grid container>
                            <Typography style={{ flexBasis: '100%' , color:'#707070'}} noWrap>
                                {cliente}
                                {isAtendimentoStatusCancelado && 
                                    <span className={'title-status'}>
                                        (cancelado)
                                    </span>
                                }
                            </Typography>
                        </Grid>,
                    body:
                        <Grid container className='content-game'>
                            <Grid item xs={12} style={{ marginTop: '-24px' }}>
                                <Table lines={alunoElementList} titles={etapa === 1 ? this.titleAlunoGame : this.titleAluno} initialOrder={'1'} pagination={false} className={'tabela-alunos'} hideHeader={true} /> 
                            </Grid>
                        </Grid>
                };
                atendimentoElementList.push(item);
            });
            if (etapa === 1) this.setState({ openModalDigitalGame: true, atendimentoElementList: atendimentoElementList }, this.props.closeLoading());
            else this.setState({ openModalInfoTurma: true, atendimentoElementList: atendimentoElementList }, this.props.closeLoading());
        } else {
            this.etapa = etapa;
            this.setState({ openModalDigitalGame: false, atendimentoElementList: [], openEtapa: true }, this.props.closeLoading());
        }
    };

    refreshContent = (idAtendimentoTurma, idAluno, isAtendimentoStatusCancelado) => {
        if (isAtendimentoStatusCancelado) return;
        let atendimentoTurmaList = this.state.atendimentoTurmaList;
        let atendimentoElementList = this.state.atendimentoElementList;
        let index = atendimentoTurmaList.findIndex(aux => { return aux.id === idAtendimentoTurma });
        let indexAluno = atendimentoTurmaList[index].alunosList.findIndex(aux => { return aux.id === idAluno });
        atendimentoTurmaList[index].alunosList[indexAluno].concluiuEAD = !atendimentoTurmaList[index].alunosList[indexAluno].concluiuEAD;
        let alunoElementList = [];
        atendimentoTurmaList[index].alunosList.map((aluno, index) => (
            alunoElementList.push([
                idAtendimentoTurma,
                aluno.nome,
                aluno.cpfcnpj,
                <Grid item xs={12}>
                    <FormControlLabel value='end' control={<Checkbox checked={aluno.concluiuEAD} disabled={isAtendimentoStatusCancelado} onChange={(e) => { this.refreshContent(idAtendimentoTurma, aluno.id, isAtendimentoStatusCancelado); }} />} label={'Concluiu o EAD'} labelPlacement='end' />
                </Grid>
            ])
        ));
        atendimentoElementList[index].body = (
            <Grid container className='content-game'>
                <Grid item xs={12} style={{ marginTop: '-24px' }}>
                    <Table lines={alunoElementList} titles={this.titleAlunoGame} initialOrder={'1'} pagination={false} className={'tabela-alunos'} hideHeader={true} /> 
                </Grid>
            </Grid>
        );
        this.setState({ atendimentoElementList: atendimentoElementList });
    };

    salvarDigitalGame = async (etapa, turmaDaEtapa) => {
        this.props.showLoading();
        let turma = etapa > 1 ? turmaDaEtapa : this.state.turma;
        if (etapa === 1) { //GAME
            let atendimentoTurmaList = this.state.atendimentoTurmaList;
            turma.atendimentoTurmaList = atendimentoTurmaList;
            var etapaAtual = turma.etapaAtual > 2 ? turma.etapaAtual : 2; //FASE B - ALINHAMENTO
            let canSave = true;
            for (var i = 0; i < atendimentoTurmaList.length; i++) {
                if(atendimentoTurmaList[i].atendimento.atendimentoStatus.chave !== 'cancelado') {
                    let countConcluiu = 0;
                    atendimentoTurmaList[i].alunosList.forEach(aluno => {
                        if (aluno.concluiuEAD) countConcluiu++;
                    }); 
                    canSave = canSave && countConcluiu >= 2;
                }
            };
            if(!canSave) {
                this.props.showToast('Pelo menos 2 alunos de cada atendimento devem ter concluído o EAD - Digital Game.', 'error')
                this.props.closeLoading();
                return;
            }
            turma.etapaAtual = etapaAtual;
        }
        try {
            await this.salvarTurma(turma);
            this.props.showToast('Etapa finalizada com sucesso!', 'success');
        } catch(error) {
            this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
        }
    };

    salvarTurma = async (turma) => {
        this.props.showLoading();
        return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(res => {
            this.activeStep = turma.etapaAtual === 11 && turma.status === 3 ? 15 : 
                ([1].includes(turma.etapaAtual) ? 1 : 
                ([2, 3, 4, 5].includes(turma.etapaAtual) ? turma.etapaAtual + 1 :
                ([6, 7, 8].includes(turma.etapaAtual) ? turma.etapaAtual + 2 : turma.etapaAtual + 3)));

            this.up = this.getUpList(turma.etapaAtual);
            this.setState({ turma: turma, atendimentoTurmaList: JSON.parse(JSON.stringify(turma.atendimentoTurmaList)), atendimentoElementList: [], openModalInfoTurma: false, openModalDigitalGame: false });
            this.props.closeLoading();
        }).catch(error => {
            console.log(error);
            this.props.closeLoading();
            throw error;
        });
    };

    goBackTrilha = async () => {
        this.refFirstStep = React.createRef();
        await this.setState({ openEtapa: false });
        this.refFirstStep.current.addEventListener('scroll', this.handleScroll);
        let ref = this.refEtapaList[this.state.turma.etapaAtual - 1];
        if ( [1, 2, 3, 4, 5].includes(this.state.turma.etapaAtual)) this.refFirstStep.current.scrollLeft = 0;
        else this.refFirstStep.current.scrollLeft = ref.current.offsetLeft/2;
    };

	render() {
        if (this.state.openEtapa) 
            return (
                <Grid container id={'trilha-container'} direction={'column'} justify={'center'}>
                    <Grid item xs={12}>
                        <EtapaMentoriaDigital 
                            turma={this.state.turma} 
                            usuarioConectado={this.props.usuarioConectado} 
                            goBack={this.goBackTrilha} 
                            save={this.salvarTurma}
                            etapa={this.etapa}
                            mentoresDados={this.props.mentoresDados}
                            closeLoading={this.props.closeLoading}
                            showLoading={this.props.showLoading}
                            showToast={this.props.showToast} />
                    </Grid>
                </Grid>
            );
        else 
            return (
                <Grid container id={'trilha-container'} direction={'column'} justify={'center'}>
                	<Grid item xs={12} id={'header'}>
                		{this.state.turma.nome}
                		<label>Trilha da turma</label>
                	</Grid>
                	<Grid item xs={12} id={'body'}>
                		<IconButton onClick={() => { this.scrollTo('left'); }} className={'reticencias-button'} style={{ position: 'absolute', display: this.state.showReticenciasLeft ? 'flex' : 'none', 'float': 'left' }}>
                			<MoreHorizIcon fontSize={'large'} />
                		</IconButton>
                        <IconButton onClick={() => { this.scrollTo('right'); }} className={'reticencias-button'} style={{ position: 'relative', display: this.state.showReticenciasRight ? 'flex' : 'none', 'float': 'right' }}>
                            <MoreHorizIcon fontSize={'large'} />
                        </IconButton>
            			<div className={'steper'} ref={this.refFirstStep}>
            				<Stepper activeStep={this.activeStep} className={'custom-step'}>
                                { this.getIconStep('up', 0, 1, 1) }
        						{ this.getIconStep('down', 1) }                                	
                                { this.getIconStep('up', 1, 2, 5) }
                                { this.getIconStep('down', 2) }
                            	{ this.getIconStep('down', 3) }
                            	{ this.getIconStep('down', 4) }
                            	{ this.getIconStep('down', 5) }
                                { this.getIconStep('up', 2, 6, 8) }
                            	{ this.getIconStep('down', 6) }
                            	{ this.getIconStep('down', 7) }
                            	{ this.getIconStep('down', 8) }
                                { this.getIconStep('up', 3, 9, 11) }
                            	{ this.getIconStep('down', 9) }
                            	{ this.getIconStep('down', 10) }
                                { this.getIconStep('down', 11) }
                        	</Stepper>
                        </div>
                	</Grid>
                	<Grid item xs={12} id={'footer'}>
                		<div className={'div-info-trilha'} onClick={() => { this.changeEtapa(0, true); }}>
                			<img src={process.env.PUBLIC_URL + '/assets/icons/turma-showcase-blue.svg'} alt='icon' />
                			<p style={{ fontWeight: '700', marginRight: '4px' }}>{this.numAtendimentos}</p><p> atendimentos</p>
                			<img style={{ marginLeft: '20px' }} src={process.env.PUBLIC_URL + '/assets/icons/profile-blue.svg'} alt='icon' />
                			<p style={{ fontWeight: '700', marginRight: '4px' }}>{this.numAlunos}</p><p> alunos</p>
                		</div>
                		<div className={'div-bolas'}>
                            <div className={'circulo-notificacao blue'}></div><label>EM PROGRESSO</label>
                            <div className={'circulo-notificacao green'}></div><label>CONCLUÍDA</label>
                            <div className={'circulo-notificacao gray'}></div><label>PRÓXIMAS FASES</label>
                        </div>
                	</Grid>
                	<Grid item xs={12} id={'fechar-trilha'}>
                		<GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Voltar'} click={() => { this.props.showLoading(); this.props.goBack(); }} />
                	</Grid>
                    <SimpleModal isOpen={this.state.openModalInfoTurma} handleClose={() => { this.setState({ openModalInfoTurma: false }); }} disableClick={true} type={'info'} width={'700px'} className={'turma-modal info'}>
                        <h2>Alunos participantes</h2>
                        <Grid container>
                            <Grid item xs={12} className={'grid-tabela'}>
                                <Table lines={[[this.state.turma.id, this.state.turma.nome, this.formatDataWithSlash(this.state.turma.dataInicio), this.formatDataWithSlash(this.state.turma.dataTermino)]]} titles={this.titleGame} initialOrder={'0'} pagination={false} /> 
                            </Grid>
                            { this.state.atendimentoElementList != null && this.state.atendimentoElementList.length > 0 &&
                                <Grid item xs={12}>
                                    <Collapse content={this.state.atendimentoElementList} hideButton={true} isExpanded={true} />
                                </Grid> }
                        </Grid>
                        <div className={'div-botoes'}>
                            <GenericButton color={'darkBlue'} label={'Fechar'} subClass={'basic-button'} click={() => { this.changeEtapa(undefined, false); }} />
                        </div>
                    </SimpleModal>
                    <SimpleModal isOpen={this.state.openModalDigitalGame} handleClose={() => { this.setState({ openModalDigitalGame: false }); }} disableClick={true} type={'info'} width={'700px'} className={'turma-modal'}>
                        <h2>EAD - Digital Game</h2>
                        <Grid container>
                            <Grid item xs={12} className={'grid-tabela'}>
                                <Table lines={[[this.state.turma.id, this.state.turma.nome, this.formatDataWithSlash(this.state.turma.dataInicio), this.formatDataWithSlash(this.state.turma.dataTermino)]]} titles={this.titleGame} initialOrder={'0'} pagination={false} /> 
                            </Grid>
                            { this.state.atendimentoElementList != null && this.state.atendimentoElementList.length > 0 &&
                                <Grid item xs={12}>
                                    <Collapse content={this.state.atendimentoElementList} hideButton={true} isExpanded={true} />
                                </Grid> }
                        </Grid>
                        <div className={'div-botoes'}>
                            <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => { this.changeEtapa(undefined, false) }} />
                            <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} click={() => { this.salvarDigitalGame(1) }} />
                        </div>
                    </SimpleModal>
                </Grid>
            );
    };

}    