import React, { Component } from 'react';
import Page from '../../components/page/page';
import { Grid } from "@material-ui/core";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import AutoComplete from '../../components/autoComplete/autoComplete';
import API from '../../comm/API';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Collapse from './../../components/collapse/collapse';
import Typography from '@material-ui/core/Typography';
import GridList from '@material-ui/core/GridList';
import EmptyList from '../../components/emptyList/emptyList';
import Table from '../../components/table/table';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import { Link } from 'react-router-dom';
import './configuracaoMentoriaDigital.scss';

export default class ConfiguracaoMentoriaDigital extends Component {

    nomeList = [];
    idList = [];
    grupoMentoriaDigitalList = [];
    configuracaoMentoriaDigital = [];
    errorBairro = false;
    errorUnidade = false;
    errorBairroMsg = '';
    errorUnidadeMsg = '';
    titles = [
        { id: '0', canOrder: true, label: 'Município' },
        { id: '1', canOrder: true, label: 'Bairro' },
        { id: '2', canOrder: false, label: '' }
    ];

	constructor(props) {
        super(props);
        this.state = { tab: 0, unidadeList: [], nomeGrupo: undefined, contentGrupo: [], contentCluster: [], error: false, chipUnidadeElementList: [], 
            emptyList: '', errorCluster: false, bairroList: [], municipioList: [], bairroFilterList: [], chipBairroElementList: [] };
        this.getUnidades();
        this.getMunicipios();
    };

	showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getGrupoBrasilMaisModel(nome) {
        return { nome: nome, regional: this.props.usuarioConectado.unidade.id, grupoUnidadeMentoriaDigitalList: [] };
    };

    getGrupoUnidadeModel(unidade) {
        return { unidade: { id: unidade }, grupoBrasilMais: undefined };
    };

    getClusterModel(unidade) {
        return { unidade: { id: unidade }, todosMunicipios: true, regional: this.props.usuarioConectado.unidade.id, clusterBairroMentoriaDigitalList: [], 
            clusterClienteMentoriaDigitalList: [] };
    };

    getClusterBairroModel(bairro) {
        return { bairro: { id: bairro }, cluster: undefined };
    };

    changeTab = (value, newValue) => {
    	this.setState({ tab: newValue, nomeGrupo: undefined });
    };

    getBairros = () => {
        API.get('rest/sistema/Bairro/mentoriaDigital').then(res => {
            this.setState({ bairroList: res.data });
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao realizar GET dos bairros. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    getMunicipios = () => {
        API.get('rest/pessoa/Municipio/mentoriaDigital').then(res => {
            this.setState({ municipioList: res.data });
            this.getBairros();
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao realizar GET dos municípios. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    getUnidades() {
    	API.get('rest/gestao/Unidade/mentoriaDigital').then(res => {
            this.setState({ unidadeList: res.data });
            API.get('rest/sistema/ConfiguracaoMentoriaDigital/' + this.props.usuarioConectado.unidade.id).then(res => {
                this.configuracaoMentoriaDigital = res.data;
                if (this.configuracaoMentoriaDigital != null) this.refreshContentsInit();
            }).catch(error => {
                console.log(error)
                this.closeLoading();
                this.showToast('Erro ao realizar GET da configuração. Por favor, entre em contato com o suporte.', 'error');
            });
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao realizar GET das unidades. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    changeMunicipio = (e, unidade, showError) => {
        if (e != null && e[0] != null) {
            if (!showError) this.errorBairro = false;
            let todosOsBairrosIndex = undefined;
            let bairrosFiltrados = this.state.bairroList.filter((suggestion, index) => {
                if (suggestion[0] === e[0] && suggestion[3] === 'TODOS OS BAIRROS') todosOsBairrosIndex = index;
                return suggestion[0] === e[0] && suggestion[3] !== 'TODOS OS BAIRROS';
            });
            let todosOsBairros = todosOsBairrosIndex != null ? [this.state.bairroList[todosOsBairrosIndex]] : [];
            todosOsBairros = todosOsBairros.concat(bairrosFiltrados);
            this.refreshContentCluster(todosOsBairros != null ? todosOsBairros : [], e[0], unidade);
        }
    };

    refreshContentsInit = () => {
        var listContentGrupo = this.state.contentGrupo;
        var listContentCluster = this.state.contentCluster;
        var chipUnidadeElementList = this.state.chipUnidadeElementList;
        var chipBairroElementList = this.state.chipBairroElementList;
        var i, j;
        if (this.configuracaoMentoriaDigital.clusterMentoriaDigitalList != null && this.configuracaoMentoriaDigital.clusterMentoriaDigitalList != null) {
            this.clusterMentoriaDigitalList = this.configuracaoMentoriaDigital.clusterMentoriaDigitalList;
            for (i = 0; i < this.clusterMentoriaDigitalList.length; i++) {
                let unidade = 'u' + this.clusterMentoriaDigitalList[i].unidade.id;
                let abreviacaoUnidade = this.clusterMentoriaDigitalList[i].unidade.abreviacao;
                let clusterBairroMentoriaDigitalList = this.clusterMentoriaDigitalList[i].clusterBairroMentoriaDigitalList;
                let lenClusterList = `${i + 1}`;
                lenClusterList = lenClusterList.length === 1 ? '0' + lenClusterList : lenClusterList
                if (clusterBairroMentoriaDigitalList != null && clusterBairroMentoriaDigitalList.length > 0) {
                    chipBairroElementList[unidade] = [];
                    for (j = 0; j < clusterBairroMentoriaDigitalList.length; j++) {
                        let line = [];
                        let idBairro = clusterBairroMentoriaDigitalList[j].bairro.id;
                        let idMunicipio = clusterBairroMentoriaDigitalList[j].bairro.municipio.id;
                        let descricaoMunicipio = clusterBairroMentoriaDigitalList[j].bairro.municipio.descricao;
                        let descricaoBairro = clusterBairroMentoriaDigitalList[j].bairro.descricao
                        line.push(descricaoMunicipio);
                        line.push(descricaoBairro);
                        line.push(
                            <div key={'bairro-chip-' + idBairro} className={'cluster-bairro-chip'}>
                                <GenericButton color={'transparent'} click={ (e) => { this.removeChipBairro(unidade, idBairro, idMunicipio) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                            </div>
                        );
                        chipBairroElementList[unidade].push(line);
                    };
                } else {
                    this.clusterMentoriaDigitalList[i].clusterBairroMentoriaDigitalList = [];
                    chipBairroElementList[unidade] = [];
                }
                let item = {
                    id: unidade,
                    title:
                        <Grid container>
                            <Typography style={{ flexBasis: '100%' }} noWrap>{lenClusterList + ' ' + abreviacaoUnidade}</Typography>
                        </Grid>,
                    body:
                        <Grid container spacing={1}>
                            <Grid item xs={12} lg={6} className={'collapse-unidade'}>
                                <AutoComplete
                                    suggestions={this.state.municipioList}
                                    itemKey={0}
                                    placeholder={'Selecione um município'}
                                    itemlabel={1}
                                    button={false}
                                    getInput={(e) => { this.changeMunicipio(e, unidade) }} 
                                    initialSelectedItem={['']} />
                            </Grid>
                            <Grid item xs={12} lg={6} className={'collapse-unidade'}>
                                <AutoComplete
                                    suggestions={[]}
                                    itemKey={2}
                                    placeholder={'Selecione um bairro'}
                                    itemlabel={3}
                                    button={true}
                                    getInputButton={(e) => { this.addChipBairro(e, unidade) }} 
                                    initialSelectedItem={''} />
                            </Grid>
                            <Grid item xs={12} className={'grid-tabela'}>
                                { this.state.chipBairroElementList[unidade] != null && this.state.chipBairroElementList[unidade].length > 0 &&
                                    <Table lines={this.state.chipBairroElementList[unidade]} titles={this.titles} initialOrder={'0'} pagination={true} /> }
                            </Grid>
                        </Grid>,
                    trashButton:
                        <Grid container>
                            <GenericButton color={'transparent'} click={(e) => { this.removeItemCluster(unidade) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                        </Grid>
                };
                listContentCluster.push(item);
                this.idList.push(unidade);
            };
        }
        if (this.configuracaoMentoriaDigital.grupoMentoriaDigitalList != null && this.configuracaoMentoriaDigital.grupoMentoriaDigitalList.length > 0) {
            this.grupoMentoriaDigitalList = this.configuracaoMentoriaDigital.grupoMentoriaDigitalList;
            for (i = 0; i < this.grupoMentoriaDigitalList.length; i++) {
                let nome = this.grupoMentoriaDigitalList[i].nome;
                let grupoUnidadeMentoriaDigitalList = this.grupoMentoriaDigitalList[i].grupoUnidadeMentoriaDigitalList;
                if (grupoUnidadeMentoriaDigitalList != null && grupoUnidadeMentoriaDigitalList.length > 0) {
                    chipUnidadeElementList[nome] = [];
                    for (j = 0; j < grupoUnidadeMentoriaDigitalList.length; j++) {
                        let id = grupoUnidadeMentoriaDigitalList[j].unidade.id;
                        let abreviacao = grupoUnidadeMentoriaDigitalList[j].unidade.abreviacao;
                        chipUnidadeElementList[nome].push(
                            <div key={'unidade-chip-' + id} className={'grupo-unidade-chip'}>
                                <label>{abreviacao}</label>
                                <GenericButton color={'transparent'} click={ (e) => { this.removeChipUnidade(nome, id) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                            </div>
                        );
                    };
                } else {
                    this.grupoMentoriaDigitalList[i].grupoUnidadeMentoriaDigitalList = [];
                    chipUnidadeElementList[nome] = [];
                }
                let item = {
                    id: nome,
                    title:
                        <Grid container>
                            <Typography style={{ flexBasis: '100%' }} noWrap>{nome}</Typography>
                        </Grid>,
                    body:
                        <Grid container>
                            <Grid item xs={12} className={'collapse-unidade'}>
                                <AutoComplete
                                    suggestions={this.state.unidadeList}
                                    itemKey={0}
                                    placeholder={'Selecione uma unidade operacional'}
                                    itemlabel={1}
                                    button={true}
                                    getInputButton={(e) => { this.addChipUnidade(e, nome) }} 
                                    initialSelectedItem={''} />
                            </Grid>
                            <Grid item xs={12}>
                                { chipUnidadeElementList[nome] != null && 
                                    <GridList cellHeight={20} cols={1}>
                                        { chipUnidadeElementList[nome] }
                                    </GridList> }
                            </Grid>
                        </Grid>,
                    trashButton:
                        <Grid container>
                            <GenericButton color={'transparent'} click={(e) => { this.removeItemGrupo(nome) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                        </Grid>
                };
                listContentGrupo.push(item);
                this.nomeList.push(nome);
            };
        };
        this.setState({ chipBairroElementList: chipBairroElementList, chipUnidadeElementList: chipUnidadeElementList });
        this.setState({ contentCluster: listContentCluster, contentGrupo: listContentGrupo, errorCluster: false, error: false }, this.closeLoading());
    };

    addItemCluster = (e) => {
        if (e != null && e[0] != null && !this.idList.includes('u' + e[0])) {
            let id = 'u' + e[0];
            let lenClusterList = `${this.clusterMentoriaDigitalList.length + 1}`;
            
            lenClusterList = lenClusterList.length === 1 ? '0' + lenClusterList : lenClusterList
            let item = {
                id: id,
                title:
                    <Grid container>
                        <Typography style={{ flexBasis: '100%' }} noWrap>{lenClusterList + ' ' + e[1]}</Typography>
                    </Grid>,
                body:
                    <Grid container spacing={1}>
                        <Grid item xs={12} lg={6} className={'collapse-unidade'}>
                            <AutoComplete
                                suggestions={this.state.municipioList}
                                itemKey={0}
                                placeholder={'Selecione um município'}
                                itemlabel={1}
                                button={false}
                                getInput={(e) => { this.changeMunicipio(e, id) }} 
                                initialSelectedItem={['']} />
                        </Grid>
                        <Grid item xs={12} lg={6} className={'collapse-unidade'}>
                            <AutoComplete
                                suggestions={[]}
                                itemKey={2}
                                placeholder={'Selecione um bairro'}
                                itemlabel={3}
                                button={true}
                                getInputButton={(e) => { this.addChipBairro(e, id) }} 
                                initialSelectedItem={''} />
                        </Grid>
                    </Grid>,
                trashButton:
                    <Grid container>
                        <GenericButton color={'transparent'} click={(e) => { this.removeItemCluster(id) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </Grid>
            };
            let list = this.state.contentCluster;
            let cluster = this.getClusterModel(e[0]);
            let chipBairroElementList = this.state.chipBairroElementList;
            list.push(item);
            this.idList.push(id);
            this.clusterMentoriaDigitalList.push(cluster);
            chipBairroElementList[id] = [];
            this.setState({ contentCluster: list, errorCluster: false, chipBairroElementList: chipBairroElementList });
        } else if (e != null && e[0] != null && this.idList.includes('u' + e[0])) {
            this.setState({ errorCluster : true });
        }
    };

    addItemGrupo = () => {
        if (this.state.nomeGrupo != null && this.state.nomeGrupo.length > 0 && !this.nomeList.includes(this.state.nomeGrupo.toUpperCase())) {
            let nome = this.state.nomeGrupo.toUpperCase();
            let item = {
                id: nome,
                title:
                    <Grid container>
                        <Typography style={{ flexBasis: '100%' }} noWrap>{nome}</Typography>
                    </Grid>,
                body:
                    <Grid container>
                        <Grid item xs={12} className={'collapse-unidade'}>
                            <AutoComplete
                                suggestions={this.state.unidadeList}
                                itemKey={0}
                                placeholder={'Selecione uma unidade operacional'}
                                itemlabel={1}
                                button={true}
                                getInputButton={(e) => { this.addChipUnidade(e, nome) }} 
                                initialSelectedItem={''} />
                        </Grid>
                    </Grid>,
                trashButton:
                    <Grid container>
                        <GenericButton color={'transparent'} click={(e) => { this.removeItemGrupo(nome) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </Grid>
            };
            let list = this.state.contentGrupo;
            let grupoBrasilMais = this.getGrupoBrasilMaisModel(nome);
            let chipUnidadeElementList = this.state.chipUnidadeElementList;
            list.push(item);
            this.nomeList.push(nome);
            this.grupoMentoriaDigitalList.push(grupoBrasilMais);
            chipUnidadeElementList[nome] = [];
            this.setState({ nomeGrupo: '', contentGrupo: list, error: false, chipUnidadeElementList: chipUnidadeElementList  });
        } else if (this.state.nomeGrupo != null && this.nomeList.includes(this.state.nomeGrupo.toUpperCase())) {
            this.setState({ error : true });
        }
    };

    removeItemCluster = (unidade) => {
        this.showLoading();
        let bairroList = this.state.bairroList;
        let municipioList = this.state.municipioList;
        let index = this.idList.indexOf(unidade);
        this.idList.splice(index, 1)
        this.clusterMentoriaDigitalList[index].clusterBairroMentoriaDigitalList.forEach( element => {
            let bairro = element.bairro.id;
            let indexBairroAtualizar = bairroList.findIndex(aux => { return aux[2] === bairro });
            bairroList[indexBairroAtualizar][4] = false;
            let contagemDosBairrosDoMunicipioIndex = municipioList.findIndex(aux => { return aux[0] === bairroList[indexBairroAtualizar][0] });
            if (bairroList[indexBairroAtualizar][3] === 'TODOS OS BAIRROS') municipioList[contagemDosBairrosDoMunicipioIndex][2] = false;
            else municipioList[contagemDosBairrosDoMunicipioIndex][3] = municipioList[contagemDosBairrosDoMunicipioIndex][3] - 1;
        });
        this.clusterMentoriaDigitalList.splice(index, 1);
        let chipBairroElementList = this.state.chipBairroElementList;
        chipBairroElementList.splice(index, 1);
        let list = this.state.contentCluster;
        list.splice(index, 1);
        this.setState({ chipBairroElementList: chipBairroElementList, contentCluster: list, bairroList: bairroList }, this.closeLoading());
    };

    removeItemGrupo = (nome) => {
        let unidadeList = this.state.unidadeList;
        let index = this.nomeList.indexOf(nome);
        this.nomeList.splice(index, 1);
        this.grupoMentoriaDigitalList[index].grupoUnidadeMentoriaDigitalList.forEach( element => {
            let unidade = element.unidade.id;
            let indexUnidadeAtualizar = unidadeList.findIndex(aux => { return aux[0] === unidade });
            unidadeList[indexUnidadeAtualizar][2] = false;
        });
        this.grupoMentoriaDigitalList.splice(index, 1);
        let chipUnidadeElementList = this.state.chipUnidadeElementList;
        chipUnidadeElementList.splice(index, 1);
        let list = this.state.contentGrupo;
        list.splice(index, 1);
        this.setState({ chipUnidadeElementList: chipUnidadeElementList, contentGrupo: list, unidadeList: unidadeList });
    };

    addChipBairro = (e, unidade) => {
        if (e != null && e[0] != null) {
            let bairroList = this.state.bairroList;
            let municipioList = this.state.municipioList;
            let idMunicipio = e[0];
            let idBairro = e[2];
            let existeEmOutraUnidade = e[4];
            let line = [];
            let index = this.idList.indexOf(unidade);
            let list = this.state.chipBairroElementList;
            let clusterBairro = this.getClusterBairroModel(idBairro);
            let existe = this.clusterMentoriaDigitalList[index].clusterBairroMentoriaDigitalList.findIndex(aux => { return aux.bairro.id === e[2] });
            let contagemDosBairrosDoMunicipioIndex = municipioList.findIndex(aux => { return aux[0] === idMunicipio });
            this.errorBairro = false;
            if (existe < 0 && existeEmOutraUnidade === false && municipioList[contagemDosBairrosDoMunicipioIndex][2] === false && (e[3] !== 'TODOS OS BAIRROS' || municipioList[contagemDosBairrosDoMunicipioIndex][3] === 0)) {
                line.push(e[1]);
                line.push(e[3]);
                line.push(
                    <div key={'bairro-chip-' + idBairro} className={'cluster-bairro-chip'}>
                        <GenericButton color={'transparent'} click={ (e) => { this.removeChipBairro(unidade, idBairro, idMunicipio) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </div>
                );
                list[unidade].push(line);
                this.clusterMentoriaDigitalList[index].clusterBairroMentoriaDigitalList.push(clusterBairro);
                let indexBairroAtualizar = bairroList.findIndex(aux => { return aux[2] === idBairro });
                bairroList[indexBairroAtualizar][4] = true;
                if (e[3] === 'TODOS OS BAIRROS') municipioList[contagemDosBairrosDoMunicipioIndex][2] = true;
                else municipioList[contagemDosBairrosDoMunicipioIndex][3] = municipioList[contagemDosBairrosDoMunicipioIndex][3] + 1;
            } else if (existe >= 0) {
                this.errorBairro = true;
                this.errorBairroMsg = 'Opção já cadastrada nessa unidade.';
            } else if (existeEmOutraUnidade === true) {
                this.errorBairro = true;
                this.errorBairroMsg = 'Opção já cadastrada em outra unidade.';
            } else if (municipioList[contagemDosBairrosDoMunicipioIndex][2] === true) {
                this.errorBairro = true;
                this.errorBairroMsg = 'Todos os bairros desse município já estão cadastrados nessa e/ou em outra unidade.'; 
            } else if (municipioList[contagemDosBairrosDoMunicipioIndex][2] === false && e[3] === 'TODOS OS BAIRROS') {
                this.errorBairro = true;
                this.errorBairroMsg = 'Já existem bairros desse município cadastrados nessa e/ou em outra unidade.';
            }
            this.setState({ chipBairroElementList : list, bairroList: bairroList, municipioList: municipioList }, this.changeMunicipio(e, unidade, true));
        }
    };

    addChipUnidade = (e, nome) => {
        if (e != null && e[0] != null) {
            let unidadeList = this.state.unidadeList;
            let id = e[0];
            let index = this.nomeList.indexOf(nome);
            let list = this.state.chipUnidadeElementList;
            let grupoUnidade = this.getGrupoUnidadeModel(id);
            let existe = this.grupoMentoriaDigitalList[index].grupoUnidadeMentoriaDigitalList.findIndex(aux => { return aux.unidade.id === id });
            let existeEmOutroGrupo = e[2];
            this.errorUnidade = false;
            if (existe < 0 && existeEmOutroGrupo === false) { // não existe ainda na lista nem em outro grupo
                this.grupoMentoriaDigitalList[index].grupoUnidadeMentoriaDigitalList.push(grupoUnidade);
                list[nome].push(
                    <div key={'unidade-chip-' + id} className={'grupo-unidade-chip'}>
                        <label>{e[1]}</label>
                        <GenericButton color={'transparent'} click={ (e) => { this.removeChipUnidade(nome, id) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </div>
                );
                let indexUnidadeAtualizar = unidadeList.findIndex(aux => { return aux[0] === id });
                unidadeList[indexUnidadeAtualizar][2] = true;
            } else if (existe >= 0) { // já existe na lista 
                this.errorUnidade = true;
                this.errorUnidadeMsg = 'Opção já cadastrada nesse grupo.'
            } else if (existeEmOutroGrupo === true) {
                this.errorUnidade = true;
                this.errorUnidadeMsg = 'Opção já cadastrada em outro grupo.'
            }
            this.setState({ chipUnidadeElementList : list, unidadeList: unidadeList }, this.refreshContentGrupo(nome));
        }
    };

    removeChipBairro = (unidade, bairro, municipio) => {
        let bairroList = this.state.bairroList;
        let municipioList = this.state.municipioList;
        let clusterBairroMentoriaDigitalList = this.clusterMentoriaDigitalList[this.idList.indexOf(unidade)].clusterBairroMentoriaDigitalList;
        let index = clusterBairroMentoriaDigitalList.findIndex(aux => { return aux.bairro.id === bairro });
        let chipBairroElementList = this.state.chipBairroElementList;
        clusterBairroMentoriaDigitalList.splice(index, 1);
        this.clusterMentoriaDigitalList[this.idList.indexOf(unidade)].clusterBairroMentoriaDigitalList = clusterBairroMentoriaDigitalList;
        chipBairroElementList[unidade].splice(index, 1);
        let indexBairroAtualizar = bairroList.findIndex(aux => { return aux[2] === bairro });
        bairroList[indexBairroAtualizar][4] = false;
        let contagemDosBairrosDoMunicipioIndex = municipioList.findIndex(aux => { return aux[0] === municipio });
        if (bairroList[indexBairroAtualizar][3] === 'TODOS OS BAIRROS') municipioList[contagemDosBairrosDoMunicipioIndex][2] = false;
        else municipioList[contagemDosBairrosDoMunicipioIndex][3] = municipioList[contagemDosBairrosDoMunicipioIndex][3] - 1;
        this.errorBairro = false;
        this.setState({ chipBairroElementList: chipBairroElementList, bairroList: bairroList }, this.changeMunicipio([municipio], unidade));
    };

    removeChipUnidade = (nome, idUnidade) => {
        let unidadeList = this.state.unidadeList;
        let grupoUnidadeMentoriaDigitalList = this.grupoMentoriaDigitalList[this.nomeList.indexOf(nome)].grupoUnidadeMentoriaDigitalList;
        let index = grupoUnidadeMentoriaDigitalList.findIndex(aux => { return aux.unidade.id === idUnidade });
        let chipUnidadeElementList = this.state.chipUnidadeElementList;
        grupoUnidadeMentoriaDigitalList.splice(index, 1);
        this.grupoMentoriaDigitalList[this.nomeList.indexOf(nome)].grupoUnidadeMentoriaDigitalList = grupoUnidadeMentoriaDigitalList;
        chipUnidadeElementList[nome].splice(index, 1);
        let indexUnidadeAtualizar = unidadeList.findIndex(aux => { return aux[0] === idUnidade });
        unidadeList[indexUnidadeAtualizar][2] = false;
        this.errorUnidade = false;
        this.setState({ chipUnidadeElementList: chipUnidadeElementList, unidadeList: unidadeList }, this.refreshContentGrupo(nome));
    };

    refreshContentCluster(bairrosFiltrados, municipio, unidade) {
        let index = this.idList.indexOf(unidade);
        let contentList = this.state.contentCluster;
        contentList[index].body = (
            <Grid container spacing={1}>
                <Grid item xs={12} lg={6} className={'collapse-bairro'}>
                    <AutoComplete
                        suggestions={this.state.municipioList}
                        itemKey={0}
                        placeholder={'Selecione um município'}
                        itemlabel={1}
                        button={false}
                        getInput={(e) => { this.changeMunicipio(e, unidade) }} 
                        initialSelectedItem={[municipio]} />
                </Grid>
                <Grid item xs={12} lg={6} className={'collapse-bairro'}>
                    <AutoComplete
                        suggestions={bairrosFiltrados}
                        itemKey={2}
                        placeholder={'Selecione um bairro'}
                        itemlabel={3}
                        button={true}
                        getInputButton={(e) => { this.addChipBairro(e, unidade) }} 
                        initialSelectedItem={''} />
                </Grid>
                { this.errorBairro &&
                    <Grid item xs={12} className={'bairro-error'}>
                        <span className={'agrupamento-error'}>{this.errorBairroMsg}</span> 
                    </Grid> }
                <Grid item xs={12} className={'grid-tabela'}>
                    { this.state.chipBairroElementList[unidade] != null && this.state.chipBairroElementList[unidade].length > 0 &&
                        <Table lines={this.state.chipBairroElementList[unidade]} titles={this.titles} initialOrder={'0'} pagination={true} /> }
                </Grid>
            </Grid>
        );
        this.setState({ contentCluster: contentList });
    };

    refreshContentGrupo(nome) {
        let index = this.nomeList.indexOf(nome);
        let contentList = this.state.contentGrupo;
        contentList[index].body = (
            <Grid container>
                <Grid item xs={12} className={'collapse-unidade'}>
                    <AutoComplete
                        suggestions={this.state.unidadeList}
                        itemKey={0}
                        placeholder={'Selecione uma unidade operacional'}
                        itemlabel={1}
                        button={true}
                        getInputButton={(e) => { this.addChipUnidade(e, nome) }} 
                        initialSelectedItem={''} />
                </Grid>
                { this.errorUnidade &&
                    <Grid item xs={12}>
                        <span className={'agrupamento-error'}>{this.errorUnidadeMsg}</span> 
                    </Grid> }
                <Grid item xs={12}>
                    <GridList cellHeight={20} cols={1}>
                        { this.state.chipUnidadeElementList[nome] }
                    </GridList>
                </Grid>
            </Grid>
        );
        this.setState({ contentGrupo: contentList });
    };

    salvarConfiguracao = () => {
        this.showLoading();
        this.configuracaoMentoriaDigital.grupoMentoriaDigitalList = this.grupoMentoriaDigitalList;
        this.configuracaoMentoriaDigital.clusterMentoriaDigitalList = this.clusterMentoriaDigitalList;
        API.post('rest/sistema/ConfiguracaoMentoriaDigital/' + this.props.usuarioConectado.unidade.id, this.configuracaoMentoriaDigital).then(res => {
            this.showToast('Configurações salvas com sucesso.', 'success');
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao realizar POST dos agrupamentos. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    render() {
    	return(
    		<Page icon={'brasil-mais.svg'} label={'Mentoria Digital'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
    			<Grid container spacing={1} className={'top-container'} justify={'space-between'}>
                        <Grid item>
                        <Grid container style={{marginTop: '10px'}} direction={'row'} justify={'flex-start'}>
                            <Grid item>
                                <Link to={'/brasilmais/turmasmentoriadigital'} style={{ cursor: "pointer" }}>
                                    <Breadcrumb label={'Gerenciar Turmas'} icon={'breadcrumb-blue.svg'} color={'primary'} />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link to={'/brasilmais/mentoriadigital'} style={{ cursor: "pointer" }}>
                                    <Breadcrumb label={'Setup UO\'s'} icon={'breadcrumb-gray-inverted.svg'} color={'primary'} />
                                </Link>
                            </Grid>
                            <Grid item><Breadcrumb label={'Configurações UO\'s'} icon={'breadcrumb-gray.svg'} color={'secondary'} /></Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                <Grid container className={'configuracao-mentoria-digital'} direction='column' justify='center'>
                    <Grid item className='tabs'>
                        <AppBar position='static'>
                            <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                                <Tab key={'configuracaoTab0'} value={0} label={'Regiões UO\'s Brasil Mais'}></Tab>
                                <Tab key={'configuracaoTab1'} value={1} label={'Compartilhamento de Atendimentos'}></Tab>
                            </Tabs>
                        </AppBar>
                    </Grid>
                    <Grid container justify={'center'} className={'grid-margin-top'}>
                        { this.state.tab === 0 && 
                            <Grid item xs={12} sm={10} md={7} xl={5}>
                                <Grid container justify={'center'} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <Grid item xs={12} md={9} xl={8}>
                                        <AutoComplete
                                            suggestions={this.state.unidadeList}
                                            itemKey={0}
                                            label={'Unidade operacional'}
                                            itemlabel={1}
                                            button={true}
                                            getInputButton={(e) => { this.addItemCluster(e) }}
                                            initialSelectedItem={''} />
                                    </Grid>
                                    { this.state.errorCluster && 
                                        <Grid item xs={9} xl={8} style={{ marginBottom: '10px' }}>
                                            <span className={'agrupamento-error'}>Unidade já existe na listagem abaixo.</span> 
                                        </Grid> }
                                    <Grid container className={'collapse-unidade'}>
                                        <Grid item xs={12}>
                                            { this.state.contentCluster.length > 0 ? 
                                                <Collapse id='content-cluster' content={this.state.contentCluster} isExpanded={true} /> :
                                                <EmptyList type={'noItens'} msg={'Ainda não há itens cadastrados'} /> }
                                        </Grid>
                                    </Grid> 
                                </Grid>
	                        </Grid> }
                        { this.state.tab === 1 && 
                            <Grid item xs={12} sm={10} md={7} xl={5}>
                                <Grid container justify={'center'} style={{ display: 'flex', alignItems: 'flex-end' }}>
                                    <Grid item xs={10} md={8} xl={7}>
                                        <FormField type={'text'} label={'Nome do agrupamento'} id={'agrupamento'}
                                            inputValue = {this.state.nomeGrupo}
                                            changeValue = {(e) => { this.setState({ nomeGrupo: e.target.value }); }}
                                            changeValueBlur={(e) => { this.setState({ nomeGrupo: e.target.value }); }} />
                                    </Grid>
                                    <Grid item xs={1} style={{ marginBottom: '10px' }}>
                                        <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addItemGrupo} icon={'plus.svg'} />
                                    </Grid>
                                    { this.state.error && 
                                        <Grid item xs={9} xl={8} style={{ marginBottom: '10px' }}>
                                            <span className={'agrupamento-error'}>Já existe um grupo com esse nome.</span> 
                                        </Grid> }
                                    <Grid container justify={'center'} className={'collapse-unidade'}>
                                        <Grid item xs={12}>
                                            { this.state.contentGrupo.length > 0 ?
                                                <Collapse id='content-grupo' content={this.state.contentGrupo} isExpanded={true} /> :
                                                <EmptyList type={'noItens'} msg={'Ainda não há itens cadastrados'} /> }
                                        </Grid>
                                    </Grid> 
                                </Grid>
                            </Grid> }
                    </Grid>
                    <Grid item xs={12} className={'grid-salvar-container'}>
                        <Grid container justify={'center'}>
                            <Grid item xs={12} md={2}>
                                <Link to={'/brasilmais/mentoriadigital'}>
                                    <GenericButton label={'Voltar'} subClass={'basic-button darkGrey-outline'} click={(e) => {}} />
                                </Link>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button button-form'} click={this.salvarConfiguracao} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
    		</Page>
    	);
    };
}