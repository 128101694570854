import React, {Component} from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table';
import {Grid} from "@material-ui/core";
import Page from '../../components/page/page';
import API from '../../comm/API';
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';
import './cliente.scss';

export default class Cliente extends Component {

    titles = [{ id: '0', canOrder: true, label: 'NOME/RAZÃO SOCIAL' }, { id: '1', canOrder: false, label: 'CPF/CNPJ' },
        { id: '3', canOrder: true, label: 'TIPO CLIENTE' }, { id: '4', canOrder: false,  label: 'CONTATO' }, { id: '5', canOrder: false,  label: ''}];
    clientes = [];
    isDN = false;

    constructor(props) {
        super(props);
        this.isDN = this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN' ? true : false;
        this.state = { clientes: [], filter: '', drawer: false, colaborador: null, colaboradorUrl: '', emptyList: 'seeResults' };
    }

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };
    
    changeBusca = (e) => {
        this.setState({filter: e.target.value});
    };

    getClientes = () => {
        this.showLoading();
        let cnpj = this.state.filter.replace(/\.|\-|\//g, ''); //eslint-disable-line
        let filter = isNaN(cnpj) ? this.state.filter : cnpj;
        API.get('rest/pessoa/Cliente/find?busca=' + filter).then(res => {
            this.clientes = res.data;
            for(var i = 0; i < this.clientes.length ; i++) {                
                var id = this.clientes[i][this.clientes[i].length-1];
                var contatos = this.clientes[i][4].length > 0 ? this.clientes[i][4][0].split('|')[0].split(',') : []; //seleciona apenas um contato para aparecer
                this.clientes[i][0] = (
                    <div>
                        {this.clientes[i][0]}
                        <br></br>
                        <div className={'smaller-font'}>
                            {this.clientes[i][2]}
                        </div>
                    </div>
                );
                this.clientes[i][this.clientes[i].length-1] = ( 
                    <div style={{ display: 'flex' }}>
                        <Link to={'/cad/pessoa/cliente/' + id}>
                            <GenericButton color={'transparent'} subClass={'icon-button'} icon={'pen.svg'} />
                        </Link>
                    </div>
                );
                if (contatos.length > 0) {
                    this.clientes[i][4] = (
                        <div>
                            {contatos[0]}
                            <br></br>
                            <div className={'smaller-font'}>
                                {contatos[1]}
                            </div>
                        </div>
                    );  
                }                                      
            }
            this.setState({ clientes: this.clientes });
            this.setState({ emptyList: this.clientes.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro no GET de clientes. Por favor, entre em contato com o suporte.', 'error');
        });        
    }


    render() {
        return (
            <Page icon={'client.svg'} label={'Gerenciar Clientes'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'cliente'}>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} xl={5} className={'container-busca'}>
                                <Grid>
                                    <Link to={'/cad/pessoa/cliente/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Novo Cliente'} subClass={'basic-button'} />
                                    </Link>
                                </Grid>

                                <Grid className={'formfield'}>
                                    <FormField type={'text'} id={'busca'} pressEnter={this.getClientes} button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                        icon={'search.svg'} click={this.getClientes} />} inputValue={this.state.filter.buscaLivre} changeValue={this.changeBusca}
                                        placeholder={'busque por nome, cpf/cnpj ou município'} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.clientes} titles={this.titles} initialOrder={'0'} />
                        <EmptyList type={this.state.emptyList} seeAll={this.getClientes}/>  
                    </Grid>
                </Grid>
            </Page>
        );
    }
}