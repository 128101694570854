import React, { Component } from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import EmptyList from '../../components/emptyList/emptyList';
import Tabela from '../../components/table/table';
import { Grid } from '@material-ui/core';
import Page from './../../components/page/page';
import API from './../../comm/API';
import './apropriar.scss';
import TableContext from './../../comm/context';
import ProgressBar from './../../components/progressbar/progressbar';
import SimpleModal from '../../components/modal/modal';

export default class Apropriar extends Component {

    lines = [];
    titles = [
        { id: '0', canOrder: true, label: 'Id' },
        { id: '1', canOrder: true, label: 'Nº' },
        { id: '2', canOrder: true, label: 'Título' },
        { id: '3', canOrder: true, label: 'Cliente' },
        { id: '4', canOrder: false, label: 'Produção' },
        { id: '5', canOrder: false, label: 'Prod. Realizada/Prevista' }];
    sidebar = [{ perc: 0, cliente: 'vazio', temProducao: 'none' }, { perc: 0, cliente: 'vazio', temProducao: 'none' },
    { perc: 0, cliente: 'vazio', temProducao: 'none' }, { perc: 0, cliente: 'vazio', temProducao: 'none' }];
    producaoApropriadaList = [];

    constructor(props) {
        super(props);
        var data = new Date().toLocaleDateString().split('/');

        this.montarTabelaInvalida = this.montarTabelaInvalida.bind(this);
        this.state = {
            dataApropriacao: data[2] + '-' + data[1] + '-' + data[0], busca: '',
            sidebar: this.sidebar, totalProducao: '0h', colaborador: null, usuario: null, atendimentoList: [],
            horasProducao: 0, idAtendimento: 0, emptyList: 'seeResults', openModal: false, dataInvalida: '', isInvalid: false,
            openModelProducaoRepetida: false
        };
        this.initDataApropriacao();
    };

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    async componentWillMount() {
        await this.montarSidebar(this.props.usuarioConectado.colaborador);
        this.closeLoading();
    }

    producaoApropriadaModel(atendimento, usuario, colaborador, valorApropriado, valorRelatorio) {
        return {
            dataDeApropriacao: this.state.dataApropriacao, quantidadeApropriadas: valorApropriado, quantidadeRelatorio: valorRelatorio,
            colaborador: colaborador, atendimento: atendimento, usuario: usuario
        };
    }

    initDataApropriacao = () => {
        var data = new Date();
        var ano = data.getFullYear();
        var mes = data.getMonth();
        var dia = data.getDate();

        if (dia >= 1 && dia <= 7) {
            if (mes === 0) {
                data = new Date(ano - 1, 11, 1).toLocaleDateString().split('/');
                this.firstDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + data[0];
            } else {
                data = new Date(ano, mes - 1, 1).toLocaleDateString().split('/');
                this.firstDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + data[0];
            }
        } else {
            data = new Date(ano, mes, 1).toLocaleDateString().split('/');
            this.firstDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + data[0];
        }
        data = new Date(ano, mes, dia).toLocaleDateString().split('/');
        this.lastDataApropriacaoProducao = data[2] + '-' + data[1] + '-' + data[0];
        this.setState(this.state);
    }

    async montarSidebar(colaborador) {
        let idColoborador = 0;

        if (colaborador !== null) {
            idColoborador = colaborador.id;
        }

        try {
            let res = await API.get(`rest/gestao/ProducaoApropriada/find/${idColoborador}`);
            var producaoMensalList = res.data;
            var totalProducaoMensal = 0;
            var totalRelatorioMensal = 0;
            var clienteList = [];
            if (producaoMensalList != null && producaoMensalList !== []) {
                for (var i = 0; i < producaoMensalList.length; i++) {

                    const index = clienteList.findIndex(e => e.id === producaoMensalList[i][3]); // eslint-disable-line

                    producaoMensalList[i][2] = producaoMensalList[i][2] === null ? 0 : producaoMensalList[i][2]; // Altera os valores nulos para 0
                    if (index >= 0) {
                        clienteList[index].valor = clienteList[index].valor + producaoMensalList[i][1];
                        clienteList[index].valorRelatorio = clienteList[index].valorRelatorio + producaoMensalList[i][2];
                    } else {
                        clienteList.push({
                            id: producaoMensalList[i][3], valor: producaoMensalList[i][1], valorRelatorio: producaoMensalList[i][2],
                            cliente: producaoMensalList[i][4], perc: 0
                        });
                    }
                    totalProducaoMensal = totalProducaoMensal + parseFloat(producaoMensalList[i][1]);
                    totalRelatorioMensal = totalRelatorioMensal + parseFloat(producaoMensalList[i][2]);
                }
                clienteList.sort((a, b) => (a.valor < b.valor) ? 1 : -1);
                clienteList.slice(0, 3);
                clienteList.map(x => x.perc = ((x.valor + x.valorRelatorio) / (totalProducaoMensal + totalRelatorioMensal)) * 100);
                var percTotal = 0;
                for (i = 0; i < 3; i++) {
                    if (clienteList[i] != null) {
                        this.sidebar[i].perc = clienteList[i].perc.toFixed(2);
                        this.sidebar[i].cliente = clienteList[i].cliente;
                        this.sidebar[i].valor = clienteList[i].valor + (colaborador.isLaboratorio ? ' ensaios' : ' horas');
                        this.sidebar[i].valorRelatorio = clienteList[i].valorRelatorio + ' laudos';
                        this.sidebar[i].temProducao = 'inline';
                        percTotal = percTotal + clienteList[i].perc;
                    }
                }
                this.sidebar[i].perc = percTotal > 99 ? 0 : (100 - percTotal).toFixed(2);
                this.sidebar[i].cliente = 'OUTROS';
                this.sidebar[i].temProducao = this.sidebar[i].perc > 0 && totalProducaoMensal > 0 ? 'inline' : 'none';
                this.setState({ producaoTotal: (totalProducaoMensal + totalRelatorioMensal).toLocaleString() });
            }

        } catch (error) {
            this.showToast('Erro ao tentar montar o sidebar. Contate o suporte.', 'error');
        }
    }

    aplicarFiltro = () => {
        this.showLoading();
        var filtro = '';
        var colaborador = this.props.usuarioConectado.colaborador;

        if (this.state.busca != null && this.state.busca !== '')
            filtro = 'atendimento.buscaLivreAtendimento=' + this.state.busca + '&';
        if (colaborador != null) {

            filtro += 'unidade.id=' + colaborador.unidade.id + '&';

            if (colaborador.colaboradorProdutoRegionalList == null || colaborador.colaboradorProdutoRegionalList.length === 0) {
                filtro += 'produtoRegional.id=' + 0 + '&';
            } else if (colaborador.colaboradorProdutoRegionalList.length > 0) {
                for (var index = 0; index < colaborador.colaboradorProdutoRegionalList.length; index++) {
                    if (colaborador.colaboradorProdutoRegionalList[index].produtoRegional != null)
                        filtro += 'produtoRegional.id=' + colaborador.colaboradorProdutoRegionalList[index].produtoRegional.id + '&';
                }
            }
        } else {
            filtro += 'produtoRegional.id=' + 0 + '&';
            filtro += 'unidade.id=' + 0 + '&';
        }
        if (colaborador.isLaboratorio) filtro += 'produtoLinha.CR=\'3\'&';
        else filtro += 'produtoLinha.CR<>\'3\'&';
        filtro += 'atendimentoStatus.id=4&atendimentoStatus.id=8';
        API.get('/rest/gestao/Atendimento/find?' + filtro).then(res => {
            this.producaoApropriadaList = [];
            this.lines = [];
            this.setState(this.state);
            this.montarTabela(res.data);
            this.setState({ horasProducao: '', idAtendimento: '', atendimentoList: res.data, emptyList: res.data.length === 0 ? 'noResults' : '' });
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao carregar lista de atendimentos. Por favor, entre em contato com o suporte.', 'error');
        });
    }

    montarTabela = async (atdList) => {

        atdList.forEach(atd => {
            this.lines.push([atd[0], atd[1], atd[5], atd[2],
            this.getInputNumber(atd[0]), this.getProgressBar(atd)]);
        });

        this.setState(this.state);
        this.closeLoading();

    }

    montarTabelaInvalida = (dataApropriacao) => {
        var isInvalid = '';
        this.lines.forEach((linha, index, obj) => {
            var producao = this.producaoApropriadaList.find(function (producao) { return producao.atendimento.id === linha[0] ? producao : null; });
            if (new Date(linha[4][6]) > new Date(dataApropriacao)) {
                linha[4][2] = 'Data de apropriação inválida.';
                linha[4][3] = 'Não é possível apropriar devido a data de aceitação do atendimento.';
                linha[4][4] = true;
                linha[4][5] = 0;
                isInvalid = 'Não é possível apropriar devido a data de aceitação de alguns atendimentos';
                if (producao != null) this.producaoApropriadaList.splice(this.producaoApropriadaList.indexOf(producao), 1);
            } else {
                linha[4][2] = '';
                linha[4][3] = '';
                linha[4][4] = false;
                linha[4][5] = 0;
                if (producao != null) {
                    producao.dataDeApropriacao = dataApropriacao; //atualiza data de apropriacao
                    producao.quantidadeRelatorio = 0;
                    linha[4][5] = producao.quantidadeApropriadas;
                }
            }
        });
        this.setState({ dataInvalida: isInvalid });
    }

    getProducoesApropriadas = async (idColaborador, dataApropriacao, quantApropriadas) => {
        let producoes = (await API.get(
            `rest/gestao/ProducaoApropriada?colaborador.id=${idColaborador}&dataDeApropriacao=${dataApropriacao}&quantidadeApropriadas=${quantApropriadas}`
        )).data;
        return producoes;
    }

    verifyProducaoRepetida = async () => {
        if (this.producaoApropriadaList != null && this.producaoApropriadaList.length > 0) {
            let idColaborador = this.props.usuarioConectado.colaborador.id;
            for (let producao of this.producaoApropriadaList) {
                producao.dataDeApropriacao = this.formatData(this.state.dataApropriacao) + 'T00:00:00';
                let response = await this.getProducoesApropriadas(idColaborador, this.state.dataApropriacao, producao.quantidadeApropriadas);
                if (response.length > 0) {
                    this.setState({ openModelProducaoRepetida: response.length > 0 });
                    return;
                }
            }
        } else {
            this.showToast('Lista de apropriações vazia.', 'error');
        }
    }

    salvarProducao = () => {
        this.showLoading();
        var index = 0;
        if (this.producaoApropriadaList !== [] && this.producaoApropriadaList != null) {
            this.producaoApropriadaList.forEach(producao => {
                producao.dataDeApropriacao = this.formatData(this.state.dataApropriacao) + 'T00:00:00';
                API.post('rest/gestao/ProducaoApropriada', producao).then(async res => {
                    index = index + 1;
                    if (index === this.producaoApropriadaList.length) {
                        this.producaoApropriadaList = [];
                        await this.setState({ openModal: false, openModelProducaoRepetida: false, horasProducao: '', idAtendimento: '' });
                        this.showToast('Produções salvas com sucesso!', 'success');
                        this.montarSidebar(this.props.usuarioConectado.colaborador);
                        this.aplicarFiltro();
                    }
                }).catch(error => {
                    this.closeLoading();
                    const errDataAceitacao = error.response.data.message
                    this.showToast(errDataAceitacao.substr(26).startsWith("Não") ? errDataAceitacao.substr(26) : 'Error ao salvar produção apropriada. Favor, entrar em contato com o suporte.', 'error');
                });
            });
        } else {
            this.closeLoading();
            this.showToast('Lista de apropriações vazia.', 'error');
        }
    }

    verifySaveProducao = async () => {
        await this.verifyProducaoRepetida();
        if (!this.state.openModelProducaoRepetida) {
            this.salvarProducao();
        }
    }

    formatData(date) {
        if (date != null) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');;
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    }
    
    changeData = async (e) => {
        var novaData = e.target.value.split('-');
        var isInvalid = false;
        this.producaoApropriadaList.forEach(obj => {
            let dataAceitacao = new Date(obj.atendimento.dataAceitacao).toLocaleDateString('fr-CA'); // fr-CA para formatar em yyyy-mm-dd
            if (e.target.value < dataAceitacao) {
                isInvalid = true;
            }
        })

        this.setState({dataInvalida : isInvalid ? 'Não é possível apropriar devido à data de aceitação de alguns atendimentos.' : ''});
        await this.setState({ dataApropriacao: novaData[0] + '-' + novaData[1] + '-' + novaData[2] });
    }

    changeBusca = (e) => {
        this.setState({ busca: e.target.value });
    }

    changeProducaoApropriada = async (e) => {

        var producaoNova = 0;
        producaoNova = Number(e.target.value.replace(/\D/g, "").replace(/[,.+-]+/g, ""));
        if (e.target.name === 'producao') {
            document.getElementById(e.target.id).value = producaoNova;
        } else if (e.target.name === 'ensaio') {
            document.querySelectorAll("[id='" + e.target.id + "']")[0].value = producaoNova;
        } else {
            document.querySelectorAll("[id='" + e.target.id + "']")[1].value = producaoNova;
        }
        var producao = this.producaoApropriadaList.find(function (producao) { //atualizar valor da producao
            if (producao.atendimento.id === Number(e.target.id)) {
                e.target.name === 'laudo' ? producao.quantidadeRelatorio = producaoNova : producao.quantidadeApropriadas = producaoNova;
            }
            return producao.atendimento.id === Number(e.target.id) ? producao : null;
        });
        if (producao == null && producaoNova > 0) { //cria producao se nao existir
            var atd = this.state.atendimentoList.find(function (atd) { return atd[0] === Number(e.target.id) ? atd : null });
            //transformando o atendimento em objeto para salvar no banco.. 
            let atdObject = { id: atd[0], dataAceitacao: atd[8] };
            const valorRelatorio = e.target.name === 'laudo' ? producaoNova : 0;
            const valorApropriado =  e.target.name === 'laudo' ? 0 : producaoNova;
            producao = this.producaoApropriadaModel(atdObject, this.props.usuarioConectado, this.props.usuarioConectado.colaborador, valorApropriado, valorRelatorio);
            this.producaoApropriadaList.push(producao);
        } else if (producao != null && producaoNova <= 0) { //remove producao se valor <= 0
            var index = this.producaoApropriadaList.indexOf(producao)
            document.getElementById(e.target.id).value = "";
            this.producaoApropriadaList.splice(index, 1);
        }
        //atualizando variáveis que serão utilizadas para atualizar o component progressbar.

        var isInvalid = false;
        this.producaoApropriadaList.forEach(pro => {
            if (e.target.name === 'laudo') {
                if (pro.quantidadeRelatorio > 99999 || pro.quantidadeRelatorio <= 0) isInvalid = true
            } else {
                if (pro.quantidadeApropriadas > 99999 || pro.quantidadeApropriadas <= 0) isInvalid = true
            }
        });
        await this.setState({ horasProducao: e.target.value, idAtendimento: `${e.target.id}_${e.target.name}`, isInvalid: isInvalid });
    }

    getInputNumber(id) {
        //gerando um número aleatório para compor a key para obrigar o react a atualizar o componente.
        let random = Math.ceil(Math.random() * 9000000);

        if (this.props.usuarioConectado.colaborador && this.props.usuarioConectado.colaborador.isLaboratorio) {
            return (<Grid>
                <FormField key={`${id}_ensaio_field_${random}`} type={'text'} label={'Ensaios'} changeValue={this.changeProducaoApropriada}
                    id={id} name={'ensaio'} subClassLabel={'label-table'} maxLength={'5'} />
                <FormField key={`${id}_producao_bar_${random}`} type={'text'} label={'Laudos'} changeValue={this.changeProducaoApropriada}
                    id={id} name={'laudo'} subClassLabel={'label-table'} maxLength={'5'} />
            </Grid>);
        } else {
            return (<FormField key={`${id}_producao_field_${random}`} type={'text'} changeValue={this.changeProducaoApropriada} id={id} name={'producao'}
                subClass={'input-table'} maxLength={'5'} />);
        }
    }

    getProgressBar(atd) {
        if (this.props.usuarioConectado.colaborador && this.props.usuarioConectado.colaborador.isLaboratorio) {
            return (
                <Grid>
                    <ProgressBar key={`${atd[0]}_ensaio_bar`} idAtendimento={`${atd[0]}_ensaio`} total={atd[14] ? atd[14] : 0} producao={atd[7]} />
                    <ProgressBar key={`${atd[0]}_laudo_bar`} idAtendimento={`${atd[0]}_laudo`} total={atd[15] ? atd[15] : 0} producao={atd[9]} />
                </Grid>
            );
        } else {
            return (
                <Grid style={{ marginBottom: '12px', width: '80%', fontSize: '11pt', fontWeight: '600' }}>
                    <ProgressBar key={`${atd[0]}_producao`} idAtendimento={`${atd[0]}_producao`} total={atd[14] ? atd[14] : 0} producao={atd[7]} unitEnd={'h'} />
                </Grid>
            );
        }
    }

    render() {
        return (
            <Page icon={'clock.svg'} label={'Produção Individual'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'apropriar'}>
                    <Grid item xs={12} sm={12} md={8} lg={9} xl={10}>
                        <Grid container>
                            <Grid container spacing={3} style={{ justifyContent: 'space-between' }}>
                                <Grid item xs={12} className={'pesquisa'}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={5} xl={4}>
                                            <FormField type={'text'} placeholder={'busque por ID, título, número ou cliente'}
                                                changeValue={this.changeBusca}
                                                pressEnter={this.aplicarFiltro}
                                                id={'busca'}
                                                button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                                    icon={process.env.PUBLIC_URL + 'search.svg'}
                                                    click={this.aplicarFiltro} />} />
                                        </Grid>
                                        <Grid item xs={4} className={'total-atendimentos'}>
                                            <label>Total de atendimentos: <b>{this.lines.length}</b></label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <TableContext.Provider value={{ ...this.state }}><Tabela lines={this.lines} titles={this.titles} initialOrder={'2'} />  </TableContext.Provider>
                                <EmptyList type={this.state.emptyList} seeAll={this.aplicarFiltro} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={3} xl={2} className={'sidebar'}>
                        <Grid container>
                            {Number(this.state.producaoTotal) > 0 ?
                                <Grid item xs={12} className={'porcentagens producao'}>
                                    <Grid container className={'porcentagem'}>
                                        <Grid item xs={12} style={{ marginTop: '28px', height: this.sidebar[0].perc + '%', display: this.sidebar[0].temProducao, minHeight: '5%' }}>
                                            <div className={'dark'}>
                                                <label>
                                                    {this.sidebar[0].perc}% {this.sidebar[0].cliente}<br />
                                                    <strong>
                                                        {this.sidebar[0].valor}
                                                        {this.props.usuarioConectado.colaborador.isLaboratorio ? ' / ' +this.sidebar[0].valorRelatorio : ''}
                                                    </strong>
                                                </label>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} style={{ height: this.sidebar[1].perc + '%', display: this.sidebar[1].temProducao, minHeight: '5%' }}>
                                            <div className={'light'}>
                                                <label>
                                                    {this.sidebar[1].perc}% {this.sidebar[1].cliente}<br />
                                                    <strong>
                                                        {this.sidebar[1].valor}
                                                        {this.props.usuarioConectado.colaborador.isLaboratorio ? ' / ' +this.sidebar[1].valorRelatorio : ''}
                                                    </strong>
                                                </label>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} style={{ height: this.sidebar[2].perc + '%', display: this.sidebar[2].temProducao, minHeight: '5%' }}>
                                            <div className={'dark'}>
                                                <label>
                                                    {this.sidebar[2].perc}% {this.sidebar[2].cliente}<br />
                                                    <strong>
                                                        {this.sidebar[2].valor}
                                                        {this.props.usuarioConectado.colaborador.isLaboratorio ? ' / ' +this.sidebar[2].valorRelatorio : ''}
                                                    </strong>
                                                </label>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} style={{ height: this.sidebar[3].perc + '%', display: this.sidebar[3].temProducao, minHeight: '5%' }}>
                                            <div className={'others'}><label>{this.sidebar[3].perc}% Outros</label></div>
                                        </Grid>
                                    </Grid>
                                </Grid> :
                                <Grid item xs={12} className={'porcentagens producao porcentagem vazio'}>
                                    <label style={{color: '#00569D'}}>Insira ao lado as horas trabalhadas em cada projeto</label>
                                </Grid>
                            }
                            <Grid item xs={12} className={'informacoes'}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <h4>A data limite de lançamento da produção é o último dia do mês</h4>
                                    </Grid>
                                </Grid>
                               
                                    <Grid item xs={12} className={'total'}>
                                        <b>
                                            {!this.props.usuarioConectado.colaborador.isLaboratorio ? ('Total: ' + this.state.producaoTotal + ' horas') : ('Total: ' + this.state.producaoTotal + ' itens')}
                                        </b>
                                    </Grid>
                                    <Grid item xs={12} className={'botao'}>
                                        <GenericButton color={'darkBlue'} label={'Salvar Produção'} disabled={this.producaoApropriadaList.length === 0 || this.state.isInvalid}
                                            subClass={'basic-button'} click={() => this.setState({ openModal: true })} />
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <SimpleModal
                        isOpen={this.state.openModal}
                        handleClose={() => this.setState({ openModal: false })}
                        type={'info'}
                        width={'400px'}>
                        <div className={'modal modal-apropriar'}>
                            <h2>Salvar produção</h2>
                            <label style={{ marginBottom: '15px' }}>A apropriação de produção que você deseja fazer é referente a data:</label>
                            <div style={{ width: '100%' }}>
                                <FormField type={'date'}
                                    ref='dataApropriacao'
                                    id={'dataApropriacao'}
                                    inputValue={this.state.dataApropriacao}
                                    changeValue={this.changeData}
                                    icon={'calendar.svg'}
                                    error={this.state.dataInvalida}
                                    min={this.firstDataApropriacaoProducao}
                                    max={this.lastDataApropriacaoProducao} />
                            </div>
                            <div className={'rodape'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.setState({ openModal: false })} />
                                <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} disabled={this.state.dataInvalida !== ''} click={() => { this.verifySaveProducao(); }} />
                            </div>
                        </div>
                    </SimpleModal>
                    { this.state.openModelProducaoRepetida &&
                        <SimpleModal
                            isOpen={this.state.openModelProducaoRepetida}
                            handleClose={this.clearChanges}
                            type={'warning'}
                            width={'500px'}>
                            <div className={'modal'}>
                                <Grid container className={'modal-change-status'}>
                                    <p>Você está apropriando a mesma quantidade de horas na mesma data em alguns atendimentos. Você confirma a apropriação?</p>
                                    <Grid item xs={12} className={'rodape'}>
                                        <GenericButton
                                            color={'darkGrey-outline'}
                                            label={'Cancelar'}
                                            subClass={'basic-button'}
                                            click={() => { this.setState({ openModelProducaoRepetida: false }) }} />
                                        <GenericButton color={'darkBlue'}
                                            label={'Sim, confirmo'}
                                            subClass={'basic-button'}
                                            click={() => this.salvarProducao()} />
                                    </Grid>
                                </Grid>
                            </div>
                        </SimpleModal> }
                </Grid>
            </Page>
        );
    }
}
