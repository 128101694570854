import React, { Component } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { Grid } from "@material-ui/core";
import './atividadeAvaliacaoDoAtendimento.scss';

export default class AtividadeAvaliacaoDoAtendimento extends Component {

    constructor(props) {
      super(props);

      let avaliacaoDigital = props.atividade;

      avaliacaoDigital.pontuacaoAtendimento = avaliacaoDigital.pontuacaoAtendimento || 0;
      avaliacaoDigital.pontuacaoCapacidadeTeorica = avaliacaoDigital.pontuacaoCapacidadeTeorica || 0;
      avaliacaoDigital.pontuacaoAplicacaoMetodologia = avaliacaoDigital.pontuacaoAplicacaoMetodologia || 0;
      avaliacaoDigital.pontuacaoTempoExecucaoMetodologia = avaliacaoDigital.pontuacaoTempoExecucaoMetodologia || 0;
      avaliacaoDigital.pontuacaoCustoProduto = avaliacaoDigital.pontuacaoCustoProduto || 0;
      avaliacaoDigital.pontuacaoResultados = avaliacaoDigital.pontuacaoResultados || 0;
      avaliacaoDigital.pontuacaoExpectativas = avaliacaoDigital.pontuacaoExpectativas || 0;
      avaliacaoDigital.pontuacaoResultadosMantidos = avaliacaoDigital.pontuacaoResultadosMantidos || 0;

      this.state = {
        avaliacaoDigital: props.atividade,
      };
    };


    changeInput = (e) => {
      let avaliacaoDigital = this.state.avaliacaoDigital;
      let errors = this.state.errors;
      if (e && e.target && e.target.value) {
          if (e.target.id) {
            avaliacaoDigital[e.target.id] = e.target.value;
              if (e.target.required) {
                  if (e.target.value)
                      errors[e.target.id] = '';
                  else
                      errors[e.target.id] = 'Campo obrigatório.';
              } else
                  errors[e.target.id] = '';
          }
          else if (e.target.name) {
            avaliacaoDigital[e.target.name] = e.target.value;
          }
          this.setState({ avaliacaoDigital: avaliacaoDigital, errors: errors });
      }
    };

    renderBody = () => {
      let avaliacaoDigital = this.state.avaliacaoDigital;

      return (
        <Grid container justify={'center'} style={{ marginTop: '1%' }}>
          <Grid item xs={12}>
            <label className='label-title'>Responda as perguntas abaixo para avaliar o atendimento - após salvar a atividade o mentor/consultor não terá acesso às suas respostas.</label>
          </Grid>
          <Table id="table-satisfaction" key="table-satisfaction" className='table-content'>
            <TableHead>
              <TableRow className='table-avaliacao-atendimento-linha'>
                <TableCell style={{ width: '473px'}}>
                  <span className='table-avaliacao-atendimento-question-header'>
                    Avalie seu nível de satisfação utilizando a escala a seguir:
                  </span>
                </TableCell>
                <TableCell className='table-avaliacao-atendimento-cell-header'>
                  <span>nada satisfeito</span>
                  1
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header'>
                  <span>pouco satisfeito</span>
                  2
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header'>
                  <span>satisfeito</span>
                  3
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header'>
                  <span>muito satisfeito</span>
                  4
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header'>
                  <span>totalmente satisfeito</span>
                  5
                </TableCell>
              </TableRow>
            </TableHead>


            <TableBody key='table-satisfaction' >
              <RadioGroup 
                row
                value={avaliacaoDigital.pontuacaoAtendimento} 
                onChange={({target}) => { 
                  avaliacaoDigital.pontuacaoAtendimento = +target.value;
                  this.setState({ avaliacaoDigital });
                }}
              >
                <TableRow className='table-avaliacao-atendimento-linha row-pair'>
                  <TableCell className='table-avaliacao-atendimento-question'>Qual o seu nível de satisfação com o atendimento realizado pelo(s) consultor(es)?</TableCell>

                    <TableCell className="table-avaliacao-atendimento-cell-body">
                      <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                    </TableCell>

                    <TableCell className="table-avaliacao-atendimento-cell-body">
                      <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                    </TableCell>

                    <TableCell className="table-avaliacao-atendimento-cell-body">
                      <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                    </TableCell>

                    <TableCell className="table-avaliacao-atendimento-cell-body">
                      <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                    </TableCell>

                    <TableCell className="table-avaliacao-atendimento-cell-body">
                      <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                    </TableCell>

                </TableRow>   
              </RadioGroup>

              <RadioGroup 
                row
                value={avaliacaoDigital.pontuacaoCapacidadeTeorica} 
                onChange={({target}) => { 
                  avaliacaoDigital.pontuacaoCapacidadeTeorica = +target.value;
                  this.setState({ avaliacaoDigital });
                }}
              >
                <TableRow className='table-avaliacao-atendimento-linha'>
                  <TableCell className='table-avaliacao-atendimento-question'>Qual o seu nível de satisfação com a capacitação teórica?</TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                  </TableCell>
                </TableRow>   

              </RadioGroup>


              <RadioGroup 
                row
                value={avaliacaoDigital.pontuacaoAplicacaoMetodologia} 
                onChange={({target}) => { 
                  avaliacaoDigital.pontuacaoAplicacaoMetodologia = +target.value;
                  this.setState({ avaliacaoDigital });
                }}
              >
                <TableRow className='table-avaliacao-atendimento-linha row-pair'>
                  <TableCell className='table-avaliacao-atendimento-question'>Qual o seu nível de satisfação com a aplicação da metodologia na empresa?</TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                  </TableCell>
                </TableRow>   
              </RadioGroup>


              <RadioGroup 
                row
                value={avaliacaoDigital.pontuacaoTempoExecucaoMetodologia} 
                onChange={({target}) => { 
                  avaliacaoDigital.pontuacaoTempoExecucaoMetodologia = +target.value;
                  this.setState({ avaliacaoDigital });
                }}
              >
                <TableRow className='table-avaliacao-atendimento-linha'>
                  <TableCell className='table-avaliacao-atendimento-question'>Qual o seu nível de satisfação com o tempo de execução da metodologia?</TableCell>

                  
                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                  </TableCell>
                </TableRow>   
              </RadioGroup>


              <RadioGroup 
                row
                value={avaliacaoDigital.pontuacaoCustoProduto} 
                onChange={({target}) => { 
                  avaliacaoDigital.pontuacaoCustoProduto = +target.value;
                  this.setState({ avaliacaoDigital });
                }}
              >
                <TableRow className='table-avaliacao-atendimento-linha row-pair'>
                  <TableCell className='table-avaliacao-atendimento-question'>Qual o seu nível de satisfação com o custo do produto?</TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                  </TableCell>
                </TableRow>   
              </RadioGroup>

              
              <RadioGroup 
                row
                value={avaliacaoDigital.pontuacaoResultados} 
                onChange={({target}) => { 
                  avaliacaoDigital.pontuacaoResultados = +target.value;
                  this.setState({ avaliacaoDigital });
                }}
              >
                <TableRow className='table-avaliacao-atendimento-linha'>
                  <TableCell className='table-avaliacao-atendimento-question'>Qual o seu nível de satisfação com os resultados alcançados?</TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                  </TableCell>

                  <TableCell className="table-avaliacao-atendimento-cell-body">
                    <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                  </TableCell>
                </TableRow>   
              </RadioGroup>

            </TableBody>
          </Table>

          <Table id="table-expectations" key="table-expectations" style={{ marginTop: '80px' }} className='table-content'>
            <TableHead>
              <TableRow className='table-avaliacao-atendimento-linha'>
                <TableCell style={{ width: '473px'}}>
                  <span className='table-avaliacao-atendimento-question-header'>
                    Atribua uma nota de 1 a 5 para as perguntas a seguir:
                  </span>
                </TableCell>
                <TableCell className='table-avaliacao-atendimento-cell-header'>
                  <span>nota mínima</span>
                  1
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header-simple'>
                  2
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header-simple'>
                  3
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header-simple'>
                  4
                </TableCell>

                <TableCell className='table-avaliacao-atendimento-cell-header'>
                  <span>nota máxima</span>
                  5
                </TableCell>
              </TableRow>
            </TableHead>


            <TableBody key='table-expectations'>

            <RadioGroup 
              row
              value={avaliacaoDigital.pontuacaoExpectativas} 
              onChange={({target}) => { 
                avaliacaoDigital.pontuacaoExpectativas = +target.value;
                this.setState({ avaliacaoDigital });
              }}
            >
              <TableRow className='table-avaliacao-atendimento-linha row-pair'>
                <TableCell className='table-avaliacao-atendimento-question'>A empresa acredita que as expectativas inicias foram atendidas?</TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                </TableCell>
              </TableRow>   
            </RadioGroup>

            <RadioGroup 
              row
              value={avaliacaoDigital.pontuacaoResultadosMantidos} 
              onChange={({target}) => { 
                avaliacaoDigital.pontuacaoResultadosMantidos = +target.value;
                this.setState({ avaliacaoDigital });
              }}
            >

              <TableRow className='table-avaliacao-atendimento-linha'>
                <TableCell className='table-avaliacao-atendimento-question'>A empresa acredita que os resultados obtidos serão mantidos a longo prazo?</TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={1} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={2} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={3} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={4} control={<Radio />}/>
                </TableCell>

                <TableCell className="table-avaliacao-atendimento-cell-body">
                  <FormControlLabel className={'radio'} value={5} control={<Radio />}/>
                </TableCell>
              </TableRow>   
            </RadioGroup>

            </TableBody>
          </Table>
        </Grid>
      )
    }

    render() {
      return (
        <Grid container className={'atividade-linha-producao'} style={{ marginTop: '30px' }}>
            { this.renderBody() }
        </Grid>
      )
    }

}