import React from 'react';
import { createMuiTheme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/styles';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';

import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import LastPageIcon from '@material-ui/icons/LastPage';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import './table.scss';

function desc(a, b, orderBy) {

    var aAux = a[orderBy] + '';
    var bAux = b[orderBy] + '';
    let data = aAux.split('/');
    let newDate = new Date(data[2] + '-' + data[1] + '-' + data[0] + 'T00:00:00').getTime();
    if (isNaN(newDate)) {
        
        aAux = aAux.replace(/[ÀÁÂÃÄÅ]/, "A");
        aAux = aAux.replace(/[àáâãäå]/, "a");
        aAux = aAux.replace(/[ÍÌÎÏ]/, "I");
        aAux = aAux.replace(/[íìîï]/, "i");
        aAux = aAux.replace(/[ÈÉÊË]/, "E");
        aAux = aAux.replace(/[éèêë]/, "e");
        aAux = aAux.replace(/[ÓÒÔÕÖ]/, "O");
        aAux = aAux.replace(/[óòôõö]/, "o");
        aAux = aAux.replace(/[ÚÙÛÜ]/, "U");
        aAux = aAux.replace(/[úùûü]/, "u");
        aAux = aAux.replace(/[Ç]/, "C");
        aAux = aAux.replace(/[ç]/, "c");

        bAux = bAux.replace(/[ÀÁÂÃÄÅ]/, "A");
        bAux = bAux.replace(/[àáâãäå]/, "a");
        bAux = bAux.replace(/[ÈÉÊË]/, "E");
        bAux = bAux.replace(/[éèêë]/, "e");
        bAux = bAux.replace(/[ÍÌÎÏ]/, "I");
        bAux = bAux.replace(/[íìîï]/, "i");
        bAux = bAux.replace(/[ÓÒÔÕÖ]/, "O");
        bAux = bAux.replace(/[óòôõö]/, "o");
        bAux = bAux.replace(/[ÚÙÛÜ]/, "U");
        bAux = bAux.replace(/[úùûü]/, "u");
        bAux = bAux.replace(/[Ç]/, "C");
        bAux = bAux.replace(/[ç]/, "c");
    } else {
        aAux = newDate;
        data = bAux.split('/');
        bAux = new Date(data[2] + '-' + data[1] + '-' + data[0] + 'T00:00:00').getTime();
    }

    if (bAux < aAux) {
        return -1;
    }
    if (bAux > aAux) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy)
}

function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort, headRows, numSelected, rowCount } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headRows.map(row => (
                    row.canOrder ?
                        <TableCell key={row.id} sortDirection={orderBy === row.id ? order : false}>
                            <TableSortLabel active={orderBy === row.id} direction={order} onClick={createSortHandler(row.id)}>
                                {row.label}
                                {orderBy === row.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                        :
                        <TableCell key={row.id}>
                            {row.label}
                        </TableCell>
                ))}
                {props.checkBox && <TableCell>
                    <Checkbox
                        checked={(numSelected !== 0 || rowCount !== 0) && numSelected === rowCount}
                        onChange={props.handleSelectAllClick}
                    />
                </TableCell>}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    headRows: PropTypes.array.isRequired,
    numSelected: PropTypes.number.isRequired,
    rowCount: PropTypes.number.isRequired
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(3),
        overflowX: 'auto',
        borderRadius: '0px',
        boxShadow: 'none',
        fontSize: '13px',
    },
    table: {
    },
    cell: {
        color: '#707070',
        fontSize: '13px',
        borderBottom: '1px solid #ddd;'
    },
    pointer: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgb(221,221,221)'
        }
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
}));

const tabelaSgtTheme = createMuiTheme({
    typography: {
        fontFamily: [
            'Raleway',
            '-apple-system',
            'BlinkMacSystemFont',
        ].join(','),
        fontWeightMedium: 500,
        body1: {
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: 12,
        },
    },
});

const useStyles1 = makeStyles(theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5),
    },
}));

function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    function handleFirstPageButtonClick(event) {
        onChangePage(event, 0);
    }

    function handleBackButtonClick(event) {
        onChangePage(event, page - 1);
    }

    function handleNextButtonClick(event) {
        onChangePage(event, page + 1);
    }

    function handleLastPageButtonClick(event) {
        onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    }

    return (
        <div className={classes.root}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}>
                {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
            <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
                {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        </div>
    );
}

TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};


export default function Tabela(props) {
    const classes = useStyles();

    //Ordenação
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState(props.initialOrder);
    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === 'desc';
        setOrder(isDesc ? 'asc' : 'desc');
        setOrderBy(property);
    }
    const rowsPerPage = props.rowsPerPage ? props.rowsPerPage : 50; 

    //Paginação
    const [page, setPage] = React.useState(0);
    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    const checkBox = props.checkBoxCondition ? props.checkBoxCondition: null;

    const isSelected = (id) => props.selected.map(function(e) { return e.id; }).indexOf(id) !== -1;
    const filteredLines = () => checkBox ? props.lines.filter(line => line[checkBox[0]] === checkBox[1]) : [];

    const handleCheckboxClick = (event, line) => {
        event.stopPropagation();
        const selected = props.selected;
        const selectedIndex = selected.map(function(e) { return e.id; }).indexOf(line[0]);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, { id: line[0], param: line[10] });
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        props.handleSelected(newSelected);
      };

    const handleSelectAllClick = (e) => {
        let selected = [];
        if (e.target.checked) selected = filteredLines().map(line => { return { id: line[0], param: line[10] } });
        props.handleSelected(selected);
    };
    
    if (props.lines.length < page * rowsPerPage){
        setPage(0);
    }

    if (props.lines.length > 0) {
        return (    
            <ThemeProvider theme={tabelaSgtTheme}>
                <Paper className={classes.root}>
                    <Table className={classes.table + ' tabela'}>
                        { props.hideHeader != null && props.hideHeader === true ? 
                            null :
                            <EnhancedTableHead
                                classes={classes}
                                order={order}
                                orderBy={orderBy}
                                checkBox={checkBox}
                                numSelected={props.selected ? props.selected.length : 0}
                                rowCount={filteredLines().length}
                                onRequestSort={handleRequestSort}
                                headRows={props.titles}
                                handleSelectAllClick={handleSelectAllClick}/> }
                        <TableBody>
                            {stableSort(props.lines, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map(
                                    (line, index) => {
                                        return (
                                            <TableRow key={`tableRow ${index}`} onClick={() => props.action != null ? props.action(line[props.actionParamer]) : {}} className={props.classParamer ? line[props.classParamer] : ''}>
                                                {props.titles.map(
                                                    (column, index) => {
                                                        let width = props.cellWidth && props.types && (props.types[index] === 'inputNumber' || props.types[index] === 'icon' || props.types[index] === 'graph') ? props.cellWidth : '';
                                                        return <TableCell style={{ width: width }} key={`tableCell ${index}`}>{line[column.id]}</TableCell>
                                                    }
                                                )}
                                                { checkBox && 
                                                    <TableCell>
                                                        { line[checkBox[0]] === checkBox[1] &&
                                                            <Checkbox checked={isSelected(line[0])} onClick={e => handleCheckboxClick(e, line)} /> }
                                                    </TableCell> }
                                            </TableRow>
                                        )
                                    }
                                )
                            }
                        </TableBody>
                        {props.pagination === false ? null :
                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[]}
                                        count={props.lines.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        ActionsComponent={TablePaginationActions}
                                        labelDisplayedRows={({ from, to, count }) => (`${from}-${to} de ${count}`)} />
                                </TableRow>
                            </TableFooter>
                        }
                    </Table>
                </Paper>
            </ThemeProvider>
        )
    } else {
        return null;
    }
}
