import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import { Grid } from "@material-ui/core";
import API from '../../comm/API'
import SimpleModal from './../../components/modal/modal'
import InputSelect from '../../components/inputselect/inputselect';
import List from '@material-ui/core/List';

export default class ControleBrasilMais extends Component {

    atendimento = this.props.atendimento;

    constructor(props) {
        super(props);
        this.state = {}
        console.log('this.props.isAtendimentoBrasilMaisLEAN',this.props.isAtendimentoBrasilMaisLEAN);
        console.log('this.props.isAtendimentoDIGITAL',this.props.isAtendimentoDIGITAL)
    }

    

    saveAtendimento = () => {
        this.props.showLoading();
        if(this.props.isAtendimentoBrasilMaisLEAN){
            this.atendimento.produtoRegional.id = 7700;
            this.atendimento.produtoRegional.nome = "MÓDULO MENTORIA DIGITAL";
            this.atendimento.produtoRegional.produtoNacional.id = 214;
            this.atendimento.produtoRegional.descricao = "MÓDULO MENTORIA DIGITAL"
        }
        else{
            this.atendimento.produtoRegional.id = 7659;
            this.atendimento.produtoRegional.nome = "BRASIL MAIS - MENTORIA LEAN";
            this.atendimento.produtoRegional.produtoNacional.id = 215;
            this.atendimento.produtoRegional.descricao = "BRASIL MAIS - MENTORIA LEAN"
        }


        API.post('rest/gestao/Atendimento/' + this.atendimento.id, this.atendimento).then(res => {
            this.props.changeModal('modalBrasilMais', false)
            this.props.openAtendimento(this.atendimento.id);
            this.props.showToast('Mentoria modificada com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.showToast('Error ao alterar a Mentoria. Favor, entrar em contato com o suporte.', 'error');
        });
    };

  
   

    render() {
        return (
            <Grid item xs={12}>
                <div className={'greyDiv'}>
                    <GenericButton color={'darkBlue'} label={'Alterar Produto'}
                        subClass={'basic-button'} click={() => this.props.changeModal('modalBrasilMais', true)} />
                    <h2>Controle Brasil Mais</h2>
                    <p>Clique no botão para alterar o produto do Programa Brasil Mais</p>
                    <p>Apenas disponível para atendimentos B+ com o status: "Em prospecção (rascunho)"</p>
                </div>
                {
                    <SimpleModal
                        isOpen={this.props.modalBrasilMais}
                        handleClose={() => this.props.changeModal('modalBrasilMais', false)}
                        type={'success'}
                        width={'600px'}>
                        <div className={'modal modal-fase'}>
                            <h2>Controle Brasil Mais</h2>
                            <Grid container>
                                <label>
                                    <label>Você confirma a alteração de fase/produto para o atendimento da empresa </label>
                                    <label className={'bold'}>{this.props.atendimento.cliente.nome}? </label>
                                </label>
                                <label style={{marginTop : 12}}>
                                    <label>{" Após confirmar o produto do atendimento será alterado de"} </label>
                                    {<label className={'bold'}>{this.props.isAtendimentoBrasilMaisLEAN ? ' Mentoria Lean (fase 1) ' : ' Mentoria Digital (fase 2) '}</label>}
                                    <label>{' para' }</label>
                                    <label className={'bold'}> {this.props.isAtendimentoBrasilMaisLEAN ?  ' Mentoria Digital (fase 2).' : ' Mentoria Lean (fase 1).'}</label> 
                                </label>
                                
                                <div className={'rodape'}>
                                    <GenericButton color={'darkBlue-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.props.changeModal('modalBrasilMais', false)} />
                                    <GenericButton color={'darkBlue'} label={'Alterar'} subClass={'basic-button'} click={() => this.saveAtendimento()} />
                                </div>
                            </Grid>
                        </div>
                    </SimpleModal>
                }
            </Grid>
        )
    }
}