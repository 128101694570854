export var mesList = [  { id: 0, value: '01', label: "JANEIRO" },
                        { id: 1, value: '02', label: "FEVEREIRO" },
                        { id: 2, value: '03', label: "MARÇO" },
                        { id: 3, value: '04', label: "ABRIL" },
                        { id: 4, value: '05', label: "MAIO" },
                        { id: 5, value: '06', label: "JUNHO" },
                        { id: 6, value: '07', label: "JULHO" },
                        { id: 7, value: '08', label: "AGOSTO" },
                        { id: 8, value: '09', label: "SETEMBRO" },
                        { id: 9, value: '10', label: "OUTUBRO" },
                        { id: 10, value: '11', label: "NOVEMBRO" },
                        { id: 11, value: '12', label: "DEZEMBRO" }
                    ]