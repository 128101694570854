export const CodigoDNBaseNacionalEnum = {
  smartFactoryPiloto: 42120,
  melhoriaProdutividade: 412151,
  manufaturaEnxutaPiloto: 412157,
  eficienciaEnergeticaPiloto: 412158,
  manufaturaEnxuta: 412159,
  eficienciaEnergetica: 412160,
  transformacaoFase1Piloto: 412161,
  transformacaoFase2Piloto: 412163,
};
