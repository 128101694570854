import React, { Component } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import { withStyles} from "@material-ui/core/styles";
import API from '../../comm/API';
import './graphBarHorizontal.scss';

export default class GraphBarHorizontalStatus extends Component {

    constructor(props) {
        super(props);
        this.state = {
            unidadesLean: [0,0,0,0,0],
            unidadesDigital: [0,0,0,0,0],
            unidadesAmbos: [0,0,0,0,0]
        }

        this.carregarStatus();
    }

    getStatusAtendimento = async () => {
		await API.get('rest/sistema/DashboardBrasilMais/countStatusAtendimentos/29').then( async res => {
			let dados = res.data;
            let lean = [dados.lean.triagem,dados.lean.negociacao,dados.lean.contratados_execucao,dados.lean.concluidos,dados.lean.cancel_arquiv_recusados];
            let digital = [dados.digital.triagem,dados.digital.negociacao,dados.digital.contratados_execucao,dados.digital.concluidos,dados.digital.cancel_arquiv_recusados];
            let ambos = [dados.ambos.triagem,dados.ambos.negociacao,dados.ambos.contratados_execucao,dados.ambos.concluidos,dados.ambos.cancel_arquiv_recusados];
            this.setState({ unidadesDigital: digital, unidadesLean: lean,unidadesAmbos: ambos });
		}).catch(error => {
			console.log(error);
		});
	}

	carregarStatus = async () => {
		await this.getStatusAtendimento();
	}

    

    createGrafico = () => {
        let series;

        if(this.props.fase == "L"){
			series = this.state.unidadesLean;
			
		}
		else if(this.props.fase == "D"){
			series = this.state.unidadesDigital;
		}
		else{
			series = this.state.unidadesAmbos;
		}

        let max = series.reduce((partialSum, a) => partialSum + a, 0);
        let options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Raleway'
                },
                marginTop: 0,
                spacingTop: 0,
                marginBottom: 0,
                spacingBottom: 0,
                height: 200,
                backgroundColor:"#F2F1F6"
            },
            title:{
                floating : true,
                text: ''
            },
            xAxis: {
                gridLineColor: '#B4B8C0',
                gridLineWidth: 0,
                tickmarkPlacement:'between',
                categories: ['Em triagem', 'Em negociação', 'Contratados + Em execução', 'Concluídos','Cancelados + Arquivados'],
                opposite: true,
                labels: {
                    style: {
                        width: '400px',
                        textOverflow: 'allow',
                    },
                    useHTML: true,
                    formatter: function () {
                        let val;
                        switch(this.value){
                            case 'Em triagem':
                                val = 0;
                                break;
                            case 'Em negociação' :
                                val = 1;
                                break;
                            case 'Contratados + Em execução' :
                                val = 2;
                                break;
                            case 'Concluídos' :
                                val = 3;
                                break;
                            case 'Cancelados + Arquivados':
                                val = 4;
                                break;
                        }
                        return '<div> <label style="font-weight:bold">'  + series[val] + '</label> <label style="text-transform: none; margin-left: 10px">' + this.value + '</label> </div>'
                    }
                },
                lineColor: '#707070',
                lineWidth: 1,
            },
            yAxis: {
                gridLineWidth: 0,
                lineColor: '#707070',
                min: 0,
                max: max,
                title: {
                    text: ''
                },
                labels: {
                    enabled: false
                }
            },
            plotOptions: {
                bar: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            legend:{
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>'+ this.x +'</b><br/>' + 
                            'Empresas: ' + this.y + '<br/> Porcentagem: ' + (this.y/max *100).toFixed(1) + '%';
                }
            },
            series: [{
                data: [max,max,max,max,max],
                name: 'backgroundSeries',
                colors: [
                    '#E5E4EA',
                    '#E5E4EA',
                    '#E5E4EA',
                    '#E5E4EA',
                    '#E5E4EA'
                ],
                grouping: false,
                animation: false,
                enableMouseTracking: false,
                showInLegend: false
              },{
                data: series,
                colors: [
                    '#E9B03D',
                    '#E9784D',
                    '#077CDC',
                    '#7DAE59',
                    '#D44E54'
                ]
            }],
            credits: {
                enabled: false
            },
            exporting: false
        };

        return options;
    };

    formatData = (date) => {
        date = date + 'T00:00:00';
        let dataList;
        if (typeof (date) !== 'string') {
            dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

  
   
    render() {
        let title = 'teste status';
        let currGraph = this.createGrafico();
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
        return (
            <Grid container 
            justifyContent="flex-start"
            alignItems="center" style={{maxHeight:"100%",height:"100%"}}>
                
                <Grid item xs={11}>
                    <div style={{marginLeft:"30px"}}>
                        <HighchartsReact key={title}
                            highcharts={Highcharts}
                            options={currGraph} />
                    </div>
                </Grid>            
            </Grid>
        );
    }

}