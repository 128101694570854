import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import GraficoMapaBrasilSetor from './graficoMapaBrasilSetor';
import API from '../../../comm/API';
import Carousel from '../../../components/carousel/carousel';
import CarouselNew from '../../../components/carouselNew/carouselNew';
import SectorCard from '../../../components/sectorCard/sectorCard';
import GraficoAtendimentoSetor from './graficoAtendimentosPorSetor';
import GraficoAtendimentosPorSegmento from './graficoAtendimentosPorSegmento';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import SectorHeaderCard from '../../../components/sectorHeaderCard/sectorHeaderCard';
import GraficoPrincipaisSetores from './graficoPrincipaisSetores';
import GraphBarAvaliacaoSatisfacao from './graficoAvaliacaoSatisfacao';
import GraphBarAvaliacaoNota from './graficoAvaliacaoNota';
import GraphBarTecnologiasHabilitadoras from './graficoTecnologiasHabilitadoras';

export default class DashboardBrasilMaisAvaliacoes extends Component {

	constructor(props) {
		super(props);

		this.state = {
			setoresCards: [],
			resumo: []
		}


	}

	

	render() {
		if (this.props.perfil === 'DN')
			return (
				<Grid container className={'dashboard-brasil-mais-avaliacao'}>
					<Grid item container justifyContent='center' xs={12}>
						<Grid item className={'font-title-carousel-title'} xs={12} container justifyContent='center'>
							<label>
								{'QUESTIONÁRIO: AVALIAÇÃO DE ATENDIMENTO'}
							</label>
						</Grid>
						<Grid item className={'font-title-carousel-subtitle'} xs={12} container justifyContent='center'>
						<label>
							{this.props.fase == "L" ? `Fase 1: Mentoria Lean`	:
							this.props.fase == "D" ? `Fase 2: Mentoria Digital` : `Brasil Mais`	}
						</label>
						</Grid>
						<Grid item xs={12} style={{marginTop: '15px'}}><GraphBarAvaliacaoSatisfacao fase={this.props.fase}/></Grid>
						<Grid item xs={12} ><GraphBarAvaliacaoNota fase={this.props.fase}/></Grid>
						
					</Grid>
					<Grid item container justifyContent='center' xs={12}>
						<Grid item className={'font-title-carousel-title'} xs={12} container justifyContent='center'>
							<label>
								{'QUESTIONÁRIO: TECNOLOGIAS HABILITADORAS'}
							</label>
						</Grid>
						<Grid item className={'font-title-carousel-subtitle'} xs={12} container justifyContent='center'>
						<label>
							Fase 1: Mentoria Lean
						</label>
						</Grid>
						<Grid item xs={12} style={{marginTop: '15px'}}><GraphBarTecnologiasHabilitadoras /></Grid>
						
					</Grid>
				</Grid>
			);
	}


}