import React, { Component } from 'react';
import GridList from '@material-ui/core/GridList';
import { BASE_URL } from './../../comm/API';
import { Grid } from '@material-ui/core';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import './listWithImage.scss';
import '../../modules/perfil/perfilUsuario.scss'

export default class ListWithImage extends Component {

    busca = '';

    constructor(props) {
        super(props)
        this.state = {filtro: ''};
    }    

    aplicarFiltro = (e) => {
        this.setState({filtro: this.busca});
    }

    changeBusca = (e) => {
        this.busca = e.target.value;
    }

    renderList = (item, index) => {
        if (item[0].toUpperCase().includes(this.state.filtro.toUpperCase())) {
            return (
                <div key={item[0] + index} className='tile-grid'>
                    <div className='list-img-items'>
                        <img className='img-item' src={BASE_URL + item[1]} alt=''></img>
                        <label className='tick-label'>{item[0]}</label>
                    </div>
                </div>
            )
        }
        else return null;
    }

    render() {
        return (
            <Grid item xs={10}>
                <div className='title-with-count'>
                    <label className='left'>Usuários Integrantes</label>
                    <label className='right'>{this.props.lista.length} usuário(s)</label>
                </div>
                <FormField type={'text'} label={''} placeholder={'busque pelo nome do usuário'} id={'busca'} pressEnter={this.aplicarFiltro} changeValue={this.changeBusca} 
                    button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={this.aplicarFiltro} />} />
                <Grid item xs={12} className={'grid-height'} id={'grid-height'}>
                    <GridList cellHeight={45} cols={1} spacing={3}>
                        {this.props.lista.length > 0 ? this.props.lista.map((item, index) => (this.renderList(item, index))) : <div></div>}
                    </GridList>
                </Grid>
            </Grid >
        )
    }

}