import React, { Component } from 'react';
import API from '../../comm/API';
import VisitasDefault from './visitasDefault';
import AtividadeDefault from './atividadeDefault';
import { getByKeyOrEmpty, viewDataModels } from './mentoriaUtil';

export default class MentoriaTeorica extends Component {

    STATUS_FINALIZADO = 3;

    constructor(props) {
        super(props);

        this.viewData = JSON.parse(JSON.stringify(viewDataModels.etapaTeorica));
        const etapa = this.props.turma.etapaTeoricaList.find(obj => obj.etapa === this.props.index );
        const viewData = this.updateViewData(etapa);
        const disabled = this.props.turma.status === 3 ;

        this.state = {
            turma: this.props.turma,
            etapa,
            viewData,
            disabled: disabled
        }
    };

    updateViewData = (etapa) => {
        const { leanGame, treinamentoSala } = etapa;

        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Teórica ' + (etapa.etapa === 2 ? 'B' : etapa.etapa === 4 ? 'C' : 'D');

        if (etapa.etapa !== 7) {
            this.viewData.atividades.leanGame.data = JSON.parse(JSON.stringify(leanGame || {}));
            this.viewData.atividades.leanGame.anexos.data = getByKeyOrEmpty(leanGame, 'anexoAtividadeList');
        } else {
            this.viewData.totalAtividades = 1;
            delete this.viewData.atividades.leanGame;
        }
        
        this.viewData.atividades.treinamentoSala.data = JSON.parse(JSON.stringify(treinamentoSala || {}));
        this.viewData.atividades.treinamentoSala.anexos.data = getByKeyOrEmpty(treinamentoSala, 'anexoAtividadeList');

        this.viewData.visitas.data = JSON.parse(JSON.stringify(etapa.visitaList));
        return this.viewData;
    };
    
    handleFinishMentoria = async (viewData) => {
        const isValid = this.validaVisitas(viewData);
        if (!isValid) {
            this.props.showToast('Há informações/atividades pendentes nos encontros dessa etapa.', 'error');
            return;
        }
        const turma = this.props.turma;
        turma.etapaTeoricaList = turma.etapaTeoricaList.map(etapaTeorica => {
            if (etapaTeorica.etapa === turma.etapaAtual) etapaTeorica.finalizada = true;
            return etapaTeorica
        });
        turma.etapaAtual += 1;
        try {
            await this.props.saveTurma(turma);
            this.props.showToast('Etapa finalizada com sucesso!', 'success');
            this.props.goBack();
        } catch(error) {
            this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
        }
    };

    validaVisitas = (viewData) => {
        const atividadeList = this.state.etapa.atividadeList;  
        const visitaList = viewData.visitas.data;
        return visitaList.every(visita => { return this.validaVisita(visita) }) && atividadeList && atividadeList.every(({status}) => { return status === this.STATUS_FINALIZADO }) &&
            ((this.state.etapa.etapa === 7 && atividadeList.length === 1) || (atividadeList.length === 2));            
    };

    validaVisita = (visita) => {
        return visita && visita.anexoVisitaList && visita.anexoVisitaList.length > 0 && visita.anexoVisitaList.some(anx => { return anx.descricao === 'lista de presença' })
             && visita.data && visita.turno != null && visita.horasApropriadas > 0 && visita.isVirtual != null;
    };

    validateAnexos = (atividade) => {
        return atividade && atividade.anexoAtividadeList && atividade.anexoAtividadeList.length > 0 && atividade.anexoAtividadeList.every(elm => { return elm.descricao });
    };

    validaAtividade = async (viewData, etapa, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = etapa[nomeAtividade] ? etapa[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo && nomeAtividade === 'leanGame' && etapa.etapa === 7) atividade[nomeAnexo] = [];
        else if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        etapa[nomeAtividade] = atividade;
    };


    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let visitasData = viewData.visitas.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;

            if (currScreenAtividade === 'leanGame') 
                await this.validaAtividade(viewData, etapa, currScreenAtividade, 'leanGame', 'anexoAtividadeList', this.validateAnexos, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'treinamentoSala') 
                await this.validaAtividade(viewData, etapa, currScreenAtividade, 'treinamentoSala', 'anexoAtividadeList', this.validateAnexos, this.STATUS_FINALIZADO);

            if (selectedVisita) {
                selectedVisita.anexoVisitaList = [];
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaVisita(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = visitasData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                etapa.visitaList[index] = selectedVisita;
                if (index > -1) etapa.visitaList[index] = selectedVisita;
                else etapa.visitaList.push(selectedVisita);
            };
            etapa.horasApropriadas = etapa.visitaList.reduce((a, b) => + a + + b.horasApropriadas, 0); 
            etapa.atividadeList = [];
            etapa.atividadeList = etapa.atividadeList.concat(etapa.leanGame != null ? etapa.leanGame : []);
            etapa.atividadeList = etapa.atividadeList.concat(etapa.treinamentoSala != null ? etapa.treinamentoSala : []);
        }
        else {
            etapa.atividadeList = [];
            let atividades = viewData.atividades;
            let leanGame = atividades.leanGame;
            let treinamentoSala = atividades.treinamentoSala;
            if (leanGame && Object.keys(leanGame.data).length == 0) etapa.leanGame = null;
            if (Object.keys(treinamentoSala.data).length == 0) etapa.treinamentoSala = null;
            etapa.horasApropriadas = etapa.visitaList.reduce((a, b) => + a + + b.horasApropriadas, 0); 
            etapa.visitaList = visitasData;
        }
        let etapaIndex = turma.etapaTeoricaList.findIndex(obj => { return obj.etapa === this.props.index });
        turma.etapaTeoricaList[etapaIndex] = etapa; 
        if(etapa.horasApropriadas > 8){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else {
            return API.post('rest/gestao/Turma/' + turma.id, turma).then(() => {
                return API.get('rest/gestao/Turma/' + turma.id).then(res => {
                    turma = res.data;
                    etapa = turma.etapaTeoricaList.find(obj => obj.etapa === this.props.index );
                    this.setState({ turma, etapa, viewData: this.updateViewData(etapa) });
                    this.props.showToast('Etapa editada com sucesso!', 'success');
                }).catch(error => {
                    this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                    throw new Error(error);
                });
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
        });}
    };
    
    /**
     * Function para renderizar a tela de atividade
     * com seus anexos, a ser passado através de props
     * @memberof MentoriaTeorica
     * @param selectedAtividade Atividade selecionada 
     *                          ( contém os anexos e demais outros dados )
     * @param reloadAtividade   método utilizado para atualizar realizar um 
     *                          reload na tela da atividade atual em visitasDefault
     */
    renderAtividade = (selectedAtividade, reloadAtividade) => {
        return (
            <AtividadeDefault
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                showEmptyEvidencias={ true }
                showTopButtons={ true  && !this.state.disabled}
                showAnexos={ true }
                viewData={ this.state.viewData }
                disabled={this.state.disabled} />
        );
    };

    render() {
        return (
            <VisitasDefault
                turma={ this.state.turma }
                fase={ this.props.fase }
                goBack={ this.props.goBack }
                closeLoading={ this.props.closeLoading }
                showLoading={ this.props.showLoading }
                showToast={ this.props.showToast }
                viewData={ this.state.viewData }
                etapa = {this.state.etapa}
                handleFinishMentoria={ this.handleFinishMentoria }
                saveEtapa={ this.saveEtapa }
                renderAtividade={ this.renderAtividade }
                dontShowFinalizarMentoria={ this.state.etapa.finalizada }
                disabled={this.state.disabled}
            />
        )
    };
};