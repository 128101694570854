import React from "react";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { produtoNacionalBMPEnum } from "../../modules/atendimento/enum/produtoNacionalBMP";

const obrigatoriosPrograma = (id_produto_nacional) => {
    if (id_produto_nacional === produtoNacionalBMPEnum.manufaturaEnxuta) {
        return (
            <li>
                Para conclusão do atendimento necessário que o Indicador Produtividade esteja entre 20%-100%, caso contrário deve ser enviado para parecer DN, com a justificativa do resultado no campo COMENTÁRIO.
            </li>
        );
    } else if (id_produto_nacional === produtoNacionalBMPEnum.smartFactoryPiloto) {
        return (
            <li>
                Para conclusão do atendimento necessário todos os indicadores, mesmo que zerados, e documentação obrigatória.
            </li>
        );
    } else if (id_produto_nacional === produtoNacionalBMPEnum.eficienciaEnergetica) {
        return (
            <li>
                Para conclusão do atendimento necessário que o percentual do Redução de Consumo Energético esteja entre 10%-100%, caso contrário deve ser enviado para parecer DN, com a justificativa do resultado no campo COMENTÁRIO.
            </li>
        );
    }
    return null;
};

const formatSufixo = (sufixo) => {
    if (!sufixo) {
        return '';
    }

    return `(${sufixo.replace(/[()]/g, '')})`;
};

const renderNomeIndicador = (indicador, programa) => {
    switch (indicador.nome) {
        case "Está apta a receber o Smart Factory":
            return "Empresa apta a Categoria Smart Factory?";

        case "Redução de Consumo Energético":
            if (indicador.sufixo === "%" && programa.id_produto_nacional === produtoNacionalBMPEnum.eficienciaEnergetica) {
                return "Redução de Consumo Energético (%) (mínimo 10%)";
            }
            break;

        case "Retorno do Programa (payback)":
            if (programa.id_produto_nacional === produtoNacionalBMPEnum.manufaturaEnxuta) {
                return `${indicador.nome}`;
            }
            break;

        case "Inclui Hardware":
        case "Inclui Software":
        case "Inclui Serviço":
        case "Oportunidade de PDI identificada":
            if (programa.id_produto_nacional === produtoNacionalBMPEnum.transformacaoFase1Piloto) {
                return `${indicador.nome}`;
            }
            break;

        default:
            return `${indicador.nome} ${formatSufixo(indicador.sufixo)}`;
    }
};


const inputsNecessarios = (programa) => {
    if (produtoNacionalBMPEnum.smartFactoryPiloto === programa.id_produto_nacional) {
        return 'Inputs Necessários (Se o projeto não houve medição informar 0 (zero)):'
    }

    return 'Inputs Necessários:'
};

const IndicadoresAlert = ({ programa }) => {
    let content = null;
    if (programa) {
        const indicadoresUnicos = new Set(programa.inputs.map(indicador => indicador.nome));
        content = (
            <Alert severity="warning">
                <AlertTitle>
                    <strong>Atenção:</strong> Indicadores do {programa.nome}
                </AlertTitle>
                Estes são os Inputs do {programa.nome}. Para garantir a conclusão deste atendimento, observe as seguintes diretrizes:
                <ul>
                    <li>
                        <strong>{inputsNecessarios(programa)}</strong>
                        <ul>
                            {[...indicadoresUnicos].map((nome, index) => (
                                <li key={index}>{nome}</li>
                            ))}
                        </ul>
                    </li>
                    <li>
                        <strong>Indicadores de Desempenho(Necessários):</strong>
                        <ul>
                            {programa.indicadores.map((indicador, index) => (
                                <li key={index}>
                                    {renderNomeIndicador(indicador, programa)}
                                </li>
                            ))}
                        </ul>
                    </li>
                    {obrigatoriosPrograma(programa.id_produto_nacional)}
                </ul>
            </Alert>
        );
    }

    return <Grid item xs={12}>{content}</Grid>;
};


export default IndicadoresAlert;
