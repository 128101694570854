import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import API from "../../comm/API";
import GenericButton from "../genericButton/genericButton";
import InputSelect from "../inputselect/inputselect";
import "./header.scss";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.setUnidadeUsuario();
    this.hasNotification = false;
    this.atendimentosCount = 0;
    this.checkNotification();
  }

  setUnidadeUsuario() {
    if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
      this.unidade = this.props.usuarioConectado.colaborador.unidade;
    } else {
      this.unidade = this.props.usuarioConectado.unidade;
    }
  }

  showNotificationIcon() {
    return (
      this.props.usuarioConectado.perfilUsuario.nome === "ADMINISTRADOR" ||
      this.props.usuarioConectado.perfilUsuario.nome === "GESTOR" ||
      this.props.usuarioConectado.perfilUsuario.nome === "COORDENADOR"
    );
  }

  getFiltro() {
    var filtro = {};
    filtro["unidadeParceira.id"] = this.unidade.id;
    filtro["statusNotificacao.id"] = "1";
    return filtro;
  }

  checkNotification() {
    API.get("rest/gestao/AtendimentoRede", { params: this.getFiltro() }).then((res) => {
      if (res.data.length > 0) {
        this.hasNotification = true;
        this.atendimentosCount = res.data.length;
      } else {
        this.hasNotification = false;
      }
      this.setState(this.state);
    });
  }

  openSite() {
    window.open("https://portal.sgt.pe.senai.br/");
  }

  render() {
    return (
      <div className={"header"}>
        <Grid container className={"header-parent"}>
          <Grid item xs={6} className={"header-title"}>
            <img src={process.env.PUBLIC_URL + "/assets/icons/" + this.props.icon} alt="headerIcon" />
            {this.props.label}
          </Grid>
          <Grid item className={"icones"} xs={6}>
            {this.props.usuarioConectado != null &&
            this.props.usuarioConectado.colaborador != null &&
            this.props.usuarioConectado.colaborador.isAtivo &&
            this.props.usuarioConectado.colaborador.colaboradorUnidadeList.length > 1 ? (
              <InputSelect
                suggestions={this.props.unidadeList}
                itemKey={"abreviacao"}
                itemLabel={"abreviacao"}
                id={"unidade"}
                getInput={this.props.changeUnidade}
                initialSelectedItem={this.props.usuarioConectado.colaborador.unidade}
              />
            ) : null}
            {this.showNotificationIcon() && (
              <Link to={"/cad/sistema/notificacao"}>
                {this.hasNotification && (
                  <div className={"circulo-notificacao"}>
                    <span>{this.atendimentosCount}</span>
                  </div>
                )}
                <GenericButton icon={"bell.svg"} color={"transparent"} subClass={"header-icones"}></GenericButton>
              </Link>
            )}

            <GenericButton icon={"question.svg"} color={"transparent"} subClass={"header-icones"} click={this.openSite} />
          </Grid>
        </Grid>
      </div>
    );
  }
}
