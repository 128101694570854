import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import FormField from './../../components/formField/formField';
import InputSelect from './../../components/inputselect/inputselect';
import API from './../../comm/API';
import Page from './../../components/page/page';
import Stepper from './../../components/stepper/stepper';
import AutoComplete from './../../components/autoComplete/autoComplete';
import './unidadeNovo.scss';

class UnidadeModel {
    constructor(id, codigo, cnpj, codigoOBA, abreviacao,
        descricao, municipio, tipoUnidade, especialidadeUnidade,
        unidadePai, cliente, perfilUsoSistema) {
        this.id = id ? id : 0;
        this.codigo = codigo ? codigo : 0;
        this.cnpj = cnpj ? cnpj : '';
        this.codigoOBA = codigoOBA ? codigoOBA : '';
        this.abreviacao = abreviacao ? abreviacao : '';
        this.descricao = descricao ? descricao : '';
        this.municipio = { descricao: '' };
        this.tipoUnidade = { descricao: '', id: '', sigla: '', type: '' };
        this.especialidadeUnidade = {};
        this.unidadePai = { abreviacao: '' };
        this.cliente = { nome: '' };
        this.perfilUsoSistema = perfilUsoSistema ? perfilUsoSistema : '';
    }
}

export default class GerenciarUnidade extends Component {
    
    tipoUnidade = [{ sigla: '' }];
    state = { 
        unidade: new UnidadeModel(), 
        tipoUnidade: [{ sigla: '' }], 
        unidadesPai: [], 
        perfilList: ['SGT', 'B+P', 'I+A', 'IPEA'], 
        especialidadeUnidade: [], listaClientes: []
    };
    errors = {codigoOBA: ''};
    titles = ['Dados Básicos', 'Especificações', 'Revisão'];
    
    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    componentWillMount() {
        this.listarUniadesPai();
        this.getEspecialidadeUnidade();
        this.getClientes();
        this.getUnidade();
    };

    listarUniadesPai = async () => {
        let filtro = { sigla: 'notEquals(DN)'}
        let tipoUnidade = await API.get('/rest/auxiliar/TipoUnidade', { params: filtro });
        await this.setState({ tipoUnidade: tipoUnidade.data });

        let unidadesPai = await API.get(`/rest/gestao/Unidade?tipoUnidade.sigla=${this.state.tipoUnidade[0].sigla}&ordenarPor=id`);
        await this.setState({ unidadesPai: unidadesPai.data });
    };

    getEspecialidadeUnidade = async () => {
        await API.get('/rest/auxiliar/EspecialidadeUnidade').then(res => {
            this.setState({ especialidadeUnidade: res.data });
        }).catch(error => { });
    };

    getClientes = async () => {
        await API.get('/rest/pessoa/Cliente/0/300?nome=%25').then(res => {
            this.setState({ listaClientes: res.data });
        }).catch(error => { });
    };

    getUnidade = async () => {
        if (this.props.idUnidade) {
            this.showLoading();
            await API.get(`rest/gestao/Unidade/${this.props.idUnidade}`).then(res => {
                this.setState({ unidade: res.data });
                this.closeLoading();
            }).catch(error => {
                this.closeLoading();
                this.showLoading('Error ao exibir a unidade. Por favor entre em contato com o suporte.','error')
             });
        } else {
            var unidadeAux = this.state.unidade;
            unidadeAux.perfilUsoSistema = 'SGT';
            this.setState({ unidade: unidadeAux });
        }
    };

    changeInputState = (e) => {
        let u = this.state.unidade;
        if (e != null && e.target != null) {
            u[e.target.id] = e.target.value;
            if (this.errors[e.target.id] != null && (e.target.value == null || e.target.value === '')) {
                this.errors[e.target.id] = 'Campo obrigatório';
            } else if (e.target.id === 'codigoOBA' && e.target.value.length !== 7){
                this.errors[e.target.id] = 'Código OBA inválido';
            }else if (this.errors[e.target.id] != null && this.errors[e.target.id] != null && e.target.id === 'codigoOBA' && this.state.unidade.unidadePai.abreviacao === '') {
                this.errors[e.target.id] = 'Selecione a Unidade Pai';
            } else if (this.errors[e.target.id] != null && e.target.id === 'codigoOBA') {
                let uniAntiga = { ...u['unidadePai']};
                this.findUnidade(u, uniAntiga);
                this.errors[e.target.id] = '';
            } else if (this.errors[e.target.id] != null) {
                this.errors[e.target.id] = '';
            }
            this.setState({ unidade: u });
        } else if (e != null && e.type != null && e.type === 'unidade') {
            let uniAntiga = { ...u['unidadePai']};
            u['unidadePai'] = e.abreviacao !== '' ? e : u['unidadePai'];
            if (u.codigoOBA !== '') this.findUnidade(u, uniAntiga);
            this.setState({ unidade: u });
        } else if (e != null && e.type != null) {
            u[e.type] = e;
            this.setState({ unidade: u });
        }
    };

    salvarUnidade = () => {
        this.showLoading();
        let url = this.props.idUnidade ? `rest/gestao/Unidade/${this.props.idUnidade}` : '/rest/gestao/Unidade';
        API.post(url, this.state.unidade).then(res => {
            this.closeLoading();
            this.showToast('Unidade cadastrada/atualizada com sucesso.', 'success', '/cad/pessoa/unidade');
        }).catch(error => {
            this.closeLoading();
            if (error.response.data[0] && error.response.data[0].message && error.response.data[0].message.includes('unique')) {
                this.showToast('Já existe uma unidade cadastrada com esse Código OBA.', 'error');
            } else {
                this.showToast('Erro ao salvar a unidade. Entre em contato com o suporte.', 'error');
            }
        });
    };

    findUnidade = async (uni, uniAntiga) => {
        if (uni.codigoOBA.length === 7 && uni.unidadePai.id !== '' && uni.unidadePai.municipio != null) {
            this.showLoading();
            let url = 'https://ws3.sistemaindustria.org.br:9443/api-basi/v1/transparencia/entidades/3/estados/' + uni.unidadePai.municipio.unidadeFederativa.sigla + '/unidades/' + uni.codigoOBA;
            await API.get(url).then(async res => {
                let u = uni;
                u.cnpj = res.data.cnpj;
                u.descricao = res.data.nomeUnidade;
                u.abreviacao = res.data.nomeUnidade;
                if (res.data.nomeCidade) {
                    let municipio = await API.get(`/rest/pessoa/Municipio?descricao=${res.data.nomeCidade.toUpperCase()}`)
                        .then(res => { return res.data[0]; });
                    u.municipio = municipio ? municipio : {};
                    this.closeLoading();
                }
                this.errors.codigoOBA = '';
                this.setState({ unidade: u });
            }).catch(error => {
                this.closeLoading();
                this.errors.codigoOBA = 'Código OBA inválido';
                this.setState(this.state);
                if (error.response.data && error.response.data.detalhe) {
                    this.showToast(error.response.data.detalhe, 'error');
                } else {
                    this.showToast('Código OBA inválido.', 'error');
                }
                if (uniAntiga.id !== '') {
                    uni.unidadePai = uniAntiga;
                    this.setState({ unidade: uni })  
                }              
            });
        } else {
            this.errors.codigoOBA = 'Código OBA inválido';
            this.setState(this.state);
        }
    };

    createTemplateCliente(cliente) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{cliente.nome}</label>
                </div>
                <div>
                    <p>{cliente.razaoSocial}</p>
                    <p>{cliente.cpfcnpj} - {(cliente.enderecoList && cliente.enderecoList.length > 0
                        && cliente.enderecoList[0].municipio) ? cliente.enderecoList[0].municipio.descricao : ''}</p>
                </div>
            </div>
        );
    };

    validateUnidade = (step) => {
        switch (step) {
            case 0:
                return this.state.unidade.unidadePai.id && this.state.unidade.codigoOBA && this.state.unidade.perfilUsoSistema
                    && this.state.unidade.cnpj && this.state.unidade.abreviacao && this.state.unidade.descricao 
                    && this.state.unidade.municipio && this.state.unidade.codigoOBA.length === 7;             
            case 1:
               return this.state.unidade.tipoUnidade.id !== '' && this.state.unidade.especialidadeUnidade && this.state.unidade.especialidadeUnidade.id;
            default:
                return true;
        }
    };

    maskCnpj(value) {
        return value
            .replace(/\D/g, '')
            .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
    };

    getSteps() {
        return ([
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={8} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={2} className={'paddingForm'}>
                            <FormField type={'text'} id={'unidadePai'}  inputValue={this.state.tipoUnidade[0].sigla} disabled={true} label={'Unidade Pai'}
                                changeValue={(e) => { }} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm autoComplete'} id={'grid-autocomplete'}>
                            <AutoComplete suggestions={this.state.unidadesPai} placeholder={'Busque pelo nome da Unidade Pai'} itemKey={'id'} label={''} id={'unidadePai'}
                                key='autoCompleteUnidadePai' initialSelectedItem={this.state.unidade.unidadePai} itemlabel={'abreviacao'} getInput={this.changeInputState} mandatory={true} />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <FormField type={'text'} label={'Código OBA da Unidade'} id={'codigoOBA'} inputValue={this.state.unidade.codigoOBA}
                                changeValue={this.changeInputState} error={this.errors.codigoOBA} />
                        </Grid>
                        <Grid item xs={12} sm={3} className={'paddingForm'}>
                            <InputSelect label={'Tipo Acesso'} 
                                id={'perfilUsoSistema'} 
                                suggestions={this.state.perfilList}
                                initialSelectedItem={this.state.unidade.perfilUsoSistema} 
                                error={this.state.unidade.perfilUsoSistema != null ? '' : 'Campo obrigatório'}
                                getInput={async (e) => { let u = this.state.unidade; u.perfilUsoSistema = e; await this.setState({ unidade: u }); }}></InputSelect>
                        </Grid>
                        <Grid item xs={12} sm={5} className={'paddingForm'}>
                            <FormField type={'text'} label={'CNPJ'} id={'cnpj'} inputValue={this.maskCnpj(this.state.unidade.cnpj) || ''}
                                changeValue={this.changeInputState} disabled={true} />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <FormField type={'text'} label={'Abreviação'} id={'abreviacao'} inputValue={this.state.unidade.abreviacao}
                                changeValue={this.changeInputState} disabled={true} />
                        </Grid>
                        <Grid item xs={12} sm={8} className={'paddingForm'}>
                            <FormField type={'text'} label={'Descrição'} id={'descricao'} inputValue={this.state.unidade.descricao}
                                changeValue={this.changeInputState} disabled={true} />
                        </Grid>
                        <Grid item xs={12} sm={4} >
                            <FormField type={'text'} label={'Município'} id={'municipio'} inputValue={this.state.unidade.municipio.descricao}
                                changeValue={this.changeInputState} disabled={true} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,

            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <InputSelect key='tipoUnidade' label={'Tipo Unidade'} suggestions={this.state.tipoUnidade} itemLabel={'descricao'} itemKey={'id'}
                                initialSelectedItem={this.state.unidade.tipoUnidade} id={'tipoUnidade'} getInput={this.changeInputState}></InputSelect>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <InputSelect key='especialidadeUnidade' label={'Especialidade Unidade'} id={'especialidadeUnidade'} suggestions={this.state.especialidadeUnidade} itemLabel={'descricao'} itemKey={'id'}
                                initialSelectedItem={this.state.unidade.especialidadeUnidade} getInput={this.changeInputState} />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <AutoComplete label={'Cliente'}
                                placeholder={'busque por nome, cpf/cnpj ou município'}  
                                path={'rest/pessoa/Cliente/0/30?buscaLivre='}
                                itemKey={'id'}
                                key='autoCompleteClientes'
                                initialSelectedItem={this.state.unidade.cliente ? this.state.unidade.cliente : ''}
                                itemlabel={'nome'}
                                id={'cliente'}
                                defaultObject={true}
                                itemTemplate={this.createTemplateCliente}
                                getInput={this.changeInputState} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,

            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} className={'review-div'}>
                            <div className={'review-square'}>
                                <label >1</label>
                            </div>
                            <div className={'review-info'}>
                                <label className={'review-title'}>Dados Básicos da Unidade</label>
                                <Grid container>
                                    <Grid item xs={6}>Unidade Pai:</Grid>
                                    <Grid item xs={6}> {this.state.tipoUnidade[0].sigla} - {this.state.unidade.unidadePai ? this.state.unidade.unidadePai.descricao : ''}</Grid>
                                    <Grid item xs={6}>Código OBA:</Grid>
                                    <Grid item xs={6}>{this.state.unidade.codigoOBA}</Grid>
                                    <Grid item xs={6}>CNPJ:</Grid>
                                    <Grid item xs={6}>{this.maskCnpj(this.state.unidade.cnpj)} </Grid>
                                    <Grid item xs={6}>Abreviação:</Grid>
                                    <Grid item xs={6}>{this.state.unidade.abreviacao}</Grid>
                                    <Grid item xs={6}>Descrição:</Grid>
                                    <Grid item xs={6}>{this.state.unidade.descricao}</Grid>
                                    <Grid item xs={6}>Município:</Grid>
                                    <Grid item xs={6}>{this.state.unidade.municipio ? this.state.unidade.municipio.descricao : ''}</Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={'review-div'}>
                            <div className={'review-square'}>
                                <label>2</label>
                            </div>
                            <div className={'review-info'}>
                                <label className={'review-title'}>Especificações da Unidade</label>
                                <Grid container>
                                    <Grid item xs={6}>Tipo unidade:</Grid>
                                    <Grid item xs={6}>{this.state.unidade.tipoUnidade.descricao}</Grid>
                                    <Grid item xs={6}>Especialidade:</Grid>
                                    <Grid item xs={6}>{this.state.unidade.especialidadeUnidade.descricao}</Grid>
                                    <Grid item xs={6}>Cliente:</Grid>
                                    <Grid item xs={6}>{this.state.unidade.cliente ? this.state.unidade.cliente.nome : ''}</Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>]
        );
    };


    render() {
        return (
            <Page icon={'profile.svg'} label={'Nova Unidade'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container id={'nova-unidade'}>
                    <Stepper titles={this.titles} steps={this.getSteps()} reset={'Unidade criada com sucesso!'} sendForm={this.salvarUnidade} history={this.props.history} validation={this.validateUnidade}/>
                </Grid>
            </Page>
        );
    };
}