import React, { Component } from 'react';
import EncontrosDefault from './encontrosDefault';
import API from '../../comm/API';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtilDigital';
import AtividadeDefaultDigital from './atividadeDefaultDigital';
import AtividadeSetupPlataformaIot from './atividadeSetupPlataformaIot';
import AtendimentosCardDigital from './atendimentosCardDigital';

export default class ConsultoriaB extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'encontros'
    };

    constructor(props) {
        super(props);
        const etapaName = `etapaConsultoria${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.turma = this.props.turma.nome;
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Consultoria B';
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);
        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoConsultoria: undefined,
            etapa,
            atendimentoList,
            clientName: '',
            disabled: false
        };
    };

    getSetupMinaModel(){
        return { 
            status: 1, 
            ordemVisita: null, 
            anexoSetupMINAList: [], 
            maisInfo: ""
        };
    }
    
    getModels = () => {
        return {
            setupIOT: this.getSetupMinaModel(),
        }
    }

    validateSetupIOT(setupIOT){
        return  setupIOT.anexoSetupMINAList && setupIOT.anexoSetupMINAList.every(elm => { return elm.descricao });
    }


    updateViewData = (atendimento) => {
        //Vitor
        let index = this.state.turma.atendimentoTurmaList.findIndex(obj => { return obj.atendimento.id === atendimento.idAtendimento });
        this.setState({disabled:this.state.turma.atendimentoTurmaList[index].atendimento.relatorioFinal != null  || this.state.turma.status === 3})

        this.viewData.encontros.data = atendimento.encontroList;
        const { setupMINA } = atendimento; 
        
        this.viewData.atividades.setupMINA.data =  JSON.parse(JSON.stringify(setupMINA || this.getSetupMinaModel()));
        this.viewData.atividades.setupMINA.anexos.data = getByKeyOrEmpty(setupMINA, 'anexoSetupMINAList');
    };

    validateAtendimentoConsultoria = async (atendimentoConsultoria) => {  
        let encontroList = atendimentoConsultoria.encontroList;

        let setupMINA = atendimentoConsultoria.setupMINA;
        if (!atendimentoConsultoria.cancelado) {
            return await encontroList && encontroList.length > 0 && encontroList.every(encontro => { return this.validaEncontro(encontro) }) 
                 
        }
        return true;        
    };


    validaEncontro = (encontro) => {
        return encontro && encontro.anexoEncontroList && encontro.anexoEncontroList.length > 0 && !encontro.anexoEncontroList.filter(anx => { return !anx.isDocumento }).some(anx => { return anx.descricao === '' }) &&
        encontro.data && encontro.isVirtual != null && encontro.turno != null  && encontro.idMentor != null && encontro.horasApropriadas > 0;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoConsultoria = this.state.atendimentoConsultoria;
        let turma = this.props.turma;
        let validado = await this.validateAtendimentoConsultoria(atendimentoConsultoria);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }


        if (validado) {
            this.props.showLoading();
            atendimentoConsultoria.finalizado = true;
            let indexAtendimento = turma.etapaConsultoriaB.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
            turma.etapaConsultoriaB.atendimentoList[indexAtendimento] = atendimentoConsultoria; //atualiza atendimentoConsultoria na etapa pratica b da turma
            let etapa = turma.etapaConsultoriaB;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaConsultoriaB;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.props.closeLoading();
                        this.setState({ turma, etapa, atendimentoList, atendimentoConsultoria: atd }, this.goBackAtendimentos());
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    validaAtividade = async (viewData, atendimentoConsultoria, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoConsultoria[nomeAtividade] ? atendimentoConsultoria[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoConsultoria[nomeAtividade] = atividade;
    };
    
    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoConsultoria = this.state.atendimentoConsultoria;
        let encontrosData = viewData.encontros.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;

            if (currScreenAtividade === 'setupIOT') 
                await this.validaAtividade(viewData, atendimentoConsultoria, currScreenAtividade, 'setupMINA', 'anexoSetupMINAList', this.validateSetupIOT, this.STATUS_FINALIZADO);

            if (selectedVisita) {
                selectedVisita.anexoEncontroList = [];
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.imagens != null ? selectedVisita.imagens : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaEncontro(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = encontrosData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoConsultoria.encontroList[index] = selectedVisita;
                else atendimentoConsultoria.encontroList.push(selectedVisita);
            }
            atendimentoConsultoria.horasApropriadas = atendimentoConsultoria.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let atividades = viewData.atividades;
            let setupMINA = atividades.setupMINA;

            if ((!setupMINA.data) || (setupMINA.data  && !setupMINA.data.ordemVisita)) atendimentoConsultoria.setupMINA = null;
            atendimentoConsultoria.encontroList = encontrosData;
            atendimentoConsultoria.horasApropriadas = atendimentoConsultoria.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        let indexAtendimento = turma.etapaConsultoriaB.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
        turma.etapaConsultoriaB.atendimentoList[indexAtendimento] = atendimentoConsultoria; //atualiza atendimentoConsultoria na etapa pratica b da turma

        if(atendimentoConsultoria.horasApropriadas > 8){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else {return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
            return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                turma = res.data;
                etapa = turma.etapaConsultoriaB;
                let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
                let atd = etapa.atendimentoList[index];
                await this.updateViewData(atd);
                this.setState({ turma, etapa, atendimentoConsultoria: atd });
                this.props.showToast('Etapa editada com sucesso!', 'success');
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }).catch(error => {
            this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        });}
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = true;
        let showAnexos = true;
        let render = null;


        switch (selectedAtividade.screenAtividade) {
            case 'setupIOT':
                render = <AtividadeSetupPlataformaIot 
                atividade={selectedAtividade.data}
                //vitor
                disabled={this.state.disabled} />;
                showEmptyEvidencias = false;
                break;
            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefaultDigital
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                addInfoAnexo={'Anexe imagens da tela da plataforma IOT, de uma videochamada e/ou dos responsáveis utilizando o programa: '}
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                invertAnexos={true}
                disabled={this.state.disabled}
                border={false}
                showTopButtons={ showTopButtons && !this.state.disabled}
                showAnexos={ showAnexos }
                renderChildren={ render }
                clientName={ this.state.clientName }
                viewData={ this.state.viewData } 
                />
        );
    };

    renderVisita = (atendimento) => {
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoConsultoria: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoConsultoria: undefined });
    };

    render() {
        return (<>
            { this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCardDigital
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Consultoria' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita } 
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <EncontrosDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    models={ this.getModels }
                    disabled={this.state.disabled}
                    atendimento={ this.state.atendimentoConsultoria }
                    etapa = {this.state.etapa}
                    saveEtapa={ this.saveEtapa }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    dontShowFinalizarMentoria={ this.state.atendimentoConsultoria.finalizado }
                    mentoresDados={this.props.mentoresDados}
                />
            }
        </>)
    };
};