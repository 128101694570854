import { TipoInputEnum } from "./tipoInput";

export const InputBMPEEEnum = {
    MedicaoInicialConsumo: { idProgramaToInput: 9, nome: "Medição de Consumo", id_tipo_input: TipoInputEnum.MedicaoInicial },
    MedicaoFinalConsumo: { idProgramaToInput: 14, nome: "Medição de Consumo", id_tipo_input: TipoInputEnum.MedicaoFinal },
    ValorKWhMedio: { idProgramaToInput: 1, nome: "Valor do KWh Médio", id_tipo_input: TipoInputEnum.ValorUnico },
    EconomiaCorrecaoFatorPotencia: { idProgramaToInput: 2, nome: "Análise tarifária - Redução de custo com Correção de Fator de Potência", id_tipo_input: TipoInputEnum.ValorUnico },
    EconomiaReadequacaoDemandaContratada: { idProgramaToInput: 3, nome: "Análise tarifária - Redução de custo com Readequação da Demanda Contratada", id_tipo_input: TipoInputEnum.ValorUnico },
    EconomiaReequadramentoTarifario: { idProgramaToInput: 4, nome: "Análise tarifária - Redução de custo com Reenquadramento Tarifário", id_tipo_input: TipoInputEnum.ValorUnico },
    InvestimentoAcaoAnaliseTarifaria: { idProgramaToInput: 5, nome: "Investimentos com Ações da Análise Tarifária [OPEX + CAPEX]", id_tipo_input: TipoInputEnum.ValorUnico },
    InvestimentoUsosFinais: { idProgramaToInput: 6, nome: "Investimentos com Usos Finais [OPEX + CAPEX]", id_tipo_input: TipoInputEnum.ValorUnico },
    SmartFactory: { idProgramaToInput: 19, nome: "Empresa apta a Categoria Smart Factory?", id_tipo_input: TipoInputEnum.SmartFactory }
};