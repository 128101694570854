import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { ThemeProvider } from '@material-ui/styles';
import Icon from '@material-ui/core/Icon';
import GenericButton from '../genericButton/genericButton';
import { Grid } from '@material-ui/core';
import './stepper.scss';

const tabelaSgtTheme = createMuiTheme({
    typography: {
        fontFamily: [
            'Raleway',
            '-apple-system',
            'BlinkMacSystemFont',
        ].join(','),
        fontWeightMedium: 500,
        body1: {
            fontWeight: 500,
        },
        subtitle1: {
            fontSize: 12,
        },
        button: {
            fontStyle: 'italic',
        },
    },
});


export default function HorizontalLabelPositionBelowStepper(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = props.titles;

    function handleNext() {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (props.step) props.step(activeStep);
    }

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    }

    function goBackPage() {
        props.history.goBack();
    }

    return (
        <ThemeProvider theme={tabelaSgtTheme}>
            <Grid container className={'stepper'}>
                <Grid item xs={12} lg={9} xl={8}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map(
                            (label, index) => {
                                if (index < activeStep) {
                                    return <Step key={label}>
                                        <StepLabel icon={<Icon fontSize={'small'}>done</Icon>}>{label}</StepLabel>
                                    </Step>
                                } else {
                                    return <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                }
                            })}
                    </Stepper>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={'stepperBody'}>
                        <Grid item xs={12} className={'stepperBodyTitle'}>
                            <p>
                            {props.newTitle ? props.newTitle[activeStep] : props.titles[activeStep]}                     
                            </p>
                        </Grid>
                        <Grid item xs={12} className={'stepperBodySon'}>
                            {props.steps[activeStep]}
                        </Grid>
                        <Grid item className={'rodapeStepper'}>
                            <GenericButton key={'buttonleft'}
                                color={'darkBlue-outline'}
                                subClass={'basic-button'}
                                label={activeStep === 0 ? 'Cancelar' : 'Voltar'}
                                click={activeStep === 0 ? goBackPage : handleBack}
                                disabled={activeStep === 0 && !props.history ? true : false} />
                        </Grid>
                        {props.hasMiddleButton && <Grid item className={'rodapeStepper'}> <GenericButton key={'buttonmiddle'}
                            color={'lightBlue'}
                            subClass={'basic-button'}
                            label={(activeStep === steps.length - 1 && props.isDN) ? 'Recusar' : 'Salvar'}
                            click={activeStep === steps.length - 1 ? props.middleButtonSendForm : props.middleButtonClick}
                            disabled={props.disableMiddleButton}
                        /> </Grid>}
                        <Grid item className={'rodapeStepper'}>
                            <GenericButton key={'buttonright'}
                                color={'darkBlue'}
                                subClass={'basic-button'}
                                label={activeStep === steps.length - 1 ? `${props.sendButton ? props.sendButton : 'Finalizar'}` : 'Próximo'}
                                click={activeStep === steps.length - 1 ? props.sendForm : handleNext}
                                title={activeStep === steps.length - 1 && props.title ? props.title : ''}
                                disabled={props.validation != null ? !props.validation(activeStep) : false} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}