import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

export default class PageBrasilMais extends Component {

    render() {
        return (
            <Grid container className={'page-brasil-mais'}>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item md={6}>
                            <Grid container>
                                <Grid item xs={3} md={1}>
                                </Grid>
                                <Grid item xs={9} md={11} className={'turmaNome'}>
                                    <label>{this.props.name}</label>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Grid container>
                                <Grid item xs={2} md={1}>
                                    <div className={'turmaEtapaCircle'}><span>{this.props.fase}</span></div>
                                </Grid>
                                <Grid item xs={4} md={11} className={'headerMentoria'}>
                                    {this.props.title}
                                </Grid>
                            </Grid>
                        </Grid>
                        { this.props.renderTopButtons ? this.props.renderTopButtons() : null }
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item md={6}>
                                <Grid container>
                                    <Grid item md={1}>
                                    </Grid>
                                    <Grid item md={11} className={'turma-producao'}>
                                        <label>{this.props.subTitle}</label>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {this.props.children}
            </Grid>
        );
    };
};