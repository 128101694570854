import * as Sentry from "@sentry/react";
import React, { Component } from "react";
import WebFont from "webfontloader";
import Routes from "./routes";

WebFont.load({
  google: {
    families: ["Raleway:400,500,600,700,800,900", "sans-serif"],
  },
});

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

class App extends Component {
  render() {
    return (
      <Sentry.ErrorBoundary fallback={<p></p>}>
        <Routes />
      </Sentry.ErrorBoundary>
    );
  }
}

export default App;
