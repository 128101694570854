import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import Carousel from '../../../components/carousel/carousel';
import SectorCard from '../../../components/sectorCard/sectorCard';
import TurmaCards from '../../../components/turmaCard/turmaCard';
import GraficoAlunoTurmaPorEstado from './graficoAlunoTurmaPorEstado.js';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import GraficoMentoresPorEstado from './graficoMentoresPorEstado';
import GraficoAlunosTurmasDR from './graficoAlunosTurmasDR';
import PainelTurmas from './painelTurmas';
import { SettingsCellSharp } from '@material-ui/icons';
import CarouselNew from '../../../components/carouselNew/carouselNew';
import SectorHeaderCard from '../../../components/sectorHeaderCard/sectorHeaderCard';
import GraficoConsultoresHomologados from './graficoConsultoresHomologados';

export default class DashboardBrasilMaisTurmas extends Component {

	constructor(props) {
		super(props);
		this.state = {
			turmasConcluidas: 0,
			alunosInicio: 0,
			empresasInicio: 0,
			unidadesAtendendo: 0,
			turmasAtrasadas: 0,
			ferramentaMaisUtilizada: 0,
			ferramentasCards: [],
			turmaCards: []
		}
		if (this.props.perfil === 'DN') {
			API.get('rest/sistema/DashboardBrasilMais/ferramentas').then(json => {
			if (json.data && json.data.length > 0) this.setState({ ferramentasCards: json.data })
			}).catch(error => {
				console.log(error);
			});
		}
		API.get('rest/sistema/DashboardBrasilMais/cardsTurma').then(res => {
			let dados = res.data;
			if (this.props.perfil === 'DN'){
				let data = [];
				data.alunos_mentorados = {
					L : dados.alunos_mentorados.lean,
					D : dados.alunos_mentorados.digital,
					LD : dados.alunos_mentorados.ambos,
				}
				data.empresas_atendidas = {
					L : dados.empresas_atendidas.lean,
					D : dados.empresas_atendidas.digital,
					LD : dados.empresas_atendidas.ambos,
				}
				data.unidades_operacionais = {
					L : dados.unidades_operacionais.lean,
					D : dados.unidades_operacionais.digital,
					LD : dados.unidades_operacionais.ambos,
				}
				data.turmas_concluidas = {
					L : dados.turmas_concluidas.lean,
					D : dados.turmas_concluidas.digital,
					LD : dados.turmas_concluidas.ambos,
				}
				this.setState({turmaCards : data})
			}
			else if (dados) {
				this.setState({ turmasConcluidas: dados.turmasConcluidas, alunosInicio: dados.alunosInicio, empresasInicio: dados.empresasInicio, 
					unidadesAtendendo: dados.unidadesAtendendo, turmasAtrasadas: dados.turmasAtrasadas, ferramentaMaisUtilizada: dados.ferramentaMaisUtilizada });
			} 
		}).catch(error => {
			console.log(error);
		});
	}

	getFerramentasModel() {
		return [
			['-', 3, 0],
			['-', 4, 0],
			['-', 5, 0],
			['-', 6, 0],
			['-', 7, 0],
			['-', 8, 0],
			['-', 9, 0],
			['-', 10, 0]
		];
	}

	tools = [
		{ id: 3, name: 'Fluxo contínuo', toolIcon: './../../assets/icons/fluxo-continuo-painel.svg', toolWhiteIcon: './../../assets/icons/fluxo-continuo.svg' },
		{ id: 4, name: 'Qualidade na fonte', toolIcon: './../../assets/icons/qualidade-na-fonte-painel.svg', toolWhiteIcon: './../../assets/icons/qualidade-fonte.svg' },
		{ id: 5, name: 'Troca rápida de ferramenta', toolIcon: './../../assets/icons/troca-rapida-painel.svg', toolWhiteIcon: './../../assets/icons/troca-rapida-ferramenta.svg' },
		{ id: 6, name: '5S', toolIcon: './../../assets/icons/5s-painel.svg', toolWhiteIcon: './../../assets/icons/cinco-s.svg' },
		{ id: 7, name: 'Trabalho padronizado', toolIcon: './../../assets/icons/trabalho-padronizado-painel.svg', toolWhiteIcon: './../../assets/icons/trabalho-padronizado.svg' },
		{ id: 8, name: 'Produção puxada', toolIcon: './../../assets/icons/producao-puxada-painel.svg', toolWhiteIcon: './../../assets/icons/producao-puxada.svg' },
		{ id: 9, name: 'Outra Ferramenta', toolIcon: './../../assets/icons/outra-ferramenta-painel.svg', toolWhiteIcon: './../../assets/icons/outras-ferramentas.svg' },
		{ id: 10, name: 'MFV', toolIcon: './../../assets/icons/mfv-painel.svg', toolWhiteIcon: '' },
	];

	ferramentasCarousel() {
		var content = [];
		let ferramentas = this.state.ferramentasCards;
		if(ferramentas.length > 0){for(let i = 0;i<8;i++){
			let tool = this.tools.find(a => a.id === (i + 3));
			content.push(
				<div key={'carrossel-card-' + i}>
					<SectorCard description={ferramentas[i].nome_ferramenta} use={ ferramentas[i].qtd_utilizada } sector={ ferramentas[i].nome_setor } icons={tool.toolIcon} tabSector={false} valueOnly={this.props.perfil === 'DN' ? true : false}/>
				</div>
			);
		}}
		return content;
	}

	render() {
		let toolMaisUtilizada = this.props.perfil !== 'UO' ? '' :
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<span>Ferramenta opcional mais utilizada</span>
				{ this.state.ferramentaMaisUtilizada >= 3 && <img style={{ height: '55px', width: '55px', marginTop: '10px', marginBottom: '10px' }} src={this.tools[this.state.ferramentaMaisUtilizada - 3].toolWhiteIcon} alt='' /> }
			</div>;
		let faseAtual;
		switch(this.props.fase){
			case 'D':
				faseAtual = "Mentoria Digital";
				break;
			case "L":
				faseAtual = "Mentoria Lean";
				break;
			case "LD":
				faseAtual = "Brasil Mais";
				break;
		}
		if (this.props.perfil === 'DN')
			return (
				<Grid container className={'dashboard-brasil-mais-turmas'}>
					<Grid item xs={12} className={'dashboard-cards'}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={3} className={'card'}>
								<DashboardCard 
								id = {'invertido'} 
								color={'card-blue'} 
								text={
									<div className={'card-label-light'}>
									<label>Turmas concluídas </label>
									<label> {faseAtual} </label>
									</div> } 
								value={this.state.turmaCards.turmas_concluidas ? this.state.turmaCards.turmas_concluidas[this.props.fase].toLocaleString('pt-BR') : 0} 
								 />
							</Grid>
							<Grid item xs={12} md={3} className={'card'}>
								<DashboardCard 
								id = {'invertido'}
								color={'card-blue'} 
								text={
									<div className={'card-label-light'}>
										<label>Alunos mentorados </label>
										<label> {faseAtual} </label>
									</div>} 
								value={this.state.turmaCards.alunos_mentorados ? this.state.turmaCards.alunos_mentorados[this.props.fase].toLocaleString('pt-BR') : 0}  />
							</Grid>
							<Grid item xs={12} md={3} className={'card'}>
								<DashboardCard
								id = {'invertido'} 
								color={'card-blue'} 
								text={
								<div className={'card-label-light'}>
									<label>Empresas atendidas </label>
									<label> {faseAtual} </label>
								</div>} 
								value={this.state.turmaCards.empresas_atendidas ? this.state.turmaCards.empresas_atendidas[this.props.fase].toLocaleString('pt-BR') : 0} 
								 />
							</Grid>
							<Grid item xs={12} md={3} className={'card'}>
								<DashboardCard 
								id = {'invertido'}
								color={'card-blue'} 
								text={
								<div className={'card-label-light'}>
									<label>Unidades operacionais </label>
									<label> {faseAtual} </label>
								</div>} 
								value={this.state.turmaCards.unidades_operacionais ? this.state.turmaCards.unidades_operacionais[this.props.fase].toLocaleString('pt-BR') : 0}/>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12} container justifyContent='center' style={{ marginTop: '24px' }}>
						<Grid item className={'font-title-carousel-title'} xs={12} container justifyContent='center'>
							<label>
								{'FERRAMENTAS UTILIZADAS NOS ATENDIMENTOS CONCLUÍDOS'}
							</label>
						</Grid>
						<Grid item className={'font-title-carousel-subtitle'} xs={12} container justifyContent='center'>
							<label>
								{this.props.fase == "L" ? `Fase 1: Mentoria Lean`	:
								this.props.fase == "D" ? `Fase 2: Mentoria Digital` : `Brasil Mais`	}
							</label>
						</Grid>
						<Grid item xs={12}>
							<CarouselNew header ={<SectorHeaderCard tabSector={false}/>} content={this.ferramentasCarousel()} />
						</Grid>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '24px' }}>
						<GraficoConsultoresHomologados fase={this.props.fase} />
					</Grid>
				</Grid>
			);
		else if (this.props.perfil === 'DR')
			return (
            	<Grid container className={'dashboard-brasil-mais-turmas'}>
					<TurmaCards />
					<Grid item xs={12} style={{ marginTop: '24px' }}>
		    			<GraficoAlunosTurmasDR />
		    		</Grid>
            	</Grid>
            );
		else if (this.props.perfil === 'UO')
			return (
            	<Grid container className={'dashboard-brasil-mais-turmas'}>
            		<Grid item xs={12}>
            			<Grid container spacing={3}>
            				<Grid item xs={8}>
		            			<TurmaCards perfil={this.props.perfil} />
		            		</Grid>
		            		<Grid item xs={4} className={'dashboard-cards aba-turma-cards'}>
								<Grid container spacing={3} direction="column" justify="center">
				            		<Grid item xs={12}>
										<DashboardCard color={'card-dark-gray'} text={'Turmas com atraso no cronograma'} value={this.state.turmasAtrasadas} style={{ height: '238px' }} />
									</Grid>
									<Grid item xs={12} className={'card'}>
						                <DashboardCard color={'card-blue'} text={toolMaisUtilizada} value={this.state.ferramentaMaisUtilizada >= 3 ? this.tools[this.state.ferramentaMaisUtilizada - 3].name : '-'} style={{ height: '238px' }} /> 
						            </Grid>
								</Grid>
							</Grid>
            			</Grid>
						<PainelTurmas />
            		</Grid>
            	</Grid>
            );
	}

}