import { TipoInputEnum } from "./tipoInput";

export const InputBMPTDEnum = {
    NivelIntegracaoVertical: { idProgramaToInput: 38, nome: "Processos - Nível de Integração Vertical", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelIntegracaoHorizontal: { idProgramaToInput: 39, nome: "Processos - Nível de Integração Horizontal", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelIntegracaoCicloVidaProduto: { idProgramaToInput: 40, nome: "Processos - Nível de Integração do Ciclo de Vida do Produto", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelAutomacaoChaoFabrica: { idProgramaToInput: 41, nome: "Tecnologia - Nível de Automação no Chão de Fábrica", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelAutomacaoEmpresa: { idProgramaToInput: 42, nome: "Tecnologia - Nível de Automação na Empresa", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelAutomacaoInstalacoes: { idProgramaToInput: 43, nome: "Tecnologia - Nível de Automação nas Instalações", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelConectividadeChaoFabrica: { idProgramaToInput: 44, nome: "Tecnologia - Nível de Conectividade no Chão de Fábrica", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelConectividadeEmpresa: { idProgramaToInput: 45, nome: "Tecnologia - Nível de Conectividade na Empresa", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelConectividadeInstalacoes: { idProgramaToInput: 46, nome: "Tecnologia - Nível de Conectividade nas Instalações", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelInteligenciaChaoFabrica: { idProgramaToInput: 47, nome: "Tecnologia - Nível de Inteligência no Chão de Fábrica", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelInteligenciaEmpresa: { idProgramaToInput: 48, nome: "Tecnologia - Nível de Inteligência na Empresa", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelInteligenciaInstalacoes: { idProgramaToInput: 49, nome: "Tecnologia - Nível de Inteligência nas Instalações", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelDesenvolvimentoCapacitacaoMaoObra: { idProgramaToInput: 50, nome: "Organização - Nível de Desenvolvimento e Capacitação da Mão de Obra", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelLideranca: { idProgramaToInput: 51, nome: "Organização - Nível de Liderança", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelColaboracaoIntraEntreEmpresas: { idProgramaToInput: 52, nome: "Organização - Nível de Colaboração Intra e Entre Empresas", id_tipo_input: TipoInputEnum.NivelTransformacao },
    NivelEstrategiaGovernanca: { idProgramaToInput: 53, nome: "Organização - Nível de Estratégia e Governança", id_tipo_input: TipoInputEnum.NivelTransformacao },
    AreaPriorizadaInvestimento: { idProgramaToInput: 54, nome: "Dimensão Priorizada para Investimento", id_tipo_input: TipoInputEnum.PrioridadeTransformacao },
    FaixaInvestimento: { idProgramaToInput: 55, nome: "Faixa de Investimento", id_tipo_input: TipoInputEnum.FaixaTransformacao },
    TecnologiaPrincipal: { idProgramaToInput: 56, nome: "Tecnologia Principal", id_tipo_input: TipoInputEnum.TecnologiaTransformacao },
    IncluiHardware: { idProgramaToInput: 57, nome: "Inclui Hardware", id_tipo_input: TipoInputEnum.IncluiTransformacao },
    IncluiSoftware: { idProgramaToInput: 58, nome: "Inclui Software", id_tipo_input: TipoInputEnum.IncluiTransformacao },
    IncluiServico: { idProgramaToInput: 59, nome: "Inclui Serviço", id_tipo_input: TipoInputEnum.IncluiTransformacao },
    OpcaoInvestimentoEmpresa: { idProgramaToInput: 60, nome: "Opção Da Empresa", id_tipo_input: TipoInputEnum.InvestimentoTransformacao },
    OportunidadePDIIndentificada: { idProgramaToInput: 61, nome: "Oportunidade de PDI identificada", id_tipo_input: TipoInputEnum.Aprovacao },
    CnpjFornecedorPrincipal: { idProgramaToInput: 62, nome: "CNPJ do fornecedor principal", id_tipo_input: TipoInputEnum.Cnpj },
};
