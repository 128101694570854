import React, {Component} from 'react';
import FormField from '../../components/formField/formField';
import InputSelect from './../../components/inputselect/inputselect';
import Stepper from '../../components/stepper/stepper';
import { Grid } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import Page from '../../components/page/page'
import API, { BASE_URL } from './../../comm/API';
import { SyncRequest } from './../../comm/APIsync';
import './usuarioNovo.scss';

export default class UsuarioNovo extends Component { 

    usuario = {};
    usuarioConectado = {};
    linhasChips = []; //lista para exibir no input
    linhasMappedP = []; //lista para exibir na revisão
    unidadesPermitidas = [];
    titles = ['Dados de acesso', 'Informações do Perfil', 'Revisão'];
    ativoList = [{type: 'isAtivo', label: 'SIM', valor: true}, {type: 'isAtivo', label: 'NÃO', valor: false}];
    errors = { nome: '', login: '', senha: '', unidade: '', perfilUsuario: '', produtoLinha: '', isAtivo: '' };
    
    constructor(props) {
        super(props);
        this.state = {linhas: [], unidades: [], perfisUsuario: [], isEdicao: false};   
        this.usuario = this.usuarioModel(); 
        var father = this;  
        var filtroUnidade = '/usuario'; // SE DN
        if (props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR') { 
            filtroUnidade = '?id=' + props.usuarioConectado.unidade.id;
        } else if (props.usuarioConectado.unidade.tipoUnidade.sigla === 'UO') {
            filtroUnidade = '?id=' + props.usuarioConectado.unidade.unidadePai.id;
        }
        this.getInfos(filtroUnidade);
        if (props.idUsuario != null && !isNaN(props.idUsuario)) {
            var res = SyncRequest('get', BASE_URL + 'rest/sistema/Usuario/usuario/' + props.idUsuario);
            this.usuario = res;
            if (this.usuario !== false && this.usuario != null) {
                if (this.usuario.unidade.tipoUnidade.sigla === 'UO') this.changePerfil(this.usuario.unidade.unidadePai.id);
                else this.changePerfil(this.usuario.unidade.id);
                this.linhasChips = this.usuario.usuarioProdutoLinhaList.map(function(linhaUsuario) {
                    return <Chip key={linhaUsuario.produtoLinha.id} label={`${linhaUsuario.produtoLinha.descricao}`} 
                            icon={<Icon onClick={(e) => father.updateLinhas(linhaUsuario.produtoLinha.id)}>clear</Icon>} />
                });
                this.linhasMappedP = this.usuario.usuarioProdutoLinhaList.map(function(linhaUsuario) {
                    return <p key={'p'+ linhaUsuario.produtoLinha.id}>{linhaUsuario.produtoLinha.descricao}</p>
                });
                this.state = {...this.state, isEdicao: true};
            } else {
                this.state = {...this.state};
            }
        }
    };

    getInfos = (filtroUnidade) => {
        API.get('rest/gestao/Unidade' + filtroUnidade).then(res => { //unidade do usuario
            this.closeLoading();
            this.setState({unidades: res.data});
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao realizar GET das unidades.', 'error');
        });
        API.get('rest/sistema/controle/unidadesPermitidas').then(res => {
            this.unidadesPermitidas = res.data;
        }).catch(error => {
            this.showToast('Error ao realizar GET dos controles.', 'error');
        });
        API.get('rest/auxiliar/ProdutoLinha?isVisivel=true').then(res => {
            this.setState({linhas: res.data});
        }).catch(error => {
            this.showToast('Error ao realizar GET das linhas.', 'error');
        });
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    changePerfil(unidade) {
        API.get('rest/sistema/PerfilUsuario/usuario/' + unidade).then(res => {
            this.setState({perfisUsuario: res.data});
        }).catch(error => {
            this.showToast('Error ao realizar GET dos perfis.', 'error');
        });
    }

    usuarioModel() {
        this.linhasMappedP = [];
        this.linhasChips = [];
        return ({id: null, nome: '', login: '', senha: '', isAdministrador: false, isAtivo: true, urlFoto: 'dist/img/user-login.png', 
            idUltimoDashboardAcessado: null, colaborador: null, unidade: null, perfilUsuario: null, perfilUsoSistema: null, 
            isIntegracao: false, usuarioProdutoLinhaList: []});
    }

    changeInput = (e) => {
        if (e != null && e.target != null) {
            this.usuario[e.target.id] = e.target.value;
            if (e.target.value == null || e.target.value === '') {
                this.errors[e.target.id] = 'Campo obrigatório';
            } else if (e.target.id === 'login' && !this.validateLogin()) {
                this.errors[e.target.id] = 'Login inválido'
            } else {
                this.errors[e.target.id] = '';
            }
        } else if (e != null && e.type != null) {
            if (e.type === 'unidade' && e.id != null && (this.usuario.unidade == null || 
                (this.usuario.unidade != null && this.usuario.unidade.tipoUnidade.sigla !== 'UO' && e.id !== this.usuario.unidade.id) ||
                (this.usuario.unidade != null && this.usuario.unidade.tipoUnidade.sigla === 'UO' && this.usuario.unidade.unidadePai.id !== e.id))) {
                this.usuario.perfilUsuario = null;
                this.changePerfil(e.id);
            } 
            if (e.type === 'perfilUsuario' && e.nome.toUpperCase() === 'ADMINISTRADOR') this.usuario.isAdministrador = true;
            else if (e.type === 'perfilUsuario') this.usuario.isAdministrador = false;
            this.usuario[e.type] = e.id != null ? e : e.valor;
            this.errors[e.type] = '';
        } else if (e == null) {
            this.errors['unidade'] = 'Campo obrigatório';
        }
        this.setState(this.state);
    };

    updateLinhas = (e) => {
        var id = e.id != null ? e.id : e;
        var linha = this.state.linhas.find((x) => Number(x.id) === Number(id)); //encontra linha selecionada
        var index = linha != null ? this.usuario.usuarioProdutoLinhaList.findIndex(obj => Number(obj.produtoLinha.id) === Number(linha.id)) : -1;
        if (index === -1 && linha != null) { //adiciona linha selecionada
            this.usuario.usuarioProdutoLinhaList.push({produtoLinha: linha});
            this.linhasChips.push(<Chip key={linha.id} label={`${linha.descricao}`} icon={<Icon onClick={(e) => this.updateLinhas(linha.id)}>clear</Icon>} />);
            this.linhasMappedP.push(<p key={'p'+ linha.id}>{linha.descricao}</p>); 
        } else if (e !== '' && e !== null && e.id == null) { //ou remove linha selecionada se click on x
            this.usuario.usuarioProdutoLinhaList.splice(index, 1);
            this.linhasMappedP.splice(index, 1);
            this.linhasChips.splice(index, 1);
        }
        if (this.usuario.usuarioProdutoLinhaList.length <= 0) {
            this.errors.produtoLinha = 'Adicione pelo menos uma linha.'; 
        } else {
            this.errors.produtoLinha = '';
        }
        this.setState(this.state);
    };

    salvarUsuario = () => {
        this.showLoading();
        this.usuario.nome = this.usuario.nome.toUpperCase();
        this.usuario.login = this.usuario.login.toLowerCase();
        if (this.props.usuarioConectado.unidade.tipoUnidade.sigla !== 'UO') {
            var idunidade = this.usuario.unidade.tipoUnidade.sigla !== 'UO' ? this.usuario.unidade.id : this.usuario.unidade.unidadePai.id;
            var index = this.unidadesPermitidas === [] ? -1 : this.unidadesPermitidas.indexOf(idunidade);
            if (index !== -1 || this.usuario.unidade.tipoUnidade.sigla === 'DN' || !this.usuario.isAtivo) {
                var filtro = '';
                if (this.props.idUsuario != null && !isNaN(this.props.idUsuario)) filtro = '/' + this.props.idUsuario
                API.post('rest/sistema/Usuario' + filtro, this.usuario).then(res => {
                    this.closeLoading();
                    if (filtro !== '') this.showToast('Usuário atualizado com sucesso!', 'success', '/cad/sistema/usuario');
                    else this.showToast('Usuário cadastrado com sucesso!', 'success', '/cad/sistema/usuario');
                }).catch(error => {
                    this.closeLoading();
                    if (error.response.data[0].path.includes('login') > -1)
                        this.showToast('Login já cadastrado no SGT. Por favor, informe um login válido.', 'error');
                    else
                        this.showToast('Error ao tentar cadastrar o usuário.\nFavor, entrar em contato com o suporte.', 'error');
                });
            } else if (this.usuario.id) {
                this.closeLoading();
                this.showToast('Não é possível ativar usuário pois o limite de usuários ativos no regional já foi atingido.', 'error');
            } else {
                this.closeLoading();
                this.showToast('Não é possível criar usuário ativo pois o limite de usuários ativos já foi atingido.', 'error');
            }
        } else {
            this.closeLoading();
            this.showToast('Unidade UO não cria usuário.', 'error');
        }
    };

    validateUsuario = (step) => {
        switch (step) {
            case 0:
                return this.usuario.nome && this.errors.login === '' && this.validateSenha();
            case 1:
                return this.usuario.unidade && this.usuario.perfilUsuario && this.usuario.usuarioProdutoLinhaList.length > 0;
            default:
                return true;
        }
    };

    validateLogin() {
        if (this.usuario.login != null && (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.usuario.login)))
            return true;
        return false;
    };

    validateSenha() {
        if (this.usuario.senha !== '' && this.usuario.senha != null && this.usuario.senha.length >= 6 && this.usuario.senha.length <= 20)
            return true;
        else if (this.usuario.senha === '' && this.usuario.id != null)
            return true;
        return false;
    };

    getSteps() {
        return (
            [<Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={12}>
                            <FormField type={'text'} label={'Nome'} id={'nome'} changeValueBlur={this.changeInput} error={this.errors.nome} 
                                initialValue={this.usuario.nome} />
                        </Grid>
                        <Grid item xs={12} sm={7} className={'paddingForm'}>
                            <FormField type={'text'} label={'Login'} id={'login'} placeholder={'Exemplo: admin@admin.com'} changeValueBlur={this.changeInput} 
                                initialValue={this.usuario.login} error={this.errors.login} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <FormField type={'password'} label={'Senha'} id={'senha'} placeholder={'De 6 a 20 caracteres'} changeValueBlur={this.changeInput} initialValue={this.usuario.senha}
                                error={this.errors.senha} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12}>
                            <InputSelect label={'Regional'} suggestions={this.state.unidades} itemLabel={'descricao'} getInput={this.changeInput} id={'unidade'} 
                            value={this.usuario.unidade} error={this.errors.unidade} itemKey={'id'} initialSelectedItem={this.state.isEdicao === false ? 
                            (this.usuario.unidade) : (this.usuario.unidade.tipoUnidade.sigla === 'UO' ? this.usuario.unidade.unidadePai : this.usuario.unidade)} />
                        </Grid>
                        <Grid item xs={9} className={'paddingForm'}>
                            <InputSelect label={'Perfil de menu'} suggestions={this.state.perfisUsuario} itemLabel={'nome'} getInput={this.changeInput} id={'perfilUsuario'} 
                            value={this.usuario.perfilUsuario} error={this.errors.perfilUsuario} itemKey={'id'} initialSelectedItem={this.usuario.perfilUsuario} />
                        </Grid>
                        <Grid item xs={3}>
                            <InputSelect label={'Usuário ativo'} suggestions={this.ativoList} itemLabel={'label'} getInput={this.changeInput} id={'isAtivo'} itemKey={'label'}
                            value={this.usuario.isAtivo ? this.ativoList[0] : this.ativoList[1]} error={this.errors.isAtivo} 
                            initialSelectedItem={this.usuario.isAtivo ? this.ativoList[0] : this.ativoList[1]}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect suggestions={this.state.linhas} itemLabel={'descricao'} label={'Linhas de Serviço'} itemKey={'id'}
                            hasButton={true} getInput={this.updateLinhas} error={this.errors.produtoLinha} />
                        </Grid>
                        <Grid item xs={12} className={'linha-chips'}>
                            <div>{this.linhasChips}</div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} className={'review-div'}>
                            <div className={'review-square'}>
                                <label>1</label>
                            </div>
                            <div className={'review-info'}>
                                <label className={'review-title'}>Dados de acesso</label>
                                <Grid container>
                                    <Grid item xs={3}>Nome</Grid>
                                    <Grid item xs={9}>{this.usuario.nome.toUpperCase()}</Grid>
                                    <Grid item xs={3}>Login</Grid>
                                    <Grid item xs={9}>{this.usuario.login.toLowerCase()}</Grid> 
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12} className={'review-div'}>
                            <div className={'review-square'}>
                                <label>2</label> 
                            </div>
                            <div className={'review-info'}>
                                <label className={'review-title'}>Dados do perfil</label>
                                <Grid container>
                                    <Grid item xs={3}>Unidade</Grid>
                                    <Grid item xs={9}>{this.usuario.unidade ? this.usuario.unidade.descricao : ''}</Grid>
                                    <Grid item xs={3}>Perfil</Grid>
                                    <Grid item xs={9}>{this.usuario.perfilUsuario ? this.usuario.perfilUsuario.nome : ''}</Grid>
                                    <Grid item xs={3}>Linhas de Serviço</Grid>
                                    <Grid item xs={9}>{this.linhasMappedP}</Grid>
                                    <Grid item xs={5}>Administrador</Grid>
                                    <Grid item xs={7}>{this.usuario.isAdministrador ? 'SIM' : 'NÃO'}</Grid>
                                    <Grid item xs={5}>Ativo?</Grid>
                                    <Grid item xs={7}>{this.usuario.isAtivo ? 'SIM' : 'NÃO'}</Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>]
        );
    };

    
    render() {
        return (
            <Page icon={'profile.svg'} label={'Novo Usuário'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'usuario-novo'}>
                    <Stepper titles={this.titles} steps={this.getSteps()} sendForm={this.salvarUsuario} validation={this.validateUsuario} history={this.props.history} />
                </Grid>
            </Page>
        );
    };
}