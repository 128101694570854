export class AtendimentoModel {
   constructor() {
      this.id = 0;
      this.numero = 0;
      this.dataEmissao = null;
      this.dataNegociacao = null;
      this.dataAceitacao = null;
      this.dataConclusao = null;
      this.dataCancelamento = null;
      this.dataRecusa = null;
      this.dataAtualizacao = null;
      this.dataExecucao = null;
      this.dataArquivamento = null;
      this.atendimentoStatus = {};
      this.atendimentoStatusSI = {};
      this.produtoRegional = { nome: '', produtoNacional: { id: 0, produtoCategoria: { produtoLinha: { CR: '' } } } };
      this.cliente = { razaoSocial: '', cpfcnpj: '', enderecoList: [] };
      this.regional = {};
      this.unidade = { id: '', abreviacao: '', unidadePai : { id: ''} };
      this.dataInicioPrevista = new Date();
      this.dataConclusaoPrevista = new Date();
      this.isEscopoIndefinido = false;
      this.numeroDeProducaoEstimada = 0;
      this.numeroDeRelatorioEstimado = 0;
      this.numeroDeReceitaEstimada = 0;
      this.vlrFinanceiro = 0;
      this.vlrEconomico = 0;
      this.vlrFinanceiroDr = 0;
      this.vlrEconomicoDr = 0;
      this.isValorHora = false;
      this.isEmRede = false;
      this.isCompartilhado = false;
      this.isMetaGoverno = false;
      this.isIntegracao = false;
      this.tipoBPIpea = '';
      this.origemacaoprospectiva = '';
      this.responsavelacaoprospectiva = '';
      this.resumoExecutivo = '';
      this.areaServico = '';
      this.complexidade = '';
      this.grauInovacao = '';
      this.abrangenciaImpacto = '';
      this.titulo = '';
      this.setor = { id: 0, descricao: '' };
      this.produtividade = 0;
      this.movimentacao = 0;
      this.disponibilidade = 0;
      this.qualidade = 0;
      this.retornoPrograma = 0;
      this.colaborador = {};
      this.produtoLinha = {};
      this.previsaoProducaoEmRedeList = [];
      this.previsaoProducaoCompartilhadaList = [];
      this.previsaoReceitaList = [];
      this.atendimentoFaseList = [];
      this.areaPesquisaList = [];
      this.pesquisaSatisfacao = {};
      this.proposta = {};
      this.anexoIndicadorProdutividadeList = [];
      this.setor = { id: 0, descricao: '' };
      this.apl = { descricao: '' };
      this.solicitacaoStatus = {};
      this.observacao = '';
      this.codigoIntegracaoAtendimento = '';
      this.comentario = '';
      this.numeroProjetoSGF = '';
      this.atendimentoPesquisa = new AtendimentoPesquisaModel();
   }

}

export class PrevisaoReceitaModel {
   constructor() {
      this.valor = '';
      this.economico = '';
      this.vlrfinanceiro = '';
      this.vlreconomico = '';
      this.receitarepassada = 0;
      this.detalhes = '';
      this.atendimento = {};
      this.tipoFontePagadora = '';
      this.tipoPrevisaoReceita = {
         descricao: '',
      };
      this.fontePagadora = { nome: '' };
      this.agenciaBolsa = {};
      this.ictParceiro = { descricao: '' };
      this.agenciaNacional = { descricao: '' };
      this.agenciaFomento = { descricao: '' };
      this.unidade = { abreviacao: '' };
      this.sistemaIndustria = { razaoSocial: '' };
      this.randID = null;
   }
}

export class PrevisaoProducaoEmRedeModel {
   constructor() {
      this.numeroDeProducaoEstimada = 0;
      this.numeroDeReceitaEstimada = 0;
      this.tipoFontePagadora = '';
      this.percReceitaEstimada = 0;
      this.percProducaoEstimada = 0;
      this.unidade = {};
      this.atendimento = {};
   }
}

export class AtendimentoPesquisaModel{
   constructor(){
       this.tipoVenda = {descricao: '' }; 
       this.tipoProduto = {descricao: '' }; 
       this.detalhes = '' ;
   }
}

export var areaPesquisaSuggestions = [
   {descricao: 'Óleo e Gás [FUNTEC]', group: 'ENERGIA [FUNTEC] [ENCTI]'},
   {descricao: 'Energias Renováveis', group: 'ENERGIA [FUNTEC] [ENCTI]'},
   {descricao: 'Biomassa [FUNTEC] [ENCTI]', group: 'ENERGIA [FUNTEC] [ENCTI]'},
   {descricao: 'Acumuladores de Energia', group: 'ENERGIA [FUNTEC] [ENCTI]'},
   {descricao: 'Semicondutores [FUNTEC]', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Simulação e Emulação', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Big Data', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Cloud & Edge Computing', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Computação Gráfica', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Visão Computacional', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Supercomputação', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Telecomunicações', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Tecnologias de Realidade Estendida', group: 'MICROELETRÔNICA E TICS'},
   {descricao: 'Economia e Sociedade Digital [ENCTI]', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'Ciências e Tecnologias Sociais [ENCTI]', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'Cidades Inteligentes', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'Defesa, Segurança e Proteção [ENCTI]', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'Tecnologias Aeroespaciais  [ENCTI]', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'Tecnologias Navais (Subsea)', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'Mobilidade e Transportes', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'Veículos de Baixo Impacto Ambiental [FUNTEC]', group: 'SOCIEDADE E MOBILIDADE'},
   {descricao: 'IoT: Internet das Coisas', group: 'INDÚSTRIA'},
   {descricao: 'Sistemas Inteligentes/Cognitivos [FUNTEC]', group: 'INDÚSTRIA'},
   {descricao: 'Tecnologias e Processos Produtivos', group: 'INDÚSTRIA'},
   {descricao: 'Robótica (Remota e Autônoma)', group: 'INDÚSTRIA'},
   {descricao: 'Manufatura Avançada (Indústria 4.0) [FUNTEC]', group: 'INDÚSTRIA'},
   {descricao: 'Manufatura Aditiva', group: 'INDÚSTRIA'},
   {descricao: 'Fotônica', group: 'INDÚSTRIA'},
   {descricao: 'Metrologia Industrial', group: 'INDÚSTRIA'},
   {descricao: 'Materiais e Superfícies', group: 'INDÚSTRIA'},
   {descricao: 'Tecnologias Construtivas e Estruturas', group: 'INDÚSTRIA'},
   {descricao: 'Agricultura de Precisão', group: 'INDÚSTRIA'},
   {descricao: 'Design for X (ex. Logistics, Environment, ...)', group: 'INDÚSTRIA'},
   {descricao: 'Saúde [ENCTI]', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Nutrição', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Tecnologia de Alimentos [ENCTI]', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Tecnologias Médicas', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Meio Ambiente e Recursos Naturais', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Água [ENCTI]', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Clima [ENCTI]', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Minerais e Minerais Estratégicos [FUNTEC]', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Resíduos e Efluentes', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Desenvolvimento Sustentável', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Mineração Inteligente', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Embalagens', group: 'SAÚDE E MEIO AMBIENTE'},
   {descricao: 'Química Orgânica e Inorgânica', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Bioeconomia / Bioinformática [ENCTI]', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Economia Circular', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Biologia Molecular', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Microbiologia', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Bioquímica', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Biotecnologia', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Genômica / Engenharia Genética', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Nanotecnologia', group: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]'},
   {descricao: 'Tecnologias Convergentes e Habilitadoras [ENCTI]', group: 'Outros'},
];

export var suggestions = [
   { label: 'ENERGIA [FUNTEC] [ENCTI]', options: ['Óleo e Gás [FUNTEC]', 'Energias Renováveis', 'Biomassa [FUNTEC] [ENCTI]', 'Acumuladores de Energia'] },
   {
      label: 'MICROELETRÔNICA E TICS', options: ['Semicondutores [FUNTEC]', 'Simulação e Emulação', 'Big Data', 'Cloud & Edge Computing', 'Computação Gráfica',
         'Visão Computacional', 'Supercomputação', 'Telecomunicações', 'Tecnologias de Realidade Estendida']
   }, {
      label: 'SOCIEDADE E MOBILIDADE',
      options: ['Economia e Sociedade Digital [ENCTI]', 'Ciências e Tecnologias Sociais [ENCTI]', 'Cidades Inteligentes', 'Defesa, Segurança e Proteção [ENCTI]',
         'Tecnologias Aeroespaciais  [ENCTI]', 'Tecnologias Navais (Subsea)', 'Mobilidade e Transportes', 'Veículos de Baixo Impacto Ambiental [FUNTEC]']
   },
   {
      label: 'INDÚSTRIA', options: ['IoT: Internet das Coisas', 'Sistemas Inteligentes/Cognitivos [FUNTEC]', 'Tecnologias e Processos Produtivos',
         'Robótica (Remota e Autônoma)', 'Manufatura Avançada (Indústria 4.0) [FUNTEC]', 'Manufatura Aditiva', 'Fotônica', 'Metrologia Industrial',
         'Materiais e Superfícies', 'Tecnologias Construtivas e Estruturas', 'Agricultura de Precisão', 'Design for X (ex. Logistics, Environment, ...)']
   },
   {
      label: 'SAÚDE E MEIO AMBIENTE', options: ['Saúde [ENCTI]', 'Nutrição', 'Tecnologia de Alimentos [ENCTI]', 'Tecnologias Médicas',
         'Meio Ambiente e Recursos Naturais', 'Água [ENCTI]', 'Clima [ENCTI]', 'Minerais e Minerais Estratégicos [FUNTEC]', 'Resíduos e Efluentes',
         'Desenvolvimento Sustentável', 'Mineração Inteligente', 'Embalagens']
   }, {
      label: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]', options: ['Química Orgânica e Inorgânica',
         'Bioeconomia / Bioinformática [ENCTI]', 'Economia Circular', 'Biologia Molecular', 'Microbiologia', 'Bioquímica', 'Biotecnologia', 'Genômica / Engenharia Genética',
         'Nanotecnologia']
   }, { label: 'Outros', options: ['Tecnologias Convergentes e Habilitadoras [ENCTI]'] }
];

export var areaServicoOptions = {
   580: [
      "Biologia Sintética",
      "Transformação Química",
      "Engenharia de Processos Químicos e Bioquímicos",
      "Desenvolvimento de Processos e Fibras para a Indústria Têxtil"
   ],
   676: [
      "Tecnologia de Materiais aplicada à Conformação & União de Materiais",
      "Processos de Conformação de Materiais",
      "Processos de União de Materiais"
   ],
   536: [
      "Desenvolvimento de Revestimentos por Tecnologia Plasma e Caracterização Tribológica",
      "Desenvolvimento de Revestimentos por Química Molhada e Caracterização Física, Química e Ótica"
   ],
   569: [
      "Tecnologias de Usinagem e de Manufatura Aditiva",
      "Tecnologias de Medição por Coordenadas em Grandes Volumes",
      "Soluções Integradas por Manufatura Digital",
      "Tecnologias de Interação"
   ],
   669: [
      "Robôs Especiais e Autônomos",
      "Projeto Mecatrônico",
      "Gerenciamento e Controle de Plantas Industriais",
      "Comunicação Remota e Redes Industriais",
      "Sistemas de Medição e Aquisição de Dados"
   ],
   697: [
      "Logística Empresarial",
      "Movimentação de Materiais",
      "Mobilidade"
   ],
   266: [
      "Pesquisa Prospectiva de Novas Tecnologias em TIC",
      "Design de Novos Conceitos de TIC",
      "Desenvolvimento de Soluções Customizadas Intensivas em Software",
      "Integração de Sistemas de TIC Complexos"
   ],
   557: [
      "Projeto de Sistemas - Sensores",
      "Caracterização Eletrônica e Confiabilidade",
      "Sistemas Inteligentes - Empacotamento",
      "Sensores em Monitoramento de Processos"
   ],
   537: [
      "Desenvolvimento e Otimização de Ligas",
      "Desenvolvimento e Otimização de Processos Metalúrgicos"
   ],
   538: [
      "Diagnóstico Mineral para Processo",
      "Desenvolvimento & Otimização em Processamento Mineral",
      "Escalonamento de Processos Minerais (Planta Piloto)"
   ],
   570: [
      "Elastômeros",
      "Plásticos",
      "Blendas e Compósitos",
      "Tintas",
      "Adesivos",
      "Síntese de Polímeros"
   ],
   240: [
      "Tecnologias Limpas: Reuso, Reciclagem e Monitor. de Resíduos/Efluentes",
      "Verticalização Mineral: Desenvolvimento de Novos Produtos e Sustentabilidade",
      "Infraestrutura: Modernização/Implementação de Novas Tecnologias no Segmento",
      "Soluções em Segurança: Redução de Acidentes e Aumento da Confiabilidade"
   ],
   578: [
      "Equipamentos e Componentes para Geração de Energias Renováveis",
      "Aplicação de Energias Renováveis em Processos Industriais",
      "Sustentabilidade em Energias Renováveis"
   ],
   958: [
      "Projeto e Desenvolvimento de Estruturas de Engenharia",
      "Aplicação de Novos Materiais Estruturais Leves",
      "Desenvolvimento de Sistemas e Estruturas Inteligentes",
      "Simulação e Modelagem Computacional Aplicadas a Estruturas de Engenharia",
      "Desenv. de Métodos de Caracterização Mecânica e Estrutural"
   ],
   529: [
      "Sistemas de Controle e Otimização",
      "Processamento de Sinais",
      "Sistemas de Comunicação",
      "Verificação, Simulação e Gestão da Qualidade"
   ],
   643: [
      "Energia e Sustentabilidade",
      "Engenharia de Bioprocessos e Biotecnologia",
      "Bioeconomia Circular",
      "Biomateriais e Bioprodutos",
      "Tecnologias de Descarbonização"
   ],
   949: [
      "Soluções tecnológicas para corrosão (caracterização, tratamento, inibição)",
      "Sensores eletroquímicos para saúde, alimentos e meio ambiente",
      "Desenv. de novos dispositivos de acumulo de energia",
      "Nanotecnologia em eletroquímica para novos materiais"
   ],
   766: [
      "Manufatura Aditiva a Laser",
      "Tratamento de Superfícies a Laser",
      "Soldagem e Corte a Laser"
   ],
   764: [
      "Desenvolvimento de Processos de Usinagem",
      "Desenvolvimento de Produtos e Máquinas Automáticas",
      "Tecnologia de Materiais aplicados a Processos de Usinagem"
   ]
};

export var tipoPrevisaoReceitaOptions = {
   1: 'MERCADO',
   2: 'FOMENTO',
   3: 'SENAI',
   4: 'OUTROS',
   5: 'ICT',
   6: 'EMPRESA ATENDIDA',
   7: 'AGÊNCIA NACIONAL',
   8: 'AGÊNCIA DE FOMENTO',
   9: 'AGÊNCIA DE BOLSAS',
   10: 'UO DO ATENDIMENTO',
   11: 'DR DO ATENDIMENTO',
   12: 'SENAI PARCEIRO',
   13: 'SISTEMA INDÚSTRIA'
}

export var setorIndustriaOptions = ["Gestão",
   "Alimentos e Bebidas",
   "Gráfica e Editorial",
   "Celulose e Papel",
   "Couro e Calçados",
   "Gemologia e Joalheria",
   "Madeira e Mobiliário",
   "Química",
   "Refrigeração e Climatização",
   "Têxtil",
   "Vestuário",
   "Segurança no Trabalho",
   "Minerais não Metálicos",
   "Telecomunicações",
   "Meio Ambiente",
   "Eletroeletrônica",
   "Mineração",
   "Automotiva",
   "Metrologia",
   "Educação",
   "Polímeros",
   "Energia",
   "Petróleo e Gás",
   "Logística",
   "Construção Civil - Edificações",
   "Construção Civil - Construção Pesada",
   "Construção Civil - Instalações",
   "Construção Naval",
   "Energia GTD",
   "Energia Sucroalcooleira",
   "Metalmecânica - Fabricação Mecânica",
   "Metalmecânica - Mecânica",
   "Metalmecânica - Metalurgia",
   "Metalmecânica Soldagem",
   "Tecnologia da Informação - Hardware",
   "Tecnologia da Informação - Software",
   "Transporte Aeronáutico",
   "Transporte Ferroviário",
   "Energia Renovável",
   "Automação e Mecatrônica",
   "Outros"];