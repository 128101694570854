import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBar from '../../../components/graphBar/graphBar';

export default class GraficoProdutividadeEstadoSegmento extends Component {

    tooltipEstado = {
        formatter: function() {
            let yValue = this.point.y;
            return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.point.category}: ${yValue.toLocaleString('pt-BR')}%</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    tooltipSegmento = {
        formatter: function() {
            let yValue = this.point.y;
            return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.series.chart.options.fullCategories[this.point.category - 1]}: ${yValue.toLocaleString('pt-BR')}%</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    legend = { enabled: false };

    plotOptions = {
        column: {
            pointPadding: 0.2,
            borderWidth: 0,
            zones: [{ value: 20, color: '#D44E54' }, { value: 100, color: '#00B1E8' }, { color: '#E9B03D' }],
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };

    plotLines = [
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 20 }, 
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 100 }
    ];

    labelsY =  {
        formatter: function() {
            if (this.value <= 20 || (this.value === 100)) return this.value + "%";
        },
        style: {
            color: '#707070'
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            switch: [
                { 
                    label: 'Estado', 
                    title: 'Aumento de produtividade industrial por estado', 
                    tooltip: this.tooltipEstado, 
                    categories: [], 
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoEstado,
                    tickInterval: 20,
                    legend: this.legend,
                    height: 250,
                    plotOptions: this.plotOptions,
                    plotLines: this.plotLines,
                    labelsY: this.labelsY,
                    gridLineWidthY: 0
                },
                {
                    label: 'Segmento industrial', 
                    title: 'Aumento de produtividade industrial por segmento industrial', 
                    tooltip: this.tooltipSegmento, 
                    categories: [], 
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoSegmento,
                    tickInterval: 20,
                    legend: this.legend,
                    height: 250,
                    plotOptions: this.plotOptions,
                    plotLines: this.plotLines,
                    labelsY: this.labelsY,
                    gridLineWidthY: 0
                }
            ]
        };
    };

    fillGraficoEstado = async (dataInicio, dataTermino) => {
        let series = [];
        let categories = [];
        let fullCategories = [];
        let resumeInfos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/geralAtendimentosProdutividade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${false}&hasResumo=${true}`).then(async res => {
            if (res.data != null && res.data.estados != null) {
                let resumo = res.data.resumo;
                if (resumo && resumo.length > 0 && resumo.length === 3) {
                    resumeInfos = {
                        title: 'Estados com maior média de produtividade',
                        data: [
                            { title: resumo[0][0], value: resumo[0][1], suffix: '%' }, 
                            { title: resumo[1][0], value: resumo[1][1], suffix: '%' }, 
                            { title: resumo[2][0], value: resumo[2][1], suffix: '%' }
                        ]
                    };
                } 
                series = await [{data: res.data.estados.map(i => i[1]) }];
                categories = await [ res.data.estados.map(i=> i[0]) ];
                fullCategories = await [ res.data.estados.map(i=> i[0]) ];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories[0], fullCategories[0], resumeInfos];
    };

    fillGraficoSegmento = async (dataInicio, dataTermino) => {
        let series = { data: [] };
        let categories = [];
        let fullCategories = [];
        let resumeInfos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/setorAtendimentosProdutividade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${false}`).then(async res => {
            if (res.data != null) {
                let resumo = res.data.resumo;
                if (resumo && resumo.length > 0 && resumo.length === 3) {
                    resumeInfos = {
                        title: 'Segmentos com maior média de produtividade',
                        data: [
                            { title: resumo[0][0], value: resumo[0][1], suffix: '%' }, 
                            { title: resumo[1][0], value: resumo[1][1], suffix: '%' }, 
                            { title: resumo[2][0], value: resumo[2][1], suffix: '%' }
                        ]
                    };
                } 
                series = await [{ data: res.data.grafico.map(i => i[1]) }];
                categories = await [ res.data.grafico.map((i, index) => index + 1) ];
                fullCategories = await [ res.data.grafico.map(i => i[0]) ];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories[0], fullCategories[0], resumeInfos];
    };
   
    render() {
        return (
            <Grid container justify={'center'}>      
                <GraphBar switch={this.state.switch} disabledSwitch={false} hasFilter={true} init={'left'} />
            </Grid>
        );
    };
}