import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page'
import API, { BASE_URL } from '../../comm/API'
import { SyncRequest } from '../../comm/APIsync';
import Chip from "@material-ui/core/Chip";
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import './meusDados.scss';

export default class MeusDados extends Component {
    usuario = {
        nome: '',
        login: '',
        senha: '',
        isAdministrador: false,
        isAtivo: true,
        isIntegracao: false,
        urlFoto: "dist/img/usuario-login.png",
        colaborador: null,
        unidade: null,
        perfilUsuario: null,
        tomcatusuarioRoleList: [],
        usuarioProdutoLinhaList: []
    }
    errors = {
        nome: { error: '' },
        login: { error: '' },
        senhaAtual: { error: '' },
        novaSenha1: { error: '' },
        novaSenha2: { error: '' },
    }
    // isEditing = true;
    skillList = [];
    senhaAtual = '';
    novaSenha1 = '';
    novaSenha2 = '';

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    }

    constructor(props) {
        super(props);
        this.state = {
            isEditing : false,
            isInvalid : false
        }
        this.usuario = this.props.usuarioConectado;
        this.hasColaborador = this.props.usuarioConectado.colaborador != null;
        API.get('rest/auxiliar/Skill').then(res => {
            this.skillList = res.data;
        }).catch(error => {
            alert('Erro ao realizar GET das skills.\nPor favor, entre em contato com o suporte.');
        });
    }
    changeInput = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'login' || e.target.id === 'nome'){
                this.usuario[e.target.id] = e.target.value;
                if (e.target.id === 'login'){
                    this.usuario.colaborador.email = e.target.value;
                }
                if (e.target.id === 'nome'){
                    this.usuario.colaborador.nome = e.target.value;
                }
            } else {
                this.usuario.colaborador[e.target.id] = e.target.value;
            }
            if (e.target.required){
                if (e.target.value == null || e.target.value === '') {
                    this.errors[e.target.id].error = 'Campo obrigatório';
                } else {
                    this.errors[e.target.id].error = '';
                }
            }
        }
        this.setState(this.state);
        this.validateForm();
    }
    changeSenha = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'senhaAtual') {
                this.senhaAtual = e.target.value;
                this.setState(this.state);
                this.validateOldPassword();
            } else if (e.target.id === 'novaSenha1') {
                this.novaSenha1 = e.target.value;
                this.errors.novaSenha1.error = this.validateNewPassword(e.target.value);

                if (this.novaSenha1 !== this.novaSenha2) {
                    this.errors.novaSenha2.error = 'Senhas não coincidem.';
                } else {
                    this.errors.novaSenha2.error = '';
                }
            } else {
                this.novaSenha2 = e.target.value;
                if (this.novaSenha1 !== this.novaSenha2) {
                    this.errors.novaSenha2.error = 'Senhas não coincidem.';
                } else {
                    this.errors.novaSenha2.error = '';                    
                }
            }
            this.setState(this.state);
            this.validateForm();
        }
    }
    validateOldPassword = () => {
        API.post('rest/security/alterarSenha', {
            login: this.usuario.login,
            senha: this.senhaAtual
        }).then(res => {
            if(res.data.podeAlterarSenha === "true"){
                this.errors.senhaAtual.error = '';
            } else {
                this.errors.senhaAtual.error = 'Senha incorreta.';
            }
            this.setState(this.state);
            this.validateForm();
        }).catch(error => {
            console.log(error)
        });  
    }
    validateNewPassword = (value) => {
        var erros = '';
        var hasNumbers = new RegExp("[0-9]");
        var hasLetters = new RegExp("[A-Za-z]");
        if (value === ''){
            return erros;
        } else {
            if ((value.length < 8 || value.length > 20) || !hasNumbers.test(value) || !hasLetters.test(value)){
                erros += 'Senha inválida.'
            }
        return erros;
        }
    }
    changeSkill = (e) => {
        this.usuario.colaborador.colaboradorSkillList = e;
        this.setState(this.state);
    }
    componentDidMount() {
        this.closeLoading();
    }
    allowEdit = () => {
        this.setState({isEditing: true});
    }
    blockEdit = () => {
        this.showLoading()
        this.usuario = SyncRequest('get', BASE_URL + 'rest/security/usuarioConectado');
        this.setState({isEditing: false});
        this.closeLoading();
    }
    saveUsuario = () => {
        this.showLoading();
        this.usuario.nome = this.usuario.nome.toUpperCase();
        this.usuario.login = this.usuario.login.toLowerCase();
        if (this.novaSenha1 !== ''){
            this.usuario.senha = this.novaSenha1;
        }

        API.post('rest/sistema/Usuario/' + this.props.usuarioConectado.id, this.usuario).then(res => {
            if (this.props.usuarioConectado.colaborador != null ){
                API.post('rest/gestao/Colaborador/' + this.props.usuarioConectado.colaborador.id, this.usuario.colaborador).then(res => {
                    this.setState({isEditing: false});
                    this.closeLoading();
                    this.showToast('Perfil atualizado com sucesso!', 'success');
                }).catch(error => {
                    this.closeLoading();
                    this.showToast('Erro ao atualizar colaborador. Entre em contato com o suporte.', 'error');
                });
            } else {
                this.setState({isEditing: false});
                this.closeLoading();
                this.showToast('Perfil atualizado com sucesso!', 'success');
            }
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao atualizar perfil. Entre em contato com o suporte.', 'error');
        });
    }
    uploadFoto = (e) => {
        if (e.target.files.length > 0){
            this.showLoading();
            let file = e.target.files[0];
            this.setState(this.state);
    
            let formData = new FormData();
            formData.append('name', 'file');
            formData.append('file', file);
            formData.append('fileName', file.name);
    
            API.post('rest/upload/foto/usuario/' + this.props.usuarioConectado.id, formData, {
                headers: { 'Content-Type': "multipart/form-data" }
            }).then(res => {
                this.usuario = res.data;
                this.setState({isEditing: false});
                this.closeLoading();
                this.showToast('Foto anexada com sucesso!', 'success')
            }).catch(error => {
                this.closeLoading();
                this.showToast('Erro ao arquivar arquivo. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }

    validateForm = () => {
        var isInvalid = !(this.errors.nome.error === '' && this.errors.login.error === '' && 
        this.errors.senhaAtual.error === '' && this.errors.novaSenha1.error === '' &&this.errors.novaSenha2.error === '');
        this.setState({ isInvalid: isInvalid });
    }


    render() {
        return (
            <Page icon={'profileConfig.svg'} label={'Configurações do perfil'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container id={'meus-dados'}>
                    <Grid item xs={12} md={11} lg={10} xl={8} className={'div-basic'}>
                        <Grid item xs={12} className='div-profile'>
                            <label className={'darkBlue basic-button control-label'} id="control-label" for="uploadfile">
                                    <img src={process.env.PUBLIC_URL + '/assets/icons/pen.svg'} alt={'pen'}/>
                            </label>

                            <input className={'file-input'} type="file" id="uploadfile" name="file" value={undefined} onChange={this.uploadFoto}/>

                            {this.usuario.urlFoto != null ?
                                <img alt='' src={BASE_URL + this.usuario.urlFoto + '?' + new Date().getTime()} className={'usuario-photo'} />
                            :
                                <img alt='' src={process.env.PUBLIC_URL + 'user-login.png'} className={'usuario-photo'} />
                            }
                        </Grid>
                    </Grid>                    
                    {this.state.isEditing ? 
                        <Grid item xs={12} md={11} lg={10} xl={8} className={'div-name'}>
                            <FormField type={'text'} label={''} id={'nome'} changeValue={this.changeInput} complete="off"
                            inputValue={this.usuario.nome} error={this.errors.nome.error} required={'required'}/>
                        </Grid>
                    :
                    <Grid item xs={12} md={11} lg={10} xl={8} className={'div-name'}>
                        <p className={'name'}>{this.usuario.nome}</p>
                        <GenericButton color={'darkBlue'} subClass={'basic-button'} click={this.allowEdit} label={'Editar'} /> 
                    </Grid>
                    }
                    
                    <Grid item xs={12} md={11} lg={10} xl={8}>
                        <p className={'title'}>Dados Pesoais</p>
                        <Grid container spacing={3} style={{ alignItems: 'flex-start' }}>
                            <Grid item xs={12} sm={6} md={4}>
                                {this.state.isEditing ?
                                    <FormField type={'text'} label={'E-mail'} id={'login'} changeValue={this.changeInput}
                                        inputValue={this.usuario.login} error={this.errors.login.error} required={'required'}/>
                                    :
                                    <div>
                                        <p className={'info-title'}>Email</p>
                                        <p className={'info'}>{this.usuario.login}</p>
                                    </div>
                                }
                            </Grid>
                            {this.hasColaborador ? 
                                <Grid item xs={12} sm={6} md={4}>
                                {this.state.isEditing ?
                                    <FormField type={'number'} label={'Telefone'} id={'telefone'} changeValue={this.changeInput}
                                        inputValue={this.usuario.colaborador.telefone}/>
                                    :
                                    <div>
                                        <p className={'info-title'}>Telefone</p>
                                        <p className={'info'}>{this.usuario.colaborador.telefone}</p>
                                    </div>
                                }
                                </Grid> : null}
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <p className={'info-title'}>Perfil</p>
                                    <p className={'info'}>{this.usuario.perfilUsuario.nome}</p>
                                </div>
                            </Grid>
                            {this.hasColaborador ? 
                                <Grid item xs={12} sm={6} md={4}>
                                    <div>
                                        <p className={'info-title'}>CPF</p>
                                        <p className={'info'}>{this.usuario.colaborador.cpf}</p>
                                    </div>
                                </Grid> : null }
                            
                            {this.hasColaborador ? <Grid item xs={12} sm={6} md={4}>
                                {this.state.isEditing ?
                                    <FormField type={'text'} label={'Currículo'} id={'linkCurriculumVitae'} inputValue={this.usuario.colaborador.linkCurriculumVitae}
                                        changeValue={this.changeInput} placeholder={'Insira a URL do currículo Lattes'} />
                                    :
                                    <div>
                                        <p className={'info-title'}>Currículo</p>
                                        <p className={'info'}>{this.usuario.colaborador.linkCurriculumVitae}</p>
                                    </div>
                                }
                            </Grid> : null }
                            <Grid item xs={12} sm={6} md={4}>
                                <div>
                                    <p className={'info-title'}>Local de Trabalho</p>
                                    {this.usuario.colaborador != null ? 
                                        this.usuario.colaborador.colaboradorUnidadeList.map(
                                            (element, index) => (
                                                <p className={'info'}>{element.unidade.descricao}</p>
                                            ))                                        
                                        :
                                        <p className={'info'}>{this.usuario.unidade.descricao}</p>
                                    }
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    {this.hasColaborador ?
                        <Grid item xs={12} md={11} lg={10} xl={8}>
                            <p className={'title'}>Competências</p>
                            <Grid container spacing={3} style={{ alignItems: 'flex-start' }}>
                                {this.usuario.colaborador.colaboradorProdutoRegionalList.map(
                                    (colaboradorProdutoRegional, index) => (
                                        <Grid item xs={12} sm={6} key={`produto-${index}`}>
                                            <p className={'info-title'}>{colaboradorProdutoRegional.produtoRegional.nome}</p>
                                            <p className={'info'}>{colaboradorProdutoRegional.produtoRegional.produtoNacional.descricao}</p>
                                        </Grid>
                                    )
                                )}
                            </Grid>
                        </Grid>
                        : null}

                    {this.hasColaborador ?
                        <Grid item xs={12} md={10} lg={10} xl={8}>
                            <p className={'title'}>Skills</p>
                            {this.state.isEditing ?
                                <AutoCompleteMany
                                    key={'skills'}
                                    suggestions={this.skillList}
                                    itemlabel={'descricao'}
                                    getInput={this.changeSkill}
                                    itemKey={'id'}
                                    label={''}
                                    chipLabel={''}
                                    placeholder={'Selecione uma Skill'}
                                    id={'descricao'}
                                    initialOjectSelectedList={this.usuario.colaborador.colaboradorSkillList}
                                    initialOjectSelectedListClass={'skill'} />
                                :
                                <Grid>
                                    {this.usuario.colaborador.colaboradorSkillList.map(
                                        (colaboradorSkill, index) => (
                                            <Chip key={index} label={<label><p>{colaboradorSkill.skill.descricao}</p></label>} />
                                        )
                                    )
                                    }
                                </Grid>
                            }
                        </Grid>
                        : null}
                    <Grid item xs={12} md={10} lg={10} xl={8}>
                        <p className={'title'}>Senha</p>
                        {this.state.isEditing ?
                            <Grid container spacing={3} style={{alignItems: 'flex-start'}}>
                                <Grid item xs={12} md={12} lg={4}>
                                    <FormField type={'password'} label={'Senha atual'} name={'password'} id={'senhaAtual'} changeValue={this.changeSenha}
                                        inputValue={this.senhaAtual} error={this.errors.senhaAtual.error} complete="new-password"/>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <FormField type={'password'} label={'Nova senha'} id={'novaSenha1'} changeValue={this.changeSenha}
                                        inputValue={this.novaSenha1} error={this.errors.novaSenha1.error} />
                                        <label className={'font-grey'}>A senha deve ter de 8 a 20 dígitos, e conter letras e números.</label>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <FormField type={'password'} label={'Repita a nova senha'} id={'novaSenha2'} changeValue={this.changeSenha}
                                        inputValue={this.novaSenha2} error={this.errors.novaSenha2.error} />
                                </Grid>
                            </Grid>
                            : <p className={'senha'}>**********</p>}
                    </Grid>
                    {this.state.isEditing ?
                        <Grid item xs={12} md={10} lg={10} xl={8} className={'rodape'}>
                            <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} click={this.blockEdit} label={'Cancelar'} />
                            <GenericButton color={'darkBlue'} subClass={'basic-button'} click={this.saveUsuario} disabled={this.state.isInvalid} label={'Salvar'} />
                        </Grid>
                    : null }
                </Grid>
            </Page>
        )
    }
}


