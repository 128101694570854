import React, { Component } from 'react'
import './publicacaoNovo.scss'
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper'
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API'
import InputSelect from '../../components/inputselect/inputselect';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import InputMany from '../../components/inputMany/inputMany';

const titles = ['Dados da Publicação', 'Participantes', 'Revisão']

export default class PublicacaoNovo extends Component {

    publicacao = {
        titulo: "",
        ano: new Date().getFullYear(),
        tipoPublicacao: "",
        nomeMeioPublicacao: "",
        cidade: "",
        pais: "",
        isComercial: false,
        detalhes: "",
        unidade: null,
        publicacaoColaboradorList: [],
        autores: []
    };
    
    errors = {
        titulo: '',
        isComercial: '',
        nomeMeioPublicacao: '',
        tipoPublicacao: '',
        cidade: '',
        pais: '',
        ano: '',
        publicacaoColaboradorList: ''
    };
    perfilPublicacaoList = [
        { id: 0, type: 'isComercial', label: 'Científica', value: true },
        { id: 1, type: 'isComercial', label: 'Comercial', value: false }
    ];
    meioPublicacaoList = [
        { id: 0, type: 'nomeMeioPublicacao', label: 'Impressa' },
        { id: 1, type: 'nomeMeioPublicacao', label: 'Digital' },
        { id: 2, type: 'nomeMeioPublicacao', label: 'Impressa e Digital' }
    ];
    tipoPublicacaoList = [
        { id: 0, type: 'tipoPublicacao', label: 'Artigo em Periódico' },
        { id: 1, type: 'tipoPublicacao', label: 'Artigo em anais' },
        { id: 2, type: 'tipoPublicacao', label: 'Capítulo de livro' },
        { id: 3, type: 'tipoPublicacao', label: 'Livro' }
    ];

    anoList = [];
    currentYear = new Date().getFullYear();

    loadSelectPerfil = false;
    loadSelectMeio = false;
    loadSelectTipo = false;

    constructor(props) {
        super(props)
        this.state = {
            user: undefined,
            participantesList: []
        };

        this.getPublicacao();
        this.getAnoList();
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getPublicacao = () => {
        if (this.props.idPublicacao !== undefined) {
            API.get('rest/gestao/Publicacao/' + this.props.idPublicacao).then(res => {
                this.publicacao = res.data;
                this.getColaboradorList();
                this.publicacao.autores = res.data.autores != null ? res.data.autores.length > 0 ? res.data.autores.split(',') : [] : [];
                this.setState(this.state);
            });
        } else {
            if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
                this.publicacao.unidade = this.props.usuarioConectado.colaborador.unidade;
            } else {
                this.publicacao.unidade = this.props.usuarioConectado.unidade;
            }
            this.getColaboradorList();
        }
    };

    getAnoList = () => {
        for (var i = this.currentYear; i >= 2000; i--) {
            this.anoList.push({ id: i, type: 'ano', label: i });
        }
        this.setState(this.state);
    };

    getColaboradorList = () => {
        var filter = { 'unidade': this.publicacao.unidade.id };
        API.get('rest/gestao/ColaboradorUnidade/find', { params: filter }).then(res => {
            this.setState({ colaboradorList: res.data });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao carregar lista de colaboradores. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    validatePublicacao = (step) => {
        switch (step) {
            case 0:
                return this.publicacao.titulo && this.publicacao.nomeMeioPublicacao && 
                this.publicacao.tipoPublicacao && this.publicacao.cidade && this.publicacao.pais && this.publicacao.ano;
            case 1:
                return this.publicacao.publicacaoColaboradorList.length > 0;
            default:
                return true
        }
    }
    savePublicacao = () => {
        this.showLoading();
        this.publicacao.autores = this.publicacao.autores.toString();
        if (this.publicacao.id === undefined) {            
            API.post('rest/gestao/Publicacao', this.publicacao).then(res => {
                this.closeLoading();
                this.showToast('Publicação cadastrada com sucesso!', 'success', '/cad/pessoa/publicacao');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao cadastrar publicação. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Publicacao/' + this.publicacao.id, this.publicacao).then(res => {
                this.closeLoading();
                this.showToast('Publicação editada com sucesso!', 'success', '/cad/pessoa/publicacao');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao editar publicação. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }
    changeInput = (e) => {
        if (e != null && e.target != null) {
            this.publicacao[e.target.id] = e.target.value.toUpperCase();
            if (e.target.required) {
                if (e.target.value == null || e.target.value === '') {
                    this.errors[e.target.id] = 'Campo obrigatório';
                } else {
                    this.errors[e.target.id] = '';
                }
            }
        } else if (e != null && e.type != null) {
            if (e.type === 'isComercial') {
                this.publicacao.isComercial = e.value;
            } else {
                this.publicacao[e.type] = e.label;
            }
        } else if (e != null && e !== '') {
            this.publicacao.publicacaoColaboradorList = e;
            if (e.length === 0) {
                this.errors.publicacaoColaboradorList = 'Insira ao menos um colaborador';
            } else {
                this.errors.publicacaoColaboradorList = '';
            }
        }
        this.setState(this.state);
    }
    changeAutores = (e) => {
        this.publicacao.autores = e;
        this.setState(this.state);
    }

    getSteps = () => {
        return [
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={7} xl={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Título'} id={'titulo'} inputValue={this.publicacao.titulo} changeValue={this.changeInput}
                                required={'required'} error={this.errors.titulo}/>
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <InputSelect
                                label={'Perfil de Publicação'}
                                suggestions={this.perfilPublicacaoList}
                                itemKey={'value'}
                                itemLabel={'label'}
                                id={'isComercial'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ value: this.publicacao.isComercial}}
                                error={this.errors.isComercial}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <InputSelect
                                label={'Meio de Publicação'}
                                suggestions={this.meioPublicacaoList}
                                itemKey={'label'}
                                itemLabel={'label'}
                                id={'nomeMeioPublicacao'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ label: this.publicacao.nomeMeioPublicacao != null ? this.publicacao.nomeMeioPublicacao : undefined }}
                                error={this.errors.nomeMeioPublicacao}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputSelect
                                label={'Tipo da Publicacao'}
                                suggestions={this.tipoPublicacaoList}
                                itemKey={'label'}
                                itemLabel={'label'}
                                id={'tipoPublicacao'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ label: this.publicacao.tipoPublicacao != null ? this.publicacao.tipoPublicacao : undefined }}
                                error={this.errors.tipoPublicacao}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <FormField type={'text'} label={'Cidade'} id={'cidade'} inputValue={this.publicacao.cidade} changeValue={this.changeInput}
                                required={'required'} error={this.errors.cidade}/>
                        </Grid>
                        <Grid item xs={12} sm={4} className={'paddingForm'}>
                            <FormField type={'text'} label={'País'} id={'pais'} inputValue={this.publicacao.pais} changeValue={this.changeInput}
                                required={'required'} error={this.errors.pais}/>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <InputSelect
                                label={'Ano'}
                                suggestions={this.anoList}
                                itemKey={'label'}
                                itemLabel={'label'}
                                id={'ano'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ label: this.publicacao.ano }}
                                error={this.errors.ano}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <AutoCompleteMany
                        suggestions={this.state.colaboradorList}
                        itemlabel={'nome'}
                        getInput={this.changeInput}
                        itemKey={'id'}
                        label={'Colaboradores(s)'}
                        chipLabel={'Nomes'}
                        id={'nome'}
                        initialOjectSelectedList={this.publicacao.publicacaoColaboradorList}
                        initialOjectSelectedListClass={'colaborador'}
                        error={this.errors.publicacaoColaboradorList}
                        placeholder={'Procure um colaborador pelo seu nome'}
                    />
                    <InputMany
                        getInput={this.changeAutores}
                        label={'Autores(s)'}
                        chipLabel={'Nomes'}
                        id={'autores'}
                        initialSelectedItensList={this.publicacao.autores}
                        placeholder={'Digite o nome do autor'}
                    />
                </Grid>
            </Grid>,
            <Grid container className={"step review-step"}>
                <Grid item xs={12} sm={10} md={6} xl={4} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>1</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Dados do Publicacao</label>
                        <Grid container>
                            <Grid item xs={6}>Título</Grid>
                            <Grid item xs={6}>{this.publicacao.titulo}</Grid>
                            <Grid item xs={6}>Perfil de Publicação</Grid>
                            <Grid item xs={6}>{this.publicacao.isComercial ? 'Comercial' : 'Científico'}</Grid>
                            <Grid item xs={6}>Meio de Publicação</Grid>
                            <Grid item xs={6}>{this.publicacao.nomeMeioPublicacao}</Grid>
                            <Grid item xs={6}>Tipo de Publicação</Grid>
                            <Grid item xs={6}>{this.publicacao.tipoPublicacao}</Grid>
                            <Grid item xs={6}>Cidade</Grid>
                            <Grid item xs={6}>{this.publicacao.cidade}</Grid>
                            <Grid item xs={6}>País</Grid>
                            <Grid item xs={6}>{this.publicacao.pais}</Grid>
                            <Grid item xs={6}>Ano</Grid>
                            <Grid item xs={6}>{this.publicacao.ano}</Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} md={6} xl={4} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>2</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Participantes</label>
                        <Grid container>
                            <Grid item xs={6}>Colaboradores</Grid>
                            <Grid item xs={6}>
                                {this.publicacao.publicacaoColaboradorList.map(
                                    (publicacaoColaborador, index) => (
                                        <label key={index}>{publicacaoColaborador.colaborador.nome}<br></br></label>
                                    )
                                )}
                            </Grid>
                            <Grid item xs={6}>Autores</Grid>
                            <Grid item xs={6}>
                                {this.publicacao.autores.map(
                                    (autor, index) => (
                                        <label key={index}>{autor}<br></br></label>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid >]
    }

    
    render() {
        return (
            <Page icon={'doc.svg'} label={'Nova Publicação'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'novo-publicacao'}>
                    <Stepper
                        titles={titles}
                        steps={this.getSteps()}
                        validation={this.validatePublicacao}
                        sendForm={this.savePublicacao} 
                        history={this.props.history} />
                </Grid>
            </Page>
        );
    }
}