import React, { Component } from 'react';
import { Grid, AppBar, Tabs, Tab, Toolbar,Tooltip } from '@material-ui/core';
import API , { BASE_URL } from '../../../comm/API';
import InputSelect from '../../../components/inputselect/inputselect';
import Page from '../../../components/page/page';
import DashboardBrasilMaisGeral from './dashboardBrasilMaisGeral';
import DashboardBrasilMaisSetor from './dashboardBrasilMaisSetor';
import DashboardBrasilMaisTurmas from './dashboardBrasilMaisTurmas';
import DashboardBrasilMaisMentores from './dashboardBrasilMaisMentores';
import DashboardBrasilMaisIndicadores from './dashboardBrasilMaisIndicadores';
import GenericButton from '../../../components/genericButton/genericButton';
import { withStyles} from "@material-ui/core/styles";
import './dashboardBrasilMais.scss';
import DashboardBrasilMaisTemp from './DashboardBrasilMaisTemp';
import DashboardBrasilMaisAvaliacoes from './dashboardBrasilMaisAvaliacoes';

export default class DashboardBrasilMais extends Component {

	perfil = 'UO';

	constructor(props) {
        super(props);
        let d = new Date
        this.perfil = props.usuarioConectado.unidade.tipoUnidade.sigla;
        this.state = { tab: 0, relatorioOption: 1, fase: this.perfil === 'DN' ? 'LD' : 'L', lastUpdate: d };
        this.unidadeDR = this.perfil === 'DR' ? props.usuarioConectado.unidade.descricao : this.perfil === 'UO' ? props.usuarioConectado.unidade.unidadePai.descricao : '';
        this.unidadeUO = this.perfil === 'UO' ? props.usuarioConectado.unidade.descricao : '';
    }

    RelatorioOptions = [
        { id: 0, label: 'Escolher fase', value: 0},
        { id: 1, label: 'Fase 1: Mentoria Lean', value: 1}, 
        { id: 2, label: 'Fase 2: Mentoria Digital', value: 2}, 
        { id: 3, label: 'Brasil Mais', value: 3}    
    ];

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    }

    componentDidMount() {
    	this.closeLoading();
    }

    changeTab = (value, newValue) => {
    	this.setState({ tab: newValue });
    }

    changeRelatorioOption = (e) => {
        this.setState({ relatorioOption: e.id });
    };

    changeFase = (fase) => {
        let nextFase = ""
        if(fase == "D"){
            if(this.state.fase == "LD"){
                nextFase = "L";
            }
            else if(this.state.fase == "D"){
                nextFase = "L";
            }
            else{
                nextFase = "LD";
            }
        }
        else{
            if(this.state.fase == "LD"){
                nextFase = "D";
            }
            else if(this.state.fase == "D"){
                nextFase = "LD";
            }
            else{
                nextFase = "D";
            }
        }
        this.setState({ fase: nextFase });
    };

    downloadRelatorio = () => {
        this.showLoading();


        try {
            if(this.state.relatorioOption == 1){
                let path = `rest/gestao/RelatorioTurmaLean/getRelatorioBrasilMais`;
                window.open(BASE_URL + path);
            }
            if(this.state.relatorioOption == 2){
                let path = `rest/gestao/RelatorioTurmaDigital/getRelatorioBrasilMais`;
                window.open(BASE_URL + path);
            }
            if(this.state.relatorioOption == 3){
                let path = `rest/gestao/RelatorioTurmaGeral/getRelatorioBrasilMais`;
                window.open(BASE_URL + path);
            }
            
            this.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }

    };

    formatDate = () => {
        let date = this.state.lastUpdate;
        let m = date.getMonth() + 1 >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
        let min = date.getMinutes() >=10 ? date.getMinutes() : '0' + date.getMinutes();
        return(date.getDate() + '/' + m + '/' + date.getFullYear() + " - " + date.getHours() + ':' + min)
    }

    render() {

        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);

    	return (
            <Page icon={'brasil-mais.svg'} label={'Dashboard Brasil Mais'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container id={'dashboard-brasil-mais'}>
                    <Grid item xs={12} className={'tabs'}>
                        <AppBar position='static' className={'sticky-bar'}>
                            {this.perfil === 'DN' && <div>
                                <Grid container>
                                    <Grid item xs={10}>
                                        <Grid container 
                                        spacing={2}
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center">
                                            <Grid item xs={3}>
                                                <div style={{marginBottom: "10px", marginTop: "10px"}}>
                                                    <label>FILTRAR POR FASE</label>
                                                </div>
                                                <div className='buttons-div'>
                                                    {this.state.fase == "D" ?
                                                    <div style={{display: "flex"}}>
                                                        <div className={'icon-btn-dash'}>
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/assets/icons/check-no-blue.svg'}
                                                                width={'10px'}
                                                                height={'10px'} />
                                                        </div>
                                                        <GenericButton color={'darkBlue-outline'} label={'Lean'} subClass={'basic-button'} click={() =>{this.changeFase("L")}} />
                                                    </div>
                                                    :
                                                    <div style={{display: "flex"}}>
                                                        <div className={'icon-btn-dash'}>
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/assets/icons/check-ok-blue.svg'}
                                                                width={'12px'}
                                                                height={'12px'} />
                                                        </div>
                                                        <GenericButton color={'darkBlue'} label={'Lean'} subClass={'basic-button'} click={() =>{this.changeFase("L")}} />
                                                    </div>}
                                                    {this.state.fase == "L" ?
                                                    <div style={{display: "flex"}}>
                                                        <div className={'icon-btn-dash'}>
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/assets/icons/check-no-blue.svg'}
                                                                width={'10px'}
                                                                height={'10px'} />
                                                        </div>
                                                        <GenericButton color={'darkBlue-outline'} label={'Digital'} subClass={'basic-button'} click={() =>{this.changeFase("D")}} />
                                                    </div>
                                                    :
                                                    <div style={{display: "flex"}}>
                                                        <div className={'icon-btn-dash'}>
                                                            <img
                                                                src={process.env.PUBLIC_URL + '/assets/icons/check-ok-blue.svg'}
                                                                width={'12px'}
                                                                height={'12px'} />
                                                        </div>
                                                        <GenericButton color={'darkBlue'} label={'Digital'} subClass={'basic-button'} click={() =>{this.changeFase("D")}} />
                                                    </div>}
                                                    <ModifiedTooltip 
                                                    title={ 
                                                    <div>Escolha a fase desejada para atualizar os dados do painel. Como default o painel apresenta de forma unificada os dados das duas fases do programa.</div>}
                                                    placement="right" arrow>
                                                        <img style={{height: 22, width: 22}} src={process.env.PUBLIC_URL + '/assets/icons/info-dash-gray.svg'}                                                             
                                                        alt={'icon'} />
                                                    </ModifiedTooltip>
                                                </div>                                               
                                            </Grid>
                                            <Grid item xs={7}>
                                                <div style={{ marginBottom: "10px", marginTop: "10px"}}>
                                                    <label>RELATÓRIO</label>
                                                </div>
                                                <div style={{marginBottom: "30px"}}>
                                                    <Grid container
                                                    spacing={2}
                                                    direction="row"
                                                    justifyContent="flex-start"
                                                    alignItems="center">
                                                        <Grid item xs={3} > 
                                                            <div className='buttons-div' style={{marginBottom: "0px"}}>
                                                                <GenericButton color={'darkBlue'} label={'Baixar Relatório'} subClass={'basic-button with-icon'}
                                                                icon={process.env.PUBLIC_URL + 'download-white.svg'} disabled={this.state.relatorioOption == 0}
                                                                click={() => { this.downloadRelatorio(); }}/>
                                                                <ModifiedTooltip 
                                                                title={ 
                                                                <div>Baixe um relatório (Excel) contendo os dados do painel escolhendo a fase e clicando em baixar.</div>}
                                                                placement="right" arrow>
                                                                    <img style={{height: 22, width: 22}} src={process.env.PUBLIC_URL + '/assets/icons/info-dash-gray.svg'}                                                             
                                                                    alt={'icon'} />
                                                                </ModifiedTooltip>
                                                            </div>
                                                            
                                                        </Grid>
                                                    </Grid>
                                                </div>  
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Grid container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center">
                                            <Grid item xs={12}>
                                                <div className='refresh-div'>
                                                    <GenericButton color={'darkBlue-outline'} label={'Atualizar'} subClass={'basic-button with-icon'}
                                                            icon={process.env.PUBLIC_URL + 'refresh-blue.svg'}
                                                            click={() => window.location.reload(false)}/>
                                                    <label className='time-label'>{"Atualizado em: " + this.formatDate()}</label>
                                                </div>                                               
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>}
                            <Toolbar >
                                <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto' style={{width : "100%"}}>
                                <Tab key={'Tab0'} value={0} label={'Geral'} ></Tab>
                                <Tab key={'Tab1'} value={1} label={'Setor Industrial'} ></Tab>
                                <Tab key={'Tab2'} value={2} label={'Turmas'}></Tab>
                                { this.perfil === 'UO' && <Tab key={'Tab3'} value={3} label={'Mentores Brasil Mais'}></Tab> }
                                <Tab key={'Tab4'} value={4} label={'Indicadores'}></Tab>
                                { this.perfil === 'DN' && <Tab key={'Tab5'} value={5} label={'Avaliações'}></Tab> }
                                </Tabs>
                            </Toolbar>                            
                        </AppBar>
                            { this.state.tab === 0 &&
    	                	<Grid item xs={12}>
    	                		<DashboardBrasilMaisGeral perfil={this.perfil} closeLoading={this.closeLoading} showLoading={this.showLoading}
    	                			showToast={this.showToast} fase={this.state.fase}/>
    	                    </Grid> }
    	                { this.state.tab === 1 && 
    	                    <Grid item xs={12}>
    	                    	<DashboardBrasilMaisSetor perfil={this.perfil} closeLoading={this.closeLoading} showLoading={this.showLoading} 
    	                    		showToast={this.showToast} fase={this.state.fase}/>
    	                    </Grid> }
    	                { this.state.tab === 2 && 
    	                	<Grid item xs={12}>
    	                        <DashboardBrasilMaisTurmas perfil={this.perfil} closeLoading={this.closeLoading} showLoading={this.showLoading} 
    	                        	showToast={this.showToast} fase={this.state.fase}/>
    	                    </Grid> }
                        { this.state.tab === 3 && 
                            <Grid item xs={12}>
                                <DashboardBrasilMaisMentores perfil={this.perfil} closeLoading={this.closeLoading} showLoading={this.showLoading} 
                                    showToast={this.showToast} fase={this.state.fase}/>
                            </Grid> }
    	                { this.state.tab === 4 && 
    	                    <Grid item xs={12}>
    	                    	<DashboardBrasilMaisIndicadores perfil={this.perfil} closeLoading={this.closeLoading} showLoading={this.showLoading} 
    	                    		showToast={this.showToast} usuarioConectado={this.props.usuarioConectado} fase={this.state.fase}/>
    	                    </Grid> }
                        { this.state.tab === 5 && 
    	                    <Grid item xs={12}>
    	                    	<DashboardBrasilMaisAvaliacoes perfil={this.perfil} closeLoading={this.closeLoading} showLoading={this.showLoading} 
    	                    		showToast={this.showToast} usuarioConectado={this.props.usuarioConectado} fase={this.state.fase}/>
    	                    </Grid> }                        
    	            </Grid>
                </Grid>
            </Page>
        );
    }
}