import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import './graphBarHorizontal.scss';

export default class GraphBarHorizontalSimples extends Component {

    constructor(props) {
        super(props);
        this.state = {
            title: props.graph.title,
            series: props.graph.series,
        }
    }

    createGrafico = () => {
        let series = this.state.series;
        let graph = this.props.graph;
        let options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Raleway'
                },
                backgroundColor: null,
                height: this.props.height,
                width: this.props.width,
            },
            tooltip: graph.tooltip,
            plotOptions: graph.plotOptions,
            xAxis: {
                gridLineColor: '#707070',
                gridLineWidth: 1,
                lineColor: '#707070',
                lineWidth: 1,
                categories: [],
                title: false,
                labels: {
                    enabled: false
                }
            },
            yAxis: {
                plotLines: graph.plotLinesY,
                tickInterval: graph.tickInterval,
                min: graph.minY,
                max: graph.maxY,
                title: false,
                labels: graph.labelsY ? graph.labelsY : { enabled: false }
            },
            series: series,
            credits: {
                enabled: false
            },
            legend: { enabled: false },
            exporting: false
        };
        return options;
    };

    render() {
        return (
            <Grid container style={this.props.style}>
                <HighchartsReact key={this.props.graph.title}
                    highcharts={Highcharts}
                    options={this.createGrafico()} />
            </Grid>
        );
    }

}