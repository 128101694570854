import React, { Component } from 'react';
import axios from 'axios';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Masks from '../../comm/masks';
import FormField from '../../components/formField/formField';
import GenericButton from './../../components/genericButton/genericButton';
import DownloadFiles from '../../comm/DownloadFiles';
import API from '../../comm/API';
import InputSelect from '../../components/inputselect/inputselect';
import { Grid,  Icon, Tooltip, Chip} from "@material-ui/core"
import { withStyles} from "@material-ui/core/styles";
import UploadTopButtonsDigital from './uploadTopButtonsDigital';
import AnexosDefault from './anexosDefault';

export default class AtividadeIndicadoresPerformance extends Component {


    MaturidadeOptions = [
        { id: 1, label: 'Otimização', value: 'OTIMIZAÇAO'}, 
        { id: 2, label: 'Sensorização e Conectividade', value: 'SENSORIAÇAO E CONECTIVIDADE'}, 
        { id: 3, label: 'Visibilidade e Transparência', value: 'VISIBILIDADE E TRANSPARENCIA'},
        { id: 4, label: 'Capacidade Preditiva', value: 'CAPACIDADE PREDITIVA'},    
        { id: 5, label : 'Flexibilidade e Adaptabilidade', value : 'FLEXIBILIDADE E ADAPTIBILIDADE'}     
    ];

    cincoDias = 432000000;
    
    constructor(props) {
        super(props);

        let turma = this.props.turma;
        let atendimento = this.props.atendimento
        let etapa = this.props.etapa;
        
        let indicadoresDigital  = props.atividade;
        let medicaoInicial = "";
        let atividade = this.getDefaultValues(indicadoresDigital);
        if(this.props.etapa=='D'){
            medicaoInicial = this.getInitialIndicators();
            medicaoInicial  = this.getDefaultValues(medicaoInicial);
        }
        

        this.state = {
            indicadoresDigital: atividade,
            indicadoresDigitalTabInitial: medicaoInicial,
            errors: {
                coletaIndicadoresText: '',
            },
            Tab: 0,
            tabInitialFinal: 'initial',
        };
    };

    getDefaultValues = (indicadoresDigital) => { 
        indicadoresDigital.avaliacao = [];
        indicadoresDigital.medicaoManual = 0;
        indicadoresDigital.disponibilidadeManual = 0;
        indicadoresDigital.performanceManual = 0;
        indicadoresDigital.qualidadeManual = 0;
        indicadoresDigital.medicaoOEE = 0;
        indicadoresDigital.disponibilidadeOEE = 0;
        indicadoresDigital.performanceOEE = 0;
        indicadoresDigital.qualidadeOEE = 0;


        if(!indicadoresDigital.oeeDigital){
            indicadoresDigital.oeeDigital = {
                id: null,dataInicial: '',dataFinal: '', medicao: '',disponibilidade: '',performance: '',qualidade: '', isManual: false, anexoOEEList: []
            }
        }
        else if(this.props.etapa !== 'B'){
            if(indicadoresDigital.oeeDigital.isManual){
                indicadoresDigital.medicaoManual = indicadoresDigital.oeeDigital.medicao;
                indicadoresDigital.disponibilidadeManual = indicadoresDigital.oeeDigital.disponibilidade;
                indicadoresDigital.performanceManual = indicadoresDigital.oeeDigital.performance;
                indicadoresDigital.qualidadeManual = indicadoresDigital.oeeDigital.qualidade;
            }
            else{
                indicadoresDigital.medicaoOEE = indicadoresDigital.oeeDigital.medicao;
                indicadoresDigital.disponibilidadeOEE = indicadoresDigital.oeeDigital.disponibilidade;
                indicadoresDigital.performanceOEE = indicadoresDigital.oeeDigital.performance;
                indicadoresDigital.qualidadeOEE = indicadoresDigital.oeeDigital.qualidade;
            }
        }
        if(indicadoresDigital.maturidadeDigital != null){
            if (indicadoresDigital.maturidadeDigital.anexoMaturidadeDigitalList != null) {
                indicadoresDigital.maturidadeDigital.anexoMaturidadeDigitalList.forEach(anexo => {
                    if (anexo.descricao === 'PDF da Avaliacao') {
                        indicadoresDigital.avaliacao.push(anexo);
                        let index = indicadoresDigital.maturidadeDigital.anexoMaturidadeDigitalList.indexOf(anexo);
                        indicadoresDigital.maturidadeDigital.anexoMaturidadeDigitalList.splice(index,1);
                    }
                });
            }
        }

        return indicadoresDigital;
    }

    getIndicatorByIdAtendimento = (idAtendimento, etapa, indicator) => {
        const atendimento = this.props.turma[etapa].atendimentoList.find(atendimento => atendimento.idAtendimento === idAtendimento);

        return atendimento[indicator] || {};
    }
    
    getInitialIndicators = () => {
        return this.getIndicatorByIdAtendimento(this.props.atendimento.idAtendimento, 'etapaPraticaC', 'indicadoresDigital');
    }
    
    changeInputProd = (e) => {
        let indicadoresDigital = this.state.indicadoresDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
                indicadoresDigital.produtividadeDigital[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
                indicadoresDigital.produtividadeDigital[e.target.name] = e.target.value;
            }
            this.setState({ indicadoresDigital: indicadoresDigital, errors: errors });
        }
    };

    changeInputMat = (e) => {
        let indicadoresDigital = this.state.indicadoresDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                indicadoresDigital.maturidadeDigital[e.target.name] = e.target.value + 'T00:00:00-03:00';
            }
            else if (e.target.name) {
                indicadoresDigital.maturidadeDigital[e.target.name] = e.target.value;
            }
            else if (e.target.id) {
                indicadoresDigital.maturidadeDigital[e.target.id] = e.target.value;
            }
            this.setState({ indicadoresDigital: indicadoresDigital, errors: errors });
        }
    };

    changeInputOEE = (e) => {
        let indicadoresDigital = this.state.indicadoresDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                indicadoresDigital.oeeDigital[e.target.name] = e.target.value + 'T00:00:00-03:00';
            }
            else if (e.target.name) {
                indicadoresDigital.oeeDigital[e.target.name] = e.target.value;
            }
            else if (e.target.id) {
                indicadoresDigital.oeeDigital[e.target.id] = e.target.value;
            }
            this.setState({ indicadoresDigital: indicadoresDigital, errors: errors });
        }
    };

    changeInput = (e) => {
        let indicadoresDigital = this.state.indicadoresDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.type === 'date') {
                indicadoresDigital[e.target.name] = e.target.value + 'T00:00:00-03:00';
            }
            else if (e.target.name) {
                indicadoresDigital[e.target.name] = e.target.value;
            }
            else if (e.target.id) {
                indicadoresDigital[e.target.id] = e.target.value;
            }
            this.setState({ indicadoresDigital: indicadoresDigital, errors: errors });
        }
    };

    switchOEE = () => {
        console.log()
        if(this.props.etapa !== 'B' && !this.props.disabled){
            let indicadoresDigital = this.state.indicadoresDigital;
        indicadoresDigital.oeeDigital.isManual = !indicadoresDigital.oeeDigital.isManual;
        this.setState({ indicadoresDigital: indicadoresDigital });
        }        
    }

    changeNivel = (e) => {
        let indicadoresDigital = this.state.indicadoresDigital;
        indicadoresDigital.maturidadeDigital.nivel = this.MaturidadeOptions[e.id - 1].value;
        this.setState({ indicadoresDigital: this.state.indicadoresDigital });
    };

    formatData(date) {
        if (date) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    };

    uploadPdfAvaliacao = (e) => {
        this.props.showLoading();
        let file = e.target.files[0];
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file && !(file.name.toLowerCase().includes('jpg') || file.name.toLowerCase().includes('jpeg') || 
            file.name.toLowerCase().includes('png') || file.name.toLowerCase().includes('pdf'))) {
            this.props.closeLoading();
            this.props.showToast('Tipo de arquivo não permitido. Anexar apenas PDF, JPG, JPEG ou PNG.', 'error');
            return;
        }
        if (file.size > 10000000) {
            this.props.closeLoading();
            this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }  
        let indicadoresDigital = this.state.indicadoresDigital;      
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            const anexo = {
                descricao: 'PDF da Avaliacao',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
            };
            indicadoresDigital.avaliacao.push(anexo);
            this.setState({ indicadoresDigital: indicadoresDigital });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        })   
       
    };

    uploadGraph = (formData) => {
        this.props.showLoading();
        API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            let indicadoresDigital = this.state.indicadoresDigital;
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url
            };
            indicadoresDigital.oeeDigital.anexoOEEList.push(anexo);
            await this.setState({ indicadoresDigital: indicadoresDigital });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        });
    };

    calculaProdutividade = (atividade) => {
        let resultado = 0;
        if (atividade.operadoresTurno && atividade.horasTurno && atividade.prodRealizada) {
            resultado = Number((atividade.prodRealizada / atividade.operadoresTurno / atividade.horasTurno ).toFixed(6));
            atividade.medicao = resultado;
        }
        return resultado;
    };

    calculaTempoOp = (atividade) => {
        let resultado = 0;
        if (atividade.horasTurno && atividade.horasParadas ) {
            resultado = Number((atividade.horasTurno - atividade.horasParadas ).toFixed(6));
            atividade.tempoOperacao = resultado;
        }
        return resultado;
    };

    calculaProdPoss = (atividade) => {
        let resultado = 0;
        if (atividade.tempoOperacao && atividade.taxaProducao) {
            resultado = Number((atividade.tempoOperacao * atividade.taxaProducao ).toFixed(6));
            atividade.prodPossivel = resultado;
        }
        return resultado;
    };

    calculaProdBoa = (atividade) => {
        let resultado = 0;
        if (atividade.prodRealizada && atividade.prodNaoConforme) {
            resultado = Number((atividade.prodRealizada - atividade.prodNaoConforme  ).toFixed(6));
            atividade.prodBoa = resultado;
        }
        return resultado;
    };

    calculaOEE = (atividade) => {
        let resultado = 0;
        if (atividade.disponibilidadeManual && atividade.performanceManual && atividade.qualidadeManual) {
            resultado = Number((atividade.disponibilidadeManual * atividade.performanceManual * atividade.qualidadeManual /10000 ).toFixed(6));
            atividade.medicaoManual = resultado;
        }
        return resultado;
    };

    removerPdfAvaliacao = (element) => {
        let indicadoresDigital = this.state.indicadoresDigital;         
        let index = indicadoresDigital.avaliacao.findIndex(prop => {
            return (element.id && element.id === prop.id) || element.nomeArquivo === prop.nomeArquivo;
        });
        if (index !== -1) {
            indicadoresDigital.avaliacao.splice(index, 1);
        }
        this.setState({ indicadoresDigital: indicadoresDigital });
    };

    removerAnexo = (element) => {
        let indicadoresDigital = this.state.indicadoresDigital;
        let anexos = this.state.indicadoresDigital.oeeDigital.anexoOEEList;
        console.log(anexos);
        let index = -1;
        index = anexos.findIndex(item => ((element.id && item.id === element.id) || (item.descricao === element.descricao && item.nomeArquivo === element.nomeArquivo)));
        if (index > -1) {
            anexos.splice(index, 1);
        }
        console.log('splice',anexos);
        indicadoresDigital.oeeDigital.anexoOEEList = anexos;
        this.setState({ indicadoresDigital: indicadoresDigital });
    };

    getOEE = () =>{
        this.props.showLoading();
        API.get('rest/gestao/TurmaMentoriaDigital/getInfoCliente/' + this.props.atendimento.idAtendimento).then(async res => {
        const api = axios.create({baseURL: 'https://dev.mina.com.br/mes_be_sponsor/'});      
        let indicadoresDigital = this.state.indicadoresDigital;
        let initDate  = new Date(indicadoresDigital.oeeDigital.dataInicial);
        let finDate = new Date(indicadoresDigital.oeeDigital.dataFinal);
        let cpfcnpj = (res.data > 11 ? Masks.maskByTipoPessoa(res.data, 1) : Masks.maskByTipoPessoa(res.data, 0))
        let cincoDias = 432000000;
        let url = '/api/v1/ExternalAcessApi/IndicatorsByCnpjAndPeriod?CNPJ=' + cpfcnpj + '&InitDateTime=' + initDate.toJSON() + '&EndDateTime=' + finDate.toJSON()
        const response = await api.get(
        url,
        {
            headers: { 
            'ApiKey': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE2Mjk3NjgyMTksImV4cCI6MTY2MTMwNDIxOSwiYXVkIjoid3d3Lm1pbmEuY29tLmJyIiwic3ViIjoic3Vwb3J0ZUBtaW5hLmNvbS5iciIsIlByb2pldG8iOiJNZW50b3JpYURpZ2l0YWwifQ.jByvfnEBsoqUxiPypYUWPO0HogVsLaL07QUfcRflWCs',
        },
        },
        );
        if (response == undefined){
            throw Error;
        }
               
        indicadoresDigital.medicaoOEE = response.data.oee;
        indicadoresDigital.disponibilidadeOEE = response.data.availability;
        indicadoresDigital.performanceOEE = response.data.performance;
        indicadoresDigital.qualidadeOEE =  response.data.quality;        
        await this.setState({ indicadoresDigital: indicadoresDigital });
        this.props.closeLoading();
        }).catch(error => {
            this.props.showToast('Erro ao tentar obter dados da plataforma IOT. Favor, entrar em contato com o suporte.', 'error');
            this.props.closeLoading();
        });
    }

    checkDate = () =>{
        let indicadoresDigital = this.state.indicadoresDigital;
        let initDate  = new Date(indicadoresDigital.oeeDigital.dataInicial);
        let finDate = new Date(indicadoresDigital.oeeDigital.dataFinal);

        return (Math.abs(finDate.getTime() - initDate.getTime()) > this.cincoDias)
    }

    renderOEE = (isOnlyLabel) => {
        let atividade = isOnlyLabel ? this.state.indicadoresDigitalTabInitial : this.state.indicadoresDigital;
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
        if(atividade.oeeDigital.isManual){
            return(
            <>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ paddingLeft: '3%', marginBottom: -20 }}>
                    <Grid item xs={12} md={4} xl={3}>
                    <div>
                        <label> Informe o período da coleta (mínimo de 5 dias)</label>
                    </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}  >
                    <div>
                    </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                    <div>
                    </div>
                    </Grid>            
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ paddingLeft: '3%', marginBottom: 32 }}>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <FormField 
                                type={'date'} 
                                ref='data' id={'data'}
                                name = {'dataInicial'}
                                label={'Data inicial'}
                                inputValue={atividade.oeeDigital.dataInicial ? this.formatData(atividade.oeeDigital.dataInicial) : ''}
                                changeValue={this.changeInputOEE}
                                disabled={this.props.etapa === 'B' || this.props.disabled}
                                icon={'calendar.svg'}
                                style={{width:'100%'}} 
                                isOnlyLabel={isOnlyLabel}
                            />
                        </div>
                    </Grid>                            
                    <Grid item xs={12} md={4} xl={3}>
                    <div className={'div-field-btn field-label'}>
                            <FormField 
                                type={'date'} 
                                ref='data' id={'data'}
                                name = {'dataFinal'}
                                label={'Data final'}
                                inputValue={atividade.oeeDigital.dataFinal ? this.formatData(atividade.oeeDigital.dataFinal) : ''}
                                changeValue={this.changeInputOEE}
                                disabled={this.props.etapa === 'B' || this.props.disabled}
                                icon={'calendar.svg'}
                                style={{width:'100%'}} 
                                isOnlyLabel={isOnlyLabel}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3} style={{alignItems:'flex-end', display:'flex'}}>
                        <div >                            
                        </div>
                    </Grid>           
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ paddingLeft: '3%', marginBottom: 10 }}>
                    <Grid item xs={12} md={4} xl={3}>
                    <div>
                        <label> Informe os valores do período</label>
                    </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}  >
                    <div>
                    </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                    <div>
                    </div>
                    </Grid>            
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ paddingLeft: '3%', marginBottom: 32 }}>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <FormField 
                                id={'disponibilidadeManual'} 
                                key={'disponibilidadeManual'} 
                                label={'Disponibilidade'}
                                type={'number'}
                                min='1' 
                                maxLength='15'
                                subtype={'seiscasas'}
                                disabled={this.props.disabled} 
                                changeValue={this.changeInput} 
                                inputValue={atividade.disponibilidadeManual}
                                isOnlyLabel={isOnlyLabel}
                            />                            
                        </div>
                    </Grid>                            
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <FormField 
                                id={'performanceManual'} 
                                key={'performanceManual'} 
                                label={'Performance'}
                                type={'number'}
                                min='1' 
                                maxLength='15'
                                subtype={'seiscasas'}
                                disabled={this.props.disabled} 
                                changeValue={this.changeInput} 
                                inputValue={atividade.performanceManual}
                                isOnlyLabel={isOnlyLabel}
                            />                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <FormField 
                                id={'qualidadeManual'} 
                                key={'qualidadeManual'} 
                                label={'Qualidade'}
                                type={'number'}
                                min='1' 
                                maxLength='15'
                                subtype={'seiscasas'}
                                disabled={this.props.disabled} 
                                changeValue={this.changeInput} 
                                inputValue={atividade.qualidadeManual}
                                isOnlyLabel={isOnlyLabel}
                            />                            
                        </div>
                    </Grid>           
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', borderTop: '1px solid #E1E3E6', marginTop: '2%', 
                    opacity:this.props.etapa === 'B' ? 0.5 : 1 }} >
                    <Grid item xs={12} md={4} xl={3}>
                        {(!this.props.etapa == 'D' || this.state.tabInitialFinal == 'initial') ?
                        <div>
                            <label className={'label-processo-medicao'}>OEE (medição inicial)</label>                          
                        </div>:
                        <div>
                            <label className={'label-processo-medicao'}>OEE (medição final)</label>                          
                        </div>}
                        <div className={'grid-indicador'}>
                            <ModifiedTooltip 
                            title={ 
                            <div>Disponibilidade<br />
                            <span style={{color:'#E9B03D'}}>(*) multiplicação </span><br/>
                            Performance<br/>
                            <span style={{color:'#E9B03D'}}>(*) multiplicação </span><br/>
                            Qualidade</div>}
                            placement="right" arrow>
                                <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                            </ModifiedTooltip>
                            {this.calculaOEE(atividade).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}%
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                    </Grid>
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ marginTop: '2%', paddingLeft: '3%' }}>
                        <Grid item xs={12} >
                            <Grid container justify={'center'} style={{ paddingTop: '1%', display: 'flex', alignItems: 'flex-end', borderTop: '2px solid #707070' }}>
                            
                                <Grid item xs={12} style={{paddingBottom: 'unset'}}>
                                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                        <Grid item md={6}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                <label> Anexe gráficos do OEE (prints da tela da Plataforma IOT ou gráficos gerados com os dados)</label>
                                            </div>
                                        </Grid>                                        
                                        { (!this.props.disabled || isOnlyLabel) && <UploadTopButtonsDigital uploadFile={ this.uploadGraph } showToast={ this.props.showToast } label={'Gráficos OEE'}/> }                                        
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} style={{paddingTop: '1%'}}>
                                    <AnexosDefault
                                        showEmptyEvidencias={ true }
                                        anexos={ atividade.oeeDigital.anexoOEEList }
                                        title='Indicadores de Performance'
                                        fase={this.props.fase}
                                        turma={this.props.turma}
                                        disableForm={this.props.disabled || isOnlyLabel}
                                        removerAnexo={(element) => this.removerAnexo(element)}
                                        darkBorder={ true }
                                        msg={'Você ainda não anexou evidências.'} />
                                </Grid> 
                            </Grid>
                        </Grid> 
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginTop: '2%'
                    , opacity:this.props.etapa === 'B' ? 0.5 : 1 }} >
                    {!isOnlyLabel && (
                        <Grid item xs={9} >
                            <div>
                                <label style={{font:'italic normal medium 13px/15px Raleway', opacity: this.props.disabled ? 0.4 : 1,
                                        cursor: this.props.disabled ? 'not-allowed': 'cursor'}}  >{'Para inserir os dados via Plataforma IOT: '}
                                <span style={{textDecoration:'underline', cursor:'pointer'}} disabled={this.props.disabled} onClick={() => {this.switchOEE()}}>clique aqui</span></label>                          
                            </div>
                        </Grid>
                    )}
                </Grid>
            </>);
        }
        else{
            return(
            <>  
                {!isOnlyLabel && (
                    <Grid container justify={'center'} direction={'row'} spacing={4} style={{ paddingLeft: '3%', marginBottom: -20 }}>
                        <Grid item xs={12} md={4} xl={3}>
                            <div>
                                <label> Informe o período da coleta (mínimo de 5 dias)</label>
                            </div>
                            </Grid>
                            <Grid item xs={12} md={4} xl={3}  >
                            <div>
                            </div>
                            </Grid>
                            <Grid item xs={12} md={4} xl={3}>
                            <div>
                            </div>
                        </Grid>           
                    </Grid>
                )}
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ paddingLeft: '3%', marginBottom: 32 }}>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <FormField 
                                type={'date'} 
                                ref='data' id={'data'}
                                name = {'dataInicial'}
                                label={'Data inicial'}
                                inputValue={atividade.oeeDigital.dataInicial ? this.formatData(atividade.oeeDigital.dataInicial) : ''}
                                changeValue={this.changeInputOEE}
                                disabled={this.props.etapa === 'B'}
                                icon={'calendar.svg'}
                                style={{width:'100%'}} 
                                isOnlyLabel={isOnlyLabel}
                            />
                        </div>
                    </Grid>                            
                    <Grid item xs={12} md={4} xl={3}>
                    <div className={'div-field-btn field-label'}>
                            <FormField 
                                type={'date'} 
                                ref='data' id={'data'}
                                name = {'dataFinal'}
                                label={'Data final'}
                                inputValue={atividade.oeeDigital.dataFinal ? this.formatData(atividade.oeeDigital.dataFinal) : ''}
                                changeValue={this.changeInputOEE}
                                disabled={this.props.etapa === 'B'}
                                icon={'calendar.svg'}
                                style={{width:'100%'}} 
                                isOnlyLabel={isOnlyLabel}
                            />
                        </div>
                    </Grid>
                    {!isOnlyLabel && (
                        <Grid item xs={12} md={4} xl={3} style={{alignItems:'flex-end', display:'flex'}}>
                            <div style={{marginBottom:10, width: '100%'}}>
                                <label style={{marginBottom:10, }}>{'Clique para obter dados automaticamente:'}</label>
                                <GenericButton color={ 'yellow'}
                                click={ (e) => {this.getOEE()}}
                                subClass={'basic-button full-width'}
                                disabled={this.props.etapa === 'B' || !(atividade.oeeDigital.dataFinal && atividade.oeeDigital.dataInicial) || atividade.oeeDigital.dataFinal && atividade.oeeDigital.dataInicial  && !(this.checkDate())}
                                style={{width:'100%'}}
                                label={'OBTER DADOS DA PLATAFORMA IOT'}/>
                            </div>
                        </Grid>           
                    )}
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', borderTop: '1px solid #E1E3E6', marginTop: '2%', 
                    opacity:this.props.etapa === 'B' ? 0.5 : 1 }} >
                        <Grid item xs={12} md={4} xl={3}>
                            {(!this.props.etapa == 'D' || this.state.tabInitialFinal == 'initial') ?
                            <div>
                                <label className={'label-processo'}>OEE (medição inicial)</label>                          
                            </div>:
                            <div>
                                <label className={'label-processo'}>OEE (medição final)</label>                          
                            </div>}
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Disponibilidade<br />
                                <span style={{color:'#E9B03D'}}>(*) multiplicação </span><br/>
                                Performance<br/>
                                <span style={{color:'#E9B03D'}}>(*) multiplicação </span><br/>
                                Qualidade</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {atividade.medicaoOEE ? (atividade.medicaoOEE).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 }) : '-'}%
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                        </Grid>
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginTop: '2%',
                    opacity:this.props.etapa === 'B' ? 0.5 : 1 }} >
                        <Grid item xs={12} md={4} xl={3}>
                            <div>
                                <label className={'label-processo'}>Disponibilidade</label>                          
                            </div>
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Tempo de operação<br />
                                <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                                Quantidade de horas por turno</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {atividade.disponibilidadeOEE ? (atividade.disponibilidadeOEE).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 }) : '-'}%
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div>
                                <label className={'label-processo'}>Performance</label>                          
                            </div>
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Produção realizada<br />
                                <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                                Quantidade possível</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {atividade.performanceOEE ? (atividade.performanceOEE).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 }) : '-'}%
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div>
                                <label className={'label-processo'}>Qualidade</label>                          
                            </div>
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Produção boa<br />
                                <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                                Produção realizada</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {atividade.qualidadeOEE ? (atividade.qualidadeOEE).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 }) : '-'}%
                            </div>                            
                        </Grid>
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginTop: '2%'
                    , opacity:this.props.etapa === 'B' ? 0.5 : 1 }} >
                        {!isOnlyLabel && (
                            <Grid item xs={9} >
                                <div>
                                    <label style={{font:'italic normal medium 13px/15px Raleway'}} >{'Para inserir os dados manualmente: '}
                                    <span style={{textDecoration:'underline', cursor:'pointer'}} onClick={() => {this.switchOEE()}}>clique aqui</span></label>                          
                                </div>
                            </Grid>
                        )}
                    </Grid>
            </>);
        }

    }


    renderBody = (isOnlyLabel) => {
        let atividade = isOnlyLabel ? this.state.indicadoresDigitalTabInitial : this.state.indicadoresDigital;
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
        
        console.log('atividade',atividade);

        return (
            <Grid container justify={'center'} style={{ marginTop: '3%' }}>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ paddingLeft: '3%', marginBottom: 10 }}>
                    <Grid item xs={2}>
                    <div>
                    </div>
                    </Grid>
                    <Grid item xs={6}  >
                    <div>
                        <label> Clique no tipo de indicador para inserir dados: </label>
                    </div>
                    </Grid>
                    <Grid item xs={2}>
                    <div>
                    </div>
                    </Grid>
                                        
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={0} style={{ paddingLeft: '3%', marginBottom: 32 }} >
                    <Grid item xs={2}>
                    <div>
                    </div>
                    </Grid>
                    <Grid item xs={2}>
                    <div>
                        <GenericButton color={this.state.Tab == 0 ? 'orange' :'lightBlue'}
                         click={ (e) => {this.setState({Tab: 0})}}
                         subClass={'basic-button rounded-left tab full-width'}
                         style={{width:'100%'}}
                         label={'Produtividade'}/>
                    </div>
                    </Grid>
                    <Grid item xs={2} style={{marginLeft: 3, marginRight: 3}}>
                    <div>
                        <GenericButton color={this.state.Tab == 1 ? 'orange' :'lightBlue'}
                         click={ (e) => {this.setState({Tab: 1})}}
                         subClass={'basic-button full-width tab'}
                         style={{width:'100%'}}
                         label={'OEE'}/> 
                    </div>
                    </Grid>
                    <Grid item xs={2}>
                    <div>
                        <GenericButton color={this.state.Tab == 2 ? 'orange' :'lightBlue'}
                         click={ (e) => {this.setState({Tab: 2})}}
                         subClass={'basic-button rounded-right tab full-width'}
                         style={{width:'100%'}}
                         label={'Maturidade'}/>
                    </div>
                    </Grid>
                    <Grid item xs={2}>
                    <div>
                    </div>
                    </Grid>
                </Grid>
                {this.state.Tab == 0 &&
                <>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%'}} >
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'operadoresTurno'} 
                                    key={'operadoresTurno'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Operadores por turno'}
                                    changeValue={this.changeInputProd}
                                    disabled={this.props.disabled}
                                    inputValue={atividade.produtividadeDigital.operadoresTurno}
                                    isOnlyLabel={isOnlyLabel}        
                                />                            
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'horasTurno'} 
                                    key={'horasTurno'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Horas por turno'}
                                    disabled={this.props.disabled}
                                    changeValue={this.changeInputProd}
                                    inputValue={atividade.produtividadeDigital.horasTurno}
                                    isOnlyLabel={isOnlyLabel}        
                                />                            

                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'horasParadas'} 
                                    key={'horasParadas'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Horas de paradas'}
                                    changeValue={this.changeInputProd}
                                    disabled={this.props.etapa === 'B' || this.props.etapa === 'C' || this.props.disabled} 
                                    inputValue={atividade.produtividadeDigital.horasParadas}
                                    isOnlyLabel={isOnlyLabel}        
                                />                            
                            </div>
                        </Grid> 
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginTop: '2%' }} >
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'prodNaoConforme'} 
                                    key={'prodNaoConforme'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Produção não conforme'}
                                    changeValue={this.changeInputProd}
                                    disabled={this.props.etapa === 'B' || this.props.etapa === 'C'|| this.props.disabled} 
                                    inputValue={atividade.produtividadeDigital.prodNaoConforme}
                                    isOnlyLabel={isOnlyLabel}        
                                />                            
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'prodRealizada'} 
                                    key={'prodRealizada'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Produção realizada'}
                                    disabled={this.props.disabled}
                                    changeValue={this.changeInputProd}
                                    inputValue={atividade.produtividadeDigital.prodRealizada}
                                    isOnlyLabel={isOnlyLabel}        
                                />                            
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'taxaProducao'} 
                                    key={'taxaProducao'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Taxa de produção'}
                                    changeValue={this.changeInputProd}
                                    disabled={this.props.etapa === 'B' || this.props.etapa === 'C' || this.props.disabled} 
                                    inputValue={atividade.produtividadeDigital.taxaProducao}
                                    isOnlyLabel={isOnlyLabel}   
                                />                            
                            </div>
                        </Grid> 
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', borderTop: '1px solid #E1E3E6', marginTop: '2%', 
                    opacity:this.props.etapa === 'B' || this.props.etapa === 'C' ? 0.5 : 1 }} >
                        <Grid item xs={12} md={4} xl={3}>
                            <div>
                                <label className={'label-processo'}>Tempo de operação</label>                          
                            </div>
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Horas por turno<br />
                                <span style={{color:'#E9B03D'}}>(-) subtração </span><br/>
                                Horas de paradas</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {this.calculaTempoOp(atividade.produtividadeDigital).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}/hh
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div >
                                <label className={'label-processo'}>Produção possível</label>                          
                            </div>
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Tempo de Operação<br />
                                <span style={{color:'#E9B03D'}}>(*) multiplicação </span><br/>
                                Taxa de Produção</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {this.calculaProdPoss(atividade.produtividadeDigital).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}/hh
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div>
                                <label className={'label-processo'}>Produção boa</label>                    
                            </div>
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Produção realizada<br />
                                <span style={{color:'#E9B03D'}}>(-) subtração </span><br/>
                                Produção não conforme</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {this.calculaProdBoa(atividade.produtividadeDigital).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}/hh
                                
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginTop: '2%' }} >
                        <Grid item xs={12} md={4} xl={3}>
                            {(!this.props.etapa == 'D' || this.state.tabInitialFinal == 'initial') ? 
                            <div>
                                <label className={'label-processo-medicao'}>Produtividade (medição inicial)</label>                          
                            </div>:
                            <div>
                                <label className={'label-processo-medicao'}>Produtividade (medição final)</label>                          
                            </div>}
                            <div className={'grid-indicador'}>
                                <ModifiedTooltip 
                                title={ 
                                <div>Produção Realizada<br />
                                <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                                Operadores por turno<br />
                                <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                                Horas por turno</div>}
                                placement="right" arrow>
                                    <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                                </ModifiedTooltip>
                                {this.calculaProdutividade(atividade.produtividadeDigital).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}/hh
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            
                        </Grid>
                    </Grid>
                    {this.props.etapa !== 'D' && 
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginTop: '2%' }} >
                        <Grid item xs={9}>
                            <div className={'warning-label'}>
                                <label >* Os campos bloqueados não são necessários para o cálculo da medição inicial mas serão habilitados para preenchimento na Mentoria Prática da Etapa D para calcular a medição final</label>                          
                            </div>
                            
                        </Grid>
                    </Grid>}

                </>
                }
                {this.state.Tab == 1 &&
                this.renderOEE(isOnlyLabel)
                }
                {this.state.Tab == 2 &&
                <>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%' }} >
                        <Grid item xs={12} md={4} xl={3} className={'title-label'} >
                            {(!this.props.etapa == 'D' || this.state.tabInitialFinal == 'initial') ?
                            <div>
                                <label> Maturidade (medição inicial) </label>
                            </div>:
                            <div>
                                <label> Maturidade (medição final) </label>
                            </div>}
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>                            
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>                        
                        </Grid> 
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%' }} >
                        <Grid item xs={12} md={4} xl={3} style={{ display: 'flex' }}>
                            <div className={'div-field-btn field-label'} style={{ alignItems:'flex-end' }}>
                            <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/calendar-brasilmais.svg'} width={30} height={30} style={{marginRight: '10px', marginBottom:'10px'}} />
                            </div>
                            <div className={'div-field-btn field-label'}>
                                <FormField 
                                    type={'date'} 
                                    ref='data' id={'data'}
                                    name = {'dataAvaliacao'}
                                    label={'Senai 4.0'}
                                    placeholder={'Data da avaliação'}
                                    inputValue={atividade.maturidadeDigital.dataAvaliacao ? this.formatData(atividade.maturidadeDigital.dataAvaliacao) : ''}
                                    changeValue={this.changeInputMat}
                                    disabled={this.props.disabled}
                                    icon={'calendar.svg'}
                                    style={{width:'100%'}} 
                                    isOnlyLabel={isOnlyLabel}
                                />
                            </div>
                            </Grid>                            
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <InputSelect
                                    suggestions={this.MaturidadeOptions}
                                    label={'Informe o nível de maturidade da empresa'}
                                    itemKey={'id'}
                                    itemLabel={'label'}
                                    id={'tipo'}
                                    getInput={this.changeNivel}
                                    initialSelectedItem={{ id: atividade.maturidadeDigital.nivel ?  this.MaturidadeOptions.find(o => o.value === atividade.maturidadeDigital.nivel) ? this.MaturidadeOptions.find(o => o.value === atividade.maturidadeDigital.nivel).id : 1 : 1 }}
                                    isOnlyLabel={isOnlyLabel}
                                    disabled={this.props.disabled}
                                    inputLabelValue={this.state.indicadoresDigital.maturidadeDigital.nivel}
                                />                            
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'pontuacaoTotal'} 
                                    key={'pontuacaoTotal'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Informe a pontuação total da empresa'}
                                    changeValue={this.changeInputMat}
                                    disabled={this.props.disabled}
                                    inputValue={atividade.maturidadeDigital.pontuacaoTotal}
                                    isOnlyLabel={isOnlyLabel}
                                />                            
                            </div>
                        </Grid> 
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%', marginTop: '1%' }} >
                        <Grid item xs={12} md={4} xl={3} className={'title-label-gray'} style={{color:'#707070' }} >
                            <div>
                                <label> Informe a pontuação por dimensão </label>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>                            
                        </Grid>
                        <Grid item xs={12} md={4} xl={3} >                        
                        </Grid> 
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%' }} >
                        <Grid item xs={12} md={4} xl={3} >
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'pontuacaoEstrategia'} 
                                    key={'pontuacaoEstrategia'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Estratégia e Organização'}
                                    changeValue={this.changeInputMat} 
                                    disabled={this.props.disabled}
                                    inputValue={atividade.maturidadeDigital.pontuacaoEstrategia}
                                    isOnlyLabel={isOnlyLabel}
                                />                            
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'pontuacaoManufatura'} 
                                    key={'pontuacaoManufatura'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Manufatura e Cadeia de Suprimentos'}
                                    disabled={this.props.disabled}
                                    changeValue={this.changeInputMat}
                                    inputValue={atividade.maturidadeDigital.pontuacaoManufatura}
                                    isOnlyLabel={isOnlyLabel}
                                />                            
                            </div>                            
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>                        
                        </Grid> 
                    </Grid>
                    <Grid container justify={'center'} direction={'row'} spacing={8} style={{ paddingLeft: '3%' ,marginTop: '2%'}} >
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'pontuacaoNegocio'} 
                                    key={'pontuacaoNegocio'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Modelos de Negócio, Produtos e Serviços'}
                                    changeValue={this.changeInputMat}
                                    disabled={this.props.disabled} 
                                    inputValue={atividade.maturidadeDigital.pontuacaoNegocio}
                                    isOnlyLabel={isOnlyLabel}
                                />                            
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <div className={'div-field-btn field-label'}>
                                <FormField
                                    type={'number'}
                                    id={'pontuacaoCultura'} 
                                    key={'pontuacaoCultura'}
                                    min='1' 
                                    maxLength='15'
                                    subtype={'seiscasas'}
                                    label={'Cultura e Pessoas'}
                                    changeValue={this.changeInputMat}
                                    disabled={this.props.disabled} 
                                    inputValue={atividade.maturidadeDigital.pontuacaoCultura}
                                    isOnlyLabel={isOnlyLabel}
                                />                            
                            </div>                            
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>                        
                        </Grid> 
                    </Grid>
                    <Grid container justify={'center'} style={{ marginTop: '2%', borderTop: '1px solid #E1E3E6', paddingLeft: '3%' }} >
                        <Grid item xs={4} xl={3} className={'anexos-visitas-box-top'} >
                            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', paddingTop: '10%' }}>
                                <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/relatorio-brasilmais.svg'} width={30} height={30} />
                                {(!this.props.etapa == 'D' || this.state.tabInitialFinal == 'initial') ? 
                                <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>{'Avaliação de Maturidade (inicial)'}</span>:
                                <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>{'Avaliação de Maturidade (final)'}</span>}
                            </Grid>
                            <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                                {!isOnlyLabel && (
                                    <>
                                        <label
                                            className={'lightBlue basic-button control-label'}
                                            id="upload-atividade"
                                            htmlFor="uploadfile-listapresenca"
                                            style={{opacity: this.props.disabled ? 0.4 : 1,
                                                cursor: this.props.disabled ? 'not-allowed': 'cursor'}}>Anexar
                                        </label>
                                        <input
                                            className={'file-input listaPresenca'}
                                            type="file"
                                            id="uploadfile-listapresenca"
                                            name="file"
                                            value={undefined}
                                            disabled={this.props.disabled}
                                            onChange={(element) => { this.uploadPdfAvaliacao(element) }}
                                            />
                                    </>
                                )}
                            </Grid>
                            <Grid>
                                {atividade.avaliacao.length > 0 ?
                                    atividade.avaliacao.map(
                                        (anexo, index) => (
                                            <Chip key={index}
                                                label={<label style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                                                    <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                    <p>{anexo.nomeArquivo}</p></label>}
                                                icon={this.props.disabled ? <></> :<Icon onClick={() => this.removerPdfAvaliacao(anexo)} className={'close'}>clear</Icon>}
                                            />
                                        )) : <></>}
                                
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>                        
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>                        
                        </Grid>                 
                    </Grid>
                    

                </>
                }
                
                
                
            </Grid>
        )
    }

    renderTabContent = () => {
        switch(this.state.tabInitialFinal) {
            case 'initial':
              return this.renderBody(true);
            case 'final':
                return this.renderBody();
          }
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao-digital'} style={{ marginTop: this.props.etapa === 'D'  ? '-30px' : '30px' }}>
                {this.props.etapa === 'D' ? (
                    <Grid container justify={'center'} style={{ marginTop: '3%' }} spacing={6}>
                        <Grid item xs={12}>
                            <AppBar position='static'>
                                <Tabs 
                                    value={this.state.tabInitialFinal} 
                                    onChange={(_event, newValue) => this.setState({ tabInitialFinal: newValue})} 
                                    variant='scrollable' 
                                    scrollButtons='auto'
                                >
                                    <Tab key={'Resultados'} value={'initial'} label={'Medição inicial'} />
                                    <Tab key={'Análises'} value={'final'} label={'Medição final'} />
                                </Tabs>
                            </AppBar>
                        </Grid>
            
                        <Grid item xs={12}>
                            {this.renderTabContent()}
                        </Grid>
                    </Grid>
                ) : (
                    this.renderBody() 
                )}
            </Grid>
        )
    }

}