import React, { Component } from 'react';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import './autoCompleteMany.scss';
import GenericButton from './../../components/genericButton/genericButton';
import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import API from './../../comm/API';
import Icon from '@material-ui/core/Icon';
import { OutputTable } from '../../components/outputTable/outputTable';


export default class AutoCompleteMany extends Component {

    state = { value: null, selectedItem: this.setInitialSelectedItens(), suggestionsFromServer: [], selectedItemFromObject: [], 
        initialSelectedItemFromObjectCopy: this.props.initialOjectSelectedList };

    constructor(props) {
        super(props);
        this.addItens = this.addItens.bind(this);
    }

    getSuggestions(value, { showEmpty = false } = {}) {
        const inputValue = deburr(value.trim()).toLowerCase();
        const inputLength = inputValue.length;
        let count = 0;
        if (!this.props.path && this.props.suggestions) {
            return inputLength === 0 && !showEmpty
            ? []
            : this.props.suggestions.filter(suggestion => {
                const keep =
                count < 5 && suggestion && suggestion[`${this.props.itemlabel}`] && suggestion[`${this.props.itemlabel}`].toLowerCase().includes(inputValue);
                if (keep) {
                    count += 1;
                }
                return keep;
            });
        } else {
            return this.state.suggestionsFromServer;
        }
    }

    showSuggestionsTemplate(suggestion) {
        if (this.props.itemTemplate) {
            return this.props.itemTemplate(suggestion);
        } else {
            return suggestion[`${this.props.itemlabel}`];
        }
    }

    getSuggestionsFromServer = async (value) => {
        let typed = value && this.props.upper ? value.toUpperCase() : value;
        await API.get(this.props.path + `${typed}${this.props.posPath ? this.props.posPath : ''}`).then(res => {
            this.setState({ suggestionsFromServer: res.data });
        }).catch(error => { });
    }

    renderSuggestion(suggestionProps) {
        const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
        const isHighlighted = highlightedIndex === index;

        return (
            <MenuItem
                {...itemProps}
                key={suggestion[`${this.props.itemKey}`]}
                selected={isHighlighted}
                component="div">
                {this.showSuggestionsTemplate(suggestion)}
            </MenuItem>
        );
    }

    async removeItens(item) {
        let array = this.state.selectedItem.filter((it) => {
            return item[`${this.props.itemKey}`] !== it[`${this.props.itemKey}`];
        });
        await this.setState({ selectedItem: array });

        if (this.props.initialOjectSelectedList) {
            this.updateModelList();
        } else {
            this.props.getInput(this.state.selectedItem);
        }
    }

    setInitialSelectedItens() {
        let array = [];
        if (this.props.initialSelectedItensList) {
            this.props.initialSelectedItensList.forEach(element => {
                array.push(element);
            })
        } else if (this.props.initialOjectSelectedList && this.props.initialOjectSelectedListClass) {
            this.props.initialOjectSelectedList.forEach(element => {
                array.push(element[`${this.props.initialOjectSelectedListClass}`]);
            })
        }

        if (this.props.initialOjectSelectedList) {
            this.props.getInput(this.props.initialOjectSelectedList);
        } else {
            this.props.getInput(array);
        }
        return array;
    }
    renderChipList(inputProps) {
        if (this.state.selectedItem && !this.props.notRenderChip && this.props.renderAsTable) {
            return (
                <OutputTable 
                    menuItems={this.props.menuItems}
                    selectedItems={this.state.selectedItem}
                    propertiesList={this.props.propertiesList}
                    itemKey={this.props.itemKey}
                />
            )
        }
        if (this.state.selectedItem && !this.props.notRenderChip) {
            return (
                <div>
                    {this.state.selectedItem.length > 0 &&
                        this.state.selectedItem.map(item => (
                            <Chip title={item[`${this.props.itemlabel}`]}
                            key={item[`${this.props.itemKey}`]} 
                            label={item[`${this.props.itemlabel}`]} 
                            icon={this.props.disabled? '' : <Icon onClick={() => this.removeItens(item)} className={'close'}>clear</Icon>} />
                        ))}
                </div>
            );
        } else {
            return (<div></div>);
        }

    }

    async addItens() {
        if (this.state.value) {
            let isIn = this.state.selectedItem.filter((item) => {
                return this.state.value[`${this.props.itemKey}`] === item[`${this.props.itemKey}`];
            });

            if (isIn.length === 0) {
                await this.setState(({ selectedItem }) => ({
                    selectedItem: [...selectedItem, this.state.value]
                }));

                if (this.props.initialOjectSelectedList) {
                    this.updateModelList();
                    this.setState({value: null});
                } else {
                    this.props.getInput(this.state.selectedItem);
                    this.setState({value: null});
                }

            }
        }
    }

    getModelObject(obj) {
        let model = {};

        if (obj) {
            let array = this.props.itensPropertiesCopy;
            Object.keys(obj).forEach(function (key) {

                if (array && array.includes(key)) {
                    model[key] = obj[key];
                } else {
                    model[key] = null;
                }
            });
            return model;
        }
    }

    updateModelList() {

        let list = [];
        this.state.selectedItem.forEach(element => {

            let isIn = this.state.initialSelectedItemFromObjectCopy.find(el => {
                return el[`${this.props.initialOjectSelectedListClass}`][`${this.props.itemKey}`] === element[`${this.props.itemKey}`];
            });

            if (isIn) {
                list.push(isIn);
            } else if(this.props.initialOjectSelectedList.length > 0){
                let obj = this.getModelObject(this.props.initialOjectSelectedList[0]);
                obj[`${this.props.initialOjectSelectedListClass}`] = element;
                list.push(obj);
            }else {
                let obj = this.props.modelObject ? this.props.modelObject : {};
                obj[`${this.props.initialOjectSelectedListClass}`] = element;
                list.push(obj);
            }
        })
        this.setState({ selectedItemFromObject: list });
        this.props.getInput(this.state.selectedItemFromObject);
    }

    renderInput(inputProps) {
        const { InputProps, ref, ...other } = inputProps;
        return (
            <TextField
                InputProps={{
                    inputRef: ref,
                    classes: {
                    },
                    ...InputProps,
                }}
                {...other} disabled={this.props.disabled}/>
        );
    }

    get erroFlag() {
        return (this.props.error === undefined || this.props.error === '' ? 'none' : 'inline')
    }

    get warningFlag() {
        return (this.props.warning === undefined || this.props.warning === '' ? 'none' : 'inline')
    }

    updateState = async (value) => {
        if (value.selectedItem) {
            await this.setState({ value: value.selectedItem});
        }
    }
    
    render() {
        return (
            <div className={'auto-complete-many'} >
                <label>{this.props.label}</label>
                <Grid container>
                    <Grid item xs={12} className={'auto-complete-field'}>
                        <Downshift disabled={this.props.disabled} disableUnderline={true} id="downshift-options" itemToString={(item) => { return item ? item[`${this.props.itemlabel}`] : '' }} 
                        selectedItem={this.state.value} onStateChange={(value) => { this.updateState(value) }}>
                            {({
                                clearSelection,
                                getInputProps,
                                getItemProps,
                                getLabelProps,
                                getMenuProps,
                                highlightedIndex,
                                inputValue,
                                isOpen,
                                openMenu,
                                selectedItem
                            }) => {
                                const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
                                    placeholder: this.props.placeholder,
                                    onChange: event => {
                                        if (event.target.value === "") {
                                            clearSelection();
                                        }
                                        if (this.props.path) {
                                            this.getSuggestionsFromServer(event.target.value);
                                        }
                                    },
                                    onSelect: async () => {
                                        await this.setState({ value: selectedItem });
                                    },
                                    onFocus: openMenu
                                });

                                return (
                                    <div >
                                        {this.renderInput({
                                            fullWidth: true,
                                            InputLabelProps: getLabelProps({ shrink: true }),
                                            InputProps: { onBlur, onChange, onFocus },
                                            inputProps
                                        })}

                                        <div {...getMenuProps()}>
                                            {isOpen ? (
                                                <Paper square>
                                                    {this.getSuggestions(inputValue, { showEmpty: false }).map(
                                                        (suggestion, index) =>
                                                            this.renderSuggestion({
                                                                suggestion,
                                                                index,
                                                                itemProps: getItemProps({ item: suggestion }),
                                                                highlightedIndex,
                                                                selectedItem
                                                            })
                                                    )}
                                                </Paper>
                                            ) : null}
                                        </div>
                                    </div>
                                );
                            }}
                        </Downshift>
                        <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addItens} icon={'plus.svg'} disabled={this.props.disabled}/>
                    </Grid>
                    {this.state.selectedItem.length > 0 && <Grid item xs={12}>
                        <br></br>
                        <p className={'chip-label'}>{this.props.chipLabel}</p>
                        {this.renderChipList({})}
                        <span style={{ display: this.erroFlag }} className={'erro'}>{this.props.error}</span>
                        <span style={{ display: this.warningFlag }} className={'warning'}>{this.props.warning}</span>
                    </Grid>}
                </Grid>
            </div>

        )
    }
}