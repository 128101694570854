import React, { Component } from 'react'
import {Grid} from '@material-ui/core';
import './emptyList.scss'
import GenericButton from '../genericButton/genericButton';

export default class EmptyList extends Component {

    render() {
        if (this.props.type != null && this.props.type !== '') {
            const sizeIcon = this.props.sizeIcon ? this.props.sizeIcon : {};
            return(
                <Grid id={'empty-list'}>
                    <Grid id={'div-bigger'}>
                        <img style={sizeIcon} src={process.env.PUBLIC_URL + '/assets/icons/' + (this.props.icon != null ? this.props.icon + '.svg' : this.props.type + '.svg')} alt={''}/>
                        {this.props.type === 'seeResults' ?
                            <Grid className={'div-smaller'}>
                                <p className={'title'}>Para ver resultados, realize sua busca</p>
                                <p className={'description'}>Caso você queira ver todos os itens cadastrados, <br></br>clique no botão abaixo.</p>
                                <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} click={this.props.seeAll} label={'Ver Todos'}/>
                            </Grid>
                        : this.props.type === 'noResults' ?
                            <Grid className={'div-smaller'}>
                                <p className={'title'}>Nenhum resultado encontrado</p>
                                <p className={'description'}>Não foi encontrado nenhum resultado com os filtros ou termos utilizados. Digite outros termos ou filtros e tente novamente.</p>
                            </Grid>
                        : this.props.type === 'noItens' && this.props.msg != null && this.props.msg !== '' ?
                            <Grid className={'div-smaller'}>
                                <p className={'title'}>{this.props.msg}</p>
                                {this.props.subMsg != null ? <p className={'description'}>{this.props.subMsg}</p> : null}
                            </Grid>
                        :
                            <Grid className={'div-smaller'}>
                                <p className={'title'}>Ainda não há itens cadastrados</p>
                                {/* <p className={'description'}>Cadastre um novo item clicando no botão <b>"cadastrar novo"</b> e ele aparecerá aqui.</p> */}
                            </Grid>
                        }
                    </Grid>
                </Grid>
            )
        } else {
            return null;
        }
    }
}
