import React, { Component } from 'react';
import API from '../../comm/API';
import EncontrosDefault from './encontrosDefault';
import AtividadeDefaultDigital from './atividadeDefaultDigital';
import { getByKeyOrEmpty, viewDataModels } from './mentoriaUtilDigital';

export default class MentoriaTeoricaDigital extends Component {

    STATUS_FINALIZADO = 3;

    constructor(props) {
        super(props);
        this.viewData = JSON.parse(JSON.stringify(viewDataModels.etapaTeorica));
        const etapa = this.props.turma.etapaTeoricaList.find(obj => obj.etapa === this.props.index );
        const viewData = this.updateViewData(etapa);
        const disabled = this.props.turma.status === 3 || this.props.turma.atendimentoTurmaList.some(atd => atd.atendimento.relatorioFinal != null);

        this.state = {
            turma: this.props.turma,
            etapa,
            viewData,
            disabled: disabled
        }
        
    };

    updateViewData = (etapa) => {
        const {  treinamentoColetivo } = etapa;


        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Teórica Digital ' + (etapa.etapa === 3 ? 'B' : etapa.etapa === 6 ? 'C' : 'D');

        
        this.viewData.atividades.treinamentoColetivo.data = JSON.parse(JSON.stringify(treinamentoColetivo || {}));
        this.viewData.atividades.treinamentoColetivo.anexos.data = getByKeyOrEmpty(treinamentoColetivo, 'anexoAtividadeList');

        this.viewData.encontros.data = JSON.parse(JSON.stringify(etapa.encontroList));
        return this.viewData;
    };
    
    handleFinishMentoria = async (viewData) => {
        const isValid = this.validaEncontros(viewData);
        if (!isValid) {
            this.props.showToast('Há informações/atividades pendentes nas visitas dessa etapa.', 'error');
            return;
        }
        const turma = this.props.turma;
        turma.etapaTeoricaList = turma.etapaTeoricaList.map(etapaTeorica => {
            if (etapaTeorica.etapa === turma.etapaAtual) etapaTeorica.finalizada = true;
            return etapaTeorica
        });
        turma.etapaAtual += 1;
        try {
            await this.props.saveTurma(turma);
            this.props.showToast('Etapa finalizada com sucesso!', 'success');
            this.props.goBack();
        } catch(error) {
            this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
        }
    };

    validaEncontros = (viewData) => {
        let treinamentoColetivo = viewData.atividades.treinamentoColetivo.data;
        const encontroList = viewData.encontros.data;
        return encontroList && encontroList.length > 0 && encontroList.every(visita => { return this.validaVisita(visita) }) && treinamentoColetivo && treinamentoColetivo.status == this.STATUS_FINALIZADO;            
    };

    filterImagens = (anexo) =>{
        return anexo.descricao != 'lista de presença' && anexo.descricao != 'outros'
    }

    validaVisita = (visita) => {
        return visita && visita.anexoEncontroList && visita.anexoEncontroList.length > 0 && visita.anexoEncontroList.some(anx => { return anx.descricao === 'lista de presença' })
        && visita.data && visita.turno != null  && visita.idMentor != null && visita.horasApropriadas > 0         && visita.isVirtual != null;
    };

    validateAnexos = (atividade) => {
        return atividade && atividade.anexoAtividadeList && atividade.anexoAtividadeList.length > 0 && atividade.anexoAtividadeList.every(elm => { return elm.descricao });
    };

    validaAtividade = async (viewData, etapa, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = etapa[nomeAtividade] ? etapa[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo && nomeAtividade === 'leanGame' && etapa.etapa === 7) atividade[nomeAnexo] = [];
        else if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        console.log(atividade);
        let validate = funcaoValidacaoAtividade(atividade);
        if (!validate) {
            this.props.showToast(nomeAnexo && atividade[nomeAnexo].length === 0 ? 'É necessário anexar pelo menos uma evidência antes de salvar.' : 'Há informações pendentes nessa atividade.', 'error');
            throw new Error();
        } else {
            atividade.status = status;
        }
        
        etapa[nomeAtividade] = atividade;
    };


    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        
        let turma = this.props.turma;
        let visitasData = viewData.encontros.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;

            if (currScreenAtividade === 'treinamentoColetivo') 
                await this.validaAtividade(viewData, etapa, currScreenAtividade, 'treinamentoColetivo', 'anexoAtividadeList', this.validateAnexos, this.STATUS_FINALIZADO);

            if (selectedVisita) {
                selectedVisita.anexoEncontroList = [];
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaVisita(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = visitasData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                etapa.encontroList[index] = selectedVisita;
                if (index > -1) etapa.encontroList[index] = selectedVisita;
                else etapa.encontroList.push(selectedVisita);
            };
            etapa.horasApropriadas = etapa.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0); 
            etapa.atividades = [];
            etapa.atividades = etapa.atividades.concat(etapa.treinamentoColetivo != null ? etapa.treinamentoColetivo : []);
        }
        else {
            etapa.atividades = [];
            let atividades = viewData.atividades;
            let treinamentoColetivo = atividades.treinamentoColetivo;
            if (Object.keys(treinamentoColetivo.data).length == 0) etapa.treinamentoColetivo = null;
            etapa.encontroList = visitasData;
            etapa.horasApropriadas = etapa.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        let etapaIndex = turma.etapaTeoricaList.findIndex(obj => { return obj.etapa === this.props.index });
        turma.etapaTeoricaList[etapaIndex] = etapa; 
        if(etapa.horasApropriadas > 4){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else{
            return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(res => {
                    turma = res.data;
                    etapa = turma.etapaTeoricaList.find(obj => obj.etapa === this.props.index );
                    this.setState({ turma, etapa, viewData: this.updateViewData(etapa) });
                    this.props.showToast('Etapa editada com sucesso!', 'success');
                }).catch(error => {
                    this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                    throw new Error(error);
                });
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }
        
    };
    
    /**
     * Function para renderizar a tela de atividade
     * com seus anexos, a ser passado através de props
     * @memberof MentoriaTeorica
     * @param selectedAtividade Atividade selecionada 
     *                          ( contém os anexos e demais outros dados )
     * @param reloadAtividade   método utilizado para atualizar realizar um 
     *                          reload na tela da atividade atual em visitasDefault
     */
    renderAtividade = (selectedAtividade, reloadAtividade) => {
        return (
            <AtividadeDefaultDigital
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                border = {false}
                showEmptyEvidencias={ true }
                showTopButtons={ true && !this.state.disabled}
                showAnexos={ true }
                disabled={this.state.disabled}
                viewData={ this.state.viewData } />
        );
    };

    render() {
        return (
            <EncontrosDefault
                turma={ this.state.turma }
                fase={ this.props.fase }
                title= { 'Mentoria Teórica' }
                etapa = {this.state.etapa}
                goBack={ this.props.goBack }
                closeLoading={ this.props.closeLoading }
                showLoading={ this.props.showLoading }
                showToast={ this.props.showToast }
                viewData={ this.state.viewData }
                handleFinishMentoria={ this.handleFinishMentoria }
                saveEtapa={ this.saveEtapa }
                renderAtividade={ this.renderAtividade }
                disabled={this.state.disabled}
                dontShowFinalizarMentoria={ this.state.etapa.finalizada }
                mentoresDados={this.props.mentoresDados}
            />
        )
    };
};