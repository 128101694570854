import React, { Component } from 'react';
import { Grid, Switch } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import solidGauge from "highcharts/modules/solid-gauge.js";
import HighchartsReact from 'highcharts-react-official';

highchartsMore(Highcharts);
solidGauge(Highcharts);


export default class GraficoMetas extends Component {

    constructor(props) {
		super(props);
		this.state = {
			isAnual: false,
		}
	};

    printIcon = (e) => {
		let renderer = e.target.renderer;
		let series = e.target.series;
		const chartWidth = e.target.chartWidth;
		const plotHeight = e.target.plotHeight;
		series.forEach((serie) => {
			if (!serie.icon) {
				serie.icon = renderer
					.path(["M", -6, 0, "L", 4, 0, "M", -1, -6, "L", 4, 0, -1, 6])
					.attr({ stroke: "#ffffff", "stroke-width": 2, zIndex: 10})
					.add(serie.group);
			}
			serie.icon.translate(
				chartWidth / 2 - 10, plotHeight / 2 - serie.points[0].shapeArgs.innerR - 
				(serie.points[0].shapeArgs.r - serie.points[0].shapeArgs.innerR) / 2
			);
		});
	};

	printCenter = (e) => {
        const chart = e.target
        let newX = chart.plotWidth / 2 + chart.plotLeft;
        let newY = chart.plotHeight / 2 + chart.plotTop;
        chart.renderer.text('0', newX, newY)
        .attr({
            align: 'center'
        })
		.css({
			font: 'bold 35px/56px Raleway',
			color: '#707070',
        }).add();

        chart.renderer.text(`${'Meta não definida'}`, newX, newY + 20)
        .attr({
            align: 'center'
        })
		.css({
			font: '15px Raleway',
			color: '#707070',
		}).add();
    };

	changeIsAnual = (e) => {
		this.setState({ isAnual: e.target.checked });
	};
    
    create = () => {
		return {
			chart: {
				type: "solidgauge",
				events: {
					render: this.printIcon,
					load: this.printCenter,
				},
				height: !this.props.isDR ? 350 : 410,
				width: 350,
				backgroundColor: "#F2F1F6",
			},		
			tooltip: {
				outside: true,
				borderWidth: 1,
				borderColor: "#B4B8C0",
				shadow: false,
				valueSuffix: "%",
				pointFormat:
					'<p style="font: bold 13px Raleway; color: #707070">{series.name}</p><br>' +
					'<p style="font: 13px Raleway; color: #707070">{point.x} - {point.y}</p>',
				followPointer: true,
				backgroundColor: "rgba(255,255,255)"
			},
			pane: {
				size: 250,
				startAngle: 0,
				endAngle: 360,
				background: [
					{
						outerRadius: "112%",
						innerRadius: "88%",
						backgroundColor: Highcharts.Color("#C2E4F3").get(),
						borderWidth: 0
					},
					{
						outerRadius: "88%",
						innerRadius: "63%",
						backgroundColor: Highcharts.color(Highcharts.getOptions().colors[2]).setOpacity(0.2).get(),
						borderWidth: 0
					}
				]
			},
		
			yAxis: {
				min: 0,
				max: 100,
				lineWidth: 0,
				tickPositions: []
			},
		
			plotOptions: {
				solidgauge: {
					dataLabels: {
						enabled: false
					},
					linecap: "round",
					stickyTracking: false,
					rounded: true
				}
			},
		
			series: [
				{
					name: "Atendimentos concluídos",
					type: "solidgauge",
					data: [
						{
							color: "#00B1E8",
							radius: "112%",
							innerRadius: "88%",
							y: 0,
							x: 0
						}
					]
				},
				{
					name: "Atendimentos aceitos",
					type: "solidgauge",
					data: [
						{
							color: "#B7EE8E",
							radius: "88%",
							innerRadius: "63%",
							y: 0,
							x: 0
						}
					]
				}
			],
		
			credits: {
				enabled: false
			},

			exporting: false
		};
	};
	
	render() {
 
        return (
            <Grid container item justify={'center'} className={'etapa grafico-meta'}>
                <Grid item xs={12}>
                	<h1 className={'mapa-do-brasil-title'}>Empresas atendidas em <label style={{ fontWeight: 'bold' }}>{!this.state.isAnual ? new Date().getFullYear() : 'todo o Brasil Mais'}</label></h1>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                    	<HighchartsReact 
                        	highcharts={Highcharts}
                        	options={this.create()} />
                    </div>
                </Grid>
                <Grid item xs={12} className={'box-bottom-grafico-meta'}>
                    <Grid container spacing={2} justify={'center'}>
                        <Grid item> <label> Anual </label> </Grid>
                        <Grid item style={{ padding: 5}}>
                            <Switch disabled={false} checked={this.state.isAnual} onChange={this.changeIsAnual} color="default"/> 
                        </Grid>
                        <Grid item> <label> Total do programa </label> </Grid>
                    </Grid>
                    <Grid container spacing={1} justify={'center'} style={{marginTop: '3%'}}>
                        <Grid item className={'label-meta'}> <label> meta: </label> </Grid>
                        <Grid item > <label> {`${0}`} empresas </label> </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

}