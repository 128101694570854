import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import { Grid } from "@material-ui/core";
import API from '../../comm/API'
import SimpleModal from './../../components/modal/modal'
import InputSelect from '../../components/inputselect/inputselect';
import './atendimento.scss'

export default class Producao extends Component {

    atendimento = this.props.atendimento;
    totalQuantidadeApropriadas = 0;
    totalQuantidadeRelatorio = 0;
    producaoPorUnidade = [];
    totalProducaoPorUnidade = {
        totalForecasts: 0,
        totalProductions: 0,
    };
    historicoProducao = [];
    historicoProducaoGrouped = [];
    historicoProducaoMetrologiaGrouped = [];
    firstMonth = '';
    firstYear = '';
    lastMonth = '';
    lastYear = '';
    error = undefined;
    anoList = [];

    mesList = [
        { value: 0, label: "Jan" },
        { value: 1, label: "Fev" },
        { value: 2, label: "Mar" },
        { value: 3, label: "Abr" },
        { value: 4, label: "Mai" },
        { value: 5, label: "Jun" },
        { value: 6, label: "Jul" },
        { value: 7, label: "Ago" },
        { value: 8, label: "Set" },
        { value: 9, label: "Out" },
        { value: 10, label: "Nov" },
        { value: 11, label: "Dez" }
    ]

    constructor(props) {
        super(props);
        this.state = {
            ableToSearch: false,
        }
        this.getAnoList();
    }
    getInitialDatePeriod = () => {

        const dataConclusao = this.props.atendimento.dataConclusao ? new Date(this.props.atendimento.dataConclusao) : new Date();

        if (this.props.atendimento.dataEmissao) {
            this.firstMonth = new Date(this.props.atendimento.dataEmissao).getUTCMonth();
            this.firstYear = new Date(this.props.atendimento.dataEmissao).getUTCFullYear();
            this.lastMonth = dataConclusao.getUTCMonth();
            this.lastYear = dataConclusao.getUTCFullYear();

            this.setState(this.state);
        }
    }
    componentDidMount() {
        this.getTotalProducao();
        this.getInitialDatePeriod();
    };
    getAnoList = () => {
        for (var i = new Date().getFullYear(); i >= 2010; i--) {
            this.anoList.push({ value: i });
        }
    }
    mergeTotalProductionWithForecast = (productions, forecasts) => {
        return productions.map(production => {
            
            let isProductionInForecast = forecasts.find(forecast => forecast.unidade.id === production[0]);

            if (!isProductionInForecast && (this.props.isAtendimentoLEAN || this.props.isAtendimentoBrasilMaisLEAN)) {
                isProductionInForecast = {
                    numeroDeProducaoEstimada: this.props.atendimento.numeroDeProducaoEstimada
                }
            };

            if (!isProductionInForecast && (!this.props.isAtendimentoLEAN || !this.props.isAtendimentoBrasilMaisLEAN)) {
                isProductionInForecast = {
                    numeroDeProducaoEstimada: this.props.atendimento.numeroDeProducaoEstimada
                }
            };
            
            if (isProductionInForecast) {
                const productionFormatted = {
                    partners: production[1],
                    properProduction: production[2],
                }
    
                return Object.assign({}, productionFormatted, isProductionInForecast);
            } 

            return undefined;
        }).filter(filtered => filtered !== undefined);
    }
    calculateTotalHours = (productionsAndForecasts) => {
        return productionsAndForecasts.reduce((prev, current) => {

            return {
                totalForecasts: prev.totalForecasts + current.numeroDeProducaoEstimada,
                totalProductions: prev.totalProductions + current.properProduction,
            }

        }, {
            totalForecasts: 0,
            totalProductions: 0,
        })
    }
    getTotalProducao = () => {
        var filter = {};
        filter["atendimento.id"] = this.props.atendimento.id;

        if (this.props.isMetrologia) {
            API.get('/rest/gestao/ProducaoApropriada/sum/quantidadeRelatorio', {
                params: filter
            }).then(res => {
                this.totalQuantidadeRelatorio = res.data.sum;
                API.get('/rest/gestao/ProducaoApropriada/sum/quantidadeApropriadas', {
                    params: filter
                }).then(res => {
                    this.totalQuantidadeApropriadas = res.data.sum;
                    this.setState(this.state);
                })
            })
        } else {
            API.get('/rest/gestao/ProducaoApropriada/sumByUnidade/' + this.props.atendimento.id).then(res => {
                
                this.producaoPorUnidade = this.mergeTotalProductionWithForecast(res.data, this.props.atendimento.previsaoProducaoEmRedeList);

                this.totalProducaoPorUnidade = this.calculateTotalHours(this.producaoPorUnidade);

                this.setState(this.state);
            });
        }
    }
    setStateError = (errorMessage) => {
        this.error = errorMessage;
        this.setState(this.state);
    }
    checkIfIsAbleToSearch = () => {
        if (this.props.hasValue(this.firstMonth) && this.props.hasValue(this.firstYear) && this.props.hasValue(this.lastMonth) && this.props.hasValue(this.lastYear)) {
            return this.setState({ ableToSearch: true });
        }

        return this.setState({ ableToSearch: false });
    }
    checkIfItIsAValidDate = () => {
        if (this.props.atendimento.dataEmissao) {
            const dataInicio = new Date(this.props.atendimento.dataEmissao);
            const dataFinal = this.props.atendimento.dataConclusao ? new Date(this.props.atendimento.dataConclusao) : new Date();


            if (this.firstYear > this.lastYear) {
                this.setStateError('A data inicial deve ser menor do que a data final');
                return false;
            }

            if (this.firstYear === this.lastYear && this.firstMonth > this.lastMonth) {
                this.setStateError('A data inicial deve ser menor do que a data final');
                return false;
            }


            if (this.firstYear < dataInicio.getUTCFullYear()) {
                this.setStateError('O ano inicial deve ser maior do que o ano de criação do atendimento');
                return false;
            }

            if (this.firstYear === dataInicio.getUTCFullYear() && this.firstMonth < dataInicio.getUTCMonth()) {
                this.setStateError('A data inicial deve ser maior do que a data de criação do atendimento');
                return false;
            }

            if (this.lastYear > dataFinal.getUTCFullYear()) {
                this.setStateError('O ano final deve ser menor do que o ano de conclusão do atendimento');
                return false;
            }

            if (this.lastYear === dataFinal.getUTCFullYear() && this.lastMonth > dataFinal.getUTCMonth()) {
                this.setStateError('A data final deve ser menor do que a data de conclusão do atendimento');
                return false;
            }

            this.setStateError(null);
            return true;
        }

        return true;
    }

    returnUserOrCollaborator(obj) {
        if (obj.usuario && obj.usuario.nome) return obj.usuario;
        if (obj.colaborador && obj.colaborador.nome) return obj.colaborador;

        return {};
    }

    groupMetrologia = (historicoProducao) => {
        const uniqueColaboradores = [...new Set(historicoProducao.map(historico => this.returnUserOrCollaborator(historico).nome ))];

        const historicoMetrologiaGrouped = [];

        const isLote = historicoProducao
            .filter(historico => historico.isLote)
            .reduce((prev, current) => {
                return {
                    colaborador: {nome: 'APROPRIAÇÃO EM LOTE'},
                    quantidadeApropriadas: prev.quantidadeApropriadas + current.quantidadeApropriadas,
                    quantidadeRelatorio: prev.quantidadeRelatorio + current.quantidadeRelatorio, 
                }
            }, {
                colaborador: {nome: ''},
                quantidadeApropriadas: 0,
                quantidadeRelatorio: 0,
            });
        
        if (isLote && this.returnUserOrCollaborator(isLote).nome) {
            historicoMetrologiaGrouped.unshift(isLote);
        }

        uniqueColaboradores.forEach(nome => {
            if (nome !== undefined) {
                const sumProduction = historicoProducao
                    .filter(production => this.returnUserOrCollaborator(production).nome && this.returnUserOrCollaborator(production).nome === nome && !production.isLote)
                    .reduce((prev, current) => {
                        return {
                            colaborador: {nome: this.returnUserOrCollaborator(current).nome},
                            quantidadeApropriadas: prev.quantidadeApropriadas + current.quantidadeApropriadas,
                            quantidadeRelatorio: prev.quantidadeRelatorio + current.quantidadeRelatorio, 
                        }
                    }, {
                        colaborador: {nome: ''},
                        quantidadeApropriadas: 0,
                        quantidadeRelatorio: 0,
                    });
                
                if (sumProduction && this.returnUserOrCollaborator(sumProduction).nome) historicoMetrologiaGrouped.push(sumProduction);
            }
        });

        return historicoMetrologiaGrouped;
    }
    groupUnits = (historicoProducao) => {
        const uniqueUnits = [...new Set(historicoProducao.map(historico =>  historico.unidadeAbreviacao ))];
        let productionsByUnits = [];


        for (let unit of uniqueUnits) {
            const unitsFiltered = historicoProducao.filter(historico => historico.unidadeAbreviacao === unit);

            let productionSum = unitsFiltered;

            if (unitsFiltered) {
                productionSum = [];

                const uniqueColaboradores = [...new Set(unitsFiltered.map(historico =>  this.returnUserOrCollaborator(historico).nome ))];
                
                uniqueColaboradores.forEach(nome => {
                    if (nome !== undefined) {
                        const sumProduction = unitsFiltered
                            .filter(production => this.returnUserOrCollaborator(production).nome && this.returnUserOrCollaborator(production).nome === nome && !production.isLote)
                            .reduce((prev, current) => {
                                return {
                                    colaborador: {nome: this.returnUserOrCollaborator(current).nome},
                                    quantidadeApropriadas: prev.quantidadeApropriadas + current.quantidadeApropriadas 
                                }
                            }, {
                                colaborador: {nome: ''},
                                quantidadeApropriadas: 0,
                            });
                        productionSum.push(sumProduction);
                    }
                });
            }
            const isLote = unitsFiltered
                .filter(historico => historico.isLote)
                .reduce((prev, current) => {
                    return {
                        colaborador: {nome: 'APROPRIAÇÃO EM LOTE'},
                        quantidadeApropriadas: prev.quantidadeApropriadas + current.quantidadeApropriadas 
                    }
                }, {
                    colaborador: {nome: ''},
                    quantidadeApropriadas: 0,
                });

            if (isLote && this.returnUserOrCollaborator(isLote).nome) {
                productionSum.unshift(isLote);
            }

            const totalProductionByUnit = productionSum.reduce((prev, current) => prev + current.quantidadeApropriadas, 0);

            productionsByUnits.push({ productionSum, unit, totalProductionByUnit });
        }

        return productionsByUnits;
    }
    getHistoricoProducao = () => {
        if (!this.props.modalProducao) {
            this.props.changeModal('modalProducao', true);
        }
        if (
            this.props.hasValue(this.firstMonth) 
            && this.props.hasValue(this.firstYear) 
            && this.props.hasValue(this.lastMonth) 
            && this.props.hasValue(this.lastYear)
            && this.checkIfItIsAValidDate()
        ) {

            this.props.showLoading();
            let firstDay = new Date();
            let lastDay = new Date();
            firstDay.setDate(1);
            firstDay.setMonth(this.firstMonth);
            firstDay.setFullYear(this.firstYear);
            lastDay.setDate(1);
            lastDay.setMonth(this.lastMonth === 11 ? 0 : this.lastMonth + 1);
            lastDay.setFullYear(this.lastMonth === 11 ? this.lastYear + 1 : this.lastYear);
            lastDay.setDate(lastDay.getDate() - 1);

            var filter = {};
            filter["atendimento.id"] = this.props.atendimento.id;
            filter['dataDeApropriacao'] = "between '" + this.props.formatData(firstDay) + " 00:00:00' and '" + this.props.formatData(lastDay) + " 23:59:59' ";

            API.get('/rest/gestao/ProducaoApropriada/historicoModal', {
                params: filter
            }).then(res => {
                if (this.props.isMetrologia) {
                    this.historicoProducaoMetrologiaGrouped = this.groupMetrologia(res.data);
                } else {
                    this.historicoProducaoGrouped = this.groupUnits(res.data);
                }

                this.setState(this.state);
                this.props.closeLoading();
            }).catch(error => {
                this.props.closeLoading();
            });
        }
    }
    changeFirstYear = (e) => {
        this.firstYear = e.value;
        this.setState(this.state);
        this.checkIfIsAbleToSearch();
    }
    changeFirstMonth = (e) => {
        this.firstMonth = e.value;
        this.setState(this.state);
        this.checkIfIsAbleToSearch();
    }
    changeLastYear = (e) => {
        this.lastYear = e.value;
        this.setState(this.state);
        this.checkIfIsAbleToSearch();
    }
    changeLastMonth = (e) => {
        this.lastMonth = e.value;
        this.setState(this.state);
        this.checkIfIsAbleToSearch();
    }
 
    render() {
        return (
            <Grid item xs={12} md={6}>
                <div className={'greyDiv'}>
                    <GenericButton color={'darkBlue'} subClass={'basic-button'}
                        click={() => this.getHistoricoProducao()} label={'Ver detalhes'} />
                    <h2>Produção</h2>
                    {this.props.isMetrologia ?
                        <Grid container>
                            <Grid item xs={8}><b>Ensaios</b></Grid>
                            <Grid item xs={4}>{this.totalQuantidadeApropriadas}</Grid>
                            <Grid item xs={8}><b>Relatórios</b></Grid>
                            <Grid item xs={4}>{this.totalQuantidadeRelatorio}</Grid>
                        </Grid>
                        :
                        this.producaoPorUnidade.length === 0 ?
                            <Grid container>
                                <Grid item xs={8} className={'paddingForm'}><b>Total</b></Grid>
                                <Grid item xs={4}>0h</Grid>
                            </Grid>
                            :
                            this.producaoPorUnidade.map((producao, index) => (
                                <Grid container key={index}>
                                    <Grid item xs={8} className={'paddingForm'}><b>{producao.partners}</b></Grid>
                                    <Grid item xs={4}>{producao.properProduction}h</Grid>
                                </Grid>
                            ))
                    }
                </div>
                {this.props.modalProducao ?
                    <SimpleModal
                        isOpen={this.props.modalProducao}
                        // TODO: Resetar states quando fechar o modal
                        handleClose={() => this.props.changeModal('modalProducao', false)}
                        type={'info'}
                        width={'750px'}>
                        <div className={'modal modal-historico-producao'}>
                            <h2>Histórico de produção</h2>

                            <Grid container className="container-historico-producao">
                                {!!this.producaoPorUnidade.length && !this.props.isMetrologia && (
                                    <>
                                        <Grid item xs={6}>
                                            <p>
                                                <strong>Parceiros</strong>
                                            </p>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <p>
                                                <strong>Produção prevista</strong>
                                            </p>
                                        </Grid>

                                        <Grid item xs={3}>
                                            <p>
                                                <strong>Produção apropriada</strong>
                                            </p>
                                        </Grid>

                                        {this.producaoPorUnidade.map((producao, index) => (
                                            <Grid key={index} container className="line-historico-producao" style={{ marginBottom: index === this.producaoPorUnidade.length - 1 ? 0 : 12 }}>
                                                <Grid item xs={6}>
                                                    <span>
                                                        {producao.partners}
                                                    </span>
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <span>
                                                    {producao.numeroDeProducaoEstimada}h
                                                    </span>
                                                </Grid>

                                                <Grid item xs={3}>
                                                    <span>
                                                        {producao.properProduction}h
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        ))}
                                        <Grid container >
                                            <Grid item xs={6}>
                                                <p>
                                                    <strong>TOTAL</strong>
                                                </p>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <p>
                                                    <strong>{this.totalProducaoPorUnidade.totalForecasts}h</strong>
                                                </p>
                                            </Grid>

                                            <Grid item xs={3}>
                                                <p>
                                                    <strong>{this.totalProducaoPorUnidade.totalProductions}h</strong>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </>
                                )}
                            </Grid>

                            <Grid container>
                                <Grid container justify="center" style={{ marginTop: 24 }}>
                                    {!this.props.isMetrologia && (
                                        <p className={'font-blue'}>Histórico de produção</p>
                                    )}
                                </Grid>

                                <Grid item xs={12}>
                                    <p>
                                        <strong>
                                            Escolha o período
                                        </strong>
                                    </p>
                                </Grid>

                                <Grid item xs={5}>
                                    <label className={'font-blue'}>De</label>
                                </Grid>

                                <Grid item xs={7}>
                                    <label className={'font-blue'}>Para</label>
                                </Grid>

                                <Grid container alignItems="center"  style={{ height: 34 }}>
                                    <Grid item xs={2}>
                                        <InputSelect
                                            suggestions={this.mesList}
                                            itemKey={'value'}
                                            itemLabel={'label'}
                                            id={'firstMonth'}
                                            getInput={this.changeFirstMonth}
                                            initialSelectedItem={{ value: this.firstMonth }}
                                        />
                                    </Grid>

                                    <Grid item xs={2}>
                                        <InputSelect
                                            suggestions={this.anoList}
                                            itemKey={'value'}
                                            itemLabel={'value'}
                                            id={'firstYear'}
                                            getInput={this.changeFirstYear}
                                            initialSelectedItem={{ value: this.firstYear }}
                                        />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <img src={process.env.PUBLIC_URL + '/assets/icons/arrowRight.svg'} alt='arrowIcon' style={{ padding: '0 16px' }} />
                                    </Grid>

                                    <Grid item xs={2}>      
                                        <InputSelect
                                            suggestions={this.mesList}
                                            itemKey={'value'}
                                            itemLabel={'label'}
                                            id={'lastMonth'}
                                            getInput={this.changeLastMonth}
                                            initialSelectedItem={{ value: this.lastMonth }}
                                        />
                                    </Grid>

                                    <Grid item xs={2}>      
                                        <InputSelect
                                            suggestions={this.anoList}
                                            itemKey={'value'}
                                            itemLabel={'value'}
                                            id={'lastYear'}
                                            getInput={this.changeLastYear}
                                            initialSelectedItem={{ value: this.lastYear }}
                                        />
                                    
                                    </Grid>

                                    <Grid item xs={2} style={{ marginLeft: 8 }}>      
                                        <GenericButton 
                                            color={'darkBlue'} 
                                            subClass={'basic-button'}
                                            label={'Buscar'}
                                            click={this.getHistoricoProducao} 
                                            disabled={!this.state.ableToSearch}
                                        />
                                    
                                    </Grid>

                                </Grid>

                                {this.error && (
                                    <Grid item xs={12} className="erro-modal-historico-producao">
                                        <small>{this.error}</small>
                                    </Grid>
                                )}
                                
                                <Grid item xs={12}>
                                    {(this.props.isMetrologia && this.historicoProducaoMetrologiaGrouped.length === 0) || (!this.props.isMetrologia && this.historicoProducaoGrouped.length === 0) ?
                                        <p>
                                            {
                                                (this.props.hasValue(this.firstMonth) && this.props.hasValue(this.firstYear) && this.props.hasValue(this.lastMonth) && this.props.hasValue(this.lastYear)) ?
                                                    'Não há registros nesse período de tempo.' : 'Selecione um intervalo de tempo.'
                                            }
                                        </p>
                                        :
                                        this.props.isMetrologia ?
                                            <Grid
                                                style={{ marginTop: 24 }}
                                            >
                                                {this.historicoProducaoMetrologiaGrouped.map((producao, index) => (
                                                    <Grid 
                                                        container 
                                                        key={index} 
                                                        className={'colaborador'}
                                                    >
                                                        <Grid item xs={10}>{producao.colaborador.nome}</Grid>
                                                        <Grid item xs={2} className={'horas'}>
                                                            {producao.quantidadeApropriadas} Ensaios<br></br>
                                                            {producao.quantidadeRelatorio} Relatórios
                                                        </Grid>
                                                        
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        :
                                            this.historicoProducaoGrouped.map((producao, index) => (
                                                <Grid container key={index} className={'colaborador'} style={{ marginTop: 40 }}>

                                                    <Grid item xs={10} >
                                                        <p>
                                                            <strong>
                                                                {producao.unit}
                                                            </strong>
                                                        </p>
                                                    </Grid>

                                                    <Grid item xs={2} >
                                                        <p>
                                                            <strong>
                                                                {producao.totalProductionByUnit}h
                                                            </strong>
                                                        </p>

                                                    </Grid>

                                                    {producao.productionSum.map((productionFiltered, index) => (
                                                        <Grid container key={index} className="line-historico-producao">
                                                            <Grid item xs={10}>{productionFiltered.colaborador.nome}</Grid>
                                                            <Grid item xs={2}>{productionFiltered.quantidadeApropriadas}h</Grid>
                                                        </Grid>
                                                    ))}

                                                </Grid>
                                            ))
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </SimpleModal> : null}
            </Grid>
        )
    }
}