import React, { Component } from 'react';
import { Grid,Tooltip } from '@material-ui/core';
import { withStyles} from "@material-ui/core/styles";
import './dashboardCard.scss';

export default class DashboardCard extends Component {

    render() {
        let icon = (
            this.props.icon ? <Grid item>
                <img className={'icon-turma'} src={`./../../assets/icons/${this.props.icon}.svg`} alt=''/>
            </Grid> : null
        )
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway',
                maxWidth: '500px'
            }
          })(Tooltip);

        
        return (
            this.props.tooltip ?
                <Grid container 
                direction={'column'} 
                justify={"flex-start"}
                alignItems={"center"}
                className={`${this.props.color}`}>
                    <Grid container item xs={2}
                    justify={"flex-end"}
                    alignItems={"center"} 
                    style={{maxWidth:"100%" ,width:"100%"}}>
                        <div style={{marginRight:"12px"}}>
                            <ModifiedTooltip 
                                title={this.props.tooltip}
                                placement={this.props.tooltipPlacement ? this.props.tooltipPlacement : "right"} arrow>
                                    {this.props.tooltipLight ? 
                                    <img style={{height: 22, width: 22}} src={process.env.PUBLIC_URL +  '/assets/icons/info-dash-white.svg' }                                                             
                                    alt={'icon'} /> :
                                    <img style={{height: 22, width: 22}} src={process.env.PUBLIC_URL +  '/assets/icons/info-dash-gray.svg'}                                                             
                                    alt={'icon'} />}
                            </ModifiedTooltip>
                        </div>                        
                    </Grid>
                    <Grid item xs={8} style={{maxWidth:"100%" ,width:"100%"}}>
                        {this.props.id !== 'invertido' 
                        ?
                        <Grid container direction={'column'} justify={'center'} style={{maxHeight:"100%" ,height:"100%"}}>
                            {icon}
                            <Grid item className={'font-text'}>{this.props.text}</Grid>
                            {this.props.text === 'Setor que mais utiliza' || this.props.setor
                            ?                   
                            <Grid item style={{fontSize: '21px'}} className={'font-number'}>{this.props.value}</Grid>
                            :
                            <Grid item className={'font-number'}>{this.props.value}</Grid>
                            }
                        </Grid> 
                        :
                        <Grid container direction={'column'} justify={'center'} style={{maxHeight:"100%" ,height:"100%"}}>
                            {icon}
                            <Grid item className={'font-number'}>{this.props.value}</Grid>
                            <Grid item className={'font-text'}>{this.props.text}</Grid>
                        </Grid>}
                    </Grid>
                    <Grid item xs={2}>
                    </Grid>
                </Grid>                
                :
                this.props.id !== 'invertido' 
                ?
                <Grid container direction={'column'} justify={'center'} className={`${this.props.color}`}>
                    {icon}
                    <Grid item className={'font-text'}>{this.props.text}</Grid>
                    {this.props.text === 'Setor que mais utiliza' || this.props.setor
                    ?                   
                      <Grid item style={{fontSize: '21px'}} className={'font-number'}>{this.props.value}</Grid>
                    :
                    <Grid item className={'font-number'}>{this.props.value}</Grid>
                    }
                </Grid> 
                :
                <Grid container direction={'column'} justify={'center'} className={`${this.props.color}`}>
                    {icon}
                    <Grid item className={'font-number'}>{this.props.value}</Grid>
                    <Grid item className={'font-text'}>{this.props.text}</Grid>
                </Grid> 
        )
    }
}

