import React, { Component } from 'react'

import './genericButton.scss'

export default class GenericButton extends Component {

    get subClass() {
        return (this.props.subClass !== undefined ? this.props.subClass : '');
    }

    get haveImage() {
        return (this.props.icon == null || this.props.icon === '' ? 'none' : 'inline');
    }

    render() {
        return (!this.props.inverseOrder ? (
            <button id={this.props.id} className={`${this.props.color} ${this.subClass}`} onClick={this.props.click} disabled={this.props.disabled} title={this.props.title ? this.props.title : ''}>
                {this.props.label}
                {this.props.icon ?
                    <img style={{ display: this.haveImage }}
                        src={process.env.PUBLIC_URL + '/assets/icons/' + this.props.icon}
                        width={this.props.iconWidth}
                        height={this.props.iconHeight}
                        alt={this.props.label} /> : null
                }
            </button>
        ) :
            (<button id={this.props.id} className={`${this.props.color} ${this.subClass}`} onClick={this.props.click} disabled={this.props.disabled} title={this.props.title ? this.props.title : ''}>
                {this.props.icon ?
                    <img style={{ display: this.haveImage }}
                        src={process.env.PUBLIC_URL + '/assets/icons/' + this.props.icon}
                        width={this.props.iconWidth}
                        height={this.props.iconHeight}
                        alt={this.props.label} /> : null
                }
                &ensp;{this.props.label}
            </button>)
        )
    }
}

