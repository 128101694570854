import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import GenericButton from '../../components/genericButton/genericButton';
import './cardVisita.scss';

export default class CardVisita extends Component {

    descricaoList = {
        REPORT: 'relatório de visita',
        PRESENCE_LIST: 'lista de presença',
        ATD_EVALUATION: 'avaliação do atendimento',
        OTHERS: 'outros',
    };

    render() {
        let visita = this.props.visita;
        let etapaFinalizada = this.props.etapaFinalizada;
        let atendimento = this.props.atendimento;
        visita.relatorios = [];
        visita.listaPresenca = [];
        visita.outrosDocumentos = [];
        visita.avaliacaoAtendimento = [];
        if (visita.anexoVisitaList != null) {
            visita.anexoVisitaList.forEach(anexo => {
                if (anexo.descricao === this.descricaoList.REPORT) visita.relatorios.push(anexo);
                else if (anexo.descricao === this.descricaoList.PRESENCE_LIST) visita.listaPresenca.push(anexo);
                else if (anexo.descricao === this.descricaoList.OTHERS) visita.outrosDocumentos.push(anexo);
                else if (anexo.descricao === this.descricaoList.ATD_EVALUATION) visita.avaliacaoAtendimento.push(anexo);
            });
        }
        let isConsultoriaD = this.props.etapaTurma === 'Consultoria' && this.props.faseTurma === 'D';
        let iconBaseRule =  visita.listaPresenca.length > 0;
        let iconRule = isConsultoriaD ? (iconBaseRule && visita.relatorios.length > 0 ? 'info_azul_bold.svg' : 'info_laranja_bold.svg')
            : (iconBaseRule ? 'info_azul_bold.svg' : 'info_laranja_bold.svg');
        return (
            <Grid item xs={12} md={6} lg={4} xl={3}>
                <Grid container>
                    <Grid className={'cardVisita'} item xs={11} onClick={this.props.onClick}>
                        <Grid container className={'headerVisita'}>
                            <Grid item xs={6} className={'titleVisita'}>Encontro {visita.ordem > 10 ? visita.ordem : '0' + visita.ordem}</Grid>
                            <Grid item xs={6} className={'dataVisita'}>{new Date(visita.data).toLocaleDateString('pt-BR')}</Grid>
                        </Grid>
                        <Grid container className={'subHeaderVisita'} >
                            <Grid item xs={4} className={'subVisita left'} >
                                <Grid item xs={12}>
                                    <GenericButton inverseOrder={true} label={visita.horasApropriadas + ' h'} subClass={'icon-button button-visita'} icon={'producao.svg'} />
                                </Grid>
                                <Grid item xs={12} className={'legendaVisita'}>produção</Grid>
                            </Grid>
                            <Grid item xs={4} className={'subVisita'}>
                                <Grid item xs={12}>
                                    <GenericButton inverseOrder={true} label={this.props.nAtividades + '/' + this.props.totalAtividades} subClass={'icon-button button-visita'} icon={'atividade.svg'} />
                                </Grid>
                                <Grid item xs={12} className={'legendaVisita'}>atividade</Grid>
                            </Grid>
                            <Grid item xs={4} className={'subVisita right'}>
                                <Grid item xs={12}>
                                    <GenericButton subClass={'icon-button button-visita'} icon={iconRule} />
                                </Grid>
                                <Grid item xs={12} className={'legendaVisita'}>informações</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    {((this.props.etapaTurma === 'Mentoria Teórica' && !etapaFinalizada) || (atendimento && !atendimento.finalizado)) &&
                        <Grid item xs={1}>
                            <GenericButton color={'transparent'} subClass={'icon-and-button trash-visita'}
                                icon={'lixeira-brasilmais.svg'} click={() => { this.props.openModalVisita(visita) }} />
                        </Grid>}
                </Grid>
            </Grid>
        )
    };
}
