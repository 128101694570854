import React, { useEffect, useState } from 'react';
import { maskMoney } from '../../comm/masks';

export function InputCurrencyMask(props) {

  const [ inputValue, setInputValue ] = useState('');

  useEffect(() => {
    const value = typeof props.value === 'number' ? maskMoney(props.value.toString()) : props.value;

    setInputValue(value);
  }, [props.value])
  

  const handleChange = (event) => {

    if (event && event.target) {
      const formattedCurrency = maskMoney(event.target.value);

      props.onChange(event, formattedCurrency);
    }
  }

  return (
    <input 
      value={inputValue} 
      onChange={handleChange}
      disabled={props.disabled}
    />
  )
}