import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import GenericButton from "./../../components/genericButton/genericButton";
import FormField from "./../../components/formField/formField";
import API, { BASE_URL, newBackend } from "./../../comm/API";
import { SyncRequest } from "./../../comm/APIsync";
import Loading from "./../../components/loading/loading";
import "./login.scss";
import { Link } from "@material-ui/core";

export default class Login extends Component {
  errors = { login: "", senha: "" };
  ano = new Date().getFullYear();
  checkSessionInterval = undefined;

  constructor(props) {
    super(props);
    var logado = SyncRequest(
      "get",
      BASE_URL + "rest/security/usuarioConectado"
    );
    this.state = {
      login: "",
      senha: "",
      logado: logado,
      email: "",
      urlPhoto: process.env.PUBLIC_URL + "user-login.png",
      showLoading: false,
    };
  }

  loadingShow = () => {};
  loadingClose = () => {};
  getFunctions = (showToast, loadingShow, loadingClose) => {
    if (loadingShow != null) this.loadingShow = loadingShow;
    if (loadingClose != null) this.loadingClose = loadingClose;
  };

  changeInput = (e) => {
    if (e.target.value != null && e.target.value !== "") {
      if (
        e.target.id === "login" &&
        new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(
          e.target.value.toLowerCase()
        )
      ) {
        //se for email válido
        this.errors[e.target.id] = "";
      } else if (e.target.id === "login") {
        this.errors[e.target.id] = "Email inválido";
      } else {
        this.errors[e.target.id] = "";
      }
    } else {
      this.errors[e.target.id] = "Campo obrigatório";
    }
    if (e.target.id === "login")
      this.setState({ login: e.target.value.toLowerCase() });
    else if (e.target.id === "senha") this.setState({ senha: e.target.value });
  };

  downloadPhoto = () => {
    let url = this.state.login;
    if (new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(url)) {
      API.get("rest/security/foto/" + url)
        .then((res) => {
          if (res.data.usuario !== 0)
            this.setState({
              urlPhoto: BASE_URL + res.data.url + "?" + new Date().getTime(),
            });
          else
            this.setState({
              urlPhoto: process.env.PUBLIC_URL + "user-login.png",
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  refreshSreenInvalideSessioByForce = () => {
    this.checkSessionInterval = setInterval(() => {
      if (sessionStorage.getItem("logado") === "true") {
        API.get("rest/security/verificarSessao")
          .then((res) => {
            if (res && res.data != null && res.data === false) {
              sessionStorage.setItem("logado", false);
              window.location = "/#";
              clearInterval(this.checkSessionInterval);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, 1800000); //30 minutos
  };

  refreshSreenInvalideSessionByTime = (timeout) => {
    timeout = (timeout + 60) * 1000;
    setTimeout(() => {
      if (sessionStorage.getItem("logado") === "true") {
        API.get("rest/security/logout")
          .then((res) => {
            sessionStorage.setItem("logado", false);
            window.location = "/#";
            clearInterval(this.checkSessionInterval);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }, timeout);
  };

  logar = async () => {
    var body = this.state;
    if (
      this.state.login != null &&
      this.state.login !== "" &&
      this.state.senha != null &&
      this.state.senha !== ""
    ) {
      this.loadingShow();
      API.post("rest/security/login", body)
        .then((res) => {
          if (res.data.login === true) {
            sessionStorage.setItem("logado", true);
            this.refreshSreenInvalideSessioByForce();
            this.refreshSreenInvalideSessionByTime(res.data.timeout);
            this.setState({ logado: true });
          } else {
            this.errors["login"] = " ";
            this.errors["senha"] = "Usuário e/ou senha inválido(s).";
            this.setState({ logado: false });
          }
        })
        .catch((error) => {
          this.setState({ logado: false });
          alert("Erro no login. \nEntre em contato com o suporte.");
        });

      try {
        const bodyNewApi = {
          login: body.login,
          password: body.senha,
        };
        await newBackend.post("/auth/login", bodyNewApi, {
          withCredentials: true,
        });
      } catch (error) {
        console.log("Erro no login da nova api");
      }

      this.loadingClose();
    } else if (
      (this.state.login == null || this.state.login === "") &&
      (this.state.senha == null || this.state.senha === "")
    ) {
      this.errors.login = "Campo obrigatório";
      this.errors.senha = "Campo obrigatório";
      this.setState({ logado: false });
    } else if (this.state.login == null || this.state.login === "") {
      this.errors.login = "Campo obrigatório";
      this.setState({ logado: false });
    } else if (this.state.senha == null || this.state.senha === "") {
      this.errors.senha = "Campo obrigatório";
      this.setState({ logado: false });
    }
  };

  render() {
    if (this.state.logado) return <Redirect to="/dashboard" />;
    else
      return (
        <div className={"background"}>
          <Loading getFunctions={this.getFunctions} />
          <div className={"header-body"}>
            <div className={"headerLogin"}>
              <img src="./../../assets/images/logo1.svg" alt="" />
            </div>
            <div className={"login"}>
              <div className="div-circle-login">
                <img alt="" src={this.state.urlPhoto} />
              </div>
              <FormField
                type={"email"}
                label={"Login"}
                error={this.errors.login}
                changeValue={this.changeInput}
                changeValueBlur={this.downloadPhoto}
                pressEnter={this.logar}
                id={"login"}
                inputValue={this.state.login}
              />
              <FormField
                type={"password"}
                label={"Senha"}
                error={this.errors.senha}
                inputValue={this.state.senha}
                changeValue={this.changeInput}
                pressEnter={this.logar}
                id={"senha"}
              />
              <Link
                href="https://next.sgt.isitics.com/recuperar-senha"
                className="esqueciSenha"
              >
                Esqueceu sua senha? Clique aqui para redefinir
              </Link>
              <GenericButton
                color={"darkBlue"}
                label={"Entrar"}
                subClass={"basic-button"}
                click={this.logar}
              />
            </div>
          </div>
          <div className={"footerLogin"}>
            <label>
              © Copyright 2015-{this.ano} - Todos os direitos reservados a CNI -
              Confederação Nacional da Indústria.
            </label>
            <img src="./../../assets/images/footer.svg" alt="" />
          </div>
        </div>
      );
  }
}
