import React, { Component } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import { withStyles} from "@material-ui/core/styles";
import API from '../../../comm/API';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import CarouselAvaliacao from '../../../components/carousel/carouselAvaliacao';

export default class GraphBarTecnologiasHabilitadoras extends Component {

    constructor(props) {
        super(props);
        this.state = {
            L:{
                1: [63,37],
                2: [20,80],
                3: [87,13],
                4: [43,57],
                5: [4,96],
                6: [45,55]
            }
            
        }

        this.carregarStatus();
    }

    pushNotas = (object) =>{
        let array = [];
        array.push(object.nao);
        array.push(object.sim);
        return array;

    }

    getStatusAtendimento = async () => {
		
        await API.get('rest/sistema/DashboardBrasilMais/tecnologiasHabilitadoras').then( async res => {
			let dados = res.data;
            let questionsL = {
                1: this.pushNotas(dados.lean.linha_producao),
                2: this.pushNotas(dados.lean.manufatura_discreta),
                3: this.pushNotas(dados.lean.investimento_digital),
                4: this.pushNotas(dados.lean.boa_wifi),
                5: this.pushNotas(dados.lean.maturidade_tecnologica),
                6: this.pushNotas(dados.lean.mentoria_digital)};
            this.setState({L:questionsL });
		}).catch(error => {
			console.log(error);
		});
	}

	carregarStatus = async () => {
		await this.getStatusAtendimento();
	}

    

    createGrafico  =  (fase,page) => {
        let series = [];
	    series = this.state[fase][page];
        let max = series.reduce((partialSum, a) => partialSum + a, 0);
        let options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Raleway'
                },
                marginTop: 0,
                spacingTop: 0,
                marginBottom: 0,
                spacingBottom: 0,
                height: 200,
                backgroundColor:"#F2F1F6"
            },
            title:{
                floating : true,
                text: ''
            },
            xAxis: {
                gridLineColor: '#B4B8C0',
                gridLineWidth: 0,
                tickmarkPlacement:'between',
                categories: ['Não', 'Sim'],
                opposite: true,
                labels: {
                    style: {
                        width: '400px',
                        textOverflow: 'allow',
                    },
                    useHTML: true,
                    formatter: function () {
                        let val;
                        switch(this.value){
                            case 'Não':
                                val = 0;
                                break;
                            case 'Sim' :
                                val = 1;
                                break;
                        }
                        return '<div> <label style="font-weight:bold">'  + series[val] + '</label> <label style="text-transform: none; margin-left: 10px">' + this.value + '</label> </div>'
                    }
                },
                lineColor: '#707070',
                lineWidth: 1,
            },
            yAxis: {
                gridLineWidth: 0,
                lineColor: '#707070',
                min: 0,
                max: max,
                title: {
                    text: ''
                },
                labels: {
                    enabled: false
                }
            },
            plotOptions: {
                
                bar: {
                    
                    colorByPoint: true,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            legend:{
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>'+ this.x +'</b><br/>' + 
                            'valor: ' + this.y + '<br/> Porcentagem: ' + (this.y/max *100).toFixed(1) + '%';
                }
            },
            series: [{
                data: [max,max],
                name: 'backgroundSeries',
                colors: [
                    '#FFFFFF',
                    '#FFFFFF',
                ],
                grouping: false,
                animation: false,
                enableMouseTracking: false,
                showInLegend: false,
                
              },{
                data: series,
                colors: [
                    '#B2B4B7',
                    '#B7EE8E',
                ]
            }],
            credits: {
                enabled: false
            },
            exporting: false
        };

        return options;
    };

    formatData = (date) => {
        date = date + 'T00:00:00';
        let dataList;
        if (typeof (date) !== 'string') {
            dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

    AvaliacaoCarousel() {
		let content = [];
		for(let i=0;i<6;i++){
			content.push(
				<div key={'carrossel-card-' + i}>
					{this.graficoAvaliacao(i + 1)}
				</div>
			)
		};
		return content;
	}

  
   
    graficoAvaliacao(page) {
        let title = 'teste status';
        let currGraph = this.createGrafico('L',page);
        let series;
        series = this.state.L[page];
        let max = Math.max(...series);
        let total = series.reduce((partialSum, a) => partialSum + a, 0);
        let index = series.indexOf(max);
        let categories =  ['Não', 'Sim'];
        let perguntas = [
            'A empresa tem intenção de digitalizar sua(s) linha(s) de produção?',
            'A empresa possui processos de manufatura discretos?',
            'A empresa tem intenção de realizar investimentos em digitalização?',
            'A empresa possui boa rede wifi na linha de produção?',
            'A empresa possui maturidade tecnológica para digitalização?',
            'A empresa tem intenção de participar na FASE 2 - Mentoria Digital?'
        ];
        let porcentagem =  (max/total * 100).toFixed(2);
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
        return (
            <Grid container spacing={0} >
                
                <Grid item xs={12} style={{marginTop:"20px"}}>
                    <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0} style={{maxHeight:"100%",height:"100%"}}>
                        <Grid item xs={3} className={'card'} style={{maxHeight:"100%",height:"100%"}}>
                            <DashboardCard color={'card-dark-gray'} text={
                                <div className='card-titles-div white-label'>
                                    <label >{perguntas[page-1]}</label>
                                </div>} />
                        </Grid>                
                        <Grid item xs={7}>
                            <div >
                                <HighchartsReact key={title}
                                    highcharts={Highcharts}
                                    options={currGraph} />
                            </div>
                        </Grid>
                        <Grid item xs={2} className={'card'} style={{maxHeight:"100%",height:"100%"}}>
                            <DashboardCard color={'card-dark-gray'}  id = {'invertido'} text={categories[index]} value={porcentagem + '%'}/>
                        </Grid>            
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className='page-label'>
                        <label >{page + '/6'}</label>
                    </div>
                </Grid>
            </Grid>
            
        );
    }

    render() {
        
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    <CarouselAvaliacao content={this.AvaliacaoCarousel()} top={false}/>
                </Grid>
            </Grid>
            
        );
    }

}