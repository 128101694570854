import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import { Grid } from "@material-ui/core";
import API from '../../comm/API'
import Table from '../../components/table/table';
import SimpleModal from './../../components/modal/modal';
import Loading from '../../components/loading/loading';
import Toast from '../../components/toast/toast';

export default class Entrega extends Component {

    entregaList = [];
    data = undefined;
    nome = "";

    titles = [
        { id: '0', canOrder: false, label: 'Nome' },
        { id: '1', canOrder: false, label: 'Data' },
        { id: '4', canOrder: false, label: '' }
    ];
    lines = [];

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    constructor(props) {
        super(props);
        this.state = {}
        this.getEntregaList();
        this.getLinesEntrega();
    }
    errors = {
        data: { subClass: '', error: '' },
        nome: { subClass: '', error: '' }
    }

    getEntregaList = () => {
        if (this.props.atendimento.entregaList != null) {
            this.props.atendimento.entregaList.forEach(entrega => {
                this.entregaList.push(entrega);
            });
        } else {
            this.entregaList = [];
        }
    }
    getLinesEntrega = () => {
        this.lines = [];
        this.entregaList.forEach(entrega => {
            var line = [];
            line.push(entrega.nome);
            line.push(this.props.formatDataWithSlash(entrega.data));
            line.push(<div style={{ display: 'flex' }}>
                <GenericButton color={'transparent'} subClass={'icon-button'} icon={'trash.svg'} click={() => this.removeEntrega(entrega)} />
            </div>);
            this.lines.push(line);
            this.setState(this.state);
        });
    }
    removeEntrega = (entrega) => {

        var index = this.entregaList.indexOf(entrega);
        if (index !== -1) {
            this.entregaList.splice(index, 1);
        }
        this.setState(this.state);
    }

    changeEntrega = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'nome') {
                if (e.target.value === "") {
                    this.errors.nome.error = 'Campo obrigatório';
                    this.errors.nome.subClass = 'isInvalid';
                    this.nome = "";
                } else {
                    this.errors.nome.error = '';
                    this.errors.nome.subClass = '';
                    this.nome = e.target.value;
                }
            }
            if (e.target.id === 'data') {
                this.data = e.target.value + 'T00:00:00-03:00';
            }
        }
        this.setState(this.state);
    }

    clearEntrega = () => {
        this.nome = "";
        this.data = undefined;
        //this.entregaList = [];
        this.setState(this.state);
    }

    validateEntrega = () => {
        return !(this.nome !== null && this.nome !== "" && this.data != null);
    }

    registerEntrega = () => {
        var entrega = { nome: "", data: null };
        entrega.nome = this.nome;
        entrega.data = this.data;
        this.entregaList.push(entrega);
        this.nome = "";
        this.data = undefined;
        this.setState(this.state);
        this.getLinesEntrega();
    }
    saveList = () => {
        var atendimento = this.props.atendimento;
        atendimento.entregaList = this.entregaList;
        this.showLoading();
        API.post('rest/gestao/Atendimento/' + atendimento.id, atendimento).then(res => {
            
            this.closeLoading();
            this.clearEntrega();
            this.props.changeModalShowToast('modalEntrega', false, 'Entregas atualizadas com sucesso!' );      
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Error ao atualizar entregas. Favor, entrar em contato com o suporte.', 'error');
        });
    }
    render() {
        return (
            <Grid item xs={12} sm={6}>
                <div className={'greyDiv'}>
                    {!this.props.isDN && (this.props.isNegociacao || this.props.isAceito || this.props.isExecucao) &&
                        <GenericButton color={'darkBlue'} label={'Registrar entrega'} subClass={'basic-button'} 
                        click={() => {this.props.changeModal('modalEntrega', true);}} />
                    }
                    <h2>Entregas</h2>
                    {this.props.atendimento.entregaList != null && this.props.atendimento.entregaList.length > 0 ?
                        this.props.atendimento.entregaList.map(entrega => (
                            <Grid container key={entrega.id}>
                                <Grid item xs={8}><b>{entrega.nome}</b></Grid>
                                <Grid item xs={4}>Data: {this.props.formatDataWithSlash(entrega.data)}</Grid>
                            </Grid>
                        ))
                        :
                        <p>Este atendimento ainda não possui entregas registradas.</p>
                    }
                </div>
                {!this.props.modalEntrega ? null :
                    <div><Toast getFunctions={this.getFunctions} />
                        <SimpleModal
                            isOpen={this.props.modalEntrega}
                            handleClose={() => this.props.changeModal('modalEntrega', false)}
                            type={'info'}
                            width={'700px'}>
                            <Loading getFunctions={this.getFunctions} />
                            <div className={'modal modal-entrega'}>
                                <h2>Registrar Entrega</h2>
                                <Grid container>
                                    <Grid item xs={8} className={'paddingForm'}>
                                        <FormField
                                            type={'text'} label={'Nome'} ref='nome' id={'nome'}
                                            inputValue={this.nome}
                                            changeValue={this.changeEntrega} error={this.errors.nome.error} />
                                    </Grid>
                                    <Grid item xs={4} className={'grid-right-button'}>
                                        <FormField type={'date'} label={'Data da entrega'} ref='data' id={'data'}
                                            inputValue={this.props.formatData(this.data)}
                                            changeValue={this.changeEntrega}
                                            icon={'calendar.svg'} error={this.errors.data.error} />
                                        <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.registerEntrega} icon={'plus.svg'} disabled={this.validateEntrega()} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.entregaList.length > 0 ?
                                            <Table lines={this.lines} titles={this.titles} initialOrder={'0'} pagination={false} />
                                            : null}
                                    </Grid>

                                    <Grid item xs={12} className={'rodape'}>
                                        <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={()=>{this.clearEntrega(); this.props.changeModal('modalEntrega', false);}} />
                                        <GenericButton color={'darkBlue'} label={'Registrar'} subClass={'basic-button'} click={this.saveList} />
                                    </Grid>
                                </Grid>
                            </div>
                        </SimpleModal></div>
                }
            </Grid>
        )
    }
}