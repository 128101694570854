import React, { Component } from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import API from '../../comm/API';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import DownloadFiles from '../../comm/DownloadFiles';
import './atendimento.scss';
import SimpleModal from '../../components/modal/modal';

export default class Relatorio extends Component {

    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    state = { modalConfirma: false , atendimento: this.props.atendimento, openModalGenerateReport: false };

    getStatusButton() {
        if(this.props.turma){
            if(this.props.isAtendimentoDIGITAL){
                const atendimentoPraticaD = this.props.turma.etapaPraticaD.atendimentoList.find(atd => { return atd.idAtendimento === this.state.atendimento.id });
                return !(this.state.atendimento.atendimentoStatus.id === 8 && atendimentoPraticaD && atendimentoPraticaD.finalizado);
            }else {
                const atendimentoConsultoriaD = this.props.turma.etapaConsultoriaD.atendimentoList.find(atd => { return atd.idAtendimento === this.state.atendimento.id });
                return !(this.state.atendimento.atendimentoStatus.id === 8 && atendimentoConsultoriaD.finalizado);
            }
        }
        return false;
    };
    
    
    generateReport = (e) => {
        this.props.showLoading();
        API.post(this.props.isAtendimentoDIGITAL ? 'rest/gestao/RelatorioTurmaDigital/final' : 'rest/upload/relatorioFinal', this.state.atendimento).then(async res => {
            this.props.refreshAtendimento(res.data);
            this.setState({ atendimento: res.data ,openModalGenerateReport: false});
            this.props.closeLoading();
            this.props.showToast('Relatorio gerado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Erro ao gerar o relatório. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    closeModalGenerateReport = () => {
        this.setState({openModalGenerateReport: false});
    }
    
    render() {
        return (
            <>
                <div className={'greyDiv'}>
                    {!this.props.isConcluido && !this.props.isDN &&
                        <GenericButton color={'darkBlue'} label={'Gerar relatório'} subClass={'basic-button'} click={(e) => {this.setState({openModalGenerateReport: true})}}
                            disabled={this.getStatusButton()} />}
                    <h2>Relatório final</h2>
                    {this.state.atendimento.relatorioFinal == null ?
                        <p style={{ fontSize: '13px', margin: '4px 0px' }}>Este atendimento ainda não possui relatório final.</p> :
                        <div style={{ display: 'inline-flex' }}>
                            <Chip label={<label style={{ display: 'flex', alignItems: 'center' }}>
                                <Icon className={'attach'} style={{ marginRight: '5px' }} onClick={() => { this.props.showLoading(); DownloadFiles(this.state.atendimento.relatorioFinal.url, this.state.atendimento.relatorioFinal.nomeArquivo); this.props.closeLoading(); } }>attach_file</Icon>
                                <p style={{ marginRight: '10px' }}>{this.state.atendimento.relatorioFinal.nomeArquivo}</p></label>} />
                            <p style={{ fontSize: '13px', margin: '4px 0px' }}>Emissão: {new Date(this.state.atendimento.relatorioFinal.dataGeracao).toLocaleString()}</p>
                        </div>}
                </div>
                <SimpleModal
                    isOpen={this.state.openModalGenerateReport}
                    handleClose={() => this.closeModalGenerateReport()}
                    type={'warning'}
                    width={'500px'}
                    height={'600px'}
                    className={'evento-modal'}>
                    <label>Ao gerar o relatório final, não será mais possível</label>
                    <label className={'bold'}> editar dados informados nas atividades preenchidas anteriormente.</label>
                    <div className={'rodape'}>
                        <GenericButton color={'darkBlue'} label={'Cancelar'} subClass={'basic-button'} click={ () => this.closeModalGenerateReport()} />
                        <GenericButton color={'darkGrey-outline'} label={'Baixar'} subClass={'basic-button'} click={this.generateReport} />
                    </div>                    
                </SimpleModal>
            </>
        )
    };
}