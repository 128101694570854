import React from "react";
import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import GenericButton from "../genericButton/genericButton";
import { Link } from 'react-router-dom';

const AlertBrasilMaisProdutivo = ({ cliente, atendimento = false }) => {

    if (!cliente) {
        return null;
    }

    const faltaRais = cliente.porte_cliente_rais == null;
    const faltaFaturamento = cliente.porte_cliente_faturamento == null;
    const faltaClassificacao = cliente.idtipocnpj == null;

    const contatosSemCpf = !cliente.contatos || cliente.contatos.some(contato => !contato.cpf);

    if (faltaRais || faltaFaturamento || contatosSemCpf) {
        const mensagemLista = (
            <ul>
                {faltaRais && (
                    <li>
                        <strong>Quantidade de funcionários</strong>
                    </li>
                )}
                {faltaFaturamento && (
                    <li>
                        <strong>Valor do faturamento</strong>
                    </li>
                )}
                {faltaClassificacao && (
                    <li>
                        <strong>Classificação: Mercado/Empresa</strong>
                    </li>
                )}
                {contatosSemCpf && (
                    <li>
                        <strong>Dados do responsável pela empresa</strong>
                    </li>
                )}
            </ul>
        );

        const mensagem = atendimento
        ? "Para avançar no cadastro do atendimento Novo B+P, é necessário atualizar os dados do cadastro da empresa:"
        : "É obrigatório a atualização do cadastro da empresa:";

        return (
            <Grid item xs={12}>
                <Alert severity="error">
                    <AlertTitle>
                        <strong>Atenção:</strong> Atendimento do Programa Brasil Mais Produtivo
                    </AlertTitle>
                    {mensagem}
                    {mensagemLista}
                    <div style={{ marginBottom: '0.7em' }}>Clique no botão abaixo para atualizar os dados:</div>
                    <Link to={`/cad/pessoa/cliente/${cliente.id}`}>
                        <GenericButton color={'inverse-red'} subClass={'basic-button'} label={'Editar Cliente'} class={'cliente'}/>
                    </Link>                    
                </Alert>
            </Grid>
        );
    } else {
        return null;
    }
};

export default AlertBrasilMaisProdutivo;
