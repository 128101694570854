import { Grid } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import React, { Component } from 'react';
import API from '../../comm/API';
import DownloadFiles from '../../comm/DownloadFiles';
import GenericButton from '../../components/genericButton/genericButton';
import SimpleModal from './../../components/modal/modal';
import './atendimento.scss';

export default class TermoAceite extends Component {

    state = {
        modalAnexo: false,
        tipoTermo: { id: 1 }, termoAceiteList: Object.assign([], this.props.atendimento.termoAceiteList),
        atendimento: this.props.atendimento
    };

    getTipoTermos = () => {
        this.setState({ modalAnexo: true });
    };

    uploadAnexo = (e) => {
        if (e.target != null && e.target.files[0] != null) {
            let file = e.target.files[0];
            let formData = new FormData();
            formData.append('name', 'file');
            formData.append('file', file);
            formData.append('fileName', file.name);
            if (file.size > 10000000) {
                this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
                return;
            }
            this.props.showLoading();
            API.post(`rest/upload/atendimento/termoaceite/${this.props.atendimento.id}/${this.state.tipoTermo.id}`, formData, {
                headers: { 'Content-Type': "multipart/form-data" }
            }).then(async res => {
                let atendimentoAux = this.state.atendimento;
                atendimentoAux.termoAceiteList = res.data.termoAceiteList;
                this.props.refreshAtendimento(atendimentoAux);
                this.setState({ termoAceiteList: res.data.termoAceiteList, atendimento: atendimentoAux, modalAnexo: false }, this.props.closeLoading());
                this.props.showToast('Termo anexado com sucesso!', 'success');
            }).catch(error => {
                this.props.closeLoading();
                this.props.showToast('Erro ao anexar o termo. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            this.props.showToast('Por favor, selecione o tipo do termo.', 'error');
        }
    };

    removeAnexo = (anexo) => {
        this.props.showLoading();
        let termoAux = this.state.termoAceiteList;
        let index = termoAux.findIndex(prop => {
            return prop.id === anexo.id;
        });
        if (index !== -1) {
            termoAux.splice(index, 1);
        }
        let atendimentoAux = this.state.atendimento;
        atendimentoAux.termoAceiteList = termoAux;
        API.post(`/rest/gestao/Atendimento/${atendimentoAux.id}`, atendimentoAux).then(res => {
            this.props.refreshAtendimento(atendimentoAux);
            this.setState({ termoAceiteList: termoAux, atendimento: atendimentoAux }, this.props.closeLoading());
            this.props.showToast('Termo removido com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Erro tentar remover termo. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    downloadLayout = () => {
        window.open(process.env.PUBLIC_URL + '/assets/termo/Modelo - Termo de Adesão - Mentoria Lean.docx');
    };

    downloadLayoutDigital = () => {
        window.open(process.env.PUBLIC_URL + '/assets/termo/Modelo - Termo de Adesão - Mentoria Digital.docx');
    };

    render() {
        return (
            <div className={'greyDiv'}>
                { this.props.isNegociacao && !this.props.isDN &&
                    <GenericButton color={'darkBlue'} label={'Anexar documento'} subClass={'basic-button'} 
                        click={this.getTipoTermos} /> }
                <h2>Termos</h2>
                { this.state.termoAceiteList.length > 0 ? 
                    <div>
                        { this.props.atendimento.termoAceiteList.map(
                            (anexo, index) => (
                                <Grid item xs={12} key={index} style={{display: 'inline-grid'}}>
                                    <label className={'blue-label'}>{[1,3].includes(anexo.tipoTermo.id) ? 'adesão' : 'encerramento'}</label>
                                    <Chip key={index}
                                        label={<label style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '10px' }}>
                                        <Icon className={'attach'} style={{ marginRight: '5px'}} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                        <p style={{ marginRight: '10px' }}>{anexo.nomeArquivo}</p></label>} />  
                                </Grid>
                            ))}
                    </div> : <p style={{ fontSize: '13px', margin: '4px 0px' }}>Este atendimento não possui termos anexados.</p> }
                
                <div style={{display: 'inline-flex', marginTop: '15px'}}>
                    <span style={{ fontSize: '13px', fontWeight: '600', padding: '8px 10px 0px 0px' }}>Modelo do termo de adesão (sugestão)</span>
                    <GenericButton color={'transparent'} subClass={'pen icon-button'} icon={'download.svg'} click={this.props.isAtendimentoDIGITAL ? this.downloadLayoutDigital : this.downloadLayout} />
                </div>
                <SimpleModal
                    disableClick={true}
                    isOpen={this.state.modalAnexo}
                    handleClose={() => this.setState({ modalAnexo: false })}
                    type={'info'}
                    width={'600px'}>
                    <div className={'modal modal-entrega'}>
                        <h2>Comprovante</h2>
                        <Grid container>
                            <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                                <div style={{ display: 'flex'}}>
                                    <label style={{ border: 'unset' }}
                                        className={'lightBlue basic-button control-label-comprovante'}
                                        id="control-label"
                                        htmlFor="uploadfile">Adicionar comprovante
                                    </label>
                                    <input
                                        className={'file-input'}
                                        type="file"
                                        id="uploadfile"
                                        name="file"
                                        value={undefined}
                                        onChange={this.uploadAnexo} 
                                        onClick={(event)=> { 
                                            event.target.value = null
                                       }}/>
                                    <span style={{fontSize: '11px', padding: '15px 10px'}}>Extensão permitida: pdf, msg</span>
                                </div>
                            </Grid>
                            { this.state.termoAceiteList.length > 0 ?
                                this.state.termoAceiteList.map(
                                    (anexo, index) => (
                                        <Grid item xs={12} key={'termo-' + anexo.id}>
                                            <p>{anexo.tipoTermo.descricao}</p>
                                            <Chip key={index}
                                                label={<label style={{ display: 'flex', alignItems: 'center' }}>
                                                    <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                    <p style={[1,3].includes(anexo.tipoTermo.id) ? {} : { marginRight: '10px' }}>{anexo.nomeArquivo}</p></label>}
                                                icon={[1,3].includes(anexo.tipoTermo.id) ? <Icon onClick={() => this.removeAnexo(anexo)} className={'close'}>clear</Icon> : null} /> 
                                        </Grid>
                                    )) : <p style={{ fontSize: '13px' }}>Este atendimento ainda não possui nenhum termo anexado.</p>}
                            <Grid item xs={12} className={'rodape'} style={{ marginTop: '30px'}}>
                                <GenericButton color={'darkBlue'} label={'Fechar'} subClass={'basic-button'} click={() => this.setState({ modalAnexo: false })} />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
            </div>
        )
    };
}