import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import React, { Component } from "react";
import GenericButton from "./../../components/genericButton/genericButton";
import "./inputselect.scss";

export default class SelectItem extends Component {
  state = { value: "" };

  componentDidMount() {
    this.setInitialSelectedItem();
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialSelectedItem !== prevProps.initialSelectedItem) {
      this.setInitialSelectedItem();
    }
  }

  setInitialSelectedItem() {
    const { initialSelectedItem, suggestions, itemKey } = this.props;
    if (initialSelectedItem && suggestions) {
      const newValue = suggestions.find((item) => (itemKey ? item[itemKey] === initialSelectedItem[itemKey] : item === initialSelectedItem)) || "";

      // Somente atualize o estado e chame getInput se o valor for diferente
      if (this.state.value !== newValue) {
        this.setState({ value: newValue }, () => {
          // A função getInput só deve ser chamada se o valor realmente mudou
          if (this.props.getInput && this.state.value !== newValue) {
            this.props.getInput(newValue);
          }
        });
      }
    }
  }

  addItens = () => {
    this.props.getInput(this.state.value);
    this.setState({ value: "" });
  };

  showItens() {
    const { itemLabel, itemKey, suggestions, itemTemplate } = this.props;
    if (itemLabel && itemKey && suggestions) {
      return suggestions.map((item, index) => (
        <MenuItem key={item[itemKey] + index} value={item} disabled={item.group ? true : false} className={item.group && item.group !== "grey" ? "isGroup" : ""}>
          {itemTemplate ? itemTemplate(item) : item[itemLabel]}
        </MenuItem>
      ));
    } else if (suggestions) {
      return suggestions.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ));
    }
  }

  hasButton() {
    return this.props.hasButton ? "flex" : "none";
  }

  render() {
    const { label, isOnlyLabel, inputLabelValue, id, disabled, error } = this.props;
    const { value } = this.state;
    return (
      <div className={error ? "inputSelectDiv isInvalid" : "inputSelectDiv"}>
        <label>{label}</label>
        {isOnlyLabel ? (
          <div className="inputSelect-onlyLabel-container">
            <span>{inputLabelValue}</span>
          </div>
        ) : (
          <>
            <div style={{ display: "flex" }}>
              <Select
                className="selectOptions"
                value={value}
                id={id}
                disabled={disabled}
                onChange={(e) => {
                  this.setState({ value: e.target.value }, () => {
                    if (!this.props.hasButton) this.props.getInput(e.target.value);
                  });
                }}
              >
                {this.showItens()}
              </Select>
              <div style={{ display: this.hasButton() }}>
                <GenericButton color="darkBlue" subClass="icon-button" click={this.addItens} icon="plus.svg" disabled={disabled} />
              </div>
            </div>
            <span className="isInvalid" style={{ display: error ? "inline" : "none" }}>
              {error}
            </span>
          </>
        )}
      </div>
    );
  }
}
