import React from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GenericButton from '../../../components/genericButton/genericButton';


export default class PainelTurmas extends React.Component {

    currPainel = { status: 'Em espera', statusBox: '' };

    headerButtons = [
        { color: 'yellow', clicked: true, status: 1, painel: 'Em espera', statusBox: '' },
        { color: 'green', clicked: false, status: 2, painel: 'Em atendimento', statusBox: 'atendimento' },
        { color: 'blue', clicked: false, status: 3, painel: 'Concluídas', statusBox: 'concluida' },
    ]

    statusTurmas = { 1: 'turmasEmEspera', 2: 'turmasEmAtendimento', 3: 'turmasConcluidas' };

    currTurmas = this.statusTurmas[1];

    constructor(props) {
        super(props);
        this.state = {
            turmasEmEspera: [],
            turmasEmAtendimento: [],
            turmasConcluidas: [],
            countTurmas: {}
        };
        this.loadTurmas();
        this.getCountTurmas();
    }

    loadTurmas = async () => {
        await this.getDataTurmas(3);
        await this.getDataTurmas(2);
        await this.getDataTurmas(1);
    }

    getDataTurmas = async (status) => {
        let turmas = this.state[this.statusTurmas[status]];
        if (turmas.length === 0) {
            await API.get(`rest/sistema/DashboardBrasilMais/infoPainelTurmas/${status}`).then(res => {
                turmas[this.statusTurmas[status]] = res.data;
                this.setState(turmas);
            }).catch(error => {
                console.log(error);
            });
        }
        this.currTurmas = this.statusTurmas[status];
        this.setState(this.state);
    }

    getCountTurmas = async () => {
        await API.get('rest/sistema/DashboardBrasilMais/countPainelTurmas').then(res => {
            this.setState({ countTurmas: res.data });
        }).catch(error => {
            console.log(error);
        });
    }

    headerButton = (label, value, curr, ...sec) => {
        return (
            <Grid item xs={3} md={2} className={'button-painel-turma'}>
                <GenericButton
                    color={this.headerButtons[curr].color}
                    label={<> <span> {value ? value : 0} </span> {label} </>}
                    subClass={`${this.headerButtons[curr].clicked ? 'clicked' : null} basic-button`}
                    click={async () => {
                        this.headerButtons[curr].clicked = true;
                        this.headerButtons[sec[0]].clicked = false;
                        this.headerButtons[sec[1]].clicked = false;
                        this.currPainel.status = this.headerButtons[curr].painel;
                        this.currPainel.statusBox = this.headerButtons[curr].statusBox;
                        await this.getDataTurmas(this.headerButtons[curr].status);
                        this.setState(this.state);
                    }} />
            </Grid>
        )
    }

    bodyIcons = (icon, value, isAtendimentos) => {
        let colorCircle = isAtendimentos ? (value === 0 ? 'min' : value >= 1 ? 'max' : '') : '';
        return(
            <Grid item xs={3}> 
                <Grid container justify={'center'} spacing={2}>
                    <Grid item>
                        <img src={`./../../assets/icons/${icon}.svg`} alt=''/>
                    </Grid>
                    <Grid item>
                        <span className={`circle ${colorCircle}`}>{value}</span>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    barBox = (etapa) => {
        let progressBar = (etapa * 100) / 9;
        return progressBar.toFixed(2).toString() + '%';
    }
    
    render() {
        return (
            <Grid container spacing={3} style={{ marginTop: '2%' }}>
                <Grid item xs={12} className={'title-painel-turma'}>
                    <Grid container>
                        <Grid item xs={12} className={'font-title'}>Painel de turmas da unidade operacional</Grid>
                        <Grid item xs={12} className={'underline'}></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {this.headerButton('em espera', this.state.countTurmas.espera, 0, 1, 2)}
                        {this.headerButton('em andamento', this.state.countTurmas.andamento, 1, 0, 2)}
                        {this.headerButton('concluídas', this.state.countTurmas.concluida, 2, 0, 1)}
                    </Grid>
                </Grid>                
                {this.state[this.currTurmas].map((obj, index) =>
                    <Grid item xs={6} key={index}>
                        <Grid item className={`box-painel-turma ${this.currPainel.statusBox}`}>
                            <Grid container className={'header-box'} direction={'row'}>
                                <Grid item xs={9}>
                                    <div className={'label-turma'}> <label> Turma </label> </div>
                                    <div className={'label-nome-turma'}> <label> {obj.nomeTurma} </label> </div>
                                </Grid>
                                <Grid item xs={3} className={`status-box ${this.currPainel.statusBox}`}>
                                    <Grid container justify="flex-end">
                                        <Grid item container justify={'center'}>
                                            <label> {this.currPainel.status === 'Concluídas' ? 'Concluída' : this.currPainel.status} </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            { this.currPainel.status === 'Em atendimento' && <div style={{ background: `linear-gradient(90deg, #7DAE59 ${this.barBox(obj.etapa)}, rgba(0, 177, 232, 0.3) ${'1%'}`, height: '10px'}}></div> }
                            <Grid container className={'body-box'} direction={'row'}>
                                {this.bodyIcons('atendimentos', obj.atendimentos, true)}
                                {this.bodyIcons('alunos', obj.alunos)}
                                {this.bodyIcons('mentores', obj.mentores)}
                                {this.bodyIcons('unidades', obj.unidades)}
                            </Grid>
                            {this.currPainel.status === 'Em atendimento' && <Grid container className={'foot-box'} justify={'center'} direction={'row'}>
                                <Grid item xs={12} md={5} xl={5}>
                                    <Grid container justify="flex-start">
                                        <Grid item container justify={'center'}>
                                            <label> Início: { obj.dataInicio.split(' ')[0].split('-').reverse().join('/') } </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={7} xl={7}>
                                    <Grid container justify="flex-end">
                                        <Grid item container justify={'center'}>
                                            <label> Previsão de término: { obj.dataTermino.split(' ')[0].split('-').reverse().join('/') } </label>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                            {this.currPainel.status === 'Concluídas' && 
                            <Grid container className={'foot-box'} justify={'center'} direction={'row'}>
                                <Grid item xs={12} className={`${!obj.produtividade ? 'no-meta' : ''}`}>
                                    <label> Atendimentos dentro da meta de produtividade: <span>{obj.produtividade ? 'sim' : 'não'}</span> </label>
                                </Grid>
                            </Grid>}
                        </Grid>
                    </Grid>)}
            </Grid>
        )
    }
}