import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import API from '../../comm/API';
import DownloadFiles from '../../comm/DownloadFiles';
import { Grid, Chip, Icon } from "@material-ui/core"

export default class AtividadeDocumentacaoFinal extends Component {

    constructor(props) {
      super(props);

      let documentacaoDigital = props.atividade;

      documentacaoDigital.anexoDocumentacaoList = !!documentacaoDigital.anexoDocumentacaoList.length ? documentacaoDigital.anexoDocumentacaoList : [];

      this.state = {
        documentacaoDigital: props.atividade,
      };
    };

    uploadRegister = (e) => {
      this.props.showLoading();
      let file = e.target.files[0];
      let formData = new FormData();
      formData.append('name', 'file');
      formData.append('file', file);
      formData.append('fileName', file.name);
      if (file && !(file.name.toLowerCase().includes('jpg') || file.name.toLowerCase().includes('jpeg') || 
          file.name.toLowerCase().includes('png') || file.name.toLowerCase().includes('pdf'))) {
          this.props.closeLoading();
          this.props.showToast('Tipo de arquivo não permitido. Anexar apenas PDF, JPG, JPEG ou PNG.', 'error');
          return;
      }
      if (file.size > 10000000) {
          this.props.closeLoading();
          this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
          return;
      }  
      let documentacaoDigitalAnexo = this.state.documentacaoDigital;      
      API.post(`rest/upload/anexoTurmaDigital/${this.props.turma.id}`, formData, {
          headers: { 'Content-Type': "multipart/form-data" }
      }).then(async res => {
          const anexo = {
              descricao: 'Registro de Visitas',
              nomeArquivo: res.data.nomeArquivo,
              tamanho: res.data.tamanho,
              tipo: res.data.tipo,
              url: res.data.url,
          };
          documentacaoDigitalAnexo.anexoDocumentacaoList.push(anexo);

          this.setState({ documentacaoDigital: documentacaoDigitalAnexo });
          this.props.closeLoading();
          this.props.showToast('Arquivo anexado com sucesso!', 'success');
      }).catch(error => {
          this.props.closeLoading();
          this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
          console.log(error);
      })
    };

    removerRegister = (element) => {
      let documentacaoDigital = this.state.documentacaoDigital;         
      let index = documentacaoDigital.anexoDocumentacaoList.findIndex(prop => {
          return (element.id && element.id === prop.id) || element.nomeArquivo === prop.nomeArquivo;
      });
      if (index !== -1) {
        documentacaoDigital.anexoDocumentacaoList.splice(index, 1);
      }
      this.setState({documentacaoDigital:documentacaoDigital });
    };

    changeInput = (e) => {
        let documentacaoDigital = this.state.documentacaoDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
              documentacaoDigital[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
              documentacaoDigital[e.target.name] = e.target.value;
            }
            this.setState({ documentacaoDigital: documentacaoDigital, errors: errors });
        }
    };

    renderBody = () => {
        let documentacaoDigital = this.state.documentacaoDigital;

        return (
            <Grid container justify={'center'} style={{ marginTop: '1%' }}>
                <Grid container justify={'flex-start'} style={{ marginLeft: '2%' }} spacing={4}>
                    <Grid item xs={12}>
                      <Grid container justify={'flex-start'}>
                        <Grid item xs={4} xl={3} className={'anexos-visitas-box-top'} >
                          <Grid item xs={12}>
                            Anexar arquivo único completamente preenchido:
                          </Grid>
                          <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', paddingTop: '5%' }}>
                              <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/relatorio-brasilmais.svg'} width={30} height={30} />
                              <span style={{ paddingLeft: '25px', textAlign: 'left', font: '16px/19px Raleway', letterSpacing: '0px', color: '#707070', opacity: 1 }}>{'Registro Encontros > Mentor/Consultor'}</span>
                          </Grid>
                          <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                              <label
                                  className={'lightBlue basic-button control-label'}
                                  id="upload-atividade"
                                  htmlFor="uploadfile-listapresenca"
                                  style={{opacity: this.props.disabled ? 0.4 : 1,
                                    cursor: this.props.disabled ? 'not-allowed': 'cursor'}}>Anexar
                              </label>
                              <input
                                  className={'file-input listaPresenca'}
                                  type="file"
                                  id="uploadfile-listapresenca"
                                  name="file"
                                  style={{opacity : this.props.disabled ? 0.5 : 1 }}
                                  disabled={this.props.disabled}
                                  value={undefined}
                                  onChange={(element) => { this.uploadRegister(element) }}
                              />
                          </Grid>
                          <Grid>
                              {documentacaoDigital.anexoDocumentacaoList.length > 0 ?
                                  documentacaoDigital.anexoDocumentacaoList.map(
                                      (anexo, index) => (
                                          <Chip key={index}
                                              label={<label style={{ display: 'flex', alignItems: 'center', marginBottom: 0 }}>
                                                  <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                  <p>{anexo.nomeArquivo}</p></label>}
                                              icon={this.props.disabled ? <></> :<Icon onClick={() => this.removerRegister(anexo)} className={'close'}>clear</Icon>}
                                          />
                                      )) : <></>}
                              
                          </Grid>
                      </Grid>                 
                  </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={'div-field-btn'}>
                          <FormField 
                            type={'textarea'}
                            changeValue={(e) => { 
                              documentacaoDigital.maisInfo = e.target.value;
                              this.setState({ documentacaoDigital });
                            }} 
                            inputValue={documentacaoDigital.maisInfo}
                            label={
                              <>
                                <b style={{ fontWeight: 'normal'}}>Mais informações</b> <label style={{ color: '#B4B8C0', fontSize: 11 }}>*Opcional</label>
                              </>
                            }
                            disabled={this.props.disabled}
                            placeholder="Digite aqui"
                          />
                        </div>
                    </Grid>
                    
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ marginTop: '3%' }}>
                    
                   
                </Grid>
                <Grid container justify={'center'} style={{ marginTop: '3%' }} className={'linha-producao-box'}>
                    
                </Grid>
            </Grid>
        )
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao'} style={{ marginTop: '30px' }}>
                { this.renderBody() }
            </Grid>
        )
    }

}