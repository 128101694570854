import React, { Component } from 'react';
import API from '../../comm/API';
import VisitasDefault from './visitasDefault';
import AtendimentosCard from './atendimentosCard';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtil';
import AtividadeDefault from './atividadeDefault';
import AtividadeTecnologiasDigitais from './atividadeTecnologiasDigitais';
import AtividadeResultados from './atividadeResultados';
import AtividadeAvaliacaoDoAtendimento from './atividadeAvaliacaoDoAtendimento';

export default class MentoriaPratica extends Component {

    STATUS_FINALIZADO = 3;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'visitas'
    };

    atendimentoEtapaC = null;
    atendimentoEtapaD = null;

    constructor(props) {
        super(props);

        const etapaName = `etapaConsultoria${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);

        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoPratica: undefined,
            clientName: '',
            etapa,
            atendimentoList,
            showTopButtons: true,
            disabled: false
        }
    };

    getTecnologiasDigitaisModel = () => {
        return {
            status: 1,
            ordemVisita: null,
            digitalizarLinhaProducao: false,
            processoManufaturaDiscreto: false,
            maturidadeTecnologica: false,
            boaWifi: false,
            doInvestimentoDigital: false,
            doMentoriaDigital: false
        }
    }

    getAvaliacaoModel = () => {
        return {
            status: 1,
            ordemVisita: null, 
            pontuacaoAtendimento: 0,
            pontuacaoCapacidadeTeorica: 0,
            pontuacaoAplicacaoMetodologia: 0,
            pontuacaoTempoExecucaoMetodologia: 0,
            pontuacaoCustoProduto: 0,
            pontuacaoResultados: 0,
            pontuacaoExpectativas: 0,
            pontuacaoResultadosMantidos: 0
        };
    }

    getResultadoModel = () => {
        return {
            status: 1,
            ordemVisita: null,
            assinaturas: [],
        }
    }

    validateDepoimentos(depoimentos) {
        return depoimentos && depoimentos.anexoAtividadeList && depoimentos.anexoAtividadeList.length > 0 && depoimentos.anexoAtividadeList.every(anx => { return anx.descricao });
    };

    validateResultado(resultado) {
        return resultado && resultado.assinaturas && resultado.assinaturas.length === 2;
    };

    validateAvaliacao(avaliacao) {
        return avaliacao && avaliacao.pontuacaoAtendimento && avaliacao.pontuacaoCapacidadeTeorica && avaliacao.pontuacaoAplicacaoMetodologia && 
        avaliacao.pontuacaoTempoExecucaoMetodologia && avaliacao.pontuacaoCustoProduto && avaliacao.pontuacaoResultados &&
        avaliacao.pontuacaoExpectativas && avaliacao.pontuacaoResultadosMantidos;
    }

    updateViewData = (atendimento) => {
        this.viewData.visitas.data = atendimento.visitaList;
        const { tecnologiasDigitais, depoimentos, resultado, avaliacao } = atendimento;

        this.setState({disabled: this.state.turma.status === 3})

        this.viewData.atividades.tecnologiasDigitais.data = JSON.parse(JSON.stringify(tecnologiasDigitais || this.getTecnologiasDigitaisModel()));

        this.viewData.atividades.depoimentos.data = JSON.parse(JSON.stringify(depoimentos || { }));
        this.viewData.atividades.depoimentos.anexos.data = getByKeyOrEmpty(depoimentos, 'anexoAtividadeList');

        this.viewData.atividades.avaliacao.data =  JSON.parse(JSON.stringify(avaliacao || this.getAvaliacaoModel()));

        this.viewData.atividades.avaliacao.disabled =  avaliacao && avaliacao.status === 3;

        this.viewData.atividades.resultado.data = JSON.parse(JSON.stringify(resultado || this.getResultadoModel()));
    };

    validaAtividade = async (viewData, atendimentoPratica, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoPratica[nomeAtividade] ? atendimentoPratica[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade ? funcaoValidacaoAtividade(atividade) : true;
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoPratica[nomeAtividade] = atividade;
    };
    
    saveEtapa = async (viewData) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoPratica = this.state.atendimentoPratica;
        let visitasData = viewData.visitas.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;
        
        if (currScreenAtividade === 'tecnologiasDigitais') 
            await this.validaAtividade(viewData, atendimentoPratica, 'tecnologiasDigitais', '', '', this.STATUS_FINALIZADO);
        else if (currScreenAtividade === 'depoimentos') 
            await this.validaAtividade(viewData, atendimentoPratica, 'depoimentos', 'anexoAtividadeList', this.validateDepoimentos, this.STATUS_FINALIZADO);
        else if (currScreenAtividade === 'avaliacao') 
            await this.validaAtividade(viewData, atendimentoPratica, 'avaliacao', '', this.validateAvaliacao, this.STATUS_FINALIZADO);
        else if (currScreenAtividade === 'resultado') 
            await this.validaAtividade(viewData, atendimentoPratica, 'resultado', '', this.validateResultado, this.STATUS_FINALIZADO);

        if (selectedVisita) {
            selectedVisita.anexoVisitaList = [];
            selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
            selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
            selectedVisita.anexoVisitaList = selectedVisita.anexoVisitaList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
            if (selectedVisita.id && !currScreenAtividade) {
                if ((this.state.etapa.finalizada && !this.validaVisita(selectedVisita))) {
                    this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                    throw new Error();
                }
            }
            let index = visitasData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
            if (index > -1) atendimentoPratica.visitaList[index] = selectedVisita;
            else atendimentoPratica.visitaList.push(selectedVisita);
        };

        atendimentoPratica.horasApropriadas = atendimentoPratica.visitaList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        let indexAtendimento = turma.etapaConsultoriaD.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
        turma.etapaConsultoriaD.atendimentoList[indexAtendimento] = atendimentoPratica;
        if(atendimentoPratica.horasApropriadas > 4){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else{
            return API.post('rest/gestao/Turma/' + turma.id, turma).then(() => {
                return API.get('rest/gestao/Turma/' + turma.id).then(async res => {
                    turma = res.data;
                    etapa = turma.etapaConsultoriaD;
                    let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                    let atd = etapa.atendimentoList[index];
                    await this.updateViewData(atd);
                    this.setState({ turma, etapa, atendimentoPratica: atd, switchAnexos: undefined, showTopButtons: true });
                    this.props.showToast('Etapa editada com sucesso!', 'success');
                }).catch(error => {
                    this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                    throw new Error(error);
                });
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }
        
    };

    validaAtendimentoPratica = async (atendimentoPratica) => {  
        let visitaList = atendimentoPratica.visitaList;
        let tecnologiasDigitais = atendimentoPratica.tecnologiasDigitais;
        let depoimentos = atendimentoPratica.depoimentos;
        let resultado = atendimentoPratica.resultado;
        let avaliacao = atendimentoPratica.avaliacao;
        if (!atendimentoPratica.cancelado) {
            return await visitaList && visitaList.length > 0 && visitaList.every(visita => { return this.validaVisita(visita) }) 
                && tecnologiasDigitais && tecnologiasDigitais.status === this.STATUS_FINALIZADO 
                && depoimentos && depoimentos.status === this.STATUS_FINALIZADO
                && avaliacao && avaliacao.status === this.STATUS_FINALIZADO
                && resultado && resultado.status === this.STATUS_FINALIZADO;
        }
        return true;
    };

    validaVisita = (visita) => {
        return visita && visita.anexoVisitaList && visita.anexoVisitaList.length > 0 
            && visita.anexoVisitaList.some(anx => { return anx.descricao === 'lista de presença' })
            && visita.anexoVisitaList.some(anx => { return anx.descricao === 'relatório de visita' }) 
            && visita.data && visita.turno != null && visita.horasApropriadas > 0  && visita.isVirtual != null;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoPratica = this.state.atendimentoPratica;
        let turma = this.props.turma;
        let validado = await this.validaAtendimentoPratica(atendimentoPratica);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoPratica.finalizado = true;
            let indexAtendimento = turma.etapaConsultoriaD.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
            turma.etapaConsultoriaD.atendimentoList[indexAtendimento] = atendimentoPratica;
            let etapa = turma.etapaConsultoriaD;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.status = this.STATUS_FINALIZADO;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return API.post('rest/gestao/Turma/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/Turma/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaConsultoriaD;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.setState({ turma, etapa, atendimentoList, atendimentoPratica: atd }, this.goBackAtendimentos());
                        this.props.closeLoading();
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    getAtendimentoEtapaC = (atendimentoPratica) => {
        return this.props.turma.etapaPraticaC.atendimentoList.find(atd => { return atd.idAtendimento === atendimentoPratica.idAtendimento });
    };

    getAtendimentoEtapaD = (atendimentoPratica) => {
        return this.props.turma.etapaPraticaD.atendimentoList.find(atd => { return atd.idAtendimento === atendimentoPratica.idAtendimento });
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = this.state.showTopButtons;
        let showAnexos = true;
        let render = null;
        switch (selectedAtividade.screenAtividade) {
            case 'tecnologiasDigitais':
                render = <AtividadeTecnologiasDigitais atividade={selectedAtividade.data} disabled={this.state.disabled}/>;
                showTopButtons = false;
                showAnexos = false;
                break;
            case 'avaliacao':
                render = <AtividadeAvaliacaoDoAtendimento atividade={selectedAtividade.data} disabled={this.state.disabled}/>;
                showTopButtons = false;
                showAnexos = false;
                break;
            case 'resultado':
                render = <AtividadeResultados atividade={selectedAtividade.data} atendimentoPraticaC={this.atendimentoEtapaC} atendimentoPraticaD={this.atendimentoEtapaD} 
                    turma={this.props.turma} atendimentoPratica={this.state.atendimentoPratica} showLoading={this.props.showLoading} closeLoading={this.props.closeLoading} 
                    showToast={this.props.showToast} usuarioConectado={ this.props.usuarioConectado } disabled={this.state.disabled}/>;
                showTopButtons = false;
                showAnexos = false;
                break;
            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefault
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                showTopButtons={ showTopButtons && !this.state.disabled}
                showAnexos={ showAnexos }
                renderChildren={ render } 
                clientName={ this.state.clientName }
                viewData={ this.state.viewData }
                disabled={this.state.disabled} />
        );
    };

    renderVisita = (atendimento) => {
        this.atendimentoEtapaC = this.getAtendimentoEtapaC(atendimento);
        this.atendimentoEtapaD = this.getAtendimentoEtapaD(atendimento);
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoPratica: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, showTopButtons: true });
    };

    render() {
        return (<>
            {this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCard
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Consultoria' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita }
                />
            }
            {this.state.renderTo === this.renderOptions.VISITAS &&
                <VisitasDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    etapa = {this.state.etapa}
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    saveEtapa={ this.saveEtapa }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    dontShowFinalizarMentoria={ this.state.atendimentoPratica.finalizado }
                    disabled={this.state.disabled}
                />
            }
        </>)
    };
};