import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBar from '../../../components/graphBar/graphBar';

export default class GraficoAlunoTurmaPorEstado extends Component {

    estados = { 
        'AC': 'Acre', 'AL': 'Alagoas', 'AM': 'Amazonas', 'AP': 'Amapá', 'BA': 'Bahia', 'CE': 'Ceará',
        'DF': 'Distrito Federal', 'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão', 'MG': 'Minas Gerais',
        'MS': 'Mato Grosso do Sul', 'MT': 'Mato Grosso', 'PA': 'Pará', 'PB': 'Paraíba', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'PR': 'Paraná', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte', 'RO': 'Rondônia', 
        'RR': 'Roraima', 'RS': 'Rio Grande do Sul', 'SC': 'Santa Catarina', 'SE': 'Sergipe', 'SP': 'São Paulo', 'TO': 'Tocantins'
    };

    tooltipTurma = {
        formatter: function() {
            let tooltip = `<p style="font: bold 13px Raleway; color: #707070">${this.series.name.serieName}</p><br>` +
            `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.point.category}: ${this.point.y}</p><br>` +
            `<p style="font: 13px Raleway; color: #707070">Total: ${this.point.total}</p>`;
            return tooltip;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };
    tooltipAluno = {
        formatter: function() {
            let tooltip = `<p style="font: bold 13px Raleway; color: #707070">${this.series.name.serieName}</p><br>` +
            `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.series.chart.options.fullCategories[this.point.category]}: ${this.point.y}</p><br>` +
            `<p style="font: 13px Raleway; color: #707070">Total: ${this.point.total}</p>`;
            return tooltip;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    legendAluno = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function() {
            return '<div style="cursor:auto;display:flex;align-items:center;"><div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '"></div><label style="font-weight:500;font-size:11px">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
    };
    legendTurma = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function() {
            return '<div style="cursor:auto;display:flex;align-items:center;"><div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '"></div><label style="font-weight:500;font-size:11px">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
     };

    plotOptionsAluno = {
        series: {
            states: {
                inactive: {
                  opacity: 1
                }
              },
              pointPadding: 0.25,
        },
        column: {
            stacking: 'normal',
            borderColor: null,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };

    plotOptionsTurma = {
        series: {
            states: {
                inactive: {
                  opacity: 1
                }
              },
              pointPadding: 0.25,
        },
        column: {
            stacking: 'normal',
            borderColor: null,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };
    
    constructor(props) {
        super(props);
        let categories = Object.keys(this.estados);
        this.state = {
            switch: [
                { 
                    label: 'Turmas', 
                    title: 'Turmas Brasil Mais por estado', 
                    tooltip: this.tooltipTurma, 
                    categories: categories, 
                    series: [],
                    seriesFunction: this.graficoTurma,
                    tickInterval: 20,
                    legend: this.legendTurma,
                    height: 250,
                    plotOptions: this.plotOptionsTurma,
                },
                {
                    label: 'Alunos', 
                    title: 'Alunos mentorados por estado',
                    tooltip: this.tooltipAluno, 
                    categories: categories, 
                    fullCategories: this.estados,
                    series: [],
                    seriesFunction: this.graficoAluno,
                    tickInterval: 100,
                    legend: this.legendAluno,
                    height: 250,
                    plotOptions: this.plotOptionsAluno,
                }
            ]
        };
    };

    graficoTurma = async() => {
        let series = [];
        await API.get(`rest/sistema/DashboardBrasilMais/geralAlunoTurmaPorEstado?isTurma=${true}`).then(async res => {
            let dataEspera = [];
            let dataExecucao = [];
            let dataConcluido = [];
            if (res.data != null && res.data.estados != null) {
                await res.data.estados.forEach(estado => {
                    dataEspera.push(estado[1]);
                    dataExecucao.push(estado[2]);
                    dataConcluido.push(estado[3]);
                });
                series = [{
                        name: { legendName: 'Turma em espera', serieName: 'Turma em espera'},
                        data: dataEspera,
                        color: '#B7EE8E'
                    }, {
                        name: { legendName: 'Turma em execução', serieName: 'Turma em execução'},
                        data: dataExecucao,
                        color: '#7DAE59'
                    }, {
                        name: { legendName: 'Turmas concluidas', serieName: 'Turmas concluidas'},
                        data: dataConcluido,
                        color: '#00B1E8'
                    }
                ];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series];
    };

    graficoAluno = async() => {
        let series = [];
        await API.get(`rest/sistema/DashboardBrasilMais/geralAlunoTurmaPorEstado?isTurma=${false}`).then(async res => {
            let dataAguardando = [];
            let dataMentoria = [];
            let dataMentorados = [];
            if (res.data != null && res.data.estados != null) {
                await res.data.estados.forEach(estado => {
                    dataAguardando.push(estado[1]);
                    dataMentoria.push(estado[2]);
                    dataMentorados.push(estado[3]);
            
                });
                series = [{
                    name: { legendName: 'Alunos aguardando', serieName: 'Alunos aguardando'},
                    data: dataAguardando,
                    color: '#B7EE8E'
                }, {
                    name: { legendName: 'Alunos em mentoria', serieName: 'Alunos em mentoria'},
                    data: dataMentoria,
                    color: '#7DAE59'
                }, {
                    name: { legendName: 'Alunos mentorados', serieName: 'Alunos mentorados'},
                    data: dataMentorados,
                    color: '#00B1E8'
                }
            ];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series];
    };

    render() {
        return (
            <Grid container justify={'center'}>      
                <GraphBar switch={this.state.switch} disabledSwitch={false} hasFilter={false} init={'left'} /> 
            </Grid>
        );
    };

}