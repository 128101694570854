import React, { useEffect, useState, useCallback } from 'react';
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import GenericButton from "../../components/genericButton/genericButton";
import TableRow from "@material-ui/core/TableRow";
import InputSelect from "../../components/inputselect/inputselect";
import FormField from "../../components/formField/formField";
import Page from "../../components/page/page";
import SimplePopover from "../../components/simplePopover/simplePopover";
import SimpleModal from "./../../components/modal/modal";
import { newApi as Api } from "../../comm/API";
import "./kpi.scss";

export const KpiBaseContent = (props) => {
  const ACTUAL_YEAR = new Date().getFullYear();

  useEffect(() => {
    const initialYear = 2019;
    const array = Array(ACTUAL_YEAR - initialYear + 1).fill().map((_, index) => ({id: index, year: initialYear + index}));
    setSelectedYear(array[array.length - 1]);
    setYearList(array);

    getUserInfo();
    getIsiList()
  }, []); 

  const token = localStorage.getItem("sgt:tokenJWT");

  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  const KPI_ASSESSMENT_CLASS_NAME = {
    0: "good",
    1: "bad",
    2: "constant",
    default: "default"
  }

  const KPI_ASSESSMENT = {
    0: process.env.PUBLIC_URL + "/assets/icons/bom.svg",
    1: process.env.PUBLIC_URL + "/assets/icons/ruim.svg",
    2: process.env.PUBLIC_URL + "/assets/icons/neutro.svg",
    default: process.env.PUBLIC_URL + "/assets/icons/question.svg"
  }

  const KPI_TREND = {
    0: process.env.PUBLIC_URL + "/assets/icons/aumentar.svg",
    1: process.env.PUBLIC_URL + "/assets/icons/diminuir.svg",
    2: process.env.PUBLIC_URL + "/assets/icons/permanecer.svg",
    default: process.env.PUBLIC_URL + "/assets/icons/question.svg"
  }

  const getKpiList = async (selectedUnityParam = selectedUnity) => {
    
    setShowLoading(true);

    const filterNewBack = {
      idKpiFactor: props.idKpiFactor,
      year: selectedYear && selectedYear.year || ACTUAL_YEAR,
      idUnity: selectedUnityParam.id,
    }

    const configObject = {...config, params: filterNewBack};

    const {kpiDictionaryList: kpiData} = (await Api.get("/rest/kpi", configObject)).data;

    setKpiList(kpiData);
    setYearFiltered(filterNewBack.year)
    setShowLoading(false);
  }

  const formatKpiValues = useCallback((formatType, kpiValue) => {

    const validatedKpi = kpiValue || 0;

    const KPI_TYPES = {
      1: validatedKpi,
      2: `${validatedKpi.toFixed(2)}%`,
      3: validatedKpi.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumIntegerDigits: 2
      }),
      default: 0,
    }

    return KPI_TYPES[formatType] || KPI_TYPES.default;
  }, []);

  const [userInfo, setUserInfo] = useState({});
  const [isiList, setIsiList] = useState([]);
  const [selectedUnity, setSelectedUnity] = useState(null);
  const [selectedYear, setSelectedYear] = useState({ id: 0,
    year: ACTUAL_YEAR});
  const [yearFiltered, setYearFiltered] = useState(ACTUAL_YEAR); 
  const [yearList, setYearList] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [kpiList, setKpiList] = useState([]);
  const [kpiModal, setKpiModal] = useState({});
  const [openModal, setOpenModal] = useState(false);

  const getUserInfo = useCallback(async () => {
    setShowLoading(true);

    const userProfileInfo = (await Api.get("/rest/accounts/userProfileInfo", config)).data;

    setUserInfo(userProfileInfo);
    setShowLoading(false);
  }, [userInfo])

  const getIsiList = useCallback(async () => {
    const filterNewBack = {
      take: 1000,
      page: 1,
      idUnitySpecialty: 1,
    }

    const configObject = {...config, params: filterNewBack};

    const {units: isiListData} = (await Api.get("/rest/accounts/units", configObject)).data;

    setIsiList(isiListData);
    setSelectedUnity(isiListData[{}]);

    getKpiList(isiListData[0]);
  }, [isiList, selectedUnity, kpiList])

  const getInput = useCallback((kpiType, kpiValue, key) => {

    kpiType = kpiType === 'monetário' ? 'moeda' : kpiType;

    const INPUT_LENGTH = {
      moeda: 18,
      porcentagem: 7,
      inteiro: 13
    }

    return (
      <FormField
        type={"number"}
        subtype={kpiType}
        inputValue={kpiValue ? kpiValue : ""}
        changeValue={(e) => changeInput(e, key)}
        id={`${new Date().getMilliseconds()}`}
        min="0"
        maxLength={INPUT_LENGTH[kpiType]}
        disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
      />
    );
  }, [ kpiModal ])

  const changeInput = useCallback((e, key) => {
    if (e && e.target) {
      const newKpiObject = {...kpiModal};

      newKpiObject.valueKpi[key] = isNaN(+e.target.value) || e.target.value === '' ? e.target.value : +e.target.value;

      setKpiModal(newKpiObject);
    }
  }, [kpiModal]);

  const saveKpi = useCallback(async () => {
    setShowLoading(true);

    const isUpdate = 'id' in kpiModal.valueKpi;

    if (isUpdate) {
      await Api.patch(`/rest/kpi/${kpiModal.valueKpi.id}`, kpiModal.valueKpi, config);

      setOpenModal(false);
      setKpiModal({});

      return await getKpiList();
    }

    await Api.post('/rest/kpi', kpiModal.valueKpi, config);

    setOpenModal(false);
    setKpiModal({});

    return await getKpiList();

  }, [kpiModal, config]);

  return (
    <Page
      icon={"indicadores.svg"}
      label={"KPI List"}
      usuarioConectado={props.usuarioConectado}
      showLoading={showLoading}
    >

      <Grid container id={"kpi"}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <InputSelect
                label={"ISI"}
                suggestions={isiList}
                itemLabel={"abbreviation"}
                getInput={(event) => {
                  if (event != null) {
                    setSelectedUnity(event)
                  }
                }}
                id={"isi"}
                itemKey={"id"}
                initialSelectedItem={selectedUnity}
                disabled={false}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className={"grid-right-button"}>
              <InputSelect
                label={"Ano"}
                suggestions={yearList}
                itemKey={"year"}
                itemLabel={"year"}
                initialSelectedItem={selectedYear || {year: ACTUAL_YEAR}}
                getInput={(event) => {
                  if (event != null) {
                    setSelectedYear(event);
                  }
                }}
                id={"ano"}
              />
                     
              <GenericButton
                color={"darkBlue"}
                label={"Aplicar"}
                subClass={"basic-button"}
                click={async (e) => {
                  await getKpiList();
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <h2 className={"title"}>{props.title}</h2>
          <Table id="tableBody">
            <TableHead>
              <TableRow>
                <TableCell className="indicator-kpi">Indicador</TableCell>
                <TableCell>Direcionador estratégico</TableCell>
                <TableCell>
                  <p>
                      Passado <span>{yearFiltered - 1 || ACTUAL_YEAR - 1}</span>
                  </p>
                </TableCell>
                <TableCell>
                  <p>
                    Presente <span> {yearFiltered || ACTUAL_YEAR}</span>
                  </p>
                </TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Tendência</TableCell>
                <TableCell>
                  <p className="spacing-field">
                    Futuro <span> Meta {yearFiltered || ACTUAL_YEAR}</span>
                  </p>
                </TableCell>
                <TableCell>
                  <p className="spacing-field">
                    Futuro <span> Meta {yearFiltered + 1 || ACTUAL_YEAR + 1}</span>
                  </p>
                </TableCell>
                
              </TableRow>
            </TableHead>
            <TableBody>
              {kpiList.map((kpi, index) =>
                (
                  <TableRow
                    key={index}
                    className={KPI_ASSESSMENT_CLASS_NAME[kpi.valueKpi.assessment] || KPI_ASSESSMENT_CLASS_NAME.default}
                    onClick={() => {
                      const kpiCopy = {...kpi};
                      kpiCopy.valueKpi = {...kpi.valueKpi};
                      setKpiModal(kpiCopy);
                      setOpenModal(true);
                    }}
                  >
                    <TableCell className="cell-definition">
                      <SimplePopover
                        content={kpi.definition}
                      />
                      {kpi.name}
                    </TableCell>
                    <TableCell>
                      {kpi.bmf}
                    </TableCell>
                    <TableCell>
                      {formatKpiValues(kpi.formatKpiValue.id, kpi.valueKpi.actualPast)}
                    </TableCell>
                    <TableCell>
                      {formatKpiValues(kpi.formatKpiValue.id, kpi.valueKpi.actualCurrent)}
                    </TableCell>
                    <TableCell>
                      <img
                        src={KPI_ASSESSMENT[kpi.valueKpi.assessment] || KPI_ASSESSMENT.default}
                        className={"imagem"}
                        alt={"assessment"}
                      />
                    </TableCell>
                    <TableCell>
                      <img
                        src={KPI_TREND[kpi.valueKpi.trend] || KPI_TREND.default}
                        className={"imagem"}
                        alt={"trend"}
                      />
                    </TableCell>
                    <TableCell>
                      {formatKpiValues(kpi.formatKpiValue.id, kpi.valueKpi.targetCurrent)}
                    </TableCell>
                    <TableCell>
                      {formatKpiValues(kpi.formatKpiValue.id, kpi.valueKpi.targetFuture)}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </Grid>
        {openModal && kpiModal && userInfo &&  (
          <SimpleModal
            isOpen={openModal && kpiModal}
            handleClose={() => {
              setOpenModal(false);
              setKpiModal({});
            }}
            type={"info"}
            width={"600px"}
          >
            <div className={"modal modal-kpi"}>
              <h2>{kpiModal.name}</h2>
              <Grid container spacing={3}>
                <Grid item xs={12} className={"info-basic"}>
                  <Grid>
                    <p className={"category"}>Passado</p>
                    <div className={"flex-infos"}>
                      <p>{yearFiltered - 1}</p>
                        <div className={"sub-category-value"}>
                          {formatKpiValues(kpiModal.formatKpiValue.id, kpiModal.valueKpi.actualPast)}
                        </div>
                    </div>
                  </Grid>
                  <Grid>
                    <p className={"category"}>Presente</p>
                    <div className={"flex-infos"}>
                      <p>{yearFiltered}</p>
                      {kpiModal.idKpiType === 2 ? (
                        <div className={"sub-category-value"}>
                          {formatKpiValues(kpiModal.formatKpiValue.id, kpiModal.valueKpi.actualCurrent)}
                        </div>
                      ) : (
                        <div className={"sub-category-value"}>
                          {getInput(kpiModal.formatKpiValue.name, kpiModal.valueKpi.actualCurrent, 'actualCurrent')}
                        </div>
                      )}
                    </div>
                    
                  </Grid>
                  <Grid>
                    <p className={"category"}>Futuro</p>
                    <div className={"flex-infos"}>
                      <p className={"sub-category"}>
                        Target - {yearFiltered}
                      </p>
                      <div className={"sub-category-value"}>
                        {getInput(kpiModal.formatKpiValue.name, kpiModal.valueKpi.targetCurrent, 'targetCurrent')}
                      </div>
                    </div>
                    <div className={"flex-infos"}>
                      <p className={"sub-category"}>
                        Target - {yearFiltered + 1}
                      </p>
                      <div className={"sub-category-value"}>
                        {getInput(kpiModal.formatKpiValue.name, kpiModal.valueKpi.targetFuture, 'targetFuture')}
                      </div>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container id={"radio-buttons"}>
                  <Grid item xs={6}>
                      <label className={"radio-label"}>Avaliação</label>
                      <FormField
                        type={"radio"}
                        radioLabel={
                          <div>
                            <span>Bom</span>
                            <img src={KPI_ASSESSMENT[0]} alt="bom" />
                          </div>
                        }
                        checked={kpiModal.valueKpi.assessment === 0}
                        inputValue={0}
                        changeValue={(e) => changeInput(e, 'assessment')}
                        name={"assessment"}
                        disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
                      />
                      <FormField
                        type={"radio"}
                        radioLabel={
                          <div>
                            <span>Regular</span>
                            <img src={KPI_ASSESSMENT[2]} alt="neutro" />
                          </div>
                        }
                        checked={kpiModal.valueKpi.assessment === 2}
                        inputValue={2}
                        changeValue={(e) => changeInput(e, 'assessment')}
                        name={"assessment"}
                        disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
                      />
                      <FormField
                        type={"radio"}
                        radioLabel={
                          <div>
                            <span>Ruim</span>
                            <img src={KPI_ASSESSMENT[1]} alt="ruim" />
                          </div>
                        }
                        checked={kpiModal.valueKpi.assessment === 1}
                        inputValue={1}
                        changeValue={(e) => changeInput(e, 'assessment')}
                        name={"assessment"}
                        disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <label className={"radio-label"}>Tendência</label>
                      <FormField
                        type={"radio"}
                        radioLabel={
                          <div>
                            <span>Aumentar</span>
                            <img src={KPI_TREND[0]} alt="aumentar" />
                          </div>
                        }
                        checked={kpiModal.valueKpi.trend === 0}
                        inputValue={0}
                        changeValue={(e) => changeInput(e, 'trend')}
                        name={"trend"}
                        disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
                      />
                      <FormField
                        type={"radio"}
                        radioLabel={
                          <div>
                            <span>Permanecer</span>
                            <img src={KPI_TREND[2]} alt="permanecer" />
                          </div>
                        }
                        checked={kpiModal.valueKpi.trend === 2}
                        inputValue={2}
                        changeValue={(e) => changeInput(e, 'trend')}
                        name={"trend"}
                        disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
                      />
                      <FormField
                        type={"radio"}
                        radioLabel={
                          <div>
                            <span>Diminuir</span>
                            <img src={KPI_TREND[1]} alt="diminuir" />
                          </div>
                        }
                        checked={kpiModal.valueKpi.trend === 1}
                        inputValue={1}
                        changeValue={(e) => changeInput(e, 'trend')}
                        name={"trend"}
                        disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
                      />
                    </Grid>
                   
                  </Grid>
                  <Grid item>
                    <FormField
                      type={"textarea"}
                      id={"comment"}
                      label={
                        "Observações & Explicações (Comentários dos ISIs para explicar os números)"
                      }
                      inputValue={kpiModal.valueKpi.comment}
                      changeValue={(e) => changeInput(e, 'comment')}
                      disabled={userInfo && userInfo.unity && userInfo.unity.typeUnity && userInfo.unity.typeUnity.acronym === 'DN'}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={"rodape"}>
                  <GenericButton
                    color={"darkGrey-outline"}
                    label={"Cancelar"}
                    subClass={"basic-button"}
                    click={(e) => {
                      setOpenModal(false);
                      setKpiModal({});
                    }}
                  />
                  <GenericButton
                    color={"darkBlue"}
                    label={"Salvar"}
                    subClass={"basic-button"}
                    click={saveKpi}
                  />
                </Grid>
              </Grid>
            </div>
          </SimpleModal>
        )}
      </Grid>
    </Page>
  )
}