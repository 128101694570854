import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../comm/API';
import DashboardCard from '../dashboardCard/dashboardCard';

export default class IndicatorCard extends Component {

    constructor(props) {
        super(props);
        this.state = { indicadores: [] };

        API.get('rest/sistema/DashboardBrasilMais/indicadores').then(res => {
			this.setState({ indicadores: res.data })
		}).catch(error => {
			console.log(error);
			this.showToast('Erro ao buscar cards do dashboard. Favor, entrar em contato com o suporte.', 'error');
		});
    }
    
    getCard(icon, description, infoCard, value, lowValue) {
        return(
            <Grid container>
                <Grid item xs={12} className={'card'}>
                    <Grid container direction={'column'} justify={'center'} className={'card-light-gray'}>
                        <Grid item>
                            <img className={'icon-sector'} src={`./../../assets/icons/${icon}.svg`} alt=''/>
                        </Grid>
                        <Grid item className={'font-sector'}>{description}</Grid>
                    </Grid>
                    <Grid item xs={12}>                        
                        <DashboardCard color={'card-green'} text={infoCard} value={value} />
                        { (icon === 'produtividade') && 
                            <Grid item xs={12}>
                                <DashboardCard color={'card-orange'} text={'Número de atendimentos abaixo da meta'} value={lowValue} /> 
                            </Grid> }
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        let infoMedia = 'Média estadual ';

        if(this.props.profile === 'DN') {
            infoMedia = 'Média nacional ';
        } else if (this.props.profile === 'UO') {
            infoMedia = 'Média '
        }

        const indicadores = this.state.indicadores;
        return (
            <Grid container spacing={3} >
                <Grid item xs={12} justify={'center'}>
                    <Grid item className={'font-title-indicators'}>{this.props.title}</Grid>
                    <Grid item xs={12} className={'line'}></Grid>
                </Grid>          
                <Grid item xs={12} md={3} xl={3} className={'dashboard-cards'}>
                    {this.getCard("produtividade", "AUMENTO DA PRODUTIVIDADE INDUSTRIAL", 
                    infoMedia + 'do aumento de produtividade', (indicadores[0] ? indicadores[0].toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : indicadores[0]) + '%', indicadores[4])}
                </Grid>
                <Grid item xs={12} md={3} xl={3} className={'dashboard-cards'}>
                    {this.getCard("payback", "PAYBACK", infoMedia + 'do tempo de payback', (indicadores[3] ? indicadores[3].toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : indicadores[3]) + ' meses')}
                </Grid>
                <Grid item xs={12} md={3} xl={3} className={'dashboard-cards'}>
                    {this.getCard("movimento", "MOVIMENTO", infoMedia + 'da redução de movimento', (indicadores[1] ? indicadores[1].toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : indicadores[1]) + '%')}
                </Grid>
                <Grid item xs={12} md={3} xl={3} className={'dashboard-cards'}>
                    {this.getCard("qualidade", "QUALIDADE", infoMedia + 'do aumento da qualidade', (indicadores[2] ? indicadores[2].toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : indicadores[2]) + '%')}
                </Grid>
            </Grid>
        );
    }
}

