import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import GenericButton from '../../components/genericButton/genericButton';
import SimpleModal from './../../components/modal/modal';
import FormField from '../../components/formField/formField';
import API from '../../comm/API';
import Chip from "@material-ui/core/Chip";
import Icon from '@material-ui/core/Icon';
import Loading from '../../components/loading/loading';
import Toast from '../../components/toast/toast';
import DownloadFiles from '../../comm/DownloadFiles';

export default class PesquisaSatisfacao extends Component {

    state = {anexoIndicadorList: this.props.atendimento.anexoIndicadorProdutividadeList}

    pesquisa = {
        contentquality: null, presentationresults: null, requirementsfulfillment: null,
        teamavailability: null, teamcommitment: null, teamcommunicationskills: null,
        projectmanagement: null, technologicalcompetencies: null, knowledgerelatedfield: null,
        deadlinesobservance: null, postponementsdealing: null, timeframe: null,
        valuebenefit: null, priceperformanceratio: null, impact: null,
        executeprojectagain: true, recommendation: null, comments: null,
        orderprojectdeliverables: null, orderinteractionorder: null,
        orderprofessionalcompetence: null, ordertimeframe: null, orderimpact: null, anexourl: null,
    }
    showErrors = false;

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    constructor(props) {
        super(props);
        if (this.props.atendimento.pesquisaSatisfacao != null) {
            this.getPesquisaModel();
        } else {
            this.getDefaultModel();
        }
    }

    getPesquisaModel = () => {
        this.pesquisa.anexourl = this.props.atendimento.pesquisaSatisfacao.anexourl;
        this.pesquisa.contentquality = this.props.atendimento.pesquisaSatisfacao.contentquality;
        this.pesquisa.presentationresults = this.props.atendimento.pesquisaSatisfacao.presentationresults;
        this.pesquisa.requirementsfulfillment = this.props.atendimento.pesquisaSatisfacao.requirementsfulfillment;
        this.pesquisa.teamavailability = this.props.atendimento.pesquisaSatisfacao.teamavailability;
        this.pesquisa.teamcommitment = this.props.atendimento.pesquisaSatisfacao.teamcommitment;
        this.pesquisa.teamcommunicationskills = this.props.atendimento.pesquisaSatisfacao.teamcommunicationskills;
        this.pesquisa.projectmanagement = this.props.atendimento.pesquisaSatisfacao.projectmanagement;
        this.pesquisa.technologicalcompetencies = this.props.atendimento.pesquisaSatisfacao.technologicalcompetencies;
        this.pesquisa.knowledgerelatedfield = this.props.atendimento.pesquisaSatisfacao.knowledgerelatedfield;
        this.pesquisa.deadlinesobservance = this.props.atendimento.pesquisaSatisfacao.deadlinesobservance;
        this.pesquisa.postponementsdealing = this.props.atendimento.pesquisaSatisfacao.postponementsdealing;
        this.pesquisa.timeframe = this.props.atendimento.pesquisaSatisfacao.timeframe;
        this.pesquisa.valuebenefit = this.props.atendimento.pesquisaSatisfacao.valuebenefit;
        this.pesquisa.priceperformanceratio = this.props.atendimento.pesquisaSatisfacao.priceperformanceratio;
        this.pesquisa.impact = this.props.atendimento.pesquisaSatisfacao.impact;
        this.pesquisa.executeprojectagain = this.props.atendimento.pesquisaSatisfacao.executeprojectagain;
        this.pesquisa.recommendation = this.props.atendimento.pesquisaSatisfacao.recommendation;
        this.pesquisa.comments = this.props.atendimento.pesquisaSatisfacao.comments;
        this.pesquisa.orderprojectdeliverables = this.props.atendimento.pesquisaSatisfacao.orderprojectdeliverables;
        this.pesquisa.orderinteractionorder = this.props.atendimento.pesquisaSatisfacao.orderinteractionorder;
        this.pesquisa.orderprofessionalcompetence = this.props.atendimento.pesquisaSatisfacao.orderprofessionalcompetence;
        this.pesquisa.ordertimeframe = this.props.atendimento.pesquisaSatisfacao.ordertimeframe;
        this.pesquisa.orderimpact = this.props.atendimento.pesquisaSatisfacao.orderimpact;
    }
    getDefaultModel = () => {
        this.pesquisa.anexourl = null;
        this.pesquisa.contentquality = null;
        this.pesquisa.presentationresults = null;
        this.pesquisa.requirementsfulfillment = null;
        this.pesquisa.teamavailability = null;
        this.pesquisa.teamcommitment = null;
        this.pesquisa.teamcommunicationskills = null;
        this.pesquisa.projectmanagement = null;
        this.pesquisa.technologicalcompetencies = null;
        this.pesquisa.knowledgerelatedfield = null;
        this.pesquisa.deadlinesobservance = null;
        this.pesquisa.postponementsdealing = null;
        this.pesquisa.timeframe = null;
        this.pesquisa.valuebenefit = null;
        this.pesquisa.priceperformanceratio = null;
        this.pesquisa.impact = null;
        this.pesquisa.executeprojectagain = null;
        this.pesquisa.recommendation = null;
        this.pesquisa.comments = "";
        this.pesquisa.orderprojectdeliverables = undefined;
        this.pesquisa.orderinteractionorder = undefined;
        this.pesquisa.orderprofessionalcompetence = undefined;
        this.pesquisa.ordertimeframe = undefined;
        this.pesquisa.orderimpact = undefined;
    }

    changeInput = (e) => {
        if (e != null && e.target != null) {
            if (e.target.type === 'radio') {
                if (e.target.name === "executeprojectagain") {
                    this.pesquisa[e.target.name] = e.target.value === "true" ? true : false;
                } else {
                    this.pesquisa[e.target.name] = parseInt(e.target.value);
                }
            } else if (e.target.type === 'number') {
                if (parseInt(e.target.value) >= 1 && parseInt(e.target.value) <= 5) {
                    this.pesquisa[e.target.id] = parseInt(e.target.value);
                } else {
                    this.pesquisa[e.target.id] = undefined;
                }
            } else {
                this.pesquisa[e.target.id] = e.target.value;
            }
        }
        this.setState(this.state);
    }
    clearPesquisa = () => {
        this.showErrors = false;
        if (this.props.atendimento.pesquisaSatisfacao != null) {
            this.getPesquisaModel();
        } else {
            this.getDefaultModel();
        }
        this.setState(this.state);
        this.props.changeModal('modalPesquisa', false);
    }
    validatePesquisa() {
        return !this.pesquisa.contentquality || !this.pesquisa.presentationresults || !this.pesquisa.requirementsfulfillment
            || !this.pesquisa.teamavailability || !this.pesquisa.teamcommitment || !this.pesquisa.teamcommunicationskills
            || !this.pesquisa.projectmanagement || !this.pesquisa.technologicalcompetencies || !this.pesquisa.knowledgerelatedfield
            || !this.pesquisa.deadlinesobservance || !this.pesquisa.postponementsdealing || !this.pesquisa.timeframe
            || !this.pesquisa.valuebenefit || !this.pesquisa.priceperformanceratio || !this.pesquisa.impact
            || !this.pesquisa.recommendation || !this.pesquisa.orderprojectdeliverables
            || !this.pesquisa.orderinteractionorder || !this.pesquisa.orderprofessionalcompetence
            || !this.pesquisa.ordertimeframe || !this.pesquisa.orderimpact;
    }
    savePesquisa = () => {

        this.showErrors = this.validatePesquisa();
        this.setState(this.state);
        
        if (!this.showErrors) {
            this.showLoading();
            var atendimento = this.props.atendimento
            atendimento.pesquisaSatisfacao = this.pesquisa;
            API.post('rest/gestao/Atendimento/' + atendimento.id, atendimento).then(res => {
                this.closeLoading();
                this.props.changeModalShowToast('modalPesquisa', false, 'Pesquisa respondida com sucesso!' );  
            }).catch(error => {
                this.closeLoading();
                this.props.showToast('Erro ao salvar pesquisa. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }
    removeAnexo = (anexo) => {
        this.showLoading();
        var atendimento = this.props.atendimento;
        let anexoIndicadorListCopy = this.state.anexoIndicadorList
        let index = anexoIndicadorListCopy.findIndex(prop =>{
            return prop.url === anexo.url;
        }); 
        if (index !== -1) {
            anexoIndicadorListCopy.splice(index, 1);
            atendimento.anexoIndicadorProdutividadeList = anexoIndicadorListCopy;
        }
        API.post('rest/gestao/Atendimento/' + atendimento.id, atendimento).then(res => {
            this.setState({anexoIndicadorList: anexoIndicadorListCopy});
            this.closeLoading();
            this.showToast('Anexo excluído com sucesso!', 'success');
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao deletar anexo. Favor, entrar em contato com o suporte.', 'error');
        });
    }
    uploadAnexo = (e) => {
        
        let file = e.target.files[0];
        this.setState(this.state);
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if(file.size > 10000000){
            this.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }

        this.showLoading();
        API.post('rest/upload/atendimento/anexo/' + this.props.atendimento.id + '/pesquisa_satisfacao_pdi', formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(res => {
            this.setState({anexoIndicadorList : res.data.anexoIndicadorProdutividadeList});
            this.closeLoading();
            this.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao arqivar arquivo. Favor, entrar em contato com o suporte.', 'error');
        });
    }
    render() {
        return (
            <Grid item xs={12}>
                <div className={'greyDiv'}>
                    <GenericButton color={'darkBlue'} subClass={'basic-button'} click={() => this.props.changeModal('modalPesquisa', true)}
                        label={'Responder pesquisa'} />
                    <h2>Pesquisa de Satisfação</h2>
                </div>
                <div><Toast getFunctions={this.getFunctions} /><SimpleModal
                    isOpen={this.props.modalPesquisa}
                    handleClose={() => this.props.changeModal('modalPesquisa', false)}
                    type={'info'}
                    width={'900px'}>
                    <Loading getFunctions={this.getFunctions} />
                    <div className={'modal modal-pesquisa'}>
                        <h2>{'Pesquisa de satisfação'}</h2>

                        <Grid container>

                            <Grid container style={{ margin: '16px 0px 32px' }}>
                                <Grid item xs={12} md={4}><b>Nome do projeto</b></Grid>
                                <Grid item xs={12} md={8}>{this.props.atendimento.titulo}</Grid>
                                <Grid item xs={12} md={4}><b> Número do projeto</b></Grid>
                                <Grid item xs={12} md={8}>{this.props.atendimento.numero}</Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>01.</label>
                                        <label>Quão satisfeito você está com as entregas do projeto?</label>
                                    </Grid>
                                    <Grid item xs={8} className={'labels'}>
                                        <div><label>Muito satisfeito</label></div>
                                        <div><label>Satisfeito</label></div>
                                        <div><label>Neutro</label></div>
                                        <div><label>Insatisfeito</label></div>
                                        <div><label>Muito insatisfeito</label></div>
                                    </Grid>
                                </Grid>
                                <Grid className={'pesquisa-body'}>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.contentquality ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com a qualidade do conteúdo</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.contentquality === 5} inputValue={5} changeValue={this.changeInput} name={'contentquality'} />
                                            <FormField type={'radio'} checked={this.pesquisa.contentquality === 4} inputValue={4} changeValue={this.changeInput} name={'contentquality'} />
                                            <FormField type={'radio'} checked={this.pesquisa.contentquality === 3} inputValue={3} changeValue={this.changeInput} name={'contentquality'} />
                                            <FormField type={'radio'} checked={this.pesquisa.contentquality === 2} inputValue={2} changeValue={this.changeInput} name={'contentquality'} />
                                            <FormField type={'radio'} checked={this.pesquisa.contentquality === 1} inputValue={1} changeValue={this.changeInput} name={'contentquality'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.presentationresults ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com a apresentação dos resultados</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.presentationresults === 5} inputValue={5} changeValue={this.changeInput} name={'presentationresults'} />
                                            <FormField type={'radio'} checked={this.pesquisa.presentationresults === 4} inputValue={4} changeValue={this.changeInput} name={'presentationresults'} />
                                            <FormField type={'radio'} checked={this.pesquisa.presentationresults === 3} inputValue={3} changeValue={this.changeInput} name={'presentationresults'} />
                                            <FormField type={'radio'} checked={this.pesquisa.presentationresults === 2} inputValue={2} changeValue={this.changeInput} name={'presentationresults'} />
                                            <FormField type={'radio'} checked={this.pesquisa.presentationresults === 1} inputValue={1} changeValue={this.changeInput} name={'presentationresults'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.requirementsfulfillment ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com o cumprimento dos requisitos do projeto</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.requirementsfulfillment === 5} inputValue={5} changeValue={this.changeInput} name={'requirementsfulfillment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.requirementsfulfillment === 4} inputValue={4} changeValue={this.changeInput} name={'requirementsfulfillment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.requirementsfulfillment === 3} inputValue={3} changeValue={this.changeInput} name={'requirementsfulfillment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.requirementsfulfillment === 2} inputValue={2} changeValue={this.changeInput} name={'requirementsfulfillment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.requirementsfulfillment === 1} inputValue={1} changeValue={this.changeInput} name={'requirementsfulfillment'} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>02.</label>
                                        <label>Quão satisfeito você está na interação com a equipe do projeto?</label>
                                    </Grid>
                                    <Grid item xs={8} className={'labels'}>
                                        <div><label>Muito satisfeito</label></div>
                                        <div><label>Satisfeito</label></div>
                                        <div><label>Neutro</label></div>
                                        <div><label>Insatisfeito</label></div>
                                        <div><label>Muito insatisfeito</label></div>
                                    </Grid>
                                </Grid>
                                <Grid className={'pesquisa-body'}>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.teamavailability ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com a disponibilidade da equipe</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.teamavailability === 5} inputValue={5} changeValue={this.changeInput} name={'teamavailability'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamavailability === 4} inputValue={4} changeValue={this.changeInput} name={'teamavailability'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamavailability === 3} inputValue={3} changeValue={this.changeInput} name={'teamavailability'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamavailability === 2} inputValue={2} changeValue={this.changeInput} name={'teamavailability'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamavailability === 1} inputValue={1} changeValue={this.changeInput} name={'teamavailability'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.teamcommitment ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com o comprometimento da equipe</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommitment === 5} inputValue={5} changeValue={this.changeInput} name={'teamcommitment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommitment === 4} inputValue={4} changeValue={this.changeInput} name={'teamcommitment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommitment === 3} inputValue={3} changeValue={this.changeInput} name={'teamcommitment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommitment === 2} inputValue={2} changeValue={this.changeInput} name={'teamcommitment'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommitment === 1} inputValue={1} changeValue={this.changeInput} name={'teamcommitment'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.teamcommunicationskills ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com as habilidades de comunicação da equipe</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommunicationskills === 5} inputValue={5} changeValue={this.changeInput} name={'teamcommunicationskills'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommunicationskills === 4} inputValue={4} changeValue={this.changeInput} name={'teamcommunicationskills'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommunicationskills === 3} inputValue={3} changeValue={this.changeInput} name={'teamcommunicationskills'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommunicationskills === 2} inputValue={2} changeValue={this.changeInput} name={'teamcommunicationskills'} />
                                            <FormField type={'radio'} checked={this.pesquisa.teamcommunicationskills === 1} inputValue={1} changeValue={this.changeInput} name={'teamcommunicationskills'} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>03.</label>
                                        <label>Quão satisfeito você está com as competências profissionais da equipe do projeto?</label>
                                    </Grid>
                                    <Grid item xs={8} className={'labels'}>
                                        <div><label>Muito satisfeito</label></div>
                                        <div><label>Satisfeito</label></div>
                                        <div><label>Neutro</label></div>
                                        <div><label>Insatisfeito</label></div>
                                        <div><label>Muito insatisfeito</label></div>
                                    </Grid>
                                </Grid>
                                <Grid className={'pesquisa-body'}>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.projectmanagement ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com a gestão do projeto</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.projectmanagement === 5} inputValue={5} changeValue={this.changeInput} name={'projectmanagement'} />
                                            <FormField type={'radio'} checked={this.pesquisa.projectmanagement === 4} inputValue={4} changeValue={this.changeInput} name={'projectmanagement'} />
                                            <FormField type={'radio'} checked={this.pesquisa.projectmanagement === 3} inputValue={3} changeValue={this.changeInput} name={'projectmanagement'} />
                                            <FormField type={'radio'} checked={this.pesquisa.projectmanagement === 2} inputValue={2} changeValue={this.changeInput} name={'projectmanagement'} />
                                            <FormField type={'radio'} checked={this.pesquisa.projectmanagement === 1} inputValue={1} changeValue={this.changeInput} name={'projectmanagement'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.technologicalcompetencies ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com as competências tecnológicas da equipe</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.technologicalcompetencies === 5} inputValue={5} changeValue={this.changeInput} name={'technologicalcompetencies'} />
                                            <FormField type={'radio'} checked={this.pesquisa.technologicalcompetencies === 4} inputValue={4} changeValue={this.changeInput} name={'technologicalcompetencies'} />
                                            <FormField type={'radio'} checked={this.pesquisa.technologicalcompetencies === 3} inputValue={3} changeValue={this.changeInput} name={'technologicalcompetencies'} />
                                            <FormField type={'radio'} checked={this.pesquisa.technologicalcompetencies === 2} inputValue={2} changeValue={this.changeInput} name={'technologicalcompetencies'} />
                                            <FormField type={'radio'} checked={this.pesquisa.technologicalcompetencies === 1} inputValue={1} changeValue={this.changeInput} name={'technologicalcompetencies'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.knowledgerelatedfield ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com o conhecimento na área relacionada ao projeto</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.knowledgerelatedfield === 5} inputValue={5} changeValue={this.changeInput} name={'knowledgerelatedfield'} />
                                            <FormField type={'radio'} checked={this.pesquisa.knowledgerelatedfield === 4} inputValue={4} changeValue={this.changeInput} name={'knowledgerelatedfield'} />
                                            <FormField type={'radio'} checked={this.pesquisa.knowledgerelatedfield === 3} inputValue={3} changeValue={this.changeInput} name={'knowledgerelatedfield'} />
                                            <FormField type={'radio'} checked={this.pesquisa.knowledgerelatedfield === 2} inputValue={2} changeValue={this.changeInput} name={'knowledgerelatedfield'} />
                                            <FormField type={'radio'} checked={this.pesquisa.knowledgerelatedfield === 1} inputValue={1} changeValue={this.changeInput} name={'knowledgerelatedfield'} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>04.</label>
                                        <label>Quão satisfeito você está com o cumprimento do cronograma do projeto?</label>
                                    </Grid>
                                    <Grid item xs={8} className={'labels'}>
                                        <div><label>Muito satisfeito</label></div>
                                        <div><label>Satisfeito</label></div>
                                        <div><label>Neutro</label></div>
                                        <div><label>Insatisfeito</label></div>
                                        <div><label>Muito insatisfeito</label></div>
                                    </Grid>
                                </Grid>
                                <Grid className={'pesquisa-body'}>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.deadlinesobservance ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com o cumprimento de prazos</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.deadlinesobservance === 5} inputValue={5} changeValue={this.changeInput} name={'deadlinesobservance'} />
                                            <FormField type={'radio'} checked={this.pesquisa.deadlinesobservance === 4} inputValue={4} changeValue={this.changeInput} name={'deadlinesobservance'} />
                                            <FormField type={'radio'} checked={this.pesquisa.deadlinesobservance === 3} inputValue={3} changeValue={this.changeInput} name={'deadlinesobservance'} />
                                            <FormField type={'radio'} checked={this.pesquisa.deadlinesobservance === 2} inputValue={2} changeValue={this.changeInput} name={'deadlinesobservance'} />
                                            <FormField type={'radio'} checked={this.pesquisa.deadlinesobservance === 1} inputValue={1} changeValue={this.changeInput} name={'deadlinesobservance'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.postponementsdealing ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com a negociação de adiamentos e mudanças</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.postponementsdealing === 5} inputValue={5} changeValue={this.changeInput} name={'postponementsdealing'} />
                                            <FormField type={'radio'} checked={this.pesquisa.postponementsdealing === 4} inputValue={4} changeValue={this.changeInput} name={'postponementsdealing'} />
                                            <FormField type={'radio'} checked={this.pesquisa.postponementsdealing === 3} inputValue={3} changeValue={this.changeInput} name={'postponementsdealing'} />
                                            <FormField type={'radio'} checked={this.pesquisa.postponementsdealing === 2} inputValue={2} changeValue={this.changeInput} name={'postponementsdealing'} />
                                            <FormField type={'radio'} checked={this.pesquisa.postponementsdealing === 1} inputValue={1} changeValue={this.changeInput} name={'postponementsdealing'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.timeframe ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com os prazos do cronograma proposto</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.timeframe === 5} inputValue={5} changeValue={this.changeInput} name={'timeframe'} />
                                            <FormField type={'radio'} checked={this.pesquisa.timeframe === 4} inputValue={4} changeValue={this.changeInput} name={'timeframe'} />
                                            <FormField type={'radio'} checked={this.pesquisa.timeframe === 3} inputValue={3} changeValue={this.changeInput} name={'timeframe'} />
                                            <FormField type={'radio'} checked={this.pesquisa.timeframe === 2} inputValue={2} changeValue={this.changeInput} name={'timeframe'} />
                                            <FormField type={'radio'} checked={this.pesquisa.timeframe === 1} inputValue={1} changeValue={this.changeInput} name={'timeframe'} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>05.</label>
                                        <label>Quão satisfeito você está com o impacto do projeto?</label>
                                    </Grid>
                                    <Grid item xs={8} className={'labels'}>
                                        <div><label>Muito satisfeito</label></div>
                                        <div><label>Satisfeito</label></div>
                                        <div><label>Neutro</label></div>
                                        <div><label>Insatisfeito</label></div>
                                        <div><label>Muito insatisfeito</label></div>
                                    </Grid>
                                </Grid>
                                <Grid className={'pesquisa-body'}>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.valuebenefit ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com o valor/benefício do projeto</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.valuebenefit === 5} inputValue={5} changeValue={this.changeInput} name={'valuebenefit'} />
                                            <FormField type={'radio'} checked={this.pesquisa.valuebenefit === 4} inputValue={4} changeValue={this.changeInput} name={'valuebenefit'} />
                                            <FormField type={'radio'} checked={this.pesquisa.valuebenefit === 3} inputValue={3} changeValue={this.changeInput} name={'valuebenefit'} />
                                            <FormField type={'radio'} checked={this.pesquisa.valuebenefit === 2} inputValue={2} changeValue={this.changeInput} name={'valuebenefit'} />
                                            <FormField type={'radio'} checked={this.pesquisa.valuebenefit === 1} inputValue={1} changeValue={this.changeInput} name={'valuebenefit'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.priceperformanceratio ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com a relação preço-desempenho</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.priceperformanceratio === 5} inputValue={5} changeValue={this.changeInput} name={'priceperformanceratio'} />
                                            <FormField type={'radio'} checked={this.pesquisa.priceperformanceratio === 4} inputValue={4} changeValue={this.changeInput} name={'priceperformanceratio'} />
                                            <FormField type={'radio'} checked={this.pesquisa.priceperformanceratio === 3} inputValue={3} changeValue={this.changeInput} name={'priceperformanceratio'} />
                                            <FormField type={'radio'} checked={this.pesquisa.priceperformanceratio === 2} inputValue={2} changeValue={this.changeInput} name={'priceperformanceratio'} />
                                            <FormField type={'radio'} checked={this.pesquisa.priceperformanceratio === 1} inputValue={1} changeValue={this.changeInput} name={'priceperformanceratio'} />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.impact ?
                                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'} alt='errorIcon' />
                                                : null}
                                            <label>Com o impacto esperado e percebido</label>
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.impact === 5} inputValue={5} changeValue={this.changeInput} name={'impact'} />
                                            <FormField type={'radio'} checked={this.pesquisa.impact === 4} inputValue={4} changeValue={this.changeInput} name={'impact'} />
                                            <FormField type={'radio'} checked={this.pesquisa.impact === 3} inputValue={3} changeValue={this.changeInput} name={'impact'} />
                                            <FormField type={'radio'} checked={this.pesquisa.impact === 2} inputValue={2} changeValue={this.changeInput} name={'impact'} />
                                            <FormField type={'radio'} checked={this.pesquisa.impact === 1} inputValue={1} changeValue={this.changeInput} name={'impact'} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>06.</label>
                                        <label>Você executaria projetos com o instituto novamente?</label>
                                    </Grid>
                                    <Grid item xs={8} style={{ display: 'flex' }}>
                                        <FormField type={'radio'} label={'Sim'} checked={this.pesquisa.executeprojectagain} inputValue={true} changeValue={this.changeInput} name={'executeprojectagain'} />
                                        <FormField type={'radio'} label={'Não'} checked={!this.pesquisa.executeprojectagain} inputValue={false} changeValue={this.changeInput} name={'executeprojectagain'} />
                                    </Grid>
                                </Grid>

                                <Grid className={'pesquisa-body'}></Grid>
                                <br></br><br></br>

                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>Qual é a probabilidade de você recomendar o instituto para parceiros de negócios e/ou colegas?</label>
                                    </Grid>
                                    <Grid item xs={8} className={'labels'}>
                                        <div><label>Muito provável</label></div>
                                        <div><label>Provável</label></div>
                                        <div><label>Talvez</label></div>
                                        <div><label>Improvável</label></div>
                                        <div><label>Muito improvável</label></div>
                                    </Grid>
                                </Grid>
                                <Grid className={'pesquisa-body'}>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && !this.pesquisa.recommendation ?
                                                <label className={'font-red'}>Campo obrigatório</label> : null}
                                        </Grid>
                                        <Grid item xs={8} className={'radios'}>
                                            <FormField type={'radio'} checked={this.pesquisa.recommendation === 5} inputValue={5} changeValue={this.changeInput} name={'recommendation'} />
                                            <FormField type={'radio'} checked={this.pesquisa.recommendation === 4} inputValue={4} changeValue={this.changeInput} name={'recommendation'} />
                                            <FormField type={'radio'} checked={this.pesquisa.recommendation === 3} inputValue={3} changeValue={this.changeInput} name={'recommendation'} />
                                            <FormField type={'radio'} checked={this.pesquisa.recommendation === 2} inputValue={2} changeValue={this.changeInput} name={'recommendation'} />
                                            <FormField type={'radio'} checked={this.pesquisa.recommendation === 1} inputValue={1} changeValue={this.changeInput} name={'recommendation'} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid container className={'pesquisa-head'}>
                                    <Grid item xs={4} className={'question'}>
                                        <label>De 1 a 5 (sendo 1 pouco importante e 5 muito importante), classifique seu grau de importância com relação aos seguintes aspectos?</label>
                                    </Grid>
                                    <Grid item xs={8} className={'labels importancia'}>
                                        <span>Entregas de projeto</span>
                                        <span>Interação</span>
                                        <span>Competência profissional</span>
                                        <span>Prazos e entrega</span>
                                        <span>Impacto e Benefícios</span>
                                    </Grid>
                                </Grid>
                                <Grid className={'pesquisa-body'}>
                                    <Grid container className={'line'}>
                                        <Grid item xs={4} className={'sub-question'}>
                                            {this.showErrors && (!this.pesquisa.orderprojectdeliverables || !this.pesquisa.orderinteractionorder ||
                                                !this.pesquisa.orderprofessionalcompetence || !this.pesquisa.ordertimeframe || !this.pesquisa.orderimpact) ?
                                                <label className={'font-red'}>Campos obrigatórios. <br></br>Os valores devem estar entre 1 e 5.</label> : null}
                                        </Grid>
                                        <Grid item xs={8} className={'radios input'}>

                                            <FormField
                                                type={'number'} id={'orderprojectdeliverables'} inputValue={this.pesquisa.orderprojectdeliverables}
                                                changeValue={this.changeInput} required={'required'} min={1} max={5} />

                                            <FormField
                                                type={'number'} id={'orderinteractionorder'} inputValue={this.pesquisa.orderinteractionorder}
                                                changeValue={this.changeInput} required={'required'} max />

                                            <FormField
                                                type={'number'} id={'orderprofessionalcompetence'} inputValue={this.pesquisa.orderprofessionalcompetence}
                                                changeValue={this.changeInput} required={'required'} />

                                            <FormField
                                                type={'number'} id={'ordertimeframe'} inputValue={this.pesquisa.ordertimeframe}
                                                changeValue={this.changeInput} required={'required'} />

                                            <FormField
                                                type={'number'} id={'orderimpact'} inputValue={this.pesquisa.orderimpact}
                                                changeValue={this.changeInput} required={'required'} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={'comment'}>
                                <FormField
                                    type={'textarea'}
                                    id={'comments'}
                                    label={'Demais Comentários?'}
                                    inputValue={this.pesquisa.comments}
                                    changeValue={this.changeInput} />
                            </Grid>
                            <br></br><br></br>

                            <Grid item xs={12} style={{ textAlign: 'center', padding: '16px' }}>
                                {this.showErrors ? <label className={'font-red'}>Preencha os campos obrigatórios.</label> : null}
                            </Grid>

                            <Grid item xs={12} style={{ margin: '0 0 16px 0' }}>
                                <label
                                    className={'lightBlue basic-button control-label'}
                                    id="control-label"
                                    htmlFor="uploadfile">Adicionar anexo
                                </label>

                                <input
                                    className={'file-input'}
                                    type="file"
                                    id="uploadfile"
                                    name="file"
                                    value={this.pesquisa.anexourl}
                                    onChange={this.uploadAnexo} />
                            </Grid>
                            <Grid>
                                {this.state.anexoIndicadorList.map(
                                    (anexo, index) => (
                                        anexo.url.includes("pesquisa_satisfacao") ?
                                            <Chip key={index}
                                                label={<label style={{ display: 'flex' }}>
                                                    <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                    <p>{anexo.nomeArquivo}</p></label>}
                                                icon={<Icon onClick={() => this.removeAnexo(anexo)} className={'close'}>clear</Icon>}
                                            /> : null
                                    ))}
                            </Grid>

                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton
                                    color={'darkGrey-outline'}
                                    label={'Cancelar'}
                                    subClass={'basic-button'}
                                    click={() => this.clearPesquisa()} />

                                <GenericButton
                                    color={'darkBlue'}
                                    label={'Concluir'}
                                    subClass={'basic-button'}
                                    click={this.savePesquisa} />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal></div>
            </Grid>
        )
    }
}