import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import CollapseGraph from '../../../components/collapseGraph/collapseGraph';

export default class GraficoMediaProdutividade extends Component {

    estados = { 
        'AC': 'Acre', 'AL': 'Alagoas', 'AM': 'Amazonas', 'AP': 'Amapá', 'BA': 'Bahia', 'CE': 'Ceará',
        'DF': 'Distrito Federal', 'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão', 'MG': 'Minas Gerais',
        'MS': 'Mato Grosso do Sul', 'MT': 'Mato Grosso', 'PA': 'Pará', 'PB': 'Paraíba', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'PR': 'Paraná', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte', 'RO': 'Rondônia', 
        'RR': 'Roraima', 'RS': 'Rio Grande do Sul', 'SC': 'Santa Catarina', 'SE': 'Sergipe', 'SP': 'São Paulo', 'TO': 'Tocantins'
    };

	constructor(props) {
		super(props);

		this.state = {           

            dados : {digital : undefined, lean: undefined, ambos: undefined},
			medias : {digital : undefined, lean: undefined, ambos: undefined},
			total: {digital : 0, lean: 0, ambos: 0},
			geral: {digital : 0, lean: 0, ambos: 0},
		};

		this.carregarDados();

	}

    tools = [
        { id: 3, name: 'ABAIXO DA META', color: '#D44E54' },
        { id: 4, name: 'ACIMA DA META', color: '#077CDC' },
    ];

    fillGraficoAtendimento = async () => {
        let digital = [];
		let lean = [];
		let ambos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/mediaAumentoProdutividade`).then(async res => {
            let dataD = [];
			let dataL = [];
			let dataLD = [];
			let mediaD = [];
			let mediaL = [];
			let mediaLD = [];
			let categories = Object.keys(this.estados);
            if (res.data != null ) {
                await categories.forEach(estado => {
                    dataD.push(Number(res.data.digital[estado].toFixed(2)));
					dataL.push(Number(res.data.lean[estado].toFixed(2)));
					dataLD.push(Number(res.data.ambos[estado].toFixed(2)));
					mediaD.push({value : Number(res.data.digital[estado].toFixed(2)), estado : estado});
					mediaL.push({value : Number(res.data.lean[estado].toFixed(2)), estado : estado});
					mediaLD.push({value : Number(res.data.ambos[estado].toFixed(2)), estado : estado});
                });
                digital.push({
                        name: '',
                        data: dataD,
                        zones: [{
							value: 20,
							color: '#D44E54',
						},  {
							color: '#077CDC',
						}]
                    })
                ;
				lean.push({
					name: '',
					data: dataL,
					zones: [{
						value: 20,
						color: '#D44E54',
					},  {
						color: '#077CDC',
					}]
				})
				;
				ambos.push({
					name: '',
					data: dataLD,
					zones: [{
						value: 20,
						color: '#D44E54',
					},  {
						color: '#077CDC',
					}]
				})
				;
				let geral = {digital : res.data.digital.media_geral, lean: res.data.lean.media_geral, ambos: res.data.ambos.media_geral}	
                
                this.setState({ dados : {digital: digital, lean:lean, ambos: ambos},
					 	total:{digital: res.data.digital.atendimentos_totais, lean:res.data.lean.atendimentos_totais, ambos: res.data.ambos.atendimentos_totais},
						medias:{digital: mediaD, lean:mediaL, ambos: mediaLD}, geral: geral});
            }
        }).catch(error => {
            console.log(error);
        });
    };

	carregarDados = async () => {
		await this.fillGraficoAtendimento();
	}

	


	create = (fase) => {

        let categories = Object.keys(this.estados);

		let series = [];

		let subtitle

		let medGeral = 0;

		if(fase == "L"){
			series = this.state.dados.lean;
			medGeral = this.state.geral.lean;
			subtitle = {
				enabled:true,
				text: `Fase 1: Mentoria Lean`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		else if(fase == "D"){
			series = this.state.dados.digital;
			medGeral = this.state.geral.digital;
			subtitle = {
				enabled:true,
				text: `Fase 2: Mentoria Digital`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		else{
			series = this.state.dados.ambos;
			medGeral = this.state.geral.ambos;
			subtitle = {
				enabled:true,
				text: `Brasil Mais`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		

		return {
			chart: {
                type: 'column',
                style: {
                    fontFamily: 'Raleway'
                },
				height:  500 ,
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				marginTop : 80
			},
			title: {
				enabled:true,
				text: `MÉDIA DO AUMENTO DE PRODUTIVIDADE POR DR`,
				style: {
					fontFamily: 'Raleway',
					font: "20px",
					fontWeight: 600,

					'color': '#077CDC',
				},
                margin: 80
				
			},
            fullCategories: this.estados,
            xAxis: {
                lineColor: '#707070',
                lineWidth: 0.5,
                categories: categories,
                labels: {
                    style: {
                        color: '#707070'
                    }
                },
            },
			subtitle: subtitle,
			tooltip: {
				headerFormat: '<b>Aumento Produtividade</b><br/>',
                pointFormat: '{point.category}: {point.y}%<br/>Média Geral:' + medGeral + '%'
					},
			yAxis: {
                lineColor: '#707070',
                lineWidth: 0,
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    style: {
                        color: '#707070'
                    }
                }
            },
			plotOptions: {
				column: {
					dataLabels: {
						enabled: true,
						style: {
							font: 'Raleway',
							fontSize: '15px !important',
							color: '#707070'
						}
					},
					states: {
	                    hover: {
	                        enabled: false
	                    },
						inactive: {
							opacity: 1
						}
					},
					showInLegend: false
				},				
			},
			series: series,
			credits: {
				enabled: false
			},
			exporting: {
				width: 2000,
				menuItemDefinitions: {
					// Custom definition
					printChart: {
						text: 'Imprimir gráfico'
					},
					downloadPNG:{
						text: 'Baixar como PNG'
					},
					downloadJPEG:{
						text: 'Baixar como JPEG'
					},
					downloadSVG:{
						text: 'Baixar como SVG'
					},
					viewFullscreen:{
						text: 'Ver em tela cheia'
					},
					downloadCSV:{
						text: 'Baixar tabela de dados'
					}
				},
                subtitle :{
                    style: {
                        'margin-top': '30',
                    }				
                },
                chart: {
                    marginTop: '150'
                },
                subtitle:{
                    y: 0
                },
				fallbackToExportServer: false,
				buttons: {
					contextButton: {
						menuItems:[ "printChart", "viewFullscreen", "downloadCSV", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"]
					}
				}
			}
		};
	}

	render() {
		
		let mediasL = [];
		let mediasD = [];
		let mediasLD = [];
		 
	
		if(this.state.medias.lean){
			let dataL = this.state.medias.lean.sort(function(a, b){return b.value - a.value});
			mediasL.push(dataL[0]);
			mediasL.push(dataL[1]);
			mediasL.push(dataL[2]);
			mediasL.push(dataL[26]);
			mediasL.push(dataL[25]);
			mediasL.push(dataL[24]);
		}
		else{
			mediasL.push({value : 0,estado: ''});
			mediasL.push({value : 0,estado: ''});
			mediasL.push({value : 0,estado: ''});
			mediasL.push({value : 0,estado: ''});
			mediasL.push({value : 0,estado: ''});
			mediasL.push({value : 0,estado: ''});
		}
		if(this.state.medias.digital){
			let dataD = this.state.medias.lean.sort(function(a, b){return b.value - a.value});
			mediasD.push(dataD[0]);
			mediasD.push(dataD[1]);
			mediasD.push(dataD[2]);
			mediasD.push(dataD[26]);
			mediasD.push(dataD[25]);
			mediasD.push(dataD[24]);
		}
		else{
			mediasD.push({value : 0,estado: ''});
			mediasD.push({value : 0,estado: ''});
			mediasD.push({value : 0,estado: ''});
			mediasD.push({value : 0,estado: ''});
			mediasD.push({value : 0,estado: ''});
			mediasD.push({value : 0,estado: ''});
		}
		if(this.state.medias.ambos){
			let dataLD = this.state.medias.lean.sort(function(a, b){return b.value - a.value});
			mediasLD.push(dataLD[0]);
			mediasLD.push(dataLD[1]);
			mediasLD.push(dataLD[2]);
			mediasLD.push(dataLD[26]);
			mediasLD.push(dataLD[25]);
			mediasLD.push(dataLD[24]);
		}
		else{
			mediasLD.push({value : 0,estado: ''});
			mediasLD.push({value : 0,estado: ''});
			mediasLD.push({value : 0,estado: ''});
			mediasLD.push({value : 0,estado: ''});
			mediasLD.push({value : 0,estado: ''});
			mediasLD.push({value : 0,estado: ''});
		}

        let contentPanelL ={
			id: 'ConsultoresHomologadosInfo',
			body :	
			<div className='dashboard-extra-info'>
				<label>Atendimentos concluídos <span className='blue-value'> {this.state.total.lean}</span> </label>
				<label>DRs com maiores médias : 
				{mediasL[0].estado}: <span className='blue-value'> {mediasL[0].value}%</span>,
				{mediasL[1].estado}: <span className='blue-value'> {mediasL[1].value}%</span>,
				{mediasL[2].estado}: <span className='blue-value'> {mediasL[2].value}%</span> 
				</label>
				<label>DRs com menores médias : 
				{mediasL[3].estado}: <span className='blue-value'> {mediasL[3].value}%</span>,
				{mediasL[4].estado}: <span className='blue-value'> {mediasL[4].value}%</span>,
				{mediasL[5].estado}: <span className='blue-value'> {mediasL[5].value}%</span> 
				</label>
			</div>
		}
		let contentPanelD ={
			id: 'ConsultoresHomologadosInfo',
			body :	
			<div className='dashboard-extra-info'>
				<label>Atendimentos concluídos <span className='blue-value'> {this.state.total.digital}</span> </label>
				<label>DRs com maiores médias : 
				{mediasD[0].estado}: <span className='blue-value'> {mediasD[0].value}%</span>,
				{mediasD[1].estado}: <span className='blue-value'> {mediasD[1].value}%</span>,
				{mediasD[2].estado}: <span className='blue-value'> {mediasD[2].value}%</span> 
				</label>
				<label>DRs com menores médias : 
				{mediasD[3].estado}: <span className='blue-value'> {mediasD[3].value}%</span>,
				{mediasD[4].estado}: <span className='blue-value'> {mediasD[4].value}%</span>,
				{mediasD[5].estado}: <span className='blue-value'> {mediasD[5].value}%</span> 
				</label>
			</div>
		}
		let contentPanelLD ={
			id: 'ConsultoresHomologadosInfo',
			body :	
			<div className='dashboard-extra-info'>
				<label>Atendimentos concluídos <span className='blue-value'> {this.state.total.ambos}</span> </label>
				<label>DRs com maiores médias : 
				{mediasLD[0].estado}: <span className='blue-value'> {mediasLD[0].value}%</span>,
				{mediasLD[1].estado}: <span className='blue-value'> {mediasLD[1].value}%</span>,
				{mediasLD[2].estado}: <span className='blue-value'> {mediasLD[2].value}%</span> 
				</label>
				<label>DRs com menores médias : 
				{mediasLD[3].estado}: <span className='blue-value'> {mediasLD[3].value}%</span>,
				{mediasLD[4].estado}: <span className='blue-value'> {mediasLD[4].value}%</span>,
				{mediasLD[5].estado}: <span className='blue-value'> {mediasLD[5].value}%</span> 
				</label>
			</div>
		}

		let GraphL =  this.create('L');
		let GraphD =  this.create('D');
		let GraphLD =  this.create('LD');

		return (
			<Grid container justify={'center'} className={'dashboard-porte-setor border-cinza'}>
				<Grid item xs={12}>
					<div style={{ margin: '40px' }}>
						{this.props.fase == 'L' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphL} />}
						{this.props.fase == 'D' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphD} />}
						{this.props.fase == 'LD' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphLD} />}
					</div>
				</Grid>
                <Grid item xs={12} className={'mapa-do-brasil-legenda'} style={{marginBottom: '3%'}}>
                    <Grid container direction={'row'} spacing={3} justify={'center'}>
                        {this.tools.map((tool, index) => {
                            return (
                                <>
                                    <Grid item className={'mapa-do-brasil-legenda-bloco'} key={'leg-' + index}>
                                        <div className={'legenda-shape'} style={{ background: tool.color }}></div>
                                        <span>{tool.name}</span>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
					{this.props.fase == 'L' && 
					<CollapseGraph
                    content={[contentPanelL]}
                    isExpanded={true}
                    hideButton={true}/>}
					{this.props.fase == 'D' &&
					<CollapseGraph
                    content={[contentPanelD]}
                    isExpanded={true}
                    hideButton={true}/>}
					{this.props.fase == 'LD' && 
					<CollapseGraph
                    content={[contentPanelLD]}
                    isExpanded={true}
                    hideButton={true}/>}
                
				</Grid>
			</Grid>
		);
	}
}