import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import { Grid } from "@material-ui/core"

export default class AtividadeMaisInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {
          atividade: props.atividade,
        };
    };

    changeInput = (e) => {
        let atividade = this.state.atividade;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
              atividade[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
              atividade[e.target.name] = e.target.value;
            }
            this.setState({ atividade: atividade, errors: errors });
        }
    };

    renderBody = () => {
        let atividade = this.state.atividade;

        return (
            <Grid container justify={'center'} style={{ marginTop: '3%' }}>
                <Grid container justify={'flex-start'} spacing={4}>
                    <Grid item xs={12}>
                    </Grid>
                    <Grid item xs={6}>
                        <div className={'div-field-btn'}>
                          <FormField 
                            type={'textarea'}
                            changeValue={(e) => { 
                              atividade.maisInfo = e.target.value;
                              this.setState({ atividade });
                            }} 
                            inputValue={atividade.maisInfo}
                            label={
                              <>
                                <b style={{ fontWeight: 'normal'}}>Mais informações</b> <label style={{ color: '#B4B8C0', fontSize: 11 }}>*Opcional</label>
                              </>
                            }
                            disabled={this.props.disabled}
                            placeholder="Digite aqui"
                          />
                        </div>
                    </Grid>
                    
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ marginTop: '3%' }}>
                    
                   
                </Grid>
                <Grid container justify={'center'} style={{ marginTop: '3%' }} className={'linha-producao-box'}>
                    
                </Grid>
            </Grid>
        )
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao'} style={{ marginTop: '30px' }}>
                { this.renderBody() }
            </Grid>
        )
    }

}