import React, { Component } from 'react'
import './laboratorio.scss'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Tabela from '../../components/table/table'
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API'
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';


const titles = [
    { id: '0', canOrder: true, label: 'Nome' },
    { id: '1', canOrder: true, label: 'Unidade' },
    { id: '2', canOrder: false, label: 'Competências' },
    { id: '3', canOrder: false, label: 'Contato' },
    { id: '4', canOrder: false, label: '' }]

export default class Laboratorio extends Component {

    constructor(props) {
        super(props);
        this.state = {
            laboratorioList: [],
            emptyList: 'seeResults',
            lines: [],
            filter: {
                ordenarPor: 'nome',
                isLaboratorio: 'true'
            }
        }
    };

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    changeBuscaLivreColaborador = (e) => {
        var filterAux = ''
        if (e.target.value !== undefined && e.target.value !== '') {
            filterAux = this.state.filter
            filterAux.buscaLivreColaborador = e.target.value
            this.setState({ filter: filterAux })
        } else {
            filterAux = this.state.filter
            delete filterAux.buscaLivreColaborador
            this.setState({ filter: filterAux })
        }
    }

    getLaboratorios = () => {
        this.showLoading();
        API.get('rest/gestao/Colaborador/', {
            params: this.state.filter
        }).then(res => {
            this.setState({ laboratorioList: res.data });
            var lines = [];

            for (var i = 0; i < this.state.laboratorioList.length; i++) {
                var laboratorioAux = [];

                laboratorioAux.push(`${this.state.laboratorioList[i].nome}`);
                laboratorioAux.push(`${this.state.laboratorioList[i].unidade.abreviacao}`);
                var competencias = ''
                for (var y = 0; y < this.state.laboratorioList[i].colaboradorProdutoRegionalList.length; y++) {
                    if (this.state.laboratorioList[i].colaboradorProdutoRegionalList[y].produtoRegional) {
                        competencias += this.state.laboratorioList[i].colaboradorProdutoRegionalList[y].produtoRegional.nome;
                        competencias += y === (this.state.laboratorioList[i].colaboradorProdutoRegionalList.length - 1) ? '.' : ','
                    }
                }
                laboratorioAux.push(competencias);
                laboratorioAux.push(<div>{this.state.laboratorioList[i].email}<br></br>{this.state.laboratorioList[i].telefone}</div>)

                laboratorioAux.push(
                    <div style={{ display: 'flex' }}>
                        <Link to={`/cad/pessoa/laboratorio/${this.state.laboratorioList[i].id}`}>
                            <GenericButton color={'transparent'} subClass={'icon-button'}
                                icon={'pen.svg'} />
                        </Link>
                    </div>)
                lines.push(laboratorioAux)
            }
            this.setState({ lines: lines })
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Ocorreu um erro ao carregar lista de laboratórios. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    render() {
        return (
            <Page icon={'lab.svg'} label={'Gerenciar Laboratórios'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'laboratorio'}>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} xl={5} className={'container'}>
                                <Grid>
                                <Link to={'/cad/pessoa/laboratorio/novo'}>
                                    <GenericButton color={'darkBlue'} label={'Cadastrar Novo Laboratório'} subClass={'basic-button'} />
                                </Link>
                                </Grid>

                                <Grid className={'formfield'}>
                                <FormField type={'text'} placeholder={'busque pelo nome, cpf, competência, skill ou unidade'}
                                    changeValue={this.changeBuscaLivreColaborador}
                                    pressEnter={this.getLaboratorios}
                                    id={'busca'}
                                    button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                    icon={process.env.PUBLIC_URL + 'search.svg'}
                                    click={this.getLaboratorios} />} />
                                    </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Tabela lines={this.state.lines} titles={titles} initialOrder={'0'} />
                        <EmptyList type={this.state.emptyList} seeAll={this.getLaboratorios} />
                    </Grid>
                </Grid>
            </Page>
        );
    }
}