import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from './../../comm/API';
import InputSelect from '../../components/inputselect/inputselect';
import AutoComplete from './../../components/autoComplete/autoComplete';
import './solicitacoesDN.scss';

export default class MetasGoverno extends Component {

    state = {
        qtdAceitoSetor: null, metaGoverno: null, metaSenai: null, isMetaGoverno: "Não",
        previsaoReceita: { fontePagadora: { nome: '' } }
    };

    componentWillMount() {
        this.getQtdeAtendimentoAceitoSetor();
        this.getItemMetaRegional();
    }
    async getQtdeAtendimentoAceitoSetor() {
        let qtdAceitoSetor = (await API.get(`rest/gestao/Atendimento/count?setor.id=${this.props.atendimento.setor.id}&solicitacaoStatus.chave=conforme`)).data;
        this.setState({ qtdAceitoSetor: qtdAceitoSetor.count });
        this.verificarMeta();
    }
    async getItemMetaRegional() {
        let itemMetaRegional = (await API.get(`rest/gestao/ItemMetaRegional?metaRegional.unidade.id=${this.props.atendimento.unidade.unidadePai.id}&setor.id=${this.props.atendimento.setor.id}`)).data;
        
        if (itemMetaRegional && itemMetaRegional.length > 0) {
            this.setState({ metaGoverno: itemMetaRegional[0].metaGoverno, metaSenai: itemMetaRegional[0].metaSenai });
        }
        this.verificarMeta();
    }
    verificarMeta() {
        if (this.state.metaSenai && this.state.qtdAceitoSetor && this.state.metaSenai !== 0 && (this.state.qtdAceitoSetor + 1 > this.state.metaSenai)) {
            if (this.props.atendimento.numeroDeReceitaEstimada === 18000) {
                this.setState({ isMetaGoverno: "Sim" });
                this.createPrevisaoReceitaBP();
            }
        }
    }
    createPrevisaoReceitaBP() {
        let previsaoReceita = this.getPrevisaoReceitaModel();
        previsaoReceita.tipoPrevisaoReceita = {id:1, descricao: 'MERCADO'};
        previsaoReceita.valor = 15000;
        previsaoReceita.fontePagadora = { nome: '' };
        this.setState({ previsaoReceita: previsaoReceita });
        this.props.getPrevisaoReceita(this.state.previsaoReceita);
    };
    getPrevisaoReceitaModel() {
        return { valor: 0, qtdeRelatorios: 0, tipoPrevisaoReceita: null, fontePagadora: null };
    };
    async changeMetaGoverno(e) {
        await this.setState({ isMetaGoverno: e });   
        if (this.state.isMetaGoverno === 'Sim') {
            this.createPrevisaoReceitaBP();
        } else {
            this.setState({ previsaoReceita: { fontePagadora: { nome: '' } } });
        }
        this.props.getMetaGoverno(this.state.isMetaGoverno);
    };
    changeFontePagadora(prev) {
        let preRec = this.state.previsaoReceita;
        preRec.fontePagadora = prev;
        this.setState({ previsaoReceita: preRec });
    }

    render() {
        return (
            <div className={'greyDiv'}>
                <Grid container spacing={2} direction="row"
                            justify="center" alignItems="center">

                    <Grid item xs={12}>
                        <h2 className={'font-blue'}>Metas Governo</h2>
                    </Grid>
                    <Grid item xs={4}>
                        <InputSelect key='metaGoverno' suggestions={["Sim", "Não"]}
                            id={'metaGoverno'} initialSelectedItem={this.state.isMetaGoverno}
                            getInput={(e) => { this.changeMetaGoverno(e) }}></InputSelect>
                    </Grid>
                    {this.state.isMetaGoverno === 'Sim' && <Grid item xs={4}>
                        <b>Valor</b>
                    </Grid>}
                    {this.state.isMetaGoverno === 'Sim' && <Grid item xs={4}>
                        <label>R$ {this.state.previsaoReceita.valor}</label>
                    </Grid>}
                    {this.state.isMetaGoverno === 'Sim' && <Grid item xs={12}>
                        <AutoComplete placeholder={'Digite o nome da fonte pagadora'} itemKey={'id'} key='autoCompleteFonteMercado'
                            path={'rest/pessoa/Cliente/0/30?isFonteBP=true&buscaLivre='}
                            itemlabel={'nome'} initialSelectedItem={this.state.previsaoReceita.fontePagadora}
                            getInput={(e) => { this.changeFontePagadora(e) }} />
                    </Grid>}
                </Grid></div>
        )
    }
}