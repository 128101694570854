import React, { Component } from 'react'
import TableContext from './../../comm/context';

import './progressbar.scss';

export default class ProgressBar extends Component {

    percentual = null;
    color = null;
    producaoEstimada = null;
    total = null;
    incremented = null;

    static contextType = TableContext;

    componentWillMount() {
        this.confVariables();
    }
    componentWillReceiveProps(props) {
        this.total = props.total;
    }

    confVariables(incremented) {
        this.incremented = incremented;
        this.total = parseFloat(this.props.total) + (this.incremented ? parseFloat(this.incremented) : 0);
        this.percentual = this.props.producao !== 0 ? (this.total / this.props.producao) : 1;
        this.color = this.props.producao !== 0 ? (this.percentual > 1 ? 'redPercent' : 'greenPercent') : 'yellowPercent';
        this.producaoEstimada = this.props.producao;
    }

    render() {
        if (this.props.idAtendimento === this.context.idAtendimento) {
            this.confVariables(this.context.horasProducao);
        }else{
            this.confVariables(this.incremented);
        }

        return (
            <div className={'progress-bar'}>
                <label>{
                    this.props.unitBegin  ? this.props.unitBegin : ''}{this.total.toLocaleString('pt-BR', { minimumFractionDigits: this.props.unitEnd === 'h' ? 0 : 2 })}{this.props.unitEnd}
                    {this.producaoEstimada === 0 ? '' : `/
                ${this.props.unitBegin ? this.props.unitBegin : ''}${this.producaoEstimada.toLocaleString('pt-BR', { minimumFractionDigits: this.props.unitEnd === 'h' ? 0 : 2 })}${this.props.unitEnd ? this.props.unitEnd : ''}`
                    }</label>
                <div className={'total'}>
                    <div className={this.props.defaultClass ? this.props.defaultClass : this.color}
                        style={{ width: '' + (this.percentual > 1 ? 100 : (this.percentual * 100)) + '%' }}>
                        {this.producaoEstimada === 0 ? 'Indefinida' : Number((this.percentual * 100).toFixed(1)) + '%'}</div>
                </div>
            </div>
        )
    }
}