import React, { Component } from 'react'
import { Grid } from "@material-ui/core"

export default class LegendaGrafico extends Component {
    render() {
        return (
            <Grid className={'legenda-divergencia'}>
                {this.props.titulo1}
                <div style={{display: 'flex'}}>
                    <div className={"rectangle"}></div>
                    <span style={{ color: '#077CDC' }}>{this.props.sgt}</span>
                </div>
                <div style={{display: 'flex'}} className={'divergencia-lightblue'}>
                    <div className={"rectangle"}></div>
                    <span style={{ color: '#92ACC2' }}>{this.props.si}</span>
                </div>
                Divergência total:
                <div style={{display: 'flex'}}>
                    <i className={"circle after icone"}></i>
                    <span style={{ color: '#D44E54' }}>{this.props.divergencia}</span>
                </div>
            </Grid>
        )
    }
}