import { Grid } from "@material-ui/core";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import API, { BASE_URL } from '../../comm/API';
import EmptyList from '../../components/emptyList/emptyList';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import Page from '../../components/page/page';
import Table from '../../components/table/table';
import './colaborador.scss';

const titles = [
    { id: '0', canOrder: false, label: 'Foto' },
    { id: '1', canOrder: true, label: 'Colaborador' },
    { id: '2', canOrder: true, label: 'Unidade' },
    { id: '3', canOrder: false, label: 'Competências' },
    { id: '4', canOrder: false,  label: 'Skills' },
    { id: '5', canOrder: false,  label: 'Contato'},
    { id: '6', canOrder: true,  label: 'Ativo'},
    { id: '7', canOrder: false,  label: ''}]

export default class GerenciarColaborador extends Component {

    constructor(props) {
        super(props);
        this.state = {
            colaboradorList: [], 
            emptyList: 'seeResults',
            lines: [],
            filter : {
                ordenarPor: 'nome',
                isLaboratorio: 'false'
            },
            colaborador: null,
            colaboradorUrl: ''
        }
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };
    
    changeBuscaLivreColaborador = (e) => {
        var filterAux = '';
        if (e.target.value !== undefined && e.target.value !== '') {
            filterAux = this.state.filter
            filterAux.buscaLivreColaborador = e.target.value
        } else {
            filterAux = this.state.filter
            delete filterAux.buscaLivreColaborador
        }
        this.setState({filter: filterAux});
    };

    getColaboradores = () => {
        this.showLoading();
        this.setState({lines: []});
        API.get('rest/gestao/Colaborador', {
            params: this.state.filter
        }).then(res => {
            this.setState({colaboradorList: res.data});
            var lines = [];
            for (var i = 0; i < this.state.colaboradorList.length ; i ++) {
                var colaboradorAux = [];
                var urlFoto = '';
                var unidades = '';
                var competencias = '';
                var skills = '';
                if (this.state.colaboradorList[i].urlFoto !== '' && this.state.colaboradorList[i].urlFoto !== null && this.state.colaboradorList[i].urlFoto !== undefined) {  
                    urlFoto = BASE_URL + this.state.colaboradorList[i].urlFoto + '?' + new Date().getTime()
                } else {
                    urlFoto = process.env.PUBLIC_URL + 'user-login.png';
                }        
                colaboradorAux.push(<div className='div-circle-login'><img alt='' src={urlFoto}/></div>);
                colaboradorAux.push(`${this.state.colaboradorList[i].nome}`);
                if (this.state.colaboradorList[i].colaboradorUnidadeList != null){
                    for (var y = 0; y < this.state.colaboradorList[i].colaboradorUnidadeList.length; y++){
                        unidades += this.state.colaboradorList[i].colaboradorUnidadeList[y].unidade.abreviacao;
                        unidades += y === (this.state.colaboradorList[i].colaboradorUnidadeList.length - 1) ? '.' : ',';
                    }
                }
                colaboradorAux.push(unidades);
                for(y = 0; y < this.state.colaboradorList[i].colaboradorProdutoRegionalList.length; y++) {
                    let produtoRegional = this.state.colaboradorList[i].colaboradorProdutoRegionalList[y].produtoRegional
                    if (produtoRegional != null) {
                        competencias += produtoRegional.nome;
                        competencias += y === (this.state.colaboradorList[i].colaboradorProdutoRegionalList.length - 1) ? '.' : ','
                    }
                }
                colaboradorAux.push(competencias);
                for(y = 0; y < this.state.colaboradorList[i].colaboradorSkillList.length; y++) {
                    let skill = this.state.colaboradorList[i].colaboradorSkillList[y].skill;
                    if (skill != null) {
                        skills += skill.descricao;
                        skills += y === (this.state.colaboradorList[i].colaboradorSkillList.length - 1) ? '.' : ',';
                    }
                }
                colaboradorAux.push(skills)
                colaboradorAux.push(<div>{this.state.colaboradorList[i].email}<br></br>{this.state.colaboradorList[i].telefone}</div>);
                colaboradorAux.push(this.state.colaboradorList[i].isAtivo === true ? 'SIM' : 'NÃO');
                colaboradorAux.push( 
                    <div style={{ display: 'flex' }}>
                        <Link to={`/cad/pessoa/colaborador/${this.state.colaboradorList[i].id}`}>
                            <GenericButton color={'transparent'} subClass={'icon-button'} 
                            icon={'pen.svg'}/>
                        </Link>
                    </div>
                );
                lines.push(colaboradorAux);
            }
            this.setState({lines: lines});
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao carregar colaborador. Por favor, entre em contato com o suporte.', 'error');
        });  
    };

    render() {
        return (
            <Page icon={'profile.svg'} label={'Gerenciar Colaboradores'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-colaborador'}>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6} xl={5} className={'container-busca'} style={{display: 'flex', alignItems: 'self-end'}}>
                                <Grid>
                                    <Link to={'/cad/pessoa/colaborador/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Novo Colaborador'} subClass={'basic-button'} />
                                    </Link>
                                </Grid>

                                <Grid className={'formfield'}>
                                    <FormField type={'text'} label={''} placeholder={'busque pelo nome, cpf, competência, skill ou unidade'}
                                        button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                        icon={'search.svg'}
                                        click={this.getColaboradores}/>}
                                        changeValue={this.changeBuscaLivreColaborador}
                                        pressEnter={this.getColaboradores} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.lines} titles={titles} initialOrder={'1'}/>
                        <EmptyList type={this.state.emptyList} seeAll={this.getColaboradores}/>
                    </Grid>
                </Grid>
            </Page>
        );
    }
}