import React from 'react';
import "./kpi.scss";
import { KpiBaseContent } from './kpiBaseContent';

export const BusinessProcess = (props) => {
  return (
    <KpiBaseContent 
      idKpiFactor={3}
      title='Processo de negócio'
      usuarioConectado={props.usuarioConectado}
    />
  )
} 