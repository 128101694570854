import React, { Component } from 'react'
import './laboratorioNovo.scss'
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper'
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API'
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import Masks from '../../comm/masks';

const titles = ['Dados', 'Competências', 'Revisão']

export default class LaboratorioNovo extends Component {

    laboratorio = {
        nome: "",
        cpf: "",
        numeroDeHorasDeTrabalho: 0,
        urlFoto: "dist/img/user-login.png",
        telefone: "",
        linkCurriculumVitae: "",
        isAtivo: true,
        isLaboratorio: true,
        isTransferidoParaIndustria: false,
        numeroCentroResponsabilidade: "",
        email: "",
        emailResponsavel: "",
        formacaoColaborador: null,
        tipoContratoColaborador: null,
        funcaoColaborador: null,
        unidade: null,
        unidadeFederativa: null,
        laboratorioSkillList: [],
        colaboradorProdutoRegionalList: [],
        colaboradorUnidadeList: []
    }
    allUnidadeList = [];
    usuarioConectadoUnidadeSigla = '';
    dptoRegionalUser = '';

    constructor(props) {
        super(props);
        let unidadeSigla = props.usuarioConectado.unidade.tipoUnidade.sigla;
        this.dptoRegionalUser = unidadeSigla === 'UO' ? props.usuarioConectado.unidade.unidadePai.id : (unidadeSigla === 'DR' ? props.usuarioConectado.unidade.id : '');
        this.state = {
            user: null,
            idLaboratorio: props.idLaboratorio !== undefined ? props.idLaboratorio : undefined,
            unidadeList: [],
            competenciaList: [],
            isNew: true,
            renderAutocomplete: false,
        }
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentWillMount(){
        this.configPage();
    }
    componentDidMount() {
        //this.closeLoading();
    };

    configPage(){
        const unidadeUsuarioConectado = this.props.usuarioConectado.unidade;
        this.usuarioConectadoUnidadeSigla =  unidadeUsuarioConectado.tipoUnidade.sigla;
        this.getLaboratorio(this.props.idLaboratorio, unidadeUsuarioConectado);
    };

    loadSelectUnidade = false
    errors = {
        nome: { subClass: '', error: '' },
        cpf: { subClass: '', error: '' },
        email: { subClass: '', error: '' },
        emailResponsavel: { subClass: '', error: '' },
        telefone: { subClass: '', error: '' }
    };

    getLaboratorio = (idLaboratorio, unidade) => {
        if (idLaboratorio !== undefined) {
            this.showLoading();
            API.get('rest/gestao/Colaborador/' + idLaboratorio).then(res => {
                this.laboratorio = res.data;
                this.dptoRegionalUser = this.laboratorio.colaboradorUnidadeList[0].unidade.tipoUnidade.sigla === 'UO' ?
                                        this.laboratorio.colaboradorUnidadeList[0].unidade.unidadePai.id :
                                        this.laboratorio.colaboradorUnidadeList[0].unidade.id;
                this.setState({ isNew: false });
                this.getCompetenciaList();
                this.getUnidadeList(unidade);
            })
        } else {
            this.laboratorio.unidade = unidade;
            if (this.dptoRegionalUser != null && Number(this.dptoRegionalUser) > 0) this.getCompetenciaList();
            this.setState({ isNew: false })
            this.getUnidadeList(unidade);
        }
    }
    getUnidadeList = (unidade) => {
        const filter = this.getUnidadeFilter(unidade)
       
        API.get('rest/gestao/Unidade', { params: filter }).then(res => {
            this.allUnidadeList = res.data;
            this.setState({ unidadeList: res.data, renderAutocomplete: true });
     
            this.refreshUnit();
            this.closeLoading();
        }).catch(error => {
            this.setState({ renderAutocomplete: false });
            this.closeLoading();
            this.showToast('Infelizmente ocorreu um erro ao carregar as unidades. Favor, entre em contato com o suporte.','error')     
        });
    }
    getUnidadeFilter = (unidade) => {
        const filter = {};
        if (unidade.tipoUnidade.sigla === 'UO') {
            filter['id'] = unidade.id;
        } else if (unidade.tipoUnidade.sigla === 'DR') {
            filter['unidadePai.id'] = unidade.id;
        } else { 
            /* Caso DN: 
               Listagem pode apenas ter UO como Unidade
               Todo id > 29 pertencem a UO */
            filter['id'] = 'greater(29)';
        }
        return filter;
    }
    refreshUnit = () => {
        this.state.unidadeList.forEach((obj) => {
            if (obj.id === parseInt(this.laboratorio.unidade.id)) {
                this.laboratorio.unidade = obj;
                this.setState(this.state);
            }
        });
    }
    getCompetenciaList = () => {
        let route = 'rest/auxiliar/ProdutoRegional/findall/' + this.dptoRegionalUser;
        API.get(route).then(res => {
            this.setState({ competenciaList: res.data })
        }).catch(error => {
            this.showToast('Error ao gerar a lista de competências. Por favor, entre em contato com o suporte.', 'error');
        })
    }

    validateEmail(email) {
        if(email) return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(email);
        else return new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(this.colaborador.email);
    };

    validateTelefone(telefone) {
        return new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g).test(telefone) && telefone.length >= 14; //eslint-disable-line
    };
    
    changeInput = (e) => {
        if (e != null && e.target != null) {
            this.laboratorio[e.target.id] = e.target.value;
            if (e.target.type === 'radio') {
                this.laboratorio.isAtivo = !this.laboratorio.isAtivo;
            } else if ((e.target.id === 'email' || e.target.id === 'emailResponsavel') && e.target.value !== '' && !this.validateEmail(e.target.value)) {
                this.errors[e.target.id].error = 'Email inválido';
            } else if (e.target.id === 'telefone' && e.target.value !== '' && !this.validateTelefone(e.target.value)) {
                this.errors[e.target.id].error = 'Telefone inválido';
            } else if ((e.target.required) && (e.target.value == null || e.target.value === '')) {
                this.errors[e.target.id].error = 'Campo obrigatório';
                this.errors[e.target.id].subClass = 'isInvalid';
            } else {
                this.errors[e.target.id].error = '';
                this.errors[e.target.id].subClass = '';
            }
        } else if (e != null && e.type === 'unidade') {
            this.laboratorio.unidade = e;
        } else if (e){
            this.laboratorio.colaboradorProdutoRegionalList = e;
        }
        this.setState(this.state);
    };

    validateLaboratorio = (step) => {
        switch (step) {
            case 0:
                return this.laboratorio.nome && this.laboratorio.cpf && this.laboratorio.email &&
                       this.laboratorio.colaboradorUnidadeList && this.laboratorio.colaboradorUnidadeList.length > 0 
            default:
                return true
        }
    }
    saveLaboratorio = () => {

        if(this.laboratorio){
            this.laboratorio.cpf = Masks.removeMask(this.laboratorio.cpf);
            this.laboratorio.telefone = Masks.removeMask(this.laboratorio.telefone);    
        }

        this.showLoading();

        if (this.laboratorio.id === undefined) {
            API.post('rest/gestao/Colaborador', this.laboratorio).then(res => {
                this.closeLoading();
                this.showToast('Laboratório cadastrado com sucesso!', 'success', '/cad/pessoa/laboratorio')
            }).catch(error => {
                this.closeLoading();
                this.showToast('Erro ao cadastrar laboratório. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Colaborador/' + this.laboratorio.id, this.laboratorio).then(res => {
                this.closeLoading();
                this.showToast('Laboratório editado com sucesso!', 'success', '/cad/pessoa/laboratorio');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao cadastrar laboratório. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }
    changeUnidades = (unidadesSelecionadas) => {
        if (!unidadesSelecionadas) return;

        this.laboratorio.colaboradorUnidadeList = unidadesSelecionadas;
        const selectListSize = unidadesSelecionadas.length;

        if ( selectListSize > 0 ) {

            if (!this.dptoRegionalUser || this.dptoRegionalUser !== unidadesSelecionadas[0].unidade.unidadePai.id) {
                this.dptoRegionalUser = unidadesSelecionadas[0].unidade.unidadePai.id;
                this.getProdutoRegionalList();
            }
            const filteredList = this.allUnidadeList.filter(unidade => 
                (unidade.unidadePai && unidade.unidadePai.id === unidadesSelecionadas[0].unidade.unidadePai.id)
            );
            this.setState({ unidadeList: filteredList });
            
        } else {
            
            if (this.usuarioConectadoUnidadeSigla === 'DN') {
                this.competenciaList = [];
                this.setState({ unidadeList: this.allUnidadeList });
                this.laboratorio.colaboradorProdutoRegionalList = [];
                this.dptoRegionalUser = '';
            }
        }

        this.setState(this.state);
    };
    createTemplateUnidade(unidade){
        return (
            <div className={'auto-complete-options'}>
                <div>
                    <label className={'square'}>{unidade.municipio ? unidade.municipio.unidadeFederativa.sigla : ''}</label>
                    <label>{unidade.municipio ? unidade.municipio.descricao : ''}</label>
                </div>
                <div>
                    <p>{unidade.descricao}</p>                    
                </div>
            </div>
        );
    };
    
    getProdutoRegionalList() {
        let route = 'rest/auxiliar/ProdutoRegional/findall/' + this.dptoRegionalUser;
        API.get(route).then(res => {
            this.competenciaList = res.data;
        }).catch(error => {
            this.showToast('Erro ao realizar GET das competências. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    getSteps = () => {
        return [
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={8} className={'paddingForm'}>
                            <FormField type={'text'} label={'Nome'} id={'nome'} inputValue={this.laboratorio.nome} changeValue={this.changeInput}
                                required={'required'} error={this.errors.nome.error} subClass={this.errors.nome.subClass} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormField type={'text'} label={'CPF'} id={'cpf'} inputValue={Masks.maskCpf(this.laboratorio.cpf)} changeValue={this.changeInput}
                                required={'required'} error={this.errors.cpf.error} subClass={this.errors.cpf.subClass} />
                        </Grid>
                        <Grid item xs={12} sm={8} className={'paddingForm'}>
                            <FormField type={'text'} label={'Email'} id={'email'} inputValue={this.laboratorio.email} changeValue={this.changeInput}
                                required={'required'} error={this.errors.email.error} subClass={this.errors.email.subClass} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormField type={'text'} label={'Telefone'} id={'telefone'} inputValue={Masks.maskTelefone(this.laboratorio.telefone)} changeValue={this.changeInput} required={false}
                                error={this.errors.telefone.error} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Email do Responsável'} id={'emailResponsavel'} inputValue={this.laboratorio.emailResponsavel} changeValue={this.changeInput}
                                required={false} error={this.errors.emailResponsavel.error} subClass={this.errors.emailResponsavel.subClass} placeholder={'Digite o e-mail do responsável'} />
                        </Grid>
                        <Grid item xs={12} >
                            {this.state.renderAutocomplete ? 
                                <AutoCompleteMany
                                key={'colaboradorUnidadeList'}
                                itemlabel={'descricao'}
                                suggestions={this.state.unidadeList}
                                getInput={this.changeUnidades}
                                itemTemplate={this.createTemplateUnidade}
                                itemKey={'id'}
                                label={'Selecione a unidade'}
                                chipLabel={'Unidade(s)'}
                                placeholder={'Ex: Insituto Senai de Inovação'}
                                id={'unidade'}
                                initialOjectSelectedList={this.laboratorio.colaboradorUnidadeList}
                                initialOjectSelectedListClass={'unidade'}
                            /> : null }
                        </Grid>


                        <Grid item xs={12} sm={4}>
                            <label className={'radio-label'}>Status</label>
                            <div style={{ display: 'flex' }}>
                                <FormField type={'radio'} radioLabel={'Ativo'} checked={this.laboratorio.isAtivo === true} inputValue={true} changeValue={this.changeInput} name={'isativo'} />
                                <FormField type={'radio'} radioLabel={'Inativo'} checked={this.laboratorio.isAtivo === false} inputValue={false} changeValue={this.changeInput} name={'isativo'} />
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <AutoCompleteMany
                        suggestions={this.state.competenciaList}
                        itemlabel={'nome'}
                        getInput={this.changeInput}
                        itemTemplate={this.functionItemTemplate}
                        itemKey={'id'}
                        label={'Tipo de Competência'}
                        chipLabel={'Competências Associadas'}
                        id={'nome'}
                        initialOjectSelectedList={this.laboratorio.colaboradorProdutoRegionalList}
                        initialOjectSelectedListClass={'produtoRegional'}       
                    />
                </Grid>
            </Grid>,
            <Grid container className={"step review-step"}>
                <Grid item xs={12} sm={10} md={4} xl={3} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>1</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Dados Pessoais</label>
                        <Grid container>
                            <Grid item xs={6}>Nome</Grid>
                            <Grid item xs={6}>{this.laboratorio.nome}</Grid>
                            <Grid item xs={6}>CPF do responsável</Grid>
                            <Grid item xs={6}>{Masks.maskCpf(this.laboratorio.cpf)}</Grid>
                            <Grid item xs={6}>Email</Grid>
                            <Grid item xs={6}>{this.laboratorio.email}</Grid>
                            <Grid item xs={6}>Telefone</Grid>
                            <Grid item xs={6}>{Masks.maskTelefone(this.laboratorio.telefone)}</Grid>
                            <Grid item xs={6}>Email do responsável</Grid>
                            <Grid item xs={6}>{this.laboratorio.emailResponsavel}</Grid>
                            <Grid item xs={3}>Unidade</Grid>                         
                            <Grid item xs={9}>
                            {this.laboratorio.colaboradorUnidadeList != null ? 
                                this.laboratorio.colaboradorUnidadeList.map(
                                    (colaboradorUnidade, index) => (
                                        <p key={index}>{colaboradorUnidade.unidade.descricao}</p>
                                    )
                                ) : null}
                            </Grid>                                
                            <Grid item xs={6}>Status</Grid>
                            <Grid item xs={6}>{this.laboratorio.isAtivo ? 'Ativo' : 'Inativo'}</Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} md={4} xl={3} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>2</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Competências</label>
                        <Grid container>
                            <Grid item xs={12}>
                                {this.laboratorio.colaboradorProdutoRegionalList.map((competencia) => { return competencia.produtoRegional? competencia.produtoRegional.nome : ''}).join('; ')}                                      
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        ]
    }
    createTemplateCompetencia(produtoRegional) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label className={'square'}>{produtoRegional.unidade.municipio.unidadeFederativa.sigla}</label>
                    <label>{produtoRegional.nome}</label>
                </div>
                <div>
                    <p>{produtoRegional.produtoNacional.descricao}</p>
                    <p>{produtoRegional.produtoNacional.produtoCategoria.descricao} - {produtoRegional.produtoNacional.produtoCategoria.produtoLinha.descricao}</p>
                </div>
            </div>
        );
    }
    
    render() {
            return (
                <Page icon={'lab.svg'} label={'Novo Laboratório'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                    <Grid container className={'laboratorio-novo'}>
                        <Stepper
                            titles={titles}
                            steps={this.getSteps(this.state)}
                            validation={this.validateLaboratorio}
                            sendForm={this.saveLaboratorio} 
                            history={this.props.history} />
                    </Grid>
                </Page>
            )
    }
}