import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

const HtmlTooltip = withStyles(theme => ({
    tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0)',
        maxWidth: 500,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}))(Tooltip);

export default function CustomizedTooltips(props) {
    return (
        <div>
            <HtmlTooltip title={<React.Fragment>  {props.children}  </React.Fragment>} >
                {props.element}
            </HtmlTooltip>
        </div>
    );
}
