import React, { Component } from 'react';
import GenericButton from '../../components/genericButton/genericButton'
import FormField from '../../components/formField/formField'
import { Grid } from '@material-ui/core'
import GridList from '@material-ui/core/GridList';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import '../perfil/perfilUsuario.scss'

export default class PermissionsTable extends Component {

    busca = '';

    constructor(props) {
        super(props);
        this.state = {filtro: ''};
    }

    aplicarFiltro = (e) => {
        this.setState({filtro: this.busca});
    }

    changeBusca = (e) => {
        this.busca = e.target.value;
    }

    checkIfAlreadyMarked(item) {
        let myReturn = false;
        if (this.props.telaPermitidaList.length > 0) {
            this.props.telaPermitidaList.forEach(function (itemList) {
                if (Number(itemList.tela.id) === Number(item.id)) {
                    myReturn = true;
                }
            });
        }
        return myReturn;
    }

    /* Item Dashboard ISI desativado temporariamente em FormControlLabel para ajustes. */
    getItems = () => {       
        return (
            <Grid container>
                <Grid item xs={12} className={'grid-height'} id={'grid-height'}>
                    <div className={'list'}>
                        <GridList cellHeight={45} cols={1} scrolltoindex={0}>
                            {this.props.telasList != null && this.props.telasList !== [] ? 
                                this.props.telasList.map((item, index) => (
                                item.titulo.toUpperCase().includes(this.state.filtro.toUpperCase()) ?
                                    <div key={index + Math.random()} cols={1} className='tile-grid'>
                                        <div className='list-tick-items'>
                                            <label className='tick-label'> {item.titulo.toUpperCase() + ' (' + item.grupoTela.nome.toUpperCase() + ')'} </label>
                                            <FormControlLabel className='tick-item' label={''} control={<Checkbox disabled={item.titulo == 'Dashboard ISI' ? true : false} defaultChecked={this.checkIfAlreadyMarked(item)} 
                                                onChange={(e) => {this.props.changeTelasPermitidas(item)}} style={{color: '#077CDC'}} />} />
                                        </div>
                                    </div> 
                                    : null
                                )) 
                                : <div></div>
                            }
                        </GridList>
                    </div>
                </Grid>
            </Grid>
        )       
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={10}>
                    <div className='title-with-count'>
                        <label className='left'>Permissões</label>
                        <label className='right'>{this.props.telaPermitidaList.length} de {this.props.telasList.length}</label>
                    </div>
                    <FormField type={'text'} label={''} placeholder={'busque pelo nome da tela'} id={'busca'} pressEnter={this.aplicarFiltro} changeValue={this.changeBusca} 
                        button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={this.aplicarFiltro} />} />
                    {this.getItems()}
                </Grid>
            </Grid>
        )
    }
}
