import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../comm/API';
import DashboardCard from '../dashboardCard/dashboardCard';

export default class TurmaCard extends Component {

    constructor(props) {
        super(props);
        this.state = { cards: [] };

        API.get('rest/sistema/DashboardBrasilMais/turmaCards').then(res => {
		    this.setState({ cards: res.data ? res.data.cards[0] : [] })
		}).catch(error => {
			console.log(error);
		});
    }

    getCard(icon, description, value, size) {
        return(       
            <Grid item xs={size} className={'card'}>
                <DashboardCard color={'card-light-gray'} text={description} value={value} icon={icon} id={'invertido'} style={{ width: 300, padding: icon === 'unidades-operacionais' ? 0 : '20px' }} /> 
            </Grid>
        );
    }

    render() {
        const cards = this.state.cards;
        return ( cards && this.props.perfil !== 'UO' ?
            <Grid container spacing={3} justify={'space-between'}>
                <Grid item xs={12} className={'dashboard-cards flex-item'}>
                    {this.getCard("alunos-mentorados", "Alunos mentorados pelo Brasil Mais", (cards[0] ? cards[0].toLocaleString('pt-BR') : 0), 12)}
                </Grid>
                <Grid item xs={12} className={'dashboard-cards flex-item'}>
                    {this.getCard("empresas-atendidas", "Empresas atendidas pelo Brasil Mais", (cards[1] ? cards[1].toLocaleString('pt-BR') : 0), 12)}
                </Grid>
                <Grid item xs={12} className={'dashboard-cards flex-item'}>
                    {this.getCard("mentores-ativos", "Mentores ativos atendendo o Brasil Mais", (cards[2] ? cards[2].toLocaleString('pt-BR') : 0), 12)}
                </Grid>
                <Grid item xs={12} className={'dashboard-cards flex-item'}>
                    {this.getCard("unidades-operacionais", "Unidades operacionais atendendo o Brasil Mais", (cards[3] ? cards[3].toLocaleString('pt-BR') : 0), 12)}
                </Grid> 
                <Grid item xs={12} className={'dashboard-cards flex-item'}>
                    {this.getCard("turmas-br-mais", "Turmas Brasil Mais", (cards[4] ? cards[4].toLocaleString('pt-BR') : 0), 12)}
                </Grid>
            </Grid> :
            <Grid container spacing={3}>
                {this.getCard("alunos-mentorados", "Alunos mentorados pelo Brasil Mais", (cards[0] ? cards[0].toLocaleString('pt-BR') : 0), 6)}
                {this.getCard("empresas-atendidas", "Empresas atendidas pelo Brasil Mais", (cards[1] ? cards[1].toLocaleString('pt-BR') : 0), 6)}
                {this.getCard("mentores-ativos", "Mentores ativos atendendo o Brasil Mais", (cards[2] ? cards[2].toLocaleString('pt-BR') : 0), 6)}
                {this.getCard("turmas-br-mais", "Turmas Brasil Mais", (cards[4] ? cards[4].toLocaleString('pt-BR') : 0), 6)}
            </Grid>
        );
    }
}

