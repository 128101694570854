import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import IndicatorCards from '../../../components/indicatorCard/indicatorCard';
import GraficoPayback from './graficoPayback';
import GraficoProdutividadeEstadoSegmento from './graficoProdutividadeEstadoSegmento';
import GraficoProdutividadeUnidadeSegmento from './graficoProdutividadeUnidadeSegmento';
import GraficoPaybackSegmentoUnidade from './graficoPaybackSegmentoUnidade';
import IndicatorCardNew from '../../../components/indicatorCard/indicatorCardNew';
import GraficoMediaProdutividade from './graficoMediaProdutividade';

export default class DashboardBrasilMaisIndicadores extends Component {

    render() {
        if (this.props.perfil === 'DN') 
        	return (
        		<Grid container className={'dashboard-brasil-mais-indicadores'}>
        			<IndicatorCardNew title={'Média nacional dos indicadores Brasil Mais'} profile={'DN'} fase ={this.props.fase} />
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <GraficoMediaProdutividade fase={this.props.fase} />
                    </Grid>
        		</Grid>
        	);
        else if (this.props.perfil === 'DR')
            return (
                <Grid container className={'dashboard-brasil-mais-indicadores'}>
                    <IndicatorCards title={'Média estadual dos indicadores Brasil Mais'} profile={'DR'} />
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <GraficoProdutividadeUnidadeSegmento usuarioConectado={this.props.usuarioConectado} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <GraficoPaybackSegmentoUnidade usuarioConectado={this.props.usuarioConectado} />
                    </Grid>
                </Grid>
            );
        else if (this.props.perfil === 'UO')
            return (
                <Grid container className={'dashboard-brasil-mais-indicadores'}>
                    <IndicatorCards title={'Média dos indicadores Brasil Mais'} profile={'UO'} />
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <GraficoProdutividadeUnidadeSegmento usuarioConectado={this.props.usuarioConectado} perfil={this.props.perfil} hideCarregaMais={true} />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: '24px' }}>
                        <GraficoPaybackSegmentoUnidade usuarioConectado={this.props.usuarioConectado} perfil={this.props.perfil} hideCarregaMais={true} />
                    </Grid>
                </Grid>
            );
    };

};