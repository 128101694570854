export const IndicadoresEnum = {
    ProdutividadeLean: 1,
    QualidadeReducaoDeRefugoRetrabalho: 2,
    ReducaoDeMovimentacaoLean: 3,
    RetornoDoPrograma: 4,
    ReducaoDeConsumoEnergeticoPercentual: 5,
    ReducaoDeCustoComEnergia: 6,
    Colaborador: 7,
    OEE: 8,
    DisponibilidadeOEE: 9,
    PerformanceOEE: 10,
    QualidadeLean: 11,
    EmissaoDeCO2Evitada: 12,
    UsinaEquivalente: 13,
    CasaEquivalente: 14,
    QualidadeOEE: 15,
    ProdutividadeDigitalizacao: 16,
    RetornoDoProgramaDigitalizacao: 17,
    AnaliseDeRisco: 18,
    Indicador5: 19,
    Indicador6: 20,
    Indicador7: 21,
    Indicador8: 22,
    Indicador9: 23,
    Indicador10: 24,
    NumeroDeOperadoresPorTurno: 25,
    QuantidadeDeHorasPorTurno: 26,
    QuantidadeProduzidaNasHorasTrabalhadasPorTurno: 27,
    ReducaoDeConsumoEnergetico: 28,
    RetornoDoProgramaEE: 29,
    GanhoFinanceiroMensal: 30,
    EmpresaAptaACategoriaSmartFactory: 31,
    ProcessosNivelDeIntegracaoVertical: 32,
    ProcessosNivelDeIntegracaoHorizontal: 33,
    ProcessosNivelDeIntegracaoDoCicloDeVidaDoProduto: 34,
    TecnologiaNivelDeAutomacaoNoChaoDeFabrica: 35,
    TecnologiaNivelDeAutomacaoNaEmpresa: 36,
    TecnologiaNivelDeAutomacaoNasInstalacoes: 37,
    TecnologiaNivelDeConectividadeNoChaoDeFabrica: 38,
    TecnologiaNivelDeConectividadeNaEmpresa: 39,
    TecnologiaNivelDeConectividadeNasInstalacoes: 40,
    TecnologiaNivelDeInteligenciaNoChaoDeFabrica: 41,
    TecnologiaNivelDeInteligenciaNaEmpresa: 42,
    TecnologiaNivelDeInteligenciaNasInstalacoes: 43,
    OrganizacaoNivelDeDesenvolvimentoECapacitacaoDaMaoDeObra: 44,
    OrganizacaoNivelDeLideranca: 45,
    OrganizacaoNivelDeColaboracaoIntraEEntreEmpresas: 46,
    OrganizacaoNivelDeEstrategiaEGovernanca: 47,
    DimensaoPriorizadaParaInvestimento: 48,
    FaixaDeInvestimento: 49,
    IncluiHardware: 50,
    IncluiSoftware: 51,
    IncluiServico: 52,
    TecnologiaPrincipal: 53,
    OpcaoDaEmpresa: 54,
    OportunidadeDePDIIdentificada: 55,
    CNPJDoFornecedorPrincipal: 56,
    CNPJDaEmpresaAtendida: 57
  };
  