import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import MentoriaAlinhamentoDigital from './MentoriaAlinhamentoDigital';

import MentoriaPraticaD from './mentoriaPraticaDDigital';
import MentoriaPraticaCDigital from './mentoriaPraticaCDigital';
import ConsultoriaB from './consultoriaBDigital';
import ConsultoriaC from './consultoriaCDigital';
import ConsultoriaD from './consultoriaDDigital';
import MentoriaPraticaBDigital from './MentoriaPraticaBDigital';
import MentoriaTeoricaDigital from './mentoriaTeoricaDigital';

const etapa = {
    EDUCACAO_DISTANCIA: 1,
    MENTORIA_ALINHAMENTO: 2,
    MENTORIA_TEORICA_B: 3,
    MENTORIA_PRATICA_B: 4,
    CONSULTORIA_B: 5,
    MENTORIA_TEORICA_C: 6,
    MENTORIA_PRATICA_C: 7,
    CONSULTORIA_C: 8,
    MENTORIA_TEORICA_D: 9,
    MENTORIA_PRATICA_D: 10,
    CONSULTORIA_D: 11,
}

const arrayFerramentas = [
    { name: 'fluxoContinuo', codAtividade: 3 },
    { name: 'qualidadeNaFonte', codAtividade: 4 },
    { name: 'trocaRapidaFerramenta', codAtividade: 5 },
    { name: 'cincoS', codAtividade: 6 },
    { name: 'trabalhoPadronizado', codAtividade: 7 },
    { name: 'producaoPuxada', codAtividade: 8 },
    { name: 'mfv' }
];

export default class EtapaMentoriaDigital extends Component {

    constructor(props) {
        super(props);
        this.state = { turma: props.turma };
    };

    componentDidMount() {
        this.props.closeLoading();
    };

    renderEtapa() {
        
        switch (this.props.etapa) {
            case etapa.EDUCACAO_DISTANCIA:
                return;

            case etapa.MENTORIA_ALINHAMENTO:
                return (<MentoriaAlinhamentoDigital turma={this.state.turma} index={this.props.etapa} fase={'B'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast} mentoresDados={this.props.mentoresDados.etapaAlinhamentoB}></MentoriaAlinhamentoDigital>);

            case etapa.MENTORIA_TEORICA_B:
                return (<MentoriaTeoricaDigital turma={this.state.turma} index={this.props.etapa} fase={'B'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast} mentoresDados={this.props.mentoresDados.etapaTeoricaB}></MentoriaTeoricaDigital>);

            case etapa.MENTORIA_PRATICA_B:

                return (<MentoriaPraticaBDigital turma={this.state.turma} index={this.props.etapa} fase={'B'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast} mentoresDados={this.props.mentoresDados.etapaPraticaB}></MentoriaPraticaBDigital>);
            
            case etapa.CONSULTORIA_B:
                return(
                    <ConsultoriaB 
                        turma={this.state.turma} 
                        index={this.props.etapa} 
                        fase={'B'} 
                        goBack={this.props.goBack} 
                        saveTurma={this.props.save}
                        closeLoading={this.props.closeLoading} 
                        showLoading={this.props.showLoading} 
                        showToast={this.props.showToast}
                        mentoresDados={this.props.mentoresDados.etapaConsultoriaB}
                    />
                )
            case etapa.MENTORIA_TEORICA_C:
                return (<MentoriaTeoricaDigital turma={this.state.turma} index={this.props.etapa} fase={'C'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast} mentoresDados={this.props.mentoresDados.etapaTeoricaC}></MentoriaTeoricaDigital>);

            case etapa.MENTORIA_PRATICA_C:
                return (<MentoriaPraticaCDigital turma={this.state.turma} index={this.props.etapa} fase={'C'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast} mentoresDados={this.props.mentoresDados.etapaPraticaC}></MentoriaPraticaCDigital>);

            case etapa.CONSULTORIA_C: 
                return (
                    <ConsultoriaC 
                        turma={this.state.turma} 
                        arrayFerramentas={arrayFerramentas} 
                        index={this.props.etapa} 
                        fase={'C'} 
                        goBack={this.props.goBack} 
                        saveTurma={this.props.save}
                        closeLoading={this.props.closeLoading} 
                        showLoading={this.props.showLoading} 
                        showToast={this.props.showToast}
                        mentoresDados={this.props.mentoresDados.etapaConsultoriaC} />
                );

            case etapa.MENTORIA_TEORICA_D:
                return (<MentoriaTeoricaDigital turma={this.state.turma} index={this.props.etapa} fase={'D'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast} mentoresDados={this.props.mentoresDados.etapaTeoricaD}></MentoriaTeoricaDigital>);

            case etapa.MENTORIA_PRATICA_D:
                return (
                    <MentoriaPraticaD 
                        turma={this.state.turma} 
                        arrayFerramentas={arrayFerramentas} 
                        index={this.props.etapa} 
                        fase={'D'} 
                        goBack={this.props.goBack} 
                        saveTurma={this.props.save}
                        closeLoading={this.props.closeLoading} 
                        showLoading={this.props.showLoading} 
                        showToast={this.props.showToast}
                        mentoresDados={this.props.mentoresDados.etapaPraticaD} />
                );

            case etapa.CONSULTORIA_D:
                return (
                    <ConsultoriaD 
                        turma={this.state.turma} 
                        index={this.props.etapa} 
                        fase={'D'} 
                        goBack={this.props.goBack} 
                        saveTurma={this.props.save}
                        closeLoading={this.props.closeLoading} 
                        showLoading={this.props.showLoading} 
                        showToast={this.props.showToast} 
                        usuarioConectado={this.props.usuarioConectado}
                        mentoresDados={this.props.mentoresDados.etapaConsultoriaD}
                    />
                );

            default:
                return;
        }
    };

    render() {
        return this.state.turma ? 
        (
            <Grid container className={'etapa'}>
                {this.renderEtapa()}
            </Grid>
        ): null;
    };
}
