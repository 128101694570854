import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBar from '../../../components/graphBar/graphBar';

export default class GraficoAtendimentosPorEstado extends Component {

    estados = { 
        'AC': 'Acre', 'AL': 'Alagoas', 'AM': 'Amazonas', 'AP': 'Amapá', 'BA': 'Bahia', 'CE': 'Ceará',
        'DF': 'Distrito Federal', 'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão', 'MG': 'Minas Gerais',
        'MS': 'Mato Grosso do Sul', 'MT': 'Mato Grosso', 'PA': 'Pará', 'PB': 'Paraíba', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'PR': 'Paraná', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte', 'RO': 'Rondônia', 
        'RR': 'Roraima', 'RS': 'Rio Grande do Sul', 'SC': 'Santa Catarina', 'SE': 'Sergipe', 'SP': 'São Paulo', 'TO': 'Tocantins'
    };

    tooltipAtendimento = {
        formatter: function() {
            let tooltip = `<p style="font: bold 13px Raleway; color: #707070">${this.series.name.serieName}</p><br>` +
            `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.series.chart.options.fullCategories[this.point.category]}: ${this.point.y}</p><br>` +
            `<p style="font: 13px Raleway; color: #707070">Total: ${this.point.total}</p>`;
            return tooltip;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };
    tooltipProdutividade = {
        formatter: function() {
            return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.point.y}%</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    legendAtendimento = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function() {
            return '<div style="cursor:auto;display:flex;align-items:center;"><div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '"></div><label style="font-weight:500;font-size:11px">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
    };
    legendProdutividade = { enabled: false };

    plotOptionsAtendimento = {
        series: {
            states: {
                inactive: {
                  opacity: 1
                }
              },
              pointPadding: 0.25,
        },
        column: {
            stacking: 'normal',
            borderColor: null,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };
    plotOptionsProdutividade = {
        column: {
            pointPadding: 0.2,
            borderWidth: 0,
            zones: [{ value: 20, color: '#D44E54' }, { value: 100, color: '#00B1E8' }, { color: '#E9B03D' }],
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };
    plotLinesProdutividade = [
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 20 }, 
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 100 }
    ];

    labelsYProdutividade =  {
        formatter: function() {
            if(this.value <= 20 || (this.value === 100))
                return this.value + "%";
        },
        style: {
            color: '#707070'
        }
    };

    constructor(props) {
        super(props);
        let categories = Object.keys(this.estados);
        this.state = {
            switch: [
                { 
                    label: 'Produtividade', 
                    title: 'Média do aumento de produtividade industrial por estado', 
                    tooltip: this.tooltipProdutividade, 
                    categories: categories, 
                    series: [],
                    seriesFunction: this.fillGraficoProdutividade,
                    tickInterval: 20,
                    legend: this.legendProdutividade,
                    height: 250,
                    plotOptions: this.plotOptionsProdutividade,
                    plotLines: this.plotLinesProdutividade,
                    labelsY: this.labelsYProdutividade,
                },
                {
                    label: 'Atendimento', 
                    title: 'Atendimentos Brasil Mais por estado num período', 
                    tooltip: this.tooltipAtendimento, 
                    categories: categories, 
                    fullCategories: this.estados,
                    series: [],
                    seriesFunction: this.fillGraficoAtendimento,
                    tickInterval: 100,
                    legend: this.legendAtendimento,
                    height: 250,
                    plotOptions: this.plotOptionsAtendimento
                }
            ]
        };
    };

    fillGraficoAtendimento = async (dataInicio, dataTermino) => {
        let series = [];
        await API.get(`rest/sistema/DashboardBrasilMais/geralAtendimentosProdutividade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${true}`).then(async res => {
            let dataNegociacao = [];
            let dataAceitacao = [];
            let dataConcluido = [];
            let dataExecucao = [];
            if (res.data != null && res.data.estados != null) {
                await res.data.estados.forEach(estado => {
                    dataNegociacao.push(estado[1]);
                    dataAceitacao.push(estado[2]);
                    dataConcluido.push(estado[3]);
                    dataExecucao.push(estado[4]);
                });
                series = [{
                        name: { legendName: 'Atendimentos em negociação', serieName: 'Negociação'},
                        data: dataNegociacao,
                        color: '#E9B03D'
                    }, {
                        name: { legendName: 'Atendimentos aceitos', serieName: 'Aceitos'},
                        data: dataAceitacao,
                        color: '#B7EE8E'
                    }, {
                        name: { legendName: 'Atendimentos em execução', serieName: 'Execução'},
                        data: dataExecucao,
                        color: '#7DAE59'
                    }, {
                        name: { legendName: 'Atendimentos concluídos', serieName: 'Concluídos'},
                        data: dataConcluido,
                        color: '#00B1E8'
                    }
                ];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series];
    };

    fillGraficoProdutividade = async (dataInicio, dataTermino) => {
        let series = [];
        await API.get(`rest/sistema/DashboardBrasilMais/geralAtendimentosProdutividade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${false}`).then(async res => {
            if (res.data != null && res.data.estados != null) {
                series = await [{data: res.data.estados.map(i => i[1]) }];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series];
    };
   
    render() {
        return (
            <Grid container justify={'center'} style={{ height: '100%' }}>      
                <GraphBar switch={this.state.switch} disabledSwitch={false} hasFilter={true} init={'right'} border={'border-azul'} />
            </Grid>
        );
    };

}