import { Grid, Tooltip } from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from 'react';
import API from '../../comm/API';
import CollapseGraph from '../collapseGraph/collapseGraph';
import DashboardCard from '../dashboardCard/dashboardCard';

export default class IndicatorCardNew extends Component {

    constructor(props) {
        super(props);
        this.state = { indicadores: {
            lean : null,
            digital : null,
            ambos : null,
            atendimentos_totais : null
        } };

        API.get('rest/sistema/DashboardBrasilMais/indicadores').then(res => {
			this.setState({ indicadores: res.data })
		}).catch(error => {
			console.log(error);
			this.showToast('Erro ao buscar cards do dashboard. Favor, entrar em contato com o suporte.', 'error');
		});
    }
    
    getCard(icon, description, infoCard, value, lowValue,fase,tooltip) {
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway',
                maxWidth: '500px'
            }
          })(Tooltip);

        return(
            <Grid container>
                <Grid item xs={12} className={'card'}>
                    <Grid container direction={'column'} justify={'center'} className={'card-dark-blue-title'}>
                        <Grid item className={'font-title'}>{fase}</Grid>
                    </Grid>
                    <Grid container direction={'column'} justify={'center'} className={'card-light-gray-tool'}>
                        <Grid container item
                        justify={"flex-end"}
                        alignItems={"center"} 
                        style={{maxWidth:"100%" ,width:"100%"}}>
                            <div style={{marginRight:"12px"}}>
                                {tooltip ? <ModifiedTooltip 
                                    title={tooltip}
                                    placement={"right"} arrow>                                        
                                        <img style={{height: 22, width: 22}} src={process.env.PUBLIC_URL +  '/assets/icons/info-dash-gray.svg'}                                                             
                                        alt={'icon'} />
                                </ModifiedTooltip> :
                                <div style={{height: 22}}/>}
                            </div>                        
                        </Grid>
                    </Grid>
                    <Grid container direction={'column'} justify={'center'} className={'card-light-gray'}>
                        <Grid item>
                            <img className={'icon-sector'} src={`./../../assets/icons/${icon}.svg`} alt=''/>
                        </Grid>
                        <Grid item className={'font-sector'}>{description}</Grid>
                    </Grid>
                    <Grid item xs={12}>                        
                        <DashboardCard color={'card-green-new'} text={infoCard} value={value} id={'invertido'} />
                        { (icon === 'produtividade') && 
                            <Grid item xs={12}>
                                <DashboardCard color={'card-orange'} text={'Número de atendimentos abaixo da meta'} value={lowValue} id={'invertido'} /> 
                            </Grid> }
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    render() {
        let infoMedia = 'Média estadual ';

        if(this.props.profile === 'DN') {
            infoMedia = 'Média nacional ';
        } else if (this.props.profile === 'UO') {
            infoMedia = 'Média '
        }
        let atdLD = 0;
        let atdL = 0;
        let atdD = 0;
        if(this.state.indicadores.atendimentos_totais){
            atdLD = this.state.indicadores.atendimentos_totais.ambos;
            atdL = this.state.indicadores.atendimentos_totais.lean;
            atdD = this.state.indicadores.atendimentos_totais.digital;


        }

        let contentPanelLD ={
			id: 'BrasilMaisInfo',
			body :	
			<div className='dashboard-extra-info'>
            	<label>Atendimentos concluídos (Brasil Mais): <span className='blue-value'> {atdLD}</span> </label>
				<label>Fórmula para todos os cálculos: <span className='blue-value'>  Medição FINAL ÷ Medição INICIAL </span> </label>
				<label>Cálculos realizados apenas com indicadores de atendimentos concluídos.</label>
			</div>
		};
        let contentPanelL ={
			id: 'MentoriaLeanInfo',
			body :	
			<div className='dashboard-extra-info'>
            	<label>Atendimentos concluídos (Mentoria Lean): <span className='blue-value'> {atdL}</span> </label>
				<label>Fórmula para todos os cálculos: <span className='blue-value'>  Medição FINAL ÷ Medição INICIAL </span> </label>
				<label>* Os indicadores de “Movimento” e "Qualidade" não são obrigatórios na trilha do Mentoria Lean e o resultado apresentado refere-se apenas às medições das empresas que coletaram os dados.</label>
			</div>
		};
        let contentPanelD ={
			id: 'MentoriaDigitalInfo',
			body :	
			<div className='dashboard-extra-info'>
            	<label>Atendimentos concluídos (Mentoria Digital): <span className='blue-value'> {atdD}</span> </label>
				<label>Fórmula para todos os cálculos: <span className='blue-value'>  Medição FINAL ÷ Medição INICIAL </span> </label>
				<label>Cálculos realizados apenas com indicadores de atendimentos concluídos.</label>
			</div>
		};

        const indicadores = this.state.indicadores;
        return (
            <Grid container spacing={3} >
                <Grid item xs={12} justify={'center'} >
                    <Grid item className={'font-title-indicators'}>MÉDIA NACIONAL DOS INDICADORES</Grid>
                    <Grid item className={'font-title-indicators-subtitle'}>
                        {this.props.fase == "L" ? `Fase 1: Mentoria Lean`	:
							this.props.fase == "D" ? `Fase 2: Mentoria Digital` : `Brasil Mais`	}
                    </Grid>
                    <Grid item xs={12} className={'line'}></Grid>
                </Grid>
                {this.props.fase == "LD" && <Grid item container xs={12} justify={'center'}>
                    <Grid item container xs={6} justify={'center'}>
                        <Grid item xs={6} className={'dashboard-cards'} style={{paddingRight:'10px'}}>
                            {this.getCard(
                                "produtividade",
                                <div className='indicator-title'>
                                    <label>PRODUTIVIDADE</label>
                                    <label>INDUSTRIAL</label>
                                </div>,
                                infoMedia + 'do aumento de produtividade',
                                (indicadores.ambos ? indicadores.ambos.mediaprodutividade.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                                indicadores.ambos ? indicadores.ambos.abaixo_meta.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0,
                                "BRASIL MAIS",
                                <div>
                                Fórmula: <br />
                                (Produtividade FINAL<br />
                                ÷ Produtividade INICIAL) - 1
                                </div>)}
                        </Grid>
                        <Grid item xs={6} className={'dashboard-cards'} style={{paddingLeft:'10px'}}>
                            {this.getCard(
                            "payback",
                            <div className='indicator-title'>
                                <label>PAYBACK</label>
                            </div>,
                            infoMedia + 'do tempo de payback',
                            (indicadores.ambos ? indicadores.ambos.payback.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + ' meses',
                            null,
                            "BRASIL MAIS",
                            <div>
                            Fórmula: <br /> 
                            Valor total da consultoria<br />
                            ÷ Retorno mensal na empresa após intervenções
                            </div>)}
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'10px'}}>
                            <CollapseGraph
                            content={[contentPanelLD]}
                            isExpanded={true}
                            hideButton={true}
                            />
				        </Grid> 
                    </Grid>             
                </Grid>}
                {this.props.fase == "LD" && <Grid item container xs={12} justify={'center'}>
                    <Grid item container xs={6} alignItems="flex-start" justifyContent="flex-start" style={{paddingRight:'10px'}}>
                        <Grid item xs={6} className={'dashboard-cards'} style={{paddingRight:'10px'}}>
                            {this.getCard(
                                "movimento",
                                <div className='indicator-title'>
                                    <label>MOVIMENTO</label>
                                    <label>(Opcional)</label>
                                </div>,
                                infoMedia + 'da redução de movimento',
                                (indicadores.ambos ? indicadores.ambos.movimento.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                                null,
                                "MENTORIA LEAN",
                                <div>
                                Fórmula: <br />
                                (Movimento FINAL <br />
                                ÷ Movimento INICIAL) -1
                                </div>)}
                        </Grid>
                        <Grid item xs={6} className={'dashboard-cards'} style={{paddingLeft:'10px'}}>
                            {this.getCard(
                            "qualidade",
                            <div className='indicator-title'>
                                <label>QUALIDADE</label>
                                <label>(Opcional)</label>
                            </div>,
                            infoMedia + 'do aumento da qualidade',
                            (indicadores.ambos ? indicadores.ambos.qualidade.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                            null,
                            "MENTORIA LEAN",
                            <div>
                            Fórmula: <br />
                            (Qualidade FINAL <br />
                            ÷ Qualidade INICIAL) -1 
                            </div>)}
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'10px'}}>
                            <CollapseGraph
                            content={[contentPanelL]}
                            isExpanded={true}
                            hideButton={true}
                            />
				        </Grid> 
                    </Grid>
                    <Grid item container xs={6} alignItems="flex-start" justifyContent="flex-start" style={{paddingLeft:'10px'}}>
                        <Grid item xs={6} className={'dashboard-cards'} style={{paddingRight:'10px'}}>
                            {this.getCard(
                                "oee",
                                <div className='indicator-title'>
                                    <label>OEE </label>
                                    <label>Overall Equipment Effectiveness</label>
                                </div>,
                                infoMedia + 'do aumento de OEE',
                                (indicadores.ambos ? indicadores.ambos.oee.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                                null,
                                "MENTORIA DIGITAL",
                                <div>
                                Fórmula: <br />
                                (OEE FINAL <br />
                                ÷ OEE INICIAL) -1
                                </div>)}
                        </Grid>
                        <Grid item xs={6} className={'dashboard-cards'} style={{paddingLeft:'10px'}}>
                            {this.getCard(
                            "maturidade",
                            <div className='indicator-title'>
                                <label>MATURIDADE</label>
                            </div>,
                            infoMedia + 'do aumento de maturidade',
                            (indicadores.ambos ? indicadores.ambos.maturidade.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                            null,
                            "MENTORIA DIGITAL",
                            <div>
                            Fórmula: <br />
                            (Maturidade FINAL <br />
                            ÷ Maturidade INICIAL) -1
                            </div>)}
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'10px'}}>
                            <CollapseGraph
                            content={[contentPanelD]}
                            isExpanded={true}
                            hideButton={true}
                            />
				        </Grid> 
                    </Grid>             
                </Grid>}
                {this.props.fase == "L" && <Grid item container xs={12} justify={'center'}>
                    <Grid item container xs={12} alignItems="flex-start" justifyContent="flex-start" >
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingRight:'10px'}}>
                            {this.getCard(
                                "produtividade",
                                <div className='indicator-title'>
                                    <label>PRODUTIVIDADE</label>
                                    <label>INDUSTRIAL</label>
                                </div>,
                                infoMedia + 'do aumento de produtividade',
                                (indicadores.lean ? indicadores.lean.mediaprodutividade.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                                indicadores.lean ? indicadores.lean.abaixo_meta.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0,
                                "MENTORIA LEAN",
                                <div>
                                Fórmula: <br />
                                Produtividade FINAL <br />
                                ÷ Produtividade INICIAL
                                </div>)}
                        </Grid>
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingLeft:'10px',paddingRight:'10px'}}>
                        {this.getCard(
                            "payback",
                            <div className='indicator-title'>
                                <label>PAYBACK</label>
                            </div>,
                            infoMedia + 'do tempo de payback',
                            (indicadores.lean ? indicadores.lean.payback.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + ' meses',
                            null,
                            "MENTORIA LEAN",
                            <div>
                            Fórmula: <br /> 
                            Valor total da consultoria<br />
                            ÷ Retorno mensal na empresa após intervenções
                            </div>)}
                        </Grid>
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingRight:'10px',paddingLeft:'10px'}}>
                            {this.getCard(
                                "movimento",
                                <div className='indicator-title'>
                                    <label>MOVIMENTO</label>
                                    <label>(Opcional)</label>
                                </div>,
                                infoMedia + 'da redução de movimento',
                                (indicadores.lean ? indicadores.lean.movimento.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                                null,
                                "MENTORIA LEAN",
                                <div>
                                Fórmula: <br />
                                Movimento FINAL <br />
                                ÷ Movimento INICIAL
                                </div>)}
                        </Grid>
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingLeft:'10px'}}>
                            {this.getCard(
                            "qualidade",
                            <div className='indicator-title'>
                                <label>QUALIDADE</label>
                                <label>(Opcional)</label>
                            </div>,
                            infoMedia + 'do aumento da qualidade',
                            (indicadores.lean ? indicadores.lean.qualidade.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                            null,
                            "MENTORIA LEAN",
                            <div>
                            Fórmula: <br />
                            Qualidade FINAL <br />
                            ÷ Qualidade INICIAL
                            </div>)}
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'10px'}}>
                            <CollapseGraph
                            content={[contentPanelL]}
                            isExpanded={true}
                            hideButton={true}
                            />
				        </Grid> 
                    </Grid>             
                </Grid>}
                {this.props.fase == "D" && <Grid item container xs={12} justify={'center'}>
                    <Grid item container xs={12} alignItems="flex-start" justifyContent="flex-start" >
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingRight:'10px'}}>
                            {this.getCard(
                                "produtividade",
                                <div className='indicator-title'>
                                    <label>PRODUTIVIDADE</label>
                                    <label>INDUSTRIAL</label>
                                </div>,
                                infoMedia + 'do aumento de produtividade',
                                (indicadores.digital ? indicadores.digital.mediaprodutividade.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                                indicadores.digital ? indicadores.digital.abaixo_meta.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0,
                                "MENTORIA DIGITAL",
                                <div>
                                Fórmula: <br />
                                Produtividade FINAL <br />
                                ÷ Produtividade INICIAL
                                </div>)}
                        </Grid>
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingLeft:'10px',paddingRight:'10px'}}>
                        {this.getCard(
                            "payback",
                            <div className='indicator-title'>
                                <label>PAYBACK</label>
                            </div>,
                            infoMedia + 'do tempo de payback',
                            (indicadores.digital ? indicadores.digital.payback.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + ' meses',
                            null,
                            "MENTORIA DIGITAL",
                            <div>
                            Fórmula: <br /> 
                            Valor total da consultoria<br />
                            ÷ Retorno mensal na empresa após intervenções
                            </div>)}
                        </Grid>
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingRight:'10px',paddingLeft:'10px'}}>
                            {this.getCard(
                                "oee",
                                <div className='indicator-title'>
                                    <label>OEE </label>
                                    <label>Overall Equipment Effectiveness</label>
                                </div>,
                                infoMedia + 'do aumento de OEE',
                                (indicadores.digital ? indicadores.digital.oee.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                                null,
                                "MENTORIA DIGITAL",
                                <div>
                                Fórmula: <br />
                                OEE FINAL <br />
                                ÷ OEE INICIAL
                                </div>)}
                        </Grid>
                        <Grid item xs={3} className={'dashboard-cards'} style={{paddingLeft:'10px'}}>
                            {this.getCard(
                            "maturidade",
                            <div className='indicator-title'>
                                <label>MATURIDADE</label>
                            </div>,
                            infoMedia + 'do aumento de maturidade',
                            (indicadores.digital ? indicadores.digital.maturidade.toLocaleString('pt-BR', { maximumFractionDigits: 2 }) : 0) + '%',
                            null,
                            "MENTORIA DIGITAL",
                            <div>
                            Fórmula: <br />
                            Maturidade FINAL <br />
                            ÷ Maturidade INICIAL
                            </div>)}
                        </Grid>
                        <Grid item xs={12} style={{paddingTop:'10px'}}>
                            <CollapseGraph
                            content={[contentPanelD]}
                            isExpanded={true}
                            hideButton={true}
                            />
				        </Grid> 
                    </Grid>             
                </Grid>}           
                
            </Grid>
        );
    }
}

