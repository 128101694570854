import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import MentoriaTeorica from './mentoriaTeorica';
import MentoriaPraticaB from './mentoriaPraticaB';
import MentoriaPraticaC from './mentoriaPraticaC';
import MentoriaPraticaD from './mentoriaPraticaD';
import ConsultoriaC from './consultoriaC';
import ConsultoriaD from './consultoriaD';

const etapa = {
    EDUCACAO_DISTANCIA: 1,
    MENTORIA_TEORICA_B: 2,
    MENTORIA_PRATICA_B: 3,
    MENTORIA_TEORICA_C: 4,
    MENTORIA_PRATICA_C: 5,
    CONSULTORIA_C: 6,
    MENTORIA_TEORICA_D: 7,
    MENTORIA_PRATICA_D: 8,
    CONSULTORIA_D: 9,
}

const arrayFerramentas = [
    { name: 'fluxoContinuo', codAtividade: 3 },
    { name: 'qualidadeNaFonte', codAtividade: 4 },
    { name: 'trocaRapidaFerramenta', codAtividade: 5 },
    { name: 'cincoS', codAtividade: 6 },
    { name: 'trabalhoPadronizado', codAtividade: 7 },
    { name: 'producaoPuxada', codAtividade: 8 },
    { name: 'mfv' }
];

export default class Etapa extends Component {

    constructor(props) {
        super(props);
        this.state = { turma: props.turma };
    };

    componentDidMount() {
        this.props.closeLoading();
    };

    renderEtapa() {
        switch (this.props.etapa) {
            case etapa.EDUCACAO_DISTANCIA:
                return;

            case etapa.MENTORIA_TEORICA_B:
                return (<MentoriaTeorica turma={this.state.turma} index={this.props.etapa} fase={'B'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast}></MentoriaTeorica>);

            case etapa.MENTORIA_PRATICA_B:
                return (<MentoriaPraticaB turma={this.state.turma} index={this.props.etapa} fase={'B'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast}></MentoriaPraticaB>);

            case etapa.MENTORIA_TEORICA_C:
                return (<MentoriaTeorica turma={this.state.turma} index={this.props.etapa} fase={'C'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast}></MentoriaTeorica>);

            case etapa.MENTORIA_PRATICA_C:
                return (<MentoriaPraticaC turma={this.state.turma} index={this.props.etapa} fase={'C'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast}></MentoriaPraticaC>);

            case etapa.CONSULTORIA_C: 
                return (<ConsultoriaC turma={this.state.turma} arrayFerramentas={arrayFerramentas} index={this.props.etapa} fase={'C'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast}></ConsultoriaC>);

            case etapa.MENTORIA_TEORICA_D:
                return (<MentoriaTeorica turma={this.state.turma} index={this.props.etapa} fase={'D'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast}></MentoriaTeorica>);

            case etapa.MENTORIA_PRATICA_D:
                return (<MentoriaPraticaD turma={this.state.turma} arrayFerramentas={arrayFerramentas} index={this.props.etapa} fase={'D'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast}></MentoriaPraticaD>);

            case etapa.CONSULTORIA_D:
                return (<ConsultoriaD turma={this.state.turma} index={this.props.etapa} fase={'D'} goBack={this.props.goBack} saveTurma={this.props.save}
                    closeLoading={this.props.closeLoading} showLoading={this.props.showLoading} showToast={this.props.showToast} usuarioConectado={this.props.usuarioConectado}></ConsultoriaD>);

            default:
                return;
        }
    };

    render() {
        return this.state.turma ? 
        (
            <Grid container className={'etapa'}>
                {this.renderEtapa()}
            </Grid>
        ): null;
    };
}
