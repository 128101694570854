import React, { Component } from 'react'
import { Grid } from "@material-ui/core"
export default class Legenda extends Component {
    render() {
        return (
            <Grid container className={'legenda-divergencia'}>
                <Grid item xs={4}><div className={"square"}></div><span>SGT</span></Grid>
                <Grid item xs={3}><div className={"square"} style={{ backgroundColor: '#B5CFE5' }}></div><span>SI</span></Grid>
                <Grid item xs={5}><i className={"circle after icone"}></i><span>Há Divergência</span></Grid>
            </Grid>
        )
    }
}