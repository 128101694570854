import React, { Component } from 'react';
import EncontrosDefault from './encontrosDefault';
import API from '../../comm/API';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtilDigital';
import AtividadeDefaultDigital from './atividadeDefaultDigital';
import AtendimentosCardDigital from './atendimentosCardDigital';
import AtividadeIndicadoresPerformance from './AtividadeIndicadoresPerformance';
import AtividadeAutossuficiencia from './atividadeAutossuficiencia';
import AtividadeAnaliseDosResultados from './atividadeAnaliseDosResultados';

export default class MentoriaPraticaDDigital extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'encontros'
    };

    constructor(props) {
        super(props);
        const etapaName = `etapaPratica${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.turma = this.props.turma.nome;
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Prática D';
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);
        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoPratica: undefined,
            etapa,
            atendimentoList,
            clientName: '',
            disabled: false
        };
    };

    getIndicadoresList= async (indicadores) =>{
        let list = [];
        list = list.concat(indicadores.avaliacao);
        return await list;
    }

    getIndicadoresDigitalModel = () => {
        return { status: 1, ordemVisita: null, anexoIndicadoresList: [], produtividadeDigital: {id: null, operadoresTurno: '', horasTurno: '', horasParadas: '', prodNaoConforme: '',
        prodRealizada: '', taxaProducao: '', tempoOperacao: '', prodPossivel: '', prodBoa: '', medicao: ''}, maturidadeDigital: { id: null, dataAvaliacao: '', nivel: '', pontuacaoTotal: '',
        pontuacaoEstrategia: '',    pontuacaoManufatura: '', pontuacaoNegocio: '', pontuacaoCultura: '', anexoMaturidadeDigitalList: []}, oeeDigital:{id: null,
        dataInicial: null,dataFinal: null, medicao: null,disponibilidade: null, performance: null, qualidade: null, anexoOEEList: []}};
    };
    
    getAutossuficienciaDigitalModel(){
        return { 
            status: 1, 
            ordemVisita: null, 
            anexoAutossuficienciaList: [], 
            maisInfo: ""
        };
    }

    getAnaliseDosResultadosModel() {
        return {
            ordemVisita: null,
            status: 1,
            calculoRetorno: "",
            retornoMensal: null,
            retornoPrograma: null,
            produtividade: "",
            oee: "",
            disponibilidade: "",
            performance: "",
            qualidade: "",
            analiseRetorno: "",
            resultados: ""
        }
    }


    getModels = () => {
        return {
            indicadoresDigital: this.getIndicadoresDigitalModel(),
            autossuficienciaDigital: this.getAutossuficienciaDigitalModel(),
            analiseResultadosDigital: this.getAnaliseDosResultadosModel(),
        }
    }

    validaAutossuficiencia(autossuficienciaDigital){
        return  !!autossuficienciaDigital.anexoAutossuficienciaList.length && autossuficienciaDigital.maisInfo && autossuficienciaDigital.anexoAutossuficienciaList.every(elm => { return elm.descricao });
    }

    validaAnaliseDosResultados(analiseDosResultados){
        return analiseDosResultados.analiseRetorno && analiseDosResultados.calculoRetorno && analiseDosResultados.disponibilidade && 
        analiseDosResultados.oee && analiseDosResultados.performance && analiseDosResultados.produtividade && analiseDosResultados.qualidade &&
        analiseDosResultados.resultados && analiseDosResultados.retornoMensal && analiseDosResultados.retornoPrograma
    }

    checkDate = (indicadores) =>{
        let indicadoresDigital = indicadores;
        let initDate  = new Date(indicadoresDigital.oeeDigital.dataInicial);
        let finDate = new Date(indicadoresDigital.oeeDigital.dataFinal);
        let cincoDias = 432000000

        return (Math.abs(finDate.getTime() - initDate.getTime()) > cincoDias)
    }


    validateIndicadores(indicadores){
        return indicadores.produtividadeDigital &&
        indicadores.produtividadeDigital.operadoresTurno &&
        indicadores.produtividadeDigital.horasTurno  &&
        indicadores.produtividadeDigital.prodRealizada &&
        indicadores.produtividadeDigital.medicao &&
        indicadores.maturidadeDigital && 
        indicadores.maturidadeDigital.dataAvaliacao &&
        indicadores.maturidadeDigital.nivel && 
        indicadores.maturidadeDigital.pontuacaoTotal && 
        indicadores.maturidadeDigital.pontuacaoEstrategia &&
        indicadores.maturidadeDigital.pontuacaoManufatura && 
        indicadores.maturidadeDigital.pontuacaoNegocio && 
        indicadores.maturidadeDigital.pontuacaoCultura && 
        indicadores.maturidadeDigital.anexoMaturidadeDigitalList && 
        indicadores.maturidadeDigital.anexoMaturidadeDigitalList.length > 0 && 
        indicadores.oeeDigital.dataInicial && 
        indicadores.oeeDigital.dataFinal &&
        indicadores.oeeDigital.medicao && 
        indicadores.oeeDigital.disponibilidade && 
        indicadores.oeeDigital.performance && 
        indicadores.oeeDigital.qualidade &&
        (!indicadores.oeeDigital.isManual || (indicadores.oeeDigital.anexoOEEList.length > 0 && indicadores.oeeDigital.anexoOEEList.every(elm => { return elm.descricao })))
    }
    

    updateViewData = (atendimento) => {
        let index = this.state.turma.atendimentoTurmaList.findIndex(obj => { return obj.atendimento.id === atendimento.idAtendimento });
        this.setState({disabled:this.state.turma.atendimentoTurmaList[index].atendimento.relatorioFinal != null  || this.state.turma.status === 3})

        this.viewData.encontros.data = atendimento.encontroList;
        const { indicadoresDigital, autossuficienciaDigital, analiseResultadosDigital } = atendimento;
        this.viewData.atividades.indicadoresDigital.data =  JSON.parse(JSON.stringify(indicadoresDigital || this.getIndicadoresDigitalModel()));

        this.viewData.atividades.analiseResultadosDigital.disabled = indicadoresDigital ? false : true; 
        this.viewData.atividades.analiseResultadosDigital.data =  JSON.parse(JSON.stringify(analiseResultadosDigital || this.getAnaliseDosResultadosModel()));

        this.viewData.atividades.autossuficienciaDigital.data =  JSON.parse(JSON.stringify(autossuficienciaDigital || this.getAutossuficienciaDigitalModel()));
        this.viewData.atividades.autossuficienciaDigital.anexos.data = getByKeyOrEmpty(autossuficienciaDigital, 'anexoAutossuficienciaList');
    };

    validaAtendimentoPratica = async (atendimentoPratica) => {  
        let encontroList = atendimentoPratica.encontroList;
        let indicadoresDigital = atendimentoPratica.indicadoresDigital;
        if (!atendimentoPratica.cancelado) {
            return await encontroList && encontroList.length > 0 && encontroList.every(encontro => { return this.validaEncontro(encontro) }) &&
            indicadoresDigital && indicadoresDigital.status === this.STATUS_FINALIZADO
        }
        return true;        
    };

    validaEncontro = (encontro) => {
        return encontro && encontro.anexoEncontroList && encontro.anexoEncontroList.length > 0 && !encontro.anexoEncontroList.filter(anx => { return !anx.isDocumento }).some(anx => { return anx.descricao === '' }) &&
        encontro.anexoEncontroList.some(anx => { return anx.descricao === 'lista de presença' && anx.isDocumento }) && encontro.data && encontro.isVirtual != null  && encontro.idMentor != null && encontro.turno != null && encontro.horasApropriadas > 0;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoPratica = this.state.atendimentoPratica;
        let turma = this.props.turma;
        let validado = await this.validaAtendimentoPratica(atendimentoPratica);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoPratica.finalizado = true;
            let indexAtendimento = turma.etapaPraticaB.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
            turma.etapaPraticaD.atendimentoList[indexAtendimento] = atendimentoPratica; //atualiza atendimentoPratica na etapa alinhamento da turma
            let etapa = turma.etapaPraticaD;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaPraticaD;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.props.closeLoading();
                        this.setState({ turma, etapa, atendimentoList, atendimentoPratica: atd }, this.goBackAtendimentos());
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    transformCurrencyStringToNumber = (value) => {
        return Number(value.toString().substring(2).replace(',', '.'));
    }

    validaAtividade = async (viewData, atendimentoPratica, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoPratica[nomeAtividade] ? atendimentoPratica[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 

        if(nomeAnexo && nomeAtividade === 'indicadoresDigital'){
            if(!this.checkDate(atividade)){
                this.props.showToast('O periodo de coleta do OEE precisa ter no minimo 5 dias entre as datas', 'error');
                throw new Error();
            }
            if(atividade.oeeDigital.isManual){
                atividade.oeeDigital.medicao = atividade.medicaoManual;
                atividade.oeeDigital.disponibilidade = atividade.disponibilidadeManual;
                atividade.oeeDigital.performance = atividade.performanceManual;
                atividade.oeeDigital.qualidade = atividade.qualidadeManual;
            }
            else{
                atividade.oeeDigital.medicao = atividade.medicaoOEE;
                atividade.oeeDigital.disponibilidade = atividade.disponibilidadeOEE;
                atividade.oeeDigital.performance = atividade.performanceOEE;
                atividade.oeeDigital.qualidade = atividade.qualidadeOEE;
            }
            atividade.maturidadeDigital.anexoMaturidadeDigitalList = await this.getIndicadoresList(atividade);
        } else if (nomeAtividade === 'analiseResultadosDigital' && atividade.retornoMensal)  {
            atividade.retornoMensal = this.transformCurrencyStringToNumber(atividade.retornoMensal)
        }
        else if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;

        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoPratica[nomeAtividade] = atividade;
    };
    
    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoPratica = this.state.atendimentoPratica;
        let encontrosData = viewData.encontros.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;

            if (currScreenAtividade === 'indicadoresDigital') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'indicadoresDigital', 'anexoIndicadoresList', this.validateIndicadores, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'autossuficienciaDigital') {
                this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'autossuficienciaDigital', 'anexoAutossuficienciaList', this.validaAutossuficiencia, this.STATUS_FINALIZADO);
            } 
            else if (currScreenAtividade === 'analiseResultadosDigital') {
                this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'analiseResultadosDigital', null, this.validaAnaliseDosResultados, this.STATUS_FINALIZADO);
            }
            
            if (selectedVisita) {
                selectedVisita.anexoEncontroList = [];
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.imagens != null ? selectedVisita.imagens : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaEncontro(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = encontrosData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoPratica.encontroList[index] = selectedVisita;
                else atendimentoPratica.encontroList.push(selectedVisita);
            }
            atendimentoPratica.horasApropriadas = atendimentoPratica.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let atividades = viewData.atividades;
            let indicadoresDigital = atividades.indicadoresDigital;
            let autossuficienciaDigital = atividades.autossuficienciaDigital;
            let analiseResultadosDigital = atividades.analiseResultadosDigital;

            if (!indicadoresDigital.data.ordemVisita) atendimentoPratica.indicadoresDigital = null;
            if (!autossuficienciaDigital.data.ordemVisita) atendimentoPratica.autossuficienciaDigital = null;
            if (!analiseResultadosDigital.data.ordemVisita) atendimentoPratica.analiseResultadosDigital = null;


            atendimentoPratica.encontroList = encontrosData;
            atendimentoPratica.horasApropriadas = atendimentoPratica.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        let indexAtendimento = turma.etapaPraticaD.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
        turma.etapaPraticaD.atendimentoList[indexAtendimento] = atendimentoPratica; //atualiza atendimentoPratica na etapa pratica d da turma
        if(atendimentoPratica.horasApropriadas > 4){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else {return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
            return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                turma = res.data;
                etapa = turma.etapaPraticaD;
                let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                let atd = etapa.atendimentoList[index];
                await this.updateViewData(atd);
                this.setState({ turma, etapa, atendimentoPratica: atd });
                this.props.showToast('Etapa editada com sucesso!', 'success');
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }).catch(error => {
            this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        });}
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = true;
        let showAnexos = true;
        let render = null;
        let border = true;
        let invertAnexos = false;
        switch (selectedAtividade.screenAtividade) {
            case 'indicadoresDigital':
                render = <AtividadeIndicadoresPerformance 
                    atividade={selectedAtividade.data}
                    showLoading={ this.props.showLoading }
                    closeLoading={ this.props.closeLoading }
                    atendimento={ this.state.atendimentoPratica }
                    turma={ this.state.turma } 
                    showToast={ this.props.showToast }
                    etapa={'D'}
                    disabled={this.state.disabled}
                />;
                showEmptyEvidencias = false;
                showTopButtons = false;
                showAnexos = false;
                border = false;
                break;
            case 'autossuficienciaDigital':
                render = <AtividadeAutossuficiencia atividade={selectedAtividade.data} disabled={this.state.disabled} />;
                showEmptyEvidencias = false;
                invertAnexos = true;
                border = false;
                break;

            case 'analiseResultadosDigital':
                render = <AtividadeAnaliseDosResultados atividade={selectedAtividade.data} turma={ this.state.turma } atendimento={ this.state.atendimentoPratica } disabled={this.state.disabled} />;
                showEmptyEvidencias = false;
                showTopButtons = false;
                showAnexos = false;
                break;

            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefaultDigital
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                showTopButtons={ showTopButtons && !this.state.disabled}
                addInfoAnexo={selectedAtividade.screenAtividade == 'autossuficienciaDigital' ? 'Anexe imagens das ações realizadas na estruturação do sistema de autossuficiência incluindo uma descrição do processo:' : ''}
                showAnexos={ showAnexos }
                invertAnexos={invertAnexos}
                renderChildren={ render }
                border = {border}
                clientName={ this.state.clientName }
                viewData={ this.state.viewData } />
        );
    };

    renderVisita = (atendimento) => {
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoPratica: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoPratica: undefined });
    };

    render() {
        return (<>
            { this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCardDigital
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Mentoria Prática' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita } 
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <EncontrosDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    disabled={this.state.disabled}
                    models={ this.getModels }
                    atendimento={ this.state.atendimentoPratica }
                    etapa = {this.state.etapa}
                    saveEtapa={ this.saveEtapa }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    dontShowFinalizarMentoria={ this.state.atendimentoPratica.finalizado }
                    mentoresDados={this.props.mentoresDados}
                />
            }
        </>)
    };
};