import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';

export default class GraficoAtendimentosCadastros extends Component {

   
    estados = { 
        'AC': 'Acre', 'AL': 'Alagoas', 'AM': 'Amazonas', 'AP': 'Amapá', 'BA': 'Bahia', 'CE': 'Ceará',
        'DF': 'Distrito Federal', 'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão', 'MG': 'Minas Gerais',
        'MS': 'Mato Grosso do Sul', 'MT': 'Mato Grosso', 'PA': 'Pará', 'PB': 'Paraíba', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'PR': 'Paraná', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte', 'RO': 'Rondônia', 
        'RR': 'Roraima', 'RS': 'Rio Grande do Sul', 'SC': 'Santa Catarina', 'SE': 'Sergipe', 'SP': 'São Paulo', 'TO': 'Tocantins'
    };

	constructor(props) {
		super(props);

		this.state = {           

            dados : {digital : undefined, lean: undefined, ambos: undefined},
		};

		this.carregarDados();

	}

    fillGraficoAtendimento = async () => {
        let digital = [];
		let lean = [];
		let ambos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/countStatusAtendimentosDr`).then(async res => {
			let categories = Object.keys(this.estados);
            let dataContratadosD = [];
            let dataConcluidosD = [];
            let dataNegociacaoD = [];
            let dataTriagemD = [];
            let dataCanceladosD = [];
			let dataContratadosL = [];
            let dataConcluidosL = [];
            let dataNegociacaoL = [];
            let dataTriagemL = [];
            let dataCanceladosL = [];
			let dataContratadosLD = [];
            let dataConcluidosLD = [];
            let dataNegociacaoLD = [];
            let dataTriagemLD = [];
            let dataCanceladosLD = [];

            if (res.data != null ) {
                await categories.forEach(estado => {
                    dataContratadosD.push(res.data.digital.contratados_execucao[estado]);
                    dataConcluidosD.push(res.data.digital.concluidos[estado])
                    dataNegociacaoD.push(res.data.digital.negociacao[estado])
                    dataTriagemD.push(res.data.digital.triagem[estado])
                    dataCanceladosD.push(res.data.digital.cancel_arquiv_recusados[estado])
					dataContratadosL.push(res.data.lean.contratados_execucao[estado]);
                    dataConcluidosL.push(res.data.lean.concluidos[estado])
                    dataNegociacaoL.push(res.data.lean.negociacao[estado])
                    dataTriagemL.push(res.data.lean.triagem[estado])
                    dataCanceladosL.push(res.data.lean.cancel_arquiv_recusados[estado])
					dataContratadosLD.push(res.data.ambos.contratados_execucao[estado]);
                    dataConcluidosLD.push(res.data.ambos.concluidos[estado])
                    dataNegociacaoLD.push(res.data.ambos.negociacao[estado])
                    dataTriagemLD.push(res.data.ambos.triagem[estado])
                    dataCanceladosLD.push(res.data.ambos.cancel_arquiv_recusados[estado])
                });
                digital.push({
                    name: 'Contratados e Em Execução',
                    data: dataContratadosD,
                    color: '#077CDC'
                });
                digital.push({
                    name: 'Concluídos',
                    data: dataConcluidosD,
                    color: '#7DAE59'
                });
                digital.push({
                    name: 'Em negociação',
                    data: dataNegociacaoD,
                    color: '#E9784D'
                });
                digital.push({
                    name: 'Em triagem',
                    data: dataTriagemD,
                    color: '#E9B03D'
                });
                digital.push({
                    name: 'Cancelados e Arquivados',
                    data: dataCanceladosD,
                    color: '#D44E54'
                });
				lean.push({
                    name: 'Contratados e Em Execução',
                    data: dataContratadosL,
                    color: '#077CDC'
                });
                lean.push({
                    name: 'Concluídos',
                    data: dataConcluidosL,
                    color: '#7DAE59'
                });
                lean.push({
                    name: 'Em negociação',
                    data: dataNegociacaoL,
                    color: '#E9784D'
                });
                lean.push({
                    name: 'Em triagem',
                    data: dataTriagemL,
                    color: '#E9B03D'
                });
                lean.push({
                    name: 'Cancelados e Arquivados',
                    data: dataCanceladosL,
                    color: '#D44E54'
                });
				ambos.push({
                    name: 'Contratados e Em Execução',
                    data: dataContratadosLD,
                    color: '#077CDC'
                });
                ambos.push({
                    name: 'Concluídos',
                    data: dataConcluidosLD,
                    color: '#7DAE59'
                });
                ambos.push({
                    name: 'Em negociação',
                    data: dataNegociacaoLD,
                    color: '#E9784D'
                });
                ambos.push({
                    name: 'Em triagem',
                    data: dataTriagemLD,
                    color: '#E9B03D'
                });
                ambos.push({
                    name: 'Cancelados e Arquivados',
                    data: dataCanceladosLD,
                    color: '#D44E54'
                });
                this.setState({ dados : {digital: digital, lean:lean, ambos: ambos} });
            }
        }).catch(error => {
            console.log(error);
        });
    };

	carregarDados = async () => {
		await this.fillGraficoAtendimento();
	}

	
	

	create = (fase) => {

		

        let categories = Object.keys(this.estados);

		let series = [];

		let subtitle


		if(fase == "L"){
			series= this.state.dados.lean;
			subtitle = {
				enabled:true,
				text: `Fase 1: Mentoria Lean`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		else if(fase == "D"){
			series= this.state.dados.digital;
			subtitle = {
				enabled:true,
				text: `Fase 2: Mentoria Digital`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}
		else{
			series= this.state.dados.ambos;
			subtitle = {
				enabled:true,
				text: `Brasil Mais`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 40				
			}
		}

		
		return {
			chart: {
                type: 'column',
                style: {
                    fontFamily: 'Raleway'
                },
				height:  570 ,
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				marginTop : 80
			},
			title: {
				enabled:true,
				text: `ATENDIMENTOS E CADASTROS POR STATUS`,
				style: {
					fontFamily: 'Raleway',
					font: "20px",
					fontWeight: 600,

					'color': '#077CDC',
				},
                margin: 60
				
			},
            fullCategories: this.estados,
            xAxis: {
                lineColor: '#707070',
                lineWidth: 0.5,
                categories: categories,
                labels: {
                    style: {
                        color: '#707070'
                    }
                },
            },
			subtitle: subtitle,
			tooltip: {
				formatter: function () {
                    return '<b>'+ this.x +'</b><br/>' + 
                            this.series.name + ': ' + this.y + '<br/> Porcentagem: ' + (this.y/this.total  *100).toFixed(1) + '%';
                }
              },
			yAxis: { 
                min: 0,
                title: {
                text: 'Nº de Atendimentos'
                },
                stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color:  'gray',
					font: 'Raleway',
					fontSize: '14px '
                }
                },
                lineColor: '#707070',
                lineWidth: 0,
                labels: {
                    style: {
                        color: '#707070'
                    }
                }
            },
			plotOptions: {
				column: {
                    stacking: 'normal',
					dataLabels: {
						enabled: false,
						style: {
							font: 'Raleway',
							fontSize: '15px !important',
							color: '#707070',
							width: '100% !important'
						}
					}
				},				
			},
			legend:{
				itemDistance: 40,
				margin: 30	
			},
			series: series,
			credits: {
				enabled: false
			},
			exporting: {
				menuItemDefinitions: {
					// Custom definition
					printChart: {
						text: 'Imprimir gráfico'
					},
					downloadPNG:{
						text: 'Baixar como PNG'
					},
					downloadJPEG:{
						text: 'Baixar como JPEG'
					},
					downloadSVG:{
						text: 'Baixar como SVG'
					},
					downloadCSV:{
						text: 'Baixar tabela de dados'
					}
				},
                subtitle :{
					y: 0,
                    style: {
                        marginTop: '30px',
                    }				
                },
                chart: {
                    marginTop: '150'
                },
				width: 1500,
				fallbackToExportServer: false,
				buttons: {
					contextButton: {
						menuItems:[ "printChart", "downloadCSV", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"]
					}
				}
			}
		};
	}

	render() {
		
		
		let GraphL =  this.create('L');
		let GraphD =  this.create('D');
		let GraphLD =  this.create('LD');
		return (
			<Grid container justify={'center'} style={{height:'100%'}} className={'dashboard-porte-setor border-cinza'}>
				<Grid item xs={12}>
					<div style={{ margin: '40px', marginBottom: '30px' }}>
						{this.props.fase == 'L' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphL} />}
						{this.props.fase == 'D' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphD} />}
						{this.props.fase == 'LD' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphLD} />}
					</div>
				</Grid>
			</Grid>
		);
	}

}