import React, { Component } from 'react';
import API from '../../comm/API';
import { withStyles} from "@material-ui/core/styles";
import Results from '../../components/results/results';
import GenericButton from '../../components/genericButton/genericButton';
import SimpleModal from '../../components/modal/modal';
import { Grid, Tooltip } from "@material-ui/core"
import TermoEncerramentoDigital from './termoEncerramentoDigital';
import './atividadeResultadoGeral.scss';

export default class AtividadeResultadoGeral extends Component {

    referencia = React.createRef();

    constructor(props) {
      super(props);

      let resultado = props.atividade;

      const atendimento = this.props.turma.atendimentoTurmaList.find(atdTurma => { return atdTurma.atendimento.id === this.props.atendimento.idAtendimento }).atendimento;
      const cliente = atendimento.cliente;


      resultado.assinaturas = !!resultado.assinaturas.length ? resultado.assinaturas : [];

      this.state = {
        resultado: props.atividade,
        produtividade: this.getEmptyIndicatorModel(),
        oee: this.getEmptyIndicatorModel(),
        maturidade: this.getEmptyIndicatorModel(),
        oeeSubitens: {},
        maturidadeSubitens: {},
        payback: null,
        cliente,
        lean: null,
        showTermo: false,
        atendimento,
        modalExcluirAssinatura: false,
      };
    };

    getEmptyIndicatorModel = () => {
      return {
        initial: 0,
        final: 0
      };
    }

    getIndicatorByIdAtendimento = (idAtendimento, etapa, indicator) => {
      const atendimento = this.props.turma[etapa].atendimentoList.find(atendimento => atendimento.idAtendimento === idAtendimento);
  
      return atendimento[indicator] || {};
    }
  
    getInitialIndicators = () => {
      return this.getIndicatorByIdAtendimento(this.props.atendimento.idAtendimento, 'etapaPraticaC', 'indicadoresDigital');
    }
  
    getFinalIndicators = () => {
      return this.getIndicatorByIdAtendimento(this.props.atendimento.idAtendimento, 'etapaPraticaD', 'indicadoresDigital');
    }

    getDataLean = async ()  => {
      const response = await API.get(`rest/gestao/TurmaMentoriaDigital/getInfoLean/${this.state.cliente.id}`);

      return response.data;
    }

    componentDidMount() {
      this.getDataLean().then(lean => {
        this.setState({ lean });
      });

      const initialIndicators = this.getInitialIndicators();
      const finalIndicators = this.getFinalIndicators();
      const analiseResultadosDigital = this.getIndicatorByIdAtendimento(this.props.atendimento.idAtendimento, 'etapaPraticaD', 'analiseResultadosDigital');
      const payback = analiseResultadosDigital && analiseResultadosDigital.retornoPrograma && analiseResultadosDigital.retornoPrograma;

      const oeeSubitens = {
        'Disponibilidade': {
          initial: initialIndicators.oeeDigital.disponibilidade,
          final: finalIndicators.oeeDigital.disponibilidade,
        },
        'Performance': {
          initial: initialIndicators.oeeDigital.performance,
          final: finalIndicators.oeeDigital.performance,
        },
        'Qualidade': {
          initial: initialIndicators.oeeDigital.qualidade,
          final: finalIndicators.oeeDigital.qualidade,
        }
      }
  
  
      const maturidadeSubitens = {
        'Estratégia e Organizações': {
          initial: initialIndicators.maturidadeDigital.pontuacaoEstrategia,
          final: finalIndicators.maturidadeDigital.pontuacaoEstrategia,
        },
        'Manufatura e Cadeia de Suprimentos': {
          initial: initialIndicators.maturidadeDigital.pontuacaoManufatura,
          final: finalIndicators.maturidadeDigital.pontuacaoManufatura,
        },
        'Modelos de Negócio, Produtos e Serviços': {
          initial: initialIndicators.maturidadeDigital.pontuacaoNegocio,
          final: finalIndicators.maturidadeDigital.pontuacaoNegocio,
        },
        'Cultura e Pessoas': {
          initial: initialIndicators.maturidadeDigital.pontuacaoCultura,
          final: finalIndicators.maturidadeDigital.pontuacaoCultura,
        }
      }
  
      const produtividade = {
        initial: initialIndicators.produtividadeDigital.medicao,
        final: finalIndicators.produtividadeDigital.medicao,
      }
  
      const maturidade = {
        initial: initialIndicators.maturidadeDigital.pontuacaoTotal,
        final: finalIndicators.maturidadeDigital.pontuacaoTotal,
      }
  
      const oee = {
        initial: initialIndicators.oeeDigital.medicao,
        final: finalIndicators.oeeDigital.medicao,
      }
  
      this.setState({ oeeSubitens, maturidadeSubitens, produtividade, maturidade, oee, payback })
    }

    changeShowTermo = (value) => {
      if (value) this.referencia.current.parentElement.parentElement.style.visibility = 'hidden';
      else this.referencia.current.parentElement.parentElement.style.visibility = 'visible';
      this.setState({ showTermo: value })
    }

    renderBody = (isInside) => {
        let resultado = this.state.resultado;
        const ModifiedTooltip = withStyles({
          tooltip: {
              fontSize: '15px',
              fontFamily:'Raleway'
          }
        })(Tooltip);

        return (
          <Grid container justify={'center'} style={{ marginTop: '1%', padding: isInside ? '0 8%' : '0'}}>

            {!this.state.showTermo && (
              <Grid container justify='center'>
                <Grid item xs={12} md={8} xl={6}>
                    <Grid container direction={'row'} justify={'center'} className={'termo-grid'}>
                        <Grid item xs={12} md={5} xl={4} style={{ display: 'flex', alignItems: 'center' }}>
                            <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/termo-encerramento.svg'} width={30} height={30} />
                            <div>
                              <div className={'termo-info'}> <span id={'span-upload-atividade'}>Termo de encerramento</span> </div>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={2} style={{ display: 'flex', alignItems: 'center'}}>
                            <GenericButton 
                              color={!!resultado.assinaturas.length ? 'darkGreen' : 'lightBlue'} 
                              label={!!resultado.assinaturas.length ? 'Assinado' : 'Assinar'} 
                              subClass={'basic-button'}
                              click={() => resultado.assinaturas.length === 0 && this.changeShowTermo(true)}
                              disabled={this.props.disabled} 
                            />

                            {resultado.assinaturas.length > 0 && (
                              <ModifiedTooltip title='Excluir assinatura' placement='top' arrow>
                                <div>
                                  <img 
                                    alt={'Excluir assinatura'} 
                                    src={process.env.PUBLIC_URL + '/assets/icons/trash.svg'} 
                                    width={20} 
                                    height={20} 
                                    style={{ marginLeft: 8, opacity: this.props.disabled ? 0.4 : 1,
                                      cursor: this.props.disabled ? 'not-allowed': 'cursor'}}
                                    onClick={this.props.disabled ? () => {} : () => this.setState({ modalExcluirAssinatura: true })}
                                  />
                                </div>
                              </ModifiedTooltip>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}><div className={'div-border-bottom'} /></Grid>
              </Grid> 
            )}

              {this.state.lean && (
                <>
                  {this.state.lean.informacoesLean.produtividadeInicial && this.state.lean.informacoesLean.produtividadeFinal &&
                  <Grid container justifyContent='center' style={{ marginBottom: '64px' }}>
                    <Grid item xs={12} className='results-block-title'>
                      <span>MENTORIA LEAN</span>
                    </Grid>

                    <Grid container className='results-item-container'>
                      <div className='results-meta-container'>
                        <span>meta:</span>
                        <span>+20%</span>
                      </div>
                      <Grid xs={9}>
                        <Results 
                          title="Produtividade"
                          initial={this.state.lean.informacoesLean.produtividadeInicial}
                          final={this.state.lean.informacoesLean.produtividadeFinal}
                          type='hour'
                        />
                      </Grid>
                    </Grid>
                    <div className='divisor'/>
                  </Grid>}
                </>
              )}


              <Grid container justifyContent='center'>
                <Grid item xs={12} className='results-block-title'>
                  <span>MENTORIA DIGITAL</span>
                </Grid>

                <Grid container className='results-item-container'>
                  <div className='results-meta-container'>
                    <span>meta:</span>
                    <span>+20%</span>
                  </div>
                  <Grid xs={9}>
                    <Results 
                      title="Produtividade"
                      initial={this.state.produtividade.initial}
                      final={this.state.produtividade.final}
                      type='hour'
                    />
                  </Grid>
                </Grid>

                <Grid container className='results-item-container-with-subitems'>
                  <div className='results-meta-container' />
                  <Grid xs={9}>
                    <Results 
                      title="OEE"
                      initial={this.state.oee.initial}
                      final={this.state.oee.final}
                      subItems={this.state.oeeSubitens}
                      type='percent'
                    />
                  </Grid>
                </Grid>

                <Grid container className='results-item-container-with-subitems'>
                  <div className='results-meta-container' />
                  <Grid xs={9}>
                    <Results 
                      title="Maturidade"
                      initial={this.state.maturidade.initial}
                      final={this.state.maturidade.final}
                      subItems={this.state.maturidadeSubitens}
                      type='integer'
                    />
                  </Grid>
                </Grid>

              </Grid>
              {this.state.payback && (
                <Grid container className='general-result-payback-container'>
                  <Grid item xs={4} className='general-result-payback-content'>
                    <div className='general-result-payback'>
                      <p>
                        Retorno do Programa
                      </p>
                    </div>
                    <div className='general-result-payback-months'>
                      {this.state.payback.toFixed(4).replace('.', ',')} meses
                    </div>
                  </Grid>
                  <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
                    <ModifiedTooltip 
                      title={ 
                        <div>
                          Valor total da consultoria<br />
                          <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                          Retorno mensal na empresa após intervenções
                        </div>
                      }
                      placement="right" 
                      arrow
                    >
                      <img 
                        style={{height: 20, width: 20}} 
                        src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} 
                        alt={'icon'} 
                      />
                    </ModifiedTooltip>
                  </Grid>
                </Grid>
              )}
          </Grid>
        )
    }

    render() {
      return (
        <Grid container className={'atividade-linha-producao'} style={{ marginTop: '30px' }} ref={this.referencia}>
          {!this.state.showTermo && (
            this.renderBody() 
          )}
          {this.state.showTermo && (
            <div>
              <TermoEncerramentoDigital 
                turma={this.props.turma}
                atendimento={this.state.atendimento}
                changeShowTermo={this.changeShowTermo}
                atividade={this.props.atividade}
                showLoading={this.props.showLoading} 
                closeLoading={this.props.closeLoading} 
                showToast={this.props.showToast}
                usuarioConectado={ this.props.usuarioConectado }
              >
                {(isInside) => this.renderBody(isInside)}
              </TermoEncerramentoDigital>
            </div>
          )}

          <SimpleModal
            isOpen={this.state.modalExcluirAssinatura}
            handleClose={() => this.setState({ modalExcluirAssinatura: false })}
            type={'warning'}
            width={'400px'}>
            <Grid container>
              <p style={{ color: '#707070', fontSize: '17px', textAlign: 'center'}}>
                Você confirma a remoção das assinaturas do Termo de Encerramento da  
                <strong>
                  {' ' + this.state.cliente.razaoSocial}
                </strong>
                ?
              </p>

              <Grid item xs={12} className={'rodape'}>
                <GenericButton
                  color={'darkBlue'}
                  label={'Cancelar'}
                  subClass={'basic-button'}
                  click={() => this.setState({ modalExcluirAssinatura: false }) }
                />

                <GenericButton 
                  color={'darkGrey-outline'}
                  label={'Remover'}
                  subClass={'basic-button'}
                  click={() => {
                    const resultado = this.state.resultado;

                    resultado.assinaturas = [];

                    this.setState({ resultado, modalExcluirAssinatura: false });
                    this.props.showToast('Assinaturas removidas com sucesso!', 'success');
                  }}
                />
              </Grid>

            </Grid>
          </SimpleModal>
        </Grid>
      )
    }

}