import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper';
import { Grid } from '@material-ui/core';
import Page from '../../components/page/page';
import API from '../../comm/API';
import InputSelect from '../../components/inputselect/inputselect';
import AutoComplete from '../../components/autoComplete/autoComplete';
import GenericButton from './../../components/genericButton/genericButton';
import Table from '../../components/table/table';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import { Link } from 'react-router-dom';
import './turmaNovo.scss';

export default class TurmaNovo extends Component {

    today = this.formatData(new Date()) + 'T00:00:00';
    isEdicao = false;
	titles = ['Informações gerais', 'Cronograma', 'Revisão'];
    titlesEtapa = [
        { id: '1', canOrder: false, label: 'Etapa' },
        { id: '2', canOrder: false, label: 'Data de início' },
        { id: '3', canOrder: false, label: 'Data de término' },
        { id: '4', canOrder: false, label: 'Mentor/Consultor'},
        { id: '5', canOrder: false, label: ''},
    ];
    titlesEtapaLast = [
        { id: '1', canOrder: false, label: 'Etapa' },
        { id: '2', canOrder: false, label: 'Data de início' },
        { id: '3', canOrder: false, label: 'Data de término' },
        { id: '4', canOrder: false, label: 'Mentor/Consultor'},
    ];
    etapaList = [ 
        { id: 2, etapa: 'B - Teórica', type: 'Etapa' },
        { id: 3, etapa: 'B - Prática', type: 'Etapa' },
        { id: 4, etapa: 'C - Teórica', type: 'Etapa' },
        { id: 5, etapa: 'C - Prática', type: 'Etapa' },
        { id: 6, etapa: 'C - Consultoria', type: 'Etapa' },
        { id: 7, etapa: 'D - Teórica', type: 'Etapa' },
        { id: 8, etapa: 'D - Prática', type: 'Etapa' },
        { id: 9, etapa: 'D - Consultoria', type: 'Etapa' },
    ];
    titlesGame = [
        { id: '0', canOrder: false, label: 'Turma' },
        { id: '1', canOrder: false, label: 'Data de início' },
        { id: '2', canOrder: false, label: 'Data de término' }
    ];

    etapasToEdit = [];

    isDR = this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR';

	constructor(props) {
        super(props);
        let etapaTurma = this.getEtapaTurmaModel();
        this.isEdicao = props.idTurma != null && !isNaN(props.idTurma);
        this.state = { turma: null, errors: { nome : '', dataInicio: '', dataTermino: '' }, etapaTurmaList: [], mentorList: [], etapaTurmaElementList: [], 
            etapaTurma: etapaTurma, errorsEtapa: { dataInicio: '', dataTermino: '', etapa: '', mentor: '' }, etapaIdList: [] };
	};

	componentDidMount() {
        if (this.isEdicao) {
           API.get('rest/gestao/Turma/' + this.props.idTurma).then(res => {
                this.convertEtapaList(res.data);
                this.getMentorList(this.isDR ? this.props.idTurma : null);
                this.setState({ turma: res.data });
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Error ao realizar GET da turma. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            let turma = this.getTurmaModel();
            this.setState({ turma: turma });
            this.getMentorList(null);
        }
    };

	showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getTurmaModel() {
    	return { etapaAtual: 0, emProgresso: false, status: 1, nome: '', dataInicio: this.today, dataTermino: this.today, etapaA: { etapa: 1 }, etapaTeoricaList: [], 
            etapaPraticaB: undefined, etapaPraticaC: undefined, etapaPraticaD: undefined, etapaConsultoriaC: undefined, etapaConsultoriaD: undefined, atendimentoTurmaList: [],
            unidade: this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.unidade != null ? this.props.usuarioConectado.colaborador.unidade.id :
            this.props.usuarioConectado.colaborador.colaboradorUnidadeList[0].id };
    };

    getEtapaTurmaModel() {
        return { dataInicio: '', dataTermino: '', mentor: '', etapa: -1, horasApropriadas: 0, finalizada: false };
    };

    getMentorList(idTurma) {
        let path = idTurma ? '?idTurma=' + idTurma : '';
        API.get('rest/gestao/Colaborador/brasilmais' + path).then(res => {
            this.setState({ mentorList: res.data != null ? res.data : []});
            this.closeLoading();
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Error ao realizar GET de mentores. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    convertEtapaList = (turma) => {
        let etapaTurmaList = []
        let etapaTurmaElementList = [];
        let etapaIdList = [];
        let line = [];
        let index = -1;
        let etapaAtual = turma.etapaAtual;
        if (turma.etapaTeoricaList != null) {
            for (let i = 0; i < turma.etapaTeoricaList.length; i++) {
                let etapaTeorica = turma.etapaTeoricaList[i];
                line = [];
                index = this.etapaList.findIndex(aux => { return aux.id === etapaTeorica.etapa });
                line.push(etapaTeorica.etapa);
                line.push(this.etapaList[index].etapa);
                line.push(this.formatDataWithSlash(etapaTeorica.dataInicio));
                line.push(this.formatDataWithSlash(etapaTeorica.dataTermino));
                line.push(etapaTeorica.mentor.nome);
                if (etapaTeorica.etapa >= etapaAtual && turma.status < 3) {
                    line.push(
                        <div key={'etapa-chip-' + etapaTeorica.etapa} className={'etapa-chip'}>
                            <GenericButton color={'transparent'} click={(e) => { this.removeEtapaTurma(etapaTeorica.etapa) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                        </div>
                    );
                } else {
                    line.push('');
                }
                etapaTurmaElementList.push(line);
                etapaIdList.push(etapaTeorica.etapa);
                etapaTurmaList.push(etapaTeorica);
            }
        }
        if (turma.etapaPraticaB != null) {
            line = [];
            index = this.etapaList.findIndex(aux => { return aux.id === turma.etapaPraticaB.etapa });
            line.push(turma.etapaPraticaB.etapa);
            line.push(this.etapaList[index].etapa);
            line.push(this.formatDataWithSlash(turma.etapaPraticaB.dataInicio));
            line.push(this.formatDataWithSlash(turma.etapaPraticaB.dataTermino));
            line.push(turma.etapaPraticaB.mentor.nome);
            if (turma.etapaPraticaB.etapa >= etapaAtual && turma.status < 3) {
                line.push(
                    <div key={'etapa-chip-' + turma.etapaPraticaB.etapa} className={'etapa-chip'}>
                        <GenericButton color={'transparent'} click={(e) => { this.removeEtapaTurma(turma.etapaPraticaB.etapa) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </div>
                );
            } else {
                line.push('');
            }
            etapaTurmaElementList.push(line);
            etapaIdList.push(turma.etapaPraticaB.etapa);
            etapaTurmaList.push(turma.etapaPraticaB);
        }
        if (turma.etapaPraticaC != null) {
            line = [];
            index = this.etapaList.findIndex(aux => { return aux.id === turma.etapaPraticaC.etapa });
            line.push(turma.etapaPraticaC.etapa);
            line.push(this.etapaList[index].etapa);
            line.push(this.formatDataWithSlash(turma.etapaPraticaC.dataInicio));
            line.push(this.formatDataWithSlash(turma.etapaPraticaC.dataTermino));
            line.push(turma.etapaPraticaC.mentor.nome);
            if (turma.etapaPraticaC.etapa >= etapaAtual && turma.status < 3) {
                line.push(
                    <div key={'etapa-chip-' + turma.etapaPraticaC.etapa} className={'etapa-chip'}>
                        <GenericButton color={'transparent'} click={(e) => { this.removeEtapaTurma(turma.etapaPraticaC.etapa) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </div>
                );
            } else {
                line.push('');
            }
            etapaTurmaElementList.push(line);
            etapaIdList.push(turma.etapaPraticaC.etapa);
            etapaTurmaList.push(turma.etapaPraticaC);
        }
        if (turma.etapaPraticaD != null) {
            line = [];
            index = this.etapaList.findIndex(aux => { return aux.id === turma.etapaPraticaD.etapa });
            line.push(turma.etapaPraticaD.etapa);
            line.push(this.etapaList[index].etapa);
            line.push(this.formatDataWithSlash(turma.etapaPraticaD.dataInicio));
            line.push(this.formatDataWithSlash(turma.etapaPraticaD.dataTermino));
            line.push(turma.etapaPraticaD.mentor.nome);
            if (turma.etapaPraticaD.etapa >= etapaAtual && turma.status < 3) {
                line.push(
                    <div key={'etapa-chip-' + turma.etapaPraticaD.etapa} className={'etapa-chip'}>
                        <GenericButton color={'transparent'} click={(e) => { this.removeEtapaTurma(turma.etapaPraticaD.etapa) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </div>
                );
            } else {
                line.push('');
            }
            etapaTurmaElementList.push(line);
            etapaIdList.push(turma.etapaPraticaD.etapa);
            etapaTurmaList.push(turma.etapaPraticaD);
        }
        if (turma.etapaConsultoriaC != null) {
            line = [];
            index = this.etapaList.findIndex(aux => { return aux.id === turma.etapaConsultoriaC.etapa });
            line.push(turma.etapaConsultoriaC.etapa);
            line.push(this.etapaList[index].etapa);
            line.push(this.formatDataWithSlash(turma.etapaConsultoriaC.dataInicio));
            line.push(this.formatDataWithSlash(turma.etapaConsultoriaC.dataTermino));
            line.push(turma.etapaConsultoriaC.mentor.nome);
            if (turma.etapaConsultoriaC.etapa >= etapaAtual && turma.status < 3) {
                line.push(
                    <div key={'etapa-chip-' + turma.etapaConsultoriaC.etapa} className={'etapa-chip'}>
                        <GenericButton color={'transparent'} click={(e) => { this.removeEtapaTurma(turma.etapaConsultoriaC.etapa) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </div>
                );
            } else {
                line.push('');
            }
            etapaTurmaElementList.push(line);
            etapaIdList.push(turma.etapaConsultoriaC.etapa);
            etapaTurmaList.push(turma.etapaConsultoriaC);
        }
        if (turma.etapaConsultoriaD != null) {
            line = [];
            index = this.etapaList.findIndex(aux => { return aux.id === turma.etapaConsultoriaD.etapa });
            line.push(turma.etapaConsultoriaD.etapa);
            line.push(this.etapaList[index].etapa);
            line.push(this.formatDataWithSlash(turma.etapaConsultoriaD.dataInicio));
            line.push(this.formatDataWithSlash(turma.etapaConsultoriaD.dataTermino));
            line.push(turma.etapaConsultoriaD.mentor.nome);
            if (turma.etapaConsultoriaD.etapa >= etapaAtual && turma.status < 3) {
                line.push(
                    <div key={'etapa-chip-' + turma.etapaConsultoriaD.etapa} className={'etapa-chip'}>
                        <GenericButton color={'transparent'} click={(e) => { this.removeEtapaTurma(turma.etapaConsultoriaD.etapa) }} subClass={'icon-and-button'} icon={'trash.svg'} />
                    </div>
                );
            } else {
                line.push('');
            }
            etapaTurmaElementList.push(line);
            etapaIdList.push(turma.etapaConsultoriaD.etapa);
            etapaTurmaList.push(turma.etapaConsultoriaD);
        }
        this.setState({ etapaTurmaElementList: etapaTurmaElementList, etapaTurmaList: etapaTurmaList, etapaIdList: etapaIdList });
    };

    formatData(date) {
        if (typeof (date) !== 'string') {
            let dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                var dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

    formatDataWithSlash(date) {
        if (date != null) {
            var dataList = date.split('T');
            var dataAux = dataList[0].split('-');
            var data = dataAux[2] + '/' + dataAux[1] + '/' + dataAux[0]
            return data;
        }
    };

    changeInput = (e, tipo) => {
        let objeto = this.state.turma;
        let errors = this.state.errors;
        if (tipo === 'etapa') {
            objeto = this.state.etapaTurma;
            errors = this.state.errorsEtapa;
        } 
        if (e != null && e.target != null) {
            if (e.target.required) {
                if (e.target.value == null || e.target.value === '') {
                    errors[e.target.id] = 'Campo obrigatório';
                } else {
                    errors[e.target.id] = '';
                }
            }
        	if (e.target.id === 'dataInicio' || e.target.id === 'dataTermino') {
                objeto[e.target.id] = e.target.value + 'T00:00:00';
                errors['dataTermino'] = '';
                errors['dataInicio'] = '';
                if (e.target.value === '') {
                    errors[e.target.id] = 'Campo obrigatório';
                } else if (this.formatData(objeto.dataInicio) > this.formatData(objeto.dataTermino)) {
                    errors[e.target.id] = 'Data inválida';                    
                } else if (tipo === 'etapa') {
                    let etapaIdListSorted = Object.assign([], this.state.etapaIdList);
                    etapaIdListSorted.push(objeto.etapa.id);
                    etapaIdListSorted = etapaIdListSorted.sort();
                    let index = etapaIdListSorted.findIndex(aux => { return aux === objeto.etapa.id });
                    let menorEtapa = index - 1 < 0 ? -1 : this.state.etapaTurmaList.findIndex(aux => { return aux.etapa === etapaIdListSorted[index - 1] }); 
                    let maiorEtapa = index + 1 === etapaIdListSorted.length ? -1 : this.state.etapaTurmaList.findIndex(aux => { return aux.etapa === etapaIdListSorted[index + 1] }); 
                    if ((menorEtapa > -1 && this.formatData(objeto.dataInicio) <= this.formatData(this.state.etapaTurmaList[menorEtapa].dataTermino)) || 
                        (maiorEtapa > -1 && this.formatData(objeto.dataTermino) >= this.formatData(this.state.etapaTurmaList[maiorEtapa].dataInicio))) {
                        errors['dataTermino'] = '';
                        errors['dataInicio'] = 'Cronograma incompatível com a(s) etapa(s) cadastrada(s)';
                    }
                }
            } else if (e.target.id === 'nome') {
                objeto.nome = e.target.value.toUpperCase();
            } 
        } else if (e != null && e.type != null) {
            if (this.state.etapaIdList.includes(e.id)) errors.etapa = 'Etapa já cadastrada';
            else errors.etapa = '';
            objeto.etapa = e;
        } else if (e != null && e[0] != null) {
            objeto.mentor = e;
        }
        if (tipo === 'etapa') this.setState({  etapaTurma: objeto, errorsEtapa: errors });
        else this.setState({ turma : objeto, errros: errors });
    };

    addEtapaTurma = () => {
        let etapaTurmaElementList = this.state.etapaTurmaElementList;
        let etapaTurmaList = this.state.etapaTurmaList;
        let etapaIdList = this.state.etapaIdList;
        let etapaTurma = this.state.etapaTurma;
        let etapaTurmaElement = this.getEtapaTurmaModel();
        let etapaTurmaReal = this.getEtapaTurmaModel();
        let dataInicio = this.formatDataWithSlash(etapaTurma.dataInicio);
        let dataTermino = this.formatDataWithSlash(etapaTurma.dataTermino);
        let line = [];
        line.push(etapaTurma.etapa.id);
        line.push(etapaTurma.etapa.etapa);
        line.push(dataInicio);
        line.push(dataTermino);
        line.push(etapaTurma.mentor[1]);
        line.push(
            <div key={'etapa-chip-' + etapaTurma.etapa.id} className={'etapa-chip'}>
                <GenericButton color={'transparent'} click={(e) => { this.removeEtapaTurma(etapaTurma.etapa.id) }} subClass={'icon-and-button'} icon={'trash.svg'} />
            </div>
        );
        etapaTurmaElementList.push(line);
        if (this.isEdicao && this.etapasToEdit.length > 0) {
            let etapaToEdit = this.etapasToEdit.find(obj => obj.etapa === etapaTurma.etapa.id)
            if (etapaToEdit && etapaToEdit.id) {
                etapaTurmaReal = etapaToEdit;
                this.etapasToEdit = this.etapasToEdit.filter(obj => obj.etapa !== etapaTurma.etapa.id);
            }
        }
        etapaTurmaReal.mentor = { 'id': etapaTurma.mentor[0] }; 
        etapaTurmaReal.etapa = etapaTurma.etapa.id;
        etapaTurmaReal.dataInicio = etapaTurma.dataInicio;
        etapaTurmaReal.dataTermino = etapaTurma.dataTermino;
        etapaTurmaList.push(etapaTurmaReal);
        etapaIdList.push(etapaTurma.etapa.id);
        this.setState({ etapaTurmaElementList: etapaTurmaElementList, etapaTurmaList: etapaTurmaList, etapaTurma: etapaTurmaElement, etapaIdList: etapaIdList });
    };

    removeEtapaTurma = (id) => {
        let etapaTurmaElementList = this.state.etapaTurmaElementList;
        let etapaTurmaList = this.state.etapaTurmaList;
        let etapaIdList = this.state.etapaIdList;
        let etapaTurmaElement = this.getEtapaTurmaModel();
        let errorsEtapa = { dataInicio: '', dataTermino: '', etapa: '', mentor: '' };
        let index = etapaIdList.findIndex(aux => { return aux === id });
        etapaIdList.splice(index, 1);
        if(this.isEdicao) {
            this.etapasToEdit.push(etapaTurmaList[index]);
        }
        etapaTurmaList.splice(index, 1);
        etapaTurmaElementList.splice(index, 1);
        this.setState({ etapaTurmaElementList: etapaTurmaElementList, etapaTurmaList: etapaTurmaList, etapaTurma: etapaTurmaElement, etapaIdList: etapaIdList, errorsEtapa: errorsEtapa });
    };

    validateTurma = (step) => {
        let result = true;
        if (step === 0) {
            result = this.state.turma.nome != null && this.state.turma.nome.length > 0 && this.state.turma.dataInicio && this.state.turma.dataTermino &&
                this.state.errors.nome === '' && this.state.errors.dataInicio === '' && this.state.errors.dataTermino === '';
        } else if (step === 1 && this.isEdicao && this.state.turma.etapaAtual > 1 && this.state.turma.status > 1) {
            result = this.state.etapaTurmaList != null && this.state.etapaTurmaList.length === this.etapaList.length;
        } else if (step === 3) {
            result = this.state.etapaTurma.etapa != null && this.state.etapaTurma.etapa.id != null && this.state.etapaTurma.mentor != null &&
                this.state.etapaTurma.mentor[0] != null && this.state.etapaTurma.dataInicio != null && this.state.etapaTurma.dataTermino != null &&
                this.state.errorsEtapa.dataInicio === '' && this.state.errorsEtapa.dataTermino === '' && this.state.errorsEtapa.mentor === '' && 
                this.state.errorsEtapa.etapa === '' && this.state.etapaTurma.dataInicio !== '' && this.state.etapaTurma.dataTermino !== '';
        }
        return result;
    };

    saveTurma = () => {
        this.showLoading();
        let turma = this.state.turma;
        let etapaMentoriaList = this.state.etapaTurmaList;
        turma.etapaTeoricaList = [];
        etapaMentoriaList.forEach(etapaMentoria => {
            if (etapaMentoria.etapa === 1) turma.etapaA = etapaMentoria;
            else if (etapaMentoria.etapa === 2 || etapaMentoria.etapa === 4 || etapaMentoria.etapa === 7) turma.etapaTeoricaList.push(etapaMentoria);
            else if (etapaMentoria.etapa === 3) turma.etapaPraticaB = etapaMentoria;
            else if (etapaMentoria.etapa === 5) turma.etapaPraticaC = etapaMentoria;
            else if (etapaMentoria.etapa === 6) turma.etapaConsultoriaC = etapaMentoria;
            else if (etapaMentoria.etapa === 8) turma.etapaPraticaD = etapaMentoria;
            else if (etapaMentoria.etapa === 9) turma.etapaConsultoriaD = etapaMentoria;
        });
        if (this.isEdicao) {
            API.post('rest/gestao/Turma/' + turma.id, turma).then(res => {
                this.closeLoading();
                this.showToast('Turma editada com sucesso!', 'success', '/brasilmais/mentorialean');
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Error ao tentar editar turma. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Turma', turma).then(res => {
                this.closeLoading();
                this.showToast('Turma cadastrada com sucesso!', 'success', '/brasilmais/mentorialean');
            }).catch(error => {
                console.log(error);
                this.closeLoading();
                this.showToast('Error ao tentar cadastrar turma. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    };

    getSteps = () => {
        return [
            <Grid container className={'step'}>
                <Grid item xs={12} md={6}  xl={4}>
                    <Grid container justify={'center'} direction={'row'}>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Nome da turma'} id={'nome'} inputValue={this.state.turma.nome} changeValue={this.changeInput}
                                required={'required'} error={this.state.errors.nome} maxLength={'250'} disabled={this.isEdicao && this.state.turma.status > 1} />
                        </Grid>
                        <Grid item xs={12}>
                            <label className='label-field'>Datas previstas</label>
                            <br /><br />
                        </Grid>
                        <Grid container direction={'row'} justify={'flex-start'} alignItems={'flex-start'}>
                            <Grid item xs={1} style={{ marginTop: 3 }}>
                                <label className='label-field'>Início</label>
                            </Grid>
                            <Grid item xs={4} className={'paddingForm'}>
                                <FormField type={'date'} ref={'dataInicio'} id={'dataInicio'} inputValue={this.formatData(this.state.turma.dataInicio)} 
                                	changeValue={this.changeInput} required={'required'} error={this.state.errors.dataInicio} icon={'calendar.svg'} disabled={this.isEdicao  && this.state.turma.status > 1} />
                            </Grid>
                            <Grid item xs={1} style={{ marginTop: 3, marginRight: '20px' }}>
                                <label className='label-field'>Término</label>
                            </Grid>
                            <Grid item xs={4} >
                                <FormField type={'date'} ref={'dataTermino'} id={'dataTermino'} inputValue={this.formatData(this.state.turma.dataTermino)} 
                                	changeValue={this.changeInput} required={'required'} error={this.state.errors.dataTermino} icon={'calendar.svg'} disabled={this.isEdicao && this.state.turma.status > 2} />
                            </Grid>
                        </Grid>                                   
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}>
                <Grid item xs={12} md={8} xl={6}>
                    <Grid container justify={'center'} direction={'row'} spacing={1}>
                        <Grid item xs={12} sm={2} className={'etapa'}>
                            <InputSelect label={'Etapa'} suggestions={this.etapaList} itemLabel={'etapa'} getInput={(e) => { this.changeInput(e, 'etapa') }} id={'etapa'} itemKey={'id'}
                                initialSelectedItem={this.state.etapaTurma.etapa} error={this.state.errorsEtapa.etapa} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <AutoComplete label={'Mentor'} suggestions={this.state.mentorList} itemKey={0} key={'autoCompleteColaboradores'} error={this.state.errorsEtapa.mentor}
                                initialSelectedItem={this.state.etapaTurma.mentor} itemlabel={1} getInput={(e) => { this.changeInput(e, 'etapa') }} />
                        </Grid>
                        <Grid item xs={6} sm={2} className={'etapa'}>
                            <FormField type={'date'} ref={'dataInicio'} id={'dataInicio'} label={'Início'} inputValue={this.formatData(this.state.etapaTurma.dataInicio)} 
                                changeValue={(e) => { this.changeInput(e, 'etapa') }} required={false} error={this.state.errorsEtapa.dataInicio} icon={'calendar.svg'} 
                                min={this.formatData(this.state.turma.dataInicio)} max={this.formatData(this.state.turma.dataTermino)} />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <FormField type={'date'} ref={'dataTermino'} id={'dataTermino'} label={'Término'} inputValue={this.formatData(this.state.etapaTurma.dataTermino)} 
                                changeValue={(e) => { this.changeInput(e, 'etapa') }} required={false} error={this.state.errorsEtapa.dataTermino} icon={'calendar.svg'} 
                                min={this.formatData(this.state.turma.dataInicio)} max={this.formatData(this.state.turma.dataTermino)} />
                        </Grid>
                        <Grid item xs={6} sm={1} className={'botao'}>
                            <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addEtapaTurma} icon={'plus.svg'} disabled={!this.validateTurma(3)} />
                        </Grid>
                        { this.state.etapaTurmaElementList != null && this.state.etapaTurmaElementList.length > 0 &&
                            <Grid item xs={12} xl={11} className={'turma-nova-tabela'}>
                                <Table lines={this.state.etapaTurmaElementList} titles={this.titlesEtapa} initialOrder={'0'} pagination={false} /> 
                            </Grid> }
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step review-step'} justify={'center'} direction={'row'} spacing={1}>
                <Grid item xs={12} md={8} xl={6}>
                    { this.state.turma != null && <Grid item xs={12} xl={11} className={'turma-nova-tabela'}>
                        <Table lines={[[this.state.turma.nome, this.formatDataWithSlash(this.state.turma.dataInicio), this.formatDataWithSlash(this.state.turma.dataTermino)]]} titles={this.titlesGame} initialOrder={'0'} pagination={false} /> 
                    </Grid> }
                    <Grid item xs={12} xl={11} className={'turma-nova-tabela'}>
                        <Table lines={this.state.etapaTurmaElementList} titles={this.titlesEtapaLast} initialOrder={'0'} pagination={false} /> 
                    </Grid>
                </Grid>
            </Grid >]
    };

    render() {
    	return (
            <Page icon={'doc.svg'} label={'Nova Turma'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container spacing={1} className={'top-container'}>
                    <Grid container direction={'row'} justify={'flex-start'}>
                        <Grid item>
                            <Link to={'/brasilmais/mentorialean'} style={{ cursor: "pointer" }}>
                                <Breadcrumb label={'Gerenciar Turmas'} icon={'breadcrumb-blue.svg'} color={'primary'} />
                            </Link>
                        </Grid>
                        <Grid item><Breadcrumb label={'Editar Turma'} icon={'breadcrumb-gray.svg'} color={'secondary'} /></Grid>
                    </Grid>
                </Grid>
                <Grid container className={'nova-turma'}>
                    { this.state.turma && 
                        <Stepper
                            titles={this.titles}
                            steps={this.getSteps()}
                            validation={this.validateTurma}
                            sendForm={this.saveTurma} 
                            history={this.props.history} />}
                </Grid>
            </Page>
    	);
    };

}