import React, { Component } from 'react';
import { Grid } from "@material-ui/core";
import './box.scss';

export default class Box extends Component {
    colors = ['#1AAECC', '#347BF6', '#4CB1F7', '#126DAC'];
    getTrend = (trend) => {
        switch (Number(trend)) {
            case 0: return process.env.PUBLIC_URL + `/assets/icons/aumentar.svg`;
            case 1: return process.env.PUBLIC_URL + `/assets/icons/diminuir.svg`;
            case 2: return process.env.PUBLIC_URL + `/assets/icons/permanecer.svg`;
            default: return process.env.PUBLIC_URL + `/assets/icons/question.svg`;
        }
    }
    getAssessment = (assessment) => {
        switch (Number(assessment)) {
            case 0: return process.env.PUBLIC_URL + `/assets/icons/bom.svg`;
            case 1: return process.env.PUBLIC_URL + `/assets/icons/ruim.svg`;
            case 2: return process.env.PUBLIC_URL + `/assets/icons/neutro.svg`;
            default: return process.env.PUBLIC_URL + `/assets/icons/question.svg`;
        }
    }
    render() {
        return (
            <Grid container style={this.props.header === 'Optimized Productivity' && this.props.tab && this.props.tab === 'stable' ? {marginTop: '300px'} : {marginTop: '15px'}}>
                <Grid item xs={8}>
                    <Grid container>
                        {!this.props.only_content && this.props.title && <Grid container style={{ marginBottom: this.props.header ? '20px' : '0' }}>
                            <Grid item xs={12}><h1 className={'box-title'}>{this.props.title}</h1></Grid>
                            <Grid item xs={12}><hr className={'barra'} style={{ borderColor: this.colors[this.props.color_index ? this.props.color_index : 0] }}></hr></Grid>
                        </Grid>}
                        <Grid container className={'box'}>
                            {!this.props.only_content && this.props.header && !this.props.header_duplo && <Grid item xs={12} className={'box-header'} style={{ display: 'flex', backgroundColor: this.colors[this.props.color_index ? this.props.color_index : 0] }}>
                                <Grid item xs={12}>{this.props.header}</Grid>
                            </Grid>}
                            {!this.props.only_content && this.props.header && this.props.header_duplo && <Grid item xs={12} className={'box-header'} style={{ backgroundColor: this.colors[this.props.color_index ? this.props.color_index : 0] }}>
                                <Grid item xs={12}>{this.props.header}</Grid>
                                <Grid item xs={12} className={'box-sub-header'}>{this.props.header_duplo}</Grid>
                            </Grid>}
                            <Grid item xs={1}></Grid>
                            <Grid item xs={10} className="container-box">
                                <Grid style={(this.props.only_content || !this.props.header) ? { paddingTop: '25px' } : { paddingTop: '5px' }} item xs={12} className={'text1'}>
                                    {this.props.name}
                                </Grid>
                                <Grid style={(this.props.only_content || !this.props.header) ? { paddingTop: '0px' } : { paddingTop: '10px' }} item xs={12}>
                                    <Grid container>
                                        <Grid item xs={this.props.forecast2_value ? 4 : 6}>
                                            <Grid className={'text1 text3'} item xs={12}>{this.props.actual_year}</Grid>
                                            <Grid item xs={12}>Actual</Grid>
                                            <Grid className={'text1 text2'} item xs={12}>{this.props.actual_value}</Grid>
                                        </Grid>
                                        <Grid item xs={this.props.forecast2_value ? 4 : 6}>
                                            <Grid className={'text1 text3'} item xs={12}>{this.props.forecast_year}</Grid>
                                            <Grid item xs={12}>Forecast</Grid>
                                            <Grid className={'text1 text2'} item xs={12}>{this.props.forecast_value}</Grid>
                                        </Grid>
                                        {this.props.forecast2_value && <Grid item xs={4}>
                                            <Grid className={'text1 text3'} item xs={12}>{this.props.forecast2_year}</Grid>
                                            <Grid item xs={12}>Forecast</Grid>
                                            <Grid className={'text1 text2'} item xs={12}>{this.props.forecast2_value}</Grid>
                                        </Grid>}
                                    </Grid>
                                </Grid>
                                <Grid style={{ paddingTop: '10px' }} item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}><hr></hr></Grid>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>Assessment</Grid>
                                            <Grid item xs={12}><img src={this.getAssessment(this.props.assessment)} className={'imagem'} alt={'trend'} /></Grid>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Grid item xs={12}>Trend</Grid>
                                            <Grid item xs={12}><img src={this.getTrend(this.props.trend)} className={'imagem'} alt={'trend'} /></Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {this.props.icon && (this.props.icon !== 'strategic' || (this.props.icon === 'strategic' && this.props.title !== 'Business Sucess')) ?
                    <Grid item xs={4} id={'grid-img'}>
                        <img src={process.env.PUBLIC_URL + '/assets/icons/' + this.props.icon + '.svg'} alt='seta' className={this.props.icon} />
                    </Grid>
                    : null
                }
            </Grid>
        )
    }
}