const Masks = {
    maskCpf(value) {
        return value
            .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
            .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d{1,2})/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
    },

    maskByTipoPessoa(value, tipoPessoa) {
        switch (tipoPessoa) {
            case 0: // CPF
                value = value.substring(0, 14);
                return value
                    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
                    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
                    .replace(/(\d{3})(\d)/, '$1.$2')
                    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
            case 1: // CNPJ
                value = value.substring(0, 18);
                return value
                    .replace(/\D/g, '') // substitui qualquer caracter que nao seja numero por nada
                    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de numero, o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona . antes do segundo grupo de numero
                    .replace(/(\d{3})(\d)/, '$1.$2') // captura 2 grupos de numero, o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona . antes do segundo grupo de numero
                    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de numero, o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona / antes do segundo grupo de numero
                    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
                    .replace(/(-\d{2})\d+?$/, '$1'); // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
            case 2: // Passaporte
                return value;
            case 3: // Documento
                return value;
            default:
                return '';
        }
    },

    maskTelefone(value) {
        if (value != null && value.length > 0) {
            value = value.substring(0, 15);
            value =  value.replace(/\D/g, "")             //Remove tudo o que não é dígito
                    .replace(/^(\d{2})(\d)/g, "($1) $2") //Coloca parênteses em volta dos dois primeiros dígitos
                    .replace(/(\d)(\d{4})$/, "$1-$2");
        } 
        return value;
    },

    maskCep(value) {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{2})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1');
    },
    maskCurrency(value) {
        if (typeof (value) === "number") {
            return "R$ " + value.toLocaleString('pt-BR', {maximumFractionDigits: 2, minimumFractionDigits: 2});
        } else if (typeof (value) === "string") {
            if (value.includes(".")) {
                let res = value.split(".");
                let decimal = "";
                if (parseFloat(res[1]) === 0 || res[1] === "") {
                    decimal = "00"
                } else if (res[1].length < 2) {
                    decimal = res[1] * 10
                } else {
                    decimal = res[1].slice(0, 2);
                }
                return "R$ " + parseFloat(res[0]).toLocaleString('pt-BR') + "," + decimal;
            } else if (value.includes(",")) {
                let res = value.split(",");
                let decimal = "";
                if (parseFloat(res[1]) === 0 || res[1] === "") {
                    decimal = "00"
                } else if (res[1].length < 2) {
                    decimal = res[1] * 10
                } else {
                    decimal = res[1].slice(0, 2);
                }
                return "R$ " + parseFloat(res[0]).toLocaleString('pt-BR') + "," + decimal;
            } else {
                return "R$ " + parseFloat(value).toLocaleString('pt-BR') + ",00"
            }
        }
    },
    maskDecimal(value, decimal) {
        var mask
        if (decimal === 4) mask = /^-?\d+(?:\.\d{0,4})?/;
        else if (decimal === 1) mask = /^-?\d+(?:\.\d{0,1})?/;
        else mask = /^-?\d+(?:\.\d{0,2})?/;
        
        if (value === "" || value == null || isNaN(value)) {
            return 0.0000;
        }

        if (typeof (value) === "number") {
            return value.toString().match(mask)[0];
        } else {
            if (value.includes(".")) {
                return value.match(mask)[0];
            } else {
                return value.match(mask)[0].replace(",", ".");
            }
        }
    },
    maskCnae(value) {
        if (value && value !== '' && value.length === 7)
            return value.replace(/\D/g, '').replace(/(\d{4})(\d{1})(\d{2})/, '$1-$2/$3');
        return value;
    },
    removeMask(value) {
        return value
            .replace(/\D/g, '');             //Remove tudo o que não é dígito
    }
};

/*https://github.com/s-yadav/react-number-format*/

const getDigitsFromValue = (value = '') => value.replace(/(-(?!\d))|[^0-9|-]/g, '') || '';

const padDigits = (digits, type) => {
    const desiredLength = type === 'inteiro' ? 1 :
        (type === 'indicador' ? 5 :
            (type === 'umacasa' ? 2 :
                (type === 'seiscasas' ? 7 : 3)));
    const actualLength = digits.length;

    if (actualLength >= desiredLength) {
        return digits;
    }

    const amountToAdd = desiredLength - actualLength;
    const padding = '0'.repeat(amountToAdd);
    return padding + digits;
};

const removeLeadingZeros = number => number.replace(/^0+([0-9]+)/, '$1');

const addDecimalToNumber = (number, separator, type) => {
    const centsStartingPosition = type === 'inteiro' ? number.length :
        (type === 'indicador' ? number.length - 4 :
            (type === 'umacasa' ? number.length - 1 :
                (type === 'seiscasas' ? number.length - 6 : number.length - 2)));
    var dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
    const cents = number.substring(centsStartingPosition)
    dollars = applyThousandSeparator(dollars, '.');
    return type === 'inteiro' ? dollars : (dollars + separator + cents);
};

export const toCurrency = (value, type, separator = '.') => {
    const digits = getDigitsFromValue(value)
    const digitsWithPadding = padDigits(digits, type)
    return addDecimalToNumber(digitsWithPadding, separator, type);
};

export function applyThousandSeparator(str: string, thousandSeparator: string) {
    const thousandsGroupRegex = /(\d)(?=(\d{3})+(?!\d))/g;
    let index = str.search(/[1-9]/);
    index = index === -1 ? str.length : index;
    return str.substring(0, index) + str.substring(index, str.length).replace(thousandsGroupRegex, '$1' + thousandSeparator);
}

export const maskMoney = (value) => {
    const cleanValue = value.replace(/[^0-9]/g, '');
  
    if (cleanValue === '') {
      return '';
    }
  
    let int = +cleanValue;
  
    if (int >= Number.MAX_SAFE_INTEGER + 1) {
      int = +cleanValue.slice(0, cleanValue.length - 1);
    }
  
    let ret = (int / 100).toString();
  
    if (ret.search('.') !== -1) {
      ret = ret.replace(/[.]/g, ',');
  
      const split = ret.split(',');
  
      if (split.length === 1) {
        split.push('');
      }
  
      if (split[1].length === 0) {
        split[1] += '0';
      }
      if (split[1].length === 1) {
        split[1] += '0';
      }
  
      ret = `${split[0]},${split[1]}`;
    }
  
    return `R$ ${ret}`;
};

export default Masks;