import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import { Grid } from "@material-ui/core";
import API from '../../comm/API'
import SimpleModal from './../../components/modal/modal'
import InputSelect from '../../components/inputselect/inputselect';
import AutoComplete from '../../components/autoComplete/autoComplete';
import FormField from '../../components/formField/formField';
import Chip from "@material-ui/core/Chip";
import Icon from '@material-ui/core/Icon';
import DownloadFiles from '../../comm/DownloadFiles';
import Loading from '../../components/loading/loading';
import { tipoInputSelectBMPTD } from './enum/tipoInputSelectBMPTD';
import { indicadorTipoInput } from './enum/indicadorTipoInput';
import { TipoInputEnum } from './enum/tipoInput';
import Masks from '../../comm/masks';

export default class Indicadores extends Component {

    atendimento = this.props.atendimento;
    colaborador = this.props.atendimento.colaborador;
    canEditIndicadores = false;
    canSolicitarDN = false;
    isBaseNacional = this.props.atendimento.produtoRegional.produtoNacional.isBaseNacional;
    novosIndicadores = this.props.novosIndicadores ? this.props.novosIndicadores.sort((i, j) => (i.indicadorReferencial.id > j.indicadorReferencial.id) ? 1 : -1) : null;

    //Atendimento
    produtividade = 0;
    qualidade = 0;
    retornoPrograma = 0;
    movimentacao = 0;
    disponibilidade = 0;

    //Indicadores
    reducaoConsumo = 0;
    reducaoCusto = 0;
    reducaoConsumoAno = 0;
    reducaoCustoAno = 0;
    emissaoCo = 0;
    usinaEquivalente = 0;
    casaEquivalente = 0;
    payback = 0;
    performance = 0;
    produtividadeFinal = 0;
    oeeInicial = 0;
    oeeFinal = 0;
    oee = 0;
    maturidade = 0;

    anexoOptions = [];
    anexoList = [];
    anexoOption = { nome: "Selecione o tipo", valor: 0 };

    constructor(props) {
        super(props);
        this.state = {}
        this.getAnexoOptions();
    };

    calculaAumento = (attrC, attrD) => {
        return (attrC !== 0 && attrD !== 0 ? 100 * (attrD - attrC) / attrC : (attrC === 0 && attrD > 0 ? attrD : 0));
    };

    calculaReducao = (attrC, attrD) => {
        return (attrC !== 0 && attrD !== 0 ? 100 * (attrC - attrD) / attrC : 0);
    };

    getIndicadoresBrasilMaisLEAN = () => {
        let processoC = this.props.turma.etapaPraticaC.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
        let processoD = this.props.turma.etapaPraticaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
        if (processoC && processoC.processoProdutivo && processoD && processoD.processoProdutivo) {
            this.produtividade = this.calculaAumento(processoC.processoProdutivo.produtividade, processoD.processoProdutivo.produtividade);
            this.movimentacao = this.calculaReducao(processoC.processoProdutivo.movimentacao, processoD.processoProdutivo.movimentacao);
            this.qualidade = this.calculaAumento(processoC.processoProdutivo.qualidade, processoD.processoProdutivo.qualidade);
            this.payback = processoD.processoProdutivo.retornoDoPrograma;
            this.atendimento.produtividade = this.produtividade;
        }
    }

    getIndicadoresBrasilMaisDIGITAL = () => {
        let processoC = this.props.turma.etapaPraticaC.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
        let processoD = this.props.turma.etapaPraticaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
        // pedir opnião do que mostrar


        if (processoC && processoC.indicadoresDigital && processoD && processoD.indicadoresDigital) {
            this.produtividade = this.calculaAumento(processoC.indicadoresDigital.produtividadeDigital.medicao, processoD.indicadoresDigital.produtividadeDigital.medicao);
            this.oee = this.calculaAumento(processoC.indicadoresDigital.oeeDigital.medicao, processoD.indicadoresDigital.oeeDigital.medicao);
            this.maturidade = this.calculaAumento(processoC.indicadoresDigital.maturidadeDigital.pontuacaoTotal, processoD.indicadoresDigital.maturidadeDigital.pontuacaoTotal);
            this.payback = processoD.analiseResultadosDigital.retornoPrograma;
        }
    }

    checkIfCanEditIndicadores = () => {
        //Usuários podem editar indicadores caso estejam sem status ou não conformes.
        //Usuários não podem editar caso ele esteja em análise ou conformes.
        if (this.atendimento.solicitacaoStatus == null) {
            this.canEditIndicadores = true;
        } else {
            this.canEditIndicadores = (
                this.atendimento.solicitacaoStatus.chave === 'naoconforme' ||
                this.atendimento.solicitacaoStatus.chave === 'naoconformeIA' ||
                this.atendimento.solicitacaoStatus.chave === 'naoconformeIA180' ||
                this.atendimento.solicitacaoStatus.chave === 'naoconformeIPEA' ||
                this.atendimento.solicitacaoStatus.chave === 'naoconformeIE' ||
                this.atendimento.solicitacaoStatus.chave === 'naoconformeLEAN' ||
                this.atendimento.solicitacaoStatus.chave === 'naoconformeDIGITAL'
            );
        }
        return this.canEditIndicadores;
    };

    checkIfCanSolicitarDN = () => {
        if (this.props.isAtendimentoIE && this.props.indicadores != null && this.props.indicadores[0] != null) {
            if (this.props.indicadores[0].reducaoConsumo == null ||
                this.props.indicadores[0].reducaoCusto == null ||
                this.props.indicadores[0].reducaoConsumoAno == null ||
                this.props.indicadores[0].reducaoCustoAno == null ||
                this.props.indicadores[0].emissaoCo == null ||
                this.props.indicadores[0].usinaEquivalente == null ||
                this.props.indicadores[0].casaEquivalente == null ||
                this.props.indicadores[0].payback == null) {
                this.canSolicitarDN = false;
            }
        } else if (!(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL)) {
            if (this.props.isAtendimentoIA || this.props.isAtendimentoIA180) {
                if (this.props.atendimento.disponibilidade == null) {
                    this.canSolicitarDN = false;
                }

            } else if (this.props.atendimento.movimentacao == null) {
                this.canSolicitarDN = false;
            }
            if (this.props.atendimento.produtividade == null ||
                this.props.atendimento.retornoPrograma == null) {
                this.canSolicitarDN = false;
            }
        }
        if (this.props.isAtendimentoIE) {
            this.canSolicitarDN = this.anexoList.length >= 9 && this.canEditIndicadores;
        } else if (!(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL)) {
            this.canSolicitarDN = this.anexoList.length === (this.anexoOptions.length - 1) && this.canEditIndicadores;
        }
        if (this.props.isAtendimentoIPEA && this.props.atendimento.tipoBPIpea !== 'CONSULTORIA') this.canSolicitarDN = false;
        if (this.props.isAtendimentoBrasilMaisLEAN && this.props.turma) {
            let consultoriaD = this.props.turma.etapaConsultoriaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            this.canSolicitarDN = this.props.turma.etapaAtual === 9 && consultoriaD && consultoriaD.finalizado && this.produtividade < 20 &&
                (this.atendimento.solicitacaoStatus == null || (this.atendimento.solicitacaoStatus != null &&
                    (this.atendimento.solicitacaoStatus.chave === 'naoconforme' || this.atendimento.solicitacaoStatus.chave === 'conforme')));
        }
        if (this.props.isAtendimentoDIGITAL && this.props.turma) {
            let praticaD = this.props.turma.etapaPraticaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            let consultoriaD = this.props.turma.etapaConsultoriaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            this.canSolicitarDN = this.props.turma.etapaAtual >= 10 && praticaD && praticaD.finalizado && consultoriaD && consultoriaD.finalizado && this.produtividade < 20 &&
                (this.atendimento.solicitacaoStatus == null || (this.atendimento.solicitacaoStatus != null &&
                    (this.atendimento.solicitacaoStatus.chave === 'naoconformeDIGITAL' || this.atendimento.solicitacaoStatus.chave === 'conformeDIGITAL')));
        }
        this.setState(this.state);
    };

    changeColaborador = (e) => {
        this.colaborador = e;
        this.setState(this.state);
    };

    changeAnexoOption = (e) => {
        this.anexoOption = e;
        this.setState(this.state);
    };

    getIndicadoresModel = () => {
        this.colaborador = this.props.atendimento.colaborador;
        this.produtividade = this.props.atendimento.produtividade;
        this.qualidade = this.props.atendimento.qualidade;
        this.retornoPrograma = this.props.atendimento.retornoPrograma;
        this.movimentacao = this.props.atendimento.movimentacao;
        this.disponibilidade = this.props.atendimento.disponibilidade;
        this.anexoList = this.props.atendimento.anexoIndicadorProdutividadeList.slice();
        if (this.props.indicadores != null && this.props.indicadores[0] != null) {
            this.reducaoConsumo = this.props.indicadores[0].reducaoConsumo;
            this.reducaoCusto = this.props.indicadores[0].reducaoCusto;
            this.reducaoConsumoAno = this.props.indicadores[0].reducaoConsumoAno;
            this.reducaoCustoAno = this.props.indicadores[0].reducaoCustoAno;
            this.emissaoCo = this.props.indicadores[0].emissaoCo;
            this.usinaEquivalente = this.props.indicadores[0].usinaEquivalente;
            this.casaEquivalente = this.props.indicadores[0].casaEquivalente;
            this.payback = this.props.indicadores[0].payback;
            this.performance = this.props.indicadores[0].performance;
            this.produtividadeFinal = this.props.indicadores[0].produtividadeFinal;
            this.oeeInicial = this.props.indicadores[0].oeeInicial;
            this.oeeFinal = this.props.indicadores[0].oeeFinal;
        }
    };

    clearIndicadores = () => {
        this.getIndicadoresModel();
        this.setState(this.state);
        this.props.changeModal('modalIndicadores', false);
    };

    saveIndicadores = () => {
        var atendimento = this.props.atendimento;
        atendimento.produtividade = this.produtividade;
        atendimento.qualidade = this.qualidade;
        atendimento.retornoPrograma = this.retornoPrograma;
        atendimento.movimentacao = this.movimentacao;
        atendimento.disponibilidade = this.disponibilidade;
        atendimento.colaborador = this.colaborador;
        atendimento.anexoIndicadorProdutividadeList = this.anexoList;
        if (this.props.indicadores != null && this.props.indicadores[0] != null) {
            var indicadores = this.props.indicadores[0];
            indicadores.produtividade = this.produtividade;
            indicadores.qualidade = this.qualidade;
            indicadores.retornoPrograma = this.retornoPrograma;
            indicadores.movimentacao = this.movimentacao;
            indicadores.disponibilidade = this.disponibilidade;
            indicadores.reducaoConsumo = this.reducaoConsumo;
            indicadores.reducaoCusto = this.reducaoCusto;
            indicadores.reducaoConsumoAno = this.reducaoConsumoAno;
            indicadores.reducaoCustoAno = this.reducaoCustoAno;
            indicadores.emissaoCo = this.emissaoCo;
            indicadores.usinaEquivalente = this.usinaEquivalente;
            indicadores.casaEquivalente = this.casaEquivalente;
            indicadores.payback = this.payback;
            indicadores.performance = this.performance;
            indicadores.produtividadeFinal = this.produtividadeFinal;
            indicadores.oeeInicial = this.oeeInicial;
            indicadores.oeeFinal = this.oeeFinal;
        }

        this.props.showLoading();
        API.post('rest/gestao/Atendimento/' + atendimento.id, atendimento).then(res => {
            if (this.props.indicadores != null && this.props.indicadores[0] != null) {
                API.post('rest/gestao/Indicadores/' + indicadores.id, indicadores).then(res => {
                    this.props.changeModalShowToast('modalIndicadores', false, 'Indicadores registrados com sucesso!');
                    this.props.refreshAtendimento(atendimento);
                    this.checkIfCanSolicitarDN();
                    this.props.closeLoading();
                }).catch(error => {
                    console.log(error)
                    this.props.closeLoading();
                    this.props.showToast('Erro ao avaliar indicadores. Favor, entrar em contato com o suporte.', 'error');
                });
            } else {
                this.props.changeModalShowToast('modalIndicadores', false, 'Indicadores registrados com sucesso!');
                this.props.refreshAtendimento(atendimento);
                this.checkIfCanSolicitarDN();
                this.props.closeLoading();
            }
        }).catch(error => {
            console.log(error)
            this.props.closeLoading();
            this.props.showToast('Erro ao avaliar indicadores. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    changeInput = (e) => {
        if (e != null && e.target != null) {
            this[e.target.id] = e.target.value;
            this.setState(this.state);
        }
    };

    getAnexoOptions = () => {
        var filter = new URLSearchParams();
        if (this.props.isAtendimentoBP) {
            filter.append('proprietario', 'BP');
        } else if (this.props.isAtendimentoIA) {
            filter.append('proprietario', 'IA');
        } else if (this.props.isAtendimentoIA180) {
            filter.append('proprietario', 'IA180');
        } else if (this.props.isAtendimentoIPEA) {
            filter.append('proprietario', 'IPEA_' + this.props.atendimento.tipoBPIpea.toUpperCase());
        } else if (this.props.isAtendimentoLEAN28) {
            filter.append('proprietario', 'LEAN28');
        } else if (this.props.isAtendimentoLEAN56) {
            filter.append('proprietario', 'LEAN56');
        } else if (this.props.isAtendimentoMENTORIA) {
            filter.append('proprietario', 'MENTORIA');
        } else if (this.props.isAtendimentoIE) {
            filter.append('proprietario', 'IE');
        }
        if (this.props.turma != null && this.props.isAtendimentoBrasilMaisLEAN) {
            this.getIndicadoresBrasilMaisLEAN();
            this.checkIfCanSolicitarDN();
        } else if (this.props.turma != null && this.props.isAtendimentoDIGITAL) {
            this.getIndicadoresBrasilMaisDIGITAL();
            this.checkIfCanSolicitarDN();
        } else {
            API.get('/rest/auxiliar/TipoAnexo', { params: filter }).then(res => {
                this.anexoOptions = [{ nome: "Selecione o tipo", valor: 0, group: true }];

                res.data.forEach(element => {
                    this.anexoOptions.push({ nome: element.nome, valor: element.valor })
                });
                this.getIndicadoresModel();
                this.checkIfCanEditIndicadores();
                this.checkIfCanSolicitarDN();
            });
        }
    };

    removeAnexo = (anexo) => {
        let atendimento = this.props.atendimento;
        let anexoListCopy = this.anexoList;
        let index = anexoListCopy.findIndex(prop => {
            return prop.url === anexo.url;
        });
        if (index !== -1) anexoListCopy.splice(index, 1);

        atendimento.anexoIndicadorProdutividadeList = anexoListCopy;

        this.props.showLoading();
        API.post('rest/gestao/Atendimento/' + this.props.atendimento.id, atendimento).then(res => {
            this.anexoList = anexoListCopy;
            this.props.atendimento.anexoIndicadorProdutividadeList = anexoListCopy;
            this.props.closeLoading();
            this.props.showToast('Anexo excluído com sucesso!', 'success');
            this.checkIfCanSolicitarDN();
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Erro ao deletar anexo. Favor, entrar em contato com o suporte.', 'error');
            this.checkIfCanSolicitarDN();
        });
    };

    uploadAnexo = (e) => {
        let file = e.target.files[0];
        this.setState(this.state);

        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file.size > 10000000) {
            this.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }
        this.props.showLoading();
        API.post('rest/upload/atendimento/anexo/' + this.props.atendimento.id + '/' + this.getNomeArquivo(this.anexoOption.valor), formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(res => {
            this.anexoOption = { nome: "Selecione o tipo", valor: 0 };
            this.anexoList = res.data.anexoIndicadorProdutividadeList;
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
            this.checkIfCanSolicitarDN();
        }).catch(error => {
            this.props.closeLoading();
            if (file.name.endsWith(".pdf") || file.name.endsWith(".doc") || file.name.endsWith(".docx") || file.name.endsWith(".xlsx") || file.name.endsWith(".xls")) {
                this.props.showToast('Erro no upload do arquivo. Favor, entrar em contato com o suporte.', 'error');
            } else {
                this.props.showToast('Extensão inválida. Formatos permitidos: pdf, doc, docx, xlsx e xls', 'error');
            }
        });
    }

    getNomeArquivo(valor) {
        let countUrls = 0;
        this.anexoList.forEach(arq => {
            if (arq.url.includes(valor)) {
                countUrls = countUrls + 1;
            }
        });
        return this.anexoOption.valor + '_' + countUrls;
    };

    buttonIndicadores = () => {
        const status = this.props.atendimento.atendimentoStatus.chave;

        if (status === 'aceito' || status === 'emexecucao') {
            if (this.props.gerenciarAtendimento) {
                if (!this.props.isDN && (this.props.atendimento.solicitacaoStatus == null || this.props.atendimento.solicitacaoStatus.descricao === 'Não Conforme') && this.checkIfCanEditIndicadores()) {
                    return <GenericButton color={'darkBlue'} label={'Registrar indicadores'} subClass={'basic-button'} click={() => { this.props.showLoading(); this.props.openIndicadoresScreen(true); }} />
                }
            } else if (this.props.isDN && this.props.atendimento.solicitacaoStatus && this.props.atendimento.solicitacaoStatus.descricao === 'Em Análise') {
                return <GenericButton color={'darkBlue'} label={'Avaliar indicadores'} subClass={'basic-button'} click={() => { this.props.showLoading(); this.props.openIndicadoresScreen(true); }} />
            }
        }
    }

    isIndicadorCnpj = (indicadorRef) => {
        const tipoInput = indicadorTipoInput.find(item => item.id === indicadorRef.id)?.tipo;
        return tipoInput === TipoInputEnum.Cnpj;
      }
    

    formatIndicadorResultado = (indicador) => {
        const tipoInput = indicadorTipoInput.find(item => item.id === indicador.indicadorReferencial.id)?.tipo;

        const isIndicadorCnpj = this.isIndicadorCnpj(indicador.indicadorReferencial); 

        if (tipoInput && tipoInputSelectBMPTD[tipoInput]) {
            const selectedOption = tipoInputSelectBMPTD[tipoInput].find(option => option.value === indicador.resultado.toString());
            if (selectedOption) {
                indicador.indicadorReferencial.sufixo = indicador.indicadorReferencial.sufixo !== "(0-5)" ? indicador.indicadorReferencial.sufixo : "";
                return `${selectedOption.label} ${indicador.indicadorReferencial.sufixo}`;
            }
        }

        if (isIndicadorCnpj) {
            const resultadoString = indicador.resultado?.toString() || '';
            return Masks.maskByTipoPessoa(resultadoString, 1);
        }

        return indicador.resultado.toLocaleString('pt-BR', {
            minimumFractionDigits: 6,
            maximumFractionDigits: 6
        }) + (indicador.indicadorReferencial.sufixo === '%' ? indicador.indicadorReferencial.sufixo : ' ' + indicador.indicadorReferencial.sufixo);
    }

    render() {
        return (
            <Grid item xs={12}>
                {this.props.isModuloNacional ?
                    <div className={'greyDiv'}>
                        {this.buttonIndicadores()}
                        <h2>Indicadores</h2>
                        <Grid container>
                            <Grid item xs={12}>
                                {this.novosIndicadores && this.novosIndicadores.length > 0 ?
                                    this.novosIndicadores.map((indicador, index) => (
                                        <Grid container key={'indicador_' + index}>
                                            {indicador.solicitacaoStatus ?
                                                <Grid item xs={1} className={"tooltip-solicitacao"}>
                                                    <span className={'tooltip-info indicadores'}>{indicador.solicitacaoStatus.chave === 'conforme' ? 'Aprovado' : 'Reprovado'}</span>
                                                    <img alt={''} src={indicador.solicitacaoStatus.chave === 'conforme' ? '/assets/icons/aprovado.svg' : '/assets/icons/reprovado.svg'} />
                                                </Grid> :
                                                <Grid item xs={1} className={"tooltip-solicitacao"}>
                                                    <span className={'tooltip-info indicadores'}>Não avaliado</span>
                                                    <img alt={''} src={'/assets/icons/nao-avaliado.svg'} />
                                                </Grid>}
                                            <Grid item xs={8}><b>{indicador.indicadorReferencial.nome}</b></Grid>
                                            <Grid item xs={3}>
                                                {this.formatIndicadorResultado(indicador)}
                                            </Grid>

                                        </Grid>
                                    )) :
                                    <p>Este atendimento não possui indicadores registrados.</p>}
                            </Grid>
                        </Grid>
                    </div> :
                    <div className={'greyDiv'}>
                        {(this.props.isAceito || this.props.isExecucao) && !(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && !this.props.isDN && (this.props.atendimento.solicitacaoStatus == null || this.props.atendimento.solicitacaoStatus.descricao === 'Não Conforme') &&
                            <GenericButton color={'darkBlue'} label={'Registar Indicadores'} subClass={'basic-button'}
                                click={() => { this.getIndicadoresModel(); this.checkIfCanEditIndicadores(); this.props.changeModal('modalIndicadores', true) }} />}
                        <h2>Indicadores</h2>
                        {(this.props.isAtendimentoBP || this.props.isAtendimentoIA || this.props.isAtendimentoIPEA || this.props.isAtendimentoLEAN || this.props.isAtendimentoIA180) ?
                            <Grid container>
                                <Grid item xs={8}><b>Produtividade (em %)</b></Grid>
                                <Grid item xs={4}>{this.props.atendimento.produtividade ? this.props.atendimento.produtividade.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                            </Grid>
                            : null}
                        {(this.props.isAtendimentoDIGITAL) ?
                            <Grid container>
                                <Grid item xs={8}><b>Produtividade (em %)</b></Grid>
                                <Grid item xs={4}>{this.produtividade ? this.produtividade.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                                <Grid item xs={8}><b>OEE (em %)</b></Grid>
                                <Grid item xs={4}>{this.oee ? this.oee.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                                <Grid item xs={8}><b>Maturidade (em %)</b></Grid>
                                <Grid item xs={4}>{this.maturidade ? this.maturidade.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                                <Grid item xs={4}><b>Payback (em meses)</b></Grid>
                                <Grid item xs={8}>{this.payback ? this.payback.toLocaleString('pt-BR', { maximumFractionDigits: 6, minimumFractionDigits: 6 }) + ' mes(es)' : '-'}</Grid>
                            </Grid>
                            : null
                        }
                        {(this.props.isAtendimentoBP || this.props.isAtendimentoIPEA || this.props.isAtendimentoLEAN) ?
                            <Grid container>
                                <Grid item xs={8}><b>Movimentação (em %)</b></Grid>
                                <Grid item xs={4}>{this.props.atendimento.movimentacao ? this.props.atendimento.movimentacao.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                            </Grid>
                            : null}

                        {(this.props.isAtendimentoIA || this.props.isAtendimentoIA180) ?
                            <Grid container>
                                <Grid item xs={8}><b>Disponibilidade (em %)</b></Grid>
                                <Grid item xs={4}>{this.props.atendimento.disponibilidade ? this.props.atendimento.disponibilidade.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                            </Grid>
                            : null}


                        {(this.props.isAtendimentoBP || this.props.isAtendimentoIPEA || this.props.isAtendimentoIA || this.props.isAtendimentoLEAN || this.props.isAtendimentoIA180) ?
                            <Grid container>
                                <Grid item xs={8}><b>Qualidade - Retrabalho/Refugo (em %)</b></Grid>
                                <Grid item xs={4}>{this.props.atendimento.qualidade ? this.props.atendimento.qualidade.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                                <Grid item xs={8}><b>Retorno do Programa S (em meses)</b></Grid>
                                <Grid item xs={4}>{this.props.atendimento.retornoPrograma ? this.props.atendimento.retornoPrograma.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : '-'} mes(es)</Grid>
                            </Grid>
                            : null}


                        {this.props.isAtendimentoIE && this.props.indicadores != null && this.props.indicadores.length > 0 ?
                            <Grid container>
                                <Grid item xs={8}><b>Redução de Consumo Energético com Intervenção (em %)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].reducaoConsumo ? this.props.indicadores[0].reducaoConsumo.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                                <Grid item xs={8}><b>Redução de Custo com Energia na Intervenção (em %)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].reducaoCusto ? this.props.indicadores[0].reducaoCusto.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} %</Grid>
                                <Grid item xs={8}><b>Redução de Consumo/ano (em MWh/ano)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].reducaoConsumoAno ? this.props.indicadores[0].reducaoConsumoAno.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} MWh/ano</Grid>
                                <Grid item xs={8}><b>Redução de Custo/ano (em R$)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].reducaoCustoAno ? 'R$ ' + this.props.indicadores[0].reducaoCustoAno.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'}</Grid>
                                <Grid item xs={8}><b>Emissão de CO2 (em ton CO2)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].emissaoCo ? this.props.indicadores[0].emissaoCo.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} ton CO2</Grid>
                                <Grid item xs={8}><b>Usina Equivalente (em MW)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].usinaEquivalente ? this.props.indicadores[0].usinaEquivalente.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} MW</Grid>
                                <Grid item xs={8}><b>Casa Equivalente (em Número de casas)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].casaEquivalente ? this.props.indicadores[0].casaEquivalente.toLocaleString('pt-BR', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '-'} casa(s)</Grid>
                                <Grid item xs={8}><b>Payback (em meses)</b></Grid>
                                <Grid item xs={4}>{this.props.indicadores[0].payback ? this.props.indicadores[0].payback.toLocaleString('pt-BR', { maximumFractionDigits: 1, minimumFractionDigits: 1 }) : '-'} mes(es)</Grid>
                            </Grid>
                            : null}

                        {this.props.isAtendimentoBrasilMaisLEAN ?
                            <Grid container>
                                <Grid item xs={4}><b>Produtividade (em %)</b></Grid>
                                <Grid item xs={8}>{this.produtividade ? this.produtividade.toLocaleString('pt-BR', { maximumFractionDigits: 6, minimumFractionDigits: 6 }) + ' %' : '-'}</Grid>
                                <Grid item xs={4}><b>Movimentação (em %)</b></Grid>
                                <Grid item xs={8}>{this.movimentacao ? this.movimentacao.toLocaleString('pt-BR', { maximumFractionDigits: 6, minimumFractionDigits: 6 }) + ' %' : '-'}</Grid>
                                <Grid item xs={4}><b>Qualidade (em %)</b></Grid>
                                <Grid item xs={8}>{this.qualidade ? this.qualidade.toLocaleString('pt-BR', { maximumFractionDigits: 6, minimumFractionDigits: 6 }) + ' %' : '-'}</Grid>
                                <Grid item xs={4}><b>Payback (em meses)</b></Grid>
                                <Grid item xs={8}>{this.payback ? this.payback.toLocaleString('pt-BR', { maximumFractionDigits: 6, minimumFractionDigits: 6 }) + ' mes(es)' : '-'}</Grid>
                            </Grid>
                            : null
                        }
                        {!this.props.isAtendimentoBrasilMaisLEAN &&
                            <Grid container>
                                <Grid item xs={8}><b>Colaborador</b></Grid>
                                <Grid item xs={4}>{this.props.atendimento != null && this.props.atendimento.colaborador != null ? this.props.atendimento.colaborador.nome : '-'}</Grid>
                            </Grid>
                        }
                        {(this.props.atendimento.solicitacaoStatus && !this.props.isDN && (this.props.atendimento.solicitacaoStatus.chave === 'naoconforme' ||
                            this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIA' || this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIA180' ||
                            this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIPEA' || this.props.atendimento.solicitacaoStatus.chave === 'naoconformeIE' ||
                            this.props.atendimento.solicitacaoStatus.chave === 'naoconformeLEAN' || this.props.atendimento.solicitacaoStatus.chave === 'naoconformeDIGITAL' ||
                            this.props.atendimento.solicitacaoStatus.chave === 'conforme' || this.props.atendimento.solicitacaoStatus.chave === 'conformeIA' ||
                            this.props.atendimento.solicitacaoStatus.chave === 'conformeIA180' || this.props.atendimento.solicitacaoStatus.chave === 'conformeIPEA' ||
                            this.props.atendimento.solicitacaoStatus.chave === 'conformeIE' || this.props.atendimento.solicitacaoStatus.chave === 'conformeLEAN' ||
                            this.props.atendimento.solicitacaoStatus.chave === 'conformeDIGITAL')) ?
                            <Grid item xs={12} style={{ marginTop: '15px' }}>
                                <div className={'greyDiv observacao-dn'}>
                                    <h2>Observação do DN:</h2>
                                    <p>{this.props.atendimento.observacao ? this.props.atendimento.observacao : 'Sem observação.'}</p>
                                </div>
                            </Grid>
                            : null
                        }
                    </div>}
                <SimpleModal
                    isOpen={this.props.modalIndicadores}
                    handleClose={() => this.props.changeModal('modalIndicadores', false)}
                    type={'info'}
                    className={'modal-indicadores'}
                    disableClick={true}
                    width={'700px'}>
                    <Loading getFunctions={this.getFunctions} />
                    <div className={'modal modal-comentario'}>
                        {this.props.isDN ? <h2>Avaliar Indicadores</h2> : <h2>Registrar Indicadores</h2>}
                        <Grid container>
                            {(this.props.isAtendimentoBP || this.props.isAtendimentoIA || this.props.isAtendimentoIPEA || this.props.isAtendimentoLEAN || this.props.isAtendimentoIA180) ?
                                <Grid item xs={12} md={6} className={'paddingForm'}>
                                    <FormField type={'number'} id={'produtividade'} label={'Produtividade (em %)'} disabled={!(this.canEditIndicadores)}
                                        changeValue={this.changeInput} inputValue={this.produtividade} min="0" maxLength="15" subtype={'indicador'} />
                                </Grid>
                                : null}

                            {(this.props.isAtendimentoDIGITAL) ?
                                <Grid container>
                                    <Grid item xs={12} md={6} className={'paddingForm'}>
                                        <FormField type={'number'} id={'produtividade'} label={'Produtividade Inicial (em %)'} disabled={!(this.canEditIndicadores)}
                                            changeValue={this.changeInput} inputValue={this.produtividade} min="0" maxLength="15" subtype={'indicador'} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormField type={'number'} id={'produtividadeFinal'} label={'Produtividade Final (em %)'} disabled={!(this.canEditIndicadores)}
                                            changeValue={this.changeInput} inputValue={this.produtividadeFinal} min="0" maxLength="15" subtype={'indicador'} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className={'paddingForm'}>
                                        <FormField type={'number'} id={'oeeInicial'} label={'OEE Inicial (em %)'} disabled={!(this.canEditIndicadores)}
                                            changeValue={this.changeInput} inputValue={this.oeeInicial} min="0" maxLength="15" subtype={'indicador'} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormField type={'number'} id={'oeeFinal'} label={'OEE Final (em %)'} disabled={!(this.canEditIndicadores)}
                                            changeValue={this.changeInput} inputValue={this.oeeFinal} min="0" maxLength="15" subtype={'indicador'} />
                                    </Grid>
                                </Grid>
                                : null}

                            {(this.props.isAtendimentoBP || this.props.isAtendimentoIPEA || this.props.isAtendimentoLEAN) ?
                                <Grid item xs={12} md={6} >
                                    <FormField type={'number'} id={'movimentacao'} label={'Movimentação (em %)'} disabled={!(this.canEditIndicadores)}
                                        changeValue={this.changeInput} inputValue={this.movimentacao} min="0" maxLength="15" subtype={'indicador'} />
                                </Grid>
                                : null}

                            {this.props.isAtendimentoIA || this.props.isAtendimentoIA180 ?
                                <Grid item xs={12} md={6} className={'paddingForm'}>
                                    <FormField type={'number'} id={'disponibilidade'} label={'Disponibilidade (em %)'} disabled={!(this.canEditIndicadores)}
                                        changeValue={this.changeInput} inputValue={this.disponibilidade} min="0" maxLength="15" subtype={'indicador'} />
                                </Grid>
                                : null}



                            {(this.props.isAtendimentoBP || this.props.isAtendimentoIPEA || this.props.isAtendimentoIA || this.props.isAtendimentoLEAN || this.props.isAtendimentoIA180) ?
                                <Grid container>
                                    <Grid item xs={12} md={6} className={'paddingForm'}>
                                        <FormField type={'number'} id={'qualidade'} label={'Qualidade - Retrabalho/Refugo (em %)'}
                                            changeValue={this.changeInput} inputValue={this.qualidade} min="0" maxLength="15" disabled={!(this.canEditIndicadores)} subtype={'indicador'} />
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <FormField type={'number'} id={'retornoPrograma'} label={'Retorno do Programa S (em meses)'} subtype={'umacasa'} min="0" maxLength="9"
                                            changeValue={this.changeInput} inputValue={this.retornoPrograma} disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                </Grid>
                                : null}

                            {this.props.isAtendimentoIE ?
                                <Grid container>
                                    <Grid item xs={12} md={6} className={'paddingForm'}>
                                        <FormField type={'number'} id={'reducaoConsumo'} label={'Redução de Consumo Energético com Intervenção (em %)'} subtype={'indicador'}
                                            changeValue={this.changeInput} inputValue={this.reducaoConsumo} min="0" maxLength="15" disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormField type={'number'} id={'reducaoCusto'} label={'Redução de Custo com Energia na Intervenção (em %)'} subtype={'indicador'}
                                            changeValue={this.changeInput} inputValue={this.reducaoCusto} min="0" maxLength="15" disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className={'paddingForm'}>
                                        <FormField type={'number'} id={'reducaoConsumoAno'} label={'Redução de Consumo/ano (em MWh/ano)'} subtype={'indicador'}
                                            changeValue={this.changeInput} inputValue={this.reducaoConsumoAno} min="0" maxLength="15" disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormField type={'number'} id={'reducaoCustoAno'} label={'Redução de Custo/ano (em R$)'} subtype={'indicador'}
                                            changeValue={this.changeInput} inputValue={this.reducaoCustoAno} min="0" maxLength="15" disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className={'paddingForm'}>
                                        <FormField type={'number'} id={'emissaoCo'} label={'Emissão de CO2 (em ton CO2)'} subtype={'indicador'}
                                            changeValue={this.changeInput} inputValue={this.emissaoCo} min="0" maxLength="15" disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormField type={'number'} id={'usinaEquivalente'} label={'Usina Equivalente (em MW)'} subtype={'indicador'}
                                            changeValue={this.changeInput} inputValue={this.usinaEquivalente} min="0" maxLength="15" disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                    <Grid item xs={12} md={6} className={'paddingForm'}>
                                        <FormField type={'number'} id={'casaEquivalente'} label={'Casa Equivalente (em Número de casas)'} subtype={'indicador'} min="0" maxLength="15"
                                            changeValue={this.changeInput} inputValue={this.casaEquivalente} disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FormField type={'number'} id={'payback'} label={'Payback (em meses)'} subtype={'umacasa'} min="0" maxLength="9"
                                            changeValue={this.changeInput} inputValue={this.payback} disabled={!(this.canEditIndicadores)} />
                                    </Grid>
                                </Grid>
                                : null}

                            <Grid item xs={12}>
                                <AutoComplete
                                    label={'Colaborador'}
                                    path={'rest/gestao/Colaborador/0/30?buscaLivreColaborador='}
                                    itemKey={'id'}
                                    key='autoCompleteColaboradores'
                                    initialSelectedItem={this.colaborador}
                                    itemlabel={'nome'}
                                    getInput={this.changeColaborador}
                                    disabled={!(this.canEditIndicadores)} />
                            </Grid>
                            {this.canEditIndicadores ?
                                <Grid item xs={12} md={5}>
                                    <InputSelect
                                        suggestions={this.anexoOptions}
                                        itemKey={'valor'}
                                        itemLabel={'nome'}
                                        label={'Anexos (pdf, doc, docx, xlsx, xls)'}
                                        id={'anexoOption'}
                                        getInput={this.changeAnexoOption}
                                        initialSelectedItem={{ valor: this.anexoOption.valor }} />
                                </Grid>

                                :
                                <Grid item xs={12}>
                                    <p className={'font-blue'}>Anexos</p>
                                </Grid>
                            }
                            {this.canEditIndicadores && this.anexoOption.valor !== 0 ?
                                <Grid item xs={12}>
                                    <label
                                        className={'lightBlue basic-button control-label'}
                                        id="control-label"
                                        htmlFor="uploadfile">Adicionar anexo
                                    </label>

                                    <input
                                        className={'file-input'}
                                        type="file"
                                        id="uploadfile"
                                        name="file"
                                        value={undefined}
                                        onChange={this.uploadAnexo} />
                                </Grid>
                                : null}
                            <Grid item xs={12}>
                                {this.anexoList.map(
                                    (anexo, index) => (
                                        <Chip key={index}
                                            label={<label style={{ display: 'flex', alignItems: 'center' }}>
                                                <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                                                <p>{anexo.nomeArquivo}</p>
                                            </label>}
                                            icon={this.canEditIndicadores ? <Icon onClick={() => this.removeAnexo(anexo)} className={'close'}>clear</Icon> : null} />
                                    ))}
                            </Grid>
                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={this.clearIndicadores} />
                                <GenericButton color={'darkBlue'} label={'Atualizar'} subClass={'basic-button'} click={this.saveIndicadores} disabled={!this.canEditIndicadores} />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
            </Grid>
        )
    }
}