import { TipoInputEnum } from "./tipoInput";

export const InputBMPManufaturaEnum = {
    GanhoFinanceiro: { idProgramaToInput: 37, nome: "Ganho Financeiro Mensal", id_tipo_input: TipoInputEnum.ValorUnico },
    InvestimentoDeAcoes: { idProgramaToInput: 8, nome: "Investimento das ações", id_tipo_input: TipoInputEnum.ValorUnico },
    QuantidadeProduzidaInicial: { idProgramaToInput: 11, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeProduzidaFinal: { idProgramaToInput: 16, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    QuantidadeHorasTurnoInicial: { idProgramaToInput: 12, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeHorasTurnoFinal: { idProgramaToInput: 17, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    NumeroOperadoresTurnoInicial: { idProgramaToInput: 13, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    NumeroOperadoresTurnoFinal: { idProgramaToInput: 18, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    SmartFactory: { idProgramaToInput: 20, nome: "Empresa apta a Categoria Smart Factory?", id_tipo_input: TipoInputEnum.SmartFactory }
};