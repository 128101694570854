import React, { Component } from 'react';
import EncontrosDefault from './encontrosDefault';
import API from '../../comm/API';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtilDigital';
import AtividadeDefaultDigital from './atividadeDefaultDigital';
import AtividadeLinhaDeProducaoDigital from './atividadeLinhaDeProducaoDigital';
import AtendimentosCardDigital from './atendimentosCardDigital';
import AtividadeProcessoProdutivoDigital from './atividadeProcessoProdutivoDigital';
import AtividadeArtefatos from './atividadeArtefatos';
import AtividadeIndicadoresPerformance from './AtividadeIndicadoresPerformance';

export default class MentoriaPraticaBDigital extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'encontros'
    };

    constructor(props) {
        super(props);
        const etapaName = `etapaPratica${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.turma = this.props.turma.nome;
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Prática B';
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);
        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoPratica: undefined,
            etapa,
            atendimentoList,
            clientName: '',
            disabled: false
        };
    };

    getProcessoProdutivoAnexosList = async (processoProdutivo, anexos) => {
        let i = 0
        while(i < anexos.length){
            if (anexos[i].descricao === 'Ata de kickoff') {
                anexos.splice(i,1);
            }
            else {
                i++;
            }
        }
        let list = anexos;
        console.log(list);
        list = list.concat(processoProdutivo.kickoff);
        console.log(list);
        return await list;
    }

    getArtefatosList = async (artefatos) => {
        let list = [];
        list = list.concat(artefatos.registroVisitas); 
        list = list.concat(artefatos.imagensAntes); 
        list = list.concat(artefatos.imagensDepois);
        return await list;
    };

    getIndicadoresList= async (indicadores) =>{
        let list = [];
        list = list.concat(indicadores.avaliacao);
        return await list;
    }

    getLinhadeProducaoModel(){
        return { status: 1, ordemVisita: null, anexoLinhaProducaoList: [], linhaProducaoIntervencao: '', linhaProducaoLean: '', processoManufatura: '', producaoSeriada: null,
                linhaMonitorada: '', wifiEstavel: null, temResponsavelMINA: null, temAparelhoMINA: null, acompanhamentoProdutividade: '', acompanhamentoProducao: '',sabeMotivosParadaDeLinha: null,
                trataMotivosParadaDeLinha: null };
    }

    getProcessoProdutivoModel(){
        return { status: 1, ordemVisita: null, anexoProcessoProdutivoList: [], kickoff: [], quantidadeFuncionarios: '', faturamento: '', pontuacaoMaturidade: '', possuiERP: '',
                qualERP: '', metodoContagemProducao: '', descricao: '', acompanhamentoProdutividade: '', acompanhamentoProducao: '', sabeMotivosParadaDeLinha: null,trataMotivosParadaDeLinha: null,
                metodoMonitoramento: null,  };
    }

    getArtefatosInstalacaoModel(){
        return { status: 1, ordemVisita: null, anexoArtefatosInstalacaoList: [], registroVisitas: [], artefatoKitDigital:{id: null, dataCessaoSensor: null, sensor: null, dataCessaoColetor: null,
        coletor: null, dataCessaoMina: null, mina: null, kitMontagem: null, local: null}, instalacaoDigital: {id: null, coletorDigitalList: [], instaladorDigital: { id: null, nome: null,
        email: null}, dataInstalacao: null, valorTotal: null, pontosColeta: null, numeroSensores: null, numeroColetores: null, observacoesInfraestrutura: null}, tipoModelo: 1 , imagensAntes:[], imagensDepois: [],
         };
    }

    getIndicadoresModel(){
        return { status: 1, ordemVisita: null, anexoIndicadoresList: [], produtividadeDigital: {id: null, operadoresTurno: '', horasTurno: '', horasParadas: '', prodNaoConforme: '',
            prodRealizada: '', taxaProducao: '', tempoOperacao: '', prodPossivel: '', prodBoa: '', medicao: ''}, maturidadeDigital: { id: null, dataAvaliacao: '', nivel: '', pontuacaoTotal: '',
            pontuacaoEstrategia: '',    pontuacaoManufatura: '', pontuacaoNegocio: '', pontuacaoCultura: '', anexoMaturidadeDigitalList: []}};
    }

    

    getModels = () => {
        return {
            processoProdutivoDigital: this.getProcessoProdutivoModel(),
            linhaDeProducaoDigital: this.getLinhadeProducaoModel(),
            artefatosInstalacaoDigital: this.getArtefatosInstalacaoModel(),
            indicadoresDigital: this.getIndicadoresModel(),
        }
    }

    filterAnexos = (el) => {
        return el.descricao != 'Ata de kickoff';
    }

    validateLinhadeProducao(linhadeProducao){
        return  linhadeProducao.anexoLinhaProducaoList && linhadeProducao.linhaProducaoIntervencao && linhadeProducao.linhaProducaoLean && linhadeProducao.processoManufatura &&
        linhadeProducao.producaoSeriada != null && linhadeProducao.linhaMonitorada && linhadeProducao.wifiEstavel != null && linhadeProducao.temResponsavelMINA != null && linhadeProducao.temAparelhoMINA != null && 
        linhadeProducao.acompanhamentoProdutividade && linhadeProducao.acompanhamentoProducao && linhadeProducao.sabeMotivosParadaDeLinha != null && linhadeProducao.trataMotivosParadaDeLinha != null &&
        linhadeProducao.anexoLinhaProducaoList.every(elm => { return elm.descricao });
    }

    validateProcessoProdutivo(processoProdutivo){
        return  processoProdutivo.anexoProcessoProdutivoList.filter(el => { return el.descricao != 'Ata de kickoff' }).length > 0 && processoProdutivo.quantidadeFuncionarios && processoProdutivo.faturamento  &&
        processoProdutivo.possuiERP != null && (processoProdutivo.possuiERP != null && (!processoProdutivo.possuiERP || processoProdutivo.qualERP)) && processoProdutivo.metodoContagemProducao  && processoProdutivo.acompanhamentoProdutividade &&
        processoProdutivo.acompanhamentoProducao && processoProdutivo.sabeMotivosParadaDeLinha != null && processoProdutivo.trataMotivosParadaDeLinha != null && processoProdutivo.metodoMonitoramento != null &&
        processoProdutivo.kickoff.length > 0 && processoProdutivo.anexoProcessoProdutivoList.filter(el => { return el.descricao != 'Ata de kickoff' }).every(elm => { return elm.descricao }) && processoProdutivo.descricao;
    }

    //editar dps para adicionar condicionais das datas
    validateArtefatos(artefatos){
        return artefatos.anexoArtefatosInstalacaoList && artefatos.artefatoKitDigital.sensor && artefatos.artefatoKitDigital.coletor && artefatos.artefatoKitDigital.mina && artefatos.artefatoKitDigital.kitMontagem &&
        (!(artefatos.artefatoKitDigital.coletor == "AQUISICAO VIA SENAI (CESSAO 20 SEMANAS)" || artefatos.artefatoKitDigital.coletor == "AQUISICAO VIA SENAI (CESSAO 12 MESES)") || artefatos.artefatoKitDigital.dataCessaoColetor) &&
        (!(artefatos.artefatoKitDigital.mina == "AQUISICAO VIA SENAI (LICENCA DE 20 SEMANAS)" || artefatos.artefatoKitDigital.mina == "AQUISICAO VIA SENAI (LICENCA DE 12 MESES)") || artefatos.artefatoKitDigital.dataCessaoMina) &&
        artefatos.artefatoKitDigital.local && artefatos.instalacaoDigital.coletorDigitalList && artefatos.instalacaoDigital.coletorDigitalList.length > 0 && artefatos.instalacaoDigital.instaladorDigital.nome &&
        artefatos.instalacaoDigital.instaladorDigital.email && artefatos.instalacaoDigital.dataInstalacao && artefatos.instalacaoDigital.valorTotal && artefatos.instalacaoDigital.pontosColeta &&
        artefatos.instalacaoDigital.numeroSensores && artefatos.instalacaoDigital.numeroColetores && artefatos.instalacaoDigital.observacoesInfraestrutura && artefatos.anexoArtefatosInstalacaoList.every(elm => { return elm.descricao }) &&
        artefatos.registroVisitas.length > 0 && artefatos.imagensAntes.length > 0 && artefatos.imagensDepois.length > 0;
    }

    validateIndicadores(indicadores){
        return indicadores.produtividadeDigital && indicadores.produtividadeDigital.operadoresTurno && indicadores.produtividadeDigital.horasTurno  &&
        indicadores.produtividadeDigital.prodRealizada && indicadores.produtividadeDigital.medicao && indicadores.maturidadeDigital && 
        indicadores.maturidadeDigital.dataAvaliacao && indicadores.maturidadeDigital.nivel && indicadores.maturidadeDigital.pontuacaoTotal && indicadores.maturidadeDigital.pontuacaoEstrategia &&
        indicadores.maturidadeDigital.pontuacaoManufatura && indicadores.maturidadeDigital.pontuacaoNegocio && indicadores.maturidadeDigital.pontuacaoCultura && indicadores.maturidadeDigital.anexoMaturidadeDigitalList
        && indicadores.maturidadeDigital.anexoMaturidadeDigitalList.length > 0 
    }
    

    updateViewData = (atendimento) => {

        let index = this.state.turma.atendimentoTurmaList.findIndex(obj => { return obj.atendimento.id === atendimento.idAtendimento });
        this.setState({disabled:this.state.turma.atendimentoTurmaList[index].atendimento.relatorioFinal != null  || this.state.turma.status === 3});

        this.viewData.encontros.data = atendimento.encontroList;
        const { processoProdutivoDigital, linhaDeProducaoDigital, artefatosInstalacaoDigital, indicadoresDigital } = atendimento;
        let turma = this.props.turma 
        
        this.viewData.atividades.processoProdutivoDigital.data =  JSON.parse(JSON.stringify(processoProdutivoDigital || this.getProcessoProdutivoModel()));
        this.viewData.atividades.processoProdutivoDigital.anexos.data = getByKeyOrEmpty(processoProdutivoDigital, 'anexoProcessoProdutivoList');

        if(!processoProdutivoDigital){
            if(turma.etapaAlinhamentoB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).processoProdutivoDigital){
                this.viewData.atividades.processoProdutivoDigital.data = JSON.parse(JSON.stringify(turma.etapaAlinhamentoB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).processoProdutivoDigital));
                this.viewData.atividades.processoProdutivoDigital.anexos.data = getByKeyOrEmpty(turma.etapaAlinhamentoB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).processoProdutivoDigital, 'anexoProcessoProdutivoList');
                this.viewData.atividades.processoProdutivoDigital.data.status = 2;
            }
        }
        
        this.viewData.atividades.linhaDeProducaoDigital.data = JSON.parse(JSON.stringify(linhaDeProducaoDigital || this.getLinhadeProducaoModel()));
        this.viewData.atividades.linhaDeProducaoDigital.anexos.data = getByKeyOrEmpty(linhaDeProducaoDigital, 'anexoLinhaProducaoList');

        if(!linhaDeProducaoDigital){
            if(turma.etapaAlinhamentoB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).linhaDeProducaoDigital){
                this.viewData.atividades.linhaDeProducaoDigital.data = JSON.parse(JSON.stringify(turma.etapaAlinhamentoB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).linhaDeProducaoDigital));
                this.viewData.atividades.linhaDeProducaoDigital.anexos.data = getByKeyOrEmpty(turma.etapaAlinhamentoB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).linhaDeProducaoDigital, 'anexoLinhaProducaoList');
                this.viewData.atividades.linhaDeProducaoDigital.data.status = 2;
            }
        }

        this.viewData.atividades.artefatosInstalacaoDigital.data =  JSON.parse(JSON.stringify(artefatosInstalacaoDigital || this.getArtefatosInstalacaoModel()));
        this.viewData.atividades.artefatosInstalacaoDigital.anexos.data = getByKeyOrEmpty(artefatosInstalacaoDigital, 'anexoArtefatosInstalacaoList');

        this.viewData.atividades.indicadoresDigital.data =  JSON.parse(JSON.stringify(indicadoresDigital || this.getIndicadoresModel()));

    };

    validaAtendimentoPratica = async (atendimentoPratica) => {  
        let encontroList = atendimentoPratica.encontroList;
        let processoProdutivoDigital = atendimentoPratica.processoProdutivoDigital;
        let linhaDeProducaoDigital = atendimentoPratica.linhaDeProducaoDigital;
        let artefatosInstalacaoDigital = atendimentoPratica.artefatosInstalacaoDigital;
        let indicadoresDigital = atendimentoPratica.indicadoresDigital;
        if (!atendimentoPratica.cancelado) {
            return await encontroList && encontroList.length > 0 && encontroList.every(encontro => { return this.validaEncontro(encontro) }) &&
            processoProdutivoDigital && processoProdutivoDigital.status === this.STATUS_FINALIZADO && linhaDeProducaoDigital && linhaDeProducaoDigital.status === this.STATUS_FINALIZADO
            && artefatosInstalacaoDigital && artefatosInstalacaoDigital.status === this.STATUS_FINALIZADO && indicadoresDigital && indicadoresDigital.status === this.STATUS_FINALIZADO
                 
        }
        return true;        
    };

    validateEmail = (email) => {      
        return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      }

    validaEncontro = (encontro) => {
        return encontro && encontro.anexoEncontroList && encontro.anexoEncontroList.length > 0 && !encontro.anexoEncontroList.filter(anx => { return !anx.isDocumento }).some(anx => { return anx.descricao === '' }) &&
        encontro.anexoEncontroList.some(anx => { return anx.descricao === 'lista de presença' && anx.isDocumento }) && encontro.data && encontro.isVirtual != null && encontro.turno != null  && encontro.idMentor != null && encontro.horasApropriadas > 0;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoPratica = this.state.atendimentoPratica;
        let turma = this.props.turma;
        let validado = await this.validaAtendimentoPratica(atendimentoPratica);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoPratica.finalizado = true;
            let indexAtendimento = turma.etapaPraticaB.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
            turma.etapaPraticaB.atendimentoList[indexAtendimento] = atendimentoPratica; //atualiza atendimentoPratica na etapa alinhamento da turma
            let etapa = turma.etapaPraticaB;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaPraticaB;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.props.closeLoading();
                        this.setState({ turma, etapa, atendimentoList, atendimentoPratica: atd }, this.goBackAtendimentos());
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    validaAtividade = async (viewData, atendimentoPratica, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoPratica[nomeAtividade] ? atendimentoPratica[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo && nomeAtividade === 'processoProdutivoDigital') atividade[nomeAnexo] = await this.getProcessoProdutivoAnexosList(atividade, viewData.selected.atividade.anexos.data);
        else if (nomeAnexo && nomeAtividade === 'artefatosInstalacaoDigital') {
            if(!this.validateEmail(atividade.instalacaoDigital.instaladorDigital.email)){
                this.props.showToast('E-mail do instalador não é válido', 'error');
                throw new Error();
            }
            atividade[nomeAnexo] = await this.getArtefatosList(atividade, viewData.selected.atividade.anexos.data);
            atividade.artefatoKitDigital.sensor = atividade.artefatoKitDigital.sensor == "OUTRO" ? atividade.sensorOutros : atividade.artefatoKitDigital.sensor;
            atividade.artefatoKitDigital.coletor = atividade.artefatoKitDigital.coletor == "OUTRO" ? atividade.coletorOutros : atividade.artefatoKitDigital.coletor;
            atividade.artefatoKitDigital.mina = atividade.artefatoKitDigital.mina == "OUTRO" ? atividade.minaOutros : atividade.artefatoKitDigital.mina;
            atividade.artefatoKitDigital.kitMontagem = atividade.artefatoKitDigital.kitMontagem == "OUTRO" ? atividade.kitOutros : atividade.artefatoKitDigital.kitMontagem;}
        else if(nomeAnexo && nomeAtividade === 'indicadoresDigital'){
            atividade.maturidadeDigital.anexoMaturidadeDigitalList = await this.getIndicadoresList(atividade);
        }
        else if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoPratica[nomeAtividade] = atividade;
    };
    
    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoPratica = this.state.atendimentoPratica;
        let encontrosData = viewData.encontros.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;
            console.log(viewData.selected.atividade);
            if (currScreenAtividade === 'linhaDeProducaoDigital') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'linhaDeProducaoDigital', 'anexoLinhaProducaoList', this.validateLinhadeProducao, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'processoProdutivoDigital') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'processoProdutivoDigital', 'anexoProcessoProdutivoList', this.validateProcessoProdutivo, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'artefatosInstalacaoDigital') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'artefatosInstalacaoDigital', 'anexoArtefatosInstalacaoList', this.validateArtefatos, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'indicadoresDigital') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'indicadoresDigital', 'anexoIndicadoresList', this.validateIndicadores, this.STATUS_FINALIZADO);
            
            if (selectedVisita) {
                selectedVisita.anexoEncontroList = [];
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.imagens != null ? selectedVisita.imagens : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaEncontro(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = encontrosData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoPratica.encontroList[index] = selectedVisita;
                else atendimentoPratica.encontroList.push(selectedVisita);
            }
            atendimentoPratica.horasApropriadas = atendimentoPratica.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let atividades = viewData.atividades;
            let linhaDeProducaoDigital = atividades.linhaDeProducaoDigital;
            let processoProdutivoDigital = atividades.processoProdutivoDigital;
            let artefatosInstalacaoDigital = atividades.artefatosInstalacaoDigital;
            let indicadoresDigital = atividades.indicadoresDigital;
            if (!artefatosInstalacaoDigital.data.ordemVisita) atendimentoPratica.artefatosInstalacaoDigital = null;
            if (!indicadoresDigital.data.ordemVisita) atendimentoPratica.indicadoresDigital = null;
            if (!linhaDeProducaoDigital.data.ordemVisita) atendimentoPratica.linhaDeProducaoDigital = null;
            if (!processoProdutivoDigital.data.ordemVisita) atendimentoPratica.processoProdutivoDigital = null;
            atendimentoPratica.encontroList = encontrosData;
            atendimentoPratica.horasApropriadas = atendimentoPratica.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        let indexAtendimento = turma.etapaPraticaB.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
        turma.etapaPraticaB.atendimentoList[indexAtendimento] = atendimentoPratica; //atualiza atendimentoPratica na etapa pratica b da turma
        if(atendimentoPratica.horasApropriadas > 16){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else {return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
            return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                turma = res.data;
                etapa = turma.etapaPraticaB;
                let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                let atd = etapa.atendimentoList[index];
                await this.updateViewData(atd);
                this.setState({ turma, etapa, atendimentoPratica: atd });
                this.props.showToast('Etapa editada com sucesso!', 'success');
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }).catch(error => {
            this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        });}
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = true;
        let showAnexos = true;
        let render = null;
        let border = true;
        let invertAnexos = false;
        console.log(selectedAtividade.screenAtividade);
        switch (selectedAtividade.screenAtividade) {
            case 'linhaDeProducaoDigital':
                render = <AtividadeLinhaDeProducaoDigital atividade={selectedAtividade.data} disabled={this.state.disabled}/>;
                invertAnexos = true;
                border = false;
                break;
            case 'processoProdutivoDigital':
                render = <AtividadeProcessoProdutivoDigital 
                atividade={selectedAtividade.data}
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                turma={ this.state.turma } 
                showToast={ this.props.showToast }
                disabled={this.state.disabled}/>;
                invertAnexos = true;
                border = false;
                break;
            case 'artefatosInstalacaoDigital':
                render = <AtividadeArtefatos atividade={selectedAtividade.data}
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                turma={ this.state.turma } 
                showToast={ this.props.showToast }
                fase={this.props.fase}
                title={selectedAtividade.anexos.title}
                disabled={this.state.disabled}/>;
                showTopButtons = false;
                showAnexos = false;
                break;
            case 'indicadoresDigital':
                render = <AtividadeIndicadoresPerformance atividade={selectedAtividade.data}
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                turma={ this.state.turma } 
                showToast={ this.props.showToast }
                etapa={'B'}
                disabled={this.state.disabled}/>;
                showTopButtons = false;
                showAnexos = false;
                break;

            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefaultDigital
                turma={ this.state.turma }
                fase={ this.props.fase }
                disabled={this.state.disabled}
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                showTopButtons={ showTopButtons && !this.state.disabled}
                showAnexos={ showAnexos }
                invertAnexos={invertAnexos}
                renderChildren={ render }
                border = {border}
                clientName={ this.state.clientName }
                viewData={ this.state.viewData } />
        );
    };

    renderVisita = (atendimento) => {
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoPratica: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoPratica: undefined });
    };

    render() {
        return (<>
            { this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCardDigital
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Mentoria Prática' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita } 
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <EncontrosDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    models={ this.getModels }
                    atendimento={ this.state.atendimentoPratica }
                    etapa = {this.state.etapa}
                    disabled={this.state.disabled}
                    saveEtapa={ this.saveEtapa }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    dontShowFinalizarMentoria={ this.state.atendimentoPratica.finalizado }
                    mentoresDados={this.props.mentoresDados}
                />
            }
        </>)
    };
};