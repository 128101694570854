import React, { Component } from 'react';
import { Grid, Switch } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import FormField from '../formField/formField';

export default class GraphBar extends Component {

	constructor(props) {
		super(props);
        let dataInicio = new Date();
        let dataTermino = new Date();
        dataInicio.setDate(1);
        dataInicio.setMonth(0);
        dataTermino.setDate(31);
        dataTermino.setMonth(11);
		this.state = {
            date: { 
                dataInicio: dataInicio.toISOString().split('T')[0],
                dataTermino: dataTermino.toISOString().split('T')[0]
            },
            errors: { dataInicio: '' },
            flagSwitch: props.init === 'right' ? true : false,
            title: props.init === 'right' ? props.switch[1].title : props.switch[0].title,
            seriesLeft: props.switch[0].series ? props.switch[0].series : [],
            seriesRight: props.switch[1] && props.switch[1].series ? props.switch[1].series : [],
            categoriesLeft: props.switch[0].categories ? props.switch[0].categories : [],
            categoriesRight: props.switch[1] && props.switch[1].categories ? props.switch[1].categories : [],
            fullCategoriesLeft: props.switch[0].fullCategories ? props.switch[0].fullCategories : [],
            fullCategoriesRight: props.switch[1] && props.switch[1].fullCategories ? props.switch[1].fullCategories : [],
            resumeInfosLeft: props.switch[0].resumeInfos ? props.switch[0].resumeInfos : [],
            resumeInfosRight: props.switch[1] && props.switch[1].resumeInfos ? props.switch[1].resumeInfos : [],
        }
        if (this.props.hasFilter || this.props.switch[0].seriesFunction) this.changeInput({ target: {id: 'dataInicio', value: dataInicio.toISOString().split('T')[0] }});
    }

    createGrafico = (index) => {
        let series = index === 0 ? this.state.seriesLeft : this.state.seriesRight;
        let categories = index === 0 ? this.state.categoriesLeft : this.state.categoriesRight;
        let fullCategories = index === 0 ? this.state.fullCategoriesLeft : this.state.fullCategoriesRight;
        let options = {
            chart: {
                type: 'column',
                style: {
                    fontFamily: 'Raleway'
                }
            },
            fullCategories: fullCategories,
            xAxis: {
                lineColor: '#707070',
                lineWidth: 1.5,
                categories: categories,
                labels: {
                    style: {
                        color: '#707070'
                    }
                },
            },
            yAxis: {
                lineColor: '#707070',
                lineWidth: 1.5,
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    style: {
                        color: '#707070'
                    }
                }
            },
            series: series,
            credits: {
                enabled: false
            },
            exporting: false
        };
        if (this.props.switch[index].plotOptions) options.plotOptions = this.props.switch[index].plotOptions;
        if (this.props.switch[index].legend) options.legend = this.props.switch[index].legend;
        if (this.props.switch[index].tickInterval != null) options.yAxis.tickInterval = this.props.switch[index].tickInterval;
        if (this.props.switch[index].tooltip) options.tooltip = this.props.switch[index].tooltip;
        if (this.props.switch[index].plotLines) options.yAxis.plotLines = this.props.switch[index].plotLines;
        if (this.props.switch[index].labelsY) options.yAxis.labels = this.props.switch[index].labelsY;
        if (this.props.switch[index].gridLineWidthY != null) options.yAxis.gridLineWidth = this.props.switch[index].gridLineWidthY;
        if (this.props.switch[index].height != null) options.chart.height = this.props.switch[index].height;
        return options;
    }

    formatData = (date) => {
        date = date + 'T00:00:00';
        let dataList;
        if (typeof (date) !== 'string') {
            dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

    changeInput = async (e) => {
        let dateObj = this.state.date;
        let errors = this.state.errors;
        let flagSwitch = this.state.flagSwitch;
        let seriesLeft = this.state.seriesLeft;
        let seriesRight = this.state.seriesRight;
        let categoriesLeft = this.state.categoriesLeft;
        let categoriesRight = this.state.categoriesRight;
        let fullCategoriesLeft = this.state.fullCategoriesLeft;
        let fullCategoriesRight = this.state.fullCategoriesRight;
        let resumeInfosLeft = this.state.resumeInfosLeft;
        let resumeInfosRight = this.state.resumeInfosRight;
        if (e.target.id === 'dataInicio' || e.target.id === 'dataTermino') {
            dateObj[e.target.id] = e.target.value;
            if (this.formatData(dateObj.dataInicio) > this.formatData(dateObj.dataTermino)) {
                errors['dataInicio'] = 'Data inválida';
            } else {
                errors['dataInicio'] = '';
                let retornoLeft = await this.props.switch[0].seriesFunction(dateObj.dataInicio, dateObj.dataTermino);
                if (retornoLeft[0]) seriesLeft = retornoLeft[0];
                if (retornoLeft[1]) categoriesLeft = retornoLeft[1];
                if (retornoLeft[2]) fullCategoriesLeft = retornoLeft[2]; 
                if (retornoLeft[3]) resumeInfosLeft = retornoLeft[3]; 
                if (this.props.switch.length === 2) {
                    let retornoRight = await this.props.switch[1].seriesFunction(dateObj.dataInicio, dateObj.dataTermino);
                    if (retornoRight[0]) seriesRight = retornoRight[0];
                    if (retornoRight[1]) categoriesRight = retornoRight[1];
                    if (retornoRight[2]) fullCategoriesRight = retornoRight[2];
                    if (retornoRight[3]) resumeInfosRight = retornoRight[3];
                }       
            }
        } else if (e.target.id === 'switch') {
            flagSwitch = !flagSwitch;
        }
        this.setState({ flagSwitch, date: dateObj, errors, seriesRight, seriesLeft, categoriesRight, categoriesLeft, fullCategoriesRight, fullCategoriesLeft, resumeInfosRight, resumeInfosLeft });
    };

    render() {
        let title = this.state.flagSwitch ? this.props.switch[1].title : this.props.switch[0].title;
        let currGraph = this.state.flagSwitch ? this.createGrafico(1) : this.createGrafico(0);
        let resumeInfos = this.state.flagSwitch ? this.state.resumeInfosRight : this.state.resumeInfosLeft;
		return (
            <Grid container justify={'center'} className={this.props.border ? this.props.border : 'border-cinza'}>
                <Grid item xs={12} md={11}>
                    <h1 className={'mapa-do-brasil-title'}>{title}</h1>
                    { resumeInfos && resumeInfos.data && resumeInfos.data.length > 0 && 
                        <div className={'cadastrado-concluido-total'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '15px' }}>
                            <label key={resumeInfos.title}>{resumeInfos.title}:</label>
                            { resumeInfos.data.map(aux => {
                                return (
                                    <label key={aux.title}>{aux.title}: {aux.value.toLocaleString('pt-BR')}{aux.suffix ? aux.suffix : ''}</label>
                                )})}
                            <br />
                        </div> }
                    <div style={{ marginTop: '4%' }}>
                        <HighchartsReact key={title}
                            highcharts={Highcharts}
                            options={currGraph} />
                    </div>
                    <Grid container direction={'row'} justify={'space-between'} spacing={3} style={{ marginTop: '2%', marginBottom: '10px' }} className={'label-atds'}>
                        <Grid item xs={12} md={5} style={{marginTop: '30px'}}>
                        { this.props.switch.length === 2 &&
                            <Grid container spacing={2}>
                                <Grid item><label>{this.props.switch[0].label}</label></Grid>
                                <Grid item style={{ padding: 5}}>
                                    <Switch disabled={this.props.disabledSwitch} id={'switch'} checked={this.state.flagSwitch} onChange={this.changeInput} color='default' />
                                </Grid>
                                <Grid item><label>{this.props.switch[1].label}</label></Grid>
                            </Grid> }
                        </Grid>
                        <Grid item xs={12} md={6}>
                            { this.props.hasFilter && 
                                <Grid container spacing={2} justify={'flex-end'}>
                                    <Grid item xs={6} md={4} xl={3}>
                                        <label>Início</label>
                                        <FormField type={'date'} id={'dataInicio'} inputValue={this.state.date.dataInicio}
                                            changeValue={this.changeInput} error={this.state.errors.dataInicio} icon={'calendar.svg'} disabled={false} />
                                    </Grid>
                                    <Grid item xs={6} md={4} xl={3}>
                                        <label>Término</label>
                                        <FormField type={'date'} id={'dataTermino'} inputValue={this.state.date.dataTermino}
                                            changeValue={this.changeInput} error={this.state.errors.dataTermino} icon={'calendar.svg'} disabled={false} />
                                    </Grid>
                                </Grid> }
                        </Grid> 
                    </Grid>
                </Grid>
            </Grid>
		);
	}
}