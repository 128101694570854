import { TipoInputEnum } from "./tipoInput";

export const InputSmartFactoryEnum = {
    QuantidadeProduzidaInicial: { idProgramaToInput: 21, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeProduzidaFinal: { idProgramaToInput: 29, nome: "Quantidade produzida nas horas trabalhadas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    QuantidadeHorasTurnoInicial: { idProgramaToInput: 22, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QuantidadeHorasTurnoFinal: { idProgramaToInput: 30, nome: "Quantidade de horas por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    NumeroOperadoresTurnoInicial: { idProgramaToInput: 23, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoInicial },
    NumeroOperadoresTurnoFinal: { idProgramaToInput: 31, nome: "Número de operadores por turno", id_tipo_input: TipoInputEnum.MedicaoFinal },
    OEEInicial: { idProgramaToInput: 24, nome: "OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    OEEFinal: { idProgramaToInput: 32, nome: "OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    DisponibilidadeOEEInicial: { idProgramaToInput: 25, nome: "Disponibilidade OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    DisponibilidadeOEEFinal: { idProgramaToInput: 33, nome: "Disponibilidade OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    PerformanceOEEInicial: { idProgramaToInput: 26, nome: "Performance OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    PerformanceOEEFinal: { idProgramaToInput: 34, nome: "Performance OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    QualidadeOEEInicial: { idProgramaToInput: 27, nome: "Qualidade OEE", id_tipo_input: TipoInputEnum.MedicaoInicial },
    QualidadeOEEFinal: { idProgramaToInput: 35, nome: "Qualidade OEE", id_tipo_input: TipoInputEnum.MedicaoFinal },
    ReducaoLeanInicial: { idProgramaToInput: 28, nome: "Redução de Movimentação Lean", id_tipo_input: TipoInputEnum.MedicaoInicial },
    ReducaoLeanFinal: { idProgramaToInput: 36, nome: "Redução de Movimentação Lean", id_tipo_input: TipoInputEnum.MedicaoFinal },
};