import React, { Component } from 'react'
import './treinamentoNovo.scss'
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper'
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API'
import InputSelect from '../../components/inputselect/inputselect';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';

const titles = ['Dados do Treinamento', 'Participantes', 'Revisão']

export default class TreinamentoNovo extends Component {

    treinamento = {
        titulo: "",
        duration: '',
        areaConhecimento: "",
        descricao: "",
        dataInicio: new Date(),
        dataConclusao: new Date(),
        unidade: null,
        treinamentoColaboradorList: []
    };

    errors = {
        titulo: '' ,
        duration: '',
        areaConhecimento: '',
        dataInicio: '',
        dataConclusao: '',
        treinamentoColaboradorList: ''
    };

    areaConhecimentoList = [
        {id: 0, label: 'ENERGIA [FUNTEC] [ENCTI]', group: true},

        {id: 1, type: 'areaConhecimento', label: 'Óleo e Gás [FUNTEC]'},
        {id: 2, type: 'areaConhecimento', label: 'Energias Renováveis'},
        {id: 3, type: 'areaConhecimento', label: 'Biomassa [FUNTEC] [ENCTI]'},
        {id: 4, type: 'areaConhecimento', label: 'Acumuladores de Energia'},

        {id: 5, type: 'areaConhecimento', label: 'MICROELETRÔNICA E TICS',group: true},

        {id: 6, type: 'areaConhecimento', label: 'Semicondutores [FUNTEC]'},
        {id: 7, type: 'areaConhecimento', label: 'Simulação e Emulação'},
        {id: 8, type: 'areaConhecimento', label: 'Big Data'},
        {id: 9, type: 'areaConhecimento', label: 'Cloud & Edge Computing'},
        {id: 10, type: 'areaConhecimento', label: 'Computação Gráfica'},
        {id: 11, type: 'areaConhecimento', label: 'Visão Computacional'},
        {id: 12, type: 'areaConhecimento', label: 'Supercomputação'},
        {id: 13, type: 'areaConhecimento', label: 'Telecomunicações'},
        {id: 14, type: 'areaConhecimento', label: 'Tecnologias de Realidade Estendida'},
        
        {id: 15, type: 'areaConhecimento', label: 'SOCIEDADE E MOBILIDADE', group: true},

        {id: 16, type: 'areaConhecimento', label: 'Economia e Sociedade Digital [ENCTI]'},
        {id: 17, type: 'areaConhecimento', label: 'Ciências e Tecnologias Sociais [ENCTI]'},
        {id: 18, type: 'areaConhecimento', label: 'Cidades Inteligentes'},
        {id: 19, type: 'areaConhecimento', label: 'Defesa, Segurança e Proteção [ENCTI]'},
        {id: 20, type: 'areaConhecimento', label: 'Tecnologias Aeroespaciais  [ENCTI]'},
        {id: 21, type: 'areaConhecimento', label: 'Tecnologias Navais (Subsea)'},
        {id: 22, type: 'areaConhecimento', label: 'Mobilidade e Transportes'},
        {id: 23, type: 'areaConhecimento', label: 'Veículos de Baixo Impacto Ambiental [FUNTEC]'},
        
        {id: 24, type: 'areaConhecimento', label: 'INDÚSTRIA', group: true},

        {id: 25, type: 'areaConhecimento', label: 'IoT: Internet das Coisas'},
        {id: 26, type: 'areaConhecimento', label: 'Sistemas Inteligentes/Cognitivos [FUNTEC]'},
        {id: 27, type: 'areaConhecimento', label: 'Tecnologias e Processos Produtivos'},
        {id: 28, type: 'areaConhecimento', label: 'Robótica (Remota e Autônoma)'},
        {id: 29, type: 'areaConhecimento', label: 'Manufatura Avançada (Indústria 4.0) [FUNTEC]'},
        {id: 30, type: 'areaConhecimento', label: 'Manufatura Aditiva'},
        {id: 31, type: 'areaConhecimento', label: 'Fotônica'},
        {id: 32, type: 'areaConhecimento', label: 'Metrologia Industrial'},
        {id: 33, type: 'areaConhecimento', label: 'Materiais e Superfícies'},
        {id: 34, type: 'areaConhecimento', label: 'Tecnologias Construtivas e Estruturas'},
        {id: 35, type: 'areaConhecimento', label: 'Agricultura de Precisão'},
        {id: 36, type: 'areaConhecimento', label: 'Design for X (ex. Logistics, Environment, ...)'},

        {id: 37, type: 'areaConhecimento', label: 'SAÚDE E MEIO AMBIENTE', group: true},
        {id: 38, type: 'areaConhecimento', label: 'Saúde [ENCTI]'},
        {id: 39, type: 'areaConhecimento', label: 'Nutrição'},
        {id: 40, type: 'areaConhecimento', label: 'Tecnologia de Alimentos [ENCTI]'},
        {id: 41, type: 'areaConhecimento', label: 'Tecnologias Médicas'},
        {id: 42, type: 'areaConhecimento', label: 'Meio Ambiente e Recursos Naturais'},
        {id: 43, type: 'areaConhecimento', label: 'Água [ENCTI]'},
        {id: 44, type: 'areaConhecimento', label: 'Clima [ENCTI]'},
        {id: 45, type: 'areaConhecimento', label: 'Minerais e Minerais Estratégicos [FUNTEC]'},
        {id: 46, type: 'areaConhecimento', label: 'Resíduos e Efluentes'},
        {id: 47, type: 'areaConhecimento', label: 'Desenvolvimento Sustentável'},
        {id: 48, type: 'areaConhecimento', label: 'Mineração Inteligente'},
        {id: 49, type: 'areaConhecimento', label: 'Embalagens'},

        {id: 50, type: 'areaConhecimento', label: 'QUÍMICA E BIOTECNOLOGIA [FUNTEC]', group: true},

        {id: 51, type: 'areaConhecimento', label: 'Química Orgânica e Inorgânica'},
        {id: 52, type: 'areaConhecimento', label: 'Bioeconomia / Bioinformática [ENCTI]'},
        {id: 53, type: 'areaConhecimento', label: 'Economia Circular'},
        {id: 54, type: 'areaConhecimento', label: 'Biologia Molecular'},
        {id: 55, type: 'areaConhecimento', label: 'Microbiologia'},
        {id: 56, type: 'areaConhecimento', label: 'Bioquímica'},
        {id: 57, type: 'areaConhecimento', label: 'Biotecnologia'},
        {id: 58, type: 'areaConhecimento', label: 'Genômica / Engenharia Genética'},
        {id: 59, type: 'areaConhecimento', label: 'Nanotecnologia'},
        
        {id: 60, type: 'areaConhecimento', label: 'OUTROS', group: true},

        {id: 61, type: 'areaConhecimento', label: 'Tecnologias Convergentes e Habilitadoras [ENCTI]'}
    ];
   
    anoList = [];
    currentYear = new Date().getFullYear();

    constructor(props) {
        super(props)
        this.state = {
            user: undefined,
            idColaborador: props.idTreinamento !== undefined ? props.idTreinamento : undefined,
            participantesList: []
        };
        for (var i = this.currentYear; i >= 2000; i--) {
            this.anoList.push({ id: i, type: 'ano', label: i });
        }
        this.getTreinamento();
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getTreinamento = () => {
        if (this.props.idTreinamento !== undefined) {
            API.get('rest/gestao/Treinamento/' + this.props.idTreinamento).then(res => {
                this.treinamento = res.data;
                this.getColaboradorList();
                this.setState(this.state);
            })
        } else {
            if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
                this.treinamento.unidade = this.props.usuarioConectado.colaborador.unidade;
            } else {
                this.treinamento.unidade = this.props.usuarioConectado.unidade;
            }
            this.getColaboradorList();
        }
    }

    getColaboradorList = () => {
        var filter = { 'unidade': this.treinamento.unidade.id };
        API.get('rest/gestao/ColaboradorUnidade/find', { params: filter }).then(res => {
            this.setState({ colaboradorList: res.data });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao carregar lista de colaboradores. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    validateTreinamento = (step) => {
        switch (step) {
            case 0:
                return this.treinamento.titulo && this.treinamento.duration && this.treinamento.areaConhecimento 
                && this.treinamento.dataInicio && this.treinamento.dataConclusao 
                && this.errors.dataInicio === '' && this.errors.dataConclusao === '';
            case 1:
                return this.treinamento.treinamentoColaboradorList.length > 0;
            default:
                return true
        }
    }
    saveTreinamento = () => {
        this.showLoading();
        if (this.treinamento.id === undefined) {
            API.post('rest/gestao/Treinamento', this.treinamento).then(res => {
                this.closeLoading();
                this.showToast('Treinamento cadastrado com sucesso!', 'success', '/cad/pessoa/treinamento');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao cadastrar treinamento. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Treinamento/' + this.treinamento.id, this.treinamento).then(res => {
                this.closeLoading();
                this.showToast('Treinamento editado com sucesso!', 'success', '/cad/pessoa/treinamento');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao editar treinamento. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }
    formatData (date) {
        if (typeof (date) !== 'string') {
            let dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                var dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    }
    changeInput = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'dataInicio') {
                this.treinamento.dataInicio = e.target.value + 'T00:00:00-03:00';
            } else if (e.target.id === 'dataConclusao') {
                this.treinamento.ano = parseInt(e.target.value.split('-')[0]);
                this.treinamento.dataConclusao = e.target.value + 'T23:59:59-03:00';
            } else if (e.target.type === 'text' || e.target.type === 'textarea') {
                this.treinamento[e.target.id] = e.target.value.toUpperCase();
            } else {
                this.treinamento[e.target.id] = e.target.value;
            }  
            if (e.target.required) {
                if (e.target.value == null || e.target.value === '') {
                    this.errors[e.target.id] = 'Campo obrigatório';
                } else {
                    this.errors[e.target.id] = '';
                }
            }
            if (e.target.value === '' && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors[e.target.id] = 'Campo obrigatório';
            } else if (this.formatData(this.treinamento.dataInicio) > this.formatData(this.treinamento.dataConclusao) && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors[e.target.id] = 'Data de início deve ser menor ou igual a data de conclusão';
            } else if (this.formatData(this.treinamento.dataInicio) <= this.formatData(this.treinamento.dataConclusao) && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors['dataConclusao'] = '';
                this.errors['dataInicio'] = '';
            } else if (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio') {
                this.errors[e.target.id] = '';
            }       
        } else if (e != null && e.type != null) {
            this.treinamento[e.type] = e.label;
        } else if (e != null && e !== '') {
            this.treinamento.treinamentoColaboradorList = e;
            if(e.length <= 0){
                this.errors.treinamentoColaboradorList = 'Insira ao menos um colaborador.';
            } else {
                this.errors.treinamentoColaboradorList = '';
            }
        }        
        this.setState(this.state);
    }

    getSteps = () => {
        return [
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={7} xl={4}>
                    <Grid container>
                        <Grid item xs={12}>
                            <FormField type={'text'} label={'Título'} id={'titulo'} inputValue={this.treinamento.titulo} changeValue={this.changeInput}
                                required={'required'} error={this.errors.titulo} />
                        </Grid>
                        <Grid item xs={12} sm={6} className={'paddingForm'}>
                            <FormField type={'date'} label={'Início do Treinamento'} ref='dataInicio' id='dataInicio'
                                inputValue={this.formatData(this.treinamento.dataInicio)} changeValue={this.changeInput} required={'required'} 
                                error={this.errors.dataInicio} icon={'calendar.svg'}
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormField type={'date'} label={'Fim do Treinamento'} ref='dataConclusao' id='dataConclusao'
                                inputValue={this.formatData(this.treinamento.dataConclusao)} changeValue={this.changeInput} required={'required'} 
                                error={this.errors.dataConclusao} icon={'calendar.svg'}
                                />
                        </Grid>
                        <Grid item xs={12} sm={5}  className={'paddingForm'}>
                            <FormField type={'number'} label={'Duração'} id={'duration'} inputValue={this.treinamento.duration} changeValue={this.changeInput}
                                required={'required'} error={this.errors.duration}/>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <InputSelect
                                label={'Área de Conhecimento'}
                                suggestions={this.areaConhecimentoList}
                                itemKey={'label'}
                                itemLabel={'label'}
                                id={'areaConhecimento'}
                                getInput={this.changeInput}
                                initialSelectedItem={{ label: this.treinamento.areaConhecimento}}
                                error={this.errors.areaConhecimento}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField type={'textarea'} label={'Descrição'} id={'descricao'} inputValue={this.treinamento.descricao} changeValue={this.changeInput}/>
                        </Grid>                                                
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={"step"}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <AutoCompleteMany
                        suggestions={this.state.colaboradorList}
                        itemlabel={'nome'}
                        getInput={this.changeInput}
                        itemKey={'id'}
                        label={'Participante(s)'}
                        chipLabel={'Nomes'}
                        id={'nome'}
                        initialOjectSelectedList={this.treinamento.treinamentoColaboradorList}
                        initialOjectSelectedListClass={'colaborador'}
                        error={this.errors.treinamentoColaboradorList}
                    />
                </Grid>
            </Grid>,
            <Grid container className={"step review-step"}>
                <Grid item xs={12} sm={10} md={6} xl={4} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>1</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Dados do Treinamento</label>
                        <Grid container>
                            <Grid item xs={6}>Nome</Grid>
                            <Grid item xs={6}>{this.treinamento.titulo}</Grid>
                            <Grid item xs={6}>Início do Treinamento</Grid>
                            <Grid item xs={6}>{this.formatData(this.treinamento.dataInicio)}</Grid>
                            <Grid item xs={6}>Fim do Treinamento</Grid>
                            <Grid item xs={6}>{this.formatData(this.treinamento.dataConclusao)}</Grid>
                            <Grid item xs={6}>Duração</Grid>
                            <Grid item xs={6}>{this.treinamento.duration}</Grid>
                            <Grid item xs={6}>Área de Conhecimento</Grid>
                            <Grid item xs={6}>{this.treinamento.areaConhecimento}</Grid>
                            <Grid item xs={6}>Descrição</Grid>
                            <Grid item xs={6}>{this.treinamento.descricao}</Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} sm={10} md={6} xl={4} className={'review-div'}>
                    <div className={'review-square'}>
                        <label>2</label>
                    </div>
                    <div className={'review-info'}>
                        <label className={'review-title'}>Participantes</label>
                        <Grid container>
                            <Grid item xs={12}>
                                {this.treinamento.treinamentoColaboradorList.map(
                                    (treinamentoColaborador, index) => (
                                        <p key={index}>{treinamentoColaborador.colaborador.nome}</p>
                                    )
                                )}
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid >]
    }

    
    render() {
        return (
            <Page icon={'doc.svg'} label={'Novo Treinamento'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'novo-treinamento'}>
                    <Stepper
                        titles={titles}
                        steps={this.getSteps()}
                        validation={this.validateTreinamento}
                        sendForm={this.saveTreinamento}
                        history={this.props.history} />
                </Grid>
            </Page>
        );
    }
}