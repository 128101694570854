import React, { Component } from 'react';
import Page from '../../components/page/page';
import {  FormGroup, Grid, Switch, Tooltip } from "@material-ui/core";
import GenericButton from '../../components/genericButton/genericButton';
import Breadcrumb from '../../components/breadcrumb/breadcrumb';
import FormField from './../../components/formField/formField';
import Collapse from './../../components/collapse/collapse';
import CollapseTitle from './../../components/collapseTitle/collapseTitle';
import CollapseItem from './../../components/collapseItem/collapseItem';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SimpleModal from '../../components/modal/modal';
import Table from './../../components/table/table';
import { withStyles} from "@material-ui/core/styles";
import AutoComplete from './../../components/autoComplete/autoComplete';
import API, { BASE_URL } from './../../comm/API';
import { Link } from 'react-router-dom';
import Masks from '../../comm/masks';
import './unidadesMentoriaDigital.scss';

export default class UnidadesBrasilMais extends Component {

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    titles = [
        { id: '1', canOrder: false, label: 'Cliente' },
        { id: '2', canOrder: false, label: 'Unidade Operacional' }]

    titlesTabelaBuscaAvancada = [
        { id: '1', canOrder: false, label: '' },
        { id: '2', canOrder: false, label: '' }];

    titlesNovosClientes = [
        { id: '1', canOrder: false, label: 'N° Cliente' },
        { id: '2', canOrder: false, label: 'CNPJ' },
        { id: '3', canOrder: false, label: 'Empresa' },
        { id: '4', canOrder: false, label: 'Município' },
        { id: '5', canOrder: false, label: 'Bairro' },
        { id: '6', canOrder: false, label: '' }]

    state = {
        flagButton: true, contentCollapse: [], openModal: false, novosClientes: 0,
        cluster: [], clientes: [], titles: this.titles, lines: [], unidade: '', filtroTexto: '',
        contentCollapseColumn1: [], contentCollapseColumn2: [], contentCollapseColumn3: [],
        openModalBuscaAvancada: false, listUnidades: [], moverFlag: true, arquivarFlag: true,
        clientesSwitch: false, openModalNovosClientes: false, hasClientes : false,
        listNovosClientes: [], titlesNovosClientes: this.titlesNovosClientes, 
        hasFilter: false, listArquivar: [], openModalArquivar: false , clustersArquivados:[],
        openModalEnviar: false
    };

    componentDidMount() {
        this.findCluster();
        this.getTotalNovosClientes();
    };

    async changeFlag(a) {
        if (a == 'mover') {

            if (this.state.flagButton) {
                await this.setState({
                    flagButton: !this.state.flagButton,
                    moverFlag: !this.state.moverFlag
                });
            }
            else {
                await this.setState({
                    arquivarFlag: !this.state.arquivarFlag,
                    moverFlag: !this.state.moverFlag
                });
            }
        }
        else if (a == 'arquivar') {
            if (this.state.flagButton) {
                await this.setState({
                    flagButton: !this.state.flagButton,
                    arquivarFlag: !this.state.arquivarFlag
                });
            }
            else {
                await this.setState({
                    arquivarFlag: !this.state.arquivarFlag,
                    moverFlag: !this.state.moverFlag
                });
            }
        }
        this.refreshContent();
    };

    async changeUnidade(e) {
        this.setState({ unidade: e });
    };

    async getTotalNovosClientes() {
        let novosClientes = (await API.get(`/rest/sistema/Cluster/count/${this.props.usuarioConectado.unidade.id}`)).data;
        let listCli = (await API.get(`/rest/sistema/Cluster/getFila/${this.props.usuarioConectado.unidade.id}`)).data;
        this.setState({ novosClientes: novosClientes, listNovosClientes: listCli});
    };

    findCluster = async () => {
        this.showLoading();
        let url = `/rest/sistema/Cluster/findCluster?idRegional=${this.props.usuarioConectado.unidade.id}&abreviacao=${this.state.filtroTexto}`;
        this.state.listUnidades.forEach((uni) => { url = url + `&id=${uni.id}` });
        let listCluster = (await API.get(url)).data;
        let cli = listCluster.some(cluster => {
            return cluster.clusterClienteABDIList.length > 0          
            });
        await this.setState({
            cluster: listCluster, openModalBuscaAvancada: false, openModal: false, openModalNovosClientes: false,
            openModalArquivar: false , openModalEnviar : false , hasClientes : cli
        });
        this.getTotalNovosClientes();
        this.refreshContent();
        this.closeLoading();
    };

    async classificarClientesABDI() {
        let listCluster = (await API.get(`/rest/sistema/Cluster/classificar/${this.props.usuarioConectado.unidade.id}`)).data;
        let cli = listCluster.some(cluster => {
            return cluster.clusterClienteABDIList.length > 0          
            });
        await this.setState({ cluster: listCluster, hasClientes : cli });
        await this.getTotalNovosClientes();
        await this.refreshContent();
        this.closeLoading();
    };

    transformarEmAtendimento = () => {
        this.showLoading();
        API.get('rest/gestao/Atendimento/transformar/' + this.props.usuarioConectado.unidade.id).then(res => {
            if (res != null && res.data != null && (res.data.includes("Não") || res.data.includes("inválido"))) this.showToast('Não há clientes a serem enviados para as unidades no seu regional!', 'error');
            else if (res != null && res.data != null) this.showToast('Clientes enviados para as unidades!', 'success');
            this.findCluster();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao tentar enviar clientes. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    async toggleClientes() {
        await this.setState({ clientesSwitch: !this.state.clientesSwitch });
        this.refreshContent();
    }

    async refreshContent() {
        let array = this.state.cluster.map((cluster) => {
            
            return {
                id: cluster.id, title:
                    <Grid container>
                        <Typography style={{ flexBasis: '100%' }} noWrap>{cluster.unidade.abreviacao}</Typography>
                    </Grid>,
                body:
                    <Grid container>
                        <Grid item xs={12}>
                            {cluster.clusterClienteABDIList.map((cli, index) => {
                                        return (
                                            <div key={'cluster-' + index}>
                                                <CollapseItem title={cli.clienteABDI.cliente.nome}
                                                    lean={false}
                                                    cpfcnpj={(cli.clienteABDI.cliente.cpfcnpj.length > 11 ? Masks.maskByTipoPessoa(cli.clienteABDI.cliente.cpfcnpj, 1) :
                                                        Masks.maskByTipoPessoa(cli.clienteABDI.cliente.cpfcnpj, 0))}
                                                    className={'collapse-container'}
                                                    flagButton={this.state.flagButton}
                                                    isExpanded={this.state.clientesSwitch}
                                                    selector={
                                                        <FormControlLabel
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                            control={<Checkbox onChange={(e) => { this.selecionarCliente(e, cluster.id, cli.clienteABDI.cliente.id) }}
                                                                style={{ visibility: !this.state.flagButton ? 'visible' : 'hidden' }} />}
                                                        />} />
                                            </div>
                                        )
                            })}
                        </Grid>
                    </Grid>
            }
        });


        let contentCollapseColumn1 = [];
        let contentCollapseColumn2 = [];
        let contentCollapseColumn3 = [];

        array.forEach((content, index) => {
            if (index % 3 === 0) {
                contentCollapseColumn1.push(content);
            } else if (index % 3 === 1) {
                contentCollapseColumn2.push(content);
            } else if (index % 3 === 2) {
                contentCollapseColumn3.push(content);
            }
        });

        this.setState({
            contentCollapseColumn1: contentCollapseColumn1,
            contentCollapseColumn2: contentCollapseColumn2, contentCollapseColumn3: contentCollapseColumn3
        })
    };

    closeModal = () => {
        this.setState({ openModal: false, unidade: '', flagButton: true, moverFlag: true }, this.refreshContent);

    };

    closeModalArquivar = () => {
        this.setState({ openModalArquivar: false, flagButton: true, arquivarFlag : true }, this.refreshContent);
    }

    closeModalBuscaAvancada = () => {
        this.setState({ listUnidades: [], openModalBuscaAvancada: false });
    };

    closeModalNovosClientes = () => {
        this.setState({ openModalNovosClientes: false });
    }

    closeModalEnviar = () => {
        this.setState({ openModalEnviar: false });
    }

    moverClientes = async () => {
        this.showLoading();
        let clientes = { cluster: this.state.unidade.id, clienteMentoriaLeanList: this.state.lines.map(e => { return e[0] }).join(',') };
        await API.post(`/rest/sistema/Cluster/mover/${this.props.usuarioConectado.unidade.id}`, clientes).then(async res => {
            await this.setState({ flagButton: true , moverFlag: true }, this.findCluster);
            this.showToast('Clientes movidos com sucesso!', 'success');
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao tentar mover clientes. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    arquivarClientes = async () => {
        this.showLoading();
        let clientes = { clusterList: this.state.cluster.map(e => { return e.id }).join(','), clienteMentoriaLeanList: this.state.lines.map(e => { return e[0] }).join(',') };
        await API.post(`/rest/sistema/Cluster/arquivar/${this.props.usuarioConectado.unidade.id}`, clientes).then(async res => {
            await this.setState({ flagButton: true, arquivarFlag: true }, this.findCluster);
            this.showToast('Arquivamento realizado com sucesso!', 'success');
        }).catch(error => {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao tentar arquivar clientes. Por favor, entre em contato com o suporte.', 'error');
        });
    };

    selecionarCliente(e, idCluster, idCliente) {
        let cluster = this.state.cluster;
        cluster.forEach(cluster => {
            cluster.clusterClienteABDIList.forEach(cli =>{
                if (cli.clienteABDI.cliente.id === idCliente) {  
                    cli.clienteABDI.cliente.selected = e.target.checked;
            }
            });            
        });
        this.setState({ cluster: cluster });
    };
    async handleModal(a) {
        let lines = [];
        let nomes = [];
        

        this.state.cluster.forEach(cluster => {
            cluster.clusterClienteABDIList.forEach(cli =>{
                if (cli.clienteABDI.cliente.selected) {
                    
                            lines.push([cli.clienteABDI.cliente.id,
                                <div>
                                    <p>
                                        {(cli.clienteABDI.cliente.cpfcnpj.length > 11 ? Masks.maskByTipoPessoa(cli.clienteABDI.cliente.cpfcnpj, 1) :
                                            Masks.maskByTipoPessoa(cli.clienteABDI.cliente.cpfcnpj, 0))} - {cli.clienteABDI.cliente.razaoSocial}
                                    </p>
                                </div>
                                ,
                                cluster.unidade.abreviacao]);
                            nomes.push(cli.clienteABDI.cliente.razaoSocial);
                };
            });            
        });

        await this.setState({ lines: lines, listArquivar: nomes });

        if (this.state.lines.length === 0) {
            if (a == 'mover') {
                if (this.state.flagButton) {
                    await this.setState({
                        flagButton: !this.state.flagButton,
                        moverFlag: !this.state.moverFlag
                    });
                }
                else {
                    await this.setState({
                        flagButton: !this.state.flagButton,
                        moverFlag: !this.state.moverFlag
                    });
                }
            }
            else if (a == 'arquivar') {
                if (this.state.flagButton) {
                    await this.setState({
                        flagButton: !this.state.flagButton,
                        arquivarFlag: !this.state.arquivarFlag
                    });
                }
                else {
                    await this.setState({
                        flagButton: !this.state.flagButton,
                        arquivarFlag: !this.state.arquivarFlag
                    });
                }
            }
            this.refreshContent();
        } else {
            if (a == 'mover') {
                this.refreshContent();
                this.setState({ openModal: true });
            }
            else {
                this.refreshContent();
                this.setState({ openModalArquivar: true });
            }
            
        }
    };

    getLinesNovosClientes() {
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);

        let list = this.state.listNovosClientes.map((item, index) => {            
            
            return [item[1], item[1],
                (item[2].length > 11 ? Masks.maskByTipoPessoa(item[2], 1) :
                    Masks.maskByTipoPessoa(item[2], 0)),
                item[3], item[5], item[4],
                    <div>
                        {item[0] ? null : 
                            <ModifiedTooltip title="Nenhuma UO atende a região do cliente" placement="top" arrow>
                                <img src={process.env.PUBLIC_URL + '/assets/icons/alertRed.svg'}
                                    width={'18px'}
                                    height={'18px'} />
                            </ModifiedTooltip>}
                        </div>]; });

        return list;
    }

    addUnidade(e) {
        let listUnidades = this.state.listUnidades;
        listUnidades.push(e);
        this.setState({ listUnidades: listUnidades });
    };

    getLinesTable() {
        let list = this.state.listUnidades.map((item, index) => {
            return [item.id, item.abreviacao,
            <GenericButton color={'transparent'} click={(e) => { this.deleteUnidade(index) }} subClass={'icon-and-button'} icon={'trash.svg'} />];
        });
        return list;
    };

    deleteUnidade(index) {
        let list = this.state.listUnidades;
        list.splice(index, 1);
        this.setState({ listUnidades: list });
    };

    downloadRelatorio = () => {
        this.showLoading();

        let queryParams = '';

        this.state.listUnidades.forEach((uni) => {
            queryParams = queryParams + `&id=${uni.id}`;
        });
        
        try {
            let path = `rest/gestao/RelatorioTurmaLean/getSetupUO?idRegional=${this.props.usuarioConectado.unidade.id}${queryParams}`;
            console.log(path);
            window.open(BASE_URL + path);
            this.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }
    };

    downloadRelatorioNovosClientes = () => {
        try {
            this.showLoading();
            let path = `rest/gestao/RelatorioTurmaLean/getSetupUO/NovosClientes/${this.props.usuarioConectado.unidade.id}`;
            console.log(path);
            window.open(BASE_URL + path);
            this.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.closeLoading();
            this.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }
    }
   

    render() {

        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);

        return (
            <Page icon={'brasil-mais.svg'} label={'Mentoria Lean'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container id={'unidades-mentoria-digital'}>
                    <Grid container spacing={1} className={'top-container'} justify={'space-between'}>
                        <Grid item>
                            <Grid container direction={'row'} justify={'flex-start'}>
                                <Grid item>
                                    <Link to={'/brasilmais/mentorialean'} style={{ cursor: "pointer" }}>
                                        <Breadcrumb label={'Gerenciar Turmas'} icon={'breadcrumb-blue.svg'} color={'primary'} />
                                    </Link>
                                </Grid>
                                <Grid item><Breadcrumb label={'Setup UO\'s'} icon={'breadcrumb-gray.svg'} color={'secondary'} /></Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={8}>
                            <Grid container direction={'row'} justify={'flex-end'} alignItens ={'center'} spacing={1}>
                                <Grid item sm={5} xl={4}>
                                    <FormField type={'text'} pressEnter={this.findCluster} placeholder={'Selecione uma Unidade de atendimento Brasil +'}
                                        button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={this.findCluster}
                                        disabled={!this.state.hasClientes}/>}
                                        inputValue={this.setState.filtroTexto} changeValue={(e) => { this.setState({ filtroTexto: e.target.value }); }} />
                                </Grid>
                                <Grid item>

                                    <Grid className={'botao'}>
                                        <GenericButton color={'lightBlue'} label={'Busca Avançada'} subClass={'basic-button with-icon'}
                                            disabled={!this.state.hasClientes} 
                                            click={(e) => { this.setState({ openModalBuscaAvancada: true }) }} icon={process.env.PUBLIC_URL + 'filter.svg'} />
                                        {this.state.hasFilter === true &&
                                            <GenericButton color={'white'} subClass={'icon-button'} click={() => { this.resetFields(); this.findCluster(); }} icon={'close.svg'} />
                                            }
                                    </Grid>

                                </Grid>
                                <Grid item>
                                    {this.state.moverFlag &&
                                        <GenericButton color={'lightBlue'} label={'Mover'} subClass={'basic-button with-icon '}
                                        disabled={!this.state.hasClientes}
                                        click={(e) => { this.changeFlag('mover'); }} icon={process.env.PUBLIC_URL + 'mover.svg'} />}
                                    {!this.state.moverFlag &&
                                        <GenericButton color={'orange'} label={'Mover'} subClass={'basic-button with-icon'}
                                        disabled={!this.state.hasClientes} 
                                        click={(e) => { this.handleModal('mover'); }} icon={process.env.PUBLIC_URL + 'mover.svg'} />}
                                </Grid>
                                <Grid item>
                                    {this.state.arquivarFlag &&
                                        <GenericButton color={'lightBlue'} label={'Arquivar'} subClass={'basic-button with-icon'}
                                            disabled={!this.state.hasClientes}
                                            click={(e) => { this.changeFlag('arquivar'); }} icon={process.env.PUBLIC_URL + 'arquivar.svg'} />}
                                    {!this.state.arquivarFlag &&
                                        <GenericButton color={'orange'} label={'Arquivar'} subClass={'basic-button with-icon'}
                                        disabled={!this.state.hasClientes}
                                            click={(e) => { this.handleModal('arquivar'); }} icon={process.env.PUBLIC_URL + 'arquivar.svg'} />}

                                </Grid>
                                <Grid item  >
                                    <Link to={'/brasilmais/configuracao'}>

                                        <GenericButton color={'lightBlue'} label={'Configurações UO\'s'} subClass={'basic-button with-icon'}

                                            click={(e) => { }} icon={process.env.PUBLIC_URL + 'config1.svg'} />
                                    </Link>
                                </Grid>
                                <Grid item  >
                                    <ModifiedTooltip title={'Baixar relatório'} placement="top-start" arrow onClick={this.downloadRelatorio}>
                                        <img style={{ cursor: "pointer" }}
                                            src={process.env.PUBLIC_URL + '/assets/icons/download-darkblue.svg'}
                                            width={'25px'}
                                            height={'25px'} />
                                    </ModifiedTooltip>
                                </Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                    <Grid container spacing={1} className={'header-campos'}>
                        <Grid item xs={12} sm={1}>
                            <Grid container direction={'column'} justify={'center'} className={this.state.novosClientes > 0 ? 'card-green' : 'card-orange'}
                                onClick={this.state.novosClientes > 0 ? (e) => { this.setState({ openModalNovosClientes: true })}  : (e) => {} }>
                                <Grid item className={'font-bigger'}>{this.state.novosClientes}</Grid>
                                <Grid item>Novo(s)<br/>cliente(s)</Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={6} sm={2} >
                            <Grid container spacing={1} direction={'column'} justify={'space-between'} alignItems={'flex-start'} className={'vincular-container'} >
                                <Grid item>
                                    <ModifiedTooltip title="Clientes arquivados" placement="top-start" arrow>
                                        <Link to={'/brasilmais/unidadesoperacionais/arquivados'}>
                                            <img style={{ cursor: "pointer" }}
                                                src={process.env.PUBLIC_URL + '/assets/icons/arquivar-blue.svg'}
                                                width={'26px'}
                                                    height={'26px'} />
                                        </Link>
                                    </ModifiedTooltip>
                                </Grid>
                                <Grid item>
                                    <Grid container spacing={1} direction={'column'} justify={'flex-end'} className={'header-campos'}>
                                        <Grid item>
                                            <GenericButton color={'darkBlue'} label={'Vincular à UO'} subClass={'basic-button vincular-botao'} disabled={this.state.novosClientes === 0}
                                                click={(e) => { this.showLoading(); this.classificarClientesABDI() }} />
                                        </Grid>
                                        <Grid item>
                                            <GenericButton color={'darkBlue'} label={'Enviar para UO'} subClass={'basic-button enviar-botao'}
                                                disabled={!this.state.hasClientes} click={(e) => { this.setState({ openModalEnviar: true }) }} />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} alignItems={'center'} justify={'flex-end'} className={'switch-container'}>
                        <Grid item> <label> Resumir </label> </Grid>
                        <Grid item style={{ padding: 5 }}>
                            <Switch disabled={false} checked={this.state.clientesSwitch} onChange={() => { this.toggleClientes() }} color="default" />
                        </Grid>
                        <Grid item> <label> Expandir </label> </Grid>

                    </Grid>
                    <Grid container className={'collapse-container'}>
                        <Grid item xs={4}>
                            <CollapseTitle content={this.state.contentCollapseColumn1} isExpanded={true} />
                        </Grid>
                        <Grid item xs={4}>
                            <CollapseTitle content={this.state.contentCollapseColumn2} isExpanded={true} />
                        </Grid>
                        <Grid item xs={4}>
                            <CollapseTitle content={this.state.contentCollapseColumn3} isExpanded={true} />
                        </Grid>
                    </Grid>
                </Grid>
                <SimpleModal
                    isOpen={this.state.openModal}
                    disableClick={true}
                    handleClose={() => this.closeModal()}
                    type={'info'}
                    width={'700px'}>
                    <div className={'modal modal-mover'}>
                        <h2>Mover atendimento</h2>
                        <Grid container>
                            <Grid item xs={12}>
                                <Table lines={this.state.lines} titles={this.state.titles} initialOrder={'0'} pagination={false} />
                            </Grid>
                            <Grid item xs={12}>
                                <AutoComplete itemKey={'id'} initialSelectedItem={this.state.unidade}
                                    itemlabel={'abreviacao'} key='autoCompleteUnidade' label={'Escolha outra Unidade Operacional'}
                                    getInput={(e) => { this.changeUnidade(e) }} path={`/rest/sistema/Cluster/unidade/${this.props.usuarioConectado.unidade.id}?abreviacao=`} />
                            </Grid>
                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton
                                    color={'darkGrey-outline'}
                                    label={'Cancelar'}
                                    subClass={'basic-button'}
                                    click={this.closeModal} />
                                <GenericButton
                                    color={'darkBlue'}
                                    label={'Mover'}
                                    subClass={'basic-button'}
                                    click={this.moverClientes} />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
                <SimpleModal
                    className={''}
                    isOpen={this.state.openModalBuscaAvancada}
                    disableClick={true}
                    handleClose={this.closeModalBuscaAvancada}
                    type={'info'}
                    width={'700px'}>
                    <div className={'modal modal-avancada'}>
                        <h2>Busca avançada</h2>
                        <Grid container>
                            <Grid item xs={12}>
                                <AutoComplete itemKey={'id'} itemlabel={'abreviacao'} key='autoCompleteUnidadeBuscaAvancada' label={'Unidade Operacional'} initialSelectedItem={''}
                                    getInputButton={(e) => { this.addUnidade(e) }} button={true} path={`/rest/sistema/Cluster/unidade/${this.props.usuarioConectado.unidade.id}?abreviacao=`} />
                            </Grid>
                            <Grid item xs={12}>
                                <Table lines={this.getLinesTable()} titles={this.titlesTabelaBuscaAvancada} initialOrder={'9'} pagination={false} />
                            </Grid>
                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton
                                    color={'darkGrey-outline'}
                                    label={'Cancelar'}
                                    subClass={'basic-button'}
                                    click={this.closeModalBuscaAvancada} />
                                <GenericButton
                                    color={'darkBlue'}
                                    label={'Buscar'}
                                    subClass={'basic-button'}
                                    click={() => { this.findCluster() }} />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
                <SimpleModal
                    className={''}
                    isOpen={this.state.openModalNovosClientes}
                    disableClick={true}
                    handleClose={this.closeModalNovosClientes}
                    type={'info'}
                    width={'1100px'}>
                    <div className={'modal modal-novosclientes'}>
                        <h2>Novos Clientes</h2>
                        <Grid container>
                            <Grid item xs={12}>
                                <Table lines={this.getLinesNovosClientes()} titles={this.state.titlesNovosClientes} initialOrder={'0'} pagination={false} />
                            </Grid>
                            <Grid item xs={12} className={'botao-relatorio'}>
                                <GenericButton  color={'darkBlue'} label={'Baixar relatório'} subClass={'basic-button with-icon'}
                                    click={this.downloadRelatorioNovosClientes} icon={process.env.PUBLIC_URL + 'download-white.svg'} />
                            </Grid>
                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton
                                    color={'darkBlue-outline'}
                                    label={'Cancelar'}
                                    subClass={'basic-button'}
                                    click={this.closeModalNovosClientes} />
      
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
                <SimpleModal
                    isOpen={this.state.openModalArquivar}
                    handleClose={this.closeModalArquivar}
                    disableClick={true}
                    type={'success'}
                    width={'380px'}
                    className={'evento-modal'}>
                    <label>Voce confirma o arquivamento do(s) cliente(s) </label>
                    <label className={'bold'}>{this.state.listArquivar.join(", ")}</label>
                    <div className={'rodape'}>
                        <GenericButton color={'darkBlue'} label={'Cancelar'} subClass={'basic-button'} click={this.closeModalArquivar} />
                        <GenericButton color={'darkGrey-outline'} label={'Arquivar'} subClass={'basic-button'} click={this.arquivarClientes} />
                    </div>
                </SimpleModal>
                <SimpleModal
                    isOpen={this.state.openModalEnviar}
                    handleClose={this.closeModalEnviar}
                    disableClick={true}
                    type={'success'}
                    width={'380px'}
                    className={'evento-modal'}>
                    <label>Você confirma o envio dos clientes listados para as referentes</label>
                    <label className={'bold'}>Unidades Operacionais?</label>
                    <div className={'rodape'}>
                        <GenericButton color={'darkBlue'} label={'Cancelar'} subClass={'basic-button'} click={this.closeModalEnviar} />
                        <GenericButton color={'darkGrey-outline'} label={'Confirmar'} subClass={'basic-button'} click={this.transformarEmAtendimento} />
                    </div>
                </SimpleModal>
            </Page>
        )
    }

}