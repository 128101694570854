import React, { Component } from "react";
import "./funil.scss";
import GenericButton from "../../components/genericButton/genericButton";
import SimplePopover from "../../components/simplePopover/simplePopover";
import FormField from "../../components/formField/formField";
import { Grid } from "@material-ui/core";
import Page from "../../components/page/page";
import API from "../../comm/API";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import InputSelect from "../../components/inputselect/inputselect";
export default class Funil extends Component {
  drList = [];
  unidadeList = [];
  dr = undefined;
  unidade = undefined;

  tab = 0;

  anoList = [];
  currentYear = new Date().getFullYear();
  ano = this.currentYear;

  isDN = false;
  isDR = false;
  isUO = false;

  funil = {
    contactsTarget: 0,
    contactsAtual: 0,
    visitsTarget: 0,
    requestProposalTarget: 0,
    requestProposalAtual: 0,
    submittedProposalTarget: 0,
    projectsContractedTarget: 0,
    revenueGeneratedTarget: 0,
    revenueGeneratedAtual: 0,
    totalResourcesAcquiredTarget: 0,
  };
  errors = {
    contactsTarget: "",
    contactsAtual: "",
    visitsTarget: "",
    requestProposalTarget: "",
    requestProposalAtual: "",
    submittedProposalTarget: "",
    projectsContractedTarget: "",
    revenueGeneratedTarget: "",
    revenueGeneratedAtual: "",
    totalResourcesAcquiredTarget: "",
  };

  constructor(props) {
    super(props);
    this.state = { isValid: true, isEditing: false, showFunil: false };
    this.getLists();
    this.getAnoList();
  }

  showToast = () => {};
  showLoading = () => {};
  closeLoading = () => {};
  getFunctions = (showToast, showLoading, closeLoading) => {
    if (showToast != null) this.showToast = showToast;
    if (showLoading != null) this.showLoading = showLoading;
    if (closeLoading != null) this.closeLoading = closeLoading;
  };

  getAnoList = () => {
    for (var i = this.currentYear; i >= 2019; i--) {
      this.anoList.push({ id: i, label: i });
    }
  };

  getLists = () => {
    //Verifica se usuário tem colaborador e seleciona unidade e dr de acordo com o usuário logado
    if (
      this.props.usuarioConectado.colaborador != null &&
      this.props.usuarioConectado.colaborador.isAtivo
    ) {
      this.unidade = this.props.usuarioConectado.colaborador.unidade;
      this.unidadeList = [this.props.usuarioConectado.colaborador.unidade];
      this.isUO = true;
      this.drList = [this.unidade.unidadePai];
      this.dr = this.unidade.unidadePai;
      this.setState(this.state);
      this.loadFunil();
    } else {
      this.unidade = this.props.usuarioConectado.unidade;

      if (this.unidade.tipoUnidade.sigla === "DN") {
        this.isDN = true;
        var filter = { "unidadePai.id": 29, ordenarPor: "id" };
        API.get("rest/gestao/Unidade", { params: filter }).then((res) => {
          this.dr = res.data[0];
          this.drList = res.data;
          this.setState(this.state);
          this.loadUnidadeList(true);
        });
      } else {
        this.isDR = true;
        this.dr = this.unidade;
        this.drList = [this.unidade];
        this.setState(this.state);
        this.loadUnidadeList(true);
      }
    }
  };
  loadUnidadeList = (load) => {
    var filter = { "unidadePai.id": this.dr.id, ordenarPor: "descricao" };
    API.get("rest/gestao/Unidade", { params: filter }).then((res) => {
      this.unidadeList = res.data;
      this.unidade = res.data[0];
      this.setState(this.state);
      if (load) {
        this.loadFunil();
      }
    });
  };
  loadFunil = () => {
    this.setState({ isEditing: false });
    this.showLoading();
    this.loadErrors();

    var filter = {
      idUnidade: this.unidade.id,
      ano: this.ano,
    };

    API.get("rest/sistema/Funil", { params: filter })
      .then((res) => {
        if (res.data.id !== undefined) {
          this.funil = res.data;
          this.setState(this);
        } else {
          this.funil.contactsTarget = 0;
          this.funil.contactsAtual = 0;
          this.funil.visitsTarget = 0;
          this.funil.requestProposalTarget = 0;
          this.funil.requestProposalAtual = 0;
          this.funil.submittedProposalTarget = 0;
          this.funil.projectsContractedTarget = 0;
          this.funil.revenueGeneratedTarget = 0;
          this.funil.revenueGeneratedAtual = 0;
          this.funil.totalResourcesAcquiredTarget = 0;

          this.funil.id = undefined;
          this.funil.idUnidade = this.unidade.id;
          this.funil.ano = this.ano;
          this.setState(this);
        }

        API.get("rest/gestao/Visita/count", {
          params: { ano: this.ano, "unidade.id": this.unidade.id },
        }).then((res) => {
          this.funil.visitsAtual = res.data.count;
          this.setState(this);
          API.get("rest/gestao/Atendimento/count", {
            params: this.getFiltro(0),
          }).then((res) => {
            this.funil.submittedProposalAtual = res.data.count;
            this.setState(this);
            API.get("rest/gestao/Atendimento/count", {
              params: this.getFiltro(1),
            }).then((res) => {
              this.funil.projectsContractedAtual = res.data.count;
              this.setState(this);
              API.get("rest/gestao/Atendimento/sum/numeroDeReceitaEstimada", {
                params: this.getFiltro(1),
              }).then((res) => {
                this.funil.totalResourcesAcquiredAtual = res.data.sum;
                this.setState({ showFunil: true });
                this.closeLoading();
              });
            });
          });
        });
      })
      .catch((error) => {
        this.setState({ showFunil: false });
        this.closeLoading();
        this.showToast(
          "Erro ao carregar funil. Favor, entrar em contato com o suporte.",
          "error"
        );
      });
  };
  loadErrors = () => {
    this.errors.contactsTarget = "";
    this.errors.contactsAtual = "";
    this.errors.visitsTarget = "";
    this.errors.requestProposalTarget = "";
    this.errors.requestProposalAtual = "";
    this.errors.submittedProposalTarget = "";
    this.errors.projectsContractedTarget = "";
    this.errors.revenueGeneratedTarget = "";
    this.errors.revenueGeneratedAtual = "";
    this.errors.totalResourcesAcquiredTarget = "";
  };
  getFiltro = (tipo) => {
    var filter = {};
    filter["unidade.id"] = this.unidade.id;
    if (tipo === 0) {
      filter["dataNegociacao"] =
        "between(" +
        this.ano +
        "-01-01T00:00:00-02:00," +
        this.ano +
        "-12-31T23:59:59-02:00)";
    } else {
      filter["dataAceitacao"] =
        "between(" +
        this.ano +
        "-01-01T00:00:00-02:00," +
        this.ano +
        "-12-31T23:59:59-02:00)";
    }
    return filter;
  };
  calculaPercentual = (numerador, denominador, tipo) => {
    var resultado = 0;

    if (!Number.isNaN(numerador) && !Number.isNaN(denominador)) {
      if (denominador !== 0) {
        resultado = (numerador / denominador).toFixed(2);
      }
    }
    if (resultado > 140 && tipo === 0) {
      resultado = 140;
    }
    return resultado;
  };
  calculaAbreviacao = (numero) => {
    if (numero != null) {
      if (numero / 1000 <= 1) {
        return numero;
      } else if (numero / 1000000 < 1) {
        var numero1 = (numero / 1000).toFixed(2);
        return numero1 + "K";
      } else if (numero / 1000000000 < 1) {
        var numero2 = (numero / 1000000).toFixed(2);
        return numero2 + "M";
      } else {
        var numero3 = (numero / 1000000000).toFixed(2);
        return numero3 + "T";
      }
    }
  };
  changeInput = (e) => {
    if (e != null && e.target != null) {
      if (
        e.target.id === "revenueGeneratedTarget" ||
        e.target.id === "revenueGeneratedAtual" ||
        e.target.id === "totalResourcesAcquiredTarget"
      ) {
        this.funil[e.target.id] = parseFloat(e.target.value);
      } else {
        this.funil[e.target.id] = parseInt(e.target.value);
      }
      if (e.target.value === "") {
        this.errors[e.target.id] = "Valor inválido.";
      } else {
        this.errors[e.target.id] = "";
      }
      this.validateFunil();
    }
  };
  changeDR = (e) => {
    if (e != null && e !== "" && e.id !== this.dr.id) {
      this.unidade = undefined;
      this.unidadeList = [];
      this.dr = e;
      this.setState(this.state);
      this.loadUnidadeList(false);
    }
  };
  changeUnidade = (e) => {
    if (e != null && e !== "" && e.id !== this.unidade.id) {
      this.unidade = e;
      this.setState(this.state);
    }
  };

  changeAno = (e) => {
    if (e != null && e.label !== this.ano) {
      this.ano = e.label;
    }
  };

  changeTab = (e, newValue) => {
    if (newValue != null) {
      this.tab = newValue;
      this.setState(this.state);
    }
  };
  allowEdit = () => {
    this.setState({ isEditing: true });
  };
  setColor = (value) => {
    if (value >= 80) {
      return "greenPercent";
    } else if (value >= 40) {
      return "yellowPercent";
    } else {
      return "redPercent";
    }
  };
  getInfomation = (index) => {
    switch (index) {
      case 1:
        return "Are contacts that are proactively acquired. Thus the ISI has present proactively its value proposition to the prioritized contacts. The identification of the prioritized companies can be achieved through market analysis and/or market segmentation.";
      case 2:
        return "Are agreed appointments with a contact of the potential customer to present the ISIs product and service portfolio and/or discuss the challenges of the company (incl. companies that visit the ISI).";
      case 3:
        return "Quantifies the number of companies requests for (an offer of) ISI products and/or services.";
      case 4:
        return "Shows the ability of the ISI to identify the challenges of the potential customer and to offer suitable solutions.";
      case 5:
        return "Describes the number of agreed proposals that have been signed between the ISI and the prospective customer.";
      case 6:
        return "Due to the considered reference framework, this number can refer to revenue generated from industry for R&D&I projects or revenue generated for a specific market segment, business area or service portfolio product or service, etc. (Determination of a reference framework is prerequisite)";
      default:
        break;
    }
  };
  validateFunil = () => {
    this.setState({
      isValid:
        this.errors.contactsTarget === "" &&
        this.errors.contactsAtual === "" &&
        this.errors.visitsTarget === "" &&
        this.errors.requestProposalTarget === "" &&
        this.errors.requestProposalAtual === "" &&
        this.errors.submittedProposalTarget === "" &&
        this.errors.projectsContractedTarget === "" &&
        this.errors.revenueGeneratedTarget === "" &&
        this.errors.revenueGeneratedAtual === "" &&
        this.errors.totalResourcesAcquiredTarget === "",
    });
  };
  saveFunil = () => {
    this.showLoading();
    if (this.funil.id === undefined) {
      API.post("rest/sistema/Funil", this.funil)
        .then((res) => {
          this.setState({ isEditing: false });
          this.loadFunil();
          this.showToast("Funil registrado com sucesso!", "success");
        })
        .catch((error) => {
          this.closeLoading();
          this.showToast(
            "Error ao registrar funil. Favor, entrar em contato com o suporte.",
            "error"
          );
        });
    } else {
      API.post("rest/sistema/Funil/" + this.funil.id, this.funil)
        .then((res) => {
          this.setState({ isEditing: false });
          this.loadFunil();
          this.showToast("Funil editado com sucesso!", "success");
        })
        .catch((error) => {
          this.closeLoading();
          this.showToast(
            "Error ao editar funil. Favor, entrar em contato com o suporte.",
            "error"
          );
        });
    }
  };

  render() {
    return (
      <Page
        icon={"indicadores.svg"}
        label={"Sales Funnel"}
        getFunctions={this.getFunctions}
        usuarioConectado={this.props.usuarioConectado}
      >
        <Grid container id={"funil"}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4} xl={3}>
                <InputSelect
                  label={"Departamento Regional"}
                  suggestions={this.drList}
                  itemLabel={"descricao"}
                  getInput={this.changeDR}
                  id={"dr"}
                  itemKey={"descricao"}
                  initialSelectedItem={this.dr}
                  disabled={this.isUO || this.isDR}
                />
              </Grid>
              <Grid item xs={12} sm={5} xl={4}>
                <InputSelect
                  label={"Unidade"}
                  suggestions={this.unidadeList}
                  itemLabel={"abreviacao"}
                  getInput={this.changeUnidade}
                  id={"unidade"}
                  itemKey={"abreviacao"}
                  initialSelectedItem={this.unidade}
                  disabled={this.isUO}
                />
              </Grid>
              <Grid item xs={12} sm={2} md={2} className={"grid-right-button"}>
                <InputSelect
                  label={"Ano"}
                  suggestions={this.anoList}
                  itemKey={"label"}
                  itemLabel={"label"}
                  id={"ano"}
                  getInput={this.changeAno}
                  initialSelectedItem={{ label: this.ano }}
                />
                <GenericButton
                  color={"darkBlue"}
                  label={"Aplicar"}
                  subClass={"basic-button"}
                  click={this.loadFunil}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className="tabs">
            <AppBar position="static">
              <Tabs
                value={this.tab}
                onChange={this.changeTab}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab
                  key={"NavbarFunil0"}
                  value={0}
                  label={"Effectivity Monitoring"}
                ></Tab>
                <Tab
                  key={"NavbarFunil1"}
                  value={1}
                  label={"Efficiency Monitoring"}
                ></Tab>
              </Tabs>
            </AppBar>
          </Grid>

          {this.state.showFunil ? (
            <Grid item xs={12} md={8}>
              <Grid item xs={12} className={"funil-header"}>
                <label>Indicators</label>

                {this.tab === 0 && this.isUO ? (
                  this.state.isEditing ? (
                    <Grid style={{ display: "flex" }}>
                      <GenericButton
                        color={"darkBlue-outline"}
                        label={"Cancelar"}
                        subClass={"basic-button"}
                        click={this.loadFunil}
                      />
                      <GenericButton
                        color={"darkBlue"}
                        label={"Salvar"}
                        subClass={"basic-button"}
                        click={this.saveFunil}
                        disabled={!this.state.isValid}
                      />
                    </Grid>
                  ) : (
                    <GenericButton
                      color={"darkBlue-outline"}
                      label={"Editar dados"}
                      subClass={"basic-button"}
                      click={this.allowEdit}
                    />
                  )
                ) : null}
              </Grid>
              {this.tab === 0 ? (
                <Grid item xs={12}>
                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}>01</label>
                      <div className={"tittle"}>
                        Contacts with priorized companies
                        <SimplePopover
                          content={this.getInfomation(1)}
                        ></SimplePopover>
                      </div>
                      {this.state.isEditing ? (
                        <div className={"numbers n2"}>
                          <label className={"label-small font-blue"}>
                            TARGET
                          </label>
                          <FormField
                            type={"number"}
                            id={"contactsTarget"}
                            inputValue={this.funil.contactsTarget}
                            changeValue={this.changeInput}
                            error={this.errors.contactsTarget}
                          />
                          <label className={"label-small font-blue"}>
                            ATUAL
                          </label>
                          <FormField
                            type={"number"}
                            id={"contactsAtual"}
                            inputValue={this.funil.contactsAtual}
                            changeValue={this.changeInput}
                            error={this.errors.contactsAtual}
                          />
                        </div>
                      ) : (
                        <div className={"numbers n1"}>
                          <label className={"label-small"}>TARGET</label>
                          <label className={"font-light-blue"}>
                            {this.funil.contactsTarget}
                          </label>
                          <label className={"label-small"}>ATUAL</label>
                          <label className={"font-blue"}>
                            {this.funil.contactsAtual}
                          </label>
                        </div>
                      )}
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.contactsAtual * 100,
                            this.funil.contactsTarget,
                            1
                          )
                        )}
                      >
                        {this.calculaPercentual(
                          this.funil.contactsAtual * 100,
                          this.funil.contactsTarget,
                          1
                        )}
                        %
                      </label>
                    </div>
                    {this.state.isEditing ? null : (
                      <div className={"funilBarras"}>
                        <div
                          className={"barraSuperior"}
                          style={{ width: "100%" }}
                        ></div>
                        <div
                          className={"barraInferior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.contactsAtual * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}> 02</label>
                      <div className={"tittle"}>
                        Visits at prioritized companies
                        <SimplePopover
                          content={this.getInfomation(2)}
                        ></SimplePopover>
                      </div>
                      {this.state.isEditing ? (
                        <div className={"numbers n2"}>
                          <label className={"label-small font-blue"}>
                            {" "}
                            TARGET
                          </label>
                          <FormField
                            type={"number"}
                            id={"visitsTarget"}
                            inputValue={this.funil.visitsTarget}
                            changeValue={this.changeInput}
                            error={this.errors.visitsTarget}
                          />
                          <label className={"label-small font-blue"}>
                            {" "}
                            ATUAL
                          </label>
                          <label className={"font-blue"}>
                            {" "}
                            {this.funil.visitsAtual}
                          </label>
                        </div>
                      ) : (
                        <div className={"numbers n1"}>
                          <label className={"label-small"}> TARGET</label>
                          <label className={"font-light-blue"}>
                            {" "}
                            {this.funil.visitsTarget}
                          </label>
                          <label className={"label-small"}> ATUAL</label>
                          <label className={"font-blue"}>
                            {" "}
                            {this.funil.visitsAtual}
                          </label>
                        </div>
                      )}
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.visitsAtual * 100,
                            this.funil.visitsTarget,
                            1
                          )
                        )}
                      >
                        {this.calculaPercentual(
                          this.funil.visitsAtual * 100,
                          this.funil.visitsTarget,
                          1
                        )}
                        %
                      </label>
                    </div>
                    {this.state.isEditing ? null : (
                      <div className={"funilBarras"}>
                        <div
                          className={"barraSuperior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.visitsTarget * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                        <div
                          className={"barraInferior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.visitsAtual * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}> 03</label>
                      <div className={"tittle"}>
                        Requests for project proposal
                        <SimplePopover
                          content={this.getInfomation(3)}
                        ></SimplePopover>
                      </div>
                      {this.state.isEditing ? (
                        <div className={"numbers n2"}>
                          <label className={"label-small font-blue"}>
                            {" "}
                            TARGET
                          </label>
                          <FormField
                            type={"number"}
                            id={"requestProposalTarget"}
                            inputValue={this.funil.requestProposalTarget}
                            changeValue={this.changeInput}
                            error={this.errors.requestProposalTarget}
                          />
                          <label className={"label-small font-blue"}>
                            {" "}
                            ATUAL
                          </label>
                          <FormField
                            type={"number"}
                            id={"requestProposalAtual"}
                            inputValue={this.funil.requestProposalAtual}
                            changeValue={this.changeInput}
                            error={this.errors.requestProposalAtual}
                          />
                        </div>
                      ) : (
                        <div className={"numbers n1"}>
                          <label className={"label-small"}> TARGET</label>
                          <label className={"font-light-blue"}>
                            {" "}
                            {this.funil.requestProposalTarget}
                          </label>
                          <label className={"label-small"}> ATUAL</label>
                          <label className={"font-blue"}>
                            {" "}
                            {this.funil.requestProposalAtual}
                          </label>
                        </div>
                      )}
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.requestProposalAtual * 100,
                            this.funil.requestProposalTarget,
                            1
                          )
                        )}
                      >
                        {this.calculaPercentual(
                          this.funil.requestProposalAtual * 100,
                          this.funil.requestProposalTarget,
                          1
                        )}
                        %
                      </label>
                    </div>
                    {this.state.isEditing ? null : (
                      <div className={"funilBarras"}>
                        <div
                          className={"barraSuperior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.requestProposalTarget * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                        <div
                          className={"barraInferior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.requestProposalAtual * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}> 04</label>
                      <div className={"tittle"}>
                        Project proposals submitted
                        <SimplePopover
                          content={this.getInfomation(4)}
                        ></SimplePopover>
                      </div>
                      {this.state.isEditing ? (
                        <div className={"numbers n2"}>
                          <label className={"label-small font-blue"}>
                            {" "}
                            TARGET
                          </label>
                          <FormField
                            type={"number"}
                            id={"submittedProposalTarget"}
                            inputValue={this.funil.submittedProposalTarget}
                            changeValue={this.changeInput}
                            error={this.errors.submittedProposalTarget}
                          />
                          <label className={"label-small font-blue"}>
                            {" "}
                            ATUAL
                          </label>
                          <label className={"font-blue"}>
                            {" "}
                            {this.funil.submittedProposalAtual}
                          </label>
                        </div>
                      ) : (
                        <div className={"numbers n1"}>
                          <label className={"label-small"}> TARGET</label>
                          <label className={"font-light-blue"}>
                            {" "}
                            {this.funil.submittedProposalTarget}
                          </label>
                          <label className={"label-small"}> ATUAL</label>
                          <label className={"font-blue"}>
                            {" "}
                            {this.funil.submittedProposalAtual}
                          </label>
                        </div>
                      )}
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.submittedProposalAtual * 100,
                            this.funil.submittedProposalTarget,
                            1
                          )
                        )}
                      >
                        {this.calculaPercentual(
                          this.funil.submittedProposalAtual * 100,
                          this.funil.submittedProposalTarget,
                          1
                        )}
                        %
                      </label>
                    </div>
                    {this.state.isEditing ? null : (
                      <div className={"funilBarras"}>
                        <div
                          className={"barraSuperior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.submittedProposalTarget * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                        <div
                          className={"barraInferior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.submittedProposalAtual * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}> 05</label>
                      <div className={"tittle"}>
                        Project contracted
                        <SimplePopover
                          content={this.getInfomation(5)}
                        ></SimplePopover>
                      </div>
                      {this.state.isEditing ? (
                        <div className={"numbers n2"}>
                          <label className={"label-small font-blue"}>
                            {" "}
                            TARGET
                          </label>
                          <FormField
                            type={"number"}
                            id={"projectsContractedTarget"}
                            inputValue={this.funil.projectsContractedTarget}
                            changeValue={this.changeInput}
                            error={this.errors.projectsContractedTarget}
                          />
                          <label className={"label-small font-blue"}>
                            {" "}
                            ATUAL
                          </label>
                          <label className={"font-blue"}>
                            {" "}
                            {this.funil.projectsContractedAtual}
                          </label>
                        </div>
                      ) : (
                        <div className={"numbers n1"}>
                          <label className={"label-small"}> TARGET</label>
                          <label className={"font-light-blue"}>
                            {" "}
                            {this.funil.projectsContractedTarget}
                          </label>
                          <label className={"label-small"}> ATUAL</label>
                          <label className={"font-blue"}>
                            {" "}
                            {this.funil.projectsContractedAtual}
                          </label>
                        </div>
                      )}
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.projectsContractedAtual * 100,
                            this.funil.projectsContractedTarget,
                            1
                          )
                        )}
                      >
                        {this.calculaPercentual(
                          this.funil.projectsContractedAtual * 100,
                          this.funil.projectsContractedTarget,
                          1
                        )}
                        %
                      </label>
                    </div>
                    {this.state.isEditing ? null : (
                      <div className={"funilBarras"}>
                        <div
                          className={"barraSuperior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.projectsContractedTarget * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                        <div
                          className={"barraInferior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.projectsContractedAtual * 100,
                                this.funil.contactsTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <hr></hr>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"green-block"}> R$</label>
                      <div className={"tittle"}>
                        Revenue Generated
                        <SimplePopover
                          content={this.getInfomation(6)}
                        ></SimplePopover>
                      </div>
                      {this.state.isEditing ? (
                        <div className={"numbers n2"}>
                          <label className={"label-small font-blue"}>
                            {" "}
                            TARGET
                          </label>
                          <FormField
                            type={"number"}
                            id={"revenueGeneratedTarget"}
                            min={0}
                            inputValue={this.funil.revenueGeneratedTarget}
                            changeValue={this.changeInput}
                            error={this.errors.revenueGeneratedTarget}
                          />
                          <label className={"label-small font-blue"}>
                            {" "}
                            ATUAL
                          </label>
                          <FormField
                            type={"number"}
                            id={"revenueGeneratedAtual"}
                            min={0}
                            inputValue={this.funil.revenueGeneratedAtual}
                            changeValue={this.changeInput}
                            error={this.errors.revenueGeneratedAtual}
                          />
                        </div>
                      ) : (
                        <div className={"numbers n3"}>
                          <label className={"label-small"}> TARGET</label>
                          <label className={"font-light-blue"}>
                            {this.calculaAbreviacao(
                              this.funil.revenueGeneratedTarget
                            )}
                          </label>
                          <label className={"label-small"}> ATUAL</label>
                          <label className={"font-blue"}>
                            {this.calculaAbreviacao(
                              this.funil.revenueGeneratedAtual
                            )}
                          </label>
                        </div>
                      )}
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.revenueGeneratedAtual * 100,
                            this.funil.revenueGeneratedTarget,
                            1
                          )
                        )}
                      >
                        {this.calculaPercentual(
                          this.funil.revenueGeneratedAtual * 100,
                          this.funil.revenueGeneratedTarget,
                          1
                        )}
                        %
                      </label>
                    </div>
                    {this.state.isEditing ? null : (
                      <div className={"funilBarras"}>
                        <div
                          className={"barraSuperior"}
                          style={{ width: "100%" }}
                        ></div>
                        <div
                          className={"barraInferior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.revenueGeneratedAtual * 100,
                                this.funil.revenueGeneratedTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"green-block"}> R$</label>
                      <div className={"tittle"}>Total resources acquired</div>
                      {this.state.isEditing ? (
                        <div className={"numbers n2"}>
                          <label className={"label-small font-blue"}>
                            {" "}
                            TARGET
                          </label>
                          <FormField
                            type={"number"}
                            id={"totalResourcesAcquiredTarget"}
                            min={0}
                            inputValue={this.funil.totalResourcesAcquiredTarget}
                            changeValue={this.changeInput}
                            error={this.errors.totalResourcesAcquiredTarget}
                          />
                          <label className={"label-small font-blue"}>
                            {" "}
                            ATUAL
                          </label>
                          <label className={"font-blue"}>
                            {this.calculaAbreviacao(
                              this.funil.totalResourcesAcquiredAtual
                            )}
                          </label>
                        </div>
                      ) : (
                        <div className={"numbers n3"}>
                          <label className={"label-small"}> TARGET</label>
                          <label className={"font-light-blue"}>
                            {this.calculaAbreviacao(
                              this.funil.totalResourcesAcquiredTarget
                            )}
                          </label>
                          <label className={"label-small"}> ATUAL</label>
                          <label className={"font-blue"}>
                            {this.calculaAbreviacao(
                              this.funil.totalResourcesAcquiredAtual
                            )}
                          </label>
                        </div>
                      )}
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.totalResourcesAcquiredAtual * 100,
                            this.funil.totalResourcesAcquiredTarget,
                            1
                          )
                        )}
                      >
                        {this.calculaPercentual(
                          this.funil.totalResourcesAcquiredAtual * 100,
                          this.funil.totalResourcesAcquiredTarget,
                          1
                        )}
                        %
                      </label>
                    </div>
                    {this.state.isEditing ? null : (
                      <div className={"funilBarras"}>
                        <div
                          className={"barraSuperior"}
                          style={{ width: "100%" }}
                        ></div>
                        <div
                          className={"barraInferior"}
                          style={{
                            width:
                              this.calculaPercentual(
                                this.funil.totalResourcesAcquiredAtual * 100,
                                this.funil.totalResourcesAcquiredTarget,
                                0
                              ) + "%",
                          }}
                        ></div>
                      </div>
                    )}
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}>01</label>
                      <div className={"tittle"}>Contacts proposals</div>
                      <div className={"numbers n3"}>
                        <label className={"label-small"}>TARGET</label>
                        <label className={"font-blue"}>
                          {this.calculaPercentual(
                            this.funil.requestProposalTarget * 100,
                            this.funil.contactsTarget,
                            1
                          )}
                          %
                        </label>
                        <label className={"label-small"}>ATUAL</label>
                        <label className={"font-blue"}>
                          {this.calculaPercentual(
                            this.funil.requestProposalAtual * 100,
                            this.funil.contactsAtual,
                            1
                          )}
                          %
                        </label>
                      </div>
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.requestProposalAtual * 100,
                            this.funil.contactsAtual,
                            1
                          )
                        )}
                      ></label>
                    </div>
                    <div className={"funilBarras"}>
                      <div
                        className={"barraSuperior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.requestProposalTarget * 100,
                              this.funil.contactsTarget,
                              0
                            ) + "%",
                        }}
                      ></div>
                      <div
                        className={"barraInferior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.requestProposalAtual * 100,
                              this.funil.contactsAtual,
                              0
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}>02</label>
                      <div className={"tittle"}>Contact conversion</div>
                      <div className={"numbers n3"}>
                        <label className={"label-small"}>TARGET</label>
                        <label className={"font-light-blue"}>
                          {this.calculaPercentual(
                            this.funil.requestProposalTarget * 100,
                            this.funil.visitsTarget,
                            1
                          )}
                          %
                        </label>
                        <label className={"label-small"}>ATUAL</label>
                        <label className={"font-blue"}>
                          {this.calculaPercentual(
                            this.funil.requestProposalAtual * 100,
                            this.funil.visitsAtual,
                            1
                          )}
                          %
                        </label>
                      </div>
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.requestProposalAtual * 100,
                            this.funil.visitsAtual,
                            1
                          )
                        )}
                      ></label>
                    </div>
                    <div className={"funilBarras"}>
                      <div
                        className={"barraSuperior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.requestProposalTarget * 100,
                              this.funil.visitsTarget,
                              0
                            ) + "%",
                        }}
                      ></div>
                      <div
                        className={"barraInferior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.requestProposalAtual * 100,
                              this.funil.visitsAtual,
                              0
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}>03</label>
                      <div className={"tittle"}>Attendance rate</div>
                      <div className={"numbers n3"}>
                        <label className={"label-small"}>TARGET</label>
                        <label className={"font-light-blue"}>
                          {this.calculaPercentual(
                            this.funil.submittedProposalTarget * 100,
                            this.funil.requestProposalTarget,
                            1
                          )}
                          %
                        </label>
                        <label className={"label-small"}>ATUAL</label>
                        <label className={"font-blue"}>
                          {this.calculaPercentual(
                            this.funil.submittedProposalAtual * 100,
                            this.funil.requestProposalAtual,
                            1
                          )}
                          %
                        </label>
                      </div>
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.submittedProposalAtual * 100,
                            this.funil.requestProposalAtual,
                            1
                          )
                        )}
                      ></label>
                    </div>
                    <div className={"funilBarras"}>
                      <div
                        className={"barraSuperior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.submittedProposalTarget * 100,
                              this.funil.requestProposalTarget,
                              0
                            ) + "%",
                        }}
                      ></div>
                      <div
                        className={"barraInferior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.submittedProposalAtual * 100,
                              this.funil.requestProposalAtual,
                              0
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}>04</label>
                      <div className={"tittle"}>Acquisition success rate</div>
                      <div className={"numbers n3"}>
                        <label className={"label-small"}>TARGET</label>
                        <label className={"font-light-blue"}>
                          {this.calculaPercentual(
                            this.funil.projectsContractedTarget * 100,
                            this.funil.submittedProposalTarget,
                            1
                          )}
                          %
                        </label>
                        <label className={"label-small"}>ATUAL</label>
                        <label className={"font-blue"}>
                          {this.calculaPercentual(
                            this.funil.projectsContractedAtual * 100,
                            this.funil.submittedProposalAtual,
                            1
                          )}
                          %
                        </label>
                      </div>
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.funil.projectsContractedAtual * 100,
                            this.funil.submittedProposalAtual,
                            1
                          )
                        )}
                      ></label>
                    </div>
                    <div className={"funilBarras"}>
                      <div
                        className={"barraSuperior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.projectsContractedTarget * 100,
                              this.funil.submittedProposalTarget,
                              0
                            ) + "%",
                        }}
                      ></div>
                      <div
                        className={"barraInferior"}
                        style={{
                          width:
                            this.calculaPercentual(
                              this.funil.projectsContractedAtual * 100,
                              this.funil.submittedProposalAtual,
                              0
                            ) + "%",
                        }}
                      ></div>
                    </div>
                  </div>

                  <div className={"funilPai"}>
                    <div className={"funilBase"}>
                      <label className={"blue-block"}>05</label>
                      <div className={"tittle"}>Project volume</div>
                      <div className={"numbers n3"}>
                        <label className={"label-small"}>TARGET</label>
                        <label className={"font-light-blue"}>
                          R$
                          {this.calculaPercentual(
                            this.funil.revenueGeneratedTarget,
                            this.funil.projectsContractedTarget,
                            1
                          )}
                        </label>
                        <label className={"label-small"}>ATUAL</label>
                        <label className={"font-blue"}>
                          R$
                          {this.calculaPercentual(
                            this.funil.revenueGeneratedAtual,
                            this.funil.projectsContractedAtual,
                            1
                          )}
                        </label>
                      </div>
                      <label
                        className={this.setColor(
                          this.calculaPercentual(
                            this.calculaPercentual(
                              this.funil.revenueGeneratedTarget * 100,
                              this.funil.projectsContractedTarget,
                              1
                            ),
                            this.calculaPercentual(
                              this.funil.revenueGeneratedAtual,
                              this.funil.projectsContractedAtual,
                              1
                            ),
                            1
                          )
                        )}
                      ></label>
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          ) : null}
          <Grid item xs={12} md={4}>
            <Grid item className={"funil-header"}>
              <label>Phases of Sales Funnel</label>
            </Grid>
            <Grid item xs={12} md={4} className={"info"}>
              <div>
                <label className={"blue-block"}>01</label>
                <label className={"info-label"}>
                  Identify & priorizite potential customers
                </label>
              </div>
              <div>
                <label className={"blue-block"}>02</label>
                <label className={"info-label"}>
                  Initiante contact to potential customers
                </label>
                <br></br>
              </div>
              <div>
                <label className={"blue-block"}>03</label>
                <label className={"info-label"}>
                  Identify challenges & elaborate solutions
                </label>
                <br></br>
              </div>
              <div>
                <label className={"blue-block"}>04</label>
                <label className={"info-label"}>
                  Develop, submit & negotiante proposal
                </label>
                <br></br>
              </div>
              <div>
                <label className={"blue-block"}>05</label>
                <label className={"info-label"}>Close project contract</label>
                <br></br>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Page>
    );
  }
}
