import React, { useCallback } from 'react';
import { Table, TableCell, TableHead, TableRow,TableBody } from '@material-ui/core';
import GenericButton from '../genericButton/genericButton';

export const OutputTable = (props) => {
    const removeUnusualProperties = useCallback((obj, propertiesList) => {
        const filtered = Object.keys(obj)
            .filter((key) => propertiesList.includes(key))
            .reduce((acc, key) => {
                acc[key] = obj[key];
                return acc;
            }, {});

        return filtered;
    }, [])

    const generateKey = (data) => {
        return `${data}_${new Date().getTime()}`;
    }

    return (
        <Table className={'tabela-entregas'}>
            <TableHead>
                <TableRow>
                    {props.menuItems.map((menu) => (
                        <TableCell key={generateKey(menu)}>{menu}</TableCell>
                    ))}
                    
                </TableRow>
            </TableHead>
            <TableBody> 
                {props.selectedItems.map((item) => {
                    const formattedItem =  removeUnusualProperties(item, props.propertiesList);

                    return (
                        <TableRow key={generateKey(item[props.itemKey])}>
                            {Object.entries(formattedItem).map((obj) => {
                                return (
                                    <TableCell key={generateKey(item[obj[0]])}>{item[obj[0]]}</TableCell>
                                )
                            })}
                            {props.onRemoveItem && (
                                <GenericButton 
                                    color={'transparent'} 
                                    subClass={'icon-button trash-button'} 
                                    icon={'trash.svg'}
                                    click={() => props.onRemoveItem(item)}  
                                />
                            )}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}