import React, { Component } from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table'
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API';
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';
import './produtoRegional.scss';

export default class ProdutoRegional extends Component {

	titles = [
        { id: '1', canOrder: true, label: 'Nome' },
        { id: '2', canOrder: true, label: 'Linha' },
        { id: '3', canOrder: true, label: 'Categoria' },
        { id: '4', canOrder: true,  label: 'Produto Nacional' },
        { id: '6', canOrder: true,  label: 'Ativo'},
        { id: '7', canOrder: false,  label: ''}
    ];
    titlesDn = [
        { id: '1', canOrder: true, label: 'Nome' },
        { id: '2', canOrder: true, label: 'Linha' },
        { id: '3', canOrder: true, label: 'Categoria' },
        { id: '4', canOrder: true,  label: 'Produto Nacional' },
        { id: '5', canOrder: true,  label: 'Unidade'},
        { id: '6', canOrder: true,  label: 'Ativo'},
        { id: '7', canOrder: false,  label: ''}
    ];
    produtos = [];
    busca = '';
    estado = '';

    constructor(props) {
        super(props);
        this.state = {
            produtos: [], 
            emptyList: 'seeResults', 
            isDR: this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR' && 
            (this.props.usuarioConectado.colaborador === undefined || 
                (this.props.usuarioConectado.colaborador != null && !this.props.usuarioConectado.colaborador.isAtivo))
        };
        if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN') this.titles = this.titlesDn;
        else this.estado = this.props.usuarioConectado.unidade.municipio.unidadeFederativa.descricao;
        API.get('/rest/auxiliar/ProdutoRegional/find').then(res => {
            this.produtos = res.data;
            for (var i = 0; i < this.produtos.length; i++) {
                this.produtos[i].push(
                    <div style={{display: 'flex'}}>
                        <Link to={`/cad/atendimento/produtoRegional/${this.produtos[i][0]}`} style={{display: this.state.isDR ? 'inline' : 'none'}}>
                            <GenericButton color={'transparent'} subClass={'pen icon-button'} icon={'pen.svg'} />
                        </Link>
                    </div>
                );
            }
        }).catch(error => {
        	alert('Erro ao realizar GET dos produtos regionais.\nPor favor, entre em contato com o suporte.');
            console.log(error);
        });
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    aplicarFiltro = () => {
        this.showLoading();
        if (this.busca != null && this.busca !== '') {
            var result = this.produtos.filter(produto => {
                return produto.toString().toLowerCase().includes(this.busca.toLowerCase());
            });
            if (result != null) this.setState({produtos: result});
        } else {
            this.setState({produtos: this.produtos});
        }
        this.setState({ emptyList: (result != null && result.length === 0) ? 'noResults' : '' });
        this.closeLoading();
    };

    changeBusca = (e) => {
        this.busca = e.target.value;
    };

    render() {
        return (
            <Page icon={'paste.svg'} label={'Produtos Regionais'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-produtos-regionais'}>
                	<Grid item xs={12} className={'estado'}>
                		{this.estado}
                	</Grid>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={(this.state.isDR ? 6 : 5)} xl={(this.state.isDR ? 5 : 4)} className={'grid-left-button'}>
                                { this.state.isDR ?
                                    <Link to={'/cad/atendimento/produtoRegional/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Novo Produto'} subClass={'basic-button'} />
                                    </Link> 
                                : null }
                                <FormField type={'text'} label={''} id={'busca'} pressEnter={this.aplicarFiltro} placeholder={'busque por nome, categoria ou linha'}
                                    button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} 
                                    click={this.aplicarFiltro}/>} changeValue={this.changeBusca} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.produtos} titles={this.titles} initialOrder={'1'} />
                        <EmptyList type={this.state.emptyList} seeAll={this.aplicarFiltro}/>
                    </Grid>
                </Grid>
            </Page>
        );
    };
}