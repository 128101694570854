import React from 'react';
import "./kpi.scss";
import { KpiBaseContent } from './kpiBaseContent';

export const BusinessSuccess = (props) => {
  return (
    <KpiBaseContent 
      idKpiFactor={1}
      title='Sucesso do negócio'
      usuarioConectado={props.usuarioConectado}
    />
  )
} 