export const getByKeyOrEmpty = (object, key) => {
    const result = object && object[key] ? object[key] : [];
    return JSON.parse(JSON.stringify(result));
};

export const mapAtendimentoNome = (etapa, turma) => {
    return etapa.atendimentoList.map(atendimento => {
        const finded = turma.atendimentoTurmaList.find(e => e.atendimento.id === atendimento.idAtendimento);
        return {...atendimento, clientName: finded.atendimento.cliente.nome, atendimentoStatus: finded.atendimento.atendimentoStatus.id}
    })
};

export const viewDataModels = {

    etapaTeorica: {
        etapa: {
            etapaFinalizada: false,
        },
        visitas: {
            title: 'Mentoria Teórica',
            data: [],
        },
        atividades: {
            treinamentoSala: {
                data: {},
                icon: 'treinamento-sala.svg',
                label: 'Treinamento em sala',
                screenAtividade: 'treinamentoSala',
                anexos: {
                    data: [],
                    title: 'Treinamento em sala de aula',
                    defaultIcon: 'treinamento-brasilmais',
                }
            },
            leanGame: {
                data: {},
                icon: 'lean-game.svg',
                label: 'Lean Game Tabuleiro',
                screenAtividade: 'leanGame',
                anexos: {
                    data: [],
                    title: 'Lean Game Digital',
                    defaultIcon: 'lean-brasilmais',
                }
            },
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '8h',
        totalAtividades: 2
    },
    
    etapaPraticaB: {
        etapa: {
            etapaFinalizada: false,
        },
        visitas: {
            title: 'Mentoria Prática',
            data: [],
        },
        atividades: {
            estadoPresente: {
                data: {},
                icon: 'estado-presente.svg',
                label: 'Estado Presente',
                screenAtividade: 'estadoPresente',
                anexos: {
                    data: [],
                    title: 'Estado Presente',
                    defaultIcon: '',
                }
            },
            mfv: {
                data: {},
                icon: 'mfv.svg',
                label: 'Mapa de Fluxo de Valor (MFV)',
                screenAtividade: 'mfv',
                anexos: {
                    data: [],
                    title: 'Mapa de Fluxo de Valor (MFV)',
                    defaultIcon: '',
                }
            },
            desperdicio: {
                data: {},
                icon: 'desperdicio.svg',
                label: 'Desperdícios',
                screenAtividade: 'desperdicios',
                anexos: {
                    data: [],
                    title: 'Desperdícios',
                    defaultIcon: '',
                }
            },
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '12h',
        totalAtividades: 3
    },

    etapaPraticaC: {
        etapa: {
            etapaFinalizada: false,
        },
        visitas: {
            title: 'Mentoria Prática',
            data: [],
        },
        atividades: {
            planoDeAcao: {
                data: {},
                icon: 'plano-de-acao.svg',
                label: 'Plano de ação',
                screenAtividade: 'planoDeAcao',
                anexos: {
                    data: [],
                    title: 'Plano de ação',
                    defaultIcon: '',
                }
            },
            processoProdutivo: {
                data: {},
                icon: 'processo-produtivo.svg',
                label: 'Processo produtivo',
                screenAtividade: 'processoProdutivo',
                anexos: {
                    data: [],
                    title: 'Processo produtivo',
                    defaultIcon: '',
                }
            },
            linhaDeProducao: {
                data: {},
                icon: 'linha-de-producao.svg',
                label: 'Linha de Produção',
                screenAtividade: 'linhaDeProducao',
                anexos: {
                    data: [],
                    title: 'Linha de Produção',
                    defaultIcon: '',
                }
            },
            desperdicio: {
                data: {},
                icon: 'desperdicio.svg',
                label: 'Desperdícios',
                screenAtividade: 'desperdicios',
                anexos: {
                    data: [],
                    title: 'Desperdícios',
                    defaultIcon: '',
                },
                dontCount: true
            },
        },
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '4h',
        totalAtividades: 3
    },

    etapaPraticaD: {
        etapa: {
            etapaFinalizada: false,
        },
        visitas: {
            title: 'Mentoria Prática',
            data: [],
        },
        atividades: {
            planoDeAcao: {
                data: {},
                icon: 'plano-de-acao.svg',
                label: 'Plano de ação',
                screenAtividade: 'planoDeAcao',
                anexos: {
                    data: [],
                    title: 'Plano de ação',
                    defaultIcon: '',
                }
            },
            processoProdutivo: {
                data: {},
                icon: 'processo-produtivo.svg',
                label: 'Processo produtivo',
                screenAtividade: 'processoProdutivo',
                anexos: {
                    data: [],
                    title: 'Processo produtivo',
                    defaultIcon: '',
                }
            },
            linhaDeProducao: {
                data: {},
                icon: 'linha-de-producao.svg',
                label: 'Linha de Produção',
                screenAtividade: 'linhaDeProducao',
                anexos: {
                    data: [],
                    title: 'Linha de Produção',
                    defaultIcon: '',
                }
            },
            desperdicio: {
                data: {},
                icon: 'desperdicio.svg',
                label: 'Desperdícios',
                screenAtividade: 'desperdicios',
                anexos: {
                    data: [],
                    title: 'Desperdícios',
                    defaultIcon: '',
                }
            }
        },
        ferramentas: {
            fluxoContinuo: {
                data: {},
                icon: 'fluxoContinuo.svg',
                label: 'Fluxo Contínuo',
                screenAtividade: 'fluxoContinuo',
                anexos: {
                    data: [],
                    title: 'Fluxo Contínuo',
                    defaultIcon: 'fluxo-continuo',
                }
            },
            qualidadeNaFonte: {
                data: {},
                icon: 'qualidadeNaFonte.svg',
                label: 'Qualidade na Fonte',
                screenAtividade: 'qualidadeNaFonte',
                anexos: {
                    data: [],
                    title: 'Qualidade na Fonte',
                    defaultIcon: 'qualidade-fonte',
                }
            },
            cincoS: {
                data: {},
                icon: 'cincoS.svg',
                label: '5S',
                screenAtividade: 'cincoS',
                anexos: {
                    data: [],
                    title: '5S',
                    defaultIcon: 'cinco-s',
                }
            },
            trocaRapidaFerramenta: {
                data: {},
                icon: 'trocaRapidaFerramenta.svg',
                label: 'Troca Rápida de Ferramenta',
                screenAtividade: 'trocaRapidaFerramenta',
                anexos: {
                    data: [],
                    title: 'Troca Rápida de Ferramenta',
                    defaultIcon: 'troca-rapida-ferramenta',
                }
            },
            trabalhoPadronizado: {
                data: {},
                icon: 'trabalhoPadronizado.svg',
                label: 'Trabalho Padronizado',
                screenAtividade: 'trabalhoPadronizado',
                anexos: {
                    data: [],
                    title: 'Trabalho Padronizado',
                    defaultIcon: 'trabalho-padronizado',
                }
            },
            mfv: {
                data: {},
                icon: 'mfv.svg',
                label: 'Mapa de Fluxo de Valor (MFV)',
                screenAtividade: 'mfv',
                anexos: {
                    data: [],
                    title: 'Mapa de Fluxo de Valor (MFV)',
                    defaultIcon: '',
                }
            },
            producaoPuxada: {
                data: {},
                icon: 'producaoPuxada.svg',
                label: 'Produção Puxada',
                screenAtividade: 'producaoPuxada',
                anexos: {
                    data: [],
                    title: 'Produção Puxada',
                    defaultIcon: 'producao-puxada',
                }
            },
            outrasFerramentasList: []
        },
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '4h',
        totalAtividades: 11
    },

    etapaConsultoriaC: {
        etapa: {
            etapaFinalizada: false,
        },
        visitas: {
            title: 'Consultoria',
            data: [],
        },
        atividades: {
            planoDeAcao: {
                data: {},
                icon: 'plano-de-acao.svg',
                label: 'Plano de ação',
                screenAtividade: 'planoDeAcao',
                anexos: {
                    data: [],
                    title: 'Plano de ação',
                    defaultIcon: '',
                },
                dontCount: true
            },
            processoProdutivo: {
                data: {},
                icon: 'processo-produtivo.svg',
                label: 'Processo produtivo',
                screenAtividade: 'processoProdutivo',
                anexos: {
                    data: [],
                    title: 'Processo produtivo',
                    defaultIcon: '',
                },
                dontCount: true
            },
            linhaDeProducao: {
                data: {},
                icon: 'linha-de-producao.svg',
                label: 'Linha de Produção',
                screenAtividade: 'linhaDeProducao',
                anexos: {
                    data: [],
                    title: 'Linha de Produção',
                    defaultIcon: '',
                },
                dontCount: true
            },
            desperdicio: {
                data: {},
                icon: 'desperdicio.svg',
                label: 'Desperdícios',
                screenAtividade: 'desperdicios',
                anexos: {
                    data: [],
                    title: 'Desperdícios',
                    defaultIcon: '',
                },
                dontCount: true
            },
        },
        ferramentas: {
            fluxoContinuo: {
                data: {},
                icon: 'fluxoContinuo.svg',
                label: 'Fluxo Contínuo',
                screenAtividade: 'fluxoContinuo',
                anexos: {
                    data: [],
                    title: 'Fluxo Contínuo',
                    defaultIcon: 'fluxo-continuo',
                }
            },
            qualidadeNaFonte: {
                data: {},
                icon: 'qualidadeNaFonte.svg',
                label: 'Qualidade na Fonte',
                screenAtividade: 'qualidadeNaFonte',
                anexos: {
                    data: [],
                    title: 'Qualidade na Fonte',
                    defaultIcon: 'qualidade-fonte',
                }
            },
            cincoS: {
                data: {},
                icon: 'cincoS.svg',
                label: '5S',
                screenAtividade: 'cincoS',
                anexos: {
                    data: [],
                    title: '5S',
                    defaultIcon: 'cinco-s',
                }
            },
            trocaRapidaFerramenta: {
                data: {},
                icon: 'trocaRapidaFerramenta.svg',
                label: 'Troca Rápida de Ferramenta',
                screenAtividade: 'trocaRapidaFerramenta',
                anexos: {
                    data: [],
                    title: 'Troca Rápida de Ferramenta',
                    defaultIcon: 'troca-rapida-ferramenta',
                }
            },
            trabalhoPadronizado: {
                data: {},
                icon: 'trabalhoPadronizado.svg',
                label: 'Trabalho Padronizado',
                screenAtividade: 'trabalhoPadronizado',
                anexos: {
                    data: [],
                    title: 'Trabalho Padronizado',
                    defaultIcon: 'trabalho-padronizado',
                }
            },
            mfv: {
                data: {},
                icon: 'mfv.svg',
                label: 'Mapa de Fluxo de Valor (MFV)',
                screenAtividade: 'mfv',
                anexos: {
                    data: [],
                    title: 'Mapa de Fluxo de Valor (MFV)',
                    defaultIcon: '',
                }
            },
            producaoPuxada: {
                data: {},
                icon: 'producaoPuxada.svg',
                label: 'Produção Puxada',
                screenAtividade: 'producaoPuxada',
                anexos: {
                    data: [],
                    title: 'Produção Puxada',
                    defaultIcon: 'producao-puxada',
                }
            },
            outrasFerramentasList: []
        },
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '12h',
        totalAtividades: 7,     
    },

    etapaConsultoriaD: {
        etapa: {
            etapaFinalizada: false,
        },
        visitas: {
            title: 'Consultoria',
            data: [],
        },
        atividades: { 
            tecnologiasDigitais: {
                data: {},
                icon: 'tecnologias-digitais.svg',
                label: 'Tecnologias Digitais',
                screenAtividade: 'tecnologiasDigitais',
                anexos: {
                    data: [],
                    title: 'Tecnologias Digitais',
                    defaultIcon: '',
                },
            },
            depoimentos: {
                data: {},
                icon: 'depoimentos.svg',
                label: 'Depoimentos',
                screenAtividade: 'depoimentos',
                anexos: {
                    data: [],
                    title: 'Depoimentos',
                    defaultIcon: 'depoimentos_',
                },
            },
            avaliacao: {
                data: {},
                icon: 'avaliacao-digital.svg',
                label: 'Avaliação do Atendimento',
                screenAtividade: 'avaliacao',
                anexos: {
                    data: [],
                    title: 'Avaliação do Atendimento',
                    defaultIcon: '',
                },
                disabled: false
            },
            resultado: {
                data: {},
                icon: 'resultados.svg',
                label: 'Resultados',
                screenAtividade: 'resultado',
                anexos: {
                    data: [],
                    title: 'Resultados',
                    defaultIcon: '',
                },
            },
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '4h',
        totalAtividades: 4
    },

};