import React, { Component } from 'react';
import { Grid,Tooltip } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import { withStyles} from "@material-ui/core/styles";
import FormField from '../../components/formField/formField';

export default class AtividadeProcessoProdutivo extends Component {

	constructor(props) {
        super(props);
        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let disableEditAntes = etapa.etapa >= 8; 
        let tab = !disableEditAntes ? 'Antes' : 'Depois';
        let formAntes = tab === 'Antes' ? props.atividade.data : turma.etapaPraticaC.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).processoProdutivo; //sempre mentoria prática c
        let formDepois = tab === 'Depois' ? props.atividade.data : turma.etapaPraticaD.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).processoProdutivo; //sempre mentoria prática d
        props.showTopButtons(true);
        props.switchAnexos(null);
        this.state = { tab: tab, disableDepois: turma.etapaAtual < 8, disableEditAntes: disableEditAntes, formAntes: formAntes, formDepois: formDepois, errorsAntes: this.getErrorsModel(), errorsDepois: this.getErrorsModel() };
    };

    getErrorsModel() {
    	return { operadoresPorTurno: '', numeroDeTurnos: '', horasPorTurno: '', capacidadeProdutiva: '', horasPorOperador: '', producaoPorTurno: '', retrabalhoPorTurno: '', 
        	materiaPrima: '', insumos: '', descarte: '', superproducao: '',  produtividade: '' };
    };

    changeTab = (value, newValue) => {
        let atividade = newValue === 'Antes' ? this.state.formAntes : this.state.formDepois;
        let showTopButtons = (newValue === 'Antes' && !this.state.disableEditAntes) || (newValue === 'Depois' && this.state.disableEditAntes);
        this.props.showTopButtons(showTopButtons);
        this.props.switchAnexos(showTopButtons ? this.props.atividade.anexos.data : atividade.anexoProcessoProdutivoList);
        this.setState({ tab: newValue });
    };

	changeInput = (e) => {
        let atividade = this.state.tab === 'Antes' ? this.state.formAntes : this.state.formDepois;
        let errors = this.state.tab === 'Antes' ? this.state.errorsAntes : this.state.errorsDepois;
        if (e && e.target && e.target.id) {
            atividade[e.target.id] = e.target.value === '' ? null : Number(e.target.value);
            if (e.target.required) {
                if (e.target.value === '0' || e.target.value === '0.00') errors[e.target.id] = 'Valor deve ser maior que 0.';
                else if (e.target.value) errors[e.target.id] = '';
                else errors[e.target.id] = 'Campo obrigatório.';
            } 
            if (this.state.tab === 'Antes') this.setState({ formAntes: atividade, errorsAntes: errors });
            else if (this.state.tab === 'Depois') this.setState({ formDepois: atividade, errorsDepois: errors });
        } 
    };

    calculaProdutividade = (atividade) => {
        let resultado = 0;
        if (atividade.producaoPorTurno && atividade.operadoresPorTurno && atividade.horasPorTurno) {
            resultado = Number((atividade.producaoPorTurno / (atividade.operadoresPorTurno * atividade.horasPorTurno)).toFixed(6));
            atividade.produtividade = resultado;
        }
        return resultado;
    };

    calculaQualidade = (atividade) => {
        let resultado = 0;
        if (atividade.retrabalhoPorTurno && atividade.operadoresPorTurno && atividade.horasPorTurno) {
            resultado = Number((atividade.retrabalhoPorTurno / (atividade.operadoresPorTurno * atividade.horasPorTurno)).toFixed(6));
            atividade.qualidade = resultado;
        }
        return resultado;
    };

	renderBody = () => {
        let atividade = this.state.tab === 'Antes' ? this.state.formAntes : this.state.formDepois;
        let disabled = (this.state.tab === 'Antes' && this.state.disableEditAntes) || (this.state.tab === 'Depois' && !this.state.disableEditAntes);
        let errors = this.state.tab === 'Antes' ? this.state.errorsAntes : this.state.errorsDepois;

        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);

        return (
            <Grid container justify={'center'} spacing={1} className={'grid-body'} key={this.state.tab + atividade.id}>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'operadoresPorTurno'} key={'operadoresPorTurno'} label={'Operadores por turno'} disabled={disabled || this.props.disabled} required={true}
                        changeValue={this.changeInput} inputValue={atividade.operadoresPorTurno} min='1' maxLength='15' subtype={'seiscasas'} error={errors.operadoresPorTurno} />
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'numeroDeTurnos'} key={'numeroDeTurnos'} label={'Nº de turnos'} disabled={disabled || this.props.disabled} required={true}
                        changeValue={this.changeInput} inputValue={atividade.numeroDeTurnos} min='1' maxLength='15' subtype={'seiscasas'} error={errors.numeroDeTurnos} />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'horasPorTurno'} key={'horasPorTurno'} label={'Horas por turno'} disabled={disabled || this.props.disabled} required={true}
                        changeValue={this.changeInput} inputValue={atividade.horasPorTurno} min='1' maxLength='15' subtype={'seiscasas'} error={errors.horasPorTurno}/>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'capacidadeProdutiva'} key={'capacidadeProdutiva'} label={'Capacidade produtiva'} disabled={disabled || this.props.disabled} required={true}
                        changeValue={this.changeInput} inputValue={atividade.capacidadeProdutiva} min='1' maxLength='15' subtype={'seiscasas'} error={errors.capacidadeProdutiva}/>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'horasPorOperador'} key={'horasPorOperador'} label={'Horas por operador'} disabled={disabled || this.props.disabled} required={true}
                        changeValue={this.changeInput} inputValue={atividade.horasPorOperador} min='1' maxLength='15' subtype={'seiscasas'} error={errors.horasPorOperador} />
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'movimentacao'} key={'movimentacao'} label={'Movimentação'} disabled={disabled || this.props.disabled}
                        changeValue={this.changeInput} inputValue={atividade.movimentacao} min='0' maxLength='15' subtype={'seiscasas'} />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'producaoPorTurno'} key={'producaoPorTurno'} label={'Produção por turno'} disabled={disabled || this.props.disabled} required={true}
                        changeValue={this.changeInput} inputValue={atividade.producaoPorTurno} min='1' maxLength='15' subtype={'seiscasas'} error={errors.producaoPorTurno}/>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'retrabalhoPorTurno'} key={'retrabalhoPorTurno'} label={'Retrabalho por turno'} disabled={disabled || this.props.disabled} required={true}
                        changeValue={this.changeInput} inputValue={atividade.retrabalhoPorTurno} min='1' maxLength='15' subtype={'seiscasas'} error={errors.retrabalhoPorTurno}/>
                </Grid>
                { this.state.tab === 'Depois' && 
                	<>
                	<Grid item xs={12}></Grid>
                	<Grid item xs={12} md={4} xl={3}>
	                    <FormField type={'number'} id={'retornoDoPrograma'} key={'retornoDoPrograma'} label={'Retorno do programa'} disabled={disabled || this.props.disabled} required={true}
	                        changeValue={this.changeInput} inputValue={atividade.retornoDoPrograma} min='1' maxLength='15' subtype={'seiscasas'} error={errors.retornoDoPrograma} />
	                </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <FormField type={'number'} id={'memoriaDeCalculo'} key={'memoriaDeCalculo'} label={'Memória de cálculo'} disabled={disabled || this.props.disabled} required={true}
                            changeValue={this.changeInput} inputValue={atividade.memoriaDeCalculo} min='1' maxLength='15' subtype={'seiscasas'} error={errors.memoriaDeCalculo} />
                    </Grid>
	                </> }
	            <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <label className={'label-processo'}>Desperdícios de estoque</label>
                </Grid>
                <Grid item xs={12} md={4} xl={3}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'materiaPrima'} key={'materiaPrima'} label={'Matéria-prima (R$/mês)'} disabled={disabled || this.props.disabled} required={false}
                        enableEmpty={true} changeValue={this.changeInput} inputValue={atividade.materiaPrima} min='1' maxLength='16' subtype={'seiscasas'}/>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'retrabalho'} key={'retrabalho'} label={'Retrabalho (R$/mês)'} disabled={disabled || this.props.disabled} required={false}
                        enableEmpty={true} changeValue={this.changeInput} inputValue={atividade.retrabalho} min='0' maxLength='16' subtype={'seiscasas'}  />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'descarte'} key={'descarte'} label={'Descarte (R$/mês)'} disabled={disabled || this.props.disabled} required={false}
                        enableEmpty={true} changeValue={this.changeInput} inputValue={atividade.descarte} min='1' maxLength='16' subtype={'seiscasas'} />
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'superproducao'} key={'superproducao'} label={'Superprodução (R$/mês)'} disabled={disabled || this.props.disabled} required={false}
                        enableEmpty={true} changeValue={this.changeInput} inputValue={atividade.superproducao} min='1' maxLength='16' subtype={'seiscasas'} />
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <FormField type={'number'} id={'insumos'} key={'insumos'} label={'Insumos (R$/mês)'} disabled={disabled || this.props.disabled} required={false}
                        enableEmpty={true} changeValue={this.changeInput} inputValue={atividade.insumos} min='1' maxLength='16' subtype={'seiscasas'} />
                </Grid>
                <Grid item xs={12} md={4} xl={3}></Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <label className={'label-processo'}>Produtividade</label>
                </Grid>
                <Grid item xs={12} md={4} xl={3}>
                    <label className={'label-processo'}>Qualidade</label>
                </Grid>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={4} xl={3} key={'produtividade'}>
                    <div className={'grid-indicador'}>
                        <ModifiedTooltip 
                            title={ 
                            <div>Produção por turno<br />
                            <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                            Operadores por turno<br />
                            <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                            Horas por turno</div>}
                            placement="right" arrow>
                                <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                        </ModifiedTooltip>
                        {this.calculaProdutividade(atividade).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}/hh
                    </div>
                </Grid>
                <Grid item xs={12} md={4} xl={3} key={'qualidade'}>
                    <div className={'grid-indicador'}>
                        <ModifiedTooltip 
                            title={ 
                            <div>Retrabalho por Turno<br />
                            <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                            Operadores por turno<br />
                            <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                            Horas por turno</div>}
                            placement="right" arrow>
                                <img style={{height: 17, width: 17}} src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} alt={'icon'} />
                        </ModifiedTooltip>
                        {this.calculaQualidade(atividade).toLocaleString('pt-BR', { minimumFractionDigits: 6, maximumFractionDigits: 6 })}
                    </div>
                </Grid>
            </Grid>
        );
    };

	render() {
        return (
            <Grid container className={'atividade-processo-produtivo'} style={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                    <AppBar position='static'>
                        <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                            <Tab key={'Antes'} value={'Antes'} label={'Antes'} disabled={false}></Tab>
                            <Tab key={'Depois'} value={'Depois'} label={'Depois'} disabled={this.state.disableDepois}></Tab>
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item xs={12}>
                    { this.renderBody() }
                </Grid>
            </Grid>
        )
    };

}