import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

Sentry.init({
  dsn: "https://4fa742d38ca242d9963f95d5e4fef0da@sentry.isitics.com/2",
  integrations: [new BrowserTracing({ tracingOrigins: ["*"] }), new Sentry.Replay({maskAllText: false, maskAllInputs: false, blockAllMedia: false})],
  
   beforeSend: (event) => {
    // Desabilita o Sentry no Localhost
    if (window.location.hostname === "localhost") {
      return null;
    }
    return event;
  },
  tracesSampleRate: 0.2,
  replaysOnErrorSampleRate: 1.0
});



ReactDOM.render(<App />, document.getElementById("root"));
