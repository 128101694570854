import React, { Component } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import CheckIcon from '@material-ui/icons/Check';
import Icon from '@material-ui/core/Icon';
import { Redirect } from 'react-router-dom';
import './toast.scss';

export default class Toast extends Component {

	constructor(props) {
		super(props);
		this.state = { showToast: false, toastType: '', toastMsg: '', toastIcon: '', redirect: false, redirectLink: '', redirectState: null };
		if (props.getFunctions) props.getFunctions(this.showToast, null, null);
	};

	handleClose = (event, reason) => {
		if (reason === 'clickaway') return;
		this.setState({ showToast: false });
	};

	showToast = (msg, type, redirectLink, redirectState=null) => {
		var icon = type === 'success' ? <Icon id={'icone'}><CheckIcon /></Icon> : <Icon id={'icone'}><PriorityHighIcon /></Icon>;
		this.setState({ toastMsg: msg, toastType: type, showToast: true, toastIcon: icon, redirectLink: redirectLink, redirectState: redirectState });
		if (redirectLink) {
			setTimeout(() => { this.setState({ redirect: true }) }, 2630);
		}
	};

	render() {
		if (this.state.redirect) {
			return (<Redirect 
						to={{
							pathname: this.state.redirectLink,
							state: this.state.redirectState
						}} 
					/>
			);
		} else {
			return (
				<div>
					<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'left' }} open={this.state.showToast} autoHideDuration={2600}
						ContentProps={{ 'aria-describedby': 'message-id' }} message={<span id='message-id'>{this.state.toastIcon}{this.state.toastMsg}</span>}
						onClose={this.handleClose} className={'toast ' + this.state.toastType} />
					{this.state.redirectLink !== '' && this.state.redirectLink != null ? <div className={'snack-bar-class'}></div> : null}
				</div>
			);
		}
	};
}
