import React, { Component } from 'react';
import API from '../../../comm/API';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Grid } from '@material-ui/core';
import FormField from '../../../components/formField/formField';

export default class GraficoCadastradoConcluido extends Component {

    constructor(props) {
    	super(props);
    	let dataInicio = new Date();
        let dataTermino = new Date();
        dataInicio.setDate(1);
        dataInicio.setMonth(0);
        dataTermino.setDate(31);
        dataTermino.setMonth(11);
    	this.state = {
    		dataInicio: dataInicio.toISOString().split('T')[0],
    		dataTermino: dataTermino.toISOString().split('T')[0], 
    		errors: {
    			dataInicio: '',
    			dataTermino: ''
    		},
    		series: [{ name: 'STATUS', data: [0] }], 
    		categories: ['MES-ANO'],
    		totalInfo: [
    			{ titulo: 'Atendimentos concluídos*', valor: 0}, 
    			{ titulo: 'Atendimentos aceitos/em execução*', valor: 0},
    			{ titulo: 'Atendimentos aceitos/contratados*', valor: 0}
    		]
    	};
    	Highcharts.setOptions({
            lang: {
                decimalPoint: ',',
                thousandsSep: '.'
            }
        });
        this.getInfos();
        this.getInfosTotal();
    };

    getFiltro = () => {
    	let filtro = {};
    	filtro['dataInicio'] = this.state.dataInicio;
    	filtro['dataTermino'] = this.state.dataTermino;
    	return filtro;
    };

    getInfos = () => {
    	API.get('rest/sistema/DashboardBrasilMais/geralCadastradoConcluido', { params: this.getFiltro() } ).then(res => {
			if (res.data != null) {
				let results = res.data;
				let series = [];
				if (results.abdi != null) series.push({ name: 'Cadastramento na ABDI', data: results.abdi[0], color: '#D5D9E1', marker: { fillColor: 'white', lineWidth: 2, lineColor: '#D5D9E1' }});
				series.push({ name: 'Atendimentos aceitos/contratados', data: results.aceito[0], color: '#B7EE8E', marker: { fillColor: 'white', lineWidth: 2, lineColor: '#B7EE8E' }});
				series.push({ name: 'Atendimentos aceitos/em execução', data: results.execucao[0], color: '#7DAE59', marker: { fillColor: 'white', lineWidth: 2, lineColor: '#7DAE59' }});
				series.push({ name: 'Atendimentos concluídos', data: results.concluido[0], color: '#00B1E8', marker: { fillColor: 'white', lineWidth: 2, lineColor: '#00B1E8' }});
				let categories = results.eixoX[0];
				this.setState({ categories, series });
			}
        }).catch(error => {
            console.log(error);
        });
    };

    getInfosTotal = () => {
    	API.get('rest/sistema/DashboardBrasilMais/geralCadastradoConcluidoTotal').then(res => {
			if (res.data != null) {
				let list = res.data;
                let totalInfo = [
                    { titulo: 'Cadastramento na ABDI*', valor: list[0]},
                    { titulo: 'Atendimentos concluídos*', valor: list[1]},
                    { titulo: 'Atendimentos aceitos/em execução*', valor: list[2]},
                    { titulo: 'Atendimentos aceitos/contratados*', valor: list[3]}
                ];
                if (!totalInfo[0].valor) totalInfo = totalInfo.slice(1, totalInfo.length);
				this.setState({ totalInfo: totalInfo });
			}
        }).catch(error => {
            console.log(error);
        });
    };

    changeInput = async (e) => {
    	let errors = this.state.errors;
    	let dataInicio = this.state.dataInicio;
    	let dataTermino = this.state.dataTermino;
        if (e.target.value != null && e.target.value !== '' && (e.target.id === 'dataInicio' || e.target.id === 'dataTermino')) {
        	let newData = e.target.value;
            if (e.target.id === 'dataInicio' && newData > dataTermino) {
                errors[e.target.id] = 'Data inválida';
                this.setState({ [e.target.id] : e.target.value, errors: errors });
            } else if (e.target.id === 'dataTermino' && newData < dataInicio) {
            	errors[e.target.id] = 'Data inválida';
            	this.setState({ [e.target.id] : e.target.value, errors: errors });
            } else {
                errors['dataInicio'] = '';
                errors['dataTermino'] = '';
                await this.setState({ [e.target.id] : e.target.value, errors: errors });
                this.getInfos();
            }
        }
    };

    loadChartConfigs = () => {
    	let eixoX = this.state.categories;
    	let eixoY = this.state.series;
    	let plotLines = []
    	for (let i = 0; i < eixoX.length; i++) {
    		plotLines.push({ color: '#F2F1F6', value: i, width: 1 });
    	}
        return {
            chart: {
                type: 'line',
                sytle: {
                    fontFamily: 'Raleway',
                    color: '#707070'
                },
                marginRight: 0,
                height: this.props.isDN ? '350px' : '250px',
            },
            credits: { 
            	enabled: false 
            },
          	legend: {
                className: 'highcharts-graph-legend',
                itemStyle:{'font-weight':'500','color': '#707070' , 'font-size':'12px', "cursor":"auto", "display":"flex", "align-items":"center"},
		        
		    },
            tooltip: {
                formatter: function() {
	                return `<p style='font: 11px Raleway; font-weight: 500; color: #707070'>${this.point.y.toLocaleString('pt-BR')}</p><br>`;
                },
				outside: false,
				borderWidth: 1,
				borderColor: '#B4B8C0',
				shadow: false,
				followPointer: true,
				backgroundColor: 'rgba(255,255,255)'
			},
            yAxis: {
                visible: true,
                title: { text: '' },
                min: 0,
                lineColor: '#707070',
                lineWidth: 1.5,
                labels: {
                	style: {
                		color: '#707070'
                	}
                },
                allowDecimals: false,
                gridLineWidth: 0
            },
            title: { text: '' },
            xAxis: {
                categories: eixoX,
                lineColor: '#707070',
                lineWidth: 1.5,
                labels: {
                	style: {
                		color: '#707070'
                	}
                },
                plotLines: plotLines,
                //gridLineWidth: 1
            },
            series: eixoY,
            plotOptions: {
                line: {
                    events: {
                        legendItemClick: function () {
                            return false;
                        }
                    },
                    enableMouseTracking: true,
                    shadow: false
                },
                allowPointSelect: false,
                series: {
                	marker: {
                		symbol: 'circle'
                	},
                	shadow: false,
                	states: {
		                hover: {
		                    enabled: false
		                },
		                inactive: {
                         	opacity: 1
                        }
		            }
                }
            },
            exporting: {
				menuItemDefinitions: {
					// Custom definition
					printChart: {
						text: 'Imprimir gráfico'
					},
					downloadPNG:{
						text: 'Baixar como PNG'
					},
					downloadJPEG:{
						text: 'Baixar como JPEG'
					},
					downloadSVG:{
						text: 'Baixar como SVG'
					},
					viewFullscreen:{
						text: 'Ver em tela cheia'
					},
					downloadCSV:{
						text: 'Baixar tabela de dados'
					}
				},
                chartOptions: { // specific options for the exported image
                    plotOptions: {
                        series: {
                            dataLabels: {
                                enabled: true
                            }
                        }
                    }
                },
				fallbackToExportServer: false,
				buttons: {
					contextButton: {
						menuItems:[ "printChart", "viewFullscreen", "downloadCSV", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"]
					}
				}
            }
        };
    };

    render() {
    	let chart = this.loadChartConfigs();
    	return (
            <Grid container className={'border-cinza'} justify={'center'} style={{ height: '100%' }}>
        		<Grid item xs={12} md={11}>
                    <div className={'grafico-cadastrado-concluido'} style={{ width: '100%' }}>
            			<h1 className={'mapa-do-brasil-title'}>Empresas cadastradas x atendimentos concluídos por mês</h1>
            			<div className={'cadastrado-concluido-total'} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginBottom: '15px' }}>
            				{ this.state.totalInfo.map(aux => {
            					return (
            						<label key={aux.titulo}>{aux.titulo}: <b>{aux.valor.toLocaleString('pt-BR')}</b></label>
            					)})}
            				<br />
            				<label style={{ fontSize: 10 }}>*Consolidado desde <br />o início do Brasil Mais</label>
            			</div>
            			<HighchartsReact highcharts={Highcharts} options={chart} />
            			<div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', marginBottom: '20px'}}>
            				<Grid container spacing={2} justify={'flex-end'}>
                                <Grid item xs={6} md={3} xl={2}>
                                    <label>Início</label>
                                    <FormField type={'date'} id={'dataInicio'} inputValue={this.state.dataInicio}
                                        changeValue={this.changeInput} error={this.state.errors.dataInicio} icon={'calendar.svg'} disabled={false} />
                                </Grid>
                                <Grid item xs={6} md={3} xl={2}>
                                    <label>Término</label>
                                    <FormField type={'date'} id={'dataTermino'} inputValue={this.state.dataTermino}
                                        changeValue={this.changeInput} error={this.state.errors.dataTermino} icon={'calendar.svg'} disabled={false} />
                                </Grid>
                            </Grid>
            			</div>
            		</div>
                </Grid>
            </Grid>
    	);
    };

}
