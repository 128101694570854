import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import PageBrasilMais from './pageBrasilMais';
import GenericButton from '../../components/genericButton/genericButton';
import './atendimentosCard.scss';

export default class AtendimentosCard extends Component {

    constructor(props) {
        super(props);
        this.status = {
            CANCELED: { title: 'Cancelado', statusClass: 'card-canceled', icon: 'producao_white.svg' },
            FINALIZED: { title: 'Finalizado', statusClass: 'card-finalized', icon: 'producao_white.svg' },
            PROGRESS: { title: 'Em progresso', statusClass: '', icon: 'producao.svg' },
            CONCLUDED: { title: 'Concluído', statusClass: 'card-concluded', icon: 'producao_white.svg' },
        }
    };

    getStatus = (atendimento) => {
        return (atendimento.atendimentoStatus === 7) ? this.status.CANCELED :
                (atendimento.atendimentoStatus === 6) ? this.status.CONCLUDED : 
                (atendimento.finalizado) ? this.status.FINALIZED : this.status.PROGRESS;
    };

    renderAtendimentos = () => {
       return (
            <Grid container justify='center' item xs={12} id={'atendimentos-card'}>
                <Grid container item xs={12} md={12} lg={8} className={'wrapper-cards'}>
                    { this.props.atendimentos.map((atendimento, index) => {
                        const status = this.getStatus(atendimento);
                        return (
                            <Grid container item xs={12} md={5} xl={4} key={index} className={`card-default ${status.statusClass}`}
                                  onClick={() => { if (status.title !== 'Cancelado') this.props.renderVisita(atendimento); }}>
                                <Grid item xs={9} className={'information-side'}>
                                    <p className={'status'}>{status.title}</p>
                                    <p className={'title'}>{atendimento.clientName}</p>
                                </Grid>
                                <Grid item xs={3} className={'appropriate-hours-side'}>
                                    <GenericButton 
                                        inverseOrder={true}
                                        label={`${atendimento.horasApropriadas} h`}
                                        subClass={'icon-button button-visita'}
                                        icon={status.icon} 
                                    />
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        );
    };

    renderFooterButtons = () => {
        return (
            <Grid item xs={12} className={'bottom-buttons'}>
                <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Retornar à Trilha'} click={this.props.goBack} />
            </Grid>
        )
    };

    render() {
        return(
            <PageBrasilMais
                name={this.props.turma.nome}
                fase={this.props.fase}
                title={ this.props.title }>
                { this.renderAtendimentos() }
                { this.renderFooterButtons() }
            </PageBrasilMais>
        )
    };

}   