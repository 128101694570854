import React, { Component } from 'react';
import { Grid, Switch } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import FormField from '../formField/formField';
import API from '../../comm/API';
import AutoComplete from '../autoComplete/autoComplete';
import GenericButton from '../../components/genericButton/genericButton';
import './graphBarHorizontal.scss';

export default class GraphBarHorizontal extends Component {

    constructor(props) {
        super(props);
        let dataInicio = new Date();
        let dataTermino = new Date();
        dataInicio.setDate(1);
        dataInicio.setMonth(0);
        dataTermino.setDate(31);
        dataTermino.setMonth(11);
        this.state = {
            date: { 
                dataInicio: dataInicio.toISOString().split('T')[0],
                dataTermino: dataTermino.toISOString().split('T')[0]
            },
            buscaUnidade: '',
            errors: { dataInicio: '' },
            flagSwitch: props.init === 'right' ? true : false,
            title: props.init === 'right' ? props.switch[1].title : props.switch[0].title,
            seriesLeft: props.switch[0].series ? props.switch[0].series : [],
            seriesRight: props.switch[1] && props.switch[1].series ? props.switch[1].series : [],
            categoriesLeft: props.switch[0].categories ? props.switch[0].categories : [],
            categoriesRight: props.switch[1] && props.switch[1].categories ? props.switch[1].categories : [],
            unidades: [],
            heightLeft: 0,
            heightRight: 0,
            limiteLeft: 5,
            limiteRight: 5
        }
        if (this.props.hasFilter || this.props.switch[0].seriesFunction) this.changeInput({ target: {id: 'dataInicio', value: dataInicio.toISOString().split('T')[0] }});
        this.getUnidadesRegional();
    }

    createGrafico = (index) => {
        let series = index === 0 ? this.state.seriesLeft : this.state.seriesRight;
        let height = index === 0 ? this.state.heightLeft : this.state.heightRight;
        let categories = index === 0 ? this.state.categoriesLeft : this.state.categoriesRight;
        let options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Raleway'
                },
                height: height
            },
            xAxis: {
                gridLineColor: '#B4B8C0',
                gridLineWidth: 1,
                tickmarkPlacement:'between',
                labels: {
                    style: {
                        width: '400px',
                        textOverflow: 'allow',
                    },
                    useHTML: true,
                    formatter: function () {
                        return '<div class="horizontal-bar-graph"> <label>' + this.value + '</label> </div>'
                    }
                },
                lineColor: '#707070',
                lineWidth: 1.5,
                categories: categories
            },
            yAxis: {
                gridLineWidth: 0,
                lineColor: '#707070',
                min: 0,
                title: {
                    text: ''
                }
            },
            series: series,
            credits: {
                enabled: false
            },
            exporting: false
        };
        if (this.props.switch[index].plotOptions) options.plotOptions = this.props.switch[index].plotOptions;
        if (this.props.switch[index].legend) options.legend = this.props.switch[index].legend;
        if (this.props.switch[index].tickInterval != null) options.yAxis.tickInterval = this.props.switch[index].tickInterval;
        if (this.props.switch[index].tooltip) options.tooltip = this.props.switch[index].tooltip;
        if (this.props.switch[index].plotLines) options.yAxis.plotLines = this.props.switch[index].plotLines;
        if (this.props.switch[index].labelsY) options.yAxis.labels = this.props.switch[index].labelsY;
        if (this.props.switch[index].gridLineWidthY != null) options.yAxis.gridLineWidth = this.props.switch[index].gridLineWidthY;

        return options;
    };

    formatData = (date) => {
        date = date + 'T00:00:00';
        let dataList;
        if (typeof (date) !== 'string') {
            dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

    changeInput = async (e) => {
        let dateObj = this.state.date;
        let buscaUnidade = this.state.buscaUnidade;
        let errors = this.state.errors;
        let heightLeft = this.state.heightLeft;
        let heightRight = this.state.heightRight;
        let limiteLeft = this.state.limiteLeft;
        let limiteRight = this.state.limiteRight;
        let flagSwitch = this.state.flagSwitch;
        let seriesLeft = this.state.seriesLeft;
        let seriesRight = this.state.seriesRight;
        let categoriesLeft = this.state.categoriesLeft;
        let categoriesRight = this.state.categoriesRight;

        const callSeriesFunction = async () => {
            let retornoLeft = await this.props.switch[0].seriesFunction(dateObj.dataInicio, dateObj.dataTermino, buscaUnidade, limiteLeft);
            if (retornoLeft[0]) seriesLeft = retornoLeft[0];
            if (retornoLeft[1]) categoriesLeft = retornoLeft[1];
            if(retornoLeft[2]) heightLeft = retornoLeft[2]
            if (this.props.switch.length === 2) {
                let retornoRight = await this.props.switch[1].seriesFunction(dateObj.dataInicio, dateObj.dataTermino, buscaUnidade, limiteRight);
                if (retornoRight[0]) seriesRight = retornoRight[0];
                if (retornoRight[1]) categoriesRight = retornoRight[1];
                if(retornoRight[2]) heightRight = retornoRight[2]
            }
        }

        if (e.target && (e.target.id === 'dataInicio' || e.target.id === 'dataTermino')) {
            dateObj[e.target.id] = e.target.value;
            if (this.formatData(dateObj.dataInicio) > this.formatData(dateObj.dataTermino)) {
                errors['dataInicio'] = 'Data inválida';
            } else {
                errors['dataInicio'] = '';
                await callSeriesFunction();
            }
        } else if (e.target && e.target.id === 'switch') flagSwitch = !flagSwitch;
        
        else if (e.target && e.target.id === 'load') {
            if (this.state.flagSwitch) limiteRight = limiteRight + 5;
            else limiteLeft = limiteLeft + 5;
            await callSeriesFunction();
        } else {
            if (e != null && e[1] != null)
                buscaUnidade = e[1];
                await callSeriesFunction();
        }
        this.setState({ 
            flagSwitch, date: dateObj, errors, seriesRight, seriesLeft, categoriesRight, categoriesLeft, 
            buscaUnidade: buscaUnidade, heightLeft: heightLeft, heightRight: heightRight, limiteLeft: limiteLeft,
            limiteRight: limiteRight
        });
    };

    getUnidadesRegional = async () => {
        await API.get('rest/gestao/Unidade/unidadesPorUsuario').then(async res => {
            if (res.data != null) {
                this.setState({ unidades: res.data })
            }
        }).catch(error => {
            console.log(error);
        });
    };
   
    render() {
        let title = this.state.flagSwitch ? this.props.switch[1].title : this.props.switch[0].title;
        let currGraph = this.state.flagSwitch ? this.createGrafico(1) : this.createGrafico(0);
        return (
            <Grid container justify={'center'} className={'border-cinza'}>
                <Grid container justify={'center'}>
                    <Grid item xs={12} md={11}>
                        <h1 className={'mapa-do-brasil-title'}>{title}</h1>
                        <Grid container direction={'row'} justify={'space-between'} spacing={3}>
                            <Grid item xs={6} md={4} xl={3} style={{ marginTop: '8px' }}>
                                <AutoComplete
                                    suggestions={this.state.unidades}
                                    itemKey={0}
                                    itemlabel={1}
                                    button={false}
                                    getInput={(e) => { this.changeInput(e) }} 
                                    initialSelectedItem={['']} 
                                    disabled={this.props.perfil === 'UO'}/>
                                
                            </Grid>
                            <Grid item xs={12} md={7}>
                                <Grid container spacing={2} justify={'flex-end'}>
                                    <Grid item xs={6} md={4} xl={3}>
                                        <label>Início</label>
                                        <FormField type={'date'} id={'dataInicio'} inputValue={this.state.date.dataInicio}
                                            changeValue={this.changeInput} error={this.state.errors.dataInicio} icon={'calendar.svg'} disabled={false} />
                                    </Grid>
                                    <Grid item xs={6} md={4} xl={3}>
                                        <label>Término</label>
                                        <FormField type={'date'} id={'dataTermino'} inputValue={this.state.date.dataTermino}
                                            changeValue={this.changeInput} error={this.state.errors.dataTermino} icon={'calendar.svg'} disabled={false} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop: '4%' }}>
                            <HighchartsReact key={title}
                                highcharts={Highcharts}
                                options={currGraph} />
                        </div>
                        <Grid container direction={'row'} justify={'space-between'} spacing={3} style={{ marginTop: '15px', marginBottom: '15px' }}>
                            <Grid item xs={12} md={5} style={{marginTop: '10px'}}>
                                { this.props.switch.length === 2 &&
                                    <Grid container spacing={2}>
                                        <Grid item><label>{this.props.switch[0].label}</label></Grid>
                                        <Grid item style={{ padding: 5}}>
                                            <Switch disabled={this.props.disabledSwitch} id={'switch'} checked={this.state.flagSwitch} onChange={this.changeInput} color='default' />
                                        </Grid>
                                        <Grid item><label>{this.props.switch[1].label}</label></Grid>
                                    </Grid> }
                            </Grid>
                            { !this.props.hideCarregaMais && 
                                <Grid item xs={12} md={6}>
                                    <Grid container spacing={2} justify={'flex-end'}>
                                        <Grid item xs={6} md={4} xl={2}>
                                            <GenericButton color={'darkBlue-outline'} label={'Carregar mais'} id={'load'} subClass={'basic-button'}
                                                click={this.changeInput} />
                                        </Grid>
                                    </Grid>
                                </Grid> }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

}