import React, { Component } from 'react'
import './produtoNacional.scss'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table'
import { Grid } from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API'
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';

export default class ProdutoNacional extends Component {

    titles = [
        { id: '6', canOrder: true, label: 'ID' },
        { id: '0', canOrder: true, label: 'CR' },
        { id: '1', canOrder: true, label: 'Descricao' },
        { id: '2', canOrder: true, label: 'Categoria' },
        { id: '3', canOrder: true, label: 'Linha' },
        { id: '4', canOrder: true, label: 'Ativo'},
        { id: '5', canOrder: false,  label: ''}
    ];

    constructor(props) {
        super(props);
        this.state = {
            produtoNacionalList: [],
            emptyList: 'seeResults',
            lines: [],
            filter: {
                ordenarPor: 'id desc'
            },
            produtoNacional: null,
            searchInput: '',
            isDN : this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DN' && 
            (this.props.usuarioConectado.colaborador === undefined || 
                (this.props.usuarioConectado.colaborador != null && !this.props.usuarioConectado.colaborador.isAtivo))
        }
    }

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    }

    componentDidMount() {
        this.closeLoading();
    }

    changeSearchInput = (e) => {
        this.setState({ searchInput: e.target.value.toUpperCase() })
    }

    getProdutosNacionais = () => {
        this.showLoading();
        var filterAux = this.state.filter;
        if (this.state.searchInput !== undefined) {
            filterAux.descricao = '%' + this.state.searchInput + '%';
            this.setState({ filter: filterAux });
        } else {
            delete filterAux.descricao;
            this.setState({ filter: filterAux });
        }

        API.get('rest/auxiliar/ProdutoNacional', {
            params: this.state.filter
        }).then(res => {
            this.setState({ produtoNacionalList: res.data })
            var lines = []

            for (var i = 0; i < this.state.produtoNacionalList.length; i++) {
                var produtoNacionalAux = []

                //CR e Descrição
                produtoNacionalAux.push(this.state.produtoNacionalList[i].CR)
                produtoNacionalAux.push(this.state.produtoNacionalList[i].descricao)

                //Produto Categoria e Produto Linha
                if (this.state.produtoNacionalList[i].produtoCategoria !== undefined) {
                    produtoNacionalAux.push(this.state.produtoNacionalList[i].produtoCategoria.descricao)
                    produtoNacionalAux.push(this.state.produtoNacionalList[i].produtoCategoria.produtoLinha.descricao)
                } else {
                    produtoNacionalAux.push('')
                    produtoNacionalAux.push('')
                }

                //Ativo
                produtoNacionalAux.push(this.state.produtoNacionalList[i].isAtivo ? 'SIM' : 'NÃO');

                //Edit
                produtoNacionalAux.push(
                    <div style={{ display: 'flex' }}>
                        <Link to={`/cad/atendimento/produtoNacional/${this.state.produtoNacionalList[i].id}`}>
                            <GenericButton color={'transparent'} subClass={'icon-button'} 
                                icon={'pen.svg'}/>
                        </Link>
                    </div>);
                
                produtoNacionalAux.push(this.state.produtoNacionalList[i].id);
                lines.push(produtoNacionalAux);
            }
            this.setState({ lines: lines })
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Ocorreu um erro ao carregar lista de produtos nacionais. Favor, entrar em contato com o suporte.', 'error');
        })
    }

    render() {
        return (
            <Page icon={'paste.svg'} label={'Gerenciar Produto Nacional'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-produtos-nacionais'}>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={(this.state.isDN ? 6 : 5)} xl={(this.state.isDN ? 5 : 4)} className={'grid-left-button'}>
                            { this.state.isDN ?
                                <Link to={'/cad/atendimento/produtoNacional/novo'}>
                                    <GenericButton color={'darkBlue'} label={'Cadastrar Novo Produto'} subClass={'basic-button'} />
                                </Link>
                            : null }
                                <FormField type={'text'} label={''} placeholder={'busque por nome do produto'} 
                                    pressEnter={this.getProdutosNacionais}
                                    button={<GenericButton color={'darkBlue'} click={this.getProdutosNacionais}
                                    icon={'search.svg'} subClass={'icon-button'} />}
                                    inputValue={this.state.searchInput} changeValue={this.changeSearchInput} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.lines} titles={this.titles} initialOrder={''} />
                        <EmptyList type={this.state.emptyList} seeAll={this.getProdutosNacionais}/>
                    </Grid>
                </Grid>
            </Page>
        );
    }
}