import React, { Component } from 'react';
import EncontrosDefault from './encontrosDefault';
import API from '../../comm/API';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtilDigital';
import AtividadeDefaultDigital from './atividadeDefaultDigital';
import AtividadeLinhaDeProducaoDigital from './atividadeLinhaDeProducaoDigital';
import AtendimentosCardDigital from './atendimentosCardDigital';
import AtividadeProcessoProdutivoDigital from './atividadeProcessoProdutivoDigital';

export default class MentoriaAlinhamentoDigital extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'encontros'
    };

    constructor(props) {
        super(props);
        const etapaName = `etapaAlinhamento${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.turma = this.props.turma.nome;
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Alinhamento B';
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);
        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoAlinhamento: undefined,
            etapa,
            atendimentoList,
            clientName: '',
            disabled: false
        };
    };

    getDesperdicioList = async (desperdicio) => {
        let list = desperdicio.defeitoList;
        list = list.concat(desperdicio.esperaList); 
        list = list.concat(desperdicio.estoqueList); 
        list = list.concat(desperdicio.intelectualList); 
        list = list.concat(desperdicio.processamentoList); 
        list = list.concat(desperdicio.superProducaoList);
        list = list.concat(desperdicio.transporteList);
        list = list.concat(desperdicio.movimentacaoList);
        return await list;
    };

    getLinhadeProducaoModel(){
        return { status: 1, ordemVisita: null, anexoLinhaProducaoList: [], linhaProducaoIntervencao: '', linhaProducaoLean: '', processoManufatura: '', producaoSeriada: null,
                linhaMonitorada: '', wifiEstavel: null, temResponsavelMINA: null, temAparelhoMINA: null, acompanhamentoProdutividade: '', acompanhamentoProducao: '',sabeMotivosParadaDeLinha: null,
                trataMotivosParadaDeLinha: null };
    }

    getProcessoProdutivoModel(){
        return { status: 1, ordemVisita: null, anexoProcessoProdutivoList: [], kickoff: [], quantidadeFuncionarios: '', faturamento: '', pontuacaoMaturidade: '', possuiERP: '',
                qualERP: '', metodoContagemProducao: '', descricao: '', acompanhamentoProdutividade: '', acompanhamentoProducao: '', sabeMotivosParadaDeLinha: null,trataMotivosParadaDeLinha: null,
                metodoMonitoramento: null,  };
    }

    

    getModels = () => {
        return {
            processoProdutivoDigital: this.getProcessoProdutivoModel(),
            linhaDeProducaoDigital: this.getLinhadeProducaoModel(),
        }
    }

    validateLinhadeProducao(linhadeProducao){
        return  linhadeProducao.anexoLinhaProducaoList && linhadeProducao.linhaProducaoIntervencao && linhadeProducao.linhaProducaoLean && linhadeProducao.processoManufatura &&
        linhadeProducao.producaoSeriada != null && linhadeProducao.linhaMonitorada && linhadeProducao.wifiEstavel != null && linhadeProducao.temResponsavelMINA != null && linhadeProducao.temAparelhoMINA != null && 
        linhadeProducao.acompanhamentoProdutividade && linhadeProducao.acompanhamentoProducao && linhadeProducao.sabeMotivosParadaDeLinha != null && linhadeProducao.trataMotivosParadaDeLinha != null &&
        linhadeProducao.anexoLinhaProducaoList.every(elm => { return elm.descricao });
    }

    validateProcessoProdutivo(processoProdutivo){
        return  processoProdutivo.anexoProcessoProdutivoList.length > 0 &&
        processoProdutivo.quantidadeFuncionarios && 
        processoProdutivo.faturamento  &&
        processoProdutivo.possuiERP != null && 
        (processoProdutivo.possuiERP != null && (!processoProdutivo.possuiERP || processoProdutivo.qualERP)) && 
        processoProdutivo.metodoContagemProducao  && 
        processoProdutivo.acompanhamentoProdutividade &&
        processoProdutivo.acompanhamentoProducao && 
        processoProdutivo.sabeMotivosParadaDeLinha != null && 
        processoProdutivo.trataMotivosParadaDeLinha != null && 
        processoProdutivo.metodoMonitoramento != null &&
        processoProdutivo.anexoProcessoProdutivoList.every(elm => { return elm.descricao });
    }
    

    updateViewData = (atendimento) => {
        let index = this.state.turma.atendimentoTurmaList.findIndex(obj => { return obj.atendimento.id === atendimento.idAtendimento });
        this.setState({disabled:this.state.turma.atendimentoTurmaList[index].atendimento.relatorioFinal != null || this.state.turma.status === 3})

        this.viewData.encontros.data = atendimento.encontroList;
        const { processoProdutivoDigital, linhaDeProducaoDigital } = atendimento; 
        
        this.viewData.atividades.processoProdutivoDigital.data =  JSON.parse(JSON.stringify(processoProdutivoDigital || this.getProcessoProdutivoModel()));
        this.viewData.atividades.processoProdutivoDigital.anexos.data = getByKeyOrEmpty(processoProdutivoDigital, 'anexoProcessoProdutivoList');
        
        this.viewData.atividades.linhaDeProducaoDigital.data = JSON.parse(JSON.stringify(linhaDeProducaoDigital || this.getLinhadeProducaoModel()));
        this.viewData.atividades.linhaDeProducaoDigital.anexos.data = getByKeyOrEmpty(linhaDeProducaoDigital, 'anexoLinhaProducaoList');

    };

    validaAtendimentoAlinhamento = async (atendimentoAlinhamento) => {  
        let encontroList = atendimentoAlinhamento.encontroList;
        let processoProdutivoDigital = atendimentoAlinhamento.processoProdutivoDigital;
        let linhaDeProducaoDigital = atendimentoAlinhamento.linhaDeProducaoDigital;
        if (!atendimentoAlinhamento.cancelado) {
            return await encontroList && encontroList.length > 0 && encontroList.every(encontro => { return this.validaEncontro(encontro) }) 
                 
        }
        return true;        
    };

    filterImagens = (anexo) =>{
        return !anexo.isDocumento
    }

    validaEncontro = (encontro) => {
        return encontro && encontro.anexoEncontroList && encontro.anexoEncontroList.length > 0 && !encontro.anexoEncontroList.filter(this.filterImagens).some(anx => { return anx.descricao === '' })
            && encontro.data && encontro.isVirtual != null && encontro.turno != null && encontro.horasApropriadas > 0;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoAlinhamento = this.state.atendimentoAlinhamento;
        let turma = this.props.turma;
        let validado = await this.validaAtendimentoAlinhamento(atendimentoAlinhamento);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoAlinhamento.finalizado = true;
            let indexAtendimento = turma.etapaAlinhamentoB.atendimentoList.findIndex(obj => { return obj.id === atendimentoAlinhamento.id });
            turma.etapaAlinhamentoB.atendimentoList[indexAtendimento] = atendimentoAlinhamento; //atualiza atendimentoAlinhamento na etapa alinhamento da turma
            let etapa = turma.etapaAlinhamentoB;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaAlinhamentoB;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoAlinhamento.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.props.closeLoading();
                        this.setState({ turma, etapa, atendimentoList, atendimentoAlinhamento: atd }, this.goBackAtendimentos());
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    validaAtividade = async (viewData, atendimentoAlinhamento, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoAlinhamento[nomeAtividade] ? atendimentoAlinhamento[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        console.log('this.state.etapa.finalizada',this.state.etapa.finalizada);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        
        atendimentoAlinhamento[nomeAtividade] = atividade;
    };
    
    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoAlinhamento = this.state.atendimentoAlinhamento;
        let encontrosData = viewData.encontros.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;
            console.log(viewData.selected.atividade);
            if (currScreenAtividade === 'linhaDeProducaoDigital') 
                await this.validaAtividade(viewData, atendimentoAlinhamento, currScreenAtividade, 'linhaDeProducaoDigital', 'anexoLinhaProducaoList', this.validateLinhadeProducao, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'processoProdutivoDigital') 
                await this.validaAtividade(viewData, atendimentoAlinhamento, currScreenAtividade, 'processoProdutivoDigital', 'anexoProcessoProdutivoList', this.validateProcessoProdutivo, this.STATUS_FINALIZADO);

            if (selectedVisita) {
                selectedVisita.anexoEncontroList = [];
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.imagens != null ? selectedVisita.imagens : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((etapa.finalizada && !this.validaEncontro(selectedVisita)) || !(selectedVisita.turno != null && selectedVisita.data && (selectedVisita.isVirtual != null) && selectedVisita.horasApropriadas > 0)) {
                        this.props.showToast('Há informações pendentes nessa visita.', 'error');
                        throw new Error();
                    }
                }
                let index = encontrosData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoAlinhamento.encontroList[index] = selectedVisita;
                else atendimentoAlinhamento.encontroList.push(selectedVisita);
            }
            atendimentoAlinhamento.horasApropriadas = atendimentoAlinhamento.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let atividades = viewData.atividades;
            let linhaDeProducaoDigital = atividades.linhaDeProducaoDigital;
            let processoProdutivoDigital = atividades.processoProdutivoDigital;
            if (!linhaDeProducaoDigital.data.ordemVisita) atendimentoAlinhamento.linhaDeProducaoDigital = null;
            if (!processoProdutivoDigital.data.ordemVisita) atendimentoAlinhamento.processoProdutivoDigital = null;
            atendimentoAlinhamento.encontroList = encontrosData;
            atendimentoAlinhamento.horasApropriadas = atendimentoAlinhamento.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        let indexAtendimento = turma.etapaAlinhamentoB.atendimentoList.findIndex(obj => { return obj.id === atendimentoAlinhamento.id });
        turma.etapaAlinhamentoB.atendimentoList[indexAtendimento] = atendimentoAlinhamento; //atualiza atendimentoAlinhamento na etapa alinhamento b da turma
        if(atendimentoAlinhamento.horasApropriadas > 2){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else{
          return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
            return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                turma = res.data;
                etapa = turma.etapaAlinhamentoB;
                let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoAlinhamento.id });
                let atd = etapa.atendimentoList[index];
                await this.updateViewData(atd);
                this.setState({ turma, etapa, atendimentoAlinhamento: atd });
                this.props.showToast('Etapa editada com sucesso!', 'success');
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }).catch(error => {
            this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        });  
        }
        
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = true;
        let showAnexos = true;
        let render = null;
        let border = true;
        let invertAnexos = false;
        console.log(selectedAtividade.screenAtividade);
        switch (selectedAtividade.screenAtividade) {
            case 'linhaDeProducaoDigital':
                render = <AtividadeLinhaDeProducaoDigital 
                atividade={selectedAtividade.data} 
                disabled={this.state.disabled}/>;
                invertAnexos = true;
                border = false;
                break;
            case 'processoProdutivoDigital':
                render = <AtividadeProcessoProdutivoDigital 
                atividade={selectedAtividade.data}
                alinhamento={true}
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                turma={ this.state.turma } 
                showToast={ this.props.showToast }
                disabled={this.state.disabled}/>;
                invertAnexos = true;
                border = false;
                break;
            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefaultDigital
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ selectedAtividade.anexos.data }
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                showTopButtons={ showTopButtons && !this.state.disabled}
                disabled={this.state.disabled}
                showAnexos={ showAnexos }
                invertAnexos={invertAnexos}
                border = {border}
                renderChildren={ render }
                clientName={ this.state.clientName }
                viewData={ this.state.viewData } />
        );
    };

    renderVisita = (atendimento) => {
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoAlinhamento: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoAlinhamento: undefined });
    };

    render() {
        return (<>
            { this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCardDigital
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Alinhamento' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita } 
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <EncontrosDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Alinhamento' }
                    etapa = {this.state.etapa}
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    showToast={ this.props.showToast }
                    disabled={this.state.disabled}
                    viewData={ this.state.viewData }
                    models={ this.getModels }
                    atendimento={ this.state.atendimentoAlinhamento }
                    saveEtapa={ this.saveEtapa }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    dontShowFinalizarMentoria={ this.state.atendimentoAlinhamento.finalizado }
                    mentoresDados={this.props.mentoresDados}
                />
            }
        </>)
    };
};