import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';

import Table from '../../../components/table/table';
import TableRow from '@material-ui/core/TableRow';
import GraphBarHorizontalSimples from '../../../components/graphBar/graphBarHorizontalSimples';


export default class GraficoPrincipaisSetores extends Component {

    titles = [
        { id: '0', canOrder: false, label: 'CNAE (Descrição da Divisão)' },
        { id: '1', canOrder: false, label: 'Nº de empresas', width: '260px' },
        { id: '2', canOrder: false, label: '', width: '420px' },
    ];

    types = ['str', 'number', 'graph'];

    tooltipAtividadesMentores = {
        formatter: function () {
            return `<p style="font: bold 13px Raleway;  color: #707070">${this.series.name.legendName}</p><br>` +
                `<p style="font: 13px Raleway; font-weight: 500; color: #707070;">Total: ${this.y} %</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    tools = [
        { id: 3, name: 'Indústrias de transformação', color: '#077CDC' },
        { id: 4, name: 'Agricultura, pecuária, produção florestal, pesca e aqüicultura', color: '#E9B03D' },
        { id: 5, name: 'Construção', color: '#B7EE8E' },
        { id: 6, name: 'Comércio; Reparação de veículos automotores e motocicletas', color: '#B4B8C0' },
    ];

    legend = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function () {
            return '<div style="cursor:auto;display:flex;align-items:center;">' + 
            '<div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '">' + 
            '</div><label style="font-weight:500;font-size:11px">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
    };

    plotOptionsAtividadesMentores = {
        bar: {
            pointPadding: 1,
            borderWidth: 0,
            events: {
                legendItemClick: function () {
                    return false;
                },
            dataLabels: {
                enabled: true,
                inside: true,
            }
            
            },
        },
        series: {
            pointWidth: 60,
            states: {
                inactive: {
                    opacity: 1
                }
            }
        },
    };



    labelsY = {
        formatter: function () {
            return this.value;
        },
        style: {
            color: '#707070'
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            setoresInfo: {L:[],D:[],LD:[]},
            finalLine: {L:[],D:[],LD:[]},
        };
        this.getSetoresInfo();
    }

    createGraph = (series, labelY) => {
        return {
            chart: {
                type: 'bar',
                backgroundColor: '#F2F1F6'
            },
            tooltip: this.tooltipAtividadesMentores,
            series: series,
            tickInterval: 20,
            tickColor: '#FFFFFF',
            minY: 0,
            maxY: 100,
            legend: this.legend,
            plotOptions: this.plotOptionsAtividadesMentores,
            labelsY: labelY,
        }
    }

    getSetoresInfo = async () => {
		await API.get(`rest/sistema/DashboardBrasilMais/countTopAtendimentosSetor`).then(async json => {
            let dataL = json.data.lean;
            
            
            let dataD = json.data.digital;
            let dataLD = json.data.ambos;
            let setoresInfoListL = [];
            let setoresInfoListD = [];
            let setoresInfoListLD = [];
            dataL.sort(function(a, b) {
                return b.quantidade - a.quantidade ;
            });
            dataD.sort(function(a, b) {
                return b.quantidade - a.quantidade;
            });
            dataLD.sort(function(a, b) {
                return b.quantidade - a.quantidade;
            });
            let sumL = dataL.reduce(function(total, num) {
                return total + num.quantidade;
            }, 0);
            let sumD = dataD.reduce(function(total, num) {
                return total + num.quantidade;
            }, 0);
            let sumLD = dataLD.reduce(function(total, num) {
                return total + num.quantidade;
            }, 0);
            
            let allSeriesL = []
            let sumSectorL = 0;
            let finalLineL = {
                0:null,
                1:null,
                2:null,
                class: 'blue-row',
            };
            if (dataL != null ) {
                let rows = dataL;
                for (let i = 0; i < 10; i++) {
                    let series = [];
                    let row = rows[i];
                    let value = row.quantidade;
                    sumSectorL = sumSectorL + value;
                    let data = value/sumL * 100;
                    dataL = Math.round(data * 1e2) / 1e2
                    series.push({
                        name: { legendName: row.setor, serieName: row.setor },
                        data: [dataL],
                        color: '#077CDC',
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            format: '{point.y} %',
                            style: {
                                fontSize: '16px'
                            }
                        }
                    });
                    allSeriesL.push({ cnae: row.setor, value: value, series: series });
                }
                allSeriesL.forEach((obj, i) => {
                    let isLastIndex = (i + 1 === allSeriesL.length);
                    let marginBottom =  '-25px';
                    let height =  80;
                    let graph = this.createGraph(obj.series,  null);
                    let setoresInfo = {
                        0:null,
                        1:null,
                        2:null,
                        class: null,
                    };
                    obj.class = '';
                    
                    setoresInfo[0] = obj.cnae
                    setoresInfo[1] = obj.value
                    setoresInfo[2] = <GraphBarHorizontalSimples graph={graph} height={height} width={400} style={{ margin: `-20px -10px ${marginBottom}` }} />;
                    setoresInfo.class = '';
                    setoresInfoListL.push(setoresInfo);
                });                
                finalLineL[0] = <label>NÚMERO TOTAL DE EMPRESAS (TOP 10)</label>;
                finalLineL[1] = <label>{sumSectorL}</label>;
                let percentagem = sumSectorL/sumL * 100;
                percentagem = Math.round(percentagem * 1e2) / 1e2;
                finalLineL[2] = <label><span style={{fontWeight:700}}>{percentagem}</span>% dos CNPJs atendidos</label>;
                setoresInfoListL.push(finalLineL);
            }
            let allSeriesD = []
            let sumSectorD = 0;
            let finalLineD = {
                0:null,
                1:null,
                2:null,
                class: 'blue-row',
            };
            if (dataD != null ) {
                let rows = dataD;
                for (let i = 0; i < 10; i++) {
                    let series = [];
                    let row = rows[i];
                    let value = row[1] + row[2];
                    sumSectorD = sumSectorD + value;
                    let data = value/sumD * 100;
                    dataD = Math.round(data * 1e2) / 1e2
                    series.push({
                        name: { legendName: row[0], serieName: row[0] },
                        data: [dataD],
                        color: '#077CDC',
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            format: '{point.y} %',
                            style: {
                                fontSize: '16px'
                            }
                        }
                    });
                    allSeriesD.push({ cnae: row[0], value: value, series: series });
                }
                allSeriesD.forEach((obj, i) => {
                    let isLastIndex = (i + 1 === allSeriesD.length);
                    let marginBottom =  '-25px';
                    let height =  80;
                    let graph = this.createGraph(obj.series,  null);
                    let setoresInfo = {
                        0:null,
                        1:null,
                        2:null,
                        class: null,
                    };
                    obj.class = '';
                    
                    setoresInfo[0] = obj.cnae
                    setoresInfo[1] = obj.value
                    setoresInfo[2] = <GraphBarHorizontalSimples graph={graph} height={height} width={400} style={{ margin: `-20px -10px ${marginBottom}` }} />;
                    setoresInfo.class = '';
                    setoresInfoListD.push(setoresInfo);
                });                
                finalLineD[0] = <label>NÚMERO TOTAL DE EMPRESAS (TOP 10)</label>;
                finalLineD[1] = <label>{sumSectorD}</label>;
                let percentagem = sumSectorD/sumLD * 100;
                percentagem = Math.round(percentagem * 1e2) / 1e2;
                finalLineD[2] = <label><span style={{fontWeight:700}}>{percentagem}</span>% dos CNPJs atendidos</label>;
                setoresInfoListD.push(finalLineD);
            }
            let allSeriesLD = []
            let sumSectorLD = 0;
            let finalLineLD = {
                0:null,
                1:null,
                2:null,
                class: 'blue-row',
            };
            if (dataLD != null ) {
                let rows = dataLD;
                for (let i = 0; i < 10; i++) {
                    let series = [];
                    let row = rows[i];
                    let value = row[1] + row[2];
                    sumSectorLD = sumSectorLD + value;
                    let data = value/sumLD * 100;
                    dataLD = Math.round(data * 1e2) / 1e2
                    series.push({
                        name: { legendName: row[0], serieName: row[0] },
                        data: [dataLD],
                        color: '#077CDC',
                        dataLabels: {
                            enabled: true,
                            inside: true,
                            format: '{point.y} %',
                            style: {
                                fontSize: '16px'
                            }
                        }
                    });
                    allSeriesLD.push({ cnae: row[0], value: value, series: series });
                }
                allSeriesLD.forEach((obj, i) => {
                    let isLastIndex = (i + 1 === allSeriesLD.length);
                    let marginBottom =  '-25px';
                    let height =  80;
                    let graph = this.createGraph(obj.series,  null);
                    let setoresInfo = {
                        0:null,
                        1:null,
                        2:null,
                        class: null,
                    };
                    obj.class = '';
                    
                    setoresInfo[0] = obj.cnae
                    setoresInfo[1] = obj.value
                    setoresInfo[2] = <GraphBarHorizontalSimples graph={graph} height={height} width={400} style={{ margin: `-20px -10px ${marginBottom}` }} />;
                    setoresInfo.class = '';
                    setoresInfoListLD.push(setoresInfo);
                });                
                finalLineLD[0] = <label>NÚMERO TOTAL DE EMPRESAS (TOP 10)</label>;
                finalLineLD[1] = <label>{sumSectorLD}</label>;
                let percentagem = sumSectorLD/sumLD * 100;
                percentagem = Math.round(percentagem * 1e2) / 1e2;
                finalLineLD[2] = <label><span style={{fontWeight:700}}>{percentagem}</span>% dos CNPJs atendidos</label>;
                setoresInfoListLD.push(finalLineLD);
            }
			this.setState({ setoresInfo: setoresInfoListL, finalLine: [finalLineL] })
		}).catch(error => {
			console.log(error);
		});
	}

    render() {
        return (
            this.state.setoresInfo.length > 0 && <Grid container justify={'center'}>
                <Grid item xs={12} className={'setor-table'}>
                    <Table lines={this.state.setoresInfo} titles={this.titles} types={this.types}  cellWidth={420}  pagination={false} classParamer={'class'}/>
                </Grid>
                <Grid item xs={12} className={'mapa-do-brasil-legenda'} style={{ marginTop: '3%' ,marginBottom: '3%'}}>
                    <Grid container direction={'row'} spacing={3} justify={'center'}>
                        {this.tools.map((tool, index) => {
                            return (
                                <>
                                    <Grid item className={'mapa-do-brasil-legenda-bloco'} key={'leg-' + index}>
                                        <div className={'legenda-shape'} style={{ background: tool.color }}></div>
                                        <span>{tool.name}</span>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

}