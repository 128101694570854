import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBarHorizontal from '../../../components/graphBar/graphBarHorizontal';

export default class GraficoAtendimentoProdutividade extends Component {

    tooltipProdutividade = {
        formatter: function() {
            let yValue = this.point.y;
            return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${yValue.toLocaleString('pt-BR')}%</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    tooltipAtendimento = {
        formatter: function() {
            console.log(this.series);
            let tooltip = `<p style="font: bold 13px Raleway; color: #707070">${this.series.name.serieName}</p><br>` +
            `<p style="font: 13px Raleway; font-weight: 500; color: #707070;">${this.point.category}: ${this.point.y}</p><br>` +
            `<p style="font: 13px Raleway; color: #707070">Total: ${this.point.total}</p>`;
            return tooltip;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };
    
    legend = { enabled: false };

    legendAtendimento = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function() {
            return '<div style="cursor:auto;display:flex;align-items:center;"><div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '"></div><label style="font-weight:500;font-size:11px;color: #707070">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
    };

    plotOptionsAtendimento = {
        series: {
            states: {
                inactive: {
                    opacity: 1
                }
            },
            pointPadding: 0.25,
            pointWidth: 13
        },
        bar: {
            stacking: 'normal',
            borderColor: null,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };

    plotOptionsProdutividade = {
        bar: {
            pointPadding: 1,
            borderWidth: 0,
            zones: [{ value: 1, color: '#FFFFFF' }, { value: 20, color: '#D44E54' }, { value: 100, color: '#00B1E8' }, { color: '#45B1E9' }],
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        },
        series: {
            pointWidth: 13
        },
    };

    plotLinesProdutividade = [
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 20 }, 
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 100 }
    ];

    labelsYProdutividade =  {
        formatter: function() {
            if(this.value <= 20 || (this.value === 100))
                return this.value + "%";
        },
        style: {
            color: '#707070'
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            switch: [
                {
                    label: 'Produtividade', 
                    title: 'Aumento de produtividade industrial por unidade operacional no Brasil Mais', 
                    tooltip: this.tooltipProdutividade, 
                    categories: [],
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoProdutividade,
                    tickInterval: 20,
                    legend: this.legend,
                    plotOptions: this.plotOptionsProdutividade,
                    plotLines: this.plotLinesProdutividade,
                    labelsY: this.labelsYProdutividade,
                },
                {
                    label: 'Atendimento', 
                    title: 'Atendimentos Brasil Mais por unidade operacional', 
                    tooltip: this.tooltipAtendimento, 
                    categories: [],
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoAtendimento,
                    tickInterval: 100,
                    legend: this.legendAtendimento,
                    plotOptions: this.plotOptionsAtendimento
                }
            ]
        };
    };

    fillGraficoAtendimento = async (dataInicio, dataTermino, busca, limite) => {
        let series = [];
        let categories = [];
        let height = 0;
        await API.get(
            `rest/sistema/DashboardBrasilMais/geralatendimentosProdutividadeUnidade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${true}&busca=${busca}&limite=${limite}`
        ).then(async res => {
            let dataNegociacao = [];
            let dataAceitacao = [];
            let dataConcluido = [];
            let dataExecucao = [];
            if (res.data != null && res.data.unidades != null) {
                categories = await res.data.unidades.map(i => i[0]);
                await res.data.unidades.forEach(und => {
                    dataNegociacao.push(und[1]);
                    dataAceitacao.push(und[2]);
                    dataConcluido.push(und[3]);
                    dataExecucao.push(und[4]);
                });
                series = [{
                        name: { legendName: 'Atendimentos em negociação', serieName: 'Negociação'},
                        data: dataNegociacao,
                        color: '#E9B03D'
                    }, {
                        name: { legendName: 'Atendimentos aceitos', serieName: 'Aceitos'},
                        data: dataAceitacao,
                        color: '#B7EE8E'
                    }, {
                        name: { legendName: 'Atendimentos em execução', serieName: 'Execução'},
                        data: dataExecucao,
                        color: '#7DAE59'
                    }, {
                        name: { legendName: 'Atendimentos concluídos', serieName: 'Concluídos'},
                        data: dataConcluido,
                        color: '#00B1E8'
                    }
                ];
                height = series.length === 1 ? 132 : series.length > 1 && series[0].data.length > 1 ? (54 * series[0].data.length) + 54 : 132;
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, height];
    };

    fillGraficoProdutividade = async (dataInicio, dataTermino, busca, limite) => {
        let series = [];
        let categories = [];
        let height = 0;
        await API.get(
            `rest/sistema/DashboardBrasilMais/geralatendimentosProdutividadeUnidade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${false}&busca=${busca}&limite=${limite}`
        ).then(async res => {
            if (res.data != null && res.data.unidades != null) {
                categories = await res.data.unidades.map(i => i[0]);
                series =  [{data: await res.data.unidades.map(i => i[1]) }];
                height = series.length >= 1 && series[0].data.length >= 1 ? (50 * series[0].data.length) + 50 : 100;
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, height];
    };
   
    render() {
        return (
            <Grid container className={'grafico-atd-prod'} justify={'center'} style={{ height: '100%' }}>
                <GraphBarHorizontal switch={this.state.switch} disabledSwitch={false} hasFilter={true} init={'left'} />
            </Grid>
        );
    };

}