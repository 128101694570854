import React, { Component } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import Login from './modules/login/login';
import PrivateRoute from './comm/privateRoute.js';
import Apropriar from './modules/producao/apropriar';
import Unidade from './modules/unidade/unidade';
import UnidadeNovo from './modules/unidade/unidadeNovo';
import ApropriarEmGrupo from './modules/producao/apropriaremgrupo/apropriaremgrupo';
import Usuario from './modules/usuario/usuario'
import UsuarioNovo from './modules/usuario/usuarioNovo'
import LoginSuporte from './modules/loginSuporte/loginSuporte'
import Evento from './modules/evento/evento'
import EventoNovo from './modules/evento/eventoNovo'
import Publicacao from './modules/publicacao/publicacao'
import PublicacaoNovo from './modules/publicacao/publicacaoNovo'
import Treinamento from './modules/treinamento/treinamento'
import TreinamentoNovo from './modules/treinamento/treinamentoNovo'
import Visita from './modules/visita/visita'
import VisitaNovo from './modules/visita/visitaNovo'
import Colaborador from './modules/colaborador/colaborador';
import ColaboradorNovo from './modules/colaborador/colaboradorNovo';
import Cliente from './modules/cliente/cliente';
import ClienteNovo from './modules/cliente/clienteNovo';
import PerfilUsuario from './modules/perfil/perfilUsuario';
import Laboratorio from './modules/laboratorio/laboratorio';
import LaboratorioNovo from './modules/laboratorio/laboratorioNovo';
import ProdutoNacional from './modules/produtoNacional/produtoNacional';
import ProdutoNacionalNovo from './modules/produtoNacional/produtoNacionalNovo';
import Atendimento from './modules/atendimento/atendimento';
import AtendimentoNovo from './modules/atendimento/atendimentoNovo';
import Parametro from './modules/parametro/parametro'
import Dashboard from './modules/dashboard/dashboard';
import DashboardDivergencia from './modules/dashboard/dashboardDivergencia';
import Receita from './modules/receita/receita';
import Notificacao from './modules/notificacao/notificacao';
import ProdutoRegional from './modules/produtoRegional/produtoRegional';
import ProdutoRegionalNovo from './modules/produtoRegional/produtoRegionalNovo';
import SolucaoIntegradora from './modules/solucaoIntegradora/solucaoIntegradora';
import MeusDados from './modules/meusDados/meusDados';
import SolitacoesDN from './modules/solicitacoesDN/solicitacoesDN';
import NoAccess from './modules/noAccess/noAccess';
import NotFound from './modules/notFound/notFound';
import DashboardISI from './modules/dashboardISI/dashboardISI';
import UnidadesBrasilMais from './modules/brasilMais/unidadesBrasilMais';
import UnidadesMentoriaDigital from './modules/brasilMais/unidadesMentoriaDigital';
import ArquivadosMentoriaDigital from './modules/brasilMais/arquivadosMentoriaDigital';
import ArquivadosMentoriaLean from './modules/brasilMais/arquivadosMentoriaLean';
import TurmaMentoriaDigital from './modules/brasilMais/turmaMentoriaDigital';
import TurmaNovoMentoriaDigital from './modules/brasilMais/turmaNovoMentoriaDigital';
import ConfiguracaoBrasilMais from './modules/brasilMais/configuracaoBrasilMais';
import Turma from './modules/brasilMais/turma';
import TurmaNovo from './modules/brasilMais/turmaNovo';
import DashboardBrasilMais from './modules/dashboard/dashboardBrasilMais/dashboardBrasilMais';
import HistoricoReceita from './modules/receita/historico';
import Funil from './modules/indicadores/funil';
import { BusinessSuccess } from './modules/indicadores/kpiBusinessSucess';
import { CustomerValue } from './modules/indicadores/kpiCustomerValue';
import { BusinessProcess } from './modules/indicadores/kpiBusinessProcess';
import { ResourceCapital } from './modules/indicadores/kpiResourceCapital';
import { RelatorioDeOperacoes } from './modules/relatorioDeOperacoes/relatorioDeOperacoes'
import Metas from './modules/indicadores/metas';
import ConfiguracaoMentoriaDigital from './modules/brasilMais/configuracaoMentoriaDigital';
import ReviewTurmaMentoriaDigital from './modules/brasilMais/reviewTurmaMentoriaDigital';
import ReviewTurmaMentoriaLean from './modules/brasilMais/reviewTurmaMentoriaLean';

class Routes extends Component {
	render() {
		return (
			<Router>
				<Switch>
					<Route path='/' exact component={Login} />
					<Route path='/noAccess' component={NoAccess} />

					<PrivateRoute path='/cad/atendimento/producao/colaborador' component={Apropriar} />
					<PrivateRoute path='/cad/atendimento/producao' component={ApropriarEmGrupo} />

					<PrivateRoute path='/cad/sistema/usuario' exact component={Usuario} />
					<PrivateRoute path='/cad/sistema/usuario/novo' exact component={UsuarioNovo} />
					<PrivateRoute path='/cad/sistema/usuario/:idUsuario' exact component={UsuarioNovo} />
					<PrivateRoute path='/cad/sistema/loginSuporte' exact component={LoginSuporte} />

					
					<PrivateRoute path='/cad/pessoa/laboratorio' exact component={Laboratorio} />
					<PrivateRoute path='/cad/pessoa/laboratorio/novo' exact component={LaboratorioNovo} />
					<PrivateRoute path='/cad/pessoa/laboratorio/:idLaboratorio' exact component={LaboratorioNovo} />

					<PrivateRoute path='/cad/pessoa/unidade' exact component={Unidade} />
					<PrivateRoute path='/cad/pessoa/unidade/novo' exact component={UnidadeNovo} />
					<PrivateRoute path='/cad/pessoa/unidade/novo/:idUnidade' component={UnidadeNovo} />

					<PrivateRoute path='/cad/pessoa/evento' exact component={Evento} />
					<PrivateRoute path='/cad/pessoa/evento/novo' exact component={EventoNovo} />
					<PrivateRoute path='/cad/pessoa/evento/:idEvento' exact component={EventoNovo} />

					<PrivateRoute path='/cad/pessoa/treinamento' exact component={Treinamento} />
					<PrivateRoute path='/cad/pessoa/treinamento/novo' exact component={TreinamentoNovo} />
					<PrivateRoute path='/cad/pessoa/treinamento/:idTreinamento' exact component={TreinamentoNovo} />

					<PrivateRoute path='/cad/pessoa/visita' exact component={Visita} />
					<PrivateRoute path='/cad/pessoa/visita/novo' exact component={VisitaNovo} />
					<PrivateRoute path='/cad/pessoa/visita/:idVisita' exact component={VisitaNovo} />

					<PrivateRoute path='/cad/pessoa/publicacao' exact component={Publicacao} />
					<PrivateRoute path='/cad/pessoa/publicacao/novo' exact component={PublicacaoNovo} />
					<PrivateRoute path='/cad/pessoa/publicacao/:idPublicacao' exact component={PublicacaoNovo} />

					<PrivateRoute path='/cad/pessoa/colaborador' exact component={Colaborador} />
					<PrivateRoute path='/cad/pessoa/colaborador/novo' exact component={ColaboradorNovo} />
					<PrivateRoute path='/cad/pessoa/colaborador/:idColaborador' exact component={ColaboradorNovo} />

					<PrivateRoute path='/cad/pessoa/cliente' exact component={Cliente} />
					<PrivateRoute path='/cad/pessoa/cliente/novo' exact component={ClienteNovo} />
					<PrivateRoute path='/cad/pessoa/cliente/:idCliente' exact component={ClienteNovo} />

					<PrivateRoute path='/cad/atendimento/produtoNacional' exact component={ProdutoNacional} />
					<PrivateRoute path='/cad/atendimento/produtoNacional/novo' exact component={ProdutoNacionalNovo} />
					<PrivateRoute path='/cad/atendimento/produtoNacional/:idProdutoNacional' exact component={ProdutoNacionalNovo} />

					<PrivateRoute path='/cad/sistema/relatorioDeOperacoes/' exact component={RelatorioDeOperacoes} />

					<PrivateRoute path='/cad/atendimento/atendimento' exact component={Atendimento} />
					<PrivateRoute path='/cad/atendimento/atendimento/novo' exact component={AtendimentoNovo} />
					<PrivateRoute path='/cad/atendimento/atendimento/novo/:idAtendimento' exact component={AtendimentoNovo} />
					<PrivateRoute path='/cad/atendimento/atendimento/:idAtendimento/rede' exact component={AtendimentoNovo} />

					<PrivateRoute path='/cad/atendimento/produtoRegional' exact component={ProdutoRegional} />
					<PrivateRoute path='/cad/atendimento/produtoRegional/novo' exact component={ProdutoRegionalNovo} />
					<PrivateRoute path='/cad/atendimento/produtoRegional/:idProdutoRegional' exact component={ProdutoRegionalNovo} />

					<PrivateRoute path='/cad/sistema/perfilUsuario' component={PerfilUsuario} />
					<PrivateRoute path='/unidadesNovo' component={UnidadeNovo} />
					<PrivateRoute path='/receita' exact component={Receita} />
					<PrivateRoute path='/receita/historico/:idAtendimento' exact component={HistoricoReceita} />
					<PrivateRoute path='/cad/parametro' component={Parametro} />

					<PrivateRoute path='/cad/sistema/notificacao' component={Notificacao} />

					<PrivateRoute path='/dashboard' component={Dashboard} />
					<PrivateRoute path='/dashboardISI' component={DashboardISI} />
					<PrivateRoute path='/cad/sistema/divergencia' component={DashboardDivergencia} />
					<PrivateRoute path='/dashboardBrasilMais' component={DashboardBrasilMais} />

					<PrivateRoute path='/cad/sistema/funil' component={Funil} />
					<PrivateRoute path='/cad/sistema/kpi-business' component={BusinessSuccess} />
					<PrivateRoute path='/cad/sistema/kpi-customer-value' component={CustomerValue} />
					<PrivateRoute path='/cad/sistema/kpi-business-process' component={BusinessProcess} />
					<PrivateRoute path='/cad/sistema/kpi-resources-capital' component={ResourceCapital} />
					<PrivateRoute path='/cad/sistema/Metas' component={Metas} />

					<PrivateRoute path='/rel/integracao/unigest' exact component={SolucaoIntegradora} />
					<PrivateRoute path='/cad/atendimento/solicitacaoDN' exact component={SolitacoesDN} />
					
					<PrivateRoute path='/cad/sistema/meusDados' component={MeusDados} />

					<PrivateRoute path='/brasilmais/unidadesoperacionais' exact component={UnidadesBrasilMais} />
					<PrivateRoute path='/brasilmais/unidadesoperacionais/arquivados' exact component={ArquivadosMentoriaLean} />
					<PrivateRoute path='/brasilmais/configuracao' exact component={ConfiguracaoBrasilMais} />
					<PrivateRoute path='/brasilmais/mentorialean' exact component={Turma} />
					<PrivateRoute path='/brasilmais/mentorialean/novo' exact component={TurmaNovo} />
					<PrivateRoute path='/brasilmais/mentorialean/:idTurma' exact component={TurmaNovo} />
					<PrivateRoute path='/brasilmais/mentorialean/review/:idTurma' exact component={ReviewTurmaMentoriaLean} />
					
					<PrivateRoute path='/brasilmais/mentoriadigital' exact component={UnidadesMentoriaDigital} />
					<PrivateRoute path='/brasilmais/mentoriadigital/arquivados' exact component={ArquivadosMentoriaDigital} />
					<PrivateRoute path='/brasilmais/mentoriadigital/configuracao' exact component={ConfiguracaoMentoriaDigital} />
					<PrivateRoute path='/brasilmais/turmasmentoriadigital' exact component={TurmaMentoriaDigital} />
					<PrivateRoute path='/brasilmais/turmasmentoriadigital/novo' exact component={TurmaNovoMentoriaDigital} />
					<PrivateRoute path='/brasilmais/turmasmentoriadigital/:idTurma' exact component={TurmaNovoMentoriaDigital} />
					<PrivateRoute path='/brasilmais/turmasmentoriadigital/review/:idTurma' component={ReviewTurmaMentoriaDigital} />
					

					<Route component={NotFound} />
				</Switch>
			</Router>
		);
	}
}

export default Routes;
