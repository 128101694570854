import React, { Component } from 'react';
import EncontrosDefault from './encontrosDefault';
import API from '../../comm/API';
import AtendimentosCard from './atendimentosCard';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtilDigital';
import AtividadeDefaultDigital from './atividadeDefaultDigital';
import AtividadeCicloPDCADigital from './atividadeCicloPDCADigital';
import AtividadeIndicadoresPerformance from './AtividadeIndicadoresPerformance';
import AtendimentosCardDigital from './atendimentosCardDigital';

export default class MentoriaPratica extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    STATUS_EMPROGRESSO = 2;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'encontros'
    };

    switchAnexos = null;
    atendimentoEtapaB = null;

    constructor(props) {
        super(props);

        const etapaName = `etapaPratica${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Mentoria Prática C';
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);

        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoPratica: undefined,
            etapa,
            atendimentoList,
            showTopButtons: true,
            switchAnexos: [],
            clientName: '',
            disabled: false
        };
    };

    getIndicadoresList= async (indicadores) =>{
        let list = [];
        list = list.concat(indicadores.avaliacao);
        return await list;
    }

    getIndicadoresDigitalModel = () => {
        return { status: 1, ordemVisita: null, anexoIndicadoresList: [], produtividadeDigital: {id: null, operadoresTurno: '', horasTurno: '', horasParadas: '', prodNaoConforme: '',
        prodRealizada: '', taxaProducao: '', tempoOperacao: '', prodPossivel: '', prodBoa: '', medicao: ''}, maturidadeDigital: { id: null, dataAvaliacao: '', nivel: '', pontuacaoTotal: '',
        pontuacaoEstrategia: '',    pontuacaoManufatura: '', pontuacaoNegocio: '', pontuacaoCultura: '', anexoMaturidadeDigitalList: []}, oeeDigital:{id: null,
        dataInicial: null,dataFinal: null, medicao: null,disponibilidade: null, performance: null, qualidade: null}};
    };

    getCicloPDCAModel() {
        return { 
            temaA3:{
                contramedidasA3Digital: {
                    planoDeAcaoList:[],
                    anexoContramedidasA3List:[],
                    id: 1},
                analise: {
                    codItem: 4,
                    descricao: '',
                    id: 1, 
                    nome: 'ANALISE', 
                    status: 1,
                    anexoItemA3List:[]},
                condicoesAtuais:{
                    codItem: 2,
                    descricao: '',
                    id: 4,
                    nome: 'CONDICOES ATUAIS',
                    status: 1,
                    anexoItemA3List: []},
                historico:{
                    codItem: 1,
                    descricao: '',
                    id: 2,
                    nome:'HISTORICO',
                    status: 1,
                    anexoItemA3List: []},
                id: 1,
                metas: {
                    codItem: 3,
                    descricao: '',
                    id: 3,
                    nome:'METAS',
                    status: 1,
                    anexoItemA3List:[]},
                nome:'',
            }, 
            anexoRelatorioA3List:[],
            id: 2,
            ordemVisita: 1,
            status: 1,
        }
    };
        
    getModels = () => {
        return {
            relatorioA3Digital: this.getCicloPDCAModel(),
            indicadoresDigital: this.getIndicadoresDigitalModel()
        }
    }

    validateCicloPDCA(cicloPDCA) {
        return cicloPDCA.temaA3 && cicloPDCA.temaA3.contramedidasA3Digital && cicloPDCA.temaA3.nome &&
        cicloPDCA.temaA3.contramedidasA3Digital.anexoContramedidasA3List &&
        cicloPDCA.temaA3.contramedidasA3Digital.anexoContramedidasA3List.length > 0 &&
        cicloPDCA.temaA3.contramedidasA3Digital.anexoContramedidasA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.contramedidasA3Digital.planoDeAcaoList &&
        cicloPDCA.temaA3.contramedidasA3Digital.planoDeAcaoList.length > 0 &&
        cicloPDCA.temaA3.contramedidasA3Digital.planoDeAcaoList.every(elm => { return elm.causaSuspeita && elm.itemAcao && elm.prazo && elm.responsavelAcao }) &&
        cicloPDCA.temaA3.analise &&
        cicloPDCA.temaA3.analise.anexoItemA3List &&
        cicloPDCA.temaA3.analise.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.analise.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.analise.descricao &&
        cicloPDCA.temaA3.condicoesAtuais &&
        cicloPDCA.temaA3.condicoesAtuais.anexoItemA3List &&
        cicloPDCA.temaA3.condicoesAtuais.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.condicoesAtuais.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.condicoesAtuais.descricao &&
        cicloPDCA.temaA3.historico &&
        cicloPDCA.temaA3.historico.anexoItemA3List &&
        cicloPDCA.temaA3.historico.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.historico.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.historico.descricao &&
        cicloPDCA.temaA3.metas &&
        cicloPDCA.temaA3.metas.anexoItemA3List &&
        cicloPDCA.temaA3.metas.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.metas.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.metas.descricao;
    };

    checkDate = (indicadores) =>{
        let indicadoresDigital = indicadores;
        let initDate  = new Date(indicadoresDigital.oeeDigital.dataInicial);
        let finDate = new Date(indicadoresDigital.oeeDigital.dataFinal);
        let cincoDias = 432000000

        return (Math.abs(finDate.getTime() - initDate.getTime()) > cincoDias)
    }

    validateIndicadores(indicadores){
        return indicadores.produtividadeDigital &&
        indicadores.produtividadeDigital.operadoresTurno &&
        indicadores.produtividadeDigital.horasTurno  &&
        indicadores.produtividadeDigital.prodRealizada &&
        indicadores.produtividadeDigital.medicao &&
        indicadores.maturidadeDigital && 
        indicadores.maturidadeDigital.dataAvaliacao &&
        indicadores.maturidadeDigital.nivel && 
        indicadores.maturidadeDigital.pontuacaoTotal && 
        indicadores.maturidadeDigital.pontuacaoEstrategia &&
        indicadores.maturidadeDigital.pontuacaoManufatura && 
        indicadores.maturidadeDigital.pontuacaoNegocio && 
        indicadores.maturidadeDigital.pontuacaoCultura && 
        indicadores.maturidadeDigital.anexoMaturidadeDigitalList && 
        indicadores.maturidadeDigital.anexoMaturidadeDigitalList.length > 0 && 
        indicadores.oeeDigital.dataInicial && 
        indicadores.oeeDigital.dataFinal &&
        indicadores.oeeDigital.medicao && 
        indicadores.oeeDigital.disponibilidade && 
        indicadores.oeeDigital.performance && 
        indicadores.oeeDigital.qualidade &&
        (!indicadores.oeeDigital.isManual || (indicadores.oeeDigital.anexoOEEList.length > 0 && indicadores.oeeDigital.anexoOEEList.every(elm => { return elm.descricao })))
    }

    updateViewData = (atendimento) => { 
        let index = this.state.turma.atendimentoTurmaList.findIndex(obj => { return obj.atendimento.id === atendimento.idAtendimento });
        this.setState({disabled:this.state.turma.atendimentoTurmaList[index].atendimento.relatorioFinal != null  || this.state.turma.status === 3})
        
        this.viewData.encontros.data = atendimento.encontroList;
        const { indicadoresDigital, relatorioA3Digital} = atendimento;
        let turma = this.props.turma

        if(!indicadoresDigital){
            if(turma.etapaPraticaB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).processoProdutivoDigital.status != 1){
                this.viewData.atividades.indicadoresDigital.data = JSON.parse(JSON.stringify(turma.etapaPraticaB.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).indicadoresDigital));
                this.viewData.atividades.indicadoresDigital.data.status = 2;
            }
        }
        else{
            this.viewData.atividades.indicadoresDigital.data =  JSON.parse(JSON.stringify(indicadoresDigital || this.getProcessoProdutivoModel()));
        }
        
        this.viewData.atividades.relatorioA3Digital.data = JSON.parse(JSON.stringify(relatorioA3Digital || this.getCicloPDCAModel()));
    };

    validaAtendimentoPratica = async (atendimentoPratica) => {  
        let encontroList = atendimentoPratica.encontroList;
        let indicadoresDigital = atendimentoPratica.indicadoresDigital;
        let relatorioA3Digital = atendimentoPratica.relatorioA3Digital;
        if (!atendimentoPratica.cancelado) {
            return await encontroList && encontroList.length > 0 && encontroList.every(encontro => { return this.validaEncontro(encontro) }) &&
            indicadoresDigital && indicadoresDigital.status === this.STATUS_FINALIZADO && relatorioA3Digital && relatorioA3Digital.status === this.STATUS_FINALIZADO
                 
        }
        return true;
    };
    
    validaEncontro = (encontro) => {
        return encontro && encontro.anexoEncontroList && encontro.anexoEncontroList.length > 0 && !encontro.anexoEncontroList.filter(anx => { return !anx.isDocumento }).some(anx => { return anx.descricao === '' }) &&
        encontro.anexoEncontroList.some(anx => { return anx.descricao === 'lista de presença' && anx.isDocumento }) && encontro.data && encontro.isVirtual != null && encontro.turno != null  && encontro.idMentor != null && encontro.horasApropriadas > 0;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoPratica = this.state.atendimentoPratica;
        let turma = this.props.turma;
        let validado = await this.validaAtendimentoPratica(atendimentoPratica);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoPratica.finalizado = true;
            let indexAtendimento = turma.etapaPraticaC.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
            turma.etapaPraticaC.atendimentoList[indexAtendimento] = atendimentoPratica;
            let etapa = turma.etapaPraticaC;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaPraticaC;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.setState({ turma, etapa, atendimentoList, atendimentoPratica: atd }, this.goBackAtendimentos());
                        this.props.closeLoading();
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    validaAtividade = async (viewData, atendimentoPratica, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoPratica[nomeAtividade] ? atendimentoPratica[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        if(nomeAnexo && nomeAtividade === 'indicadoresDigital'){
            if(!this.checkDate(atividade)){
                this.props.showToast('O periodo de coleta do OEE precisa ter no minimo 5 dias entre as datas', 'error');
                throw new Error();
            }
            if(atividade.oeeDigital.isManual){
                atividade.oeeDigital.medicao = atividade.medicaoManual;
                atividade.oeeDigital.disponibilidade = atividade.disponibilidadeManual;
                atividade.oeeDigital.performance = atividade.performanceManual;
                atividade.oeeDigital.qualidade = atividade.qualidadeManual;
            }
            else{
                atividade.oeeDigital.medicao = atividade.medicaoOEE;
                atividade.oeeDigital.disponibilidade = atividade.disponibilidadeOEE;
                atividade.oeeDigital.performance = atividade.performanceOEE;
                atividade.oeeDigital.qualidade = atividade.qualidadeOEE;
            }
            atividade.maturidadeDigital.anexoMaturidadeDigitalList = await this.getIndicadoresList(atividade);
        }
        else if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoPratica[nomeAtividade] = atividade;
    };

    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoPratica = this.state.atendimentoPratica;
        let encontrosData = viewData.encontros.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;
            
            if (currScreenAtividade === 'indicadoresDigital') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'indicadoresDigital', 'anexoIndicadoresList', this.validateIndicadores, this.STATUS_FINALIZADO);
            else if (currScreenAtividade === 'relatorioA3Digital') 
                await this.validaAtividade(viewData, atendimentoPratica, currScreenAtividade, 'relatorioA3Digital', null, this.validateCicloPDCA, this.STATUS_FINALIZADO);
            
            if (selectedVisita) {
                selectedVisita.anexoEncontroList = [];
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.imagens != null ? selectedVisita.imagens : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaEncontro(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = encontrosData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoPratica.encontroList[index] = selectedVisita;
                else atendimentoPratica.encontroList.push(selectedVisita);
            }
            atendimentoPratica.horasApropriadas = atendimentoPratica.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let atividades = viewData.atividades;
            let indicadoresDigital = atividades.indicadoresDigital;
            let relatorioA3Digital = atividades.relatorioA3Digital;
            if (!indicadoresDigital.data.ordemVisita) atendimentoPratica.indicadoresDigital = null;
            if (!relatorioA3Digital.data.ordemVisita) atendimentoPratica.relatorioA3Digital = null;
            atendimentoPratica.encontroList = encontrosData;
            atendimentoPratica.horasApropriadas = atendimentoPratica.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        let indexAtendimento = turma.etapaPraticaC.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
        turma.etapaPraticaC.atendimentoList[indexAtendimento] = atendimentoPratica;
        let indexAtendimentoB = turma.etapaPraticaB.atendimentoList.findIndex(obj => { return obj.id === this.atendimentoEtapaB.id });
        turma.etapaPraticaB.atendimentoList[indexAtendimentoB] = this.atendimentoEtapaB;
        if(atendimentoPratica.horasApropriadas > 12){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else {return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
            return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                turma = res.data;
                etapa = turma.etapaPraticaC;
                let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoPratica.id });
                let atd = etapa.atendimentoList[index];
                this.atendimentoEtapaB = this.getAtendimentoEtapaB(atd);
                await this.updateViewData(atd);
                this.setState({ turma, etapa, atendimentoPratica: atd, switchAnexos: undefined, showTopButtons: true });
                this.props.showToast('Etapa editada com sucesso!', 'success');
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }).catch(error => {
            this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        });}
    };

    showTopButtons = (value) => {
        this.setState({ showTopButtons: value });
    };

    switchAnexos = (anexosList) => {
        this.setState({ switchAnexos: anexosList });
    };

    getAtendimentoEtapaB = (atendimentoPratica) => {
        return this.props.turma.etapaPraticaB.atendimentoList.find(atd => { return atd.idAtendimento === atendimentoPratica.idAtendimento });
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = this.state.showTopButtons;
        let showAnexos = true;
        let render = null;
        let switchAnexos = this.state.switchAnexos && this.state.switchAnexos.length > 0 ? this.state.switchAnexos : selectedAtividade.anexos.data;
        switch (selectedAtividade.screenAtividade) {
            case 'relatorioA3Digital':
                render = <AtividadeCicloPDCADigital 
                    atividade={selectedAtividade.data}
                    atendimento={this.state.atendimentoPratica} 
                    etapa={this.state.etapa} 
                    turma={this.props.turma}                            
                    showLoading={this.props.showLoading} 
                    closeLoading={this.props.closeLoading} 
                    showToast={this.props.showToast} 
                    fase={ this.props.fase }
                    disabled={this.state.disabled}
                    etapaAtividade={'praticaC'}

                />;
                showTopButtons = false;
                showAnexos = false;
                break;
                case 'indicadoresDigital':
                    render = <AtividadeIndicadoresPerformance 
                    atividade={selectedAtividade.data}
                    showLoading={ this.props.showLoading }
                    closeLoading={ this.props.closeLoading }
                    atendimento={this.state.atendimentoPratica}
                    turma={ this.state.turma } 
                    showToast={ this.props.showToast }
                    etapa={'C'}
                    disabled={this.state.disabled}
                />;
                showTopButtons = false;
                showAnexos = false;
                break;
            default:
                render = null;
                showEmptyEvidencias = true;
                showTopButtons = true;
                showAnexos = true;
        }
        return (
            <AtividadeDefaultDigital
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ switchAnexos }
                disabled={this.state.disabled}
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                EtAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                showTopButtons={ showTopButtons && !this.state.disabled}
                showAnexos={ showAnexos }
                renderChildren={ render } 
                clientName={ this.state.clientName }
                viewData={ this.state.viewData } />
        );
    };

    renderVisita = (atendimento) => {
        this.atendimentoEtapaB = this.getAtendimentoEtapaB(atendimento);
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoPratica: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoPratica: undefined, switchAnexos: undefined, showTopButtons: true });
    };

    render() {
        return (<>
            {this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCardDigital
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Mentoria Prática' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita }
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <EncontrosDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    showLoading={ this.props.showLoading }
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    models={ this.getModels }
                    atendimento={ this.state.atendimentoPratica }
                    etapa = {this.state.etapa}
                    disabled={this.state.disabled}
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    saveEtapa={ this.saveEtapa }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    switchAnexos={ this.switchAnexos }
                    dontShowFinalizarMentoria={ this.state.atendimentoPratica.finalizado }
                    mentoresDados={this.props.mentoresDados}
                />
            }
        </>)
    };
};