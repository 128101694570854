import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import GenericButton from './../../components/genericButton/genericButton';
import InputSelect from '../../components/inputselect/inputselect';
import { Grid, Switch, AppBar, Tabs, Tab, FormControl, RadioGroup, 
    FormControlLabel, Radio, Chip, Icon} from "@material-ui/core"

export default class AtividadeLinhaDeProducao extends Component {

    tipoSistemaProdutivoOptions = [
        { id: 1, label: "Produção por projeto" },
        { id: 2, label: "Job Shops" },
        { id: 3, label: "Lotes/Bateladas" },
        { id: 4, label: "Produção em massa" },
        { id: 5, label: "Produção por processo contínuo" },
        { id: 6, label: "Misto" }
    ];

    tipoArranjoFisicoOptions = [
        { id: 1, label: "Arranjo físico posicional" },
        { id: 2, label: "Arranjo físico por processo" },
        { id: 3, label: "Células de produção" },
        { id: 4, label: "Em linha" },
        { id: 5, label: "Misto" },
    ];

    tipoProdutoOptions = [
        { id: 1, label: "Personalizado" },
        { id: 2, label: "Padronizado" },
        { id: 3, label: "Modularizado" }
    ];

    cacheColetaIndicadoresText = '';
    
    constructor(props) {
        super(props);

        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let disableEditAntes = etapa.etapa >= 8; 
        let tab = !disableEditAntes ? 'Antes' : 'Depois';
        let formAntes = tab === 'Antes' ? props.atividade.data : turma.etapaPraticaC.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).linhaDeProducao;
        let formDepois = tab === 'Depois' ? props.atividade.data : turma.etapaPraticaD.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).linhaDeProducao;
        props.showTopButtons(true);
        props.switchAnexos(null);
        this.state = {
            linhaDeProducao: tab === 'Antes' ? formAntes : formDepois,
            errors: {
                coletaIndicadoresText: '',
                tipoArranjo: ['Cadastre pelo menos um tipo.', ''],
                tipoSistema: ['Cadastre pelo meno um tipo.', ''],
            },
            tab: tab,
            formAntes: formAntes,
            formDepois: formDepois,
            disableDepois: turma.etapaAtual < 8, 
            disableEditAntes: disableEditAntes, 
        };
    };

    changeTab = async (value, newValue) => {
        const linhaDeProducao = newValue !== 'Antes' ? this.state.formDepois : this.state.formAntes;
        let showTopButtons = (newValue === 'Antes' && !this.state.disableEditAntes) || (newValue === 'Depois' && this.state.disableEditAntes);
        this.props.showTopButtons(showTopButtons);
        this.props.switchAnexos(showTopButtons ? this.props.atividade.anexos.data : linhaDeProducao.anexoLinhaProducaoList);
        this.setState({ tab: newValue, linhaDeProducao: linhaDeProducao });
    };

    convertInfo = (currList, currOptions) => {
        const editDisable = (this.state.tab === 'Antes' && this.state.disableEditAntes) || (this.state.tab === 'Depois' && !this.state.disableEditAntes);
        return this.state.linhaDeProducao[currList].map((tipoObj, index) => (
            <Chip key={index} label={<label style={{ display: 'flex', alignItems: 'center', paddingLeft: '6px' }} >
                <p style={{ paddingTop: '7px' }}>{currOptions.find(elm => elm.id === tipoObj.tipo).label}</p></label>}
                icon={this.props.disabled || editDisable ? null :<Icon onClick={() => {
                    if (currList === 'tipoSistemaProdutivoList') this.rmTipoSistema(tipoObj, currList)
                    else this.rmTipoArranjo(tipoObj, currList)
                } } className={'close'}>clear</Icon>} />
        ));
    };

    changeInput = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
                linhaDeProducao[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
                linhaDeProducao[e.target.name] = e.target.value;
            }
            this.setState({ linhaDeProducao: linhaDeProducao, errors: errors });
        }
    };

    changeTipoProduto = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        linhaDeProducao.tipoProduto = e.id;
        this.setState({ linhaDeProducao: this.state.linhaDeProducao });
    };

    changeTipoSistema = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        linhaDeProducao.tipoSistema = e.id;
        this.setState({ linhaDeProducao: this.state.linhaDeProducao });
    };

    changeTipoArranjo = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        linhaDeProducao.tipoArranjo = e.id;
        this.setState({ linhaDeProducao: this.state.linhaDeProducao });
    };

    addTipos = (currTipo, currList) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        let errors = this.state.errors;
        const add = () => {
            linhaDeProducao[currList].push({ tipo: linhaDeProducao[currTipo] });
            errors[currTipo][0] = '';
            errors[currTipo][1] = '';
        }
        if (linhaDeProducao[currTipo] && linhaDeProducao[currList].length > 0) {
            let index = linhaDeProducao[currList].findIndex(tipoObj => { return tipoObj.tipo === linhaDeProducao[currTipo]; });
            if (index < 0) add();
            else errors[currTipo][1] = 'Tipo já cadastrado.';
        }
        else if (linhaDeProducao[currTipo]) add();
        this.setState({ linhaDeProducao: linhaDeProducao, errors : errors});
    };

    removeTipos = (tipoObj, currList, currTipo) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        let errors = this.state.errors;
        let index = linhaDeProducao[currList].findIndex(obj => { return obj.tipo === tipoObj.tipo; });
        if (index >= 0) {
            linhaDeProducao[currList].splice(index, 1);
            if (linhaDeProducao[currList].length === 0 && errors[currTipo]) errors[currTipo][0] = 'Cadastre pelo meno um tipo.';
            else if (errors[currTipo]) errors[currTipo][1] = '';
            this.setState({ linhaDeProducao: linhaDeProducao, errors : errors});
        }
    };

    addTipoSistema = () => { this.addTipos('tipoSistema', 'tipoSistemaProdutivoList'); }

    addTipoArranjo = () => { this.addTipos('tipoArranjo', 'tipoArranjoFisicoList'); }

    rmTipoSistema(tipoObj, currList) { this.removeTipos(tipoObj, currList, 'tipoSistema'); }

    rmTipoArranjo(tipoObj, currList) { this.removeTipos(tipoObj, currList, 'tipoArranjo'); }

    changeSwitchField = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        let errors = this.state.errors;
        if (e.target.id === 'ifColetaIndicadores') {
            linhaDeProducao.ifColetaIndicadores = !e.target.checked
            if (!linhaDeProducao.ifColetaIndicadores) {
                this.cacheColetaIndicadoresText = linhaDeProducao.coletaIndicadoresText;
                linhaDeProducao.coletaIndicadoresText = '';
            }
            else linhaDeProducao.coletaIndicadoresText = this.cacheColetaIndicadoresText;
            errors['coletaIndicadoresText'] = !e.target.checked && !linhaDeProducao.coletaIndicadoresText ? 'Campo obrigatório.' : '';
        }
        else linhaDeProducao.tipoProducao = e.target.checked ? 2 : 1
        this.setState({ linhaDeProducao: linhaDeProducao, errors : errors });
    }

    renderRadioButton = (property, letter, label) => {
        const low = `Baix${letter}`;
        const mid = `Médi${letter}`;
        const great = 'Grande';
        const editDisable = (this.state.tab === 'Antes' && this.state.disableEditAntes) || (this.state.tab === 'Depois' && !this.state.disableEditAntes);
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Grid container style={{ marginBottom: '3%' }} direction={'column'}>
                    <Grid item> <label> {label} </label> </Grid>
                    <Grid item className={'radioDisabled'}>
                        <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name={property} value={this.state.linhaDeProducao[property]} 
                            onChange={this.changeInput} disabled={this.props.disabled} row>
                                <FormControlLabel
                                    value={low}
                                    control={<Radio color="primary" disabled={editDisable}/> }
                                    label={low}
                                    labelPlacement="end"
                                    disabled={this.props.disabled}
                                    />
                                <FormControlLabel
                                    value={mid}
                                    control={<Radio color="primary" disabled={editDisable}/>}
                                    label={mid}
                                    labelPlacement="end"
                                    disabled={this.props.disabled}
                                    />
                                <FormControlLabel
                                    value={great}
                                    control={<Radio color="primary" disabled={editDisable}/>}
                                    label={great}
                                    labelPlacement="end"
                                    disabled={this.props.disabled}
                                    />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    renderBody = () => {
        const editDisable = (this.state.tab === 'Antes' && this.state.disableEditAntes) || (this.state.tab === 'Depois' && !this.state.disableEditAntes);
        return (
            <Grid container justify={'center'} style={{ marginTop: '3%' }}>
                <Grid container justify={'center'} direction={'row'} spacing={4}>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn'}>
                            <InputSelect
                                error={this.state.linhaDeProducao.tipoSistemaProdutivoList.length === 0 ? this.state.errors.tipoSistema[0] : this.state.errors.tipoSistema[1]}
                                suggestions={this.tipoSistemaProdutivoOptions}
                                label={'Tipo de sistema produtivo'}
                                itemKey={'id'}
                                itemLabel={'label'}
                                id={'tipo'}
                                getInput={this.changeTipoSistema}
                                initialSelectedItem={{ id: this.state.linhaDeProducao.tipoSistema }}
                                disabled={this.props.disabled || editDisable}/>
                            <GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'plus.svg'} click={this.addTipoSistema} disabled={this.props.disabled}/>
                        </div>
                        <div>
                            { this.convertInfo('tipoSistemaProdutivoList', this.tipoSistemaProdutivoOptions) }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn'}>
                            <InputSelect
                                error={this.state.linhaDeProducao.tipoArranjoFisicoList.length === 0 ? this.state.errors.tipoArranjo[0] : this.state.errors.tipoArranjo[1]}
                                suggestions={this.tipoArranjoFisicoOptions}
                                label={'Tipo de arranjo físico'}
                                itemKey={'id'}
                                itemLabel={'label'}
                                id={'tipo'}
                                getInput={this.changeTipoArranjo}
                                initialSelectedItem={{ id: this.state.linhaDeProducao.tipoArranjo }} 
                                disabled={this.props.disabled || editDisable} />
                            <GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'plus.svg'} click={this.addTipoArranjo} disabled={this.props.disabled}/>
                        </div>
                        <div>
                            { this.convertInfo('tipoArranjoFisicoList', this.tipoArranjoFisicoOptions) }
                        </div>
                    </Grid>
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ marginTop: '3%' }}>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn error'}>
                            <InputSelect
                                suggestions={this.tipoProdutoOptions}
                                label={'Tipo de produto'}
                                itemKey={'id'}
                                itemLabel={'label'}
                                id={'tipo'}
                                getInput={this.changeTipoProduto}
                                initialSelectedItem={{ id: this.state.linhaDeProducao.tipoProduto }} 
                                disabled={this.props.disabled || editDisable}/>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3} >
                        <div>
                            <FormField type={'textarea'} label={'Como é a coleta de indicadores da linha produtiva?'} 
                                placeholder={'Escreva um texto descritivo'} disabled={(!this.state.linhaDeProducao.ifColetaIndicadores || editDisable ) || this.props.disabled}
                                error={this.state.errors.coletaIndicadoresText}
                                changeValue={this.changeInput} id={'coletaIndicadoresText'} required={true}
                                inputValue={this.state.linhaDeProducao.coletaIndicadoresText} maxLength={"200"}
                                />
                        </div>
                    </Grid>
                </Grid>
                <Grid container justify={'center'} style={{ marginTop: '3%' }} className={'linha-producao-box'}>
                    <Grid item>
                        <Grid container justify={'center'} direction={'row'} style={{ marginTop: '3%', marginBottom: '3%' }}>
                            <Grid item xs={12} md={4} xl={4}>
                                <label> Tipo de produção </label>
                                <Grid container spacing={2} style={{ marginTop: '2%' }}>
                                    <Grid item> <label> Discreto </label> </Grid>
                                    <Grid item style={{ padding: 1}}>
                                        <Switch disabled={this.props.disabled || editDisable} checked={this.state.linhaDeProducao.tipoProducao !== 1} onChange={this.changeSwitchField} id="tipoProduto" color="default"/> 
                                    </Grid>
                                    <Grid item> <label> Contínuo </label> </Grid>
                                </Grid>
                            </Grid>
                            {this.renderRadioButton("condicaoDeTrabalho", "a", "Condição de trabalho da linha produtiva")}
                            {this.renderRadioButton("grauManutencao", "o", "Grau de manutenção da linha produtiva")}
                        </Grid>
                        <Grid container justify={'center'} direction={'row'} style={{ marginTop: '3%', marginBottom: '3%' }}>
                            {this.renderRadioButton("variedadeProducao", "a", "Variedade da produção")}
                            {this.renderRadioButton("grauUtilizacaoTecnologica", "o", "Grau de utilização tecnológica da linha produtiva")}
                            {this.renderRadioButton("setupEquipamentos", "o", "Setup dos equipamentos")}
                        </Grid>
                        <Grid container justify={'center'} direction={'row'} style={{ marginTop: '3%', marginBottom: '3%' }}>
                            <Grid item xs={12} md={4} xl={4}>
                                <label> A empresa coleta indicadores da linha produtiva? </label>
                                <Grid container spacing={2} style={{ marginTop: '2%' }}>
                                    <Grid item> <label> Sim </label> </Grid>
                                    <Grid item style={{ padding: 1}}>
                                        <Switch disabled={this.props.disabled || editDisable} checked={!this.state.linhaDeProducao.ifColetaIndicadores} onChange={this.changeSwitchField} 
                                        id="ifColetaIndicadores" color="default"/>
                                    </Grid>
                                    <Grid item> <label> Não </label> </Grid>
                                </Grid>
                            </Grid>
                            {this.renderRadioButton("organizacao5s", "a", "Organização da linha produtiva (5S)")}
                            {this.renderRadioButton("niveisAcumuloEstoque", "o", "Níveis de acúmulo de estoque")}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao'} style={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                    <AppBar position='static'>
                        <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                            <Tab key={'Antes'} value={'Antes'} label={'Antes'} disabled={false}></Tab>
                            <Tab key={'Depois'} value={'Depois'} label={'Depois'} disabled={this.state.disableDepois}></Tab>
                        </Tabs>
                    </AppBar>
                </Grid>
                { this.renderBody() }
            </Grid>
        )
    }

}