import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';

import Table from '../../../components/table/table';
import GraphBarHorizontalSimples from '../../../components/graphBar/graphBarHorizontalSimples';


export default class GraficoAtividadesMentoresHabilitados extends Component {

    titles = [
        { id: '0', canOrder: false, label: 'Mentores' },
        { id: '1', canOrder: false, label: 'Em espera' },
        { id: '2', canOrder: false, label: 'Em atendimento' },
        { id: '3', canOrder: false, label: 'Concluídas' },
        { id: '4', canOrder: false, label: 'Ferramentas opcionais' },
        { id: '5', canOrder: false, label: 'Mais utilizada' }
    ];

    types = ['str', 'number', 'number', 'number', 'graph', 'str'];

    tools = [
        { id: 3, name: 'Fluxo contínuo', color: '#E9B03D' },
        { id: 4, name: 'Qualidade na fonte', color: '#B7EE8E' },
        { id: 5, name: 'Troca rápida da ferramenta', color: '#E9784D' },
        { id: 6, name: '5S', color: '#00B1E8' },
        { id: 7, name: 'Trabalho padronizado', color: '#7DAE59' },
        { id: 8, name: 'Produção puxada', color: '#077CDC' },
        { id: 9, name: 'Outra ferramenta', color: '#707070' }
    ];

    tooltipAtividadesMentores = {
        formatter: function () {
            return `<p style="font: bold 13px Raleway;  color: #707070">${this.series.name.legendName}</p><br>` +
                `<p style="font: 13px Raleway; font-weight: 500; color: #707070;">Total: ${this.y}</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    legend = {
        squareSymbol: false,
        symbolHeight: 0,
        symbolWidth: 0,
        symbolRadius: 0,
        useHTML: true,
        labelFormatter: function () {
            return '<div style="cursor:auto;display:flex;align-items:center;">' + 
            '<div style="height:10px;width:22px;border-radius:5px;margin-right:10px;display:inline-block;background:' + this.color + '">' + 
            '</div><label style="font-weight:500;font-size:11px">' + (this.name.legendName ? this.name.legendName : this.name) + '</label></div>';
        }
    };

    plotOptionsAtividadesMentores = {
        bar: {
            pointPadding: 1,
            borderWidth: 0,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        },
        series: {
            pointWidth: 10,
            stacking: 'normal',
            states: {
                inactive: {
                    opacity: 1
                }
            }
        },
    };

    plotLineY = { color: '#E1E3E6', width: 1.5 };

    plotLinesY = [
        { ...this.plotLineY, value: 20 },
        { ...this.plotLineY, value: 40 },
        { ...this.plotLineY, value: 60 },
        { ...this.plotLineY, value: 80 },
        { ...this.plotLineY, value: 100 },
    ];

    labelsY = {
        formatter: function () {
            return this.value;
        },
        style: {
            color: '#707070'
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            mentoresInfo: [],
        };
        this.getMentoresInfo();
    }

    createGraph = (series, labelY) => {
        return {
            tooltip: this.tooltipAtividadesMentores,
            series: series,
            tickInterval: 20,
            minY: 0,
            maxY: 100,
            legend: this.legend,
            plotOptions: this.plotOptionsAtividadesMentores,
            plotLinesY: this.plotLinesY,
            labelsY: labelY,
        }
    }

    getMentoresInfo = async () => {
		await API.get(`rest/sistema/DashboardBrasilMais/mentoresAtivosHabilitados/${false}`).then(async json => {
            let data = json.data;
            let mentoresInfoList = [];
            let allSeries = []
            if (data != null && data.mentores != null) {
                let rows = data.mentores;
                for (let i = 0; i < rows.length; i++) {
                    let series = [];
                    let row = rows[i];
                    let toolValuesGraph = row[4];
                    let sum = toolValuesGraph.reduce((i, j) => i + j, 0);
                    let mentoresInfo = row.slice(0, row.length - 1);
                    toolValuesGraph.forEach((item, index) => {
                        let toolInfo = this.tools[index];
                        series.push({
                            name: { legendName: toolInfo.name, serieName: toolInfo.name },
                            data: [item],
                            color: toolInfo.color
                        })
                    });
                    let majorTool = series.length > 0 ? series.reduce((series_1, series_2) => {
                        return series_1.data[0] > series_2.data[0] ? series_1 : series_2;
                    }).name.serieName : '';
                    series.reverse();
                    allSeries.push({ sum: sum, majorTool: majorTool, series: series, mentoresInfo: mentoresInfo });
                }
                allSeries.reverse();
                allSeries.forEach((obj, i) => {
                    let isLastIndex = (i + 1 === allSeries.length);
                    let marginBottom = isLastIndex ? '-45px' : '-25px';
                    let height = isLastIndex ? 100 : 80;
                    let graph = this.createGraph(obj.series, isLastIndex ? this.labelsY : null);
                    obj.mentoresInfo.push(<GraphBarHorizontalSimples graph={graph} height={height} width={400} style={{ margin: `-20px -10px ${marginBottom}` }} />);
                    obj.mentoresInfo.push(obj.majorTool);
                    mentoresInfoList.push(obj.mentoresInfo);
                })
            }
			this.setState({ mentoresInfo: mentoresInfoList })
		}).catch(error => {
			console.log(error);
		});
	}

    render() {
        return (
            this.state.mentoresInfo.length > 0 && <Grid container justify={'center'}>
                <Grid item xs={12}>
                    <Table lines={this.state.mentoresInfo} titles={this.titles} types={this.types} cellWidth={420} initialOrder={'0'} pagination={false}/>
                </Grid>
                <Grid item xs={12} className={'mapa-do-brasil-legenda'} style={{ marginTop: '3%' }}>
                    <Grid container direction={'row'} spacing={3} justify={'center'}>
                        {this.tools.map((tool, index) => {
                            return (
                                <>
                                    <Grid item className={'mapa-do-brasil-legenda-bloco'} key={'leg-' + index}>
                                        <div className={'legenda-shape'} style={{ background: tool.color }}></div>
                                        <span>{tool.name}</span>
                                    </Grid>
                                </>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        );
    }

}