import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import GenericButton from './../../components/genericButton/genericButton';
import InputSelect from '../../components/inputselect/inputselect';
import { Grid, Switch, AppBar, Tabs, Tab, FormControl, RadioGroup, 
    FormControlLabel, Radio, Chip, Icon} from "@material-ui/core"

export default class AtividadeLinhaDeProducaoDigital extends Component {


    linhaProducaoLeanOptions = [
        { id: 1, label: "Sim", value: "SIM" },
        { id: 2, label: "Não", value: "NAO" },
        { id: 3, label: "Não fez o Mentoria Lean" , value:  "NAO FEZ O MENTORIA LEAN" }
    ];

    cacheColetaIndicadoresText = '';
    
    constructor(props) {
        super(props);
        let disabled = this.props.disabled;

        let turma = this.props.turma;
        let etapa = this.props.etapa;
        this.state = {
            linhaDeProducao :props.atividade,
            errors: {
                coletaIndicadoresText: '',
            }
        };
    };


    changeInput = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
                linhaDeProducao[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
                linhaDeProducao[e.target.name] = e.target.value;
            }
            this.setState({ linhaDeProducao: linhaDeProducao, errors: errors });
        }
    };


    changeInputBool = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            
            if (e.target.name) {
                linhaDeProducao[e.target.name] = this.str2bool(e.target.value);
            }
            this.setState({ linhaDeProducao: linhaDeProducao, errors: errors });
        }
    };

    changeLean = (e) => {
        let linhaDeProducao = this.state.linhaDeProducao;
        linhaDeProducao.linhaProducaoLean = this.linhaProducaoLeanOptions[e.id - 1].value;
        this.setState({ linhaDeProducao: this.state.linhaDeProducao });
    };


    str2bool = (value) => {
        if (value && typeof value === "string") {
             if (value.toLowerCase() === "true") return true;
             if (value.toLowerCase() === "false") return false;
        }
        return value;
     }

    renderRadioButton = (property, type, label) => {
        if(type == "D/C"  || type == "M/A"){
            let val1, val2, op1, op2;
            
            if(type == "D/C"){
                val1 = "DISCRETA";
                val2 = "CONTINUA";
                op1 = "Discreta";
                op2 = "Contínua";
            }
            
            else{
                val1 = "MANUAL";
                val2 = "AUTOMATIZADA";
                op1 = "Manual";
                op2 = "Automatizada";
            }
            return (
                <Grid item xs={12} md={4} xl={4}>
                    <Grid container style={{ marginBottom: '1%' }} direction={'column'}>
                        <Grid item> <label style={{maxWidth:'80%' , display:'flex', marginBottom: -10}}> {label} </label> </Grid>
                        <Grid item className={'radioDisabled'}>
                            <FormControl component="fieldset">
                                <RadioGroup disabled={this.props.disabled} aria-label="position" name={property} value={this.state.linhaDeProducao[property]} 
                                onChange={this.changeInput} row>
                                    <FormControlLabel
                                        value={val1}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" /> }
                                        label={op1}
                                        labelPlacement="end"
                                        />
                                    <FormControlLabel
                                        value={val2}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" />}
                                        label={op2}
                                        labelPlacement="end"
                                        />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        else if(type == "S/N"){
            let val1 = 'true';
            let val2 = 'false';
            let op1 = "Sim";
            let op2 = "Não";
            return (
                <Grid item xs={12} md={4} xl={4}>
                    <Grid container style={{ marginBottom: '1%' }} direction={'column'}>
                        <Grid item> <label style={{maxWidth:'80%' , display:'flex', marginBottom: -10}}> {label} </label> </Grid>
                        <Grid item className={'radioDisabled'}>
                            <FormControl component="fieldset">
                                <RadioGroup disabled={this.props.disabled} aria-label="position" name={property} value={this.state.linhaDeProducao[property] != null ? this.state.linhaDeProducao[property].toString() :null} 
                                onChange={this.changeInputBool} row>
                                    <FormControlLabel
                                        value={val1}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" /> }
                                        label={op1}
                                        labelPlacement="end"
                                        />
                                    <FormControlLabel
                                        value={val2}
                                        disabled={this.props.disabled}
                                        control={<Radio color="primary" />}
                                        label={op2}
                                        labelPlacement="end"
                                        />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            )
            }
        else{
        let val1, val2, val3, op1, op2, op3;
        op1 = 'Diário';
        op2 = 'Semanal';
        op3 = 'Não';
        val1 = 'DIARIO';
        val2 = 'SEMANAL';
        val3 = 'NAO';
        return (
            <Grid item xs={12} md={4} xl={4}>
                <Grid container style={{ marginBottom: '3%' }} direction={'column'}>
                    <Grid item> <label style={{maxWidth:'80%' , display:'flex', marginBottom: -10}}> {label} </label> </Grid>
                    <Grid item className={'radioDisabled'}>
                        <FormControl component="fieldset">
                            <RadioGroup disabled={this.props.disabled} aria-label="position" name={property} value={this.state.linhaDeProducao[property]} 
                            onChange={this.changeInput} row>
                                <FormControlLabel
                                    value={val1}
                                    disabled={this.props.disabled}
                                    control={<Radio color="primary" /> }
                                    label={op1}
                                    labelPlacement="end"
                                    />
                                <FormControlLabel
                                    value={val2}
                                    disabled={this.props.disabled}
                                    control={<Radio color="primary" />}
                                    label={op2}
                                    labelPlacement="end"
                                    />
                                <FormControlLabel
                                    value={val3}
                                    disabled={this.props.disabled}
                                    control={<Radio color="primary" />}
                                    label={op3}
                                    labelPlacement="end"
                                    />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
        )

        }
        
    }

    renderBody = () => {
        let atividade = this.state.linhaDeProducao;
        return (
            <Grid container justify={'center'} style={{ marginTop: '3%' }}>
                <Grid container justify={'flex-start'} direction={'row'} spacing={4} style={{ paddingLeft: '3%' }}>
                    <Grid item xs={12} md={4} xl={3} className={'title-label'} >
                    <div>
                    <label> Linha à ser monitorada </label>
                    </div>
                    </Grid>
                                        
                </Grid>
                <Grid container justify={'flex-start'} direction={'row'} spacing={8} style={{ paddingLeft: '3%' }} >
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <FormField 
                            id={'linhaProducaoIntervencao'} 
                            key={'linhaProducaoIntervencao'} 
                            label={'Qual linha de produção terá intervenção?'} 
                            changeValue={this.changeInput} 
                            disabled={this.props.disabled}
                            inputValue={atividade.linhaProducaoIntervencao}/>                            
                        </div>
                    </Grid>
                    <Grid item xs={12} md={4} xl={3}>
                        <div className={'div-field-btn field-label'}>
                            <InputSelect
                                suggestions={this.linhaProducaoLeanOptions}
                                label={'A linha é a mesma do Mentoria Lean?'}
                                itemKey={'id'}
                                itemLabel={'label'}
                                id={'tipo'}
                                disabled={this.props.disabled}
                                getInput={this.changeLean}
                                initialSelectedItem={{ id: this.state.linhaDeProducao.linhaProducaoLean ? this.linhaProducaoLeanOptions.find(o => o.value === this.state.linhaDeProducao.linhaProducaoLean) ? this.linhaProducaoLeanOptions.find(o => o.value === this.state.linhaDeProducao.linhaProducaoLean).id : 1 : 1 }} />
                        </div>
                    </Grid>                   
                   
                </Grid>
                <Grid container justify={'flex-start'} style={{ marginTop: '2%'}} className={'linha-producao-box'}>
                    <Grid item xs = {12}>
                            <Grid container justify={'space-evenly'} direction={'row'} style={{ marginTop: '1%', marginBottom: '1%'}}>
                                {this.renderRadioButton("processoManufatura", "D/C", "O processo a ser monitorado é de manufatura:")}
                                {this.renderRadioButton("producaoSeriada", "S/N", "A linha a ser monitorada apresenta produção seriada?")}
                                {this.renderRadioButton("linhaMonitorada", "M/A", "A linha a ser monitorada é:")}
                            </Grid>                        
                    </Grid>                    
                </Grid>
                <Grid container justify={'flex-start'}  style={{marginTop: '2%', borderTop: '1px solid #E1E3E6', paddingLeft: '3%' }} direction={'row'} spacing={4}>
                    <Grid item xs={12} className={'title-label'} >
                    <div>
                    <label> Sobre a Gestão de Produtividade da Linha de Produção </label>
                    </div>
                    </Grid>
                                        
                </Grid>
                <Grid container justify={'flex-start'} style={{ marginTop: '2%'}} className={'linha-producao-box'}>
                    <Grid item xs = {12}>
                            <Grid container justify={'flex-start'} direction={'row'} style={{ marginTop: '1%', marginBottom: '1%'}}>
                                {this.renderRadioButton("acompanhamentoProdutividade", "D/S", "A empresa faz acompanhamento da produtividade?")}
                                {this.renderRadioButton("acompanhamentoProducao", "D/S", "A empresa faz acompanhamento da produção?")}
                            </Grid>
                            <Grid container justify={'flex-start'} direction={'row'} style={{ marginTop: '1%', marginBottom: '1%'}}>
                                {this.renderRadioButton("sabeMotivosParadaDeLinha", "S/N", "A empresa sabe os motivos de parada da linha?")}
                                {this.renderRadioButton("trataMotivosParadaDeLinha", "S/N", "A empresa trata os motivos de parada da linha?")}
                            </Grid>                        
                    </Grid>                    
                </Grid>
                <Grid container justify={'flex-start'}  style={{marginTop: '2%', borderTop: '1px solid #E1E3E6', paddingLeft: '3%' }} direction={'row'} spacing={4}>
                    <Grid item xs={12} className={'title-label'} >
                    <div>
                    <label> Infraestrutura </label>
                    </div>
                    </Grid>                                        
                </Grid>
                <Grid container justify={'flex-start'} style={{ marginTop: '2%'}} className={'linha-producao-box'}>
                    <Grid item xs = {12}>
                            <Grid container justify={'space-evenly'} direction={'row'} style={{ marginTop: '1%', marginBottom: '1%'}}>
                                {this.renderRadioButton("wifiEstavel", "S/N", "A empresa possui rede wi-fi estável próxima a linha a ser monitorada?")}
                                {this.renderRadioButton("temResponsavelMINA", "S/N", "A empresa designou algum responsável para realizar os apontamentos na Plataforma IOT?")}
                                {this.renderRadioButton("temAparelhoMINA", "S/N", "A empresa disponibilizou algum aparelho para realizar os apontamentos na Plataforma IOT?")}
                            </Grid>                      
                    </Grid>                    
                </Grid>
            </Grid>
        )
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao-digital'} style={{ marginTop: '30px' }}>
                { this.renderBody() }
            </Grid>
        )
    }

}