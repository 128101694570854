import React from 'react'

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
//import Popper from '@material-ui/core/Popper';

export default function SGTLogoMenuIcon(props) {
    if (props.isOpen === false) {
        return (
            <MenuIcon />
        )
    }
    else {
        return (
            <>
            <img src={process.env.PUBLIC_URL + '/assets/icons/logoSGT-MENOR.svg'} alt='SGTLogoMenu'/>
            <ChevronLeft />
            </>
        )
    }

}
