import React, { Component } from 'react';
import { Button, Chip, Grid, Icon } from '@material-ui/core';
import Visita from './visita';
import EmptyList from '../../components/emptyList/emptyList';
import GenericButton from '../../components/genericButton/genericButton';
import PageBrasilMais from './pageBrasilMais';
import DownloadFiles from '../../comm/DownloadFiles';
import PageBrasilMaisDigital from './pageBrasilMaisDigital'
import CardAtividade from './cardAtividade';
import InfoEncontro from './infoEncontro';
import FormField from '../../components/formField/formField';
import SimpleModal from './../../components/modal/modal';

import './visitasDefault.scss';
import CardEncontro from './cardEncontro';

export default class Mentoria extends Component {
    
    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    selectedVisita = {};
    statusList = [
        { id: 1, label: 'Pendente', class: 'pendente' },
        { id: 2, label: 'Em progresso', class: 'emprogresso' },
        { id: 3, label: 'Feito', class: 'feito' },
        { id: 4, label: 'Contínua', class: 'continuo' },
        { id: 5, label: 'Complementar', class: 'complementar'}
    ];
    selectedAtividade = {};
    openModal = false;
    currVisita = null;
    evidencias = [];
    evidenciaElementList = [];
    DocsAnexados = [];
    encontroCounter = 1;


    constructor(props) {
        super(props);


        this.state = {
            renderBody: 'encontros', editVisitaOrdem: null,
            viewData: JSON.parse(JSON.stringify(this.props.viewData)),
            otherFerramentaLabel: '',
            errorInput: '',
            toggleModal : false
        }
        this.viewDataClone = JSON.parse(JSON.stringify(this.props.viewData));
        this.bindFunctions();
        this.getAnexos();
    };

    countAtividades = () => {
        let totalAtividades = this.state.viewData.totalAtividades;
        if (this.state.viewData.ferramentaConsultoriaList) {
            totalAtividades += this.state.viewData.ferramentaConsultoriaList.length;
        }
        return totalAtividades;
    }

    getOutrasFerramentasModelView = () => {
        return {
            data: {},
            icon: 'outrasFerramentas.svg',
            label: '',
            screenAtividade: '',
            anexos: {
                data: [],
                title: '',
                defaultIcon: 'outras-ferramentas',
            }
        }
    }

    bindFunctions() {
        this.setRenderBody = this.setRenderBody.bind(this);
        this.renderFooterButtons = this.renderFooterButtons.bind(this);
        this.renderFooterButtonsAtividades = this.renderFooterButtonsAtividades.bind(this);
    };

    componentWillReceiveProps(nextProps) {
        this.setState({ viewData: nextProps.viewData })
        this.viewDataClone = JSON.parse(JSON.stringify(nextProps.viewData));
    };

    setRenderBody(value, clear) {
        if (clear) {
            const viewDataClone = JSON.parse(JSON.stringify(this.viewDataClone));
            this.setState({ renderBody: value, viewData: viewDataClone });
        } else {
            this.setState({ renderBody: value });
        }
    };

    changeModal(status){
        this.setState({toggleModal: status});
    }

    getHourLimit = (etapa) =>{
        switch (etapa) {
            case 2:
                return 2;
            case 3:
            case 6:
            case 8:
            case 9:
            case 10:
            case 11:
                return 4;
            case 4:
                return 16;
            case 5:
                return 8;
                break;
            case 7:
                return 12;
        }
    }

    getAnexos = async () => {
        this.evidencias = [];
        this.evidenciaElementList = [];
        this.docsAnexados = [];
        this.encontroCounter = 1;
        console.log(this.props.etapa);
        if (this.props.turma != null && this.evidenciaElementList.length === 0) {
            if(this.props.atendimento){ 
                this.props.atendimento.encontroList.forEach((el)=>{
                this.getEvidenciasEncontros(el, ['anexoEncontroList']);
                this.encontroCounter++;
                });
                await this.getEvidenciasAtividades(this.props.atendimento,['linhaDeProducaoDigital', 'anexoLinhaProducaoList'],'Linha de Produção');
                await this.getEvidenciasAtividades(this.props.atendimento,['processoProdutivoDigital', 'anexoProcessoProdutivoList'],'Processo Produtivo');
                await this.getEvidenciasAtividades(this.props.atendimento,['artefatosInstalacaoDigital', 'anexoArtefatosInstalacaoList'],'Artefatos, Kit & Instalação');
                await this.docsAnexados.push(<Grid item  style={{marginTop: 20}}> <label style={{fontWeight: 'bold', color:'#077CDC'}}> {'Indicadores de Performance'} </label></Grid>)
                await this.getEvidenciasAtividades(this.props.atendimento,['indicadoresDigital','maturidadeDigital', 'anexoMaturidadeDigitalList']);
                await this.getEvidenciasAtividades(this.props.atendimento,['indicadoresDigital','anexoIndicadoresList']);
                await this.getEvidenciasAtividades(this.props.atendimento,['indicadoresDigital','oeeDigital','anexoOEEList']);
                await this.convertAnexos();
                await this.getEvidenciasAtividades(this.props.atendimento,['setupMINA', 'anexoSetupMINAList'],'Setup Plataforma IOT');
                await this.docsAnexados.push(<Grid item  style={{marginTop: 20}}> <label style={{fontWeight: 'bold', color:'#077CDC'}}> {'Relatório A3: Ciclo PDCA'} </label></Grid>)
                await this.getEvidenciasAtividades(this.props.atendimento,['relatorioA3Digital','temaA3', 'itemA3List','anexoItemA3List']);
                await this.getEvidenciasAtividades(this.props.atendimento,['relatorioA3Digital','temaA3', 'contramedidasA3Digital','anexoContramedidasA3List']);
                await this.getEvidenciasAtividades(this.props.atendimento,['relatorioA3Digital','temaA3', 'acompanhamentoA3Digital','anexoAcompanhamentoA3List']);
                await this.getEvidenciasAtividades(this.props.atendimento,['relatorioA3Digital','temaA3', 'conclusaoA3Digital','anexoMelhoriaA3List']);
                await this.convertAnexos();
                if(this.props.atendimento.ferramentaConsultoriaList){
                    this.props.atendimento.ferramentaConsultoriaList.forEach((el)=>{
                        this.getEvidenciasAtividades(el,['anexoFerramentaList'],el.nome);
                        });
                }
                await this.getEvidenciasAtividades(this.props.atendimento,['autossuficienciaDigital', 'anexoAutossuficienciaList'],'Autossuficiência');
                await this.getEvidenciasAtividades(this.props.atendimento,['documentacaoDigital', 'anexoDocumentacaoList'],'Documentação Final');

            }
            else{
                this.props.etapa.encontroList.forEach((el)=>{
                    this.getEvidenciasEncontros(el, ['anexoEncontroList']);
                    this.encontroCounter++;
                    });
                this.getEvidenciasAtividades(this.props.etapa,['treinamentoColetivo', 'anexoAtividadeList'],'Treinamento Coletivo');
            }
            
        }
    };

    convertAnexos = async () => {
        this.evidenciaElementList = this.evidencias.map(
            (anexo, index) => (
                <Chip key={index}
                    label={<label style={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
                        <Icon className={'attach'} style={{ marginRight: '5px' }} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                        <p>{anexo.nomeArquivo}</p></label> } />
        ));
        this.evidencias= [];
        if(this.evidenciaElementList.length == 0){
            this.docsAnexados.pop();
        }
        else{
            this.docsAnexados.push(
            <Grid item>
                <Grid container spacing={1}>
                    { this.evidenciaElementList }
                </Grid>
            </Grid>);
        }
        
        this.setState(this.state);
    };

    getEvidenciasEncontros = (elmt, array) => {
        if (elmt) {
            if (array.length === 0) {
                if (elmt.id != null) this.evidencias = this.evidencias.concat(elmt);
                else if (elmt[0] != null && elmt[0].id != null) this.evidencias = this.evidencias.concat(elmt[0]);
            } else if (!Array.isArray(elmt)) {
                let nameProp = array[0];
                array.splice(0, 1)
                if (Array.isArray(elmt[nameProp]) && array.length === 0){
                    this.docsAnexados.push(<Grid item  style={{marginTop: 20}}> <label style={{fontWeight: 'bold', color:'#077CDC'}}> {'Encontro 0' + this.encontroCounter} </label></Grid>)
                    
                    elmt[nameProp].forEach(element => {
                        this.getEvidenciasEncontros(element, array);
                    });
                    this.convertAnexos();
                }
                    
                else this.getEvidenciasEncontros(elmt[nameProp], array);
            } else if (array) {
                let nameProp = array[0];
                array.splice(0, 1)
                elmt.forEach((element, index) => {
                    if (element.idAtendimento == null || (element.idAtendimento != null && element.idAtendimento === this.props.atendimento.idAtendimento)) {
                        if (Array.isArray(element[nameProp])) 
                            element[nameProp].forEach((aux, i) => {
                                this.getEvidenciasEncontros(aux, Object.assign([], array));
                            });                        
                        else this.getEvidenciasEncontros(element[nameProp], Object.assign([], array));
                    }
                });
            }
        }
    }; 
    
    getEvidenciasAtividades = (elmt, array , name) => {
        if (elmt) {
            if (array.length === 0) {
                if (elmt.id != null) this.evidencias = this.evidencias.concat(elmt);
                else if (elmt[0] != null && elmt[0].id != null) this.evidencias = this.evidencias.concat(elmt[0]);
            } else if (!Array.isArray(elmt)) {
                let nameProp = array[0];
                array.splice(0, 1)
                if (Array.isArray(elmt[nameProp]) && array.length === 0){
                    if(name){
                        this.docsAnexados.push(<Grid item  style={{marginTop: 20}}> <label style={{fontWeight: 'bold', color:'#077CDC'}}> {name} </label></Grid>) 
                    }                                       
                    elmt[nameProp].forEach(element => {
                        this.getEvidenciasAtividades(element, array, name);
                    });
                    if(name){
                        this.convertAnexos();
                    }
                    
                }
                    
                else this.getEvidenciasAtividades(elmt[nameProp], array, name);
            } else if (array) {
                let nameProp = array[0];
                array.splice(0, 1)
                elmt.forEach((element, index) => {
                    if (element.idAtendimento == null || (element.idAtendimento != null && element.idAtendimento === this.props.atendimento.idAtendimento)) {
                        if (Array.isArray(element[nameProp])) 
                            element[nameProp].forEach((aux, i) => {
                                this.getEvidenciasAtividades(aux, Object.assign([], array), name);
                            });                        
                        else this.getEvidenciasAtividades(element[nameProp], Object.assign([], array), name);
                    }
                });
            }
        }
    };

    checkDate = () =>{
        let d = new Date();
        let dataI = new Date(this.props.etapa.dataInicio);
        let dataF = new Date(this.props.etapa.dataTermino);
        let proxMes = dataF.getMonth() == 11 ? 0 : dataF.getMonth() + 1;
        let proxAno = dataF.getMonth() == 11 ? dataF.getFullYear() + 1 : dataF.getFullYear();
        let maxDate = new Date(proxAno,proxMes,8)
        return(d.getTime() <= maxDate.getTime())
    }

    renderTopButtons(setRenderBody) {
        return (<>
            <Grid item md={6} >
                <Grid container style={{ justifyContent: 'flex-end' }}>
                    { !this.props.disabled && <><label className={'labelButtonAdd'}>Adicionar Encontro</label>
                    <GenericButton color={'lightBlue'} subClass={'icon-button'}
                        icon={process.env.PUBLIC_URL + 'plus.svg'}
                        click={() => {
                                if(!this.state.viewData.etapa.etapaFinalizada || this.checkDate()){
                                    if(this.props.atendimento ? this.props.atendimento.horasApropriadas < this.getHourLimit(this.props.etapa.etapa): this.props.etapa.horasApropriadas < this.getHourLimit(this.props.etapa.etapa)){
                                        let ordem = 0;
                                        let encontrosData = this.state.viewData.encontros.data;
                                        if (encontrosData) {
                                            ordem = encontrosData.length === 0 ? 1 : encontrosData[encontrosData.length - 1].ordem + 1;
                                        } 
                                        this.selectedVisita = { data: new Date(), ordem: ordem, isVirtual : false, turno: 0, idMentor: null, horasApropriadas: 0, imagens: [], listaPresenca: [], outrosDocumentos: [] };
                                        this.state.viewData.encontros.data.push(this.selectedVisita);
                                        setRenderBody('atividades');
                                    }
                                    else{
                                        this.props.showToast('Não é permitido criar mais encontros. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
                                    }
                                }
                                else{
                                    this.props.showToast('Não é permitido criar novos encontros. O prazo para inclusão de novos encontros em etapas concluídas foi ultrapassado.', 'error');
                                }
                                    
                                     
                                }} /> </>}
                    <label className={'labelRelatorio'} style={{marginLeft: 10}}>Relatórios e Anexos</label>
                    <GenericButton color={'lightBlue'} subClass={'icon-button'}
                        icon={process.env.PUBLIC_URL + 'relatorio-brasilmais-white.svg'}
                        click={() => {this.getAnexos(); this.changeModal(true);}} />
                        <SimpleModal
                            isOpen={this.state.toggleModal}
                            handleClose={() => this.changeModal(false)}
                            type={'info'}
                            width={'1000px'}
                        >
                            <Grid container>
                                <Grid container className="expand-header">
                                    <Grid item xs={1} className="expand-icon-fase">
                                    <div className={'expand-icon-etapa-blue'}><span>{this.props.fase}</span></div>
                                    </Grid>

                                    <Grid item xs={10} className="expand-icon-info">
                                    <label>{this.props.turma.nome}</label>

                                    <h1>{'Relatórios e Anexos'}</h1>
                                    <h1 style={{fontWeight: 'bold'}}>{this.props.title}</h1>
                                    </Grid>
                                </Grid>

                                <Grid container justify={'center'}>
                                { this.docsAnexados.length === 0 ?
                                    <EmptyList  type={'noItens'} msg={' '} msg={'Ainda não há evidências anexadas'} sizeIcon={{ width: 'auto' }}/> :
                                    <Grid container direction={'column'} spacing={1} style={{padding: '0 100px', marginTop: 20}}>
                                        { this.docsAnexados }
                                    </Grid> }                                    
                                </Grid>
                                <Grid container justify={'center'} >
                                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Voltar'} click={() => { this.changeModal(false)  }} />
                                </Grid>

                            </Grid>
                        </SimpleModal>
                </Grid>
            </Grid>
            
        </>)
    };

    save = async (goTo, viewData, rmVisita) => {
        this.props.showLoading();
        this.state.viewData.selected.visita = this.selectedVisita;
        this.state.viewData.selected.atividade = this.selectedAtividade;
        try {
            await this.props.saveEtapa(viewData ? viewData : this.state.viewData, rmVisita);
            this.selectedAtividade = null;
            this.setRenderBody(goTo, true);
        } catch(error) {
            console.log(error);
        } finally {
            this.props.closeLoading();
        }
    };
    
    countAtividadeNumber = (element, atividades) => {
        return Object.keys(atividades).reduce((prev, key) => {
            const atividadeAtual = atividades[key];
            if (Array.isArray(atividadeAtual)) return prev + this.countAtividadeNumber(element, atividadeAtual);
            const { data } = atividadeAtual;
            let ordem = this.props.fase === 'C' && key === 'mfv' ? 1 : data.ordemVisita;
            const value = this.itsFinishedStatus(data) && ordem <= element.ordem && !atividadeAtual.dontCount ? 1 : 0;
            return prev + value;
        }, 0);
    };

    countAtividadeFerramenta = (element) => {
        const { atividades } = this.state.viewData;
        const { ferramentaConsultoriaList } = this.state.viewData;
        let count = this.countAtividadeNumber(element, atividades);
        if (ferramentaConsultoriaList) count += this.countAtividadeNumber(element, ferramentaConsultoriaList);
        return count;
    }
    
    itsFinishedStatus = (entity) => {
        return entity && (entity.status === this.STATUS_FINALIZADO || entity.status === this.STATUS_CONTINUO);  
    };

    cleanOtherFerramentaLabel = () => {
        this.setState({otherFerramentaLabel: '', errorInput: ''});
    }

    renderFooterButtons() {
        const goBack = () => {
            this.props.goBackAtendimentos();
            this.cleanOtherFerramentaLabel();
        };
        return (
            <Grid item xs={12} className={'bottom-buttons'}>
                { !this.props.dontShowFinalizarMentoria &&
                    <GenericButton color={'darkGreen'} subClass={'basic-button'} label={'Finalizar Mentoria'} click={() => this.props.handleFinishMentoria(this.state.viewData) } />
                }
                &emsp;
                { !this.props.goBackAtendimentos &&
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Retornar à Trilha'} click={this.props.goBack} />
                }
                &emsp;
                { this.props.goBackAtendimentos &&
                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Voltar'} click={ goBack } />
                }
            </Grid>
        );
    };

    renderFooterButtonsAtividades(type, setRenderBody) {
        const renderBody = () => {
            setRenderBody('encontros', true);
            this.cleanOtherFerramentaLabel();
        };
        if (type === 'info') 
            return (
                <Grid item xs={12} className={'bottom-buttons'}>
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Voltar'} click={ renderBody } />
                    &emsp;
                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Salvar'} disabled={this.props.disabled} click={(e) => { this.save('encontros')}} />                    
                </Grid> 
            );
        else 
            return (
                <Grid item xs={12} className={'bottom-buttons'}>
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Retornar à Trilha'} click={this.props.goBack} />
                    &emsp;
                    <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Voltar'} click={ renderBody } />
                </Grid> 
            );
    };

    removerVisitaCard = async (id) => {
        let viewData = this.state.viewData;
        let atividades = viewData.atividades;
        let encontros = viewData.encontros;
        let index = encontros.data.findIndex(obj => obj.id === id);
        if (index > -1) {
            let ordem = encontros.data[index].ordem;
            let atividadeKeys = Object.keys(atividades);
            atividadeKeys.forEach(key => {
                if (atividades[key].data.ordemVisita === ordem) {
                    if (key === 'desperdicio' && this.props.fase === 'C') return;
                    atividades[key].data = this.props.models ? this.props.models()[key] : {};
                    atividades[key].anexos.data = [];
                }
            });
            if (viewData.ferramentaConsultoriaList) {
                const tools = viewData.ferramentaConsultoriaList.map(tool => {
                    if (tool.data.ordemVisita === ordem) {
                        tool.data = {};
                        tool.anexos.data = [];
                    }

                    return tool;
                })
               
                viewData.ferramentaConsultoriaList = tools;
            }
            encontros.data.splice(index, 1);
            viewData.encontros = encontros;
            viewData.atividades = atividades;
        }
        await this.save('encontros', viewData, true);
        this.setRenderBody('encontros', true);
        this.closeModalVisita();
    }

    openModalVisita = (visita) => {
        this.openModal = true;
        this.currVisita = visita;
        this.setState(this.state);
    }

    closeModalVisita = () => {
        this.openModal = false;
        this.currVisita = null;
        this.setState(this.state);
    }

    renderEncontrosCards() {
        const goback = () => {
            this.props.goBackAtendimentos();
            this.cleanOtherFerramentaLabel();
        };
        return (this.state.viewData.encontros.data.length === 0 ?
            (<>
                <Grid item xs={12}>
                    <Grid container>
                        <Grid item xs={12}>
                            <EmptyList icon={'nuvem'} type={'noItens'} msg={' '} subMsg={'Você ainda não adicionou encontros.'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} className={'bottom-buttons'}>
                    <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Retornar à Trilha'} click={this.props.goBack} />
                    &emsp;
                    { this.props.goBackAtendimentos &&
                        <GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Voltar'} click={ goback } />
                    }
                </Grid>
            </>)
            :
            (<>
                <Grid item xs={12} style={{ paddingLeft: '4%', marginTop: '10px' }}>
                    <Grid container spacing={2}>
                        {this.state.viewData.encontros.data.map((element, index) => {
                            return <CardEncontro 
                                        key={index}
                                        visita={element}
                                        openModalVisita={this.openModalVisita}
                                        removerVisitaCard={(id) => { this.removerVisitaCard(id) }}
                                        etapaTurma={this.state.viewData.encontros.title}
                                        etapaFinalizada={this.state.viewData.etapa.etapaFinalizada}
                                        atendimento={this.props.atendimento}
                                        faseTurma={this.props.fase}
                                        nAtividades={ this.countAtividadeFerramenta(element) }
                                        totalAtividades={ this.countAtividades() }
                                        onClick={() => { this.selectedVisita = element; this.setState({ renderBody: 'atividades' }); }}>
                                    </CardEncontro>
                        })}
                    </Grid>
                    {this.currVisita && <SimpleModal
                        isOpen={this.openModal}
                        handleClose={this.closeModalVisita}
                        type={'warning'}
                        width={'500px'}>
                        <div className={'modal'}>
                            <Grid container className={'modal-remove-visita'}>
                                <Grid item xs={12}> 
                                    <p>
                                        Você confirma a remoção da
                                        <label> Visita {this.currVisita.ordem > 10 ? this.currVisita.ordem : '0' + this.currVisita.ordem} </label> –
                                        { !(this.state.viewData.etapa.nome.includes('Mentoria Teórica')) && (<> <label> {this.props.clientName} </label> –</>) }
                                        <label> {this.state.viewData.etapa.nome} </label> –
                                        <label> {this.props.turma.nome} </label>?
                                    </p> 
                                </Grid>
                                <br/>
                                <Grid item xs={12} className={'rodape'}>
                                    <GenericButton
                                        color={'darkGrey-outline'}
                                        label={'Cancelar'}
                                        subClass={'basic-button'}
                                        click={this.closeModalVisita} />
                                    <GenericButton color={'darkBlue'}
                                        label={'Confirmar'}
                                        subClass={'basic-button'}
                                        click={() => { this.removerVisitaCard(this.currVisita.id) }} />
                                </Grid>
                            </Grid>
                        </div>
                    </SimpleModal>}
                </Grid>
                {this.renderFooterButtons()}
            </>)
        )
    };

    renderVisita(setRenderBody) {
        return ( <Visita renderAtividades={ () => this.renderAtividadesEferramentas(setRenderBody) } renderInfo={ () => this.renderInfo(setRenderBody) } /> );
    };

    capitalizeNameTool = (string) => {
        let splitStr = string.split(' ');
        return splitStr.map((str) => { 
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        }).join(' ');
    };

    changeInput = (e) => {
        let otherFerramentaLabel = this.state.otherFerramentaLabel;
        if (e && e.target && e.target.value) {
            otherFerramentaLabel = this.capitalizeNameTool(e.target.value);
            this.setState({ otherFerramentaLabel: otherFerramentaLabel });
        }
    };

    addFerramentas = () => {
        let viewData = this.state.viewData;
        let otherFerramentaLabel = this.state.otherFerramentaLabel;
        let otherFerramentaModel = this.getOutrasFerramentasModelView();
        let errorInput = this.state.errorInput;
        if (otherFerramentaLabel && otherFerramentaLabel.length > 0) {
            let index = viewData.ferramentaConsultoriaList.findIndex(tool => {
                return tool.label.toUpperCase() === otherFerramentaLabel.toUpperCase();
            });
            if (index < 0) {
                otherFerramentaModel.label = otherFerramentaLabel;
                otherFerramentaModel.anexos.title = otherFerramentaLabel;
                otherFerramentaModel.screenAtividade = otherFerramentaLabel;
                viewData.ferramentaConsultoriaList.push(otherFerramentaModel);
                errorInput = '';
                otherFerramentaLabel = '';
            } else errorInput = 'Já existe uma ferramenta com esse nome.'
        } else errorInput = 'Informe um nome para a nova ferramenta.'
        this.setState({ viewData: viewData, otherFerramentaLabel: otherFerramentaLabel, errorInput: errorInput });
    }

    renderAtividadesEferramentas(setRenderBody) {
        return (
            <>
                { this.renderAtividades(setRenderBody) }
                { this.renderFerramentas() }
                { this.state.viewData.ferramentaConsultoriaList && <Grid container direction={'row'} style={{ marginTop: '2%' }}>
                    <Grid item xs={12} md={4} xl={3} >
                        <div className={'add-ferramentas'}>
                            <FormField type={'text'} label={'Inclua uma ferramenta/atividade'} id={'label'} inputValue={this.state.otherFerramentaLabel} 
                            pressEnter={this.addFerramentas} placeholder={''} changeValue={(e) => { this.changeInput(e) }} error={this.state.errorInput}
                            disabled={this.props.disabled}/>
                            <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addFerramentas} icon={'plus.svg'} disabled={this.props.disabled} />
                        </div>
                    </Grid>
                  </Grid> }
                { this.renderFooterButtonsAtividades('atividades', setRenderBody) }
            </>
        )
    }

    renderCardAtividade(index, atividade, statusIndex, disabled) {
        let status = this.statusList.find(aux => { return aux.id === atividade.data.status });
        return (
            <CardAtividade
                key={index}
                icon={ atividade.icon } 
                label={ atividade.label }
                status={ status ? status : this.statusList[statusIndex] }
                disabled={atividade.disabled}
                noCap={true}
                onClick={() => { this.selectedAtividade = atividade; this.setRenderBody( 'atividade' ); }} 
            />
        );
    }

    renderAtividades() {
        const { atividades } = this.state.viewData;
        const cardsAtividade = Object.keys(atividades).map((key, index) => {
            const atividade = atividades[key];
            return this.renderCardAtividade(index, atividade, 0)
        });
        return (<>
            <Grid container style={{ marginTop: '10px' }}>
                { cardsAtividade }
            </Grid>
        </>)
    };

    renderFerramentas() {
        const { ferramentaConsultoriaList } = this.state.viewData;
        if (ferramentaConsultoriaList) {
            const cardsAtividade = ferramentaConsultoriaList.map((ferramenta, index) => {
                return this.renderCardAtividade(index, ferramenta, 4);
            })

            return (<>
                <Grid item className={'label-ferramentas'} style={{ marginTop: '55px', marginBottom: '8px' }}> <label> Ferramentas para intervenção <br /> (incluir apenas se necessário) </label> </Grid>
                <Grid container> { cardsAtividade } </Grid>
            </>)
        }
    };

    renderFooterButtonsDefault = () => {
        const renderBody = () => {
            this.setRenderBody('atividades', true);
            this.cleanOtherFerramentaLabel();
        };
        return (
            <Grid item xs={12} className={'bottom-buttons'}>
                <GenericButton color={'darkBlue-outline'} subClass={'basic-button'} label={'Voltar'} click={ renderBody } />
                &emsp;
                <GenericButton color={'darkBlue'} subClass={'basic-button'} disabled={this.props.disabled} label={'Salvar'} click={() => this.save('encontros') } />
            </Grid>
        );
    };

    renderInfo(setRenderBody) {
        this.selectedAtividade = null;
        return (<>
            <InfoEncontro
                idturma={this.props.turma.id}
                turma={this.props.turma}
                etapaTurma={this.state.viewData.encontros.title}
                etapa={this.state.viewData.etapa}
                faseTurma={this.props.fase}
                encontro={this.selectedVisita}
                closeLoading={this.props.closeLoading}
                showLoading={this.props.showLoading}
                showToast={this.props.showToast}
                disabled={this.props.disabled}
                mentoresDados={this.props.mentoresDados}>
            </InfoEncontro>
            {this.renderFooterButtonsAtividades('info', setRenderBody)}
        </>)
    };

    sumHorasRealizadasEtapa() {
        return this.state.viewData.encontros.data.reduce((a, b) => + a + + b.horasApropriadas, 0);
    };
    
    reloadAtividade = () => {
        this.setRenderBody('atividade');
    };

    render() {
        switch (this.state.renderBody) {
            case 'encontros':
                return (
                    <PageBrasilMaisDigital
                        name={this.props.turma.nome}
                        fase={this.props.fase}
                        title={this.state.viewData.encontros.title}
                        subTitle={ this.props.clientName ? 
                            <label style={{ display: 'flex', fontSize: '15px', fontWeight: '600' }}>
                                <img style={{ marginRight: '10px' }} src={process.env.PUBLIC_URL + '/assets/icons/showcase-cinza.svg'} alt={'icon'} />
                                <label style={{ marginTop: '4px' }}>{this.props.clientName}</label>
                                <label style={{ marginTop: '4px', marginLeft: '40px', fontWeight: '400' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.state.viewData.horas}</label>
                            </label> : <label>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.props.viewData.horas}</label> }
                        renderTopButtons={(e) => { return this.renderTopButtons(this.setRenderBody) }}>
                        {this.renderEncontrosCards()}
                    </PageBrasilMaisDigital>
                );
            case 'atividades':
                return (
                    <PageBrasilMaisDigital
                        name={this.props.turma.nome}
                        fase={this.props.fase}
                        title={<><label className={'label-bold-head'}>Encontro {this.selectedVisita.ordem > 10 ? this.selectedVisita.ordem: '0' + this.selectedVisita.ordem}</label><label> | {this.state.viewData.encontros.title}</label></>}
                        subTitle={ this.props.clientName ? 
                            <label style={{ display: 'flex', fontSize: '15px', fontWeight: '600' }}>
                                <img style={{ marginRight: '10px' }} src={process.env.PUBLIC_URL + '/assets/icons/showcase-cinza.svg'} alt={'icon'} />
                                <label style={{ marginTop: '4px' }}>{this.props.clientName}</label>
                                <label style={{ marginTop: '4px', marginLeft: '40px', fontWeight: '400' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.state.viewData.horas}</label>
                            </label> : <label style={{ display: 'flex', marginBottom: '4px' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.props.viewData.horas}</label> }>
                        {this.renderVisita(this.setRenderBody)}
                    </PageBrasilMaisDigital>
                )
            case 'atividade':
                return (
                    <Grid container>
                        {this.props.renderAtividade(this.selectedAtividade, this.reloadAtividade)}
                        {this.renderFooterButtonsDefault()}
                    </Grid>
                )
            default:
                return;
        }
    };
};