import React, { Component } from 'react';
import Page from './../../components/page/page';
import { Grid } from '@material-ui/core';

export default class NotFound extends Component {

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };


    render() {
        return (
            <Page icon={'alert.svg'} label={'Página não encontrada'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container>
                    <Grid item xs={12}>
                        <h2>A página que você está tentando acessar não foi encontrada.</h2>
                    </Grid>
                </Grid>
            </Page>
        );
    };
}