import { Grid } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import React, { Component } from "react";
import { BsExclamationTriangleFill } from "react-icons/bs";
import API, { newBackend } from "../../comm/API";
import Masks from "../../comm/masks";
import FormField from "../../components/formField/formField";
import GenericButton from "../../components/genericButton/genericButton";
import InputSelect from "../../components/inputselect/inputselect";
import Stepper from "../../components/stepper/stepper";
import SimpleModal from "./../../components/modal/modal";
import { CodigoDNBaseNacionalEnum } from "./enum/codigoDNBaseNacional";

export default class ChangeStatus extends Component {
  statusList = [];
  isUnidadeBP = false;
  isUnidadeIA = false;
  isUnidadeIPEA = false;
  isUnidadeIE = false;
  producaoApropriadaList = [];
  tipoJustificativaList = [];
  anexoOptions = [];
  newStatus = null;
  totalReceitaApropriada = 0;
  dataAceitacao = undefined;
  canChangeStatus = false;
  tipoWarning = "";
  solicitacaoStatusCp = Object.assign({}, this.props.atendimento.solicitacaoStatus);
  titles = ["Revisão", "Classificação", "Contratação"];
  subtitles = ["Revisão", "Classificação de Grau de Maturidade", "Sobre a contratação"];

  //Atendimento B+ LEAN
  produtividade = 0;
  consultoriaD = undefined;

  atendimentoJustificativa = {
    tipoJustificativa: { id: 1 },
    detalhes: "",
    atendimento: undefined,
  };
  nomeArquivoContratoModalConfirm = "";
  uploadAnexoContratoModalConfirm = undefined;

  errors = {
    dataAceitacao: { subClass: "", error: "" },
  };

  constructor(props) {
    super(props);
    this.state = {
      buttonLabel: "",
      modalParecer: false,
      observacao: this.props.atendimento.observacao,
      openModalAceitoPdi: false,
      atendimentoPDI: {},
      nomeArquivoContrato: "",
      uploadAnexoContrato: undefined,
    };
    this.getProducaoApropriada();
    this.getTotalReceitaApropriada();
    this.getPortes();
    this.getUnidadePerfil();
    if (props.isAtendimentoBrasilMaisLEAN && props.turma && props.turma.status > 1) this.getProdutividadeBrasilMaisLEAN();
    else if (props.isAtendimentoDIGITAL && props.turma && props.turma.status > 1) this.getIndicadoresBrasilMaisDIGITAL();
  }

  componentWillMount() {
    var filter = new URLSearchParams();
    if (this.props.isAtendimentoBP) {
      filter.append("proprietario", "BP");
    } else if (this.props.isAtendimentoIA) {
      filter.append("proprietario", "IA");
    } else if (this.props.isAtendimentoIA180) {
      filter.append("proprietario", "IA180");
    } else if (this.props.isAtendimentoIPEA) {
      filter.append("proprietario", "IPEA_" + this.props.atendimento.tipoBPIpea.toUpperCase());
    } else if (this.props.isAtendimentoLEAN28) {
      filter.append("proprietario", "LEAN28");
    } else if (this.props.isAtendimentoLEAN56) {
      filter.append("proprietario", "LEAN56");
    } else if (this.props.isAtendimentoMENTORIA) {
      filter.append("proprietario", "MENTORIA");
    } else if (this.props.isAtendimentoIE) {
      filter.append("proprietario", "IE");
    } else if (this.props.isAtendimentoDIGITAL) {
      filter.append("proprietario", "DIGITAL");
    }
    API.get("/rest/auxiliar/TipoAnexo", { params: filter }).then((res) => {
      res.data.forEach((element) => {
        this.anexoOptions.push({ nome: element.nome, valor: element.valor });
      });
    });
  }

  componentDidMount() {
    this.changeBorderColor();
    this.getTotalReceitaApropriada();
  }

  componentWillReceiveProps(newProps) {
    if (this.statusList.length > 0) {
      this.statusList = [];
      this.setState(this.state);
      this.getProducaoApropriada();
      this.getPortes();
    }
  }

  getTotalReceitaApropriada = () => {
    var filter = {
      "atendimento.id": this.props.atendimento.id,
    };

    API.get("/rest/gestao/ReceitaFaturamento/sum/valor", {
      params: filter,
    })
      .then((res) => {
        this.totalReceitaApropriada = res.data.sum;
        this.getStatusList();
      })
      .catch((error) => {
        console.log(error);
        this.props.showToast("Erro ao consultar a Receita. Favor, entrar em contato com o suporte.", "error");
      });
  };

  getPortes = async () => {
    newBackend.get(`/cliente/${this.props.atendimento.cliente.id}`).then((res) => {
      this.porte_cliente_faturamento = res.data.porte_cliente_faturamento;
      this.porte_cliente_rais = res.data.porte_cliente_rais;
      this.getStatusList();
    }).catch((error) => {
      console.log(error);
      this.props.showToast("Erro ao consultar os portes do cliente. Favor, entrar em contato com o suporte.", "error");
    });
  }

  getProducaoApropriada = () => {
    var filter = {
      "atendimento.id": this.props.atendimento.id,
    };
    API.get("/rest/gestao/ProducaoApropriada/count", {
      params: filter,
    })
      .then((res) => {
        this.producaoApropriadaQtd = res.data ? res.data.count : 0;
        this.getStatusList();
      })
      .catch((error) => {
        console.log(error);
        this.props.showToast("Erro ao consultar a produção apropriada. Favor, entrar em contato com o suporte.", "error");
      });
  };

  async getTipoJustificativas() {
    await API.get("/rest/auxiliar/TipoJustificativa")
      .then((res) => {
        this.tipoJustificativaList = res.data;
        this.atendimentoJustificativa.tipoJustificativa = res.data[0];
        this.props.closeLoading();
      })
      .catch((error) => {
        console.log(error);
        this.props.showToast("Erro ao buscar tipos de justificativa. Favor, entrar em contato com o suporte.", "error");
      });
  }

  getStatusList = () => {
    var filterAux = this.getFiltroAtendimentoStatus(this.props.atendimento.atendimentoStatus.chave);

    let solicitacaoStatus = this.props.atendimento.solicitacaoStatus;
    if (solicitacaoStatus != null) {
      if (solicitacaoStatus.chave === "naoconforme" || solicitacaoStatus.chave === "analise") {
        if (!this.BypassBaseNacional()) {
          filterAux.chave = filterAux.chave.filter((status) => status !== "concluido");
        } else {
          if (!filterAux.chave.includes("concluido")) {
            filterAux.chave.push("concluido");
          }
        }
      }
    }

    var filter = new URLSearchParams();

    if (typeof filterAux.chave !== "string") {
      filterAux.chave.forEach((element) => {
        filter.append("chave", element);
      });
    } else {
      filter.append("chave", filterAux.chave);
    }
    API.get("/rest/gestao/AtendimentoStatus", {
      params: filter,
    })
      .then((res) => {
        this.statusList = res.data;
        this.setState(this.state);
      })
      .catch((error) => {
        this.showToast("Erro ao listar os status dos atendimentos. Favor, entrar em contato com o suporte.", "error");
      });
  };

  createTemplateStatus = (status) => {
    return (
      <div className={"input-select-options change"}>
        <div>
          <label className={`${status.chave}`}>{status.descricao}</label>
        </div>
      </div>
    );
  };

  createTemplateSolicitacao(solicitacaoStatus) {
    return (
      <div className={"change-status"}>
        <div>
          <label className={solicitacaoStatus.chave + " solicitacaoStatus"}>{solicitacaoStatus.descricao}</label>
        </div>
      </div>
    );
  }

  getUnidadePerfil = () => {
    var perfil = this.props.usuario.unidade.tipoUnidade.perfilUsoSistema;

    switch (perfil) {
      case "B+P":
        this.isUnidadeBP = true;
        break;
      case "I+A":
        this.isUnidadeIA = true;
        break;
      case "IPEA":
        this.isUnidadeIPEA = true;
        break;
      case "I+E":
        this.isUnidadeIE = true;
        break;
      default:
        break;
    }
  };

  setVariablesAtendimentoPDI = () => {
    this.canChangeStatus = true;
    const atendimentoPDI = { ...this.props.atendimento };

    atendimentoPDI.trlInicial = "trlInicial" in atendimentoPDI ? atendimentoPDI.trlInicial : 1;
    atendimentoPDI.trlFinal = "trlFinal" in atendimentoPDI ? atendimentoPDI.trlFinal : 1;
    atendimentoPDI.atendimentoStatus = this.newStatus;
    this.setState({ openModalAceitoPdi: true, atendimentoPDI });
  };

  openModal = async (e) => {
    if (e != null && e !== "") {
      if (this.props.atendimento.atendimentoStatus.id !== e.id) {
        if (this.props.isAtendimentoBrasilMaisLEAN) {
          this.props.showLoading();
          await this.getTipoJustificativas();
        }
        this.newStatus = e;
        this.canChangeStatus = e.chave !== "aceito";

        this.setState(this.state);
        if (
          (this.newStatus.chave === "aceito" || this.newStatus.chave === "emexecucao") &&
          (this.props.isAtendimentoBP ||
            (this.props.isAtendimentoIPEA && this.props.atendimento.tipoBPIpea === "CONSULTORIA") ||
            this.props.isAtendimentoIE ||
            this.props.isAtendimentoLEAN ||
            this.props.isAtendimentoDIGITAL ||
            (!this.props.isAtendimentoBrasilMaisLEAN &&
              !this.props.isAtendimentoIA &&
              !this.props.isAtendimentoIA180 &&
              !this.props.isAtendimentoLEAN &&
              this.props.atendimento &&
              this.props.atendimento.produtoRegional &&
              this.props.atendimento.produtoRegional.produtoNacional &&
              this.props.atendimento.produtoRegional.produtoNacional.isBaseNacional))
        ) {
          this.verifyMetas();
        } else {
          if (this.props.atendimento.produtoLinha && this.props.atendimento.produtoLinha.id === 2 && this.newStatus.chave === "aceito") {
            this.setVariablesAtendimentoPDI();
          } else {
            this.props.changeModal("modalConfirmaStatus", true);
          }
        }
      }
    }
  };

  verifyMetas = () => {
    var filter = new URLSearchParams();
    var filterItem = new URLSearchParams();
    if (this.props.isAtendimentoBP) {
      filter.append("setor.id", this.props.atendimento.setor.id);
      filter.append("produtoRegional.produtoNacional.id", this.props.idProdutoBrasilProdutivo);
      filterItem.append("setor.id", this.props.atendimento.setor.id);
      filterItem.append("metaRegional.unidade.id", this.props.atendimento.unidade.unidadePai.id);
    } else if (this.props.isAtendimentoIPEA) {
      this.tipoWarning = "IPEA";
      filter.append("tipoBPIpea", this.props.atendimento.tipoBPIpea);
      filter.append("produtoRegional.produtoNacional.id", this.props.idProdutoBrasilProdutivoIPEA);
      filterItem.append("metaRegionalIPEA.unidade.id", this.props.atendimento.unidade.unidadePai.id);
    } else if (this.props.isAtendimentoLEAN28) {
      this.tipoWarning = "LEAN";
      filter.append("produtoRegional.produtoNacional.id", this.props.idProdutoBrasilProdutivoLEAN28);
      filterItem.append("tipoMeta", "B+P EAD Lean 28");
      filterItem.append("metaRegional.unidade.id", this.props.atendimento.unidade.unidadePai.id);
    } else if (this.props.isAtendimentoLEAN56) {
      this.tipoWarning = "LEAN";
      filter.append("produtoRegional.produtoNacional.id", this.props.idProdutoBrasilProdutivoLEAN56);
      filterItem.append("tipoMeta", "B+P EAD Lean 56");
      filterItem.append("metaRegional.unidade.id", this.props.atendimento.unidade.unidadePai.id);
    } else if (this.props.isAtendimentoMENTORIA) {
      this.tipoWarning = "LEAN";
      filter.append("produtoRegional.produtoNacional.id", this.props.idProdutoBrasilProdutivoMENTORIA);
      filterItem.append("tipoMeta", "B+P Mentoria Lean");
      filterItem.append("metaRegional.unidade.id", this.props.atendimento.unidade.unidadePai.id);
    } else if (this.props.isAtendimentoIE) {
      this.tipoWarning = "IE";
      filter.append("produtoRegional.produtoNacional.id", this.props.idProdutoBrasilProdutivoIE);
      filter.append("setor.id", this.props.atendimento.setor.id);
      filterItem.append("setor.id", this.props.atendimento.setor.id);
      filterItem.append("metaRegionalIE.unidade.id", this.props.atendimento.unidade.unidadePai.id);
    } else if (this.props.isAtendimentoDIGITAL) {
      filter.append("produtoRegional.produtoNacional.id", this.props.idMentoriaDigital);
      filterItem.append("metaRegional.unidade.id", this.props.atendimento.unidade.unidadePai.id);
      filterItem.append("metaRegional.idproduto", this.props.idMentoriaDigital);
    } else if (
      !this.props.isAtendimentoBrasilMaisLEAN &&
      !this.props.isAtendimentoIA &&
      !this.props.isAtendimentoIA180 &&
      !this.props.isAtendimentoLEAN &&
      this.props.atendimento &&
      this.props.atendimento.produtoRegional &&
      this.props.atendimento.produtoRegional.produtoNacional &&
      this.props.atendimento.produtoRegional.produtoNacional.isBaseNacional
    ) {
      filter.append("produtoRegional.produtoNacional.id", this.props.atendimento.produtoRegional.produtoNacional.id);
      filterItem.append("metaRegional.idproduto", this.props.atendimento.produtoRegional.produtoNacional.id);
      filterItem.append("metaRegional.unidade.id", this.props.atendimento.unidade.unidadePai.id);
    }
    filter.append("atendimentoStatus.chave", "aceito");
    filter.append("atendimentoStatus.chave", "emexecucao");
    filter.append("atendimentoStatus.chave", "concluido");
    filter.append("atendimentoStatus.chave", "cancelado");
    filter.append("unidade.unidadePai.id", this.props.atendimento.unidade.unidadePai.id);

    API.get("/rest/gestao/Atendimento/count", {
      params: filter,
    })
      .then((res) => {
        var qtdeAtendimentoAceitoSetor = res.data.count;
        var metaRegional = 0;
        API.get("/rest/gestao/ItemMetaRegional" + this.tipoWarning, {
          params: filterItem,
        }).then((res) => {
          if (res && res.data && res.data[0] != null) {
            if (this.props.isAtendimentoBP) {
              var metaSenai = res.data[0].metaSenai;
              var metaGoverno = res.data[0].metaGoverno;
              metaRegional = metaGoverno + metaSenai;
            } else {
              metaRegional = res.data[0].meta;
            }
          }
          if (qtdeAtendimentoAceitoSetor >= metaRegional) {
            this.props.changeModal("modalWarningStatus", true);
          } else {
            if (this.props.atendimento.produtoLinha && this.props.atendimento.produtoLinha.id === 2 && this.newStatus.chave === "aceito") {
              this.setVariablesAtendimentoPDI();
            } else {
              this.props.changeModal("modalConfirmaStatus", true);
            }
          }
        });
      })
      .catch((error) => {
        this.props.showToast("Erro ao contar os atendimentos. Favor, entrar em contato com o suporte.", "error");
      });
  };

  saveStatus = () => {
    var atendimentoAux = this.props.atendimento;
    atendimentoAux.atendimentoStatus = this.newStatus;

    if (atendimentoAux.atendimentoStatus.id === 4) {
      atendimentoAux.dataAceitacao = this.props.formatData(this.dataAceitacao) + "T00:00:00-03:00";
    }
    this.props.changeStatus();
    this.setState(this.state);

    this.props.showLoading();
    API.post("rest/gestao/Atendimento/" + atendimentoAux.id, atendimentoAux)
      .then((res) => {
        this.handleAnexarProposta(this.uploadAnexoContratoModalConfirm, this.props.atendimento.proposta.id).then((_) => {
          if (this.props.isAtendimentoBrasilMaisLEAN && (this.newStatus.chave === "cancelado" || this.newStatus.chave === "arquivado" || this.newStatus.chave === "recusado")) {
            this.atendimentoJustificativa.atendimento = atendimentoAux;
            API.post("rest/gestao/AtendimentoJustificativa", this.atendimentoJustificativa)
              .then((res) => {
                this.props.openAtendimento(this.atendimentoJustificativa.atendimento.id, true);
                API.get(`rest/gestao/Atendimento/${this.props.atendimento.id}`).then((updatedAtendimento) => {
                  this.props.refreshAtendimento(updatedAtendimento.data);
                  this.props.changeModalShowToast("modalConfirmaStatus", false, "Status alterado com sucesso!");
                  this.props.closeLoading();
                });
              })
              .catch((error) => {
                console.log(error);
                this.props.closeLoading();
                this.props.showToast("Erro ao salvar justificativa. Favor, entrar em contato com o suporte.", "error");
              });
          } else {
            API.get(`rest/gestao/Atendimento/${this.props.atendimento.id}`).then((updatedAtendimento) => {
              this.props.refreshAtendimento(updatedAtendimento.data);
              this.props.changeModalShowToast("modalConfirmaStatus", false, "Status alterado com sucesso!");
              this.props.closeLoading();
            });
          }
        });
      })
      .catch((error) => {
        this.props.closeLoading();
        this.props.showToast("Erro ao mudar status. Favor, entrar em contato com o suporte.", "error");
      });
  };

  getFiltroAtendimentoStatus = (statusAtual) => {
    var aSituacao = [];
    if (this.props.isAtendimentoDIGITAL && this.temSolicitacaoDN()) {
      aSituacao["aceito"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
      aSituacao["emexecucao"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
    }
    if (this.producaoApropriadaQtd === 0) {
      aSituacao["emelaboracao"] = [{ chave: [statusAtual, "pronto"] }];
      aSituacao["pronto"] = [{ chave: [statusAtual, "pronto", "arquivado"] }];
      aSituacao["arquivado"] = [{ chave: [statusAtual, "arquivado"] }];
      aSituacao["recusado"] = [{ chave: statusAtual }];
      aSituacao["concluido"] = [{ chave: statusAtual }];
      aSituacao["cancelado"] = [{ chave: statusAtual }];
      aSituacao["negociacao"] = this.props.isAtendimentoBrasilMaisLEAN ? [{ chave: [statusAtual, "recusado"] }] : [{ chave: [statusAtual, "aceito", "recusado"] }];
      aSituacao["aceito"] = [{ chave: [statusAtual, "cancelado"] }];
      aSituacao["emexecucao"] = [{ chave: [statusAtual, "cancelado"] }];
    } else {
      aSituacao["emelaboracao"] = [{ chave: [statusAtual, "pronto"] }];
      aSituacao["pronto"] = [{ chave: [statusAtual, "pronto", "arquivado"] }];
      aSituacao["arquivado"] = [{ chave: [statusAtual, "arquivado"] }];
      if (
        this.props.isAtendimentoBP ||
        this.props.isAtendimentoIA ||
        this.props.isAtendimentoIPEA ||
        this.props.isAtendimentoLEAN ||
        this.props.atendimento.produtoRegional.produtoNacional.isBaseNacional ||
        this.props.isAtendimentoIE ||
        this.props.isAtendimentoIA180 ||
        this.props.isAtendimentoDIGITAL ||
        this.props.isAtendimentoBrasilMaisLEAN
      ) {
        if (this.props.atendimento.proposta == null) {
          aSituacao["negociacao"] = [{ chave: [statusAtual, "recusado"] }];
        } else {
          if (this.props.atendimento.proposta.anexoPropostaList == null) {
            aSituacao["negociacao"] = [{ chave: [statusAtual, "recusado"] }];
          } else {
            if (this.props.atendimento.proposta.anexoPropostaList.length >= 1) {
              if (this.props.isAtendimentoBrasilMaisLEAN) {
                aSituacao["negociacao"] = [{ chave: [statusAtual, "recusado"] }];
              } else {
                aSituacao["negociacao"] = [{ chave: [statusAtual, "aceito", "recusado"] }];
              }
            } else {
              aSituacao["negociacao"] = [{ chave: [statusAtual, "recusado"] }];
            }
          }
        }
        if (this.temRegistroIndicadores() && this.temSolicitacaoDN()) {
          if (this.props.atendimento.solicitacaoStatus != null && this.props.atendimento.solicitacaoStatus.descricao === "Não Conforme") {
            aSituacao["aceito"] = [{ chave: [statusAtual, "cancelado"] }];
            aSituacao["emexecucao"] = [{ chave: [statusAtual, "cancelado"] }];
          } else {
            aSituacao["aceito"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
            aSituacao["emexecucao"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
          }
        } else {
          if (this.BypassBaseNacional()) {
            aSituacao["aceito"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
            aSituacao["emexecucao"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
          } else {
            aSituacao["aceito"] = [{ chave: [statusAtual, "cancelado"] }];
            aSituacao["emexecucao"] = [{ chave: [statusAtual, "cancelado"] }];
          }
        }
      } else {
        aSituacao["negociacao"] = [{ chave: [statusAtual, "aceito", "recusado"] }];
        aSituacao["aceito"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
        aSituacao["emexecucao"] = [{ chave: [statusAtual, "concluido", "cancelado"] }];
      }
      aSituacao["recusado"] = [{ chave: statusAtual }];
      aSituacao["concluido"] = [{ chave: statusAtual }];
      aSituacao["cancelado"] = [{ chave: statusAtual }];
    }

    return aSituacao[statusAtual][0];
  };

  temRegistroIndicadores = () => {
    var rtno = true;
    if (this.props.isAtendimentoIE && this.props.indicadores != null) {
      if (
        this.props.indicadores[0].reducaoConsumo == undefined || // eslint-disable-line
        this.props.indicadores[0].reducaoCusto == undefined || // eslint-disable-line
        this.props.indicadores[0].reducaoConsumoAno == undefined || // eslint-disable-line
        this.props.indicadores[0].reducaoCustoAno == undefined || // eslint-disable-line
        this.props.indicadores[0].emissaoCo == undefined || // eslint-disable-line
        this.props.indicadores[0].usinaEquivalente == undefined || // eslint-disable-line
        this.props.indicadores[0].casaEquivalente == undefined || // eslint-disable-line
        this.props.indicadores[0].payback == undefined
      ) {
        // eslint-disable-line
        rtno = false;
      }
    } else if (this.props.isAtendimentoDIGITAL && this.props.indicadores != null) {
      if (
        this.props.indicadores[0].produtividade == undefined || // eslint-disable-line
        this.props.indicadores[0].produtividadeFinal == undefined || // eslint-disable-line
        this.props.indicadores[0].oeeInicial == undefined || // eslint-disable-line
        this.props.indicadores[0].oeeFinal == undefined || // eslint-disable-line
        this.props.indicadores[0].disponibilidade == undefined || // eslint-disable-line
        this.props.indicadores[0].performance == undefined || // eslint-disable-line
        this.props.indicadores[0].qualidade == undefined || // eslint-disable-line
        this.props.indicadores[0].retornoPrograma == undefined
      ) {
        // eslint-disable-line
        rtno = false;
      }
    } else if (this.props.isAtendimentoBrasilMaisLEAN) {
      let consultoriaD = this.props.turma.etapaConsultoriaD.atendimentoList.find((aux) => {
        return aux.idAtendimento === this.props.atendimento.id;
      });
      let isHomologado = this.props.usuario.colaborador && this.props.usuario.colaborador.isAtivo && this.props.usuario.colaborador.isHomologadoBrasilMais;
      let isDR = (this.props.usuario.colaborador == null || !this.props.usuario.colaborador.isAtivo) && this.props.usuario.unidade.tipoUnidade.id === 1;
      let podeConcluir = this.props.turma.etapaAtual === 9 && consultoriaD && consultoriaD.finalizado && (isHomologado || isDR);
      let flagEncerrameto = false,
        flagAceite = false;
      this.props.atendimento.termoAceiteList.forEach((termo) => {
        if (termo.tipoTermo.id === 1) flagAceite = true;
        else if (termo.tipoTermo.id === 2) flagEncerrameto = true;
      });
      rtno = podeConcluir && flagAceite && flagEncerrameto && this.props.atendimento.relatorioFinal && this.props.atendimento.relatorioFinal.id;
    } else {
      if (this.props.isAtendimentoIA || this.props.isAtendimentoIA180) {
        if (this.props.atendimento.disponibilidade == undefined) {
          // eslint-disable-line
          rtno = false;
        }
      } else if (this.props.atendimento.movimentacao == undefined) {
        // eslint-disable-line
        rtno = false;
      }
      if (
        this.props.atendimento.produtividade == undefined || // eslint-disable-line
        this.props.atendimento.retornoPrograma == undefined
      ) {
        // eslint-disable-line
        rtno = false;
      }
    }
    return rtno;
  };

  bypassDNRota = () => {
    const { produtoRegional } = this.props.atendimento;
    const codigoDN = produtoRegional.produtoNacional.codigoDN;
    const falhas = [];

    // Definindo os códigos válidos para bypass
    const codigosValidos = [412148];

    if (!codigosValidos.includes(Number(codigoDN))) {
      falhas.push(`Código DN ${codigoDN} não é válido para bypass Rota 2030.`);
      return false;
    }

    const producaoReceitaOk = this.producaoApropriadaQtd > 0 && this.totalReceitaApropriada > 0;

    return producaoReceitaOk;
  };

  BypassBaseNacional = () => {
    const { produtoRegional } = this.props.atendimento;
    const codigoDN = produtoRegional.produtoNacional.codigoDN;
    const falhas = [];

    // Definindo os códigos válidos para bypass
    const codigosValidos = [
      CodigoDNBaseNacionalEnum.melhoriaProdutividade,
      CodigoDNBaseNacionalEnum.manufaturaEnxutaPiloto,
      CodigoDNBaseNacionalEnum.eficienciaEnergeticaPiloto,
      CodigoDNBaseNacionalEnum.transformacaoFase1Piloto,
      CodigoDNBaseNacionalEnum.transformacaoFase2Piloto,
      CodigoDNBaseNacionalEnum.eficienciaEnergetica,
      CodigoDNBaseNacionalEnum.manufaturaEnxuta,
      CodigoDNBaseNacionalEnum.smartFactoryPiloto,
    ];
    const codigosBMP = [
      CodigoDNBaseNacionalEnum.transformacaoFase1Piloto,
      CodigoDNBaseNacionalEnum.transformacaoFase2Piloto,
      CodigoDNBaseNacionalEnum.eficienciaEnergetica,
      CodigoDNBaseNacionalEnum.manufaturaEnxuta,
      CodigoDNBaseNacionalEnum.smartFactoryPiloto,
    ];
    const codigosBMPVerificarPercentual = [CodigoDNBaseNacionalEnum.manufaturaEnxuta, CodigoDNBaseNacionalEnum.eficienciaEnergetica];

    // Verificação inicial do código DN
    if (!codigosValidos.includes(Number(codigoDN))) {
      falhas.push(`Código DN ${codigoDN} não é válido para bypass.`);
      return false;
    }
    // Realiza as checagens necessárias
    const anexosOk = this.checkAnexoReferencialRegional(codigoDN);
    if (!anexosOk) {
      falhas.push("A checagem de anexos falhou.");
    }

    const verificarPercentuais = codigosBMPVerificarPercentual.includes(codigoDN);
    const percentuaisOk = this.checkPercentuaisBaseNacionais(codigoDN, verificarPercentuais);
    if (!percentuaisOk) {
      falhas.push("A checagem de percentuais base nacionais falhou.");
    }

    const producaoReceitaOk = this.producaoApropriadaQtd > 0 && this.totalReceitaApropriada > 0;
    if (!producaoReceitaOk) {
      // falhas.push('A checagem de produção e receita apropriadas falhou.');
    }

    let portesOk = true;
    if (codigosBMP.includes(Number(codigoDN))) {
      portesOk = !!this.porte_cliente_faturamento && !!this.porte_cliente_rais;
      if (!portesOk) {
        falhas.push("É necessário atualizar o porte do cliente.");
        return false;
      }
    }

    // Verificação final combinando todas as condições necessárias
    const bypassValido = anexosOk && percentuaisOk && producaoReceitaOk && portesOk;
    if (!bypassValido) {
      falhas.forEach((falha) => {
        console.log(falha);
      });
    }

    return bypassValido;
  };

  checkPercentuaisBaseNacionais = (codigoDN, verificarPercentuais = true) => {
    const { indicadorAtendimentoReferencialList } = this.props.atendimento;

    if (!indicadorAtendimentoReferencialList || indicadorAtendimentoReferencialList.length === 0) {
      return false;
    }

    const configuracoes = {
      [CodigoDNBaseNacionalEnum.melhoriaProdutividade]: {
        obrigatorio: ["1"],
        percentuais: { 1: [20, 100] },
      },
      [CodigoDNBaseNacionalEnum.manufaturaEnxutaPiloto]: {
        obrigatorio: ["1", "4"],
        percentuais: { 1: [10, 100] },
      },
      [CodigoDNBaseNacionalEnum.eficienciaEnergeticaPiloto]: {
        obrigatorio: ["29", "5", "6", "12", "13", "14", "28"],
        percentuais: { 5: [10, 100] },
      },
      [CodigoDNBaseNacionalEnum.manufaturaEnxuta]: {
        obrigatorio: ["1", "4", "30", "31"],
        percentuais: { 1: [20, 100] },
      },
      [CodigoDNBaseNacionalEnum.eficienciaEnergetica]: {
        obrigatorio: ["5", "6", "12", "13", "14", "28", "29", "31"],
        percentuais: { 5: [10, 100] },
      },
      [CodigoDNBaseNacionalEnum.smartFactoryPiloto]: {
        obrigatorio: ["1", "3", "8", "9", "10", "15"],
        percentuais: { 1: [0, 100] },
      },
      [CodigoDNBaseNacionalEnum.transformacaoFase1Piloto]: {
        obrigatorio: ["32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57"],
      },
      [CodigoDNBaseNacionalEnum.transformacaoFase2Piloto]: {
        obrigatorio: [ "1", "4", "8", "9", "10", "15", "56", "57" ]
      },
    };

    if (!configuracoes.hasOwnProperty(Number(codigoDN))) {
      return false;
    }

    // Verifica se todos os indicadores obrigatórios estão presentes
    const indicadoresObrigatorios = configuracoes[Number(codigoDN)].obrigatorio;
    const indicadoresPresentes = indicadorAtendimentoReferencialList.map((i) => i.indicadorReferencial.id.toString());
    if (!indicadoresObrigatorios.every((id) => indicadoresPresentes.includes(id))) {
      return false; // Um ou mais indicadores obrigatórios não estão presentes
    }

    // Verifica os percentuais apenas se o parâmetro verificarPercentuais for true
    if (verificarPercentuais) {
      // Verifica se os indicadores estão dentro das faixas de percentuais
      const percentuais = configuracoes[Number(codigoDN)].percentuais;
      for (const [indicadorId, [min, max]] of Object.entries(percentuais)) {
        const indicador = indicadorAtendimentoReferencialList.find((i) => i.indicadorReferencial.id.toString() === indicadorId);
        if (!indicador || indicador.resultado < min || indicador.resultado > max) {
          return false;
        }
      }
    }

    return true; // Todos os indicadores obrigatórios estão presentes e dentro da faixa, ou não estamos verificando os percentuais
  };


  checkAnexoReferencialRegional = (codigoDN) => {
    // ID|  NOME                                   |
    // --+-----------------------------------------+
    // 6 |Termo de Adesão ou Contrato              |
    // 7 |Termo de Encerramento                    |
    // 12|Relatório de Atendimento                 |
    // 13|Relatório de Atendimento PDF             |
    // 23|Formulário de Priorização Padrão         |
    // 24|Empresas atendidas no projeto            |
    // 25|Relatório de Assessoria Educacional (4h) |

    const configuracoesAnexo = {
      [CodigoDNBaseNacionalEnum.melhoriaProdutividade]: { qtdAnexo: 2, idsNecessarios: [12, 7] },
      [CodigoDNBaseNacionalEnum.manufaturaEnxutaPiloto]: { qtdAnexo: 3, idsNecessarios: [6, 12, 23] },
      [CodigoDNBaseNacionalEnum.eficienciaEnergeticaPiloto]: { qtdAnexo: 3, idsNecessarios: [6, 12, 23] },
      [CodigoDNBaseNacionalEnum.manufaturaEnxuta]: { qtdAnexo: 8, idsNecessarios: [6, 12, 13, 19, 20, 21, 22, 23] },
      [CodigoDNBaseNacionalEnum.eficienciaEnergetica]: { qtdAnexo: 8, idsNecessarios: [6, 12, 13, 19, 20, 21, 22, 23] },
      [CodigoDNBaseNacionalEnum.smartFactoryPiloto]: { qtdAnexo: 2, idsNecessarios: [24, 25] },
      [CodigoDNBaseNacionalEnum.transformacaoFase1Piloto]: { qtdAnexo: 4, idsNecessarios: [1, 6, 12, 13] },
      [CodigoDNBaseNacionalEnum.transformacaoFase2Piloto]: { qtdAnexo: 4, idsNecessarios: [1, 6, 12, 13] },
    };

    const { qtdAnexo, idsNecessarios } = configuracoesAnexo[Number(codigoDN)];

    // Verifica se o array de anexos é válido
    const anexos = this.props.atendimento.anexoAtendimentoReferencialList;
    if (!Array.isArray(anexos) || anexos.length < qtdAnexo) {
      console.log(`O objeto não tem pelo menos ${qtdAnexo} itens`);
      return false;
    }

    // Verifica se todos os IDs necessários estão presentes
    const idsEncontrados = new Set();
    for (const { anexoReferencial } of anexos) {
      if (anexoReferencial && idsNecessarios.includes(anexoReferencial.id)) {
        idsEncontrados.add(anexoReferencial.id);
      }
    }

    if (idsEncontrados.size !== idsNecessarios.length) {
      console.log("O objeto não contém todos os IDs necessários");
      return false;
    }

    // Se passar por todas as verificações, retorna verdadeiro
    return true;
  };

  temSolicitacaoDN = () => {
    var rtno = true;
    if (this.props.isAtendimentoIPEA && this.props.atendimento.tipoBPIpea === "DIAGNOSTICO") {
      return this.props.atendimento.anexoIndicadorProdutividadeList.length >= 3;
    } else if (
      (this.props.isAtendimentoBrasilMaisLEAN && this.props.atendimento.solicitacaoStatus == null && this.props.atendimento.produtividade >= 20) ||
      (this.props.isAtendimentoBrasilMaisLEAN && this.props.atendimento.solicitacaoStatus && this.props.atendimento.solicitacaoStatus.chave === "conforme")
    ) {
      return true;
    } else if (
      (this.props.isAtendimentoDIGITAL && this.props.atendimento.solicitacaoStatus == null && this.props.atendimento.produtividade >= 20) ||
      (this.props.isAtendimentoDIGITAL && this.props.atendimento.solicitacaoStatus && this.props.atendimento.solicitacaoStatus.chave === "conformeDIGITAL")
    ) {
      return true;
    } else if (this.props.atendimento.solicitacaoStatus == null) {
      rtno = false;
    } else {
      if (
        this.props.atendimento.solicitacaoStatus.chave === "analise" ||
        this.props.atendimento.solicitacaoStatus.chave === "analiseIA" ||
        this.props.atendimento.solicitacaoStatus.chave === "analiseIPEA" ||
        this.props.atendimento.solicitacaoStatus.chave === "analiseIE" ||
        this.props.atendimento.solicitacaoStatus.chave === "analiseIA180" ||
        this.props.atendimento.solicitacaoStatus.chave === "analiseLEAN" ||
        this.props.atendimento.solicitacaoStatus.chave === "analiseDIGITAL"
      ) {
        rtno = false;
      }
    }
    return rtno;
  };

  getType = () => {
    if (this.newStatus.chave === "cancelado" || this.newStatus.chave === "arquivado" || this.newStatus.chave === "recusado" || this.newStatus.chave === "concluido") {
      return "warning";
    } else {
      return "success";
    }
  };

  clearChangeStatus = () => {
    this.dataAceitacao = undefined;
    this.errors.dataAceitacao.error = "";
    this.errors.dataAceitacao.subClass = "";
    if (this.props.isAtendimentoBrasilMaisLEAN && (this.newStatus.chave === "cancelado" || this.newStatus.chave === "arquivado" || this.newStatus.chave === "recusado")) {
      this.atendimentoJustificativa = {
        tipoJustificativa: this.tipoJustificativaList[0],
        detalhes: "",
        atendimento: undefined,
      };
    }
    this.nomeArquivoContratoModalConfirm = "";
    this.uploadAnexoContratoModalConfirm = undefined;
    this.setState(this.state);
    this.props.changeModal("modalConfirmaStatus", false);
  };

  clearChangeStatusPDI = () => {
    this.dataAceitacao = undefined;
    this.errors.dataAceitacao.error = "";
    this.errors.dataAceitacao.subClass = "";
    this.newStatus = undefined;
    this.setState({
      atendimentoPDI: {},
      nomeArquivoContrato: "",
      uploadAnexoContrato: undefined,
      openModalAceitoPdi: false,
    });
  };

  changeDataAceitacao = (e, isPDI = false) => {
    var today = new Date();
    today.setHours(23);
    if (e != null && e !== "") {
      var dateString = e.target.value + "T21:00:00-03:00";
      var dateNegociacao = new Date(this.props.atendimento.dataNegociacao);

      if (this.props.formatData(dateString) <= this.props.formatData(today) && this.props.formatData(dateString) >= this.props.formatData(dateNegociacao)) {
        this.dataAceitacao = e.target.value;
        this.errors.dataAceitacao.error = "";
        this.errors.dataAceitacao.subClass = "";
        this.canChangeStatus = true;
      } else {
        this.dataAceitacao = e.target.value;
        this.canChangeStatus = false;
        this.errors.dataAceitacao.error = "A data de aceitação deve estar entre a data de negociação e a data atual.";
        this.errors.dataAceitacao.subClass = "isInvalid";
      }
    }

    if (isPDI && this.dataAceitacao) {
      const atendimento = { ...this.state.atendimentoPDI };
      atendimento.dataAceitacao = this.dataAceitacao;
      this.setState({ atendimentoPDI: atendimento });
    } else {
      this.setState(this.state);
    }
  };

  checkIfCanSolicitarDNBMP = (codigoDn) => {
    let result;
    const codigoSFPiloto = 42120;
    const codigosBMP = [412159, 412160];
    const producaoReceitaOk = this.producaoApropriadaQtd > 0 && this.totalReceitaApropriada > 0;
    let portesOk = true;
    if (codigosBMP.includes(Number(codigoDn))) {
      portesOk = !!this.porte_cliente_faturamento && !!this.porte_cliente_rais;
      if (!portesOk) {
        return false;
      }
    }
    // Verifica se o códigoDn está presente em codigosDnEEME para os programas Eficiencia Energetica e Manufatura Enxuta
    if (codigosBMP.includes(codigoDn)) {
      const anexoOk = this.checkAnexoReferencialRegional(codigoDn);
      const indicadoresOk = this.checkPercentuaisBaseNacionais(codigoDn, false);
      result = anexoOk && producaoReceitaOk && portesOk && indicadoresOk;
      return result;
    }

    if (Number(codigoDn) === codigoSFPiloto) {
      result = false;
      return result;
    }

    result = true;
    return result;
  }


  checkIfCanSolicitarDN = () => {
    let anexoList = this.props.atendimento.anexoIndicadorProdutividadeList.slice();
    let result = false;
    if (this.props.isAtendimentoIE && this.props.indicadores != null && this.props.indicadores[0] != null) {
      if (
        this.props.indicadores[0].reducaoConsumo == null ||
        this.props.indicadores[0].reducaoCusto == null ||
        this.props.indicadores[0].reducaoConsumoAno == null ||
        this.props.indicadores[0].reducaoCustoAno == null ||
        this.props.indicadores[0].emissaoCo == null ||
        this.props.indicadores[0].usinaEquivalente == null ||
        this.props.indicadores[0].casaEquivalente == null ||
        this.props.indicadores[0].payback == null
      ) {
        result = false;
      }
    } else if (!this.props.isAtendimentoBrasilMaisLEAN) {
      if (this.props.isAtendimentoIA || this.props.isAtendimentoIA180) {
        if (this.props.atendimento.disponibilidade == null) {
          result = false;
        }
      } else if (this.props.atendimento.movimentacao == null) {
        result = false;
      }
      if (this.props.atendimento.produtividade == null || this.props.atendimento.retornoPrograma == null) {
        result = false;
      }
    }
    if (this.props.isAtendimentoIE) {
      result = anexoList.length >= 9;
    } else if (!this.props.isAtendimentoBrasilMaisLEAN) {
      result = anexoList.length >= this.anexoOptions.length;
    }
    if (this.props.isAtendimentoIPEA && this.props.atendimento.tipoBPIpea !== "CONSULTORIA") result = false;
    if (this.props.isAtendimentoBrasilMaisLEAN && this.props.turma) {
      result =
        this.props.turma.etapaAtual === 9 &&
        this.consultoriaD &&
        this.consultoriaD.finalizado &&
        this.produtividade < 20 &&
        (this.props.atendimento.solicitacaoStatus == null ||
          (this.props.atendimento.solicitacaoStatus != null && (this.props.atendimento.solicitacaoStatus.chave === "naoconforme" || this.props.atendimento.solicitacaoStatus.chave === "conforme")));
    } else if (this.props.isModuloNacional) {
      result =
        this.props.atendimento.indicadorAtendimentoReferencialList &&
        this.props.atendimento.indicadorAtendimentoReferencialList.length > 0 &&
        this.props.atendimento.anexoAtendimentoReferencialList &&
        this.props.atendimento.anexoAtendimentoReferencialList.length > 0;
    }
    if (this.props.isAtendimentoDIGITAL && this.props.turma) {
      let praticaD = this.props.turma.etapaPraticaD.atendimentoList.find((aux) => {
        return aux.idAtendimento === this.props.atendimento.id;
      });
      let consultoriaD = this.props.turma.etapaConsultoriaD.atendimentoList.find((aux) => {
        return aux.idAtendimento === this.props.atendimento.id;
      });
      result =
        this.props.turma.etapaAtual >= 10 &&
        praticaD &&
        praticaD.finalizado &&
        consultoriaD &&
        consultoriaD.finalizado &&
        this.produtividade < 20 &&
        (this.props.atendimento.solicitacaoStatus == null ||
          (this.props.atendimento.solicitacaoStatus != null &&
            (this.props.atendimento.solicitacaoStatus.chave === "naoconformeDIGITAL" || this.props.atendimento.solicitacaoStatus.chave === "conformeDIGITAL")));
    }
    return result;
  };

  solicitarEEnviarAprovacaoDN = (type) => {
    this.props.showLoading();
    var filter = {};
    if (this.props.isAtendimentoIA) {
      filter = { chave: type + "IA" };
    } else if (this.props.isAtendimentoIA180) {
      filter = { chave: type + "IA180" };
    } else if (this.props.isAtendimentoBP || this.props.isAtendimentoBrasilMaisLEAN) {
      filter = { chave: type };
    } else if (this.props.isAtendimentoIPEA) {
      filter = { chave: type + "IPEA" };
    } else if (this.props.isAtendimentoIE) {
      filter = { chave: type + "IE" };
    } else if (this.props.isAtendimentoLEAN) {
      filter = { chave: type + "LEAN" };
    } else if (this.props.isAtendimentoDIGITAL) {
      filter = { chave: type + "DIGITAL" };
    } else if (this.props.atendimento.produtoRegional.produtoNacional.isBaseNacional) {
      filter = { chave: type };
    }
    API.get("/rest/gestao/SolicitacaoStatus", { params: filter })
      .then((res) => {
        this.props.atendimento.solicitacaoStatus = res.data[0];
        this.props.atendimento.observacao = this.state.observacao;
        this.props.editarSolicitacaoStatus(res.data[0]);
        API.post("rest/gestao/Atendimento/" + this.props.atendimento.id, this.props.atendimento)
          .then((res) => {
            this.solicitacaoStatusCp = Object.assign({}, this.props.atendimento.solicitacaoStatus);
            this.props.refreshAtendimento(this.props.atendimento);
            this.clearChanges();
            this.props.closeLoading();
            this.changeBorderColor();
            this.props.showToast("Solicitação enviada com sucesso!", "success");
          })
          .catch((error) => {
            console.log(error);
            this.clearChanges();
            this.props.closeLoading();
            this.props.showToast("Erro ao enviar solicitação ao DN. Favor, entrar em contato com o suporte.", "error");
          });
      })
      .catch((error) => {
        console.log(error);
        this.clearChanges();
        this.props.closeLoading();
        this.props.showToast("Erro ao enviar solicitação ao DN. Favor, entrar em contato com o suporte.", "error");
      });
  };

  clearChanges = () => {
    this.solicitacaoStatusCp = Object.assign({}, this.props.atendimento.solicitacaoStatus);
    this.setState({
      modalParecer: false,
      buttonLabel: "",
      observacao: this.props.atendimento.observacao,
    });
  };

  changeBorderColor = () => {
    let doc = document.getElementById("box-parecer-dn");
    if (doc) {
      let solicitarParecerBorder =
        (this.props.isAceito || this.props.isExecucao) &&
        this.props.gerenciarAtendimento &&
        !this.props.isDN &&
        (this.props.atendimento.solicitacaoStatus == null || this.props.atendimento.solicitacaoStatus.descricao === "Não Conforme") &&
        this.checkIfCanSolicitarDN();
      let enviarParecerBorder =
        (this.props.isAceito || this.props.isExecucao) && !this.props.gerenciarAtendimento && this.props.isDN && this.props.atendimento.solicitacaoStatus.descricao === "Em Análise";
      if (solicitarParecerBorder || enviarParecerBorder) {
        this.intervalBorder = setInterval(() => {
          doc.style.border = doc.style.border && doc.style.border.includes("rgb(7, 124, 220)") ? "solid 2px transparent" : "solid 2px #077CDC";
        }, 1000);
      } else {
        clearInterval(this.intervalBorder);
        doc.style.border = "solid 2px transparent";
      }
    }
  };

  async setSolicitacaoStatus() {
    let solcStatus = this.state.solicitacaoStatus.find((solc) => {
      return this.state.atendimento.solicitacaoStatus.chave === solc.chave;
    });
    if (solcStatus) {
      let atend = this.state.atendimento;
      if (this.isAtendimentoBP || this.isAtendimentoBrasilMaisLEAN) {
        atend.solicitacaoStatus = { id: solcStatus.ids[0] };
      }
      if (this.isAtendimentoIA) {
        atend.solicitacaoStatus = { id: solcStatus.ids[1] };
      }
      if (this.isAtendimentoIPEA) {
        atend.solicitacaoStatus = { id: solcStatus.ids[2] };
      }
      if (this.isAtendimentoIE) {
        atend.solicitacaoStatus = { id: solcStatus.ids[3] };
      }
      if (this.isAtendimentoLEAN) {
        atend.solicitacaoStatus = { id: solcStatus.ids[4] };
      }
      if (this.isAtendimentoIA180) {
        atend.solicitacaoStatus = { id: solcStatus.ids[5] };
      }
      if (this.isAtendimentoDIGITAL) {
        atend.solicitacaoStatus = { id: solcStatus.ids[6] };
      }
      await this.setState({ atendimento: atend });
    }
  }

  calculaAumento = (attrC, attrD) => {
    return attrC > 0 && attrD > 0 ? (100 * (attrD - attrC)) / attrC : attrC === 0 && attrD > 0 ? attrD : 0;
  };

  getProdutividadeBrasilMaisLEAN = () => {
    this.consultoriaD = this.props.turma.etapaConsultoriaD
      ? this.props.turma.etapaConsultoriaD.atendimentoList.find((aux) => {
        return aux.idAtendimento === this.props.atendimento.id;
      })
      : undefined;
    let PraticaC = this.props.turma.etapaPraticaC
      ? this.props.turma.etapaPraticaC.atendimentoList.find((aux) => {
        return aux.idAtendimento === this.props.atendimento.id;
      })
      : undefined;
    let processoC = PraticaC ? PraticaC.processoProdutivo : undefined;
    let PraticaD = this.props.turma.etapaPraticaD
      ? this.props.turma.etapaPraticaD.atendimentoList.find((aux) => {
        return aux.idAtendimento === this.props.atendimento.id;
      })
      : undefined;
    let processoD = PraticaD ? PraticaD.processoProdutivo : undefined;
    if (processoC && processoD) {
      this.produtividade = this.calculaAumento(processoC.produtividade, processoD.produtividade);
    }
  };

  getIndicadoresBrasilMaisDIGITAL = () => {
    let processoC = this.props.turma.etapaPraticaC.atendimentoList.find((aux) => {
      return aux.idAtendimento === this.props.atendimento.id;
    });
    let processoD = this.props.turma.etapaPraticaD.atendimentoList.find((aux) => {
      return aux.idAtendimento === this.props.atendimento.id;
    });

    if (processoC && processoC.indicadoresDigital && processoD && processoD.indicadoresDigital) {
      this.produtividade = this.calculaAumento(processoC.indicadoresDigital.produtividadeDigital.medicao, processoD.indicadoresDigital.produtividadeDigital.medicao);
    }
  };

  getSumAtendimentoTotal = () => {
    // TODO: Verificar se o cálculo de fato é esse
    // var rtno = this.props.atendimento.numeroDeReceitaEstimada + this.props.atendimento.vlrEconomico;
    const rtno = this.props.atendimento.previsaoReceitaList.reduce((acc, current) => {
      return acc + current.valor + current.economico;
    }, 0);
    return rtno;
  };

  getSumAtendimentoTotalFinanceiro = () => {
    // TODO: Verificar se o cálculo de fato é esse
    // var rtno = this.props.atendimento.numeroDeReceitaEstimada + this.props.atendimento.vlrEconomico;
    const rtno = this.props.atendimento.previsaoReceitaList.reduce((acc, current) => {
      return acc + current.valor;
    }, 0);
    return rtno;
  };

  getSumAtendimentoTotalEconomico = () => {
    // TODO: Verificar se o cálculo de fato é esse
    // var rtno = this.props.atendimento.numeroDeReceitaEstimada + this.props.atendimento.vlrEconomico;
    const rtno = this.props.atendimento.previsaoReceitaList.reduce((acc, current) => {
      return acc + current.economico;
    }, 0);
    return rtno;
  };

  getPrevisaoReceitaSum = (previsaoReceita) => {
    if (typeof previsaoReceita.valor === "undefined" || previsaoReceita.valor === null) {
      previsaoReceita.valor = 0;
    }
    if (typeof previsaoReceita.economico === "undefined" || previsaoReceita.economico === null) {
      previsaoReceita.economico = 0;
    }
    var rtno = previsaoReceita.valor + previsaoReceita.economico;
    return rtno;
  };

  getPrevisaoReceitaFinanceiroSum = (previsaoReceita) => {
    if (typeof previsaoReceita.valor === "undefined" || previsaoReceita.valor === null) {
      previsaoReceita.valor = 0;
    }

    return previsaoReceita.valor;
  };

  getPrevisaoReceitaEconomicoSum = (previsaoReceita) => {
    if (typeof previsaoReceita.economico === "undefined" || previsaoReceita.economico === null) {
      previsaoReceita.economico = 0;
    }

    return previsaoReceita.economico;
  };

  getPrevisaoReceitaDescription = (previsaoReceita) => {
    switch (previsaoReceita.tipoPrevisaoReceita.id) {
      case 1: //MERCADO
      case 2: //FORMENTO
        return previsaoReceita.fontePagadora.cpfcnpj + " - " + previsaoReceita.fontePagadora.nome;
      case 3: //SENAI
        return "Senai";
      case 5: //ICT
        return previsaoReceita.ictParceiro.descricao;
      case 7: //AGENCIA NACIONAL
        return previsaoReceita.agenciaNacional.descricao;
      case 8: //AGENCIA DE FORMETO
        return previsaoReceita.agenciaFomento.linhaFomento + " - " + previsaoReceita.agenciaFomento.descricao;
      case 10: //UO DO ATENDIMENTO
      case 11: //DR DO ATENDIMENTO
      case 12: //SENAI PARCEIRO
        return previsaoReceita.unidade.cnpj + " - " + previsaoReceita.unidade.descricao;
      case 13: //SISTEMA INDÚSTRIA
      case 14: //SESI/SENAI DN
        if (previsaoReceita.sistemaIndustria != null) {
          return previsaoReceita.sistemaIndustria.cpfcnpj + " - " + previsaoReceita.sistemaIndustria.nome;
        } else {
          return previsaoReceita.fontePagadora.cpfcnpj + " - " + previsaoReceita.fontePagadora.nome;
        }
      default:
        return <label></label>;
    }
  };

  changeTRLRadio = (e, type) => {
    let atendimento = { ...this.state.atendimentoPDI };
    if (this.state.atendimentoPDI[type] != e) {
      atendimento[type] = parseInt(e);
      this.setState({ atendimentoPDI: atendimento });
    }
  };

  formatData(date) {
    if (date) {
      if (typeof date !== "string") {
        let dataList = date.toLocaleDateString().split("/");
        return dataList[2] + "-" + dataList[1] + "-" + dataList[0];
      } else {
        if (date.length > 10) {
          var dataList = date.split("T");
          return dataList[0];
        } else {
          return date;
        }
      }
    }
  }

  validateConcluidoPDI = (step) => {
    switch (step) {
      case 0:
        return true;
      case 1:
        return true;
      case 2:
        const validation =
          this.state.uploadAnexoContrato && this.state.nomeArquivoContrato && this.state.atendimentoPDI.dataAceitacao && this.state.atendimentoPDI.dataAceitacao && !this.errors.dataAceitacao.error;
        return validation;
      default:
        return true;
    }
  };

  handleAnexarProposta = async (anexo, idProposta) => {
    if (anexo != undefined) {
      let file = anexo;
      let formData = new FormData();
      formData.append("name", "file");
      formData.append("file", file);
      formData.append("fileName", file.name);
      if (file.size > 10000000) {
        this.props.closeLoading();
        this.props.showToast("O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.", "error");
        return;
      }

      await API.post("rest/upload/proposta/anexo/" + idProposta, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    }
  };

  saveStatusPDI = async () => {
    if (this.state.atendimentoPDI.id) {
      try {
        this.props.showLoading();

        await this.handleAnexarProposta(this.state.uploadAnexoContrato, this.state.atendimentoPDI.proposta.id);

        const atendimento = { ...this.state.atendimentoPDI };

        atendimento.dataAceitacao = atendimento.dataAceitacao + "T00:00:00-03:00";

        await API.post(`rest/gestao/Atendimento/${atendimento.id}`, atendimento);
        const updatedAtendimento = await API.get(`rest/gestao/Atendimento/${atendimento.id}`);

        this.props.refreshAtendimento(updatedAtendimento.data);

        this.props.changeModalShowToast("modalConfirmaStatus", false, "Status alterado com sucesso!");
        this.clearChangeStatusPDI();
        this.props.closeLoading();
      } catch (e) {
        this.props.closeLoading();
        this.props.showToast("Erro ao mudar o status do atendimento. Favor, entrar em contato com o suporte.", "error");
      }
    }
  };

  getSteps = () => {
    return [
      // 1- Revisão
      <Grid>
        <Grid container>
          <Grid item xs={12}>
            <h3>Datas previstas</h3>
          </Grid>
          <Grid item xs={1} style={{ marginTop: 5 }}>
            <label className="label-field">Início</label>
          </Grid>
          <Grid item xs={5} className={"paddingForm"}>
            <FormField
              type={"date"}
              ref="dataInicio"
              id="dataInicio"
              inputValue={this.formatData(this.props.atendimento.dataInicioPrevista)}
              changeValue={() => { }}
              disabled={true}
              error={this.errors.dataInicio}
              icon={"calendar.svg"}
            />
          </Grid>

          <Grid item xs={1} style={{ marginTop: 5 }}>
            <label className="label-field">Fim</label>
          </Grid>

          <Grid item xs={5}>
            <FormField
              type={"date"}
              ref="dataConclusao"
              id="dataConclusao"
              inputValue={this.formatData(this.props.atendimento.dataConclusaoPrevista)}
              changeValue={() => { }}
              disabled={true}
              error={this.errors.dataConclusao}
              icon={"calendar.svg"}
            />
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <h3>Recursos</h3>
            </Grid>
            {this.props.atendimento.previsaoReceitaList.map((previsaoReceita, index) =>
              previsaoReceita ? (
                <Grid container key={index} className={"container-info"}>
                  <Grid item xs={9}>
                    <b>
                      {index < 10 ? "0" + (index + 1) : index + 1} Fonte pagadora | {previsaoReceita.tipoPrevisaoReceita.descricao}
                    </b>
                  </Grid>

                  {this.getPrevisaoReceitaFinanceiroSum(previsaoReceita) > 0 && (
                    <>
                      <Grid item xs={1}>
                        <label>Fin</label>
                      </Grid>
                      <Grid item xs={2} className={"label-info"}>
                        {Masks.maskCurrency(this.getPrevisaoReceitaFinanceiroSum(previsaoReceita))}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={9} className={"label-info"}>
                    {this.getPrevisaoReceitaDescription(previsaoReceita)}
                  </Grid>

                  {this.getPrevisaoReceitaEconomicoSum(previsaoReceita) > 0 && (
                    <>
                      <Grid item xs={1}>
                        <label>Eco</label>
                      </Grid>
                      <Grid item xs={2} className={"label-info"}>
                        {Masks.maskCurrency(this.getPrevisaoReceitaEconomicoSum(previsaoReceita))}
                      </Grid>
                    </>
                  )}

                  <br></br>
                  <br></br>
                </Grid>
              ) : null
            )}
            <div
              item
              xs={12}
              style={{
                height: 1,
                backgroundColor: "#B4B8C0",
                width: "100%",
                marginBottom: 16,
              }}
            />
            <Grid container style={{ marginBottom: 8 }}>
              <Grid item xs={10}>
                <label>Valor total financeiro</label>
              </Grid>
              <Grid item xs={2}>
                <label>{this.props.atendimento.isValorHora ? "indefinida" : Masks.maskCurrency(this.getSumAtendimentoTotalFinanceiro())}</label>
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: 8 }}>
              <Grid item xs={10}>
                <label>Valor total econômico</label>
              </Grid>
              <Grid item xs={2}>
                <label>{this.props.atendimento.isValorHora ? "indefinida" : Masks.maskCurrency(this.getSumAtendimentoTotalEconomico())}</label>
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: 8 }}>
              <Grid item xs={10}>
                <b>Valor total do projeto</b>
              </Grid>
              <Grid item xs={2}>
                <b>{this.props.atendimento.isValorHora ? "indefinida" : Masks.maskCurrency(this.getSumAtendimentoTotal())}</b>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>,
      // 2- Classificação
      <Grid style={{ marginBottom: 32 }}>
        <Grid item xs={12}>
          <h3>TRL Inicial</h3>
        </Grid>
        <Grid container>
          <RadioGroup row className={"radio-group radio-group-change-status"} value={this.state.atendimentoPDI.trlInicial} onChange={(e) => this.changeTRLRadio(e.target.value, "trlInicial")}>
            <FormControlLabel className={"radio"} value={1} control={<Radio />} label={"1"} />
            <FormControlLabel className={"radio"} value={2} control={<Radio />} label={"2"} />
            <FormControlLabel className={"radio"} value={3} control={<Radio />} label={"3"} />
            <FormControlLabel className={"radio"} value={4} control={<Radio />} label={"4"} />
            <FormControlLabel className={"radio"} value={5} control={<Radio />} label={"5"} />
            <FormControlLabel className={"radio"} value={6} control={<Radio />} label={"6"} />
            <FormControlLabel className={"radio"} value={7} control={<Radio />} label={"7"} />
            <FormControlLabel className={"radio"} value={8} control={<Radio />} label={"8"} />
            <FormControlLabel className={"radio"} value={9} control={<Radio />} label={"9"} />
          </RadioGroup>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 24 }}>
          <h3>TRL Final</h3>
        </Grid>
        <Grid container>
          <RadioGroup row className={"radio-group radio-group-change-status"} value={this.state.atendimentoPDI.trlFinal} onChange={(e) => this.changeTRLRadio(e.target.value, "trlFinal")}>
            <FormControlLabel className={"radio"} value={1} control={<Radio />} label={"1"} />
            <FormControlLabel className={"radio"} value={2} control={<Radio />} label={"2"} />
            <FormControlLabel className={"radio"} value={3} control={<Radio />} label={"3"} />
            <FormControlLabel className={"radio"} value={4} control={<Radio />} label={"4"} />
            <FormControlLabel className={"radio"} value={5} control={<Radio />} label={"5"} />
            <FormControlLabel className={"radio"} value={6} control={<Radio />} label={"6"} />
            <FormControlLabel className={"radio"} value={7} control={<Radio />} label={"7"} />
            <FormControlLabel className={"radio"} value={8} control={<Radio />} label={"8"} />
            <FormControlLabel className={"radio"} value={9} control={<Radio />} label={"9"} />
          </RadioGroup>
        </Grid>
      </Grid>,
      // Contratação
      <Grid container justify="flex-start" style={{ marginBottom: 64 }}>
        <Grid item xs={12} style={{ marginTop: 5, marginBottom: 8 }}>
          <label className="label-field">Data de contratação</label>
        </Grid>
        <Grid item xs={4} className={"paddingForm"}>
          <FormField
            type={"date"}
            ref="dataAceitacao"
            id="dataAceitacao"
            inputValue={this.formatData(this.state.atendimentoPDI.dataAceitacao)}
            changeValue={(e) => this.changeDataAceitacao(e, true)}
            required={"required"}
            error={this.errors.dataAceitacao.error}
            icon={"calendar.svg"}
          />
        </Grid>

        <Grid item xs={12} style={{ marginTop: 32 }}>
          <label className="label-field">Anexo de contrato</label>
          <label className="label-field" style={{ display: "block" }}>
            ( apenas arquivos em pdf, doc, docx, xls, xlsx )
          </label>
        </Grid>

        <Grid item xs={4}>
          <label
            className={"darkBlue basic-button"}
            id="control-label"
            htmlFor="uploadfile"
            style={{
              color: "#fff",
            }}
          >
            Anexar arquivo
          </label>
          <input
            type="file"
            name="upload"
            id="uploadfile"
            className="file-input"
            onChange={(e) =>
              this.setState({
                nomeArquivoContrato: e.target.value,
                uploadAnexoContrato: e.target.files[0],
              })
            }
          />
        </Grid>
        {this.state.nomeArquivoContrato && (
          <Grid item xs={12} style={{ display: "flex", alignItems: "center", marginTop: 16 }}>
            <strong style={{ marginRight: 4 }}>Nome do arquivo:</strong>
            <p className="file-name">{this.state.nomeArquivoContrato.split("\\").pop()}</p>
          </Grid>
        )}
      </Grid>,
    ];
  };

  canChangeStatusChecker = () => {
    if (this.state.atendimentoPDI.id) return !this.canChangeStatus;

    switch (this.newStatus.chave) {
      case "concluido":
        return !this.canChangeStatus;
      case "aceito":
        return !(this.canChangeStatus && this.uploadAnexoContratoModalConfirm !== undefined);
      default:
        return !this.canChangeStatus;
    }
  };

  render() {
    let buttonLabel = this.props.isDN ? "Enviar Parecer" : "Solicitar Parecer";
    let showBoxParecer = !this.props.isPronto && !this.props.isNegociacao && !this.props.isArquivado && !this.BypassBaseNacional();
    let showSelectSolicitacao = !this.props.gerenciarAtendimento && this.props.isDN && this.props.atendimento.solicitacaoStatus.descricao === "Em Análise";
    const codigoDN = Number(this.props.atendimento.produtoRegional.produtoNacional.codigoDN);
    let canSolicitarAparecerBP =
      this.props.atendimento.produtoRegional.produtoNacional.codigoDN === 412148 ? !this.BypassBaseNacional() : !this.bypassDNRota();
    canSolicitarAparecerBP = this.checkIfCanSolicitarDNBMP(codigoDN);
    let solicitarParecer =
      ((this.props.isAceito || this.props.isExecucao) &&
        this.props.gerenciarAtendimento &&
        !this.props.isDN &&
        (this.props.atendimento.solicitacaoStatus == null ||
          this.props.atendimento.solicitacaoStatus.descricao === "Não Conforme" ||
          this.props.atendimento.solicitacaoStatus.descricao === "Conforme") &&
        this.checkIfCanSolicitarDN()) ||
      (this.props.isAtendimentoDIGITAL &&
        (this.props.atendimento.solicitacaoStatus == null || (this.props.atendimento.solicitacaoStatus != null && this.props.atendimento.solicitacaoStatus.descricao == null)));
    let enviarParecer =
      !this.props.gerenciarAtendimento && this.props.isDN && this.props.atendimento.solicitacaoStatus.descricao === "Em Análise" && this.solicitacaoStatusCp.descricao !== "Em Análise";
    if (this.props.isModuloNacional) {
      let indicador = this.props.atendimento.indicadorAtendimentoReferencialList.some((indicador) => {
        return indicador.solicitacaoStatus && indicador.solicitacaoStatus.descricao === "Não Conforme";
      });
      let anexo = this.props.atendimento.anexoAtendimentoReferencialList.some((anexo) => {
        return anexo.solicitacaoStatus && anexo.solicitacaoStatus.descricao === "Não Conforme";
      });
      if (indicador || anexo) this.props.solicitacaoStatusList[1]["group"] = "grey";
      else {
        //ter pelo menos um indicador ou anexo avaliado para enviar parecer
        this.props.solicitacaoStatusList[1]["group"] = null;
        indicador = this.props.atendimento.indicadorAtendimentoReferencialList.some((indicador) => {
          return indicador.solicitacaoStatus;
        });
        anexo = this.props.atendimento.anexoAtendimentoReferencialList.some((anexo) => {
          return anexo.solicitacaoStatus;
        });
      }
      enviarParecer = enviarParecer && (indicador || anexo);
    }
    return (
      <Grid container spacing={2} className={"box-change-status"}>
        <Grid item xs={12} md={showBoxParecer ? 6 : 12}>
          <div className={"greyDiv"}>
            <h2>Status</h2>
            <div style={{ width: "100%" }}>
              {this.statusList.length > 0 && this.statusList != null && this.props.gerenciarAtendimento && !this.props.isDN && this.props.canChangeStatus ? (
                <InputSelect
                  suggestions={this.statusList}
                  itemKey={"id"}
                  itemLabel={"descricao"}
                  itemTemplate={this.createTemplateStatus}
                  id={"atendimentoStatus"}
                  getInput={this.openModal}
                  initialSelectedItem={{
                    id: this.props.atendimento.atendimentoStatus.id,
                  }}
                />
              ) : (
                <div className={"input-select-options change"}>
                  <label className={`${this.props.atendimento.atendimentoStatus.chave}`}>{this.props.atendimento.atendimentoStatus.descricao}</label>
                </div>
              )}
            </div>
          </div>
        </Grid>
        {showBoxParecer && (
          <Grid item xs={12} md={6}>
            <div className={"greyDiv change-status"} id={"box-parecer-dn"}>
              <GenericButton
                color={"darkBlue"}
                label={buttonLabel}
                subClass={"basic-button"}
                click={() => {
                  this.setState({ buttonLabel, modalParecer: true });
                }}
                disabled={
                  (buttonLabel === "Solicitar Parecer" && !solicitarParecer) ||
                  (buttonLabel === "Enviar Parecer" && !enviarParecer) ||
                  (buttonLabel === "Solicitar Parecer" && !canSolicitarAparecerBP)
                }
              />
              <h2>Parecer DN</h2>
              {showSelectSolicitacao ? (
                <InputSelect
                  suggestions={this.props.solicitacaoStatusList}
                  itemLabel={"descricao"}
                  itemKey={"descricao"}
                  itemTemplate={this.createTemplateSolicitacao}
                  id={"atendimentoStatus"}
                  getInput={(e) => {
                    this.solicitacaoStatusCp = e;
                    this.setState(this.state);
                  }}
                  initialSelectedItem={this.solicitacaoStatusCp}
                />
              ) : (
                <div className={"input-select-options change"}>
                  <label className={this.props.atendimento.solicitacaoStatus ? this.props.atendimento.solicitacaoStatus.chave + " solicitacaoStatus" : ""}>
                    {this.props.atendimento.solicitacaoStatus ? this.props.atendimento.solicitacaoStatus.descricao : "Este atendimento não possui parecer registrado."}
                    {this.props.atendimento.produtoRegional.produtoNacional.codigoDN === "412151" && !this.BypassBaseNacional() && this.props.atendimento.comentario == "" && (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", verticalAlign: "middle" }}>
                        <BsExclamationTriangleFill size={20} color="orange" />
                        <p style={{ fontSize: "0.90rem", fontWeight: "bold", marginLeft: "10px", marginTop: "14px" }}>
                          Necessário justificar o resultado de produtividade menor que 20% e maior que 100% no campo Comentário.
                        </p>
                      </div>
                    )}
                    {this.props.atendimento.produtoRegional.produtoNacional.codigoDN === "412157" && !this.BypassBaseNacional() && this.props.atendimento.comentario == "" && (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", verticalAlign: "middle" }}>
                        <BsExclamationTriangleFill size={20} color="orange" />
                        <p style={{ fontSize: "0.90rem", fontWeight: "bold", marginLeft: "10px", marginTop: "14px" }}>
                          Para conclusão do atendimento necessário que o percentual do Indicador de Produtividade esteja entre <strong>20%-100% </strong>, caso contrario deve ser enviado para parecer
                          DN, com a justificativa do resultado no campo <strong>COMENTÁRIO</strong>.
                        </p>
                      </div>
                    )}
                    {this.props.atendimento.produtoRegional.produtoNacional.codigoDN === "412158" && !this.BypassBaseNacional() && this.props.atendimento.comentario == "" && (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", verticalAlign: "middle" }}>
                        <BsExclamationTriangleFill size={20} color="orange" />
                        <p style={{ fontSize: "0.90rem", fontWeight: "bold", marginLeft: "10px", marginTop: "14px" }}>
                          Para conclusão do atendimento necessário que o percentual do Redução de Consumo Energético esteja entre <strong>10%-100% </strong> , caso contrário deve ser enviado para
                          parecer DN, com a justificativa do resultado no campo <strong>COMENTÁRIO</strong>.
                        </p>
                      </div>
                    )}
                  </label>
                </div>
              )}
            </div>
          </Grid>
        )}
        {this.props.modalConfirmaStatus ? (
          <SimpleModal isOpen={this.props.modalConfirmaStatus} handleClose={this.clearChangeStatus} type={`${this.getType()}`} width={"500px"}>
            <div className={"modal modal-change-status"}>
              <Grid container>
                <p>Você confirma a mudança de status do atendimento para:</p>
                <label className={`${this.newStatus.chave}`}>{this.newStatus.descricao}?</label>
                <br></br>
                {!this.state.atendimentoPDI.id && (
                  <>
                    {this.newStatus !== null &&
                      this.props.isAtendimentoBrasilMaisLEAN &&
                      (this.newStatus.chave === "cancelado" || this.newStatus.chave === "arquivado" || this.newStatus.chave === "recusado") ? (
                      <div className="justificativa">
                        <InputSelect
                          key="tipoJustificativa"
                          label={"Qual o motivo da mudança do status?"}
                          suggestions={this.tipoJustificativaList}
                          itemLabel={"descricao"}
                          itemKey={"id"}
                          getInput={(e) => {
                            this.atendimentoJustificativa.tipoJustificativa = e;
                          }}
                          initialSelectedItem={{
                            id: this.atendimentoJustificativa.tipoJustificativa.id,
                          }}
                        />
                        <FormField
                          type={"textarea"}
                          label={""}
                          placeholder={"Descreva o motivo."}
                          changeValue={(e) => {
                            this.atendimentoJustificativa.detalhes = e.target.value;
                            this.setState(this.state);
                          }}
                          disabled={false}
                          inputValue={this.atendimentoJustificativa.detalhes}
                          maxLength={"250"}
                        />
                      </div>
                    ) : null}
                    {this.newStatus !== null && this.newStatus.chave === "aceito" ? (
                      <>
                        <div style={{ display: "flex", margin: "15px 15px" }}>
                          <FormField
                            type={"date"}
                            ref="dataAceitacao"
                            id={"dataAceitacao"}
                            inputValue={this.props.formatData(this.dataAceitacao)}
                            changeValue={this.changeDataAceitacao}
                            icon={"calendar.svg"}
                          />
                        </div>
                      </>
                    ) : this.newStatus !== null && this.newStatus.chave === "concluido" ? (
                      <span className={"font-red"}>Esta operação fará com que não seja mais possível apropriar produção e receita!</span>
                    ) : null}

                    <span className={"font-red"}>{this.errors.dataAceitacao.error}</span>

                    {this.newStatus !== null && this.newStatus.chave === "aceito" && (
                      <Grid container style={{ marginLeft: 16 }}>
                        <Grid item xs={12} style={{ marginTop: 32 }}>
                          <div className="label-field">Anexo de Instrumento Jurídico</div>
                          <div className="label-field" style={{ display: "block", marginBottom: 16 }}>
                            ( apenas arquivos em pdf, doc, docx, xls, xlsx )
                          </div>
                        </Grid>

                        <Grid item xs={4}>
                          <label className={"darkBlue basic-button no-style"} id="control-label" htmlFor="uploadfile">
                            Anexar arquivo
                          </label>
                          <input
                            type="file"
                            name="upload"
                            id="uploadfile"
                            className="file-input"
                            onChange={(e) => {
                              this.nomeArquivoContratoModalConfirm = e.target.value;
                              this.uploadAnexoContratoModalConfirm = e.target.files[0];
                              this.setState(this.state);
                            }}
                          />
                        </Grid>
                        {this.nomeArquivoContratoModalConfirm && (
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: 16,
                            }}
                          >
                            <strong style={{ marginRight: 4, fontSize: 12 }}>Nome do arquivo:</strong>
                            <p className="file-name" style={{ fontSize: 12 }}>
                              {this.nomeArquivoContratoModalConfirm.split("\\").pop()}
                            </p>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </>
                )}

                <Grid item xs={12} className={"rodape"}>
                  <GenericButton color={"darkGrey-outline"} label={"Cancelar"} subClass={"basic-button"} click={this.clearChangeStatus} />

                  <GenericButton
                    color={"darkBlue"}
                    label={"Confirmar"}
                    subClass={"basic-button"}
                    click={!this.state.atendimentoPDI.id ? this.saveStatus : this.saveStatusPDI}
                    disabled={this.canChangeStatusChecker()}
                  />
                </Grid>
              </Grid>
            </div>
          </SimpleModal>
        ) : null}
        {this.props.modalWarningStatus ? (
          <SimpleModal isOpen={this.props.modalWarningStatus} handleClose={() => this.props.changeModal("modalWarningStatus", false)} type={"warning"} width={"400px"}>
            <div className={"modal modal-change-status"}>
              <Grid container>
                <p>
                  <b>Não é possível alterar o status deste atendimento.</b> Regional já atingiu a meta de atendimentos aceitos / concluídos / cancelados para esse produto.
                </p>
                <Grid item xs={12} className={"rodape"}>
                  <GenericButton color={"darkGrey-outline"} label={"Voltar"} subClass={"basic-button"} click={() => this.props.changeModal("modalWarningStatus", false)} />
                </Grid>
              </Grid>
            </div>
          </SimpleModal>
        ) : null}
        {this.state.modalParecer && this.state.buttonLabel === "Enviar Parecer" && (
          <SimpleModal isOpen={this.state.modalParecer} handleClose={this.clearChanges} type={this.solicitacaoStatusCp.descricao === "Conforme" ? "success" : "warning"} width={"500px"}>
            <div className={"modal"}>
              <Grid container className={"modal-change-status"}>
                <p>Você confirma a atualização do parecer para</p>
                <label className={`${this.solicitacaoStatusCp.chave}`}> {this.solicitacaoStatusCp.descricao}? </label>
                <br></br>
                <Grid item xs={12} className={"field-note"}>
                  <p>Adicionar observação (opcional)</p>
                  <FormField
                    type={"textarea"}
                    placeholder={"Escreva uma nota sobre este parecer"}
                    changeValue={(e) => {
                      this.setState({ observacao: e.target.value });
                    }}
                    disabled={false}
                    inputValue={this.state.observacao}
                    maxLength={"1000"}
                  />
                </Grid>
                <Grid item xs={12} className={"rodape"}>
                  <GenericButton color={"darkGrey-outline"} label={"Cancelar"} subClass={"basic-button"} click={this.clearChanges} />
                  <GenericButton color={"darkBlue"} label={"Confirmar"} subClass={"basic-button"} click={() => this.solicitarEEnviarAprovacaoDN(this.solicitacaoStatusCp.chave)} />
                </Grid>
              </Grid>
            </div>
          </SimpleModal>
        )}
        {this.state.modalParecer && this.state.buttonLabel === "Solicitar Parecer" && (
          <SimpleModal isOpen={this.state.modalParecer} handleClose={this.clearChanges} type={"info-blue"} width={"500px"}>
            <div className={"modal"}>
              <Grid container className={"modal-change-status"}>
                <p>Você deseja solicitar o parecer do atendimento agora?</p>
                <Grid item xs={12} className={"rodape"}>
                  <GenericButton color={"darkGrey-outline"} label={"Cancelar"} subClass={"basic-button"} click={this.clearChanges} />
                  <GenericButton color={"darkBlue"} label={"Sim, desejo"} subClass={"basic-button"} click={() => this.solicitarEEnviarAprovacaoDN("analise")} />
                </Grid>
              </Grid>
            </div>
          </SimpleModal>
        )}

        {this.state.openModalAceitoPdi && (
          <SimpleModal isOpen={this.state.openModalAceitoPdi} handleClose={this.clearChangeStatusPDI} width={"800px"}>
            <div className={"modal"}>
              <Grid container>
                <p>Antes de realizar a mudança de status você precisa revisar e adicionar algumas informações</p>

                <Stepper
                  titles={this.titles}
                  newTitle={this.subtitles}
                  steps={this.getSteps()}
                  validation={this.validateConcluidoPDI}
                  sendForm={() => this.props.changeModal("modalConfirmaStatus", true)}
                />
              </Grid>
            </div>
          </SimpleModal>
        )}
      </Grid>
    );
  }
}
