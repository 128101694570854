import React, { Component } from 'react';
import EncontrosDefault from './encontrosDefault';
import API from '../../comm/API';
import AtendimentosCardDigital from './atendimentosCardDigital';
import { getByKeyOrEmpty, mapAtendimentoNome, viewDataModels } from './mentoriaUtilDigital';
import AtividadeDefaultDigital from './atividadeDefaultDigital';
import AtividadeCicloPDCADigital from './atividadeCicloPDCADigital';
import AtividadeMFV from './atividadeMFV';
import AtividadeMaisInfo from './atividadeMaisInfo';

import './atividadeCicloPDCADigital.scss';

export default class ConsultoriaC extends Component {

    STATUS_FINALIZADO = 3;
    STATUS_CONTINUO = 4;
    STATUS_EMPROGRESSO = 2;
    renderOptions = {
        ATENDIMENTO: 'atendimento',
        VISITAS: 'encontros'
    }

    switchAnexos = null;
    atendimentoEtapaB = null;
    atendimentoEtapaC = null;

    constructor(props) {
        super(props);

        const etapaName = `etapaConsultoria${this.props.fase}`;
        const etapa = this.props.turma[etapaName];
        this.viewData = JSON.parse(JSON.stringify(viewDataModels[etapaName]));
        this.viewData.turma = this.props.turma.nome;
        this.viewData.etapa.etapaFinalizada = etapa.finalizada;
        this.viewData.etapa.nome = 'Consultoria C';
        this.arrayFerramentas = this.props.arrayFerramentas;
        const atendimentoList = mapAtendimentoNome(etapa, this.props.turma);

        this.state = {
            turma: this.props.turma,
            renderTo: this.renderOptions.ATENDIMENTO,
            viewData: this.viewData,
            atendimentoConsultoria: undefined,
            etapa,
            atendimentoList,
            showTopButtons: true,
            switchAnexos: [],
            clientName: '',
            disabled: false
        };
    };

    getCicloPDCAModel() {
        return { 
            temaA3:{
                contramedidasA3Digital: {
                    planoDeAcaoList:[],
                    anexoContramedidasA3List:[],
                    id: 1
                },
                acompanhamentoA3Digital:{
                    medidasAcompanhamentoList: [],
                    anexoAcompanhamentoA3List: [],
                    id:1
                },
                conclusaoA3Digital:{
                    anexoMelhoriaA3List:[],
                    id:1
                },
                analise: {
                    codItem: 4,
                    descricao: '',
                    id: 1, 
                    nome: 'ANALISE', 
                    status: 1,
                    anexoItemA3List:[]},
                condicoesAtuais:{
                    codItem: 2,
                    descricao: '',
                    id: 4,
                    nome: 'CONDICOES ATUAIS',
                    status: 1,
                    anexoItemA3List: []},
                historico:{
                    codItem: 1,
                    descricao: '',
                    id: 2,
                    nome:'HISTORICO',
                    status: 1,
                    anexoItemA3List: []},
                // id: 1,
                metas: {
                    codItem: 3,
                    descricao: '',
                    id: 3,
                    nome:'METAS',
                    status: 1,
                    anexoItemA3List:[]},
                // nome:'',
                verificacao: {
                    codItem: 5,
                    descricao: '',
                    id: 5, 
                    nome: 'VERIFICACAO',
                    status: 1,
                    anexoItemA3List:[],
                }

            }, 
            anexoRelatorioA3List:[],
            id: 2,
            ordemVisita: 1,
            status: 1,
        }
    };

    getModels = () => {
        return {
          relatorioA3Digital: this.getCicloPDCAModel(),
          ferramentaConsultoriaList: [],
        }
    }

    validateFerramentaConsultoriaList(ferramentaConsultoriaList){
      return  ferramentaConsultoriaList && ferramentaConsultoriaList.every(elm => { return elm.ordemVisita && elm.maisInfo && elm.anexoFerramentaList && elm.anexoFerramentaList.every(anexo => anexo.descricao) });
    }

    getOutrasFerramentasModelView = () => {
        return {
            data: {},
            icon: 'outrasFerramentas.svg',
            label: '',
            screenAtividade: '',
            anexos: {
                data: [],
                title: '',
                defaultIcon: 'outras-ferramentas'
            }
        }
    };

    updateViewData = (atendimento) => {
        let index = this.state.turma.atendimentoTurmaList.findIndex(obj => { return obj.atendimento.id === atendimento.idAtendimento });
        this.setState({disabled:this.state.turma.atendimentoTurmaList[index].atendimento.relatorioFinal != null   || this.state.turma.status === 3})

        this.viewData.encontros.data = atendimento.encontroList;
        const { relatorioA3Digital, ferramentaConsultoriaList } = atendimento; 
        let turma = this.props.turma

        this.viewData.atividades.relatorioA3Digital.data = JSON.parse(JSON.stringify(relatorioA3Digital || this.getCicloPDCAModel()));
        this.viewData.atividades.relatorioA3Digital.anexos.data = getByKeyOrEmpty(relatorioA3Digital, 'anexoItemA3List');

        if(!relatorioA3Digital){
            if(turma.etapaPraticaC.atendimentoList.find(atd => {return atd.idAtendimento === atendimento.idAtendimento}).relatorioA3Digital){
                this.viewData.atividades.relatorioA3Digital.data = JSON.parse(JSON.stringify(turma.etapaPraticaC.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).relatorioA3Digital));
                this.viewData.atividades.relatorioA3Digital.anexos.data = getByKeyOrEmpty(turma.etapaPraticaC.atendimentoList.find(atd => { return atd.idAtendimento === atendimento.idAtendimento }).relatorioA3Digital, 'anexoItemA3List');
                this.viewData.atividades.relatorioA3Digital.data.status = 2;
            }
        }

        this.viewData.ferramentaConsultoriaList = ferramentaConsultoriaList.map(tool => {
            let currTool = this.getOutrasFerramentasModelView();
            currTool.data = JSON.parse(JSON.stringify(tool || {}));
            currTool.anexos.data = getByKeyOrEmpty(tool, 'anexoFerramentaList');
            currTool.anexos.title = tool.nome;
            currTool.label = tool.nome;
            currTool.screenAtividade = tool.nome;

            return currTool;
        });

    };

    validateAtendimentoConsultoria = async (atendimentoConsultoria) => {  
        let encontroList = atendimentoConsultoria.encontroList;
        let relatorioA3Digital = atendimentoConsultoria.relatorioA3Digital;
        let ferramentaConsultoriaList = atendimentoConsultoria.ferramentaConsultoriaList;
        if (!atendimentoConsultoria.cancelado) {
            return await encontroList && encontroList.length > 0 && encontroList.every(encontro => { return this.validaEncontro(encontro) }) 
                && ferramentaConsultoriaList && ferramentaConsultoriaList.every(ferramenta => ferramenta.status === this.STATUS_FINALIZADO)
                && relatorioA3Digital && relatorioA3Digital.status === this.STATUS_FINALIZADO
            }
        return true;        
    };

    validaEncontro = (encontro) => {
        return encontro && encontro.anexoEncontroList && encontro.anexoEncontroList.length > 0 && !encontro.anexoEncontroList.filter(anx => { return !anx.isDocumento }).some(anx => { return anx.descricao === '' }) &&
        encontro.data && encontro.isVirtual != null && encontro.turno != null  && encontro.idMentor != null && encontro.horasApropriadas > 0;
    };

    handleFinishMentoriaAtendimento = async () => {
        let atendimentoConsultoria = this.state.atendimentoConsultoria;
        let turma = this.props.turma;
        let validado = await this.validateAtendimentoConsultoria(atendimentoConsultoria);

        const errorFinish = (error) => {
            this.props.closeLoading();
            this.props.showToast('Error ao tentar finalizar etapa. Favor, entrar em contato com o suporte.', 'error');
            throw new Error(error);
        }

        if (validado) {
            this.props.showLoading();
            atendimentoConsultoria.finalizado = true;
            let indexAtendimento = turma.etapaConsultoriaC.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
            turma.etapaConsultoriaC.atendimentoList[indexAtendimento] = atendimentoConsultoria; 
            let etapa = turma.etapaConsultoriaC;
            let i, count = 0;
            for (i = 0; i < etapa.atendimentoList.length; i++) {           
                if (!etapa.atendimentoList[i].finalizado && !etapa.atendimentoList[i].cancelado) break;
                count++;
            }
            if (count === etapa.atendimentoList.length) { //save + finalizar etapa
                etapa.finalizada = true;
                turma.etapaAtual += 1;
                try {
                    this.props.showLoading();
                    await this.props.saveTurma(turma);
                    this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    this.props.goBack();
                } catch(error) {
                    this.props.showToast('Error ao tentar finalizar a etapa. Favor, entrar em contato com o suporte.', 'error');
                }
            } else { //save
                etapa = this.state.etapa;
                return await API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                    return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                        turma = res.data;
                        etapa = turma.etapaConsultoriaC;
                        let atendimentoList = mapAtendimentoNome(etapa, turma);
                        let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
                        let atd = etapa.atendimentoList[index];
                        await this.updateViewData(atd);
                        this.props.closeLoading();
                        this.setState({ turma, etapa, atendimentoList, atendimentoConsultoria: atd }, this.goBackAtendimentos());
                        this.props.showToast('Etapa finalizada com sucesso!', 'success');
                    }).catch(error => { errorFinish(error); });
                }).catch(error => { errorFinish(error); });
            }
        } else {
            this.props.showToast('Há informações/atividade(s) pendente(s) nos encontros dessa etapa.', 'error');
            return;
        }
    };

    validateFerramentas = (atividade) => {
        return atividade && atividade.anexoFerramentaList && atividade.anexoFerramentaList.length > 0 && atividade.anexoFerramentaList.every(elm => { return elm.descricao });
    };

    validaAtividade = async (viewData, atendimentoConsultoria, currScreenAtividade, nomeAtividade, nomeAnexo, funcaoValidacaoAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        atividade.ordemVisita = atendimentoConsultoria[nomeAtividade] ? atendimentoConsultoria[nomeAtividade].ordemVisita : viewData.selected.visita.ordem; 
        
         
        if (nomeAnexo) atividade[nomeAnexo] = viewData.selected.atividade.anexos.data;
        let validate = funcaoValidacaoAtividade(atividade);
        if(this.state.etapa.finalizada){
            if (!validate) {
                this.props.showToast('Há informações pendentes nessa atividade.', 'error');
                throw new Error();
            } else {
                atividade.status = status;
            }
        }
        else{
            if (!validate) {
                atividade.status = 2;
            } else {
                atividade.status = status;
            }
        }
        atendimentoConsultoria[nomeAtividade] = atividade;
    };

    validateCicloPDCA(cicloPDCA) {
        return cicloPDCA.temaA3 && cicloPDCA.temaA3.contramedidasA3Digital 
        && cicloPDCA.temaA3.acompanhamentoA3Digital && cicloPDCA.temaA3.conclusaoA3Digital
        && cicloPDCA.temaA3.nome &&
        cicloPDCA.temaA3.contramedidasA3Digital.anexoContramedidasA3List &&
        cicloPDCA.temaA3.contramedidasA3Digital.anexoContramedidasA3List.length > 0 &&
        cicloPDCA.temaA3.contramedidasA3Digital.anexoContramedidasA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.contramedidasA3Digital.planoDeAcaoList &&
        cicloPDCA.temaA3.contramedidasA3Digital.planoDeAcaoList.length > 0 &&
        cicloPDCA.temaA3.contramedidasA3Digital.planoDeAcaoList.every(elm => { return elm.causaSuspeita && elm.itemAcao && elm.prazo && elm.responsavelAcao && elm.achados }) &&
       
        cicloPDCA.temaA3.acompanhamentoA3Digital.anexoAcompanhamentoA3List &&
        cicloPDCA.temaA3.acompanhamentoA3Digital.anexoAcompanhamentoA3List.length > 0 &&
        cicloPDCA.temaA3.acompanhamentoA3Digital.anexoAcompanhamentoA3List.every(elm => { return elm.descricao}) &&
        cicloPDCA.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList &&
        cicloPDCA.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList.length > 0 &&
        cicloPDCA.temaA3.acompanhamentoA3Digital.medidasAcompanhamentoList.every(elm => { return elm.item && elm.responsavel && elm.prazo && elm.status }) &&
        
        cicloPDCA.temaA3.conclusaoA3Digital.anexoMelhoriaA3List &&
        cicloPDCA.temaA3.conclusaoA3Digital.anexoMelhoriaA3List.length > 0 &&
        cicloPDCA.temaA3.conclusaoA3Digital.anexoMelhoriaA3List.filter(anx => anx.antes).every(elm => { return elm.descricao}) &&
        cicloPDCA.temaA3.conclusaoA3Digital.anexoMelhoriaA3List.filter(anx => !anx.antes).every(elm => { return elm.descricao}) &&

        cicloPDCA.temaA3.analise &&
        cicloPDCA.temaA3.analise.anexoItemA3List &&
        cicloPDCA.temaA3.analise.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.analise.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.analise.descricao &&
        cicloPDCA.temaA3.condicoesAtuais &&
        cicloPDCA.temaA3.condicoesAtuais.anexoItemA3List &&
        cicloPDCA.temaA3.condicoesAtuais.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.condicoesAtuais.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.condicoesAtuais.descricao &&
        cicloPDCA.temaA3.historico &&
        cicloPDCA.temaA3.historico.anexoItemA3List &&
        cicloPDCA.temaA3.historico.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.historico.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.historico.descricao &&
        cicloPDCA.temaA3.metas &&
        cicloPDCA.temaA3.metas.anexoItemA3List &&
        cicloPDCA.temaA3.metas.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.metas.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.metas.descricao &&
        cicloPDCA.temaA3.verificacao &&
        cicloPDCA.temaA3.verificacao.anexoItemA3List &&
        cicloPDCA.temaA3.verificacao.anexoItemA3List.length > 0 &&
        cicloPDCA.temaA3.verificacao.anexoItemA3List.every(elm => { return elm.descricao }) &&
        cicloPDCA.temaA3.verificacao.descricao;


    };

    validaOutrasFerramentas = (viewData, atendimentoConsultoria, currScreenAtividade, status) => {
        let atividade = viewData.selected.atividade.data;
        let outrasFerramentasView = viewData.ferramentaConsultoriaList;
        const index = outrasFerramentasView.findIndex(item => {
            return item.label.toUpperCase() === currScreenAtividade.toUpperCase();
        })
        atividade.ordemVisita = Object.keys(atividade).length > 0 ? atividade.ordemVisita : viewData.selected.visita.ordem;
        atividade.anexoFerramentaList = viewData.selected.atividade.anexos.data;
        let validate = this.validateFerramentas(atividade);
        if (!validate) {
            this.props.showToast('É necessário anexar pelo menos uma evidência antes de salvar.', 'error');
            return new Error('É necessário anexar pelo menos uma evidência antes de salvar.');
        } else atividade.status = status;

        outrasFerramentasView[index].data = atividade;
        outrasFerramentasView[index].anexos.data = atividade.anexoFerramentaList;
        atendimentoConsultoria.ferramentaConsultoriaList = outrasFerramentasView.map((item, index) => { 
            let ferramenta = item.data;
            ferramenta.nome = item.label
            ferramenta.turno = index;
            return ferramenta
        });
       
    };
    
    saveEtapa = async (viewData, rmVisita) => {
        let etapa = this.state.etapa;
        let turma = this.props.turma;
        let atendimentoConsultoria = this.state.atendimentoConsultoria;
        let encontrosData = viewData.encontros.data;
        let selectedVisita = viewData.selected.visita;
        let currScreenAtividade;
        
        let hasError = false;

        if (!rmVisita) {
            if (viewData.selected.atividade) currScreenAtividade = viewData.selected.atividade.screenAtividade;

            switch(currScreenAtividade) {
                    // TODO: Adicionar a validação do Relatório A3 aqui
                    case 'relatorioA3Digital':
                        await this.validaAtividade(viewData, atendimentoConsultoria, currScreenAtividade, 'relatorioA3Digital', null, this.validateCicloPDCA, this.STATUS_FINALIZADO);
                        break;
                    case undefined:
                        break;
                    default:
                        const validation = this.validaOutrasFerramentas(viewData, atendimentoConsultoria, currScreenAtividade, this.STATUS_FINALIZADO);
                        if (validation) hasError = true;
                        break;
            }
            
            if (selectedVisita) {
                selectedVisita.anexoEncontroList = [];
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.relatorios != null ? selectedVisita.relatorios : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.listaPresenca != null ? selectedVisita.listaPresenca : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.outrosDocumentos != null ? selectedVisita.outrosDocumentos : []);
                selectedVisita.anexoEncontroList = selectedVisita.anexoEncontroList.concat(selectedVisita.imagens != null ? selectedVisita.imagens : []);
                if (selectedVisita.id && !currScreenAtividade) {
                    if ((this.state.etapa.finalizada && !this.validaEncontro(selectedVisita))) {
                        this.props.showToast('Há informações pendentes nesse encontro.', 'error');
                        throw new Error();
                    }
                }
                let index = encontrosData.findIndex(aux => { return selectedVisita.ordem === aux.ordem });
                if (index > -1) atendimentoConsultoria.encontroList[index] = selectedVisita;
                else atendimentoConsultoria.encontroList.push(selectedVisita);
            }
            atendimentoConsultoria.horasApropriadas = atendimentoConsultoria.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        else {
            let ferramentaConsultoriaList = viewData.ferramentaConsultoriaList;

            if ((!ferramentaConsultoriaList.data) || (ferramentaConsultoriaList.data  && !ferramentaConsultoriaList.data.every(ferramenta => ferramenta.ordemVisita))) atendimentoConsultoria.ferramentaConsultoriaList = [];
            atendimentoConsultoria.encontroList = encontrosData;
            atendimentoConsultoria.horasApropriadas = atendimentoConsultoria.encontroList.reduce((a, b) => + a + + b.horasApropriadas, 0);
        }
        let indexAtendimento = turma.etapaConsultoriaC.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
        turma.etapaConsultoriaC.atendimentoList[indexAtendimento] = atendimentoConsultoria; //atualiza atendimentoConsultoria na etapa pratica b da turma
        
        if(atendimentoConsultoria.horasApropriadas > 4){
            this.props.showToast('Não é permitido apropriar mais horas. Você atingiu o limite máximo de horas estabelecidas para esta etapa.', 'error');
            throw new Error();
        }
        else {if (!hasError) {
            return API.post('rest/gestao/TurmaMentoriaDigital/' + turma.id, turma).then(() => {
                return API.get('rest/gestao/TurmaMentoriaDigital/' + turma.id).then(async res => {
                    turma = res.data;
                    etapa = turma.etapaConsultoriaC;
                    let index = etapa.atendimentoList.findIndex(obj => { return obj.id === atendimentoConsultoria.id });
                    let atd = etapa.atendimentoList[index];
                    await this.updateViewData(atd);
                    this.setState({ turma, etapa, atendimentoConsultoria: atd });
                    this.props.showToast('Etapa editada com sucesso!', 'success');
                }).catch(error => {
                    this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                    throw new Error(error);
                });
            }).catch(error => {
                this.props.showToast('Error ao tentar editar etapa. Favor, entrar em contato com o suporte.', 'error');
                throw new Error(error);
            });
        }}

       
    };

    showTopButtons = (value) => {
        this.setState({ showTopButtons: value });
    };

    switchAnexos = (anexosList) => {
        this.setState({ switchAnexos: anexosList });
    };

    renderAtividade = (selectedAtividade, reloadAtividade) => {
        let showEmptyEvidencias = false;
        let showTopButtons = this.state.showTopButtons;
        let showAnexos = true;
        let render = null;
        let switchAnexos = this.state.switchAnexos && this.state.switchAnexos.length > 0 ? this.state.switchAnexos : selectedAtividade.anexos.data;
        switch (selectedAtividade.screenAtividade) {
            
            case 'relatorioA3Digital':
                render = <AtividadeCicloPDCADigital 
                    atividade={selectedAtividade.data}
                    atendimento={this.state.atendimentoConsultoria} 
                    turma={this.props.turma}                            
                    showLoading={this.props.showLoading} 
                    closeLoading={this.props.closeLoading} 
                    showToast={this.props.showToast} 
                    turma={this.state.turma}
                    fase={ this.props.fase }
                    etapaAtividade={'consultoriaC'}
                    etapa={'C'}
                    disabled={this.state.disabled}

                />;
                showTopButtons = false;
                showAnexos = false;
                break;          
            case 'mfv':
                render = <AtividadeMFV atividade={selectedAtividade.data} />;
                showTopButtons = true;
                showAnexos = true;
                break;
            default:
                render = <AtividadeMaisInfo  
                atividade={selectedAtividade.data}
                disabled={this.state.disabled} />
        }
        return (
            <AtividadeDefaultDigital
                turma={ this.state.turma }
                fase={ this.props.fase }
                showLoading={ this.props.showLoading }
                closeLoading={ this.props.closeLoading }
                showToast={ this.props.showToast }
                reloadAtividade={ reloadAtividade }
                title={ selectedAtividade.anexos.title }
                anexos={ switchAnexos }
                addInfoAnexo={'Descreva a implementação da ferramenta, explique o antes e depois e a necessidade de implantação.'}
                defaultIcon={ selectedAtividade.anexos.defaultIcon }
                saveAtividades={ this.saveAtividades }
                showEmptyEvidencias={ showEmptyEvidencias }
                invertAnexos={true}
                disabled={this.state.disabled}
                showTopButtons={ showTopButtons && !this.state.disabled}

                showAnexos={ showAnexos }
                renderChildren={ render }
                clientName={ this.state.clientName }
                viewData={ this.state.viewData } 
            />
        );
    };

    renderVisita = (atendimento) => {
        this.updateViewData(atendimento);
        this.setState({ renderTo: this.renderOptions.VISITAS, viewData: this.viewData, atendimentoConsultoria: atendimento, clientName: atendimento.clientName });
    };

    goBackAtendimentos = () => {
        this.setState({ renderTo: this.renderOptions.ATENDIMENTO, atendimentoConsultoria: undefined, switchAnexos: undefined, showTopButtons: true });
    };

    render() {
        return (<>
            { this.state.renderTo === this.renderOptions.ATENDIMENTO &&
                <AtendimentosCardDigital
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    title= { 'Consultoria' }
                    goBack={ this.props.goBack }
                    atendimentos={ this.state.atendimentoList }
                    renderVisita={ this.renderVisita } 
                />
            }
            { this.state.renderTo === this.renderOptions.VISITAS &&
                <EncontrosDefault
                    turma={ this.state.turma }
                    fase={ this.props.fase }
                    goBack={ this.props.goBack }
                    goBackAtendimentos={ this.goBackAtendimentos }
                    closeLoading={ this.props.closeLoading }
                    etapa = {this.state.etapa}
                    showLoading={ this.props.showLoading }
                    showToast={ this.props.showToast }
                    viewData={ this.state.viewData }
                    disabled={this.state.disabled}
                    models={ this.getModels }
                    atendimento={ this.state.atendimentoConsultoria }
                    etapa = {this.state.etapa}
                    saveEtapa={ this.saveEtapa }
                    handleFinishMentoria={ this.handleFinishMentoriaAtendimento }
                    renderAtividade={ this.renderAtividade }
                    clientName={ this.state.clientName }
                    dontShowFinalizarMentoria={ this.state.atendimentoConsultoria.finalizado }
                    mentoresDados={this.props.mentoresDados}
                />
            }
        </>)
    };
};