import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';

export default class AtividadeEstadoPresente extends Component {

    constructor(props) {
        super(props);
        this.state = { estadoPresente: props.atividade, errors: { atuacaoEmpresa: '', familiaProdutoPrincipal: '', principalCliente: '' }};
    };

    changeInput(e) {
        let estado = this.state.estadoPresente;
        let errors = this.state.errors;
        if (e && e.target && e.target.id) {
            estado[e.target.id] = e.target.value.toUpperCase();
            if (e.target.required) {
                if (e.target.value) {
                    errors[e.target.id] = '';
                } else {
                    errors[e.target.id] = 'Campo obrigatório.';
                }
            } else if (e.target.id === 'principalCliente' && estado.principaisClientesList.length === 0 && !e.target.value) {
                errors[e.target.id] = 'Adicione pelo menos um cliente.';
            } else {
                errors[e.target.id] = '';
            }
            this.setState({ estadoPresente: estado, errors: errors });
        } 
    };

    addCliente = () => {
        let estado = this.state.estadoPresente;
        let errors = this.state.errors;
        if (estado.principalCliente && estado.principalCliente.length > 0) {
            let index = estado.principaisClientesList.findIndex(cliente => { return cliente.nome.toUpperCase() === estado.principalCliente.toUpperCase()});
            if (index < 0) {
                estado.principaisClientesList.push({ nome: estado.principalCliente });
                estado.principalCliente = '';
            } else {
                errors.principalCliente = 'Cliente já adicionado.'
            }
            this.setState({ estadoPresente: estado, errors: errors });
        }
    };

    removeCliente = (cliente) => {
        let estado = this.state.estadoPresente;
        let errors = this.state.errors;
        let index = estado.principaisClientesList.findIndex(cli => { return cli.id === cliente.id && cli.nome.toUpperCase() === cliente.nome.toUpperCase() });
        if (index >= 0) {
            estado.principaisClientesList.splice(index, 1);
            if (estado.principaisClientesList.length === 0) errors['principalCliente'] = 'Adicione pelo menos um cliente.';
            this.setState({ estadoPresente: estado, errors: errors });
        }
    };

    convertClientes = () => {
        return this.state.estadoPresente.principaisClientesList.map((cliente, index) => (
            <Chip key={index} label={<label style={{ display: 'flex', alignItems: 'center' }}><p>{cliente.nome}</p></label>}
                icon={this.props.disabled ? <></> : <Icon onClick={() => this.removeCliente(cliente)} className={'close'}>clear</Icon>} />
        ));
    };

    render() {
        return (
            <Grid container className={'atividade-estado-presente'} spacing={1}>
                <Grid item xs={12} className={'grid-label'}>
                    <label>Descrição do estado atual da empresa</label>
                    <div className={'div-border'} />
                </Grid>
                <Grid container direction={'column'} justify={'center'} alignItems={'center'} alignContent={'center'}>
                    <Grid container justify={'center'} spacing={1}>
                        <Grid item xs={12} md={4} xl={3}>
                            <FormField label={'Atuação da empresa'} id={'atuacaoEmpresa'} type={'text'} maxLength={'250'} changeValue={(e) => { this.changeInput(e) }} required={true}
                                inputValue={this.state.estadoPresente.atuacaoEmpresa ? this.state.estadoPresente.atuacaoEmpresa : ''} error={this.state.errors.atuacaoEmpresa} disabled={this.props.disabled}/>
                        </Grid>
                        <Grid item xs={12} md={4} xl={3}>
                            <FormField label={'Família do produto principal'} id={'familiaProdutoPrincipal'} type={'text'} maxLength={'250'} changeValue={(e) => { this.changeInput(e) }} required={true}
                            inputValue={this.state.estadoPresente.familiaProdutoPrincipal ? this.state.estadoPresente.familiaProdutoPrincipal : ''} error={this.state.errors.familiaProdutoPrincipal} disabled={this.props.disabled}/>
                        </Grid>
                        <Grid item xs={12}></Grid>
                    </Grid>
                    <Grid container justify={'center'} style={{ display: 'flex', alignItems: 'flex-end' }} spacing={1}>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} md={5} xl={3} className={'formfield-button ' + (this.state.errors.principalCliente.length > 0 ? 'error' : '')}>
                            <div className={'div-formfield-button'}>
                                <FormField type={'text'} label={'Principais clientes'} id={'principalCliente'} pressEnter={this.addCliente} placeholder={''}
                                    inputValue={this.state.estadoPresente.principalCliente ? this.state.estadoPresente.principalCliente : ''} 
                                    changeValue={(e) => { this.changeInput(e) }} disabled={this.props.disabled}/>
                                <GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'plus.svg'} click={this.addCliente} disabled={this.props.disabled}/>
                            </div>
                            { this.state.errors.principalCliente.length > 0 && 
                                <span className={'span-error'}>{this.state.errors.principalCliente}<br /><br /></span> }
                            { this.convertClientes() }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
};