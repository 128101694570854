import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import EmptyList from '../../components/emptyList/emptyList';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import ExpandImages from '../../components/expand/expand';
import { BASE_URL } from './../../comm/API';

export default class AnexosDefault extends Component {

    state = {};
    render() {
        return (this.props.anexos.length === 0 ?
            <Grid item xs={12}>
                { this.props.showEmptyEvidencias ?
                    <Grid container>
                        <Grid item xs={12}>
                        <div className={'div-border ' + (this.props.darkBorder ? 'dark' : 'top') + (this.props.darkBorder === 'none' ? ' none': '')} />
                        </Grid>
                        <Grid item xs={12}>
                            <EmptyList icon={this.props.icon} type={'noItens'} msg={' '} subMsg={this.props.addInfoAnexo ? this.props.addInfoAnexo : this.props.msg} sizeIcon={{ width: 'auto' }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={'div-border top'} />
                        </Grid>
                    </Grid> :
                    <Grid container>
                        <Grid item xs={12}>

                            {this.props.addInfoAnexo && (
                                <div className="add-info-digital add-info-negative">
                                    <label>{this.props.addInfoAnexo}</label>
                                </div>
                            )}
                        </Grid>
                    </Grid> }
            </Grid> :
            <Grid item xs={12}>
                <Grid container className={'atividade-dafault-form'} justify={'center'}>
                    <Grid item xs={12}>
                        <div className={'div-border bottom ' + (this.props.darkBorder ? 'dark' : 'top') + (this.props.darkBorder === 'none' ? ' none': '')} />
                    </Grid>
                    {this.props.addInfoAnexo && (
                        <div className="add-info-digital add-info-positive">
                            <label>{this.props.addInfoAnexo}</label>
                        </div>
                    )}
                    <Grid container>
                        <Grid item xs={this.props.turma && this.props.title ? 11 : 12}>
                            <Grid container style={{ justifyContent: 'center' }}>
                                { this.props.anexos.map((element, index) => {
                                    return (
                                        <Grid item key={index} style={{ padding: '30px', paddingTop: '10px'}} >
                                            <Grid container>
                                                <img alt={''} src={BASE_URL + element.url} width={300} height={170} style={{objectFit: 'contain', backgroundColor: '#f2f1f6'}} />
                                                <GenericButton color={'transparent'} subClass={'icon-button flex-end'}
                                                    icon={process.env.PUBLIC_URL + 'lixeira-brasilmais.svg'}
                                                    click={() => { this.props.removerAnexo(element) }} disabled={this.props.disableForm} />
                                            </Grid>
                                            <FormField label={'Descritivo da imagem'} type={'textarea'} maxLength={'250'} changeValue={(e) => { element.descricao = e.target.value; this.setState(this.state); }} inputValue={element.descricao} 
                                                error={element.descricao ? '' : 'Adicione uma descrição.'} disabled={this.props.disableForm} />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>

                        {this.props.turma && this.props.title && (
                            <Grid item xs={1}>
                                <ExpandImages
                                    anexos={this.props.anexos}
                                    fase={this.props.fase}
                                    title={this.props.title}
                                    turma={this.props.turma}
                                />
                            </Grid>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={'div-border'} />
                    </Grid>
                </Grid>
            </Grid>
        );
    };
};