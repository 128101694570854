import React, { Component } from 'react';
import AnexosDefault from './anexosDefault';
import UploadTopButtons from './uploadTopButtons';
import API from '../../comm/API';
import PageBrasilMais from './pageBrasilMais';
import { Grid } from '@material-ui/core';

export default class AtividadeDefault extends Component {

    uploadFile = (formData) => {
        this.props.showLoading();
        API.post(`rest/upload/anexoTurma/${this.props.turma.id}`, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            const anexo = {
                descricao: '',
                nomeArquivo: res.data.nomeArquivo,
                tamanho: res.data.tamanho,
                tipo: res.data.tipo,
                url: res.data.url,
            };
            this.props.anexos.push(anexo);
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
            this.props.reloadAtividade();
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Ocorreu erro ao anexar arquivo. Favor, entre em contato com o suporte.', 'error');
            console.log(error);
        })
    };

    removerAnexo = (element) => {
        let anexos = this.props.anexos;
        let index = -1;
        index = anexos.findIndex(item => ((element.id && item.id === element.id) || (item.descricao === element.descricao && item.nomeArquivo === element.nomeArquivo)));
        if (index > -1) {
            anexos = anexos.splice(index, 1);
        }
        this.props.reloadAtividade();
    };

    renderUploadTopButton = () => {
        if (this.props.showTopButtons) return ( <UploadTopButtons uploadFile={ this.uploadFile } showToast={ this.props.showToast } /> );
        else return (<Grid item md={6} style={{ margin: '8px 0px', height: '30px' }}></Grid>) 
    };

    renderAnexos = () => {
        const icon = this.props.defaultIcon;
        const nomeAnexo = icon === 'depoimentos_' ? 'depoimentos' : 'evidências';
        return (
            <AnexosDefault
                disableForm={ !this.props.showTopButtons || this.props.disabled}
                showEmptyEvidencias={ this.props.showEmptyEvidencias }
                anexos={ this.props.anexos }
                removerAnexo={(element) => this.removerAnexo(element)}
                icon={ icon }
                msg={`Você ainda não anexou ${nomeAnexo}.`}
                turma={this.props.turma}
                fase={this.props.fase}
                title={this.props.title || ''} />
        );
    };

    sumHorasRealizadasEtapa() {
        return this.props.viewData.visitas.data.reduce((a, b) => + a + + b.horasApropriadas, 0);
    };

    render() {
        return(
            <PageBrasilMais 
                name={this.props.turma.nome}
                fase={this.props.fase}
                title={ this.props.title }
                subTitle={ this.props.clientName ? 
                    <label style={{ display: 'flex', fontSize: '15px', fontWeight: '600', marginTop: '-14px' }}>
                        <img style={{ marginRight: '10px' }} src={process.env.PUBLIC_URL + '/assets/icons/showcase-cinza.svg'} alt={'icon'} />
                        <label style={{ marginTop: '4px' }}>{this.props.clientName}</label>
                        <label style={{ marginTop: '4px', marginLeft: '40px', fontWeight: '400' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.props.viewData.horas}</label>
                    </label> : <label style={{ display: 'flex', fontSize: '15px', marginTop: '-14px', marginBottom: '4px' }}>Horas realizadas: {this.sumHorasRealizadasEtapa()}/{this.props.viewData.horas}</label> }
                renderTopButtons={ this.renderUploadTopButton }>
                    { this.props.renderChildren }
                    { this.props.showAnexos ? this.renderAnexos() : null }
            </PageBrasilMais>
        )
    }

}
    
    