import React, { Component } from 'react';
import Page from '../../components/page/page';
import { Grid } from '@material-ui/core';
import API from '../../comm/API';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PermissionsTable from './permissionsTable';
import ListWithImage from '../../components/listWithImage/listWithImage';
import GenericButton from '../../components/genericButton/genericButton';
import '../perfil/perfilUsuario.scss';

export default class PerfilUsuario extends Component {

    constructor(props) {
        super(props);
        this.state = {profileList: [], telasList: [], usuarios: [], perfilUsuario: [], tab: 0};
        this.getInfos();
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    telaPermitidaModel(item) {
        return ({tela: item});
    };

    getInfos = () => {
        API.get('rest/sistema/TelaTemp').then(res => { //todas as telas
            this.setState({telasList: res.data});
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao realizar GET das telas.', 'error');
        });
        API.get('rest/sistema/PerfilUsuario?unidade.id=' + this.props.usuarioConectado.unidade.id).then(res => { //perfis da unidade do usuário
            API.get('rest/sistema/Usuario/perfil?id=' + res.data[0].id).then(res => {
                this.closeLoading();
                this.setState({usuarios: res.data});
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao realizar GET dos usuários.', 'error');
            });
            this.setState({profileList: res.data, tab: res.data[0].id, perfilUsuario: res.data[0]});
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao realizar GET dos perfis.', 'error');
        });
    };

    changePerfil = (value, newValue) => {
        this.showLoading();
        API.get('rest/sistema/Usuario/perfil?id=' + newValue).then(res => {
            this.closeLoading();
            this.setState({usuarios: res.data});
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao realizar GET dos usuários.', 'error');
        });
        var index = this.state.profileList.findIndex(obj => Number(obj.id) === Number(newValue));
        this.setState({perfilUsuario: this.state.profileList[index], tab: Number(newValue)});
    };

    changeTelasPermitidas = (item) => {
        var perfil = this.state.perfilUsuario;
        var tela = perfil.telaPermitidaTempList.find(obj => Number(obj.tela.id) === Number(item.id));
        if (tela == null) { //adiciona tela
            perfil.telaPermitidaTempList.push(this.telaPermitidaModel(item));
        } else { //remove tela
            var index = perfil.telaPermitidaTempList.findIndex(obj => Number(obj.tela.id) === Number(item.id));
            perfil.telaPermitidaTempList.splice(index, 1);
        }
        this.setState({perfilUsuario: perfil});
    }

    salvarPerfilUsuario = () => {
        this.showLoading();
        var perfil = this.state.perfilUsuario;
        if (perfil.id != null && perfil.unidade != null && perfil.nome != null && perfil.telaPermitidaTempList.length > 0) {
            API.post('rest/sistema/PerfilUsuario/' + perfil.id, perfil).then(res => {
                this.closeLoading();
                this.showToast('Perfil atualizado com sucesso!', 'success');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao tentar atualizar o perfil.\nFavor, entrar em contato com o suporte.', 'error');
            });
        } else if (perfil.telaPermitidaTempList.length <= 0) {
            this.closeLoading();
            this.showToast('O perfil precisa ter pelo menos uma tela permitida.', 'error');
        } else {
            this.closeLoading();
            this.showToast('Atualização de perfil com problemas.\nPor favor, entre em contato com o suporte.', 'error');
        }
    };

    
    render() {     
        return (
            <Page icon={'config.svg'} value={'Gerenciar Colaboradores'} label={'Configuração de Perfil de Usuário'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                {this.state.profileList != null && this.state.profileList.length > 0 ? 
                <Grid container className={'perfil-usuario'} direction='column' justify='center'>
                    <Grid item xs={12} className='tabs'>
                        <AppBar position='static'>
                            <Tabs value={this.state.tab} onChange={this.changePerfil} variant='scrollable' scrollButtons='auto'>
                                {this.state.profileList.map(
                                    (perfil) => (<Tab key={'NavbarPerfilUsuario-' + perfil.id} value={perfil.id} label={perfil.nome.toLowerCase()}></Tab>)
                                )}
                            </Tabs>
                        </AppBar>
                    </Grid>
                    <Grid container justify='center' className={'grid-margin-top'}>
                        <Grid item xs={12} sm={10} md={8} lg={5} xl={5}>
                            <PermissionsTable telaPermitidaList={this.state.perfilUsuario != null ? this.state.perfilUsuario.telaPermitidaTempList : []} 
                                telasList={this.state.telasList} changeTelasPermitidas={this.changeTelasPermitidas} />
                        </Grid>
                        <Grid item xs={12} sm={10} md={8} lg={5} xl={5}>
                            <ListWithImage lista={this.state.usuarios} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='center' className={'grid-margin'}>
                                <GenericButton color={'darkBlue'} label={'Salvar Alterações'} subClass={'basic-button button-form'} click={this.salvarPerfilUsuario} padding={1} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                : <div></div>}
            </Page>
        )
    };
}


