import React, { Component } from 'react';
import { Grid, Switch } from '@material-ui/core';

export default class AtividadeTecnologiasDigitais extends Component {

    labelsFields = [
        {id: 'digitalizarLinhaProducao', label: 'A empresa tem intenção de digitalizar sua(s) linha(s) de produção?'},
        {id: 'maturidadeTecnologica', label: 'A empresa possui maturidade tecnológica para digitalização?'},
        {id: 'doInvestimentoDigital', label: 'A empresa tem intenção de realizar investimentos em digitalização?'},
        {id: 'processoManufaturaDiscreto', label: 'A empresa possui processos de manufatura discretos?'},
        {id: 'boaWifi', label: 'A empresa possui boa rede wifi na linha de produção?'},
        {id: 'doMentoriaDigital', label: 'A empresa tem intenção de participar na FASE 0 - Mentoria Digital?'}
    ]

    constructor(props) {
        super(props);
        this.state = { tecnologiasDigitais: this.props.atividade };
    };

    changeSwitchField = (e) => {
        let tenologias = this.state.tecnologiasDigitais;
        tenologias[e.target.id] = !e.target.checked;
        this.setState({ tecnologiasDigitais: tenologias });
    };

    renderSwitchButton = (obj, index) => {
        return (
            <Grid key={index} item xs={12} md={4} xl={4}>
                <label> {obj.label} </label>
                <Grid container spacing={3} style={{ marginTop: '2%' }}>
                    <Grid item> <label> Sim </label> </Grid>
                    <Grid item style={{ padding: 1}}>
                        <Switch disabled={this.props.disabled} checked={!this.state.tecnologiasDigitais[obj.id]} onChange={this.changeSwitchField} id={obj.id} color="default"/>
                    </Grid>
                    <Grid item> <label> Não </label> </Grid>
                </Grid>
            </Grid>
        )
    };

    render() {
        return (
            <Grid container style={{ marginTop: '5%' }} className={'atividade-tecnologias'}>
                <Grid item className={'label-box'}> <label> Tecnologias habilitadoras para a Mentoria Digital </label> </Grid>
                <Grid container justify={'center'} direction={'row'}>
                    <Grid item className={'tecnologias-box'}>
                        <Grid container justify={'center'} direction={'row'} spacing={5} style={{ marginBottom: '3%', marginTop: '3%' }}>
                            { this.labelsFields.map((obj, index) => { return this.renderSwitchButton(obj, index) }) }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };
}