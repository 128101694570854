import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API , { BASE_URL } from '../../../comm/API';
import GenericButton from '../../../components/genericButton/genericButton';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import GraphCard from '../../../components/graphCard/graphCard';
import GraficoMapaBrasil from './graficoMapaBrasil';
import GraficoMetas from './graficoMetas.js';
import GraficoPorteSetor from './graficoPorteSetor.js';
import GraficoAtendimentosPorEstado from './graficoAtendimentosPorEstado.js';
import GraficoCadastradoConcluido from './graficoCadastradoConcluido';
import GraficoAtendimentoProdutividade from './graficoAtendimentoProdutividade';
import EmptyList from '../../../components/emptyList/emptyList';

export default class DashboardBrasilMaisTemp extends Component {

	

	constructor(props) {
		super(props);
		this.state = {
			valoresCards: [],
			valoresGraficoCards: [],
		}
		API.get('rest/sistema/DashboardBrasilMais/cards').then(json => {
			this.setState({ valoresCards: json.data })
		}).catch(error => {
			console.log(error);
			this.showToast('Erro ao buscar cards do dashboard. Favor, entrar em contato com o suporte.', 'error');
		});

		API.get('rest/sistema/DashboardBrasilMais/cardsHorasReceita').then(json => {
			this.setState({ valoresGraficoCards: json.data })
		}).catch(error => {
			console.log(error);
		});
	}

	downloadRelatorio = () => {
        this.props.showLoading();


        try {
            let path = `rest/gestao/RelatorioTurmaLean/getRelatorioBrasilMais`;
            window.open(BASE_URL + path);
            this.props.closeLoading();
            
        } catch (error) {
            console.log(error);
            this.props.closeLoading();
            this.props.showToast('Erro ao gerar relatório. Favor, entrar em contato com o suporte.', 'error');
        }

    };

	render() {
		if (this.props.perfil === 'DN' || this.props.perfil === 'DR')
			return (
				<Grid container spacing={3} className={'dashboard-brasil-mais-geral'}>
					<Grid item xs={12} md={4} xl={3}>
						<Grid container className={'relatorio-box'}>
								<Grid item className={'relatorio-label'}>
									<label>Baixe aqui o relatório geral do Brasil Mais</label>
									<label>contendo dados atualizados sobre</label>
									<label className={'bold'}>clientes, atendimentos, indicadores</label>
									<label className={'bold'}>e consultores:</label>
								</Grid>
								<Grid item className={'relatorio-title'}>
									<label>Relatório Geral </label>
									<label>BRASIL MAIS</label>
									<GenericButton color={'darkBlue'} label={'Baixar relatório'} subClass={'basic-button'} click={() => { this.downloadRelatorio(); }} />
								</Grid>
							</Grid>	
					</Grid>
                    <Grid item xs className={'dashboard-empty'}>
                        <EmptyList icon={'em-construcao'} type={'noItens'} msg={'Vem aí o novo Dashboard Brasil Mais'} subMsg={'Estamos realizando melhorias para atender demandas de usuários e incluir o Mentoria Digital. Em breve um novo Dashboard/Painel será lançado.'} sizeIcon={{ width: 'auto' }}/>
                    </Grid>
                </Grid>
			);
		else if (this.props.perfil === 'UO')
			return (
                    <EmptyList icon={'em-construcao'} type={'noItens'} msg={'Vem aí o novo Dashboard Brasil Mais'} subMsg={'Estamos realizando melhorias para atender demandas de usuários e incluir o Mentoria Digital. Em breve um novo Dashboard/Painel será lançado.'} sizeIcon={{ width: 'auto' }}/>
			);
	}
}