import React, { Component } from "react";
import FormField from "../../components/formField/formField";
import { Grid } from "@material-ui/core";
import SimpleModal from "./../../components/modal/modal";
import GenericButton from "../../components/genericButton/genericButton";
import Loading from "../../components/loading/loading";
import "./modal.scss";

export class CreateMeta extends Component {
  constructor(props) {
    super(props);
    this.state = { meta: this.props.meta };
  }

  showLoading = () => {};
  closeLoading = () => {};
  getFunctions = (showToast, showLoading, closeLoading) => {
    if (showLoading != null) this.showLoading = showLoading;
    if (closeLoading != null) this.closeLoading = closeLoading;
  };

  getInput = (e) => {
    if (e != null && e.target != null) {
      let meta = this.state.meta;
      let total = 0.0;
      if (Number.isNaN(e.target.value)) {
        meta[e.target.id] = 0;
      } else {
        if (Number(e.target.value) <= 9999999999) {
          if (
            this.props.meta.tipoMeta === "Receita" ||
            this.props.meta.tipoMeta === "Despesa"
          ) {
            meta[e.target.id] = Number(Number(e.target.value).toFixed(2));
          } else {
            meta[e.target.id] = parseInt(e.target.value);
          }
        }
      }
      if (meta.jan != null) total = total + meta.jan;
      if (meta.fev != null) total = total + meta.fev;
      if (meta.mar != null) total = total + meta.mar;
      if (meta.abr != null) total = total + meta.abr;
      if (meta.mai != null) total = total + meta.mai;
      if (meta.jun != null) total = total + meta.jun;
      if (meta.jul != null) total = total + meta.jul;
      if (meta.ago != null) total = total + meta.ago;
      if (meta.set != null) total = total + meta.set;
      if (meta.out != null) total = total + meta.out;
      if (meta.nov != null) total = total + meta.nov;
      if (meta.dez != null) total = total + meta.dez;
      meta.total = total;
      this.setState({ meta: meta });
    }
  };

  salvarMeta = () => {
    this.props.salvarMeta(this.state.meta);
  };

  render() {
    return (
      <div>
        <Loading getFunctions={this.getFunctions} />
        <SimpleModal
          isOpen={this.props.open}
          handleClose={this.props.handleModal}
          disableClick={true}
          type={"info"}
          width={"700px"}
          className={"modal-metas"}
        >
          <h2>METAS - {this.props.titulo}</h2>
          <Grid container>
            <Grid item xs={12} style={{ paddingBottom: "30px" }}>
              <Grid
                container
                alignItems={"center"}
                alignContent={"center"}
                direction={"column"}
                justify={"center"}
              >
                <Grid item xs={12}>
                  <label id={"tipoMeta"}>{this.state.meta.tipoMeta}</label>
                </Grid>
                <Grid item xs={12}>
                  <label id={"info"}>
                    Preencha os campos com os valores das metas
                  </label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} style={{ paddingBottom: "30px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Janeiro"}
                    inputValue={this.state.meta.jan}
                    changeValue={this.getInput}
                    id={"jan"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Fevereiro"}
                    inputValue={this.state.meta.fev}
                    changeValue={this.getInput}
                    id={"fev"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Março"}
                    inputValue={this.state.meta.mar}
                    changeValue={this.getInput}
                    id={"mar"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Abril"}
                    inputValue={this.state.meta.abr}
                    changeValue={this.getInput}
                    id={"abr"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Maio"}
                    inputValue={this.state.meta.mai}
                    changeValue={this.getInput}
                    id={"mai"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Junho"}
                    inputValue={this.state.meta.jun}
                    changeValue={this.getInput}
                    id={"jun"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Julho"}
                    inputValue={this.state.meta.jul}
                    changeValue={this.getInput}
                    id={"jul"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Agosto"}
                    inputValue={this.state.meta.ago}
                    changeValue={this.getInput}
                    id={"ago"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Setembro"}
                    inputValue={this.state.meta.set}
                    changeValue={this.getInput}
                    id={"set"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Outubro"}
                    inputValue={this.state.meta.out}
                    changeValue={this.getInput}
                    id={"out"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Novembro"}
                    inputValue={this.state.meta.nov}
                    changeValue={this.getInput}
                    id={"nov"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type={"number"}
                    label={"Dezembro"}
                    inputValue={this.state.meta.dez}
                    changeValue={this.getInput}
                    id={"dez"}
                    step={".01"}
                    min={"0"}
                    max={"9999999999"}
                    className={"mes-style"}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={"info-total"}>
              <label id={"infoTotal"}>Total {this.state.meta.ano}</label>
              <label id={"infoValor"}>
                {this.state.meta.tipoMeta === "Despesa" ||
                this.state.meta.tipoMeta === "Receita"
                  ? "R$ " +
                    Number(this.state.meta.total.toFixed(2)).toLocaleString(
                      "pt-BR"
                    )
                  : this.state.meta.total}
              </label>
            </Grid>
          </Grid>
          <div className={"div-botoes"}>
            <GenericButton
              color={"darkGrey-outline"}
              label={"Cancelar"}
              subClass={"basic-button"}
              click={this.props.handleModal}
            />
            <GenericButton
              color={"darkBlue"}
              label={"Salvar"}
              subClass={"basic-button"}
              click={this.salvarMeta}
            />
          </div>
        </SimpleModal>
      </div>
    );
  }
}
