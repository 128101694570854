import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import drilldown from "highcharts/modules/drilldown";
import HighchartsReact from 'highcharts-react-official';

import './graphCard.scss';

highchartsMore(Highcharts);
drilldown(Highcharts)


export default class GraphCard extends Component {
  
    create = () => {
        return {
            chart: {
                type: 'column',
                backgroundColor: null,
                style: {
                  fontFamily: 'Raleway',
                },
                height: 150,
              },
              title: {
                text: ' '
              },
              accessibility: {
                announceNewData: {
                  enabled: false
                }
              },
              credits: {
                  enabled: false
              },
              tooltip: {
                  enabled: false
              },
              xAxis: {
                type: 'category',
                labels: {
                    enabled: false
                }
              },
              yAxis: {
                title: {
                  text: ' '
                },
                labels: {
                    enabled: false
                },
                gridLineWidth: 0,
                minorGridLineWidth: 0,
              },
              legend: {
                enabled: false
              },
              plotOptions: {
                series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true, 
                        format: this.props.graficoReceita ? '{point.y:,.2f}' : '{point.y:,.0f}',
                        style: {
                            color: '#707070',
                            fontWeight: 'normal',
                            textOutline: '0px contrast',
                        }
                    },
                    states: {
                        hover: {
                            enabled: false 
                        }
                    }
                }
              },
              exporting: false,
              series: [
                {
                  name: "",
                  colorByPoint: false,
                  data: [
                    {
                      name: " ",
                      y: this.props.value1,
                      drilldown: " ",
                      color: '#00B1E8',
                      dataLabels: {
                        enabled: true,
                        y: -25,
                        style: {
                            color: '#707070',
                            fontWeight: 'normal',
                            fontSize: '12px',
                            
                        }
                      }
                    },
                    {
                      name: "",
                      y: this.props.value2,
                      drilldown: "",
                      color: '#B7EE8E',
                      dataLabels: {
                        enabled: true,                    
                        style: {
                          color: '#707070',
                          fontWeight: 'normal',
                          fontSize: '12px'
                        }
                      }
                    }
                  ]
                }
              ]
        };
    };

    render() {
        return (
          <Grid container direction={'column'} justify={'center'} className={'graphCard'}>
              <Grid item className={'font-title'}>{this.props.title}</Grid>
              <Grid item className={'font-subtitle'}>{this.props.subtitle}</Grid>
              <Grid id={'graph-column'} item xs={12} >
                  <HighchartsReact
                      highcharts={Highcharts}
                      options={this.create()}
                  />
          </Grid>
			</Grid>
        )
    }
}