import React, { Component } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import FormField from '../../components/formField/formField';
import InputSelect from '../../components/inputselect/inputselect';
import { withStyles} from "@material-ui/core/styles";
import { Grid, Tooltip } from "@material-ui/core";
import Results from '../../components/results/results';
import './atividadeAnaliseDosResultados.scss';

export default class AtividadeAnaliseDosResultados extends Component {
  calculationReturnProgramItems = [
    {label: 'Base: Excedente de produção', value: 'BASE: EXCEDENTE DE PRODUÇÃO'},
    {label: 'Base: Redução do custo do produto', value: 'BASE: REDUÇÃO DO CUSTO DO PRODUTO'}, 
  ];

  getEmptyIndicatorModel = () => {
    return {
      initial: 0,
      final: 0
    };
  }

  constructor(props) {
    super(props);

    let disabled = this.props.disabled;
    let analiseResultadosDigital = props.atividade;

    analiseResultadosDigital.retornoMensal = analiseResultadosDigital.retornoMensal ? this.transformInitialValueToCurrency(analiseResultadosDigital.retornoMensal) : 0;
    analiseResultadosDigital.calculoRetorno = analiseResultadosDigital.calculoRetorno || '';
    analiseResultadosDigital.retornoPrograma = analiseResultadosDigital.retornoPrograma || 0;
    analiseResultadosDigital.produtividade = analiseResultadosDigital.produtividade || '';
    analiseResultadosDigital.oee = analiseResultadosDigital.oee || '';
    analiseResultadosDigital.disponibilidade = analiseResultadosDigital.disponibilidade || '';
    analiseResultadosDigital.performance = analiseResultadosDigital.performance || '';
    analiseResultadosDigital.qualidade = analiseResultadosDigital.qualidade || '';
    analiseResultadosDigital.analiseRetorno = analiseResultadosDigital.analiseRetorno || '';
    analiseResultadosDigital.resultados = analiseResultadosDigital.resultados || '';

    this.state = {
      analiseResultadosDigital: props.atividade,
      tab: 'Resultados',
      oeeSubitens: {},
      maturidadeSubitens: {},
      produtividade: this.getEmptyIndicatorModel(),
      oee: this.getEmptyIndicatorModel(),
      maturidade: this.getEmptyIndicatorModel(),
      totalConsultoria: 0,
    };
  };

  transformInitialValueToCurrency(initialValue) {
    return `R$ ${initialValue.toFixed(2).replace('.', ',')}`;
  }


  getIndicatorByIdAtendimento = (idAtendimento, etapa, indicator) => {
    const atendimento = this.props.turma[etapa].atendimentoList.find(atendimento => atendimento.idAtendimento === idAtendimento);

    return atendimento[indicator] || {};
  }

  getInitialIndicators = () => {
    return this.getIndicatorByIdAtendimento(this.props.atendimento.idAtendimento, 'etapaPraticaC', 'indicadoresDigital');
  }

  getFinalIndicators = () => {
    return this.getIndicatorByIdAtendimento(this.props.atendimento.idAtendimento, 'etapaPraticaD', 'indicadoresDigital');
  }

  getTotalConsultoria = () => {
    const artefatosInstalacaoDigital = this.getIndicatorByIdAtendimento(this.props.atendimento.idAtendimento, 'etapaPraticaB', 'artefatosInstalacaoDigital');

    return artefatosInstalacaoDigital.instalacaoDigital && artefatosInstalacaoDigital.instalacaoDigital.valorTotal ? artefatosInstalacaoDigital.instalacaoDigital.valorTotal : 0;
  }


  componentDidMount() {

    const initialIndicators = this.getInitialIndicators();
    const finalIndicators = this.getFinalIndicators();
    const totalConsultoria = this.getTotalConsultoria();

    const oeeSubitens = {
      'Disponibilidade': {
        initial: initialIndicators.oeeDigital.disponibilidade,
        final: finalIndicators.oeeDigital.disponibilidade,
      },
      'Performance': {
        initial: initialIndicators.oeeDigital.performance,
        final: finalIndicators.oeeDigital.performance,
      },
      'Qualidade': {
        initial: initialIndicators.oeeDigital.qualidade,
        final: finalIndicators.oeeDigital.qualidade,
      }
    }


    const maturidadeSubitens = {
      'Estratégia e Organizações': {
        initial: initialIndicators.maturidadeDigital.pontuacaoEstrategia,
        final: finalIndicators.maturidadeDigital.pontuacaoEstrategia,
      },
      'Manufatura e Cadeia de Suprimentos': {
        initial: initialIndicators.maturidadeDigital.pontuacaoManufatura,
        final: finalIndicators.maturidadeDigital.pontuacaoManufatura,
      },
      'Modelos de Negócio Produtos e Serviços': {
        initial: initialIndicators.maturidadeDigital.pontuacaoNegocio,
        final: finalIndicators.maturidadeDigital.pontuacaoNegocio,
      },
      'Cultura e Pessoas': {
        initial: initialIndicators.maturidadeDigital.pontuacaoCultura,
        final: finalIndicators.maturidadeDigital.pontuacaoCultura,
      }
    }

    const produtividade = {
      initial: initialIndicators.produtividadeDigital.medicao,
      final: finalIndicators.produtividadeDigital.medicao,
    }

    const maturidade = {
      initial: initialIndicators.maturidadeDigital.pontuacaoTotal,
      final: finalIndicators.maturidadeDigital.pontuacaoTotal,
    }

    const oee = {
      initial: initialIndicators.oeeDigital.medicao,
      final: finalIndicators.oeeDigital.medicao,
    }



    this.setState({ oeeSubitens, maturidadeSubitens, produtividade, maturidade, oee, totalConsultoria })
  }

  changeTab = async (_event, newValue) => {
    this.setState({ tab: newValue });
  };

  transformCurrencyStringToNumber = (value) => {
    return Number(value.toString().substring(2).replace(',', '.'));
  }

  changeMonthReturn = (e, value) => {
    if (e != null && e.target != null && value) {
      
      const analiseResultadosDigital = this.state.analiseResultadosDigital;

      analiseResultadosDigital.retornoMensal = value;

      const pureValue = this.transformCurrencyStringToNumber(value);

      const retornoPrograma = pureValue === 0 ? 0 : this.state.totalConsultoria / pureValue;

      analiseResultadosDigital.retornoPrograma = retornoPrograma;
      
      this.setState({ analiseResultadosDigital });
    }
  }

  renderTabContent() {

    const ModifiedTooltip = withStyles({
      tooltip: {
          fontSize: '15px',
          fontFamily:'Raleway'
      }
    })(Tooltip);

    switch(this.state.tab) {
      case 'Resultados':
        return (
          <Grid container spacing={6} className="tabs-results">
            <Grid item xs={12}>
              <Results 
                title="Produtividade"
                initial={this.state.produtividade.initial}
                final={this.state.produtividade.final}
                type='hour'
              />
            </Grid>

            <Grid item xs={12}>
              <Results 
                title="OEE"
                initial={this.state.oee.initial}
                final={this.state.oee.final}
                subItems={this.state.oeeSubitens}
                type='percent'
              />
            </Grid>

            <Grid item xs={12}>
              <Results 
                title="Maturidade"
                initial={this.state.maturidade.initial}
                final={this.state.maturidade.final}
                subItems={this.state.maturidadeSubitens}
                type='integer'
              />
            </Grid>

            <div className="divider" />

            <Grid container spacing={4} style={{ padding: '0 40px'}}>
              <Grid item xs={4}>
                <InputSelect
                  label={<span style={{ color: '#077CDC' }}>Cálculo do Retorno do Programa</span>}
                  suggestions={this.calculationReturnProgramItems}
                  itemKey={'value'}
                  disabled={this.props.disabled}
                  itemLabel={'label'}
                  id={'calculoRetorno'}
                  getInput={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.calculoRetorno = e.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  initialSelectedItem={{ value: this.state.analiseResultadosDigital.calculoRetorno }}
                />
              </Grid>

              <Grid item xs={4}>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Retorno mensal na empresa após intervenções</span>}
                  type={"currency"}
                  changeValue={this.changeMonthReturn}
                  value={this.state.analiseResultadosDigital.retornoMensal}
                  placeholder="R$"
                  disabled={this.props.disabled}
                />
              </Grid>

              <Grid item xs={5} className="tabs-results-payback-container">
                <span className='tabs-results-payback-title'>
                  Retorno do Programa
                </span>
                <section className='tabs-results-payback-section'>
                  <ModifiedTooltip 
                    title={ 
                      <div>
                        Valor total da consultoria<br />
                        <span style={{color:'#E9B03D'}}>(÷) divisão </span><br/>
                        Retorno mensal na empresa após intervenções
                      </div>
                    }
                    placement="right" 
                    arrow
                  >
                    <img 
                      style={{height: 20, width: 20}} 
                      src={process.env.PUBLIC_URL + '/assets/icons/info-gray.svg'} 
                      alt={'icon'} 
                    />
                  </ModifiedTooltip>

                  <section className='tabs-results-payback-months'>
                    {this.state.analiseResultadosDigital.retornoPrograma.toFixed(4).replace('.', ',')} meses
                  </section>
                </section>

              </Grid>
              
            </Grid>
          </Grid>
        )
      case 'Análises':
        return (
          <Grid container className='tabs-analysis'>
            <Grid item xs={6} className='tabs-analysis-item'>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Análise do Índice de Produtividade</span>}
                  type={"textarea"}
                  changeValue={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.produtividade = e.target.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  inputValue={this.state.analiseResultadosDigital.produtividade}
                  placeholder="Digite aqui"
                  counter={1000}
                  subClass={'double-height'}
                  disabled={this.props.disabled}
                />
            </Grid>
            <Grid item xs={6}>                
            </Grid>


            <Grid item xs={6} className='tabs-analysis-item'>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Análise do Índice OEE</span>}
                  type={"textarea"}
                  changeValue={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.oee = e.target.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  inputValue={this.state.analiseResultadosDigital.oee}
                  placeholder="Digite aqui"
                  counter={1000}
                  subClass={'double-height'}
                  disabled={this.props.disabled}
                />
            </Grid>

            <Grid item xs={6}>                
            </Grid>

            <Grid item xs={6} className='tabs-analysis-item'>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Análise do Índice de Disponibilidade</span>}
                  type={"textarea"}
                  disabled={this.props.disabled}
                  placeholder="Digite aqui"
                  changeValue={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.disponibilidade = e.target.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  inputValue={this.state.analiseResultadosDigital.disponibilidade}
                  counter={1000}
                  subClass={'double-height'}
                />
            </Grid>

            <Grid item xs={6}>                
            </Grid>

            <Grid item xs={6} className='tabs-analysis-item'>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Análise do Índice de Performance</span>}
                  type={"textarea"}
                  disabled={this.props.disabled}
                  placeholder="Digite aqui"
                  counter={1000}
                  subClass={'double-height'}
                  changeValue={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.performance = e.target.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  inputValue={this.state.analiseResultadosDigital.performance}
                />
            </Grid>

            <Grid item xs={6}>                
            </Grid>

            <Grid item xs={6} className='tabs-analysis-item'>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Análise do Índice de Qualidade</span>}
                  type={"textarea"}
                  disabled={this.props.disabled}
                  placeholder="Digite aqui"
                  counter={1000}
                  subClass={'double-height'}
                  changeValue={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.qualidade = e.target.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  inputValue={this.state.analiseResultadosDigital.qualidade}
                />
            </Grid>

            <Grid item xs={6}>                
            </Grid>

            <Grid item xs={6} className='tabs-analysis-item'>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Análise do Retorno do Programa</span>}
                  type={"textarea"}
                  disabled={this.props.disabled}
                  placeholder="Digite aqui"
                  counter={1000}
                  subClass={'double-height'}
                  changeValue={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.analiseRetorno = e.target.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  inputValue={this.state.analiseResultadosDigital.analiseRetorno}
                />
            </Grid>

            <Grid item xs={6}>                
            </Grid>


            <Grid item xs={6} className='tabs-analysis-item'>
                <FormField
                  label={<span style={{ color: '#077CDC', fontSize: '15px' }}>Correlacionar os problemas, ações, resultados e benefícios para a empresa.</span>}
                  type={"textarea"}
                  disabled={this.props.disabled}
                  placeholder="Digite aqui"
                  counter={1000}
                  subClass={'double-height'}
                  changeValue={(e) => {
                    let analiseResultadosDigital = this.state.analiseResultadosDigital;
                    analiseResultadosDigital.resultados = e.target.value;
                    this.setState({analiseResultadosDigital});
                  }}
                  inputValue={this.state.analiseResultadosDigital.resultados}
                />
            </Grid>
          </Grid>
        )
    }
  }

  renderBody = () => {
      return (
          <Grid container justify={'center'} style={{ marginTop: '3%' }} spacing={6}>
            <Grid item xs={12}>
              <AppBar position='static'>
                <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                  <Tab key={'Resultados'} value={'Resultados'} label={'Resultados'} />
                  <Tab key={'Análises'} value={'Análises'} label={'Análises'} />
                </Tabs>
              </AppBar>
            </Grid>

            <Grid item xs={12}>
              {this.renderTabContent()}
            </Grid>
          </Grid>
      )
  }

  render() {
      return (
        <Grid container style={{ marginTop: '30px' }}>
          { this.renderBody() }
        </Grid>
      )
  }

}