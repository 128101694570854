import React from "react";
import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const AlertTipoCnpj = () => {

        return (
            <Grid item xs={12}>
                <Alert severity="error">
                    <AlertTitle>
                        <strong>Atenção:</strong> Para avançar o campo Classificação precisa ser preenchido
                    </AlertTitle>
                    <div style={{ marginBottom: '0.7em' }}>Selecione uma opção abaixo para poder avançar</div>                
                </Alert>
            </Grid>
        );
};

export default AlertTipoCnpj;