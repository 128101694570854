import React, { Component } from 'react';
import { Grid } from '@material-ui/core';

import './sectorCard.scss'
import DashboardCard from '../dashboardCard/dashboardCard';

export default class SectorCard extends Component {

    cards() {
        
        if(this.props.tabSector){
            if(this.props.valueOnly){
                return (    
                    <>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-blue-small'}  value={this.props.finished}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-green'}  value={this.props.progress} />
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-dark-gray'}  value={this.props.productivity + '%'} /> 
                        </Grid>
                    </>   
                );
            }
            else{
                return (    
                    <>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-blue'} text={'Número de atendimentos concluídos'} value={this.props.finished}/>
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-green'} text={'Número de atendimentos em andamento'} value={this.props.progress} />
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-dark-gray'} text={'Média de aumento de produtividade dos atendimentos concluídos'} value={this.props.productivity + '%'} /> 
                        </Grid>
                    </>   
                );
            }
            
        } else {
            if(this.props.valueOnly){
                return (    
                    <>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-green'}  value={this.props.use} />
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-dark-gray'} setor={true} value={this.props.sector} /> 
                        </Grid>
                    </>   
                );
            }
            else{
                return(
                    <>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-green'} text={'Número de utilizações nos atendimentos'} value={this.props.use} />
                        </Grid>
                        <Grid item xs={12}>
                            <DashboardCard color={'card-blue-tools'} text={'Setor que mais utiliza'} value={this.props.sector} />
                        </Grid>
                    </>
                );
            }
            
        }
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className={this.props.valueOnly ? 'card card-carousel-fixed' : 'card card-carousel'}>
                    <Grid container direction={'column'} justify={'center'} className={'card-light-gray'}>
                        <Grid item>
                            <img className={'icon-sector'} src={this.props.icons} alt=''/>
                        </Grid>
                        <Grid item className={'font-sector'}>{this.props.description}</Grid>
                    </Grid>
                    {this.cards()}
                </Grid>
            </Grid>
        )
    }
}

