import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import FormField from '../../components/formField/formField';
import Collapse from './../../components/collapse/collapse';
import Typography from '@material-ui/core/Typography';
import GenericButton from '../../components/genericButton/genericButton';
import EmptyList from '../../components/emptyList/emptyList';

export default class AtividadePlanoDeAcao extends Component {

	constructor(props) {
        super(props);
        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let disableEditAntes = etapa.etapa >= 8; 
        let tab = !disableEditAntes ? 'Antes' : 'Depois';
        let formAntes, formDepois, errorsAntes, errorsDepois;
        if (tab === 'Antes') {
            formAntes = props.atividade;
            formDepois = turma.etapaPraticaD.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).planoDeAcao; //sempre mentoria prática d
            errorsAntes = props.atividade.planoList.map(() => { return this.getErrorsModel() });
            errorsDepois = [];
        } else {
            formAntes = turma.etapaPraticaC.atendimentoList.find(atd => { return atd.idAtendimento === this.props.atendimento.idAtendimento }).planoDeAcao; //sempre mentoria prática c
            formDepois = props.atividade;
            errorsAntes = [];
            errorsDepois = props.atividade.planoList.map(() => { return this.getErrorsModel() });
        }
        this.state = { tab: tab, disableDepois: turma.etapaAtual < 8, disableEditAntes: disableEditAntes, formAntes: formAntes, formDepois: formDepois, errorsAntes: errorsAntes, errorsDepois: errorsDepois,
            nomePlano: '', nomePlanoError: false };
    };

    getErrorsModel() {
    	return { nomePlano: '', OQue: '', porQue: '', quem: '', quando: '', onde: '', como: '', quanto: '' };
    };

    changeTab = (value, newValue) => {
        this.setState({ tab: newValue });
    };

    getPlanoDeAcaoModel() {
        return { nomePlano: this.state.nomePlano, OQue: '', porQue: '', quem: '', quando: '', onde: '', como: '', quanto: '' };
    };

	changeInput = (nomePlano, e) => {
        let atividade = this.state.tab === 'Antes' ? this.state.formAntes : this.state.formDepois;
        let errors = this.state.tab === 'Antes' ? this.state.errorsAntes : this.state.errorsDepois;
        let index = atividade.planoList.findIndex(plano => { return plano.nomePlano.toUpperCase() === nomePlano.toUpperCase() });
        if (e && e.target && e.target.id && e.target.id === 'nomePlano') {
            this.setState({ nomePlanoError: index > -1, nomePlano: e.target.value.toUpperCase() });
        } else if (e && e.target && e.target.id && index > -1) {
            atividade.planoList[index][e.target.id] = e.target.value.toUpperCase();
            if (e.target.value) errors[index][e.target.id] = '';
            else errors[index][e.target.id] = 'Campo obrigatório.';
            if (this.state.tab === 'Antes') this.setState({ formAntes: atividade, errorsAntes: errors });
            else if (this.state.tab === 'Depois') this.setState({ formDepois: atividade, errorsDepois: errors });
        } 
    };

	renderBody = () => {
        let atividade = this.state.tab === 'Antes' ? this.state.formAntes : this.state.formDepois;
        let disabled = this.props.disabled || (this.state.tab === 'Antes' && this.state.disableEditAntes) || (this.state.tab === 'Depois' && !this.state.disableEditAntes);
        let errors = this.state.tab === 'Antes' ? this.state.errorsAntes : this.state.errorsDepois;
        return (
            <Grid container justify={'center'} spacing={1} className={'grid-body'} key={this.state.tab + atividade.id}>
                <Grid container justify={'center'} style={{ display: 'flex', alignItems: 'center', marginTop: '40px' }}>
                    <Grid item xs={11} md={5} xl={4} className={'add-button'}>
                        <FormField type={'text'} label={'Nome do Plano 5W2H'} id={'nomePlano'} key={'nomePlano'} changeValue={(e) => this.changeInput(e.target.value, e)} 
                            inputValue={this.state.nomePlano} disabled={disabled} />
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: '20px' }}>
                        <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addItem} icon={'plus.svg'} disabled={this.state.nomePlanoError || disabled} />
                    </Grid>
                    <Grid item xs={12}>
                    </Grid>
                    { this.state.nomePlanoError && 
                        <Grid item xs={12} md={6} xl={5} style={{ marginBottom: '10px' }}>
                            <span className={'nome-error'}>Já existe um plano com esse nome.</span> 
                        </Grid> }
                </Grid>
                <Grid item xs={12}>
                    <div className={'div-border top ' + (atividade.planoList && atividade.planoList.length > 0 ? 'bottom' : '')} />
                </Grid>
                <Grid item xs={12} md={11} xl={9}>
                    { atividade.planoList && atividade.planoList.length > 0 ?
                        <Collapse id={'content-cluster'} content={this.convertToCollapse(atividade, disabled, errors)} isExpanded={true} /> :
                        <EmptyList type={'noItens'} msg={' '} subMsg={'Não há planos cadastrados.'} /> }
                </Grid>
            </Grid>
        );
    };

    convertToCollapse = (atividade, disabled, errors) => {
        return atividade.planoList.map((aux, index) => {
            return {
                id: aux.nomePlano,
                title:
                    <Grid container>
                        <Typography style={{ flexBasis: '100%' }} noWrap>{aux.nomePlano}</Typography>
                    </Grid>,
                body:
                    <Grid container justify={'center'} spacing={1} className={'form-acao'}>
                        <Grid item xs={12} md={4} xl={4}>
                            <FormField type={'textarea'} id={'OQue'} key={'OQue'} label={'O que?'} disabled={disabled} placeholder={'Escreva um texto descrevendo'} changeValueBlur={(e) => this.changeInput(aux.nomePlano, e)}
                                changeValue={(e) => this.changeInput(aux.nomePlano, e)} inputValue={aux.OQue} min='1' maxLength='250' error={disabled ? '' : errors[index].OQue} />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <FormField type={'textarea'} id={'porQue'} key={'porQue'} label={'Por que?'} disabled={disabled} placeholder={'Escreva um texto descrevendo'} changeValueBlur={(e) => this.changeInput(aux.nomePlano, e)}
                                changeValue={(e) => this.changeInput(aux.nomePlano, e)} inputValue={aux.porQue} min='1' maxLength='250' error={disabled ? '' : errors[index].porQue} />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <FormField type={'textarea'} id={'quem'} key={'quem'} label={'Quem?'} disabled={disabled} placeholder={'Escreva um texto descrevendo'} changeValueBlur={(e) => this.changeInput(aux.nomePlano, e)}
                                changeValue={(e) => this.changeInput(aux.nomePlano, e)} inputValue={aux.quem} min='1' maxLength='250' error={disabled ? '' : errors[index].quem} />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <FormField type={'textarea'} id={'quando'} key={'quando'} label={'Quando?'} disabled={disabled} placeholder={'Escreva um texto descrevendo'} changeValueBlur={(e) => this.changeInput(aux.nomePlano, e)}
                                changeValue={(e) => this.changeInput(aux.nomePlano, e)} inputValue={aux.quando} min='1' maxLength='250' error={disabled ? '' : errors[index].quando} />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <FormField type={'textarea'} id={'onde'} key={'onde'} label={'Onde?'} disabled={disabled} placeholder={'Escreva um texto descrevendo'} changeValueBlur={(e) => this.changeInput(aux.nomePlano, e)}
                                changeValue={(e) => this.changeInput(aux.nomePlano, e)} inputValue={aux.onde} min='1' maxLength='250' error={disabled ? '' : errors[index].onde} />
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <FormField type={'textarea'} id={'como'} key={'como'} label={'Como?'} disabled={disabled} placeholder={'Escreva um texto descrevendo'} changeValueBlur={(e) => this.changeInput(aux.nomePlano, e)}
                                changeValue={(e) => this.changeInput(aux.nomePlano, e)} inputValue={aux.como} min='1' maxLength='250' error={disabled ? '' : errors[index].como} />
                        </Grid>
                        <Grid item xs={12} md={4} xl={4}>
                            <FormField type={'textarea'} id={'quanto'} key={'quanto'} label={'Quanto?'} disabled={disabled} placeholder={'Escreva um texto descrevendo'} changeValueBlur={(e) => this.changeInput(aux.nomePlano, e)}
                                changeValue={(e) => this.changeInput(aux.nomePlano, e)} inputValue={aux.quanto} min='1' maxLength='250' error={disabled ? '' : errors[index].quanto} />
                        </Grid>
                    </Grid>,
                trashButton:
                    <Grid container>
                        <GenericButton color={'transparent'} click={(e) => { this.removeItem(aux.nomePlano) }} subClass={'icon-and-button'} icon={'trash.svg'} disabled={disabled} />
                    </Grid>
            };
        });
    };

    addItem = () => {
        if (this.state.nomePlano && this.state.nomePlano.length > 0) {
            let atividade = this.state.tab === 'Antes' ? this.state.formAntes : this.state.formDepois;
            let errors = this.state.tab === 'Antes' ? this.state.errorsAntes : this.state.errorsDepois;
            atividade.planoList.push(this.getPlanoDeAcaoModel());
            errors.push(this.getErrorsModel());
            if (this.state.tab === 'Antes') this.setState({ formAntes: atividade, errorsAntes: errors, nomePlano: '', nomePlanoError: false });
            else this.setState({ formDepois: atividade, errorsDepois: errors, nomePlano: '', nomePlanoError: false });
        }
    };

    removeItem = (nomePlano) => {
        let atividade = this.state.tab === 'Antes' ? this.state.formAntes : this.state.formDepois;
        let errors = this.state.tab === 'Antes' ? this.state.errorsAntes : this.state.errorsDepois;
        let index = atividade.planoList.findIndex(plano => { return plano.nomePlano === nomePlano }); 
        atividade.planoList.splice(index, 1);
        errors.splice(index, 1);
        if (this.state.tab === 'Antes') this.setState({ formAntes: atividade, errorsAntes: errors, nomePlano: '', nomePlanoError: false });
        else this.setState({ formDepois: atividade, errorsDepois: errors, nomePlano: '', nomePlanoError: false });
    };

	render() {
        return (
            <Grid container className={'atividade-plano-de-acao'} style={{ marginTop: '30px' }}>
                <Grid item xs={12}>
                    <AppBar position='static'>
                        <Tabs value={this.state.tab} onChange={this.changeTab} variant='scrollable' scrollButtons='auto'>
                            <Tab key={'Antes'} value={'Antes'} label={'Antes'} disabled={false}></Tab>
                            <Tab key={'Depois'} value={'Depois'} label={'Depois'} disabled={this.state.disableDepois || !this.state.formDepois}></Tab>
                        </Tabs>
                    </AppBar>
                </Grid>
                <Grid item xs={12}>
                    { this.renderBody() }
                </Grid>
                <Grid item xs={12}>
                    <div className={'div-border top'} />
                </Grid>
            </Grid>
        )
    };

}