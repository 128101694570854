import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import { Grid } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import GenericButton from './../../components/genericButton/genericButton';

export default class AtividadeMFV extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            mfv: props.atividade, 
            errors: { 
                leadTime: '', 
                taktTime: '', 
                linhaProducaoIntervencao: '', 
                mainProdutoLinha: '', 
                maisInfo: '',
                motivoLinhaProducao: '',
                isLinhaGargaloFabrica: false,
                tipoProduto: 1
            }
        };
    };

    changeInput = (e) => {
        let mfv = this.state.mfv;
        let errors = this.state.errors;
        if (e && e.target && e.target.id) {
            mfv[e.target.id] = e.target.value.toUpperCase();
            if (e.target.required) {
                if (e.target.value === '0' && (e.target.id === 'leadTime' || e.target.id === 'taktTime')) {
                    errors[e.target.id] = 'Valor deve ser maior que 0.';
                }
                else if (e.target.value) {
                    errors[e.target.id] = '';
                }
                else {
                    errors[e.target.id] = 'Campo obrigatório.';
                }
            } else if (e.target.id === 'maisInfo' && mfv.maisInformacoesList.length === 0 && !e.target.value) {
                errors[e.target.id] = 'Cadastre pelo menos 1 informação.';
            } else {
                errors[e.target.id] = '';
            }
            this.setState({ mfv: mfv, errors: errors });
        } 
    };

    addInfo = () => {
        let mfv = this.state.mfv;
        let errors = this.state.errors;
        if (mfv.maisInfo && mfv.maisInfo.length > 0) {
            let index = mfv.maisInformacoesList.findIndex(maisInfo => { 
                return maisInfo.info.toUpperCase() === mfv.maisInfo.toUpperCase()
            });
            if (index < 0) {
                mfv.maisInformacoesList.push({ info: mfv.maisInfo });
                mfv.maisInfo = '';
            } else errors.maisInfo = 'Informação já adicionada.'
            this.setState({ mfv: mfv, errors: errors });
        }
    };

    removeInfo = (currInfo) => {
        let mfv = this.state.mfv;
        let errors = this.state.errors;
        let index = mfv.maisInformacoesList.findIndex(maisInfo => {
            return maisInfo.id === currInfo.id && maisInfo.info.toUpperCase() === currInfo.info.toUpperCase()
        });
        if (index >= 0) {
            mfv.maisInformacoesList.splice(index, 1);
            errors.maisInfo = '';
            if (mfv.maisInformacoesList.length === 0) errors['maisInfo'] = 'Cadastre pelo menos 1 informação.';
            this.setState({ mfv: mfv, errors: errors });
        }
    };

    changeSwitchField = (e) => {
        let mfv = this.state.mfv;
        if (e.target.id === 'gargaloFabrica')
            mfv.isLinhaGargaloFabrica = !e.target.checked;
        else mfv.tipoProduto = e.target.checked ? 2 : 1;
        this.setState({ mfv: mfv });
    };

    convertInfo = () => {
        return this.state.mfv.maisInformacoesList.map((maisInfo, index) => (
            <Chip key={index} label={<label style={{ display: 'flex', alignItems: 'center'}}><p>{maisInfo.info}</p></label>}
                icon={this.props.disabled ? <></> : <Icon onClick={() => this.removeInfo(maisInfo)} className={'close'}>clear</Icon>} />
        ));
    };

    render() {
        return (
            <Grid container spacing={1} style={{ display: 'flex' }} className={'atividade-mfv'}>
                <Grid item xs={12} className={'grid-label'}>
                    <div className={'div-border top bottom'} />
                </Grid>
                <Grid container direction={'column'} justify={'center'} alignItems={'center'} alignContent={'center'}>
                    <Grid container justify={'center'} spacing={2} direction={'row'}>
                        <Grid item xs={10}>
                            <Grid container spacing={1} direction={'row'} justify={'center'}>
                                <Grid item xs={12} md={3} xl={2}>
                                    <FormField type={'number'} label={'Qual o Lead Time?'} id={'leadTime'} 
                                        inputValue={this.state.mfv.leadTime} subtype={'inteiro'}
                                        changeValue={this.changeInput} required={true}
                                        min='1' maxLength='15' error={this.state.errors.leadTime}
                                        disabled={this.props.disabled} />
                                </Grid>
                                <Grid item xs={12} md={3} xl={2}>
                                    <FormField type={'number'} label={'Qual o Takt Time?'} id={'taktTime'}
                                        inputValue={this.state.mfv.taktTime} subtype={'inteiro'}
                                        changeValue={this.changeInput} required={true}
                                        min='1' maxLength='15' error={this.state.errors.taktTime}
                                        disabled={this.props.disabled}/>
                                </Grid>
                                <Grid item xs={12} md={6} xl={4}>
                                    <FormField type={'text'} label={'Qual linha de produção terá intervenção?'} ref='texto' id={'linhaProducaoIntervencao'}
                                        inputValue={this.state.mfv.linhaProducaoIntervencao}
                                        changeValue={this.changeInput} required={true} error={this.state.errors.linhaProducaoIntervencao}
                                        disabled={this.props.disabled}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container justify={'center'} style={{ marginTop: '2%' }}>
                        <Grid container spacing={2} direction={'row'} justify={'center'}>
                            <Grid item xs={11}>
                                <Grid container spacing={1} direction={'row'} justify={'center'}>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <FormField type={'text'} label={'Qual o principal produto da linha produtiva?'} ref='texto' id={'mainProdutoLinha'}
                                            inputValue={this.state.mfv.mainProdutoLinha}
                                            changeValue={this.changeInput} required={true} error={this.state.errors.mainProdutoLinha}
                                            disabled={this.props.disabled}/>
                                    </Grid>
                                    <Grid item xs={12} md={6} xl={4}>
                                        <FormField type={'text'} label={'Qual o motivo de seleção da linha de produção?'} ref='texto' id={'motivoLinhaProducao'}
                                            inputValue={this.state.mfv.motivoLinhaProducao}
                                            changeValue={this.changeInput} required={true} error={this.state.errors.motivoLinhaProducao}
                                            disabled={this.props.disabled}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>               
                <Grid container style={{ justifyContent: 'center', marginTop: '1%'}} spacing={4}>
                    <Grid item >
                        <Grid container spacing={8} justify={'center'}>
                            <Grid item >
                                <Grid container spacing={2}> <Grid item> <label className={'label-title'}> A linha escolhida é o gargalo da fábrica? </label> </Grid> </Grid>
                                <Grid container spacing={2}>
                                    <Grid item> <label className={'label-title'}> Sim </label> </Grid>
                                    <Grid item style={{ padding: 1}}>
                                        <Switch checked={!this.state.mfv.isLinhaGargaloFabrica} onChange={this.changeSwitchField} id="gargaloFabrica" color="default" disabled={this.props.disabled} /> 
                                    </Grid>
                                    <Grid item > <label className={'label-title'}> Não </label> </Grid>
                                </Grid>
                            </Grid>
                            <Grid item >
                                <Grid container spacing={2}> <Grid item> <label  className={'label-title'}> Tipo do produto da produção </label> </Grid> </Grid>
                                <Grid container spacing={2}>
                                    <Grid item> <label className={'label-title'}> Discreto </label> </Grid>
                                    <Grid item style={{ padding: 1}}>
                                        <Switch checked={this.state.mfv.tipoProduto !== 1} onChange={this.changeSwitchField} id="tipoProduto" color="default" disabled={this.props.disabled}/> 
                                    </Grid>
                                    <Grid item> <label className={'label-title'}> Contínuo </label> </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justify={'center'} spacing={4}>
                    <Grid item xs={12} md={7} xl={5}>
                        <Grid container justify={'center'} direction={'row'} style={{ marginTop: '3%', marginLeft: '12px' }} >
                            <Grid item sm={9} >
                                <FormField type={'text'} label={'Cadastrar mais informações'} id={'maisInfo'} pressEnter={this.addInfo} placeholder={''}
                                    inputValue={this.state.mfv.maisInfo ? this.state.mfv.maisInfo : ''} 
                                    changeValue={(e) => { this.changeInput(e) }} error={this.state.errors.maisInfo}
                                    disabled={this.props.disabled}
                                />
                            </Grid>
                            <Grid item md={1} className={'botao'}>
                                <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addInfo} icon={'plus.svg'} disabled={this.props.disabled} />
                            </Grid>
                            <Grid item xs={10}> { this.convertInfo() } </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}