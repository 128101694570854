import React from "react";
import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

const AlertAtendimentoBMP = ({ cliente, produtoRegional }) => {

    return (
        <Grid item xs={12}>
            <Alert severity="error">
                <AlertTitle>
                    <strong>Atenção:</strong> Atendimento do Programa Brasil Mais Produtivo
                </AlertTitle>
                {`O cliente ${cliente.nome} já está cadastrado no produto ${produtoRegional.produtoNacional.descricao}. Portanto, não é possível cadastrá-lo em outro atendimento do mesmo produto.`}
            </Alert>
        </Grid>
    );
};

export default AlertAtendimentoBMP;
