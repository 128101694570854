import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import './totalsBlock.scss';


export default class TotalsBlock extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Grid item xs={4} className={'totals'}>
                {this.props.children}
            </Grid>
        )
    }
}