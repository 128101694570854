import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import './cardAtividade.scss'

export default class CardAtividade extends Component {

    render() {
        return (
            <Grid item xs={2} className={`card-atividade ${this.props.disabled && 'card-atividade-disabled'}`} onClick={!this.props.disabled && this.props.onClick}>
                <Grid container style={{ justifyContent: 'center' }}>
                    <Grid item xs={12} className={'iconeAtividade'}>
                        <img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/' + this.props.icon} />
                    </Grid>
                </Grid>
                <Grid container style={{ justifyContent: 'center', marginTop: '10px' }}>
                    <Grid item xs={12} className={this.props.noCap ? 'labelAtividadeNoCap' : 'labelAtividade'}>
                        {this.props.label}
                    </Grid>
                </Grid>
                <Grid container className={'statusAtividade ' + this.props.status.class}>
                    <Grid item xs={12} className={'statusAtividadeText'}>
                        { this.props.status.label }
                        { this.props.status.id !== 4 && this.props.status.id !== 5 && <>&ensp;<img alt={''} src={process.env.PUBLIC_URL + '/assets/icons/status-' + this.props.status.class + '.svg'} /></>}
                    </Grid>
                </Grid>
            </Grid>
        )
    };
};