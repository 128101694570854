import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import { Grid } from "@material-ui/core";
import API from '../../comm/API'
import SimpleModal from './../../components/modal/modal'
import InputSelect from '../../components/inputselect/inputselect';
import List from '@material-ui/core/List';

export default class Fase extends Component {

    atendimento = this.props.atendimento;
    fase = null;
    dataFinal = null;
    quantidadeDeHoras = undefined;

    constructor(props) {
        super(props);
        this.state = {}
    }
    errors = {
        fase: { subClass: '', error: '' },
        quantidadeDeHoras: { subClass: '', error: '' },
        dataFinal: { subClass: '', error: '' }
    }

    changeFase = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'quantidadeDeHoras') {
                if (e.target.value <= 0) {
                    this.errors.quantidadeDeHoras.error = 'A quantidade de horas deve ser maior que 0.';
                    this.errors.quantidadeDeHoras.subClass = 'isInvalid';
                    this.quantidadeDeHoras = undefined;
                } else {
                    this.errors.quantidadeDeHoras.error = '';
                    this.errors.quantidadeDeHoras.subClass = '';
                    this.quantidadeDeHoras = e.target.value;
                }
            }
            if (e.target.id === 'dataFinal') {
                this.dataFinal = e.target.value + 'T00:00:00-03:00';
            }
        } else if (e != null) {
            let val = this.atendimento.atendimentoFaseList.find(element => {
                return element.fase.id === e.id;
            });
            if (val) {
                this.errors.fase.error = 'Esta fase já foi registrada neste atendimento.';
                this.errors.fase.subClass = 'isInvalid';
                this.fase = null;
            } else {
                this.errors.fase.error = '';
                this.errors.fase.subClass = '';
                this.fase = e;
            }
        }
        this.setState(this.state);
    }

    clearFase = () => {
        this.fase = undefined;
        this.dataFinal = undefined;
        this.quantidadeDeHoras = undefined;
        this.setState(this.state);
        this.props.changeModal('modalFase', false);
    }

    validateFase = () => {
        return !(this.fase != null && this.quantidadeDeHoras > 0 && this.dataFinal != null);
    };

    saveFase = () => {
        this.props.showLoading();
        var atendimentoFase = { fase: null, dataFinal: null, quantidadeDeHoras: 0 };
        atendimentoFase.fase = this.fase;
        atendimentoFase.dataFinal = this.dataFinal;
        atendimentoFase.quantidadeDeHoras = this.quantidadeDeHoras;

        this.atendimento.atendimentoFaseList.push(atendimentoFase);
        API.post('rest/gestao/Atendimento/' + this.atendimento.id, this.atendimento).then(res => {
            this.clearFase();
            this.props.openAtendimento(this.atendimento.id);
            this.props.showToast('Fase adicionada com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.showToast('Error ao cadastrar fase. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    getEtapaTeorica(etapa, descricao) {
        let item = undefined;
        let dataInicio = '', dataTermino = '';
        if (this.props.turma && [2, 4, 7].includes(etapa)) {
            item = this.props.turma.etapaTeoricaList.find(aux => { return aux.etapa === etapa });
            dataInicio = item ? item.dataInicio : null;
            dataTermino = item ?  item.dataTermino : null;
        } else if (this.props.turma && etapa === 3) {
            item = this.props.turma.etapaPraticaB.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaPraticaB.dataInicio;
            dataTermino = this.props.turma.etapaPraticaB.dataTermino;
        } else if (this.props.turma && etapa === 5) {
            item = this.props.turma.etapaPraticaC.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaPraticaC.dataInicio;
            dataTermino = this.props.turma.etapaPraticaC.dataTermino;
        } else if (this.props.turma && etapa === 6) {
            item = this.props.turma.etapaConsultoriaC.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaConsultoriaC.dataInicio;
            dataTermino = this.props.turma.etapaConsultoriaC.dataTermino;
        } else if (this.props.turma && etapa === 8) {
            item = this.props.turma.etapaPraticaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaPraticaD.dataInicio;
            dataTermino = this.props.turma.etapaPraticaD.dataTermino;
        } else if (this.props.turma && etapa === 9) {
            item = this.props.turma.etapaConsultoriaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaConsultoriaD.dataInicio;
            dataTermino = this.props.turma.etapaConsultoriaD.dataTermino;
        }
        return (item &&
            <Grid container key={'etapa' + etapa}>
                <Grid item xs={3} style={{ fontWeight: '700' }}>{item.etapa ? descricao : '-'}</Grid>
                <Grid item xs={2}>{this.props.formatDataWithSlash(dataInicio)}</Grid>
                <Grid item xs={2}>{this.props.formatDataWithSlash(dataTermino)}</Grid>
                <Grid item xs={2}>{item.horasApropriadas ? item.horasApropriadas + ' horas' : '-'}</Grid>
                <Grid item xs={2}>{item.finalizada || item.finalizado ? 'Finalizada' : 'Não finalizada'}</Grid>
            </Grid>
        );
    };

    getEtapaTeoricaDigital(etapa, descricao) {
        let item = undefined;
        let dataInicio = '', dataTermino = '';
        if (this.props.turma &&  [3, 6, 9].includes(etapa)) {
            item = this.props.turma.etapaTeoricaList.find(aux => { return aux.etapa === etapa });
            dataInicio = item.dataInicio;
            dataTermino = item.dataTermino;
        } else if (this.props.turma && etapa === 2) {
            item = this.props.turma.etapaAlinhamentoB.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaAlinhamentoB.dataInicio;
            dataTermino = this.props.turma.etapaAlinhamentoB.dataTermino;
        } else if (this.props.turma && etapa === 4) {
            item = this.props.turma.etapaPraticaB.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaPraticaB.dataInicio;
            dataTermino = this.props.turma.etapaPraticaB.dataTermino;
        } else if (this.props.turma && etapa === 5) {
            item = this.props.turma.etapaConsultoriaB.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaConsultoriaB.dataInicio;
            dataTermino = this.props.turma.etapaConsultoriaB.dataTermino;
        } else if (this.props.turma && etapa === 7) {
            item = this.props.turma.etapaPraticaC.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaPraticaC.dataInicio;
            dataTermino = this.props.turma.etapaPraticaC.dataTermino;
        } else if (this.props.turma && etapa === 8) {
            item = this.props.turma.etapaConsultoriaC.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaConsultoriaC.dataInicio;
            dataTermino = this.props.turma.etapaConsultoriaC.dataTermino;
        } else if (this.props.turma && etapa === 10) {
            item = this.props.turma.etapaPraticaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaPraticaD.dataInicio;
            dataTermino = this.props.turma.etapaPraticaD.dataTermino;
        } else if (this.props.turma && etapa === 11) {
            item = this.props.turma.etapaConsultoriaD.atendimentoList.find(aux => { return aux.idAtendimento === this.atendimento.id });
            dataInicio = this.props.turma.etapaConsultoriaD.dataInicio;
            dataTermino = this.props.turma.etapaConsultoriaD.dataTermino;
        }
        if(item){
            return (
            <Grid container key={'etapa' + etapa}>
                <Grid item xs={3} style={{ fontWeight: '700' }}>{item.etapa ? descricao : '-'}</Grid>
                <Grid item xs={2}>{this.props.formatDataWithSlash(dataInicio)}</Grid>
                <Grid item xs={2}>{this.props.formatDataWithSlash(dataTermino)}</Grid>
                <Grid item xs={2}>{item.horasApropriadas ? item.horasApropriadas + ' horas' : '-'}</Grid>
                <Grid item xs={2}>{item.finalizada || item.finalizado ? 'Finalizada' : 'Não finalizada'}</Grid>
            </Grid>
        );
        }
        
    };

    render() {
        return (
            <Grid item xs={12}>
                <div className={'greyDiv'}>
                    { !this.props.isDN && (this.props.isAceito || this.props.isExecucao) && !(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && this.atendimento.atendimentoFaseList.length < this.atendimento.produtoRegional.produtoNacional.fasesList.length && this.props.showButton &&
                        <GenericButton color={'darkBlue'} label={'Registrar fase'}
                            subClass={'basic-button'} click={() => this.props.changeModal('modalFase', true)} />
                    }
                    { (this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && (this.props.isAceito || this.props.isExecucao || this.props.isConcluido || this.props.isCancelado) &&
                        <GenericButton color={'darkBlue'} label={'Acessar trilha'}
                            subClass={'basic-button'} click={() => this.props.changeModal('modalTrilha', true)} />
                    }
                    <h2>Fases</h2>
                    { !(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) && this.atendimento.atendimentoFaseList.length === 0 ?
                        <p>Este atendimento ainda não possui fases registradas.</p> :
                        this.atendimento.atendimentoFaseList.map(item => (
                            <Grid container key={item.id}>
                                <Grid item xs={4}><b>{item.fase.descricao}</b></Grid>
                                <Grid item xs={3}>{item.quantidadeDeHoras}h</Grid>
                                <Grid item xs={5}>Início: {this.props.formatDataWithSlash(item.dataFinal)}</Grid>
                            </Grid>
                        )) }
                    { this.props.isAtendimentoBrasilMaisLEAN && this.props.turma != null && this.props.turma.etapaTeoricaList.length > 0 ?
                        <List dense={true}>
                            { this.props.turma.etapaA ?
                                <Grid container key={'etapaA'}>
                                    <Grid item xs={3} style={{ fontWeight: '700' }}>A - Lean Game Digital</Grid>
                                    <Grid item xs={2}>-</Grid>
                                    <Grid item xs={2}>-</Grid>
                                    <Grid item xs={2}>-</Grid>
                                    <Grid item xs={2}>{this.props.turma.etapaAtual > 1 ? 'Finalizada' : 'Não finalizada'}</Grid>
                                </Grid> : null }
                            { this.props.turma.etapaTeoricaList.length > 0 ? 
                                this.getEtapaTeorica(2, 'B - Teórica')
                                : null }
                            { this.props.turma.etapaPraticaB ?
                                this.getEtapaTeorica(3, 'B - Prática')
                                : null }
                            { this.props.turma.etapaTeoricaList.length > 0 ? 
                                this.getEtapaTeorica(4, 'C - Teórica')
                                : null }
                            { this.props.turma.etapaPraticaC ?
                                this.getEtapaTeorica(5, 'C - Prática')
                                : null }
                            { this.props.turma.etapaConsultoriaC ?
                                this.getEtapaTeorica(6, 'C - Consultoria')
                                : null }
                            { this.props.turma.etapaTeoricaList.length > 0 ? 
                                this.getEtapaTeorica(7, 'D - Teórica')
                                : null }
                            { this.props.turma.etapaPraticaD ?
                                this.getEtapaTeorica(8, 'D - Prática')
                                : null }
                            { this.props.turma.etapaConsultoriaD ?
                                this.getEtapaTeorica(9, 'D - Consultoria')
                                : null }
                        </List> : null }
                    { this.props.isAtendimentoDIGITAL && this.props.turma != null && this.props.turma.etapaTeoricaList.length > 0 ?
                        <List dense={true}>
                            { this.props.turma.etapaA ?
                                <Grid container key={'etapaA'}>
                                    <Grid item xs={3} style={{ fontWeight: '700' }}>A - Lean Game Digital</Grid>
                                    <Grid item xs={2}>-</Grid>
                                    <Grid item xs={2}>-</Grid>
                                    <Grid item xs={2}>-</Grid>
                                    <Grid item xs={2}>{this.props.turma.etapaAtual > 1 ? 'Finalizada' : 'Não finalizada'}</Grid>
                                </Grid> : null }
                            { this.props.turma.etapaTeoricaList.length > 0 ? 
                                this.getEtapaTeoricaDigital(2, 'B - Alinhamento')
                                : null }
                            { this.props.turma.etapaPraticaB ?
                                this.getEtapaTeoricaDigital(3, 'B - Teórica')
                                : null }
                            { this.props.turma.etapaPraticaB ?
                                this.getEtapaTeoricaDigital(4, 'B - Prática')
                                : null }
                            { this.props.turma.etapaPraticaB ?
                                this.getEtapaTeoricaDigital(5, 'B - Consultoria')
                                : null }
                            { this.props.turma.etapaTeoricaList.length > 0 ? 
                                this.getEtapaTeoricaDigital(6, 'C - Teórica')
                                : null }
                            { this.props.turma.etapaPraticaC ?
                                this.getEtapaTeoricaDigital(7, 'C - Prática')
                                : null }
                            { this.props.turma.etapaConsultoriaC ?
                                this.getEtapaTeoricaDigital(8, 'C - Consultoria')
                                : null }
                            { this.props.turma.etapaTeoricaList.length > 0 ? 
                                this.getEtapaTeoricaDigital(9, 'D - Teórica')
                                : null }
                            { this.props.turma.etapaPraticaD ?
                                this.getEtapaTeoricaDigital(10, 'D - Prática')
                                : null }
                            { this.props.turma.etapaConsultoriaD ?
                                this.getEtapaTeoricaDigital(11, 'D - Consultoria')
                                : null }
                        </List> : null }
                </div>
                {!this.props.modalFase || !(this.props.isAtendimentoBrasilMaisLEAN || this.props.isAtendimentoDIGITAL) ? null :
                    <SimpleModal
                        isOpen={this.props.modalFase}
                        handleClose={() => this.props.changeModal('modalFase', false)}
                        type={'info'}
                        width={'400px'}>
                        <div className={'modal modal-fase'}>
                            <h2>Registrar Fase</h2>
                            <Grid container>
                                <Grid item xs={12}>
                                    <InputSelect
                                        label={'Fase'}
                                        suggestions={this.atendimento.produtoRegional.produtoNacional.fasesList}
                                        itemKey={'id'}
                                        itemLabel={'descricao'}
                                        id={'atendimentoFase.fase'}
                                        getInput={this.changeFase}
                                        error={this.errors.fase.error}
                                        initialSelectedItem={{ id: this.fase != null ? this.fase.id : undefined }}
                                    />
                                </Grid>
                                <Grid item xs={6} className={'paddingForm'}>
                                    <FormField type={'number'} label={'Horas'} ref='quantidadeDeHoras' id={'quantidadeDeHoras'}
                                        inputValue={this.quantidadeDeHoras}
                                        changeValue={this.changeFase} error={this.errors.quantidadeDeHoras.error} />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormField type={'date'} label={'Data de início'} ref='dataFinal' id={'dataFinal'}
                                        inputValue={this.props.formatData(this.dataFinal)}
                                        changeValue={this.changeFase} icon={'calendar.svg'} error={this.errors.dataFinal.error} />
                                </Grid>
                                <Grid item xs={12} className={'rodape'}>
                                    <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={this.clearFase} />
                                    <GenericButton color={'darkBlue'} label={'Registrar'} subClass={'basic-button'} click={this.saveFase}
                                        disabled={this.validateFase()} />
                                </Grid>
                            </Grid>
                        </div>
                    </SimpleModal>
                }
            </Grid>
        )
    }
}