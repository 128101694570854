export const SyncRequest = (method, url, json) => {
    var xhr = new XMLHttpRequest()
    xhr.open(method, url, false)
    xhr.withCredentials = true
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.setRequestHeader('Accept', 'application/json')
    xhr.send((json === undefined) ? null : json)
    if (xhr.status === 200) {
        return JSON.parse(xhr.response)
    }
    else {
        return false
    }
}
