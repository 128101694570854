import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import GraficoMapaBrasilSetor from './graficoMapaBrasilSetor';
import API from '../../../comm/API';
import Carousel from '../../../components/carousel/carousel';
import CarouselNew from '../../../components/carouselNew/carouselNew';
import SectorCard from '../../../components/sectorCard/sectorCard';
import GraficoAtendimentoSetor from './graficoAtendimentosPorSetor';
import GraficoAtendimentosPorSegmento from './graficoAtendimentosPorSegmento';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import SectorHeaderCard from '../../../components/sectorHeaderCard/sectorHeaderCard';
import GraficoPrincipaisSetores from './graficoPrincipaisSetores';

export default class DashboardBrasilMaisSetor extends Component {

	constructor(props) {
		super(props);

		this.state = {
			setoresCards: [],
			setoresCardsL: [],
			setoresCardsD: [],
			setoresCardsLD: [],
			resumo: []
		}

		if(this.props.perfil === 'DN'){
			API.get('rest/sistema/DashboardBrasilMais/countAtendimentosSetor').then(json => {
				let dataL = [];
				let dataD = [];
				let dataLD = [];
				let setorL = json.data.lean;
				
				setorL.forEach(setor =>{
					dataL.push(this.pushSetor(setor));
				});
				json.data.digital.forEach(setor =>{
					dataD.push(this.pushSetor(setor));
				});
				json.data.ambos.forEach(setor =>{
					dataLD.push(this.pushSetor(setor));
				});
				this.setState({ setoresCardsL: dataL,setoresCardsD: dataD,setoresCardsLD: dataLD })
			}).catch(error => {
				console.log(error);
				this.props.showToast('Erro ao buscar cards do dashboard. Favor, entrar em contato com o suporte.', 'error');
			});
		}
		else{
			API.get('rest/sistema/DashboardBrasilMais/setores').then(json => {
				this.setState({ setoresCards: json.data })
			}).catch(error => {
				console.log(error);
				this.props.showToast('Erro ao buscar cards do dashboard. Favor, entrar em contato com o suporte.', 'error');
			});
		};

		API.get('rest/sistema/DashboardBrasilMais/cardsSegmentoResumo').then(json => {
			if (json.data != null) {
				this.setState({ resumo: json.data.resumo })

			}
		}).catch(error => {
			console.log(error);
			this.props.showToast('Erro ao buscar cards segmento do dashboard. Favor, entrar em contato com o suporte.', 'error');
		});

	}

	sectorIcons = [
		'./../../assets/icons/artes-cultura-esporte-e-recreacao.svg',
		'./../../assets/icons/atividades-administrativas-e-servicos-complementares.svg',
		'./../../assets/icons/comunicacao-e-informatica.svg',
		'./../../assets/icons/construcao-civil.svg',
		'./../../assets/icons/industria-de-transformacao.svg',
		'./../../assets/icons/industria-de-transporte-ferroviario-e-dutoviario.svg',
		'./../../assets/icons/industria-extrativa.svg',
		'./../../assets/icons/servicos-de-utilidade-publica.svg'];


	pushSetor = (object) =>{
		let array = [];
		array.push(object.nome_setor);
		array.push(object.quantidade_concluido);
		array.push(object.quantidade_execucao);
		array.push(object["percentual: "].toFixed(2));
		return array;

	}

	setoresCarousel() {
		let content = [];
		this.state.setoresCards.forEach((element, i) => {
			content.push(
				<div key={'carrossel-card-' + i}>
					<SectorCard
						description={element[0].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						finished={element[1].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						progress={element[2].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						productivity={element[3].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						icons={this.sectorIcons[i]} tabSector={true} valueOnly={this.props.perfil === 'DN' ? true : false} />
				</div>
			)
		});
		return content;
	}

	setoresNewCarousel(fase) {
		let content = [];
		let setores =[];
		if(fase == 'L'){
			setores = this.state.setoresCardsL;
		}
		else if(fase=='D'){
			setores = this.state.setoresCardsD;
		}else{
			setores = this.state.setoresCardsLD;
		}
		setores.forEach((element, i) => {
			content.push(
				<div key={'carrossel-card-' + i}>
					<SectorCard
						description={element[0].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						finished={element[1].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						progress={element[2].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						productivity={element[3].toLocaleString('pt-BR', { maximumFractionDigits: 2 })}
						icons={this.sectorIcons[i]} tabSector={true} valueOnly={this.props.perfil === 'DN' ? true : false} />
				</div>
			)
		});
		return content;
	}

	render() {
		if (this.props.perfil === 'DN'){
			let CarouselL =  this.setoresNewCarousel('L');
			let CarouselD =  this.setoresNewCarousel('D');
			let CarouselLD =  this.setoresNewCarousel('LD');
			return (
				<Grid container className={'dashboard-brasil-mais-setor'}>
					<Grid item container justifyContent='center' xs={12}>
						<Grid item className={'font-title-carousel-title'} xs={12} container justifyContent='center'>
							<label>
								{'ATENDIMENTOS POR SETOR INDUSTRIAL'}
							</label>
						</Grid>
						<Grid item className={'font-title-carousel-subtitle'} xs={12} container justifyContent='center'>
						<label>
							{this.props.fase == "L" ? `Fase 1: Mentoria Lean`	:
							this.props.fase == "D" ? `Fase 2: Mentoria Digital` : `Brasil Mais`	}
						</label>
						</Grid>
						{this.props.fase == 'L' && <Grid item xs={12}><CarouselNew header ={<SectorHeaderCard tabSector={true}/>} content={CarouselL} /></Grid>}
						{this.props.fase == 'D' && <Grid item xs={12}><CarouselNew header ={<SectorHeaderCard tabSector={true}/>} content={CarouselD} /></Grid>}
						{this.props.fase == 'LD' && <Grid item xs={12}><CarouselNew header ={<SectorHeaderCard tabSector={true}/>} content={CarouselLD} /></Grid>}
					</Grid>
					<Grid item xs={12} container justifyContent='center' style={{ marginTop: '60px' }}>
						<Grid item className={'font-title-carousel-title'} xs={12} container justifyContent='center'>
							<label>
								{'TOP 10: PRINCIPAIS SETORES INDUSTRIAIS NO PROGRAMA'}
							</label>
						</Grid>
						<Grid item className={'font-title-carousel-subtitle'} xs={12} container justifyContent='center'>
						<label>
							{this.props.fase == "L" ? `Fase 1: Mentoria Lean`	:
							this.props.fase == "D" ? `Fase 2: Mentoria Digital` : `Brasil Mais`	}
						</label>
						</Grid>
						<Grid item xs={12}>
							<GraficoPrincipaisSetores fase={this.props.fase}/>
						</Grid>
					</Grid>
				</Grid>
			);
		}
			
		else if (this.props.perfil === 'DR')
			return (
				<Grid container className={'dashboard-brasil-mais-setor'}>
					<Grid item xs={12}>
						<Carousel title={'Atendimentos concluídos por Setor'} content={this.setoresCarousel()} />
					</Grid>
					<Grid item xs={12} style={{ marginTop: '24px' }}>
						<Grid container spacing={3} justify={'center'}>
							<Grid item xs={12} md={6} className={'box-grafico-glacial'}>
								<GraficoAtendimentoSetor />
							</Grid>
							{ this.state.resumo.length > 0 && 
                                <Grid container xs={12} md={6} className={'dashboard-cards'} style={{ marginBottom: 0 }}>
                                    <Grid item xs={12}  style={{ paddingTop: '8px' }}>
                                        <label>Segmentos industriais com mais atendimentos</label>
                                    </Grid>
                                    <Grid item container direction={'row'} spacing={3} style={{ padding: '12px', paddingTop: '25px' }}>
                                        <Grid item xs={6}>
	                                        <DashboardCard color={'card-blue-dr'} text={this.state.resumo && this.state.resumo[0] ? this.state.resumo[0][0] : ''} value={this.state.resumo && this.state.resumo[0] ? this.state.resumo[0][1].toLocaleString('pt-BR') : 0} />
	                                    </Grid>
	                                    <Grid item xs={6}>
	                                        <DashboardCard color={'card-blue-dr'} text={this.state.resumo && this.state.resumo[1] ? this.state.resumo[1][0] : ''} value={this.state.resumo && this.state.resumo[1] ? this.state.resumo[1][1].toLocaleString('pt-BR') : 0} />
	                                    </Grid>
	                                    <Grid item xs={6}>
	                                        <DashboardCard color={'card-blue-dr'} text={this.state.resumo && this.state.resumo[2] ? this.state.resumo[2][0] : ''} value={this.state.resumo && this.state.resumo[2] ? this.state.resumo[2][1].toLocaleString('pt-BR') : 0} />
	                                    </Grid>
	                                    <Grid item xs={6}>
	                                        <DashboardCard color={'card-blue-dr'} text={this.state.resumo && this.state.resumo[3] ? this.state.resumo[3][0] : ''} value={this.state.resumo && this.state.resumo[3] ? this.state.resumo[3][1].toLocaleString('pt-BR') : 0} />
	                                    </Grid>
	                                </Grid>
                                </Grid> }
						</Grid>
					</Grid>
					<Grid item xs={12} style={{ marginTop: '24px' }}>
						<GraficoAtendimentosPorSegmento />
					</Grid>
				</Grid>);
		else if (this.props.perfil === 'UO')
			return (
				<Grid container className={'dashboard-brasil-mais-setor'}>
					<Grid item xs={12}>
						<Carousel title={`Atendimentos concluídos por Setor ${this.props.perfil === "UO" ? " na UO" : ""}`} content={this.setoresCarousel()} />
					</Grid>
					<Grid item xs={12} style={{ marginTop: '24px' }}>
						<GraficoAtendimentosPorSegmento perfil={this.props.perfil} />
					</Grid>
				</Grid>
			);
	}


}