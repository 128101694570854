import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import {createMuiTheme, MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import { Grid, Tooltip } from "@material-ui/core";
import './collapseItem.scss'
import '../colorPallete.scss';




export default class CollapseItem extends Component {
    
    constructor(props) {
        super(props);

        this.state = { expanded: this.props.isExpanded };

    }

    componentDidUpdate(prevProps) {
        if (this.props.isExpanded !== prevProps.isExpanded) {
            this.setState({
                expanded: this.props.isExpanded
            });
        }
    }

    handleChange = () => {
        this.setState({
            expanded : !this.state.expanded
        });
        // updateAtendimento(idpanel)
    };

    


    render() {
        
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);

        const collapseSgtTheme = createMuiTheme({
            typography: {
                fontFamily: [
                    'Raleway',
                    '-apple-system',
                    'BlinkMacSystemFont',
                ].join(','),
                fontWeightMedium: 500,
                body1: {
                    fontWeight: 500,
                },
                subtitle1: {
                    fontSize: 12,
                },
                button: {
                    fontStyle: 'italic',
                },
            },
        });

        return (
                <div className={'collapse'}>


                <ExpansionPanel expanded={this.state.expanded} onChange={this.handleChange} className={'box'}>
                        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={'boxChild'}
                            aria-controls="bh-content">
                            {this.props.selector}
                            <span className={!this.state.expanded ? 'title-closed' : 'title-expanded'} >{this.props.title}</span>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails className={'details'}>
                            <div className={'div-dados'} >
                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'flex-start'}>
                                    <Grid item xs={12}>
                                        <label className={'cliente-info'}>{this.props.cpfcnpj}</label>
                                    </Grid>
                                    {this.props.lean ?
                                    <> 
                                    <Grid item xs={12}>
                                    <label className={'cliente-info' }>{this.props.id}</label>
                                    </Grid>
                                    <Grid item xs={12}>
                                    <Grid container direction={'row'} justify={'flex-start'} alignItems={'flex-start'} spacing={1}
                                    className={'check-container' }>
                                        <Grid item xs={3}>
                                            <ModifiedTooltip title="A empresa tem inten&ccedil;&atilde;o de digitalizar sua(s) linha(s) de produ&ccedil;&atilde;o?" placement="top-start" arrow>
                                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1} >
                                                    <Grid item>

                                                        {this.props.checks[0] ?
                                                            <div className={'icon-ok'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-ok.svg'}
                                                                    width={'10px'}
                                                                    height={'7px'} />
                                                            </div> :
                                                            <div className={'icon-no'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-no.svg'}
                                                                    width={'6px'}
                                                                    height={'6px'} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <label className={'check-label'}>Digitalizar</label>
                                                    </Grid>
                                                </Grid>
                                            </ModifiedTooltip>                                                
                                        </Grid>
                                        <Grid item xs={5}>
                                            <ModifiedTooltip title="A empresa possui processos de manufatura discretos?" placement="top-start" arrow>
                                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1} >
                                                    <Grid item>
                                                        {this.props.checks[1] ?
                                                            <div className={'icon-ok'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-ok.svg'}
                                                                    width={'10px'}
                                                                    height={'7px'} />
                                                            </div> :
                                                            <div className={'icon-no'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-no.svg'}
                                                                    width={'6px'}
                                                                    height={'6px'} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <label className={'check-label'}>Manufatura Discreta</label>
                                                    </Grid>
                                                </Grid>
                                            </ModifiedTooltip>                                                
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ModifiedTooltip title="A empresa tem inten&ccedil;&atilde;o de realizar investimentos em digitaliza&ccedil;&atilde;o?" placement="top-start" arrow>
                                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1} >
                                                    <Grid item>
                                                        {this.props.checks[2] ?
                                                            <div className={'icon-ok'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-ok.svg'}
                                                                    width={'10px'}
                                                                    height={'7px'} />
                                                            </div> :
                                                            <div className={'icon-no'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-no.svg'}
                                                                    width={'6px'}
                                                                    height={'6px'} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <label className={'check-label'}>Investir</label>
                                                    </Grid>
                                                </Grid>
                                            </ModifiedTooltip>                                                
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ModifiedTooltip title="A empresa possui boa rede wifi na linha de produ&ccedil;&atilde;o?" placement="top-start" arrow>
                                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1} >
                                                    <Grid item>
                                                        {this.props.checks[3] ?
                                                            <div className={'icon-ok'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-ok.svg'}
                                                                    width={'10px'}
                                                                    height={'7px'} />
                                                            </div> :
                                                            <div className={'icon-no'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-no.svg'}
                                                                    width={'6px'}
                                                                    height={'6px'} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <label className={'check-label'}>Wi-fi</label>
                                                    </Grid>
                                                </Grid>
                                            </ModifiedTooltip>                                                
                                        </Grid>
                                        <Grid item xs={5}>
                                            <ModifiedTooltip title="A empresa possui maturidade tecnol&oacute;gica para digitaliza&ccedil;&atilde;o?" placement="top-start" arrow>
                                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1} >
                                                    <Grid item>
                                                        {this.props.checks[4] ?
                                                            <div className={'icon-ok'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-ok.svg'}
                                                                    width={'10px'}
                                                                    height={'7px'} />
                                                            </div> :
                                                            <div className={'icon-no'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-no.svg'}
                                                                    width={'6px'}
                                                                    height={'6px'} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <label className={'check-label'}>Maturidade Tecnol&oacute;gica</label>
                                                    </Grid>
                                                </Grid>
                                            </ModifiedTooltip>                                                
                                        </Grid>
                                        <Grid item xs={4}>
                                            <ModifiedTooltip title="A empresa tem inten&ccedil;&atilde;o de participar na FASE 2 - Mentoria Digital?" placement="top-start" arrow>
                                                <Grid container direction={'row'} justify={'flex-start'} alignItems={'center'} spacing={1} >
                                                    <Grid item>
                                                        {this.props.checks[5] ?
                                                            <div className={'icon-ok'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-ok.svg'}
                                                                    width={'10px'}
                                                                    height={'7px'} />
                                                            </div> :
                                                            <div className={'icon-no'}>
                                                                <img
                                                                    src={process.env.PUBLIC_URL + '/assets/icons/check-no.svg'}
                                                                    width={'6px'}
                                                                    height={'6px'} />
                                                            </div>
                                                        }
                                                    </Grid>
                                                    <Grid item xs>
                                                        <label className={'check-label'}>Mentoria Digital</label>
                                                    </Grid>
                                                </Grid>
                                            </ModifiedTooltip>                                                
                                        </Grid>
                                    </Grid>
                                    </Grid>
                                    </>
                                    : <></>}
                                    
                                </Grid>
                            </div>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>


                </div>
        );
    }
    
}