import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import { Grid } from "@material-ui/core"

export default class AtividadeAutossuficiencia extends Component {

    constructor(props) {
        super(props);

        let turma = this.props.turma;
        let etapa = this.props.etapa;
        let disabled = this.props.disabled;
        this.state = {
          autossuficienciaDigital: props.atividade,
        };
    };

    changeInput = (e) => {
        let autossuficienciaDigital = this.state.autossuficienciaDigital;
        let errors = this.state.errors;
        if (e && e.target && e.target.value) {
            if (e.target.id) {
              autossuficienciaDigital[e.target.id] = e.target.value;
                if (e.target.required) {
                    if (e.target.value)
                        errors[e.target.id] = '';
                    else
                        errors[e.target.id] = 'Campo obrigatório.';
                } else
                    errors[e.target.id] = '';
            }
            else if (e.target.name) {
              autossuficienciaDigital[e.target.name] = e.target.value;
            }
            this.setState({ autossuficienciaDigital: autossuficienciaDigital, errors: errors });
        }
    };

    renderBody = () => {
        let autossuficienciaDigital = this.state.autossuficienciaDigital;

        return (
            <Grid container justify={'center'} style={{ marginTop: '3%' }}>
                <Grid container justify={'flex-start'} spacing={4}>
                    <Grid item xs={6}>
                        <div className={'div-field-btn'} style={{ marginLeft: '32px'}}>
                          <FormField 
                            type={'textarea'}
                            disabled={this.props.disabled}
                            changeValue={(e) => { 
                              autossuficienciaDigital.maisInfo = e.target.value;
                              this.setState({ autossuficienciaDigital });
                            }} 
                            inputValue={autossuficienciaDigital.maisInfo}
                            label={'Descreva brevemente as ações realizadas:'}
                            placeholder="Digite aqui"
                          />
                        </div>
                    </Grid>
                    
                </Grid>
                <Grid container justify={'center'} direction={'row'} spacing={4} style={{ marginTop: '3%' }}>
                    
                   
                </Grid>
                <Grid container justify={'center'} style={{ marginTop: '3%' }} className={'linha-producao-box'}>
                    
                </Grid>
            </Grid>
        )
    }

    render() {
        return (
            <Grid container className={'atividade-linha-producao'} style={{ marginTop: '30px' }}>
                { this.renderBody() }
            </Grid>
        )
    }

}