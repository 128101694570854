export const getByKeyOrEmpty = (object, key) => {
    const result = object && object[key] ? object[key] : [];
    return JSON.parse(JSON.stringify(result));
};

export const mapAtendimentoNome = (etapa, turma) => {
    return etapa.atendimentoList.map(atendimento => {
        const finded = turma.atendimentoTurmaList.find(e => e.atendimento.id === atendimento.idAtendimento);
        return {...atendimento, clientName: finded.atendimento.cliente.nome, atendimentoStatus: finded.atendimento.atendimentoStatus.id}
    })
};

export const viewDataModels = {

    etapaTeorica: {
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Mentoria Teórica',
            data: [],
        },
        atividades: {
            treinamentoColetivo: {
                data: {},
                icon: 'treinamento-coletivo.svg',
                label: 'Treinamento coletivo virtual',
                screenAtividade: 'treinamentoColetivo',
                anexos: {
                    data: [],
                    title: 'Treinamento coletivo virtual',
                    defaultIcon: 'treinamento-coletivo-white',
                }
            },
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '4h',
        totalAtividades: 1
    },
    
    etapaAlinhamentoB:{
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Alinhamento',
            data: [],
        },
        atividades: {
            linhaDeProducaoDigital: {
                data: {},
                icon: 'linha-de-producao.svg',
                label: 'Linha de Produção',
                screenAtividade: 'linhaDeProducaoDigital',
                anexos: {
                    data: [],
                    title: 'Linha de Produção',
                    defaultIcon: '',
                }
            },
            processoProdutivoDigital: {
                data: {},
                icon: 'processo-produtivo.svg',
                label: 'Processo Produtivo',
                screenAtividade: 'processoProdutivoDigital',
                anexos: {
                    data: [],
                    title: 'Processo Produtivo',
                    defaultIcon: '',
                }
            },
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '2h',
        totalAtividades: 2
    },

    etapaPraticaB: {
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Mentoria Prática',
            data: [],
        },
        atividades: {
            artefatosInstalacaoDigital: {
                data: {},
                icon: 'artefatos-kits.svg',
                label: 'Artefatos, Kit & Instalação',
                screenAtividade: 'artefatosInstalacaoDigital',
                anexos: {
                    data: [],
                    title: 'Artefatos, Kit & Instalação',
                    defaultIcon: '',
                }
            },
            indicadoresDigital: {
                data: {},
                icon: 'indicadores-atividade.svg',
                label: 'Indicadores de Performance',
                screenAtividade: 'indicadoresDigital',
                anexos: {
                    data: [],
                    title: 'Indicadores de Performance',
                    defaultIcon: '',
                }
            },
            linhaDeProducaoDigital: {
                data: {},
                icon: 'linha-de-producao.svg',
                label: 'Linha de Produção',
                screenAtividade: 'linhaDeProducaoDigital',
                anexos: {
                    data: [],
                    title: 'Linha de Produção',
                    defaultIcon: '',
                }
            },
            processoProdutivoDigital: {
                data: {},
                icon: 'processo-produtivo.svg',
                label: 'Processo Produtivo',
                screenAtividade: 'processoProdutivoDigital',
                anexos: {
                    data: [],
                    title: 'Processo Produtivo',
                    defaultIcon: '',
                }
            },
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '16h',
        totalAtividades: 4
    },

    etapaConsultoriaB: {
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Consultoria',
            data: [],
        },
        atividades: {
            setupMINA: {
                data: {},
                icon: 'setupIOT.svg',
                label: 'Setup Plataforma IOT',
                screenAtividade: 'setupIOT',
                anexos: {
                    data: [],
                    title: 'Setup Plataforma IOT',
                    defaultIcon: 'treinamento-coletivo-white',
                }
            },
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '8h',
        totalAtividades: 1
    },

    etapaPraticaC: {
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Mentoria Prática',
            data: [],
        },
        atividades: {
            relatorioA3Digital:{
                data: {},
                icon: 'ciclo-PDCA.svg',
                label: 'Relatório A3: Ciclo PDCA',
                screenAtividade: 'relatorioA3Digital',
                anexos: {
                    data: [],
                    title: 'Relatório A3: Ciclo PDCA',
                    defaultIcon: '',
                }
            },
            indicadoresDigital: {
                data: {},
                icon: 'indicadores-atividade.svg',
                label: 'Indicadores de Performance',
                screenAtividade: 'indicadoresDigital',
                anexos: {
                    data: [],
                    title: 'Indicadores de Performance',
                    defaultIcon: '',
                }
            },
            
        },
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '12h',
        totalAtividades: 2
    },
    
    etapaConsultoriaC: {
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Consultoria',
            data: [],
        },
        atividades: {
            relatorioA3Digital:{
                data: {},
                icon: 'ciclo-PDCA.svg',
                label: 'Relatório A3: Ciclo PDCA',
                screenAtividade: 'relatorioA3Digital',
                anexos: {
                    data: [],
                    title: 'Relatório A3: Ciclo PDCA',
                    defaultIcon: '',
                }
            },
        },
        ferramentas: {
            outrasFerramentasList: []
        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '4h',
        totalAtividades: 1,     
    },

    etapaPraticaD: {
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Mentoria Prática',
            data: [],
        },
        atividades: {
            indicadoresDigital: {
                data: {},
                icon: 'indicadores-atividade.svg',
                label: 'Indicadores de Performance',
                screenAtividade: 'indicadoresDigital',
                anexos: {
                    data: [],
                    title: 'Indicadores de Performance',
                    defaultIcon: '',
                }
            },
            analiseResultadosDigital: {
                data: {},
                icon: 'analise-dos-resultados.svg',
                label: 'Análise dos Resultados',
                screenAtividade: 'analiseResultadosDigital',
                anexos: {
                    data: [],
                    title: 'Análise dos Resultados',
                    defaultIcon: '',
                }
            },
            autossuficienciaDigital: {
                data: {},
                icon: 'autossuficiencia.svg',
                label: 'Autossuficiência',
                screenAtividade: 'autossuficienciaDigital',
                anexos: {
                    data: [],
                    title: 'Autossuficiência',
                    defaultIcon: '',
                }
            },
        },
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '4h',
        totalAtividades: 3    
    },

    etapaConsultoriaD: {
        etapa: {
            etapaFinalizada: false,
        },
        encontros: {
            title: 'Consultoria',
            data: [],
        },
        atividades: {
            documentacaoDigital: {
                data: {},
                icon: 'documentacao-final.svg',
                label: 'Documentação Final',
                screenAtividade: 'documentacaoDigital',
                anexos: {
                    data: [],
                    title: 'Documentação Final',
                    defaultIcon: '',
                }
            },
            avaliacaoDigital: {
                data: {},
                icon: 'avaliacao-digital.svg',
                label: 'Avaliação do Atendimento',
                screenAtividade: 'avaliacaoDigital',
                anexos: {
                    data: [],
                    title: 'Avaliação do Atendimento',
                    defaultIcon: '',
                },
                disabled: false
            },
            resultado: {
                data: {},
                icon: 'resultados.svg',
                label: 'Resultado Geral',
                screenAtividade: 'resultado',
                anexos: {
                    data: [],
                    title: 'Resultado Geral',
                    defaultIcon: '',
                }
            },

        },
        //** Dados de retorno (selecionados) */
        selected: {
            atividade: {},
            visita: {}
        },
        horas: '4h',
        totalAtividades: 3 
    },

};