import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import highchartsMore from "highcharts/highcharts-more.js";
import HighchartsReact from 'highcharts-react-official';
import API from '../../../comm/API';

highchartsMore(Highcharts);

export default class GraficoAtendimentoSetor extends Component {

	setores = {
		'0': 'ARTES, CULTURA, ESPORTE E RECREAÇÃO',
		'1': 'ATIVIDADES ADMINISTRATIVAS E SERVIÇOS COMPLEMENTARES',
		'2': 'COMUNICAÇÃO E INFORMÁTICA',
		'3': 'CONSTRUÇÃO CIVIL',
		'4': 'INDÚSTRIA DE TRANSFORMAÇÃO',
		'5': 'INDÚSTRIA DE TRANSPORTE FERROVIÁRIO E DUTOVIÁRIO',
		'6': 'INDÚSTRIA EXTRATIVA',
		'7': 'SERVIÇOS DE UTILIDADE PÚBLICA'
		
	};

    constructor(props) {
		super(props);
		this.state = {
			data: []
		}
		this.fillGrafico();
	};

	fillGrafico = async () => {
        await API.get('rest/sistema/DashboardBrasilMais/setorAtendimentosConcluidos').then(async res => {
			if (res.data != null) {

				let series = res.data;
				series = series.map(serie => serie[1])
				series = series.splice(0, 8)
				await this.setState({ data: series });
			}
        }).catch(error => {
            console.log(error);
        });
    };
    
    create = () => {
		
		const setores = this.setores;
		return {
			chart: {
				polar: true,
				style: {
                    fontFamily: 'Raleway'
				},
				width: 400,
				height: 400
			},
			title: {
				text: ''
			},
			legend: { 
				enabled: false 
			},
			pane: {
				size: 317,
				startAngle: 0,
				endAngle: 360
			},
			xAxis: {
				tickInterval: 1,
				min: 1,
				max: 9,
				gridLineColor: "#707070",
				lineColor: "#707070",
				labels: {
					style: {
						fontSize: 13
					}
				}
			},
			yAxis: {
				min: 0,
				labels: {
					enabled: false
				},
				gridLineColor: "#707070"
			},
			tooltip: {
                formatter: function() {
                    let tooltip = `<p style="font: bold 12px Raleway; color: #707070">${this.point.category === 0 ? '8' : this.point.category}. ${setores[this.point.category]}</p><br>` +
                    `<p style="font: 12px Raleway; font-weight: 500; color: #707070">Total: ${this.point.y}</p><br>`;
                    return tooltip;
                },
				outside: false,
				borderWidth: 1,
				borderColor: "#B4B8C0",
				shadow: false,
				followPointer: true,
				backgroundColor: "rgba(255,255,255)"
			},
			plotOptions: {
				series: {
					pointStart: 1,
					pointInterval: 1,
					states: {
					  	hover: {
							enabled: true
					  	}
					}, 
					marker: {
						enabled: false
					},
				},
				area: {
				  color: '#C5DBE4',
				  fillOpacity: 0.70,
				  lineWidth: 1.5,
				  
				}
			},
			series: [{
				type: 'area',
				data: this.state.data
			}],
			credits: {
				enabled: false
			},
			exporting: false
		};
    }
    
    render() {
		return (
			<Grid container className={'border-cinza'}>
				<Grid container item xs={12} justify={'center'}> 
					<label>Atendimentos concluídos por setor</label>
				</Grid>
				<Grid container item xs={12} justify={'center'}>
					<HighchartsReact
						highcharts={Highcharts}
						options={this.create()}
					/>
				</Grid >
			</Grid>
		);
	};

}