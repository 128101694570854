import React, {Component} from 'react'
import './treinamento.scss'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table'
import {Grid} from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API'
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';

const titles = [
    { id: '0', canOrder: true, label: 'Título' },
    { id: '1', canOrder: true, label: 'Área de Conhecimento' },
    { id: '2', canOrder: true, label: 'Data Início' },
    { id: '3', canOrder: false, label: 'Duração' },
    { id: '4', canOrder: true,  label: 'Unidade' },
    { id: '5', canOrder: false,  label: ''}]

export default class Treinamento extends Component {

    isUO = false;
    constructor(props) {
        super(props);
        this.state = {
            treinamentoList: [], 
            emptyList: 'seeResults',
            lines: [],
            filter : new URLSearchParams(),
            buscaLivreTreinamento : ''
        }
        this.getFiltro()
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    getFiltro = () => {
        var filterAux = this.state.filter;
        filterAux.append('ordenarPor', 'titulo');
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            this.isUO = true;
            filterAux.append("unidade.id", this.props.usuarioConectado.colaborador.unidade.id);
        } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR'){
            filterAux.append("unidade.unidadePai.id", this.props.usuarioConectado.unidade.id);
        }
        this.setState({filter: filterAux});
    }
    
    changeBuscaLivreTreinamento = (e) => {
        var filterAux = this.state.filter;        
        if (e.target.value != null) {
            this.setState({buscaLivreTreinamento: e.target.value});
            filterAux.delete("buscaLivreTreinamento");
            filterAux.append("buscaLivreTreinamento", e.target.value);
            if (e.target.value === ''){
                filterAux.delete("buscaLivreTreinamento");
            }
        } else {
            filterAux.delete("buscaLivreTreinamento");
        }
        this.setState({filter: filterAux});
    }
    formatData(date) {
        if (date != null) {
            if (typeof (date) !== 'string') {
                let dataList = date.toLocaleDateString().split('/');
                return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
            } else {
                if (date.length > 10) {
                    var dataList = date.split('T');;
                    return dataList[0];
                } else {
                    return date;
                }
            }
        }
    }

    getTreinamentos = () => {
        this.showLoading();
        this.setState({lines: []});
        API.get('rest/gestao/Treinamento', {
            params: this.state.filter
        }).then(res => {
            this.setState({treinamentoList: res.data});
            var lines = [];

            for(var i = 0; i < this.state.treinamentoList.length ; i ++){
                var treinamentoAux = [];
                treinamentoAux.push(`${this.state.treinamentoList[i].titulo.toUpperCase()}`);
                treinamentoAux.push(`${this.state.treinamentoList[i].areaConhecimento}`);
                treinamentoAux.push(`${this.formatData(this.state.treinamentoList[i].dataInicio)}`);
                treinamentoAux.push(`${this.state.treinamentoList[i].duration}`);
                treinamentoAux.push(`${this.state.treinamentoList[i].unidade != null ? this.state.treinamentoList[i].unidade.abreviacao : ''}`);
                treinamentoAux.push( 
                    <div style={{ display: 'flex' }}>
                        <Link to={`/cad/pessoa/treinamento/${this.state.treinamentoList[i].id}`}>
                            <GenericButton color={'transparent'} subClass={'icon-button'} 
                            icon={'pen.svg'}/>
                        </Link>
                    </div>);

                lines.push(treinamentoAux);                  
            }
            var filterAux = this.state.filter;
            filterAux.buscaLivreTreinamento = '';
            this.setState({ lines: lines });
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao carregar lista de treinamentos. Favor, entrar em contato com o suporte.', 'error');
        });   
    }


    render() {
        return (
            <Page icon={'doc.svg'} label={'Gerenciar Treinamentos'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-treinamentos'}>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={(this.isUO ? 6 : 5)} xl={(this.isUO ? 5 : 4)} className={'grid-left-button'}>
                                {this.isUO ? 
                                    <Link to={'/cad/pessoa/treinamento/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Novo Treinamento'} subClass={'basic-button'}/>
                                    </Link>
                                : null }
                                <FormField type={'text'} pressEnter={this.getTreinamentos} placeholder={'busque por título, descrição ou área'} 
                                button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={this.getTreinamentos}/>}
                                inputValue={this.state.buscaLivreTreinamento} 
                                changeValue={this.changeBuscaLivreTreinamento}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.lines} titles={titles} initialOrder={'0'}/>
                        <EmptyList type={this.state.emptyList} seeAll={this.getTreinamentos}/>  
                    </Grid>
                </Grid>
            </Page>
        );
    }
}