import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import Icon from '@material-ui/core/Icon';
import DownloadFiles from '../../comm/DownloadFiles';

export default class EvidenciaDigital extends Component {

    evidencias = [];
    evidenciaElementList = [];

    constructor(props) {
        super(props);
        this.state = {};
        this.getAnexos();
    };

    getAnexos = async () => {
        this.evidencias = [];
        this.evidenciaElementList = [];
        if (this.props.turma != null && this.evidenciaElementList.length === 0) {
            
            await this.getEvidencias(this.props.turma.etapaTeoricaList[0], ['encontroList', 'anexoEncontroList']);
            await this.getEvidencias(this.props.turma.etapaTeoricaList[0], ['treinamentoColetivo', 'anexoAtividadeList']);
            await this.getEvidencias(this.props.turma.etapaTeoricaList[1], ['encontroList', 'anexoVisitanexoEncontroListaList']);
            await this.getEvidencias(this.props.turma.etapaTeoricaList[1], ['treinamentoColetivo', 'anexoAtividadeList']);
            await this.getEvidencias(this.props.turma.etapaTeoricaList[2], ['encontroList', 'anexoEncontroList']);
            await this.getEvidencias(this.props.turma.etapaTeoricaList[2], ['treinamentoColetivo', 'anexoAtividadeList']);        
            await this.getEvidencias(this.props.turma.etapaAlinhamentoB,['atendimentoList','encontroList', 'anexoEncontroList']);
            await this.getEvidencias(this.props.turma.etapaPraticaB,['atendimentoList','encontroList', 'anexoEncontroList']);       
            await this.getEvidencias(this.props.turma.etapaPraticaB,['atendimentoList','linhaDeProducaoDigital', 'anexoLinhaProducaoList']);
            await this.getEvidencias(this.props.turma.etapaPraticaB,['atendimentoList','processoProdutivoDigital', 'anexoProcessoProdutivoList']);
            await this.getEvidencias(this.props.turma.etapaPraticaB,['atendimentoList','artefatosInstalacaoDigital', 'anexoArtefatosInstalacaoList']);
            await this.getEvidencias(this.props.turma.etapaPraticaC,['atendimentoList','encontroList', 'anexoEncontroList']);   
            await this.getEvidencias(this.props.turma.etapaPraticaC,['atendimentoList','indicadoresDigital','maturidadeDigital', 'anexoMaturidadeDigitalList']);
            await this.getEvidencias(this.props.turma.etapaPraticaC,['atendimentoList','indicadoresDigital','anexoIndicadoresList']);
            await this.getEvidencias(this.props.turma.etapaPraticaC,['atendimentoList','indicadoresDigital','oeeDigital','anexoOEEList']);
            await this.getEvidencias(this.props.turma.etapaConsultoriaB,['atendimentoList','encontroList', 'anexoEncontroList']);   
            await this.getEvidencias(this.props.turma.etapaConsultoriaB,['atendimentoList','setupMINA', 'anexoSetupMINAList']);
            await this.getEvidencias(this.props.turma.etapaConsultoriaC,['atendimentoList','encontroList', 'anexoEncontroList']);   
            await this.getEvidencias(this.props.turma.etapaConsultoriaC,['atendimentoList','relatorioA3Digital','temaA3', 'itemA3List','anexoItemA3List']);
            await this.getEvidencias(this.props.turma.etapaConsultoriaC,['atendimentoList','relatorioA3Digital','temaA3', 'contramedidasA3Digital','anexoContramedidasA3List']);
            await this.getEvidencias(this.props.turma.etapaConsultoriaC,['atendimentoList','relatorioA3Digital','temaA3', 'acompanhamentoA3Digital','anexoAcompanhamentoA3List']);
            await this.getEvidencias(this.props.turma.etapaConsultoriaC,['atendimentoList','relatorioA3Digital','temaA3', 'conclusaoA3Digital','anexoMelhoriaA3List']);
            await this.getEvidencias(this.props.turma.etapaConsultoriaC,['atendimentoList','ferramentaConsultoriaList','anexoFerramentaList']);
            await this.getEvidencias(this.props.turma.etapaPraticaD,['atendimentoList','encontroList', 'anexoEncontroList']);   
            await this.getEvidencias(this.props.turma.etapaPraticaD,['atendimentoList','autossuficienciaDigital', 'anexoAutossuficienciaList']);
            await this.getEvidencias(this.props.turma.etapaConsultoriaD,['atendimentoList','encontroList', 'anexoEncontroList']);   
            await this.getEvidencias(this.props.turma.etapaConsultoriaD,['atendimentoList','documentacaoDigital', 'anexoDocumentacaoList']);
            await this.convertAnexos();
        }
    };

    convertAnexos = () => {
        this.evidenciaElementList = this.evidencias.map(
            (anexo, index) => (
                <Chip key={index}
                    label={<label style={{ display: 'flex', alignItems: 'center', paddingRight: '10px' }}>
                        <Icon className={'attach'} style={{ marginRight: '5px' }} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>
                        <p>{anexo.nomeArquivo}</p></label> } />
        ));
        this.setState(this.state);
    };

    getEvidencias = (elmt, array) => {
        if (elmt) {
            if (array.length === 0) {
                if (elmt.id != null) this.evidencias = this.evidencias.concat(elmt);
                else if (elmt[0] != null && elmt[0].id != null) this.evidencias = this.evidencias.concat(elmt[0]);
            } else if (!Array.isArray(elmt)) {
                let nameProp = array[0];
                array.splice(0, 1)
                if (Array.isArray(elmt[nameProp]) && array.length === 0)
                    elmt[nameProp].forEach(element => {
                        this.getEvidencias(element, array);
                    });
                else this.getEvidencias(elmt[nameProp], array);
            } else if (array) {
                let nameProp = array[0];
                array.splice(0, 1)
                elmt.forEach((element, index) => {
                    if (element.idAtendimento == null || (element.idAtendimento != null && element.idAtendimento === this.props.atendimento.id)) {
                        if (Array.isArray(element[nameProp])) 
                            element[nameProp].forEach((aux, i) => {
                                this.getEvidencias(aux, Object.assign([], array));
                            });                        
                        else this.getEvidencias(element[nameProp], Object.assign([], array));
                    }
                });
            }
        }
    };    

    render() {
        return (
            <Grid item xs={12}>
                <div className={'greyDiv'}>
                    <h2>Evidências em anexos</h2>
                    { this.evidenciaElementList.length === 0 ?
                        <p>Este atendimento ainda não possui evidências.</p> :
                        <Grid container spacing={1}>
                            { this.evidenciaElementList }
                        </Grid> }
                </div>
            </Grid>
        )
    }
}