import React, { Component } from 'react';
import MapaBrasil from '../../../components/mapaBrasil/mapaBrasil';
import API from '../../../comm/API';
import { Grid } from '@material-ui/core';

export default class GraficoMapaBrasilSetor extends Component {

    legenda = {
		title: '',
		values: [
			['#077CDC', '1. Construção civil'],
			['#00B1E8', '2. Indústria extrativa'],
			['#73D4F2', '3. Indústria de transformação'],
			['#CCEFFB', '4. Comunicação e Informática'],
			['#7DAE59', '5. Atividades administrativas e serviços complementares'],
			['#B7EE8E', '6. Artes, cultura, esporte e recreação'],
			['#E9B03D', '7. Indústria de transporte ferroviário e dutoviário'],
			['#E9784D', '8. Serviço de utilidade pública'],
			['#D5D9E1', 'Sem dados']
		]
	};

	tooltipBox = (numAtdSetor, setor, total, estado) => {
		let font = '12px Raleway';
		return(
			`onMouseMove="javascript:(function() { 
				let tooltip = document.getElementById('tooltip');
				tooltip.innerHTML = '';
				let setor = document.createElement('p')
				setor.style.font = '${'bold ' + font}';
				setor.style.margin = 0;
				setor.innerHTML = '${setor}';
				let estado = document.createElement('p');
				estado.style.font = '${font}';
				estado.style.margin = 0;
				estado.innerHTML = '${estado}: ${numAtdSetor} atendimentos';
				let total = document.createElement('p')
				total.style.font = '${font}';
				total.style.margin = 0;
				total.innerHTML = 'Total: ${total}';
				tooltip.appendChild(setor);
				tooltip.appendChild(estado);
				tooltip.appendChild(total);
				tooltip.style.display = 'block';
				tooltip.style.left = evt.pageX - 30 + 'px';
				tooltip.style.top = evt.pageY  - 20 + 'px';
			})()"`
		)
	};

	switch = {
		left: 'Atendimento'
	};

	constructor(props) {
		super(props);
		this.state = { 
			dados: { atendimento: {'AC': ['#D5D9E1', 0, '-', 0], 'AL': ['#D5D9E1', 0, '-', 0], 'AM': ['#D5D9E1', 0, '-', 0], 'AP': ['#D5D9E1', 0, '-', 0], 'BA': ['#D5D9E1', 0, '-', 0], 
				'CE': ['#D5D9E1', 0, '-', 0], 'DF': ['#D5D9E1', 0, '-', 0], 'ES': ['#D5D9E1', 0, '-', 0], 'EST': ['#D5D9E1', 0, '-', 0], 'GO': ['#D5D9E1', 0, '-', 0], 
				'MA': ['#D5D9E1', 0, '-', 0], 'MG': ['#D5D9E1', 0, '-', 0], 'MS': ['#D5D9E1', 0, '-', 0], 'MT': ['#D5D9E1', 0, '-', 0], 'PA': ['#D5D9E1', 0, '-', 0], 
				'PB': ['#D5D9E1', 0, '-', 0], 'PE': ['#D5D9E1', 0, '-', 0], 'PI': ['#D5D9E1', 0, '-', 0], 'PR': ['#D5D9E1', 0, '-', 0], 'RJ': ['#D5D9E1', 0, '-', 0], 
				'RN': ['#D5D9E1', 0, '-', 0], 'RO': ['#D5D9E1', 0, '-', 0], 'RR': ['#D5D9E1', 0, '-', 0], 'RS': ['#D5D9E1', 0, '-', 0], 'SC': ['#D5D9E1', 0, '-', 0], 
				'SE': ['#D5D9E1', 0, '-', 0], 'SP': ['#D5D9E1', 0, '-', 0], 'TO': ['#D5D9E1', 0, '-', 0]}}
		};

		API.get('rest/sistema/DashboardBrasilMais/setorMapa').then(res => {
			let list = {};
			if (res.data != null && res.data.atendimento != null) {
				res.data.atendimento.forEach(aux => {
					list[aux[0]] = [aux[1], aux[2], aux[3], aux[4]];
				});
				this.setState({ dados: {atendimento: list} });
			}
        }).catch(error => {
            console.log(error);
            this.showToast('Erro ao buscar mapa do dashboard. Favor, entrar em contato com o suporte.', 'error');
        });
	};

	render() {
		return (
			<Grid container justify={'center'} className={'border-cinza'}> 		
				<MapaBrasil dados={this.state.dados} legenda={this.legenda} switch={this.switch} isSetor={true} tooltip={this.tooltipBox} disabledSwitch={true} title={'Setor mais atendido no estado'} />
			</Grid>
		);
	};
}