import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBar from '../../../components/graphBar/graphBar';

export default class GraficoPayback extends Component {

    estados = { 
        'AC': 'Acre', 'AL': 'Alagoas', 'AM': 'Amazonas', 'AP': 'Amapá', 'BA': 'Bahia', 'CE': 'Ceará',
        'DF': 'Distrito Federal', 'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão', 'MG': 'Minas Gerais',
        'MS': 'Mato Grosso do Sul', 'MT': 'Mato Grosso', 'PA': 'Pará', 'PB': 'Paraíba', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'PR': 'Paraná', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte', 'RO': 'Rondônia', 
        'RR': 'Roraima', 'RS': 'Rio Grande do Sul', 'SC': 'Santa Catarina', 'SE': 'Sergipe', 'SP': 'São Paulo', 'TO': 'Tocantins'
    }

    tooltip = {
        formatter: function() {
            let yValue = this.point.y;
            let category = !isNaN(this.point.category) ? this.point.category - 1 : this.point.category;
            return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.series.chart.options.fullCategories[category]}: ${yValue.toLocaleString('pt-BR')} meses</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    }
    
    legend = { enabled: false };

    plotOptions = {
        series: {
            states: {
                inactive: {
                  opacity: 1
                }
              },
              pointPadding: 0.25,
        },
        column: {
            stacking: 'normal',
            borderColor: null,
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            switch: [
                {
                    label: 'Estado', 
                    title: 'Payback por estado', 
                    tooltip: this.tooltip, 
                    categories: [], 
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoEstado,
                    tickInterval: 100,
                    legend: this.legend,
                    height: 250,
                    plotOptions: this.plotOptions
                },
                {
                    label: 'Segmento industrial',
                    title: 'Payback por segmento industrial',
                    tooltip: this.tooltip,
                    categories: [], 
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoSegmento,
                    tickInterval: 100,
                    legend: this.legend,
                    height: 250,
                    plotOptions: this.plotOptions
                }
            ]
        };
    }

    fillGraficoEstado = async (dataInicio, dataTermino) => {
        let series = [];
        let resumeInfos = {};
        let categories = Object.keys(this.estados);
        let fullCategories = this.estados;
        await API.get(`rest/sistema/DashboardBrasilMais/indicadoresPayback?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isEstado=${true}`).then(async res => {
            let dataEstado = [];
            if (res.data != null && res.data.payback != null) {
                let copyData = res.data.payback.slice();
                let resumeArray = copyData.sort((i, j) => i[1] - j[1]).slice(copyData.length - 3, copyData.length).reverse();
                resumeInfos = {
                    title: 'Estados com maior média de payback',
                    data: [
                        { title: fullCategories[resumeArray[0][0]], value: resumeArray[0][1], suffix: ' meses' }, 
                        { title: fullCategories[resumeArray[1][0]], value: resumeArray[1][1], suffix: ' meses' }, 
                        { title: fullCategories[resumeArray[2][0]], value: resumeArray[2][1], suffix: ' meses' }
                    ]
                };
                await res.data.payback.forEach(estado => {
                    dataEstado.push(estado[1]);
                });
                series = [{ data: dataEstado, color: '#00B1E8' }];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, fullCategories, resumeInfos];
    }

    fillGraficoSegmento = async (dataInicio, dataTermino) => {
        let series = [];
        let resumeInfos = {};
        let categories = [];
        let fullCategories = [];
        await API.get(`rest/sistema/DashboardBrasilMais/indicadoresPayback?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isEstado=${false}`).then(async res => {
            let dataSegmento = [];
            if (res.data != null && res.data.payback != null) {
                let copyData = res.data.payback.slice();
                let resumeArray = copyData.sort((i, j) => i[1] - j[1]).slice(copyData.length - 3, copyData.length).reverse();
                resumeInfos = {
                    title: 'Segmentos com maior média de payback',
                    data: [
                        { title: resumeArray[0][0], value: resumeArray[0][1], suffix: ' meses' }, 
                        { title: resumeArray[1][0], value: resumeArray[1][1], suffix: ' meses' }, 
                        { title: resumeArray[2][0], value: resumeArray[2][1], suffix: ' meses' }
                    ]
                };
                await res.data.payback.forEach((segmento, index) => {
                    categories.push(index + 1);
                    fullCategories.push(segmento[0]);
                    dataSegmento.push(segmento[1]);
                });
                series = [{ data: dataSegmento, color: '#00B1E8' }];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, fullCategories, resumeInfos];
    }
   
    render() {
        return (
            <Grid container justify={'center'}>
                <GraphBar switch={this.state.switch} disabledSwitch={false} hasFilter={true} init={'left'} />
            </Grid>
        );
    }

}