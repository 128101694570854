import React, {Component} from 'react';
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table';
import SimpleModal from '../../components/modal/modal';
import TotalsBlock from '../../components/totalsBlock/totalsBlock';
import {Grid} from '@material-ui/core';
import Page from '../../components/page/page';
import API from '../../comm/API';
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';
import './visita.scss';

export default class Visita extends Component {
    
    isUO = false;
    titles = [
        { id: '0', canOrder: true, label: 'Nº da ação' },
        { id: '1', canOrder: true, label: 'Empresa prospectada' },
        { id: '2', canOrder: true, label: 'Data de início' },
        { id: '3', canOrder: true, label: 'Data de término' },
        { id: '4', canOrder: true,  label: 'Localização' },
        { id: '5', canOrder: true,  label: 'Status' },
        { id: '6', canOrder: true,  label: 'Atendimento vinculado' },
        { id: '7', canOrder: false,  label: ''},
        { id: '8', canOrder: false,  label: ''}
    ];
    modalDetalhe = false;
    visitaDetalhe = {
        id: 0,
        isRecebida: false,
        dataInicio: new Date(),
        dataConclusao: new Date(),
        cidade: '',
        estado: '',
        pais: '',
        produtoNacional: {
            descricao: ''
        },
        cliente: {
            razaoSocial: ''
        },
        visitaColaboradorList: [],
        descricao: '',
        atendimento: {
            id: '',
            titulo: ''
        }
    }
    isDelete= false


    constructor(props) {
        super(props);
        let filterAux = new URLSearchParams();
        filterAux.append('ordenarPor', 'id' );
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            this.isUO = true;
            filterAux.append('unidade.id', this.props.usuarioConectado.colaborador.unidade.id);
        } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR'){
            filterAux.append('unidade.unidadePai.id', this.props.usuarioConectado.unidade.id);
        }
        this.state = {
            visitaList: [], 
            emptyList: 'seeResults',
            lines: [],
            filter: filterAux,
            buscaLivreVisita: '',
            totals: {
                acoesRealizadas: 0,
                acoesRecebidas: 0,
                atendimentosVinculados: 0,
                empresasProspectadas: 0
            },
            visitaList: [],
            remover: null,
        };
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };
    
    changeBuscaLivreVisita = (e) => {
        var filterAux = this.state.filter;        
        if (e.target.value !== undefined && e.target.value !== '') {
            this.setState({buscaLivreVisita: e.target.value});
            filterAux.delete('buscaLivreVisita');
            filterAux.append('buscaLivreVisita', e.target.value)
        } else {
            filterAux.delete('buscaLivreVisita');
        }
        this.setState({filter: filterAux});
    };

    handleDeleteVisita = (visita) => {
        this.showLoading();
        API.delete(`rest/gestao/Visita/${visita.id}`)
            .then(res => {
                this.getVisitas();
                this.setState({ remover: null });
                this.changeModal('modalDetalhe', false);
                this.showToast('Ação de prospecção excluída com sucesso!', 'success');
            })
            .catch(error => {
                this.setState({ remover: null });
                this.closeLoading();
                this.showToast('Erro ao deletar uma ação de prospecção. Por favor, entre em contato com o suporte.', 'error');
            });
    }

    formatDates = (date) => new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date(date));

    getTotalsCount = () => {
        this.showLoading();

        API.get('rest/gestao/Visita/countTotals', {
            params: this.state.filter
        }).then(res => {
            const totals = {
                acoesRecebidas: res.data.acoesRecebidas,
                acoesRealizadas: res.data.acoesRealizadas,
                atendimentosVinculados: res.data.atendimentosVinculados || 0, // Retirar essa condicional quando for implementado no back
                empresasProspectadas: res.data.empresasProspectadas,
            };

            this.setState({totals});
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao realizar GET de countTotals. Por favor, entre em contato com o suporte.', 'error');
        });;
    }

    getVisitas = () => {
        this.showLoading();
        this.setState({lines: []});

        API.get('rest/gestao/Visita', {
            params: this.state.filter
        }).then(res => {
            let visitaList = res.data;

            const lines = visitaList.reverse().map(visita => {
                if (!visita.cliente) {
                    visita.cliente = {razaoSocial: 'Não possui empresa prospectada'};
                }

                if (!visita.produtoNacional) {
                    visita.produtoNacional = {descricao: ''};
                }

                if (!visita.visitaColaboradorList) {
                    visita.visitaColaboradorList = [];
                }


                const status = 
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img style={{ display: 'block' }}
                            src={`${process.env.PUBLIC_URL}/assets/icons/${
                                visita.isRecebida ? 'arrow-down.svg' : 'arrow-up.svg'
                            }`}
                            width={14}
                            height={14}
                            style={{ marginRight: 4 }}
                            alt="ícone" 
                        /> 
                        <span>{visita.isRecebida ? 'Recebida' : 'Realizada'}</span>
                    </div>

                const atendimento = 
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img style={{ display: 'block' }}
                                    src={`${process.env.PUBLIC_URL}/assets/icons/${
                                        visita.atendimento ? 'check.svg' : 'x.svg'
                                    }`}
                                    width={12}
                                    height={12}
                                    style={{ marginRight: 8 }}
                                    alt="ícone" 
                                /> 
                                <span>{visita.atendimento ? 'Sim' : 'Não'}</span>
                            </div>

                const editIcon = 
                        <div style={{ display: 'flex' }}>
                            <Link to={`/cad/pessoa/visita/${visita.id}`}>
                                <GenericButton 
                                    color={'transparent'} 
                                    subClass={'icon-button'} 
                                    icon={'pen.svg'}/>
                            </Link>
                        </div>

                const deleteIcon = 
                        <div style={{ display: 'flex' }}>
                            <GenericButton 
                                color={'transparent'} 
                                subClass={'icon-button'} 
                                icon={'delete.svg'}
                                click={() => {
                                    this.isDelete = true;
                                }}
                               
                            />
                        </div>

                return [
                    visita.id,
                    visita.cliente.razaoSocial.length > 0 ? visita.cliente.razaoSocial : visita.cliente.nome, 
                    `${this.formatDates(visita.dataInicio)}`,
                    `${this.formatDates(visita.dataConclusao)}`,
                    `${visita.cidade}-${visita.estado}`,
                    status,
                    atendimento,
                    editIcon,
                    deleteIcon
                ];
            })

            this.getTotalsCount();

            this.setState({ visitaList });
            this.setState({lines: lines});
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao realizar GET de visitas. Por favor, entre em contato com o suporte.', 'error');
        });   
    };


    changeModal(modal, status) {
        this[modal] = status;
        this.setState(this.state);
    }

    getDetalhesModal = (id) => {
        this.visitaDetalhe = this.state.visitaList.find(visita => visita.id === id);

        if (!this.visitaDetalhe.atendimento) {
            this.visitaDetalhe.atendimento = { titulo: '', id: '' };
        }

    }

    render() {
        return (
            <Page icon={'doc.svg'} label={'Prospecção'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-visitas'}>
                    <Grid item xs={12} className={'pesquisa'} >
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} sm={(this.isUO ? 6 : 5)} xl={(this.isUO ? 5 : 4)} className={'search-visita'}>
                                {this.isUO ? 
                                    <Link to={'/cad/pessoa/visita/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Ação'} subClass={'basic-button'}/>
                                    </Link>
                                : null }
                                <FormField type={'text'} label={''} placeholder={'busque pelo nome da ação de prospecção'} 
                                    button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={this.getVisitas}/>}
                                    inputValue={this.state.filter.buscaLivreEvento} 
                                    changeValue={this.changeBuscaLivreVisita}
                                    pressEnter={this.getVisitas} />
                            </Grid>
                        </Grid>
                        <TotalsBlock>
                            <label>Ações realizadas: <b>{this.state.totals.acoesRealizadas}</b></label>
                            <label>Ações recebidas: <b>{this.state.totals.acoesRecebidas}</b></label>
                            <label>Atendimentos vinculados: <b>{this.state.totals.atendimentosVinculados}</b></label>
                            <label>Entidades prospectados: <b>{this.state.totals.empresasProspectadas}</b></label>
                        </TotalsBlock>
                    </Grid>
                    <Grid item xs={12}>
                        <Table 
                            lines={this.state.lines} 
                            titles={this.titles} 
                            initialOrder={''} 
                            action={(id) => {
                                if (this.isDelete) {
                                    this.getDetalhesModal(id);
                                    this.setState({ remover: this.visitaDetalhe });
                                    this.isDelete = false;
                                } else {
                                    this.getDetalhesModal(id);
                                    this.changeModal('modalDetalhe', true);
                                    this.isDelete = false;
                                }
                            }} 
                            actionParamer={'0'}
                        />
                        <EmptyList type={this.state.emptyList} seeAll={this.getVisitas}/>    
                    </Grid>

                    <SimpleModal
                        isOpen={this.modalDetalhe}
                        handleClose={() => this.changeModal('modalDetalhe', false)}
                        type={'info'}
                        width={'781px'}>
                        <div className={'modal-detahe-visita modal'}>
                            <h2>Ação de prospecção</h2>
                            <Grid 
                                container 
                                className={'modal-detahe-visita-header'}  
                                direction="row"
                            >
                                <Grid container style={{ display: 'flex', alignItems: 'center' }}>
                                    <Grid item xs={1}>
                                        <strong>
                                            <p className={'font-blue'}>{this.visitaDetalhe.id}</p>
                                        </strong>
                                    </Grid>
                                    <Grid item xs={11}>
                                        <img style={{ display: 'block' }}
                                            src={`${process.env.PUBLIC_URL}/assets/icons/${
                                                this.visitaDetalhe.isRecebida ? 'arrow-down.svg' : 'arrow-up.svg'
                                            }`}
                                            width={12}
                                            height={12}
                                            style={{ marginRight: 8 }}
                                            alt="ícone" 
                                        /> 
                                        <span>{this.visitaDetalhe.isRecebida ? 'Recebida' : 'Realizada'}</span>
                                    </Grid>   
                                </Grid>
                            </Grid>

                            <Grid container direction={'column'}>

                                <Grid container direction={'row'}>
                                    <Grid item xs={4}>
                                        <p className="margin-item-modal-detalhes">
                                            <strong>
                                                Datas da ação
                                            </strong>
                                        </p> 
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="margin-item-modal-detalhes">Início: {this.formatDates(this.visitaDetalhe.dataInicio)}    Término: {this.formatDates(this.visitaDetalhe.dataConclusao)}</p>
                                    </Grid>
                                </Grid>

                                <Grid container direction={'row'}>
                                    <Grid item xs={4}>
                                        <strong>
                                            <p className="margin-item-modal-detalhes">Localização</p> 
                                        </strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="margin-item-modal-detalhes">{this.visitaDetalhe.cidade} - {this.visitaDetalhe.estado}, {this.visitaDetalhe.pais}</p>
                                    </Grid>
                                </Grid>


                                <Grid container  direction={'row'}>
                                    <Grid item xs={4}>
                                        <strong>
                                            <p className="margin-item-modal-detalhes">Empresa prospectada</p> 
                                        </strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="margin-item-modal-detalhes">{this.visitaDetalhe.cliente.nome || 'Não possui empresa prospectada'}</p>
                                    </Grid>
                                </Grid>


                                <Grid container  direction={'row'}>
                                    <Grid item xs={4}>
                                        <strong>
                                            <p className="margin-item-modal-detalhes">Produto Nacional</p> 
                                        </strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="margin-item-modal-detalhes">{this.visitaDetalhe.produtoNacional.descricao || 'Não possui produto nacional'}</p>
                                    </Grid>
                                </Grid>

                                <Grid container direction={'row'}>
                                    <Grid item xs={4}>
                                        <strong>
                                            <p className="margin-item-modal-detalhes">Participantes</p> 
                                        </strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="margin-item-modal-detalhes">{this.visitaDetalhe.visitaColaboradorList.map((visitaColaborador, index) => {
                                            if (!visitaColaborador.colaborador) {
                                                visitaColaborador.colaborador = { nome: '' };
                                            }
                                            
                                            const lastItem = this.visitaDetalhe.visitaColaboradorList.length - 1;

                                            return `
                                                ${visitaColaborador.colaborador.nome}${
                                                    visitaColaborador.colaborador === this.visitaDetalhe.visitaColaboradorList[lastItem].colaborador ? '' : ', '
                                                }`;
                                        })}</p>
                                    </Grid>
                                </Grid>

                                <Grid container direction={'row'}>
                                    <Grid item xs={4}>
                                        <strong>
                                            <p className="margin-item-modal-detalhes">Descrição</p> 
                                        </strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="margin-item-modal-detalhes">{this.visitaDetalhe.descricao}</p>
                                    </Grid>
                                </Grid>

                                <Grid container direction={'row'}>
                                    <Grid item xs={4}>
                                        <strong>
                                            <p className="margin-item-modal-detalhes">Atendimento vinculado</p> 
                                        </strong>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <p className="margin-item-modal-detalhes">
                                            {this.visitaDetalhe.atendimento.id ? 
                                                `${this.visitaDetalhe.atendimento.id} - ${this.visitaDetalhe.atendimento.titulo}` 
                                                : 'Não possui atendimento vinculado'
                                            }
                                        </p>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <div className={'space'}>
                                <GenericButton color={'darkBlue-outline'} label={'Excluir'} subClass={'basic-button'} click={() => {
                                    this.setState({ remover: this.visitaDetalhe});
                                }} />
                                <Link to={`/cad/pessoa/visita/${this.visitaDetalhe.id}`}>
                                    <GenericButton  color={'darkBlue'} label={'Editar'} subClass={'basic-button'} click={() => {}} />
                                </Link>
                            </div>
                        </div>
                    </SimpleModal>
                    {this.state.remover != null ? 
                        <SimpleModal isOpen={true} handleClose={(e) => this.setState({ remover: null })} disableClick={false} type={'warning'} width={'380px'} className={'evento-modal'}>
                            <label>Voce confirma a exclusão da ação </label>
                            <label className={'label-bold'}>ID {this.state.remover.id} - {this.state.remover.cliente.razaoSocial}?</label>
                            <div className={'div-botoes'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={(e) => this.setState({ remover: null })} />
                                <GenericButton color={'darkBlue'} label={'Excluir'} subClass={'basic-button'} click={() => {
                                    this.handleDeleteVisita(this.state.remover)
                                }} />
                            </div>
                        </SimpleModal> : null
                    }
                </Grid>
            </Page>
        );
    }
}