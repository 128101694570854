import React, { Component } from 'react'

import './compositeButton.scss'

export default class CompositeButton extends Component {

    get subClass() {
        return (this.props.subClass !== undefined ? this.props.subClass : '');
    }



    render() {
        return (
            <div className={'btn-group'} id={'parent'}>
                <button id={this.props.id} className={`${this.props.color} ${this.subClass}`} onClick={this.props.click} disabled={this.props.disabled} title={this.props.title ? this.props.title : ''}>
                    {this.props.label}

                </button>
                <button className={`${this.props.secondColor} ${this.subClass}`} onClick={this.props.click} disabled={this.props.disabled} title={this.props.title ? this.props.title : ''}>
                    {this.props.value}

                </button>
            </div>
        )
    }
}