import React, { Component } from 'react';
import { Grid, Switch } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting'
import offlineExporting from "highcharts/modules/offline-exporting"
import dataExporting from 'highcharts/modules/export-data';
import API from '../../../comm/API';

HC_exporting(Highcharts)
offlineExporting(Highcharts)
dataExporting(Highcharts)

export default class GraficoPorteSetor extends Component {

	constructor(props) {
		super(props);
		this.state = {
			isSetor: false,
			dadosDigital: undefined,
			dadosLean: undefined,
			dadosAmbos: undefined,

			qntPorteLean: {
				micro: 0,
				pequeno: 0,
				medio: 0,
				grande: 0,
				naoDefinido: 0
			},
			qntPorteDigital: {
				micro: 0,
				pequeno: 0,
				medio: 0,
				grande: 0,
				naoDefinido: 0
			},
			qntPorteAmbos: {
				micro: 0,
				pequeno: 0,
				medio: 0,
				grande: 0,
				naoDefinido: 0
			},
			qntSetor : {
				servicoUltilidadePublica: 0,
				industriaTransformacao: 0,
				comunicacaoInformatica: 0,
				construcaoCivil: 0,
				industriaFerroviarioDutoviario: 0,
				atividadesAdmServComplementares : 0,
				arteCulturaEsporteRecreacao: 0,
				industriaExtrativa: 0,
				naoDefinido: 0
			},

		};

		this.carregarPorte();

	}

	getPorteSetor = async () => {
		await API.get('rest/sistema/DashboardBrasilMais/geralDonut').then( async res => {
			let dados = res.data;
			this.setState({ dadosDigital: dados.digital, dadosLean: dados.lean,dadosAmbos: dados.ambos });
		}).catch(error => {
			console.log(error);
			this.props.showToast('Erro ao buscar o gráfico Porte/Setor do dashboard. Favor, entrar em contato com o suporte.', 'error');
		});
	}

	carregarPorte = async () => {
		await this.getPorteSetor();
		this.resultadoMedia();
	}

	resultadoMedia = () => {

		if (this.state.dadosDigital !== undefined && this.state.dadosLean !== undefined && this.state.dadosAmbos !== undefined) {

			let qntGrandeLean = this.state.dadosLean['Grande Porte'];
			let qntMedioLean = this.state.dadosLean['Médio Porte'];
			let qntMicroLean = this.state.dadosLean['Micro Empresa'];
			let qntPequenoLean = this.state.dadosLean['Pequeno Porte'];
			let qntNaoDefinidoPorteLean = this.state.dadosLean[4] ? this.state.dadosLean[4][1] : 0;

			let qntGrandeDigital = this.state.dadosDigital['Grande Porte'];
			let qntMedioDigital = this.state.dadosDigital['Médio Porte'];
			let qntMicroDigital = this.state.dadosDigital['Micro Empresa'];
			let qntPequenoDigital = this.state.dadosDigital['Pequeno Porte'];
			let qntNaoDefinidoPorteDigital = this.state.dadosDigital[4] ? this.state.dadosDigital[4][1] : 0;

			let qntGrandeAmbos = this.state.dadosAmbos['Grande Porte'];
			let qntMedioAmbos = this.state.dadosAmbos['Médio Porte'];
			let qntMicroAmbos = this.state.dadosAmbos['Micro Empresa'];
			let qntPequenoAmbos = this.state.dadosAmbos['Pequeno Porte'];
			let qntNaoDefinidoPorteAmbos = this.state.dadosAmbos[4] ? this.state.dadosAmbos[4][1] : 0;
			this.setState({
				qntPorteLean: {
					micro : qntMicroLean,
					pequeno : qntPequenoLean,
					medio : qntMedioLean,
					grande : qntGrandeLean,
					naoDefinido : qntNaoDefinidoPorteLean
				},
				qntPorteDigital: {
					micro : qntMicroDigital,
					pequeno : qntPequenoDigital,
					medio : qntMedioDigital,
					grande : qntGrandeDigital,
					naoDefinido : qntNaoDefinidoPorteDigital
				},
				qntPorteAmbos: {
					micro : qntMicroAmbos,
					pequeno : qntPequenoAmbos,
					medio : qntMedioAmbos,
					grande : qntGrandeAmbos,
					naoDefinido : qntNaoDefinidoPorteAmbos
				}

			});		
		}
	}

	changeIsSetor = (e) => {
		this.setState({ isSetor: e.target.checked });
	}

	create = () => {

		
		let series = [];
		
		
		

		

		

		let subtitle


		if(this.props.fase == "L"){
			subtitle = {
				enabled:true,
				text: `Fase 1: Mentoria Lean`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 90					
			};
			if (this.state.qntPorteLean.micro != null && this.state.qntPorteLean.micro > 0)
				series.push({ name: 'Microempresa <br/> (11-19 funcionários)',key:'Micro' , y: this.state.qntPorteLean.micro, selected: true, color: '#077CDC' });
			if (this.state.qntPorteLean.pequeno != null && this.state.qntPorteLean.pequeno > 0)		
				series.push({ name: 'Pequeno Porte <br/> (20-99 funcionários)',key:'Pequeno', y: this.state.qntPorteLean.pequeno, selected: true, color: '#B7EE8E'	});
			if (this.state.qntPorteLean.medio != null && this.state.qntPorteLean.medio > 0)			
				series.push({ name: 'Médio Porte <br/> (100-499 funcionários)',key:'Médio', y: this.state.qntPorteLean.medio, selected: true, color: '#B4B8C0' });
			
			if (this.state.qntPorteLean.naoDefinido != null && this.state.qntPorteLean.naoDefinido > 0)			
				series.push({ name: 'não informado', y: this.state.qntPorteLean.naoDefinido, selected: true, color: '#D5D9E1' });
		}
		else if(this.props.fase == "D"){
			subtitle = {
				enabled:true,
				text: `Fase 2: Mentoria Digital`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 90					
			};
			if (this.state.qntPorteDigital.micro != null && this.state.qntPorteDigital.micro > 0)
				series.push({ name: 'Microempresa <br/> (11-19 funcionários)',key:'Micro' , y: this.state.qntPorteDigital.micro, selected: true, color: '#077CDC' });
			if (this.state.qntPorteDigital.pequeno != null && this.state.qntPorteDigital.pequeno > 0)		
				series.push({ name: 'Pequeno Porte <br/> (20-99 funcionários)',key:'Pequeno', y: this.state.qntPorteDigital.pequeno, selected: true, color: '#B7EE8E'	});
			if (this.state.qntPorteDigital.medio != null && this.state.qntPorteDigital.medio > 0)			
				series.push({ name: 'Médio Porte <br/> (100-499 funcionários)',key:'Médio', y: this.state.qntPorteDigital.medio, selected: true, color: '#B4B8C0' });
			
			if (this.state.qntPorteDigital.naoDefinido != null && this.state.qntPorteDigital.naoDefinido > 0)			
				series.push({ name: 'não informado', y: this.state.qntPorteDigital.naoDefinido, selected: true, color: '#D5D9E1' });
		}
		else{
			subtitle = {
				enabled:true,
				text: `Brasil Mais`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 90					
			};
			if (this.state.qntPorteAmbos.micro != null && this.state.qntPorteAmbos.micro > 0)
				series.push({ name: 'Microempresa <br/> (11-19 funcionários)',key:'Micro' , y: this.state.qntPorteAmbos.micro, selected: true, color: '#077CDC' });
			if (this.state.qntPorteAmbos.pequeno != null && this.state.qntPorteAmbos.pequeno > 0)		
				series.push({ name: 'Pequeno Porte <br/> (20-99 funcionários)',key:'Pequeno', y: this.state.qntPorteAmbos.pequeno, selected: true, color: '#B7EE8E'	});
			if (this.state.qntPorteAmbos.medio != null && this.state.qntPorteAmbos.medio > 0)			
				series.push({ name: 'Médio Porte <br/> (100-499 funcionários)',key:'Médio', y: this.state.qntPorteAmbos.medio, selected: true, color: '#B4B8C0' });
			
			if (this.state.qntPorteAmbos.naoDefinido != null && this.state.qntPorteAmbos.naoDefinido > 0)			
				series.push({ name: 'não informado', y: this.state.qntPorteAmbos.naoDefinido, selected: true, color: '#D5D9E1' });
		}
		

		return {
			chart: {
				type: "pie",
				height:  500 ,
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				marginTop : 120
			},
			title: {
				enabled:true,
				text: `PORTE DAS EMPRESAS INDUSTRIAIS <br/> CADASTRADAS NO PROGRAMA`,
				style: {
					fontFamily: 'Raleway',
					font: "20px",
					fontWeight: 600,

					'color': '#077CDC',
				},
                margin: 90
				
			},
			subtitle: subtitle,
			tooltip: {
                headerFormat: '<b>{series.name}</b><br/>',
                pointFormat: 'Porcentagem: {point.percentage:.1f}%<br/>{point.y} empresas'
              },
			yAxis: {
				min: 0,
			},
			plotOptions: {
				pie: {
					dataLabels: {
						enabled: true,
						style: {
							font: 'Raleway',
							fontSize: '15px !important',
							color: '#707070'
						},
						format: '<b>{point.key}</b>: {point.percentage:.1f}% '
					},
					states: {
	                    hover: {
	                        enabled: false
	                    },
						inactive: {
							opacity: 1
						}
					},
					showInLegend: true
				},				
			},
			series: [{
				name: '',
				
				data: series
			}],
			credits: {
				enabled: false
			},
			exporting: {
				menuItemDefinitions: {
					// Custom definition
					printChart: {
						text: 'Imprimir gráfico'
					},
					downloadPNG:{
						text: 'Baixar como PNG'
					},
					downloadJPEG:{
						text: 'Baixar como JPEG'
					},
					downloadSVG:{
						text: 'Baixar como SVG'
					},
					viewFullscreen:{
						text: 'Ver em tela cheia'
					},
					downloadCSV:{
						text: 'Baixar tabela de dados'
					}
				},
				fallbackToExportServer: false,
				buttons: {
					contextButton: {
						menuItems:[ "printChart", "viewFullscreen", "downloadCSV", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"]
					}
				}
			}
		};
	}

	render() {
		return (
			<Grid container justify={'center'} className={'dashboard-porte-setor border-cinza'}>
				<Grid item xs={12}>
					<div style={{ marginTop:  '20px', marginBottom: '30px' }}>
						<HighchartsReact
							highcharts={Highcharts}
							options={this.create()} />
					</div>
				</Grid>
			</Grid>
		);
	}
}