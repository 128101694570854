export const PaisList = [
    { valor: 'AFG', type: 'pais', nome: 'AFEGANISTÃO' },
    { valor: 'ZAF', type: 'pais', nome: 'ÁFRICA DO SUL' },
    { valor: 'ALB', type: 'pais', nome: 'ALBÂNIA' },
    { valor: 'DEU', type: 'pais', nome: 'ALEMANHA' },
    { valor: 'AND', type: 'pais', nome: 'ANDORRA' },
    { valor: 'AGO', type: 'pais', nome: 'ANGOLA' },
    { valor: 'AIA', type: 'pais', nome: 'ANGUILLA' },
    { valor: 'ATA', type: 'pais', nome: 'ANTÁRCTICA' },
    { valor: 'ATG', type: 'pais', nome: 'ANTIGUA E BARBUDA' },
    { valor: 'ANT', type: 'pais', nome: 'ANTILHAS HOLANDESAS' },
    { valor: 'SAU', type: 'pais', nome: 'ARÁBIA SAUDITA' },
    { valor: 'DZA', type: 'pais', nome: 'ARGÉLIA' },
    { valor: 'ARG', type: 'pais', nome: 'ARGENTINA' },
    { valor: 'ARM', type: 'pais', nome: 'ARMÊNIA' },
    { valor: 'ABW', type: 'pais', nome: 'ARUBA' },
    { valor: 'AUS', type: 'pais', nome: 'AUSTRÁLIA' },
    { valor: 'AUT', type: 'pais', nome: 'ÁUSTRIA' },
    { valor: 'AZE', type: 'pais', nome: 'AZERBAIJÃO' },
    { valor: 'BHS', type: 'pais', nome: 'BAHAMAS' },
    { valor: 'BGD', type: 'pais', nome: 'BANGLADESH' },
    { valor: 'BRB', type: 'pais', nome: 'BARBADOS' },
    { valor: 'BHR', type: 'pais', nome: 'BAREIN' },
    { valor: 'BLR', type: 'pais', nome: 'BELARUS' },
    { valor: 'BEL', type: 'pais', nome: 'BÉLGICA' },
    { valor: 'BLZ', type: 'pais', nome: 'BELIZE' },
    { valor: 'BEN', type: 'pais', nome: 'BENIN' },
    { valor: 'BMU', type: 'pais', nome: 'BERMUDA' },
    { valor: 'BOL', type: 'pais', nome: 'BOLÍVIA' },
    { valor: 'BIH', type: 'pais', nome: 'BÓSNIA E HERZEGÓVINA' },
    { valor: 'BWA', type: 'pais', nome: 'BOTSWANA' },
    { valor: 'BRA', type: 'pais', nome: 'BRASIL'},
    { valor: 'BRN', type: 'pais', nome: 'BRUNEI' },
    { valor: 'BGR', type: 'pais', nome: 'BULGÁRIA' },
    { valor: 'BFA', type: 'pais', nome: 'BURKINA FASSO' },
    { valor: 'BDI', type: 'pais', nome: 'BURUNDI' },
    { valor: 'BTN', type: 'pais', nome: 'BUTÃO' },
    { valor: 'CPV', type: 'pais', nome: 'CABO VERDE' },
    { valor: 'CMR', type: 'pais', nome: 'CAMARÕES' },
    { valor: 'KHM', type: 'pais', nome: 'CAMBOJA' },
    { valor: 'CAN', type: 'pais', nome: 'CANADÁ' },
    { valor: 'QAT', type: 'pais', nome: 'CATAR' },
    { valor: 'KAZ', type: 'pais', nome: 'CAZAQUISTÃO' },
    { valor: 'TCD', type: 'pais', nome: 'CHADE' },
    { valor: 'CHL', type: 'pais', nome: 'CHILE' },
    { valor: 'CHN', type: 'pais', nome: 'CHINA' },
    { valor: 'CYP', type: 'pais', nome: 'CHIPRE' },
    { valor: 'SGP', type: 'pais', nome: 'CINGAPURA' },
    { valor: 'COL', type: 'pais', nome: 'COLÔMBIA' },
    { valor: 'COM', type: 'pais', nome: 'COMORES' },
    { valor: 'COG', type: 'pais', nome: 'CONGO' },
    { valor: 'PRK', type: 'pais', nome: 'CORÉIA DO NORTE' },
    { valor: 'KOR', type: 'pais', nome: 'CORÉIA DO SUL' },
    { valor: 'CIV', type: 'pais', nome: 'COSTA DO MARFIM' },
    { valor: 'CRI', type: 'pais', nome: 'COSTA RICA' },
    { valor: 'HRV', type: 'pais', nome: 'CROÁCIA' },
    { valor: 'CUB', type: 'pais', nome: 'CUBA' },
    { valor: 'DNK', type: 'pais', nome: 'DINAMARCA' },
    { valor: 'DJI', type: 'pais', nome: 'DJIBUTI' },
    { valor: 'DMA', type: 'pais', nome: 'DOMINICA' },
    { valor: 'EGY', type: 'pais', nome: 'EGITO' },
    { valor: 'SLV', type: 'pais', nome: 'EL SALVADOR' },
    { valor: 'ARE', type: 'pais', nome: 'EMIRADOS ÁRABES UNIDOS' },
    { valor: 'ECU', type: 'pais', nome: 'EQUADOR' },
    { valor: 'ERI', type: 'pais', nome: 'ERITRÉIA' },
    { valor: 'ESC', type: 'pais', nome: 'ESCÓCIA' },
    { valor: 'SVK', type: 'pais', nome: 'ESLOVÁQUIA' },
    { valor: 'SVN', type: 'pais', nome: 'ESLOVÊNIA' },
    { valor: 'ESP', type: 'pais', nome: 'ESPANHA' },
    { valor: 'USA', type: 'pais', nome: 'ESTADOS UNIDOS' },
    { valor: 'EST', type: 'pais', nome: 'ESTÔNIA' },
    { valor: 'ETH', type: 'pais', nome: 'ETIÓPIA' },
    { valor: 'RUS', type: 'pais', nome: 'FEDERAÇÃO RUSSA' },
    { valor: 'FJI', type: 'pais', nome: 'FIJI' },
    { valor: 'PHL', type: 'pais', nome: 'FILIPINAS' },
    { valor: 'FIN', type: 'pais', nome: 'FINLÂNDIA' },
    { valor: 'FRA', type: 'pais', nome: 'FRANÇA' },
    { valor: 'FXX', type: 'pais', nome: 'FRANÇA METROPOLITANA' },
    { valor: 'GAB', type: 'pais', nome: 'GABÃO' },
    { valor: 'GMB', type: 'pais', nome: 'GÂMBIA' },
    { valor: 'GHA', type: 'pais', nome: 'GANA' },
    { valor: 'GEO', type: 'pais', nome: 'GEÓRGIA' },
    { valor: 'GIB', type: 'pais', nome: 'GIBRALTAR' },
    { valor: 'GBR', type: 'pais', nome: 'GRÃ-BRETANHA' },
    { valor: 'GRD', type: 'pais', nome: 'GRANADA' },
    { valor: 'GRC', type: 'pais', nome: 'GRÉCIA' },
    { valor: 'GRL', type: 'pais', nome: 'GROENLÂNDIA' },
    { valor: 'GLP', type: 'pais', nome: 'GUADALUPE' },
    { valor: 'GUM', type: 'pais', nome: 'GUAM' },
    { valor: 'GTM', type: 'pais', nome: 'GUATEMALA' },
    { valor: 'GUY', type: 'pais', nome: 'GUIANA' },
    { valor: 'GUF', type: 'pais', nome: 'GUIANA FRANCESA' },
    { valor: 'GIN', type: 'pais', nome: 'GUINÉ' },
    { valor: 'GNQ', type: 'pais', nome: 'GUINÉ EQUATORIAL' },
    { valor: 'GNB', type: 'pais', nome: 'GUINÉ-BISSAU' },
    { valor: 'HTI', type: 'pais', nome: 'HAITI' },
    { valor: 'NLD', type: 'pais', nome: 'HOLANDA' },
    { valor: 'HND', type: 'pais', nome: 'HONDURAS' },
    { valor: 'HKG', type: 'pais', nome: 'HONG KONG' },
    { valor: 'HUN', type: 'pais', nome: 'HUNGRIA' },
    { valor: 'YEM', type: 'pais', nome: 'IÊMEN' },
    { valor: 'BVT', type: 'pais', nome: 'ILHA BOUVET' },
    { valor: 'CXR', type: 'pais', nome: 'ILHA CHRISTMAS' },
    { valor: 'NFK', type: 'pais', nome: 'ILHA NORFOLK' },
    { valor: 'CYM', type: 'pais', nome: 'ILHAS CAYMAN' },
    { valor: 'CCK', type: 'pais', nome: 'ILHAS COCOS' },
    { valor: 'COK', type: 'pais', nome: 'ILHAS COOK' },
    { valor: 'FRO', type: 'pais', nome: 'ILHAS FAROE' },
    { valor: 'SGS', type: 'pais', nome: 'ILHAS GEÓRGIA DO SUL E ILHAS SANDWICH DO SUL' },
    { valor: 'GGY', type: 'pais', nome: 'ILHAS GUERNSEY' },
    { valor: 'HMD', type: 'pais', nome: 'ILHAS HEARD E MAC DONALD' },
    { valor: 'FLK', type: 'pais', nome: 'ILHAS MALVINAS' },
    { valor: 'MNP', type: 'pais', nome: 'ILHAS MARIANA' },
    { valor: 'MHL', type: 'pais', nome: 'ILHAS MARSHALL' },
    { valor: 'PCN', type: 'pais', nome: 'ILHAS PITCAIRN' },
    { valor: 'IRE', type: 'pais', nome: 'ILHAS REUNIÃO' },
    { valor: 'SLB', type: 'pais', nome: 'ILHAS SALOMÃO' },
    { valor: 'SHN', type: 'pais', nome: 'ILHAS SANTA HELENA' },
    { valor: 'SJM', type: 'pais', nome: 'ILHAS SVALBARD E JAN MAYEN' },
    { valor: 'TKL', type: 'pais', nome: 'ILHAS TOKELAU' },
    { valor: 'TCA', type: 'pais', nome: 'ILHAS TURKS E CAICOS' },
    { valor: 'VIR', type: 'pais', nome: 'ILHAS VIRGENS' },
    { valor: 'VGB', type: 'pais', nome: 'ILHAS VIRGENS BRITÂNICAS' },
    { valor: 'WLF', type: 'pais', nome: 'ILHAS WALLIS E FUTUNA' },
    { valor: 'IND', type: 'pais', nome: 'ÍNDIA' },
    { valor: 'IDN', type: 'pais', nome: 'INDONÉSIA' },
    { valor: 'ING', type: 'pais', nome: 'INGLATERRA' },
    { valor: 'IRN', type: 'pais', nome: 'IRÃ' },
    { valor: 'IRQ', type: 'pais', nome: 'IRAQUE' },
    { valor: 'IRL', type: 'pais', nome: 'IRLANDA' },
    { valor: 'GRB', type: 'pais', nome: 'IRLANDA DO NORTE' },
    { valor: 'ISL', type: 'pais', nome: 'ISLÂNDIA' },
    { valor: 'ISR', type: 'pais', nome: 'ISRAEL' },
    { valor: 'ITA', type: 'pais', nome: 'ITÁLIA' },
    { valor: 'YUG', type: 'pais', nome: 'IUGOSLÁVIA' },
    { valor: 'JAM', type: 'pais', nome: 'JAMAICA' },
    { valor: 'JPN', type: 'pais', nome: 'JAPÃO' },
    { valor: 'JEY', type: 'pais', nome: 'JERSEY' },
    { valor: 'JOR', type: 'pais', nome: 'JORDÂNIA' },
    { valor: 'KIR', type: 'pais', nome: 'KIRIBATI' },
    { valor: 'KWT', type: 'pais', nome: 'KUWEIT' },
    { valor: 'LAO', type: 'pais', nome: 'LAOS' },
    { valor: 'LSO', type: 'pais', nome: 'LESOTO' },
    { valor: 'LVA', type: 'pais', nome: 'LETÔNIA' },
    { valor: 'LBN', type: 'pais', nome: 'LÍBANO' },
    { valor: 'LBR', type: 'pais', nome: 'LIBÉRIA' },
    { valor: 'LBY', type: 'pais', nome: 'LÍBIA' },
    { valor: 'LIE', type: 'pais', nome: 'LIECHTENSTEIN' },
    { valor: 'LTU', type: 'pais', nome: 'LITUÂNIA' },
    { valor: 'LUX', type: 'pais', nome: 'LUXEMBURGO' },
    { valor: 'MAC', type: 'pais', nome: 'MACAU' },
    { valor: 'MKD', type: 'pais', nome: 'MACEDÔNIA' },
    { valor: 'MDG', type: 'pais', nome: 'MADAGASCAR' },
    { valor: 'MYS', type: 'pais', nome: 'MALÁSIA' },
    { valor: 'MWI', type: 'pais', nome: 'MALAUÍ' },
    { valor: 'MDV', type: 'pais', nome: 'MALDIVAS' },
    { valor: 'MLI', type: 'pais', nome: 'MALI' },
    { valor: 'MLT', type: 'pais', nome: 'MALTA' },
    { valor: 'MAR', type: 'pais', nome: 'MARROCOS' },
    { valor: 'MTQ', type: 'pais', nome: 'MARTINICA' },
    { valor: 'MUS', type: 'pais', nome: 'MAURÍCIO' },
    { valor: 'MRT', type: 'pais', nome: 'MAURITÂNIA' },
    { valor: 'MYT', type: 'pais', nome: 'MAYOTTE' },
    { valor: 'MEX', type: 'pais', nome: 'MÉXICO' },
    { valor: 'MMR', type: 'pais', nome: 'MIANMAR' },
    { valor: 'FSM', type: 'pais', nome: 'MICRONÉSIA' },
    { valor: 'MOZ', type: 'pais', nome: 'MOÇAMBIQUE' },
    { valor: 'MDA', type: 'pais', nome: 'MOLDÁVIA' },
    { valor: 'MCO', type: 'pais', nome: 'MÔNACO' },
    { valor: 'MNG', type: 'pais', nome: 'MONGÓLIA' },
    { valor: 'MSR', type: 'pais', nome: 'MONTSERRAT' },
    { valor: 'NAM', type: 'pais', nome: 'NAMÍBIA' },
    { valor: 'NRU', type: 'pais', nome: 'NAURU' },
    { valor: 'NPL', type: 'pais', nome: 'NEPAL' },
    { valor: 'NIC', type: 'pais', nome: 'NICARÁGUA' },
    { valor: 'NER', type: 'pais', nome: 'NIGER' },
    { valor: 'NGA', type: 'pais', nome: 'NIGÉRIA' },
    { valor: 'NIU', type: 'pais', nome: 'NIUE' },
    { valor: 'NOR', type: 'pais', nome: 'NORUEGA' },
    { valor: 'NCL', type: 'pais', nome: 'NOVA CALEDÔNIA' },
    { valor: 'NZL', type: 'pais', nome: 'NOVA ZELÂNDIA' },
    { valor: 'OMN', type: 'pais', nome: 'OMÃ' },
    { valor: 'PDG', type: 'pais', nome: 'PAÍS DE GALES' },
    { valor: 'PLW', type: 'pais', nome: 'PALAU' },
    { valor: 'PAN', type: 'pais', nome: 'PANAMÁ' },
    { valor: 'PNG', type: 'pais', nome: 'PAPUA NOVA GUINÉ' },
    { valor: 'PAK', type: 'pais', nome: 'PAQUISTÃO' },
    { valor: 'PRY', type: 'pais', nome: 'PARAGUAI' },
    { valor: 'PER', type: 'pais', nome: 'PERU' },
    { valor: 'PYF', type: 'pais', nome: 'POLINÉSIA FRANCESA' },
    { valor: 'POL', type: 'pais', nome: 'POLÔNIA' },
    { valor: 'PRI', type: 'pais', nome: 'PORTO RICO' },
    { valor: 'PRT', type: 'pais', nome: 'PORTUGAL' },
    { valor: 'KEN', type: 'pais', nome: 'QUÊNIA' },
    { valor: 'KGZ', type: 'pais', nome: 'QUIRGUÍZIA' },
    { valor: 'REU', type: 'pais', nome: 'REINO UNIDO' },
    { valor: 'CAF', type: 'pais', nome: 'REPÚBLICA CENTRO-AFRICANA' },
    { valor: 'DOM', type: 'pais', nome: 'REPÚBLICA DOMINICANA' },
    { valor: 'CZE', type: 'pais', nome: 'REPÚBLICA TCHECA' },
    { valor: 'ROU', type: 'pais', nome: 'ROMÊNIA' },
    { valor: 'RWA', type: 'pais', nome: 'RUANDA' },
    { valor: 'ESH', type: 'pais', nome: 'SAHARA OCIDENTAL' },
    { valor: 'ASM', type: 'pais', nome: 'SAMOA AMERICANA' },
    { valor: 'WSM', type: 'pais', nome: 'SAMOA OCIDENTAL' },
    { valor: 'SMR', type: 'pais', nome: 'SAN MARINO' },
    { valor: 'LCA', type: 'pais', nome: 'SANTA LÚCIA' },
    { valor: 'KNA', type: 'pais', nome: 'SÃO CRISTÓVÃO E NÉVIS' },
    { valor: 'SPM', type: 'pais', nome: 'SÃO PIERRE E MIQUELON' },
    { valor: 'STP', type: 'pais', nome: 'SÃO TOMÉ E PRÍNCIPE' },
    { valor: 'VCT', type: 'pais', nome: 'SÃO VICENTE E GRANADINAS' },
    { valor: 'SYC', type: 'pais', nome: 'SEICHELES' },
    { valor: 'SEN', type: 'pais', nome: 'SENEGAL' },
    { valor: 'SLE', type: 'pais', nome: 'SERRA LEOA' },
    { valor: 'SYR', type: 'pais', nome: 'SÍRIA' },
    { valor: 'SOM', type: 'pais', nome: 'SOMÁLIA' },
    { valor: 'LKA', type: 'pais', nome: 'SRI LANKA' },
    { valor: 'SWZ', type: 'pais', nome: 'SUAZILÂNDIA' },
    { valor: 'SDN', type: 'pais', nome: 'SUDÃO' },
    { valor: 'SWE', type: 'pais', nome: 'SUÉCIA' },
    { valor: 'CHE', type: 'pais', nome: 'SUÍÇA' },
    { valor: 'SUR', type: 'pais', nome: 'SURINAME' },
    { valor: 'TJK', type: 'pais', nome: 'TADJIQUISTÃO' },
    { valor: 'THA', type: 'pais', nome: 'TAILÂNDIA' },
    { valor: 'TWN', type: 'pais', nome: 'TAIWAN' },
    { valor: 'TZA', type: 'pais', nome: 'TANZÂNIA' },
    { valor: 'ATF', type: 'pais', nome: 'TERRITÓRIOS FRANCESES MERIDIONAIS' },
    { valor: 'TMP', type: 'pais', nome: 'TIMOR LESTE' },
    { valor: 'TGO', type: 'pais', nome: 'TOGO' },
    { valor: 'TON', type: 'pais', nome: 'TONGA' },
    { valor: 'TTO', type: 'pais', nome: 'TRINIDAD E TOBAGO' },
    { valor: 'TUN', type: 'pais', nome: 'TUNÍSIA' },
    { valor: 'TKM', type: 'pais', nome: 'TURCOMÊNIA' },
    { valor: 'TUR', type: 'pais', nome: 'TURQUIA' },
    { valor: 'TUV', type: 'pais', nome: 'TUVALU' },
    { valor: 'UKR', type: 'pais', nome: 'UCRÂNIA' },
    { valor: 'UGA', type: 'pais', nome: 'UGANDA' },
    { valor: 'URY', type: 'pais', nome: 'URUGUAI' },
    { valor: 'UZB', type: 'pais', nome: 'UZBEQUISTÃO' },
    { valor: 'VUT', type: 'pais', nome: 'VANUATU' },
    { valor: 'VAT', type: 'pais', nome: 'VATICANO' },
    { valor: 'VEN', type: 'pais', nome: 'VENEZUELA' },
    { valor: 'VNM', type: 'pais', nome: 'VIETNÃ' },
    { valor: 'ZMB', type: 'pais', nome: 'ZÂMBIA' },
    { valor: 'ZWE', type: 'pais', nome: 'ZIMBÁBUE' }
];
