import React, { Component } from 'react';
import FormField from '../../components/formField/formField';
import Stepper from '../../components/stepper/stepper';
import { Grid } from '@material-ui/core';
import Page from '../../components/page/page';
import API from '../../comm/API';
import GenericButton from './../../components/genericButton/genericButton';
import SimpleModal from '../../components/modal/modal';
import NovoClientePage from '../cliente/clienteNovo';
import InputSelect from '../../components/inputselect/inputselect';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import AutoComplete from '../../components/autoComplete/autoComplete';
import './visitaNovo.scss';

export default class VisitaNovo extends Component {

    titles = ['Informações Gerais', 'Descritivo', 'Revisão'];
    
    errors = {
        nome: '' ,
        cidade: '' ,
        estado: '' ,
        pais: '' ,
        dataInicio: '' ,
        dataConclusao : '' ,
        isRecebida: '' ,
        cliente: '' ,
        produtoNacional: '' ,
        visitaColaboradorList : '' ,
    };
    isRecebidaList = [
        { id: 0, type: 'isRecebida', label: 'SIM', value: true },
        { id: 1, type: 'isRecebida', label: 'NÃO', value: false }
    ];

    constructor(props) {
        super(props)
        this.state = { 
            produtoNacionalList: [],
            colaboradorList: [],
            visita : {
                nome: '',
                ano:  new Date().getFullYear(),
                cidade: '',
                estado: '',
                pais: 'BRASIL',
                descricao: '',
                dataInicio: new Date(),
                dataConclusao: new Date(),
                isRecebida: true,
                unidade: {},
                cliente: {nome: ''},
                produtoNacional: {descricao: ''},
                visitaColaboradorList: []
            },
            openModal: false,
            novoCliente: {}
        };
        this.getProdutoNacionalList();
        if (props.idVisita != null && !isNaN(props.idVisita)) {
            API.get('rest/gestao/Visita/' + props.idVisita).then(res => {
                const visita = this.preventMissingAttributes(res.data);

                this.setState({ visita });
                this.getColaboradorList();
                this.setState(this.state);
            });
        } else {
            if (props.usuarioConectado.colaborador != null && props.usuarioConectado.colaborador.isAtivo) {
                this.state.visita.unidade = props.usuarioConectado.colaborador.unidade;
            } else {
                this.state.visita.unidade = props.usuarioConectado.unidade;
            }
            this.getColaboradorList();
        }
    };

    preventMissingAttributes = (visita) => {
        if (!visita.cliente) visita.cliente =  { nome: '' };
        if (!visita.produtoNacional) visita.produtoNacional = { descricao: '' };

        return visita;
    }

    salvarNovoCliente = (cliente) => {
        if (cliente != null && cliente.id != null) {
            this.state.visita.cliente = cliente;
        
            this.setState(this.state);
        }
    }

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getColaboradorList = () => {
        var filter = { 'unidade': this.state.visita.unidade.id };
        API.get('rest/gestao/ColaboradorUnidade/find', { params: filter }).then(res => {
            this.setState({ colaboradorList: res.data });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao carregar lista de colaboradores. Favor, entrar em contato com o suporte.', 'error');
        });
    };

    getProdutoNacionalList = () => {
        API.get('rest/auxiliar/ProdutoNacional?isAtivo=true').then(res => {
            this.setState({ produtoNacionalList: res.data });
        });
    };

    closeModalCliente = (cliente) => {
        if (cliente != null && cliente.id != null) {
            this.state.visita.cliente = cliente;
            this.state.openModal = false;
        
            this.setState(this.state);
        }
    };

    validateVisita = (step) => {
        switch (step) {
            case 0:
                return  this.state.visita.cliente && this.state.visita.cliente.nome && this.state.visita.produtoNacional && this.state.visita.produtoNacional.descricao 
                && this.state.visita.dataInicio && this.state.visita.dataConclusao
                && this.errors.dataInicio === '' && this.errors.dataConclusao === '';
            case 1:
                return this.state.visita.visitaColaboradorList.length > 0 && this.state.visita.cidade && this.state.visita.estado && this.state.visita.pais;
            default:
                return true;
        }
    };

    saveVisita = () => {
        this.showLoading();
        if (this.state.visita.id === undefined) {
            API.post('rest/gestao/Visita', this.state.visita).then(res => {
                this.closeLoading();
                this.showToast('Visita cadastrada com sucesso!', 'success', '/cad/pessoa/visita');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao tentar cadastrar visita. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Visita/' + this.state.visita.id, this.state.visita).then(res => {
                this.closeLoading();
                this.showToast('Visita editada com sucesso!', 'success', '/cad/pessoa/visita');
            }).catch(error => {
                this.closeLoading();
                this.showToast('Error ao tentar editar visita. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    };

    formatData(date) {
        if (typeof (date) !== 'string') {
            let dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                var dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

    changeInput = (e) => {
        if (e != null && e.target != null) {
            if (e.target.id === 'dataInicio') {
                this.state.visita.dataInicio = e.target.value + 'T00:00:00-03:00';
            } else if (e.target.id === 'dataConclusao') {
                this.state.visita.ano = parseInt(e.target.value.split('-')[0]);
                this.state.visita.dataConclusao = e.target.value + 'T23:59:59-03:00';
            } else if (e.target.type === 'text' || e.target.type === 'textarea') {
                this.state.visita[e.target.id] = e.target.value.toUpperCase();
            } else {
                this.state.visita[e.target.id] = e.target.value;
            }
            if (e.target.required) {
                if (e.target.value == null || e.target.value === '') {
                    this.errors[e.target.id] = 'Campo obrigatório';
                } else {
                    this.errors[e.target.id] = '';
                }
            } 
            if (e.target.value === '' && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors[e.target.id] = 'Campo obrigatório';
            } else if (this.formatData(this.state.visita.dataInicio) > this.formatData(this.state.visita.dataConclusao) && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors[e.target.id] = 'Data de início deve ser menor ou igual a data de conclusão';
            } else if (this.formatData(this.state.visita.dataInicio) <= this.formatData(this.state.visita.dataConclusao) && (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio')) {
                this.errors['dataConclusao'] = '';
                this.errors['dataInicio'] = '';
            } else if (e.target.id === 'dataConclusao' || e.target.id === 'dataInicio') {
                this.errors[e.target.id] = '';
            }
        } else if (e != null && e.type != null) {
            if (e.type === 'isRecebida') {
                this.state.visita.isRecebida = e.value;
            } else {
                this.state.visita[e.type] = e.label;
            }
        } else if (e != null && e !== '') {
            this.state.visita.visitaColaboradorList = e;
            if(e.length <= 0) {
                this.errors.visitaColaboradorList = 'Insira ao menos um colaborador.';
            } else {
                this.errors.visitaColaboradorList = '';
            }
        }        
        this.setState(this.state);
    };

    changeCliente = (e) => {
        this.state.visita.cliente = e;
        this.errors.cliente = !e ? 'Campo obrigatório' : '';
        this.setState(this.state);
    };

    changeProduto = (e) => {
        this.state.visita.produtoNacional = e;
        this.errors.produtoNacional = !e ? 'Campo obrigatório' : '';        
        this.setState(this.state);
    };

    changeEmpresaRecebida  = (e) => {
        if (e != null && e.target != null) {
            this.state.visita.isRecebida = (e.target.value === 'true');
            this.setState(this.state);
        }
    };

    createTemplateCliente(cliente) {
        return (
            <div className={'auto-complete-options'}>
                <div>
                    <label>{cliente.nome}</label>
                </div>
                <div>
                    <p>{cliente.razaoSocial}</p>
                    <p>{cliente.cpfcnpj}{cliente.enderecoList[0] != null ? ' - ' + cliente.enderecoList[0].municipio.descricao : ''}</p>
                </div>
            </div>
        );
    };

    formatDatesReview = (date) => {
        if (typeof date === 'string' && date.length > 10) {
            const onlyDate = date.split('T')[0];

            return new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date(onlyDate)).toString();
        }

        return new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date(date)).toString();
    };

    getSteps = () => {
        return [
            <Grid container className={'step'} style={{ marginBottom: 24}}>
                <Grid item xs={12} md={9} lg={6} xl={5} >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <label className={'radio-label'}>A ação foi:</label>
                            <div style={{ display: 'flex' }}>
                                <FormField 
                                    type={'radio'} 
                                    radioLabel={'Realizada'} 
                                    checked={this.state.visita.isRecebida === false} 
                                    inputValue={false} 
                                    changeValue={this.changeEmpresaRecebida} 
                                    name={'isRecebida'} 
                                />
                                <FormField 
                                    type={'radio'} 
                                    radioLabel={'Recebida'} 
                                    checked={this.state.visita.isRecebida === true} 
                                    inputValue={true} 
                                    changeValue={this.changeEmpresaRecebida}
                                    name={'isRecebida'} 
                                />
                            </div>
                        </Grid>
                        <Grid item xs={12}>                            
                            <AutoComplete label={'Empresa prospectada'} 
                                path={'rest/pessoa/Cliente/0/30?buscaLivre='}
                                itemKey={'id'}
                                key='autoCompleteClientes'
                                initialSelectedItem={this.state.visita.cliente}
                                itemlabel={'nome'}
                                itemTemplate={this.createTemplateCliente}
                                getInput={this.changeCliente} 
                                error={this.errors.cliente}/>
                        </Grid>
                        <Grid item xs={6} style={{ paddingBottom: '15px', marginTop: '-16px' }}>
                            <GenericButton label={'Cadastrar nova entidade'} color={'darkBlue'} subClass={'basic-button'} click={(e) => this.setState({ openModal: true })} />
                        </Grid>
                        <Grid item xs={12}>
                            <AutoComplete 
                                label={'Produto Nacional'}
                                suggestions={this.state.produtoNacionalList}
                                itemKey={'id'}
                                key='produtoNacional' 
                                initialSelectedItem={this.state.visita.produtoNacional}
                                itemlabel={'descricao'} 
                                getInput={this.changeProduto}
                                error={this.errors.produtoNacional}/>
                        </Grid>
                       

                        <Grid item xs={12} >
                            <br />
                            <label className='label-field'>Datas de ação de prospecção</label>
                        </Grid>
                        <Grid item xs={1} style={{ marginTop: 5 }}>
                            <label className='label-field'>Início</label>
                        </Grid>
                        <Grid item xs={5} className={'paddingForm'}>
                            <FormField type={'date'}  ref='dataInicio' id='dataInicio'
                                inputValue={this.formatData(this.state.visita.dataInicio)} changeValue={this.changeInput} required={'required'} 
                                error={this.errors.dataInicio} icon={'calendar.svg'} />
                        </Grid>

                        <Grid item xs={1} style={{ marginTop: 5 }}>
                            <label className='label-field'>Fim</label>
                        </Grid>

                        <Grid item xs={5} >
                            <FormField type={'date'}  ref='dataConclusao' id='dataConclusao'
                                    inputValue={this.formatData(this.state.visita.dataConclusao)} changeValue={this.changeInput} required={'required'} 
                                    error={this.errors.dataConclusao} icon={'calendar.svg'} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>,
            <Grid container className={'step'}  spacing={2} style={{ marginBottom: 24}}>
                <Grid item xs={12} md={9} lg={6} xl={5}>
                    <Grid container>
                        <Grid item xs={12} sm={5} className={'paddingForm'}>
                            <FormField type={'text'} label={'Cidade'} id={'cidade'} inputValue={this.state.visita.cidade} changeValue={this.changeInput}
                                required={'required'} error={this.errors.cidade} />
                        </Grid>
                        <Grid item xs={12} sm={3} className={'paddingForm'}>
                            <FormField type={'text'} label={'Estado'} id={'estado'} inputValue={this.state.visita.estado} changeValue={this.changeInput}
                                required={'required'} error={this.errors.estado} />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormField type={'text'} label={'País'} id={'pais'} inputValue={this.state.visita.pais} changeValue={this.changeInput}
                                required={'required'} error={this.errors.pais} />
                        </Grid>

                        <Grid item xs={12} >
                            <AutoCompleteMany
                                suggestions={this.state.colaboradorList}
                                itemlabel={'nome'}
                                getInput={this.changeInput}
                                itemKey={'id'}
                                label={'Adicione os colaboradores participantes'}
                                id={'nome'}
                                key={'autoCompleteColaboradores'}
                                initialOjectSelectedList={this.state.visita.visitaColaboradorList}
                                initialOjectSelectedListClass={'colaborador'}
                                error={this.errors.visitaColaboradorList}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormField type={'textarea'} label={'Descrição'} id={'descricao'} inputValue={this.state.visita.descricao} changeValue={this.changeInput}/>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>,
            <Grid container className={'step review-step'}>
                {/* <Grid container className={"step review-step"}> */}
                    <Grid className={'review-div'} key={1} container direction="column"
                        justify="center" alignItems="center">

                        <div className={'review-info-atendimento'}>
                            <Grid container>
                                {/* Review Informações Gerais */}
                                <Grid item xs={1}>
                                    <div style={{ backgroundColor: '#077CDC' }} className={'review-square'}>
                                        <label>1</label>
                                    </div>
                                </Grid>

                                <Grid style={{ fontWeight: '700', fontSize: '14pt', marginTop: '2px' }} item xs={11}>
                                    <label>Informações gerais</label>
                                </Grid>

                                <Grid className={'container-review'} xs={12}>

                                    {/* Informações */}
                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b >A ação foi:</b>
                                            <p style={{ width: 300 }}>{this.state.visita.isRecebida ? 'Recebida' : 'Realizada'}</p>
                                        </Grid>
                                    </Grid>

                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b>Empresa prospectada:</b>
                                            <p style={{ width: 300 }}>{this.state.visita.cliente.nome}</p>
                                        </Grid>
                                    </Grid>

                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b >Produto Nacional</b>
                                            <p style={{ width: 300 }}>{this.state.visita.produtoNacional.descricao}</p>
                                        </Grid>
                                    </Grid>


                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b>Datas da ação</b>
                                            <p style={{ marginRight: 16 }}>Início: {this.formatDatesReview(this.state.visita.dataInicio)}</p>
                                            <p>Término: {this.formatDatesReview(this.state.visita.dataConclusao)}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Review Descritivo */}
                                <Grid item xs={1}>
                                    <div style={{ backgroundColor: '#077CDC' }} className={'review-square'}>
                                        <label>2</label>
                                    </div>
                                </Grid>
                                <Grid style={{ fontWeight: '700', fontSize: '14pt', marginTop: '2px' }} item xs={11}>
                                    <label >Descritivo</label>
                                </Grid>

                                <Grid className={'container-review'}>

                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b>Cidade</b>
                                            <p style={{ width: 300 }}>{this.state.visita.cidade}</p>
                                        </Grid>
                                    </Grid>

                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b>Estado</b>
                                            <p style={{ width: 300 }}>{this.state.visita.estado}</p>
                                        </Grid>
                                    </Grid>

                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b>Pais</b>
                                            <p style={{ width: 300 }}>{this.state.visita.pais}</p>
                                        </Grid>
                                    </Grid>


                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b>Participantes</b>
                                            <p style={{ width: 300 }}>{this.state.visita.visitaColaboradorList.map(visitaColaborador => {
                                                if (!visitaColaborador.colaborador) {
                                                    visitaColaborador.colaborador = { nome: '' };
                                                }
                                                
                                                const lastItem = this.state.visita.visitaColaboradorList.length - 1;
    
                                                return `
                                                    ${visitaColaborador.colaborador.nome}${
                                                        visitaColaborador.colaborador === this.state.visita.visitaColaboradorList[lastItem].colaborador ? '' : ', '
                                                    }`;
                                            })}</p>
                                        </Grid>
                                    </Grid>

                                    <Grid className={'layout-review'}>
                                        <Grid className={'layout-review'}>
                                            <b>Descrição</b>
                                            <p style={{ width: 300 }}>{this.state.visita.descricao}</p>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
            </Grid >]
    };

    render() {
        return (
            <Page icon={'doc.svg'} label={'Nova Visita'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'novo-visita'}>
                    <Stepper
                        titles={this.titles}
                        steps={this.getSteps()}
                        validation={this.validateVisita}
                        sendForm={this.saveVisita} 
                        history={this.props.history} />
                </Grid>
                <SimpleModal
                    className={'modal-atendimento-cliente'}
                    isOpen={this.state.openModal}
                    disableClick={true}
                    handleClose={() => this.setState({ openModal: false })}
                    type={'info'}
                    width={'1000px'}>
                    <NovoClientePage usuarioConectado={this.props.usuarioConectado} asModal={true} showLoading={this.showLoading} closeLoading={this.closeLoading} showToast={this.showToast}
                        closeModal={this.closeModalCliente} updateCliente={this.salvarNovoCliente} />
                </SimpleModal>
            </Page>
        );
    };
}