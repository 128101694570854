import React, { Component } from 'react'
import GenericButton from '../../components/genericButton/genericButton';
import { Grid } from "@material-ui/core";
import API from '../../comm/API';
import SimpleModal from './../../components/modal/modal'
import Table from '../../components/table/table';
import FormField from './../../components/formField/formField';
import Masks from '../../comm/masks';
import './atendimento.scss';

class Participante extends Component {

    titlesTabela = [
        { id: '0', canOrder: false, label: 'CPF' },
        { id: '1', canOrder: false, label: 'Nome' },
        { id: '2', canOrder: false, label: '' }];

    constructor(props) {
        super(props);
        this.state = {
            modalParticipante: false, listParticipantes: [],
            modalConfirma: false, participante: { nome: '', cpfcnpj: '', concluiuEAD: false }, 
            atendimentoTurma: this.props.atendimentoTurma ? this.props.atendimentoTurma : { atendimento: { id: this.props.atendimento.id }, alunosList: [] },
            errors: {nome: { subClass: '', error: '' }, cpfcnpj: { subClass: '', error: '' }}, turma: this.props.turma
        };
    }

    componentWillReceiveProps(newProps) {
        this.getListParticipantes();
    };

    getListParticipantes() {
        if (this.props.atendimentoTurma != null) {
            let atendimentoTurma = this.props.atendimentoTurma;
            let listParticipantes = atendimentoTurma != null && atendimentoTurma.alunosList != null ? Object.assign([], atendimentoTurma.alunosList) : [];
            this.setState({ atendimentoTurma: atendimentoTurma, listParticipantes: listParticipantes });
        }
    };

    addParticipante = () => {
        let index = this.state.listParticipantes.findIndex(c => { return c.cpfcnpj === this.state.participante.cpfcnpj });
        if (this.state.participante.nome != null && this.state.participante.nome !== '' && this.state.participante.cpfcnpj && this.validateCpf() && index === -1) {
            let listParticipantes = this.state.listParticipantes;
            listParticipantes.push(this.state.participante);
            this.setState({ listParticipantes: listParticipantes, participante: { nome: '', cpfcnpj: '', concluiuEAD: false } });
        } else if (index >= 0) {
            let errors = this.state.errors;
            errors['cpfcnpj'].error = 'CPF já adicionado.';
            this.setState({ errors: errors });
        }
    };

    validateParticipante = () => {
        return this.state.participante.nome != null && this.state.participante.nome !== '' && this.state.participante.cpfcnpj && this.validateCpf(); 
    };

    getLinesTable() {
        let list = this.state.listParticipantes.map((item, index) => {
            return [Masks.maskCpf(item.cpfcnpj), item.nome,
            <GenericButton color={'transparent'} click={(e) => { this.deleteParticipanteFromTable(index) }} subClass={'icon-and-button'} icon={'trash.svg'} />];
        });
        return list;
    };

    deleteParticipanteFromTable(index) {
        let listAux = Object.assign([], this.state.listParticipantes);
        if (index >= 0) {
            listAux.splice(index, 1);
            this.setState({ listParticipantes: listAux });
        }
    };

    getAtendimentoTurmaModel() {
        return { atendimento: { id: this.props.atendimento.id }, turma: undefined, id: undefined };
    };

    salvarParticipantes() {
        this.props.showLoading();
        let atendimentoTurmaAux = this.state.atendimentoTurma != null && this.state.atendimentoTurma.id != null ? this.state.atendimentoTurma : this.getAtendimentoTurmaModel();
        atendimentoTurmaAux.alunosList = this.state.listParticipantes != null ? Object.assign([], this.state.listParticipantes) : [];
        if (this.state.turma != null && atendimentoTurmaAux.alunosList != null && atendimentoTurmaAux.alunosList.length > 1) atendimentoTurmaAux.turma = { id: this.state.turma.id };
        if (atendimentoTurmaAux.id != null) {
            API.post(`/rest/gestao/${this.props.isDigital === true ? 'AtendimentoTurmaDigital' : 'AtendimentoTurma'}/` + atendimentoTurmaAux.id, atendimentoTurmaAux).then(res => {
                this.setState({ modalParticipante: false, atendimentoTurma: atendimentoTurmaAux, turma: atendimentoTurmaAux.turma });
                this.props.closeLoading();
                this.props.showToast('Participante(s) editado(s) com sucesso!', 'success');
            }).catch(error => {
                console.log(error);
                this.props.closeLoading();
                this.props.showToast('Erro ao editar o(s) participante(s). Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post(`/rest/gestao/${this.props.isDigital === true ? 'AtendimentoTurmaDigital' : 'AtendimentoTurma'}/`, atendimentoTurmaAux).then(res => {
                const { data } = res;
                const { turma } = data;
                this.setState({ modalParticipante: false, atendimentoTurma: data, turma: turma ? turma : atendimentoTurmaAux.turma });
                this.props.closeLoading();
                this.props.showToast('Participante(s) cadastrado(s) com sucesso!', 'success');
            }).catch(error => {
                console.log(error);
                this.props.closeLoading();
                this.props.showToast('Erro ao cadastrar o(s) participante(s). Favor, entrar em contato com o suporte.', 'error');
            });
        }
        this.closeModalParticipante();
    };

    openModalConfirma(participante) {
        this.setState({ modalConfirma: true, participante: participante });
    };

    closeModalParticipante() {
        const participante = { nome: '', cpfcnpj: '', concluiuEAD: false };
        const errors = {nome: { error: '' }, cpfcnpj: { error: '' }};

        const atendimentoTurmaState = this.state.atendimentoTurma;
        let atendimentoTurma;
        let listParticipantes = [];

        if (atendimentoTurmaState) {
            atendimentoTurma = Object.assign({}, atendimentoTurmaState);
            const { alunosList } = atendimentoTurmaState; 
            listParticipantes = alunosList ? Object.assign([], alunosList) : [];
        } else {
            atendimentoTurma = { atendimento: { id: this.props.atendimento.id }, alunosList: [] };
        }

        this.setState({
            participante,
            modalParticipante: false,
            errors,
            atendimentoTurma, 
            listParticipantes
        });
    };

    getStatusButton() {
        return (this.props.atendimento.atendimentoStatus.id !== 3)
    };

    changeInput = (e) => {
        let part = this.state.participante;
        let errors = this.state.errors;
        if (e != null && e.target != null) {
            let id = e.target.id;
            let value = e.target.value.toUpperCase();
            if (id === 'cpfcnpj') value = Masks.maskByTipoPessoa(value, 0);
            part[`${id}`] = value;
            if (e.target.value == null || e.target.value === '') {
                errors[id].error = 'Campo obrigatório';
            } else if (id === 'cpfcnpj' && !this.validateCpf()) {
                errors[id].error = 'CPF inválido';     
            } else {
                errors[id].error = '';
            }
        }
        this.setState({ participante: part, errors: errors });
    };

    validateCpf() {
        let cpf = this.state.participante.cpfcnpj.replace(/\./g, '').replace(/-/g, '');
        if (cpf.length === 11 && !isNaN(cpf)) return true;
        else return false;
    };

    render() {
        return (
            <div className={'greyDiv'}>
                { this.props.isNegociacao && !this.props.isDN && 
                    <GenericButton color={'darkBlue'} label={'Cadastrar participante'} subClass={'basic-button'}
                        click={(e) => { this.setState({ modalParticipante: true }) }} /> }
                <h2>Participantes</h2>
                { this.state.atendimentoTurma != null && this.state.atendimentoTurma.alunosList != null && this.state.atendimentoTurma.alunosList.length > 0 ? 
                    <Grid container>
                        { this.state.atendimentoTurma.alunosList.map((item, index) => {
                            return (<Grid container key={index}>
                                <Grid item xs={9} xl={6} style={{ fontSize: '13px', marginLeft: '-2px'}}>{item.nome}</Grid>
                                <Grid item xs={3} xl={2} style={{ fontSize: '13px'}}>{Masks.maskCpf(item.cpfcnpj)}</Grid>
                            </Grid>);
                        })}
                    </Grid> : <p style={{ fontSize: '13px', margin: '4px 0px' }}>Este atendimento não possui participantes cadastrados.</p> }
                <SimpleModal
                    disableClick={true}
                    isOpen={this.state.modalParticipante}
                    handleClose={() => { this.closeModalParticipante() }}
                    type={'info'}
                    width={'600px'}>
                    <div className={'modal'}>
                        <h2>Cadastrar Participante</h2>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <FormField label={'Nome'} type={'text'} id={'nome'} inputValue={this.state.participante.nome} changeValue={this.changeInput}
                                    error={this.state.errors.nome.error} maxLength='80' disabled={this.state.listParticipantes != null && this.state.listParticipantes.length >= 5} />
                            </Grid>
                            <Grid item xs={10}>
                                <FormField type={'text'} label={'CPF'} id={'cpfcnpj'} inputValue={this.state.participante.cpfcnpj} changeValue={this.changeInput}
                                    error={this.state.errors.cpfcnpj.error} disabled={this.state.listParticipantes != null && this.state.listParticipantes.length >= 5} />
                            </Grid>
                            <Grid item xs={2} style={{ marginTop: '31px' }}>
                                <GenericButton color={'darkBlue'} label={'Adicionar'} subClass={'basic-button'} click={this.addParticipante} disabled={!this.validateParticipante()} />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: '25px', marginBottom: '25px'}}>
                                <Table lines={this.getLinesTable()} titles={this.titlesTabela} initialOrder={'9'} pagination={false} />
                            </Grid>
                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => { this.closeModalParticipante() }} />
                                <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} disabled={this.state.listParticipantes == null || this.state.listParticipantes.length === 0} click={(e) => { this.salvarParticipantes() }} />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
            </div>
        )
    };
}

export default Participante;