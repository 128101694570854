import React, { useState, useEffect } from "react";
import { newApi as Api } from "../../comm/API";
import { Grid } from "@material-ui/core";
import Page from "./../../components/page/page";
import FormField from "./../../components/formField/formField";
import AutocompleteMult from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import InputSelect from "../../components/inputselect/inputselect";
import GenericButton from "../../components/genericButton/genericButton";
import SimpleModal from "../../components/modal/modal";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import "./relatorioDeOperacoes.scss";

export const RelatorioDeOperacoes = (props) => {
  const typeUser = props.usuarioConectado.unidade.tipoUnidade.sigla;
  const unityUser = props.usuarioConectado.unidade.id;
  const actualYear = new Date().getFullYear();

  const [showLoading, setShowLoading] = useState(true);

  // Query Params
  const [selectedYear, setSelectedYear] = useState({ id: 0, year: actualYear });
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedTechnologicalGroup, setSelectedTechnologicalGroup] =
    useState(null);
  const [selectedUnity, setSelectedUnity] = useState(
    typeUser === "UO" ? unityUser : null
  );
  const [selectedUnityGroup, setSelectedUnityGroup] = useState(0);

  // List
  const [yearList, setYearList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [technologicalGroupList, setTechnologicalGroupList] = useState([]);
  const [unityList, setUnityList] = useState([]);
  const [unityGroupList, setUnityGroupList] = useState([]);
  const [searchFreeList, setSearchFreeList] = useState([]);
  const [isiList, setIsiList] = useState([]);

  const [nameGroup, setNameGroup] = useState("");
  const [listAttendances, setListAttendances] = useState();
  const [page, setPage] = useState(1);
  const [take, setTake] = useState(50);
  const [actualPage, setActualPage] = useState(null);
  const [from, setFrom] = useState(1);
  const [nextPage, setNextPage] = useState(null);
  const [previousPage, setPreviousPage] = useState(null);
  const [to, setTo] = useState(1);
  const [total, setTotal] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  // Busca avançada
  const [unitsListSuggestions, setUnitsListSuggestions] = useState([]);
  const [departmentListSuggestions, setDepartmentListSuggestions] = useState(
    []
  );
  const [
    technologicalGroupListSuggestions,
    setTechnologicalGroupListSuggestions,
  ] = useState([]);

  const [openModal, setOpenModal] = useState(false);

  // Blocos
  const [statusAttendance, setStatusAttendance] = useState({});
  const [productionDetail, setProductionDetail] = useState({});
  const [revenueDetail, setRevenueDetail] = useState({});
  const [isNetworkISI, setIsNetworkISI] = useState(true);
  const [stiDetail, setStiDetail] = useState({});
  const [pdiDetail, setPdiDetail] = useState({});
  const [fundingProgramsDetail, setFundingProgramsDetail] = useState({});
  const [industryDetail, setIndustryDetail] = useState({});
  const [industrySystemDetail, setIndustrySystemDetail] = useState({});

  const statusAttencandeMatch = {
    "Em Prospecção (rascunho)": "status-yellow",
    "Em Prospecção (negociação)": "status-orange",
    "Aceito/Contratado": "status-green",
    "Aceito/Em execução": "status-green",
    "Proposta Recusada": "status-red",
    Concluído: "status-blue",
    default: "status-gray",
  };

  useEffect(() => {
    setShowLoading(true);
    const initialYear = 2016;
    const array = Array(actualYear - initialYear + 1)
      .fill()
      .map((_, index) => ({ id: index, year: initialYear + index }));
    setSelectedYear(array[array.length - 1]);
    setYearList(array);
    getListSearchFree();
    if (typeUser === "UO") {
      getDataReport(selectedUnity, "UO");
    } else {
      getDataReport(null, "NoHasFilter");
    }
  }, []);

  const token = localStorage.getItem("sgt:tokenJWT");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const createRequestParams = async (
    typeRequest,
    dataRequest = selectedUnity ||
      selectedDepartment ||
      selectedTechnologicalGroup
  ) => {
    let queryParams = new URLSearchParams();

    // Filtro DR
    if (typeRequest === "DR" && selectedDepartment !== null) {
      if (selectedDepartment !== null || (Array.isArray(selectedDepartment) && selectedDepartment.length > 0)) {
        const selectedDepartmentArray = Array.isArray(selectedDepartment) ? selectedDepartment : [selectedDepartment];
  
        for (let i = 0; i < selectedDepartmentArray.length; i++) {
          queryParams.append("idDR", selectedDepartment[i]);
        }
      }
    }

    if (typeRequest === "AttendanceDR" && selectedDepartment !== null) {
      queryParams.append("idDR", selectedDepartment);
    }

    // Filtro GT
    if (typeRequest === "TG" && selectedTechnologicalGroup !== null) {
      for (let i = 0; i < selectedTechnologicalGroup.length; i++) {
        queryParams.append("idGroup", selectedTechnologicalGroup[i]);
      }
    }

    if (typeRequest === "AttendanceTG" && selectedTechnologicalGroup !== null) {
      for (let i = 0; i < selectedTechnologicalGroup.length; i++) {
        queryParams.append("idGroup", selectedTechnologicalGroup[i]);
      }
    }

    // Filtro UO
    if (
      typeRequest === "UO" &&
      selectedUnity !== null &&
      selectedUnity !== []
    ) {
      if (
        selectedUnity !== null ||
        (Array.isArray(selectedUnity) && selectedUnity.length > 0)
      ) {
        const selectedUnityArray = Array.isArray(selectedUnity)
          ? selectedUnity
          : [selectedUnity];

        for (let i = 0; i < selectedUnityArray.length; i++) {
          queryParams.append("idUO", selectedUnityArray[i]);
        }
      }
    }

    if (
      typeRequest === "AttendanceUO" &&
      selectedUnity !== null &&
      selectedUnity !== []
    ) {
      queryParams.append("idUO", selectedUnity);
    }

    // Busca livre
    if (typeRequest === "SearchFreeUO") {
      queryParams.append("idUO", dataRequest);
    }

    if (typeRequest === "SearchFreeDR") {
      queryParams.append("idDR", dataRequest);
    }
    setIsNetworkISI(false);

    if (typeRequest === "NoHasFilter") {
      setIsNetworkISI(true);
    }

    queryParams.append("year", selectedYear.year);
    return queryParams;
  };

  const getDataReport = async (
    idSearch = selectedUnity ||
      selectedDepartment ||
      selectedTechnologicalGroup,
    typeFilter
  ) => {
    const queryParams = await createRequestParams(typeFilter, idSearch);

    const configObject = { ...config, params: queryParams };

    const requestReport = (
      await Api.get("/rest/operationsReports", configObject)
    ).data;

    setStatusAttendance(requestReport.attendances.geral);
    setProductionDetail(requestReport.attendances.production);
    setRevenueDetail(requestReport.attendances.revenue);
    setStiDetail(requestReport.financialResources.sti);
    setPdiDetail(requestReport.financialResources.rdi);
    setFundingProgramsDetail(requestReport.financialResources.fundingPrograms);
    setIndustryDetail(requestReport.financialResources.industry);
    setIndustrySystemDetail(requestReport.financialResources.industrySystem);
    setIsiList(requestReport.units);
    setShowLoading(false);
  };

  const getListSearchFree = async () => {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const queryParams = {
      take: take,
      page: page,
      isiDRs: true,
    };

    const configObject = { ...config, params: queryParams };

    const requestSearchFree = (
      await Api.get("/rest/accounts/units", configObject)
    ).data;

    setSearchFreeList(requestSearchFree.units);
  };

  const getAttendances = async (
    pageReq = 1,
    idSearch = selectedUnity ||
      selectedDepartment ||
      selectedTechnologicalGroup ||
      isiList,
    typeFilter
  ) => {
    setShowLoading(true);

    const queryParams = await createRequestParams(typeFilter, idSearch);

    if (!pageReq) return;

    queryParams.append("take", take);
    queryParams.append("page", pageReq);

    const configObject = { ...config, params: queryParams };

    const requestAttendances = (
      await Api.get("/rest/operationsreports/attendances", configObject)
    ).data;

    setListAttendances(requestAttendances.attendance);
    setFrom(requestAttendances.pagination.from);
    setTo(requestAttendances.pagination.to);
    setActualPage(requestAttendances.pagination.actualPage);
    setNextPage(requestAttendances.pagination.nextPage);
    setPreviousPage(requestAttendances.pagination.prevPage);
    setTotal(requestAttendances.pagination.total);
    setTotalPages(requestAttendances.pagination.totalPages);
    setShowLoading(false);
    setOpenModal("attendance");
  };

  const getListUnits = async () => {
    const queryParams = {
      take: 50,
      page: 1,
      idUnitySpecialty: 1,
    };

    const configObject = { ...config, params: queryParams };

    const requestUnits = (await Api.get("/rest/accounts/units", configObject))
      .data;

    const orderUnits = requestUnits.units;
    setUnitsListSuggestions(orderUnits.sort(compare));
  };

  const compare = (a, b) => {
    if (a.city.federatedUnity.acronym < b.city.federatedUnity.acronym)
      return -1;
    if (a.city.federatedUnity.acronym > b.city.federatedUnity.acronym) return 1;
    return 0;
  };

  const getListDepartments = async () => {
    const queryParams = {
      take: 29,
      page: 1,
      idUnityFather: 29,
    };

    const configObject = { ...config, params: queryParams };

    const requestDepartment = (
      await Api.get("/rest/accounts/units", configObject)
    ).data;

    setDepartmentListSuggestions(requestDepartment.units);
  };

  const getListTechnologicalGroup = async () => {
    const requestTechnologicalGroup = (
      await Api.get("/rest/accounts/technologicalGroup", config)
    ).data;

    setTechnologicalGroupListSuggestions(requestTechnologicalGroup);
  };

  const addListDepartments = (event, values) => {
    setDepartmentList(values);
    let listIdsDR = [];

    if (values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        if (Array.isArray(listIdsDR)) {
          listIdsDR.push(values[i].id);
        }
      }
    }
    setSelectedDepartment(listIdsDR);
  };

  const addListTechnologicalGroup = (event, values) => {
    setTechnologicalGroupList(values);
    let listIdsGroup = [];

    if (values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        if (Array.isArray(listIdsGroup)) {
          listIdsGroup.push(values[i].id);
        }
      }
    }
    setSelectedTechnologicalGroup(listIdsGroup);
  };

  const addListUnity = async (event, values) => {
    setUnityList(values);
    let listIdsUnity = [];

    if (values.length > 0) {
      for (let i = 0; i < values.length; i++) {
        if (Array.isArray(listIdsUnity)) {
          listIdsUnity.push(values[i].id);
        }
      }
    }
    setSelectedUnity(listIdsUnity);
  };

  const addListUnityGroup = (event, values) => {
    setUnityGroupList(values);
    let listIdsUnity = [];

    for (let i = 0; i < values.length; i++) {
      if (Array.isArray(listIdsUnity)) {
        listIdsUnity.push(values[i].id);
      }
    }
    setSelectedUnityGroup(listIdsUnity);
  };

  const changeNameGroup = (event, values) => {
    let nameWritte = event.target.value;
    setNameGroup(nameWritte);
  };

  const removeFloatTag = (id) => {
    let newList;

    if (departmentList.length !== 0) {
      newList = departmentList;
      let index = departmentList.indexOf(id);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setDepartmentList(newList);
      addListDepartments(0, newList);
    }

    if (technologicalGroupList.length !== 0) {
      newList = technologicalGroupList;
      let index = technologicalGroupList.indexOf(id);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setTechnologicalGroupList(newList);
      addListTechnologicalGroup(0, newList);
    }

    if (unityGroupList.length !== 0) {
      newList = unityGroupList;
      let index = unityGroupList.indexOf(id);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setUnityGroupList(newList);
      addListUnityGroup(0, newList);
    }

    if (unityList.length !== 0) {
      newList = unityList;
      let index = unityList.indexOf(id);
      if (index !== -1) {
        newList.splice(index, 1);
      }
      setUnityList(newList);
      addListUnity(0, newList);
    }
  };

  const getDataSearchFree = async (event, values) => {
    if (values !== null) {
      let idSearch = "" || values.id;
      if (values.unitySpecialty.description === "ISI") {
        setSelectedUnity(idSearch);
        await getDataReport(idSearch, "SearchFreeUO");
      }

      if (values.unitySpecialty.description !== "ISI") {
        setSelectedDepartment(idSearch);
        await getDataReport(idSearch, "SearchFreeDR");
      }
    }
  };

  const cleanSearch = async () => {
    setDepartmentList([]);
    setSelectedDepartment(null);
    setTechnologicalGroupList([]);
    setSelectedTechnologicalGroup(null);
    setUnityList([]);
    if (typeUser === "UO") {
      setSelectedUnity(typeUser);
    } else {
      setSelectedUnity(null);
    }
    setSelectedYear({ id: 0, year: actualYear });
    getListSearchFree();
    if (!isNetworkISI && typeUser !== "UO") {
      getDataReport(null, "NoHasFilter");
    }
    if (isNetworkISI && typeUser !== "UO") {
      getDataReport(null, "NoHasFilter");
      setIsNetworkISI(true);
    }
    setOpenModal(false);
  };

  const cleanFieldCreateGroup = () => {
    setUnityGroupList([]);
    setNameGroup("");
    setOpenModal("searchAdvanced");
  };

  const createTechnologicalGroup = async () => {
    setShowLoading(true);
    let objectGroup = new Object();
    let listUnits = [];

    objectGroup.name = nameGroup;

    for (let i = 0; i < selectedUnityGroup.length; i++) {
      listUnits.push({ id: selectedUnityGroup[i] });
    }

    objectGroup.units = listUnits;

    await Api.post("/rest/accounts/technologicalGroup", objectGroup, config);

    setNameGroup("");
    setSelectedUnityGroup(0);
    setUnityGroupList([]);
    getListTechnologicalGroup();
    setOpenModal("searchAdvanced");
    setShowLoading(false);
  };

  const downloadOperationsReport = async (
    idSearch = selectedUnity ||
      selectedDepartment ||
      selectedTechnologicalGroup,
    typeFilter
  ) => {
    setShowLoading(true);

    const queryParams = await createRequestParams(typeFilter, idSearch);

    const configObject = {
      ...config,
      params: queryParams,
      responseType: "blob",
    };

    const URL = `${Api.defaults.baseURL}rest/operationsReports/downloadOperationsReportsSpreadsheet`;

    await Api.get(URL, configObject).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `relatorio-de-operacoes.xls`);
      document.body.appendChild(link);
      link.click();
      setShowLoading(false);
    });
  };

  return (
    <div>
      <Page
        icon={"relatorio.svg"}
        label={"Relatório de Operações"}
        usuarioConectado={props.usuarioConectado}
        showLoading={showLoading}
      >
        <Grid className={"mainContentPage"}>
          <Grid className={"contentSearch"}>
            <Grid className={"contentSearchFree"}>
              <AutocompleteMult
                id={"id"}
                options={searchFreeList}
                groupBy={(option) => option.abbreviation}
                getOptionLabel={(option) => option.description || ""}
                onChange={getDataSearchFree}
                value={searchFreeList}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
              />
            </Grid>

            <Grid className={"contentButtons"}>
              <GenericButton
                color={"lightBlue"}
                label={"Busca avançada"}
                subClass={"basic-button busca-avancada"}
                icon={process.env.PUBLIC_URL + "filter.svg"}
                click={() => {
                  setOpenModal("searchAdvanced");
                  getListUnits();
                  getListDepartments();
                  getListTechnologicalGroup();
                }}
              />
              {isNetworkISI === false && typeUser !== "UO" && (
                <GenericButton
                  color={"white"}
                  subClass={"icon-button"}
                  click={cleanSearch}
                  icon={"close.svg"}
                />
              )}

              <Grid style={{ marginLeft: "6%" }}>
                <GenericButton
                  label={"Baixar relatório"}
                  color={"darkBlue-outline"}
                  subClass={"basic-button"}
                  click={() =>
                    downloadOperationsReport(
                      selectedUnity !== null
                        ? selectedUnity
                        : selectedDepartment !== null
                        ? selectedDepartment
                        : selectedTechnologicalGroup !== null
                        ? selectedTechnologicalGroup
                        : null,
                      selectedUnity !== null
                        ? "UO"
                        : selectedDepartment !== null
                        ? "DR"
                        : selectedTechnologicalGroup !== null
                        ? "TG"
                        : ""
                    )
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={"contentInformations"}>
            {/* Unity Info */}
            <Grid className={"contentGridInformationDetail"}>
              {isNetworkISI ? (
                <h1 className={"unityTitle"}>REDE ISI</h1>
              ) : (
                isiList.length > 1 && (
                  <h1 className={"unityTitle"}>MÚLTIPLA SELEÇÃO</h1>
                )
              )}

              {isNetworkISI === false &&
                isiList.length === 1 &&
                isiList.map((units) => (
                  <h1 className={"unityTitle"}>{units.abbreviation}</h1>
                ))}

              {isNetworkISI === false && isiList.unityFather !== null && (
                <h1 className={"unityTitle"}>{isiList.abbreviation}</h1>
              )}

              {isNetworkISI === true ? (
                <h3 className={"labelTitle"}>Informações da rede</h3>
              ) : isNetworkISI === false && isiList.length === 1 ? (
                <h3 className={"labelTitle"}>Informações da unidade</h3>
              ) : (
                <h3 className={"labelTitle"}>Informações das unidades</h3>
              )}
              <hr />
              <Grid className={"unityDataInfo"}>
                {!isNetworkISI && (
                  <Grid>
                    {isiList.length > 0 && (
                      <>
                        <Grid className={"fieldAlignmentUnities"}>
                          <h2 className={"labelUnities"}>
                            {isiList.length > 1 ? "Unidades" : "Unidade"}
                          </h2>
                          {isiList.length > 0 ? (
                            <Grid className={"unityNameIsis"}>
                              {isiList.map((units) => (
                                <p className={"nameISI"}>
                                  {units.abbreviation}
                                </p>
                              ))}
                            </Grid>
                          ) : (
                            <Grid className={"unityNameIsis"}>
                              <p className={"nameISI"}>
                                {isiList.abbreviation}
                              </p>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    )}

                    {selectedUnity !== null && isiList.unityFather && (
                      <Grid>
                        <>
                          <Grid className={"fieldAlignmentUnities"}>
                            <h2 className={"labelUnities"}>ID do DR</h2>
                            <h2 className={"infoUnities"}>
                              {isiList.unityFather.id}
                            </h2>
                          </Grid>

                          <Grid className={"fieldAlignmentUnities"}>
                            <h2 className={"labelUnities"}>
                              Departamento Regional
                            </h2>
                            <h2 className={"infoUnities"}>
                              {isiList.unityFather.abbreviation}
                            </h2>
                          </Grid>

                          <Grid className={"fieldAlignmentUnities"}>
                            <h2 className={"labelUnities"}>Estado</h2>
                            <h2 className={"infoUnities"}>
                              {isiList.city.federatedUnity.description}
                            </h2>
                          </Grid>

                          <Grid className={"fieldAlignmentUnities"}>
                            <h2 className={"labelUnities"}>Cidade</h2>
                            <h2 className={"infoUnities"}>
                              {isiList.city.description}
                            </h2>
                          </Grid>
                        </>
                      </Grid>
                    )}
                  </Grid>
                )}

                {isNetworkISI && (
                  <Grid>
                    <Grid>
                      <Grid className={"fieldAlignmentUnities"}>
                        <h2 className={"labelUnities"}>
                          Quantidade de unidades
                        </h2>
                        <h2 className={"infoUnities"}>
                          {isiList.quantityISI === undefined
                            ? 0
                            : isiList.quantityISI}
                        </h2>
                      </Grid>
                      <Grid className={"fieldAlignmentUnities"}>
                        <h2 className={"labelUnities"}>
                          Quantidade de Departamentos Regionais
                        </h2>
                        <h2 className={"infoUnities"}>
                          {isiList.quantityDR === undefined
                            ? 0
                            : isiList.quantityDR}
                        </h2>
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid className={"fieldAlignmentUnities"}>
                  <h2 className={"labelUnities"}>Ano</h2>
                  <h2 className={"infoUnities"}>{selectedYear.year}</h2>
                </Grid>
              </Grid>

              {/* Marketing e PMO */}
              <h3 className={"labelTitle"}>Indicadores financeiros</h3>
              <hr />
              {/* STI */}
              <h4 className={"legendTitle"}>
                Planejamento Orçamentario e Execução de Serviço
              </h4>
              {stiDetail.sustainabilityRate !== undefined && (
                <Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>Receita de STI planejada</h2>
                    <h2 className={"infoUnities"}>
                      {stiDetail.plannedSTIRevenue.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>Receita de STI realizada</h2>
                    <h2 className={"infoUnities"}>
                      {stiDetail.madeSTIRevenue.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Custos operacionais planejados
                    </h2>
                    <h2 className={"infoUnities"}>
                      {stiDetail.plannedOperatingCosts.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Custos operacionais realizados
                    </h2>
                    <h2 className={"infoUnities"}>
                      {stiDetail.madeOperatingCosts.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Custos operacionais por pesquisador efetivo
                    </h2>
                    <h2 className={"infoUnities"}>
                      {stiDetail.researchersCosts.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Taxa de sustentabilidade da STI
                    </h2>
                    <h2 className={"infoUnities"}>
                      {stiDetail.sustainabilityRate.toLocaleString("pt-br", {
                        style: "percent",
                      })}
                    </h2>
                  </Grid>
                </Grid>
              )}

              {/* PDI */}
              <h4 className={"legendTitle"}>
                Planejamento Orçamentario e Execução de Projeto PDI
              </h4>
              {pdiDetail.madeRDIRevenue !== undefined && (
                <Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>Receita de PDI planejada</h2>
                    <h2 className={"infoUnities"}>
                      {pdiDetail.plannedRDIRevenue.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>Receita de PDI realizada</h2>
                    <h2 className={"infoUnities"}>
                      {pdiDetail.madeRDIRevenue.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </h2>
                  </Grid>
                </Grid>
              )}

              {/* Fomento externo */}
              <h4 className={"legendTitle"}>Fomento Externo</h4>
              {fundingProgramsDetail.plannedShareOfRevenueFromFundingPrograms !==
                undefined && (
                <Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Porcentagem planejada da receita de fomento externo
                    </h2>
                    <h2 className={"infoUnities"}>
                      {fundingProgramsDetail.plannedShareOfRevenueFromFundingPrograms.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Porcentagem na receita de fomento externo
                    </h2>
                    <h2 className={"infoUnities"}>
                      {fundingProgramsDetail.madeShareOfRevenueFromFundingPrograms.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </h2>
                  </Grid>
                </Grid>
              )}

              {/* Mercado */}
              <h4 className={"legendTitle"}>Mercado</h4>
              {industryDetail.madeShareOfIndustryRevenue !== undefined && (
                <Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Porcentagem planejada da receita de mercado
                    </h2>
                    <h2 className={"infoUnities"}>
                      {industryDetail.plannedShareOfIndustryRevenue.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Porcentagem na receita de mercado
                    </h2>
                    <h2 className={"infoUnities"}>
                      {industryDetail.madeShareOfIndustryRevenue.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </h2>
                  </Grid>
                </Grid>
              )}

              {/* Sistema Indústria */}
              <h4 className={"legendTitle"}>Sistema indústria</h4>
              {industrySystemDetail.madeShareOfRevenueFromIndustrySystem !==
                undefined && (
                <Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Porcentagem planejada da receita de Sistema indústria
                    </h2>
                    <h2 className={"infoUnities"}>
                      {industrySystemDetail.plannedShareOfRevenueFromIndustrySystem.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </h2>
                  </Grid>
                  <Grid className={"fieldAlignmentUnities"}>
                    <h2 className={"labelUnities"}>
                      Porcentagem na receita de sistema indústria
                    </h2>
                    <h2 className={"infoUnities"}>
                      {industrySystemDetail.madeShareOfRevenueFromIndustrySystem.toLocaleString(
                        "pt-br",
                        {
                          style: "currency",
                          currency: "BRL",
                        }
                      )}
                    </h2>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {/* Attendances Info */}
            <Grid className={"contentGridAttendanceDetail"}>
              <h3 className={"labelTitle"}>Atendimentos</h3>
              {statusAttendance.concluded !== undefined && (
                <Grid className={"contentBlockGeneral"}>
                  <Grid className={"contentGeneralAttendance"}>
                    <h2 className={"labelContent"}>Geral</h2>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Em prospecção (rascunho)</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.drafted.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Proposta arquivada</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.filed.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>
                        Em prospecção (negociação)
                      </h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.negotiated.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Proposta recusada</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.recused.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Aceito/contratado</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.accepted.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Aceito/execução</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.executed.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Proposta cancelada</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.canceled.toLocaleString("pt-br")}
                      </p>
                    </Grid>

                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Concluidos</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.concluded.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Total cadastrados</h4>
                      <p className={"counterStatusAttendance"}>
                        {statusAttendance.registered.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {/* Produção */}
              <Grid className={"contentGeneralProduction"}>
                {productionDetail.countRDIAttendances !== undefined && (
                  <Grid className={"contentBlockGeneral"}>
                    <h2 className={"labelContent"}>Produção</h2>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Horas trabalhadas</h4>
                      <p className={"counterStatusAttendance"}>
                        {`${productionDetail.appropriatedQuantity.toLocaleString(
                          "pt-br"
                        )} h`}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>
                        Horas trabalhadas em projetos PDI
                      </h4>
                      <p className={"counterStatusAttendance"}>
                        {`${productionDetail.appropriatedQuantityForRDI.toLocaleString(
                          "pt-br"
                        )} h`}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>
                        Quantidades de atendimentos de PDI
                      </h4>
                      <p className={"counterStatusAttendance"}>
                        {productionDetail.countRDIAttendances}
                      </p>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              {/* Receita */}
              <Grid className={"contentGeneralRevenue"}>
                {revenueDetail.entitiesServed !== undefined && (
                  <Grid className={"contentBlockGeneral"}>
                    <h2 className={"labelContent"}>Receita</h2>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>Receita apropriada</h4>
                      <p className={"counterStatusAttendance"}>
                        {revenueDetail.appropriateRevenue.toLocaleString(
                          "pt-br",
                          { style: "currency", currency: "BRL" }
                        )}
                      </p>
                    </Grid>
                    <Grid className={"fieldAlignmentStatusAttendance"}>
                      <h4 className={"statusAttendance"}>
                        Entidades atendidas
                      </h4>
                      <p className={"counterStatusAttendance"}>
                        {revenueDetail.entitiesServed.toLocaleString("pt-br")}
                      </p>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <GenericButton
                color={"darkBlue-outline"}
                label={"Ver lista de atendimentos"}
                subClass={"basic-button"}
                click={() =>
                  getAttendances(
                    1,
                    selectedUnity !== null
                      ? selectedUnity
                      : selectedDepartment !== null
                      ? selectedDepartment
                      : selectedTechnologicalGroup !== null
                      ? isiList
                      : null,
                    selectedUnity !== null
                      ? "AttendanceUO"
                      : selectedDepartment !== null
                      ? "AttendanceDR"
                      : selectedTechnologicalGroup !== null
                      ? "AttendanceTG"
                      : ""
                  )
                }
              />
            </Grid>
            {/*  */}
          </Grid>
        </Grid>

        {/* Modal Attendance */}
        {openModal === "attendance" && (
          <SimpleModal
            isOpen={openModal === "attendance"}
            handleClose={() => setOpenModal("close")}
            type={"info"}
            disableClick={true}
            width={"90%"}
          >
            <div className={"modal"}>
              <h2>Todos os atendimentos - {selectedYear.year}</h2>
              <Grid container>
                <Grid item xs={12} style={{ marginTop: "15px" }}>
                  <Table id="attendanceModal">
                    <TableHead>
                      <TableRow>
                        <TableCell>Nº</TableCell>
                        <TableCell>Título</TableCell>
                        <TableCell>Entidade atendida</TableCell>
                        <TableCell className="dataFormat">
                          Data de início
                        </TableCell>
                        <TableCell className="dataFormat">Data final</TableCell>
                        <TableCell>Status</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listAttendances !== undefined ? (
                        listAttendances.map((attendances) => (
                          <TableRow>
                            <TableCell>
                              {attendances.numberOfAttendance}
                            </TableCell>
                            <TableCell>{attendances.title}</TableCell>
                            {attendances.client !== null ? (
                              <TableCell>
                                {attendances.client.corporateName}
                              </TableCell>
                            ) : (
                              <TableCell>{""}</TableCell>
                            )}
                            <TableCell className="data">
                              {new Intl.DateTimeFormat("pt-BR", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }).format(
                                new Date(
                                  attendances.expectedStartDate.split("T")[0]
                                )
                              )}
                            </TableCell>
                            <TableCell className="data">
                              {new Intl.DateTimeFormat("pt-BR", {
                                day: "numeric",
                                month: "long",
                                year: "numeric",
                              }).format(
                                new Date(
                                  attendances.expectedConclusionDate.split(
                                    "T"
                                  )[0]
                                )
                              )}
                            </TableCell>
                            <TableCell
                              className={
                                statusAttencandeMatch[
                                  attendances.attendanceStatus.description
                                ] || statusAttencandeMatch.default
                              }
                            >
                              <span></span>
                              {attendances.attendanceStatus.description}
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow></TableRow>
                      )}
                    </TableBody>
                  </Table>
                  <div className={"paginationContent"}>
                    <p>{`${from}-${to} de ${total}`}</p>
                    <IconButton
                      onClick={() => {
                        getAttendances(
                          1,
                          selectedUnity !== null
                            ? selectedUnity
                            : selectedDepartment !== null
                            ? selectedDepartment
                            : selectedTechnologicalGroup !== null
                            ? isiList
                            : null,
                          selectedUnity !== null
                            ? "AttendanceUO"
                            : selectedDepartment !== null
                            ? "AttendanceDR"
                            : selectedTechnologicalGroup !== null
                            ? "AttendanceTG"
                            : ""
                        );
                      }}
                      disabled={actualPage === 1}
                    >
                      <FirstPageIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        getAttendances(
                          previousPage,
                          selectedUnity !== null
                            ? selectedUnity
                            : selectedDepartment !== null
                            ? selectedDepartment
                            : selectedTechnologicalGroup !== null
                            ? isiList
                            : null,
                          selectedUnity !== null
                            ? "AttendanceUO"
                            : selectedDepartment !== null
                            ? "AttendanceDR"
                            : selectedTechnologicalGroup !== null
                            ? "AttendanceTG"
                            : ""
                        );
                      }}
                      disabled={previousPage === null && actualPage === 1}
                    >
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        getAttendances(
                          nextPage,
                          selectedUnity !== null
                            ? selectedUnity
                            : selectedDepartment !== null
                            ? selectedDepartment
                            : selectedTechnologicalGroup !== null
                            ? isiList
                            : null,
                          selectedUnity !== null
                            ? "AttendanceUO"
                            : selectedDepartment !== null
                            ? "AttendanceDR"
                            : selectedTechnologicalGroup !== null
                            ? "AttendanceTG"
                            : ""
                        );
                      }}
                      disabled={
                        page === totalPages || actualPage === totalPages
                      }
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        getAttendances(
                          totalPages,
                          selectedUnity !== null
                            ? selectedUnity
                            : selectedDepartment !== null
                            ? selectedDepartment
                            : selectedTechnologicalGroup !== null
                            ? isiList
                            : null,
                          selectedUnity !== null
                            ? "AttendanceUO"
                            : selectedDepartment !== null
                            ? "AttendanceDR"
                            : selectedTechnologicalGroup !== null
                            ? "AttendanceTG"
                            : ""
                        );
                      }}
                      disabled={actualPage === totalPages}
                    >
                      <LastPageIcon />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </div>
          </SimpleModal>
        )}
        {/* Modal SearchAdvanced */}
        <SimpleModal
          isOpen={openModal === "searchAdvanced"}
          handleClose={() => {
            cleanSearch();
            setOpenModal("close");
          }}
          type={"info"}
          disableClick={true}
          width={"40rem"}
        >
          <div className={"modal"}>
            <h2>Busca avançada</h2>
            <Grid className={"containerFields"}>
              <Grid className={"yearField"}>
                <InputSelect
                  label={"Ano"}
                  suggestions={yearList}
                  itemKey={"year"}
                  itemLabel={"year"}
                  initialSelectedItem={selectedYear || { year: actualYear }}
                  getInput={(event) => {
                    if (event != null) {
                      setSelectedYear(event);
                    }
                  }}
                  id={"ano"}
                />
              </Grid>

              {typeUser !== "UO" && (
                <Grid>
                  <Grid className={"contentFieldModal"}>
                    <h3 className={"labelModalSearchAdvanced"}>
                      Departamentos Regionais
                    </h3>
                    <AutocompleteMult
                      multiple
                      id={"id"}
                      options={departmentListSuggestions || []}
                      groupBy={(option) => option.abbreviation}
                      getOptionLabel={(option) => option.description || ""}
                      onChange={addListDepartments}
                      value={departmentList}
                      disabled={
                        unityList.length > 0 ||
                        technologicalGroupList.length > 0
                      }
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />

                    {departmentList.length > 0 && (
                      <div className="SelectedTags">
                        <p>Departamento(s) selecionado(s): </p>
                        {departmentList.map((departament) => (
                          <div
                            className={"floatTags"}
                            key={departament.description}
                          >
                            <p>{departament.description}</p>
                            <span onClick={() => removeFloatTag(departament)}>
                              <img
                                alt={"imagem-fechar"}
                                style={{ width: 14, height: 14 }}
                                src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}
                              ></img>
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </Grid>

                  <Grid className={"contentFieldModal"}>
                    <h3 className={"labelModalSearchAdvanced"}>
                      Grupo Tecnológico
                    </h3>
                    <AutocompleteMult
                      multiple
                      options={technologicalGroupListSuggestions || []}
                      getOptionLabel={(option) => option.name || ""}
                      onChange={addListTechnologicalGroup}
                      disabled={
                        departmentList.length > 0 || unityList.length > 0
                      }
                      value={technologicalGroupList || []}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />
                  </Grid>
                  <GenericButton
                    label={"Criar novo grupo"}
                    color={"darkBlue"}
                    subClass={"basic-button"}
                    click={() => {
                      setSelectedUnity([]);
                      setOpenModal("createGroup");
                    }}
                  />

                  {technologicalGroupList.length > 0 && (
                    <div className="SelectedTags">
                      <p>Grupo(s) selecionado(s): </p>
                      {technologicalGroupList.map((group) => (
                        <div className={"floatTags"} key={group.name}>
                          <p>{group.name}</p>
                          <span onClick={() => removeFloatTag(group)}>
                            <img
                              alt={"imagem-fechar"}
                              style={{ width: 14, height: 14 }}
                              src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}
                            ></img>
                          </span>
                        </div>
                      ))}
                    </div>
                  )}

                  <Grid className={"contentFieldModal"}>
                    <h3 className={"labelModalSearchAdvanced"}>
                      Unidades Operacionais
                    </h3>
                    <AutocompleteMult
                      multiple
                      id={"id"}
                      options={unitsListSuggestions || []}
                      groupBy={(option) =>
                        `Unidades Operacionais de ${option.city.federatedUnity.acronym}`
                      }
                      getOptionLabel={(option) => option.description || ""}
                      onChange={addListUnity}
                      disabled={
                        technologicalGroupList.length > 0 ||
                        departmentList.length > 0
                      }
                      value={unityList}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" />
                      )}
                    />

                    {unityList.length > 0 && (
                      <div className="SelectedTags">
                        <p>Unidades(s) selecionado(s): </p>
                        {unityList.map((unity) => (
                          <div className={"floatTags"} key={unity.description}>
                            <p>{unity.description}</p>
                            <span onClick={() => removeFloatTag(unity)}>
                              <img
                                alt={"imagem-fechar"}
                                style={{ width: 14, height: 14 }}
                                src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}
                              ></img>
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
              <Grid className={"buttonGroup"}>
                <Grid className="buttonOptions">
                  <GenericButton
                    color={"darkGrey-outline"}
                    label={"Cancelar"}
                    subClass={"basic-button"}
                    click={cleanSearch}
                  />
                </Grid>

                <GenericButton
                  label={"Buscar"}
                  color={"darkBlue"}
                  subClass={"basic-button"}
                  click={() => {
                    setShowLoading(true);
                    getDataReport(
                      selectedUnity !== null
                        ? selectedUnity
                        : selectedDepartment !== null
                        ? selectedDepartment
                        : selectedTechnologicalGroup !== null
                        ? selectedTechnologicalGroup
                        : null,
                      selectedUnity !== null
                        ? "UO"
                        : selectedDepartment !== null
                        ? "DR"
                        : selectedTechnologicalGroup !== null
                        ? "TG"
                        : ""
                    );
                    setOpenModal(false);
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </SimpleModal>

        <SimpleModal
          isOpen={openModal === "createGroup"}
          handleClose={() => {
            cleanSearch();
            setOpenModal("close");
          }}
          type={"info"}
          disableClick={true}
          width={"34%"}
        >
          <div className={"modal"}>
            <h2>Criar novo grupo</h2>
            <Grid container>
              <Grid item xs={12} style={{ marginTop: "15px" }}>
                <FormField
                  label={"Nome do grupo"}
                  type={"text"}
                  id={"nome"}
                  inputValue={nameGroup}
                  changeValue={changeNameGroup}
                  maxLength={"45"}
                  required={true}
                />
                <Grid className={"contentFieldModal"}>
                  <h3 className={"labelModalSearchAdvanced"}>
                    Unidades Operacionais
                  </h3>
                  <AutocompleteMult
                    multiple
                    id={"id"}
                    options={unitsListSuggestions || []}
                    groupBy={(option) =>
                      `Unidades Operacionais de ${option.city.federatedUnity.acronym}`
                    }
                    getOptionLabel={(option) => option.description || ""}
                    onChange={addListUnityGroup}
                    value={unityGroupList}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />

                  {unityGroupList.length > 0 && (
                    <div className="SelectedTags">
                      <p>Unidades(s) selecionado(s): </p>
                      {unityGroupList.map((unityGroup) => (
                        <div
                          className={"floatTags"}
                          key={unityGroup.description}
                        >
                          <p>{unityGroup.description}</p>
                          <span onClick={() => removeFloatTag(unityGroup)}>
                            <img
                              alt={"imagem-fechar"}
                              style={{ width: 14, height: 14 }}
                              src={`${process.env.PUBLIC_URL}/assets/icons/close.svg`}
                            ></img>
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                </Grid>

                <Grid className={"buttonGroup"}>
                  <GenericButton
                    color={"darkGrey-outline"}
                    label={"Cancelar"}
                    subClass={"basic-button"}
                    click={cleanFieldCreateGroup}
                  />

                  <GenericButton
                    label={"Salvar"}
                    color={"darkBlue"}
                    subClass={"basic-button"}
                    disabled={
                      unityGroupList.length !== 0 && nameGroup !== ""
                        ? false
                        : true
                    }
                    click={createTechnologicalGroup}
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>
        </SimpleModal>
      </Page>
    </div>
  );
};
