import React, { Component } from 'react'
import { Grid } from "@material-ui/core";
import API from '../../comm/API'
import InputSelect from '../../components/inputselect/inputselect';

export default class Situacao extends Component {

    atendimento = this.props.atendimento;
    anoList = [];
    fatoStatusSIList = [];

    constructor(props) {
        super(props);
        this.state = {}
        this.getAnoList();
        this.getSituacaoList(this.props.atendimento.id);
    }
    
    mesList = [
        { type: 'mesSituacao', value: '01', label: "Janeiro" },
        { type: 'mesSituacao', value: '02', label: "Fevereiro" },
        { type: 'mesSituacao', value: '03', label: "Março" },
        { type: 'mesSituacao', value: '04', label: "Abril" },
        { type: 'mesSituacao', value: '05', label: "Maio" },
        { type: 'mesSituacao', value: '06', label: "Junho" },
        { type: 'mesSituacao', value: '07', label: "Julho" },
        { type: 'mesSituacao', value: '08', label: "Agosto" },
        { type: 'mesSituacao', value: '09', label: "Setembro" },
        { type: 'mesSituacao', value: '10', label: "Outubro" },
        { type: 'mesSituacao', value: '11', label: "Novembro" },
        { type: 'mesSituacao', value: '12', label: "Dezembro" }
    ]
    
    anoSituacao = new Date().getFullYear();
    mesSituacao = new Date().getMonth() + 1 < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
    
    getAnoList = () => {
        for (var i = new Date().getFullYear(); i >= 2015; i--) {
            this.anoList.push({ type: 'anoSituacao', value: i });
        }
    }
    
    changeSituacao = (e) => {
        if (e != null && e.type != null) {
            this[e.type] = e.value;
            this.getSituacaoList(this.atendimento.id);
        }
    }
    getSituacaoList = (id) => {
        var filter = {};
        filter["idAtendimento"] = id;
        filter["anoMes"] = this.mesSituacao + "-" + this.anoSituacao;
        filter["ordenarPor"] = "calendario.data DESC";
        
        API.get('/rest/bi/cubo/FatoStatusAtendimentoSI', {
            params: filter
        }).then(res => {
            this.fatoStatusSIList = res.data;
            this.setState(this.state);
        })
    };

    render() {
        return (
            <Grid item xs={12} md={6}>
                <div className={'greyDiv'}>
                    <h2>Histórico de Situação</h2>
                    <Grid container>
                        <Grid item xs={6}>
                            <InputSelect
                                suggestions={this.anoList}
                                itemKey={'value'}
                                itemLabel={'value'}
                                id={'anoSituacao'}
                                getInput={this.changeSituacao}
                                initialSelectedItem={{ value: this.anoSituacao }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputSelect
                                suggestions={this.mesList}
                                itemKey={'value'}
                                itemLabel={'label'}
                                id={'mesSituacao'}
                                getInput={this.changeSituacao}
                                initialSelectedItem={{ value: this.mesSituacao }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {this.fatoStatusSIList.length === 0 ?
                                <p>Não existem registros para esta data.</p>
                                :
                                this.fatoStatusSIList.map(fato => (
                                    <p key={fato.id}>{fato.calendario.dataCurta}: {fato.atendimentoStatusSI.descricao}</p>
                                ))
                            }
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        )
    }
}