import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import SimpleModal from '../modal/modal';
import Slider from 'react-slick';
import { BASE_URL } from '../../comm/API';
import './expand.scss';

export default function Expand({ turma, fase, title, anexos }) {

  const [ toggleModal, setToggleModal ] = useState(false);
  const [ anexoList, setAnexoList ] = useState([]);

  let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
    ]
  };

  const openModal = (toggle) =>{
    setToggleModal(toggle);
    let anexosJSX = anexos.map((anexo, index) => (
      <div key={'carrossel-card-' + index} className="expand-modal-content">
        <img alt={anexo.nomeArquivo} src={BASE_URL + anexo.url} className="expand-modal-image" />

        <div className="expand-modal-description">
          <p>{anexo.descricao}</p>
        </div>
      </div>
    ));

    setAnexoList(anexosJSX);
  }

  useEffect(() => {
    console.log('anexos',anexos)
    const anexosJSX = anexos.map((anexo, index) => (
      <div key={'carrossel-card-' + index} className="expand-modal-content">
        <img alt={anexo.nomeArquivo} src={BASE_URL + anexo.url} className="expand-modal-image" />

        <div className="expand-modal-description">
          <p>{anexo.descricao}</p>
        </div>
      </div>
    ));

    setAnexoList(anexosJSX);
  }, [])


  return (
    <div className="container-expand">
      <img 
        src={process.env.PUBLIC_URL + '/assets/icons/expand.svg'} 
        onClick={() => openModal(true)}
        className="container-expand-icon"
      />
      <SimpleModal
        isOpen={toggleModal}
        handleClose={() => openModal(false)}
        type={'info'}
        width={'1000px'}
      >
        <Grid container>
          <Grid container className="expand-header">
            <Grid item xs={1} className="expand-icon-fase">
              <div className={'expand-icon-etapa-blue'}><span>{fase}</span></div>
            </Grid>

            <Grid item xs={10} className="expand-icon-info">
              <label>{turma.nome}</label>

              <h1>{title}</h1>
            </Grid>
          </Grid>

          <Grid container alignItems="center" justifyContent="center" className="expand-carousel-container">
            {anexoList && (
              <div style={{ width: '763px', maxWidth: '763px'}}>
                <Slider {...settings}>
                    {anexoList}
                </Slider>
              </div>

            )}
          </Grid>

        </Grid>
      </SimpleModal>
         
    </div>
  )
}