import React, { Component } from 'react';
import { Grid, Switch } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import HC_exporting from 'highcharts/modules/exporting'
import offlineExporting from "highcharts/modules/offline-exporting"
import API from '../../../comm/API';


export default class GraficoCadastradosRecebidos extends Component {

    estados = { 
        'AC': 'Acre', 'AL': 'Alagoas', 'AM': 'Amazonas', 'AP': 'Amapá', 'BA': 'Bahia', 'CE': 'Ceará',
        'DF': 'Distrito Federal', 'ES': 'Espírito Santo', 'GO': 'Goiás', 'MA': 'Maranhão', 'MG': 'Minas Gerais',
        'MS': 'Mato Grosso do Sul', 'MT': 'Mato Grosso', 'PA': 'Pará', 'PB': 'Paraíba', 'PE': 'Pernambuco',
        'PI': 'Piauí', 'PR': 'Paraná', 'RJ': 'Rio de Janeiro', 'RN': 'Rio Grande do Norte', 'RO': 'Rondônia', 
        'RR': 'Roraima', 'RS': 'Rio Grande do Sul', 'SC': 'Santa Catarina', 'SE': 'Sergipe', 'SP': 'São Paulo', 'TO': 'Tocantins'
    };

	constructor(props) {
		super(props);

		this.state = {           

            dados : {digital : undefined, lean: undefined, ambos: undefined},
			total: {digital : undefined, lean: undefined, ambos: undefined},
		};

		this.carregarDados();

	}

	
    fillGraficoAtendimento = async () => {
        let digital = [];
		let lean = [];
		let ambos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/countEmpresasCadastradas`).then(async res => {
            let dataD = [];
			let dataL = [];
			let dataLD = [];
			let categories = Object.keys(this.estados);
            if (res.data != null ) {
                await categories.forEach(estado => {
                    dataD.push(res.data.digital[estado]);
					dataL.push(res.data.lean[estado]);
					dataLD.push(res.data.ambos[estado]);
                });
                digital.push({
                        name: '',
                        data: dataD,
                        color: '#00B1E8'
                    })
                ;
				lean.push({
					name: '',
					data: dataL,
					color: '#00B1E8'
				})
				;
				ambos.push({
					name: '',
					data: dataLD,
					color: '#00B1E8'
				})
				;	
                
                let totalL = dataL.reduce((partialSum, a) => partialSum + a, 0);
				let totalD = dataD.reduce((partialSum, a) => partialSum + a, 0);
				let totalLD = dataLD.reduce((partialSum, a) => partialSum + a, 0);
                this.setState({ dados : {digital: digital, lean:lean, ambos: ambos}, total: {lean :totalL, digital : totalD, ambos:totalLD}});
            }
        }).catch(error => {
            console.log(error);
        });
    };

	carregarDados = async () => {
		await this.fillGraficoAtendimento();
	}

	


	create = (fase) => {

        let categories = Object.keys(this.estados);

		let series = [];

		let subtitle;
		let total;


		if(fase == "L"){
			series= this.state.dados.lean;
			subtitle = {
				enabled:true,
				text: `Fase 1: Mentoria Lean`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 60				
			}
			total = this.state.total.lean;
		}
		else if(fase == "D"){
			series= this.state.dados.digital;
			subtitle = {
				enabled:true,
				text: `Fase 2: Mentoria Digital`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 60				
			}
			total = this.state.total.digital;
		}
		else{
			series= this.state.dados.ambos;
			subtitle = {
				enabled:true,
				text: `Brasil Mais`,
				style: {
					'font': '16px Raleway',
					'color': '#00B1E8',
				},
                y: 60				
			}
			total = this.state.total.ambos;
		}

		

		return {
			chart: {
                type: 'column',
                style: {
                    fontFamily: 'Raleway'
                },
				height:  500 ,
				plotBackgroundColor: null,
				plotBorderWidth: 0,
				plotShadow: false,
				marginTop : 100
			},
			title: {
				enabled:true,
				text: `CADASTROS RECEBIDOS PELO  <br/> PORTAL DO PROGRAMA POR DR`,
				style: {
					fontFamily: 'Raleway',
					font: "20px",
					fontWeight: 600,

					'color': '#077CDC',
				},
                margin: 80
				
			},
            fullCategories: this.estados,
            xAxis: {
                lineColor: '#707070',
                lineWidth: 0.5,
                categories: categories,
                labels: {
                    style: {
                        color: '#707070'
                    }
                },
            },
			subtitle: subtitle,
			tooltip: {
				headerFormat: '<b>Cadastros recebidos</b><br/>',
                pointFormat: '{point.category}: {point.y}<br/>Nº Total de Atendimentos:' + total
					},
			yAxis: {
                lineColor: '#707070',
                lineWidth: 0,
                min: 0,
                title: {
                    text: ''
                },
                labels: {
                    style: {
                        color: '#707070'
                    }
                }
            },
			plotOptions: {
				column: {
					dataLabels: {
						enabled: true,
						style: {
							font: 'Raleway',
							fontSize: '15px !important',
							color: '#707070'
						}
					},
					showInLegend: false
				},				
			},
			series: series,
			credits: {
				enabled: false
			},
			exporting: {
				menuItemDefinitions: {
					// Custom definition
					printChart: {
						text: 'Imprimir gráfico'
					},
					downloadPNG:{
						text: 'Baixar como PNG'
					},
					downloadJPEG:{
						text: 'Baixar como JPEG'
					},
					downloadSVG:{
						text: 'Baixar como SVG'
					},
					downloadCSV:{
						text: 'Baixar tabela de dados'
					}
				},
                subtitle :{
                    style: {
                        'margin-top': '30',
                    }				
                },
                chart: {
                    marginTop: '150'
                },
                subtitle:{
                    y: 0
                },
				fallbackToExportServer: false,
				buttons: {
					contextButton: {
						menuItems:[ "printChart", "downloadCSV", "separator", "downloadPNG", "downloadJPEG", "downloadSVG"]
					}
				}
			}
		};
	}

	render() {

		let GraphL =  this.create('L');
		let GraphD =  this.create('D');
		let GraphLD =  this.create('LD');

		return (
			<Grid container justify={'center'} style={{height:'100%'}} className={'dashboard-porte-setor border-cinza'}>
				<Grid item xs={12}>
					<div style={{ margin: '40px', marginBottom: '30px' }}>
						{this.props.fase == 'L' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphL} />}
						{this.props.fase == 'D' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphD} />}
						{this.props.fase == 'LD' && <HighchartsReact
							highcharts={Highcharts}
							options={GraphLD} />}
					</div>
				</Grid>
			</Grid>
		);
	}
}