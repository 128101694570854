import { Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { maskMoney } from '../../comm/masks';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import EmptyList from '../../components/emptyList/emptyList';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import InputSelect from '../../components/inputselect/inputselect';
import SimpleModal from '../../components/modal/modal';
import Page from '../../components/page/page';
import ProgressBar from '../../components/progressbar/progressbar';
import API from './../../comm/API';
import { mesList } from './../../comm/variables';
import CustomTable from './../../components/table/table';
import './receita.scss';

export default class Receita extends Component {

    producaoApropriadaList = []
    percentualTotal = 0;

    menuItems = ['Atividade', 'Data Prevista'];
    propertiesList = ["label", "dataEntrega"];
    
    titles = [
        { id: '0', canOrder: true, label: 'N°' },
        { id: '1', canOrder: true, label: 'Entidade atendida' },
        { id: '2', canOrder: true, label: 'Título' },
        { id: '3', canOrder: false, label: 'Produção Apropriada' },
        { id: '4', canOrder: false, label: 'Realizado/previsto' },
        { id: '5', canOrder: false, label: '' }]; 
        
    conditionalPayment = [];

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    }

    constructor(props) {
        super(props);
        var data = new Date().toLocaleDateString().split('/');
        this.state = {
            dataApropriacao: data[2] + '-' + data[1] + '-' + data[0],
            listProdutosFiltrados: [], valuesFiltro: [], receitaAtual: '',
            filtro: '', previsaoReceitaList: [], total: 0, entregas: [],
            valor: [], isDeclareFontePagadora: false, valueIsDeclareFontePagadora:0,
            fontesPagadoras: [], payment: {id: '', descricao: ''}, deliveries: [], 
            isPendente: false, isEnvioPendent: false, 
            openModalConfirmacao: false, openModalHistorico: false,  listHistoricoReceita: [], 
            ano: new Date().getFullYear(), anoList: [], receita: {}, dataInicio: this.getDataInicio(),
            dataFim: this.getDataFim(), sumProducaoApropriada: 0, sumReceitaApropriada: 0,
            emptyList: 'noItens', mesApropriacao: {}, recMesList: [], openModalBuscaAvancada: false, valuesEntrega: [], 
            openModalApropriarReceita: false, openModalConfirmFinanceiro: false, entregasList: [],  listProdutos: [], totalReceitaRealizada: 0
        };
        
        this.handleChangePaymentSources = this.handleChangePaymentSources.bind(this)
        this.handleSubmitPaymentSources = this.handleSubmitPaymentSources.bind(this);
    };
    
    transformCurrencyStringToNumber = (value) => {
        return Number(value.toString().substring(2).replace(',', '.'));
    }

    initDataApropriacao = () => {
        var data = new Date();
        var mes = data.getMonth();
        var dia = data.getDate();
        let recMesList = [];

        if (dia >= 1 && dia <= 7) {
            if (mes === 0) {
                recMesList.push(mesList[11]);
                recMesList.push(mesList[mes]);

            } else {
                recMesList.push(mesList[mes - 1]);
                recMesList.push(mesList[mes]);
            }
        } else {
            recMesList.push(mesList[mes]);
        }
        this.setState({ recMesList: recMesList,  mesApropriacao: { id: new Date().getMonth() } });
    }

    changeData = async (e) => {
        var novaData = e.target.value.split('-');
        var isInvalid = false;
        this.producaoApropriadaList.forEach(obj => {
            let dataAceitacao = new Date(obj.atendimento.dataAceitacao).toLocaleDateString('fr-CA'); // fr-CA para formatar em yyyy-mm-dd
            if (e.target.value < dataAceitacao) {
                isInvalid = true;
            }
        }) 

        this.setState({ dataInvalida: isInvalid ? 'Não é possível apropriar devido à data de aceitação de alguns atendimentos.' : '' });
        await this.setState({ dataApropriacao: novaData[0] + '-' + novaData[1] + '-' + novaData[2] });
    }
    envioFaturamentoAtendimentoModel(receita) {
        return {
            proposta: { id: receita.idProposta }, atendimento: { id: receita.idAtendimento, numero: receita.numeroAtendimento }, 
            receitaFaturamentoList: [], agruparProposta: 'N', condicaoPagamento: '',
            receita: receita, valor: receita.valorReceitaFaturamentoNaoEnviado, justificativa: receita.justificativa, 
            condicaoPag: this.state.payment, entregaFaturamentoList: []
        };
    }

    getEnvioFaturamentoModel() {
        return { email: '', usuario: this.props.usuarioConectado, conteudo: '', titulo: '', data: new Date(), envioFaturamentoAtendimentoList: [], emailFaturamentoList: [], status: '0' };
    }

    getCondicaoPagamento = () => {
        API.get('rest/auxiliar/CondicaoPagamento').then(res => {
            this.conditionalPayment = res.data;
            this.setState({ payment: res.data && !!res.data.length ? res.data[0] : {id: '', descricao: ''}});
        }).catch(error => {
            this.showtoast('Erro ao realizar GET das condições de pagamento. Favor, entre em contato com o suporte.', 'error');
        });
    }

    componentWillMount() {
        this.getCondicaoPagamento();
        this.setAnoList();
        this.getProdutos();
        this.initDataApropriacao();
        
    }

    getUnidade= () => {
        var idUnidade = 0;
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            idUnidade = this.props.usuarioConectado.colaborador.unidade.id;
        } else {
            idUnidade = this.props.usuarioConectado.unidade.id;
        }
        return idUnidade;
    }
    
    getProdutos = async () => {
        var idUnidade = this.getUnidade();

        this.showLoading();
        try {
            let listProdutos = await API.get(`rest/gestao/ReceitaFaturamento/atendimentos?idUnidade=` + idUnidade + `&buscaLivre=${this.state.filtro}`);  
            await this.setState({ listProdutos: listProdutos.data });
            this.filtarProdutos();
            this.closeLoading();
        } catch (error) {
            this.closeLoading();
            this.showToast('Erro ao exibir a lista de atendimentos disponíveis para apropriação. Por favor entre em contato com o suporte', 'error');
        }
    }

    salvarReceita = () => {
        let list = [];
        this.state.listProdutos.forEach(rec => {
            if (rec.changed && rec.valorReceitaFaturamentoNaoEnviadoDigitado > 0) {
                list = list.concat(this.receitaFaturamentoModel(rec));
            }
        });
        
        API.post('rest/gestao/ReceitaFaturamento/apropriarReceitas', list).then(res => {
            let qtdCorretos = res && res.data && res.data.qtdCorretos ? res.data.qtdCorretos : null;
            let qtdErros = res && res.data && res.data.qtdErros ? res.data.qtdErros : null;
            this.exibirMensagem(qtdCorretos, qtdErros, list.length);
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao tentar enviar receita. Por favor, entre em contato com o suporte.', 'error');
        });
    }

    salvarReceitaModal = async () => {

        let receita = {
            data: this.getDataApropriacao(),
            dataAtualizacao: new Date(),
            atendimento: {id: this.state.receitaAtual.idAtendimento},
            usuario: { id: this.props.usuarioConectado.id },
            condicaoPagamento: { id: this.state.payment.id },
            receitaFaturamentoEntregaList: this.state.deliveries.map(entreg => {
                return { entrega: entreg }
            }),
            receitaFaturamentoFontePagadoraList: this.state.previsaoReceitaList.map((fonte, index) =>{
                return {
                    valor: this.state.fontesPagadoras[index] ?
                        this.transformCurrencyStringToNumber(this.state.fontesPagadoras[index].value) : 0, 
                    fontePagadora: fonte.sistemaIndustria ? fonte.sistemaIndustria : fonte.fontePagadora

                };
            }).filter(filteredReceita => filteredReceita),
            valor: this.state.fontesPagadoras
                .map(fonte => typeof fonte.value === 'number' ? fonte.value : this.transformCurrencyStringToNumber(fonte.value))
                .reduce((value1, value2) => { return value1 + value2 })
        }
      
        try {
            await API.post('rest/gestao/ReceitaFaturamento/apropriarReceitas', receita);

            this.setState({ openModalApropriarReceita: false, valueIsDeclareFontePagadora: 0 });
            this.showToast('Receita(s) salva(s) com sucesso!', 'success');
            await this.getProdutos();
        } catch (error) {
            this.showToast('A(s) Receita(s) não foram salva(s), por favor entre em contato com o suporte.', 'error');
        }
    }
    
    enviarParaFinanceiro = async () => {
        try {
           
           let idUnidade = this.getUnidade();
           await API.get(`rest/gestao/EnvioFaturamentoAtendimento/enviarParaFinanceiro/${idUnidade}`);
           
            this.setState({ openModalConfirmFinanceiro: false })
            this.showToast('As receitas foram enviadas para o financeiro com sucesso!', 'success');
            await this.getProdutos();
        } catch (error) {
            this.showToast('Infelizmente, as receitas não foram enviadas para o financeiro, por favor entre em contato com o suporte.', 'error');
        }
    }   
    


    exibirMensagem(qtdCorretos, qtdErros, listLength) {
        this.setState({ openModalConfirmacao: false, openModalHistorico: false });
        this.closeLoading();
        if (qtdCorretos === listLength) {
            this.showToast('Receita(s) salva(s) com sucesso!', 'success');
        } else if (qtdErros === listLength) {
            this.showToast('A(s) Receita(s) não foram salva(s), por favor entre em contato com o suporte.', 'error');
        } else if (qtdCorretos && qtdErros) {
            this.showToast(`${qtdCorretos} receita(s) foram salva(s) com sucesso, ${qtdErros}, não foram salvas, por favor entre em contato com o suporte.`, 'warning');
        } else {
            this.showToast('Usuário inválido.', 'error');
        }
        this.getProdutos();
    }

    alterarReceita = () => {
        this.showLoading();
        let qtdErros = 0;
        let qtdCorretos = 0;

        let list = [];
        this.state.listHistoricoReceita.forEach((rec) => {
            if (rec.changed) {
                list.push(rec);
            }
        });
        if (list.length > 0) {
            if (this.validarAlteracao()) {
                list.forEach((prod) => {
                    API.post(`rest/gestao/ReceitaFaturamento/${prod.id}`, prod).then(res => {
                        qtdCorretos = qtdCorretos + 1;
                        if ((qtdCorretos + qtdErros) === list.length) {
                            this.exibirMensagem(qtdCorretos, qtdErros, list.length);
                        }
                    }).catch(error => {
                        qtdErros = qtdErros + 1;
                        if ((qtdCorretos + qtdErros) === list.length) {
                            this.exibirMensagem(qtdCorretos, qtdErros, list.length);
                        }
                    });
                });
            } else {
                this.closeLoading();
                this.showToast('O valor digitado ultrapassa a receita estimada do atendimento.', 'error');
            }
        } else {
            this.setState({ openModalConfirmacao: false, openModalHistorico: false });
            this.closeLoading();
            this.showToast('Nenhuma receita foi alterada.', 'error');
        }
    }

    validarAlteracao() {
        let somaRceitasApropriadas = 0;
        this.state.listHistoricoReceita.forEach((rec) => {
            somaRceitasApropriadas += rec.valor;
        });
        if (somaRceitasApropriadas > this.state.receita.numeroReceitaEstimada) {
            return false;
        }
        return true;
    }

    showInputReceitaFaturamento(receita) {
        var rtno = <div />;
        if (receita.isValorHora || (receita.valorReceitaFaturamento < receita.numeroReceitaEstimada && !receita.isValorHora)) {
            return (
                <FormField className={'input-receita'} type={'number'} key={'key' + receita.valorReceitaFaturamentoNaoEnviadoDigitado} 
                    inputValue={receita.valorReceitaFaturamentoNaoEnviadoDigitado} changeValue={(e) => this.changeValorReceita(e, receita)} 
                    min='0' maxLength='15' subtype={'duascasas'} />
            );
        }
        if (receita.solicitacaoStatus === 'Em Análise') {
            rtno = <div />;
        }
        return rtno;
    }

    changeBusca = (e) => {
        this.setState({ filtro: e.target.value });
    }

    changeValorReceita = async (e, receita) => {
        let list = this.state.listProdutos;
        let index = list.findIndex(obj => obj.idAtendimento === receita.idAtendimento);
        if (index >= 0) {
            list[index].valorReceitaFaturamentoNaoEnviadoDigitado = Number(e.target.value);

            if (list[index].valorReceitaFaturamentoNaoEnviadoDigitado > 0) {
                list[index].changed = true;
            } else {
                list[index].changed = false;
            }
        }
        await this.setState({ listProdutos: list });
        this.filtarProdutos();
    }

    getTotalReceitaFaturamento = (receita) => {
        let somaReceita = 0;
        somaReceita += parseFloat(receita.valorReceitaFaturamentoEnviado);
        somaReceita += parseFloat(receita.valorReceitaFaturamentoNaoEnviado);
        somaReceita += parseFloat(receita.valorReceitaFaturamentoNaoEnviadoDigitado);
        if (isNaN(somaReceita)) {
            somaReceita = receita.totalProducao;
        }
        return somaReceita;
    }

    filtarProdutos() {
        let prodList = [];
        if (this.state.listProdutos && this.state.listProdutos.length > 0)
            prodList = this.state.listProdutos;
        this.createLine(prodList);
    }

    changeFiltro(valuesFiltro) {
        let tipoProduto = [];

        valuesFiltro.forEach((receitaFiltro) => {
            switch (receitaFiltro.descricao) {
                case 'Consultoria':
                    tipoProduto.push('1');
                    break;
                case 'Pesquisa, Desenvolvimento e Inovação':
                    tipoProduto.push('2');
                    break;
                case 'Serviços Técnicos Especializados':
                    tipoProduto.push('4');
                    break;
                case 'Metrologia':
                    tipoProduto.push('3');
                    break;
                case 'Serviços Complementares':
                    tipoProduto.push('6');
                    break;
                default:
                    tipoProduto.push('1');
            }
        });
        
        let prodList = [];
        if (this.state.listProdutos && this.state.listProdutos.length > 0)
            prodList = this.state.listProdutos.filter(prod => {
                return tipoProduto.includes(prod.idProdutoLinha);
            });
        this.createLine(prodList);
        this.setState({openModalBuscaAvancada: false});
    }

    

    setAnoList = () => {
        let anoList = []
        for (var i = this.state.ano; i >= 2007; i--) {
            anoList.push({ id: i, type: 'ano', label: i });
        }
        this.setState({ anoList: anoList });
    }

    getHistoricoReceitaApropriada = async (receita) => {
        this.showLoading();
        try {
            let listEntregas = await API.get(`/rest/gestao/Entrega/find?id=${receita.idAtendimento}`);
            const suggestionsEntregas = listEntregas.data.map((receita) => (
                {
                    id: receita[0],
                    canOrder: true,
                    label: `${receita[0]} - ${receita[1]}`,
                    dataEntrega: receita[2]
                }
            ));

            this.setState({ 
                listHistoricoReceita: [], openModalHistorico: true,
                receita: receita, ano: new Date().getFullYear(), entregas: suggestionsEntregas 
            });
        } catch (error) {
            this.closeLoading()
            this.showToast('O histórico de receitas apropriadas não pode ser gerado, por favor entre em contato com o suporte.', 'error');
        }
        this.loadingTime();
    }

    changeYear = (e) => {
        this.setState({ ano: e.label });
    }

    formatDataHistorico(dat) {
        let date = new Date(dat);
        let dataList = date.toLocaleDateString().split('/');
        return dataList[0] + '/' + dataList[1] + '/' + dataList[2]
    }

    getDataInicio() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), 1);
    }

    getDataFim() {
        return new Date(new Date().getFullYear(), new Date().getMonth(), this.daysInMonth(new Date().getMonth() + 1, new Date().getFullYear()));
    }

    daysInMonth(month, year) {
        return new Date(year, month, 0).getDate();
    }

    changeValorReceitaModal(e, receita) {
        let index = this.state.listHistoricoReceita.findIndex(rec => { return receita.id === rec.id });
        let list = this.state.listHistoricoReceita;
        list[index].valor = Number(e.target.value);
        list[index].changed = true;
        this.setState({ listHistoricoReceita: list });
    }

    getQuantidadeReceitaApropriada(item, index) {
        let date = new Date(item.data);
        if (date.getTime() >= this.state.dataInicio.getTime() && date.getTime() <= this.state.dataFim.getTime()) {
            return (<FormField inputValue={item.valor} type={'number'} changeValue={(e) => { this.changeValorReceitaModal(e, item) }} 
                id={item.id} min='0' maxLength='15' subtype={'duascasas'} />);
        } else {
            return `R$ ${item.valor}`;
        }
    }

    renderListProducao() {
        return (
            this.state.listHistoricoReceita.filter((item) => {
                return (new Date(item.data)).getFullYear() === this.state.ano;
            }).sort((a, b) => (a.id < b.id) ? 1 : -1)
                .map((item) => (
                    <Grid container key={item.id} className={'receita-list-item'}>
                        <Grid item xs={6}>{item.usuario ? item.usuario.nome : '-'}</Grid>
                        <Grid item xs={3}>{this.formatDataHistorico(item.data)}</Grid>
                        <Grid item xs={3}>{this.getQuantidadeReceitaApropriada(item)}</Grid>
                    </Grid>
                ))
        );
    }

    loadingTime(){
        setTimeout(this.showLoading());
        //Segura o load para que seja feita a requisição completa
    }

    async openModalApropriarReceita(receita){
        var idUnidade = this.getUnidade();
        
        this.showLoading();
        try {
            let receitaInfo = await API.get(`/rest/gestao/Atendimento/${receita.idAtendimento}`);
            const { data } = await API.get(`rest/gestao/ReceitaFaturamento/atendimentos?idUnidade=` + idUnidade + `&buscaLivre=${receita.idAtendimento}`);
            const { 
                previsoes,
                valorReceitaFaturamentoEnviado,
                valorReceitaFaturamentoNaoEnviado,
                valorReceitaFaturamentoNaoEnviadoDigitado,
                totalProducao
            } = data[0];
            previsoes.forEach(previsao => {
                previsao.value = 0;
            })


            const receitaRealizada = { 
                valorReceitaFaturamentoEnviado,
                valorReceitaFaturamentoNaoEnviado,
                valorReceitaFaturamentoNaoEnviadoDigitado,
                totalProducao
            }

            const totalReceitaRealizada = this.getTotalReceitaFaturamento(receitaRealizada);

            const fontesPagadoras = previsoes;

            const receitaListFiltered = receitaInfo.data.previsaoReceitaList.filter(previsaoReceita => previsaoReceita.valor > 0);

            this.setState({ fontesPagadoras });
            
            this.setState({previsaoReceitaList: receitaListFiltered});
            
            this.setState({receitaAtual: receita, openModalApropriarReceita: true, totalReceitaRealizada})
            
            this.calculateReceitaTotal();
        } catch (error) {
            this.closeLoading();
            this.showToast('Os dados da receita não pode ser gerado, por favor entre em contato com o suporte.', 'error');
        }
        
        this.closeLoading();
    }

    calculateReceitaTotal() {
        const totalReceita = this.state.previsaoReceitaList.reduce((acc, _, index) => {

            const fontesPagadorasValue = this.state.fontesPagadoras[index] ? this.state.fontesPagadoras[index].value : 0;

            const fonteNumber = typeof fontesPagadorasValue === 'number' ? 
                fontesPagadorasValue : 
                this.transformCurrencyStringToNumber(fontesPagadorasValue);
            return acc + fonteNumber;
        }, this.state.totalReceitaRealizada);
        this.setState({ total: totalReceita });
    }

    createLine(prodList) {
        let prodFiltrados = [];
        prodList.forEach(receita => {
            let produt = [];
            let producaoPrevistaTotal = receita.numeroDeRelatorioEstimado + receita.numeroDeProducaoEstimada;
            let producaoApropriadaTotal = receita.quantidadeRelatorioApropriado + receita.quantidadeHorasEnsaioApropriadas;
            const total = parseFloat(this.getTotalReceitaFaturamento(receita));
            const percentual = receita.numeroReceitaEstimada !== 0 ? (total / receita.numeroReceitaEstimada) : 1;
            const percentualTotal = Number((percentual * 100).toFixed(1));
           
            produt.push(receita.numeroAtendimento);
            produt.push(receita.nomeCliente);
            produt.push(receita.tituloAtendimento);
            produt.push(<ProgressBar 
                idAtendimento={receita.id} 
                total={producaoApropriadaTotal} 
                producao={producaoPrevistaTotal} 
                unitEnd={receita.isMetrologia ? '' : 'h'} 
            />);
            produt.push(<ProgressBar 
                idAtendimento={receita.id} 
                total={this.getTotalReceitaFaturamento(receita)} 
                producao={receita.numeroReceitaEstimada} 
                unitBegin={'R$'} 
                defaultClass={'bluePercent'} 
            />);


            produt.push(
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Link to={`/receita/historico/${receita.idAtendimento}`}>
                <GenericButton color={'darkBlue-outline'} label={'Histórico'} subClass={'basic-button'} disabled={!(total > 0)} />
                </Link>
                
                {percentualTotal >= 100 ? (
                    <GenericButton label={'Receita completa'} subClass={'button-disabled'} />
                    
                ) : (
                    <GenericButton color={'darkBlue'} label={'Apropriar receita'} subClass={'basic-button'} 
                    click={() => {this.openModalApropriarReceita(receita); this.getHistoricoReceitaApropriada(receita)}} />
                )}
            
            </div>
            
            );
             prodFiltrados.push(produt);
        });
        this.setState({ listProdutosFiltrados: prodFiltrados, isEnvioPendent: this.checkIfEnvioFinanceiroIsPendent(prodList) });
        this.setState({ emptyList: this.state.listProdutosFiltrados.length === 0 ? 'noItens' : '' });
    }

    validarCadastro() {
        let invalid = this.state.listProdutos.find(rec => {
            let somaReceita = parseFloat(rec.valorReceitaFaturamentoEnviado) + parseFloat(rec.valorReceitaFaturamentoNaoEnviado)
                + parseFloat(rec.valorReceitaFaturamentoNaoEnviadoDigitado);
            return rec.changed && (rec.valorReceitaFaturamentoNaoEnviadoDigitado < 0 ||
                somaReceita > rec.numeroReceitaEstimada || rec.isValorHora);
        });
        if (invalid) {
            this.showToast('O valor digitado no atendimento Nº ' + invalid.numeroAtendimento + ' é inválido ou ultrapassa o limite de receita.', 'error');
            return false;
        } else {
            return true;
        }
    }


    checkIfEnvioFinanceiroIsPendent = (atendimentos) => {
        const isPendent = atendimentos.some(atendimento => atendimento.valorReceitaFaturamentoNaoEnviado > 0);

        return !isPendent;
    }


    getUnidade() {
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            return this.props.usuarioConectado.colaborador.unidade;
        } else {
            return this.props.usuarioConectado.unidade;
        }
    }

    getDataMesAtual() {
        let date = new Date();
        let dataList = date.toLocaleDateString().split('/');
        return dataList[2] + '-' + dataList[1] + '-01';
    }

    getAtendimentoApropriadoList = () => {
        var envioFaturamento = this.getEnvioFaturamentoModel();
        this.state.listProdutos.forEach((item) => {
            if (item.valorReceitaFaturamentoNaoEnviado > 0) {
                envioFaturamento.envioFaturamentoAtendimentoList.push(this.envioFaturamentoAtendimentoModel(item));
            }
        });
        return { 'envioFaturamento': envioFaturamento, 'condicaoPagamentoList': this.conditionalPayment };
    }

    changeMes(e) {
        this.setState({ mesApropriacao: e });
    }

    getDataApropriacao() {
        let dataAtual = new Date();
        let ano = dataAtual.getFullYear();
        if(dataAtual.getMonth() === 0 && this.state.mesApropriacao.id === 11) {
            ano = ano - 1;
        }
        let dataApropriar = new Date(ano, this.state.mesApropriacao.id, 28);
        return dataApropriar;
    }
  
    changeAutoCompleteMany = (values) => {
        this.setState({valuesFiltro: values});
    }

    onTagsChange = (e = []) => {
        this.setState({ deliveries: e })
    };
    
    changeInputReceita(receita) {
        const currentReceita = receita.target.value;

    }

    formatDates = (date) => new Intl.DateTimeFormat("pt-BR", 
    { timeZone: "UTC" }).format(new Date(date)).toString();

    resetInputValuePrevisaoReceita = (index)  => {
        let fontes = this.state.fontesPagadoras;

        fontes[index].value = 0;

        this.setState({ fontesPagadoras: fontes});
    }
  
    handleChangePaymentSources(e, value, indexFontesPagadoras) {

        if (e.target) {
            let fontesPagadoras = this.state.fontesPagadoras;

            fontesPagadoras[indexFontesPagadoras].value = value;

            this.setState({  fontesPagadoras });

            this.calculateReceitaTotal();
        }
    }

    handleSubmitPaymentSources(e) {
        e.preventDefault();
    }

    getInputCheckbox = (e) => {
        if (e != null && e.target != null) {
            this.setState({ [e.target.value]: e.target.checked});
            
            const updatedFontes = this.state.fontesPagadoras.map(fonte => {
                fonte.value = 0;

                return fonte;
            })

            this.setState({ valueIsDeclareFontePagadora: 0, fontesPagadoras: updatedFontes });

            this.calculateReceitaTotal();
        }
    };

    apropriarRateio = (e, value) => {
        if (e != null && e.target != null && value) {
            this.setState({valueIsDeclareFontePagadora: value });
            var sumTotalRateio = 0;
            const updatedFontes = this.state.fontesPagadoras.map(fonte => {
                sumTotalRateio += fonte.rateio;
                fonte.rateio = sumTotalRateio < 1 ? fonte.rateio : (fonte.rateio - (sumTotalRateio - 1));
                const sumValueString = (this.transformCurrencyStringToNumber(value) * fonte.rateio).toFixed(2).toString();
                fonte.value = maskMoney(sumValueString);

                return fonte;
            });

            this.setState({ fontesPagadoras: updatedFontes });

            this.calculateReceitaTotal();
        }
    }

    getNomePrevisaoReceita = (previsaoReceita) => {
        if (previsaoReceita.tipoPrevisaoReceita) {
            switch(previsaoReceita.tipoPrevisaoReceita.id) {
                case 1://MERCADO
                case 2://FORMENTO
                    return previsaoReceita.fontePagadora.nome;
                case 3://SENAI
                    return 'Senai';
                case 5://ICT
                    return previsaoReceita.ictParceiro.descricao;
                case 7://AGENCIA NACIONAL
                    return previsaoReceita.agenciaNacional.descricao;
                case 8://AGENCIA DE FORMETO
                    return previsaoReceita.agenciaFomento.descricao;
                case 10://UO DO ATENDIMENTO
                case 11://DR DO ATENDIMENTO
                case 12://SENAI PARCEIRO
                    return previsaoReceita.unidade.descricao;
                case 13://SISTEMA INDÚSTRIA
                case 14://SESI/SENAI DN
                    return previsaoReceita.sistemaIndustria ? previsaoReceita.sistemaIndustria.nome : previsaoReceita.fontePagadora.razaoSocial;
                default:
                    return previsaoReceita.fontePagadora ? previsaoReceita.fontePagadora.nome : '';
            }
            
        }
    }

    render() {
        return (
            <Page icon={'coin.svg'} label={'Apropriação de receita'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'receita'} direction='column' justify='center'>

                    <Grid item xs={12}>
                        <Grid container className={'apropriar'} style={{ marginTop: 15 }}>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid container spacing={3} style={{ justifyContent: 'space-between' }}>
                                        <Grid item xs={12} md={12} lg={12} xl={12} className={'pesquisa'}>
                                            <Grid container spacing={3} >
                                                <Grid item xs={6} style={{display: 'flex', alignItems: 'center'}}>
                                                    <FormField type={'text'} placeholder={'busque por ID, título, número ou cliente'}
                                                        changeValue={this.changeBusca} pressEnter={this.getProdutos}
                                                        id={'busca'} button={<GenericButton color={'darkBlue'} subClass={'icon-button'}
                                                            icon={process.env.PUBLIC_URL + 'search.svg'} click={this.getProdutos} />} />

                                                    <GenericButton color={'lightBlue'} label={'Busca avançada'} subClass={'basic-button busca-avancada'}
                                                        icon={process.env.PUBLIC_URL + 'filter.svg'} click={() => this.setState({openModalBuscaAvancada: true})} />
                                                </Grid>
                                                <Grid item xs={2}>
                                                </Grid>
                                                <Grid item xs={4} className={'total-atendimentos'}>
                                                    <label>Quantidade de atendimentos <b>{this.state.listProdutosFiltrados.length}</b></label>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} style={{marginTop: '50px'}}>
                                        <CustomTable lines={this.state.listProdutosFiltrados} titles={this.titles} initialOrder={''} />
                                        <EmptyList type={this.state.emptyList} seeAll={this.getProdutos} msg={'Não há atendimentos cadastrados nessa linha'} />
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '20px', textAlign: 'right', paddingRight: '10px'}}>
                                        {this.state.listProdutosFiltrados.length > 0 && 
                                            <GenericButton 
                                                color={'lightBlue'} 
                                                label={'Enviar para financeiro'} 
                                                subClass={'basic-button'} 
                                                click={() => this.setState({openModalConfirmFinanceiro: true})} 
                                                disabled={this.state.isEnvioPendent}
                                            />}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <SimpleModal isOpen={this.state.openModalConfirmFinanceiro}
                            handleClose={() => this.setState({ openModalConfirmFinanceiro: false })}
                            type={'info'} width={'400px'}>
                            <div className={'modal modal-historico'}>

                                <Grid container direction="column" justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <label className={'labelReceita'}>Você confirma o envio das apropriações de receita para financeiro?</label>
                                    </Grid>
                                </Grid>

                                <div className={'space-receita'}>
                                    <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.setState({ openModalConfirmFinanceiro: false })} />
                                    <GenericButton color={'darkBlue'} label={'Sim, confirmo'} subClass={'basic-button'} click={() => {this.enviarParaFinanceiro()}} />
                                </div>
                            </div>
                        </SimpleModal>

                        <SimpleModal
                            isOpen={this.state.openModalBuscaAvancada}
                            handleClose={() => this.setState({openModalBuscaAvancada: false})}
                            type={'info'}
                            disableClick={true}
                            width={'550px'}>
                            <div className={'modal modal-atendimento-filtro'}>
                                <h2>Busca avançada</h2>

                                <div className={'select-produto'}>
                                    <AutoCompleteMany
                                        itemlabel={'descricao'}
                                        getInput={ (e) => this.setState({valuesFiltro: e}) }
                                        itemKey={'id'}
                                        label={'Linha'}
                                        chipLabel={'Linha(s) selecionado(s)'}
                                        suggestions={[{id: 0, descricao: 'Consultoria'}, {id: 1, descricao: 'Pesquisa, Desenvolvimento e Inovação'}, {id: 2, descricao: 'Serviços Técnicos Especializados'},
                                                    {id: 3, descricao: 'Metrologia'}, {id: 4, descricao: 'Serviços Complementares'}]}
                                        upper={true} />
                                </div>

                                <div className={'rodape'}>
                                    <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.setState({openModalBuscaAvancada: false})} />
                                    <GenericButton color={'darkBlue'} label={'Filtrar'} subClass={'basic-button'} disabled={this.state.valuesFiltro.length == 0} click={() => this.changeFiltro(this.state.valuesFiltro)} />
                                </div>
                            </div>
                        </SimpleModal>
                    
                        <SimpleModal isOpen={this.state.openModalApropriarReceita}
                            handleClose={() => {this.setState({ openModalApropriarReceita: false, valueIsDeclareFontePagadora: 0 })}}
                            type={'info'} width={'800px'}>
                            <div className={'modal modal-appReceita'}>

                                <h2>Apropriar receita - Atendimento {this.state.receitaAtual.numeroAtendimento}</h2>

                                <Grid container style={{marginTop: '30px'}}>
                                    <Grid style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                        <Grid item xs={6} style={{paddingRight: '10px'}}>
                                            <InputSelect key='mesApropriacao' suggestions={this.state.recMesList} itemLabel={'label'}
                                                label={'Mês da apropriação'}
                                                initialSelectedItem={this.state.mesApropriacao} itemKey={'id'}
                                                getInput={(e) => { if(e) {this.changeMes(e)}  }} 
                                            />
                                        </Grid>
                                        <Grid item xs={6} style={{paddingLeft: '10px'}}>
                                            <InputSelect
                                                label={'Condição de pagamento'}
                                                suggestions={this.conditionalPayment}                                           
                                                getInput={(value) => {
                                                    if (value) {
                                                        this.setState({ payment: value });
                                                    }
                                                }}    
                                                itemLabel="descricao"
                                                itemKey={'id'}           
                                                initialSelectedItem={this.state.payment} 
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent:'center'}}>   
                                        <Grid item xs={6} style={{paddingRight: '10px', marginTop: '10px'}}>
                                            <FormControlLabel className={'payment-checkbox'}
                                                control={<Checkbox 
                                                    checked={this.state.isDeclareFontePagadora} 
                                                    onChange={this.getInputCheckbox} 
                                                    value='isDeclareFontePagadora' 
                                                    className={'checkbox'} 
                                                />} 
                                            label='Apropriar por fonte pagadora' />
                                        </Grid>
                                        <Grid item xs={6} style={{paddingLeft: '10px'}} >
                                            <FormField
                                                label={'Apropriar sem declarar fonte pagadora ( rateio )'}
                                                type={"currency"}
                                                changeValue={this.apropriarRateio}
                                                value={this.state.valueIsDeclareFontePagadora}
                                                disabled={this.state.isDeclareFontePagadora}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container className={'data-appReceita'}>
                                        <Grid item xs={12} className={'head'}>
                                            <Grid item xs={6}>Fonte pagadora</Grid>
                                            <Grid item xs={6}>Receita individual</Grid>
                                        </Grid>
                                        {this.state.previsaoReceitaList.map((previsaoReceita, index) => {
                                                return (
                                                    <Grid container spacing={2} className={'line'} key={previsaoReceita.id}>
                                                        <Grid item xs={6}>
                                                            {this.getNomePrevisaoReceita(previsaoReceita)}
                                                        </Grid>
                                                        <Grid item xs={6}> 
                                                            <FormField
                                                                type={"currency"}
                                                                changeValue={(event, formattedValue) => this.handleChangePaymentSources(event, formattedValue, index)}
                                                                value={this.state.fontesPagadoras[index] ? this.state.fontesPagadoras[index].value : 0}
                                                                disabled={!this.state.isDeclareFontePagadora}
                                                            />
                                                            {this.state.isDeclareFontePagadora && (
                                                                <GenericButton color={'transparent'} subClass={'icon-button'} icon={'trash.svg'} click={() => {this.resetInputValuePrevisaoReceita(index); this.calculateReceitaTotal()}}  />
                                                            )}
                                                        </Grid>
                                                    </Grid>
                                                )
                                        })}
                                        
                                        {this.state.previsaoReceitaList.length > 0 && this.state.total ? (
                                            <Grid item xs={12} className={'rodape-line'} >
                                                
                                                <Grid item xs={6}>Receita total para o mês atual</Grid>
                                                <Grid item xs={6}>{this.state.total.toLocaleString('pt-br', { currency: 'BRL', style: 'currency', })}
                                              
                                                </Grid>
                                            </Grid>
                                        ): 
                                        <Grid item xs={12} className={'rodape-line'}>
                                                
                                            <Grid item xs={6}>Receita total para o mês atual</Grid>
                                            <Grid item xs={6}>{this.state.total.toLocaleString('pt-br', { currency: 'BRL', style: 'currency', })}
                                        
                                            </Grid>
                                        </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '20px'}}> 
                                        <AutoCompleteMany
                                            itemlabel={'label'}
                                            getInput={ (e) => this.onTagsChange(e) }
                                            itemKey={'id'} 
                                            label={'Vincular entregas (opcional)'}
                                            chipLabel={'Entrega(s) selecionada(s)'}
                                            suggestions={this.state.entregas}
                                            upper={true} 
                                            renderAsTable={true}
                                            menuItems={this.menuItems}
                                            disabled={!(this.state.entregas.length > 0)}
                                            propertiesList={this.propertiesList}
                                        />
                                    </Grid>
                                </Grid>
                                <div className={'rodape'}>
                                    <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.setState({ openModalApropriarReceita: false, valueIsDeclareFontePagadora: 0 })} />
                                    <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} click={() => {this.salvarReceitaModal()}} />
                                </div>
                            </div>
                        </SimpleModal>
                    </Grid>
                </Grid>
            </Page>
        );
    }
}