import React, { Component } from 'react';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import './loading.scss';

export default class Loading extends Component {

	constructor(props) {
		super(props);
		this.state = { showLoading: this.props.showLoading != null ? this.props.showLoading : false };
		if (props.getFunctions) props.getFunctions(null, this.showLoading, this.handleClose);
	}

	handleClose = () => {
    	this.setState({ showLoading: false });
  	};

  	showLoading = () => {
  		this.setState({ showLoading: true });
  	};

	componentDidUpdate(prevProps) {
		if (this.props.showLoading != undefined && this.props.showLoading !== prevProps.showLoading) {
				this.setState({ showLoading: this.props.showLoading });
		}
	}

	render() {
		return (
            this.state.showLoading ? <Modal aria-labelledby='transition-modal-title' aria-describedby='transition-modal-description' 
            	className={'loading-modal'} 
            	open={true} closeAfterTransition BackdropComponent={Backdrop} BackdropProps={{ timeout: 300 }}>
		        <Fade in={true}>
					<div className={'loading-fade'}>
						<img className={'loading-img'} src={process.env.PUBLIC_URL + '/assets/icons/Preloader.gif'} alt='' />
					</div>
		        </Fade>
	      </Modal> : null
	    );
	}
}