import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { maskMoney } from '../../comm/masks';
import AutoCompleteMany from '../../components/autoCompleteMany/autoCompleteMany';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import InputSelect from '../../components/inputselect/inputselect';
import SimpleModal from '../../components/modal/modal';
import { OutputTable } from '../../components/outputTable/outputTable';
import Page from '../../components/page/page';
import ProgressBar from '../../components/progressbar/progressbar';
import API from './../../comm/API';
import { mesList } from './../../comm/variables';
import CustomTable from './../../components/table/table';
import './receita.scss';

export default class HistoricoReceita extends Component {

    monthNames = ["JANEIRO", "FEVEREIRO", "MARÇO", "ABRIL", "MAIO", "JUNHO",
    "JULHO", "AGOSTO", "SETEMBRO", "OUTUBRO", "NOVEMBRO", "DEZEMBRO"
    ];

    menuItems = ['Atividade', 'Data Prevista'];
    propertiesList = ["label", "dataEntrega"];
    month = undefined;

    titles = [
        { id: '0', canOrder: true, label: 'Data' },
        { id: '1', canOrder: false, label: 'Apropriado por' },
        { id: '2', canOrder: false, label: 'Receita' },
        { id: '3', canOrder: false, label: '' }];


    conditionalPayment = [];


    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;

        this.setState(this.state);
    }

    constructor(props) {
        super(props);

        this.state = { 
            page: 0, receita: {}, listHistoricoReceita: [], listHistoricoReceitaFiltrado: [],
            openModalApropriarReceita: false, valuesEntrega: [], openModalEntregas: false,
            receitaAtual: {}, isDeclareFontePagadora: false, valueIsDeclareFontePagadora:0, 
            previsaoReceitaList: [], fontesPagadoras: [], total: 0, valor: [], 
            quantidadeHorasEnsaioApropriadas: 0, quantidadeRelatorioApropriado: 0,
            listProdutos: [], entregas: [], deliveries: [], recMesList: [],
            mesApropriacao: {}, totalReceitaRealizada: 0, listProdutosFiltrados: [], isPendente: false, percentualTotal: 0,
            openModalEditHistoricoReceita: false, historicoReceitaEdit: {
                usuario: {
                    nome: ''
                },
                data: '',
                condicaoPagamentoDescricao: ''
            },
            fontesPagadorasEdit: {}, totalReceitaEdit: 0, previsaoReceitaListEdit: []
        };
    };

    transformCurrencyStringToNumber = (value) => {
        return Number(value.toString().substring(2).replace(',', '.'));
    }

    initDataApropriacao = (date) => {
        var data = date ? new Date(date) : new Date();
        var mes = data.getMonth();
        var dia = data.getDate();
        let recMesList = [];

        if (dia >= 1 && dia <= 7) {
            if (mes === 0) {
                recMesList.push(mesList[11]);
                recMesList.push(mesList[mes]);

            } else {
                recMesList.push(mesList[mes - 1]);
                recMesList.push(mesList[mes]);
            }
        } else {
            recMesList.push(mesList[mes]);
        }
        this.setState({ recMesList: recMesList,  mesApropriacao: { id: new Date().getMonth() } });
    }

    checkIfEnvioFinanceiroIsPendent = (historicoReceita) => {
        return historicoReceita.valorReceitaFaturamentoNaoEnviado > 0 && !historicoReceita.envioFaturamentoAtendimento.id && !historicoReceita.previsaoReceita.id;
    }

    getCondicaoPagamento = () => {
        API.get('rest/auxiliar/CondicaoPagamento').then(res => {
            this.showLoading();
            this.conditionalPayment = res.data;
            this.closeLoading();
            this.setState({ payment: res.data && !!res.data.length ? res.data[0] : {id: '', descricao: ''}});
        }).catch(error => {
            this.closeLoading();
            this.showtoast('Erro ao realizar GET das condições de pagamento. Favor, entre em contato com o suporte.', 'error');
        });
    }

    componentWillMount() {
        this.getCondicaoPagamento();
        this.getReceitaApropriadaByIdAtendimento();
        this.initDataApropriacao();
    }
    
    componentDidMount(){
        this.getHistoricoReceitaApropriada();
    }


    calculateReceitaTotal() {
        const totalReceita = this.state.previsaoReceitaList.reduce((acc, _, index) => {

            const fontesPagadorasValue = this.state.fontesPagadoras[index] ? this.state.fontesPagadoras[index].value : 0;

            const fonteNumber = typeof fontesPagadorasValue === 'number' ? 
                fontesPagadorasValue : 
                this.transformCurrencyStringToNumber(fontesPagadorasValue);

            return acc + fonteNumber;

        }, this.state.totalReceitaRealizada);

        this.setState({ total: totalReceita });
    }


    calculateReceitaTotalEdit(updatedFontes=this.state.fontesPagadorasEdit.receitaFaturamentoFontePagadoraList) {
        let totalFontesPagadoras = this.state.total - this.state.fontesPagadorasEdit.valor;

        updatedFontes.forEach(fonte => {
            totalFontesPagadoras += typeof fonte.valor === 'string' ? this.transformCurrencyStringToNumber(fonte.valor) : fonte.valor ;
        });

        this.setState({ totalReceitaEdit: totalFontesPagadoras });
    }

    handleGetFontesPagadorasByReceitaFaturamento = async (receitaFaturamento) => {
        const response = await API.get(`/rest/gestao/ReceitaFaturamento/${receitaFaturamento.id}`);

        const fontePagadora = {...response.data};

        
        fontePagadora.receitaFaturamentoFontePagadoraList.forEach(fontePagadora => {
            fontePagadora.valor = maskMoney((fontePagadora.valor * 100).toString());
        });
        
        return fontePagadora;
    }
    
    
    handleOpenModalEditReceita = async (receita) => {
        const fontesPagadoras = await this.handleGetFontesPagadorasByReceitaFaturamento(receita);

        const receitaListFiltered = fontesPagadoras.atendimento.previsaoReceitaList.filter(previsaoReceita => previsaoReceita.valor > 0);

        this.setState({ openModalEditHistoricoReceita: true, fontesPagadorasEdit: fontesPagadoras, previsaoReceitaListEdit: receitaListFiltered });
        this.calculateReceitaTotalEdit();
    }
  
    getReceitaApropriadaByIdAtendimento = async () => {
        this.showLoading();
        try {
            let receitaInfo = await API.get(`/rest/gestao/Atendimento/${this.props.idAtendimento}`);
            const { data } = await API.get(`rest/gestao/ReceitaFaturamento/atendimentos?idUnidade=` + receitaInfo.data.unidade.id + `&buscaLivre=${this.props.idAtendimento}`);
            const { 
                previsoes, 
                valorReceitaFaturamentoEnviado,
                valorReceitaFaturamentoNaoEnviado,
                valorReceitaFaturamentoNaoEnviadoDigitado,
                totalProducao,
                numeroReceitaEstimada,

            } = data[0];
    
            previsoes.forEach(previsao => {
                previsao.value = 0;
            })

            const receitaRealizada = { 
                valorReceitaFaturamentoEnviado,
                valorReceitaFaturamentoNaoEnviado,
                valorReceitaFaturamentoNaoEnviadoDigitado,
                totalProducao,
                numeroReceitaEstimada
            }

            const receitaListFiltered = receitaInfo.data.previsaoReceitaList.filter(previsaoReceita => previsaoReceita.valor > 0);

            
            const totalReceitaRealizada = this.getTotalReceitaFaturamento(receitaRealizada);
            const percentual = receitaRealizada.numeroReceitaEstimada !== 0 ? 
                (totalReceitaRealizada / receitaRealizada.numeroReceitaEstimada) : 1;
            const percentualTotal = Number((percentual * 100).toFixed(1));
    
            const fontesPagadoras = previsoes;
    
            this.setState({ 
                receita: receitaInfo.data, 
                fontesPagadoras, 
                previsaoReceitaList: receitaListFiltered,
                percentualTotal,
            });

            
            
            this.setState({totalReceitaRealizada})
            
            this.calculateReceitaTotal();
            this.closeLoading();
        } catch (error) {
            this.closeLoading();
            this.showToast('Os dados da receita não pode ser gerado, por favor entre em contato com o suporte.', 'error');
        }
    }

    openModalApropriarReceita = () => {
        this.calculateReceitaTotal();

        this.setState({ 
            openModalApropriarReceita: true,
        });
    }

    formatDates = (date) => new Intl.DateTimeFormat("pt-BR", 
        { timeZone: "UTC" }).format(new Date(date)).toString();

    handleChangePaymentSources(e, value, indexFontesPagadoras) {
        if (e.target) {
            let fontesPagadoras = this.state.fontesPagadoras;

            fontesPagadoras[indexFontesPagadoras].value = value;

            this.setState({  fontesPagadoras });

            this.calculateReceitaTotal();
        }
        
    }

    getInputCheckbox = (e) => {
        if (e != null && e.target != null) {
            this.setState({ [e.target.value]: e.target.checked});
            
            const updatedFontes = this.state.fontesPagadoras.map(fonte => {
                fonte.value = 0;

                return fonte;
            })

            this.setState({ valueIsDeclareFontePagadora: 0, fontesPagadoras: updatedFontes });

            this.calculateReceitaTotal();
        }
    };


    apropriarRateio = (e, value) => {
        if (e != null && e.target != null) {
            this.setState({valueIsDeclareFontePagadora: value });

            const updatedFontes = this.state.fontesPagadoras.map(fonte => {
                const sumValueString = (this.transformCurrencyStringToNumber(value) * fonte.rateio).toFixed(2).toString();
                fonte.value = maskMoney(sumValueString);

                return fonte;
            });

            this.setState({ fontesPagadoras: updatedFontes });

            this.calculateReceitaTotal();
        }
    }


    getHistoricoReceitaApropriada = async () => {
        try {
            this.showLoading();
            let listReceitaTest = await API.get(`/rest/gestao/ReceitaFaturamento/historicoReceita/${this.props.idAtendimento}`);

            let historicoRec = listReceitaTest.data.map(rec => {
                return {
                    id: rec[0], data: new Date(rec[1] + ' 00:00:00'), valor: rec[2], envioFaturamentoAtendimento: { id: rec[3] },
                    atendimento: { id: rec[4] }, codigoIntegracaoReceita: rec[5], justificativa: rec[6], usuario: { id: rec[7], nome: rec[8] },
                    previsaoReceita: { id: rec[9] ? rec[9] : null},
                    quantidadeRelatorioApropriado: rec[10], quantidadeHorasEnsaioApropriadas: rec[11],valorReceitaFaturamento: rec[12], 
                    valorReceitaFaturamentoEnviado: rec[13], valorReceitaFaturamentoNaoEnviado: rec[14], valorReceitaFaturamentoNaoEnviadoDigitado: rec[15],
                    idCondicaoPagamento: rec[16], condicaoPagamentoDescricao: rec[17]
                }
            });
            const getProd = historicoRec[0].quantidadeHorasEnsaioApropriadas
            const getHorasRelatorio = historicoRec[0].quantidadeRelatorioApropriado
            const getValorReceitaFaturamento = historicoRec[0].valorReceitaFaturamento
            const getValorReceitaFaturamentoEnviado = historicoRec[0].valorReceitaFaturamentoEnviado
            const getValorReceitaFaturamentoNaoEnviado = historicoRec[0].valorReceitaFaturamentoNaoEnviado
            const getValorReceitaFaturamentoNaoEnviadoDigitado = historicoRec[0].valorReceitaFaturamentoNaoEnviadoDigitado

            let listEntregas = await API.get(`/rest/gestao/Entrega/find?id=${this.props.idAtendimento}`);
            let suggestionsEntregas = listEntregas.data.map((receita) => (
                {
                    id: receita[0],
                    canOrder: true,
                    label: `${receita[0]} - ${receita[1]}`,
                    dataEntrega: receita[2]
                }
            ));

        
            
            this.setState({ 
                listHistoricoReceita: historicoRec, ano: new Date().getFullYear(),
                quantidadeHorasEnsaioApropriadas: getProd, 
                quantidadeRelatorioApropriado: getHorasRelatorio,
                valorReceitaFaturamento: getValorReceitaFaturamento,
                valorReceitaFaturamentoEnviado: getValorReceitaFaturamentoEnviado,
                valorReceitaFaturamentoNaoEnviado: getValorReceitaFaturamentoNaoEnviado,
                valorReceitaFaturamentoNaoEnviadoDigitado: getValorReceitaFaturamentoNaoEnviadoDigitado,
                entregas: suggestionsEntregas              
            });
            this.filtrarHistorico(historicoRec);
            this.isReceitaPendente();
            this.closeLoading();
        } catch (error) {
            this.closeLoading();
            this.showToast('O histórico de receitas apropriadas não pode ser gerado, por favor entre em contato com o suporte.', 'error');
        }
    }

    getTotalReceitaFaturamento = (receita = this.state) => {
        let somaReceita = 0;
        somaReceita += parseFloat(receita.valorReceitaFaturamentoEnviado);
        somaReceita += parseFloat(receita.valorReceitaFaturamentoNaoEnviado);
        somaReceita += parseFloat(receita.valorReceitaFaturamentoNaoEnviadoDigitado);
        if (isNaN(somaReceita)) {
            somaReceita = receita.totalProducao;
        }
        return somaReceita;
    }

    resetInputValuePrevisaoReceita = (index)  => {
        let fontes = this.state.fontesPagadoras;

        fontes[index].value = 0;

        this.setState({ fontesPagadoras: fontes});
    }
  
    getFormattedDate = (data) => {
        var d = new Date(data);
        return `${this.monthNames[d.getMonth()]} / ${d.getFullYear()}`;
    }
  
    getDataApropriacao() {
        let dataAtual = new Date();
        let ano = dataAtual.getFullYear();
        if(dataAtual.getMonth() === 0 && this.state.mesApropriacao.id === 11) {
            ano = ano - 1;
        }
        let dataApropriar = new Date(ano, this.state.mesApropriacao.id, 28);
        return dataApropriar;
    }
    
    verifyDate = (data) => {
        var date = new Date(data);
        var currentDate = new Date();

        if(date.getFullYear() == currentDate.getFullYear() && date.getMonth() == currentDate.getMonth()) {
            return true;
        }
        return false;
    }

    filtrarHistorico(list) {
        let histFiltrados = [];
        list.forEach(receita => {
            let hist = [];
            hist.push(this.getFormattedDate(receita.data));
            hist.push(receita.usuario.nome);
            hist.push(receita.valor.toLocaleString('pt-br', { currency: 'BRL', style: 'currency' }));
            hist.push( this.checkIfEnvioFinanceiroIsPendent(receita) && this.verifyDate(receita.data) ? 
                <GenericButton 
                    color={'darkBlue-outline'} 
                    label={'Editar'} 
                    subClass={'basic-button'} 
                    click={() => this.handleOpenModalEditReceita(receita)} 
                /> : '');
            histFiltrados.push(hist);
        });
        this.setState({ listHistoricoReceitaFiltrado: histFiltrados });
    }

    salvarReceita = () => {
        let list = [];
        this.state.listProdutos.forEach(rec => {
            if (rec.changed && rec.valorReceitaFaturamentoNaoEnviadoDigitado > 0) {
                list = list.concat(this.receitaFaturamentoModel(rec));
            }
        });

        this.showLoading();
        
        API.post('rest/gestao/ReceitaFaturamento/apropriarReceitas', list).then(res => {
            let qtdCorretos = res && res.data && res.data.qtdCorretos ? res.data.qtdCorretos : null;
            let qtdErros = res && res.data && res.data.qtdErros ? res.data.qtdErros : null;
            this.closeLoading();
            this.exibirMensagem(qtdCorretos, qtdErros, list.length);
        }).catch(error => {
            this.closeLoading();
            this.showToast('Erro ao tentar enviar receita. Por favor, entre em contato com o suporte.', 'error');
        });
    }


    salvarReceitaModal = async () => {
        let receita = {
            data: this.getDataApropriacao(),
            dataAtualizacao: new Date(),
            atendimento: {id: this.props.idAtendimento},
            usuario: { id: this.props.usuarioConectado.id },
            condicaoPagamento: { id: this.state.payment.id },
            receitaFaturamentoEntregaList: this.state.deliveries.map(entreg => {
                return { entrega: entreg }
            }),
            receitaFaturamentoFontePagadoraList: this.state.previsaoReceitaList.map((fonte, index) =>{
                return {
                    valor: this.state.fontesPagadoras[index] ?
                        this.transformCurrencyStringToNumber(this.state.fontesPagadoras[index].value) : 0, 
                    fontePagadora: fonte.sistemaIndustria ? fonte.sistemaIndustria : fonte.fontePagadora 
                };
            }).filter(filteredReceita => filteredReceita),
            valor: this.state.fontesPagadoras
                .map(fonte => typeof fonte.value === 'number' ? fonte.value : this.transformCurrencyStringToNumber(fonte.value))
                .reduce((value1, value2) => { return value1 + value2 })
        }
      
        try {
            this.showLoading();
            await API.post('rest/gestao/ReceitaFaturamento/apropriarReceitas', receita);

            this.getReceitaApropriadaByIdAtendimento();
            this.getHistoricoReceitaApropriada();
            
            this.setState({ openModalApropriarReceita: false, valueIsDeclareFontePagadora: 0 });
            this.closeLoading();
            this.showToast('Receita(s) salva(s) com sucesso!', 'success');
        } catch (error) {
            this.closeLoading();
            this.showToast('A(s) Receita(s) não foram salva(s), por favor entre em contato com o suporte.', 'error');
        }
    }

    salvarEditReceitaModal = async () => {
       
        try {
            this.showLoading();

            const fontesPagadoras = {...this.state.fontesPagadorasEdit};

            fontesPagadoras.receitaFaturamentoFontePagadoraList.forEach(receitaFaturamento => {
                receitaFaturamento.valor = typeof receitaFaturamento.valor === 'string' ? this.transformCurrencyStringToNumber(receitaFaturamento.valor) : 0;
            });

            fontesPagadoras.valor = fontesPagadoras.receitaFaturamentoFontePagadoraList.reduce((acc, current) => acc + current.valor, 0);

            await API.post(`rest/gestao/ReceitaFaturamento/${fontesPagadoras.id}`, fontesPagadoras);

            this.getReceitaApropriadaByIdAtendimento();
            this.getHistoricoReceitaApropriada();
            
            this.setState({ openModalEditHistoricoReceita: false });
            this.closeLoading();
            this.showToast('Receita(s) salva(s) com sucesso!', 'success');
        } catch (error) {
            this.closeLoading();
            this.showToast('A(s) Receita(s) não foram salva(s), por favor entre em contato com o suporte.', 'error');
        }
    }

    

    exibirMensagem(qtdCorretos, qtdErros, listLength) {
        this.setState({ openModalConfirmacao: false, openModalHistorico: false });
        this.closeLoading();
        if (qtdCorretos === listLength) {
            this.showToast('Receita(s) salva(s) com sucesso!', 'success');
        } else if (qtdErros === listLength) {
            this.showToast('A(s) Receita(s) não foram salva(s), por favor entre em contato com o suporte.', 'error');
        } else if (qtdCorretos && qtdErros) {
            this.showToast(`${qtdCorretos} receita(s) foram salva(s) com sucesso, ${qtdErros}, não foram salvas, por favor entre em contato com o suporte.`, 'warning');
        } else {
            this.showToast('Usuário inválido.', 'error');
        }
    }


    onTagsChange = (e) => {
        this.setState({ deliveries: e })
    };

    changeMes(e) {
        this.setState({ mesApropriacao: e });
    }

    isReceitaPendente = () => {

        let linesAtendimento = this.state.listProdutosFiltrados;
        let valid = true;

       const find = linesAtendimento.find( line =>  line.valorReceitaFaturamentoNaoEnviado != 0  );

        this.setState({ isPendente: valid });

    }


    getNomePrevisaoReceita = (previsaoReceita) => {
        if (previsaoReceita.tipoPrevisaoReceita) {
            switch(previsaoReceita.tipoPrevisaoReceita.id) {
                case 1://MERCADO
                case 2://FOMENTO
                    return previsaoReceita.fontePagadora.nome;
                case 3://SENAI
                    return 'Senai';
                case 5://ICT
                    return previsaoReceita.ictParceiro.descricao;
                case 7://AGENCIA NACIONAL
                    return previsaoReceita.agenciaNacional.descricao;
                case 8://AGENCIA DE FOMETO
                    return previsaoReceita.agenciaFomento.descricao;
                case 10://UO DO ATENDIMENTO
                case 11://DR DO ATENDIMENTO
                case 12://SENAI PARCEIRO
                    return previsaoReceita.unidade.descricao;
                case 13://SISTEMA INDÚSTRIA
                case 14://SESI/SENAI DN
                    return previsaoReceita.sistemaIndustria ? previsaoReceita.sistemaIndustria.nome : previsaoReceita.fontePagadora.razaoSocial;
                default:
                    return previsaoReceita.fontePagadora ? previsaoReceita.fontePagadora.nome : '';
            }
            
        }
    }

    handleCloseModalApropriarReceita = () => {

        const updatedFontesPagadoras = this.state.fontesPagadoras.map(fonte => {
            fonte.value = 0;

            return fonte;
        })

        this.setState({ 
            openModalApropriarReceita: false,
            valueIsDeclareFontePagadora: 0,
            fontesPagadoras: updatedFontesPagadoras,
        });

    }

    returnDateLabel = (date) => {
        if (!date) return '';

        const newDate = new Date(date);
      
        const month = newDate.getMonth();

        return mesList[month].label;
    }

    handleEditFontesPagorasValor = (idReceitaFaturamentoFontePagadora, value) => {

        const fontesPagadoras = {...this.state.fontesPagadorasEdit};

        fontesPagadoras.receitaFaturamentoFontePagadoraList.forEach(fonte => {
            if (fonte.id === idReceitaFaturamentoFontePagadora) {
                fonte.valor = value ? value : 0;
            }
        });

        this.setState({ fontesPagadorasEdit: fontesPagadoras });
        this.calculateReceitaTotalEdit(fontesPagadoras.receitaFaturamentoFontePagadoraList);
    }   

    getSelectedTableEntregaEdit = () => {
        return this.state.fontesPagadorasEdit.receitaFaturamentoEntregaList.map(({entrega}) => {
            if (typeof entrega.data === 'string' && entrega.data.length > 10)
                entrega.data = new Date(entrega.data).toLocaleDateString('pt-br');

            const orderedReversed = Object.keys(entrega).reverse().reduce(
                (obj, key) => { 
                    obj[key] = entrega[key]; 
                    return obj;
                }, {}
            );

            return orderedReversed;
        });

    }

    removeItemEntregaList = (self, entregaItem) => {
        const fontesPagadoras = {...self.state.fontesPagadorasEdit};

        fontesPagadoras.receitaFaturamentoEntregaList = fontesPagadoras.receitaFaturamentoEntregaList.filter(({entrega}) => entrega.id !== entregaItem.id);

        self.setState({ fontesPagadorasEdit: fontesPagadoras });
    }
    

    getNomeFontePagadoraEdit = (previsaoReceita, index) => {

        const previsaoReceitaFromAtendimento = this.state.previsaoReceitaListEdit[index] ? this.state.previsaoReceitaListEdit[index] : this.state.previsaoReceitaListEdit.find(previsao => previsao.fontePagadora.id === previsaoReceita.fontePagadora.id);

        const nomePrevisaoReceita = this.getNomePrevisaoReceita(previsaoReceitaFromAtendimento);

        return nomePrevisaoReceita;
    }

    render() {
        return (
            <Page icon={'coin.svg'} label={'Apropriação de receita'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'receita'}>
                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Grid container>
                            <Grid className={'pagination-gerenciar-historico'}>
                                <Link to={'/receita'}>
                                    <div className={'return-gerenciar-historico'}>
                                        <ArrowBackIosIcon style={{ color: '#077CDC', fontSize: '28px' }} />
                                        <p>Apropriação de receita</p>
                                    </div>
                                </Link>
                                <ArrowForwardIosIcon style={{ color: 'darkGrey', fontSize: '14px', marginRight: '10px' }} />
                                <p style={{ color: '#077CDC'}}>{this.state.receita.titulo}</p>
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12} md={6} lg={6} className={'receita-info'}>
                                <Grid container>
                                    <Grid item xs={12}>
                                    <h6>{this.state.receita.titulo}</h6>
                                    </Grid>

                                <Grid style={{ display: 'column', width: '100%'}}>
                                    <Grid className={'Box-label'}>
                                        <Grid item xs={3}><h5>Número</h5></Grid>
                                        <Grid item xs={9}>{this.state.receita.numero}</Grid>
                                    </Grid>

                                    <Grid className={'Box-label'}>
                                        <Grid item xs={3}><h5>Entidade atendida</h5></Grid>
                                        <Grid item xs={9}>{this.state.receita.cliente && this.state.receita.cliente.nome}</Grid>
                                    </Grid>

                                    <Grid className={'Box-label'}>
                                        <Grid item xs={3}><h5>Unidade</h5></Grid>
                                        <Grid item xs={9}>{this.state.receita.unidade && this.state.receita.unidade.abreviacao}</Grid>
                                    </Grid>

                                    <Grid className={'Box-label'}>
                                        <Grid item xs={3}><h5>Produto Regional</h5></Grid>
                                        <Grid item xs={9}>{this.state.receita.produtoRegional && this.state.receita.produtoRegional.nome}</Grid>
                                    </Grid>
                                </Grid>
                                    <Grid item xs={12} style={{ paddingTop: '14px'}}>
                                        <GenericButton 
                                            color={'darkBlue'} 
                                            label={'Apropriar receita'} 
                                            subClass={'basic-button'} 
                                            click={this.openModalApropriarReceita} 
                                            disabled={this.state.percentualTotal >= 100}
                                        />
                                        <GenericButton color={'darkBlue-outline'} disabled={!(this.state.entregas.length > 0)} label={'Ver entregas'} subClass={'basic-button'} click={() => this.setState({ openModalEntregas: true })} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                            <Grid style={{ paddingTop: '40px', width: '105%', display: 'flex', marginLeft: '1vw'}}>
                                    <Grid item style={{ marginRight: '1%'}}>
                                        <div className={'progressBar'}>
                                            <p>Prod. Realizada/Prevista</p>
                                            <ProgressBar
                                                idAtendimento={this.props.idAtendimento}
                                                total={this.state.quantidadeRelatorioApropriado + this.state.quantidadeHorasEnsaioApropriadas}
                                                producao={this.state.receita.numeroDeRelatorioEstimado + this.state.receita.numeroDeProducaoEstimada}
                                                unitEnd={this.state.receita.isMetrologia ? '' : 'h'} />
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div className={'progressBar'}>
                                            <p>Receita Realizada/Prevista</p>
                                            <ProgressBar 
                                                idAtendimento={this.props.idAtendimento}
                                                total={this.getTotalReceitaFaturamento()}
                                                producao={this.state.receita.numeroDeReceitaEstimada || 0}
                                                unitBegin={'R$'} defaultClass={'bluePercent'} />
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} className={'historic-table'}>
                                <h5 style={{ fontSize: '12pt'}}>Histórico de apropriações</h5>
                                <CustomTable lines={this.state.listHistoricoReceitaFiltrado} titles={this.titles} initialOrder={''} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{paddingLeft: '30px'}}>
                        <Grid container direction={'column'} className={'sidebar sidebar-historico'}>
                            <Grid className={'boxServicos'} item xs={10}>
                                <ul className={'porcentagem'}>
                                    {this.state.fontesPagadoras.map((fontePagadora, index) => {
  
                                        if (!!fontePagadora.titulo) {
                                            return (
                                                <li style={{ height: `${fontePagadora.rateio * 100 > 100 ? 100 : fontePagadora.rateio * 100}%`}} key={fontePagadora.id}>
                                                    <div className={`values ${index % 2 === 0 ? 'dark': 'light'}`}><label>{fontePagadora.rateio * 100}% {fontePagadora.titulo}</label></div>
                                                </li>
                                            )
                                        }
                                        
                                        const totalOthers = this.state.fontesPagadoras.reduce((prev, current) => {
                                            if (!(!!current.titulo)) {
                                                return prev + current.rateio;
                                            }
                                            return 0;
                                        }, 0);

                                        return (
                                            <li style={{ height: `${totalOthers * 100 > 100 ? 100 : totalOthers * 100}%`}} key={'others'}>
                                                <div className={`values others`}><label>{totalOthers * 100}% outros</label></div>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </Grid>
                            <Grid item xs={1} className={'total'} style={{ width: '100%' }}>
                                <p
                                    
                                >
                                    Total de receita: {this.state.total.toLocaleString('pt-br', { currency: 'BRL', style: 'currency', })}
                                </p>
                            </Grid>
                        </Grid>
                    </Grid>
                    <SimpleModal isOpen={this.state.openModalApropriarReceita}
                        handleClose={this.handleCloseModalApropriarReceita}
                        type={'info'} width={'800px'}>
                        <div className={'modal modal-appReceita'}>

                            <h2>Apropriar receita - Atendimento {this.state.receita.numero}</h2>

                            <Grid container style={{marginTop: '30px'}}>
                                <Grid style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                    <Grid item xs={6} style={{paddingRight: '10px'}}>
                                        <InputSelect key='mesApropriacao' suggestions={this.state.recMesList} itemLabel={'label'}
                                            label={'Mês da apropriação'}
                                            initialSelectedItem={this.state.mesApropriacao} itemKey={'id'}
                                            getInput={(e) => { if(e) {this.changeMes(e)}  }} 
                                        />
                                    </Grid>
                                    <Grid item xs={6} style={{paddingLeft: '10px'}}>
                                        <InputSelect
                                            label={'Condição de pagamento'}
                                            suggestions={this.conditionalPayment}                                           
                                            getInput={(value) => {
                                                if (value) {
                                                    this.setState({ payment: value });
                                                }
                                            }}    
                                            itemLabel="descricao"
                                            itemKey={'id'}           
                                            initialSelectedItem={this.state.payment}                                  
                                        />
                                    </Grid>
                                </Grid>
                                <Grid style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent:'center'}}>   
                                    <Grid item xs={6} style={{paddingRight: '10px', marginTop: '10px'}}>
                                        <FormControlLabel className={'payment-checkbox'}
                                            control={<Checkbox 
                                                checked={this.state.isDeclareFontePagadora} 
                                                onChange={this.getInputCheckbox} 
                                                value='isDeclareFontePagadora' 
                                                className={'checkbox'} 
                                            />} 
                                        label='Apropriar por fonte pagadora' />
                                    </Grid>
                                    <Grid item xs={6} style={{paddingLeft: '10px'}} >
                                        <FormField
                                            label={'Apropriar sem declarar fonte pagadora ( rateio )'}
                                            type={"currency"}
                                            changeValue={this.apropriarRateio}
                                            value={this.state.valueIsDeclareFontePagadora}
                                            disabled={this.state.isDeclareFontePagadora}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container className={'data-appReceita'}>
                                    <Grid item xs={12} className={'head'}>
                                        <Grid item xs={6}>Fonte pagadora</Grid>
                                        <Grid item xs={6}>Receita individual</Grid>
                                    </Grid>
                                    {this.state.previsaoReceitaList.map((previsaoReceita, index) => {
                                        return (
                                            <Grid item xs={12} className={'line'} key={previsaoReceita.id}>
                                                <Grid item xs={6}>
                                                    {this.getNomePrevisaoReceita(previsaoReceita)}
                                                </Grid>
                                                <Grid item xs={6}> 
                                                    <FormField
                                                        type={"currency"}
                                                        changeValue={(event, formattedValue) => this.handleChangePaymentSources(event, formattedValue, index)}
                                                        value={this.state.fontesPagadoras[index] ? this.state.fontesPagadoras[index].value : 0}
                                                        disabled={!this.state.isDeclareFontePagadora}
                                                    />
                                                    {this.state.isDeclareFontePagadora && (
                                                        <GenericButton color={'transparent'} subClass={'icon-button'} icon={'trash.svg'}
                                                        click={() => {this.resetInputValuePrevisaoReceita(index)}}  />
                                                    )}
                                                </Grid>
                                            </Grid>
                                        )
                                    })}
                                    
                                    {this.state.previsaoReceitaList.length > 0 && this.state.total ? (
                                        <Grid item xs={12} className={'rodape-line'} >
                                            
                                            <Grid item xs={6}>Receita total para o mês atual</Grid>
                                            <Grid item xs={6}>{this.state.total.toLocaleString(
                                                'pt-br', { currency: 'BRL', style: 'currency', })}
                                            </Grid>
                                        </Grid>
                                    ): 
                                    <Grid item xs={12} className={'rodape-line'}>
                                        <Grid item xs={6}>Receita total para o mês atual</Grid>
                                        <Grid item xs={6}>{this.state.total.toLocaleString(
                                            'pt-br', { currency: 'BRL', style: 'currency', })}
                                        </Grid>
                                    </Grid>
                                    }

                                </Grid>
                                <Grid item xs={12} style={{marginTop: '20px'}}> 
                                        <AutoCompleteMany
                                            itemlabel={'label'}
                                            getInput={ (e) => this.onTagsChange(e) }
                                            itemKey={'id'} 
                                            label={'Vincular entregas (opcional)'}
                                            chipLabel={'Entrega(s) selecionada(s)'}
                                            suggestions={this.state.entregas}
                                            upper={true} 
                                            disabled={!(this.state.entregas.length > 0)}
                                            renderAsTable={true}
                                            menuItems={this.menuItems}
                                            propertiesList={this.propertiesList}
                                        /> 
                                </Grid>
                            </Grid>
                            <div className={'rodape'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.setState({ openModalApropriarReceita: false, valueIsDeclareFontePagadora: 0 })} />
                                <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} click={() => {this.salvarReceitaModal()}} />
                            </div>
                        </div>
                    </SimpleModal>
                    <SimpleModal isOpen={this.state.openModalEntregas}
                        handleClose={() => this.setState({ openModalEntregas: false })}
                        type={'info'} width={'800px'}>
                        <div className={'modal modal-appReceita'}>

                            <h2>Entregas - Atendimento {this.state.receita.numero}</h2>
                            <Grid container style={{marginTop: '30px'}}>
                                <Grid item xs={12}>
                                    <Table className={'tabela-entregas'}>
                                        <TableHead>
                                            <TableRow>
                                                {this.state.entregas.length === 1 ? 
                                                    <TableCell className={'atividades-entregas'}>Atividade</TableCell>
                                                : 
                                                    <TableCell className={'atividades-entregas'}>Atividades</TableCell>
                                                }  

                                                <TableCell>Data prevista</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {this.state.entregas.map((entregas) => 
                                                <TableRow>
                                                <TableCell>{entregas.label}</TableCell>
                                                <TableCell>{entregas.dataEntrega}</TableCell>
                                            </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        </div>
                    </SimpleModal>
                    
                    {this.state.fontesPagadorasEdit.id && (
                        
                        <SimpleModal 
                            isOpen={this.state.openModalEditHistoricoReceita}
                            handleClose={() => this.setState({ openModalEditHistoricoReceita: false })}
                            type={'info'} width={'800px'}>
                            <div className={'modal modal-appReceita'}>

                                <h2>Descrição de receita - Atendimento {this.state.receita.numero}</h2>
                                
                                <Grid 
                                    container 
                                    direction="column" 
                                    justify="flex-start"
                                    className="information"
                                >
                                    <label>Apropriada por {this.state.fontesPagadorasEdit ? this.state.fontesPagadorasEdit.usuario.nome : 0}</label>
                                    <label>Em { this.state.fontesPagadorasEdit ? new Date(this.state.fontesPagadorasEdit.data).toLocaleDateString('pt-br') : '' }</label>
                                </Grid>

                                <Grid container style={{marginTop: '30px'}}>
                                    <Grid style={{display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                        <Grid item xs={6} style={{paddingRight: '10px'}} className="block-input">
                                            <Grid item xs={6} className="input-title">
                                                <span>
                                                    Mês da apropriação
                                                </span>
                                            </Grid>
                                            
                                            <Grid item xs={6}>
                                                <span>
                                                    {this.state.fontesPagadorasEdit ? this.returnDateLabel(this.state.fontesPagadorasEdit.data) : ''}
                                                </span>
                                            </Grid>

                                        </Grid>
                                        <Grid item xs={6} style={{paddingLeft: '10px'}} className="block-input">
                                            <Grid item xs={6} className="input-title">
                                                <span>
                                                    Condição de pagamento
                                                </span>
                                            </Grid>
                                            
                                            
                                            <Grid item xs={6}>
                                                <span>
                                                    {this.state.fontesPagadorasEdit.condicaoPagamento ? this.state.fontesPagadorasEdit.condicaoPagamento.descricao : ''}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid container className={'data-appReceita'}>
                                        <Grid item xs={12} className={'head'}>
                                            <Grid item xs={6}>Fonte pagadora</Grid>
                                            <Grid item xs={6}>Receita individual</Grid>
                                        </Grid>
                                        {this.state.fontesPagadorasEdit.receitaFaturamentoFontePagadoraList.map((previsaoReceita, index) => {
                                            return (
                                                <Grid item xs={12} className={'line'} key={previsaoReceita.id}>
                                                    <Grid item xs={6}>
                                                        {this.getNomeFontePagadoraEdit(previsaoReceita, index)}
                                                    </Grid>
                                                    <Grid item xs={6}> 
                                                        <FormField
                                                            type={"currency"}
                                                            changeValue={(event, formattedValue) => this.handleEditFontesPagorasValor(previsaoReceita.id, formattedValue)}
                                                            value={previsaoReceita.valor ? previsaoReceita.valor : 0}
                                                            disabled={!previsaoReceita.valor}
                                                        />

                                                        <GenericButton 
                                                            color={'transparent'} 
                                                            subClass={'icon-button'} 
                                                            icon={'trash.svg'}
                                                            click={() => {this.handleEditFontesPagorasValor(previsaoReceita.id); this.calculateReceitaTotal()}}  
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )
                                        })}
                                        
                                        {this.state.previsaoReceitaList.length > 0 && this.state.total ? (
                                            <Grid item xs={12} className={'rodape-line'} >
                                                
                                                <Grid item xs={6}>Receita total para o mês atual</Grid>
                                                <Grid item xs={6}>{this.state.totalReceitaEdit.toLocaleString(
                                                    'pt-br', { currency: 'BRL', style: 'currency', })}
                                                </Grid>
                                            </Grid>
                                        ): 
                                        <Grid item xs={12} className={'rodape-line'}>
                                            <Grid item xs={6}>Receita total para o mês atual</Grid>
                                            <Grid item xs={6}>{this.state.totalReceitaEdit.toLocaleString(
                                                'pt-br', { currency: 'BRL', style: 'currency', })}
                                            </Grid>
                                        </Grid>
                                        }

                                    </Grid>
                                    <Grid item xs={12} style={{marginTop: '20px'}}> 
                                        {this.state.fontesPagadorasEdit.receitaFaturamentoEntregaList.length > 0 && (
                                            <>
                                                <h3>Entregas vinculadas</h3>
                                                <OutputTable 
                                                    menuItems={this.menuItems}
                                                    selectedItems={this.getSelectedTableEntregaEdit()}
                                                    propertiesList={['data', 'nome']}
                                                    itemKey={'id'}
                                                    onRemoveItem={(item) => this.removeItemEntregaList(this, item)}
                                                />
                                            </>
                                        )}
                                    </Grid>
                                </Grid>
                                <div className={'rodape'}>
                                    <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.setState({ openModalEditHistoricoReceita: false })} />
                                    <GenericButton color={'darkBlue'} label={'Salvar'} subClass={'basic-button'} click={() => {this.salvarEditReceitaModal()}} />
                                </div>
                            </div>
                        </SimpleModal>
                    )}
                </Grid>
            </Page>
        );
    }
}
