import React, { Component } from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import Highcharts from "highcharts/highcharts.js";
import HighchartsReact from 'highcharts-react-official';
import { withStyles} from "@material-ui/core/styles";
import API from '../../../comm/API';
import DashboardCard from '../../../components/dashboardCard/dashboardCard';
import CarouselAvaliacao from '../../../components/carousel/carouselAvaliacao';

export default class GraphBarAvaliacaoNota extends Component {

    constructor(props) {
        super(props);
        this.state = {
            L:{
                1: [63,2,30,1,4],
                2: [20,7,40,2,31],
            },
            D:{
                1: [15,50,20,7,8],
                2: [3,43,51,1,2],
            },
            LD:{
                1: [1,38,39,19,3],
                2: [16,4,75,3,2],
            },
            
        }
        this.carregarStatus();
    }

    pushNotas = (object) =>{
        let array = [];
        array.push(object[1]);
        array.push(object[2]);
        array.push(object[3]);
        array.push(object[4]);
        array.push(object[5]);
        return array;

    }

    getStatusAtendimento = async () => {
		
        await API.get('rest/sistema/DashboardBrasilMais/avaliacoesAtividades').then( async res => {
			let dados = res.data;
            let questionsL = {
                1: this.pushNotas(dados.lean.expectativas_atendidas),
                2: this.pushNotas(dados.lean.longo_prazo)};
            let questionsD = {
                1: this.pushNotas(dados.digital.expectativas_atendidas),
                2: this.pushNotas(dados.digital.longo_prazo)};
            let questionsLD = {
                1: this.pushNotas(dados.ambos.expectativas_atendidas),
                2: this.pushNotas(dados.ambos.longo_prazo)};
            this.setState({L:questionsL ,D : questionsD,LD : questionsLD});
		}).catch(error => {
			console.log(error);
		});
	}

	carregarStatus = async () => {
		await this.getStatusAtendimento();
	}

    

    createGrafico  =  (fase,page) => {
        let series = [];
	    series = this.state[fase][page];
        let max = series.reduce((partialSum, a) => partialSum + a, 0);
        let options = {
            chart: {
                type: 'bar',
                style: {
                    fontFamily: 'Raleway'
                },
                marginTop: 0,
                spacingTop: 0,
                marginBottom: 0,
                spacingBottom: 0,
                height: 200,
                backgroundColor:"#F2F1F6"
            },
            title:{
                floating : true,
                text: ''
            },
            xAxis: {
                gridLineColor: '#B4B8C0',
                gridLineWidth: 0,
                tickmarkPlacement:'between',
                categories: ['Nota 1', 'Nota 2', 'Nota 3', 'Nota 4','Nota 5'],
                opposite: true,
                labels: {
                    style: {
                        width: '400px',
                        textOverflow: 'allow',
                    },
                    useHTML: true,
                    formatter: function () {
                        let val;
                        switch(this.value){
                            case 'Nota 1':
                                val = 0;
                                break;
                            case 'Nota 2' :
                                val = 1;
                                break;
                            case 'Nota 3' :
                                val = 2;
                                break;
                            case 'Nota 4' :
                                val = 3;
                                break;
                            case 'Nota 5':
                                val = 4;
                                break;
                        }
                        return '<div> <label style="font-weight:bold">'  + series[val] + '</label> <label style="text-transform: none; margin-left: 10px">' + this.value + '</label> </div>'
                    }
                },
                lineColor: '#707070',
                lineWidth: 1,
            },
            yAxis: {
                gridLineWidth: 0,
                lineColor: '#707070',
                min: 0,
                max: max,
                title: {
                    text: ''
                },
                labels: {
                    enabled: false
                }
            },
            plotOptions: {
                series:{
                    pointWidth: 30,
                },
                bar: {
                    colorByPoint: true,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            legend:{
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    return '<b>'+ this.x +'</b><br/>' + 
                            'valor: ' + this.y + '<br/> Porcentagem: ' + (this.y/max *100).toFixed(1) + '%';
                }
            },
            series: [{
                data: [max,max,max,max,max],
                name: 'backgroundSeries',
                colors: [
                    '#FFFFFF',
                    '#FFFFFF',
                    '#FFFFFF',
                    '#FFFFFF',
                    '#FFFFFF'
                ],
                grouping: false,
                animation: false,
                enableMouseTracking: false,
                showInLegend: false
              },{
                data: series,
                colors: [
                    '#E5F8FF',
                    '#B5E8F8',
                    '#73D4F2',
                    '#00B1E8',
                    '#077CDC'
                ]
            }],
            credits: {
                enabled: false
            },
            exporting: false
        };

        return options;
    };

    formatData = (date) => {
        date = date + 'T00:00:00';
        let dataList;
        if (typeof (date) !== 'string') {
            dataList = date.toLocaleDateString().split('/');
            return dataList[2] + '-' + dataList[1] + '-' + dataList[0];
        } else {
            if (date.length > 10) {
                dataList = date.split('T');
                return dataList[0];
            } else {
                return date;
            }
        }
    };

    AvaliacaoCarousel() {
		let content = [];
		for(let i=0;i<2;i++){
			content.push(
				<div key={'carrossel-card-' + i}>
					{this.graficoAvaliacao(i + 1)}
				</div>
			)
		};
		return content;
	}

  
   
    graficoAvaliacao(page) {
        let title = 'teste status';
        let currGraph = this.createGrafico(this.props.fase,page);
        let series;
        series = this.state[this.props.fase][page];
        let max = Math.max(...series);
        let total = series.reduce((partialSum, a) => partialSum + a, 0);
        let index = series.indexOf(max);
        let categories =  ['Nota 1', 'Nota 2', 'Nota 3', 'Nota 4','Nota 5'];
        let perguntas = [
            'A empresa acredita que as expectativas inicias foram atendidas?',
            'A empresa acredita que os resultados obtidos serão mantidos a longo prazo?',
        ]
        const ModifiedTooltip = withStyles({
            tooltip: {
                fontSize: '15px',
                fontFamily:'Raleway'
            }
          })(Tooltip);
        let porcentagem =  (max/total * 100).toFixed(2);
        return (
            <Grid container spacing={0} >
                
                <Grid item xs={12} style={{marginTop:"20px"}}>
                    <Grid container 
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={0} style={{maxHeight:"100%",height:"100%"}}>
                        <Grid item xs={3} className={'card'} style={{maxHeight:"100%",height:"100%"}}>
                            <DashboardCard color={'card-dark-gray'} text={
                                <div className='card-titles-div white-label'>
                                    <label >{perguntas[page-1]}</label>
                                </div>} />
                        </Grid>                
                        <Grid item xs={7}>
                            <div >
                                <HighchartsReact key={title}
                                    highcharts={Highcharts}
                                    options={currGraph} />
                            </div>
                        </Grid>
                        <Grid item xs={2} className={'card'} style={{maxHeight:"100%",height:"100%"}}>
                            <DashboardCard color={'card-dark-gray'}  id = {'invertido'} text={categories[index]} value={porcentagem + '%'}/>
                        </Grid>            
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div className='page-label'>
                        <label >{page + '/2'}</label>
                    </div>
                </Grid>
            </Grid>
            
        );
    }

    render() {
        
        return (
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    {this.props.fase == "L" && <CarouselAvaliacao content={this.AvaliacaoCarousel()} top={false}/>}
                    {this.props.fase == "D" && <CarouselAvaliacao content={this.AvaliacaoCarousel()} top={false}/>}
                    {this.props.fase == "LD" && <CarouselAvaliacao content={this.AvaliacaoCarousel()} top={false}/>}
                </Grid>
            </Grid>
            
        );
    }

}