import React, { Component } from 'react'
import { Grid } from "@material-ui/core";

import './breadcrumb.scss'

export default class Breadcrumb extends Component {

    get subClass() {
        return (this.props.subClass !== undefined ? this.props.subClass : '');
    }



    render() {
        return (
            <div className={`breadcrumb ${this.props.color}`}>
                
                    <img src={process.env.PUBLIC_URL + '/assets/icons/' + this.props.icon} alt='breadcrumbIcon' />
                    {this.props.label}
                
            </div>
        )
    }
}
