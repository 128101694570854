import { Grid } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import Icon from '@material-ui/core/Icon';
import React, { Component } from 'react';
import API from '../../comm/API';
import DownloadFiles from '../../comm/DownloadFiles';
import AutoComplete from '../../components/autoComplete/autoComplete';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import Table from '../../components/table/table';
import SimpleModal from './../../components/modal/modal';

export default class Proposta extends Component {

    descricao = '';
    atendimentoListProposta = [];
    isNewProposta = false;

    state ={anexoPropostaList: this.props.proposta != null ? this.props.proposta.anexoPropostaList != null ? this.props.proposta.anexoPropostaList : [] : []}

    // atendimento = this.props.atendimento;
    cliente = this.props.cliente != null ? this.props.cliente : undefined;
    totalRecursos = 0;
    atendimentoList = [];

    titles = [{ id: '0', canOrder: false, label: 'Nº' },
    { id: '1', canOrder: false, label: 'Produto' },
    { id: '2', canOrder: false, label: 'Produção' },
    { id: '3', canOrder: false, label: 'Recursos' },
    { id: '4', canOrder: false, label: '' }
    ];
    lines = [];

    componentWillMount() {
        this.start();
    }
    
    start = () => {
        this.isNewProposta = !(this.props.proposta != null);
        if (this.isNewProposta === false) {
            this.descricao = this.props.proposta.descricao
            this.getAtendimentoList(this.props.proposta.id);
        }
        if (this.isNewProposta && this.props.atendimento != null) {
            this.cliente = this.props.atendimento.cliente;
            this.atendimentoListProposta.push(this.props.atendimento);
            this.setState(this.state);
            this.getLinesRegistrar();
        }
    }

    getAnexos() {
        if (this.props.proposta) {
            return this.props.proposta.anexoPropostaList;
        }
        return [];
    }

    getAtendimentoList = (idProposta) => {
        var filter = new URLSearchParams();
        filter.append('proposta.id', idProposta);

        API.get('/rest/gestao/Atendimento/find', { params: filter }).then(res => {
            this.atendimentoListProposta = res.data;
            this.setState(this.state);
            this.getLinesVisualizar();
        }).catch(error => {
            this.props.showToast('Error ao listar ao listar os atendimentos. Por favor contate o suporte.', 'error');
        });
    }

    getLinesVisualizar = () => {
        this.atendimentoListProposta.forEach(atd => {
            this.totalRecursos += atd[10];
            var line = [];
            line.push(`${atd[1]}`);
            line.push(`${atd[3]}`);
            if (!atd[6]) {
                line.push(`${atd[7].toLocaleString('pt-BR')} h`);
            } else {
                line.push(
                    <div>
                        <label>{atd[7].toLocaleString('pt-BR')} Ensaios
                        <br></br>
                            {atd[9].toLocaleString('pt-BR')} Relatórios ou Laudos</label>
                    </div>
                )
            }
            line.push(`R$ ${atd[10].toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`);
            line.push('');
            this.lines.push(line);
            this.setState(this.state);
        })
    }

    getLinesRegistrar = () => {
        this.lines = [];
        this.atendimentoListProposta.forEach(atd => {
            this.totalRecursos += atd.numeroDeReceitaEstimada;
            var line = [];
            line.push(atd.numero);
            line.push(atd.produtoRegional.nome);
            if (!this.props.isMetrologia) {
                line.push(atd.numeroDeProducaoEstimada + 'h');
            } else {
                line.push(
                    <div>
                        <label>{atd.numeroDeProducaoEstimada} Ensaios
                        <br></br>
                            {atd.numeroDeRelatorioEstimado} Relatórios ou Laudos</label>
                    </div>
                )
            }
            line.push(atd.numeroDeReceitaEstimada)
            if (this.isNewProposta && (this.props.atendimento == null || (this.props.atendimento && this.props.atendimento.id !== atd.id))) {
                line.push(<div style={{ display: 'flex' }}><GenericButton color={'transparent'} subClass={'icon-button'} icon={'trash.svg'} click={() => this.removeAtendimento(atd)} /></div>);
            }
            this.lines.push(line);
            this.setState(this.state);
        })
        this.setState(this.state);
    }

    addAtendimento = (atendimento) => {
        if (atendimento != null && atendimento !== "") {
            let atend = this.atendimentoListProposta.find(atd =>{ return atd.id === atendimento.id });
            if(!atend){
                this.atendimentoListProposta.push(atendimento);
                this.setState(this.state);
                this.getLinesRegistrar();
            }       
        }
    }

    removeAtendimento = (atendimento) => {
        if (atendimento != null && atendimento !== "") {
            var index = this.atendimentoListProposta.indexOf(atendimento);
            if (index !== -1) {
                this.atendimentoListProposta.splice(index, 1);
            }
            this.setState(this.state);
            this.getLinesRegistrar();
        }
    }

    createTemplateCliente(cliente) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{cliente.nome}</label>
                </div>
                <div>
                    <p>{cliente.razaoSocial}</p>
                    <p>{cliente.cpfcnpj}{cliente.enderecoList[0] != null ? ' - ' + cliente.enderecoList[0].municipio.descricao : ''}</p>
                </div>
            </div>
        )
    }

    createTemplateAtendimento(atendimento) {
        return (
            <div className={"auto-complete-options"}>
                <div>
                    <label>{atendimento.titulo}</label>
                </div>
                <div>
                    <p>{atendimento.produtoRegional.nome}</p>
                </div>
            </div>
        );
    }

    changeCliente = (e) => {
        if (e != null && e !== '') {        
            if(this.cliente && this.cliente.id !== e.id){
                this.atendimentoListProposta = [];
                this.setState(this.state);
            }
            this.cliente = e;
            this.atendimentoList = [];
            this.setState(this.state);
            this.getLinesRegistrar();
        } 
    }

    changeDescricao = (e) => {
        if (e != null && e.target != null) {
            this[e.target.id] = e.target.value;
            this.setState(this.state);
        }
    }

    validateProposta = () => {
        if (this.props.modalVisualizarProposta) {
            return this.descricao !== this.props.proposta.descricao;
        } else {
            return this.descricao && this.atendimentoListProposta.length > 0;
        }
    }

    saveProposta = async () => {
        let propostaAux = (this.props.proposta != null) ? this.props.proposta : { descricao: null, atendimentoList: [] };
        propostaAux.descricao = this.descricao;
        propostaAux.atendimentoList = this.atendimentoListProposta.map( atd => {
            if(atd.id){
                return atd;
            }
            return {id: atd[0]};
        });

        this.props.showLoading();
        if (propostaAux.id === undefined) {
            API.post('rest/gestao/Proposta', propostaAux).then(async res => {              
                if(this.props.atendimento && this.props.atendimento.id){
                    await this.props.openAtendimento(this.props.atendimento.id, true);
                }
                this.props.closeLoading();
                this.isNewProposta = false;
                this.setState(this.state);
                this.getLinesRegistrar();
                this.props.changeModalShowToast('modalRegistrarProposta', false, 'Proposta cadastrada com sucesso.' );      
            }).catch(error => {
                this.props.closeLoading();
                this.props.showToast('Erro ao registrar proposta. Favor, entrar em contato com o suporte.', 'error');
            });
        } else {
            API.post('rest/gestao/Proposta/' + propostaAux.id, propostaAux).then(res => {
                this.props.closeLoading();
                this.props.showToast('Proposta editada com sucesso!', 'success');
            }).catch(error => {                
                this.props.closeLoading();
                this.props.showToast('Erro ao registrar proposta. Favor, entrar em contato com o suporte.', 'error');
            });
        }
    }

    clearProposta = () => {
        if (this.isNewProposta) {
            this.cliente = undefined;
            this.descricao = '';
            this.atendimentoListProposta = [];
        } else {
            this.descricao = this.props.proposta.descricao;
            this.atendimentoListProposta = [];
            this.getAtendimentoList(this.props.proposta.id);
        }
        this.setState(this.state);
        this.props.changeModal('modalVisualizarProposta', false);
        this.props.changeModal('modalRegistrarProposta', false);
    }

    uploadAnexo = (e) => {
        this.props.showLoading();
        let file = e.target.files[0];
        this.setState(this.state);
        let formData = new FormData();
        formData.append('name', 'file');
        formData.append('file', file);
        formData.append('fileName', file.name);
        if (file.size > 10000000) {
            this.props.closeLoading();
            this.props.showToast('O tamanho do arquivo ultrapassa o limite máximo de 10Mb permitido.', 'error');
            return;
        }
        API.post('rest/upload/proposta/anexo/' + this.props.proposta.id, formData, {
            headers: { 'Content-Type': "multipart/form-data" }
        }).then(async res => {
            this.props.atendimento.proposta.anexoPropostaList = res.data.anexoPropostaList;
            this.setState({ anexoPropostaList: res.data.anexoPropostaList });
            this.props.closeLoading();
            this.props.showToast('Arquivo anexado com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Erro ao arquivar arquivo. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    removeAnexo = (anexo) => {
        this.props.showLoading();
        let propostaAux = this.props.proposta;
        let index = propostaAux.anexoPropostaList.findIndex(prop => {
            return prop.url === anexo.url;
        });
        if (index !== -1) {
            propostaAux.anexoPropostaList.splice(index, 1);
        }
        API.post('rest/gestao/Proposta/' + propostaAux.id, propostaAux).then(res => {
            this.setState({ anexoPropostaList: propostaAux.anexoPropostaList });
            this.props.closeLoading();
            this.props.showToast('Anexo excluído com sucesso!', 'success');
        }).catch(error => {
            this.props.closeLoading();
            this.props.showToast('Erro ao excluir anexo. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    render() {
        return (
            (!this.isNewProposta) ?
                <SimpleModal
                    isOpen={this.props.modalVisualizarProposta}
                    handleClose={() => this.props.changeModal('modalVisualizarProposta', false)}
                    type={'info'}
                    width={'900px'}>
                    <div className={'modal modal-visualizar-proposta'} style={{ fontSize: '13px' }}>
                        <h2>Proposta - {this.props.proposta ? 'ID ' + this.props.proposta.id : ''}</h2>
                        <Grid container>
                            <Grid item xs={12} md={8}>
                                <label className={'font-blue'}><b>Cliente Associado</b></label><br />
                                <label style={{ fontStyle: 'italic' }}>{this.cliente ? this.cliente.nome : ''}</label>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormField type={'text'}
                                    label={'Descrição/Número Proposta'}
                                    inputValue={this.descricao}
                                    changeValue={this.changeDescricao}
                                    id={'descricao'} 
                                    disabled={this.props.cliente != null && this.props.cliente.id != null} />
                            </Grid>
                            <Grid item xs={12}>
                                <label className={'font-blue'} style={{ marginTop: '16px' }}><b>Atendimentos associados</b></label>
                            </Grid>
                            <Grid item xs={12}>
                                <Table lines={this.lines} titles={this.titles} initialOrder={'0'} pagination={false} />
                            </Grid>
                            <Grid item xs={12}>
                                <p style={{ textAlign: 'end', fontWeight: '700' }}>TOTAL: R$ {this.totalRecursos.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            </Grid>
                            <Grid item xs={12}>
                                <label className={'font-blue'}><b>Anexos (pdf, doc, docx, xlsx, xls)</b></label>
                            </Grid>
                            <Grid item xs={12} style={{ margin: '16px 0 16px 0' }}>
                                <label
                                    style={{ whiteSpace: 'nowrap'}}
                                    className={'lightBlue basic-button control-label'}
                                    id="control-label"
                                    
                                    htmlFor="uploadfile">Adicionar anexo
                                </label>
                                <input
                                    className={'file-input'}
                                    type="file"
                                    id="uploadfile"
                                    name="file"
                                    value={undefined}
                                    onChange={this.uploadAnexo} />
                            </Grid>
                            <Grid>
                                { this.state.anexoPropostaList.length > 0 ?
                                    this.state.anexoPropostaList.map(
                                        (anexo, index) => (
                                            !anexo.url.includes("pesquisa_satisfacao") ?
                                                <Chip key={index}
                                                    label={<label style={{ display: 'flex', alignItems: 'center' }}>

                                                        <Icon className={'attach'} onClick={() => { DownloadFiles(anexo.url, anexo.nomeArquivo) }}>attach_file</Icon>

                                                        <p>{anexo.nomeArquivo}</p></label>}
                                                    icon={<Icon onClick={() => this.removeAnexo(anexo)} className={'close'}>clear</Icon>} /> : null
                                        )) : <p>Esta proposta não possui anexos.</p>}
                            </Grid>
                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton color={'darkGrey-outline'} label={'Voltar'} subClass={'basic-button'} click={this.clearProposta} />
                                { this.proposta && this.proposta.id && 
                                    <GenericButton color={'darkBlue'} label={'Editar'} subClass={'basic-button'} disabled={!this.validateProposta()} click={this.saveProposta} /> }
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal> :
                <SimpleModal
                    isOpen={this.props.modalRegistrarProposta}
                    handleClose={() => this.props.changeModal('modalRegistrarProposta', false)}
                    type={'info'}
                    width={'900px'}>
                    <div className={'modal modal-visualizar-proposta'}>
                        <h2>Registrar Proposta</h2>
                        <Grid container>
                            <Grid item xs={12} sm={8} className={'paddingForm'}>
                                <AutoComplete
                                    label={'Cliente associado'}
                                    path={'rest/pessoa/Cliente/0/2?buscaLivre='}
                                    itemKey={'id'}
                                    id={'autoCompleteClientes'}
                                    key='autoCompleteClientes'
                                    initialSelectedItem={this.cliente ? this.cliente : undefined}
                                    itemlabel={'nome'}
                                    itemTemplate={this.createTemplateCliente}
                                    getInput={this.changeCliente} 
                                    disabled={this.props.cliente != null && this.props.cliente.id != null} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <FormField type={'text'}
                                    label={'Descrição/Número Proposta'}
                                    changeValue={this.changeDescricao}
                                    id={'descricao'} />
                            </Grid>
                            {this.cliente ?
                                <Grid item xs={12}>
                                    <AutoComplete
                                        placeholder={'Digite o nome do atendimento'}
                                        itemTemplate={this.createTemplateAtendimento}
                                        itemKey={'id'}
                                        itemlabel={'titulo'}
                                        path={'rest/gestao/Atendimento/0/2?cliente.id=' + this.cliente.id + '&atendimentoStatus.id=2&buscaLivreAtendimento='}
                                        key='autoCompleteAtendimento'
                                        id={'autoCompleteAtendimento'}
                                        getInputButton={(e) => { this.addAtendimento(e) }}
                                        button={true} />
                                </Grid>
                                : null}
                            <Grid item xs={12} className={'tabela-proposta'}>
                                <Table lines={this.lines} titles={this.titles} initialOrder={'0'} pagination={false} />
                            </Grid>
                            <Grid item xs={12} className={'rodape'}>
                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={() => this.props.changeModal('modalRegistrarProposta', false)} />
                                <GenericButton color={'darkBlue'} label={'Registrar'} subClass={'basic-button'} click={this.saveProposta} disabled={!this.validateProposta()} />
                            </Grid>
                        </Grid>
                    </div>
                </SimpleModal>
        )
    }
}