import React, { Component } from 'react';
import { Grid, Switch } from '@material-ui/core';
import API from '../../../comm/API';
import GraphBar from '../../../components/graphBar/graphBar';
import GraphBarHorizontal from '../../../components/graphBar/graphBarHorizontal';

export default class GraficoProdutividadeUnidadeSegmento extends Component {

    tooltipProdutividade = {
        formatter: function() {
            let yValue = this.point.y;
            return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${yValue.toLocaleString('pt-BR')}%</p><br>`;
        },
        outside: true,
        borderWidth: 1,
        borderColor: "#B4B8C0",
        shadow: false,
        followPointer: true,
        backgroundColor: "rgba(255,255,255)"
    };

    tooltipSegmento = () => {
        return {
            formatter: function() {
                let yValue = this.point.y;
                return `<p style="font: 13px Raleway; font-weight: 500; color: #707070">${this.series.userOptions.fullCategories[this.point.category - 1]}: ${yValue.toLocaleString('pt-BR')}%</p><br>`;
            },
            outside: true,
            borderWidth: 1,
            borderColor: "#B4B8C0",
            shadow: false,
            followPointer: true,
            backgroundColor: "rgba(255,255,255)"
        }
    };
    
    legend = { enabled: false };

    plotOptionsProdutividade = {
        bar: {
            pointPadding: 1,
            borderWidth: 0,
            zones: [{ value: 1, color: '#FFFFFF' }, { value: 20, color: '#D44E54' }, { value: 100, color: '#00B1E8' }, { color: '#45B1E9' }],
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        },
        series: {
            pointWidth: 13
        },
    };

    plotOptionsSegmento = {
        column: {
            pointPadding: 0.2,
            borderWidth: 0,
            zones: [{ value: 20, color: '#D44E54' }, { value: 100, color: '#00B1E8' }, { color: '#E9B03D' }],
            events: {
                legendItemClick: function () {
                    return false;
                }
            },
        }
    };

    plotLines = [
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 20 }, 
        { color: '#707070', dashStyle: 'shortdash', width: 1.5, value: 100 }
    ];

    labelsY =  {
        formatter: function() {
            if(this.value <= 20 || (this.value === 100))
                return this.value + "%";
        },
        style: {
            color: '#707070'
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            switch_bar: [
                {
                    title: `Aumento de produtividade industrial ${this.props.usuarioConectado.unidade.tipoUnidade.sigla === "DR" ? 'por unidade operacional no Brasil Mais - ' + this.props.usuarioConectado.unidade.municipio.unidadeFederativa.sigla : "na UO"}` ,
                    tooltip: this.tooltipProdutividade,
                    categories: [],
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoProdutividade,
                    tickInterval: 20,
                    legend: this.legend,
                    plotOptions: this.plotOptionsProdutividade,
                    plotLines: this.plotLines,
                    labelsY: this.labelsY,
                },
            ],
            switch_col: [
                {
                    title: `Aumento de produtividade industrial por segmento industrial ${this.props.perfil === "UO" ? "na UO" : ""}`,
                    tooltip: this.tooltipSegmento(),
                    categories: [], 
                    fullCategories: [],
                    series: [],
                    seriesFunction: this.fillGraficoSegmento,
                    tickInterval: 20,
                    legend: this.legend,
                    height: 250,
                    plotOptions: this.plotOptionsSegmento,
                    plotLines: this.plotLines,
                    labelsY: this.labelsY,
                    gridLineWidthY: 0
                }
            ],
            flagSwitch: false
        };
    };

    fillGraficoSegmento = async (dataInicio, dataTermino) => {
        let series = { data: [] };
        let categories = [];
        let resumeInfos = [];
        await API.get(`rest/sistema/DashboardBrasilMais/setorAtendimentosProdutividade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${false}`).then(async res => {
            if (res.data != null) {
                let resumo = res.data.resumo;
                if (resumo && resumo.length > 0 && resumo.length === 3) {
                    resumeInfos = {
                        title: 'Segmentos com maior média de produtividade',
                        data: [
                            { title: resumo[0][0], value: resumo[0][1], suffix: '%' }, 
                            { title: resumo[1][0], value: resumo[1][1], suffix: '%' }, 
                            { title: resumo[2][0], value: resumo[2][1], suffix: '%' }
                        ]
                    };
                } 
                series = await [{ data: res.data.grafico.map(i => i[1]), fullCategories: res.data.grafico.map(i => i[0]) }];
                categories = await [ res.data.grafico.map((_, index) => index + 1) ];
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories[0], series[0].fullCategories, resumeInfos];
    };

    fillGraficoProdutividade = async (dataInicio, dataTermino, busca, limite) => {
        let series = [];
        let categories = [];
        let height = 0;
        await API.get(
            `rest/sistema/DashboardBrasilMais/geralatendimentosProdutividadeUnidade?dataInicio=${dataInicio}&dataTermino=${dataTermino}&isAtendimentos=${false}&busca=${busca}&limite=${limite}`
        ).then(async res => {
            if (res.data != null && res.data.unidades != null) {
                categories = await res.data.unidades.map(i => i[0]);
                series =  [{data: await res.data.unidades.map(i => i[1]) }];
                height = series.length >= 1 && series[0].data.length >= 1 ? (50 * series[0].data.length) + 50 : 100;
            }
        }).catch(error => {
            console.log(error);
        });
        return [series, categories, height];
    };
   
    render() {
        return (
            <Grid container justify={'center'} style={{ height: '100%' }}>
                <Grid container item style={this.state.flagSwitch ? { display: 'none'} : { display: ''}}>
                    <GraphBarHorizontal switch={this.state.switch_bar} disabledSwitch={false} hideCarregaMais={this.props.hideCarregaMais}
                        perfil={this.props.perfil} hasFilter={true} init={'left'} />
                </Grid>
                <Grid container item style={this.state.flagSwitch ? { display: ''} : { display: 'none'}}>
                    <GraphBar switch={this.state.switch_col} disabledSwitch={false}
                        hasFilter={true} init={'left'} />
                </Grid>
                <Grid container item xs={5} className={'outer-switch'}>
                    <Grid item><label>{'UO'}</label></Grid>
                    <Grid item style={{ padding: 9}}>
                        <Switch className={'switch'} disabled={false} id={'switch'} checked={this.state.flagSwitch} 
                            onChange={(e) => { this.setState({ flagSwitch: e.target.checked }); }} color='default' />
                    </Grid>
                    <Grid item><label>{'Segmento industrial'}</label></Grid>
                </Grid>
            </Grid>
        );
    };

}