import React, { Component } from 'react';
import SGTSidebar from './../../components/sidebar/SGTSidebar';
import Header from './../../components/header/header';
import { Redirect } from 'react-router-dom'
import API from './../../comm/API';
import Loading from './../../components/loading/loading';
import Toast from './../../components/toast/toast';
import { Grid } from '@material-ui/core';
import './page.scss';

export default class Page extends Component {
    unidadeList = [];
    
    constructor(props) {
        super(props);
        this.state = { menus: [] , usuarioConectado: this.props.usuarioConectado, change: false, showLoading: props.showLoading !== undefined ? props.showLoading : true};
    }

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    changeUnidade = (u) => {
        if (u != null && u !== '' && u.id !== this.props.usuarioConectado.colaborador.unidade.id) {
            var usuario = this.props.usuarioConectado;
            usuario.colaborador.unidade = u;
            usuario.unidade = u;
            this.showLoading();
            API.post('rest/sistema/Usuario/change', usuario).then(res => {
                this.setState({usuarioConectado: res.data, change: true});
            }).catch(error =>{
                this.showToast('Infelizmente houve um erro ao trocar a unidade do colaborador', 'error');
            })
        }
    }

    removeItemsMenu = (menuItems) => {
        menuItems.forEach(menu => {
            if (menu.nomeGrupoTela === 'Divulgação/Capacitação') {
                menu.itensTela = menu.itensTela.filter(tela => tela.comando === 'cad/pessoa/visita');
            };

            if (menu.nomeGrupoTela === 'Indicadores de ISIs') {
                menu.itensTela = menu.itensTela.filter(tela => tela.comando !== 'cad/sistema/funil');
            };
        })
    }

    componentWillMount() {
        //Caso o usuário seja UO, carregar uma lista com todas as unidades do mesmo.
        let usuario = this.state.usuarioConectado;
        if (usuario && usuario != null && usuario.colaborador != null && usuario.colaborador.isAtivo) {
            usuario.colaborador.colaboradorUnidadeList.forEach((element) => {
                this.unidadeList.push(element.unidade);
            });
            this.setState(this.state);
        }        

        API.get('rest/security/telasUsuario').then(res => {
            //Ordenando os menus de acordo com a ordem estabelecidada no banco de dados.
            let sortedMenus = res.data.sort((menu1, menu2) => parseInt(menu1.ordem) - parseInt(menu2.ordem));

            // SGTME-1882: Removendo itens perífericos  (divulgação e Funil) do menu
            this.removeItemsMenu(sortedMenus);

            this.setState({ menus: sortedMenus, showToast: false }); 
        }).catch(error => {
            this.showToast('Erro ao listar as telas permitidas do usuário. Favor, entrar em contato com o suporte.', 'error');
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.showLoading != undefined && this.props.showLoading !== prevProps.showLoading) {
            this.setState({ showLoading: this.props.showLoading });
        }
    }

    render() {
        if (this.state.change) return (<Redirect to='/' />);
        return (
            <div className='root'>
                                
                <SGTSidebar menus={this.state.menus} usuarioConectado={this.state.usuarioConectado} />
                <Grid container id="main">
                <Loading getFunctions={this.getFunctions} />
                <Toast getFunctions={this.getFunctions} />
                    <Grid item xs={12}>
                        <Toast getFunctions={this.props.getFunctions} />
                    </Grid>
                    <Grid item xs={12}>
                        <Loading getFunctions={this.props.getFunctions} showLoading={this.state.showLoading} />
                    </Grid>
                    <Grid item xs={12} className='content'>
                        <Header icon={this.props.icon} label={this.props.label} iconWidth={this.props.iconWidth} 
                        usuarioConectado={this.state.usuarioConectado} changeUnidade={this.changeUnidade} unidadeList={this.unidadeList}/>
                        {this.props.children}
                    </Grid>
                </Grid>
            </div>
        );
    }
}