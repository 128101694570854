import React, {Component} from 'react'
// import './evento.scss'
import GenericButton from '../../components/genericButton/genericButton';
import FormField from '../../components/formField/formField';
import Table from '../../components/table/table'
import {Grid} from "@material-ui/core";
import Page from '../../components/page/page'
import API from '../../comm/API'
import EmptyList from '../../components/emptyList/emptyList';
import { Link } from 'react-router-dom';

const titles = [
    { id: '0', canOrder: true, label: 'Título' },
    { id: '1', canOrder: true, label: 'Tipo da publicacao' },
    { id: '2', canOrder: true, label: 'Perfil da publicação' },
    { id: '3', canOrder: true, label: 'Ano' },
    { id: '4', canOrder: true,  label: 'Unidade' },
    { id: '5', canOrder: false,  label: ''}]

export default class Publicacao extends Component {

    isUO = false;

    constructor(props) {
        super(props);
        this.state = {
            publicacaoList: [], 
            emptyList: 'seeResults',
            lines: [],
            filter : new URLSearchParams(),
            buscaLivrePublicacao: ''
        }
        this.getFiltro();
    };

    showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    componentDidMount() {
        this.closeLoading();
    };

    getFiltro = () => {
        var filterAux = this.state.filter;
        filterAux.append('ordenarPor', 'titulo');
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            this.isUO = true;
            filterAux.append("unidade.id", this.props.usuarioConectado.colaborador.unidade.id);
        } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR'){
            filterAux.append("unidade.unidadePai.id", this.props.usuarioConectado.unidade.id);
        }
        this.setState({filter: filterAux});
    }    
    changeBuscaLivrePublicacao = (e) => {
        var filterAux = this.state.filter;        
        if (e.target.value != null) {
            this.setState({buscaLivrePublicacao: e.target.value.replace(/!|:|&||/g,'')});
            filterAux.delete("buscaLivrePublicacao");
            filterAux.append("buscaLivrePublicacao", e.target.value.replace(/!|:|&||/g,''))
            if (e.target.value.replace(/!|:|&||/g,'') === ''){
                filterAux.delete("buscaLivrePublicacao");
            }
        } else {
            filterAux.delete("buscaLivrePublicacao");
        }
        this.setState({filter: filterAux});
    }
    getPublicacoes = () => {
        this.showLoading();
        this.setState({lines: []});
        API.get('rest/gestao/Publicacao', {
            params: this.state.filter
        }).then(res => {
            this.setState({publicacaoList: res.data});
            var lines = [];

            for(var i = 0; i < this.state.publicacaoList.length ; i ++){
                var publicacaoAux = [];
    
                publicacaoAux.push(`${this.state.publicacaoList[i].titulo.toUpperCase()}`);
                publicacaoAux.push(`${this.state.publicacaoList[i].tipoPublicacao}`);
                publicacaoAux.push(`${this.state.publicacaoList[i].isComercial === false ? 'Científica' : 'Comercial'}`);
                publicacaoAux.push(`${this.state.publicacaoList[i].ano}`);
                publicacaoAux.push(`${this.state.publicacaoList[i].unidade != null ? this.state.publicacaoList[i].unidade.abreviacao : ''}`);
                publicacaoAux.push( 
                    <div style={{ display: 'flex' }}>
                        <Link to={`/cad/pessoa/publicacao/${this.state.publicacaoList[i].id}`}>
                            <GenericButton color={'transparent'} subClass={'icon-button'} 
                            icon={'pen.svg'}/>
                        </Link>
                    </div>);

                lines.push(publicacaoAux);                  
            }
            this.setState({ lines: lines });
            this.setState({ emptyList: lines.length === 0 ? 'noResults' : '' });
            this.closeLoading();
        }).catch(error => {
            this.closeLoading();
            this.showToast('Error ao carregar lista de publicações. Favor, entrar em contato com o suporte.', 'error');
        }); 
    }


    render() {
        return (
            <Page icon={'doc.svg'} label={'Gerenciar Publicações'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
                <Grid container className={'gerenciar-publicacao'}>
                    <Grid item xs={12} className={'pesquisa'}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={(this.isUO ? 6 : 5)} xl={(this.isUO ? 5 : 4)} className={'grid-left-button'}>
                                {this.isUO ? 
                                    <Link to={'/cad/pessoa/publicacao/novo'}>
                                        <GenericButton color={'darkBlue'} label={'Cadastrar Nova Publicação'} subClass={'basic-button'}/>
                                    </Link>
                                : null }
                                <FormField type={'text'} pressEnter={this.getPublicacoes} placeholder={'busque por título, tipo, meio de publicação'}
                                button={<GenericButton color={'darkBlue'} subClass={'icon-button'} icon={'search.svg'} click={this.getPublicacoes}/>}
                                inputValue={this.state.buscaLivrePublicacao} 
                                changeValue={this.changeBuscaLivrePublicacao}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Table lines={this.state.lines} titles={titles} initialOrder={'0'}/>
                        <EmptyList type={this.state.emptyList} seeAll={this.getPublicacoes}/>                            
                    </Grid>
                </Grid>
            </Page>
        );
    }
}