import { Grid } from "@material-ui/core";
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Downshift from 'downshift';
import deburr from 'lodash/deburr';
import React, { Component } from 'react';
import API from './../../comm/API';
import GenericButton from './../../components/genericButton/genericButton';
import './autoComplete.scss';

import debounce from 'lodash/debounce';

export default class AutoComplete extends Component {

    state = { value: this.setInitialSelectedItem(this.props.initialSelectedItem), suggestionsFromServer: [], obrigatory: false };

    getSuggestions(value, { showEmpty = false } = {}) {
        const inputValue = value ? deburr(value.toString().trim()).toLowerCase() : '';
        const inputLength = inputValue.length;
        let count = 0;

        if (!this.props.path) {
            if (this.props.suggestions) {

                return inputLength === 0 && !showEmpty ? this.props.suggestions
                    : this.props.suggestions.filter(suggestion => {
                        const keep =
                            count < 5 && deburr(suggestion[`${this.props.itemlabel}`]).toLowerCase().includes(inputValue);
                        if (keep) { count += 1; }

                        return keep;
                    });
            } else { return [] }
        } else {
            return this.state.suggestionsFromServer;
        }
    }

    setInitialSelectedItem(initialSelectedItem) {
        //Analisar a necessidade dessa condição
        if (!this.props.button) {
            this.props.getInput(initialSelectedItem);
        }
        return initialSelectedItem;
    }

    componentWillReceiveProps(newProps) {
        // if (newProps.initialSelectedItem) {
            if (!this.props.isComponentShouldNotPropsUpdate) {
                this.setState({ value: newProps.initialSelectedItem });
            }
        // }
    }
    getSuggestionsFromServer = debounce(async (typed) => {
        if(this.props.toUpperCase){
            typed = typed.toUpperCase();
        }
        try {
            const res = await API.get(this.props.path + `${typed}${this.props.posPath ? this.props.posPath : ''}`);
            this.setState({ suggestionsFromServer: res.data });
        } catch (error) {
            console.error(error);
        }
    }, 500);


    showSuggestionsTemplate(suggestion) {
        if (this.props.itemTemplate) {
            return this.props.itemTemplate(suggestion);
        } else {
            return suggestion[`${this.props.itemlabel}`];
        }
    }

    addItens = async () => {
        this.props.getInputButton(this.state.value);
        await this.setState({ value: null });
    }

    renderSuggestion(suggestionProps) {
        const { suggestion, index, itemProps, highlightedIndex } = suggestionProps;
        const isHighlighted = highlightedIndex === index;
        return (
            <MenuItem
                {...itemProps}
                key={suggestion[`${this.props.itemlabel}`] + '_' + (suggestion.id != null ? suggestion.id : Math.random())}
                selected={isHighlighted}
                component="div">
                {this.showSuggestionsTemplate(suggestion)}
            </MenuItem>
        );
    }

    renderInput(inputProps) {
        const { InputProps, ref, ...other } = inputProps;
        return (<TextField InputProps={{ inputRef: ref, classes: {}, ...InputProps }} {...other} disabled={this.props.disabled} />);
    }
    updateState = async (value) => {
        
        if (value.selectedItem) {
            await this.setState({ value: value.selectedItem, obrigatory: false });

            //Analisar a necessidade dessa condição
            if (!this.props.button) {
                this.props.getInput(this.state.value);
           }
        }else{
            // if(this.props.defaultObject){
            //     this.props.getInput(Object.defineProperty({}, `${this.props.itemlabel}`, { value: value.inputValue === undefined ? '' : value.inputValue }));
            // }else{
            //  this.props.getInput('');
            // }
            if(this.props.madatory){
                this.setState({ obrigatory: true });
            }
        }
    }

    get erroFlag() {
        return (this.props.error === undefined || this.props.error === '' ? 'none' : 'inline')
    }

    get warningFlag() {
        return (this.props.warning === undefined || this.props.warning === '' ? 'none' : 'inline')
    }

    render() {
        return (
            <div className={'auto-complete-many'} >
                <label>{this.props.label}</label>
                <Grid container>
                    <Grid item xs={12} className={'auto-complete-field'}>
                        <Downshift disableUnderline={true} selectedItem={this.state.value} id="downshift-options"
                            onStateChange={(value) => { this.updateState(value) }}
                            itemToString={(item) => { 
                                return item ? item[`${this.props.itemlabel}`] : '' 
                                }}>
                            {({
                                clearSelection,
                                getInputProps,
                                getItemProps,
                                getLabelProps,
                                getMenuProps,
                                highlightedIndex,
                                inputValue,
                                isOpen,
                                openMenu,
                                selectedItem
                            }) => {
                                const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({

                                    placeholder: this.props.placeholder,
                                    onChange: event => {
                                        if (event.target.value === "") {
                                            clearSelection();
                                        }
                                        if (this.props.path) {
                                            this.getSuggestionsFromServer(event.target.value);
                                        }
                                    },
                                    onFocus: openMenu
                                });

                                return (
                                    <div className="div-autocomplete">
                                        {this.renderInput({
                                            fullWidth: true,
                                            InputLabelProps: getLabelProps({ shrink: true }),
                                            InputProps: { onBlur, onChange, onFocus },
                                            inputProps
                                        })}

                                        <div {...getMenuProps()}>
                                            {isOpen ? (
                                                <Paper square>
                                                    {this.getSuggestions(inputValue, { showEmpty: false }).map(
                                                        (suggestion, index) =>
                                                            this.renderSuggestion({
                                                                suggestion,
                                                                index,
                                                                itemProps: getItemProps({ item: suggestion }),
                                                                highlightedIndex,
                                                                selectedItem
                                                            })
                                                    )
                                                    }
                                                </Paper>
                                            ) : null}
                                        </div>
                                        <img src={process.env.PUBLIC_URL + '/assets/icons/searchgray.svg'} alt='icon' id={'search-icon'} />
                                    </div>
                                );
                            }}
                        </Downshift>

                        {this.props.button && <GenericButton color={'darkBlue'} subClass={'icon-button'} click={this.addItens} icon={'plus.svg'} disabled={this.props.disabled}/>}
                    </Grid>
                    {this.state.obrigatory && <span>Campo Obrigatório</span>}
                    <span style={{ display: this.warningFlag }}>{this.props.warning}</span>
                </Grid>
            </div>
        )
    }
}