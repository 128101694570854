import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import GenericButton from '../../components/genericButton/genericButton';
import SimpleModal from './../../components/modal/modal';
import AutoComplete from '../../components/autoComplete/autoComplete';
import Toast from '../../components/toast/toast';
import Loading from '../../components/loading/loading';
import API from '../../comm/API';


export default class Prospeccao extends Component {

    errors = {
        acao: '' ,
    };

    constructor(props) {
        super(props);
        let filterAux = new URLSearchParams();
        filterAux.append('ordenarPor', 'nome' );
        filterAux.append('atendimento.id', '' );
        if (this.props.usuarioConectado.colaborador != null && this.props.usuarioConectado.colaborador.isAtivo) {
            this.isUO = true;
            filterAux.append('unidade.id', this.props.usuarioConectado.colaborador.unidade.id);
        } else if (this.props.usuarioConectado.unidade.tipoUnidade.sigla === 'DR'){
            filterAux.append('unidade.unidadePai.id', this.props.usuarioConectado.unidade.id);
        }
        this.state = {
            filter: filterAux,
            acaoList: [],
            acao: {
                id: ''
            },
            remover: null,
        }
    }

    showToast = () => { };
    showLoading = () => { };
    closeLoading = () => { };
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

    getAcoesProspeccao = () => {
        this.showLoading();
        API.get('rest/gestao/Visita/availableVisits',  {
            params: this.state.filter
        })
            .then(res => {
                this.closeLoading();
                this.setState({ acaoList: res.data });
            })
            .catch(error => {
                this.closeLoading();
                this.showToast('Erro ao realizar GET de visitas. Por favor, entre em contato com o suporte.', 'error');
            });
    }

    vincularAcao = () => {
        this.showLoading();
        API.post(`rest/gestao/Visita/vincularAtendimento/${this.state.acao.id}/atendimento/${this.props.atendimento.id}`, {}, {
            headers: {"Access-Control-Allow-Origin": "*"}
        })
            .then(res => {
                this.closeLoading();
                this.setState({ acao: { id: '' } });
                this.props.openAtendimento(this.props.atendimento.id);
                this.getAcoesProspeccao();
                this.props.changeModalShowToast('modalProspeccao', false, 'Ação vinculada com sucesso!' );
            })
            .catch(error => {
                this.closeLoading();
                this.showToast('Erro ao vincular a ação. Por favor, entre em contato com o suporte.', 'error');
            })
    }

    removeAcao = (acao) => {
        this.showLoading();
        API.post(`rest/gestao/Visita/desvincularAtendimento/${acao.id}`, {}, {
            headers: {"Access-Control-Allow-Origin": "*"}
        })
            .then(res => {
                this.closeLoading();
                this.setState({ remover: null });
                this.setState({ acao: { id: '' } });
                this.props.openAtendimento(this.props.atendimento.id);
                this.getAcoesProspeccao();
                this.props.changeModalShowToast('modalProspeccao', false, 'Ação removida com sucesso!' );
            })
            .catch(error => {
                this.closeLoading();
                this.setState({ remover: null });
                this.showToast('Erro ao remover a ação. Por favor, entre em contato com o suporte.', 'error');
            })
    }

    removeMissingAttributes = (prospeccao) => {
        if (!prospeccao.cliente) {
            prospeccao.cliente = {razaoSocial: 'Não possui empresa prospectada'};
        }

        if (!prospeccao.produtoNacional) {
            prospeccao.produtoNacional = {descricao: 'Não possui produto nacional'};
        }

        if (!prospeccao.visitaColaboradorList) {
            prospeccao.visitaColaboradorList = [];
        }

        return prospeccao;
    }

    changeInputAcao = (event) => {
        if (event) this.setState({ acao: event });
    }

    componentDidMount() {
        this.getAcoesProspeccao();
    }

    createTemplateAcao = (acao) => {
        return (
            <div className={'auto-complete-options'}>
                <div>{acao.id}</div>
            </div>
        )
    }

    formatDates = (date) => new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(new Date(date)).toString();


    render() {
        return (
            <Grid item xs={12} md={6}>
                <div className={'greyDiv'}>
                    <GenericButton color={'darkBlue'} subClass={'basic-button'}
                        click={() => this.props.changeModal('modalProspeccao', true)} label={'Ver detalhes'} />
                    <h2>Prospecção</h2>
                    {this.props.atendimento.visitaList != null && this.props.atendimento.visitaList.length > 0 ?
                        this.props.atendimento.visitaList.map(prospeccao => (
                            <Grid container key={prospeccao.id} alignItems="center" justify="center" direction={'row'}>
                                <Grid item xs={1}>
                                    <span>{prospeccao.id}</span>
                                </Grid>
                                <Grid item xs={1}>
                                    <img style={{ display: 'block' }}
                                        src={`${process.env.PUBLIC_URL}/assets/icons/${
                                            prospeccao.isRecebida ? 'arrow-down.svg' : 'arrow-up.svg'
                                        }`}
                                        width={12}
                                        height={12}
                                        alt="ícone" 
                                    /> 
                                </Grid>
                                <Grid item xs={2}>
                                    <span>{prospeccao.isRecebida ? 'Recebida' : 'Realizada'}</span>
                                </Grid>
                                <Grid item xs={8}><p>{this.formatDates(prospeccao.dataInicio)} - {this.formatDates(prospeccao.dataConclusao)}</p></Grid>
                            </Grid>
                        ))
                        :
                        <p>Não há ação de prospecção vinculada</p>
                    }
                </div>
                {this.props.modalProspeccao && (
                    <div>
                        <Toast getFunctions={this.getFunctions} />
                        <SimpleModal
                            isOpen={this.props.modalProspeccao}
                            handleClose={() => this.props.changeModal('modalProspeccao', false)}
                            type={'info'}
                            width={'900px'}
                        >
                            <Loading getFunctions={this.getFunctions} />
                            <div className={'modal'}>
                                <h2>Ação de prospecção</h2>

                                <Grid>
                                    <Grid container style={{ marginBottom: 16 }}>

                                        {this.props.atendimento.visitaList != null && this.props.atendimento.visitaList.length > 0 ?
                                            this.props.atendimento.visitaList.map(prospeccaoPure => {

                                                const prospeccao = this.removeMissingAttributes(prospeccaoPure);

                                                return (
                                                    <Grid container  style={{ marginTop: 16, marginBottom: 16 }} key={prospeccao.id}>
                                                        <Grid 
                                                            container 
                                                            className={'modal-detahe-visita-header'}  
                                                            direction="row"
                                                            alignItems="center"
                                                        >
                                                            <Grid item xs={1}>
                                                                <p className={'font-blue'}>
                                                                    <strong>
                                                                        {prospeccao.id}
                                                                    </strong>
                                                                </p>
                                                            </Grid> 
                                                            <Grid item xs={1}>
                                                                <img style={{ display: 'block', marginLeft: -16 }}
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/${
                                                                        prospeccao.isRecebida ? 'arrow-down.svg' : 'arrow-up.svg'
                                                                    }`}
                                                                    width={12}
                                                                    height={12}
                                                                    alt="ícone" 
                                                                /> 
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <span>{prospeccao.isRecebida ? 'Recebida' : 'Realizada'}</span>
                                                            </Grid>  
                                                            
                                                        
                                                            <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                <img style={{ display: 'block' }}
                                                                    src={`${process.env.PUBLIC_URL}/assets/icons/x.svg`}
                                                                    width={14}
                                                                    height={14}
                                                                    style={{ cursor: 'pointer' }}
                                                                    alt="ícone de fechar"
                                                                    onClick={() => this.setState({ remover: prospeccao })} 
                                                                /> 
                                                            </Grid>
                                                        </Grid>

                                                        <Grid  container direction={'column'}>

                                                            <Grid container  direction={'row'} alignItems="center">
                                                                <Grid item xs={5}>
                                                                    <p className="margin-item-modal-detalhes">
                                                                        <strong>
                                                                            Datas da ação
                                                                        </strong>
                                                                    </p> 
                                                                </Grid>
                                                                <Grid item xs={7} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                                                    <p className="margin-item-modal-detalhes">Início: {this.formatDates(prospeccao.dataInicio)}</p>
                                                                    <p>Término: {this.formatDates(prospeccao.dataConclusao)}</p>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container  direction={'row'}>
                                                                <Grid item xs={5}>
                                                                    <strong>
                                                                        <p className="margin-item-modal-detalhes">Localização</p> 
                                                                    </strong>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <p className="margin-item-modal-detalhes">{prospeccao.cidade} - {prospeccao.estado}, {prospeccao.pais}</p>
                                                                </Grid>
                                                            </Grid>


                                                            <Grid container  direction={'row'}>
                                                                <Grid item xs={5}>
                                                                    <strong>
                                                                        <p className="margin-item-modal-detalhes">Empresa prospectada</p> 
                                                                    </strong>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <p className="margin-item-modal-detalhes">{prospeccao.cliente.razaoSocial}</p>
                                                                </Grid>
                                                            </Grid>


                                                            <Grid container direction={'row'}>
                                                                <Grid item xs={5}>
                                                                    <strong>
                                                                        <p className="margin-item-modal-detalhes">Produto Nacional</p> 
                                                                    </strong>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <p className="margin-item-modal-detalhes">{prospeccao.produtoNacional.descricao}</p>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container direction={'row'}>
                                                                <Grid item xs={5}>
                                                                    <strong>
                                                                        <p className="margin-item-modal-detalhes">Participantes</p> 
                                                                    </strong>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <p className="margin-item-modal-detalhes">{prospeccao.visitaColaboradorList.map((visitaColaborador, index) => {
                                                                        if (!visitaColaborador.colaborador) {
                                                                            visitaColaborador.colaborador = { nome: '' };
                                                                        }
                                                                        
                                                                        const lastItem = prospeccao.visitaColaboradorList.length - 1;

                                                                        return `
                                                                            ${visitaColaborador.colaborador.nome}${
                                                                                visitaColaborador.colaborador === prospeccao.visitaColaboradorList[lastItem].colaborador ? '' : ', '
                                                                            }`;
                                                                    })}</p>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container  direction={'row'}>
                                                                <Grid item xs={5}>
                                                                    <strong>
                                                                        <p className="margin-item-modal-detalhes">Descrição</p> 
                                                                    </strong>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <p className="margin-item-modal-detalhes">{prospeccao.descricao}</p>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid container direction={'row'}>
                                                                <Grid item xs={5}>
                                                                    <strong>
                                                                        <p className="margin-item-modal-detalhes">Atendimento vinculado</p> 
                                                                    </strong>
                                                                </Grid>
                                                                <Grid item xs={7}>
                                                                    <p className="margin-item-modal-detalhes">
                                                                        {this.props.atendimento.titulo}
                                                                    </p>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {this.state.remover != null ? 
                                                            <SimpleModal isOpen={true} handleClose={(e) => this.setState({ remover: null })} disableClick={false} type={'warning'} width={'380px'} className={'evento-modal'}>
                                                                <label>Voce confirma a exclusão do vinculo de ação </label>
                                                                <label className={'label-bold'}>ID {this.state.remover.id} - {this.state.remover.cliente.razaoSocial}?</label>
                                                                <div className={'div-botoes'}>
                                                                <GenericButton color={'darkGrey-outline'} label={'Cancelar'} subClass={'basic-button'} click={(e) => this.setState({ remover: null })} />
                                                                <GenericButton color={'darkBlue'} label={'Excluir'} subClass={'basic-button'} click={() => {
                                                                    this.removeAcao(this.state.remover)
                                                                }} />
                                                                </div>
                                                            </SimpleModal> : null
                                                        }
                                                    </Grid>
                                                )
                                            })
                                            :
                                            <Grid container style={{ marginTop: 16, marginBottom: 32 }}>
                                                <p>Não há ação de prospecção vinculada</p>
                                            </Grid>
                                        }
                                    </Grid>

                                    <Grid container alignItems="center" direction="row" spacing={2}>
                                        <Grid item xs={12} >
                                            <h3 style={{ border: 'none', margin: 0 }}>Vincular ação de prospecção</h3>
                                        </Grid>
                                        <Grid item xs={6} >
                                            <AutoComplete 
                                                placeholder='Digite o número'
                                                suggestions={this.state.acaoList}
                                                itemKey={'id'}
                                                key='autoCompleteAcao'
                                                initialSelectedItem={this.state.acao}
                                                itemlabel={'id'}
                                                getInput={this.changeInputAcao} 
                                                error={this.errors.acao}
                                            />
                                        </Grid>
                                        <Grid item xs={2} >
                                            <GenericButton 
                                                color={'darkBlue'} 
                                                label={'Vincular'} 
                                                subClass={'basic-button'} 
                                                click={this.vincularAcao} 
                                                disabled={(this.state.acao.id ? false : true)}
                                            />
                                        </Grid>


                                        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Link to={'/cad/pessoa/visita/novo'}>
                                                <GenericButton 
                                                    color={'lightBlue'} 
                                                    label={'Cadastrar ação'} 
                                                    subClass={'basic-button'} 
                                                    click={this.vincularAcao} 
                                                />
                                            </Link>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </SimpleModal>
                    </div>
                )}
            </Grid>
        )
    }
}