import React, { Component } from 'react';
import Page from '../../components/page/page';
import { Grid } from '@material-ui/core';
import API from '../../comm/API';
import GridList from '@material-ui/core/GridList';
import FormField from '../../components/formField/formField';
import GenericButton from '../../components/genericButton/genericButton';
import './parametro.scss';

export default class Parametro extends Component {

	constructor(props) {
		super(props);
		this.state = { parametroUnidadeList: [], parametros: [], errors: [] };
		
		if (props.usuarioConectado != null) {
				API.get('rest/gestao/ParametroUnidade?unidade.id=' + props.usuarioConectado.unidade.id).then(res => { 
					var parametroUnidadeList = res.data;
					var parametros = [];
					var parametro = {};
					for (var i = 0; i < parametroUnidadeList.length; i++) {
						var valor = parametroUnidadeList[i].valorString != null && parametroUnidadeList[i].valorString !== '' ? parametroUnidadeList[i].valorString : '';
						var index = parametros.length > 0 ? this.findIndex(parametros, parametroUnidadeList[i].parametro.grupoParametro.nome) : -1;
						if (index < 0) {
							parametros.push(this.parametroListModel(parametroUnidadeList[i].parametro.grupoParametro.nome));
							index = parametros.length - 1;
						}
						parametro = this.parametroModel(parametroUnidadeList[i].parametro.nome, valor, i, parametroUnidadeList[i].parametro.tipoCampo);
						parametros[index].parametroList.push(parametro);
					}
					if (this.state) {
						this.setState({ parametroUnidadeList: res.data, parametros: parametros });
						this.renderParametros();
					} else {
						console.log('state não criado ainda');
					}
					this.closeLoading();
				}).catch(error => {
					this.closeLoading();
					alert('Error ao realizar GET dos parâmetros da unidade.');
				});
			// }
	    } else {
	    	this.closeLoading();
	    	alert('Usuário não conectado.');
		}
		this.closeLoading();
	};

	showToast = () => {};
    showLoading = () => {};
    closeLoading = () => {};
    getFunctions = (showToast, showLoading, closeLoading) => {
        if (showToast != null) this.showToast = showToast;
        if (showLoading != null) this.showLoading = showLoading;
        if (closeLoading != null) this.closeLoading = closeLoading;
    };

	findIndex(parametros, nome) {
		return parametros.findIndex(obj => obj.grupoParametro === nome);
	};

	parametroListModel(grupo) {
		return { grupoParametro: grupo, parametroList: [] };
	};

	parametroModel(parametro, valor, index, tipo) {
		return { parametro: parametro, valor: valor, index: index, tipoCampo: tipo };
	};

	changeParametro = (e) => {
		var indexList = e.target.id.split('_'); //parametrounidade_grupo_parametro
		var parametros = this.state.parametros;
		var parametrosUnidadeList = this.state.parametroUnidadeList;
		if (parametrosUnidadeList[indexList[0]].parametro.tipoCampo === 'input-email') {
			parametros[indexList[1]].parametroList[indexList[2]].valor = e.target.value.toLowerCase();
			parametrosUnidadeList[indexList[0]].valorString = e.target.value;
		} else {
			parametros[indexList[1]].parametroList[indexList[2]].valor = e.target.value;
			parametrosUnidadeList[indexList[0]].valorString = e.target.value;
		}
		this.setState({ parametros: parametros, parametrosUnidadeList: parametrosUnidadeList });
	};

	renderParametros() {
		return (		
			this.state.parametros.map((grupo, i) => 
				<Grid item xs={12} lg={10} xl={7} style={{width: '100%'}} key={i}>
					<div className={'header-parametro'}>
						<label>{grupo.grupoParametro}</label>
						<label style={{'float': 'right'}}> Parâmetros: {grupo.parametroList.length}</label>
					</div>
					<hr />
					<GridList cellHeight={51} cols={1} style={{overflow: 'visible'}}>
	                    {grupo.parametroList.map((item, index) => 
	                    	<div className={'item-lista'} key={index}>
	                    		<span className={'tipo-unidade-span'}>{this.props.usuarioConectado.unidade.tipoUnidade.sigla}</span>
	                    		<label>{item.parametro}</label>
	                    		<GenericButton color={'darkBlue'} subClass={'basic-button'} label={'Salvar'} click={this.salvarParametro} id={item.index} 
	                    			disabled={!this.validarValor(item)} />
	                        	<FormField type={'text'} inputValue={item.valor} placeholder={item.tipoCampo === 'input-email' ? 'exemplo@email.com.br' : 
	                        		(item.parametro.includes('URL') ? 'www.exemplo.com.br' : '')} id={item.index + '_' + i + '_' + index} changeValue={this.changeParametro} 
	                        		error={this.validarValor(item) ? null : 'Email inválido.'} />
	                        </div>
	                    )}
	                </GridList>
				</Grid>
			)
		);
	};

	salvarParametro = (e) => {
		var id = this.state.parametroUnidadeList[e.target.id].id;
		API.post('rest/gestao/ParametroUnidade/' + id, this.state.parametroUnidadeList[e.target.id]).then(res => {
			this.showToast('Parâmetro salvo com sucesso.', 'success');
        }).catch(error => {
        	this.showToast('Erro ao salvar o parâmetro. Por favor, entre em contato com o suporte.', 'error');
        });
	};

	validarValor = (item) => {
		var validar = true;
		if (item.valor != null && item.valor.length > 0) {
			if (item.tipoCampo === 'input-email' && !(new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g).test(item.valor))) {
				validar = false; 
			}
		}
		return validar;
	};

    
	render() {			
		return (
			<Page icon={'config2.svg'} value={'Parâmetros'} label={'Parâmetros'} getFunctions={this.getFunctions} usuarioConectado={this.props.usuarioConectado}>
				<Grid container className={'parametro'}>
					<Grid item xs={12} lg={10} xl={7} className={'top-pagina'}>
						<label>Grupos de Parâmetros</label>
					</Grid>
					{ this.state.parametros.length > 0 ? this.renderParametros() : <div></div> }
				</Grid>
			</Page>
		);
	};
}